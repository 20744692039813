import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string';
import { Button } from './index';
import { getStrippedColumnId } from '../utils/grid';

export default class GridTextFilter extends Component {
  constructor(props) {
    super(props);
    const valuesSet = new Set();
    props.rowModel.rowsToDisplay.forEach(row => {
      valuesSet.add(row.data[props.colDef.field]);
    });

    const search = queryString.parse(props.getSearch());
    const strippedId = getStrippedColumnId(props.column.colId);

    this.state = {
      options: Array.from(valuesSet).sort().map(value => ({ value, label: value })).filter(option => !!option.value),
      selectedOptions: search[`${strippedId}Filter`] ? search[`${strippedId}Filter`].map(value => ({ value, label: value })) : [],
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  doesFilterPass(params) {
    return !this.state.selectedOptions.length || !!this.state.selectedOptions.find(option => option.value === params.data[this.props.colDef.field]);
  }

  isFilterActive() {
    return this.state.selectedOptions.length;
  }

  handleSelect(selectedOptions) {
    this.setState({ selectedOptions: selectedOptions || [] });
  }

  setModel(model) {
    if (model === null) {
      this.setState({ selectedOptions: [] }, this.changeFilterAndUpdateHistory());
    }
  }

  async handleFilter(evt) {
    this.props.api.hidePopupMenu();
    if (evt.target.value === 'Reset') {
      await this.setState({ selectedOptions: [] });
    }
    this.changeFilterAndUpdateHistory();
  }

  changeFilterAndUpdateHistory() {
    this.props.filterChangedCallback();
    const search = queryString.parse(this.props.getSearch());
    const [id] = this.props.column.colId.split('_');
    const filterKey = `${id}Filter`;
    if (this.state.selectedOptions.length) {
      search[filterKey] = this.state.selectedOptions.map(option => option.value);
    } else if (search[filterKey]) {
      delete search[filterKey];
    }
    const updatedQueryString = queryString.stringify(search);
    this.props.pushHistory('/rankings', `?${updatedQueryString}`);
  }

  render() {
    return (
      <div className="grid-filter-container">
        <div className="grid-filter-footer">
          <Button
            appearance="danger"
            onClick={this.handleFilter}
          >
            Reset
          </Button>
          <Button
            appearance="primary"
            onClick={this.handleFilter}
          >
            Apply
          </Button>
        </div>
        <div className="grid-filter-select">
          <Select
            isMulti
            menuIsOpen
            onChange={this.handleSelect}
            options={this.state.options}
            value={this.state.selectedOptions}
          />
        </div>
      </div>
    )
  }
};
