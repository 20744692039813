import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { Button, Input } from './index';

export default class GridNumberFilter extends Component {
  static equals = 'Equals';
  static lessThan = 'Less than';
  static lessThanEqual = 'Less than or equal';
  static greaterThan = 'Greater than';
  static greaterThanEqual = 'Greater than or equal';
  static inRange = 'In range';
  static inputOne = 'input-one';
  static inputTwo = 'input-two';
  static filterTypes = [
    { value: GridNumberFilter.equals, label: GridNumberFilter.equals },
    { value: GridNumberFilter.lessThan, label: GridNumberFilter.lessThan },
    { value: GridNumberFilter.lessThanEqual, label: GridNumberFilter.lessThanEqual },
    { value: GridNumberFilter.greaterThan, label: GridNumberFilter.greaterThan },
    { value: GridNumberFilter.greaterThanEqual, label: GridNumberFilter.greaterThanEqual },
    { value: GridNumberFilter.inRange, label: GridNumberFilter.inRange },
  ]
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      selectedFilterType: GridNumberFilter.filterTypes[0],
      value: '',
      valueTwo: '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  doesFilterPass(params) {
    if (this.state.value.length) {
      const colValue = this.props.valueGetter(params.node);
      const filterValueOne = Number(this.state.value);
      const filterValueTwo = Number(this.state.valueTwo);

      switch (this.state.selectedFilterType.value) {
        case GridNumberFilter.equals:
          return colValue === filterValueOne;
        case GridNumberFilter.lessThan:
          return colValue < filterValueOne;
        case GridNumberFilter.lessThanEqual:
          return colValue <= filterValueOne;
        case GridNumberFilter.greaterThan:
          return colValue > filterValueOne;
        case GridNumberFilter.greaterThanEqual:
          return colValue >= filterValueOne;
        case GridNumberFilter.inRange:
          return colValue >= filterValueOne && colValue <= filterValueTwo;
        default:
          return true;
      }
    } else {
      return true;
    }
  }

  isFilterActive() {
    return this.state.value.length;
  }

  handleSelect(selectedFilterType) {
    this.setState({ selectedFilterType });
  }

  handleChange(evt) {
    const key = evt.target.name === GridNumberFilter.inputOne ? 'value' : 'valueTwo'
    this.setState({ [key]: evt.target.value });
  }

  async handleFilter(evt) {
    this.props.api.hidePopupMenu();
    if (evt.target.value === 'Reset') {
      await this.setState({ value: '', valueTwo: '' });
    }
    this.props.filterChangedCallback();
  }

  handleMenuClose() {
    this.setState({ isMenuOpen: false });
  }

  handleMenuOpen() {
    this.setState({ isMenuOpen: true });
  }

  setModel(model) {
    // Ag Grid filter component required method
    // Return null to make a no-op
    return null;
  }

  render() {
    return (
      <div className={classNames('grid-filter-container', { 'grid-filter-menu-open': this.state.isMenuOpen })}>
        <div className="grid-filter-footer">
          <Button
            appearance="danger"
            onClick={this.handleFilter}
          >
            Reset
          </Button>
          <Button
            appearance="primary"
            onClick={this.handleFilter}
          >
            Apply
          </Button>
        </div>
        <Select
          onChange={this.handleSelect}
          onMenuClose={this.handleMenuClose}
          onMenuOpen={this.handleMenuOpen}
          options={GridNumberFilter.filterTypes}
          value={this.state.selectedFilterType}
        />
        <Input
          name={GridNumberFilter.inputOne}
          onChange={this.handleChange}
          type="number"
          value={this.state.value}
        />
        {
          this.state.selectedFilterType.value === GridNumberFilter.inRange
            ? (
              <Input
                name={GridNumberFilter.inputTwo}
                onChange={this.handleChange}
                type="number"
                value={this.state.valueTwo}
              />
            )
            : null
        }
      </div>
    )
  }
};
