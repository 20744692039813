import { GET_RANKINGS_FOR_YEAR, GET_UPDATE_TIME, GET_YEARS } from './actions';
import { allSeasons, rankings, lastUpdate } from '../dummy';

const initialState = process.env.NODE_ENV === 'development'
  ? { rankings: { '2020': rankings, ...allSeasons }, lastUpdate, years: ['2018', '2019', '2020'] }
  : { rankings: { ...allSeasons }, years: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RANKINGS_FOR_YEAR:
      return { ...state, rankings: { ...state.rankings, [action.season]: action.rankings } };
    case GET_UPDATE_TIME:
      return { ...state, lastUpdate: action.lastUpdate };
    case GET_YEARS:
      return { ...state, years: action.years };
    default:
      return initialState;
  }
}