import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import moment from 'moment-timezone';
import Select from 'react-select';
import memoize from 'memoize-one';
import history from '../history';
import queryString from 'query-string';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {
  GridHeader,
  GridLabel,
  GridLink,
  GridNumberFilter,
  GridTextFilter,
  withFirebase
} from '../components';
import { getColumnIdFromStart } from '../utils/grid';

class Rankings extends Component {
  static defaultColumnWidth = 100;
  static teamColumnWidth = 150;

  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    this.state = {
      yearToView: search.year ? { value: search.year, label: search.year } : { value: '2020', label: '2020' },
      incomingYear: null,
      gridWidth: null,
    }

    this.getColumns = this.getColumns.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSearch = this.getSearch.bind(this);
    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleSelectYearChange = this.handleSelectYearChange.bind(this);
    this.memoizedGetColumns = memoize(this.getColumns);
    this.memoizedGetRows = memoize(this.getRows);
    this.pushHistory = this.pushHistory.bind(this);
  }

  static getLastUpdateTime = memoize(lastUpdate => {
    if (lastUpdate) {
      const timezone = moment.tz.guess();
      return {
        lastUpdate: moment.tz(lastUpdate, timezone).format('LLL'),
        zoneAbbr: moment.tz(timezone).zoneAbbr(),
      }
    } else {
      return {};
    }
  });

  handleGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const search = Object.entries(queryString.parse(this.props.location.search));
    const adjEmId = getColumnIdFromStart(this.gridColumnApi.columnController.gridColumns, 'adjEm');
    const sorts = search.length
      ? search.reduce((result, [key, value], index) => {
        if (key.includes('Sort')) {
          return [
            ...result,
            {
              colId: getColumnIdFromStart(this.gridColumnApi.columnController.gridColumns, key.replace('Sort', '')),
              sort: value
            }
          ]
        } else if (index === search.length - 1 && !result.length) {
          // Default sort to adjEm
          return [{ colId: adjEmId, sort: 'desc' }];
        } else {
          return result;
        }
      }, [])
      : [{ colId: adjEmId, sort: 'desc' }];
    const filters = search.reduce((result, [key, values]) => {
      if (key.includes('Filter')) {
        const column = getColumnIdFromStart(this.gridColumnApi.columnController.gridColumns, key.replace('Filter', ''));
        result[column] = {
          type: 'set',
          values
        }
      }
      return result;
    }, {})
    // TODO: Finish filter
    this.gridApi.setSortModel(sorts);
    this.gridApi.setFilterModel(filters);
  }

  static getDefaultRowData(team, teamData) {
    return {
      team,
      conference: teamData.conference,
      record: teamData.record,
      rankHistory: teamData.rankHistory,
      adjEm: teamData.adjEM,
      adjEmHistory: teamData.adjEmHistory,
      adjOff: teamData.adjOff,
      adjOffHistory: teamData.adjOffHistory,
      adjOffRankHistory: teamData.adjOffRankHistory,
      adjDef: teamData.adjDef,
      adjDefHistory: teamData.adjDefHistory,
      adjDefRankHistory: teamData.adjDefRankHistory,
      adjTempo: teamData.adjTempo,
      adjTempoHistory: teamData.adjTempoHistory,
      adjTempoRankHistory: teamData.adjTempoRankHistory,
      rank: teamData.rank
    }
  }

  getRows(rankings, years, yearToView) {
    if (yearToView === 'All') {
      return years
        .reduce((result, year) => {
          result = [...result, ...Object.entries(rankings[year]).map(([key, value]) => ({ ...value, team: key, year }))];
          return result;
        }, [])
        .sort((a, b) => b.adjEM - a.adjEM)
        .map((teamData, idx) => ({
          ...Rankings.getDefaultRowData(teamData.team, teamData),
          year: Number(teamData.year),
          seed: teamData.seed,
          finish: teamData.finish,
          rank: idx + 1,
        }));
    } else if (rankings[yearToView]) {
      return Object.entries(rankings[yearToView])
        .map(([team, teamData]) => Rankings.getDefaultRowData(team, teamData))
    } else {
      return [];
    }
  }

  getColumns(year) {
    const columns = [
      {
        headerName: 'Rank',
        field: 'rank',
        pinned: 'left',
        width: 60,
      },
      {
        headerName: 'Team',
        field: 'team',
        width: Rankings.teamColumnWidth,
        pinned: 'left',
        cellRenderer: year === '2020' ? 'GridLink' : null,
        cellRendererParams: { year: this.state.yearToView.value },
        filter: 'GridTextFilter',
      },
      { headerName: 'Conf', field: 'conference', filter: 'GridTextFilter', width: 70 },
      { headerName: 'Record', field: 'record', width: 70 },
    ];
    const kenpom = {
      headerName: 'Kenpom', children: [
        { headerName: 'AdjEM', field: 'adjEm', cellRenderer: 'GridLabel', filter: 'GridNumberFilter', },
        { headerName: 'AdjOff', field: 'adjOff', cellRenderer: 'GridLabel', filter: 'GridNumberFilter', },
        { headerName: 'AdjDef', field: 'adjDef', cellRenderer: 'GridLabel', filter: 'GridNumberFilter', },
        { headerName: 'AdjTempo', field: 'adjTempo', cellRenderer: 'GridLabel', filter: 'GridNumberFilter', },
      ],
    };
    if (year === 'All') {
      columns.push(...[
        { headerName: 'Year', field: 'year', filter: 'GridTextFilter' },
        {
          headerName: 'NCAAT', children: [
            { headerName: 'Seed', field: 'seed', filter: 'GridNumberFilter' },
            { headerName: 'Finish', field: 'finish', filter: 'GridTextFilter' },
          ]
        },
        kenpom,
      ])
    } else {
      columns.push(kenpom)
    }

    return columns;
  }

  async handleSelectYearChange(option) {
    await this.setState({ yearToView: option });
    this.gridApi.setSortModel([
      {
        colId: getColumnIdFromStart(this.gridColumnApi.columnController.gridColumns, 'adjEm'),
        sort: "desc"
      }
    ]);
    this.gridApi.setFilterModel(null);
    history.push({
      pathname: '/rankings',
      search: `?year=${option.value}`
    });
  }

  static getGridWidth(columns) {
    let width = 0;
    columns.forEach(c => {
      if (c.children) {
        width += Rankings.getGridWidth(c.children);
      } else if (c.width) {
        width += c.width
      } else {
        width += Rankings.defaultColumnWidth;
      }
    })
    return width + scrollbarSize();
  }

  pushHistory(pathname, search) {
    const update = { pathname };
    if (search) {
      update.search = search;
    }
    history.push(update);
  }

  getSearch() {
    return this.props.location.search;
  }

  render() {
    const rows = this.memoizedGetRows(this.props.rankings, this.props.years, this.state.yearToView.value);
    const columns = this.memoizedGetColumns(this.state.yearToView.value);
    const gridWidth = Rankings.getGridWidth(columns);

    const yearSelectOptions = this.props.years
      ? [{ value: 'All', label: 'All' }, ...this.props.years.sort((a, b) => b - a).map(y => ({ value: y, label: y }))]
      : [];

    const { lastUpdate, zoneAbbr } = Rankings.getLastUpdateTime(this.props.lastUpdate);

    return (
      <div className="rankings-view-container">
        <div className="rankings-select-container">
          <Select
            isSearchable={false}
            onChange={this.handleSelectYearChange}
            options={yearSelectOptions}
            value={this.state.yearToView}
          />
        </div>
        <div className="ag-theme-balham-dark rankings-view-container" style={{ maxWidth: gridWidth }}>
          <AgGridReact
            columnDefs={columns}
            defaultColDef={{
              filterParams: {
                getSearch: this.getSearch,
                pushHistory: this.pushHistory,
              },
              headerComponentParams: {
                getSearch: this.getSearch,
                pushHistory: this.pushHistory,
              },
              sortable: true,
              width: Rankings.defaultColumnWidth,
            }}
            rowData={rows}
            onGridReady={this.handleGridReady}
            overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading data for ${this.state.incomingYear} season...`}
            frameworkComponents={{ agColumnHeader: GridHeader, GridLabel, GridLink, GridTextFilter, GridNumberFilter }}
            suppressMovableColumns
          />
        </div>
        {
          lastUpdate
            ? <div className="rankings-last-update">Last update: {lastUpdate} {zoneAbbr}</div>
            : null
        }
      </div>
    )
  }
}

const mapState = state => ({
  lastUpdate: state.lastUpdate,
  rankings: state.rankings,
  years: state.years,
})

export default connect(mapState)(withFirebase(Rankings));
