import React from 'react';
import moment from 'moment-timezone';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const GridLabel = props => {
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const useHistory = props.data.rankHistory && props.data.rankHistory[today];
  let difference = 0;
  let ranking;

  if (useHistory) {
    switch (props.column.colDef.field) {
      case 'adjEm':
        difference = props.data.rankHistory[yesterday] - props.data.rankHistory[today];
        ranking = props.data.rankHistory[today];
        break;
      case 'adjOff':
        difference = props.data.adjOffRankHistory[yesterday] - props.data.adjOffRankHistory[today];
        ranking = props.data.adjOffRankHistory[today];
        break;
      case 'adjDef':
        difference = props.data.adjDefRankHistory[yesterday] - props.data.adjDefRankHistory[today];
        ranking = props.data.adjDefRankHistory[today];
        break;
      case 'adjTempo':
        difference = props.data.adjTempoRankHistory[yesterday] - props.data.adjTempoRankHistory[today];
        ranking = props.data.adjTempoRankHistory[today];
        break;
      default:
        difference = 0;
        ranking = 'n/a';
    }
  }

  const icon =
    difference > 0
      ? <FaArrowUp className="grid-label-arrow grid-label-up-arrow" />
      : difference < 0
        ? <FaArrowDown className="grid-label-arrow grid-label-down-arrow" />
        : null;

  return (
    <div className="grid-label">
      <div className="grid-label-value">{props.data[props.column.colDef.field]}</div>
      {
        useHistory
          ? <div className="grid-label-difference">{ranking} {icon}{difference ? `(${difference})` : null}</div>
          : null
      }
    </div>
  )
}

export default GridLabel;