import React from 'react';
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

const Navbar = () => (
  <div className="navbar">
    <Menu>
      <NavLink to="/rankings">Rankings</NavLink>
      <NavLink to="/about">About</NavLink>
    </Menu>
    <div className="navbar-routes">
      <NavLink to="/">Choopalytics</NavLink>
    </div>
  </div>
)

export default Navbar;