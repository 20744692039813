import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-functions';
import { firebaseConfig } from '../../secrets';
import { getRankingsForYear, getUpdateTime, getYears } from '../../store';
import axios from 'axios';

class Firebase {
  constructor(store) {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.firebase = app;
    this.store = store;
    this.sayHello = this.sayHello.bind(this);
  }

  async getRankings(season = '2020') {
    const rankings = await this.db
      .collection('rankings')
      .doc(season)
      .collection('kenpom').get();
    const data = {};
    rankings.forEach(doc => {
      data[doc.id] = doc.data();
    });
    this.store.dispatch(getRankingsForYear(data, season));
  }

  async getUpdateTime() {
    const doc = await this.db
      .collection('rankings')
      .doc('2020')
      .get();
    this.store.dispatch(getUpdateTime(doc.data().last_update));
  }

  async getYears() {
    const yearDocs = await this.db.collection('rankings').get();
    const years = [];
    yearDocs.forEach(doc => years.push(doc.id));
    this.store.dispatch(getYears(years));
  }

  async sayHello() {
    // How to invoke an HTTP function https://firebase.google.com/docs/functions/http-events#invoke_an_http_function
    try {
      const message = await axios.post(`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/hello_http`, { name: 'Ari' })
      console.log(message)
      return message;
    } catch (err) {
      throw err
    }
  }
}

export default Firebase;