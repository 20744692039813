import React from 'react';

const About = () => (
  <div className="about-container">
    <div className="header-title">About</div>
    <p>Welcome to Choopalytics!</p>
    <p>
      &emsp;Sites like <a href="https://kenpom.com/">Kenpom</a> have provided me tremendous insight into college basketball --
      both in my former life as a sports writer and now as a software engineer who still loves watching and thinking about college hoops.
    </p>
    <p>
      &emsp;I have sought to extrapolate Ken Pomeroy's renowned data into a more user friendly environment. Here at Choopalytics,
        you can easily compare teams across multiple seasons, filter on NCAA tournament success and view charts displaying
        efficiency trends.
    </p>
    <p>
      &emsp;I have only pulled in the data Kenpom provides free of charge, so you will not find player stats or team stats beyond
        the front-page summary of efficiency ratings. And for now, I am only pulling Kenpom data but hope to expand to other metrics.
    </p>
    <p>
      Please enjoy!
    </p>
    <p>
      - Ari
    </p>
  </div>
)

export default About;
