// console.log(JSON.stringify(this.props.rankings['2020'], null, 2))
export const lastUpdate = "2020-01-24T11:00:12.867613+00:00";
export const rankings = {
  "Abilene Christian": {
    "adjDef": 100.9,
    "adjDefHistory": {
      "2020-01-19": 100.9,
      "2020-01-20": 100.9,
      "2020-01-21": 101,
      "2020-01-22": 100.9,
      "2020-01-23": 100.9
    },
    "adjDefRank": 156,
    "adjDefRankHistory": {
      "2020-01-19": 154,
      "2020-01-20": 155,
      "2020-01-21": 157,
      "2020-01-22": 154,
      "2020-01-23": 156
    },
    "adjEM": -3.31,
    "adjEmHistory": {
      "2020-01-19": -3.61,
      "2020-01-20": -3.61,
      "2020-01-21": -3.54,
      "2020-01-22": -3.44,
      "2020-01-23": -3.31
    },
    "adjOff": 97.6,
    "adjOffHistory": {
      "2020-01-19": 97.3,
      "2020-01-20": 97.3,
      "2020-01-21": 97.5,
      "2020-01-22": 97.4,
      "2020-01-23": 97.6
    },
    "adjOffRank": 249,
    "adjOffRankHistory": {
      "2020-01-19": 250,
      "2020-01-20": 251,
      "2020-01-21": 249,
      "2020-01-22": 250,
      "2020-01-23": 249
    },
    "adjTempo": 69.6,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.9,
      "2020-01-22": 69.9,
      "2020-01-23": 69.6
    },
    "adjTempoRank": 117,
    "adjTempoRankHistory": {
      "2020-01-19": 102,
      "2020-01-20": 103,
      "2020-01-21": 104,
      "2020-01-22": 103,
      "2020-01-23": 117
    },
    "conference": "Slnd",
    "rank": 201,
    "rankHistory": {
      "2020-01-19": 206,
      "2020-01-20": 206,
      "2020-01-21": 205,
      "2020-01-22": 204,
      "2020-01-23": 201
    },
    "record": "10-8"
  },
  "Air Force": {
    "adjDef": 109.1,
    "adjDefHistory": {
      "2020-01-19": 109.6,
      "2020-01-20": 109.6,
      "2020-01-21": 109.8,
      "2020-01-22": 109.2,
      "2020-01-23": 109.1
    },
    "adjDefRank": 318,
    "adjDefRankHistory": {
      "2020-01-19": 322,
      "2020-01-20": 322,
      "2020-01-21": 322,
      "2020-01-22": 318,
      "2020-01-23": 318
    },
    "adjEM": -0.45,
    "adjEmHistory": {
      "2020-01-19": -0.01,
      "2020-01-20": -0.01,
      "2020-01-21": 0,
      "2020-01-22": -0.64,
      "2020-01-23": -0.45
    },
    "adjOff": 108.7,
    "adjOffHistory": {
      "2020-01-19": 109.6,
      "2020-01-20": 109.6,
      "2020-01-21": 109.8,
      "2020-01-22": 108.5,
      "2020-01-23": 108.7
    },
    "adjOffRank": 49,
    "adjOffRankHistory": {
      "2020-01-19": 37,
      "2020-01-20": 37,
      "2020-01-21": 37,
      "2020-01-22": 47,
      "2020-01-23": 49
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.4,
      "2020-01-22": 68.3,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 182,
    "adjTempoRankHistory": {
      "2020-01-19": 182,
      "2020-01-20": 182,
      "2020-01-21": 183,
      "2020-01-22": 182,
      "2020-01-23": 182
    },
    "conference": "MWC",
    "rank": 176,
    "rankHistory": {
      "2020-01-19": 168,
      "2020-01-20": 168,
      "2020-01-21": 168,
      "2020-01-22": 177,
      "2020-01-23": 176
    },
    "record": "9-11"
  },
  "Akron": {
    "adjDef": 96.8,
    "adjDefHistory": {
      "2020-01-19": 97.3,
      "2020-01-20": 97.3,
      "2020-01-21": 97.4,
      "2020-01-22": 96.6,
      "2020-01-23": 96.8
    },
    "adjDefRank": 89,
    "adjDefRankHistory": {
      "2020-01-19": 94,
      "2020-01-20": 94,
      "2020-01-21": 93,
      "2020-01-22": 87,
      "2020-01-23": 89
    },
    "adjEM": 12.18,
    "adjEmHistory": {
      "2020-01-19": 10.86,
      "2020-01-20": 10.87,
      "2020-01-21": 10.97,
      "2020-01-22": 12.22,
      "2020-01-23": 12.18
    },
    "adjOff": 109,
    "adjOffHistory": {
      "2020-01-19": 108.2,
      "2020-01-20": 108.2,
      "2020-01-21": 108.4,
      "2020-01-22": 108.9,
      "2020-01-23": 109
    },
    "adjOffRank": 44,
    "adjOffRankHistory": {
      "2020-01-19": 47,
      "2020-01-20": 47,
      "2020-01-21": 48,
      "2020-01-22": 45,
      "2020-01-23": 44
    },
    "adjTempo": 70.2,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.5,
      "2020-01-22": 70.2,
      "2020-01-23": 70.2
    },
    "adjTempoRank": 84,
    "adjTempoRankHistory": {
      "2020-01-19": 77,
      "2020-01-20": 77,
      "2020-01-21": 77,
      "2020-01-22": 87,
      "2020-01-23": 84
    },
    "conference": "MAC",
    "rank": 58,
    "rankHistory": {
      "2020-01-19": 65,
      "2020-01-20": 65,
      "2020-01-21": 65,
      "2020-01-22": 58,
      "2020-01-23": 58
    },
    "record": "15-4"
  },
  "Alabama": {
    "adjDef": 93.8,
    "adjDefHistory": {
      "2020-01-19": 94.9,
      "2020-01-20": 94.9,
      "2020-01-21": 95.1,
      "2020-01-22": 94.9,
      "2020-01-23": 93.8
    },
    "adjDefRank": 54,
    "adjDefRankHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 54
    },
    "adjEM": 15.46,
    "adjEmHistory": {
      "2020-01-19": 14.83,
      "2020-01-20": 14.84,
      "2020-01-21": 14.84,
      "2020-01-22": 14.79,
      "2020-01-23": 15.46
    },
    "adjOff": 109.3,
    "adjOffHistory": {
      "2020-01-19": 109.8,
      "2020-01-20": 109.8,
      "2020-01-21": 109.9,
      "2020-01-22": 109.7,
      "2020-01-23": 109.3
    },
    "adjOffRank": 42,
    "adjOffRankHistory": {
      "2020-01-19": 36,
      "2020-01-20": 36,
      "2020-01-21": 36,
      "2020-01-22": 38,
      "2020-01-23": 42
    },
    "adjTempo": 76.2,
    "adjTempoHistory": {
      "2020-01-19": 76.5,
      "2020-01-20": 76.5,
      "2020-01-21": 76.3,
      "2020-01-22": 76.2,
      "2020-01-23": 76.2
    },
    "adjTempoRank": 3,
    "adjTempoRankHistory": {
      "2020-01-19": 3,
      "2020-01-20": 3,
      "2020-01-21": 2,
      "2020-01-22": 3,
      "2020-01-23": 3
    },
    "conference": "SEC",
    "rank": 42,
    "rankHistory": {
      "2020-01-19": 43,
      "2020-01-20": 43,
      "2020-01-21": 44,
      "2020-01-22": 44,
      "2020-01-23": 42
    },
    "record": "11-7"
  },
  "Alabama A&M": {
    "adjDef": 110,
    "adjDefHistory": {
      "2020-01-19": 109.6,
      "2020-01-20": 109.7,
      "2020-01-21": 110,
      "2020-01-22": 109.9,
      "2020-01-23": 110
    },
    "adjDefRank": 328,
    "adjDefRankHistory": {
      "2020-01-19": 324,
      "2020-01-20": 324,
      "2020-01-21": 326,
      "2020-01-22": 326,
      "2020-01-23": 328
    },
    "adjEM": -17.75,
    "adjEmHistory": {
      "2020-01-19": -17.53,
      "2020-01-20": -17.53,
      "2020-01-21": -17.66,
      "2020-01-22": -17.73,
      "2020-01-23": -17.75
    },
    "adjOff": 92.3,
    "adjOffHistory": {
      "2020-01-19": 92.1,
      "2020-01-20": 92.1,
      "2020-01-21": 92.3,
      "2020-01-22": 92.2,
      "2020-01-23": 92.3
    },
    "adjOffRank": 323,
    "adjOffRankHistory": {
      "2020-01-19": 323,
      "2020-01-20": 323,
      "2020-01-21": 322,
      "2020-01-22": 322,
      "2020-01-23": 323
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.6,
      "2020-01-22": 68.6,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 170,
    "adjTempoRankHistory": {
      "2020-01-19": 167,
      "2020-01-20": 167,
      "2020-01-21": 168,
      "2020-01-22": 168,
      "2020-01-23": 170
    },
    "conference": "SWAC",
    "rank": 338,
    "rankHistory": {
      "2020-01-19": 336,
      "2020-01-20": 336,
      "2020-01-21": 337,
      "2020-01-22": 337,
      "2020-01-23": 338
    },
    "record": "5-11"
  },
  "Alabama St.": {
    "adjDef": 107,
    "adjDefHistory": {
      "2020-01-19": 106.6,
      "2020-01-20": 106.6,
      "2020-01-21": 107,
      "2020-01-22": 106.9,
      "2020-01-23": 107
    },
    "adjDefRank": 291,
    "adjDefRankHistory": {
      "2020-01-19": 287,
      "2020-01-20": 287,
      "2020-01-21": 292,
      "2020-01-22": 291,
      "2020-01-23": 291
    },
    "adjEM": -18.46,
    "adjEmHistory": {
      "2020-01-19": -18.17,
      "2020-01-20": -18.17,
      "2020-01-21": -18.37,
      "2020-01-22": -18.37,
      "2020-01-23": -18.46
    },
    "adjOff": 88.5,
    "adjOffHistory": {
      "2020-01-19": 88.5,
      "2020-01-20": 88.5,
      "2020-01-21": 88.6,
      "2020-01-22": 88.5,
      "2020-01-23": 88.5
    },
    "adjOffRank": 345,
    "adjOffRankHistory": {
      "2020-01-19": 345,
      "2020-01-20": 345,
      "2020-01-21": 345,
      "2020-01-22": 344,
      "2020-01-23": 345
    },
    "adjTempo": 68.6,
    "adjTempoHistory": {
      "2020-01-19": 68.8,
      "2020-01-20": 68.8,
      "2020-01-21": 68.7,
      "2020-01-22": 68.7,
      "2020-01-23": 68.6
    },
    "adjTempoRank": 162,
    "adjTempoRankHistory": {
      "2020-01-19": 161,
      "2020-01-20": 161,
      "2020-01-21": 162,
      "2020-01-22": 163,
      "2020-01-23": 162
    },
    "conference": "SWAC",
    "rank": 340,
    "rankHistory": {
      "2020-01-19": 339,
      "2020-01-20": 339,
      "2020-01-21": 339,
      "2020-01-22": 339,
      "2020-01-23": 340
    },
    "record": "3-15"
  },
  "Albany": {
    "adjDef": 101.9,
    "adjDefHistory": {
      "2020-01-19": 102.1,
      "2020-01-20": 102.1,
      "2020-01-21": 102.2,
      "2020-01-22": 102.1,
      "2020-01-23": 101.9
    },
    "adjDefRank": 173,
    "adjDefRankHistory": {
      "2020-01-19": 175,
      "2020-01-20": 175,
      "2020-01-21": 175,
      "2020-01-22": 176,
      "2020-01-23": 173
    },
    "adjEM": -4.23,
    "adjEmHistory": {
      "2020-01-19": -5.18,
      "2020-01-20": -5.18,
      "2020-01-21": -5.28,
      "2020-01-22": -5.35,
      "2020-01-23": -4.23
    },
    "adjOff": 97.7,
    "adjOffHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 96.9,
      "2020-01-21": 96.9,
      "2020-01-22": 96.8,
      "2020-01-23": 97.7
    },
    "adjOffRank": 247,
    "adjOffRankHistory": {
      "2020-01-19": 261,
      "2020-01-20": 261,
      "2020-01-21": 264,
      "2020-01-22": 265,
      "2020-01-23": 247
    },
    "adjTempo": 68.6,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.3,
      "2020-01-22": 68.3,
      "2020-01-23": 68.6
    },
    "adjTempoRank": 164,
    "adjTempoRankHistory": {
      "2020-01-19": 184,
      "2020-01-20": 184,
      "2020-01-21": 184,
      "2020-01-22": 183,
      "2020-01-23": 164
    },
    "conference": "AE",
    "rank": 213,
    "rankHistory": {
      "2020-01-19": 228,
      "2020-01-20": 228,
      "2020-01-21": 227,
      "2020-01-22": 227,
      "2020-01-23": 213
    },
    "record": "11-9"
  },
  "Alcorn St.": {
    "adjDef": 106.7,
    "adjDefHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 106.6,
      "2020-01-22": 106.5,
      "2020-01-23": 106.7
    },
    "adjDefRank": 284,
    "adjDefRankHistory": {
      "2020-01-19": 299,
      "2020-01-20": 299,
      "2020-01-21": 281,
      "2020-01-22": 280,
      "2020-01-23": 284
    },
    "adjEM": -9.16,
    "adjEmHistory": {
      "2020-01-19": -11.05,
      "2020-01-20": -11.05,
      "2020-01-21": -9.11,
      "2020-01-22": -9.07,
      "2020-01-23": -9.16
    },
    "adjOff": 97.5,
    "adjOffHistory": {
      "2020-01-19": 96.6,
      "2020-01-20": 96.6,
      "2020-01-21": 97.5,
      "2020-01-22": 97.4,
      "2020-01-23": 97.5
    },
    "adjOffRank": 252,
    "adjOffRankHistory": {
      "2020-01-19": 268,
      "2020-01-20": 268,
      "2020-01-21": 250,
      "2020-01-22": 251,
      "2020-01-23": 252
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.2,
      "2020-01-20": 70.2,
      "2020-01-21": 70.7,
      "2020-01-22": 70.7,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 67,
    "adjTempoRankHistory": {
      "2020-01-19": 92,
      "2020-01-20": 92,
      "2020-01-21": 63,
      "2020-01-22": 66,
      "2020-01-23": 67
    },
    "conference": "SWAC",
    "rank": 278,
    "rankHistory": {
      "2020-01-19": 295,
      "2020-01-20": 295,
      "2020-01-21": 279,
      "2020-01-22": 278,
      "2020-01-23": 278
    },
    "record": "8-9"
  },
  "American": {
    "adjDef": 105.5,
    "adjDefHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.2,
      "2020-01-22": 105.1,
      "2020-01-23": 105.5
    },
    "adjDefRank": 251,
    "adjDefRankHistory": {
      "2020-01-19": 248,
      "2020-01-20": 248,
      "2020-01-21": 249,
      "2020-01-22": 249,
      "2020-01-23": 251
    },
    "adjEM": -4.92,
    "adjEmHistory": {
      "2020-01-19": -5.17,
      "2020-01-20": -5.17,
      "2020-01-21": -5.24,
      "2020-01-22": -5.26,
      "2020-01-23": -4.92
    },
    "adjOff": 100.5,
    "adjOffHistory": {
      "2020-01-19": 99.8,
      "2020-01-20": 99.8,
      "2020-01-21": 99.9,
      "2020-01-22": 99.8,
      "2020-01-23": 100.5
    },
    "adjOffRank": 194,
    "adjOffRankHistory": {
      "2020-01-19": 201,
      "2020-01-20": 200,
      "2020-01-21": 202,
      "2020-01-22": 203,
      "2020-01-23": 194
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.3,
      "2020-01-20": 68.3,
      "2020-01-21": 68.2,
      "2020-01-22": 68.2,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 178,
    "adjTempoRankHistory": {
      "2020-01-19": 189,
      "2020-01-20": 189,
      "2020-01-21": 189,
      "2020-01-22": 189,
      "2020-01-23": 178
    },
    "conference": "Pat",
    "rank": 226,
    "rankHistory": {
      "2020-01-19": 227,
      "2020-01-20": 227,
      "2020-01-21": 226,
      "2020-01-22": 225,
      "2020-01-23": 226
    },
    "record": "9-9"
  },
  "Appalachian St.": {
    "adjDef": 100.6,
    "adjDefHistory": {
      "2020-01-19": 100.6,
      "2020-01-20": 100.6,
      "2020-01-21": 100.6,
      "2020-01-22": 100.5,
      "2020-01-23": 100.6
    },
    "adjDefRank": 147,
    "adjDefRankHistory": {
      "2020-01-19": 149,
      "2020-01-20": 150,
      "2020-01-21": 149,
      "2020-01-22": 148,
      "2020-01-23": 147
    },
    "adjEM": -3.06,
    "adjEmHistory": {
      "2020-01-19": -3.1,
      "2020-01-20": -3.09,
      "2020-01-21": -3.02,
      "2020-01-22": -3.01,
      "2020-01-23": -3.06
    },
    "adjOff": 97.5,
    "adjOffHistory": {
      "2020-01-19": 97.5,
      "2020-01-20": 97.5,
      "2020-01-21": 97.6,
      "2020-01-22": 97.5,
      "2020-01-23": 97.5
    },
    "adjOffRank": 251,
    "adjOffRankHistory": {
      "2020-01-19": 248,
      "2020-01-20": 248,
      "2020-01-21": 248,
      "2020-01-22": 249,
      "2020-01-23": 251
    },
    "adjTempo": 67.8,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 67.9,
      "2020-01-21": 67.8,
      "2020-01-22": 67.8,
      "2020-01-23": 67.8
    },
    "adjTempoRank": 212,
    "adjTempoRankHistory": {
      "2020-01-19": 213,
      "2020-01-20": 214,
      "2020-01-21": 212,
      "2020-01-22": 212,
      "2020-01-23": 212
    },
    "conference": "SB",
    "rank": 197,
    "rankHistory": {
      "2020-01-19": 199,
      "2020-01-20": 199,
      "2020-01-21": 196,
      "2020-01-22": 198,
      "2020-01-23": 197
    },
    "record": "10-10"
  },
  "Arizona": {
    "adjDef": 92.8,
    "adjDefHistory": {
      "2020-01-19": 92.8,
      "2020-01-20": 92.8,
      "2020-01-21": 92.9,
      "2020-01-22": 92.7,
      "2020-01-23": 92.8
    },
    "adjDefRank": 42,
    "adjDefRankHistory": {
      "2020-01-19": 42,
      "2020-01-20": 42,
      "2020-01-21": 42,
      "2020-01-22": 41,
      "2020-01-23": 42
    },
    "adjEM": 21.7,
    "adjEmHistory": {
      "2020-01-19": 21.51,
      "2020-01-20": 21.51,
      "2020-01-21": 21.51,
      "2020-01-22": 21.61,
      "2020-01-23": 21.7
    },
    "adjOff": 114.5,
    "adjOffHistory": {
      "2020-01-19": 114.3,
      "2020-01-20": 114.3,
      "2020-01-21": 114.4,
      "2020-01-22": 114.3,
      "2020-01-23": 114.5
    },
    "adjOffRank": 7,
    "adjOffRankHistory": {
      "2020-01-19": 8,
      "2020-01-20": 8,
      "2020-01-21": 8,
      "2020-01-22": 7,
      "2020-01-23": 7
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 144,
    "adjTempoRankHistory": {
      "2020-01-19": 147,
      "2020-01-20": 147,
      "2020-01-21": 145,
      "2020-01-22": 145,
      "2020-01-23": 144
    },
    "conference": "P12",
    "rank": 13,
    "rankHistory": {
      "2020-01-19": 14,
      "2020-01-20": 14,
      "2020-01-21": 14,
      "2020-01-22": 13,
      "2020-01-23": 13
    },
    "record": "13-5"
  },
  "Arizona St.": {
    "adjDef": 93.4,
    "adjDefHistory": {
      "2020-01-19": 93.4,
      "2020-01-20": 93.4,
      "2020-01-21": 93.6,
      "2020-01-22": 93.4,
      "2020-01-23": 93.4
    },
    "adjDefRank": 48,
    "adjDefRankHistory": {
      "2020-01-19": 50,
      "2020-01-20": 50,
      "2020-01-21": 51,
      "2020-01-22": 49,
      "2020-01-23": 48
    },
    "adjEM": 9.35,
    "adjEmHistory": {
      "2020-01-19": 9.24,
      "2020-01-20": 9.24,
      "2020-01-21": 9.28,
      "2020-01-22": 9.29,
      "2020-01-23": 9.35
    },
    "adjOff": 102.8,
    "adjOffHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.9,
      "2020-01-22": 102.7,
      "2020-01-23": 102.8
    },
    "adjOffRank": 156,
    "adjOffRankHistory": {
      "2020-01-19": 159,
      "2020-01-20": 159,
      "2020-01-21": 154,
      "2020-01-22": 157,
      "2020-01-23": 156
    },
    "adjTempo": 72.4,
    "adjTempoHistory": {
      "2020-01-19": 72.6,
      "2020-01-20": 72.6,
      "2020-01-21": 72.5,
      "2020-01-22": 72.4,
      "2020-01-23": 72.4
    },
    "adjTempoRank": 23,
    "adjTempoRankHistory": {
      "2020-01-19": 24,
      "2020-01-20": 24,
      "2020-01-21": 23,
      "2020-01-22": 24,
      "2020-01-23": 23
    },
    "conference": "P12",
    "rank": 83,
    "rankHistory": {
      "2020-01-19": 84,
      "2020-01-20": 84,
      "2020-01-21": 86,
      "2020-01-22": 85,
      "2020-01-23": 83
    },
    "record": "11-7"
  },
  "Arkansas": {
    "adjDef": 88.5,
    "adjDefHistory": {
      "2020-01-19": 88,
      "2020-01-20": 88,
      "2020-01-21": 88.1,
      "2020-01-22": 87.8,
      "2020-01-23": 88.5
    },
    "adjDefRank": 11,
    "adjDefRankHistory": {
      "2020-01-19": 11,
      "2020-01-20": 11,
      "2020-01-21": 11,
      "2020-01-22": 9,
      "2020-01-23": 11
    },
    "adjEM": 16.88,
    "adjEmHistory": {
      "2020-01-19": 16.99,
      "2020-01-20": 16.99,
      "2020-01-21": 17.04,
      "2020-01-22": 17.05,
      "2020-01-23": 16.88
    },
    "adjOff": 105.4,
    "adjOffHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.1,
      "2020-01-22": 104.9,
      "2020-01-23": 105.4
    },
    "adjOffRank": 93,
    "adjOffRankHistory": {
      "2020-01-19": 99,
      "2020-01-20": 99,
      "2020-01-21": 97,
      "2020-01-22": 102,
      "2020-01-23": 93
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.6,
      "2020-01-22": 70.5,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 65,
    "adjTempoRankHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 71,
      "2020-01-22": 75,
      "2020-01-23": 65
    },
    "conference": "SEC",
    "rank": 34,
    "rankHistory": {
      "2020-01-19": 31,
      "2020-01-20": 31,
      "2020-01-21": 31,
      "2020-01-22": 33,
      "2020-01-23": 34
    },
    "record": "14-4"
  },
  "Arkansas Pine Bluff": {
    "adjDef": 104.8,
    "adjDefHistory": {
      "2020-01-19": 104.2,
      "2020-01-20": 104.2,
      "2020-01-21": 104.9,
      "2020-01-22": 104.7,
      "2020-01-23": 104.8
    },
    "adjDefRank": 237,
    "adjDefRankHistory": {
      "2020-01-19": 229,
      "2020-01-20": 230,
      "2020-01-21": 241,
      "2020-01-22": 241,
      "2020-01-23": 237
    },
    "adjEM": -23.79,
    "adjEmHistory": {
      "2020-01-19": -21.77,
      "2020-01-20": -21.77,
      "2020-01-21": -23.7,
      "2020-01-22": -23.77,
      "2020-01-23": -23.79
    },
    "adjOff": 81,
    "adjOffHistory": {
      "2020-01-19": 82.4,
      "2020-01-20": 82.4,
      "2020-01-21": 81.2,
      "2020-01-22": 81,
      "2020-01-23": 81
    },
    "adjOffRank": 353,
    "adjOffRankHistory": {
      "2020-01-19": 353,
      "2020-01-20": 353,
      "2020-01-21": 353,
      "2020-01-22": 353,
      "2020-01-23": 353
    },
    "adjTempo": 67.4,
    "adjTempoHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 67.5,
      "2020-01-22": 67.5,
      "2020-01-23": 67.4
    },
    "adjTempoRank": 224,
    "adjTempoRankHistory": {
      "2020-01-19": 253,
      "2020-01-20": 253,
      "2020-01-21": 224,
      "2020-01-22": 223,
      "2020-01-23": 224
    },
    "conference": "SWAC",
    "rank": 346,
    "rankHistory": {
      "2020-01-19": 344,
      "2020-01-20": 344,
      "2020-01-21": 346,
      "2020-01-22": 346,
      "2020-01-23": 346
    },
    "record": "3-14"
  },
  "Arkansas St.": {
    "adjDef": 106.6,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.6,
      "2020-01-22": 106.5,
      "2020-01-23": 106.6
    },
    "adjDefRank": 282,
    "adjDefRankHistory": {
      "2020-01-19": 282,
      "2020-01-20": 281,
      "2020-01-21": 282,
      "2020-01-22": 282,
      "2020-01-23": 282
    },
    "adjEM": -3.88,
    "adjEmHistory": {
      "2020-01-19": -4.12,
      "2020-01-20": -4.12,
      "2020-01-21": -4.08,
      "2020-01-22": -4.09,
      "2020-01-23": -3.88
    },
    "adjOff": 102.7,
    "adjOffHistory": {
      "2020-01-19": 102.4,
      "2020-01-20": 102.4,
      "2020-01-21": 102.6,
      "2020-01-22": 102.4,
      "2020-01-23": 102.7
    },
    "adjOffRank": 157,
    "adjOffRankHistory": {
      "2020-01-19": 162,
      "2020-01-20": 162,
      "2020-01-21": 162,
      "2020-01-22": 163,
      "2020-01-23": 157
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.6,
      "2020-01-22": 66.6,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 266,
    "adjTempoRankHistory": {
      "2020-01-19": 263,
      "2020-01-20": 263,
      "2020-01-21": 263,
      "2020-01-22": 263,
      "2020-01-23": 266
    },
    "conference": "SB",
    "rank": 208,
    "rankHistory": {
      "2020-01-19": 212,
      "2020-01-20": 212,
      "2020-01-21": 212,
      "2020-01-22": 212,
      "2020-01-23": 208
    },
    "record": "13-7"
  },
  "Army": {
    "adjDef": 106.4,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.5,
      "2020-01-22": 106.4,
      "2020-01-23": 106.4
    },
    "adjDefRank": 277,
    "adjDefRankHistory": {
      "2020-01-19": 283,
      "2020-01-20": 284,
      "2020-01-21": 279,
      "2020-01-22": 278,
      "2020-01-23": 277
    },
    "adjEM": -9.15,
    "adjEmHistory": {
      "2020-01-19": -11.68,
      "2020-01-20": -11.68,
      "2020-01-21": -11.6,
      "2020-01-22": -11.64,
      "2020-01-23": -9.15
    },
    "adjOff": 97.3,
    "adjOffHistory": {
      "2020-01-19": 94.8,
      "2020-01-20": 94.8,
      "2020-01-21": 94.9,
      "2020-01-22": 94.8,
      "2020-01-23": 97.3
    },
    "adjOffRank": 260,
    "adjOffRankHistory": {
      "2020-01-19": 296,
      "2020-01-20": 296,
      "2020-01-21": 298,
      "2020-01-22": 299,
      "2020-01-23": 260
    },
    "adjTempo": 69.4,
    "adjTempoHistory": {
      "2020-01-19": 69.5,
      "2020-01-20": 69.5,
      "2020-01-21": 69.5,
      "2020-01-22": 69.5,
      "2020-01-23": 69.4
    },
    "adjTempoRank": 121,
    "adjTempoRankHistory": {
      "2020-01-19": 126,
      "2020-01-20": 126,
      "2020-01-21": 122,
      "2020-01-22": 121,
      "2020-01-23": 121
    },
    "conference": "Pat",
    "rank": 277,
    "rankHistory": {
      "2020-01-19": 301,
      "2020-01-20": 301,
      "2020-01-21": 301,
      "2020-01-22": 301,
      "2020-01-23": 277
    },
    "record": "8-10"
  },
  "Auburn": {
    "adjDef": 91.8,
    "adjDefHistory": {
      "2020-01-19": 91.6,
      "2020-01-20": 91.6,
      "2020-01-21": 91.7,
      "2020-01-22": 91.5,
      "2020-01-23": 91.8
    },
    "adjDefRank": 30,
    "adjDefRankHistory": {
      "2020-01-19": 29,
      "2020-01-20": 29,
      "2020-01-21": 29,
      "2020-01-22": 28,
      "2020-01-23": 30
    },
    "adjEM": 17.9,
    "adjEmHistory": {
      "2020-01-19": 17.51,
      "2020-01-20": 17.51,
      "2020-01-21": 17.53,
      "2020-01-22": 17.55,
      "2020-01-23": 17.9
    },
    "adjOff": 109.7,
    "adjOffHistory": {
      "2020-01-19": 109.1,
      "2020-01-20": 109.1,
      "2020-01-21": 109.2,
      "2020-01-22": 109.1,
      "2020-01-23": 109.7
    },
    "adjOffRank": 38,
    "adjOffRankHistory": {
      "2020-01-19": 42,
      "2020-01-20": 42,
      "2020-01-21": 42,
      "2020-01-22": 42,
      "2020-01-23": 38
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 70.2,
      "2020-01-20": 70.2,
      "2020-01-21": 70.1,
      "2020-01-22": 70,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 108,
    "adjTempoRankHistory": {
      "2020-01-19": 95,
      "2020-01-20": 95,
      "2020-01-21": 95,
      "2020-01-22": 95,
      "2020-01-23": 108
    },
    "conference": "SEC",
    "rank": 26,
    "rankHistory": {
      "2020-01-19": 26,
      "2020-01-20": 26,
      "2020-01-21": 26,
      "2020-01-22": 28,
      "2020-01-23": 26
    },
    "record": "16-2"
  },
  "Austin Peay": {
    "adjDef": 107.6,
    "adjDefHistory": {
      "2020-01-19": 107.4,
      "2020-01-20": 107.4,
      "2020-01-21": 107.5,
      "2020-01-22": 107.4,
      "2020-01-23": 107.6
    },
    "adjDefRank": 298,
    "adjDefRankHistory": {
      "2020-01-19": 295,
      "2020-01-20": 295,
      "2020-01-21": 296,
      "2020-01-22": 296,
      "2020-01-23": 298
    },
    "adjEM": 0.38,
    "adjEmHistory": {
      "2020-01-19": 0.28,
      "2020-01-20": 0.28,
      "2020-01-21": 0.34,
      "2020-01-22": 0.36,
      "2020-01-23": 0.38
    },
    "adjOff": 108,
    "adjOffHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 107.9,
      "2020-01-22": 107.7,
      "2020-01-23": 108
    },
    "adjOffRank": 55,
    "adjOffRankHistory": {
      "2020-01-19": 55,
      "2020-01-20": 55,
      "2020-01-21": 56,
      "2020-01-22": 57,
      "2020-01-23": 55
    },
    "adjTempo": 67.7,
    "adjTempoHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 67.8,
      "2020-01-22": 67.8,
      "2020-01-23": 67.7
    },
    "adjTempoRank": 213,
    "adjTempoRankHistory": {
      "2020-01-19": 212,
      "2020-01-20": 212,
      "2020-01-21": 214,
      "2020-01-22": 213,
      "2020-01-23": 213
    },
    "conference": "OVC",
    "rank": 162,
    "rankHistory": {
      "2020-01-19": 164,
      "2020-01-20": 164,
      "2020-01-21": 162,
      "2020-01-22": 163,
      "2020-01-23": 162
    },
    "record": "12-7"
  },
  "BYU": {
    "adjDef": 95.4,
    "adjDefHistory": {
      "2020-01-19": 95.3,
      "2020-01-20": 95.4,
      "2020-01-21": 95.5,
      "2020-01-22": 95.4,
      "2020-01-23": 95.4
    },
    "adjDefRank": 71,
    "adjDefRankHistory": {
      "2020-01-19": 74,
      "2020-01-20": 75,
      "2020-01-21": 74,
      "2020-01-22": 75,
      "2020-01-23": 71
    },
    "adjEM": 17.5,
    "adjEmHistory": {
      "2020-01-19": 17.2,
      "2020-01-20": 17.22,
      "2020-01-21": 17.25,
      "2020-01-22": 17.34,
      "2020-01-23": 17.5
    },
    "adjOff": 112.8,
    "adjOffHistory": {
      "2020-01-19": 112.5,
      "2020-01-20": 112.6,
      "2020-01-21": 112.8,
      "2020-01-22": 112.7,
      "2020-01-23": 112.8
    },
    "adjOffRank": 14,
    "adjOffRankHistory": {
      "2020-01-19": 16,
      "2020-01-20": 15,
      "2020-01-21": 16,
      "2020-01-22": 15,
      "2020-01-23": 14
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.5,
      "2020-01-20": 69.5,
      "2020-01-21": 69.4,
      "2020-01-22": 69.4,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 123,
    "adjTempoRankHistory": {
      "2020-01-19": 127,
      "2020-01-20": 127,
      "2020-01-21": 126,
      "2020-01-22": 124,
      "2020-01-23": 123
    },
    "conference": "WCC",
    "rank": 28,
    "rankHistory": {
      "2020-01-19": 29,
      "2020-01-20": 29,
      "2020-01-21": 29,
      "2020-01-22": 29,
      "2020-01-23": 28
    },
    "record": "14-6"
  },
  "Ball St.": {
    "adjDef": 94.2,
    "adjDefHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.3,
      "2020-01-22": 94.1,
      "2020-01-23": 94.2
    },
    "adjDefRank": 62,
    "adjDefRankHistory": {
      "2020-01-19": 62,
      "2020-01-20": 62,
      "2020-01-21": 60,
      "2020-01-22": 62,
      "2020-01-23": 62
    },
    "adjEM": 6.61,
    "adjEmHistory": {
      "2020-01-19": 6.57,
      "2020-01-20": 6.57,
      "2020-01-21": 6.62,
      "2020-01-22": 6.68,
      "2020-01-23": 6.61
    },
    "adjOff": 100.8,
    "adjOffHistory": {
      "2020-01-19": 100.8,
      "2020-01-20": 100.8,
      "2020-01-21": 100.9,
      "2020-01-22": 100.8,
      "2020-01-23": 100.8
    },
    "adjOffRank": 189,
    "adjOffRankHistory": {
      "2020-01-19": 188,
      "2020-01-20": 188,
      "2020-01-21": 189,
      "2020-01-22": 189,
      "2020-01-23": 189
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.4,
      "2020-01-20": 67.4,
      "2020-01-21": 67.3,
      "2020-01-22": 67.3,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 232,
    "adjTempoRankHistory": {
      "2020-01-19": 233,
      "2020-01-20": 233,
      "2020-01-21": 234,
      "2020-01-22": 234,
      "2020-01-23": 232
    },
    "conference": "MAC",
    "rank": 104,
    "rankHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102,
      "2020-01-22": 101,
      "2020-01-23": 104
    },
    "record": "11-7"
  },
  "Baylor": {
    "adjDef": 86.4,
    "adjDefHistory": {
      "2020-01-19": 86.3,
      "2020-01-20": 86.3,
      "2020-01-21": 86.5,
      "2020-01-22": 86.3,
      "2020-01-23": 86.4
    },
    "adjDefRank": 4,
    "adjDefRankHistory": {
      "2020-01-19": 4,
      "2020-01-20": 4,
      "2020-01-21": 4,
      "2020-01-22": 4,
      "2020-01-23": 4
    },
    "adjEM": 24.7,
    "adjEmHistory": {
      "2020-01-19": 25.43,
      "2020-01-20": 25.43,
      "2020-01-21": 24.72,
      "2020-01-22": 24.65,
      "2020-01-23": 24.7
    },
    "adjOff": 111.1,
    "adjOffHistory": {
      "2020-01-19": 111.7,
      "2020-01-20": 111.7,
      "2020-01-21": 111.2,
      "2020-01-22": 111,
      "2020-01-23": 111.1
    },
    "adjOffRank": 28,
    "adjOffRankHistory": {
      "2020-01-19": 23,
      "2020-01-20": 23,
      "2020-01-21": 25,
      "2020-01-22": 28,
      "2020-01-23": 28
    },
    "adjTempo": 66.1,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.2,
      "2020-01-22": 66.1,
      "2020-01-23": 66.1
    },
    "adjTempoRank": 280,
    "adjTempoRankHistory": {
      "2020-01-19": 262,
      "2020-01-20": 262,
      "2020-01-21": 278,
      "2020-01-22": 280,
      "2020-01-23": 280
    },
    "conference": "B12",
    "rank": 5,
    "rankHistory": {
      "2020-01-19": 3,
      "2020-01-20": 3,
      "2020-01-21": 4,
      "2020-01-22": 4,
      "2020-01-23": 5
    },
    "record": "16-1"
  },
  "Belmont": {
    "adjDef": 98,
    "adjDefHistory": {
      "2020-01-19": 97.9,
      "2020-01-20": 97.9,
      "2020-01-21": 98,
      "2020-01-22": 97.9,
      "2020-01-23": 98
    },
    "adjDefRank": 106,
    "adjDefRankHistory": {
      "2020-01-19": 107,
      "2020-01-20": 107,
      "2020-01-21": 106,
      "2020-01-22": 106,
      "2020-01-23": 106
    },
    "adjEM": 7.61,
    "adjEmHistory": {
      "2020-01-19": 7.61,
      "2020-01-20": 7.61,
      "2020-01-21": 7.65,
      "2020-01-22": 7.61,
      "2020-01-23": 7.61
    },
    "adjOff": 105.6,
    "adjOffHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.5,
      "2020-01-23": 105.6
    },
    "adjOffRank": 87,
    "adjOffRankHistory": {
      "2020-01-19": 84,
      "2020-01-20": 84,
      "2020-01-21": 84,
      "2020-01-22": 82,
      "2020-01-23": 87
    },
    "adjTempo": 71,
    "adjTempoHistory": {
      "2020-01-19": 71.1,
      "2020-01-20": 71.1,
      "2020-01-21": 71,
      "2020-01-22": 71,
      "2020-01-23": 71
    },
    "adjTempoRank": 55,
    "adjTempoRankHistory": {
      "2020-01-19": 56,
      "2020-01-20": 56,
      "2020-01-21": 56,
      "2020-01-22": 56,
      "2020-01-23": 55
    },
    "conference": "OVC",
    "rank": 94,
    "rankHistory": {
      "2020-01-19": 92,
      "2020-01-20": 92,
      "2020-01-21": 91,
      "2020-01-22": 91,
      "2020-01-23": 94
    },
    "record": "14-5"
  },
  "Bethune Cookman": {
    "adjDef": 107.8,
    "adjDefHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 107.9,
      "2020-01-22": 107.8,
      "2020-01-23": 107.8
    },
    "adjDefRank": 299,
    "adjDefRankHistory": {
      "2020-01-19": 302,
      "2020-01-20": 302,
      "2020-01-21": 301,
      "2020-01-22": 302,
      "2020-01-23": 299
    },
    "adjEM": -10.5,
    "adjEmHistory": {
      "2020-01-19": -10.51,
      "2020-01-20": -10.51,
      "2020-01-21": -10.51,
      "2020-01-22": -10.54,
      "2020-01-23": -10.5
    },
    "adjOff": 97.3,
    "adjOffHistory": {
      "2020-01-19": 97.2,
      "2020-01-20": 97.2,
      "2020-01-21": 97.4,
      "2020-01-22": 97.2,
      "2020-01-23": 97.3
    },
    "adjOffRank": 258,
    "adjOffRankHistory": {
      "2020-01-19": 252,
      "2020-01-20": 252,
      "2020-01-21": 256,
      "2020-01-22": 257,
      "2020-01-23": 258
    },
    "adjTempo": 73.1,
    "adjTempoHistory": {
      "2020-01-19": 73.3,
      "2020-01-20": 73.3,
      "2020-01-21": 73.1,
      "2020-01-22": 73.1,
      "2020-01-23": 73.1
    },
    "adjTempoRank": 18,
    "adjTempoRankHistory": {
      "2020-01-19": 18,
      "2020-01-20": 18,
      "2020-01-21": 18,
      "2020-01-22": 18,
      "2020-01-23": 18
    },
    "conference": "MEAC",
    "rank": 290,
    "rankHistory": {
      "2020-01-19": 289,
      "2020-01-20": 289,
      "2020-01-21": 289,
      "2020-01-22": 289,
      "2020-01-23": 290
    },
    "record": "9-10"
  },
  "Binghamton": {
    "adjDef": 114.5,
    "adjDefHistory": {
      "2020-01-19": 114.7,
      "2020-01-20": 114.7,
      "2020-01-21": 114.9,
      "2020-01-22": 114.8,
      "2020-01-23": 114.5
    },
    "adjDefRank": 344,
    "adjDefRankHistory": {
      "2020-01-19": 345,
      "2020-01-20": 345,
      "2020-01-21": 345,
      "2020-01-22": 345,
      "2020-01-23": 344
    },
    "adjEM": -15.61,
    "adjEmHistory": {
      "2020-01-19": -17.47,
      "2020-01-20": -17.48,
      "2020-01-21": -17.53,
      "2020-01-22": -17.57,
      "2020-01-23": -15.61
    },
    "adjOff": 98.9,
    "adjOffHistory": {
      "2020-01-19": 97.2,
      "2020-01-20": 97.2,
      "2020-01-21": 97.4,
      "2020-01-22": 97.3,
      "2020-01-23": 98.9
    },
    "adjOffRank": 224,
    "adjOffRankHistory": {
      "2020-01-19": 255,
      "2020-01-20": 255,
      "2020-01-21": 255,
      "2020-01-22": 254,
      "2020-01-23": 224
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68.1,
      "2020-01-22": 68,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 195,
    "adjTempoRankHistory": {
      "2020-01-19": 198,
      "2020-01-20": 198,
      "2020-01-21": 199,
      "2020-01-22": 200,
      "2020-01-23": 195
    },
    "conference": "AE",
    "rank": 331,
    "rankHistory": {
      "2020-01-19": 335,
      "2020-01-20": 335,
      "2020-01-21": 335,
      "2020-01-22": 336,
      "2020-01-23": 331
    },
    "record": "8-11"
  },
  "Boise St.": {
    "adjDef": 100.5,
    "adjDefHistory": {
      "2020-01-19": 100.5,
      "2020-01-20": 100.5,
      "2020-01-21": 100.7,
      "2020-01-22": 100.6,
      "2020-01-23": 100.5
    },
    "adjDefRank": 146,
    "adjDefRankHistory": {
      "2020-01-19": 148,
      "2020-01-20": 149,
      "2020-01-21": 151,
      "2020-01-22": 150,
      "2020-01-23": 146
    },
    "adjEM": 5.24,
    "adjEmHistory": {
      "2020-01-19": 4.87,
      "2020-01-20": 4.87,
      "2020-01-21": 4.87,
      "2020-01-22": 4.92,
      "2020-01-23": 5.24
    },
    "adjOff": 105.8,
    "adjOffHistory": {
      "2020-01-19": 105.4,
      "2020-01-20": 105.4,
      "2020-01-21": 105.5,
      "2020-01-22": 105.5,
      "2020-01-23": 105.8
    },
    "adjOffRank": 84,
    "adjOffRankHistory": {
      "2020-01-19": 87,
      "2020-01-20": 87,
      "2020-01-21": 86,
      "2020-01-22": 83,
      "2020-01-23": 84
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.1,
      "2020-01-20": 70.1,
      "2020-01-21": 70,
      "2020-01-22": 70,
      "2020-01-23": 70
    },
    "adjTempoRank": 97,
    "adjTempoRankHistory": {
      "2020-01-19": 98,
      "2020-01-20": 98,
      "2020-01-21": 99,
      "2020-01-22": 99,
      "2020-01-23": 97
    },
    "conference": "MWC",
    "rank": 113,
    "rankHistory": {
      "2020-01-19": 119,
      "2020-01-20": 119,
      "2020-01-21": 119,
      "2020-01-22": 117,
      "2020-01-23": 113
    },
    "record": "12-8"
  },
  "Boston College": {
    "adjDef": 97.6,
    "adjDefHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 97,
      "2020-01-21": 97.1,
      "2020-01-22": 96.9,
      "2020-01-23": 97.6
    },
    "adjDefRank": 95,
    "adjDefRankHistory": {
      "2020-01-19": 89,
      "2020-01-20": 90,
      "2020-01-21": 90,
      "2020-01-22": 91,
      "2020-01-23": 95
    },
    "adjEM": -0.08,
    "adjEmHistory": {
      "2020-01-19": -0.66,
      "2020-01-20": -0.68,
      "2020-01-21": -0.69,
      "2020-01-22": -0.74,
      "2020-01-23": -0.08
    },
    "adjOff": 97.5,
    "adjOffHistory": {
      "2020-01-19": 96.2,
      "2020-01-20": 96.3,
      "2020-01-21": 96.4,
      "2020-01-22": 96.2,
      "2020-01-23": 97.5
    },
    "adjOffRank": 254,
    "adjOffRankHistory": {
      "2020-01-19": 276,
      "2020-01-20": 273,
      "2020-01-21": 275,
      "2020-01-22": 277,
      "2020-01-23": 254
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.7,
      "2020-01-22": 69.7,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 113,
    "adjTempoRankHistory": {
      "2020-01-19": 115,
      "2020-01-20": 113,
      "2020-01-21": 112,
      "2020-01-22": 111,
      "2020-01-23": 113
    },
    "conference": "ACC",
    "rank": 169,
    "rankHistory": {
      "2020-01-19": 179,
      "2020-01-20": 179,
      "2020-01-21": 178,
      "2020-01-22": 179,
      "2020-01-23": 169
    },
    "record": "9-10"
  },
  "Boston University": {
    "adjDef": 102.6,
    "adjDefHistory": {
      "2020-01-19": 103.2,
      "2020-01-20": 103.2,
      "2020-01-21": 103.2,
      "2020-01-22": 103.1,
      "2020-01-23": 102.6
    },
    "adjDefRank": 187,
    "adjDefRankHistory": {
      "2020-01-19": 209,
      "2020-01-20": 210,
      "2020-01-21": 209,
      "2020-01-22": 209,
      "2020-01-23": 187
    },
    "adjEM": -1.03,
    "adjEmHistory": {
      "2020-01-19": -0.53,
      "2020-01-20": -0.53,
      "2020-01-21": -0.37,
      "2020-01-22": -0.36,
      "2020-01-23": -1.03
    },
    "adjOff": 101.6,
    "adjOffHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 101.6
    },
    "adjOffRank": 177,
    "adjOffRankHistory": {
      "2020-01-19": 158,
      "2020-01-20": 158,
      "2020-01-21": 156,
      "2020-01-22": 155,
      "2020-01-23": 177
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.6,
      "2020-01-20": 67.6,
      "2020-01-21": 67.5,
      "2020-01-22": 67.5,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 233,
    "adjTempoRankHistory": {
      "2020-01-19": 222,
      "2020-01-20": 222,
      "2020-01-21": 223,
      "2020-01-22": 222,
      "2020-01-23": 233
    },
    "conference": "Pat",
    "rank": 183,
    "rankHistory": {
      "2020-01-19": 177,
      "2020-01-20": 177,
      "2020-01-21": 174,
      "2020-01-22": 174,
      "2020-01-23": 183
    },
    "record": "10-10"
  },
  "Bowling Green": {
    "adjDef": 104.1,
    "adjDefHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.5,
      "2020-01-22": 104,
      "2020-01-23": 104.1
    },
    "adjDefRank": 227,
    "adjDefRankHistory": {
      "2020-01-19": 235,
      "2020-01-20": 235,
      "2020-01-21": 236,
      "2020-01-22": 227,
      "2020-01-23": 227
    },
    "adjEM": -0.22,
    "adjEmHistory": {
      "2020-01-19": 0.32,
      "2020-01-20": 0.32,
      "2020-01-21": 0.32,
      "2020-01-22": -0.16,
      "2020-01-23": -0.22
    },
    "adjOff": 103.8,
    "adjOffHistory": {
      "2020-01-19": 104.7,
      "2020-01-20": 104.7,
      "2020-01-21": 104.9,
      "2020-01-22": 103.8,
      "2020-01-23": 103.8
    },
    "adjOffRank": 128,
    "adjOffRankHistory": {
      "2020-01-19": 107,
      "2020-01-20": 107,
      "2020-01-21": 103,
      "2020-01-22": 127,
      "2020-01-23": 128
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.6,
      "2020-01-22": 69.7,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 112,
    "adjTempoRankHistory": {
      "2020-01-19": 118,
      "2020-01-20": 118,
      "2020-01-21": 116,
      "2020-01-22": 114,
      "2020-01-23": 112
    },
    "conference": "MAC",
    "rank": 171,
    "rankHistory": {
      "2020-01-19": 163,
      "2020-01-20": 163,
      "2020-01-21": 163,
      "2020-01-22": 170,
      "2020-01-23": 171
    },
    "record": "14-5"
  },
  "Bradley": {
    "adjDef": 98,
    "adjDefHistory": {
      "2020-01-19": 97.4,
      "2020-01-20": 97.4,
      "2020-01-21": 97.6,
      "2020-01-22": 97.4,
      "2020-01-23": 98
    },
    "adjDefRank": 105,
    "adjDefRankHistory": {
      "2020-01-19": 95,
      "2020-01-20": 95,
      "2020-01-21": 95,
      "2020-01-22": 95,
      "2020-01-23": 105
    },
    "adjEM": 7.29,
    "adjEmHistory": {
      "2020-01-19": 7.46,
      "2020-01-20": 7.46,
      "2020-01-21": 7.49,
      "2020-01-22": 7.44,
      "2020-01-23": 7.29
    },
    "adjOff": 105.3,
    "adjOffHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 105,
      "2020-01-22": 104.9,
      "2020-01-23": 105.3
    },
    "adjOffRank": 95,
    "adjOffRankHistory": {
      "2020-01-19": 103,
      "2020-01-20": 102,
      "2020-01-21": 100,
      "2020-01-22": 100,
      "2020-01-23": 95
    },
    "adjTempo": 67.7,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68.1,
      "2020-01-22": 68,
      "2020-01-23": 67.7
    },
    "adjTempoRank": 214,
    "adjTempoRankHistory": {
      "2020-01-19": 194,
      "2020-01-20": 194,
      "2020-01-21": 195,
      "2020-01-22": 198,
      "2020-01-23": 214
    },
    "conference": "MVC",
    "rank": 97,
    "rankHistory": {
      "2020-01-19": 94,
      "2020-01-20": 94,
      "2020-01-21": 93,
      "2020-01-22": 94,
      "2020-01-23": 97
    },
    "record": "14-6"
  },
  "Brown": {
    "adjDef": 103.2,
    "adjDefHistory": {
      "2020-01-19": 103,
      "2020-01-20": 103,
      "2020-01-21": 103.1,
      "2020-01-22": 103,
      "2020-01-23": 103.2
    },
    "adjDefRank": 206,
    "adjDefRankHistory": {
      "2020-01-19": 205,
      "2020-01-20": 206,
      "2020-01-21": 206,
      "2020-01-22": 207,
      "2020-01-23": 206
    },
    "adjEM": -4.09,
    "adjEmHistory": {
      "2020-01-19": -4.09,
      "2020-01-20": -4.1,
      "2020-01-21": -4.01,
      "2020-01-22": -4.04,
      "2020-01-23": -4.09
    },
    "adjOff": 99.1,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 99.1,
      "2020-01-22": 99,
      "2020-01-23": 99.1
    },
    "adjOffRank": 219,
    "adjOffRankHistory": {
      "2020-01-19": 222,
      "2020-01-20": 221,
      "2020-01-21": 220,
      "2020-01-22": 219,
      "2020-01-23": 219
    },
    "adjTempo": 69.5,
    "adjTempoHistory": {
      "2020-01-19": 69.7,
      "2020-01-20": 69.7,
      "2020-01-21": 69.6,
      "2020-01-22": 69.6,
      "2020-01-23": 69.5
    },
    "adjTempoRank": 119,
    "adjTempoRankHistory": {
      "2020-01-19": 120,
      "2020-01-20": 120,
      "2020-01-21": 121,
      "2020-01-22": 120,
      "2020-01-23": 119
    },
    "conference": "Ivy",
    "rank": 211,
    "rankHistory": {
      "2020-01-19": 211,
      "2020-01-20": 211,
      "2020-01-21": 210,
      "2020-01-22": 211,
      "2020-01-23": 211
    },
    "record": "7-7"
  },
  "Bryant": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.6,
      "2020-01-23": 102.7
    },
    "adjDefRank": 190,
    "adjDefRankHistory": {
      "2020-01-19": 191,
      "2020-01-20": 191,
      "2020-01-21": 192,
      "2020-01-22": 190,
      "2020-01-23": 190
    },
    "adjEM": -4.24,
    "adjEmHistory": {
      "2020-01-19": -4.52,
      "2020-01-20": -4.51,
      "2020-01-21": -4.45,
      "2020-01-22": -4.4,
      "2020-01-23": -4.24
    },
    "adjOff": 98.5,
    "adjOffHistory": {
      "2020-01-19": 98.1,
      "2020-01-20": 98.1,
      "2020-01-21": 98.3,
      "2020-01-22": 98.2,
      "2020-01-23": 98.5
    },
    "adjOffRank": 232,
    "adjOffRankHistory": {
      "2020-01-19": 235,
      "2020-01-20": 235,
      "2020-01-21": 233,
      "2020-01-22": 232,
      "2020-01-23": 232
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.6,
      "2020-01-22": 70.6,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 72,
    "adjTempoRankHistory": {
      "2020-01-19": 73,
      "2020-01-20": 73,
      "2020-01-21": 72,
      "2020-01-22": 73,
      "2020-01-23": 72
    },
    "conference": "NEC",
    "rank": 214,
    "rankHistory": {
      "2020-01-19": 218,
      "2020-01-20": 218,
      "2020-01-21": 217,
      "2020-01-22": 215,
      "2020-01-23": 214
    },
    "record": "9-9"
  },
  "Bucknell": {
    "adjDef": 103.8,
    "adjDefHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.9,
      "2020-01-22": 103.9,
      "2020-01-23": 103.8
    },
    "adjDefRank": 223,
    "adjDefRankHistory": {
      "2020-01-19": 212,
      "2020-01-20": 213,
      "2020-01-21": 225,
      "2020-01-22": 225,
      "2020-01-23": 223
    },
    "adjEM": -5.33,
    "adjEmHistory": {
      "2020-01-19": -4.87,
      "2020-01-20": -4.87,
      "2020-01-21": -5.34,
      "2020-01-22": -5.38,
      "2020-01-23": -5.33
    },
    "adjOff": 98.5,
    "adjOffHistory": {
      "2020-01-19": 98.5,
      "2020-01-20": 98.5,
      "2020-01-21": 98.6,
      "2020-01-22": 98.5,
      "2020-01-23": 98.5
    },
    "adjOffRank": 230,
    "adjOffRankHistory": {
      "2020-01-19": 229,
      "2020-01-20": 228,
      "2020-01-21": 227,
      "2020-01-22": 226,
      "2020-01-23": 230
    },
    "adjTempo": 69.6,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.7,
      "2020-01-22": 69.7,
      "2020-01-23": 69.6
    },
    "adjTempoRank": 115,
    "adjTempoRankHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 111,
      "2020-01-22": 112,
      "2020-01-23": 115
    },
    "conference": "Pat",
    "rank": 227,
    "rankHistory": {
      "2020-01-19": 223,
      "2020-01-20": 223,
      "2020-01-21": 229,
      "2020-01-22": 230,
      "2020-01-23": 227
    },
    "record": "8-12"
  },
  "Buffalo": {
    "adjDef": 102.6,
    "adjDefHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.1,
      "2020-01-22": 102.5,
      "2020-01-23": 102.6
    },
    "adjDefRank": 186,
    "adjDefRankHistory": {
      "2020-01-19": 170,
      "2020-01-20": 170,
      "2020-01-21": 171,
      "2020-01-22": 184,
      "2020-01-23": 186
    },
    "adjEM": 2.01,
    "adjEmHistory": {
      "2020-01-19": 2.07,
      "2020-01-20": 2.06,
      "2020-01-21": 2.05,
      "2020-01-22": 2.07,
      "2020-01-23": 2.01
    },
    "adjOff": 104.6,
    "adjOffHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104.2,
      "2020-01-22": 104.6,
      "2020-01-23": 104.6
    },
    "adjOffRank": 109,
    "adjOffRankHistory": {
      "2020-01-19": 124,
      "2020-01-20": 124,
      "2020-01-21": 123,
      "2020-01-22": 108,
      "2020-01-23": 109
    },
    "adjTempo": 75.1,
    "adjTempoHistory": {
      "2020-01-19": 75.3,
      "2020-01-20": 75.3,
      "2020-01-21": 75.1,
      "2020-01-22": 75.1,
      "2020-01-23": 75.1
    },
    "adjTempoRank": 5,
    "adjTempoRankHistory": {
      "2020-01-19": 4,
      "2020-01-20": 4,
      "2020-01-21": 4,
      "2020-01-22": 4,
      "2020-01-23": 5
    },
    "conference": "MAC",
    "rank": 147,
    "rankHistory": {
      "2020-01-19": 144,
      "2020-01-20": 145,
      "2020-01-21": 145,
      "2020-01-22": 145,
      "2020-01-23": 147
    },
    "record": "13-6"
  },
  "Butler": {
    "adjDef": 89.6,
    "adjDefHistory": {
      "2020-01-19": 88.9,
      "2020-01-20": 88.9,
      "2020-01-21": 89,
      "2020-01-22": 89.6,
      "2020-01-23": 89.6
    },
    "adjDefRank": 19,
    "adjDefRankHistory": {
      "2020-01-19": 17,
      "2020-01-20": 17,
      "2020-01-21": 17,
      "2020-01-22": 20,
      "2020-01-23": 19
    },
    "adjEM": 20.73,
    "adjEmHistory": {
      "2020-01-19": 22.18,
      "2020-01-20": 22.18,
      "2020-01-21": 22.18,
      "2020-01-22": 20.72,
      "2020-01-23": 20.73
    },
    "adjOff": 110.3,
    "adjOffHistory": {
      "2020-01-19": 111.1,
      "2020-01-20": 111.1,
      "2020-01-21": 111.2,
      "2020-01-22": 110.3,
      "2020-01-23": 110.3
    },
    "adjOffRank": 34,
    "adjOffRankHistory": {
      "2020-01-19": 26,
      "2020-01-20": 26,
      "2020-01-21": 26,
      "2020-01-22": 34,
      "2020-01-23": 34
    },
    "adjTempo": 64.8,
    "adjTempoHistory": {
      "2020-01-19": 64.7,
      "2020-01-20": 64.7,
      "2020-01-21": 64.6,
      "2020-01-22": 64.8,
      "2020-01-23": 64.8
    },
    "adjTempoRank": 325,
    "adjTempoRankHistory": {
      "2020-01-19": 327,
      "2020-01-20": 327,
      "2020-01-21": 325,
      "2020-01-22": 323,
      "2020-01-23": 325
    },
    "conference": "BE",
    "rank": 15,
    "rankHistory": {
      "2020-01-19": 11,
      "2020-01-20": 11,
      "2020-01-21": 11,
      "2020-01-22": 15,
      "2020-01-23": 15
    },
    "record": "15-4"
  },
  "Cal Baptist": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 103.6,
      "2020-01-20": 103.7,
      "2020-01-21": 103.8,
      "2020-01-22": 103.7,
      "2020-01-23": 103.7
    },
    "adjDefRank": 218,
    "adjDefRankHistory": {
      "2020-01-19": 217,
      "2020-01-20": 219,
      "2020-01-21": 221,
      "2020-01-22": 221,
      "2020-01-23": 218
    },
    "adjEM": 0.28,
    "adjEmHistory": {
      "2020-01-19": 0.17,
      "2020-01-20": 0.13,
      "2020-01-21": 0.03,
      "2020-01-22": 0.02,
      "2020-01-23": 0.28
    },
    "adjOff": 104,
    "adjOffHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.8,
      "2020-01-21": 103.8,
      "2020-01-22": 103.7,
      "2020-01-23": 104
    },
    "adjOffRank": 124,
    "adjOffRankHistory": {
      "2020-01-19": 129,
      "2020-01-20": 128,
      "2020-01-21": 129,
      "2020-01-22": 130,
      "2020-01-23": 124
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.5,
      "2020-01-22": 70.4,
      "2020-01-23": 70
    },
    "adjTempoRank": 93,
    "adjTempoRankHistory": {
      "2020-01-19": 80,
      "2020-01-20": 79,
      "2020-01-21": 79,
      "2020-01-22": 80,
      "2020-01-23": 93
    },
    "conference": "WAC",
    "rank": 166,
    "rankHistory": {
      "2020-01-19": 167,
      "2020-01-20": 167,
      "2020-01-21": 167,
      "2020-01-22": 168,
      "2020-01-23": 166
    },
    "record": "14-5"
  },
  "Cal Poly": {
    "adjDef": 103.4,
    "adjDefHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.7,
      "2020-01-21": 103.8,
      "2020-01-22": 103.6,
      "2020-01-23": 103.4
    },
    "adjDefRank": 212,
    "adjDefRankHistory": {
      "2020-01-19": 219,
      "2020-01-20": 220,
      "2020-01-21": 220,
      "2020-01-22": 218,
      "2020-01-23": 212
    },
    "adjEM": -13.71,
    "adjEmHistory": {
      "2020-01-19": -13.71,
      "2020-01-20": -13.71,
      "2020-01-21": -13.73,
      "2020-01-22": -13.73,
      "2020-01-23": -13.71
    },
    "adjOff": 89.7,
    "adjOffHistory": {
      "2020-01-19": 90,
      "2020-01-20": 90,
      "2020-01-21": 90.1,
      "2020-01-22": 89.9,
      "2020-01-23": 89.7
    },
    "adjOffRank": 340,
    "adjOffRankHistory": {
      "2020-01-19": 338,
      "2020-01-20": 338,
      "2020-01-21": 339,
      "2020-01-22": 340,
      "2020-01-23": 340
    },
    "adjTempo": 68,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 68.1,
      "2020-01-22": 68,
      "2020-01-23": 68
    },
    "adjTempoRank": 202,
    "adjTempoRankHistory": {
      "2020-01-19": 200,
      "2020-01-20": 200,
      "2020-01-21": 196,
      "2020-01-22": 199,
      "2020-01-23": 202
    },
    "conference": "BW",
    "rank": 317,
    "rankHistory": {
      "2020-01-19": 318,
      "2020-01-20": 318,
      "2020-01-21": 317,
      "2020-01-22": 316,
      "2020-01-23": 317
    },
    "record": "4-13"
  },
  "Cal St. Bakersfield": {
    "adjDef": 105.1,
    "adjDefHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 104.9,
      "2020-01-22": 104.9,
      "2020-01-23": 105.1
    },
    "adjDefRank": 244,
    "adjDefRankHistory": {
      "2020-01-19": 243,
      "2020-01-20": 243,
      "2020-01-21": 244,
      "2020-01-22": 246,
      "2020-01-23": 244
    },
    "adjEM": -4.89,
    "adjEmHistory": {
      "2020-01-19": -4.63,
      "2020-01-20": -4.63,
      "2020-01-21": -4.64,
      "2020-01-22": -4.74,
      "2020-01-23": -4.89
    },
    "adjOff": 100.2,
    "adjOffHistory": {
      "2020-01-19": 100.1,
      "2020-01-20": 100.1,
      "2020-01-21": 100.3,
      "2020-01-22": 100.2,
      "2020-01-23": 100.2
    },
    "adjOffRank": 198,
    "adjOffRankHistory": {
      "2020-01-19": 198,
      "2020-01-20": 198,
      "2020-01-21": 197,
      "2020-01-22": 197,
      "2020-01-23": 198
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65.1,
      "2020-01-20": 65.1,
      "2020-01-21": 64.9,
      "2020-01-22": 64.9,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 316,
    "adjTempoRankHistory": {
      "2020-01-19": 320,
      "2020-01-20": 320,
      "2020-01-21": 319,
      "2020-01-22": 319,
      "2020-01-23": 316
    },
    "conference": "WAC",
    "rank": 224,
    "rankHistory": {
      "2020-01-19": 219,
      "2020-01-20": 219,
      "2020-01-21": 220,
      "2020-01-22": 220,
      "2020-01-23": 224
    },
    "record": "9-10"
  },
  "Cal St. Fullerton": {
    "adjDef": 101.4,
    "adjDefHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.4,
      "2020-01-22": 101.2,
      "2020-01-23": 101.4
    },
    "adjDefRank": 161,
    "adjDefRankHistory": {
      "2020-01-19": 159,
      "2020-01-20": 162,
      "2020-01-21": 163,
      "2020-01-22": 162,
      "2020-01-23": 161
    },
    "adjEM": -8.91,
    "adjEmHistory": {
      "2020-01-19": -9.74,
      "2020-01-20": -9.71,
      "2020-01-21": -9.71,
      "2020-01-22": -9.7,
      "2020-01-23": -8.91
    },
    "adjOff": 92.5,
    "adjOffHistory": {
      "2020-01-19": 91.4,
      "2020-01-20": 91.5,
      "2020-01-21": 91.7,
      "2020-01-22": 91.5,
      "2020-01-23": 92.5
    },
    "adjOffRank": 319,
    "adjOffRankHistory": {
      "2020-01-19": 330,
      "2020-01-20": 328,
      "2020-01-21": 330,
      "2020-01-22": 332,
      "2020-01-23": 319
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68.1,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 188,
    "adjTempoRankHistory": {
      "2020-01-19": 208,
      "2020-01-20": 206,
      "2020-01-21": 204,
      "2020-01-22": 204,
      "2020-01-23": 188
    },
    "conference": "BW",
    "rank": 273,
    "rankHistory": {
      "2020-01-19": 284,
      "2020-01-20": 284,
      "2020-01-21": 285,
      "2020-01-22": 284,
      "2020-01-23": 273
    },
    "record": "7-13"
  },
  "Cal St. Northridge": {
    "adjDef": 110.2,
    "adjDefHistory": {
      "2020-01-19": 109.6,
      "2020-01-20": 109.6,
      "2020-01-21": 109.8,
      "2020-01-22": 109.7,
      "2020-01-23": 110.2
    },
    "adjDefRank": 329,
    "adjDefRankHistory": {
      "2020-01-19": 323,
      "2020-01-20": 323,
      "2020-01-21": 323,
      "2020-01-22": 323,
      "2020-01-23": 329
    },
    "adjEM": -6.98,
    "adjEmHistory": {
      "2020-01-19": -8.47,
      "2020-01-20": -8.47,
      "2020-01-21": -8.5,
      "2020-01-22": -8.49,
      "2020-01-23": -6.98
    },
    "adjOff": 103.2,
    "adjOffHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.3,
      "2020-01-22": 101.2,
      "2020-01-23": 103.2
    },
    "adjOffRank": 145,
    "adjOffRankHistory": {
      "2020-01-19": 184,
      "2020-01-20": 183,
      "2020-01-21": 184,
      "2020-01-22": 182,
      "2020-01-23": 145
    },
    "adjTempo": 70.1,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.5,
      "2020-01-22": 70.5,
      "2020-01-23": 70.1
    },
    "adjTempoRank": 88,
    "adjTempoRankHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 76,
      "2020-01-22": 77,
      "2020-01-23": 88
    },
    "conference": "BW",
    "rank": 253,
    "rankHistory": {
      "2020-01-19": 268,
      "2020-01-20": 268,
      "2020-01-21": 269,
      "2020-01-22": 266,
      "2020-01-23": 253
    },
    "record": "8-13"
  },
  "California": {
    "adjDef": 100.3,
    "adjDefHistory": {
      "2020-01-19": 101.4,
      "2020-01-20": 100.3,
      "2020-01-21": 100.4,
      "2020-01-22": 100.3,
      "2020-01-23": 100.3
    },
    "adjDefRank": 142,
    "adjDefRankHistory": {
      "2020-01-19": 163,
      "2020-01-20": 145,
      "2020-01-21": 144,
      "2020-01-22": 143,
      "2020-01-23": 142
    },
    "adjEM": -1.98,
    "adjEmHistory": {
      "2020-01-19": -1.56,
      "2020-01-20": -2.01,
      "2020-01-21": -2.02,
      "2020-01-22": -1.98,
      "2020-01-23": -1.98
    },
    "adjOff": 98.3,
    "adjOffHistory": {
      "2020-01-19": 99.9,
      "2020-01-20": 98.3,
      "2020-01-21": 98.4,
      "2020-01-22": 98.3,
      "2020-01-23": 98.3
    },
    "adjOffRank": 235,
    "adjOffRankHistory": {
      "2020-01-19": 200,
      "2020-01-20": 231,
      "2020-01-21": 231,
      "2020-01-22": 230,
      "2020-01-23": 235
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65.5,
      "2020-01-20": 65.1,
      "2020-01-21": 65,
      "2020-01-22": 64.9,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 320,
    "adjTempoRankHistory": {
      "2020-01-19": 308,
      "2020-01-20": 319,
      "2020-01-21": 318,
      "2020-01-22": 318,
      "2020-01-23": 320
    },
    "conference": "P12",
    "rank": 186,
    "rankHistory": {
      "2020-01-19": 186,
      "2020-01-20": 188,
      "2020-01-21": 188,
      "2020-01-22": 188,
      "2020-01-23": 186
    },
    "record": "8-10"
  },
  "Campbell": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 102.5,
      "2020-01-20": 102.5,
      "2020-01-21": 103.7,
      "2020-01-22": 103.6,
      "2020-01-23": 103.7
    },
    "adjDefRank": 216,
    "adjDefRankHistory": {
      "2020-01-19": 185,
      "2020-01-20": 185,
      "2020-01-21": 216,
      "2020-01-22": 216,
      "2020-01-23": 216
    },
    "adjEM": -7.36,
    "adjEmHistory": {
      "2020-01-19": -6.64,
      "2020-01-20": -6.64,
      "2020-01-21": -7.28,
      "2020-01-22": -7.29,
      "2020-01-23": -7.36
    },
    "adjOff": 96.3,
    "adjOffHistory": {
      "2020-01-19": 95.9,
      "2020-01-20": 95.9,
      "2020-01-21": 96.4,
      "2020-01-22": 96.3,
      "2020-01-23": 96.3
    },
    "adjOffRank": 277,
    "adjOffRankHistory": {
      "2020-01-19": 283,
      "2020-01-20": 283,
      "2020-01-21": 274,
      "2020-01-22": 276,
      "2020-01-23": 277
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.1,
      "2020-01-20": 66.1,
      "2020-01-21": 66.6,
      "2020-01-22": 66.6,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 264,
    "adjTempoRankHistory": {
      "2020-01-19": 285,
      "2020-01-20": 285,
      "2020-01-21": 265,
      "2020-01-22": 266,
      "2020-01-23": 264
    },
    "conference": "BSth",
    "rank": 255,
    "rankHistory": {
      "2020-01-19": 247,
      "2020-01-20": 247,
      "2020-01-21": 255,
      "2020-01-22": 255,
      "2020-01-23": 255
    },
    "record": "11-8"
  },
  "Canisius": {
    "adjDef": 102,
    "adjDefHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.2,
      "2020-01-22": 102,
      "2020-01-23": 102
    },
    "adjDefRank": 174,
    "adjDefRankHistory": {
      "2020-01-19": 173,
      "2020-01-20": 173,
      "2020-01-21": 173,
      "2020-01-22": 173,
      "2020-01-23": 174
    },
    "adjEM": -4.16,
    "adjEmHistory": {
      "2020-01-19": -4.23,
      "2020-01-20": -4.22,
      "2020-01-21": -4.25,
      "2020-01-22": -4.24,
      "2020-01-23": -4.16
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.9,
      "2020-01-22": 97.8,
      "2020-01-23": 97.8
    },
    "adjOffRank": 242,
    "adjOffRankHistory": {
      "2020-01-19": 238,
      "2020-01-20": 238,
      "2020-01-21": 239,
      "2020-01-22": 239,
      "2020-01-23": 242
    },
    "adjTempo": 71.3,
    "adjTempoHistory": {
      "2020-01-19": 71.5,
      "2020-01-20": 71.5,
      "2020-01-21": 71.4,
      "2020-01-22": 71.3,
      "2020-01-23": 71.3
    },
    "adjTempoRank": 48,
    "adjTempoRankHistory": {
      "2020-01-19": 47,
      "2020-01-20": 47,
      "2020-01-21": 46,
      "2020-01-22": 46,
      "2020-01-23": 48
    },
    "conference": "MAAC",
    "rank": 212,
    "rankHistory": {
      "2020-01-19": 215,
      "2020-01-20": 215,
      "2020-01-21": 215,
      "2020-01-22": 213,
      "2020-01-23": 212
    },
    "record": "8-10"
  },
  "Central Arkansas": {
    "adjDef": 109.8,
    "adjDefHistory": {
      "2020-01-19": 109.8,
      "2020-01-20": 109.8,
      "2020-01-21": 109.9,
      "2020-01-22": 109.8,
      "2020-01-23": 109.8
    },
    "adjDefRank": 326,
    "adjDefRankHistory": {
      "2020-01-19": 325,
      "2020-01-20": 325,
      "2020-01-21": 324,
      "2020-01-22": 324,
      "2020-01-23": 326
    },
    "adjEM": -10.93,
    "adjEmHistory": {
      "2020-01-19": -11.1,
      "2020-01-20": -11.1,
      "2020-01-21": -11.09,
      "2020-01-22": -11.03,
      "2020-01-23": -10.93
    },
    "adjOff": 98.9,
    "adjOffHistory": {
      "2020-01-19": 98.7,
      "2020-01-20": 98.6,
      "2020-01-21": 98.8,
      "2020-01-22": 98.8,
      "2020-01-23": 98.9
    },
    "adjOffRank": 225,
    "adjOffRankHistory": {
      "2020-01-19": 226,
      "2020-01-20": 225,
      "2020-01-21": 223,
      "2020-01-22": 221,
      "2020-01-23": 225
    },
    "adjTempo": 73.8,
    "adjTempoHistory": {
      "2020-01-19": 74,
      "2020-01-20": 74,
      "2020-01-21": 73.9,
      "2020-01-22": 73.9,
      "2020-01-23": 73.8
    },
    "adjTempoRank": 14,
    "adjTempoRankHistory": {
      "2020-01-19": 14,
      "2020-01-20": 14,
      "2020-01-21": 14,
      "2020-01-22": 14,
      "2020-01-23": 14
    },
    "conference": "Slnd",
    "rank": 295,
    "rankHistory": {
      "2020-01-19": 296,
      "2020-01-20": 296,
      "2020-01-21": 295,
      "2020-01-22": 296,
      "2020-01-23": 295
    },
    "record": "5-14"
  },
  "Central Connecticut": {
    "adjDef": 114.9,
    "adjDefHistory": {
      "2020-01-19": 114.2,
      "2020-01-20": 114.2,
      "2020-01-21": 114.7,
      "2020-01-22": 114.7,
      "2020-01-23": 114.9
    },
    "adjDefRank": 345,
    "adjDefRankHistory": {
      "2020-01-19": 343,
      "2020-01-20": 343,
      "2020-01-21": 344,
      "2020-01-22": 344,
      "2020-01-23": 345
    },
    "adjEM": -28.27,
    "adjEmHistory": {
      "2020-01-19": -27.3,
      "2020-01-20": -27.3,
      "2020-01-21": -28.03,
      "2020-01-22": -28.09,
      "2020-01-23": -28.27
    },
    "adjOff": 86.6,
    "adjOffHistory": {
      "2020-01-19": 86.9,
      "2020-01-20": 86.9,
      "2020-01-21": 86.7,
      "2020-01-22": 86.6,
      "2020-01-23": 86.6
    },
    "adjOffRank": 350,
    "adjOffRankHistory": {
      "2020-01-19": 350,
      "2020-01-20": 350,
      "2020-01-21": 350,
      "2020-01-22": 350,
      "2020-01-23": 350
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.2,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 135,
    "adjTempoRankHistory": {
      "2020-01-19": 132,
      "2020-01-20": 132,
      "2020-01-21": 133,
      "2020-01-22": 133,
      "2020-01-23": 135
    },
    "conference": "NEC",
    "rank": 352,
    "rankHistory": {
      "2020-01-19": 350,
      "2020-01-20": 350,
      "2020-01-21": 352,
      "2020-01-22": 352,
      "2020-01-23": 352
    },
    "record": "1-19"
  },
  "Central Michigan": {
    "adjDef": 104.8,
    "adjDefHistory": {
      "2020-01-19": 104.7,
      "2020-01-20": 104.7,
      "2020-01-21": 104.8,
      "2020-01-22": 104.7,
      "2020-01-23": 104.8
    },
    "adjDefRank": 239,
    "adjDefRankHistory": {
      "2020-01-19": 239,
      "2020-01-20": 239,
      "2020-01-21": 239,
      "2020-01-22": 239,
      "2020-01-23": 239
    },
    "adjEM": -2.32,
    "adjEmHistory": {
      "2020-01-19": -2.03,
      "2020-01-20": -2.03,
      "2020-01-21": -2.01,
      "2020-01-22": -2.18,
      "2020-01-23": -2.32
    },
    "adjOff": 102.5,
    "adjOffHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.5,
      "2020-01-23": 102.5
    },
    "adjOffRank": 162,
    "adjOffRankHistory": {
      "2020-01-19": 155,
      "2020-01-20": 155,
      "2020-01-21": 158,
      "2020-01-22": 161,
      "2020-01-23": 162
    },
    "adjTempo": 74.2,
    "adjTempoHistory": {
      "2020-01-19": 74.3,
      "2020-01-20": 74.3,
      "2020-01-21": 74.2,
      "2020-01-22": 74.2,
      "2020-01-23": 74.2
    },
    "adjTempoRank": 11,
    "adjTempoRankHistory": {
      "2020-01-19": 11,
      "2020-01-20": 11,
      "2020-01-21": 11,
      "2020-01-22": 11,
      "2020-01-23": 11
    },
    "conference": "MAC",
    "rank": 190,
    "rankHistory": {
      "2020-01-19": 189,
      "2020-01-20": 189,
      "2020-01-21": 187,
      "2020-01-22": 189,
      "2020-01-23": 190
    },
    "record": "10-8"
  },
  "Charleston": {
    "adjDef": 103.3,
    "adjDefHistory": {
      "2020-01-19": 103.2,
      "2020-01-20": 103.2,
      "2020-01-21": 103.4,
      "2020-01-22": 103.2,
      "2020-01-23": 103.3
    },
    "adjDefRank": 211,
    "adjDefRankHistory": {
      "2020-01-19": 208,
      "2020-01-20": 209,
      "2020-01-21": 211,
      "2020-01-22": 211,
      "2020-01-23": 211
    },
    "adjEM": 2.59,
    "adjEmHistory": {
      "2020-01-19": 2.61,
      "2020-01-20": 2.61,
      "2020-01-21": 2.54,
      "2020-01-22": 2.55,
      "2020-01-23": 2.59
    },
    "adjOff": 105.9,
    "adjOffHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 105.9,
      "2020-01-22": 105.8,
      "2020-01-23": 105.9
    },
    "adjOffRank": 80,
    "adjOffRankHistory": {
      "2020-01-19": 80,
      "2020-01-20": 79,
      "2020-01-21": 79,
      "2020-01-22": 79,
      "2020-01-23": 80
    },
    "adjTempo": 65.4,
    "adjTempoHistory": {
      "2020-01-19": 65.6,
      "2020-01-20": 65.6,
      "2020-01-21": 65.5,
      "2020-01-22": 65.4,
      "2020-01-23": 65.4
    },
    "adjTempoRank": 300,
    "adjTempoRankHistory": {
      "2020-01-19": 303,
      "2020-01-20": 302,
      "2020-01-21": 301,
      "2020-01-22": 301,
      "2020-01-23": 300
    },
    "conference": "CAA",
    "rank": 136,
    "rankHistory": {
      "2020-01-19": 136,
      "2020-01-20": 136,
      "2020-01-21": 137,
      "2020-01-22": 136,
      "2020-01-23": 136
    },
    "record": "12-8"
  },
  "Charleston Southern": {
    "adjDef": 106.6,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.6,
      "2020-01-22": 106.6,
      "2020-01-23": 106.6
    },
    "adjDefRank": 283,
    "adjDefRankHistory": {
      "2020-01-19": 280,
      "2020-01-20": 279,
      "2020-01-21": 283,
      "2020-01-22": 283,
      "2020-01-23": 283
    },
    "adjEM": -11.24,
    "adjEmHistory": {
      "2020-01-19": -11.03,
      "2020-01-20": -11.03,
      "2020-01-21": -11.1,
      "2020-01-22": -11.18,
      "2020-01-23": -11.24
    },
    "adjOff": 95.4,
    "adjOffHistory": {
      "2020-01-19": 95.4,
      "2020-01-20": 95.4,
      "2020-01-21": 95.5,
      "2020-01-22": 95.4,
      "2020-01-23": 95.4
    },
    "adjOffRank": 294,
    "adjOffRankHistory": {
      "2020-01-19": 292,
      "2020-01-20": 292,
      "2020-01-21": 293,
      "2020-01-22": 293,
      "2020-01-23": 294
    },
    "adjTempo": 66.9,
    "adjTempoHistory": {
      "2020-01-19": 67.2,
      "2020-01-20": 67.2,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 66.9
    },
    "adjTempoRank": 253,
    "adjTempoRankHistory": {
      "2020-01-19": 246,
      "2020-01-20": 246,
      "2020-01-21": 250,
      "2020-01-22": 251,
      "2020-01-23": 253
    },
    "conference": "BSth",
    "rank": 298,
    "rankHistory": {
      "2020-01-19": 294,
      "2020-01-20": 294,
      "2020-01-21": 296,
      "2020-01-22": 298,
      "2020-01-23": 298
    },
    "record": "9-9"
  },
  "Charlotte": {
    "adjDef": 96,
    "adjDefHistory": {
      "2020-01-19": 95.2,
      "2020-01-20": 95.2,
      "2020-01-21": 96.1,
      "2020-01-22": 96,
      "2020-01-23": 96
    },
    "adjDefRank": 81,
    "adjDefRankHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 83,
      "2020-01-22": 83,
      "2020-01-23": 81
    },
    "adjEM": 0.49,
    "adjEmHistory": {
      "2020-01-19": 0.45,
      "2020-01-20": 0.46,
      "2020-01-21": 0.32,
      "2020-01-22": 0.38,
      "2020-01-23": 0.49
    },
    "adjOff": 96.5,
    "adjOffHistory": {
      "2020-01-19": 95.7,
      "2020-01-20": 95.7,
      "2020-01-21": 96.5,
      "2020-01-22": 96.4,
      "2020-01-23": 96.5
    },
    "adjOffRank": 271,
    "adjOffRankHistory": {
      "2020-01-19": 286,
      "2020-01-20": 286,
      "2020-01-21": 272,
      "2020-01-22": 273,
      "2020-01-23": 271
    },
    "adjTempo": 66,
    "adjTempoHistory": {
      "2020-01-19": 66.1,
      "2020-01-20": 66.1,
      "2020-01-21": 66,
      "2020-01-22": 66,
      "2020-01-23": 66
    },
    "adjTempoRank": 282,
    "adjTempoRankHistory": {
      "2020-01-19": 286,
      "2020-01-20": 286,
      "2020-01-21": 282,
      "2020-01-22": 283,
      "2020-01-23": 282
    },
    "conference": "CUSA",
    "rank": 161,
    "rankHistory": {
      "2020-01-19": 162,
      "2020-01-20": 162,
      "2020-01-21": 164,
      "2020-01-22": 162,
      "2020-01-23": 161
    },
    "record": "10-7"
  },
  "Chattanooga": {
    "adjDef": 101.9,
    "adjDefHistory": {
      "2020-01-19": 102.1,
      "2020-01-20": 102.1,
      "2020-01-21": 102.2,
      "2020-01-22": 102.1,
      "2020-01-23": 101.9
    },
    "adjDefRank": 172,
    "adjDefRankHistory": {
      "2020-01-19": 174,
      "2020-01-20": 174,
      "2020-01-21": 177,
      "2020-01-22": 175,
      "2020-01-23": 172
    },
    "adjEM": 0.63,
    "adjEmHistory": {
      "2020-01-19": -0.07,
      "2020-01-20": -0.07,
      "2020-01-21": -0.03,
      "2020-01-22": 0.04,
      "2020-01-23": 0.63
    },
    "adjOff": 102.5,
    "adjOffHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.2,
      "2020-01-22": 102.2,
      "2020-01-23": 102.5
    },
    "adjOffRank": 161,
    "adjOffRankHistory": {
      "2020-01-19": 168,
      "2020-01-20": 169,
      "2020-01-21": 168,
      "2020-01-22": 168,
      "2020-01-23": 161
    },
    "adjTempo": 66.3,
    "adjTempoHistory": {
      "2020-01-19": 66.3,
      "2020-01-20": 66.3,
      "2020-01-21": 66.2,
      "2020-01-22": 66.1,
      "2020-01-23": 66.3
    },
    "adjTempoRank": 274,
    "adjTempoRankHistory": {
      "2020-01-19": 279,
      "2020-01-20": 279,
      "2020-01-21": 280,
      "2020-01-22": 279,
      "2020-01-23": 274
    },
    "conference": "SC",
    "rank": 159,
    "rankHistory": {
      "2020-01-19": 169,
      "2020-01-20": 169,
      "2020-01-21": 170,
      "2020-01-22": 167,
      "2020-01-23": 159
    },
    "record": "13-7"
  },
  "Chicago St.": {
    "adjDef": 119.2,
    "adjDefHistory": {
      "2020-01-19": 118.7,
      "2020-01-20": 118.7,
      "2020-01-21": 118.9,
      "2020-01-22": 118.8,
      "2020-01-23": 119.2
    },
    "adjDefRank": 351,
    "adjDefRankHistory": {
      "2020-01-19": 351,
      "2020-01-20": 351,
      "2020-01-21": 351,
      "2020-01-22": 351,
      "2020-01-23": 351
    },
    "adjEM": -31.39,
    "adjEmHistory": {
      "2020-01-19": -30.75,
      "2020-01-20": -30.75,
      "2020-01-21": -30.79,
      "2020-01-22": -30.82,
      "2020-01-23": -31.39
    },
    "adjOff": 87.8,
    "adjOffHistory": {
      "2020-01-19": 88,
      "2020-01-20": 88,
      "2020-01-21": 88.1,
      "2020-01-22": 88,
      "2020-01-23": 87.8
    },
    "adjOffRank": 349,
    "adjOffRankHistory": {
      "2020-01-19": 346,
      "2020-01-20": 346,
      "2020-01-21": 346,
      "2020-01-22": 346,
      "2020-01-23": 349
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 71.3,
      "2020-01-20": 71.3,
      "2020-01-21": 71.2,
      "2020-01-22": 71.1,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 62,
    "adjTempoRankHistory": {
      "2020-01-19": 54,
      "2020-01-20": 54,
      "2020-01-21": 54,
      "2020-01-22": 54,
      "2020-01-23": 62
    },
    "conference": "WAC",
    "rank": 353,
    "rankHistory": {
      "2020-01-19": 353,
      "2020-01-20": 353,
      "2020-01-21": 353,
      "2020-01-22": 353,
      "2020-01-23": 353
    },
    "record": "4-18"
  },
  "Cincinnati": {
    "adjDef": 93.1,
    "adjDefHistory": {
      "2020-01-19": 91.5,
      "2020-01-20": 91.5,
      "2020-01-21": 91.6,
      "2020-01-22": 91.5,
      "2020-01-23": 93.1
    },
    "adjDefRank": 46,
    "adjDefRankHistory": {
      "2020-01-19": 28,
      "2020-01-20": 28,
      "2020-01-21": 27,
      "2020-01-22": 29,
      "2020-01-23": 46
    },
    "adjEM": 14.36,
    "adjEmHistory": {
      "2020-01-19": 13.82,
      "2020-01-20": 13.82,
      "2020-01-21": 13.83,
      "2020-01-22": 13.86,
      "2020-01-23": 14.36
    },
    "adjOff": 107.5,
    "adjOffHistory": {
      "2020-01-19": 105.3,
      "2020-01-20": 105.3,
      "2020-01-21": 105.4,
      "2020-01-22": 105.4,
      "2020-01-23": 107.5
    },
    "adjOffRank": 61,
    "adjOffRankHistory": {
      "2020-01-19": 89,
      "2020-01-20": 88,
      "2020-01-21": 88,
      "2020-01-22": 86,
      "2020-01-23": 61
    },
    "adjTempo": 69,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69
    },
    "adjTempoRank": 151,
    "adjTempoRankHistory": {
      "2020-01-19": 144,
      "2020-01-20": 145,
      "2020-01-21": 144,
      "2020-01-22": 146,
      "2020-01-23": 151
    },
    "conference": "Amer",
    "rank": 46,
    "rankHistory": {
      "2020-01-19": 50,
      "2020-01-20": 50,
      "2020-01-21": 50,
      "2020-01-22": 49,
      "2020-01-23": 46
    },
    "record": "12-7"
  },
  "Clemson": {
    "adjDef": 92.6,
    "adjDefHistory": {
      "2020-01-19": 92.1,
      "2020-01-20": 92.1,
      "2020-01-21": 92.1,
      "2020-01-22": 92.5,
      "2020-01-23": 92.6
    },
    "adjDefRank": 38,
    "adjDefRankHistory": {
      "2020-01-19": 32,
      "2020-01-20": 32,
      "2020-01-21": 33,
      "2020-01-22": 37,
      "2020-01-23": 38
    },
    "adjEM": 9.65,
    "adjEmHistory": {
      "2020-01-19": 9.81,
      "2020-01-20": 9.81,
      "2020-01-21": 9.89,
      "2020-01-22": 9.71,
      "2020-01-23": 9.65
    },
    "adjOff": 102.3,
    "adjOffHistory": {
      "2020-01-19": 101.9,
      "2020-01-20": 101.9,
      "2020-01-21": 101.9,
      "2020-01-22": 102.2,
      "2020-01-23": 102.3
    },
    "adjOffRank": 166,
    "adjOffRankHistory": {
      "2020-01-19": 172,
      "2020-01-20": 172,
      "2020-01-21": 171,
      "2020-01-22": 166,
      "2020-01-23": 166
    },
    "adjTempo": 66.4,
    "adjTempoHistory": {
      "2020-01-19": 66.7,
      "2020-01-20": 66.7,
      "2020-01-21": 66.6,
      "2020-01-22": 66.4,
      "2020-01-23": 66.4
    },
    "adjTempoRank": 271,
    "adjTempoRankHistory": {
      "2020-01-19": 265,
      "2020-01-20": 265,
      "2020-01-21": 267,
      "2020-01-22": 271,
      "2020-01-23": 271
    },
    "conference": "ACC",
    "rank": 80,
    "rankHistory": {
      "2020-01-19": 81,
      "2020-01-20": 81,
      "2020-01-21": 81,
      "2020-01-22": 79,
      "2020-01-23": 80
    },
    "record": "10-8"
  },
  "Cleveland St.": {
    "adjDef": 105.6,
    "adjDefHistory": {
      "2020-01-19": 105.3,
      "2020-01-20": 105.3,
      "2020-01-21": 105.5,
      "2020-01-22": 105.4,
      "2020-01-23": 105.6
    },
    "adjDefRank": 254,
    "adjDefRankHistory": {
      "2020-01-19": 256,
      "2020-01-20": 257,
      "2020-01-21": 255,
      "2020-01-22": 254,
      "2020-01-23": 254
    },
    "adjEM": -13.99,
    "adjEmHistory": {
      "2020-01-19": -13.77,
      "2020-01-20": -13.77,
      "2020-01-21": -13.72,
      "2020-01-22": -13.82,
      "2020-01-23": -13.99
    },
    "adjOff": 91.6,
    "adjOffHistory": {
      "2020-01-19": 91.5,
      "2020-01-20": 91.5,
      "2020-01-21": 91.7,
      "2020-01-22": 91.6,
      "2020-01-23": 91.6
    },
    "adjOffRank": 331,
    "adjOffRankHistory": {
      "2020-01-19": 328,
      "2020-01-20": 329,
      "2020-01-21": 328,
      "2020-01-22": 328,
      "2020-01-23": 331
    },
    "adjTempo": 67.9,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 68,
      "2020-01-22": 67.9,
      "2020-01-23": 67.9
    },
    "adjTempoRank": 206,
    "adjTempoRankHistory": {
      "2020-01-19": 205,
      "2020-01-20": 205,
      "2020-01-21": 203,
      "2020-01-22": 205,
      "2020-01-23": 206
    },
    "conference": "Horz",
    "rank": 321,
    "rankHistory": {
      "2020-01-19": 319,
      "2020-01-20": 319,
      "2020-01-21": 316,
      "2020-01-22": 318,
      "2020-01-23": 321
    },
    "record": "7-13"
  },
  "Coastal Carolina": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.8,
      "2020-01-22": 102.7,
      "2020-01-23": 102.7
    },
    "adjDefRank": 194,
    "adjDefRankHistory": {
      "2020-01-19": 188,
      "2020-01-20": 187,
      "2020-01-21": 190,
      "2020-01-22": 193,
      "2020-01-23": 194
    },
    "adjEM": 0.3,
    "adjEmHistory": {
      "2020-01-19": 0.26,
      "2020-01-20": 0.26,
      "2020-01-21": 0.24,
      "2020-01-22": 0.24,
      "2020-01-23": 0.3
    },
    "adjOff": 103,
    "adjOffHistory": {
      "2020-01-19": 102.9,
      "2020-01-20": 102.9,
      "2020-01-21": 103,
      "2020-01-22": 102.9,
      "2020-01-23": 103
    },
    "adjOffRank": 150,
    "adjOffRankHistory": {
      "2020-01-19": 149,
      "2020-01-20": 149,
      "2020-01-21": 150,
      "2020-01-22": 149,
      "2020-01-23": 150
    },
    "adjTempo": 72.3,
    "adjTempoHistory": {
      "2020-01-19": 72.5,
      "2020-01-20": 72.5,
      "2020-01-21": 72.4,
      "2020-01-22": 72.4,
      "2020-01-23": 72.3
    },
    "adjTempoRank": 25,
    "adjTempoRankHistory": {
      "2020-01-19": 27,
      "2020-01-20": 27,
      "2020-01-21": 25,
      "2020-01-22": 25,
      "2020-01-23": 25
    },
    "conference": "SB",
    "rank": 164,
    "rankHistory": {
      "2020-01-19": 165,
      "2020-01-20": 165,
      "2020-01-21": 165,
      "2020-01-22": 164,
      "2020-01-23": 164
    },
    "record": "11-9"
  },
  "Colgate": {
    "adjDef": 102.3,
    "adjDefHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.8,
      "2020-01-22": 102.6,
      "2020-01-23": 102.3
    },
    "adjDefRank": 180,
    "adjDefRankHistory": {
      "2020-01-19": 189,
      "2020-01-20": 188,
      "2020-01-21": 189,
      "2020-01-22": 189,
      "2020-01-23": 180
    },
    "adjEM": 4.05,
    "adjEmHistory": {
      "2020-01-19": 3.47,
      "2020-01-20": 3.47,
      "2020-01-21": 3.86,
      "2020-01-22": 3.84,
      "2020-01-23": 4.05
    },
    "adjOff": 106.4,
    "adjOffHistory": {
      "2020-01-19": 106.1,
      "2020-01-20": 106.1,
      "2020-01-21": 106.6,
      "2020-01-22": 106.5,
      "2020-01-23": 106.4
    },
    "adjOffRank": 71,
    "adjOffRankHistory": {
      "2020-01-19": 76,
      "2020-01-20": 75,
      "2020-01-21": 68,
      "2020-01-22": 70,
      "2020-01-23": 71
    },
    "adjTempo": 67.6,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 67.9,
      "2020-01-21": 67.7,
      "2020-01-22": 67.7,
      "2020-01-23": 67.6
    },
    "adjTempoRank": 216,
    "adjTempoRankHistory": {
      "2020-01-19": 215,
      "2020-01-20": 216,
      "2020-01-21": 217,
      "2020-01-22": 217,
      "2020-01-23": 216
    },
    "conference": "Pat",
    "rank": 125,
    "rankHistory": {
      "2020-01-19": 128,
      "2020-01-20": 128,
      "2020-01-21": 128,
      "2020-01-22": 128,
      "2020-01-23": 125
    },
    "record": "15-5"
  },
  "Colorado": {
    "adjDef": 89.5,
    "adjDefHistory": {
      "2020-01-19": 89.5,
      "2020-01-20": 89.6,
      "2020-01-21": 89.7,
      "2020-01-22": 89.4,
      "2020-01-23": 89.5
    },
    "adjDefRank": 17,
    "adjDefRankHistory": {
      "2020-01-19": 20,
      "2020-01-20": 20,
      "2020-01-21": 20,
      "2020-01-22": 18,
      "2020-01-23": 17
    },
    "adjEM": 17.31,
    "adjEmHistory": {
      "2020-01-19": 17.16,
      "2020-01-20": 17.16,
      "2020-01-21": 17.22,
      "2020-01-22": 17.28,
      "2020-01-23": 17.31
    },
    "adjOff": 106.8,
    "adjOffHistory": {
      "2020-01-19": 106.7,
      "2020-01-20": 106.7,
      "2020-01-21": 106.9,
      "2020-01-22": 106.7,
      "2020-01-23": 106.8
    },
    "adjOffRank": 68,
    "adjOffRankHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 65,
      "2020-01-22": 67,
      "2020-01-23": 68
    },
    "adjTempo": 68.6,
    "adjTempoHistory": {
      "2020-01-19": 68.8,
      "2020-01-20": 68.8,
      "2020-01-21": 68.7,
      "2020-01-22": 68.7,
      "2020-01-23": 68.6
    },
    "adjTempoRank": 165,
    "adjTempoRankHistory": {
      "2020-01-19": 163,
      "2020-01-20": 162,
      "2020-01-21": 163,
      "2020-01-22": 162,
      "2020-01-23": 165
    },
    "conference": "P12",
    "rank": 31,
    "rankHistory": {
      "2020-01-19": 30,
      "2020-01-20": 30,
      "2020-01-21": 30,
      "2020-01-22": 31,
      "2020-01-23": 31
    },
    "record": "14-4"
  },
  "Colorado St.": {
    "adjDef": 98.3,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.3,
      "2020-01-22": 98.2,
      "2020-01-23": 98.3
    },
    "adjDefRank": 113,
    "adjDefRankHistory": {
      "2020-01-19": 116,
      "2020-01-20": 116,
      "2020-01-21": 115,
      "2020-01-22": 113,
      "2020-01-23": 113
    },
    "adjEM": 7.83,
    "adjEmHistory": {
      "2020-01-19": 6.6,
      "2020-01-20": 6.6,
      "2020-01-21": 6.64,
      "2020-01-22": 6.67,
      "2020-01-23": 7.83
    },
    "adjOff": 106.1,
    "adjOffHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 105,
      "2020-01-22": 104.9,
      "2020-01-23": 106.1
    },
    "adjOffRank": 76,
    "adjOffRankHistory": {
      "2020-01-19": 102,
      "2020-01-20": 103,
      "2020-01-21": 102,
      "2020-01-22": 99,
      "2020-01-23": 76
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 130,
    "adjTempoRankHistory": {
      "2020-01-19": 141,
      "2020-01-20": 142,
      "2020-01-21": 141,
      "2020-01-22": 137,
      "2020-01-23": 130
    },
    "conference": "MWC",
    "rank": 92,
    "rankHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 101,
      "2020-01-22": 102,
      "2020-01-23": 92
    },
    "record": "14-7"
  },
  "Columbia": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.7,
      "2020-01-23": 102.7
    },
    "adjDefRank": 189,
    "adjDefRankHistory": {
      "2020-01-19": 192,
      "2020-01-20": 193,
      "2020-01-21": 195,
      "2020-01-22": 195,
      "2020-01-23": 189
    },
    "adjEM": -6.79,
    "adjEmHistory": {
      "2020-01-19": -6.58,
      "2020-01-20": -6.59,
      "2020-01-21": -6.7,
      "2020-01-22": -6.75,
      "2020-01-23": -6.79
    },
    "adjOff": 95.9,
    "adjOffHistory": {
      "2020-01-19": 96.1,
      "2020-01-20": 96.1,
      "2020-01-21": 96.1,
      "2020-01-22": 95.9,
      "2020-01-23": 95.9
    },
    "adjOffRank": 287,
    "adjOffRankHistory": {
      "2020-01-19": 279,
      "2020-01-20": 278,
      "2020-01-21": 282,
      "2020-01-22": 283,
      "2020-01-23": 287
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.2,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 147,
    "adjTempoRankHistory": {
      "2020-01-19": 137,
      "2020-01-20": 138,
      "2020-01-21": 137,
      "2020-01-22": 135,
      "2020-01-23": 147
    },
    "conference": "Ivy",
    "rank": 249,
    "rankHistory": {
      "2020-01-19": 246,
      "2020-01-20": 246,
      "2020-01-21": 247,
      "2020-01-22": 246,
      "2020-01-23": 249
    },
    "record": "6-11"
  },
  "Connecticut": {
    "adjDef": 93.4,
    "adjDefHistory": {
      "2020-01-19": 93.3,
      "2020-01-20": 93.3,
      "2020-01-21": 93.5,
      "2020-01-22": 93.4,
      "2020-01-23": 93.4
    },
    "adjDefRank": 49,
    "adjDefRankHistory": {
      "2020-01-19": 49,
      "2020-01-20": 49,
      "2020-01-21": 48,
      "2020-01-22": 48,
      "2020-01-23": 49
    },
    "adjEM": 10.1,
    "adjEmHistory": {
      "2020-01-19": 10.07,
      "2020-01-20": 10.07,
      "2020-01-21": 10.02,
      "2020-01-22": 10.04,
      "2020-01-23": 10.1
    },
    "adjOff": 103.5,
    "adjOffHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.5,
      "2020-01-22": 103.4,
      "2020-01-23": 103.5
    },
    "adjOffRank": 135,
    "adjOffRankHistory": {
      "2020-01-19": 139,
      "2020-01-20": 139,
      "2020-01-21": 138,
      "2020-01-22": 138,
      "2020-01-23": 135
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.4,
      "2020-01-20": 67.4,
      "2020-01-21": 67.3,
      "2020-01-22": 67.3,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 229,
    "adjTempoRankHistory": {
      "2020-01-19": 231,
      "2020-01-20": 231,
      "2020-01-21": 233,
      "2020-01-22": 231,
      "2020-01-23": 229
    },
    "conference": "Amer",
    "rank": 75,
    "rankHistory": {
      "2020-01-19": 78,
      "2020-01-20": 78,
      "2020-01-21": 78,
      "2020-01-22": 76,
      "2020-01-23": 75
    },
    "record": "10-7"
  },
  "Coppin St.": {
    "adjDef": 105.6,
    "adjDefHistory": {
      "2020-01-19": 105.6,
      "2020-01-20": 105.6,
      "2020-01-21": 105.8,
      "2020-01-22": 105.6,
      "2020-01-23": 105.6
    },
    "adjDefRank": 256,
    "adjDefRankHistory": {
      "2020-01-19": 264,
      "2020-01-20": 264,
      "2020-01-21": 262,
      "2020-01-22": 261,
      "2020-01-23": 256
    },
    "adjEM": -13.93,
    "adjEmHistory": {
      "2020-01-19": -14.36,
      "2020-01-20": -14.36,
      "2020-01-21": -14.2,
      "2020-01-22": -14.14,
      "2020-01-23": -13.93
    },
    "adjOff": 91.7,
    "adjOffHistory": {
      "2020-01-19": 91.3,
      "2020-01-20": 91.3,
      "2020-01-21": 91.6,
      "2020-01-22": 91.5,
      "2020-01-23": 91.7
    },
    "adjOffRank": 329,
    "adjOffRankHistory": {
      "2020-01-19": 333,
      "2020-01-20": 333,
      "2020-01-21": 332,
      "2020-01-22": 333,
      "2020-01-23": 329
    },
    "adjTempo": 74.9,
    "adjTempoHistory": {
      "2020-01-19": 75.1,
      "2020-01-20": 75.1,
      "2020-01-21": 74.9,
      "2020-01-22": 74.9,
      "2020-01-23": 74.9
    },
    "adjTempoRank": 7,
    "adjTempoRankHistory": {
      "2020-01-19": 7,
      "2020-01-20": 7,
      "2020-01-21": 7,
      "2020-01-22": 7,
      "2020-01-23": 7
    },
    "conference": "MEAC",
    "rank": 318,
    "rankHistory": {
      "2020-01-19": 324,
      "2020-01-20": 324,
      "2020-01-21": 324,
      "2020-01-22": 322,
      "2020-01-23": 318
    },
    "record": "5-15"
  },
  "Cornell": {
    "adjDef": 108.9,
    "adjDefHistory": {
      "2020-01-19": 108.7,
      "2020-01-20": 108.8,
      "2020-01-21": 108.8,
      "2020-01-22": 108.8,
      "2020-01-23": 108.9
    },
    "adjDefRank": 314,
    "adjDefRankHistory": {
      "2020-01-19": 317,
      "2020-01-20": 317,
      "2020-01-21": 314,
      "2020-01-22": 314,
      "2020-01-23": 314
    },
    "adjEM": -11.05,
    "adjEmHistory": {
      "2020-01-19": -11.02,
      "2020-01-20": -11.02,
      "2020-01-21": -10.99,
      "2020-01-22": -11.01,
      "2020-01-23": -11.05
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97.7,
      "2020-01-20": 97.7,
      "2020-01-21": 97.9,
      "2020-01-22": 97.8,
      "2020-01-23": 97.8
    },
    "adjOffRank": 241,
    "adjOffRankHistory": {
      "2020-01-19": 241,
      "2020-01-20": 241,
      "2020-01-21": 241,
      "2020-01-22": 240,
      "2020-01-23": 241
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.1,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 336,
    "adjTempoRankHistory": {
      "2020-01-19": 333,
      "2020-01-20": 333,
      "2020-01-21": 333,
      "2020-01-22": 334,
      "2020-01-23": 336
    },
    "conference": "Ivy",
    "rank": 296,
    "rankHistory": {
      "2020-01-19": 293,
      "2020-01-20": 293,
      "2020-01-21": 294,
      "2020-01-22": 295,
      "2020-01-23": 296
    },
    "record": "3-11"
  },
  "Creighton": {
    "adjDef": 97.2,
    "adjDefHistory": {
      "2020-01-19": 97.3,
      "2020-01-20": 97.3,
      "2020-01-21": 97.5,
      "2020-01-22": 97.4,
      "2020-01-23": 97.2
    },
    "adjDefRank": 90,
    "adjDefRankHistory": {
      "2020-01-19": 92,
      "2020-01-20": 92,
      "2020-01-21": 94,
      "2020-01-22": 93,
      "2020-01-23": 90
    },
    "adjEM": 17.09,
    "adjEmHistory": {
      "2020-01-19": 15.92,
      "2020-01-20": 15.92,
      "2020-01-21": 15.95,
      "2020-01-22": 15.86,
      "2020-01-23": 17.09
    },
    "adjOff": 114.3,
    "adjOffHistory": {
      "2020-01-19": 113.2,
      "2020-01-20": 113.2,
      "2020-01-21": 113.4,
      "2020-01-22": 113.2,
      "2020-01-23": 114.3
    },
    "adjOffRank": 8,
    "adjOffRankHistory": {
      "2020-01-19": 12,
      "2020-01-20": 12,
      "2020-01-21": 12,
      "2020-01-22": 13,
      "2020-01-23": 8
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.6,
      "2020-01-22": 68.5,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 168,
    "adjTempoRankHistory": {
      "2020-01-19": 169,
      "2020-01-20": 170,
      "2020-01-21": 170,
      "2020-01-22": 170,
      "2020-01-23": 168
    },
    "conference": "BE",
    "rank": 33,
    "rankHistory": {
      "2020-01-19": 38,
      "2020-01-20": 38,
      "2020-01-21": 39,
      "2020-01-22": 40,
      "2020-01-23": 33
    },
    "record": "15-5"
  },
  "Dartmouth": {
    "adjDef": 99.6,
    "adjDefHistory": {
      "2020-01-19": 99.5,
      "2020-01-20": 99.5,
      "2020-01-21": 99.5,
      "2020-01-22": 99.5,
      "2020-01-23": 99.6
    },
    "adjDefRank": 137,
    "adjDefRankHistory": {
      "2020-01-19": 136,
      "2020-01-20": 136,
      "2020-01-21": 136,
      "2020-01-22": 135,
      "2020-01-23": 137
    },
    "adjEM": -3.6,
    "adjEmHistory": {
      "2020-01-19": -3.53,
      "2020-01-20": -3.53,
      "2020-01-21": -3.44,
      "2020-01-22": -3.51,
      "2020-01-23": -3.6
    },
    "adjOff": 96,
    "adjOffHistory": {
      "2020-01-19": 95.9,
      "2020-01-20": 95.9,
      "2020-01-21": 96.1,
      "2020-01-22": 96,
      "2020-01-23": 96
    },
    "adjOffRank": 284,
    "adjOffRankHistory": {
      "2020-01-19": 281,
      "2020-01-20": 281,
      "2020-01-21": 284,
      "2020-01-22": 282,
      "2020-01-23": 284
    },
    "adjTempo": 65.6,
    "adjTempoHistory": {
      "2020-01-19": 65.8,
      "2020-01-20": 65.8,
      "2020-01-21": 65.6,
      "2020-01-22": 65.6,
      "2020-01-23": 65.6
    },
    "adjTempoRank": 296,
    "adjTempoRankHistory": {
      "2020-01-19": 296,
      "2020-01-20": 296,
      "2020-01-21": 296,
      "2020-01-22": 296,
      "2020-01-23": 296
    },
    "conference": "Ivy",
    "rank": 203,
    "rankHistory": {
      "2020-01-19": 204,
      "2020-01-20": 204,
      "2020-01-21": 204,
      "2020-01-22": 205,
      "2020-01-23": 203
    },
    "record": "7-9"
  },
  "Davidson": {
    "adjDef": 100.8,
    "adjDefHistory": {
      "2020-01-19": 101.1,
      "2020-01-20": 101.1,
      "2020-01-21": 101.3,
      "2020-01-22": 101.2,
      "2020-01-23": 100.8
    },
    "adjDefRank": 151,
    "adjDefRankHistory": {
      "2020-01-19": 157,
      "2020-01-20": 159,
      "2020-01-21": 160,
      "2020-01-22": 161,
      "2020-01-23": 151
    },
    "adjEM": 7.68,
    "adjEmHistory": {
      "2020-01-19": 6.74,
      "2020-01-20": 6.74,
      "2020-01-21": 6.7,
      "2020-01-22": 6.72,
      "2020-01-23": 7.68
    },
    "adjOff": 108.4,
    "adjOffHistory": {
      "2020-01-19": 107.9,
      "2020-01-20": 107.9,
      "2020-01-21": 108,
      "2020-01-22": 107.9,
      "2020-01-23": 108.4
    },
    "adjOffRank": 51,
    "adjOffRankHistory": {
      "2020-01-19": 52,
      "2020-01-20": 52,
      "2020-01-21": 53,
      "2020-01-22": 54,
      "2020-01-23": 51
    },
    "adjTempo": 65.2,
    "adjTempoHistory": {
      "2020-01-19": 65.4,
      "2020-01-20": 65.4,
      "2020-01-21": 65.3,
      "2020-01-22": 65.3,
      "2020-01-23": 65.2
    },
    "adjTempoRank": 310,
    "adjTempoRankHistory": {
      "2020-01-19": 313,
      "2020-01-20": 311,
      "2020-01-21": 311,
      "2020-01-22": 310,
      "2020-01-23": 310
    },
    "conference": "A10",
    "rank": 93,
    "rankHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 100,
      "2020-01-22": 100,
      "2020-01-23": 93
    },
    "record": "9-9"
  },
  "Dayton": {
    "adjDef": 92.8,
    "adjDefHistory": {
      "2020-01-19": 92.6,
      "2020-01-20": 92.6,
      "2020-01-21": 92.8,
      "2020-01-22": 92.6,
      "2020-01-23": 92.8
    },
    "adjDefRank": 43,
    "adjDefRankHistory": {
      "2020-01-19": 39,
      "2020-01-20": 39,
      "2020-01-21": 39,
      "2020-01-22": 40,
      "2020-01-23": 43
    },
    "adjEM": 24.94,
    "adjEmHistory": {
      "2020-01-19": 24.08,
      "2020-01-20": 24.08,
      "2020-01-21": 24.15,
      "2020-01-22": 24.25,
      "2020-01-23": 24.94
    },
    "adjOff": 117.7,
    "adjOffHistory": {
      "2020-01-19": 116.7,
      "2020-01-20": 116.7,
      "2020-01-21": 116.9,
      "2020-01-22": 116.9,
      "2020-01-23": 117.7
    },
    "adjOffRank": 2,
    "adjOffRankHistory": {
      "2020-01-19": 3,
      "2020-01-20": 3,
      "2020-01-21": 2,
      "2020-01-22": 2,
      "2020-01-23": 2
    },
    "adjTempo": 68.4,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.4,
      "2020-01-22": 68.4,
      "2020-01-23": 68.4
    },
    "adjTempoRank": 176,
    "adjTempoRankHistory": {
      "2020-01-19": 179,
      "2020-01-20": 180,
      "2020-01-21": 178,
      "2020-01-22": 180,
      "2020-01-23": 176
    },
    "conference": "A10",
    "rank": 4,
    "rankHistory": {
      "2020-01-19": 5,
      "2020-01-20": 5,
      "2020-01-21": 5,
      "2020-01-22": 5,
      "2020-01-23": 4
    },
    "record": "17-2"
  },
  "DePaul": {
    "adjDef": 94.1,
    "adjDefHistory": {
      "2020-01-19": 93.2,
      "2020-01-20": 93.2,
      "2020-01-21": 93.3,
      "2020-01-22": 93.2,
      "2020-01-23": 94.1
    },
    "adjDefRank": 59,
    "adjDefRankHistory": {
      "2020-01-19": 47,
      "2020-01-20": 47,
      "2020-01-21": 46,
      "2020-01-22": 47,
      "2020-01-23": 59
    },
    "adjEM": 11.61,
    "adjEmHistory": {
      "2020-01-19": 13,
      "2020-01-20": 12.99,
      "2020-01-21": 13.11,
      "2020-01-22": 12.94,
      "2020-01-23": 11.61
    },
    "adjOff": 105.7,
    "adjOffHistory": {
      "2020-01-19": 106.2,
      "2020-01-20": 106.2,
      "2020-01-21": 106.4,
      "2020-01-22": 106.2,
      "2020-01-23": 105.7
    },
    "adjOffRank": 85,
    "adjOffRankHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 71,
      "2020-01-22": 73,
      "2020-01-23": 85
    },
    "adjTempo": 70.3,
    "adjTempoHistory": {
      "2020-01-19": 70.5,
      "2020-01-20": 70.5,
      "2020-01-21": 70.3,
      "2020-01-22": 70.3,
      "2020-01-23": 70.3
    },
    "adjTempoRank": 81,
    "adjTempoRankHistory": {
      "2020-01-19": 84,
      "2020-01-20": 84,
      "2020-01-21": 86,
      "2020-01-22": 84,
      "2020-01-23": 81
    },
    "conference": "BE",
    "rank": 63,
    "rankHistory": {
      "2020-01-19": 53,
      "2020-01-20": 53,
      "2020-01-21": 53,
      "2020-01-22": 54,
      "2020-01-23": 63
    },
    "record": "13-6"
  },
  "Delaware": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.6,
      "2020-01-23": 105.7
    },
    "adjDefRank": 258,
    "adjDefRankHistory": {
      "2020-01-19": 259,
      "2020-01-20": 259,
      "2020-01-21": 260,
      "2020-01-22": 258,
      "2020-01-23": 258
    },
    "adjEM": -3.12,
    "adjEmHistory": {
      "2020-01-19": -2.87,
      "2020-01-20": -2.87,
      "2020-01-21": -3.05,
      "2020-01-22": -2.99,
      "2020-01-23": -3.12
    },
    "adjOff": 102.5,
    "adjOffHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.7,
      "2020-01-22": 102.6,
      "2020-01-23": 102.5
    },
    "adjOffRank": 160,
    "adjOffRankHistory": {
      "2020-01-19": 160,
      "2020-01-20": 160,
      "2020-01-21": 160,
      "2020-01-22": 159,
      "2020-01-23": 160
    },
    "adjTempo": 67.5,
    "adjTempoHistory": {
      "2020-01-19": 67.7,
      "2020-01-20": 67.7,
      "2020-01-21": 67.6,
      "2020-01-22": 67.6,
      "2020-01-23": 67.5
    },
    "adjTempoRank": 218,
    "adjTempoRankHistory": {
      "2020-01-19": 219,
      "2020-01-20": 219,
      "2020-01-21": 219,
      "2020-01-22": 220,
      "2020-01-23": 218
    },
    "conference": "CAA",
    "rank": 199,
    "rankHistory": {
      "2020-01-19": 196,
      "2020-01-20": 196,
      "2020-01-21": 198,
      "2020-01-22": 197,
      "2020-01-23": 199
    },
    "record": "13-7"
  },
  "Delaware St.": {
    "adjDef": 115.7,
    "adjDefHistory": {
      "2020-01-19": 115.2,
      "2020-01-20": 115.2,
      "2020-01-21": 115.6,
      "2020-01-22": 115.5,
      "2020-01-23": 115.7
    },
    "adjDefRank": 347,
    "adjDefRankHistory": {
      "2020-01-19": 347,
      "2020-01-20": 347,
      "2020-01-21": 347,
      "2020-01-22": 347,
      "2020-01-23": 347
    },
    "adjEM": -25.78,
    "adjEmHistory": {
      "2020-01-19": -25.54,
      "2020-01-20": -25.53,
      "2020-01-21": -25.72,
      "2020-01-22": -25.71,
      "2020-01-23": -25.78
    },
    "adjOff": 89.9,
    "adjOffHistory": {
      "2020-01-19": 89.7,
      "2020-01-20": 89.7,
      "2020-01-21": 89.8,
      "2020-01-22": 89.8,
      "2020-01-23": 89.9
    },
    "adjOffRank": 339,
    "adjOffRankHistory": {
      "2020-01-19": 340,
      "2020-01-20": 340,
      "2020-01-21": 341,
      "2020-01-22": 341,
      "2020-01-23": 339
    },
    "adjTempo": 75,
    "adjTempoHistory": {
      "2020-01-19": 75.3,
      "2020-01-20": 75.3,
      "2020-01-21": 75.1,
      "2020-01-22": 75.1,
      "2020-01-23": 75
    },
    "adjTempoRank": 6,
    "adjTempoRankHistory": {
      "2020-01-19": 5,
      "2020-01-20": 5,
      "2020-01-21": 5,
      "2020-01-22": 5,
      "2020-01-23": 6
    },
    "conference": "MEAC",
    "rank": 348,
    "rankHistory": {
      "2020-01-19": 348,
      "2020-01-20": 348,
      "2020-01-21": 348,
      "2020-01-22": 348,
      "2020-01-23": 348
    },
    "record": "2-15"
  },
  "Denver": {
    "adjDef": 106.8,
    "adjDefHistory": {
      "2020-01-19": 106.7,
      "2020-01-20": 106.7,
      "2020-01-21": 106.9,
      "2020-01-22": 106.8,
      "2020-01-23": 106.8
    },
    "adjDefRank": 286,
    "adjDefRankHistory": {
      "2020-01-19": 289,
      "2020-01-20": 289,
      "2020-01-21": 289,
      "2020-01-22": 288,
      "2020-01-23": 286
    },
    "adjEM": -13.71,
    "adjEmHistory": {
      "2020-01-19": -13.81,
      "2020-01-20": -13.79,
      "2020-01-21": -13.82,
      "2020-01-22": -13.8,
      "2020-01-23": -13.71
    },
    "adjOff": 93,
    "adjOffHistory": {
      "2020-01-19": 92.9,
      "2020-01-20": 92.9,
      "2020-01-21": 93.1,
      "2020-01-22": 93,
      "2020-01-23": 93
    },
    "adjOffRank": 312,
    "adjOffRankHistory": {
      "2020-01-19": 316,
      "2020-01-20": 315,
      "2020-01-21": 314,
      "2020-01-22": 313,
      "2020-01-23": 312
    },
    "adjTempo": 70.4,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.7,
      "2020-01-21": 70.5,
      "2020-01-22": 70.5,
      "2020-01-23": 70.4
    },
    "adjTempoRank": 78,
    "adjTempoRankHistory": {
      "2020-01-19": 76,
      "2020-01-20": 74,
      "2020-01-21": 75,
      "2020-01-22": 76,
      "2020-01-23": 78
    },
    "conference": "Sum",
    "rank": 316,
    "rankHistory": {
      "2020-01-19": 320,
      "2020-01-20": 320,
      "2020-01-21": 319,
      "2020-01-22": 317,
      "2020-01-23": 316
    },
    "record": "5-15"
  },
  "Detroit": {
    "adjDef": 108.3,
    "adjDefHistory": {
      "2020-01-19": 108.2,
      "2020-01-20": 108.2,
      "2020-01-21": 108.3,
      "2020-01-22": 108.2,
      "2020-01-23": 108.3
    },
    "adjDefRank": 307,
    "adjDefRankHistory": {
      "2020-01-19": 310,
      "2020-01-20": 310,
      "2020-01-21": 310,
      "2020-01-22": 309,
      "2020-01-23": 307
    },
    "adjEM": -9.53,
    "adjEmHistory": {
      "2020-01-19": -9.35,
      "2020-01-20": -9.35,
      "2020-01-21": -9.33,
      "2020-01-22": -9.38,
      "2020-01-23": -9.53
    },
    "adjOff": 98.8,
    "adjOffHistory": {
      "2020-01-19": 98.8,
      "2020-01-20": 98.8,
      "2020-01-21": 99,
      "2020-01-22": 98.8,
      "2020-01-23": 98.8
    },
    "adjOffRank": 226,
    "adjOffRankHistory": {
      "2020-01-19": 224,
      "2020-01-20": 223,
      "2020-01-21": 222,
      "2020-01-22": 222,
      "2020-01-23": 226
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.2,
      "2020-01-20": 70.2,
      "2020-01-21": 70.1,
      "2020-01-22": 70,
      "2020-01-23": 70
    },
    "adjTempoRank": 96,
    "adjTempoRankHistory": {
      "2020-01-19": 96,
      "2020-01-20": 96,
      "2020-01-21": 96,
      "2020-01-22": 97,
      "2020-01-23": 96
    },
    "conference": "Horz",
    "rank": 282,
    "rankHistory": {
      "2020-01-19": 279,
      "2020-01-20": 279,
      "2020-01-21": 280,
      "2020-01-22": 281,
      "2020-01-23": 282
    },
    "record": "4-16"
  },
  "Drake": {
    "adjDef": 98.4,
    "adjDefHistory": {
      "2020-01-19": 99.5,
      "2020-01-20": 99.5,
      "2020-01-21": 99.6,
      "2020-01-22": 99.6,
      "2020-01-23": 98.4
    },
    "adjDefRank": 115,
    "adjDefRankHistory": {
      "2020-01-19": 137,
      "2020-01-20": 138,
      "2020-01-21": 138,
      "2020-01-22": 138,
      "2020-01-23": 115
    },
    "adjEM": 2.05,
    "adjEmHistory": {
      "2020-01-19": 0.52,
      "2020-01-20": 0.52,
      "2020-01-21": 0.51,
      "2020-01-22": 0.41,
      "2020-01-23": 2.05
    },
    "adjOff": 100.5,
    "adjOffHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 100.2,
      "2020-01-22": 100,
      "2020-01-23": 100.5
    },
    "adjOffRank": 195,
    "adjOffRankHistory": {
      "2020-01-19": 199,
      "2020-01-20": 199,
      "2020-01-21": 198,
      "2020-01-22": 199,
      "2020-01-23": 195
    },
    "adjTempo": 67.2,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.4,
      "2020-01-23": 67.2
    },
    "adjTempoRank": 236,
    "adjTempoRankHistory": {
      "2020-01-19": 230,
      "2020-01-20": 230,
      "2020-01-21": 231,
      "2020-01-22": 228,
      "2020-01-23": 236
    },
    "conference": "MVC",
    "rank": 145,
    "rankHistory": {
      "2020-01-19": 161,
      "2020-01-20": 161,
      "2020-01-21": 161,
      "2020-01-22": 161,
      "2020-01-23": 145
    },
    "record": "14-6"
  },
  "Drexel": {
    "adjDef": 103,
    "adjDefHistory": {
      "2020-01-19": 102.9,
      "2020-01-20": 102.9,
      "2020-01-21": 103,
      "2020-01-22": 103,
      "2020-01-23": 103
    },
    "adjDefRank": 203,
    "adjDefRankHistory": {
      "2020-01-19": 201,
      "2020-01-20": 202,
      "2020-01-21": 204,
      "2020-01-22": 205,
      "2020-01-23": 203
    },
    "adjEM": -3.13,
    "adjEmHistory": {
      "2020-01-19": -3.17,
      "2020-01-20": -3.17,
      "2020-01-21": -3.19,
      "2020-01-22": -3.2,
      "2020-01-23": -3.13
    },
    "adjOff": 99.8,
    "adjOffHistory": {
      "2020-01-19": 99.7,
      "2020-01-20": 99.7,
      "2020-01-21": 99.8,
      "2020-01-22": 99.8,
      "2020-01-23": 99.8
    },
    "adjOffRank": 202,
    "adjOffRankHistory": {
      "2020-01-19": 206,
      "2020-01-20": 205,
      "2020-01-21": 204,
      "2020-01-22": 205,
      "2020-01-23": 202
    },
    "adjTempo": 68,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68.1,
      "2020-01-22": 68,
      "2020-01-23": 68
    },
    "adjTempoRank": 201,
    "adjTempoRankHistory": {
      "2020-01-19": 197,
      "2020-01-20": 197,
      "2020-01-21": 197,
      "2020-01-22": 197,
      "2020-01-23": 201
    },
    "conference": "CAA",
    "rank": 200,
    "rankHistory": {
      "2020-01-19": 200,
      "2020-01-20": 200,
      "2020-01-21": 201,
      "2020-01-22": 201,
      "2020-01-23": 200
    },
    "record": "12-8"
  },
  "Duke": {
    "adjDef": 86.9,
    "adjDefHistory": {
      "2020-01-19": 87.1,
      "2020-01-20": 87.1,
      "2020-01-21": 87.3,
      "2020-01-22": 86.7,
      "2020-01-23": 86.9
    },
    "adjDefRank": 6,
    "adjDefRankHistory": {
      "2020-01-19": 8,
      "2020-01-20": 8,
      "2020-01-21": 8,
      "2020-01-22": 5,
      "2020-01-23": 6
    },
    "adjEM": 28.8,
    "adjEmHistory": {
      "2020-01-19": 28.22,
      "2020-01-20": 28.2,
      "2020-01-21": 28.24,
      "2020-01-22": 28.8,
      "2020-01-23": 28.8
    },
    "adjOff": 115.7,
    "adjOffHistory": {
      "2020-01-19": 115.3,
      "2020-01-20": 115.3,
      "2020-01-21": 115.6,
      "2020-01-22": 115.5,
      "2020-01-23": 115.7
    },
    "adjOffRank": 5,
    "adjOffRankHistory": {
      "2020-01-19": 5,
      "2020-01-20": 4,
      "2020-01-21": 4,
      "2020-01-22": 5,
      "2020-01-23": 5
    },
    "adjTempo": 71.3,
    "adjTempoHistory": {
      "2020-01-19": 71.4,
      "2020-01-20": 71.4,
      "2020-01-21": 71.3,
      "2020-01-22": 71.3,
      "2020-01-23": 71.3
    },
    "adjTempoRank": 46,
    "adjTempoRankHistory": {
      "2020-01-19": 50,
      "2020-01-20": 50,
      "2020-01-21": 50,
      "2020-01-22": 47,
      "2020-01-23": 46
    },
    "conference": "ACC",
    "rank": 2,
    "rankHistory": {
      "2020-01-19": 2,
      "2020-01-20": 2,
      "2020-01-21": 2,
      "2020-01-22": 2,
      "2020-01-23": 2
    },
    "record": "16-3"
  },
  "Duquesne": {
    "adjDef": 94.7,
    "adjDefHistory": {
      "2020-01-19": 93.7,
      "2020-01-20": 93.7,
      "2020-01-21": 93.9,
      "2020-01-22": 93.8,
      "2020-01-23": 94.7
    },
    "adjDefRank": 66,
    "adjDefRankHistory": {
      "2020-01-19": 53,
      "2020-01-20": 53,
      "2020-01-21": 55,
      "2020-01-22": 54,
      "2020-01-23": 66
    },
    "adjEM": 8.62,
    "adjEmHistory": {
      "2020-01-19": 9.99,
      "2020-01-20": 9.99,
      "2020-01-21": 9.95,
      "2020-01-22": 9.94,
      "2020-01-23": 8.62
    },
    "adjOff": 103.4,
    "adjOffHistory": {
      "2020-01-19": 103.6,
      "2020-01-20": 103.7,
      "2020-01-21": 103.8,
      "2020-01-22": 103.8,
      "2020-01-23": 103.4
    },
    "adjOffRank": 143,
    "adjOffRankHistory": {
      "2020-01-19": 131,
      "2020-01-20": 131,
      "2020-01-21": 130,
      "2020-01-22": 129,
      "2020-01-23": 143
    },
    "adjTempo": 67.4,
    "adjTempoHistory": {
      "2020-01-19": 67.7,
      "2020-01-20": 67.7,
      "2020-01-21": 67.6,
      "2020-01-22": 67.6,
      "2020-01-23": 67.4
    },
    "adjTempoRank": 225,
    "adjTempoRankHistory": {
      "2020-01-19": 220,
      "2020-01-20": 220,
      "2020-01-21": 220,
      "2020-01-22": 219,
      "2020-01-23": 225
    },
    "conference": "A10",
    "rank": 88,
    "rankHistory": {
      "2020-01-19": 80,
      "2020-01-20": 80,
      "2020-01-21": 79,
      "2020-01-22": 77,
      "2020-01-23": 88
    },
    "record": "15-3"
  },
  "East Carolina": {
    "adjDef": 105.2,
    "adjDefHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.5,
      "2020-01-22": 104.5,
      "2020-01-23": 105.2
    },
    "adjDefRank": 246,
    "adjDefRankHistory": {
      "2020-01-19": 234,
      "2020-01-20": 234,
      "2020-01-21": 235,
      "2020-01-22": 237,
      "2020-01-23": 246
    },
    "adjEM": -7.34,
    "adjEmHistory": {
      "2020-01-19": -6.97,
      "2020-01-20": -6.97,
      "2020-01-21": -7.06,
      "2020-01-22": -7.07,
      "2020-01-23": -7.34
    },
    "adjOff": 97.9,
    "adjOffHistory": {
      "2020-01-19": 97.4,
      "2020-01-20": 97.4,
      "2020-01-21": 97.4,
      "2020-01-22": 97.4,
      "2020-01-23": 97.9
    },
    "adjOffRank": 239,
    "adjOffRankHistory": {
      "2020-01-19": 249,
      "2020-01-20": 249,
      "2020-01-21": 251,
      "2020-01-22": 252,
      "2020-01-23": 239
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.9,
      "2020-01-20": 70.9,
      "2020-01-21": 70.7,
      "2020-01-22": 70.7,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 76,
    "adjTempoRankHistory": {
      "2020-01-19": 60,
      "2020-01-20": 60,
      "2020-01-21": 65,
      "2020-01-22": 63,
      "2020-01-23": 76
    },
    "conference": "Amer",
    "rank": 254,
    "rankHistory": {
      "2020-01-19": 251,
      "2020-01-20": 251,
      "2020-01-21": 252,
      "2020-01-22": 252,
      "2020-01-23": 254
    },
    "record": "8-11"
  },
  "East Tennessee St.": {
    "adjDef": 95,
    "adjDefHistory": {
      "2020-01-19": 95,
      "2020-01-20": 95,
      "2020-01-21": 95.1,
      "2020-01-22": 94.9,
      "2020-01-23": 95
    },
    "adjDefRank": 67,
    "adjDefRankHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 68,
      "2020-01-22": 68,
      "2020-01-23": 67
    },
    "adjEM": 12.31,
    "adjEmHistory": {
      "2020-01-19": 12.27,
      "2020-01-20": 12.27,
      "2020-01-21": 12.34,
      "2020-01-22": 12.36,
      "2020-01-23": 12.31
    },
    "adjOff": 107.3,
    "adjOffHistory": {
      "2020-01-19": 107.2,
      "2020-01-20": 107.2,
      "2020-01-21": 107.4,
      "2020-01-22": 107.2,
      "2020-01-23": 107.3
    },
    "adjOffRank": 63,
    "adjOffRankHistory": {
      "2020-01-19": 61,
      "2020-01-20": 61,
      "2020-01-21": 60,
      "2020-01-22": 62,
      "2020-01-23": 63
    },
    "adjTempo": 65.7,
    "adjTempoHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 65.9,
      "2020-01-22": 65.8,
      "2020-01-23": 65.7
    },
    "adjTempoRank": 293,
    "adjTempoRankHistory": {
      "2020-01-19": 288,
      "2020-01-20": 289,
      "2020-01-21": 287,
      "2020-01-22": 289,
      "2020-01-23": 293
    },
    "conference": "SC",
    "rank": 56,
    "rankHistory": {
      "2020-01-19": 58,
      "2020-01-20": 58,
      "2020-01-21": 57,
      "2020-01-22": 57,
      "2020-01-23": 56
    },
    "record": "17-3"
  },
  "Eastern Illinois": {
    "adjDef": 103.9,
    "adjDefHistory": {
      "2020-01-19": 103.8,
      "2020-01-20": 103.8,
      "2020-01-21": 103.9,
      "2020-01-22": 103.9,
      "2020-01-23": 103.9
    },
    "adjDefRank": 224,
    "adjDefRankHistory": {
      "2020-01-19": 222,
      "2020-01-20": 223,
      "2020-01-21": 226,
      "2020-01-22": 226,
      "2020-01-23": 224
    },
    "adjEM": -4.92,
    "adjEmHistory": {
      "2020-01-19": -4.79,
      "2020-01-20": -4.79,
      "2020-01-21": -4.8,
      "2020-01-22": -4.85,
      "2020-01-23": -4.92
    },
    "adjOff": 99,
    "adjOffHistory": {
      "2020-01-19": 99,
      "2020-01-20": 99,
      "2020-01-21": 99.1,
      "2020-01-22": 99,
      "2020-01-23": 99
    },
    "adjOffRank": 222,
    "adjOffRankHistory": {
      "2020-01-19": 216,
      "2020-01-20": 215,
      "2020-01-21": 215,
      "2020-01-22": 216,
      "2020-01-23": 222
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69.4,
      "2020-01-20": 69.4,
      "2020-01-21": 69.3,
      "2020-01-22": 69.3,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 133,
    "adjTempoRankHistory": {
      "2020-01-19": 130,
      "2020-01-20": 130,
      "2020-01-21": 130,
      "2020-01-22": 131,
      "2020-01-23": 133
    },
    "conference": "OVC",
    "rank": 225,
    "rankHistory": {
      "2020-01-19": 220,
      "2020-01-20": 220,
      "2020-01-21": 221,
      "2020-01-22": 222,
      "2020-01-23": 225
    },
    "record": "9-9"
  },
  "Eastern Kentucky": {
    "adjDef": 106.9,
    "adjDefHistory": {
      "2020-01-19": 106.6,
      "2020-01-20": 106.6,
      "2020-01-21": 106.8,
      "2020-01-22": 106.7,
      "2020-01-23": 106.9
    },
    "adjDefRank": 289,
    "adjDefRankHistory": {
      "2020-01-19": 286,
      "2020-01-20": 286,
      "2020-01-21": 287,
      "2020-01-22": 285,
      "2020-01-23": 289
    },
    "adjEM": -13.96,
    "adjEmHistory": {
      "2020-01-19": -13.65,
      "2020-01-20": -13.65,
      "2020-01-21": -13.77,
      "2020-01-22": -13.83,
      "2020-01-23": -13.96
    },
    "adjOff": 92.9,
    "adjOffHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 93,
      "2020-01-22": 92.8,
      "2020-01-23": 92.9
    },
    "adjOffRank": 314,
    "adjOffRankHistory": {
      "2020-01-19": 314,
      "2020-01-20": 314,
      "2020-01-21": 316,
      "2020-01-22": 316,
      "2020-01-23": 314
    },
    "adjTempo": 74.4,
    "adjTempoHistory": {
      "2020-01-19": 74.5,
      "2020-01-20": 74.5,
      "2020-01-21": 74.4,
      "2020-01-22": 74.4,
      "2020-01-23": 74.4
    },
    "adjTempoRank": 10,
    "adjTempoRankHistory": {
      "2020-01-19": 10,
      "2020-01-20": 10,
      "2020-01-21": 10,
      "2020-01-22": 10,
      "2020-01-23": 10
    },
    "conference": "OVC",
    "rank": 319,
    "rankHistory": {
      "2020-01-19": 316,
      "2020-01-20": 316,
      "2020-01-21": 318,
      "2020-01-22": 319,
      "2020-01-23": 319
    },
    "record": "7-12"
  },
  "Eastern Michigan": {
    "adjDef": 92.9,
    "adjDefHistory": {
      "2020-01-19": 93.9,
      "2020-01-20": 93.9,
      "2020-01-21": 93.9,
      "2020-01-22": 92.8,
      "2020-01-23": 92.9
    },
    "adjDefRank": 44,
    "adjDefRankHistory": {
      "2020-01-19": 57,
      "2020-01-20": 57,
      "2020-01-21": 56,
      "2020-01-22": 44,
      "2020-01-23": 44
    },
    "adjEM": -4.62,
    "adjEmHistory": {
      "2020-01-19": -5.1,
      "2020-01-20": -5.1,
      "2020-01-21": -5.04,
      "2020-01-22": -4.57,
      "2020-01-23": -4.62
    },
    "adjOff": 88.3,
    "adjOffHistory": {
      "2020-01-19": 88.8,
      "2020-01-20": 88.8,
      "2020-01-21": 88.9,
      "2020-01-22": 88.3,
      "2020-01-23": 88.3
    },
    "adjOffRank": 346,
    "adjOffRankHistory": {
      "2020-01-19": 343,
      "2020-01-20": 343,
      "2020-01-21": 344,
      "2020-01-22": 345,
      "2020-01-23": 346
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 142,
    "adjTempoRankHistory": {
      "2020-01-19": 146,
      "2020-01-20": 146,
      "2020-01-21": 147,
      "2020-01-22": 142,
      "2020-01-23": 142
    },
    "conference": "MAC",
    "rank": 220,
    "rankHistory": {
      "2020-01-19": 226,
      "2020-01-20": 226,
      "2020-01-21": 225,
      "2020-01-22": 218,
      "2020-01-23": 220
    },
    "record": "10-9"
  },
  "Eastern Washington": {
    "adjDef": 103,
    "adjDefHistory": {
      "2020-01-19": 103.1,
      "2020-01-20": 103.1,
      "2020-01-21": 103.1,
      "2020-01-22": 103,
      "2020-01-23": 103
    },
    "adjDefRank": 204,
    "adjDefRankHistory": {
      "2020-01-19": 206,
      "2020-01-20": 207,
      "2020-01-21": 207,
      "2020-01-22": 206,
      "2020-01-23": 204
    },
    "adjEM": -0.19,
    "adjEmHistory": {
      "2020-01-19": -0.39,
      "2020-01-20": -0.39,
      "2020-01-21": -0.27,
      "2020-01-22": -0.24,
      "2020-01-23": -0.19
    },
    "adjOff": 102.8,
    "adjOffHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.7,
      "2020-01-23": 102.8
    },
    "adjOffRank": 155,
    "adjOffRankHistory": {
      "2020-01-19": 156,
      "2020-01-20": 156,
      "2020-01-21": 157,
      "2020-01-22": 156,
      "2020-01-23": 155
    },
    "adjTempo": 74.5,
    "adjTempoHistory": {
      "2020-01-19": 74.7,
      "2020-01-20": 74.7,
      "2020-01-21": 74.5,
      "2020-01-22": 74.5,
      "2020-01-23": 74.5
    },
    "adjTempoRank": 9,
    "adjTempoRankHistory": {
      "2020-01-19": 9,
      "2020-01-20": 9,
      "2020-01-21": 9,
      "2020-01-22": 9,
      "2020-01-23": 9
    },
    "conference": "BSky",
    "rank": 170,
    "rankHistory": {
      "2020-01-19": 174,
      "2020-01-20": 174,
      "2020-01-21": 173,
      "2020-01-22": 171,
      "2020-01-23": 170
    },
    "record": "11-6"
  },
  "Elon": {
    "adjDef": 108.8,
    "adjDefHistory": {
      "2020-01-19": 108.7,
      "2020-01-20": 108.7,
      "2020-01-21": 108.8,
      "2020-01-22": 108.7,
      "2020-01-23": 108.8
    },
    "adjDefRank": 312,
    "adjDefRankHistory": {
      "2020-01-19": 316,
      "2020-01-20": 316,
      "2020-01-21": 312,
      "2020-01-22": 312,
      "2020-01-23": 312
    },
    "adjEM": -12.79,
    "adjEmHistory": {
      "2020-01-19": -12.91,
      "2020-01-20": -12.9,
      "2020-01-21": -12.83,
      "2020-01-22": -12.82,
      "2020-01-23": -12.79
    },
    "adjOff": 96,
    "adjOffHistory": {
      "2020-01-19": 95.8,
      "2020-01-20": 95.8,
      "2020-01-21": 96,
      "2020-01-22": 95.9,
      "2020-01-23": 96
    },
    "adjOffRank": 285,
    "adjOffRankHistory": {
      "2020-01-19": 284,
      "2020-01-20": 284,
      "2020-01-21": 286,
      "2020-01-22": 285,
      "2020-01-23": 285
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 67.2,
      "2020-01-20": 67.2,
      "2020-01-21": 67.1,
      "2020-01-22": 67.1,
      "2020-01-23": 67
    },
    "adjTempoRank": 248,
    "adjTempoRankHistory": {
      "2020-01-19": 243,
      "2020-01-20": 243,
      "2020-01-21": 244,
      "2020-01-22": 243,
      "2020-01-23": 248
    },
    "conference": "CAA",
    "rank": 310,
    "rankHistory": {
      "2020-01-19": 310,
      "2020-01-20": 310,
      "2020-01-21": 309,
      "2020-01-22": 309,
      "2020-01-23": 310
    },
    "record": "5-15"
  },
  "Evansville": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.2,
      "2020-01-22": 105.1,
      "2020-01-23": 105.7
    },
    "adjDefRank": 260,
    "adjDefRankHistory": {
      "2020-01-19": 251,
      "2020-01-20": 251,
      "2020-01-21": 251,
      "2020-01-22": 250,
      "2020-01-23": 260
    },
    "adjEM": -10.1,
    "adjEmHistory": {
      "2020-01-19": -8.36,
      "2020-01-20": -8.36,
      "2020-01-21": -8.43,
      "2020-01-22": -8.54,
      "2020-01-23": -10.1
    },
    "adjOff": 95.6,
    "adjOffHistory": {
      "2020-01-19": 96.6,
      "2020-01-20": 96.6,
      "2020-01-21": 96.8,
      "2020-01-22": 96.6,
      "2020-01-23": 95.6
    },
    "adjOffRank": 290,
    "adjOffRankHistory": {
      "2020-01-19": 267,
      "2020-01-20": 267,
      "2020-01-21": 267,
      "2020-01-22": 269,
      "2020-01-23": 290
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.4,
      "2020-01-20": 68.4,
      "2020-01-21": 68.2,
      "2020-01-22": 68.2,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 197,
    "adjTempoRankHistory": {
      "2020-01-19": 188,
      "2020-01-20": 188,
      "2020-01-21": 190,
      "2020-01-22": 190,
      "2020-01-23": 197
    },
    "conference": "MVC",
    "rank": 286,
    "rankHistory": {
      "2020-01-19": 265,
      "2020-01-20": 265,
      "2020-01-21": 266,
      "2020-01-22": 269,
      "2020-01-23": 286
    },
    "record": "9-11"
  },
  "FIU": {
    "adjDef": 102.3,
    "adjDefHistory": {
      "2020-01-19": 102.2,
      "2020-01-20": 102.2,
      "2020-01-21": 102.3,
      "2020-01-22": 102.2,
      "2020-01-23": 102.3
    },
    "adjDefRank": 179,
    "adjDefRankHistory": {
      "2020-01-19": 176,
      "2020-01-20": 178,
      "2020-01-21": 179,
      "2020-01-22": 178,
      "2020-01-23": 179
    },
    "adjEM": 0.9,
    "adjEmHistory": {
      "2020-01-19": 0.86,
      "2020-01-20": 0.86,
      "2020-01-21": 0.9,
      "2020-01-22": 0.88,
      "2020-01-23": 0.9
    },
    "adjOff": 103.2,
    "adjOffHistory": {
      "2020-01-19": 103,
      "2020-01-20": 103,
      "2020-01-21": 103.2,
      "2020-01-22": 103.1,
      "2020-01-23": 103.2
    },
    "adjOffRank": 147,
    "adjOffRankHistory": {
      "2020-01-19": 148,
      "2020-01-20": 148,
      "2020-01-21": 149,
      "2020-01-22": 148,
      "2020-01-23": 147
    },
    "adjTempo": 73.9,
    "adjTempoHistory": {
      "2020-01-19": 74.1,
      "2020-01-20": 74.1,
      "2020-01-21": 74,
      "2020-01-22": 73.9,
      "2020-01-23": 73.9
    },
    "adjTempoRank": 12,
    "adjTempoRankHistory": {
      "2020-01-19": 13,
      "2020-01-20": 13,
      "2020-01-21": 13,
      "2020-01-22": 13,
      "2020-01-23": 12
    },
    "conference": "CUSA",
    "rank": 154,
    "rankHistory": {
      "2020-01-19": 155,
      "2020-01-20": 155,
      "2020-01-21": 155,
      "2020-01-22": 156,
      "2020-01-23": 154
    },
    "record": "13-6"
  },
  "Fairfield": {
    "adjDef": 99.9,
    "adjDefHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 100.1,
      "2020-01-22": 99.9,
      "2020-01-23": 99.9
    },
    "adjDefRank": 140,
    "adjDefRankHistory": {
      "2020-01-19": 143,
      "2020-01-20": 143,
      "2020-01-21": 142,
      "2020-01-22": 140,
      "2020-01-23": 140
    },
    "adjEM": -6.79,
    "adjEmHistory": {
      "2020-01-19": -6.98,
      "2020-01-20": -6.98,
      "2020-01-21": -6.95,
      "2020-01-22": -6.88,
      "2020-01-23": -6.79
    },
    "adjOff": 93.1,
    "adjOffHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 93.1,
      "2020-01-22": 93,
      "2020-01-23": 93.1
    },
    "adjOffRank": 310,
    "adjOffRankHistory": {
      "2020-01-19": 312,
      "2020-01-20": 312,
      "2020-01-21": 311,
      "2020-01-22": 311,
      "2020-01-23": 310
    },
    "adjTempo": 63.5,
    "adjTempoHistory": {
      "2020-01-19": 63.7,
      "2020-01-20": 63.7,
      "2020-01-21": 63.7,
      "2020-01-22": 63.6,
      "2020-01-23": 63.5
    },
    "adjTempoRank": 342,
    "adjTempoRankHistory": {
      "2020-01-19": 340,
      "2020-01-20": 340,
      "2020-01-21": 340,
      "2020-01-22": 340,
      "2020-01-23": 342
    },
    "conference": "MAAC",
    "rank": 248,
    "rankHistory": {
      "2020-01-19": 252,
      "2020-01-20": 252,
      "2020-01-21": 251,
      "2020-01-22": 250,
      "2020-01-23": 248
    },
    "record": "7-10"
  },
  "Fairleigh Dickinson": {
    "adjDef": 113.5,
    "adjDefHistory": {
      "2020-01-19": 113.4,
      "2020-01-20": 113.4,
      "2020-01-21": 113.5,
      "2020-01-22": 113.4,
      "2020-01-23": 113.5
    },
    "adjDefRank": 341,
    "adjDefRankHistory": {
      "2020-01-19": 341,
      "2020-01-20": 341,
      "2020-01-21": 342,
      "2020-01-22": 342,
      "2020-01-23": 341
    },
    "adjEM": -13.71,
    "adjEmHistory": {
      "2020-01-19": -13.82,
      "2020-01-20": -13.82,
      "2020-01-21": -13.5,
      "2020-01-22": -13.56,
      "2020-01-23": -13.71
    },
    "adjOff": 99.8,
    "adjOffHistory": {
      "2020-01-19": 99.6,
      "2020-01-20": 99.6,
      "2020-01-21": 100,
      "2020-01-22": 99.8,
      "2020-01-23": 99.8
    },
    "adjOffRank": 203,
    "adjOffRankHistory": {
      "2020-01-19": 208,
      "2020-01-20": 207,
      "2020-01-21": 200,
      "2020-01-22": 202,
      "2020-01-23": 203
    },
    "adjTempo": 67.8,
    "adjTempoHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 67.8
    },
    "adjTempoRank": 209,
    "adjTempoRankHistory": {
      "2020-01-19": 210,
      "2020-01-20": 210,
      "2020-01-21": 210,
      "2020-01-22": 209,
      "2020-01-23": 209
    },
    "conference": "NEC",
    "rank": 315,
    "rankHistory": {
      "2020-01-19": 321,
      "2020-01-20": 321,
      "2020-01-21": 315,
      "2020-01-22": 315,
      "2020-01-23": 315
    },
    "record": "4-13"
  },
  "Florida": {
    "adjDef": 93.7,
    "adjDefHistory": {
      "2020-01-19": 92.7,
      "2020-01-20": 92.7,
      "2020-01-21": 92.9,
      "2020-01-22": 93.5,
      "2020-01-23": 93.7
    },
    "adjDefRank": 50,
    "adjDefRankHistory": {
      "2020-01-19": 41,
      "2020-01-20": 41,
      "2020-01-21": 41,
      "2020-01-22": 50,
      "2020-01-23": 50
    },
    "adjEM": 17.67,
    "adjEmHistory": {
      "2020-01-19": 17.77,
      "2020-01-20": 17.77,
      "2020-01-21": 17.74,
      "2020-01-22": 17.62,
      "2020-01-23": 17.67
    },
    "adjOff": 111.3,
    "adjOffHistory": {
      "2020-01-19": 110.5,
      "2020-01-20": 110.5,
      "2020-01-21": 110.6,
      "2020-01-22": 111.1,
      "2020-01-23": 111.3
    },
    "adjOffRank": 25,
    "adjOffRankHistory": {
      "2020-01-19": 31,
      "2020-01-20": 31,
      "2020-01-21": 31,
      "2020-01-22": 26,
      "2020-01-23": 25
    },
    "adjTempo": 65.8,
    "adjTempoHistory": {
      "2020-01-19": 65.6,
      "2020-01-20": 65.6,
      "2020-01-21": 65.5,
      "2020-01-22": 65.7,
      "2020-01-23": 65.8
    },
    "adjTempoRank": 290,
    "adjTempoRankHistory": {
      "2020-01-19": 302,
      "2020-01-20": 301,
      "2020-01-21": 300,
      "2020-01-22": 294,
      "2020-01-23": 290
    },
    "conference": "SEC",
    "rank": 27,
    "rankHistory": {
      "2020-01-19": 25,
      "2020-01-20": 25,
      "2020-01-21": 25,
      "2020-01-22": 26,
      "2020-01-23": 27
    },
    "record": "12-6"
  },
  "Florida A&M": {
    "adjDef": 102.9,
    "adjDefHistory": {
      "2020-01-19": 103.2,
      "2020-01-20": 103.2,
      "2020-01-21": 103.1,
      "2020-01-22": 102.9,
      "2020-01-23": 102.9
    },
    "adjDefRank": 202,
    "adjDefRankHistory": {
      "2020-01-19": 210,
      "2020-01-20": 211,
      "2020-01-21": 205,
      "2020-01-22": 202,
      "2020-01-23": 202
    },
    "adjEM": -11.07,
    "adjEmHistory": {
      "2020-01-19": -11.85,
      "2020-01-20": -11.85,
      "2020-01-21": -11.27,
      "2020-01-22": -11.17,
      "2020-01-23": -11.07
    },
    "adjOff": 91.8,
    "adjOffHistory": {
      "2020-01-19": 91.4,
      "2020-01-20": 91.4,
      "2020-01-21": 91.8,
      "2020-01-22": 91.7,
      "2020-01-23": 91.8
    },
    "adjOffRank": 327,
    "adjOffRankHistory": {
      "2020-01-19": 331,
      "2020-01-20": 331,
      "2020-01-21": 327,
      "2020-01-22": 327,
      "2020-01-23": 327
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.6,
      "2020-01-20": 69.6,
      "2020-01-21": 69.4,
      "2020-01-22": 69.3,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 124,
    "adjTempoRankHistory": {
      "2020-01-19": 123,
      "2020-01-20": 123,
      "2020-01-21": 124,
      "2020-01-22": 126,
      "2020-01-23": 124
    },
    "conference": "MEAC",
    "rank": 297,
    "rankHistory": {
      "2020-01-19": 304,
      "2020-01-20": 304,
      "2020-01-21": 297,
      "2020-01-22": 297,
      "2020-01-23": 297
    },
    "record": "5-12"
  },
  "Florida Atlantic": {
    "adjDef": 100.7,
    "adjDefHistory": {
      "2020-01-19": 100.5,
      "2020-01-20": 100.5,
      "2020-01-21": 100.6,
      "2020-01-22": 100.6,
      "2020-01-23": 100.7
    },
    "adjDefRank": 150,
    "adjDefRankHistory": {
      "2020-01-19": 147,
      "2020-01-20": 148,
      "2020-01-21": 150,
      "2020-01-22": 149,
      "2020-01-23": 150
    },
    "adjEM": -2.32,
    "adjEmHistory": {
      "2020-01-19": -2.28,
      "2020-01-20": -2.28,
      "2020-01-21": -2.23,
      "2020-01-22": -2.3,
      "2020-01-23": -2.32
    },
    "adjOff": 98.3,
    "adjOffHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.3,
      "2020-01-21": 98.4,
      "2020-01-22": 98.3,
      "2020-01-23": 98.3
    },
    "adjOffRank": 234,
    "adjOffRankHistory": {
      "2020-01-19": 233,
      "2020-01-20": 233,
      "2020-01-21": 232,
      "2020-01-22": 231,
      "2020-01-23": 234
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.6,
      "2020-01-20": 68.6,
      "2020-01-21": 68.5,
      "2020-01-22": 68.5,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 172,
    "adjTempoRankHistory": {
      "2020-01-19": 175,
      "2020-01-20": 175,
      "2020-01-21": 173,
      "2020-01-22": 171,
      "2020-01-23": 172
    },
    "conference": "CUSA",
    "rank": 189,
    "rankHistory": {
      "2020-01-19": 192,
      "2020-01-20": 192,
      "2020-01-21": 190,
      "2020-01-22": 192,
      "2020-01-23": 189
    },
    "record": "12-7"
  },
  "Florida Gulf Coast": {
    "adjDef": 102.8,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.7,
      "2020-01-23": 102.8
    },
    "adjDefRank": 197,
    "adjDefRankHistory": {
      "2020-01-19": 195,
      "2020-01-20": 197,
      "2020-01-21": 194,
      "2020-01-22": 196,
      "2020-01-23": 197
    },
    "adjEM": -13.54,
    "adjEmHistory": {
      "2020-01-19": -13.47,
      "2020-01-20": -13.47,
      "2020-01-21": -13.49,
      "2020-01-22": -13.53,
      "2020-01-23": -13.54
    },
    "adjOff": 89.3,
    "adjOffHistory": {
      "2020-01-19": 89.2,
      "2020-01-20": 89.2,
      "2020-01-21": 89.3,
      "2020-01-22": 89.2,
      "2020-01-23": 89.3
    },
    "adjOffRank": 342,
    "adjOffRankHistory": {
      "2020-01-19": 342,
      "2020-01-20": 342,
      "2020-01-21": 342,
      "2020-01-22": 342,
      "2020-01-23": 342
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.1,
      "2020-01-22": 67.1,
      "2020-01-23": 67
    },
    "adjTempoRank": 246,
    "adjTempoRankHistory": {
      "2020-01-19": 241,
      "2020-01-20": 242,
      "2020-01-21": 245,
      "2020-01-22": 244,
      "2020-01-23": 246
    },
    "conference": "ASun",
    "rank": 313,
    "rankHistory": {
      "2020-01-19": 314,
      "2020-01-20": 314,
      "2020-01-21": 314,
      "2020-01-22": 314,
      "2020-01-23": 313
    },
    "record": "6-15"
  },
  "Florida St.": {
    "adjDef": 89.5,
    "adjDefHistory": {
      "2020-01-19": 89.3,
      "2020-01-20": 89.3,
      "2020-01-21": 89.6,
      "2020-01-22": 89.5,
      "2020-01-23": 89.5
    },
    "adjDefRank": 18,
    "adjDefRankHistory": {
      "2020-01-19": 19,
      "2020-01-20": 19,
      "2020-01-21": 19,
      "2020-01-22": 19,
      "2020-01-23": 18
    },
    "adjEM": 20.37,
    "adjEmHistory": {
      "2020-01-19": 20.72,
      "2020-01-20": 20.72,
      "2020-01-21": 20.6,
      "2020-01-22": 20.48,
      "2020-01-23": 20.37
    },
    "adjOff": 109.9,
    "adjOffHistory": {
      "2020-01-19": 110.1,
      "2020-01-20": 110.1,
      "2020-01-21": 110.2,
      "2020-01-22": 109.9,
      "2020-01-23": 109.9
    },
    "adjOffRank": 37,
    "adjOffRankHistory": {
      "2020-01-19": 34,
      "2020-01-20": 34,
      "2020-01-21": 35,
      "2020-01-22": 37,
      "2020-01-23": 37
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.2,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 134,
    "adjTempoRankHistory": {
      "2020-01-19": 133,
      "2020-01-20": 133,
      "2020-01-21": 135,
      "2020-01-22": 132,
      "2020-01-23": 134
    },
    "conference": "ACC",
    "rank": 16,
    "rankHistory": {
      "2020-01-19": 16,
      "2020-01-20": 16,
      "2020-01-21": 16,
      "2020-01-22": 16,
      "2020-01-23": 16
    },
    "record": "16-2"
  },
  "Fordham": {
    "adjDef": 98,
    "adjDefHistory": {
      "2020-01-19": 98.5,
      "2020-01-20": 98.5,
      "2020-01-21": 98.8,
      "2020-01-22": 98.7,
      "2020-01-23": 98
    },
    "adjDefRank": 108,
    "adjDefRankHistory": {
      "2020-01-19": 120,
      "2020-01-20": 120,
      "2020-01-21": 122,
      "2020-01-22": 120,
      "2020-01-23": 108
    },
    "adjEM": -8.44,
    "adjEmHistory": {
      "2020-01-19": -8.61,
      "2020-01-20": -8.61,
      "2020-01-21": -8.7,
      "2020-01-22": -8.72,
      "2020-01-23": -8.44
    },
    "adjOff": 89.6,
    "adjOffHistory": {
      "2020-01-19": 89.9,
      "2020-01-20": 89.9,
      "2020-01-21": 90.1,
      "2020-01-22": 89.9,
      "2020-01-23": 89.6
    },
    "adjOffRank": 341,
    "adjOffRankHistory": {
      "2020-01-19": 339,
      "2020-01-20": 339,
      "2020-01-21": 340,
      "2020-01-22": 339,
      "2020-01-23": 341
    },
    "adjTempo": 63.7,
    "adjTempoHistory": {
      "2020-01-19": 63.6,
      "2020-01-20": 63.6,
      "2020-01-21": 63.5,
      "2020-01-22": 63.5,
      "2020-01-23": 63.7
    },
    "adjTempoRank": 339,
    "adjTempoRankHistory": {
      "2020-01-19": 342,
      "2020-01-20": 342,
      "2020-01-21": 342,
      "2020-01-22": 342,
      "2020-01-23": 339
    },
    "conference": "A10",
    "rank": 266,
    "rankHistory": {
      "2020-01-19": 270,
      "2020-01-20": 270,
      "2020-01-21": 273,
      "2020-01-22": 273,
      "2020-01-23": 266
    },
    "record": "7-11"
  },
  "Fresno St.": {
    "adjDef": 101.6,
    "adjDefHistory": {
      "2020-01-19": 100.3,
      "2020-01-20": 100.4,
      "2020-01-21": 100.6,
      "2020-01-22": 100.4,
      "2020-01-23": 101.6
    },
    "adjDefRank": 164,
    "adjDefRankHistory": {
      "2020-01-19": 144,
      "2020-01-20": 147,
      "2020-01-21": 148,
      "2020-01-22": 146,
      "2020-01-23": 164
    },
    "adjEM": 2.65,
    "adjEmHistory": {
      "2020-01-19": 3.37,
      "2020-01-20": 3.34,
      "2020-01-21": 3.39,
      "2020-01-22": 3.42,
      "2020-01-23": 2.65
    },
    "adjOff": 104.2,
    "adjOffHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.7,
      "2020-01-21": 104,
      "2020-01-22": 103.8,
      "2020-01-23": 104.2
    },
    "adjOffRank": 122,
    "adjOffRankHistory": {
      "2020-01-19": 130,
      "2020-01-20": 130,
      "2020-01-21": 127,
      "2020-01-22": 126,
      "2020-01-23": 122
    },
    "adjTempo": 63.7,
    "adjTempoHistory": {
      "2020-01-19": 63.6,
      "2020-01-20": 63.6,
      "2020-01-21": 63.5,
      "2020-01-22": 63.6,
      "2020-01-23": 63.7
    },
    "adjTempoRank": 341,
    "adjTempoRankHistory": {
      "2020-01-19": 341,
      "2020-01-20": 341,
      "2020-01-21": 341,
      "2020-01-22": 341,
      "2020-01-23": 341
    },
    "conference": "MWC",
    "rank": 134,
    "rankHistory": {
      "2020-01-19": 130,
      "2020-01-20": 130,
      "2020-01-21": 131,
      "2020-01-22": 130,
      "2020-01-23": 134
    },
    "record": "6-13"
  },
  "Furman": {
    "adjDef": 98.4,
    "adjDefHistory": {
      "2020-01-19": 97.9,
      "2020-01-20": 97.9,
      "2020-01-21": 98,
      "2020-01-22": 98,
      "2020-01-23": 98.4
    },
    "adjDefRank": 114,
    "adjDefRankHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 107,
      "2020-01-22": 108,
      "2020-01-23": 114
    },
    "adjEM": 9.97,
    "adjEmHistory": {
      "2020-01-19": 9.64,
      "2020-01-20": 9.64,
      "2020-01-21": 9.63,
      "2020-01-22": 9.61,
      "2020-01-23": 9.97
    },
    "adjOff": 108.4,
    "adjOffHistory": {
      "2020-01-19": 107.5,
      "2020-01-20": 107.5,
      "2020-01-21": 107.7,
      "2020-01-22": 107.6,
      "2020-01-23": 108.4
    },
    "adjOffRank": 52,
    "adjOffRankHistory": {
      "2020-01-19": 57,
      "2020-01-20": 57,
      "2020-01-21": 58,
      "2020-01-22": 59,
      "2020-01-23": 52
    },
    "adjTempo": 68.4,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68.1,
      "2020-01-22": 68.1,
      "2020-01-23": 68.4
    },
    "adjTempoRank": 175,
    "adjTempoRankHistory": {
      "2020-01-19": 195,
      "2020-01-20": 195,
      "2020-01-21": 194,
      "2020-01-22": 194,
      "2020-01-23": 175
    },
    "conference": "SC",
    "rank": 77,
    "rankHistory": {
      "2020-01-19": 82,
      "2020-01-20": 82,
      "2020-01-21": 82,
      "2020-01-22": 81,
      "2020-01-23": 77
    },
    "record": "16-5"
  },
  "Gardner Webb": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.6,
      "2020-01-23": 105.7
    },
    "adjDefRank": 261,
    "adjDefRankHistory": {
      "2020-01-19": 261,
      "2020-01-20": 261,
      "2020-01-21": 258,
      "2020-01-22": 259,
      "2020-01-23": 261
    },
    "adjEM": -6.94,
    "adjEmHistory": {
      "2020-01-19": -6.71,
      "2020-01-20": -6.71,
      "2020-01-21": -6.84,
      "2020-01-22": -6.82,
      "2020-01-23": -6.94
    },
    "adjOff": 98.8,
    "adjOffHistory": {
      "2020-01-19": 98.8,
      "2020-01-20": 98.8,
      "2020-01-21": 98.8,
      "2020-01-22": 98.8,
      "2020-01-23": 98.8
    },
    "adjOffRank": 227,
    "adjOffRankHistory": {
      "2020-01-19": 225,
      "2020-01-20": 224,
      "2020-01-21": 224,
      "2020-01-22": 223,
      "2020-01-23": 227
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.6,
      "2020-01-20": 66.6,
      "2020-01-21": 66.5,
      "2020-01-22": 66.5,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 269,
    "adjTempoRankHistory": {
      "2020-01-19": 267,
      "2020-01-20": 267,
      "2020-01-21": 269,
      "2020-01-22": 267,
      "2020-01-23": 269
    },
    "conference": "BSth",
    "rank": 252,
    "rankHistory": {
      "2020-01-19": 248,
      "2020-01-20": 248,
      "2020-01-21": 249,
      "2020-01-22": 247,
      "2020-01-23": 252
    },
    "record": "6-11"
  },
  "George Mason": {
    "adjDef": 100.9,
    "adjDefHistory": {
      "2020-01-19": 101.1,
      "2020-01-20": 101.1,
      "2020-01-21": 101.3,
      "2020-01-22": 101.1,
      "2020-01-23": 100.9
    },
    "adjDefRank": 155,
    "adjDefRankHistory": {
      "2020-01-19": 156,
      "2020-01-20": 158,
      "2020-01-21": 161,
      "2020-01-22": 160,
      "2020-01-23": 155
    },
    "adjEM": 1.14,
    "adjEmHistory": {
      "2020-01-19": 0.83,
      "2020-01-20": 0.83,
      "2020-01-21": 0.75,
      "2020-01-22": 0.84,
      "2020-01-23": 1.14
    },
    "adjOff": 102.1,
    "adjOffHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102,
      "2020-01-22": 102,
      "2020-01-23": 102.1
    },
    "adjOffRank": 173,
    "adjOffRankHistory": {
      "2020-01-19": 170,
      "2020-01-20": 171,
      "2020-01-21": 170,
      "2020-01-22": 173,
      "2020-01-23": 173
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.2,
      "2020-01-22": 67.2,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 240,
    "adjTempoRankHistory": {
      "2020-01-19": 238,
      "2020-01-20": 238,
      "2020-01-21": 238,
      "2020-01-22": 238,
      "2020-01-23": 240
    },
    "conference": "A10",
    "rank": 153,
    "rankHistory": {
      "2020-01-19": 156,
      "2020-01-20": 156,
      "2020-01-21": 156,
      "2020-01-22": 157,
      "2020-01-23": 153
    },
    "record": "13-6"
  },
  "George Washington": {
    "adjDef": 103.2,
    "adjDefHistory": {
      "2020-01-19": 103.1,
      "2020-01-20": 103.1,
      "2020-01-21": 103.3,
      "2020-01-22": 103.2,
      "2020-01-23": 103.2
    },
    "adjDefRank": 207,
    "adjDefRankHistory": {
      "2020-01-19": 207,
      "2020-01-20": 208,
      "2020-01-21": 210,
      "2020-01-22": 210,
      "2020-01-23": 207
    },
    "adjEM": -4.46,
    "adjEmHistory": {
      "2020-01-19": -3.76,
      "2020-01-20": -3.76,
      "2020-01-21": -3.82,
      "2020-01-22": -3.81,
      "2020-01-23": -4.46
    },
    "adjOff": 98.7,
    "adjOffHistory": {
      "2020-01-19": 99.3,
      "2020-01-20": 99.3,
      "2020-01-21": 99.5,
      "2020-01-22": 99.4,
      "2020-01-23": 98.7
    },
    "adjOffRank": 228,
    "adjOffRankHistory": {
      "2020-01-19": 213,
      "2020-01-20": 212,
      "2020-01-21": 212,
      "2020-01-22": 212,
      "2020-01-23": 228
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 66.2,
      "2020-01-22": 66.2,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 268,
    "adjTempoRankHistory": {
      "2020-01-19": 276,
      "2020-01-20": 277,
      "2020-01-21": 277,
      "2020-01-22": 278,
      "2020-01-23": 268
    },
    "conference": "A10",
    "rank": 217,
    "rankHistory": {
      "2020-01-19": 207,
      "2020-01-20": 207,
      "2020-01-21": 207,
      "2020-01-22": 207,
      "2020-01-23": 217
    },
    "record": "8-11"
  },
  "Georgetown": {
    "adjDef": 98.2,
    "adjDefHistory": {
      "2020-01-19": 98.7,
      "2020-01-20": 98.7,
      "2020-01-21": 98.9,
      "2020-01-22": 98.7,
      "2020-01-23": 98.2
    },
    "adjDefRank": 110,
    "adjDefRankHistory": {
      "2020-01-19": 124,
      "2020-01-20": 123,
      "2020-01-21": 128,
      "2020-01-22": 123,
      "2020-01-23": 110
    },
    "adjEM": 13.24,
    "adjEmHistory": {
      "2020-01-19": 13.51,
      "2020-01-20": 13.51,
      "2020-01-21": 13.47,
      "2020-01-22": 13.52,
      "2020-01-23": 13.24
    },
    "adjOff": 111.4,
    "adjOffHistory": {
      "2020-01-19": 112.2,
      "2020-01-20": 112.2,
      "2020-01-21": 112.3,
      "2020-01-22": 112.2,
      "2020-01-23": 111.4
    },
    "adjOffRank": 24,
    "adjOffRankHistory": {
      "2020-01-19": 19,
      "2020-01-20": 19,
      "2020-01-21": 19,
      "2020-01-22": 18,
      "2020-01-23": 24
    },
    "adjTempo": 71.2,
    "adjTempoHistory": {
      "2020-01-19": 71.5,
      "2020-01-20": 71.5,
      "2020-01-21": 71.4,
      "2020-01-22": 71.4,
      "2020-01-23": 71.2
    },
    "adjTempoRank": 50,
    "adjTempoRankHistory": {
      "2020-01-19": 45,
      "2020-01-20": 45,
      "2020-01-21": 45,
      "2020-01-22": 45,
      "2020-01-23": 50
    },
    "conference": "BE",
    "rank": 52,
    "rankHistory": {
      "2020-01-19": 51,
      "2020-01-20": 51,
      "2020-01-21": 51,
      "2020-01-22": 52,
      "2020-01-23": 52
    },
    "record": "12-8"
  },
  "Georgia": {
    "adjDef": 98.9,
    "adjDefHistory": {
      "2020-01-19": 98.1,
      "2020-01-20": 98.1,
      "2020-01-21": 98.2,
      "2020-01-22": 98.8,
      "2020-01-23": 98.9
    },
    "adjDefRank": 125,
    "adjDefRankHistory": {
      "2020-01-19": 109,
      "2020-01-20": 109,
      "2020-01-21": 110,
      "2020-01-22": 126,
      "2020-01-23": 125
    },
    "adjEM": 9.21,
    "adjEmHistory": {
      "2020-01-19": 9.36,
      "2020-01-20": 9.36,
      "2020-01-21": 9.32,
      "2020-01-22": 9.39,
      "2020-01-23": 9.21
    },
    "adjOff": 108.1,
    "adjOffHistory": {
      "2020-01-19": 107.4,
      "2020-01-20": 107.5,
      "2020-01-21": 107.5,
      "2020-01-22": 108.2,
      "2020-01-23": 108.1
    },
    "adjOffRank": 54,
    "adjOffRankHistory": {
      "2020-01-19": 59,
      "2020-01-20": 59,
      "2020-01-21": 59,
      "2020-01-22": 50,
      "2020-01-23": 54
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.5,
      "2020-01-22": 70.7,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 64,
    "adjTempoRankHistory": {
      "2020-01-19": 75,
      "2020-01-20": 76,
      "2020-01-21": 74,
      "2020-01-22": 64,
      "2020-01-23": 64
    },
    "conference": "SEC",
    "rank": 84,
    "rankHistory": {
      "2020-01-19": 83,
      "2020-01-20": 83,
      "2020-01-21": 84,
      "2020-01-22": 83,
      "2020-01-23": 84
    },
    "record": "11-7"
  },
  "Georgia Southern": {
    "adjDef": 100.4,
    "adjDefHistory": {
      "2020-01-19": 100.3,
      "2020-01-20": 100.3,
      "2020-01-21": 100.5,
      "2020-01-22": 100.4,
      "2020-01-23": 100.4
    },
    "adjDefRank": 144,
    "adjDefRankHistory": {
      "2020-01-19": 145,
      "2020-01-20": 144,
      "2020-01-21": 145,
      "2020-01-22": 144,
      "2020-01-23": 144
    },
    "adjEM": 2,
    "adjEmHistory": {
      "2020-01-19": 2.04,
      "2020-01-20": 2.04,
      "2020-01-21": 1.99,
      "2020-01-22": 1.98,
      "2020-01-23": 2
    },
    "adjOff": 102.4,
    "adjOffHistory": {
      "2020-01-19": 102.4,
      "2020-01-20": 102.4,
      "2020-01-21": 102.5,
      "2020-01-22": 102.3,
      "2020-01-23": 102.4
    },
    "adjOffRank": 163,
    "adjOffRankHistory": {
      "2020-01-19": 163,
      "2020-01-20": 163,
      "2020-01-21": 164,
      "2020-01-22": 164,
      "2020-01-23": 163
    },
    "adjTempo": 71.3,
    "adjTempoHistory": {
      "2020-01-19": 71.5,
      "2020-01-20": 71.5,
      "2020-01-21": 71.4,
      "2020-01-22": 71.3,
      "2020-01-23": 71.3
    },
    "adjTempoRank": 47,
    "adjTempoRankHistory": {
      "2020-01-19": 46,
      "2020-01-20": 46,
      "2020-01-21": 47,
      "2020-01-22": 48,
      "2020-01-23": 47
    },
    "conference": "SB",
    "rank": 148,
    "rankHistory": {
      "2020-01-19": 145,
      "2020-01-20": 146,
      "2020-01-21": 146,
      "2020-01-22": 146,
      "2020-01-23": 148
    },
    "record": "12-8"
  },
  "Georgia St.": {
    "adjDef": 98.7,
    "adjDefHistory": {
      "2020-01-19": 98.7,
      "2020-01-20": 98.7,
      "2020-01-21": 98.8,
      "2020-01-22": 98.7,
      "2020-01-23": 98.7
    },
    "adjDefRank": 120,
    "adjDefRankHistory": {
      "2020-01-19": 127,
      "2020-01-20": 127,
      "2020-01-21": 126,
      "2020-01-22": 124,
      "2020-01-23": 120
    },
    "adjEM": 7.15,
    "adjEmHistory": {
      "2020-01-19": 6.91,
      "2020-01-20": 6.91,
      "2020-01-21": 6.98,
      "2020-01-22": 7.03,
      "2020-01-23": 7.15
    },
    "adjOff": 105.9,
    "adjOffHistory": {
      "2020-01-19": 105.6,
      "2020-01-20": 105.6,
      "2020-01-21": 105.8,
      "2020-01-22": 105.7,
      "2020-01-23": 105.9
    },
    "adjOffRank": 82,
    "adjOffRankHistory": {
      "2020-01-19": 82,
      "2020-01-20": 82,
      "2020-01-21": 81,
      "2020-01-22": 80,
      "2020-01-23": 82
    },
    "adjTempo": 72.6,
    "adjTempoHistory": {
      "2020-01-19": 72.7,
      "2020-01-20": 72.7,
      "2020-01-21": 72.6,
      "2020-01-22": 72.6,
      "2020-01-23": 72.6
    },
    "adjTempoRank": 22,
    "adjTempoRankHistory": {
      "2020-01-19": 23,
      "2020-01-20": 23,
      "2020-01-21": 22,
      "2020-01-22": 22,
      "2020-01-23": 22
    },
    "conference": "SB",
    "rank": 100,
    "rankHistory": {
      "2020-01-19": 98,
      "2020-01-20": 98,
      "2020-01-21": 98,
      "2020-01-22": 97,
      "2020-01-23": 100
    },
    "record": "13-7"
  },
  "Georgia Tech": {
    "adjDef": 92,
    "adjDefHistory": {
      "2020-01-19": 92.1,
      "2020-01-20": 92.1,
      "2020-01-21": 92.3,
      "2020-01-22": 92.1,
      "2020-01-23": 92
    },
    "adjDefRank": 32,
    "adjDefRankHistory": {
      "2020-01-19": 35,
      "2020-01-20": 35,
      "2020-01-21": 35,
      "2020-01-22": 31,
      "2020-01-23": 32
    },
    "adjEM": 8.9,
    "adjEmHistory": {
      "2020-01-19": 8.26,
      "2020-01-20": 8.26,
      "2020-01-21": 8.24,
      "2020-01-22": 8.23,
      "2020-01-23": 8.9
    },
    "adjOff": 100.9,
    "adjOffHistory": {
      "2020-01-19": 100.4,
      "2020-01-20": 100.4,
      "2020-01-21": 100.5,
      "2020-01-22": 100.3,
      "2020-01-23": 100.9
    },
    "adjOffRank": 186,
    "adjOffRankHistory": {
      "2020-01-19": 194,
      "2020-01-20": 194,
      "2020-01-21": 194,
      "2020-01-22": 194,
      "2020-01-23": 186
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70.2,
      "2020-01-20": 70.2,
      "2020-01-21": 70.1,
      "2020-01-22": 70,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 102,
    "adjTempoRankHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 94,
      "2020-01-22": 96,
      "2020-01-23": 102
    },
    "conference": "ACC",
    "rank": 87,
    "rankHistory": {
      "2020-01-19": 89,
      "2020-01-20": 89,
      "2020-01-21": 89,
      "2020-01-22": 88,
      "2020-01-23": 87
    },
    "record": "8-11"
  },
  "Gonzaga": {
    "adjDef": 92.7,
    "adjDefHistory": {
      "2020-01-19": 92.5,
      "2020-01-20": 92.5,
      "2020-01-21": 92.6,
      "2020-01-22": 92.5,
      "2020-01-23": 92.7
    },
    "adjDefRank": 40,
    "adjDefRankHistory": {
      "2020-01-19": 38,
      "2020-01-20": 38,
      "2020-01-21": 37,
      "2020-01-22": 36,
      "2020-01-23": 40
    },
    "adjEM": 25.32,
    "adjEmHistory": {
      "2020-01-19": 25.24,
      "2020-01-20": 25.24,
      "2020-01-21": 25.26,
      "2020-01-22": 25.33,
      "2020-01-23": 25.32
    },
    "adjOff": 118,
    "adjOffHistory": {
      "2020-01-19": 117.7,
      "2020-01-20": 117.7,
      "2020-01-21": 117.9,
      "2020-01-22": 117.8,
      "2020-01-23": 118
    },
    "adjOffRank": 1,
    "adjOffRankHistory": {
      "2020-01-19": 1,
      "2020-01-20": 1,
      "2020-01-21": 1,
      "2020-01-22": 1,
      "2020-01-23": 1
    },
    "adjTempo": 71.9,
    "adjTempoHistory": {
      "2020-01-19": 72.1,
      "2020-01-20": 72.1,
      "2020-01-21": 72,
      "2020-01-22": 72,
      "2020-01-23": 71.9
    },
    "adjTempoRank": 29,
    "adjTempoRankHistory": {
      "2020-01-19": 31,
      "2020-01-20": 31,
      "2020-01-21": 31,
      "2020-01-22": 30,
      "2020-01-23": 29
    },
    "conference": "WCC",
    "rank": 3,
    "rankHistory": {
      "2020-01-19": 4,
      "2020-01-20": 4,
      "2020-01-21": 3,
      "2020-01-22": 3,
      "2020-01-23": 3
    },
    "record": "20-1"
  },
  "Grambling St.": {
    "adjDef": 103.4,
    "adjDefHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 103.4,
      "2020-01-22": 103.3,
      "2020-01-23": 103.4
    },
    "adjDefRank": 213,
    "adjDefRankHistory": {
      "2020-01-19": 223,
      "2020-01-20": 224,
      "2020-01-21": 212,
      "2020-01-22": 212,
      "2020-01-23": 213
    },
    "adjEM": -11.91,
    "adjEmHistory": {
      "2020-01-19": -11.42,
      "2020-01-20": -11.42,
      "2020-01-21": -11.78,
      "2020-01-22": -11.79,
      "2020-01-23": -11.91
    },
    "adjOff": 91.5,
    "adjOffHistory": {
      "2020-01-19": 92.5,
      "2020-01-20": 92.5,
      "2020-01-21": 91.7,
      "2020-01-22": 91.5,
      "2020-01-23": 91.5
    },
    "adjOffRank": 332,
    "adjOffRankHistory": {
      "2020-01-19": 318,
      "2020-01-20": 318,
      "2020-01-21": 331,
      "2020-01-22": 330,
      "2020-01-23": 332
    },
    "adjTempo": 70.4,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.5,
      "2020-01-22": 70.5,
      "2020-01-23": 70.4
    },
    "adjTempoRank": 77,
    "adjTempoRankHistory": {
      "2020-01-19": 67,
      "2020-01-20": 68,
      "2020-01-21": 80,
      "2020-01-22": 78,
      "2020-01-23": 77
    },
    "conference": "SWAC",
    "rank": 305,
    "rankHistory": {
      "2020-01-19": 300,
      "2020-01-20": 300,
      "2020-01-21": 303,
      "2020-01-22": 303,
      "2020-01-23": 305
    },
    "record": "9-10"
  },
  "Grand Canyon": {
    "adjDef": 106,
    "adjDefHistory": {
      "2020-01-19": 105.7,
      "2020-01-20": 105.7,
      "2020-01-21": 106,
      "2020-01-22": 105.8,
      "2020-01-23": 106
    },
    "adjDefRank": 268,
    "adjDefRankHistory": {
      "2020-01-19": 266,
      "2020-01-20": 266,
      "2020-01-21": 268,
      "2020-01-22": 267,
      "2020-01-23": 268
    },
    "adjEM": -6.05,
    "adjEmHistory": {
      "2020-01-19": -5.94,
      "2020-01-20": -5.95,
      "2020-01-21": -6,
      "2020-01-22": -5.93,
      "2020-01-23": -6.05
    },
    "adjOff": 100,
    "adjOffHistory": {
      "2020-01-19": 99.8,
      "2020-01-20": 99.8,
      "2020-01-21": 100,
      "2020-01-22": 99.9,
      "2020-01-23": 100
    },
    "adjOffRank": 200,
    "adjOffRankHistory": {
      "2020-01-19": 203,
      "2020-01-20": 202,
      "2020-01-21": 199,
      "2020-01-22": 201,
      "2020-01-23": 200
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.4,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 227,
    "adjTempoRankHistory": {
      "2020-01-19": 226,
      "2020-01-20": 226,
      "2020-01-21": 230,
      "2020-01-22": 227,
      "2020-01-23": 227
    },
    "conference": "WAC",
    "rank": 238,
    "rankHistory": {
      "2020-01-19": 236,
      "2020-01-20": 236,
      "2020-01-21": 237,
      "2020-01-22": 237,
      "2020-01-23": 238
    },
    "record": "7-11"
  },
  "Green Bay": {
    "adjDef": 110.8,
    "adjDefHistory": {
      "2020-01-19": 110.6,
      "2020-01-20": 110.6,
      "2020-01-21": 110.8,
      "2020-01-22": 110.6,
      "2020-01-23": 110.8
    },
    "adjDefRank": 332,
    "adjDefRankHistory": {
      "2020-01-19": 335,
      "2020-01-20": 335,
      "2020-01-21": 334,
      "2020-01-22": 334,
      "2020-01-23": 332
    },
    "adjEM": -5.41,
    "adjEmHistory": {
      "2020-01-19": -5.35,
      "2020-01-20": -5.36,
      "2020-01-21": -5.35,
      "2020-01-22": -5.35,
      "2020-01-23": -5.41
    },
    "adjOff": 105.4,
    "adjOffHistory": {
      "2020-01-19": 105.3,
      "2020-01-20": 105.3,
      "2020-01-21": 105.4,
      "2020-01-22": 105.3,
      "2020-01-23": 105.4
    },
    "adjOffRank": 90,
    "adjOffRankHistory": {
      "2020-01-19": 90,
      "2020-01-20": 89,
      "2020-01-21": 87,
      "2020-01-22": 89,
      "2020-01-23": 90
    },
    "adjTempo": 73.7,
    "adjTempoHistory": {
      "2020-01-19": 73.8,
      "2020-01-20": 73.8,
      "2020-01-21": 73.7,
      "2020-01-22": 73.7,
      "2020-01-23": 73.7
    },
    "adjTempoRank": 15,
    "adjTempoRankHistory": {
      "2020-01-19": 16,
      "2020-01-20": 16,
      "2020-01-21": 15,
      "2020-01-22": 16,
      "2020-01-23": 15
    },
    "conference": "Horz",
    "rank": 229,
    "rankHistory": {
      "2020-01-19": 229,
      "2020-01-20": 229,
      "2020-01-21": 230,
      "2020-01-22": 228,
      "2020-01-23": 229
    },
    "record": "9-11"
  },
  "Hampton": {
    "adjDef": 115.5,
    "adjDefHistory": {
      "2020-01-19": 115.2,
      "2020-01-20": 115.2,
      "2020-01-21": 115.4,
      "2020-01-22": 115.3,
      "2020-01-23": 115.5
    },
    "adjDefRank": 346,
    "adjDefRankHistory": {
      "2020-01-19": 346,
      "2020-01-20": 346,
      "2020-01-21": 346,
      "2020-01-22": 346,
      "2020-01-23": 346
    },
    "adjEM": -14.93,
    "adjEmHistory": {
      "2020-01-19": -14.68,
      "2020-01-20": -14.69,
      "2020-01-21": -14.77,
      "2020-01-22": -14.85,
      "2020-01-23": -14.93
    },
    "adjOff": 100.6,
    "adjOffHistory": {
      "2020-01-19": 100.5,
      "2020-01-20": 100.5,
      "2020-01-21": 100.6,
      "2020-01-22": 100.4,
      "2020-01-23": 100.6
    },
    "adjOffRank": 193,
    "adjOffRankHistory": {
      "2020-01-19": 193,
      "2020-01-20": 193,
      "2020-01-21": 193,
      "2020-01-22": 193,
      "2020-01-23": 193
    },
    "adjTempo": 71.7,
    "adjTempoHistory": {
      "2020-01-19": 71.9,
      "2020-01-20": 71.9,
      "2020-01-21": 71.8,
      "2020-01-22": 71.7,
      "2020-01-23": 71.7
    },
    "adjTempoRank": 37,
    "adjTempoRankHistory": {
      "2020-01-19": 37,
      "2020-01-20": 37,
      "2020-01-21": 36,
      "2020-01-22": 36,
      "2020-01-23": 37
    },
    "conference": "BSth",
    "rank": 327,
    "rankHistory": {
      "2020-01-19": 329,
      "2020-01-20": 329,
      "2020-01-21": 328,
      "2020-01-22": 328,
      "2020-01-23": 327
    },
    "record": "8-10"
  },
  "Hartford": {
    "adjDef": 103.3,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 103.3
    },
    "adjDefRank": 210,
    "adjDefRankHistory": {
      "2020-01-19": 193,
      "2020-01-20": 194,
      "2020-01-21": 200,
      "2020-01-22": 198,
      "2020-01-23": 210
    },
    "adjEM": -10.4,
    "adjEmHistory": {
      "2020-01-19": -9.11,
      "2020-01-20": -9.11,
      "2020-01-21": -9.06,
      "2020-01-22": -9.09,
      "2020-01-23": -10.4
    },
    "adjOff": 92.9,
    "adjOffHistory": {
      "2020-01-19": 93.6,
      "2020-01-20": 93.6,
      "2020-01-21": 93.8,
      "2020-01-22": 93.7,
      "2020-01-23": 92.9
    },
    "adjOffRank": 315,
    "adjOffRankHistory": {
      "2020-01-19": 309,
      "2020-01-20": 309,
      "2020-01-21": 308,
      "2020-01-22": 308,
      "2020-01-23": 315
    },
    "adjTempo": 68.8,
    "adjTempoHistory": {
      "2020-01-19": 68.9,
      "2020-01-20": 68.9,
      "2020-01-21": 68.8,
      "2020-01-22": 68.8,
      "2020-01-23": 68.8
    },
    "adjTempoRank": 155,
    "adjTempoRankHistory": {
      "2020-01-19": 158,
      "2020-01-20": 158,
      "2020-01-21": 155,
      "2020-01-22": 155,
      "2020-01-23": 155
    },
    "conference": "AE",
    "rank": 288,
    "rankHistory": {
      "2020-01-19": 277,
      "2020-01-20": 277,
      "2020-01-21": 278,
      "2020-01-22": 279,
      "2020-01-23": 288
    },
    "record": "10-10"
  },
  "Harvard": {
    "adjDef": 98.8,
    "adjDefHistory": {
      "2020-01-19": 98.6,
      "2020-01-20": 98.7,
      "2020-01-21": 98.8,
      "2020-01-22": 98.5,
      "2020-01-23": 98.8
    },
    "adjDefRank": 122,
    "adjDefRankHistory": {
      "2020-01-19": 122,
      "2020-01-20": 125,
      "2020-01-21": 123,
      "2020-01-22": 119,
      "2020-01-23": 122
    },
    "adjEM": 6.25,
    "adjEmHistory": {
      "2020-01-19": 6.35,
      "2020-01-20": 6.32,
      "2020-01-21": 6.32,
      "2020-01-22": 6.38,
      "2020-01-23": 6.25
    },
    "adjOff": 105.1,
    "adjOffHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.1,
      "2020-01-22": 104.9,
      "2020-01-23": 105.1
    },
    "adjOffRank": 99,
    "adjOffRankHistory": {
      "2020-01-19": 98,
      "2020-01-20": 96,
      "2020-01-21": 98,
      "2020-01-22": 98,
      "2020-01-23": 99
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.6,
      "2020-01-22": 68.6,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 167,
    "adjTempoRankHistory": {
      "2020-01-19": 171,
      "2020-01-20": 169,
      "2020-01-21": 167,
      "2020-01-22": 167,
      "2020-01-23": 167
    },
    "conference": "Ivy",
    "rank": 105,
    "rankHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104,
      "2020-01-22": 105,
      "2020-01-23": 105
    },
    "record": "12-4"
  },
  "Hawaii": {
    "adjDef": 101.1,
    "adjDefHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.1,
      "2020-01-21": 101.2,
      "2020-01-22": 101.1,
      "2020-01-23": 101.1
    },
    "adjDefRank": 157,
    "adjDefRankHistory": {
      "2020-01-19": 158,
      "2020-01-20": 157,
      "2020-01-21": 159,
      "2020-01-22": 158,
      "2020-01-23": 157
    },
    "adjEM": -0.36,
    "adjEmHistory": {
      "2020-01-19": -0.52,
      "2020-01-20": -0.52,
      "2020-01-21": -0.46,
      "2020-01-22": -0.37,
      "2020-01-23": -0.36
    },
    "adjOff": 100.7,
    "adjOffHistory": {
      "2020-01-19": 100.6,
      "2020-01-20": 100.6,
      "2020-01-21": 100.8,
      "2020-01-22": 100.7,
      "2020-01-23": 100.7
    },
    "adjOffRank": 191,
    "adjOffRankHistory": {
      "2020-01-19": 190,
      "2020-01-20": 190,
      "2020-01-21": 190,
      "2020-01-22": 190,
      "2020-01-23": 191
    },
    "adjTempo": 69.6,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.6,
      "2020-01-22": 69.6,
      "2020-01-23": 69.6
    },
    "adjTempoRank": 118,
    "adjTempoRankHistory": {
      "2020-01-19": 116,
      "2020-01-20": 117,
      "2020-01-21": 119,
      "2020-01-22": 119,
      "2020-01-23": 118
    },
    "conference": "BW",
    "rank": 174,
    "rankHistory": {
      "2020-01-19": 176,
      "2020-01-20": 176,
      "2020-01-21": 176,
      "2020-01-22": 175,
      "2020-01-23": 174
    },
    "record": "12-6"
  },
  "High Point": {
    "adjDef": 113.6,
    "adjDefHistory": {
      "2020-01-19": 113.4,
      "2020-01-20": 113.4,
      "2020-01-21": 113.4,
      "2020-01-22": 113.3,
      "2020-01-23": 113.6
    },
    "adjDefRank": 342,
    "adjDefRankHistory": {
      "2020-01-19": 342,
      "2020-01-20": 342,
      "2020-01-21": 341,
      "2020-01-22": 341,
      "2020-01-23": 342
    },
    "adjEM": -20.52,
    "adjEmHistory": {
      "2020-01-19": -22.04,
      "2020-01-20": -22.04,
      "2020-01-21": -20.38,
      "2020-01-22": -20.42,
      "2020-01-23": -20.52
    },
    "adjOff": 93,
    "adjOffHistory": {
      "2020-01-19": 91.4,
      "2020-01-20": 91.4,
      "2020-01-21": 93,
      "2020-01-22": 92.9,
      "2020-01-23": 93
    },
    "adjOffRank": 311,
    "adjOffRankHistory": {
      "2020-01-19": 332,
      "2020-01-20": 332,
      "2020-01-21": 315,
      "2020-01-22": 315,
      "2020-01-23": 311
    },
    "adjTempo": 65.7,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 65.8,
      "2020-01-22": 65.7,
      "2020-01-23": 65.7
    },
    "adjTempoRank": 292,
    "adjTempoRankHistory": {
      "2020-01-19": 273,
      "2020-01-20": 273,
      "2020-01-21": 292,
      "2020-01-22": 292,
      "2020-01-23": 292
    },
    "conference": "BSth",
    "rank": 344,
    "rankHistory": {
      "2020-01-19": 345,
      "2020-01-20": 345,
      "2020-01-21": 344,
      "2020-01-22": 344,
      "2020-01-23": 344
    },
    "record": "5-14"
  },
  "Hofstra": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.6,
      "2020-01-21": 103.8,
      "2020-01-22": 103.7,
      "2020-01-23": 103.7
    },
    "adjDefRank": 221,
    "adjDefRankHistory": {
      "2020-01-19": 216,
      "2020-01-20": 217,
      "2020-01-21": 219,
      "2020-01-22": 220,
      "2020-01-23": 221
    },
    "adjEM": 1.68,
    "adjEmHistory": {
      "2020-01-19": 1.83,
      "2020-01-20": 1.85,
      "2020-01-21": 1.79,
      "2020-01-22": 1.75,
      "2020-01-23": 1.68
    },
    "adjOff": 105.4,
    "adjOffHistory": {
      "2020-01-19": 105.4,
      "2020-01-20": 105.5,
      "2020-01-21": 105.6,
      "2020-01-22": 105.4,
      "2020-01-23": 105.4
    },
    "adjOffRank": 92,
    "adjOffRankHistory": {
      "2020-01-19": 88,
      "2020-01-20": 86,
      "2020-01-21": 85,
      "2020-01-22": 85,
      "2020-01-23": 92
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 146,
    "adjTempoRankHistory": {
      "2020-01-19": 142,
      "2020-01-20": 137,
      "2020-01-21": 138,
      "2020-01-22": 138,
      "2020-01-23": 146
    },
    "conference": "CAA",
    "rank": 149,
    "rankHistory": {
      "2020-01-19": 148,
      "2020-01-20": 148,
      "2020-01-21": 148,
      "2020-01-22": 148,
      "2020-01-23": 149
    },
    "record": "14-6"
  },
  "Holy Cross": {
    "adjDef": 116,
    "adjDefHistory": {
      "2020-01-19": 115.4,
      "2020-01-20": 115.4,
      "2020-01-21": 115.7,
      "2020-01-22": 115.6,
      "2020-01-23": 116
    },
    "adjDefRank": 349,
    "adjDefRankHistory": {
      "2020-01-19": 348,
      "2020-01-20": 348,
      "2020-01-21": 348,
      "2020-01-22": 348,
      "2020-01-23": 349
    },
    "adjEM": -18.21,
    "adjEmHistory": {
      "2020-01-19": -18.42,
      "2020-01-20": -18.42,
      "2020-01-21": -18.52,
      "2020-01-22": -18.55,
      "2020-01-23": -18.21
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97,
      "2020-01-20": 97,
      "2020-01-21": 97.2,
      "2020-01-22": 97.1,
      "2020-01-23": 97.8
    },
    "adjOffRank": 243,
    "adjOffRankHistory": {
      "2020-01-19": 258,
      "2020-01-20": 258,
      "2020-01-21": 258,
      "2020-01-22": 259,
      "2020-01-23": 243
    },
    "adjTempo": 68.2,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68,
      "2020-01-22": 68,
      "2020-01-23": 68.2
    },
    "adjTempoRank": 189,
    "adjTempoRankHistory": {
      "2020-01-19": 199,
      "2020-01-20": 199,
      "2020-01-21": 200,
      "2020-01-22": 201,
      "2020-01-23": 189
    },
    "conference": "Pat",
    "rank": 339,
    "rankHistory": {
      "2020-01-19": 340,
      "2020-01-20": 340,
      "2020-01-21": 340,
      "2020-01-22": 340,
      "2020-01-23": 339
    },
    "record": "3-17"
  },
  "Houston": {
    "adjDef": 95.5,
    "adjDefHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.7,
      "2020-01-22": 95.6,
      "2020-01-23": 95.5
    },
    "adjDefRank": 77,
    "adjDefRankHistory": {
      "2020-01-19": 78,
      "2020-01-20": 78,
      "2020-01-21": 78,
      "2020-01-22": 80,
      "2020-01-23": 77
    },
    "adjEM": 18.32,
    "adjEmHistory": {
      "2020-01-19": 18.08,
      "2020-01-20": 18.08,
      "2020-01-21": 18.06,
      "2020-01-22": 18.12,
      "2020-01-23": 18.32
    },
    "adjOff": 113.8,
    "adjOffHistory": {
      "2020-01-19": 113.6,
      "2020-01-20": 113.6,
      "2020-01-21": 113.8,
      "2020-01-22": 113.7,
      "2020-01-23": 113.8
    },
    "adjOffRank": 13,
    "adjOffRankHistory": {
      "2020-01-19": 10,
      "2020-01-20": 10,
      "2020-01-21": 10,
      "2020-01-22": 12,
      "2020-01-23": 13
    },
    "adjTempo": 66.4,
    "adjTempoHistory": {
      "2020-01-19": 66.5,
      "2020-01-20": 66.5,
      "2020-01-21": 66.4,
      "2020-01-22": 66.4,
      "2020-01-23": 66.4
    },
    "adjTempoRank": 273,
    "adjTempoRankHistory": {
      "2020-01-19": 271,
      "2020-01-20": 271,
      "2020-01-21": 272,
      "2020-01-22": 272,
      "2020-01-23": 273
    },
    "conference": "Amer",
    "rank": 21,
    "rankHistory": {
      "2020-01-19": 23,
      "2020-01-20": 23,
      "2020-01-21": 23,
      "2020-01-22": 23,
      "2020-01-23": 21
    },
    "record": "14-4"
  },
  "Houston Baptist": {
    "adjDef": 123.6,
    "adjDefHistory": {
      "2020-01-19": 123.6,
      "2020-01-20": 123.6,
      "2020-01-21": 123.8,
      "2020-01-22": 123.8,
      "2020-01-23": 123.6
    },
    "adjDefRank": 353,
    "adjDefRankHistory": {
      "2020-01-19": 353,
      "2020-01-20": 353,
      "2020-01-21": 353,
      "2020-01-22": 353,
      "2020-01-23": 353
    },
    "adjEM": -19.34,
    "adjEmHistory": {
      "2020-01-19": -20.13,
      "2020-01-20": -20.13,
      "2020-01-21": -20.22,
      "2020-01-22": -20.27,
      "2020-01-23": -19.34
    },
    "adjOff": 104.3,
    "adjOffHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.6,
      "2020-01-22": 103.5,
      "2020-01-23": 104.3
    },
    "adjOffRank": 120,
    "adjOffRankHistory": {
      "2020-01-19": 138,
      "2020-01-20": 137,
      "2020-01-21": 136,
      "2020-01-22": 134,
      "2020-01-23": 120
    },
    "adjTempo": 77.1,
    "adjTempoHistory": {
      "2020-01-19": 77.3,
      "2020-01-20": 77.3,
      "2020-01-21": 77.2,
      "2020-01-22": 77.1,
      "2020-01-23": 77.1
    },
    "adjTempoRank": 1,
    "adjTempoRankHistory": {
      "2020-01-19": 1,
      "2020-01-20": 1,
      "2020-01-21": 1,
      "2020-01-22": 1,
      "2020-01-23": 1
    },
    "conference": "Slnd",
    "rank": 342,
    "rankHistory": {
      "2020-01-19": 343,
      "2020-01-20": 343,
      "2020-01-21": 343,
      "2020-01-22": 343,
      "2020-01-23": 342
    },
    "record": "1-15"
  },
  "Howard": {
    "adjDef": 117.6,
    "adjDefHistory": {
      "2020-01-19": 116.4,
      "2020-01-20": 116.4,
      "2020-01-21": 117.5,
      "2020-01-22": 117.4,
      "2020-01-23": 117.6
    },
    "adjDefRank": 350,
    "adjDefRankHistory": {
      "2020-01-19": 350,
      "2020-01-20": 350,
      "2020-01-21": 350,
      "2020-01-22": 350,
      "2020-01-23": 350
    },
    "adjEM": -26.28,
    "adjEmHistory": {
      "2020-01-19": -26.72,
      "2020-01-20": -26.73,
      "2020-01-21": -26.08,
      "2020-01-22": -26.11,
      "2020-01-23": -26.28
    },
    "adjOff": 91.4,
    "adjOffHistory": {
      "2020-01-19": 89.6,
      "2020-01-20": 89.6,
      "2020-01-21": 91.4,
      "2020-01-22": 91.3,
      "2020-01-23": 91.4
    },
    "adjOffRank": 334,
    "adjOffRankHistory": {
      "2020-01-19": 341,
      "2020-01-20": 341,
      "2020-01-21": 333,
      "2020-01-22": 334,
      "2020-01-23": 334
    },
    "adjTempo": 70.3,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.4,
      "2020-01-22": 70.4,
      "2020-01-23": 70.3
    },
    "adjTempoRank": 80,
    "adjTempoRankHistory": {
      "2020-01-19": 82,
      "2020-01-20": 82,
      "2020-01-21": 84,
      "2020-01-22": 82,
      "2020-01-23": 80
    },
    "conference": "MEAC",
    "rank": 349,
    "rankHistory": {
      "2020-01-19": 349,
      "2020-01-20": 349,
      "2020-01-21": 349,
      "2020-01-22": 349,
      "2020-01-23": 349
    },
    "record": "2-18"
  },
  "IUPUI": {
    "adjDef": 112,
    "adjDefHistory": {
      "2020-01-19": 111.6,
      "2020-01-20": 111.6,
      "2020-01-21": 111.8,
      "2020-01-22": 111.8,
      "2020-01-23": 112
    },
    "adjDefRank": 339,
    "adjDefRankHistory": {
      "2020-01-19": 337,
      "2020-01-20": 337,
      "2020-01-21": 338,
      "2020-01-22": 339,
      "2020-01-23": 339
    },
    "adjEM": -14.43,
    "adjEmHistory": {
      "2020-01-19": -14,
      "2020-01-20": -14.01,
      "2020-01-21": -14.05,
      "2020-01-22": -14.2,
      "2020-01-23": -14.43
    },
    "adjOff": 97.6,
    "adjOffHistory": {
      "2020-01-19": 97.6,
      "2020-01-20": 97.6,
      "2020-01-21": 97.7,
      "2020-01-22": 97.6,
      "2020-01-23": 97.6
    },
    "adjOffRank": 250,
    "adjOffRankHistory": {
      "2020-01-19": 244,
      "2020-01-20": 244,
      "2020-01-21": 245,
      "2020-01-22": 246,
      "2020-01-23": 250
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.7,
      "2020-01-22": 70.7,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 66,
    "adjTempoRankHistory": {
      "2020-01-19": 61,
      "2020-01-20": 62,
      "2020-01-21": 64,
      "2020-01-22": 65,
      "2020-01-23": 66
    },
    "conference": "Horz",
    "rank": 324,
    "rankHistory": {
      "2020-01-19": 322,
      "2020-01-20": 322,
      "2020-01-21": 321,
      "2020-01-22": 324,
      "2020-01-23": 324
    },
    "record": "5-15"
  },
  "Idaho": {
    "adjDef": 102.9,
    "adjDefHistory": {
      "2020-01-19": 102.9,
      "2020-01-20": 102.9,
      "2020-01-21": 103,
      "2020-01-22": 102.8,
      "2020-01-23": 102.9
    },
    "adjDefRank": 201,
    "adjDefRankHistory": {
      "2020-01-19": 204,
      "2020-01-20": 204,
      "2020-01-21": 203,
      "2020-01-22": 201,
      "2020-01-23": 201
    },
    "adjEM": -11.98,
    "adjEmHistory": {
      "2020-01-19": -12.1,
      "2020-01-20": -12.1,
      "2020-01-21": -12.06,
      "2020-01-22": -12.03,
      "2020-01-23": -11.98
    },
    "adjOff": 90.9,
    "adjOffHistory": {
      "2020-01-19": 90.8,
      "2020-01-20": 90.8,
      "2020-01-21": 90.9,
      "2020-01-22": 90.8,
      "2020-01-23": 90.9
    },
    "adjOffRank": 336,
    "adjOffRankHistory": {
      "2020-01-19": 336,
      "2020-01-20": 336,
      "2020-01-21": 336,
      "2020-01-22": 337,
      "2020-01-23": 336
    },
    "adjTempo": 69.5,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.6,
      "2020-01-22": 69.6,
      "2020-01-23": 69.5
    },
    "adjTempoRank": 120,
    "adjTempoRankHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 118,
      "2020-01-22": 117,
      "2020-01-23": 120
    },
    "conference": "BSky",
    "rank": 306,
    "rankHistory": {
      "2020-01-19": 305,
      "2020-01-20": 305,
      "2020-01-21": 305,
      "2020-01-22": 305,
      "2020-01-23": 306
    },
    "record": "5-12"
  },
  "Idaho St.": {
    "adjDef": 107.8,
    "adjDefHistory": {
      "2020-01-19": 107.9,
      "2020-01-20": 107.8,
      "2020-01-21": 107.9,
      "2020-01-22": 107.8,
      "2020-01-23": 107.8
    },
    "adjDefRank": 300,
    "adjDefRankHistory": {
      "2020-01-19": 305,
      "2020-01-20": 305,
      "2020-01-21": 304,
      "2020-01-22": 303,
      "2020-01-23": 300
    },
    "adjEM": -11.63,
    "adjEmHistory": {
      "2020-01-19": -11.77,
      "2020-01-20": -11.77,
      "2020-01-21": -11.71,
      "2020-01-22": -11.67,
      "2020-01-23": -11.63
    },
    "adjOff": 96.2,
    "adjOffHistory": {
      "2020-01-19": 96.1,
      "2020-01-20": 96.1,
      "2020-01-21": 96.2,
      "2020-01-22": 96.2,
      "2020-01-23": 96.2
    },
    "adjOffRank": 280,
    "adjOffRankHistory": {
      "2020-01-19": 278,
      "2020-01-20": 279,
      "2020-01-21": 280,
      "2020-01-22": 279,
      "2020-01-23": 280
    },
    "adjTempo": 65.4,
    "adjTempoHistory": {
      "2020-01-19": 65.7,
      "2020-01-20": 65.7,
      "2020-01-21": 65.5,
      "2020-01-22": 65.4,
      "2020-01-23": 65.4
    },
    "adjTempoRank": 301,
    "adjTempoRankHistory": {
      "2020-01-19": 301,
      "2020-01-20": 300,
      "2020-01-21": 302,
      "2020-01-22": 300,
      "2020-01-23": 301
    },
    "conference": "BSky",
    "rank": 304,
    "rankHistory": {
      "2020-01-19": 302,
      "2020-01-20": 302,
      "2020-01-21": 302,
      "2020-01-22": 302,
      "2020-01-23": 304
    },
    "record": "6-10"
  },
  "Illinois": {
    "adjDef": 93,
    "adjDefHistory": {
      "2020-01-19": 92.8,
      "2020-01-20": 92.8,
      "2020-01-21": 92.9,
      "2020-01-22": 92.8,
      "2020-01-23": 93
    },
    "adjDefRank": 45,
    "adjDefRankHistory": {
      "2020-01-19": 43,
      "2020-01-20": 43,
      "2020-01-21": 40,
      "2020-01-22": 42,
      "2020-01-23": 45
    },
    "adjEM": 18.08,
    "adjEmHistory": {
      "2020-01-19": 16.37,
      "2020-01-20": 16.37,
      "2020-01-21": 16.4,
      "2020-01-22": 18.21,
      "2020-01-23": 18.08
    },
    "adjOff": 111.1,
    "adjOffHistory": {
      "2020-01-19": 109.2,
      "2020-01-20": 109.2,
      "2020-01-21": 109.3,
      "2020-01-22": 111,
      "2020-01-23": 111.1
    },
    "adjOffRank": 27,
    "adjOffRankHistory": {
      "2020-01-19": 40,
      "2020-01-20": 40,
      "2020-01-21": 40,
      "2020-01-22": 27,
      "2020-01-23": 27
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.6,
      "2020-01-20": 67.6,
      "2020-01-21": 67.4,
      "2020-01-22": 67.4,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 228,
    "adjTempoRankHistory": {
      "2020-01-19": 223,
      "2020-01-20": 223,
      "2020-01-21": 225,
      "2020-01-22": 224,
      "2020-01-23": 228
    },
    "conference": "B10",
    "rank": 22,
    "rankHistory": {
      "2020-01-19": 35,
      "2020-01-20": 35,
      "2020-01-21": 36,
      "2020-01-22": 22,
      "2020-01-23": 22
    },
    "record": "14-5"
  },
  "Illinois Chicago": {
    "adjDef": 103.1,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 103.1
    },
    "adjDefRank": 205,
    "adjDefRankHistory": {
      "2020-01-19": 197,
      "2020-01-20": 198,
      "2020-01-21": 199,
      "2020-01-22": 200,
      "2020-01-23": 205
    },
    "adjEM": -7.53,
    "adjEmHistory": {
      "2020-01-19": -7.13,
      "2020-01-20": -7.13,
      "2020-01-21": -7.11,
      "2020-01-22": -7.19,
      "2020-01-23": -7.53
    },
    "adjOff": 95.6,
    "adjOffHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.8,
      "2020-01-22": 95.6,
      "2020-01-23": 95.6
    },
    "adjOffRank": 291,
    "adjOffRankHistory": {
      "2020-01-19": 289,
      "2020-01-20": 289,
      "2020-01-21": 288,
      "2020-01-22": 288,
      "2020-01-23": 291
    },
    "adjTempo": 68.8,
    "adjTempoHistory": {
      "2020-01-19": 68.9,
      "2020-01-20": 68.9,
      "2020-01-21": 68.8,
      "2020-01-22": 68.8,
      "2020-01-23": 68.8
    },
    "adjTempoRank": 157,
    "adjTempoRankHistory": {
      "2020-01-19": 156,
      "2020-01-20": 156,
      "2020-01-21": 156,
      "2020-01-22": 156,
      "2020-01-23": 157
    },
    "conference": "Horz",
    "rank": 257,
    "rankHistory": {
      "2020-01-19": 253,
      "2020-01-20": 253,
      "2020-01-21": 253,
      "2020-01-22": 253,
      "2020-01-23": 257
    },
    "record": "8-12"
  },
  "Illinois St.": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.6,
      "2020-01-22": 103.5,
      "2020-01-23": 103.7
    },
    "adjDefRank": 222,
    "adjDefRankHistory": {
      "2020-01-19": 214,
      "2020-01-20": 215,
      "2020-01-21": 214,
      "2020-01-22": 214,
      "2020-01-23": 222
    },
    "adjEM": -4.76,
    "adjEmHistory": {
      "2020-01-19": -4.86,
      "2020-01-20": -4.86,
      "2020-01-21": -4.83,
      "2020-01-22": -4.8,
      "2020-01-23": -4.76
    },
    "adjOff": 99,
    "adjOffHistory": {
      "2020-01-19": 98.6,
      "2020-01-20": 98.6,
      "2020-01-21": 98.8,
      "2020-01-22": 98.7,
      "2020-01-23": 99
    },
    "adjOffRank": 221,
    "adjOffRankHistory": {
      "2020-01-19": 228,
      "2020-01-20": 227,
      "2020-01-21": 225,
      "2020-01-22": 224,
      "2020-01-23": 221
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.6,
      "2020-01-22": 68.5,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 181,
    "adjTempoRankHistory": {
      "2020-01-19": 170,
      "2020-01-20": 171,
      "2020-01-21": 169,
      "2020-01-22": 169,
      "2020-01-23": 181
    },
    "conference": "MVC",
    "rank": 222,
    "rankHistory": {
      "2020-01-19": 222,
      "2020-01-20": 222,
      "2020-01-21": 222,
      "2020-01-22": 221,
      "2020-01-23": 222
    },
    "record": "6-13"
  },
  "Incarnate Word": {
    "adjDef": 113.3,
    "adjDefHistory": {
      "2020-01-19": 111.8,
      "2020-01-20": 111.8,
      "2020-01-21": 111.9,
      "2020-01-22": 111.8,
      "2020-01-23": 113.3
    },
    "adjDefRank": 340,
    "adjDefRankHistory": {
      "2020-01-19": 339,
      "2020-01-20": 339,
      "2020-01-21": 340,
      "2020-01-22": 340,
      "2020-01-23": 340
    },
    "adjEM": -24.59,
    "adjEmHistory": {
      "2020-01-19": -24.24,
      "2020-01-20": -24.25,
      "2020-01-21": -24.26,
      "2020-01-22": -24.29,
      "2020-01-23": -24.59
    },
    "adjOff": 88.7,
    "adjOffHistory": {
      "2020-01-19": 87.6,
      "2020-01-20": 87.6,
      "2020-01-21": 87.7,
      "2020-01-22": 87.5,
      "2020-01-23": 88.7
    },
    "adjOffRank": 344,
    "adjOffRankHistory": {
      "2020-01-19": 349,
      "2020-01-20": 349,
      "2020-01-21": 349,
      "2020-01-22": 349,
      "2020-01-23": 344
    },
    "adjTempo": 67.2,
    "adjTempoHistory": {
      "2020-01-19": 66.9,
      "2020-01-20": 66.9,
      "2020-01-21": 66.8,
      "2020-01-22": 66.8,
      "2020-01-23": 67.2
    },
    "adjTempoRank": 235,
    "adjTempoRankHistory": {
      "2020-01-19": 254,
      "2020-01-20": 254,
      "2020-01-21": 256,
      "2020-01-22": 255,
      "2020-01-23": 235
    },
    "conference": "Slnd",
    "rank": 347,
    "rankHistory": {
      "2020-01-19": 347,
      "2020-01-20": 347,
      "2020-01-21": 347,
      "2020-01-22": 347,
      "2020-01-23": 347
    },
    "record": "4-14"
  },
  "Indiana": {
    "adjDef": 92,
    "adjDefHistory": {
      "2020-01-19": 92.1,
      "2020-01-20": 92.1,
      "2020-01-21": 92,
      "2020-01-22": 92,
      "2020-01-23": 92
    },
    "adjDefRank": 31,
    "adjDefRankHistory": {
      "2020-01-19": 34,
      "2020-01-20": 34,
      "2020-01-21": 32,
      "2020-01-22": 30,
      "2020-01-23": 31
    },
    "adjEM": 15.73,
    "adjEmHistory": {
      "2020-01-19": 15.74,
      "2020-01-20": 15.74,
      "2020-01-21": 15.73,
      "2020-01-22": 15.77,
      "2020-01-23": 15.73
    },
    "adjOff": 107.7,
    "adjOffHistory": {
      "2020-01-19": 107.8,
      "2020-01-20": 107.8,
      "2020-01-21": 107.7,
      "2020-01-22": 107.7,
      "2020-01-23": 107.7
    },
    "adjOffRank": 58,
    "adjOffRankHistory": {
      "2020-01-19": 53,
      "2020-01-20": 53,
      "2020-01-21": 57,
      "2020-01-22": 58,
      "2020-01-23": 58
    },
    "adjTempo": 69,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.4,
      "2020-01-22": 69.1,
      "2020-01-23": 69
    },
    "adjTempoRank": 149,
    "adjTempoRankHistory": {
      "2020-01-19": 138,
      "2020-01-20": 139,
      "2020-01-21": 127,
      "2020-01-22": 147,
      "2020-01-23": 149
    },
    "conference": "B10",
    "rank": 40,
    "rankHistory": {
      "2020-01-19": 40,
      "2020-01-20": 40,
      "2020-01-21": 40,
      "2020-01-22": 41,
      "2020-01-23": 40
    },
    "record": "14-4"
  },
  "Indiana St.": {
    "adjDef": 102,
    "adjDefHistory": {
      "2020-01-19": 100.8,
      "2020-01-20": 100.8,
      "2020-01-21": 100.9,
      "2020-01-22": 100.9,
      "2020-01-23": 102
    },
    "adjDefRank": 175,
    "adjDefRankHistory": {
      "2020-01-19": 152,
      "2020-01-20": 152,
      "2020-01-21": 154,
      "2020-01-22": 155,
      "2020-01-23": 175
    },
    "adjEM": 4.01,
    "adjEmHistory": {
      "2020-01-19": 5.31,
      "2020-01-20": 5.31,
      "2020-01-21": 5.35,
      "2020-01-22": 5.33,
      "2020-01-23": 4.01
    },
    "adjOff": 106,
    "adjOffHistory": {
      "2020-01-19": 106.1,
      "2020-01-20": 106.1,
      "2020-01-21": 106.3,
      "2020-01-22": 106.2,
      "2020-01-23": 106
    },
    "adjOffRank": 79,
    "adjOffRankHistory": {
      "2020-01-19": 74,
      "2020-01-20": 73,
      "2020-01-21": 73,
      "2020-01-22": 72,
      "2020-01-23": 79
    },
    "adjTempo": 67.4,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 67.9,
      "2020-01-21": 67.8,
      "2020-01-22": 67.8,
      "2020-01-23": 67.4
    },
    "adjTempoRank": 222,
    "adjTempoRankHistory": {
      "2020-01-19": 217,
      "2020-01-20": 217,
      "2020-01-21": 216,
      "2020-01-22": 215,
      "2020-01-23": 222
    },
    "conference": "MVC",
    "rank": 126,
    "rankHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 112,
      "2020-01-22": 112,
      "2020-01-23": 126
    },
    "record": "10-8"
  },
  "Iona": {
    "adjDef": 106.1,
    "adjDefHistory": {
      "2020-01-19": 105.9,
      "2020-01-20": 105.9,
      "2020-01-21": 106.1,
      "2020-01-22": 106,
      "2020-01-23": 106.1
    },
    "adjDefRank": 272,
    "adjDefRankHistory": {
      "2020-01-19": 273,
      "2020-01-20": 273,
      "2020-01-21": 272,
      "2020-01-22": 273,
      "2020-01-23": 272
    },
    "adjEM": -8.36,
    "adjEmHistory": {
      "2020-01-19": -8.14,
      "2020-01-20": -8.14,
      "2020-01-21": -8.21,
      "2020-01-22": -8.28,
      "2020-01-23": -8.36
    },
    "adjOff": 97.7,
    "adjOffHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.7
    },
    "adjOffRank": 246,
    "adjOffRankHistory": {
      "2020-01-19": 239,
      "2020-01-20": 239,
      "2020-01-21": 242,
      "2020-01-22": 242,
      "2020-01-23": 246
    },
    "adjTempo": 69.9,
    "adjTempoHistory": {
      "2020-01-19": 70.2,
      "2020-01-20": 70.2,
      "2020-01-21": 70,
      "2020-01-22": 70,
      "2020-01-23": 69.9
    },
    "adjTempoRank": 98,
    "adjTempoRankHistory": {
      "2020-01-19": 94,
      "2020-01-20": 94,
      "2020-01-21": 97,
      "2020-01-22": 98,
      "2020-01-23": 98
    },
    "conference": "MAAC",
    "rank": 265,
    "rankHistory": {
      "2020-01-19": 261,
      "2020-01-20": 261,
      "2020-01-21": 263,
      "2020-01-22": 264,
      "2020-01-23": 265
    },
    "record": "4-9"
  },
  "Iowa": {
    "adjDef": 96.5,
    "adjDefHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.8,
      "2020-01-22": 95.5,
      "2020-01-23": 96.5
    },
    "adjDefRank": 85,
    "adjDefRankHistory": {
      "2020-01-19": 81,
      "2020-01-20": 81,
      "2020-01-21": 79,
      "2020-01-22": 78,
      "2020-01-23": 85
    },
    "adjEM": 20.95,
    "adjEmHistory": {
      "2020-01-19": 21.06,
      "2020-01-20": 21.06,
      "2020-01-21": 21.1,
      "2020-01-22": 21.02,
      "2020-01-23": 20.95
    },
    "adjOff": 117.5,
    "adjOffHistory": {
      "2020-01-19": 116.7,
      "2020-01-20": 116.7,
      "2020-01-21": 116.9,
      "2020-01-22": 116.6,
      "2020-01-23": 117.5
    },
    "adjOffRank": 3,
    "adjOffRankHistory": {
      "2020-01-19": 2,
      "2020-01-20": 2,
      "2020-01-21": 3,
      "2020-01-22": 3,
      "2020-01-23": 3
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.1,
      "2020-01-20": 70.1,
      "2020-01-21": 69.9,
      "2020-01-22": 69.9,
      "2020-01-23": 70
    },
    "adjTempoRank": 95,
    "adjTempoRankHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 102,
      "2020-01-22": 104,
      "2020-01-23": 95
    },
    "conference": "B10",
    "rank": 14,
    "rankHistory": {
      "2020-01-19": 15,
      "2020-01-20": 15,
      "2020-01-21": 15,
      "2020-01-22": 14,
      "2020-01-23": 14
    },
    "record": "14-5"
  },
  "Iowa St.": {
    "adjDef": 98.9,
    "adjDefHistory": {
      "2020-01-19": 98.1,
      "2020-01-20": 98.1,
      "2020-01-21": 98.3,
      "2020-01-22": 98.8,
      "2020-01-23": 98.9
    },
    "adjDefRank": 126,
    "adjDefRankHistory": {
      "2020-01-19": 110,
      "2020-01-20": 110,
      "2020-01-21": 113,
      "2020-01-22": 125,
      "2020-01-23": 126
    },
    "adjEM": 11.43,
    "adjEmHistory": {
      "2020-01-19": 11.28,
      "2020-01-20": 11.28,
      "2020-01-21": 11.33,
      "2020-01-22": 11.52,
      "2020-01-23": 11.43
    },
    "adjOff": 110.4,
    "adjOffHistory": {
      "2020-01-19": 109.4,
      "2020-01-20": 109.4,
      "2020-01-21": 109.6,
      "2020-01-22": 110.3,
      "2020-01-23": 110.4
    },
    "adjOffRank": 33,
    "adjOffRankHistory": {
      "2020-01-19": 39,
      "2020-01-20": 39,
      "2020-01-21": 38,
      "2020-01-22": 35,
      "2020-01-23": 33
    },
    "adjTempo": 70.2,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.8,
      "2020-01-22": 70.2,
      "2020-01-23": 70.2
    },
    "adjTempoRank": 86,
    "adjTempoRankHistory": {
      "2020-01-19": 111,
      "2020-01-20": 111,
      "2020-01-21": 109,
      "2020-01-22": 90,
      "2020-01-23": 86
    },
    "conference": "B12",
    "rank": 66,
    "rankHistory": {
      "2020-01-19": 63,
      "2020-01-20": 63,
      "2020-01-21": 62,
      "2020-01-22": 64,
      "2020-01-23": 66
    },
    "record": "9-9"
  },
  "Jackson St.": {
    "adjDef": 106.5,
    "adjDefHistory": {
      "2020-01-19": 106.3,
      "2020-01-20": 106.3,
      "2020-01-21": 106.4,
      "2020-01-22": 106.3,
      "2020-01-23": 106.5
    },
    "adjDefRank": 278,
    "adjDefRankHistory": {
      "2020-01-19": 277,
      "2020-01-20": 277,
      "2020-01-21": 276,
      "2020-01-22": 277,
      "2020-01-23": 278
    },
    "adjEM": -15.95,
    "adjEmHistory": {
      "2020-01-19": -15.19,
      "2020-01-20": -15.19,
      "2020-01-21": -15.73,
      "2020-01-22": -15.76,
      "2020-01-23": -15.95
    },
    "adjOff": 90.5,
    "adjOffHistory": {
      "2020-01-19": 91.1,
      "2020-01-20": 91.1,
      "2020-01-21": 90.7,
      "2020-01-22": 90.6,
      "2020-01-23": 90.5
    },
    "adjOffRank": 338,
    "adjOffRankHistory": {
      "2020-01-19": 334,
      "2020-01-20": 334,
      "2020-01-21": 338,
      "2020-01-22": 338,
      "2020-01-23": 338
    },
    "adjTempo": 68.9,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.9,
      "2020-01-22": 68.9,
      "2020-01-23": 68.9
    },
    "adjTempoRank": 153,
    "adjTempoRankHistory": {
      "2020-01-19": 168,
      "2020-01-20": 168,
      "2020-01-21": 151,
      "2020-01-22": 152,
      "2020-01-23": 153
    },
    "conference": "SWAC",
    "rank": 333,
    "rankHistory": {
      "2020-01-19": 331,
      "2020-01-20": 331,
      "2020-01-21": 332,
      "2020-01-22": 332,
      "2020-01-23": 333
    },
    "record": "5-14"
  },
  "Jacksonville": {
    "adjDef": 97.8,
    "adjDefHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.8,
      "2020-01-22": 97.7,
      "2020-01-23": 97.8
    },
    "adjDefRank": 102,
    "adjDefRankHistory": {
      "2020-01-19": 103,
      "2020-01-20": 103,
      "2020-01-21": 99,
      "2020-01-22": 102,
      "2020-01-23": 102
    },
    "adjEM": -6.2,
    "adjEmHistory": {
      "2020-01-19": -6.32,
      "2020-01-20": -6.32,
      "2020-01-21": -6.15,
      "2020-01-22": -6.16,
      "2020-01-23": -6.2
    },
    "adjOff": 91.6,
    "adjOffHistory": {
      "2020-01-19": 91.5,
      "2020-01-20": 91.5,
      "2020-01-21": 91.7,
      "2020-01-22": 91.6,
      "2020-01-23": 91.6
    },
    "adjOffRank": 330,
    "adjOffRankHistory": {
      "2020-01-19": 329,
      "2020-01-20": 330,
      "2020-01-21": 329,
      "2020-01-22": 329,
      "2020-01-23": 330
    },
    "adjTempo": 65.5,
    "adjTempoHistory": {
      "2020-01-19": 65.8,
      "2020-01-20": 65.9,
      "2020-01-21": 65.6,
      "2020-01-22": 65.6,
      "2020-01-23": 65.5
    },
    "adjTempoRank": 297,
    "adjTempoRankHistory": {
      "2020-01-19": 294,
      "2020-01-20": 294,
      "2020-01-21": 298,
      "2020-01-22": 297,
      "2020-01-23": 297
    },
    "conference": "ASun",
    "rank": 239,
    "rankHistory": {
      "2020-01-19": 240,
      "2020-01-20": 240,
      "2020-01-21": 240,
      "2020-01-22": 241,
      "2020-01-23": 239
    },
    "record": "8-12"
  },
  "Jacksonville St.": {
    "adjDef": 101.8,
    "adjDefHistory": {
      "2020-01-19": 101.6,
      "2020-01-20": 101.6,
      "2020-01-21": 101.8,
      "2020-01-22": 101.7,
      "2020-01-23": 101.8
    },
    "adjDefRank": 169,
    "adjDefRankHistory": {
      "2020-01-19": 166,
      "2020-01-20": 166,
      "2020-01-21": 168,
      "2020-01-22": 169,
      "2020-01-23": 169
    },
    "adjEM": -6.69,
    "adjEmHistory": {
      "2020-01-19": -6.42,
      "2020-01-20": -6.42,
      "2020-01-21": -6.47,
      "2020-01-22": -6.51,
      "2020-01-23": -6.69
    },
    "adjOff": 95.1,
    "adjOffHistory": {
      "2020-01-19": 95.2,
      "2020-01-20": 95.2,
      "2020-01-21": 95.3,
      "2020-01-22": 95.2,
      "2020-01-23": 95.1
    },
    "adjOffRank": 297,
    "adjOffRankHistory": {
      "2020-01-19": 293,
      "2020-01-20": 293,
      "2020-01-21": 295,
      "2020-01-22": 295,
      "2020-01-23": 297
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.5,
      "2020-01-22": 68.5,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 169,
    "adjTempoRankHistory": {
      "2020-01-19": 173,
      "2020-01-20": 173,
      "2020-01-21": 172,
      "2020-01-22": 173,
      "2020-01-23": 169
    },
    "conference": "OVC",
    "rank": 247,
    "rankHistory": {
      "2020-01-19": 244,
      "2020-01-20": 244,
      "2020-01-21": 243,
      "2020-01-22": 243,
      "2020-01-23": 247
    },
    "record": "8-11"
  },
  "James Madison": {
    "adjDef": 108,
    "adjDefHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 107.9,
      "2020-01-22": 107.8,
      "2020-01-23": 108
    },
    "adjDefRank": 303,
    "adjDefRankHistory": {
      "2020-01-19": 301,
      "2020-01-20": 301,
      "2020-01-21": 303,
      "2020-01-22": 304,
      "2020-01-23": 303
    },
    "adjEM": -8.78,
    "adjEmHistory": {
      "2020-01-19": -8.62,
      "2020-01-20": -8.62,
      "2020-01-21": -8.74,
      "2020-01-22": -8.77,
      "2020-01-23": -8.78
    },
    "adjOff": 99.2,
    "adjOffHistory": {
      "2020-01-19": 99.1,
      "2020-01-20": 99.1,
      "2020-01-21": 99.2,
      "2020-01-22": 99.1,
      "2020-01-23": 99.2
    },
    "adjOffRank": 214,
    "adjOffRankHistory": {
      "2020-01-19": 214,
      "2020-01-20": 213,
      "2020-01-21": 214,
      "2020-01-22": 214,
      "2020-01-23": 214
    },
    "adjTempo": 74.7,
    "adjTempoHistory": {
      "2020-01-19": 74.9,
      "2020-01-20": 74.9,
      "2020-01-21": 74.7,
      "2020-01-22": 74.7,
      "2020-01-23": 74.7
    },
    "adjTempoRank": 8,
    "adjTempoRankHistory": {
      "2020-01-19": 8,
      "2020-01-20": 8,
      "2020-01-21": 8,
      "2020-01-22": 8,
      "2020-01-23": 8
    },
    "conference": "CAA",
    "rank": 271,
    "rankHistory": {
      "2020-01-19": 272,
      "2020-01-20": 272,
      "2020-01-21": 274,
      "2020-01-22": 274,
      "2020-01-23": 271
    },
    "record": "8-10"
  },
  "Kansas": {
    "adjDef": 83.8,
    "adjDefHistory": {
      "2020-01-19": 83.7,
      "2020-01-20": 83.7,
      "2020-01-21": 83.7,
      "2020-01-22": 83.9,
      "2020-01-23": 83.8
    },
    "adjDefRank": 1,
    "adjDefRankHistory": {
      "2020-01-19": 1,
      "2020-01-20": 1,
      "2020-01-21": 1,
      "2020-01-22": 2,
      "2020-01-23": 1
    },
    "adjEM": 30.27,
    "adjEmHistory": {
      "2020-01-19": 29.68,
      "2020-01-20": 29.68,
      "2020-01-21": 29.73,
      "2020-01-22": 30.11,
      "2020-01-23": 30.27
    },
    "adjOff": 114.1,
    "adjOffHistory": {
      "2020-01-19": 113.4,
      "2020-01-20": 113.4,
      "2020-01-21": 113.4,
      "2020-01-22": 114,
      "2020-01-23": 114.1
    },
    "adjOffRank": 11,
    "adjOffRankHistory": {
      "2020-01-19": 11,
      "2020-01-20": 11,
      "2020-01-21": 11,
      "2020-01-22": 10,
      "2020-01-23": 11
    },
    "adjTempo": 67.2,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.2,
      "2020-01-22": 67.2,
      "2020-01-23": 67.2
    },
    "adjTempoRank": 237,
    "adjTempoRankHistory": {
      "2020-01-19": 236,
      "2020-01-20": 237,
      "2020-01-21": 237,
      "2020-01-22": 237,
      "2020-01-23": 237
    },
    "conference": "B12",
    "rank": 1,
    "rankHistory": {
      "2020-01-19": 1,
      "2020-01-20": 1,
      "2020-01-21": 1,
      "2020-01-22": 1,
      "2020-01-23": 1
    },
    "record": "15-3"
  },
  "Kansas St.": {
    "adjDef": 94.1,
    "adjDefHistory": {
      "2020-01-19": 93.4,
      "2020-01-20": 93.4,
      "2020-01-21": 93.5,
      "2020-01-22": 94,
      "2020-01-23": 94.1
    },
    "adjDefRank": 58,
    "adjDefRankHistory": {
      "2020-01-19": 51,
      "2020-01-20": 51,
      "2020-01-21": 49,
      "2020-01-22": 59,
      "2020-01-23": 58
    },
    "adjEM": 8.09,
    "adjEmHistory": {
      "2020-01-19": 8.29,
      "2020-01-20": 8.29,
      "2020-01-21": 8.27,
      "2020-01-22": 8.08,
      "2020-01-23": 8.09
    },
    "adjOff": 102.2,
    "adjOffHistory": {
      "2020-01-19": 101.7,
      "2020-01-20": 101.7,
      "2020-01-21": 101.8,
      "2020-01-22": 102.1,
      "2020-01-23": 102.2
    },
    "adjOffRank": 171,
    "adjOffRankHistory": {
      "2020-01-19": 174,
      "2020-01-20": 174,
      "2020-01-21": 173,
      "2020-01-22": 170,
      "2020-01-23": 171
    },
    "adjTempo": 66.9,
    "adjTempoHistory": {
      "2020-01-19": 67.1,
      "2020-01-20": 67.1,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 66.9
    },
    "adjTempoRank": 252,
    "adjTempoRankHistory": {
      "2020-01-19": 248,
      "2020-01-20": 248,
      "2020-01-21": 252,
      "2020-01-22": 250,
      "2020-01-23": 252
    },
    "conference": "B12",
    "rank": 91,
    "rankHistory": {
      "2020-01-19": 88,
      "2020-01-20": 88,
      "2020-01-21": 88,
      "2020-01-22": 89,
      "2020-01-23": 91
    },
    "record": "8-10"
  },
  "Kennesaw St.": {
    "adjDef": 108.8,
    "adjDefHistory": {
      "2020-01-19": 108.7,
      "2020-01-20": 108.7,
      "2020-01-21": 108.8,
      "2020-01-22": 108.7,
      "2020-01-23": 108.8
    },
    "adjDefRank": 311,
    "adjDefRankHistory": {
      "2020-01-19": 314,
      "2020-01-20": 314,
      "2020-01-21": 313,
      "2020-01-22": 313,
      "2020-01-23": 311
    },
    "adjEM": -23.26,
    "adjEmHistory": {
      "2020-01-19": -23.11,
      "2020-01-20": -23.11,
      "2020-01-21": -23.18,
      "2020-01-22": -23.26,
      "2020-01-23": -23.26
    },
    "adjOff": 85.5,
    "adjOffHistory": {
      "2020-01-19": 85.5,
      "2020-01-20": 85.5,
      "2020-01-21": 85.6,
      "2020-01-22": 85.5,
      "2020-01-23": 85.5
    },
    "adjOffRank": 351,
    "adjOffRankHistory": {
      "2020-01-19": 351,
      "2020-01-20": 351,
      "2020-01-21": 351,
      "2020-01-22": 351,
      "2020-01-23": 351
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.5,
      "2020-01-22": 68.5,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 173,
    "adjTempoRankHistory": {
      "2020-01-19": 172,
      "2020-01-20": 172,
      "2020-01-21": 174,
      "2020-01-22": 174,
      "2020-01-23": 173
    },
    "conference": "ASun",
    "rank": 345,
    "rankHistory": {
      "2020-01-19": 346,
      "2020-01-20": 346,
      "2020-01-21": 345,
      "2020-01-22": 345,
      "2020-01-23": 345
    },
    "record": "1-17"
  },
  "Kent St.": {
    "adjDef": 100.8,
    "adjDefHistory": {
      "2020-01-19": 99.3,
      "2020-01-20": 99.3,
      "2020-01-21": 99.4,
      "2020-01-22": 100.6,
      "2020-01-23": 100.8
    },
    "adjDefRank": 152,
    "adjDefRankHistory": {
      "2020-01-19": 134,
      "2020-01-20": 134,
      "2020-01-21": 134,
      "2020-01-22": 152,
      "2020-01-23": 152
    },
    "adjEM": 4.71,
    "adjEmHistory": {
      "2020-01-19": 6.24,
      "2020-01-20": 6.24,
      "2020-01-21": 6.28,
      "2020-01-22": 4.81,
      "2020-01-23": 4.71
    },
    "adjOff": 105.5,
    "adjOffHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.4,
      "2020-01-23": 105.5
    },
    "adjOffRank": 88,
    "adjOffRankHistory": {
      "2020-01-19": 85,
      "2020-01-20": 85,
      "2020-01-21": 82,
      "2020-01-22": 84,
      "2020-01-23": 88
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.5,
      "2020-01-20": 69.5,
      "2020-01-21": 69.4,
      "2020-01-22": 69.4,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 122,
    "adjTempoRankHistory": {
      "2020-01-19": 125,
      "2020-01-20": 125,
      "2020-01-21": 125,
      "2020-01-22": 122,
      "2020-01-23": 122
    },
    "conference": "MAC",
    "rank": 117,
    "rankHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 106,
      "2020-01-22": 119,
      "2020-01-23": 117
    },
    "record": "13-6"
  },
  "Kentucky": {
    "adjDef": 92.6,
    "adjDefHistory": {
      "2020-01-19": 91.7,
      "2020-01-20": 91.7,
      "2020-01-21": 91.8,
      "2020-01-22": 92.4,
      "2020-01-23": 92.6
    },
    "adjDefRank": 37,
    "adjDefRankHistory": {
      "2020-01-19": 30,
      "2020-01-20": 30,
      "2020-01-21": 31,
      "2020-01-22": 35,
      "2020-01-23": 37
    },
    "adjEM": 18.02,
    "adjEmHistory": {
      "2020-01-19": 18.38,
      "2020-01-20": 18.38,
      "2020-01-21": 18.38,
      "2020-01-22": 18.26,
      "2020-01-23": 18.02
    },
    "adjOff": 110.6,
    "adjOffHistory": {
      "2020-01-19": 110,
      "2020-01-20": 110,
      "2020-01-21": 110.2,
      "2020-01-22": 110.7,
      "2020-01-23": 110.6
    },
    "adjOffRank": 32,
    "adjOffRankHistory": {
      "2020-01-19": 35,
      "2020-01-20": 35,
      "2020-01-21": 34,
      "2020-01-22": 31,
      "2020-01-23": 32
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 68,
      "2020-01-22": 68.2,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 193,
    "adjTempoRankHistory": {
      "2020-01-19": 201,
      "2020-01-20": 201,
      "2020-01-21": 201,
      "2020-01-22": 193,
      "2020-01-23": 193
    },
    "conference": "SEC",
    "rank": 23,
    "rankHistory": {
      "2020-01-19": 21,
      "2020-01-20": 21,
      "2020-01-21": 21,
      "2020-01-22": 21,
      "2020-01-23": 23
    },
    "record": "14-4"
  },
  "LIU": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 105.8,
      "2020-01-22": 105.7,
      "2020-01-23": 105.7
    },
    "adjDefRank": 259,
    "adjDefRankHistory": {
      "2020-01-19": 241,
      "2020-01-20": 242,
      "2020-01-21": 263,
      "2020-01-22": 264,
      "2020-01-23": 259
    },
    "adjEM": -6.6,
    "adjEmHistory": {
      "2020-01-19": -5.83,
      "2020-01-20": -5.83,
      "2020-01-21": -6.69,
      "2020-01-22": -6.74,
      "2020-01-23": -6.6
    },
    "adjOff": 99.1,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 99.1,
      "2020-01-22": 99,
      "2020-01-23": 99.1
    },
    "adjOffRank": 217,
    "adjOffRankHistory": {
      "2020-01-19": 218,
      "2020-01-20": 217,
      "2020-01-21": 216,
      "2020-01-22": 218,
      "2020-01-23": 217
    },
    "adjTempo": 72.3,
    "adjTempoHistory": {
      "2020-01-19": 73.2,
      "2020-01-20": 73.2,
      "2020-01-21": 72.3,
      "2020-01-22": 72.3,
      "2020-01-23": 72.3
    },
    "adjTempoRank": 26,
    "adjTempoRankHistory": {
      "2020-01-19": 20,
      "2020-01-20": 20,
      "2020-01-21": 27,
      "2020-01-22": 26,
      "2020-01-23": 26
    },
    "conference": "NEC",
    "rank": 245,
    "rankHistory": {
      "2020-01-19": 235,
      "2020-01-20": 235,
      "2020-01-21": 246,
      "2020-01-22": 245,
      "2020-01-23": 245
    },
    "record": "9-10"
  },
  "LSU": {
    "adjDef": 99.5,
    "adjDefHistory": {
      "2020-01-19": 98.8,
      "2020-01-20": 98.8,
      "2020-01-21": 98.9,
      "2020-01-22": 99.5,
      "2020-01-23": 99.5
    },
    "adjDefRank": 136,
    "adjDefRankHistory": {
      "2020-01-19": 128,
      "2020-01-20": 128,
      "2020-01-21": 130,
      "2020-01-22": 136,
      "2020-01-23": 136
    },
    "adjEM": 16.66,
    "adjEmHistory": {
      "2020-01-19": 16.54,
      "2020-01-20": 16.54,
      "2020-01-21": 16.54,
      "2020-01-22": 16.56,
      "2020-01-23": 16.66
    },
    "adjOff": 116.2,
    "adjOffHistory": {
      "2020-01-19": 115.3,
      "2020-01-20": 115.3,
      "2020-01-21": 115.5,
      "2020-01-22": 116,
      "2020-01-23": 116.2
    },
    "adjOffRank": 4,
    "adjOffRankHistory": {
      "2020-01-19": 4,
      "2020-01-20": 5,
      "2020-01-21": 5,
      "2020-01-22": 4,
      "2020-01-23": 4
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.5,
      "2020-01-20": 70.5,
      "2020-01-21": 70.3,
      "2020-01-22": 70.5,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 74,
    "adjTempoRankHistory": {
      "2020-01-19": 88,
      "2020-01-20": 88,
      "2020-01-21": 87,
      "2020-01-22": 74,
      "2020-01-23": 74
    },
    "conference": "SEC",
    "rank": 36,
    "rankHistory": {
      "2020-01-19": 33,
      "2020-01-20": 33,
      "2020-01-21": 34,
      "2020-01-22": 34,
      "2020-01-23": 36
    },
    "record": "14-4"
  },
  "La Salle": {
    "adjDef": 97.3,
    "adjDefHistory": {
      "2020-01-19": 97.6,
      "2020-01-20": 97.6,
      "2020-01-21": 97.7,
      "2020-01-22": 97.6,
      "2020-01-23": 97.3
    },
    "adjDefRank": 91,
    "adjDefRankHistory": {
      "2020-01-19": 99,
      "2020-01-20": 97,
      "2020-01-21": 97,
      "2020-01-22": 96,
      "2020-01-23": 91
    },
    "adjEM": -0.32,
    "adjEmHistory": {
      "2020-01-19": -0.1,
      "2020-01-20": -0.1,
      "2020-01-21": -0.11,
      "2020-01-22": -0.05,
      "2020-01-23": -0.32
    },
    "adjOff": 97,
    "adjOffHistory": {
      "2020-01-19": 97.5,
      "2020-01-20": 97.5,
      "2020-01-21": 97.6,
      "2020-01-22": 97.5,
      "2020-01-23": 97
    },
    "adjOffRank": 264,
    "adjOffRankHistory": {
      "2020-01-19": 247,
      "2020-01-20": 247,
      "2020-01-21": 247,
      "2020-01-22": 248,
      "2020-01-23": 264
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 69.7,
      "2020-01-20": 69.7,
      "2020-01-21": 69.6,
      "2020-01-22": 69.6,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 109,
    "adjTempoRankHistory": {
      "2020-01-19": 119,
      "2020-01-20": 119,
      "2020-01-21": 117,
      "2020-01-22": 118,
      "2020-01-23": 109
    },
    "conference": "A10",
    "rank": 172,
    "rankHistory": {
      "2020-01-19": 170,
      "2020-01-20": 170,
      "2020-01-21": 171,
      "2020-01-22": 169,
      "2020-01-23": 172
    },
    "record": "10-8"
  },
  "Lafayette": {
    "adjDef": 107.3,
    "adjDefHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.7,
      "2020-01-22": 104.6,
      "2020-01-23": 107.3
    },
    "adjDefRank": 294,
    "adjDefRankHistory": {
      "2020-01-19": 237,
      "2020-01-20": 237,
      "2020-01-21": 238,
      "2020-01-22": 238,
      "2020-01-23": 294
    },
    "adjEM": -3.88,
    "adjEmHistory": {
      "2020-01-19": -1.32,
      "2020-01-20": -1.32,
      "2020-01-21": -1.41,
      "2020-01-22": -1.39,
      "2020-01-23": -3.88
    },
    "adjOff": 103.4,
    "adjOffHistory": {
      "2020-01-19": 103.1,
      "2020-01-20": 103.1,
      "2020-01-21": 103.3,
      "2020-01-22": 103.2,
      "2020-01-23": 103.4
    },
    "adjOffRank": 142,
    "adjOffRankHistory": {
      "2020-01-19": 147,
      "2020-01-20": 147,
      "2020-01-21": 148,
      "2020-01-22": 146,
      "2020-01-23": 142
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.7,
      "2020-01-20": 66.7,
      "2020-01-21": 66.6,
      "2020-01-22": 66.6,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 267,
    "adjTempoRankHistory": {
      "2020-01-19": 266,
      "2020-01-20": 266,
      "2020-01-21": 266,
      "2020-01-22": 264,
      "2020-01-23": 267
    },
    "conference": "Pat",
    "rank": 207,
    "rankHistory": {
      "2020-01-19": 185,
      "2020-01-20": 185,
      "2020-01-21": 184,
      "2020-01-22": 185,
      "2020-01-23": 207
    },
    "record": "11-7"
  },
  "Lamar": {
    "adjDef": 102.4,
    "adjDefHistory": {
      "2020-01-19": 101.7,
      "2020-01-20": 101.7,
      "2020-01-21": 101.8,
      "2020-01-22": 101.7,
      "2020-01-23": 102.4
    },
    "adjDefRank": 185,
    "adjDefRankHistory": {
      "2020-01-19": 167,
      "2020-01-20": 167,
      "2020-01-21": 169,
      "2020-01-22": 168,
      "2020-01-23": 185
    },
    "adjEM": -8.04,
    "adjEmHistory": {
      "2020-01-19": -8.43,
      "2020-01-20": -8.43,
      "2020-01-21": -8.33,
      "2020-01-22": -8.26,
      "2020-01-23": -8.04
    },
    "adjOff": 94.4,
    "adjOffHistory": {
      "2020-01-19": 93.2,
      "2020-01-20": 93.2,
      "2020-01-21": 93.5,
      "2020-01-22": 93.4,
      "2020-01-23": 94.4
    },
    "adjOffRank": 303,
    "adjOffRankHistory": {
      "2020-01-19": 311,
      "2020-01-20": 311,
      "2020-01-21": 310,
      "2020-01-22": 309,
      "2020-01-23": 303
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 68.9,
      "2020-01-20": 68.9,
      "2020-01-21": 68.8,
      "2020-01-22": 68.7,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 137,
    "adjTempoRankHistory": {
      "2020-01-19": 159,
      "2020-01-20": 159,
      "2020-01-21": 159,
      "2020-01-22": 160,
      "2020-01-23": 137
    },
    "conference": "Slnd",
    "rank": 262,
    "rankHistory": {
      "2020-01-19": 266,
      "2020-01-20": 266,
      "2020-01-21": 264,
      "2020-01-22": 263,
      "2020-01-23": 262
    },
    "record": "10-10"
  },
  "Lehigh": {
    "adjDef": 108.8,
    "adjDefHistory": {
      "2020-01-19": 107.8,
      "2020-01-20": 107.8,
      "2020-01-21": 108,
      "2020-01-22": 107.9,
      "2020-01-23": 108.8
    },
    "adjDefRank": 310,
    "adjDefRankHistory": {
      "2020-01-19": 303,
      "2020-01-20": 303,
      "2020-01-21": 305,
      "2020-01-22": 305,
      "2020-01-23": 310
    },
    "adjEM": -9.58,
    "adjEmHistory": {
      "2020-01-19": -9.16,
      "2020-01-20": -9.17,
      "2020-01-21": -9.39,
      "2020-01-22": -9.42,
      "2020-01-23": -9.58
    },
    "adjOff": 99.2,
    "adjOffHistory": {
      "2020-01-19": 98.6,
      "2020-01-20": 98.6,
      "2020-01-21": 98.6,
      "2020-01-22": 98.5,
      "2020-01-23": 99.2
    },
    "adjOffRank": 215,
    "adjOffRankHistory": {
      "2020-01-19": 227,
      "2020-01-20": 226,
      "2020-01-21": 228,
      "2020-01-22": 227,
      "2020-01-23": 215
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 68.9,
      "2020-01-22": 68.8,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 140,
    "adjTempoRankHistory": {
      "2020-01-19": 154,
      "2020-01-20": 154,
      "2020-01-21": 153,
      "2020-01-22": 154,
      "2020-01-23": 140
    },
    "conference": "Pat",
    "rank": 283,
    "rankHistory": {
      "2020-01-19": 278,
      "2020-01-20": 278,
      "2020-01-21": 282,
      "2020-01-22": 282,
      "2020-01-23": 283
    },
    "record": "5-14"
  },
  "Liberty": {
    "adjDef": 92.4,
    "adjDefHistory": {
      "2020-01-19": 92.2,
      "2020-01-20": 92.2,
      "2020-01-21": 92.4,
      "2020-01-22": 92.3,
      "2020-01-23": 92.4
    },
    "adjDefRank": 36,
    "adjDefRankHistory": {
      "2020-01-19": 36,
      "2020-01-20": 36,
      "2020-01-21": 36,
      "2020-01-22": 34,
      "2020-01-23": 36
    },
    "adjEM": 12.97,
    "adjEmHistory": {
      "2020-01-19": 12.91,
      "2020-01-20": 12.91,
      "2020-01-21": 12.83,
      "2020-01-22": 12.93,
      "2020-01-23": 12.97
    },
    "adjOff": 105.3,
    "adjOffHistory": {
      "2020-01-19": 105.1,
      "2020-01-20": 105.1,
      "2020-01-21": 105.3,
      "2020-01-22": 105.2,
      "2020-01-23": 105.3
    },
    "adjOffRank": 94,
    "adjOffRankHistory": {
      "2020-01-19": 94,
      "2020-01-20": 95,
      "2020-01-21": 93,
      "2020-01-22": 93,
      "2020-01-23": 94
    },
    "adjTempo": 60.9,
    "adjTempoHistory": {
      "2020-01-19": 61.1,
      "2020-01-20": 61.1,
      "2020-01-21": 61,
      "2020-01-22": 61,
      "2020-01-23": 60.9
    },
    "adjTempoRank": 352,
    "adjTempoRankHistory": {
      "2020-01-19": 352,
      "2020-01-20": 352,
      "2020-01-21": 352,
      "2020-01-22": 352,
      "2020-01-23": 352
    },
    "conference": "ASun",
    "rank": 53,
    "rankHistory": {
      "2020-01-19": 54,
      "2020-01-20": 54,
      "2020-01-21": 54,
      "2020-01-22": 55,
      "2020-01-23": 53
    },
    "record": "19-1"
  },
  "Lipscomb": {
    "adjDef": 108,
    "adjDefHistory": {
      "2020-01-19": 107.6,
      "2020-01-20": 107.6,
      "2020-01-21": 107.8,
      "2020-01-22": 107.7,
      "2020-01-23": 108
    },
    "adjDefRank": 302,
    "adjDefRankHistory": {
      "2020-01-19": 298,
      "2020-01-20": 298,
      "2020-01-21": 300,
      "2020-01-22": 301,
      "2020-01-23": 302
    },
    "adjEM": -8.95,
    "adjEmHistory": {
      "2020-01-19": -8.71,
      "2020-01-20": -8.71,
      "2020-01-21": -8.75,
      "2020-01-22": -8.82,
      "2020-01-23": -8.95
    },
    "adjOff": 99,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 99,
      "2020-01-22": 98.9,
      "2020-01-23": 99
    },
    "adjOffRank": 220,
    "adjOffRankHistory": {
      "2020-01-19": 223,
      "2020-01-20": 222,
      "2020-01-21": 221,
      "2020-01-22": 220,
      "2020-01-23": 220
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 138,
    "adjTempoRankHistory": {
      "2020-01-19": 139,
      "2020-01-20": 141,
      "2020-01-21": 142,
      "2020-01-22": 144,
      "2020-01-23": 138
    },
    "conference": "ASun",
    "rank": 276,
    "rankHistory": {
      "2020-01-19": 274,
      "2020-01-20": 274,
      "2020-01-21": 275,
      "2020-01-22": 275,
      "2020-01-23": 276
    },
    "record": "7-12"
  },
  "Little Rock": {
    "adjDef": 96.8,
    "adjDefHistory": {
      "2020-01-19": 96.8,
      "2020-01-20": 96.8,
      "2020-01-21": 96.9,
      "2020-01-22": 96.8,
      "2020-01-23": 96.8
    },
    "adjDefRank": 87,
    "adjDefRankHistory": {
      "2020-01-19": 88,
      "2020-01-20": 88,
      "2020-01-21": 87,
      "2020-01-22": 88,
      "2020-01-23": 87
    },
    "adjEM": 3.38,
    "adjEmHistory": {
      "2020-01-19": 2.94,
      "2020-01-20": 2.95,
      "2020-01-21": 3.07,
      "2020-01-22": 3.11,
      "2020-01-23": 3.38
    },
    "adjOff": 100.2,
    "adjOffHistory": {
      "2020-01-19": 99.7,
      "2020-01-20": 99.7,
      "2020-01-21": 100,
      "2020-01-22": 99.9,
      "2020-01-23": 100.2
    },
    "adjOffRank": 199,
    "adjOffRankHistory": {
      "2020-01-19": 205,
      "2020-01-20": 204,
      "2020-01-21": 201,
      "2020-01-22": 200,
      "2020-01-23": 199
    },
    "adjTempo": 68.8,
    "adjTempoHistory": {
      "2020-01-19": 68.8,
      "2020-01-20": 68.8,
      "2020-01-21": 68.6,
      "2020-01-22": 68.6,
      "2020-01-23": 68.8
    },
    "adjTempoRank": 156,
    "adjTempoRankHistory": {
      "2020-01-19": 164,
      "2020-01-20": 164,
      "2020-01-21": 165,
      "2020-01-22": 165,
      "2020-01-23": 156
    },
    "conference": "SB",
    "rank": 129,
    "rankHistory": {
      "2020-01-19": 133,
      "2020-01-20": 133,
      "2020-01-21": 133,
      "2020-01-22": 132,
      "2020-01-23": 129
    },
    "record": "14-7"
  },
  "Long Beach St.": {
    "adjDef": 104.9,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.7,
      "2020-01-22": 106.5,
      "2020-01-23": 104.9
    },
    "adjDefRank": 242,
    "adjDefRankHistory": {
      "2020-01-19": 281,
      "2020-01-20": 282,
      "2020-01-21": 284,
      "2020-01-22": 281,
      "2020-01-23": 242
    },
    "adjEM": -10.79,
    "adjEmHistory": {
      "2020-01-19": -12.16,
      "2020-01-20": -12.14,
      "2020-01-21": -12.13,
      "2020-01-22": -12.11,
      "2020-01-23": -10.79
    },
    "adjOff": 94.1,
    "adjOffHistory": {
      "2020-01-19": 94.3,
      "2020-01-20": 94.4,
      "2020-01-21": 94.5,
      "2020-01-22": 94.4,
      "2020-01-23": 94.1
    },
    "adjOffRank": 307,
    "adjOffRankHistory": {
      "2020-01-19": 303,
      "2020-01-20": 302,
      "2020-01-21": 303,
      "2020-01-22": 303,
      "2020-01-23": 307
    },
    "adjTempo": 71.7,
    "adjTempoHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 71.9,
      "2020-01-22": 71.9,
      "2020-01-23": 71.7
    },
    "adjTempoRank": 35,
    "adjTempoRankHistory": {
      "2020-01-19": 33,
      "2020-01-20": 32,
      "2020-01-21": 32,
      "2020-01-22": 32,
      "2020-01-23": 35
    },
    "conference": "BW",
    "rank": 293,
    "rankHistory": {
      "2020-01-19": 306,
      "2020-01-20": 306,
      "2020-01-21": 306,
      "2020-01-22": 306,
      "2020-01-23": 293
    },
    "record": "7-14"
  },
  "Longwood": {
    "adjDef": 106.1,
    "adjDefHistory": {
      "2020-01-19": 106.3,
      "2020-01-20": 106.3,
      "2020-01-21": 106.1,
      "2020-01-22": 105.9,
      "2020-01-23": 106.1
    },
    "adjDefRank": 270,
    "adjDefRankHistory": {
      "2020-01-19": 275,
      "2020-01-20": 276,
      "2020-01-21": 273,
      "2020-01-22": 269,
      "2020-01-23": 270
    },
    "adjEM": -15.13,
    "adjEmHistory": {
      "2020-01-19": -14.6,
      "2020-01-20": -14.6,
      "2020-01-21": -15.03,
      "2020-01-22": -14.98,
      "2020-01-23": -15.13
    },
    "adjOff": 90.9,
    "adjOffHistory": {
      "2020-01-19": 91.7,
      "2020-01-20": 91.7,
      "2020-01-21": 91.1,
      "2020-01-22": 90.9,
      "2020-01-23": 90.9
    },
    "adjOffRank": 337,
    "adjOffRankHistory": {
      "2020-01-19": 327,
      "2020-01-20": 327,
      "2020-01-21": 335,
      "2020-01-22": 336,
      "2020-01-23": 337
    },
    "adjTempo": 69.6,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.7,
      "2020-01-22": 69.7,
      "2020-01-23": 69.6
    },
    "adjTempoRank": 114,
    "adjTempoRankHistory": {
      "2020-01-19": 114,
      "2020-01-20": 115,
      "2020-01-21": 115,
      "2020-01-22": 113,
      "2020-01-23": 114
    },
    "conference": "BSth",
    "rank": 328,
    "rankHistory": {
      "2020-01-19": 328,
      "2020-01-20": 328,
      "2020-01-21": 329,
      "2020-01-22": 329,
      "2020-01-23": 328
    },
    "record": "6-14"
  },
  "Louisiana": {
    "adjDef": 106,
    "adjDefHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 106,
      "2020-01-22": 105.8,
      "2020-01-23": 106
    },
    "adjDefRank": 265,
    "adjDefRankHistory": {
      "2020-01-19": 270,
      "2020-01-20": 270,
      "2020-01-21": 267,
      "2020-01-22": 265,
      "2020-01-23": 265
    },
    "adjEM": -8.63,
    "adjEmHistory": {
      "2020-01-19": -8.62,
      "2020-01-20": -8.62,
      "2020-01-21": -8.59,
      "2020-01-22": -8.53,
      "2020-01-23": -8.63
    },
    "adjOff": 97.3,
    "adjOffHistory": {
      "2020-01-19": 97.2,
      "2020-01-20": 97.2,
      "2020-01-21": 97.4,
      "2020-01-22": 97.3,
      "2020-01-23": 97.3
    },
    "adjOffRank": 257,
    "adjOffRankHistory": {
      "2020-01-19": 253,
      "2020-01-20": 253,
      "2020-01-21": 254,
      "2020-01-22": 256,
      "2020-01-23": 257
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.6,
      "2020-01-22": 70.6,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 70,
    "adjTempoRankHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 73,
      "2020-01-22": 71,
      "2020-01-23": 70
    },
    "conference": "SB",
    "rank": 270,
    "rankHistory": {
      "2020-01-19": 271,
      "2020-01-20": 271,
      "2020-01-21": 271,
      "2020-01-22": 268,
      "2020-01-23": 270
    },
    "record": "7-13"
  },
  "Louisiana Monroe": {
    "adjDef": 105.6,
    "adjDefHistory": {
      "2020-01-19": 105.4,
      "2020-01-20": 105.5,
      "2020-01-21": 105.6,
      "2020-01-22": 105.4,
      "2020-01-23": 105.6
    },
    "adjDefRank": 255,
    "adjDefRankHistory": {
      "2020-01-19": 258,
      "2020-01-20": 258,
      "2020-01-21": 256,
      "2020-01-22": 255,
      "2020-01-23": 255
    },
    "adjEM": -8.16,
    "adjEmHistory": {
      "2020-01-19": -8.25,
      "2020-01-20": -8.25,
      "2020-01-21": -8.17,
      "2020-01-22": -8.18,
      "2020-01-23": -8.16
    },
    "adjOff": 97.4,
    "adjOffHistory": {
      "2020-01-19": 97.2,
      "2020-01-20": 97.2,
      "2020-01-21": 97.4,
      "2020-01-22": 97.3,
      "2020-01-23": 97.4
    },
    "adjOffRank": 255,
    "adjOffRankHistory": {
      "2020-01-19": 254,
      "2020-01-20": 254,
      "2020-01-21": 253,
      "2020-01-22": 255,
      "2020-01-23": 255
    },
    "adjTempo": 64,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.1,
      "2020-01-22": 64.1,
      "2020-01-23": 64
    },
    "adjTempoRank": 337,
    "adjTempoRankHistory": {
      "2020-01-19": 335,
      "2020-01-20": 334,
      "2020-01-21": 336,
      "2020-01-22": 337,
      "2020-01-23": 337
    },
    "conference": "SB",
    "rank": 264,
    "rankHistory": {
      "2020-01-19": 263,
      "2020-01-20": 263,
      "2020-01-21": 261,
      "2020-01-22": 261,
      "2020-01-23": 264
    },
    "record": "6-12"
  },
  "Louisiana Tech": {
    "adjDef": 95.5,
    "adjDefHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.4,
      "2020-01-22": 95.6,
      "2020-01-23": 95.5
    },
    "adjDefRank": 76,
    "adjDefRankHistory": {
      "2020-01-19": 80,
      "2020-01-20": 80,
      "2020-01-21": 72,
      "2020-01-22": 79,
      "2020-01-23": 76
    },
    "adjEM": 11.45,
    "adjEmHistory": {
      "2020-01-19": 11.26,
      "2020-01-20": 11.26,
      "2020-01-21": 11.3,
      "2020-01-22": 11.3,
      "2020-01-23": 11.45
    },
    "adjOff": 106.9,
    "adjOffHistory": {
      "2020-01-19": 106.8,
      "2020-01-20": 106.8,
      "2020-01-21": 106.7,
      "2020-01-22": 106.9,
      "2020-01-23": 106.9
    },
    "adjOffRank": 66,
    "adjOffRankHistory": {
      "2020-01-19": 63,
      "2020-01-20": 63,
      "2020-01-21": 67,
      "2020-01-22": 65,
      "2020-01-23": 66
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.4,
      "2020-01-20": 67.4,
      "2020-01-21": 67.6,
      "2020-01-22": 67.3,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 234,
    "adjTempoRankHistory": {
      "2020-01-19": 232,
      "2020-01-20": 232,
      "2020-01-21": 222,
      "2020-01-22": 233,
      "2020-01-23": 234
    },
    "conference": "CUSA",
    "rank": 65,
    "rankHistory": {
      "2020-01-19": 64,
      "2020-01-20": 64,
      "2020-01-21": 63,
      "2020-01-22": 66,
      "2020-01-23": 65
    },
    "record": "13-5"
  },
  "Louisville": {
    "adjDef": 89.1,
    "adjDefHistory": {
      "2020-01-19": 88.1,
      "2020-01-20": 88.1,
      "2020-01-21": 88.2,
      "2020-01-22": 88.2,
      "2020-01-23": 89.1
    },
    "adjDefRank": 16,
    "adjDefRankHistory": {
      "2020-01-19": 12,
      "2020-01-20": 12,
      "2020-01-21": 13,
      "2020-01-22": 12,
      "2020-01-23": 16
    },
    "adjEM": 22.65,
    "adjEmHistory": {
      "2020-01-19": 23.93,
      "2020-01-20": 23.93,
      "2020-01-21": 23.86,
      "2020-01-22": 23.77,
      "2020-01-23": 22.65
    },
    "adjOff": 111.8,
    "adjOffHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 112.1,
      "2020-01-22": 112,
      "2020-01-23": 111.8
    },
    "adjOffRank": 23,
    "adjOffRankHistory": {
      "2020-01-19": 21,
      "2020-01-20": 21,
      "2020-01-21": 21,
      "2020-01-22": 21,
      "2020-01-23": 23
    },
    "adjTempo": 66.3,
    "adjTempoHistory": {
      "2020-01-19": 66.6,
      "2020-01-20": 66.6,
      "2020-01-21": 66.5,
      "2020-01-22": 66.4,
      "2020-01-23": 66.3
    },
    "adjTempoRank": 276,
    "adjTempoRankHistory": {
      "2020-01-19": 269,
      "2020-01-20": 269,
      "2020-01-21": 270,
      "2020-01-22": 270,
      "2020-01-23": 276
    },
    "conference": "ACC",
    "rank": 9,
    "rankHistory": {
      "2020-01-19": 6,
      "2020-01-20": 6,
      "2020-01-21": 7,
      "2020-01-22": 7,
      "2020-01-23": 9
    },
    "record": "16-3"
  },
  "Loyola Chicago": {
    "adjDef": 94.1,
    "adjDefHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.3,
      "2020-01-22": 94.3,
      "2020-01-23": 94.1
    },
    "adjDefRank": 60,
    "adjDefRankHistory": {
      "2020-01-19": 63,
      "2020-01-20": 63,
      "2020-01-21": 63,
      "2020-01-22": 64,
      "2020-01-23": 60
    },
    "adjEM": 9.03,
    "adjEmHistory": {
      "2020-01-19": 7.78,
      "2020-01-20": 7.78,
      "2020-01-21": 7.79,
      "2020-01-22": 7.77,
      "2020-01-23": 9.03
    },
    "adjOff": 103.1,
    "adjOffHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.1,
      "2020-01-22": 102,
      "2020-01-23": 103.1
    },
    "adjOffRank": 149,
    "adjOffRankHistory": {
      "2020-01-19": 169,
      "2020-01-20": 170,
      "2020-01-21": 169,
      "2020-01-22": 172,
      "2020-01-23": 149
    },
    "adjTempo": 65.5,
    "adjTempoHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 65.8,
      "2020-01-22": 65.8,
      "2020-01-23": 65.5
    },
    "adjTempoRank": 298,
    "adjTempoRankHistory": {
      "2020-01-19": 287,
      "2020-01-20": 288,
      "2020-01-21": 288,
      "2020-01-22": 288,
      "2020-01-23": 298
    },
    "conference": "MVC",
    "rank": 86,
    "rankHistory": {
      "2020-01-19": 90,
      "2020-01-20": 90,
      "2020-01-21": 90,
      "2020-01-22": 90,
      "2020-01-23": 86
    },
    "record": "14-6"
  },
  "Loyola MD": {
    "adjDef": 108.2,
    "adjDefHistory": {
      "2020-01-19": 107.6,
      "2020-01-20": 107.6,
      "2020-01-21": 107.7,
      "2020-01-22": 107.7,
      "2020-01-23": 108.2
    },
    "adjDefRank": 305,
    "adjDefRankHistory": {
      "2020-01-19": 297,
      "2020-01-20": 297,
      "2020-01-21": 299,
      "2020-01-22": 300,
      "2020-01-23": 305
    },
    "adjEM": -8.95,
    "adjEmHistory": {
      "2020-01-19": -8.68,
      "2020-01-20": -8.68,
      "2020-01-21": -8.65,
      "2020-01-22": -8.67,
      "2020-01-23": -8.95
    },
    "adjOff": 99.2,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 99.1,
      "2020-01-22": 99,
      "2020-01-23": 99.2
    },
    "adjOffRank": 213,
    "adjOffRankHistory": {
      "2020-01-19": 219,
      "2020-01-20": 218,
      "2020-01-21": 218,
      "2020-01-22": 217,
      "2020-01-23": 213
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.1,
      "2020-01-20": 69.1,
      "2020-01-21": 69,
      "2020-01-22": 68.9,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 139,
    "adjTempoRankHistory": {
      "2020-01-19": 151,
      "2020-01-20": 151,
      "2020-01-21": 150,
      "2020-01-22": 151,
      "2020-01-23": 139
    },
    "conference": "Pat",
    "rank": 275,
    "rankHistory": {
      "2020-01-19": 273,
      "2020-01-20": 273,
      "2020-01-21": 272,
      "2020-01-22": 272,
      "2020-01-23": 275
    },
    "record": "9-11"
  },
  "Loyola Marymount": {
    "adjDef": 104.3,
    "adjDefHistory": {
      "2020-01-19": 104.1,
      "2020-01-20": 104.1,
      "2020-01-21": 104.3,
      "2020-01-22": 104.3,
      "2020-01-23": 104.3
    },
    "adjDefRank": 233,
    "adjDefRankHistory": {
      "2020-01-19": 227,
      "2020-01-20": 228,
      "2020-01-21": 231,
      "2020-01-22": 233,
      "2020-01-23": 233
    },
    "adjEM": -4.56,
    "adjEmHistory": {
      "2020-01-19": -4.5,
      "2020-01-20": -4.5,
      "2020-01-21": -4.49,
      "2020-01-22": -4.52,
      "2020-01-23": -4.56
    },
    "adjOff": 99.7,
    "adjOffHistory": {
      "2020-01-19": 99.6,
      "2020-01-20": 99.6,
      "2020-01-21": 99.8,
      "2020-01-22": 99.7,
      "2020-01-23": 99.7
    },
    "adjOffRank": 205,
    "adjOffRankHistory": {
      "2020-01-19": 207,
      "2020-01-20": 206,
      "2020-01-21": 206,
      "2020-01-22": 206,
      "2020-01-23": 205
    },
    "adjTempo": 63,
    "adjTempoHistory": {
      "2020-01-19": 63.3,
      "2020-01-20": 63.3,
      "2020-01-21": 63.1,
      "2020-01-22": 63,
      "2020-01-23": 63
    },
    "adjTempoRank": 346,
    "adjTempoRankHistory": {
      "2020-01-19": 345,
      "2020-01-20": 345,
      "2020-01-21": 345,
      "2020-01-22": 345,
      "2020-01-23": 346
    },
    "conference": "WCC",
    "rank": 219,
    "rankHistory": {
      "2020-01-19": 217,
      "2020-01-20": 217,
      "2020-01-21": 219,
      "2020-01-22": 216,
      "2020-01-23": 219
    },
    "record": "7-12"
  },
  "Maine": {
    "adjDef": 109.1,
    "adjDefHistory": {
      "2020-01-19": 109.9,
      "2020-01-20": 109.9,
      "2020-01-21": 110.1,
      "2020-01-22": 110,
      "2020-01-23": 109.1
    },
    "adjDefRank": 317,
    "adjDefRankHistory": {
      "2020-01-19": 327,
      "2020-01-20": 327,
      "2020-01-21": 328,
      "2020-01-22": 328,
      "2020-01-23": 317
    },
    "adjEM": -16.58,
    "adjEmHistory": {
      "2020-01-19": -17.57,
      "2020-01-20": -17.57,
      "2020-01-21": -17.55,
      "2020-01-22": -17.57,
      "2020-01-23": -16.58
    },
    "adjOff": 92.5,
    "adjOffHistory": {
      "2020-01-19": 92.4,
      "2020-01-20": 92.4,
      "2020-01-21": 92.5,
      "2020-01-22": 92.4,
      "2020-01-23": 92.5
    },
    "adjOffRank": 318,
    "adjOffRankHistory": {
      "2020-01-19": 321,
      "2020-01-20": 321,
      "2020-01-21": 320,
      "2020-01-22": 320,
      "2020-01-23": 318
    },
    "adjTempo": 65.5,
    "adjTempoHistory": {
      "2020-01-19": 65.7,
      "2020-01-20": 65.7,
      "2020-01-21": 65.6,
      "2020-01-22": 65.6,
      "2020-01-23": 65.5
    },
    "adjTempoRank": 299,
    "adjTempoRankHistory": {
      "2020-01-19": 297,
      "2020-01-20": 297,
      "2020-01-21": 297,
      "2020-01-22": 298,
      "2020-01-23": 299
    },
    "conference": "AE",
    "rank": 336,
    "rankHistory": {
      "2020-01-19": 337,
      "2020-01-20": 337,
      "2020-01-21": 336,
      "2020-01-22": 335,
      "2020-01-23": 336
    },
    "record": "6-14"
  },
  "Manhattan": {
    "adjDef": 98.8,
    "adjDefHistory": {
      "2020-01-19": 98.5,
      "2020-01-20": 98.5,
      "2020-01-21": 98.6,
      "2020-01-22": 98.5,
      "2020-01-23": 98.8
    },
    "adjDefRank": 123,
    "adjDefRankHistory": {
      "2020-01-19": 118,
      "2020-01-20": 118,
      "2020-01-21": 120,
      "2020-01-22": 117,
      "2020-01-23": 123
    },
    "adjEM": -6.47,
    "adjEmHistory": {
      "2020-01-19": -5.98,
      "2020-01-20": -5.98,
      "2020-01-21": -6,
      "2020-01-22": -6.02,
      "2020-01-23": -6.47
    },
    "adjOff": 92.4,
    "adjOffHistory": {
      "2020-01-19": 92.5,
      "2020-01-20": 92.5,
      "2020-01-21": 92.6,
      "2020-01-22": 92.4,
      "2020-01-23": 92.4
    },
    "adjOffRank": 322,
    "adjOffRankHistory": {
      "2020-01-19": 319,
      "2020-01-20": 319,
      "2020-01-21": 318,
      "2020-01-22": 319,
      "2020-01-23": 322
    },
    "adjTempo": 66.7,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 66.2,
      "2020-01-22": 66.2,
      "2020-01-23": 66.7
    },
    "adjTempoRank": 259,
    "adjTempoRankHistory": {
      "2020-01-19": 278,
      "2020-01-20": 278,
      "2020-01-21": 276,
      "2020-01-22": 277,
      "2020-01-23": 259
    },
    "conference": "MAAC",
    "rank": 242,
    "rankHistory": {
      "2020-01-19": 237,
      "2020-01-20": 237,
      "2020-01-21": 238,
      "2020-01-22": 239,
      "2020-01-23": 242
    },
    "record": "8-8"
  },
  "Marist": {
    "adjDef": 105.5,
    "adjDefHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.6,
      "2020-01-22": 105.5,
      "2020-01-23": 105.5
    },
    "adjDefRank": 252,
    "adjDefRankHistory": {
      "2020-01-19": 260,
      "2020-01-20": 260,
      "2020-01-21": 257,
      "2020-01-22": 257,
      "2020-01-23": 252
    },
    "adjEM": -17.28,
    "adjEmHistory": {
      "2020-01-19": -17.78,
      "2020-01-20": -17.78,
      "2020-01-21": -17.81,
      "2020-01-22": -17.83,
      "2020-01-23": -17.28
    },
    "adjOff": 88.2,
    "adjOffHistory": {
      "2020-01-19": 87.7,
      "2020-01-20": 87.7,
      "2020-01-21": 87.8,
      "2020-01-22": 87.7,
      "2020-01-23": 88.2
    },
    "adjOffRank": 347,
    "adjOffRankHistory": {
      "2020-01-19": 347,
      "2020-01-20": 347,
      "2020-01-21": 348,
      "2020-01-22": 348,
      "2020-01-23": 347
    },
    "adjTempo": 65,
    "adjTempoHistory": {
      "2020-01-19": 64.7,
      "2020-01-20": 64.7,
      "2020-01-21": 64.5,
      "2020-01-22": 64.5,
      "2020-01-23": 65
    },
    "adjTempoRank": 314,
    "adjTempoRankHistory": {
      "2020-01-19": 325,
      "2020-01-20": 325,
      "2020-01-21": 327,
      "2020-01-22": 327,
      "2020-01-23": 314
    },
    "conference": "MAAC",
    "rank": 337,
    "rankHistory": {
      "2020-01-19": 338,
      "2020-01-20": 338,
      "2020-01-21": 338,
      "2020-01-22": 338,
      "2020-01-23": 337
    },
    "record": "4-13"
  },
  "Marquette": {
    "adjDef": 94.1,
    "adjDefHistory": {
      "2020-01-19": 94.1,
      "2020-01-20": 94.1,
      "2020-01-21": 94.3,
      "2020-01-22": 94.1,
      "2020-01-23": 94.1
    },
    "adjDefRank": 61,
    "adjDefRankHistory": {
      "2020-01-19": 59,
      "2020-01-20": 59,
      "2020-01-21": 62,
      "2020-01-22": 60,
      "2020-01-23": 61
    },
    "adjEM": 17.17,
    "adjEmHistory": {
      "2020-01-19": 16.69,
      "2020-01-20": 16.69,
      "2020-01-21": 16.71,
      "2020-01-22": 17.07,
      "2020-01-23": 17.17
    },
    "adjOff": 111.3,
    "adjOffHistory": {
      "2020-01-19": 110.8,
      "2020-01-20": 110.8,
      "2020-01-21": 111,
      "2020-01-22": 111.1,
      "2020-01-23": 111.3
    },
    "adjOffRank": 26,
    "adjOffRankHistory": {
      "2020-01-19": 27,
      "2020-01-20": 27,
      "2020-01-21": 27,
      "2020-01-22": 25,
      "2020-01-23": 26
    },
    "adjTempo": 71.5,
    "adjTempoHistory": {
      "2020-01-19": 71.9,
      "2020-01-20": 71.9,
      "2020-01-21": 71.8,
      "2020-01-22": 71.5,
      "2020-01-23": 71.5
    },
    "adjTempoRank": 40,
    "adjTempoRankHistory": {
      "2020-01-19": 36,
      "2020-01-20": 36,
      "2020-01-21": 35,
      "2020-01-22": 40,
      "2020-01-23": 40
    },
    "conference": "BE",
    "rank": 32,
    "rankHistory": {
      "2020-01-19": 32,
      "2020-01-20": 32,
      "2020-01-21": 32,
      "2020-01-22": 32,
      "2020-01-23": 32
    },
    "record": "14-5"
  },
  "Marshall": {
    "adjDef": 98.8,
    "adjDefHistory": {
      "2020-01-19": 99.5,
      "2020-01-20": 99.5,
      "2020-01-21": 99.5,
      "2020-01-22": 99.3,
      "2020-01-23": 98.8
    },
    "adjDefRank": 124,
    "adjDefRankHistory": {
      "2020-01-19": 138,
      "2020-01-20": 137,
      "2020-01-21": 135,
      "2020-01-22": 133,
      "2020-01-23": 124
    },
    "adjEM": 0.8,
    "adjEmHistory": {
      "2020-01-19": 1.05,
      "2020-01-20": 1.05,
      "2020-01-21": 1.14,
      "2020-01-22": 1.26,
      "2020-01-23": 0.8
    },
    "adjOff": 99.6,
    "adjOffHistory": {
      "2020-01-19": 100.5,
      "2020-01-20": 100.6,
      "2020-01-21": 100.6,
      "2020-01-22": 100.5,
      "2020-01-23": 99.6
    },
    "adjOffRank": 209,
    "adjOffRankHistory": {
      "2020-01-19": 192,
      "2020-01-20": 192,
      "2020-01-21": 192,
      "2020-01-22": 192,
      "2020-01-23": 209
    },
    "adjTempo": 73.6,
    "adjTempoHistory": {
      "2020-01-19": 74.2,
      "2020-01-20": 74.2,
      "2020-01-21": 74.1,
      "2020-01-22": 74.1,
      "2020-01-23": 73.6
    },
    "adjTempoRank": 16,
    "adjTempoRankHistory": {
      "2020-01-19": 12,
      "2020-01-20": 12,
      "2020-01-21": 12,
      "2020-01-22": 12,
      "2020-01-23": 16
    },
    "conference": "CUSA",
    "rank": 156,
    "rankHistory": {
      "2020-01-19": 152,
      "2020-01-20": 152,
      "2020-01-21": 152,
      "2020-01-22": 153,
      "2020-01-23": 156
    },
    "record": "9-11"
  },
  "Maryland": {
    "adjDef": 86.9,
    "adjDefHistory": {
      "2020-01-19": 86.6,
      "2020-01-20": 86.6,
      "2020-01-21": 86.7,
      "2020-01-22": 87.1,
      "2020-01-23": 86.9
    },
    "adjDefRank": 5,
    "adjDefRankHistory": {
      "2020-01-19": 5,
      "2020-01-20": 5,
      "2020-01-21": 5,
      "2020-01-22": 6,
      "2020-01-23": 5
    },
    "adjEM": 22.59,
    "adjEmHistory": {
      "2020-01-19": 22.06,
      "2020-01-20": 22.06,
      "2020-01-21": 22.08,
      "2020-01-22": 22.48,
      "2020-01-23": 22.59
    },
    "adjOff": 109.5,
    "adjOffHistory": {
      "2020-01-19": 108.6,
      "2020-01-20": 108.6,
      "2020-01-21": 108.8,
      "2020-01-22": 109.6,
      "2020-01-23": 109.5
    },
    "adjOffRank": 40,
    "adjOffRankHistory": {
      "2020-01-19": 44,
      "2020-01-20": 44,
      "2020-01-21": 45,
      "2020-01-22": 39,
      "2020-01-23": 40
    },
    "adjTempo": 67.7,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 67.9,
      "2020-01-22": 67.7,
      "2020-01-23": 67.7
    },
    "adjTempoRank": 215,
    "adjTempoRankHistory": {
      "2020-01-19": 207,
      "2020-01-20": 208,
      "2020-01-21": 209,
      "2020-01-22": 216,
      "2020-01-23": 215
    },
    "conference": "B10",
    "rank": 10,
    "rankHistory": {
      "2020-01-19": 12,
      "2020-01-20": 12,
      "2020-01-21": 12,
      "2020-01-22": 10,
      "2020-01-23": 10
    },
    "record": "15-4"
  },
  "Maryland Eastern Shore": {
    "adjDef": 110,
    "adjDefHistory": {
      "2020-01-19": 110.5,
      "2020-01-20": 110.5,
      "2020-01-21": 110,
      "2020-01-22": 109.8,
      "2020-01-23": 110
    },
    "adjDefRank": 327,
    "adjDefRankHistory": {
      "2020-01-19": 332,
      "2020-01-20": 332,
      "2020-01-21": 325,
      "2020-01-22": 325,
      "2020-01-23": 327
    },
    "adjEM": -28.05,
    "adjEmHistory": {
      "2020-01-19": -27.8,
      "2020-01-20": -27.8,
      "2020-01-21": -27.97,
      "2020-01-22": -27.99,
      "2020-01-23": -28.05
    },
    "adjOff": 82,
    "adjOffHistory": {
      "2020-01-19": 82.7,
      "2020-01-20": 82.7,
      "2020-01-21": 82,
      "2020-01-22": 81.9,
      "2020-01-23": 82
    },
    "adjOffRank": 352,
    "adjOffRankHistory": {
      "2020-01-19": 352,
      "2020-01-20": 352,
      "2020-01-21": 352,
      "2020-01-22": 352,
      "2020-01-23": 352
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.4,
      "2020-01-22": 68.4,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 180,
    "adjTempoRankHistory": {
      "2020-01-19": 185,
      "2020-01-20": 185,
      "2020-01-21": 180,
      "2020-01-22": 179,
      "2020-01-23": 180
    },
    "conference": "MEAC",
    "rank": 351,
    "rankHistory": {
      "2020-01-19": 351,
      "2020-01-20": 351,
      "2020-01-21": 351,
      "2020-01-22": 351,
      "2020-01-23": 351
    },
    "record": "2-18"
  },
  "Massachusetts": {
    "adjDef": 104.3,
    "adjDefHistory": {
      "2020-01-19": 104.3,
      "2020-01-20": 104.3,
      "2020-01-21": 104.4,
      "2020-01-22": 104.3,
      "2020-01-23": 104.3
    },
    "adjDefRank": 232,
    "adjDefRankHistory": {
      "2020-01-19": 230,
      "2020-01-20": 231,
      "2020-01-21": 233,
      "2020-01-22": 234,
      "2020-01-23": 232
    },
    "adjEM": -3.67,
    "adjEmHistory": {
      "2020-01-19": -3.3,
      "2020-01-20": -3.3,
      "2020-01-21": -3.38,
      "2020-01-22": -3.31,
      "2020-01-23": -3.67
    },
    "adjOff": 100.6,
    "adjOffHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 101,
      "2020-01-22": 101,
      "2020-01-23": 100.6
    },
    "adjOffRank": 192,
    "adjOffRankHistory": {
      "2020-01-19": 186,
      "2020-01-20": 186,
      "2020-01-21": 185,
      "2020-01-22": 184,
      "2020-01-23": 192
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.9,
      "2020-01-22": 69.9,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 101,
    "adjTempoRankHistory": {
      "2020-01-19": 106,
      "2020-01-20": 106,
      "2020-01-21": 105,
      "2020-01-22": 106,
      "2020-01-23": 101
    },
    "conference": "A10",
    "rank": 204,
    "rankHistory": {
      "2020-01-19": 202,
      "2020-01-20": 202,
      "2020-01-21": 202,
      "2020-01-22": 202,
      "2020-01-23": 204
    },
    "record": "7-12"
  },
  "McNeese St.": {
    "adjDef": 108.9,
    "adjDefHistory": {
      "2020-01-19": 110.1,
      "2020-01-20": 110.1,
      "2020-01-21": 110.2,
      "2020-01-22": 110.1,
      "2020-01-23": 108.9
    },
    "adjDefRank": 313,
    "adjDefRankHistory": {
      "2020-01-19": 328,
      "2020-01-20": 328,
      "2020-01-21": 329,
      "2020-01-22": 329,
      "2020-01-23": 313
    },
    "adjEM": -6.49,
    "adjEmHistory": {
      "2020-01-19": -7.86,
      "2020-01-20": -7.86,
      "2020-01-21": -7.9,
      "2020-01-22": -7.82,
      "2020-01-23": -6.49
    },
    "adjOff": 102.4,
    "adjOffHistory": {
      "2020-01-19": 102.2,
      "2020-01-20": 102.2,
      "2020-01-21": 102.3,
      "2020-01-22": 102.3,
      "2020-01-23": 102.4
    },
    "adjOffRank": 164,
    "adjOffRankHistory": {
      "2020-01-19": 165,
      "2020-01-20": 165,
      "2020-01-21": 165,
      "2020-01-22": 165,
      "2020-01-23": 164
    },
    "adjTempo": 69,
    "adjTempoHistory": {
      "2020-01-19": 69.1,
      "2020-01-20": 69.1,
      "2020-01-21": 68.9,
      "2020-01-22": 68.9,
      "2020-01-23": 69
    },
    "adjTempoRank": 148,
    "adjTempoRankHistory": {
      "2020-01-19": 152,
      "2020-01-20": 152,
      "2020-01-21": 152,
      "2020-01-22": 153,
      "2020-01-23": 148
    },
    "conference": "Slnd",
    "rank": 243,
    "rankHistory": {
      "2020-01-19": 258,
      "2020-01-20": 258,
      "2020-01-21": 260,
      "2020-01-22": 258,
      "2020-01-23": 243
    },
    "record": "10-9"
  },
  "Memphis": {
    "adjDef": 89,
    "adjDefHistory": {
      "2020-01-19": 88.3,
      "2020-01-20": 88.3,
      "2020-01-21": 88.4,
      "2020-01-22": 88.3,
      "2020-01-23": 89
    },
    "adjDefRank": 15,
    "adjDefRankHistory": {
      "2020-01-19": 14,
      "2020-01-20": 14,
      "2020-01-21": 14,
      "2020-01-22": 13,
      "2020-01-23": 15
    },
    "adjEM": 12.71,
    "adjEmHistory": {
      "2020-01-19": 15.84,
      "2020-01-20": 15.84,
      "2020-01-21": 15.95,
      "2020-01-22": 15.97,
      "2020-01-23": 12.71
    },
    "adjOff": 101.8,
    "adjOffHistory": {
      "2020-01-19": 104.1,
      "2020-01-20": 104.1,
      "2020-01-21": 104.3,
      "2020-01-22": 104.2,
      "2020-01-23": 101.8
    },
    "adjOffRank": 174,
    "adjOffRankHistory": {
      "2020-01-19": 123,
      "2020-01-20": 123,
      "2020-01-21": 122,
      "2020-01-22": 120,
      "2020-01-23": 174
    },
    "adjTempo": 73.9,
    "adjTempoHistory": {
      "2020-01-19": 73.9,
      "2020-01-20": 73.9,
      "2020-01-21": 73.7,
      "2020-01-22": 73.7,
      "2020-01-23": 73.9
    },
    "adjTempoRank": 13,
    "adjTempoRankHistory": {
      "2020-01-19": 15,
      "2020-01-20": 15,
      "2020-01-21": 16,
      "2020-01-22": 15,
      "2020-01-23": 13
    },
    "conference": "Amer",
    "rank": 54,
    "rankHistory": {
      "2020-01-19": 39,
      "2020-01-20": 39,
      "2020-01-21": 38,
      "2020-01-22": 39,
      "2020-01-23": 54
    },
    "record": "14-4"
  },
  "Mercer": {
    "adjDef": 105.3,
    "adjDefHistory": {
      "2020-01-19": 105.1,
      "2020-01-20": 105.1,
      "2020-01-21": 105.2,
      "2020-01-22": 105.1,
      "2020-01-23": 105.3
    },
    "adjDefRank": 249,
    "adjDefRankHistory": {
      "2020-01-19": 253,
      "2020-01-20": 253,
      "2020-01-21": 252,
      "2020-01-22": 251,
      "2020-01-23": 249
    },
    "adjEM": -6.39,
    "adjEmHistory": {
      "2020-01-19": -6.87,
      "2020-01-20": -6.87,
      "2020-01-21": -6.9,
      "2020-01-22": -6.95,
      "2020-01-23": -6.39
    },
    "adjOff": 99,
    "adjOffHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.3,
      "2020-01-22": 98.2,
      "2020-01-23": 99
    },
    "adjOffRank": 223,
    "adjOffRankHistory": {
      "2020-01-19": 234,
      "2020-01-20": 234,
      "2020-01-21": 234,
      "2020-01-22": 233,
      "2020-01-23": 223
    },
    "adjTempo": 68.8,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 69.1,
      "2020-01-22": 69,
      "2020-01-23": 68.8
    },
    "adjTempoRank": 154,
    "adjTempoRankHistory": {
      "2020-01-19": 148,
      "2020-01-20": 148,
      "2020-01-21": 148,
      "2020-01-22": 148,
      "2020-01-23": 154
    },
    "conference": "SC",
    "rank": 240,
    "rankHistory": {
      "2020-01-19": 250,
      "2020-01-20": 250,
      "2020-01-21": 250,
      "2020-01-22": 251,
      "2020-01-23": 240
    },
    "record": "9-11"
  },
  "Merrimack": {
    "adjDef": 100.6,
    "adjDefHistory": {
      "2020-01-19": 101.7,
      "2020-01-20": 101.7,
      "2020-01-21": 100.8,
      "2020-01-22": 100.6,
      "2020-01-23": 100.6
    },
    "adjDefRank": 148,
    "adjDefRankHistory": {
      "2020-01-19": 169,
      "2020-01-20": 169,
      "2020-01-21": 152,
      "2020-01-22": 151,
      "2020-01-23": 148
    },
    "adjEM": -5.91,
    "adjEmHistory": {
      "2020-01-19": -7.96,
      "2020-01-20": -7.96,
      "2020-01-21": -5.9,
      "2020-01-22": -5.86,
      "2020-01-23": -5.91
    },
    "adjOff": 94.7,
    "adjOffHistory": {
      "2020-01-19": 93.8,
      "2020-01-20": 93.8,
      "2020-01-21": 94.9,
      "2020-01-22": 94.7,
      "2020-01-23": 94.7
    },
    "adjOffRank": 301,
    "adjOffRankHistory": {
      "2020-01-19": 308,
      "2020-01-20": 308,
      "2020-01-21": 300,
      "2020-01-22": 300,
      "2020-01-23": 301
    },
    "adjTempo": 63.4,
    "adjTempoHistory": {
      "2020-01-19": 63.4,
      "2020-01-20": 63.4,
      "2020-01-21": 63.4,
      "2020-01-22": 63.4,
      "2020-01-23": 63.4
    },
    "adjTempoRank": 343,
    "adjTempoRankHistory": {
      "2020-01-19": 344,
      "2020-01-20": 344,
      "2020-01-21": 343,
      "2020-01-22": 343,
      "2020-01-23": 343
    },
    "conference": "NEC",
    "rank": 236,
    "rankHistory": {
      "2020-01-19": 259,
      "2020-01-20": 259,
      "2020-01-21": 236,
      "2020-01-22": 235,
      "2020-01-23": 236
    },
    "record": "12-8"
  },
  "Miami FL": {
    "adjDef": 103.5,
    "adjDefHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.6,
      "2020-01-22": 103.5,
      "2020-01-23": 103.5
    },
    "adjDefRank": 215,
    "adjDefRankHistory": {
      "2020-01-19": 213,
      "2020-01-20": 214,
      "2020-01-21": 215,
      "2020-01-22": 215,
      "2020-01-23": 215
    },
    "adjEM": 6.7,
    "adjEmHistory": {
      "2020-01-19": 7.33,
      "2020-01-20": 7.32,
      "2020-01-21": 7.33,
      "2020-01-22": 6.91,
      "2020-01-23": 6.7
    },
    "adjOff": 110.2,
    "adjOffHistory": {
      "2020-01-19": 110.7,
      "2020-01-20": 110.8,
      "2020-01-21": 110.9,
      "2020-01-22": 110.4,
      "2020-01-23": 110.2
    },
    "adjOffRank": 35,
    "adjOffRankHistory": {
      "2020-01-19": 28,
      "2020-01-20": 28,
      "2020-01-21": 28,
      "2020-01-22": 33,
      "2020-01-23": 35
    },
    "adjTempo": 67.9,
    "adjTempoHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 67.9
    },
    "adjTempoRank": 207,
    "adjTempoRankHistory": {
      "2020-01-19": 209,
      "2020-01-20": 209,
      "2020-01-21": 208,
      "2020-01-22": 207,
      "2020-01-23": 207
    },
    "conference": "ACC",
    "rank": 102,
    "rankHistory": {
      "2020-01-19": 97,
      "2020-01-20": 97,
      "2020-01-21": 97,
      "2020-01-22": 98,
      "2020-01-23": 102
    },
    "record": "10-8"
  },
  "Miami OH": {
    "adjDef": 108.5,
    "adjDefHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 107.9,
      "2020-01-22": 108.3,
      "2020-01-23": 108.5
    },
    "adjDefRank": 309,
    "adjDefRankHistory": {
      "2020-01-19": 300,
      "2020-01-20": 300,
      "2020-01-21": 302,
      "2020-01-22": 310,
      "2020-01-23": 309
    },
    "adjEM": -5.52,
    "adjEmHistory": {
      "2020-01-19": -4.19,
      "2020-01-20": -4.19,
      "2020-01-21": -4.2,
      "2020-01-22": -5.38,
      "2020-01-23": -5.52
    },
    "adjOff": 103,
    "adjOffHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.7,
      "2020-01-22": 102.9,
      "2020-01-23": 103
    },
    "adjOffRank": 152,
    "adjOffRankHistory": {
      "2020-01-19": 134,
      "2020-01-20": 134,
      "2020-01-21": 133,
      "2020-01-22": 150,
      "2020-01-23": 152
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.6,
      "2020-01-20": 68.7,
      "2020-01-21": 68.5,
      "2020-01-22": 68.3,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 185,
    "adjTempoRankHistory": {
      "2020-01-19": 174,
      "2020-01-20": 174,
      "2020-01-21": 171,
      "2020-01-22": 185,
      "2020-01-23": 185
    },
    "conference": "MAC",
    "rank": 231,
    "rankHistory": {
      "2020-01-19": 214,
      "2020-01-20": 214,
      "2020-01-21": 214,
      "2020-01-22": 229,
      "2020-01-23": 231
    },
    "record": "8-11"
  },
  "Michigan": {
    "adjDef": 93.3,
    "adjDefHistory": {
      "2020-01-19": 93.7,
      "2020-01-20": 93.7,
      "2020-01-21": 93.8,
      "2020-01-22": 93.6,
      "2020-01-23": 93.3
    },
    "adjDefRank": 47,
    "adjDefRankHistory": {
      "2020-01-19": 54,
      "2020-01-20": 54,
      "2020-01-21": 52,
      "2020-01-22": 52,
      "2020-01-23": 47
    },
    "adjEM": 17.47,
    "adjEmHistory": {
      "2020-01-19": 18.58,
      "2020-01-20": 18.58,
      "2020-01-21": 18.6,
      "2020-01-22": 18.57,
      "2020-01-23": 17.47
    },
    "adjOff": 110.8,
    "adjOffHistory": {
      "2020-01-19": 112.2,
      "2020-01-20": 112.2,
      "2020-01-21": 112.4,
      "2020-01-22": 112.2,
      "2020-01-23": 110.8
    },
    "adjOffRank": 31,
    "adjOffRankHistory": {
      "2020-01-19": 18,
      "2020-01-20": 18,
      "2020-01-21": 18,
      "2020-01-22": 19,
      "2020-01-23": 31
    },
    "adjTempo": 68.7,
    "adjTempoHistory": {
      "2020-01-19": 68.8,
      "2020-01-20": 68.8,
      "2020-01-21": 68.6,
      "2020-01-22": 68.6,
      "2020-01-23": 68.7
    },
    "adjTempoRank": 159,
    "adjTempoRankHistory": {
      "2020-01-19": 162,
      "2020-01-20": 163,
      "2020-01-21": 166,
      "2020-01-22": 166,
      "2020-01-23": 159
    },
    "conference": "B10",
    "rank": 29,
    "rankHistory": {
      "2020-01-19": 20,
      "2020-01-20": 20,
      "2020-01-21": 20,
      "2020-01-22": 18,
      "2020-01-23": 29
    },
    "record": "11-7"
  },
  "Michigan St.": {
    "adjDef": 91,
    "adjDefHistory": {
      "2020-01-19": 90.9,
      "2020-01-20": 91,
      "2020-01-21": 91.2,
      "2020-01-22": 90.8,
      "2020-01-23": 91
    },
    "adjDefRank": 25,
    "adjDefRankHistory": {
      "2020-01-19": 27,
      "2020-01-20": 27,
      "2020-01-21": 26,
      "2020-01-22": 26,
      "2020-01-23": 25
    },
    "adjEM": 23.12,
    "adjEmHistory": {
      "2020-01-19": 23.38,
      "2020-01-20": 23.4,
      "2020-01-21": 23.37,
      "2020-01-22": 23.3,
      "2020-01-23": 23.12
    },
    "adjOff": 114.1,
    "adjOffHistory": {
      "2020-01-19": 114.3,
      "2020-01-20": 114.4,
      "2020-01-21": 114.5,
      "2020-01-22": 114.1,
      "2020-01-23": 114.1
    },
    "adjOffRank": 10,
    "adjOffRankHistory": {
      "2020-01-19": 7,
      "2020-01-20": 7,
      "2020-01-21": 7,
      "2020-01-22": 8,
      "2020-01-23": 10
    },
    "adjTempo": 70.4,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.5,
      "2020-01-22": 70.4,
      "2020-01-23": 70.4
    },
    "adjTempoRank": 79,
    "adjTempoRankHistory": {
      "2020-01-19": 79,
      "2020-01-20": 78,
      "2020-01-21": 78,
      "2020-01-22": 81,
      "2020-01-23": 79
    },
    "conference": "B10",
    "rank": 7,
    "rankHistory": {
      "2020-01-19": 7,
      "2020-01-20": 7,
      "2020-01-21": 8,
      "2020-01-22": 8,
      "2020-01-23": 7
    },
    "record": "14-4"
  },
  "Middle Tennessee": {
    "adjDef": 108.4,
    "adjDefHistory": {
      "2020-01-19": 108.1,
      "2020-01-20": 108.1,
      "2020-01-21": 108.3,
      "2020-01-22": 108.2,
      "2020-01-23": 108.4
    },
    "adjDefRank": 308,
    "adjDefRankHistory": {
      "2020-01-19": 309,
      "2020-01-20": 309,
      "2020-01-21": 309,
      "2020-01-22": 308,
      "2020-01-23": 308
    },
    "adjEM": -12.09,
    "adjEmHistory": {
      "2020-01-19": -11.83,
      "2020-01-20": -11.84,
      "2020-01-21": -11.9,
      "2020-01-22": -11.98,
      "2020-01-23": -12.09
    },
    "adjOff": 96.3,
    "adjOffHistory": {
      "2020-01-19": 96.3,
      "2020-01-20": 96.3,
      "2020-01-21": 96.4,
      "2020-01-22": 96.2,
      "2020-01-23": 96.3
    },
    "adjOffRank": 279,
    "adjOffRankHistory": {
      "2020-01-19": 273,
      "2020-01-20": 274,
      "2020-01-21": 277,
      "2020-01-22": 278,
      "2020-01-23": 279
    },
    "adjTempo": 70.1,
    "adjTempoHistory": {
      "2020-01-19": 70.3,
      "2020-01-20": 70.3,
      "2020-01-21": 70.1,
      "2020-01-22": 70.1,
      "2020-01-23": 70.1
    },
    "adjTempoRank": 89,
    "adjTempoRankHistory": {
      "2020-01-19": 91,
      "2020-01-20": 91,
      "2020-01-21": 91,
      "2020-01-22": 94,
      "2020-01-23": 89
    },
    "conference": "CUSA",
    "rank": 307,
    "rankHistory": {
      "2020-01-19": 303,
      "2020-01-20": 303,
      "2020-01-21": 304,
      "2020-01-22": 304,
      "2020-01-23": 307
    },
    "record": "4-15"
  },
  "Milwaukee": {
    "adjDef": 104.6,
    "adjDefHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.6,
      "2020-01-22": 104.4,
      "2020-01-23": 104.6
    },
    "adjDefRank": 236,
    "adjDefRankHistory": {
      "2020-01-19": 233,
      "2020-01-20": 233,
      "2020-01-21": 237,
      "2020-01-22": 236,
      "2020-01-23": 236
    },
    "adjEM": -6.88,
    "adjEmHistory": {
      "2020-01-19": -6.75,
      "2020-01-20": -6.75,
      "2020-01-21": -6.83,
      "2020-01-22": -6.86,
      "2020-01-23": -6.88
    },
    "adjOff": 97.7,
    "adjOffHistory": {
      "2020-01-19": 97.6,
      "2020-01-20": 97.6,
      "2020-01-21": 97.7,
      "2020-01-22": 97.5,
      "2020-01-23": 97.7
    },
    "adjOffRank": 248,
    "adjOffRankHistory": {
      "2020-01-19": 245,
      "2020-01-20": 246,
      "2020-01-21": 246,
      "2020-01-22": 247,
      "2020-01-23": 248
    },
    "adjTempo": 69.6,
    "adjTempoHistory": {
      "2020-01-19": 69.9,
      "2020-01-20": 69.9,
      "2020-01-21": 69.7,
      "2020-01-22": 69.7,
      "2020-01-23": 69.6
    },
    "adjTempoRank": 116,
    "adjTempoRankHistory": {
      "2020-01-19": 110,
      "2020-01-20": 110,
      "2020-01-21": 114,
      "2020-01-22": 116,
      "2020-01-23": 116
    },
    "conference": "Horz",
    "rank": 251,
    "rankHistory": {
      "2020-01-19": 249,
      "2020-01-20": 249,
      "2020-01-21": 248,
      "2020-01-22": 249,
      "2020-01-23": 251
    },
    "record": "9-10"
  },
  "Minnesota": {
    "adjDef": 96.3,
    "adjDefHistory": {
      "2020-01-19": 96.1,
      "2020-01-20": 96.1,
      "2020-01-21": 96.2,
      "2020-01-22": 96.1,
      "2020-01-23": 96.3
    },
    "adjDefRank": 82,
    "adjDefRankHistory": {
      "2020-01-19": 84,
      "2020-01-20": 84,
      "2020-01-21": 84,
      "2020-01-22": 84,
      "2020-01-23": 82
    },
    "adjEM": 16.21,
    "adjEmHistory": {
      "2020-01-19": 16.37,
      "2020-01-20": 16.37,
      "2020-01-21": 16.43,
      "2020-01-22": 16.24,
      "2020-01-23": 16.21
    },
    "adjOff": 112.5,
    "adjOffHistory": {
      "2020-01-19": 112.4,
      "2020-01-20": 112.5,
      "2020-01-21": 112.7,
      "2020-01-22": 112.4,
      "2020-01-23": 112.5
    },
    "adjOffRank": 17,
    "adjOffRankHistory": {
      "2020-01-19": 17,
      "2020-01-20": 17,
      "2020-01-21": 17,
      "2020-01-22": 17,
      "2020-01-23": 17
    },
    "adjTempo": 65.7,
    "adjTempoHistory": {
      "2020-01-19": 65.9,
      "2020-01-20": 65.9,
      "2020-01-21": 65.8,
      "2020-01-22": 65.8,
      "2020-01-23": 65.7
    },
    "adjTempoRank": 294,
    "adjTempoRankHistory": {
      "2020-01-19": 293,
      "2020-01-20": 293,
      "2020-01-21": 291,
      "2020-01-22": 290,
      "2020-01-23": 294
    },
    "conference": "B10",
    "rank": 38,
    "rankHistory": {
      "2020-01-19": 36,
      "2020-01-20": 36,
      "2020-01-21": 35,
      "2020-01-22": 37,
      "2020-01-23": 38
    },
    "record": "10-8"
  },
  "Mississippi": {
    "adjDef": 99.3,
    "adjDefHistory": {
      "2020-01-19": 98.6,
      "2020-01-20": 98.6,
      "2020-01-21": 98.8,
      "2020-01-22": 99,
      "2020-01-23": 99.3
    },
    "adjDefRank": 134,
    "adjDefRankHistory": {
      "2020-01-19": 123,
      "2020-01-20": 122,
      "2020-01-21": 124,
      "2020-01-22": 128,
      "2020-01-23": 134
    },
    "adjEM": 3.84,
    "adjEmHistory": {
      "2020-01-19": 5.29,
      "2020-01-20": 5.29,
      "2020-01-21": 5.26,
      "2020-01-22": 4.12,
      "2020-01-23": 3.84
    },
    "adjOff": 103.2,
    "adjOffHistory": {
      "2020-01-19": 103.9,
      "2020-01-20": 103.9,
      "2020-01-21": 104.1,
      "2020-01-22": 103.1,
      "2020-01-23": 103.2
    },
    "adjOffRank": 146,
    "adjOffRankHistory": {
      "2020-01-19": 126,
      "2020-01-20": 126,
      "2020-01-21": 125,
      "2020-01-22": 147,
      "2020-01-23": 146
    },
    "adjTempo": 68.2,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.4,
      "2020-01-22": 68.3,
      "2020-01-23": 68.2
    },
    "adjTempoRank": 190,
    "adjTempoRankHistory": {
      "2020-01-19": 181,
      "2020-01-20": 181,
      "2020-01-21": 182,
      "2020-01-22": 186,
      "2020-01-23": 190
    },
    "conference": "SEC",
    "rank": 127,
    "rankHistory": {
      "2020-01-19": 113,
      "2020-01-20": 113,
      "2020-01-21": 113,
      "2020-01-22": 125,
      "2020-01-23": 127
    },
    "record": "9-9"
  },
  "Mississippi St.": {
    "adjDef": 96.4,
    "adjDefHistory": {
      "2020-01-19": 96.1,
      "2020-01-20": 96.1,
      "2020-01-21": 96.4,
      "2020-01-22": 96.1,
      "2020-01-23": 96.4
    },
    "adjDefRank": 83,
    "adjDefRankHistory": {
      "2020-01-19": 85,
      "2020-01-20": 85,
      "2020-01-21": 85,
      "2020-01-22": 85,
      "2020-01-23": 83
    },
    "adjEM": 15.61,
    "adjEmHistory": {
      "2020-01-19": 15.29,
      "2020-01-20": 15.29,
      "2020-01-21": 15.25,
      "2020-01-22": 15.16,
      "2020-01-23": 15.61
    },
    "adjOff": 112,
    "adjOffHistory": {
      "2020-01-19": 111.4,
      "2020-01-20": 111.4,
      "2020-01-21": 111.6,
      "2020-01-22": 111.3,
      "2020-01-23": 112
    },
    "adjOffRank": 19,
    "adjOffRankHistory": {
      "2020-01-19": 24,
      "2020-01-20": 24,
      "2020-01-21": 23,
      "2020-01-22": 23,
      "2020-01-23": 19
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65,
      "2020-01-20": 65,
      "2020-01-21": 64.9,
      "2020-01-22": 64.8,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 315,
    "adjTempoRankHistory": {
      "2020-01-19": 322,
      "2020-01-20": 322,
      "2020-01-21": 321,
      "2020-01-22": 321,
      "2020-01-23": 315
    },
    "conference": "SEC",
    "rank": 41,
    "rankHistory": {
      "2020-01-19": 41,
      "2020-01-20": 41,
      "2020-01-21": 42,
      "2020-01-22": 42,
      "2020-01-23": 41
    },
    "record": "12-6"
  },
  "Mississippi Valley St.": {
    "adjDef": 115.8,
    "adjDefHistory": {
      "2020-01-19": 115.7,
      "2020-01-20": 115.7,
      "2020-01-21": 115.8,
      "2020-01-22": 115.7,
      "2020-01-23": 115.8
    },
    "adjDefRank": 348,
    "adjDefRankHistory": {
      "2020-01-19": 349,
      "2020-01-20": 349,
      "2020-01-21": 349,
      "2020-01-22": 349,
      "2020-01-23": 348
    },
    "adjEM": -27.9,
    "adjEmHistory": {
      "2020-01-19": -28.07,
      "2020-01-20": -28.07,
      "2020-01-21": -27.79,
      "2020-01-22": -27.87,
      "2020-01-23": -27.9
    },
    "adjOff": 87.9,
    "adjOffHistory": {
      "2020-01-19": 87.6,
      "2020-01-20": 87.6,
      "2020-01-21": 88,
      "2020-01-22": 87.8,
      "2020-01-23": 87.9
    },
    "adjOffRank": 348,
    "adjOffRankHistory": {
      "2020-01-19": 348,
      "2020-01-20": 348,
      "2020-01-21": 347,
      "2020-01-22": 347,
      "2020-01-23": 348
    },
    "adjTempo": 76.2,
    "adjTempoHistory": {
      "2020-01-19": 76.7,
      "2020-01-20": 76.7,
      "2020-01-21": 76.3,
      "2020-01-22": 76.2,
      "2020-01-23": 76.2
    },
    "adjTempoRank": 2,
    "adjTempoRankHistory": {
      "2020-01-19": 2,
      "2020-01-20": 2,
      "2020-01-21": 3,
      "2020-01-22": 2,
      "2020-01-23": 2
    },
    "conference": "SWAC",
    "rank": 350,
    "rankHistory": {
      "2020-01-19": 352,
      "2020-01-20": 352,
      "2020-01-21": 350,
      "2020-01-22": 350,
      "2020-01-23": 350
    },
    "record": "2-16"
  },
  "Missouri": {
    "adjDef": 95.4,
    "adjDefHistory": {
      "2020-01-19": 94.4,
      "2020-01-20": 94.4,
      "2020-01-21": 94.5,
      "2020-01-22": 95.3,
      "2020-01-23": 95.4
    },
    "adjDefRank": 75,
    "adjDefRankHistory": {
      "2020-01-19": 64,
      "2020-01-20": 65,
      "2020-01-21": 65,
      "2020-01-22": 73,
      "2020-01-23": 75
    },
    "adjEM": 9.2,
    "adjEmHistory": {
      "2020-01-19": 10.2,
      "2020-01-20": 10.2,
      "2020-01-21": 10.19,
      "2020-01-22": 9.21,
      "2020-01-23": 9.2
    },
    "adjOff": 104.6,
    "adjOffHistory": {
      "2020-01-19": 104.6,
      "2020-01-20": 104.6,
      "2020-01-21": 104.7,
      "2020-01-22": 104.5,
      "2020-01-23": 104.6
    },
    "adjOffRank": 108,
    "adjOffRankHistory": {
      "2020-01-19": 110,
      "2020-01-20": 110,
      "2020-01-21": 107,
      "2020-01-22": 109,
      "2020-01-23": 108
    },
    "adjTempo": 66.4,
    "adjTempoHistory": {
      "2020-01-19": 66.5,
      "2020-01-20": 66.5,
      "2020-01-21": 66.4,
      "2020-01-22": 66.5,
      "2020-01-23": 66.4
    },
    "adjTempoRank": 272,
    "adjTempoRankHistory": {
      "2020-01-19": 270,
      "2020-01-20": 270,
      "2020-01-21": 271,
      "2020-01-22": 269,
      "2020-01-23": 272
    },
    "conference": "SEC",
    "rank": 85,
    "rankHistory": {
      "2020-01-19": 75,
      "2020-01-20": 75,
      "2020-01-21": 75,
      "2020-01-22": 86,
      "2020-01-23": 85
    },
    "record": "9-9"
  },
  "Missouri St.": {
    "adjDef": 99.8,
    "adjDefHistory": {
      "2020-01-19": 99.6,
      "2020-01-20": 99.6,
      "2020-01-21": 99.7,
      "2020-01-22": 99.6,
      "2020-01-23": 99.8
    },
    "adjDefRank": 139,
    "adjDefRankHistory": {
      "2020-01-19": 139,
      "2020-01-20": 139,
      "2020-01-21": 139,
      "2020-01-22": 137,
      "2020-01-23": 139
    },
    "adjEM": 0.61,
    "adjEmHistory": {
      "2020-01-19": 0.71,
      "2020-01-20": 0.71,
      "2020-01-21": 0.72,
      "2020-01-22": 0.7,
      "2020-01-23": 0.61
    },
    "adjOff": 100.4,
    "adjOffHistory": {
      "2020-01-19": 100.3,
      "2020-01-20": 100.3,
      "2020-01-21": 100.4,
      "2020-01-22": 100.3,
      "2020-01-23": 100.4
    },
    "adjOffRank": 196,
    "adjOffRankHistory": {
      "2020-01-19": 196,
      "2020-01-20": 196,
      "2020-01-21": 195,
      "2020-01-22": 195,
      "2020-01-23": 196
    },
    "adjTempo": 65.3,
    "adjTempoHistory": {
      "2020-01-19": 65.4,
      "2020-01-20": 65.4,
      "2020-01-21": 65.3,
      "2020-01-22": 65.3,
      "2020-01-23": 65.3
    },
    "adjTempoRank": 305,
    "adjTempoRankHistory": {
      "2020-01-19": 311,
      "2020-01-20": 309,
      "2020-01-21": 309,
      "2020-01-22": 307,
      "2020-01-23": 305
    },
    "conference": "MVC",
    "rank": 160,
    "rankHistory": {
      "2020-01-19": 159,
      "2020-01-20": 159,
      "2020-01-21": 159,
      "2020-01-22": 160,
      "2020-01-23": 160
    },
    "record": "9-10"
  },
  "Monmouth": {
    "adjDef": 99.8,
    "adjDefHistory": {
      "2020-01-19": 99.7,
      "2020-01-20": 99.7,
      "2020-01-21": 99.9,
      "2020-01-22": 99.8,
      "2020-01-23": 99.8
    },
    "adjDefRank": 138,
    "adjDefRankHistory": {
      "2020-01-19": 140,
      "2020-01-20": 140,
      "2020-01-21": 141,
      "2020-01-22": 139,
      "2020-01-23": 138
    },
    "adjEM": -3.36,
    "adjEmHistory": {
      "2020-01-19": -3.33,
      "2020-01-20": -3.33,
      "2020-01-21": -3.38,
      "2020-01-22": -3.4,
      "2020-01-23": -3.36
    },
    "adjOff": 96.4,
    "adjOffHistory": {
      "2020-01-19": 96.4,
      "2020-01-20": 96.4,
      "2020-01-21": 96.6,
      "2020-01-22": 96.4,
      "2020-01-23": 96.4
    },
    "adjOffRank": 275,
    "adjOffRankHistory": {
      "2020-01-19": 271,
      "2020-01-20": 271,
      "2020-01-21": 269,
      "2020-01-22": 272,
      "2020-01-23": 275
    },
    "adjTempo": 71.6,
    "adjTempoHistory": {
      "2020-01-19": 71.8,
      "2020-01-20": 71.8,
      "2020-01-21": 71.7,
      "2020-01-22": 71.7,
      "2020-01-23": 71.6
    },
    "adjTempoRank": 38,
    "adjTempoRankHistory": {
      "2020-01-19": 39,
      "2020-01-20": 39,
      "2020-01-21": 38,
      "2020-01-22": 38,
      "2020-01-23": 38
    },
    "conference": "MAAC",
    "rank": 202,
    "rankHistory": {
      "2020-01-19": 203,
      "2020-01-20": 203,
      "2020-01-21": 203,
      "2020-01-22": 203,
      "2020-01-23": 202
    },
    "record": "10-7"
  },
  "Montana": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.7
    },
    "adjDefRank": 97,
    "adjDefRankHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 100,
      "2020-01-22": 99,
      "2020-01-23": 97
    },
    "adjEM": 1.45,
    "adjEmHistory": {
      "2020-01-19": 1.14,
      "2020-01-20": 1.14,
      "2020-01-21": 1.26,
      "2020-01-22": 1.35,
      "2020-01-23": 1.45
    },
    "adjOff": 99.1,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 99.1,
      "2020-01-22": 99,
      "2020-01-23": 99.1
    },
    "adjOffRank": 216,
    "adjOffRankHistory": {
      "2020-01-19": 220,
      "2020-01-20": 219,
      "2020-01-21": 217,
      "2020-01-22": 215,
      "2020-01-23": 216
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.3,
      "2020-01-20": 68.3,
      "2020-01-21": 68.2,
      "2020-01-22": 68.2,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 192,
    "adjTempoRankHistory": {
      "2020-01-19": 192,
      "2020-01-20": 192,
      "2020-01-21": 192,
      "2020-01-22": 191,
      "2020-01-23": 192
    },
    "conference": "BSky",
    "rank": 151,
    "rankHistory": {
      "2020-01-19": 151,
      "2020-01-20": 151,
      "2020-01-21": 151,
      "2020-01-22": 152,
      "2020-01-23": 151
    },
    "record": "10-8"
  },
  "Montana St.": {
    "adjDef": 102.1,
    "adjDefHistory": {
      "2020-01-19": 102.2,
      "2020-01-20": 102.2,
      "2020-01-21": 102.2,
      "2020-01-22": 102.1,
      "2020-01-23": 102.1
    },
    "adjDefRank": 176,
    "adjDefRankHistory": {
      "2020-01-19": 177,
      "2020-01-20": 177,
      "2020-01-21": 176,
      "2020-01-22": 177,
      "2020-01-23": 176
    },
    "adjEM": -5.62,
    "adjEmHistory": {
      "2020-01-19": -5.82,
      "2020-01-20": -5.82,
      "2020-01-21": -5.74,
      "2020-01-22": -5.67,
      "2020-01-23": -5.62
    },
    "adjOff": 96.5,
    "adjOffHistory": {
      "2020-01-19": 96.3,
      "2020-01-20": 96.3,
      "2020-01-21": 96.5,
      "2020-01-22": 96.4,
      "2020-01-23": 96.5
    },
    "adjOffRank": 272,
    "adjOffRankHistory": {
      "2020-01-19": 272,
      "2020-01-20": 272,
      "2020-01-21": 271,
      "2020-01-22": 270,
      "2020-01-23": 272
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.4,
      "2020-01-20": 67.4,
      "2020-01-21": 67.2,
      "2020-01-22": 67.2,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 238,
    "adjTempoRankHistory": {
      "2020-01-19": 235,
      "2020-01-20": 235,
      "2020-01-21": 236,
      "2020-01-22": 236,
      "2020-01-23": 238
    },
    "conference": "BSky",
    "rank": 233,
    "rankHistory": {
      "2020-01-19": 234,
      "2020-01-20": 234,
      "2020-01-21": 234,
      "2020-01-22": 232,
      "2020-01-23": 233
    },
    "record": "9-9"
  },
  "Morehead St.": {
    "adjDef": 107,
    "adjDefHistory": {
      "2020-01-19": 106.8,
      "2020-01-20": 106.8,
      "2020-01-21": 106.9,
      "2020-01-22": 106.8,
      "2020-01-23": 107
    },
    "adjDefRank": 292,
    "adjDefRankHistory": {
      "2020-01-19": 291,
      "2020-01-20": 291,
      "2020-01-21": 290,
      "2020-01-22": 289,
      "2020-01-23": 292
    },
    "adjEM": -11.56,
    "adjEmHistory": {
      "2020-01-19": -11.32,
      "2020-01-20": -11.32,
      "2020-01-21": -11.3,
      "2020-01-22": -11.44,
      "2020-01-23": -11.56
    },
    "adjOff": 95.4,
    "adjOffHistory": {
      "2020-01-19": 95.5,
      "2020-01-20": 95.5,
      "2020-01-21": 95.6,
      "2020-01-22": 95.4,
      "2020-01-23": 95.4
    },
    "adjOffRank": 293,
    "adjOffRankHistory": {
      "2020-01-19": 291,
      "2020-01-20": 291,
      "2020-01-21": 291,
      "2020-01-22": 292,
      "2020-01-23": 293
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.1,
      "2020-01-22": 67.1,
      "2020-01-23": 67
    },
    "adjTempoRank": 244,
    "adjTempoRankHistory": {
      "2020-01-19": 239,
      "2020-01-20": 239,
      "2020-01-21": 243,
      "2020-01-22": 246,
      "2020-01-23": 244
    },
    "conference": "OVC",
    "rank": 303,
    "rankHistory": {
      "2020-01-19": 298,
      "2020-01-20": 298,
      "2020-01-21": 299,
      "2020-01-22": 300,
      "2020-01-23": 303
    },
    "record": "9-10"
  },
  "Morgan St.": {
    "adjDef": 109.4,
    "adjDefHistory": {
      "2020-01-19": 109.2,
      "2020-01-20": 109.2,
      "2020-01-21": 109.4,
      "2020-01-22": 109.3,
      "2020-01-23": 109.4
    },
    "adjDefRank": 321,
    "adjDefRankHistory": {
      "2020-01-19": 319,
      "2020-01-20": 319,
      "2020-01-21": 319,
      "2020-01-22": 320,
      "2020-01-23": 321
    },
    "adjEM": -13.12,
    "adjEmHistory": {
      "2020-01-19": -12.3,
      "2020-01-20": -12.3,
      "2020-01-21": -13.03,
      "2020-01-22": -13.03,
      "2020-01-23": -13.12
    },
    "adjOff": 96.3,
    "adjOffHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 96.9,
      "2020-01-21": 96.4,
      "2020-01-22": 96.3,
      "2020-01-23": 96.3
    },
    "adjOffRank": 278,
    "adjOffRankHistory": {
      "2020-01-19": 262,
      "2020-01-20": 260,
      "2020-01-21": 276,
      "2020-01-22": 275,
      "2020-01-23": 278
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 69.3,
      "2020-01-22": 69.3,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 131,
    "adjTempoRankHistory": {
      "2020-01-19": 155,
      "2020-01-20": 155,
      "2020-01-21": 132,
      "2020-01-22": 130,
      "2020-01-23": 131
    },
    "conference": "MEAC",
    "rank": 311,
    "rankHistory": {
      "2020-01-19": 309,
      "2020-01-20": 309,
      "2020-01-21": 310,
      "2020-01-22": 310,
      "2020-01-23": 311
    },
    "record": "9-12"
  },
  "Mount St. Mary's": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 102.8,
      "2020-01-22": 102.6,
      "2020-01-23": 102.7
    },
    "adjDefRank": 191,
    "adjDefRankHistory": {
      "2020-01-19": 236,
      "2020-01-20": 236,
      "2020-01-21": 191,
      "2020-01-22": 191,
      "2020-01-23": 191
    },
    "adjEM": -8.51,
    "adjEmHistory": {
      "2020-01-19": -9.76,
      "2020-01-20": -9.76,
      "2020-01-21": -8.52,
      "2020-01-22": -8.51,
      "2020-01-23": -8.51
    },
    "adjOff": 94.2,
    "adjOffHistory": {
      "2020-01-19": 94.7,
      "2020-01-20": 94.7,
      "2020-01-21": 94.3,
      "2020-01-22": 94.1,
      "2020-01-23": 94.2
    },
    "adjOffRank": 306,
    "adjOffRankHistory": {
      "2020-01-19": 299,
      "2020-01-20": 299,
      "2020-01-21": 307,
      "2020-01-22": 307,
      "2020-01-23": 306
    },
    "adjTempo": 63.2,
    "adjTempoHistory": {
      "2020-01-19": 63.5,
      "2020-01-20": 63.5,
      "2020-01-21": 63.3,
      "2020-01-22": 63.3,
      "2020-01-23": 63.2
    },
    "adjTempoRank": 345,
    "adjTempoRankHistory": {
      "2020-01-19": 343,
      "2020-01-20": 343,
      "2020-01-21": 344,
      "2020-01-22": 344,
      "2020-01-23": 345
    },
    "conference": "NEC",
    "rank": 268,
    "rankHistory": {
      "2020-01-19": 285,
      "2020-01-20": 285,
      "2020-01-21": 270,
      "2020-01-22": 267,
      "2020-01-23": 268
    },
    "record": "8-11"
  },
  "Murray St.": {
    "adjDef": 102.2,
    "adjDefHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.1,
      "2020-01-22": 102,
      "2020-01-23": 102.2
    },
    "adjDefRank": 177,
    "adjDefRankHistory": {
      "2020-01-19": 171,
      "2020-01-20": 171,
      "2020-01-21": 172,
      "2020-01-22": 172,
      "2020-01-23": 177
    },
    "adjEM": 2.24,
    "adjEmHistory": {
      "2020-01-19": 2.33,
      "2020-01-20": 2.33,
      "2020-01-21": 2.27,
      "2020-01-22": 2.29,
      "2020-01-23": 2.24
    },
    "adjOff": 104.4,
    "adjOffHistory": {
      "2020-01-19": 104.3,
      "2020-01-20": 104.3,
      "2020-01-21": 104.4,
      "2020-01-22": 104.3,
      "2020-01-23": 104.4
    },
    "adjOffRank": 114,
    "adjOffRankHistory": {
      "2020-01-19": 119,
      "2020-01-20": 119,
      "2020-01-21": 119,
      "2020-01-22": 118,
      "2020-01-23": 114
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.6,
      "2020-01-20": 69.6,
      "2020-01-21": 69.4,
      "2020-01-22": 69.4,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 126,
    "adjTempoRankHistory": {
      "2020-01-19": 124,
      "2020-01-20": 124,
      "2020-01-21": 123,
      "2020-01-22": 123,
      "2020-01-23": 126
    },
    "conference": "OVC",
    "rank": 141,
    "rankHistory": {
      "2020-01-19": 140,
      "2020-01-20": 141,
      "2020-01-21": 140,
      "2020-01-22": 140,
      "2020-01-23": 141
    },
    "record": "13-5"
  },
  "N.C. State": {
    "adjDef": 97.9,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.9
    },
    "adjDefRank": 103,
    "adjDefRankHistory": {
      "2020-01-19": 111,
      "2020-01-20": 111,
      "2020-01-21": 102,
      "2020-01-22": 101,
      "2020-01-23": 103
    },
    "adjEM": 14.74,
    "adjEmHistory": {
      "2020-01-19": 14.43,
      "2020-01-20": 14.43,
      "2020-01-21": 14.98,
      "2020-01-22": 14.96,
      "2020-01-23": 14.74
    },
    "adjOff": 112.7,
    "adjOffHistory": {
      "2020-01-19": 112.6,
      "2020-01-20": 112.6,
      "2020-01-21": 112.9,
      "2020-01-22": 112.7,
      "2020-01-23": 112.7
    },
    "adjOffRank": 16,
    "adjOffRankHistory": {
      "2020-01-19": 15,
      "2020-01-20": 16,
      "2020-01-21": 15,
      "2020-01-22": 16,
      "2020-01-23": 16
    },
    "adjTempo": 68.7,
    "adjTempoHistory": {
      "2020-01-19": 69.1,
      "2020-01-20": 69.1,
      "2020-01-21": 68.8,
      "2020-01-22": 68.8,
      "2020-01-23": 68.7
    },
    "adjTempoRank": 158,
    "adjTempoRankHistory": {
      "2020-01-19": 150,
      "2020-01-20": 150,
      "2020-01-21": 157,
      "2020-01-22": 157,
      "2020-01-23": 158
    },
    "conference": "ACC",
    "rank": 43,
    "rankHistory": {
      "2020-01-19": 46,
      "2020-01-20": 46,
      "2020-01-21": 43,
      "2020-01-22": 43,
      "2020-01-23": 43
    },
    "record": "14-5"
  },
  "NJIT": {
    "adjDef": 105.5,
    "adjDefHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.6,
      "2020-01-23": 105.5
    },
    "adjDefRank": 253,
    "adjDefRankHistory": {
      "2020-01-19": 262,
      "2020-01-20": 262,
      "2020-01-21": 259,
      "2020-01-22": 260,
      "2020-01-23": 253
    },
    "adjEM": -8.48,
    "adjEmHistory": {
      "2020-01-19": -8.45,
      "2020-01-20": -8.46,
      "2020-01-21": -8.5,
      "2020-01-22": -8.56,
      "2020-01-23": -8.48
    },
    "adjOff": 97.1,
    "adjOffHistory": {
      "2020-01-19": 97,
      "2020-01-20": 97,
      "2020-01-21": 97.2,
      "2020-01-22": 97.1,
      "2020-01-23": 97.1
    },
    "adjOffRank": 263,
    "adjOffRankHistory": {
      "2020-01-19": 257,
      "2020-01-20": 257,
      "2020-01-21": 257,
      "2020-01-22": 260,
      "2020-01-23": 263
    },
    "adjTempo": 66.7,
    "adjTempoHistory": {
      "2020-01-19": 66.9,
      "2020-01-20": 66.9,
      "2020-01-21": 66.8,
      "2020-01-22": 66.8,
      "2020-01-23": 66.7
    },
    "adjTempoRank": 258,
    "adjTempoRankHistory": {
      "2020-01-19": 257,
      "2020-01-20": 257,
      "2020-01-21": 258,
      "2020-01-22": 256,
      "2020-01-23": 258
    },
    "conference": "ASun",
    "rank": 267,
    "rankHistory": {
      "2020-01-19": 267,
      "2020-01-20": 267,
      "2020-01-21": 268,
      "2020-01-22": 270,
      "2020-01-23": 267
    },
    "record": "6-12"
  },
  "Navy": {
    "adjDef": 101.7,
    "adjDefHistory": {
      "2020-01-19": 102.8,
      "2020-01-20": 102.8,
      "2020-01-21": 103,
      "2020-01-22": 102.9,
      "2020-01-23": 101.7
    },
    "adjDefRank": 167,
    "adjDefRankHistory": {
      "2020-01-19": 199,
      "2020-01-20": 200,
      "2020-01-21": 202,
      "2020-01-22": 203,
      "2020-01-23": 167
    },
    "adjEM": -4.3,
    "adjEmHistory": {
      "2020-01-19": -5.06,
      "2020-01-20": -5.06,
      "2020-01-21": -5.03,
      "2020-01-22": -5.01,
      "2020-01-23": -4.3
    },
    "adjOff": 97.4,
    "adjOffHistory": {
      "2020-01-19": 97.7,
      "2020-01-20": 97.8,
      "2020-01-21": 98,
      "2020-01-22": 97.9,
      "2020-01-23": 97.4
    },
    "adjOffRank": 256,
    "adjOffRankHistory": {
      "2020-01-19": 240,
      "2020-01-20": 240,
      "2020-01-21": 236,
      "2020-01-22": 238,
      "2020-01-23": 256
    },
    "adjTempo": 61.9,
    "adjTempoHistory": {
      "2020-01-19": 62.2,
      "2020-01-20": 62.2,
      "2020-01-21": 62.1,
      "2020-01-22": 62.1,
      "2020-01-23": 61.9
    },
    "adjTempoRank": 351,
    "adjTempoRankHistory": {
      "2020-01-19": 350,
      "2020-01-20": 350,
      "2020-01-21": 350,
      "2020-01-22": 351,
      "2020-01-23": 351
    },
    "conference": "Pat",
    "rank": 215,
    "rankHistory": {
      "2020-01-19": 225,
      "2020-01-20": 225,
      "2020-01-21": 224,
      "2020-01-22": 224,
      "2020-01-23": 215
    },
    "record": "11-7"
  },
  "Nebraska": {
    "adjDef": 101.1,
    "adjDefHistory": {
      "2020-01-19": 100.6,
      "2020-01-20": 100.6,
      "2020-01-21": 100.8,
      "2020-01-22": 101.1,
      "2020-01-23": 101.1
    },
    "adjDefRank": 158,
    "adjDefRankHistory": {
      "2020-01-19": 150,
      "2020-01-20": 151,
      "2020-01-21": 153,
      "2020-01-22": 159,
      "2020-01-23": 158
    },
    "adjEM": 2.76,
    "adjEmHistory": {
      "2020-01-19": 2.84,
      "2020-01-20": 2.84,
      "2020-01-21": 2.82,
      "2020-01-22": 2.7,
      "2020-01-23": 2.76
    },
    "adjOff": 103.8,
    "adjOffHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.6,
      "2020-01-22": 103.8,
      "2020-01-23": 103.8
    },
    "adjOffRank": 127,
    "adjOffRankHistory": {
      "2020-01-19": 135,
      "2020-01-20": 135,
      "2020-01-21": 134,
      "2020-01-22": 128,
      "2020-01-23": 127
    },
    "adjTempo": 71.9,
    "adjTempoHistory": {
      "2020-01-19": 71.8,
      "2020-01-20": 71.8,
      "2020-01-21": 71.7,
      "2020-01-22": 71.9,
      "2020-01-23": 71.9
    },
    "adjTempoRank": 31,
    "adjTempoRankHistory": {
      "2020-01-19": 40,
      "2020-01-20": 40,
      "2020-01-21": 40,
      "2020-01-22": 31,
      "2020-01-23": 31
    },
    "conference": "B10",
    "rank": 131,
    "rankHistory": {
      "2020-01-19": 135,
      "2020-01-20": 135,
      "2020-01-21": 134,
      "2020-01-22": 134,
      "2020-01-23": 131
    },
    "record": "7-12"
  },
  "Nebraska Omaha": {
    "adjDef": 106.3,
    "adjDefHistory": {
      "2020-01-19": 106.3,
      "2020-01-20": 106.3,
      "2020-01-21": 106.4,
      "2020-01-22": 106.3,
      "2020-01-23": 106.3
    },
    "adjDefRank": 274,
    "adjDefRankHistory": {
      "2020-01-19": 276,
      "2020-01-20": 275,
      "2020-01-21": 278,
      "2020-01-22": 276,
      "2020-01-23": 274
    },
    "adjEM": -2.89,
    "adjEmHistory": {
      "2020-01-19": -3.04,
      "2020-01-20": -3.04,
      "2020-01-21": -3.03,
      "2020-01-22": -2.98,
      "2020-01-23": -2.89
    },
    "adjOff": 103.4,
    "adjOffHistory": {
      "2020-01-19": 103.3,
      "2020-01-20": 103.3,
      "2020-01-21": 103.4,
      "2020-01-22": 103.3,
      "2020-01-23": 103.4
    },
    "adjOffRank": 139,
    "adjOffRankHistory": {
      "2020-01-19": 144,
      "2020-01-20": 145,
      "2020-01-21": 146,
      "2020-01-22": 142,
      "2020-01-23": 139
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.5,
      "2020-01-20": 69.5,
      "2020-01-21": 69.4,
      "2020-01-22": 69.4,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 127,
    "adjTempoRankHistory": {
      "2020-01-19": 129,
      "2020-01-20": 129,
      "2020-01-21": 128,
      "2020-01-22": 125,
      "2020-01-23": 127
    },
    "conference": "Sum",
    "rank": 195,
    "rankHistory": {
      "2020-01-19": 197,
      "2020-01-20": 197,
      "2020-01-21": 197,
      "2020-01-22": 196,
      "2020-01-23": 195
    },
    "record": "10-10"
  },
  "Nevada": {
    "adjDef": 99,
    "adjDefHistory": {
      "2020-01-19": 98.7,
      "2020-01-20": 98.7,
      "2020-01-21": 98.8,
      "2020-01-22": 98.8,
      "2020-01-23": 99
    },
    "adjDefRank": 128,
    "adjDefRankHistory": {
      "2020-01-19": 125,
      "2020-01-20": 124,
      "2020-01-21": 127,
      "2020-01-22": 127,
      "2020-01-23": 128
    },
    "adjEM": 7.27,
    "adjEmHistory": {
      "2020-01-19": 6.53,
      "2020-01-20": 6.53,
      "2020-01-21": 6.59,
      "2020-01-22": 6.57,
      "2020-01-23": 7.27
    },
    "adjOff": 106.2,
    "adjOffHistory": {
      "2020-01-19": 105.2,
      "2020-01-20": 105.2,
      "2020-01-21": 105.4,
      "2020-01-22": 105.4,
      "2020-01-23": 106.2
    },
    "adjOffRank": 74,
    "adjOffRankHistory": {
      "2020-01-19": 91,
      "2020-01-20": 90,
      "2020-01-21": 89,
      "2020-01-22": 87,
      "2020-01-23": 74
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.5,
      "2020-01-22": 70.4,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 75,
    "adjTempoRankHistory": {
      "2020-01-19": 81,
      "2020-01-20": 81,
      "2020-01-21": 81,
      "2020-01-22": 79,
      "2020-01-23": 75
    },
    "conference": "MWC",
    "rank": 98,
    "rankHistory": {
      "2020-01-19": 103,
      "2020-01-20": 103,
      "2020-01-21": 103,
      "2020-01-22": 103,
      "2020-01-23": 98
    },
    "record": "12-8"
  },
  "New Hampshire": {
    "adjDef": 101.3,
    "adjDefHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.4,
      "2020-01-22": 101.2,
      "2020-01-23": 101.3
    },
    "adjDefRank": 159,
    "adjDefRankHistory": {
      "2020-01-19": 161,
      "2020-01-20": 161,
      "2020-01-21": 164,
      "2020-01-22": 164,
      "2020-01-23": 159
    },
    "adjEM": -9.36,
    "adjEmHistory": {
      "2020-01-19": -9.41,
      "2020-01-20": -9.41,
      "2020-01-21": -9.43,
      "2020-01-22": -9.45,
      "2020-01-23": -9.36
    },
    "adjOff": 91.9,
    "adjOffHistory": {
      "2020-01-19": 91.8,
      "2020-01-20": 91.8,
      "2020-01-21": 91.9,
      "2020-01-22": 91.8,
      "2020-01-23": 91.9
    },
    "adjOffRank": 325,
    "adjOffRankHistory": {
      "2020-01-19": 325,
      "2020-01-20": 325,
      "2020-01-21": 325,
      "2020-01-22": 325,
      "2020-01-23": 325
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.8,
      "2020-01-22": 69.8,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 110,
    "adjTempoRankHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 107,
      "2020-01-22": 108,
      "2020-01-23": 110
    },
    "conference": "AE",
    "rank": 279,
    "rankHistory": {
      "2020-01-19": 281,
      "2020-01-20": 281,
      "2020-01-21": 283,
      "2020-01-22": 283,
      "2020-01-23": 279
    },
    "record": "9-9"
  },
  "New Mexico": {
    "adjDef": 101.8,
    "adjDefHistory": {
      "2020-01-19": 102.4,
      "2020-01-20": 102.4,
      "2020-01-21": 102.5,
      "2020-01-22": 101.9,
      "2020-01-23": 101.8
    },
    "adjDefRank": 168,
    "adjDefRankHistory": {
      "2020-01-19": 181,
      "2020-01-20": 180,
      "2020-01-21": 182,
      "2020-01-22": 171,
      "2020-01-23": 168
    },
    "adjEM": 5.19,
    "adjEmHistory": {
      "2020-01-19": 4.41,
      "2020-01-20": 4.4,
      "2020-01-21": 4.4,
      "2020-01-22": 5,
      "2020-01-23": 5.19
    },
    "adjOff": 107,
    "adjOffHistory": {
      "2020-01-19": 106.8,
      "2020-01-20": 106.8,
      "2020-01-21": 106.9,
      "2020-01-22": 106.9,
      "2020-01-23": 107
    },
    "adjOffRank": 65,
    "adjOffRankHistory": {
      "2020-01-19": 64,
      "2020-01-20": 64,
      "2020-01-21": 64,
      "2020-01-22": 64,
      "2020-01-23": 65
    },
    "adjTempo": 72.7,
    "adjTempoHistory": {
      "2020-01-19": 73.1,
      "2020-01-20": 73.1,
      "2020-01-21": 73,
      "2020-01-22": 72.7,
      "2020-01-23": 72.7
    },
    "adjTempoRank": 21,
    "adjTempoRankHistory": {
      "2020-01-19": 21,
      "2020-01-20": 21,
      "2020-01-21": 20,
      "2020-01-22": 21,
      "2020-01-23": 21
    },
    "conference": "MWC",
    "rank": 115,
    "rankHistory": {
      "2020-01-19": 122,
      "2020-01-20": 122,
      "2020-01-21": 122,
      "2020-01-22": 116,
      "2020-01-23": 115
    },
    "record": "16-5"
  },
  "New Mexico St.": {
    "adjDef": 97.4,
    "adjDefHistory": {
      "2020-01-19": 97.2,
      "2020-01-20": 97.2,
      "2020-01-21": 97.4,
      "2020-01-22": 97.4,
      "2020-01-23": 97.4
    },
    "adjDefRank": 92,
    "adjDefRankHistory": {
      "2020-01-19": 91,
      "2020-01-20": 91,
      "2020-01-21": 92,
      "2020-01-22": 94,
      "2020-01-23": 92
    },
    "adjEM": 7.44,
    "adjEmHistory": {
      "2020-01-19": 7.47,
      "2020-01-20": 7.47,
      "2020-01-21": 7.35,
      "2020-01-22": 7.35,
      "2020-01-23": 7.44
    },
    "adjOff": 104.8,
    "adjOffHistory": {
      "2020-01-19": 104.7,
      "2020-01-20": 104.7,
      "2020-01-21": 104.8,
      "2020-01-22": 104.7,
      "2020-01-23": 104.8
    },
    "adjOffRank": 105,
    "adjOffRankHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 106,
      "2020-01-22": 104,
      "2020-01-23": 105
    },
    "adjTempo": 64.8,
    "adjTempoHistory": {
      "2020-01-19": 64.9,
      "2020-01-20": 64.9,
      "2020-01-21": 64.8,
      "2020-01-22": 64.8,
      "2020-01-23": 64.8
    },
    "adjTempoRank": 324,
    "adjTempoRankHistory": {
      "2020-01-19": 323,
      "2020-01-20": 323,
      "2020-01-21": 322,
      "2020-01-22": 322,
      "2020-01-23": 324
    },
    "conference": "WAC",
    "rank": 96,
    "rankHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 96,
      "2020-01-22": 96,
      "2020-01-23": 96
    },
    "record": "14-6"
  },
  "New Orleans": {
    "adjDef": 111.2,
    "adjDefHistory": {
      "2020-01-19": 110.3,
      "2020-01-20": 110.3,
      "2020-01-21": 110.5,
      "2020-01-22": 110.4,
      "2020-01-23": 111.2
    },
    "adjDefRank": 335,
    "adjDefRankHistory": {
      "2020-01-19": 331,
      "2020-01-20": 331,
      "2020-01-21": 331,
      "2020-01-22": 331,
      "2020-01-23": 335
    },
    "adjEM": -14.47,
    "adjEmHistory": {
      "2020-01-19": -14.72,
      "2020-01-20": -14.72,
      "2020-01-21": -14.75,
      "2020-01-22": -14.81,
      "2020-01-23": -14.47
    },
    "adjOff": 96.7,
    "adjOffHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.8,
      "2020-01-22": 95.6,
      "2020-01-23": 96.7
    },
    "adjOffRank": 270,
    "adjOffRankHistory": {
      "2020-01-19": 288,
      "2020-01-20": 288,
      "2020-01-21": 287,
      "2020-01-22": 287,
      "2020-01-23": 270
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70.1,
      "2020-01-20": 70.1,
      "2020-01-21": 70,
      "2020-01-22": 69.9,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 106,
    "adjTempoRankHistory": {
      "2020-01-19": 97,
      "2020-01-20": 97,
      "2020-01-21": 101,
      "2020-01-22": 102,
      "2020-01-23": 106
    },
    "conference": "Slnd",
    "rank": 325,
    "rankHistory": {
      "2020-01-19": 330,
      "2020-01-20": 330,
      "2020-01-21": 327,
      "2020-01-22": 327,
      "2020-01-23": 325
    },
    "record": "6-13"
  },
  "Niagara": {
    "adjDef": 111.8,
    "adjDefHistory": {
      "2020-01-19": 111.7,
      "2020-01-20": 111.7,
      "2020-01-21": 111.8,
      "2020-01-22": 111.7,
      "2020-01-23": 111.8
    },
    "adjDefRank": 338,
    "adjDefRankHistory": {
      "2020-01-19": 338,
      "2020-01-20": 338,
      "2020-01-21": 339,
      "2020-01-22": 338,
      "2020-01-23": 338
    },
    "adjEM": -10.27,
    "adjEmHistory": {
      "2020-01-19": -10.4,
      "2020-01-20": -10.4,
      "2020-01-21": -10.37,
      "2020-01-22": -10.37,
      "2020-01-23": -10.27
    },
    "adjOff": 101.5,
    "adjOffHistory": {
      "2020-01-19": 101.3,
      "2020-01-20": 101.3,
      "2020-01-21": 101.5,
      "2020-01-22": 101.4,
      "2020-01-23": 101.5
    },
    "adjOffRank": 178,
    "adjOffRankHistory": {
      "2020-01-19": 180,
      "2020-01-20": 180,
      "2020-01-21": 178,
      "2020-01-22": 177,
      "2020-01-23": 178
    },
    "adjTempo": 66.2,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 66.3,
      "2020-01-22": 66.3,
      "2020-01-23": 66.2
    },
    "adjTempoRank": 277,
    "adjTempoRankHistory": {
      "2020-01-19": 275,
      "2020-01-20": 275,
      "2020-01-21": 274,
      "2020-01-22": 274,
      "2020-01-23": 277
    },
    "conference": "MAAC",
    "rank": 287,
    "rankHistory": {
      "2020-01-19": 288,
      "2020-01-20": 288,
      "2020-01-21": 288,
      "2020-01-22": 287,
      "2020-01-23": 287
    },
    "record": "6-11"
  },
  "Nicholls St.": {
    "adjDef": 98.5,
    "adjDefHistory": {
      "2020-01-19": 98.5,
      "2020-01-20": 98.5,
      "2020-01-21": 98.5,
      "2020-01-22": 98.2,
      "2020-01-23": 98.5
    },
    "adjDefRank": 117,
    "adjDefRankHistory": {
      "2020-01-19": 119,
      "2020-01-20": 119,
      "2020-01-21": 118,
      "2020-01-22": 114,
      "2020-01-23": 117
    },
    "adjEM": -2.11,
    "adjEmHistory": {
      "2020-01-19": -1.91,
      "2020-01-20": -1.9,
      "2020-01-21": -1.75,
      "2020-01-22": -1.58,
      "2020-01-23": -2.11
    },
    "adjOff": 96.4,
    "adjOffHistory": {
      "2020-01-19": 96.6,
      "2020-01-20": 96.6,
      "2020-01-21": 96.8,
      "2020-01-22": 96.6,
      "2020-01-23": 96.4
    },
    "adjOffRank": 274,
    "adjOffRankHistory": {
      "2020-01-19": 269,
      "2020-01-20": 269,
      "2020-01-21": 268,
      "2020-01-22": 267,
      "2020-01-23": 274
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.5,
      "2020-01-20": 69.5,
      "2020-01-21": 69.3,
      "2020-01-22": 69.3,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 128,
    "adjTempoRankHistory": {
      "2020-01-19": 128,
      "2020-01-20": 128,
      "2020-01-21": 129,
      "2020-01-22": 128,
      "2020-01-23": 128
    },
    "conference": "Slnd",
    "rank": 187,
    "rankHistory": {
      "2020-01-19": 187,
      "2020-01-20": 186,
      "2020-01-21": 185,
      "2020-01-22": 186,
      "2020-01-23": 187
    },
    "record": "13-7"
  },
  "Norfolk St.": {
    "adjDef": 101.6,
    "adjDefHistory": {
      "2020-01-19": 101.5,
      "2020-01-20": 101.5,
      "2020-01-21": 101.6,
      "2020-01-22": 101.6,
      "2020-01-23": 101.6
    },
    "adjDefRank": 165,
    "adjDefRankHistory": {
      "2020-01-19": 165,
      "2020-01-20": 165,
      "2020-01-21": 166,
      "2020-01-22": 167,
      "2020-01-23": 165
    },
    "adjEM": -8.57,
    "adjEmHistory": {
      "2020-01-19": -8.55,
      "2020-01-20": -8.55,
      "2020-01-21": -8.5,
      "2020-01-22": -8.6,
      "2020-01-23": -8.57
    },
    "adjOff": 93,
    "adjOffHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 93.1,
      "2020-01-22": 93,
      "2020-01-23": 93
    },
    "adjOffRank": 313,
    "adjOffRankHistory": {
      "2020-01-19": 313,
      "2020-01-20": 313,
      "2020-01-21": 312,
      "2020-01-22": 314,
      "2020-01-23": 313
    },
    "adjTempo": 65.2,
    "adjTempoHistory": {
      "2020-01-19": 65.4,
      "2020-01-20": 65.4,
      "2020-01-21": 65.2,
      "2020-01-22": 65.2,
      "2020-01-23": 65.2
    },
    "adjTempoRank": 309,
    "adjTempoRankHistory": {
      "2020-01-19": 314,
      "2020-01-20": 312,
      "2020-01-21": 312,
      "2020-01-22": 311,
      "2020-01-23": 309
    },
    "conference": "MEAC",
    "rank": 269,
    "rankHistory": {
      "2020-01-19": 269,
      "2020-01-20": 269,
      "2020-01-21": 267,
      "2020-01-22": 271,
      "2020-01-23": 269
    },
    "record": "8-11"
  },
  "North Alabama": {
    "adjDef": 102.8,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 102.8
    },
    "adjDefRank": 198,
    "adjDefRankHistory": {
      "2020-01-19": 198,
      "2020-01-20": 199,
      "2020-01-21": 197,
      "2020-01-22": 197,
      "2020-01-23": 198
    },
    "adjEM": -9.44,
    "adjEmHistory": {
      "2020-01-19": -9.36,
      "2020-01-20": -9.36,
      "2020-01-21": -9.36,
      "2020-01-22": -9.37,
      "2020-01-23": -9.44
    },
    "adjOff": 93.4,
    "adjOffHistory": {
      "2020-01-19": 93.4,
      "2020-01-20": 93.4,
      "2020-01-21": 93.5,
      "2020-01-22": 93.4,
      "2020-01-23": 93.4
    },
    "adjOffRank": 309,
    "adjOffRankHistory": {
      "2020-01-19": 310,
      "2020-01-20": 310,
      "2020-01-21": 309,
      "2020-01-22": 310,
      "2020-01-23": 309
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69.4,
      "2020-01-20": 69.4,
      "2020-01-21": 69.3,
      "2020-01-22": 69.3,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 132,
    "adjTempoRankHistory": {
      "2020-01-19": 131,
      "2020-01-20": 131,
      "2020-01-21": 131,
      "2020-01-22": 127,
      "2020-01-23": 132
    },
    "conference": "ASun",
    "rank": 280,
    "rankHistory": {
      "2020-01-19": 280,
      "2020-01-20": 280,
      "2020-01-21": 281,
      "2020-01-22": 280,
      "2020-01-23": 280
    },
    "record": "8-11"
  },
  "North Carolina": {
    "adjDef": 96.8,
    "adjDefHistory": {
      "2020-01-19": 96.7,
      "2020-01-20": 96.8,
      "2020-01-21": 96.9,
      "2020-01-22": 96.9,
      "2020-01-23": 96.8
    },
    "adjDefRank": 86,
    "adjDefRankHistory": {
      "2020-01-19": 87,
      "2020-01-20": 87,
      "2020-01-21": 88,
      "2020-01-22": 90,
      "2020-01-23": 86
    },
    "adjEM": 7.61,
    "adjEmHistory": {
      "2020-01-19": 7.66,
      "2020-01-20": 7.69,
      "2020-01-21": 7.56,
      "2020-01-22": 7.47,
      "2020-01-23": 7.61
    },
    "adjOff": 104.4,
    "adjOffHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.5,
      "2020-01-21": 104.5,
      "2020-01-22": 104.3,
      "2020-01-23": 104.4
    },
    "adjOffRank": 116,
    "adjOffRankHistory": {
      "2020-01-19": 115,
      "2020-01-20": 113,
      "2020-01-21": 115,
      "2020-01-22": 117,
      "2020-01-23": 116
    },
    "adjTempo": 70.9,
    "adjTempoHistory": {
      "2020-01-19": 71.4,
      "2020-01-20": 71.4,
      "2020-01-21": 71.3,
      "2020-01-22": 71.3,
      "2020-01-23": 70.9
    },
    "adjTempoRank": 56,
    "adjTempoRankHistory": {
      "2020-01-19": 49,
      "2020-01-20": 49,
      "2020-01-21": 49,
      "2020-01-22": 50,
      "2020-01-23": 56
    },
    "conference": "ACC",
    "rank": 95,
    "rankHistory": {
      "2020-01-19": 91,
      "2020-01-20": 91,
      "2020-01-21": 92,
      "2020-01-22": 93,
      "2020-01-23": 95
    },
    "record": "8-10"
  },
  "North Carolina A&T": {
    "adjDef": 101.9,
    "adjDefHistory": {
      "2020-01-19": 102.4,
      "2020-01-20": 102.4,
      "2020-01-21": 102,
      "2020-01-22": 101.8,
      "2020-01-23": 101.9
    },
    "adjDefRank": 170,
    "adjDefRankHistory": {
      "2020-01-19": 183,
      "2020-01-20": 182,
      "2020-01-21": 170,
      "2020-01-22": 170,
      "2020-01-23": 170
    },
    "adjEM": -10.59,
    "adjEmHistory": {
      "2020-01-19": -11.31,
      "2020-01-20": -11.3,
      "2020-01-21": -10.66,
      "2020-01-22": -10.57,
      "2020-01-23": -10.59
    },
    "adjOff": 91.3,
    "adjOffHistory": {
      "2020-01-19": 91.1,
      "2020-01-20": 91.1,
      "2020-01-21": 91.3,
      "2020-01-22": 91.2,
      "2020-01-23": 91.3
    },
    "adjOffRank": 335,
    "adjOffRankHistory": {
      "2020-01-19": 335,
      "2020-01-20": 335,
      "2020-01-21": 334,
      "2020-01-22": 335,
      "2020-01-23": 335
    },
    "adjTempo": 70.8,
    "adjTempoHistory": {
      "2020-01-19": 70.5,
      "2020-01-20": 70.5,
      "2020-01-21": 70.8,
      "2020-01-22": 70.8,
      "2020-01-23": 70.8
    },
    "adjTempoRank": 59,
    "adjTempoRankHistory": {
      "2020-01-19": 87,
      "2020-01-20": 87,
      "2020-01-21": 61,
      "2020-01-22": 62,
      "2020-01-23": 59
    },
    "conference": "MEAC",
    "rank": 291,
    "rankHistory": {
      "2020-01-19": 297,
      "2020-01-20": 297,
      "2020-01-21": 291,
      "2020-01-22": 290,
      "2020-01-23": 291
    },
    "record": "8-12"
  },
  "North Carolina Central": {
    "adjDef": 103.2,
    "adjDefHistory": {
      "2020-01-19": 102.8,
      "2020-01-20": 102.8,
      "2020-01-21": 103.2,
      "2020-01-22": 103,
      "2020-01-23": 103.2
    },
    "adjDefRank": 209,
    "adjDefRankHistory": {
      "2020-01-19": 200,
      "2020-01-20": 201,
      "2020-01-21": 208,
      "2020-01-22": 208,
      "2020-01-23": 209
    },
    "adjEM": -10.69,
    "adjEmHistory": {
      "2020-01-19": -10.15,
      "2020-01-20": -10.15,
      "2020-01-21": -10.8,
      "2020-01-22": -10.72,
      "2020-01-23": -10.69
    },
    "adjOff": 92.5,
    "adjOffHistory": {
      "2020-01-19": 92.7,
      "2020-01-20": 92.7,
      "2020-01-21": 92.4,
      "2020-01-22": 92.3,
      "2020-01-23": 92.5
    },
    "adjOffRank": 320,
    "adjOffRankHistory": {
      "2020-01-19": 317,
      "2020-01-20": 317,
      "2020-01-21": 321,
      "2020-01-22": 321,
      "2020-01-23": 320
    },
    "adjTempo": 66.2,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 66.2,
      "2020-01-22": 66.2,
      "2020-01-23": 66.2
    },
    "adjTempoRank": 279,
    "adjTempoRankHistory": {
      "2020-01-19": 277,
      "2020-01-20": 276,
      "2020-01-21": 279,
      "2020-01-22": 276,
      "2020-01-23": 279
    },
    "conference": "MEAC",
    "rank": 292,
    "rankHistory": {
      "2020-01-19": 286,
      "2020-01-20": 286,
      "2020-01-21": 292,
      "2020-01-22": 292,
      "2020-01-23": 292
    },
    "record": "7-12"
  },
  "North Dakota": {
    "adjDef": 106.6,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.6,
      "2020-01-22": 106.5,
      "2020-01-23": 106.6
    },
    "adjDefRank": 281,
    "adjDefRankHistory": {
      "2020-01-19": 278,
      "2020-01-20": 278,
      "2020-01-21": 280,
      "2020-01-22": 279,
      "2020-01-23": 281
    },
    "adjEM": -4.83,
    "adjEmHistory": {
      "2020-01-19": -4.91,
      "2020-01-20": -4.91,
      "2020-01-21": -4.91,
      "2020-01-22": -4.9,
      "2020-01-23": -4.83
    },
    "adjOff": 101.7,
    "adjOffHistory": {
      "2020-01-19": 101.6,
      "2020-01-20": 101.6,
      "2020-01-21": 101.7,
      "2020-01-22": 101.6,
      "2020-01-23": 101.7
    },
    "adjOffRank": 175,
    "adjOffRankHistory": {
      "2020-01-19": 176,
      "2020-01-20": 176,
      "2020-01-21": 176,
      "2020-01-22": 176,
      "2020-01-23": 175
    },
    "adjTempo": 69,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 69.1,
      "2020-01-22": 69,
      "2020-01-23": 69
    },
    "adjTempoRank": 150,
    "adjTempoRankHistory": {
      "2020-01-19": 145,
      "2020-01-20": 144,
      "2020-01-21": 146,
      "2020-01-22": 149,
      "2020-01-23": 150
    },
    "conference": "Sum",
    "rank": 223,
    "rankHistory": {
      "2020-01-19": 224,
      "2020-01-20": 224,
      "2020-01-21": 223,
      "2020-01-22": 223,
      "2020-01-23": 223
    },
    "record": "8-12"
  },
  "North Dakota St.": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.5,
      "2020-01-20": 102.5,
      "2020-01-21": 102.6,
      "2020-01-22": 102.5,
      "2020-01-23": 102.7
    },
    "adjDefRank": 192,
    "adjDefRankHistory": {
      "2020-01-19": 186,
      "2020-01-20": 186,
      "2020-01-21": 186,
      "2020-01-22": 183,
      "2020-01-23": 192
    },
    "adjEM": 2.11,
    "adjEmHistory": {
      "2020-01-19": 2.12,
      "2020-01-20": 2.12,
      "2020-01-21": 2.15,
      "2020-01-22": 2.18,
      "2020-01-23": 2.11
    },
    "adjOff": 104.8,
    "adjOffHistory": {
      "2020-01-19": 104.6,
      "2020-01-20": 104.6,
      "2020-01-21": 104.8,
      "2020-01-22": 104.7,
      "2020-01-23": 104.8
    },
    "adjOffRank": 104,
    "adjOffRankHistory": {
      "2020-01-19": 109,
      "2020-01-20": 109,
      "2020-01-21": 105,
      "2020-01-22": 105,
      "2020-01-23": 104
    },
    "adjTempo": 65,
    "adjTempoHistory": {
      "2020-01-19": 65,
      "2020-01-20": 65,
      "2020-01-21": 64.9,
      "2020-01-22": 64.9,
      "2020-01-23": 65
    },
    "adjTempoRank": 313,
    "adjTempoRankHistory": {
      "2020-01-19": 321,
      "2020-01-20": 321,
      "2020-01-21": 320,
      "2020-01-22": 320,
      "2020-01-23": 313
    },
    "conference": "Sum",
    "rank": 142,
    "rankHistory": {
      "2020-01-19": 141,
      "2020-01-20": 142,
      "2020-01-21": 142,
      "2020-01-22": 142,
      "2020-01-23": 142
    },
    "record": "13-7"
  },
  "North Florida": {
    "adjDef": 108,
    "adjDefHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108.1,
      "2020-01-22": 108,
      "2020-01-23": 108
    },
    "adjDefRank": 304,
    "adjDefRankHistory": {
      "2020-01-19": 308,
      "2020-01-20": 308,
      "2020-01-21": 307,
      "2020-01-22": 306,
      "2020-01-23": 304
    },
    "adjEM": -0.63,
    "adjEmHistory": {
      "2020-01-19": -0.73,
      "2020-01-20": -0.73,
      "2020-01-21": -0.71,
      "2020-01-22": -0.74,
      "2020-01-23": -0.63
    },
    "adjOff": 107.4,
    "adjOffHistory": {
      "2020-01-19": 107.3,
      "2020-01-20": 107.3,
      "2020-01-21": 107.4,
      "2020-01-22": 107.3,
      "2020-01-23": 107.4
    },
    "adjOffRank": 62,
    "adjOffRankHistory": {
      "2020-01-19": 60,
      "2020-01-20": 60,
      "2020-01-21": 61,
      "2020-01-22": 61,
      "2020-01-23": 62
    },
    "adjTempo": 69.9,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 70,
      "2020-01-22": 69.9,
      "2020-01-23": 69.9
    },
    "adjTempoRank": 99,
    "adjTempoRankHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 98,
      "2020-01-22": 101,
      "2020-01-23": 99
    },
    "conference": "ASun",
    "rank": 179,
    "rankHistory": {
      "2020-01-19": 180,
      "2020-01-20": 180,
      "2020-01-21": 179,
      "2020-01-22": 178,
      "2020-01-23": 179
    },
    "record": "11-9"
  },
  "North Texas": {
    "adjDef": 98,
    "adjDefHistory": {
      "2020-01-19": 97.9,
      "2020-01-20": 97.9,
      "2020-01-21": 97.9,
      "2020-01-22": 97.9,
      "2020-01-23": 98
    },
    "adjDefRank": 104,
    "adjDefRankHistory": {
      "2020-01-19": 106,
      "2020-01-20": 106,
      "2020-01-21": 104,
      "2020-01-22": 105,
      "2020-01-23": 104
    },
    "adjEM": 9.73,
    "adjEmHistory": {
      "2020-01-19": 8.81,
      "2020-01-20": 8.81,
      "2020-01-21": 9.37,
      "2020-01-22": 9.49,
      "2020-01-23": 9.73
    },
    "adjOff": 107.7,
    "adjOffHistory": {
      "2020-01-19": 106.7,
      "2020-01-20": 106.7,
      "2020-01-21": 107.3,
      "2020-01-22": 107.4,
      "2020-01-23": 107.7
    },
    "adjOffRank": 57,
    "adjOffRankHistory": {
      "2020-01-19": 65,
      "2020-01-20": 65,
      "2020-01-21": 62,
      "2020-01-22": 60,
      "2020-01-23": 57
    },
    "adjTempo": 62.5,
    "adjTempoHistory": {
      "2020-01-19": 62.7,
      "2020-01-20": 62.7,
      "2020-01-21": 62.5,
      "2020-01-22": 62.5,
      "2020-01-23": 62.5
    },
    "adjTempoRank": 349,
    "adjTempoRankHistory": {
      "2020-01-19": 349,
      "2020-01-20": 349,
      "2020-01-21": 349,
      "2020-01-22": 349,
      "2020-01-23": 349
    },
    "conference": "CUSA",
    "rank": 78,
    "rankHistory": {
      "2020-01-19": 86,
      "2020-01-20": 86,
      "2020-01-21": 83,
      "2020-01-22": 82,
      "2020-01-23": 78
    },
    "record": "12-8"
  },
  "Northeastern": {
    "adjDef": 104.2,
    "adjDefHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104.2,
      "2020-01-22": 104.1,
      "2020-01-23": 104.2
    },
    "adjDefRank": 230,
    "adjDefRankHistory": {
      "2020-01-19": 224,
      "2020-01-20": 225,
      "2020-01-21": 228,
      "2020-01-22": 230,
      "2020-01-23": 230
    },
    "adjEM": 2.06,
    "adjEmHistory": {
      "2020-01-19": 1.96,
      "2020-01-20": 1.96,
      "2020-01-21": 1.92,
      "2020-01-22": 1.93,
      "2020-01-23": 2.06
    },
    "adjOff": 106.3,
    "adjOffHistory": {
      "2020-01-19": 106,
      "2020-01-20": 106,
      "2020-01-21": 106.1,
      "2020-01-22": 106,
      "2020-01-23": 106.3
    },
    "adjOffRank": 73,
    "adjOffRankHistory": {
      "2020-01-19": 77,
      "2020-01-20": 76,
      "2020-01-21": 78,
      "2020-01-22": 76,
      "2020-01-23": 73
    },
    "adjTempo": 65.9,
    "adjTempoHistory": {
      "2020-01-19": 66.1,
      "2020-01-20": 66.1,
      "2020-01-21": 66,
      "2020-01-22": 65.9,
      "2020-01-23": 65.9
    },
    "adjTempoRank": 286,
    "adjTempoRankHistory": {
      "2020-01-19": 284,
      "2020-01-20": 284,
      "2020-01-21": 284,
      "2020-01-22": 285,
      "2020-01-23": 286
    },
    "conference": "CAA",
    "rank": 144,
    "rankHistory": {
      "2020-01-19": 146,
      "2020-01-20": 147,
      "2020-01-21": 147,
      "2020-01-22": 147,
      "2020-01-23": 144
    },
    "record": "10-9"
  },
  "Northern Arizona": {
    "adjDef": 105.3,
    "adjDefHistory": {
      "2020-01-19": 105.3,
      "2020-01-20": 105.3,
      "2020-01-21": 105.4,
      "2020-01-22": 105.3,
      "2020-01-23": 105.3
    },
    "adjDefRank": 248,
    "adjDefRankHistory": {
      "2020-01-19": 257,
      "2020-01-20": 256,
      "2020-01-21": 254,
      "2020-01-22": 252,
      "2020-01-23": 248
    },
    "adjEM": -3.87,
    "adjEmHistory": {
      "2020-01-19": -4.12,
      "2020-01-20": -4.12,
      "2020-01-21": -4.07,
      "2020-01-22": -3.98,
      "2020-01-23": -3.87
    },
    "adjOff": 101.5,
    "adjOffHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.4,
      "2020-01-22": 101.3,
      "2020-01-23": 101.5
    },
    "adjOffRank": 180,
    "adjOffRankHistory": {
      "2020-01-19": 182,
      "2020-01-20": 182,
      "2020-01-21": 180,
      "2020-01-22": 179,
      "2020-01-23": 180
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.6,
      "2020-01-20": 67.6,
      "2020-01-21": 67.4,
      "2020-01-22": 67.4,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 230,
    "adjTempoRankHistory": {
      "2020-01-19": 224,
      "2020-01-20": 224,
      "2020-01-21": 226,
      "2020-01-22": 226,
      "2020-01-23": 230
    },
    "conference": "BSky",
    "rank": 206,
    "rankHistory": {
      "2020-01-19": 213,
      "2020-01-20": 213,
      "2020-01-21": 211,
      "2020-01-22": 210,
      "2020-01-23": 206
    },
    "record": "9-7"
  },
  "Northern Colorado": {
    "adjDef": 98.8,
    "adjDefHistory": {
      "2020-01-19": 98.8,
      "2020-01-20": 98.8,
      "2020-01-21": 98.9,
      "2020-01-22": 98.7,
      "2020-01-23": 98.8
    },
    "adjDefRank": 121,
    "adjDefRankHistory": {
      "2020-01-19": 129,
      "2020-01-20": 129,
      "2020-01-21": 129,
      "2020-01-22": 121,
      "2020-01-23": 121
    },
    "adjEM": 5.21,
    "adjEmHistory": {
      "2020-01-19": 4.99,
      "2020-01-20": 4.99,
      "2020-01-21": 5.06,
      "2020-01-22": 5.17,
      "2020-01-23": 5.21
    },
    "adjOff": 104,
    "adjOffHistory": {
      "2020-01-19": 103.8,
      "2020-01-20": 103.8,
      "2020-01-21": 103.9,
      "2020-01-22": 103.8,
      "2020-01-23": 104
    },
    "adjOffRank": 125,
    "adjOffRankHistory": {
      "2020-01-19": 127,
      "2020-01-20": 127,
      "2020-01-21": 128,
      "2020-01-22": 125,
      "2020-01-23": 125
    },
    "adjTempo": 65.9,
    "adjTempoHistory": {
      "2020-01-19": 66.2,
      "2020-01-20": 66.2,
      "2020-01-21": 66,
      "2020-01-22": 65.9,
      "2020-01-23": 65.9
    },
    "adjTempoRank": 288,
    "adjTempoRankHistory": {
      "2020-01-19": 282,
      "2020-01-20": 282,
      "2020-01-21": 285,
      "2020-01-22": 286,
      "2020-01-23": 288
    },
    "conference": "BSky",
    "rank": 114,
    "rankHistory": {
      "2020-01-19": 117,
      "2020-01-20": 117,
      "2020-01-21": 117,
      "2020-01-22": 115,
      "2020-01-23": 114
    },
    "record": "11-6"
  },
  "Northern Illinois": {
    "adjDef": 99.4,
    "adjDefHistory": {
      "2020-01-19": 99.4,
      "2020-01-20": 99.4,
      "2020-01-21": 99.6,
      "2020-01-22": 99.3,
      "2020-01-23": 99.4
    },
    "adjDefRank": 135,
    "adjDefRankHistory": {
      "2020-01-19": 135,
      "2020-01-20": 135,
      "2020-01-21": 137,
      "2020-01-22": 134,
      "2020-01-23": 135
    },
    "adjEM": -1.36,
    "adjEmHistory": {
      "2020-01-19": -2.53,
      "2020-01-20": -2.53,
      "2020-01-21": -2.54,
      "2020-01-22": -1.17,
      "2020-01-23": -1.36
    },
    "adjOff": 98.1,
    "adjOffHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 96.9,
      "2020-01-21": 97,
      "2020-01-22": 98.2,
      "2020-01-23": 98.1
    },
    "adjOffRank": 238,
    "adjOffRankHistory": {
      "2020-01-19": 260,
      "2020-01-20": 262,
      "2020-01-21": 262,
      "2020-01-22": 234,
      "2020-01-23": 238
    },
    "adjTempo": 65.3,
    "adjTempoHistory": {
      "2020-01-19": 65.6,
      "2020-01-20": 65.6,
      "2020-01-21": 65.4,
      "2020-01-22": 65.4,
      "2020-01-23": 65.3
    },
    "adjTempoRank": 303,
    "adjTempoRankHistory": {
      "2020-01-19": 305,
      "2020-01-20": 304,
      "2020-01-21": 304,
      "2020-01-22": 302,
      "2020-01-23": 303
    },
    "conference": "MAC",
    "rank": 184,
    "rankHistory": {
      "2020-01-19": 193,
      "2020-01-20": 193,
      "2020-01-21": 194,
      "2020-01-22": 184,
      "2020-01-23": 184
    },
    "record": "10-9"
  },
  "Northern Iowa": {
    "adjDef": 98.9,
    "adjDefHistory": {
      "2020-01-19": 98.6,
      "2020-01-20": 98.6,
      "2020-01-21": 98.6,
      "2020-01-22": 98.5,
      "2020-01-23": 98.9
    },
    "adjDefRank": 127,
    "adjDefRankHistory": {
      "2020-01-19": 121,
      "2020-01-20": 121,
      "2020-01-21": 121,
      "2020-01-22": 118,
      "2020-01-23": 127
    },
    "adjEM": 13.76,
    "adjEmHistory": {
      "2020-01-19": 14.25,
      "2020-01-20": 14.25,
      "2020-01-21": 14.39,
      "2020-01-22": 14.54,
      "2020-01-23": 13.76
    },
    "adjOff": 112.7,
    "adjOffHistory": {
      "2020-01-19": 112.9,
      "2020-01-20": 112.9,
      "2020-01-21": 113,
      "2020-01-22": 113,
      "2020-01-23": 112.7
    },
    "adjOffRank": 15,
    "adjOffRankHistory": {
      "2020-01-19": 14,
      "2020-01-20": 14,
      "2020-01-21": 14,
      "2020-01-22": 14,
      "2020-01-23": 15
    },
    "adjTempo": 65.9,
    "adjTempoHistory": {
      "2020-01-19": 65.6,
      "2020-01-20": 65.6,
      "2020-01-21": 65.4,
      "2020-01-22": 65.4,
      "2020-01-23": 65.9
    },
    "adjTempoRank": 287,
    "adjTempoRankHistory": {
      "2020-01-19": 304,
      "2020-01-20": 303,
      "2020-01-21": 303,
      "2020-01-22": 303,
      "2020-01-23": 287
    },
    "conference": "MVC",
    "rank": 48,
    "rankHistory": {
      "2020-01-19": 48,
      "2020-01-20": 48,
      "2020-01-21": 47,
      "2020-01-22": 47,
      "2020-01-23": 48
    },
    "record": "16-3"
  },
  "Northern Kentucky": {
    "adjDef": 98.1,
    "adjDefHistory": {
      "2020-01-19": 98,
      "2020-01-20": 98,
      "2020-01-21": 98.1,
      "2020-01-22": 98.1,
      "2020-01-23": 98.1
    },
    "adjDefRank": 109,
    "adjDefRankHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 109,
      "2020-01-22": 109,
      "2020-01-23": 109
    },
    "adjEM": 5.89,
    "adjEmHistory": {
      "2020-01-19": 5.99,
      "2020-01-20": 5.99,
      "2020-01-21": 6.05,
      "2020-01-22": 5.93,
      "2020-01-23": 5.89
    },
    "adjOff": 104,
    "adjOffHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104.2,
      "2020-01-22": 104,
      "2020-01-23": 104
    },
    "adjOffRank": 123,
    "adjOffRankHistory": {
      "2020-01-19": 125,
      "2020-01-20": 125,
      "2020-01-21": 124,
      "2020-01-22": 124,
      "2020-01-23": 123
    },
    "adjTempo": 65.2,
    "adjTempoHistory": {
      "2020-01-19": 65.4,
      "2020-01-20": 65.4,
      "2020-01-21": 65.3,
      "2020-01-22": 65.3,
      "2020-01-23": 65.2
    },
    "adjTempoRank": 308,
    "adjTempoRankHistory": {
      "2020-01-19": 312,
      "2020-01-20": 310,
      "2020-01-21": 310,
      "2020-01-22": 309,
      "2020-01-23": 308
    },
    "conference": "Horz",
    "rank": 106,
    "rankHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108,
      "2020-01-22": 106,
      "2020-01-23": 106
    },
    "record": "14-6"
  },
  "Northwestern": {
    "adjDef": 99.2,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.4,
      "2020-01-22": 99.1,
      "2020-01-23": 99.2
    },
    "adjDefRank": 131,
    "adjDefRankHistory": {
      "2020-01-19": 115,
      "2020-01-20": 113,
      "2020-01-21": 116,
      "2020-01-22": 130,
      "2020-01-23": 131
    },
    "adjEM": 5.73,
    "adjEmHistory": {
      "2020-01-19": 6.23,
      "2020-01-20": 6.23,
      "2020-01-21": 6.29,
      "2020-01-22": 5.92,
      "2020-01-23": 5.73
    },
    "adjOff": 105,
    "adjOffHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.7,
      "2020-01-22": 105.1,
      "2020-01-23": 105
    },
    "adjOffRank": 101,
    "adjOffRankHistory": {
      "2020-01-19": 113,
      "2020-01-20": 115,
      "2020-01-21": 110,
      "2020-01-22": 95,
      "2020-01-23": 101
    },
    "adjTempo": 66.7,
    "adjTempoHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 66.9,
      "2020-01-22": 66.7,
      "2020-01-23": 66.7
    },
    "adjTempoRank": 260,
    "adjTempoRankHistory": {
      "2020-01-19": 251,
      "2020-01-20": 251,
      "2020-01-21": 254,
      "2020-01-22": 260,
      "2020-01-23": 260
    },
    "conference": "B10",
    "rank": 107,
    "rankHistory": {
      "2020-01-19": 106,
      "2020-01-20": 106,
      "2020-01-21": 105,
      "2020-01-22": 107,
      "2020-01-23": 107
    },
    "record": "6-12"
  },
  "Northwestern St.": {
    "adjDef": 106.4,
    "adjDefHistory": {
      "2020-01-19": 107.6,
      "2020-01-20": 107.6,
      "2020-01-21": 107.7,
      "2020-01-22": 107.5,
      "2020-01-23": 106.4
    },
    "adjDefRank": 275,
    "adjDefRankHistory": {
      "2020-01-19": 296,
      "2020-01-20": 296,
      "2020-01-21": 298,
      "2020-01-22": 299,
      "2020-01-23": 275
    },
    "adjEM": -12.76,
    "adjEmHistory": {
      "2020-01-19": -13.36,
      "2020-01-20": -13.36,
      "2020-01-21": -13.34,
      "2020-01-22": -13.22,
      "2020-01-23": -12.76
    },
    "adjOff": 93.6,
    "adjOffHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.3,
      "2020-01-22": 94.3,
      "2020-01-23": 93.6
    },
    "adjOffRank": 308,
    "adjOffRankHistory": {
      "2020-01-19": 306,
      "2020-01-20": 305,
      "2020-01-21": 304,
      "2020-01-22": 304,
      "2020-01-23": 308
    },
    "adjTempo": 72.1,
    "adjTempoHistory": {
      "2020-01-19": 72.5,
      "2020-01-20": 72.5,
      "2020-01-21": 72.3,
      "2020-01-22": 72.3,
      "2020-01-23": 72.1
    },
    "adjTempoRank": 28,
    "adjTempoRankHistory": {
      "2020-01-19": 28,
      "2020-01-20": 28,
      "2020-01-21": 28,
      "2020-01-22": 27,
      "2020-01-23": 28
    },
    "conference": "Slnd",
    "rank": 309,
    "rankHistory": {
      "2020-01-19": 313,
      "2020-01-20": 313,
      "2020-01-21": 312,
      "2020-01-22": 312,
      "2020-01-23": 309
    },
    "record": "7-10"
  },
  "Notre Dame": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 96.5,
      "2020-01-20": 96.6,
      "2020-01-21": 96.6,
      "2020-01-22": 96.5,
      "2020-01-23": 97.7
    },
    "adjDefRank": 100,
    "adjDefRankHistory": {
      "2020-01-19": 86,
      "2020-01-20": 86,
      "2020-01-21": 86,
      "2020-01-22": 86,
      "2020-01-23": 100
    },
    "adjEM": 11.2,
    "adjEmHistory": {
      "2020-01-19": 11.5,
      "2020-01-20": 11.52,
      "2020-01-21": 11.64,
      "2020-01-22": 11.65,
      "2020-01-23": 11.2
    },
    "adjOff": 108.9,
    "adjOffHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108.1,
      "2020-01-21": 108.3,
      "2020-01-22": 108.1,
      "2020-01-23": 108.9
    },
    "adjOffRank": 47,
    "adjOffRankHistory": {
      "2020-01-19": 51,
      "2020-01-20": 48,
      "2020-01-21": 49,
      "2020-01-22": 51,
      "2020-01-23": 47
    },
    "adjTempo": 67.9,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 68,
      "2020-01-21": 67.8,
      "2020-01-22": 67.8,
      "2020-01-23": 67.9
    },
    "adjTempoRank": 204,
    "adjTempoRankHistory": {
      "2020-01-19": 216,
      "2020-01-20": 213,
      "2020-01-21": 215,
      "2020-01-22": 214,
      "2020-01-23": 204
    },
    "conference": "ACC",
    "rank": 68,
    "rankHistory": {
      "2020-01-19": 61,
      "2020-01-20": 61,
      "2020-01-21": 60,
      "2020-01-22": 62,
      "2020-01-23": 68
    },
    "record": "11-7"
  },
  "Oakland": {
    "adjDef": 101.4,
    "adjDefHistory": {
      "2020-01-19": 101.3,
      "2020-01-20": 101.3,
      "2020-01-21": 101.5,
      "2020-01-22": 101.3,
      "2020-01-23": 101.4
    },
    "adjDefRank": 160,
    "adjDefRankHistory": {
      "2020-01-19": 162,
      "2020-01-20": 163,
      "2020-01-21": 165,
      "2020-01-22": 165,
      "2020-01-23": 160
    },
    "adjEM": -4.06,
    "adjEmHistory": {
      "2020-01-19": -4,
      "2020-01-20": -4,
      "2020-01-21": -4.01,
      "2020-01-22": -3.97,
      "2020-01-23": -4.06
    },
    "adjOff": 97.3,
    "adjOffHistory": {
      "2020-01-19": 97.3,
      "2020-01-20": 97.3,
      "2020-01-21": 97.4,
      "2020-01-22": 97.3,
      "2020-01-23": 97.3
    },
    "adjOffRank": 259,
    "adjOffRankHistory": {
      "2020-01-19": 251,
      "2020-01-20": 250,
      "2020-01-21": 252,
      "2020-01-22": 253,
      "2020-01-23": 259
    },
    "adjTempo": 65.3,
    "adjTempoHistory": {
      "2020-01-19": 65.5,
      "2020-01-20": 65.5,
      "2020-01-21": 65.4,
      "2020-01-22": 65.3,
      "2020-01-23": 65.3
    },
    "adjTempoRank": 304,
    "adjTempoRankHistory": {
      "2020-01-19": 307,
      "2020-01-20": 306,
      "2020-01-21": 306,
      "2020-01-22": 305,
      "2020-01-23": 304
    },
    "conference": "Horz",
    "rank": 210,
    "rankHistory": {
      "2020-01-19": 209,
      "2020-01-20": 209,
      "2020-01-21": 209,
      "2020-01-22": 208,
      "2020-01-23": 210
    },
    "record": "7-13"
  },
  "Ohio": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 104.9,
      "2020-01-20": 104.9,
      "2020-01-21": 105.1,
      "2020-01-22": 105.5,
      "2020-01-23": 105.7
    },
    "adjDefRank": 257,
    "adjDefRankHistory": {
      "2020-01-19": 247,
      "2020-01-20": 247,
      "2020-01-21": 247,
      "2020-01-22": 256,
      "2020-01-23": 257
    },
    "adjEM": -4.73,
    "adjEmHistory": {
      "2020-01-19": -4.05,
      "2020-01-20": -4.05,
      "2020-01-21": -4.12,
      "2020-01-22": -4.64,
      "2020-01-23": -4.73
    },
    "adjOff": 100.9,
    "adjOffHistory": {
      "2020-01-19": 100.8,
      "2020-01-20": 100.8,
      "2020-01-21": 100.9,
      "2020-01-22": 100.8,
      "2020-01-23": 100.9
    },
    "adjOffRank": 185,
    "adjOffRankHistory": {
      "2020-01-19": 187,
      "2020-01-20": 187,
      "2020-01-21": 187,
      "2020-01-22": 187,
      "2020-01-23": 185
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.4,
      "2020-01-20": 68.4,
      "2020-01-21": 68.3,
      "2020-01-22": 68.5,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 171,
    "adjTempoRankHistory": {
      "2020-01-19": 186,
      "2020-01-20": 186,
      "2020-01-21": 186,
      "2020-01-22": 172,
      "2020-01-23": 171
    },
    "conference": "MAC",
    "rank": 221,
    "rankHistory": {
      "2020-01-19": 210,
      "2020-01-20": 210,
      "2020-01-21": 213,
      "2020-01-22": 219,
      "2020-01-23": 221
    },
    "record": "10-9"
  },
  "Ohio St.": {
    "adjDef": 90.1,
    "adjDefHistory": {
      "2020-01-19": 90.2,
      "2020-01-20": 90.2,
      "2020-01-21": 90.3,
      "2020-01-22": 90.1,
      "2020-01-23": 90.1
    },
    "adjDefRank": 22,
    "adjDefRankHistory": {
      "2020-01-19": 24,
      "2020-01-20": 24,
      "2020-01-21": 23,
      "2020-01-22": 23,
      "2020-01-23": 22
    },
    "adjEM": 21.9,
    "adjEmHistory": {
      "2020-01-19": 21.63,
      "2020-01-20": 21.63,
      "2020-01-21": 21.69,
      "2020-01-22": 21.72,
      "2020-01-23": 21.9
    },
    "adjOff": 112,
    "adjOffHistory": {
      "2020-01-19": 111.9,
      "2020-01-20": 111.9,
      "2020-01-21": 112,
      "2020-01-22": 111.8,
      "2020-01-23": 112
    },
    "adjOffRank": 20,
    "adjOffRankHistory": {
      "2020-01-19": 22,
      "2020-01-20": 22,
      "2020-01-21": 22,
      "2020-01-22": 22,
      "2020-01-23": 20
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.6,
      "2020-01-22": 66.6,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 265,
    "adjTempoRankHistory": {
      "2020-01-19": 261,
      "2020-01-20": 261,
      "2020-01-21": 264,
      "2020-01-22": 265,
      "2020-01-23": 265
    },
    "conference": "B10",
    "rank": 12,
    "rankHistory": {
      "2020-01-19": 13,
      "2020-01-20": 13,
      "2020-01-21": 13,
      "2020-01-22": 12,
      "2020-01-23": 12
    },
    "record": "12-6"
  },
  "Oklahoma": {
    "adjDef": 94,
    "adjDefHistory": {
      "2020-01-19": 94.5,
      "2020-01-20": 94.5,
      "2020-01-21": 94.1,
      "2020-01-22": 94,
      "2020-01-23": 94
    },
    "adjDefRank": 57,
    "adjDefRankHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 58,
      "2020-01-22": 57,
      "2020-01-23": 57
    },
    "adjEM": 14.61,
    "adjEmHistory": {
      "2020-01-19": 14.04,
      "2020-01-20": 14.04,
      "2020-01-21": 14.51,
      "2020-01-22": 14.56,
      "2020-01-23": 14.61
    },
    "adjOff": 108.6,
    "adjOffHistory": {
      "2020-01-19": 108.5,
      "2020-01-20": 108.5,
      "2020-01-21": 108.6,
      "2020-01-22": 108.5,
      "2020-01-23": 108.6
    },
    "adjOffRank": 50,
    "adjOffRankHistory": {
      "2020-01-19": 45,
      "2020-01-20": 45,
      "2020-01-21": 46,
      "2020-01-22": 49,
      "2020-01-23": 50
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.1,
      "2020-01-22": 70.1,
      "2020-01-23": 70
    },
    "adjTempoRank": 92,
    "adjTempoRankHistory": {
      "2020-01-19": 74,
      "2020-01-20": 75,
      "2020-01-21": 92,
      "2020-01-22": 93,
      "2020-01-23": 92
    },
    "conference": "B12",
    "rank": 45,
    "rankHistory": {
      "2020-01-19": 49,
      "2020-01-20": 49,
      "2020-01-21": 46,
      "2020-01-22": 46,
      "2020-01-23": 45
    },
    "record": "12-6"
  },
  "Oklahoma St.": {
    "adjDef": 92.7,
    "adjDefHistory": {
      "2020-01-19": 91.7,
      "2020-01-20": 91.7,
      "2020-01-21": 91.8,
      "2020-01-22": 92.5,
      "2020-01-23": 92.7
    },
    "adjDefRank": 39,
    "adjDefRankHistory": {
      "2020-01-19": 31,
      "2020-01-20": 31,
      "2020-01-21": 30,
      "2020-01-22": 39,
      "2020-01-23": 39
    },
    "adjEM": 9.56,
    "adjEmHistory": {
      "2020-01-19": 10,
      "2020-01-20": 10,
      "2020-01-21": 9.92,
      "2020-01-22": 9.68,
      "2020-01-23": 9.56
    },
    "adjOff": 102.2,
    "adjOffHistory": {
      "2020-01-19": 101.7,
      "2020-01-20": 101.7,
      "2020-01-21": 101.7,
      "2020-01-22": 102.2,
      "2020-01-23": 102.2
    },
    "adjOffRank": 168,
    "adjOffRankHistory": {
      "2020-01-19": 175,
      "2020-01-20": 175,
      "2020-01-21": 174,
      "2020-01-22": 167,
      "2020-01-23": 168
    },
    "adjTempo": 69.2,
    "adjTempoHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 68.9,
      "2020-01-22": 69.3,
      "2020-01-23": 69.2
    },
    "adjTempoRank": 129,
    "adjTempoRankHistory": {
      "2020-01-19": 153,
      "2020-01-20": 153,
      "2020-01-21": 154,
      "2020-01-22": 129,
      "2020-01-23": 129
    },
    "conference": "B12",
    "rank": 81,
    "rankHistory": {
      "2020-01-19": 79,
      "2020-01-20": 79,
      "2020-01-21": 80,
      "2020-01-22": 80,
      "2020-01-23": 81
    },
    "record": "9-9"
  },
  "Old Dominion": {
    "adjDef": 95.1,
    "adjDefHistory": {
      "2020-01-19": 94.4,
      "2020-01-20": 94.4,
      "2020-01-21": 95.1,
      "2020-01-22": 95,
      "2020-01-23": 95.1
    },
    "adjDefRank": 69,
    "adjDefRankHistory": {
      "2020-01-19": 65,
      "2020-01-20": 64,
      "2020-01-21": 69,
      "2020-01-22": 69,
      "2020-01-23": 69
    },
    "adjEM": -0.39,
    "adjEmHistory": {
      "2020-01-19": -0.58,
      "2020-01-20": -0.58,
      "2020-01-21": -0.53,
      "2020-01-22": -0.44,
      "2020-01-23": -0.39
    },
    "adjOff": 94.7,
    "adjOffHistory": {
      "2020-01-19": 93.8,
      "2020-01-20": 93.8,
      "2020-01-21": 94.6,
      "2020-01-22": 94.5,
      "2020-01-23": 94.7
    },
    "adjOffRank": 302,
    "adjOffRankHistory": {
      "2020-01-19": 307,
      "2020-01-20": 307,
      "2020-01-21": 302,
      "2020-01-22": 302,
      "2020-01-23": 302
    },
    "adjTempo": 66,
    "adjTempoHistory": {
      "2020-01-19": 66.2,
      "2020-01-20": 66.2,
      "2020-01-21": 66.1,
      "2020-01-22": 66.1,
      "2020-01-23": 66
    },
    "adjTempoRank": 281,
    "adjTempoRankHistory": {
      "2020-01-19": 281,
      "2020-01-20": 281,
      "2020-01-21": 281,
      "2020-01-22": 281,
      "2020-01-23": 281
    },
    "conference": "CUSA",
    "rank": 175,
    "rankHistory": {
      "2020-01-19": 178,
      "2020-01-20": 178,
      "2020-01-21": 177,
      "2020-01-22": 176,
      "2020-01-23": 175
    },
    "record": "7-12"
  },
  "Oral Roberts": {
    "adjDef": 103.9,
    "adjDefHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104.2,
      "2020-01-22": 104,
      "2020-01-23": 103.9
    },
    "adjDefRank": 225,
    "adjDefRankHistory": {
      "2020-01-19": 226,
      "2020-01-20": 227,
      "2020-01-21": 229,
      "2020-01-22": 229,
      "2020-01-23": 225
    },
    "adjEM": 5.51,
    "adjEmHistory": {
      "2020-01-19": 5.08,
      "2020-01-20": 5.08,
      "2020-01-21": 5.13,
      "2020-01-22": 5.19,
      "2020-01-23": 5.51
    },
    "adjOff": 109.4,
    "adjOffHistory": {
      "2020-01-19": 109.1,
      "2020-01-20": 109.1,
      "2020-01-21": 109.3,
      "2020-01-22": 109.2,
      "2020-01-23": 109.4
    },
    "adjOffRank": 41,
    "adjOffRankHistory": {
      "2020-01-19": 41,
      "2020-01-20": 41,
      "2020-01-21": 41,
      "2020-01-22": 41,
      "2020-01-23": 41
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 69.9,
      "2020-01-20": 69.9,
      "2020-01-21": 69.8,
      "2020-01-22": 69.8,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 107,
    "adjTempoRankHistory": {
      "2020-01-19": 109,
      "2020-01-20": 109,
      "2020-01-21": 108,
      "2020-01-22": 109,
      "2020-01-23": 107
    },
    "conference": "Sum",
    "rank": 110,
    "rankHistory": {
      "2020-01-19": 115,
      "2020-01-20": 115,
      "2020-01-21": 116,
      "2020-01-22": 114,
      "2020-01-23": 110
    },
    "record": "11-8"
  },
  "Oregon": {
    "adjDef": 95.7,
    "adjDefHistory": {
      "2020-01-19": 95.4,
      "2020-01-20": 95.4,
      "2020-01-21": 95.5,
      "2020-01-22": 95.4,
      "2020-01-23": 95.7
    },
    "adjDefRank": 80,
    "adjDefRankHistory": {
      "2020-01-19": 75,
      "2020-01-20": 74,
      "2020-01-21": 75,
      "2020-01-22": 76,
      "2020-01-23": 80
    },
    "adjEM": 19.17,
    "adjEmHistory": {
      "2020-01-19": 19.23,
      "2020-01-20": 19.23,
      "2020-01-21": 19.29,
      "2020-01-22": 19.33,
      "2020-01-23": 19.17
    },
    "adjOff": 114.9,
    "adjOffHistory": {
      "2020-01-19": 114.6,
      "2020-01-20": 114.6,
      "2020-01-21": 114.8,
      "2020-01-22": 114.8,
      "2020-01-23": 114.9
    },
    "adjOffRank": 6,
    "adjOffRankHistory": {
      "2020-01-19": 6,
      "2020-01-20": 6,
      "2020-01-21": 6,
      "2020-01-22": 6,
      "2020-01-23": 6
    },
    "adjTempo": 64.5,
    "adjTempoHistory": {
      "2020-01-19": 64.7,
      "2020-01-20": 64.7,
      "2020-01-21": 64.6,
      "2020-01-22": 64.6,
      "2020-01-23": 64.5
    },
    "adjTempoRank": 327,
    "adjTempoRankHistory": {
      "2020-01-19": 324,
      "2020-01-20": 324,
      "2020-01-21": 324,
      "2020-01-22": 325,
      "2020-01-23": 327
    },
    "conference": "P12",
    "rank": 17,
    "rankHistory": {
      "2020-01-19": 18,
      "2020-01-20": 18,
      "2020-01-21": 18,
      "2020-01-22": 17,
      "2020-01-23": 17
    },
    "record": "15-4"
  },
  "Oregon St.": {
    "adjDef": 101.6,
    "adjDefHistory": {
      "2020-01-19": 101.5,
      "2020-01-20": 101.5,
      "2020-01-21": 101.7,
      "2020-01-22": 101.5,
      "2020-01-23": 101.6
    },
    "adjDefRank": 166,
    "adjDefRankHistory": {
      "2020-01-19": 164,
      "2020-01-20": 164,
      "2020-01-21": 167,
      "2020-01-22": 166,
      "2020-01-23": 166
    },
    "adjEM": 10.63,
    "adjEmHistory": {
      "2020-01-19": 10.57,
      "2020-01-20": 10.57,
      "2020-01-21": 10.56,
      "2020-01-22": 10.63,
      "2020-01-23": 10.63
    },
    "adjOff": 112.2,
    "adjOffHistory": {
      "2020-01-19": 112.1,
      "2020-01-20": 112.1,
      "2020-01-21": 112.3,
      "2020-01-22": 112.2,
      "2020-01-23": 112.2
    },
    "adjOffRank": 18,
    "adjOffRankHistory": {
      "2020-01-19": 20,
      "2020-01-20": 20,
      "2020-01-21": 20,
      "2020-01-22": 20,
      "2020-01-23": 18
    },
    "adjTempo": 66.9,
    "adjTempoHistory": {
      "2020-01-19": 67.1,
      "2020-01-20": 67.1,
      "2020-01-21": 66.9,
      "2020-01-22": 66.9,
      "2020-01-23": 66.9
    },
    "adjTempoRank": 255,
    "adjTempoRankHistory": {
      "2020-01-19": 250,
      "2020-01-20": 250,
      "2020-01-21": 253,
      "2020-01-22": 253,
      "2020-01-23": 255
    },
    "conference": "P12",
    "rank": 71,
    "rankHistory": {
      "2020-01-19": 73,
      "2020-01-20": 73,
      "2020-01-21": 72,
      "2020-01-22": 71,
      "2020-01-23": 71
    },
    "record": "12-6"
  },
  "Pacific": {
    "adjDef": 99.1,
    "adjDefHistory": {
      "2020-01-19": 99.1,
      "2020-01-20": 99.1,
      "2020-01-21": 99.2,
      "2020-01-22": 99,
      "2020-01-23": 99.1
    },
    "adjDefRank": 130,
    "adjDefRankHistory": {
      "2020-01-19": 131,
      "2020-01-20": 131,
      "2020-01-21": 131,
      "2020-01-22": 129,
      "2020-01-23": 130
    },
    "adjEM": 2.42,
    "adjEmHistory": {
      "2020-01-19": 2.1,
      "2020-01-20": 2.1,
      "2020-01-21": 2.17,
      "2020-01-22": 2.22,
      "2020-01-23": 2.42
    },
    "adjOff": 101.5,
    "adjOffHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.4,
      "2020-01-22": 101.3,
      "2020-01-23": 101.5
    },
    "adjOffRank": 179,
    "adjOffRankHistory": {
      "2020-01-19": 183,
      "2020-01-20": 184,
      "2020-01-21": 181,
      "2020-01-22": 180,
      "2020-01-23": 179
    },
    "adjTempo": 64.2,
    "adjTempoHistory": {
      "2020-01-19": 64.4,
      "2020-01-20": 64.4,
      "2020-01-21": 64.3,
      "2020-01-22": 64.2,
      "2020-01-23": 64.2
    },
    "adjTempoRank": 328,
    "adjTempoRankHistory": {
      "2020-01-19": 330,
      "2020-01-20": 330,
      "2020-01-21": 329,
      "2020-01-22": 328,
      "2020-01-23": 328
    },
    "conference": "WCC",
    "rank": 138,
    "rankHistory": {
      "2020-01-19": 143,
      "2020-01-20": 144,
      "2020-01-21": 141,
      "2020-01-22": 141,
      "2020-01-23": 138
    },
    "record": "15-6"
  },
  "Penn": {
    "adjDef": 102.8,
    "adjDefHistory": {
      "2020-01-19": 102.5,
      "2020-01-20": 102.5,
      "2020-01-21": 102.6,
      "2020-01-22": 102.5,
      "2020-01-23": 102.8
    },
    "adjDefRank": 196,
    "adjDefRankHistory": {
      "2020-01-19": 184,
      "2020-01-20": 184,
      "2020-01-21": 184,
      "2020-01-22": 185,
      "2020-01-23": 196
    },
    "adjEM": 0.79,
    "adjEmHistory": {
      "2020-01-19": 0.97,
      "2020-01-20": 0.97,
      "2020-01-21": 0.92,
      "2020-01-22": 0.95,
      "2020-01-23": 0.79
    },
    "adjOff": 103.6,
    "adjOffHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.5,
      "2020-01-22": 103.5,
      "2020-01-23": 103.6
    },
    "adjOffRank": 134,
    "adjOffRankHistory": {
      "2020-01-19": 136,
      "2020-01-20": 136,
      "2020-01-21": 141,
      "2020-01-22": 137,
      "2020-01-23": 134
    },
    "adjTempo": 70.8,
    "adjTempoHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 70.9,
      "2020-01-22": 70.9,
      "2020-01-23": 70.8
    },
    "adjTempoRank": 58,
    "adjTempoRankHistory": {
      "2020-01-19": 57,
      "2020-01-20": 57,
      "2020-01-21": 58,
      "2020-01-22": 58,
      "2020-01-23": 58
    },
    "conference": "Ivy",
    "rank": 157,
    "rankHistory": {
      "2020-01-19": 153,
      "2020-01-20": 153,
      "2020-01-21": 154,
      "2020-01-22": 155,
      "2020-01-23": 157
    },
    "record": "7-7"
  },
  "Penn St.": {
    "adjDef": 91.6,
    "adjDefHistory": {
      "2020-01-19": 92.9,
      "2020-01-20": 92.9,
      "2020-01-21": 93,
      "2020-01-22": 92.9,
      "2020-01-23": 91.6
    },
    "adjDefRank": 28,
    "adjDefRankHistory": {
      "2020-01-19": 45,
      "2020-01-20": 45,
      "2020-01-21": 44,
      "2020-01-22": 45,
      "2020-01-23": 28
    },
    "adjEM": 18.36,
    "adjEmHistory": {
      "2020-01-19": 17.33,
      "2020-01-20": 17.33,
      "2020-01-21": 17.32,
      "2020-01-22": 17.3,
      "2020-01-23": 18.36
    },
    "adjOff": 110,
    "adjOffHistory": {
      "2020-01-19": 110.2,
      "2020-01-20": 110.2,
      "2020-01-21": 110.3,
      "2020-01-22": 110.2,
      "2020-01-23": 110
    },
    "adjOffRank": 36,
    "adjOffRankHistory": {
      "2020-01-19": 33,
      "2020-01-20": 33,
      "2020-01-21": 33,
      "2020-01-22": 36,
      "2020-01-23": 36
    },
    "adjTempo": 71.8,
    "adjTempoHistory": {
      "2020-01-19": 71.8,
      "2020-01-20": 71.8,
      "2020-01-21": 71.7,
      "2020-01-22": 71.7,
      "2020-01-23": 71.8
    },
    "adjTempoRank": 32,
    "adjTempoRankHistory": {
      "2020-01-19": 41,
      "2020-01-20": 41,
      "2020-01-21": 39,
      "2020-01-22": 39,
      "2020-01-23": 32
    },
    "conference": "B10",
    "rank": 20,
    "rankHistory": {
      "2020-01-19": 28,
      "2020-01-20": 28,
      "2020-01-21": 28,
      "2020-01-22": 30,
      "2020-01-23": 20
    },
    "record": "14-5"
  },
  "Pepperdine": {
    "adjDef": 106,
    "adjDefHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.9,
      "2020-01-21": 106,
      "2020-01-22": 105.9,
      "2020-01-23": 106
    },
    "adjDefRank": 269,
    "adjDefRankHistory": {
      "2020-01-19": 268,
      "2020-01-20": 271,
      "2020-01-21": 270,
      "2020-01-22": 271,
      "2020-01-23": 269
    },
    "adjEM": 0.75,
    "adjEmHistory": {
      "2020-01-19": 0.74,
      "2020-01-20": 0.72,
      "2020-01-21": 0.74,
      "2020-01-22": 0.71,
      "2020-01-23": 0.75
    },
    "adjOff": 106.8,
    "adjOffHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.6,
      "2020-01-21": 106.7,
      "2020-01-22": 106.6,
      "2020-01-23": 106.8
    },
    "adjOffRank": 70,
    "adjOffRankHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 66,
      "2020-01-22": 68,
      "2020-01-23": 70
    },
    "adjTempo": 71.2,
    "adjTempoHistory": {
      "2020-01-19": 71.3,
      "2020-01-20": 71.3,
      "2020-01-21": 71.2,
      "2020-01-22": 71.2,
      "2020-01-23": 71.2
    },
    "adjTempoRank": 49,
    "adjTempoRankHistory": {
      "2020-01-19": 53,
      "2020-01-20": 53,
      "2020-01-21": 53,
      "2020-01-22": 53,
      "2020-01-23": 49
    },
    "conference": "WCC",
    "rank": 158,
    "rankHistory": {
      "2020-01-19": 157,
      "2020-01-20": 158,
      "2020-01-21": 158,
      "2020-01-22": 159,
      "2020-01-23": 158
    },
    "record": "9-10"
  },
  "Pittsburgh": {
    "adjDef": 95.4,
    "adjDefHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.3,
      "2020-01-22": 94.1,
      "2020-01-23": 95.4
    },
    "adjDefRank": 74,
    "adjDefRankHistory": {
      "2020-01-19": 61,
      "2020-01-20": 61,
      "2020-01-21": 61,
      "2020-01-22": 61,
      "2020-01-23": 74
    },
    "adjEM": 9.99,
    "adjEmHistory": {
      "2020-01-19": 10.71,
      "2020-01-20": 10.72,
      "2020-01-21": 10.77,
      "2020-01-22": 10.79,
      "2020-01-23": 9.99
    },
    "adjOff": 105.4,
    "adjOffHistory": {
      "2020-01-19": 104.9,
      "2020-01-20": 104.9,
      "2020-01-21": 105.1,
      "2020-01-22": 104.9,
      "2020-01-23": 105.4
    },
    "adjOffRank": 91,
    "adjOffRankHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 99,
      "2020-01-22": 101,
      "2020-01-23": 91
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.1,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 333,
    "adjTempoRankHistory": {
      "2020-01-19": 336,
      "2020-01-20": 336,
      "2020-01-21": 337,
      "2020-01-22": 336,
      "2020-01-23": 333
    },
    "conference": "ACC",
    "rank": 76,
    "rankHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 76
    },
    "record": "13-6"
  },
  "Portland": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.7,
      "2020-01-21": 103.9,
      "2020-01-22": 103.8,
      "2020-01-23": 103.7
    },
    "adjDefRank": 217,
    "adjDefRankHistory": {
      "2020-01-19": 221,
      "2020-01-20": 222,
      "2020-01-21": 223,
      "2020-01-22": 222,
      "2020-01-23": 217
    },
    "adjEM": -8.06,
    "adjEmHistory": {
      "2020-01-19": -8.23,
      "2020-01-20": -8.23,
      "2020-01-21": -8.2,
      "2020-01-22": -8.2,
      "2020-01-23": -8.06
    },
    "adjOff": 95.6,
    "adjOffHistory": {
      "2020-01-19": 95.5,
      "2020-01-20": 95.5,
      "2020-01-21": 95.7,
      "2020-01-22": 95.6,
      "2020-01-23": 95.6
    },
    "adjOffRank": 289,
    "adjOffRankHistory": {
      "2020-01-19": 290,
      "2020-01-20": 290,
      "2020-01-21": 290,
      "2020-01-22": 290,
      "2020-01-23": 289
    },
    "adjTempo": 67.8,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 67.8
    },
    "adjTempoRank": 208,
    "adjTempoRankHistory": {
      "2020-01-19": 203,
      "2020-01-20": 203,
      "2020-01-21": 206,
      "2020-01-22": 206,
      "2020-01-23": 208
    },
    "conference": "WCC",
    "rank": 263,
    "rankHistory": {
      "2020-01-19": 262,
      "2020-01-20": 262,
      "2020-01-21": 262,
      "2020-01-22": 262,
      "2020-01-23": 263
    },
    "record": "9-11"
  },
  "Portland St.": {
    "adjDef": 109.3,
    "adjDefHistory": {
      "2020-01-19": 109.3,
      "2020-01-20": 109.2,
      "2020-01-21": 109.3,
      "2020-01-22": 109.2,
      "2020-01-23": 109.3
    },
    "adjDefRank": 320,
    "adjDefRankHistory": {
      "2020-01-19": 320,
      "2020-01-20": 320,
      "2020-01-21": 318,
      "2020-01-22": 319,
      "2020-01-23": 320
    },
    "adjEM": -3.04,
    "adjEmHistory": {
      "2020-01-19": -3.58,
      "2020-01-20": -3.58,
      "2020-01-21": -3.16,
      "2020-01-22": -3.13,
      "2020-01-23": -3.04
    },
    "adjOff": 106.2,
    "adjOffHistory": {
      "2020-01-19": 105.7,
      "2020-01-20": 105.7,
      "2020-01-21": 106.2,
      "2020-01-22": 106.1,
      "2020-01-23": 106.2
    },
    "adjOffRank": 75,
    "adjOffRankHistory": {
      "2020-01-19": 81,
      "2020-01-20": 80,
      "2020-01-21": 76,
      "2020-01-22": 75,
      "2020-01-23": 75
    },
    "adjTempo": 70.1,
    "adjTempoHistory": {
      "2020-01-19": 69.7,
      "2020-01-20": 69.7,
      "2020-01-21": 70.1,
      "2020-01-22": 70.1,
      "2020-01-23": 70.1
    },
    "adjTempoRank": 90,
    "adjTempoRankHistory": {
      "2020-01-19": 122,
      "2020-01-20": 122,
      "2020-01-21": 93,
      "2020-01-22": 92,
      "2020-01-23": 90
    },
    "conference": "BSky",
    "rank": 196,
    "rankHistory": {
      "2020-01-19": 205,
      "2020-01-20": 205,
      "2020-01-21": 200,
      "2020-01-22": 200,
      "2020-01-23": 196
    },
    "record": "10-10"
  },
  "Prairie View A&M": {
    "adjDef": 98.3,
    "adjDefHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 99,
      "2020-01-21": 98.4,
      "2020-01-22": 98.3,
      "2020-01-23": 98.3
    },
    "adjDefRank": 112,
    "adjDefRankHistory": {
      "2020-01-19": 130,
      "2020-01-20": 130,
      "2020-01-21": 117,
      "2020-01-22": 115,
      "2020-01-23": 112
    },
    "adjEM": -2.34,
    "adjEmHistory": {
      "2020-01-19": -3.2,
      "2020-01-20": -3.22,
      "2020-01-21": -2.48,
      "2020-01-22": -2.42,
      "2020-01-23": -2.34
    },
    "adjOff": 96,
    "adjOffHistory": {
      "2020-01-19": 95.7,
      "2020-01-20": 95.8,
      "2020-01-21": 96,
      "2020-01-22": 95.9,
      "2020-01-23": 96
    },
    "adjOffRank": 286,
    "adjOffRankHistory": {
      "2020-01-19": 285,
      "2020-01-20": 285,
      "2020-01-21": 285,
      "2020-01-22": 286,
      "2020-01-23": 286
    },
    "adjTempo": 70.9,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.9,
      "2020-01-22": 70.9,
      "2020-01-23": 70.9
    },
    "adjTempoRank": 57,
    "adjTempoRankHistory": {
      "2020-01-19": 68,
      "2020-01-20": 67,
      "2020-01-21": 57,
      "2020-01-22": 57,
      "2020-01-23": 57
    },
    "conference": "SWAC",
    "rank": 191,
    "rankHistory": {
      "2020-01-19": 201,
      "2020-01-20": 201,
      "2020-01-21": 193,
      "2020-01-22": 193,
      "2020-01-23": 191
    },
    "record": "8-10"
  },
  "Presbyterian": {
    "adjDef": 111,
    "adjDefHistory": {
      "2020-01-19": 110.2,
      "2020-01-20": 110.2,
      "2020-01-21": 110.9,
      "2020-01-22": 110.8,
      "2020-01-23": 111
    },
    "adjDefRank": 333,
    "adjDefRankHistory": {
      "2020-01-19": 330,
      "2020-01-20": 330,
      "2020-01-21": 335,
      "2020-01-22": 335,
      "2020-01-23": 333
    },
    "adjEM": -15.5,
    "adjEmHistory": {
      "2020-01-19": -15.95,
      "2020-01-20": -15.95,
      "2020-01-21": -15.35,
      "2020-01-22": -15.39,
      "2020-01-23": -15.5
    },
    "adjOff": 95.5,
    "adjOffHistory": {
      "2020-01-19": 94.3,
      "2020-01-20": 94.3,
      "2020-01-21": 95.6,
      "2020-01-22": 95.5,
      "2020-01-23": 95.5
    },
    "adjOffRank": 292,
    "adjOffRankHistory": {
      "2020-01-19": 304,
      "2020-01-20": 304,
      "2020-01-21": 292,
      "2020-01-22": 291,
      "2020-01-23": 292
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 66.6,
      "2020-01-20": 66.6,
      "2020-01-21": 67.2,
      "2020-01-22": 67.1,
      "2020-01-23": 67
    },
    "adjTempoRank": 245,
    "adjTempoRankHistory": {
      "2020-01-19": 268,
      "2020-01-20": 268,
      "2020-01-21": 240,
      "2020-01-22": 240,
      "2020-01-23": 245
    },
    "conference": "BSth",
    "rank": 330,
    "rankHistory": {
      "2020-01-19": 333,
      "2020-01-20": 333,
      "2020-01-21": 331,
      "2020-01-22": 331,
      "2020-01-23": 330
    },
    "record": "8-11"
  },
  "Princeton": {
    "adjDef": 106.8,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 106.7,
      "2020-01-22": 106.6,
      "2020-01-23": 106.8
    },
    "adjDefRank": 288,
    "adjDefRankHistory": {
      "2020-01-19": 284,
      "2020-01-20": 283,
      "2020-01-21": 286,
      "2020-01-22": 284,
      "2020-01-23": 288
    },
    "adjEM": -2.54,
    "adjEmHistory": {
      "2020-01-19": -2.2,
      "2020-01-20": -2.2,
      "2020-01-21": -2.25,
      "2020-01-22": -2.26,
      "2020-01-23": -2.54
    },
    "adjOff": 104.3,
    "adjOffHistory": {
      "2020-01-19": 104.3,
      "2020-01-20": 104.3,
      "2020-01-21": 104.5,
      "2020-01-22": 104.4,
      "2020-01-23": 104.3
    },
    "adjOffRank": 119,
    "adjOffRankHistory": {
      "2020-01-19": 118,
      "2020-01-20": 118,
      "2020-01-21": 116,
      "2020-01-22": 115,
      "2020-01-23": 119
    },
    "adjTempo": 65.7,
    "adjTempoHistory": {
      "2020-01-19": 65.8,
      "2020-01-20": 65.8,
      "2020-01-21": 65.7,
      "2020-01-22": 65.7,
      "2020-01-23": 65.7
    },
    "adjTempoRank": 295,
    "adjTempoRankHistory": {
      "2020-01-19": 295,
      "2020-01-20": 295,
      "2020-01-21": 294,
      "2020-01-22": 295,
      "2020-01-23": 295
    },
    "conference": "Ivy",
    "rank": 194,
    "rankHistory": {
      "2020-01-19": 191,
      "2020-01-20": 191,
      "2020-01-21": 191,
      "2020-01-22": 191,
      "2020-01-23": 194
    },
    "record": "6-8"
  },
  "Providence": {
    "adjDef": 94.5,
    "adjDefHistory": {
      "2020-01-19": 93.9,
      "2020-01-20": 93.9,
      "2020-01-21": 94,
      "2020-01-22": 93.9,
      "2020-01-23": 94.5
    },
    "adjDefRank": 65,
    "adjDefRankHistory": {
      "2020-01-19": 56,
      "2020-01-20": 56,
      "2020-01-21": 57,
      "2020-01-22": 56,
      "2020-01-23": 65
    },
    "adjEM": 10.41,
    "adjEmHistory": {
      "2020-01-19": 10.5,
      "2020-01-20": 10.49,
      "2020-01-21": 10.45,
      "2020-01-22": 10.31,
      "2020-01-23": 10.41
    },
    "adjOff": 104.9,
    "adjOffHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.3,
      "2020-01-21": 104.5,
      "2020-01-22": 104.2,
      "2020-01-23": 104.9
    },
    "adjOffRank": 103,
    "adjOffRankHistory": {
      "2020-01-19": 117,
      "2020-01-20": 117,
      "2020-01-21": 117,
      "2020-01-22": 121,
      "2020-01-23": 103
    },
    "adjTempo": 68.4,
    "adjTempoHistory": {
      "2020-01-19": 68.9,
      "2020-01-20": 68.9,
      "2020-01-21": 68.8,
      "2020-01-22": 68.7,
      "2020-01-23": 68.4
    },
    "adjTempoRank": 177,
    "adjTempoRankHistory": {
      "2020-01-19": 157,
      "2020-01-20": 157,
      "2020-01-21": 160,
      "2020-01-22": 159,
      "2020-01-23": 177
    },
    "conference": "BE",
    "rank": 72,
    "rankHistory": {
      "2020-01-19": 74,
      "2020-01-20": 74,
      "2020-01-21": 74,
      "2020-01-22": 74,
      "2020-01-23": 72
    },
    "record": "11-9"
  },
  "Purdue": {
    "adjDef": 88.9,
    "adjDefHistory": {
      "2020-01-19": 86.8,
      "2020-01-20": 86.8,
      "2020-01-21": 86.9,
      "2020-01-22": 88.7,
      "2020-01-23": 88.9
    },
    "adjDefRank": 14,
    "adjDefRankHistory": {
      "2020-01-19": 6,
      "2020-01-20": 6,
      "2020-01-21": 6,
      "2020-01-22": 15,
      "2020-01-23": 14
    },
    "adjEM": 17.4,
    "adjEmHistory": {
      "2020-01-19": 19.49,
      "2020-01-20": 19.49,
      "2020-01-21": 19.36,
      "2020-01-22": 17.58,
      "2020-01-23": 17.4
    },
    "adjOff": 106.3,
    "adjOffHistory": {
      "2020-01-19": 106.2,
      "2020-01-20": 106.2,
      "2020-01-21": 106.3,
      "2020-01-22": 106.3,
      "2020-01-23": 106.3
    },
    "adjOffRank": 72,
    "adjOffRankHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 74,
      "2020-01-22": 71,
      "2020-01-23": 72
    },
    "adjTempo": 62.9,
    "adjTempoHistory": {
      "2020-01-19": 63.1,
      "2020-01-20": 63.1,
      "2020-01-21": 63,
      "2020-01-22": 62.9,
      "2020-01-23": 62.9
    },
    "adjTempoRank": 347,
    "adjTempoRankHistory": {
      "2020-01-19": 346,
      "2020-01-20": 346,
      "2020-01-21": 346,
      "2020-01-22": 346,
      "2020-01-23": 347
    },
    "conference": "B10",
    "rank": 30,
    "rankHistory": {
      "2020-01-19": 17,
      "2020-01-20": 17,
      "2020-01-21": 17,
      "2020-01-22": 27,
      "2020-01-23": 30
    },
    "record": "10-9"
  },
  "Purdue Fort Wayne": {
    "adjDef": 104.9,
    "adjDefHistory": {
      "2020-01-19": 104.9,
      "2020-01-20": 104.9,
      "2020-01-21": 105,
      "2020-01-22": 104.9,
      "2020-01-23": 104.9
    },
    "adjDefRank": 243,
    "adjDefRankHistory": {
      "2020-01-19": 246,
      "2020-01-20": 246,
      "2020-01-21": 246,
      "2020-01-22": 245,
      "2020-01-23": 243
    },
    "adjEM": -7.99,
    "adjEmHistory": {
      "2020-01-19": -7.82,
      "2020-01-20": -7.82,
      "2020-01-21": -7.81,
      "2020-01-22": -7.93,
      "2020-01-23": -7.99
    },
    "adjOff": 96.9,
    "adjOffHistory": {
      "2020-01-19": 97.1,
      "2020-01-20": 97.1,
      "2020-01-21": 97.2,
      "2020-01-22": 96.9,
      "2020-01-23": 96.9
    },
    "adjOffRank": 266,
    "adjOffRankHistory": {
      "2020-01-19": 256,
      "2020-01-20": 256,
      "2020-01-21": 260,
      "2020-01-22": 262,
      "2020-01-23": 266
    },
    "adjTempo": 68,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.2,
      "2020-01-21": 68.1,
      "2020-01-22": 68,
      "2020-01-23": 68
    },
    "adjTempoRank": 203,
    "adjTempoRankHistory": {
      "2020-01-19": 196,
      "2020-01-20": 196,
      "2020-01-21": 198,
      "2020-01-22": 202,
      "2020-01-23": 203
    },
    "conference": "Sum",
    "rank": 260,
    "rankHistory": {
      "2020-01-19": 257,
      "2020-01-20": 257,
      "2020-01-21": 259,
      "2020-01-22": 260,
      "2020-01-23": 260
    },
    "record": "9-11"
  },
  "Quinnipiac": {
    "adjDef": 105.4,
    "adjDefHistory": {
      "2020-01-19": 105.3,
      "2020-01-20": 105.3,
      "2020-01-21": 105.4,
      "2020-01-22": 105.4,
      "2020-01-23": 105.4
    },
    "adjDefRank": 250,
    "adjDefRankHistory": {
      "2020-01-19": 255,
      "2020-01-20": 255,
      "2020-01-21": 253,
      "2020-01-22": 253,
      "2020-01-23": 250
    },
    "adjEM": -5.72,
    "adjEmHistory": {
      "2020-01-19": -5.82,
      "2020-01-20": -5.82,
      "2020-01-21": -5.81,
      "2020-01-22": -5.86,
      "2020-01-23": -5.72
    },
    "adjOff": 99.7,
    "adjOffHistory": {
      "2020-01-19": 99.5,
      "2020-01-20": 99.5,
      "2020-01-21": 99.6,
      "2020-01-22": 99.5,
      "2020-01-23": 99.7
    },
    "adjOffRank": 206,
    "adjOffRankHistory": {
      "2020-01-19": 210,
      "2020-01-20": 209,
      "2020-01-21": 210,
      "2020-01-22": 209,
      "2020-01-23": 206
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.6,
      "2020-01-20": 68.6,
      "2020-01-21": 68.4,
      "2020-01-22": 68.4,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 184,
    "adjTempoRankHistory": {
      "2020-01-19": 177,
      "2020-01-20": 176,
      "2020-01-21": 177,
      "2020-01-22": 177,
      "2020-01-23": 184
    },
    "conference": "MAAC",
    "rank": 235,
    "rankHistory": {
      "2020-01-19": 233,
      "2020-01-20": 233,
      "2020-01-21": 235,
      "2020-01-22": 236,
      "2020-01-23": 235
    },
    "record": "9-7"
  },
  "Radford": {
    "adjDef": 102.4,
    "adjDefHistory": {
      "2020-01-19": 102.9,
      "2020-01-20": 102.9,
      "2020-01-21": 102.5,
      "2020-01-22": 102.3,
      "2020-01-23": 102.4
    },
    "adjDefRank": 183,
    "adjDefRankHistory": {
      "2020-01-19": 202,
      "2020-01-20": 203,
      "2020-01-21": 181,
      "2020-01-22": 180,
      "2020-01-23": 183
    },
    "adjEM": 2.02,
    "adjEmHistory": {
      "2020-01-19": 2.87,
      "2020-01-20": 2.87,
      "2020-01-21": 2.1,
      "2020-01-22": 2.09,
      "2020-01-23": 2.02
    },
    "adjOff": 104.4,
    "adjOffHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 104.5,
      "2020-01-22": 104.4,
      "2020-01-23": 104.4
    },
    "adjOffRank": 115,
    "adjOffRankHistory": {
      "2020-01-19": 79,
      "2020-01-20": 78,
      "2020-01-21": 112,
      "2020-01-22": 113,
      "2020-01-23": 115
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.6,
      "2020-01-20": 64.6,
      "2020-01-21": 64.2,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 331,
    "adjTempoRankHistory": {
      "2020-01-19": 328,
      "2020-01-20": 328,
      "2020-01-21": 332,
      "2020-01-22": 332,
      "2020-01-23": 331
    },
    "conference": "BSth",
    "rank": 146,
    "rankHistory": {
      "2020-01-19": 134,
      "2020-01-20": 134,
      "2020-01-21": 143,
      "2020-01-22": 144,
      "2020-01-23": 146
    },
    "record": "10-8"
  },
  "Rhode Island": {
    "adjDef": 92.1,
    "adjDefHistory": {
      "2020-01-19": 92.6,
      "2020-01-20": 92.6,
      "2020-01-21": 92.7,
      "2020-01-22": 92.5,
      "2020-01-23": 92.1
    },
    "adjDefRank": 33,
    "adjDefRankHistory": {
      "2020-01-19": 40,
      "2020-01-20": 40,
      "2020-01-21": 38,
      "2020-01-22": 38,
      "2020-01-23": 33
    },
    "adjEM": 12.11,
    "adjEmHistory": {
      "2020-01-19": 10.62,
      "2020-01-20": 10.62,
      "2020-01-21": 10.68,
      "2020-01-22": 10.72,
      "2020-01-23": 12.11
    },
    "adjOff": 104.2,
    "adjOffHistory": {
      "2020-01-19": 103.2,
      "2020-01-20": 103.2,
      "2020-01-21": 103.3,
      "2020-01-22": 103.2,
      "2020-01-23": 104.2
    },
    "adjOffRank": 121,
    "adjOffRankHistory": {
      "2020-01-19": 145,
      "2020-01-20": 146,
      "2020-01-21": 147,
      "2020-01-22": 145,
      "2020-01-23": 121
    },
    "adjTempo": 71.1,
    "adjTempoHistory": {
      "2020-01-19": 71.4,
      "2020-01-20": 71.4,
      "2020-01-21": 71.3,
      "2020-01-22": 71.3,
      "2020-01-23": 71.1
    },
    "adjTempoRank": 52,
    "adjTempoRankHistory": {
      "2020-01-19": 52,
      "2020-01-20": 52,
      "2020-01-21": 51,
      "2020-01-22": 51,
      "2020-01-23": 52
    },
    "conference": "A10",
    "rank": 59,
    "rankHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 69,
      "2020-01-22": 70,
      "2020-01-23": 59
    },
    "record": "13-5"
  },
  "Rice": {
    "adjDef": 106.3,
    "adjDefHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 106.3,
      "2020-01-22": 106.2,
      "2020-01-23": 106.3
    },
    "adjDefRank": 273,
    "adjDefRankHistory": {
      "2020-01-19": 271,
      "2020-01-20": 269,
      "2020-01-21": 275,
      "2020-01-22": 275,
      "2020-01-23": 273
    },
    "adjEM": -5.36,
    "adjEmHistory": {
      "2020-01-19": -4.84,
      "2020-01-20": -4.83,
      "2020-01-21": -5.33,
      "2020-01-22": -5.33,
      "2020-01-23": -5.36
    },
    "adjOff": 100.9,
    "adjOffHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 101,
      "2020-01-22": 100.9,
      "2020-01-23": 100.9
    },
    "adjOffRank": 187,
    "adjOffRankHistory": {
      "2020-01-19": 185,
      "2020-01-20": 185,
      "2020-01-21": 186,
      "2020-01-22": 185,
      "2020-01-23": 187
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.7,
      "2020-01-22": 70.7,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 63,
    "adjTempoRankHistory": {
      "2020-01-19": 63,
      "2020-01-20": 64,
      "2020-01-21": 68,
      "2020-01-22": 68,
      "2020-01-23": 63
    },
    "conference": "CUSA",
    "rank": 228,
    "rankHistory": {
      "2020-01-19": 221,
      "2020-01-20": 221,
      "2020-01-21": 228,
      "2020-01-22": 226,
      "2020-01-23": 228
    },
    "record": "9-11"
  },
  "Richmond": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 98.3,
      "2020-01-20": 98.3,
      "2020-01-21": 98.5,
      "2020-01-22": 98.4,
      "2020-01-23": 97.7
    },
    "adjDefRank": 101,
    "adjDefRankHistory": {
      "2020-01-19": 117,
      "2020-01-20": 117,
      "2020-01-21": 119,
      "2020-01-22": 116,
      "2020-01-23": 101
    },
    "adjEM": 11.34,
    "adjEmHistory": {
      "2020-01-19": 10.71,
      "2020-01-20": 10.71,
      "2020-01-21": 10.56,
      "2020-01-22": 10.6,
      "2020-01-23": 11.34
    },
    "adjOff": 109.1,
    "adjOffHistory": {
      "2020-01-19": 109,
      "2020-01-20": 109,
      "2020-01-21": 109.1,
      "2020-01-22": 109,
      "2020-01-23": 109.1
    },
    "adjOffRank": 43,
    "adjOffRankHistory": {
      "2020-01-19": 43,
      "2020-01-20": 43,
      "2020-01-21": 43,
      "2020-01-22": 43,
      "2020-01-23": 43
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 200,
    "adjTempoRankHistory": {
      "2020-01-19": 206,
      "2020-01-20": 207,
      "2020-01-21": 205,
      "2020-01-22": 208,
      "2020-01-23": 200
    },
    "conference": "A10",
    "rank": 67,
    "rankHistory": {
      "2020-01-19": 68,
      "2020-01-20": 69,
      "2020-01-21": 71,
      "2020-01-22": 72,
      "2020-01-23": 67
    },
    "record": "15-4"
  },
  "Rider": {
    "adjDef": 104.9,
    "adjDefHistory": {
      "2020-01-19": 104.7,
      "2020-01-20": 104.8,
      "2020-01-21": 104.9,
      "2020-01-22": 104.8,
      "2020-01-23": 104.9
    },
    "adjDefRank": 241,
    "adjDefRankHistory": {
      "2020-01-19": 240,
      "2020-01-20": 240,
      "2020-01-21": 243,
      "2020-01-22": 244,
      "2020-01-23": 241
    },
    "adjEM": -2.19,
    "adjEmHistory": {
      "2020-01-19": -2.07,
      "2020-01-20": -2.07,
      "2020-01-21": -2.17,
      "2020-01-22": -2.18,
      "2020-01-23": -2.19
    },
    "adjOff": 102.7,
    "adjOffHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.6,
      "2020-01-23": 102.7
    },
    "adjOffRank": 158,
    "adjOffRankHistory": {
      "2020-01-19": 154,
      "2020-01-20": 154,
      "2020-01-21": 159,
      "2020-01-22": 158,
      "2020-01-23": 158
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.7,
      "2020-01-20": 70.7,
      "2020-01-21": 70.6,
      "2020-01-22": 70.6,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 71,
    "adjTempoRankHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 69,
      "2020-01-22": 70,
      "2020-01-23": 71
    },
    "conference": "MAAC",
    "rank": 188,
    "rankHistory": {
      "2020-01-19": 190,
      "2020-01-20": 190,
      "2020-01-21": 189,
      "2020-01-22": 190,
      "2020-01-23": 188
    },
    "record": "9-8"
  },
  "Robert Morris": {
    "adjDef": 104,
    "adjDefHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 103.9,
      "2020-01-22": 103.8,
      "2020-01-23": 104
    },
    "adjDefRank": 226,
    "adjDefRankHistory": {
      "2020-01-19": 245,
      "2020-01-20": 245,
      "2020-01-21": 224,
      "2020-01-22": 223,
      "2020-01-23": 226
    },
    "adjEM": -4.42,
    "adjEmHistory": {
      "2020-01-19": -5.42,
      "2020-01-20": -5.41,
      "2020-01-21": -4.36,
      "2020-01-22": -4.31,
      "2020-01-23": -4.42
    },
    "adjOff": 99.5,
    "adjOffHistory": {
      "2020-01-19": 99.4,
      "2020-01-20": 99.4,
      "2020-01-21": 99.6,
      "2020-01-22": 99.5,
      "2020-01-23": 99.5
    },
    "adjOffRank": 211,
    "adjOffRankHistory": {
      "2020-01-19": 211,
      "2020-01-20": 211,
      "2020-01-21": 211,
      "2020-01-22": 211,
      "2020-01-23": 211
    },
    "adjTempo": 66.4,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.5,
      "2020-01-22": 66.5,
      "2020-01-23": 66.4
    },
    "adjTempoRank": 270,
    "adjTempoRankHistory": {
      "2020-01-19": 259,
      "2020-01-20": 260,
      "2020-01-21": 268,
      "2020-01-22": 268,
      "2020-01-23": 270
    },
    "conference": "NEC",
    "rank": 216,
    "rankHistory": {
      "2020-01-19": 230,
      "2020-01-20": 230,
      "2020-01-21": 216,
      "2020-01-22": 214,
      "2020-01-23": 216
    },
    "record": "9-10"
  },
  "Rutgers": {
    "adjDef": 88.1,
    "adjDefHistory": {
      "2020-01-19": 87.4,
      "2020-01-20": 87.4,
      "2020-01-21": 87.5,
      "2020-01-22": 87.2,
      "2020-01-23": 88.1
    },
    "adjDefRank": 9,
    "adjDefRankHistory": {
      "2020-01-19": 9,
      "2020-01-20": 9,
      "2020-01-21": 9,
      "2020-01-22": 7,
      "2020-01-23": 9
    },
    "adjEM": 17.98,
    "adjEmHistory": {
      "2020-01-19": 17.83,
      "2020-01-20": 17.83,
      "2020-01-21": 17.87,
      "2020-01-22": 18,
      "2020-01-23": 17.98
    },
    "adjOff": 106.1,
    "adjOffHistory": {
      "2020-01-19": 105.2,
      "2020-01-20": 105.2,
      "2020-01-21": 105.4,
      "2020-01-22": 105.2,
      "2020-01-23": 106.1
    },
    "adjOffRank": 78,
    "adjOffRankHistory": {
      "2020-01-19": 92,
      "2020-01-20": 91,
      "2020-01-21": 91,
      "2020-01-22": 92,
      "2020-01-23": 78
    },
    "adjTempo": 67.5,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.3,
      "2020-01-23": 67.5
    },
    "adjTempoRank": 220,
    "adjTempoRankHistory": {
      "2020-01-19": 227,
      "2020-01-20": 227,
      "2020-01-21": 229,
      "2020-01-22": 232,
      "2020-01-23": 220
    },
    "conference": "B10",
    "rank": 24,
    "rankHistory": {
      "2020-01-19": 24,
      "2020-01-20": 24,
      "2020-01-21": 24,
      "2020-01-22": 25,
      "2020-01-23": 24
    },
    "record": "14-5"
  },
  "SIU Edwardsville": {
    "adjDef": 110.7,
    "adjDefHistory": {
      "2020-01-19": 110.5,
      "2020-01-20": 110.5,
      "2020-01-21": 110.7,
      "2020-01-22": 110.6,
      "2020-01-23": 110.7
    },
    "adjDefRank": 331,
    "adjDefRankHistory": {
      "2020-01-19": 333,
      "2020-01-20": 333,
      "2020-01-21": 333,
      "2020-01-22": 332,
      "2020-01-23": 331
    },
    "adjEM": -18.8,
    "adjEmHistory": {
      "2020-01-19": -18.67,
      "2020-01-20": -18.68,
      "2020-01-21": -18.66,
      "2020-01-22": -18.65,
      "2020-01-23": -18.8
    },
    "adjOff": 91.9,
    "adjOffHistory": {
      "2020-01-19": 91.9,
      "2020-01-20": 91.9,
      "2020-01-21": 92.1,
      "2020-01-22": 91.9,
      "2020-01-23": 91.9
    },
    "adjOffRank": 326,
    "adjOffRankHistory": {
      "2020-01-19": 324,
      "2020-01-20": 324,
      "2020-01-21": 324,
      "2020-01-22": 324,
      "2020-01-23": 326
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.1,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 145,
    "adjTempoRankHistory": {
      "2020-01-19": 140,
      "2020-01-20": 140,
      "2020-01-21": 143,
      "2020-01-22": 143,
      "2020-01-23": 145
    },
    "conference": "OVC",
    "rank": 341,
    "rankHistory": {
      "2020-01-19": 341,
      "2020-01-20": 341,
      "2020-01-21": 341,
      "2020-01-22": 341,
      "2020-01-23": 341
    },
    "record": "4-15"
  },
  "SMU": {
    "adjDef": 101.5,
    "adjDefHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 101.1,
      "2020-01-22": 101,
      "2020-01-23": 101.5
    },
    "adjDefRank": 163,
    "adjDefRankHistory": {
      "2020-01-19": 155,
      "2020-01-20": 156,
      "2020-01-21": 158,
      "2020-01-22": 157,
      "2020-01-23": 163
    },
    "adjEM": 10.34,
    "adjEmHistory": {
      "2020-01-19": 10.15,
      "2020-01-20": 10.15,
      "2020-01-21": 10.13,
      "2020-01-22": 10.12,
      "2020-01-23": 10.34
    },
    "adjOff": 111.9,
    "adjOffHistory": {
      "2020-01-19": 111.1,
      "2020-01-20": 111.1,
      "2020-01-21": 111.3,
      "2020-01-22": 111.2,
      "2020-01-23": 111.9
    },
    "adjOffRank": 22,
    "adjOffRankHistory": {
      "2020-01-19": 25,
      "2020-01-20": 25,
      "2020-01-21": 24,
      "2020-01-22": 24,
      "2020-01-23": 22
    },
    "adjTempo": 64.8,
    "adjTempoHistory": {
      "2020-01-19": 65.2,
      "2020-01-20": 65.2,
      "2020-01-21": 65.1,
      "2020-01-22": 65.1,
      "2020-01-23": 64.8
    },
    "adjTempoRank": 321,
    "adjTempoRankHistory": {
      "2020-01-19": 315,
      "2020-01-20": 314,
      "2020-01-21": 314,
      "2020-01-22": 314,
      "2020-01-23": 321
    },
    "conference": "Amer",
    "rank": 73,
    "rankHistory": {
      "2020-01-19": 76,
      "2020-01-20": 76,
      "2020-01-21": 77,
      "2020-01-22": 75,
      "2020-01-23": 73
    },
    "record": "14-4"
  },
  "Sacramento St.": {
    "adjDef": 96.8,
    "adjDefHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 96.9,
      "2020-01-21": 97,
      "2020-01-22": 96.8,
      "2020-01-23": 96.8
    },
    "adjDefRank": 88,
    "adjDefRankHistory": {
      "2020-01-19": 90,
      "2020-01-20": 89,
      "2020-01-21": 89,
      "2020-01-22": 89,
      "2020-01-23": 88
    },
    "adjEM": -2.48,
    "adjEmHistory": {
      "2020-01-19": -2.72,
      "2020-01-20": -2.72,
      "2020-01-21": -2.67,
      "2020-01-22": -2.61,
      "2020-01-23": -2.48
    },
    "adjOff": 94.3,
    "adjOffHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.3,
      "2020-01-22": 94.2,
      "2020-01-23": 94.3
    },
    "adjOffRank": 305,
    "adjOffRankHistory": {
      "2020-01-19": 305,
      "2020-01-20": 306,
      "2020-01-21": 305,
      "2020-01-22": 306,
      "2020-01-23": 305
    },
    "adjTempo": 63.8,
    "adjTempoHistory": {
      "2020-01-19": 64.1,
      "2020-01-20": 64,
      "2020-01-21": 63.9,
      "2020-01-22": 63.8,
      "2020-01-23": 63.8
    },
    "adjTempoRank": 338,
    "adjTempoRankHistory": {
      "2020-01-19": 339,
      "2020-01-20": 339,
      "2020-01-21": 339,
      "2020-01-22": 339,
      "2020-01-23": 338
    },
    "conference": "BSky",
    "rank": 193,
    "rankHistory": {
      "2020-01-19": 194,
      "2020-01-20": 194,
      "2020-01-21": 195,
      "2020-01-22": 195,
      "2020-01-23": 193
    },
    "record": "10-6"
  },
  "Sacred Heart": {
    "adjDef": 104.8,
    "adjDefHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 104.9,
      "2020-01-22": 104.8,
      "2020-01-23": 104.8
    },
    "adjDefRank": 238,
    "adjDefRankHistory": {
      "2020-01-19": 250,
      "2020-01-20": 250,
      "2020-01-21": 242,
      "2020-01-22": 242,
      "2020-01-23": 238
    },
    "adjEM": -3.68,
    "adjEmHistory": {
      "2020-01-19": -2.85,
      "2020-01-20": -2.85,
      "2020-01-21": -3.57,
      "2020-01-22": -3.59,
      "2020-01-23": -3.68
    },
    "adjOff": 101.1,
    "adjOffHistory": {
      "2020-01-19": 102.1,
      "2020-01-20": 102.2,
      "2020-01-21": 101.3,
      "2020-01-22": 101.2,
      "2020-01-23": 101.1
    },
    "adjOffRank": 182,
    "adjOffRankHistory": {
      "2020-01-19": 166,
      "2020-01-20": 166,
      "2020-01-21": 183,
      "2020-01-22": 181,
      "2020-01-23": 182
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.2,
      "2020-01-22": 70.1,
      "2020-01-23": 70
    },
    "adjTempoRank": 91,
    "adjTempoRankHistory": {
      "2020-01-19": 83,
      "2020-01-20": 83,
      "2020-01-21": 90,
      "2020-01-22": 91,
      "2020-01-23": 91
    },
    "conference": "NEC",
    "rank": 205,
    "rankHistory": {
      "2020-01-19": 195,
      "2020-01-20": 195,
      "2020-01-21": 206,
      "2020-01-22": 206,
      "2020-01-23": 205
    },
    "record": "10-9"
  },
  "Saint Joseph's": {
    "adjDef": 107.4,
    "adjDefHistory": {
      "2020-01-19": 107.8,
      "2020-01-20": 107.8,
      "2020-01-21": 108,
      "2020-01-22": 107.4,
      "2020-01-23": 107.4
    },
    "adjDefRank": 297,
    "adjDefRankHistory": {
      "2020-01-19": 304,
      "2020-01-20": 304,
      "2020-01-21": 306,
      "2020-01-22": 297,
      "2020-01-23": 297
    },
    "adjEM": -6.57,
    "adjEmHistory": {
      "2020-01-19": -6.35,
      "2020-01-20": -6.35,
      "2020-01-21": -6.52,
      "2020-01-22": -6.55,
      "2020-01-23": -6.57
    },
    "adjOff": 100.8,
    "adjOffHistory": {
      "2020-01-19": 101.5,
      "2020-01-20": 101.5,
      "2020-01-21": 101.5,
      "2020-01-22": 100.9,
      "2020-01-23": 100.8
    },
    "adjOffRank": 188,
    "adjOffRankHistory": {
      "2020-01-19": 178,
      "2020-01-20": 178,
      "2020-01-21": 177,
      "2020-01-22": 186,
      "2020-01-23": 188
    },
    "adjTempo": 72.3,
    "adjTempoHistory": {
      "2020-01-19": 72.5,
      "2020-01-20": 72.5,
      "2020-01-21": 72.4,
      "2020-01-22": 72.3,
      "2020-01-23": 72.3
    },
    "adjTempoRank": 27,
    "adjTempoRankHistory": {
      "2020-01-19": 26,
      "2020-01-20": 26,
      "2020-01-21": 26,
      "2020-01-22": 28,
      "2020-01-23": 27
    },
    "conference": "A10",
    "rank": 244,
    "rankHistory": {
      "2020-01-19": 242,
      "2020-01-20": 242,
      "2020-01-21": 244,
      "2020-01-22": 244,
      "2020-01-23": 244
    },
    "record": "4-15"
  },
  "Saint Louis": {
    "adjDef": 93.7,
    "adjDefHistory": {
      "2020-01-19": 93.3,
      "2020-01-20": 93.3,
      "2020-01-21": 93.4,
      "2020-01-22": 93.2,
      "2020-01-23": 93.7
    },
    "adjDefRank": 51,
    "adjDefRankHistory": {
      "2020-01-19": 48,
      "2020-01-20": 48,
      "2020-01-21": 47,
      "2020-01-22": 46,
      "2020-01-23": 51
    },
    "adjEM": 8.59,
    "adjEmHistory": {
      "2020-01-19": 9.19,
      "2020-01-20": 9.19,
      "2020-01-21": 9.28,
      "2020-01-22": 9.32,
      "2020-01-23": 8.59
    },
    "adjOff": 102.3,
    "adjOffHistory": {
      "2020-01-19": 102.5,
      "2020-01-20": 102.5,
      "2020-01-21": 102.7,
      "2020-01-22": 102.5,
      "2020-01-23": 102.3
    },
    "adjOffRank": 165,
    "adjOffRankHistory": {
      "2020-01-19": 161,
      "2020-01-20": 161,
      "2020-01-21": 161,
      "2020-01-22": 160,
      "2020-01-23": 165
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.4,
      "2020-01-20": 68.4,
      "2020-01-21": 68.3,
      "2020-01-22": 68.3,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 194,
    "adjTempoRankHistory": {
      "2020-01-19": 187,
      "2020-01-20": 187,
      "2020-01-21": 187,
      "2020-01-22": 187,
      "2020-01-23": 194
    },
    "conference": "A10",
    "rank": 89,
    "rankHistory": {
      "2020-01-19": 85,
      "2020-01-20": 85,
      "2020-01-21": 85,
      "2020-01-22": 84,
      "2020-01-23": 89
    },
    "record": "14-5"
  },
  "Saint Mary's": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 97.6,
      "2020-01-20": 97.6,
      "2020-01-21": 97.8,
      "2020-01-22": 97.6,
      "2020-01-23": 97.7
    },
    "adjDefRank": 96,
    "adjDefRankHistory": {
      "2020-01-19": 97,
      "2020-01-20": 98,
      "2020-01-21": 98,
      "2020-01-22": 98,
      "2020-01-23": 96
    },
    "adjEM": 16.49,
    "adjEmHistory": {
      "2020-01-19": 16.25,
      "2020-01-20": 16.23,
      "2020-01-21": 16.28,
      "2020-01-22": 16.39,
      "2020-01-23": 16.49
    },
    "adjOff": 114.2,
    "adjOffHistory": {
      "2020-01-19": 113.8,
      "2020-01-20": 113.9,
      "2020-01-21": 114.1,
      "2020-01-22": 114,
      "2020-01-23": 114.2
    },
    "adjOffRank": 9,
    "adjOffRankHistory": {
      "2020-01-19": 9,
      "2020-01-20": 9,
      "2020-01-21": 9,
      "2020-01-22": 9,
      "2020-01-23": 9
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.2,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 332,
    "adjTempoRankHistory": {
      "2020-01-19": 332,
      "2020-01-20": 332,
      "2020-01-21": 330,
      "2020-01-22": 330,
      "2020-01-23": 332
    },
    "conference": "WCC",
    "rank": 37,
    "rankHistory": {
      "2020-01-19": 37,
      "2020-01-20": 37,
      "2020-01-21": 37,
      "2020-01-22": 36,
      "2020-01-23": 37
    },
    "record": "16-4"
  },
  "Saint Peter's": {
    "adjDef": 98.2,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.3,
      "2020-01-22": 98.2,
      "2020-01-23": 98.2
    },
    "adjDefRank": 111,
    "adjDefRankHistory": {
      "2020-01-19": 113,
      "2020-01-20": 115,
      "2020-01-21": 114,
      "2020-01-22": 112,
      "2020-01-23": 111
    },
    "adjEM": -5.6,
    "adjEmHistory": {
      "2020-01-19": -5.71,
      "2020-01-20": -5.7,
      "2020-01-21": -5.73,
      "2020-01-22": -5.73,
      "2020-01-23": -5.6
    },
    "adjOff": 92.6,
    "adjOffHistory": {
      "2020-01-19": 92.5,
      "2020-01-20": 92.5,
      "2020-01-21": 92.6,
      "2020-01-22": 92.5,
      "2020-01-23": 92.6
    },
    "adjOffRank": 317,
    "adjOffRankHistory": {
      "2020-01-19": 320,
      "2020-01-20": 320,
      "2020-01-21": 319,
      "2020-01-22": 318,
      "2020-01-23": 317
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 67.1,
      "2020-01-20": 67.1,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 67
    },
    "adjTempoRank": 249,
    "adjTempoRankHistory": {
      "2020-01-19": 249,
      "2020-01-20": 249,
      "2020-01-21": 248,
      "2020-01-22": 247,
      "2020-01-23": 249
    },
    "conference": "MAAC",
    "rank": 232,
    "rankHistory": {
      "2020-01-19": 231,
      "2020-01-20": 231,
      "2020-01-21": 233,
      "2020-01-22": 233,
      "2020-01-23": 232
    },
    "record": "6-9"
  },
  "Sam Houston St.": {
    "adjDef": 100.6,
    "adjDefHistory": {
      "2020-01-19": 100.4,
      "2020-01-20": 100.4,
      "2020-01-21": 100.5,
      "2020-01-22": 100.4,
      "2020-01-23": 100.6
    },
    "adjDefRank": 149,
    "adjDefRankHistory": {
      "2020-01-19": 146,
      "2020-01-20": 146,
      "2020-01-21": 146,
      "2020-01-22": 147,
      "2020-01-23": 149
    },
    "adjEM": 0.29,
    "adjEmHistory": {
      "2020-01-19": 0.23,
      "2020-01-20": 0.23,
      "2020-01-21": 0.21,
      "2020-01-22": 0.21,
      "2020-01-23": 0.29
    },
    "adjOff": 100.9,
    "adjOffHistory": {
      "2020-01-19": 100.6,
      "2020-01-20": 100.6,
      "2020-01-21": 100.7,
      "2020-01-22": 100.6,
      "2020-01-23": 100.9
    },
    "adjOffRank": 184,
    "adjOffRankHistory": {
      "2020-01-19": 191,
      "2020-01-20": 191,
      "2020-01-21": 191,
      "2020-01-22": 191,
      "2020-01-23": 184
    },
    "adjTempo": 71.1,
    "adjTempoHistory": {
      "2020-01-19": 71.5,
      "2020-01-20": 71.5,
      "2020-01-21": 71.3,
      "2020-01-22": 71.3,
      "2020-01-23": 71.1
    },
    "adjTempoRank": 54,
    "adjTempoRankHistory": {
      "2020-01-19": 48,
      "2020-01-20": 48,
      "2020-01-21": 48,
      "2020-01-22": 49,
      "2020-01-23": 54
    },
    "conference": "Slnd",
    "rank": 165,
    "rankHistory": {
      "2020-01-19": 166,
      "2020-01-20": 166,
      "2020-01-21": 166,
      "2020-01-22": 165,
      "2020-01-23": 165
    },
    "record": "14-6"
  },
  "Samford": {
    "adjDef": 109.7,
    "adjDefHistory": {
      "2020-01-19": 108.8,
      "2020-01-20": 108.8,
      "2020-01-21": 109,
      "2020-01-22": 108.9,
      "2020-01-23": 109.7
    },
    "adjDefRank": 324,
    "adjDefRankHistory": {
      "2020-01-19": 318,
      "2020-01-20": 318,
      "2020-01-21": 316,
      "2020-01-22": 317,
      "2020-01-23": 324
    },
    "adjEM": -10.07,
    "adjEmHistory": {
      "2020-01-19": -9.68,
      "2020-01-20": -9.68,
      "2020-01-21": -9.74,
      "2020-01-22": -9.8,
      "2020-01-23": -10.07
    },
    "adjOff": 99.7,
    "adjOffHistory": {
      "2020-01-19": 99.1,
      "2020-01-20": 99.1,
      "2020-01-21": 99.2,
      "2020-01-22": 99.1,
      "2020-01-23": 99.7
    },
    "adjOffRank": 208,
    "adjOffRankHistory": {
      "2020-01-19": 215,
      "2020-01-20": 214,
      "2020-01-21": 213,
      "2020-01-22": 213,
      "2020-01-23": 208
    },
    "adjTempo": 71.4,
    "adjTempoHistory": {
      "2020-01-19": 71.4,
      "2020-01-20": 71.4,
      "2020-01-21": 71.2,
      "2020-01-22": 71.2,
      "2020-01-23": 71.4
    },
    "adjTempoRank": 45,
    "adjTempoRankHistory": {
      "2020-01-19": 51,
      "2020-01-20": 51,
      "2020-01-21": 52,
      "2020-01-22": 52,
      "2020-01-23": 45
    },
    "conference": "SC",
    "rank": 285,
    "rankHistory": {
      "2020-01-19": 283,
      "2020-01-20": 283,
      "2020-01-21": 286,
      "2020-01-22": 285,
      "2020-01-23": 285
    },
    "record": "8-13"
  },
  "San Diego": {
    "adjDef": 99.2,
    "adjDefHistory": {
      "2020-01-19": 99.2,
      "2020-01-20": 99.2,
      "2020-01-21": 99.3,
      "2020-01-22": 99.2,
      "2020-01-23": 99.2
    },
    "adjDefRank": 132,
    "adjDefRankHistory": {
      "2020-01-19": 133,
      "2020-01-20": 133,
      "2020-01-21": 133,
      "2020-01-22": 131,
      "2020-01-23": 132
    },
    "adjEM": -3.1,
    "adjEmHistory": {
      "2020-01-19": -3.05,
      "2020-01-20": -3.05,
      "2020-01-21": -3.07,
      "2020-01-22": -3.08,
      "2020-01-23": -3.1
    },
    "adjOff": 96.1,
    "adjOffHistory": {
      "2020-01-19": 96.2,
      "2020-01-20": 96.1,
      "2020-01-21": 96.2,
      "2020-01-22": 96.1,
      "2020-01-23": 96.1
    },
    "adjOffRank": 282,
    "adjOffRankHistory": {
      "2020-01-19": 277,
      "2020-01-20": 277,
      "2020-01-21": 279,
      "2020-01-22": 280,
      "2020-01-23": 282
    },
    "adjTempo": 70.1,
    "adjTempoHistory": {
      "2020-01-19": 70.4,
      "2020-01-20": 70.4,
      "2020-01-21": 70.2,
      "2020-01-22": 70.2,
      "2020-01-23": 70.1
    },
    "adjTempoRank": 87,
    "adjTempoRankHistory": {
      "2020-01-19": 90,
      "2020-01-20": 90,
      "2020-01-21": 89,
      "2020-01-22": 88,
      "2020-01-23": 87
    },
    "conference": "WCC",
    "rank": 198,
    "rankHistory": {
      "2020-01-19": 198,
      "2020-01-20": 198,
      "2020-01-21": 199,
      "2020-01-22": 199,
      "2020-01-23": 198
    },
    "record": "8-13"
  },
  "San Diego St.": {
    "adjDef": 88.1,
    "adjDefHistory": {
      "2020-01-19": 87.6,
      "2020-01-20": 87.6,
      "2020-01-21": 87.6,
      "2020-01-22": 88.3,
      "2020-01-23": 88.1
    },
    "adjDefRank": 10,
    "adjDefRankHistory": {
      "2020-01-19": 10,
      "2020-01-20": 10,
      "2020-01-21": 10,
      "2020-01-22": 14,
      "2020-01-23": 10
    },
    "adjEM": 22.88,
    "adjEmHistory": {
      "2020-01-19": 22.76,
      "2020-01-20": 22.77,
      "2020-01-21": 22.86,
      "2020-01-22": 22.54,
      "2020-01-23": 22.88
    },
    "adjOff": 111,
    "adjOffHistory": {
      "2020-01-19": 110.3,
      "2020-01-20": 110.3,
      "2020-01-21": 110.5,
      "2020-01-22": 110.9,
      "2020-01-23": 111
    },
    "adjOffRank": 29,
    "adjOffRankHistory": {
      "2020-01-19": 32,
      "2020-01-20": 32,
      "2020-01-21": 32,
      "2020-01-22": 29,
      "2020-01-23": 29
    },
    "adjTempo": 65.4,
    "adjTempoHistory": {
      "2020-01-19": 65.7,
      "2020-01-20": 65.7,
      "2020-01-21": 65.7,
      "2020-01-22": 65.5,
      "2020-01-23": 65.4
    },
    "adjTempoRank": 302,
    "adjTempoRankHistory": {
      "2020-01-19": 298,
      "2020-01-20": 298,
      "2020-01-21": 295,
      "2020-01-22": 299,
      "2020-01-23": 302
    },
    "conference": "MWC",
    "rank": 8,
    "rankHistory": {
      "2020-01-19": 8,
      "2020-01-20": 8,
      "2020-01-21": 9,
      "2020-01-22": 9,
      "2020-01-23": 8
    },
    "record": "20-0"
  },
  "San Francisco": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.7,
      "2020-01-22": 102.6,
      "2020-01-23": 102.7
    },
    "adjDefRank": 188,
    "adjDefRankHistory": {
      "2020-01-19": 187,
      "2020-01-20": 190,
      "2020-01-21": 188,
      "2020-01-22": 188,
      "2020-01-23": 188
    },
    "adjEM": 6.95,
    "adjEmHistory": {
      "2020-01-19": 6.85,
      "2020-01-20": 6.83,
      "2020-01-21": 6.84,
      "2020-01-22": 6.87,
      "2020-01-23": 6.95
    },
    "adjOff": 109.6,
    "adjOffHistory": {
      "2020-01-19": 109.4,
      "2020-01-20": 109.5,
      "2020-01-21": 109.6,
      "2020-01-22": 109.5,
      "2020-01-23": 109.6
    },
    "adjOffRank": 39,
    "adjOffRankHistory": {
      "2020-01-19": 38,
      "2020-01-20": 38,
      "2020-01-21": 39,
      "2020-01-22": 40,
      "2020-01-23": 39
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.9,
      "2020-01-22": 69.9,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 103,
    "adjTempoRankHistory": {
      "2020-01-19": 103,
      "2020-01-20": 102,
      "2020-01-21": 103,
      "2020-01-22": 105,
      "2020-01-23": 103
    },
    "conference": "WCC",
    "rank": 101,
    "rankHistory": {
      "2020-01-19": 99,
      "2020-01-20": 99,
      "2020-01-21": 99,
      "2020-01-22": 99,
      "2020-01-23": 101
    },
    "record": "14-6"
  },
  "San Jose St.": {
    "adjDef": 106.7,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.6,
      "2020-01-21": 106.7,
      "2020-01-22": 106.7,
      "2020-01-23": 106.7
    },
    "adjDefRank": 285,
    "adjDefRankHistory": {
      "2020-01-19": 285,
      "2020-01-20": 285,
      "2020-01-21": 285,
      "2020-01-22": 286,
      "2020-01-23": 285
    },
    "adjEM": -10,
    "adjEmHistory": {
      "2020-01-19": -9.63,
      "2020-01-20": -9.6,
      "2020-01-21": -9.54,
      "2020-01-22": -10.15,
      "2020-01-23": -10
    },
    "adjOff": 96.7,
    "adjOffHistory": {
      "2020-01-19": 96.9,
      "2020-01-20": 97,
      "2020-01-21": 97.2,
      "2020-01-22": 96.6,
      "2020-01-23": 96.7
    },
    "adjOffRank": 268,
    "adjOffRankHistory": {
      "2020-01-19": 259,
      "2020-01-20": 259,
      "2020-01-21": 259,
      "2020-01-22": 268,
      "2020-01-23": 268
    },
    "adjTempo": 71.8,
    "adjTempoHistory": {
      "2020-01-19": 72.2,
      "2020-01-20": 72.2,
      "2020-01-21": 72.1,
      "2020-01-22": 71.8,
      "2020-01-23": 71.8
    },
    "adjTempoRank": 34,
    "adjTempoRankHistory": {
      "2020-01-19": 30,
      "2020-01-20": 30,
      "2020-01-21": 30,
      "2020-01-22": 34,
      "2020-01-23": 34
    },
    "conference": "MWC",
    "rank": 284,
    "rankHistory": {
      "2020-01-19": 282,
      "2020-01-20": 282,
      "2020-01-21": 284,
      "2020-01-22": 286,
      "2020-01-23": 284
    },
    "record": "6-14"
  },
  "Santa Clara": {
    "adjDef": 100.9,
    "adjDefHistory": {
      "2020-01-19": 100.8,
      "2020-01-20": 100.9,
      "2020-01-21": 101,
      "2020-01-22": 100.9,
      "2020-01-23": 100.9
    },
    "adjDefRank": 153,
    "adjDefRankHistory": {
      "2020-01-19": 151,
      "2020-01-20": 153,
      "2020-01-21": 155,
      "2020-01-22": 153,
      "2020-01-23": 153
    },
    "adjEM": 2.66,
    "adjEmHistory": {
      "2020-01-19": 2.42,
      "2020-01-20": 2.4,
      "2020-01-21": 2.54,
      "2020-01-22": 2.53,
      "2020-01-23": 2.66
    },
    "adjOff": 103.5,
    "adjOffHistory": {
      "2020-01-19": 103.2,
      "2020-01-20": 103.3,
      "2020-01-21": 103.5,
      "2020-01-22": 103.4,
      "2020-01-23": 103.5
    },
    "adjOffRank": 136,
    "adjOffRankHistory": {
      "2020-01-19": 146,
      "2020-01-20": 144,
      "2020-01-21": 140,
      "2020-01-22": 140,
      "2020-01-23": 136
    },
    "adjTempo": 71.4,
    "adjTempoHistory": {
      "2020-01-19": 71.6,
      "2020-01-20": 71.6,
      "2020-01-21": 71.5,
      "2020-01-22": 71.5,
      "2020-01-23": 71.4
    },
    "adjTempoRank": 43,
    "adjTempoRankHistory": {
      "2020-01-19": 43,
      "2020-01-20": 43,
      "2020-01-21": 43,
      "2020-01-22": 43,
      "2020-01-23": 43
    },
    "conference": "WCC",
    "rank": 133,
    "rankHistory": {
      "2020-01-19": 139,
      "2020-01-20": 139,
      "2020-01-21": 136,
      "2020-01-22": 137,
      "2020-01-23": 133
    },
    "record": "16-4"
  },
  "Seattle": {
    "adjDef": 102.4,
    "adjDefHistory": {
      "2020-01-19": 102.2,
      "2020-01-20": 102.2,
      "2020-01-21": 102.4,
      "2020-01-22": 102.3,
      "2020-01-23": 102.4
    },
    "adjDefRank": 181,
    "adjDefRankHistory": {
      "2020-01-19": 179,
      "2020-01-20": 179,
      "2020-01-21": 180,
      "2020-01-22": 179,
      "2020-01-23": 181
    },
    "adjEM": -4.53,
    "adjEmHistory": {
      "2020-01-19": -4.44,
      "2020-01-20": -4.44,
      "2020-01-21": -4.46,
      "2020-01-22": -4.54,
      "2020-01-23": -4.53
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.8
    },
    "adjOffRank": 240,
    "adjOffRankHistory": {
      "2020-01-19": 237,
      "2020-01-20": 237,
      "2020-01-21": 238,
      "2020-01-22": 241,
      "2020-01-23": 240
    },
    "adjTempo": 68.6,
    "adjTempoHistory": {
      "2020-01-19": 68.8,
      "2020-01-20": 68.8,
      "2020-01-21": 68.7,
      "2020-01-22": 68.6,
      "2020-01-23": 68.6
    },
    "adjTempoRank": 163,
    "adjTempoRankHistory": {
      "2020-01-19": 165,
      "2020-01-20": 165,
      "2020-01-21": 164,
      "2020-01-22": 164,
      "2020-01-23": 163
    },
    "conference": "WAC",
    "rank": 218,
    "rankHistory": {
      "2020-01-19": 216,
      "2020-01-20": 216,
      "2020-01-21": 218,
      "2020-01-22": 217,
      "2020-01-23": 218
    },
    "record": "10-10"
  },
  "Seton Hall": {
    "adjDef": 88.8,
    "adjDefHistory": {
      "2020-01-19": 88.1,
      "2020-01-20": 88.1,
      "2020-01-21": 88.2,
      "2020-01-22": 88,
      "2020-01-23": 88.8
    },
    "adjDefRank": 12,
    "adjDefRankHistory": {
      "2020-01-19": 13,
      "2020-01-20": 13,
      "2020-01-21": 12,
      "2020-01-22": 11,
      "2020-01-23": 12
    },
    "adjEM": 22.21,
    "adjEmHistory": {
      "2020-01-19": 22.41,
      "2020-01-20": 22.41,
      "2020-01-21": 22.51,
      "2020-01-22": 22.48,
      "2020-01-23": 22.21
    },
    "adjOff": 111,
    "adjOffHistory": {
      "2020-01-19": 110.5,
      "2020-01-20": 110.5,
      "2020-01-21": 110.7,
      "2020-01-22": 110.5,
      "2020-01-23": 111
    },
    "adjOffRank": 30,
    "adjOffRankHistory": {
      "2020-01-19": 30,
      "2020-01-20": 30,
      "2020-01-21": 30,
      "2020-01-22": 32,
      "2020-01-23": 30
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70.4,
      "2020-01-20": 70.4,
      "2020-01-21": 70.3,
      "2020-01-22": 70.2,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 100,
    "adjTempoRankHistory": {
      "2020-01-19": 89,
      "2020-01-20": 89,
      "2020-01-21": 88,
      "2020-01-22": 86,
      "2020-01-23": 100
    },
    "conference": "BE",
    "rank": 11,
    "rankHistory": {
      "2020-01-19": 10,
      "2020-01-20": 10,
      "2020-01-21": 10,
      "2020-01-22": 11,
      "2020-01-23": 11
    },
    "record": "15-4"
  },
  "Siena": {
    "adjDef": 106,
    "adjDefHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 105.9,
      "2020-01-22": 105.8,
      "2020-01-23": 106
    },
    "adjDefRank": 266,
    "adjDefRankHistory": {
      "2020-01-19": 267,
      "2020-01-20": 267,
      "2020-01-21": 265,
      "2020-01-22": 268,
      "2020-01-23": 266
    },
    "adjEM": -3.91,
    "adjEmHistory": {
      "2020-01-19": -3.93,
      "2020-01-20": -3.93,
      "2020-01-21": -3.98,
      "2020-01-22": -3.97,
      "2020-01-23": -3.91
    },
    "adjOff": 102.1,
    "adjOffHistory": {
      "2020-01-19": 101.9,
      "2020-01-20": 101.9,
      "2020-01-21": 101.9,
      "2020-01-22": 101.9,
      "2020-01-23": 102.1
    },
    "adjOffRank": 172,
    "adjOffRankHistory": {
      "2020-01-19": 173,
      "2020-01-20": 173,
      "2020-01-21": 172,
      "2020-01-22": 174,
      "2020-01-23": 172
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.3,
      "2020-01-20": 68.3,
      "2020-01-21": 68.2,
      "2020-01-22": 68.2,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 196,
    "adjTempoRankHistory": {
      "2020-01-19": 191,
      "2020-01-20": 191,
      "2020-01-21": 191,
      "2020-01-22": 192,
      "2020-01-23": 196
    },
    "conference": "MAAC",
    "rank": 209,
    "rankHistory": {
      "2020-01-19": 208,
      "2020-01-20": 208,
      "2020-01-21": 208,
      "2020-01-22": 209,
      "2020-01-23": 209
    },
    "record": "7-9"
  },
  "South Alabama": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.7,
      "2020-01-22": 103.6,
      "2020-01-23": 103.7
    },
    "adjDefRank": 219,
    "adjDefRankHistory": {
      "2020-01-19": 215,
      "2020-01-20": 216,
      "2020-01-21": 217,
      "2020-01-22": 217,
      "2020-01-23": 219
    },
    "adjEM": -0.77,
    "adjEmHistory": {
      "2020-01-19": -0.77,
      "2020-01-20": -0.77,
      "2020-01-21": -0.78,
      "2020-01-22": -0.84,
      "2020-01-23": -0.77
    },
    "adjOff": 102.9,
    "adjOffHistory": {
      "2020-01-19": 102.8,
      "2020-01-20": 102.8,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 102.9
    },
    "adjOffRank": 154,
    "adjOffRankHistory": {
      "2020-01-19": 152,
      "2020-01-20": 152,
      "2020-01-21": 153,
      "2020-01-22": 154,
      "2020-01-23": 154
    },
    "adjTempo": 65.3,
    "adjTempoHistory": {
      "2020-01-19": 65.5,
      "2020-01-20": 65.5,
      "2020-01-21": 65.3,
      "2020-01-22": 65.3,
      "2020-01-23": 65.3
    },
    "adjTempoRank": 306,
    "adjTempoRankHistory": {
      "2020-01-19": 309,
      "2020-01-20": 307,
      "2020-01-21": 308,
      "2020-01-22": 306,
      "2020-01-23": 306
    },
    "conference": "SB",
    "rank": 180,
    "rankHistory": {
      "2020-01-19": 182,
      "2020-01-20": 182,
      "2020-01-21": 180,
      "2020-01-22": 180,
      "2020-01-23": 180
    },
    "record": "12-8"
  },
  "South Carolina": {
    "adjDef": 96.4,
    "adjDefHistory": {
      "2020-01-19": 95.8,
      "2020-01-20": 95.8,
      "2020-01-21": 96,
      "2020-01-22": 95.7,
      "2020-01-23": 96.4
    },
    "adjDefRank": 84,
    "adjDefRankHistory": {
      "2020-01-19": 83,
      "2020-01-20": 83,
      "2020-01-21": 82,
      "2020-01-22": 82,
      "2020-01-23": 84
    },
    "adjEM": 7.18,
    "adjEmHistory": {
      "2020-01-19": 7.45,
      "2020-01-20": 7.45,
      "2020-01-21": 7.41,
      "2020-01-22": 7.55,
      "2020-01-23": 7.18
    },
    "adjOff": 103.6,
    "adjOffHistory": {
      "2020-01-19": 103.3,
      "2020-01-20": 103.3,
      "2020-01-21": 103.4,
      "2020-01-22": 103.3,
      "2020-01-23": 103.6
    },
    "adjOffRank": 132,
    "adjOffRankHistory": {
      "2020-01-19": 141,
      "2020-01-20": 140,
      "2020-01-21": 144,
      "2020-01-22": 144,
      "2020-01-23": 132
    },
    "adjTempo": 71.9,
    "adjTempoHistory": {
      "2020-01-19": 72.4,
      "2020-01-20": 72.4,
      "2020-01-21": 72.3,
      "2020-01-22": 72.2,
      "2020-01-23": 71.9
    },
    "adjTempoRank": 30,
    "adjTempoRankHistory": {
      "2020-01-19": 29,
      "2020-01-20": 29,
      "2020-01-21": 29,
      "2020-01-22": 29,
      "2020-01-23": 30
    },
    "conference": "SEC",
    "rank": 99,
    "rankHistory": {
      "2020-01-19": 95,
      "2020-01-20": 95,
      "2020-01-21": 94,
      "2020-01-22": 92,
      "2020-01-23": 99
    },
    "record": "10-8"
  },
  "South Carolina St.": {
    "adjDef": 111.4,
    "adjDefHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 111.3,
      "2020-01-22": 111.2,
      "2020-01-23": 111.4
    },
    "adjDefRank": 337,
    "adjDefRankHistory": {
      "2020-01-19": 340,
      "2020-01-20": 340,
      "2020-01-21": 337,
      "2020-01-22": 337,
      "2020-01-23": 337
    },
    "adjEM": -14.12,
    "adjEmHistory": {
      "2020-01-19": -14.37,
      "2020-01-20": -14.37,
      "2020-01-21": -14.18,
      "2020-01-22": -14.15,
      "2020-01-23": -14.12
    },
    "adjOff": 97.2,
    "adjOffHistory": {
      "2020-01-19": 97.7,
      "2020-01-20": 97.7,
      "2020-01-21": 97.1,
      "2020-01-22": 97,
      "2020-01-23": 97.2
    },
    "adjOffRank": 261,
    "adjOffRankHistory": {
      "2020-01-19": 243,
      "2020-01-20": 243,
      "2020-01-21": 261,
      "2020-01-22": 261,
      "2020-01-23": 261
    },
    "adjTempo": 67.8,
    "adjTempoHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 67.9,
      "2020-01-22": 67.8,
      "2020-01-23": 67.8
    },
    "adjTempoRank": 211,
    "adjTempoRankHistory": {
      "2020-01-19": 211,
      "2020-01-20": 211,
      "2020-01-21": 211,
      "2020-01-22": 211,
      "2020-01-23": 211
    },
    "conference": "MEAC",
    "rank": 322,
    "rankHistory": {
      "2020-01-19": 325,
      "2020-01-20": 325,
      "2020-01-21": 323,
      "2020-01-22": 323,
      "2020-01-23": 322
    },
    "record": "9-8"
  },
  "South Dakota": {
    "adjDef": 109.8,
    "adjDefHistory": {
      "2020-01-19": 109.6,
      "2020-01-20": 109.6,
      "2020-01-21": 109.7,
      "2020-01-22": 109.7,
      "2020-01-23": 109.8
    },
    "adjDefRank": 325,
    "adjDefRankHistory": {
      "2020-01-19": 321,
      "2020-01-20": 321,
      "2020-01-21": 321,
      "2020-01-22": 322,
      "2020-01-23": 325
    },
    "adjEM": -1.86,
    "adjEmHistory": {
      "2020-01-19": -1.91,
      "2020-01-20": -1.91,
      "2020-01-21": -1.86,
      "2020-01-22": -1.85,
      "2020-01-23": -1.86
    },
    "adjOff": 108,
    "adjOffHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.6,
      "2020-01-21": 107.9,
      "2020-01-22": 107.8,
      "2020-01-23": 108
    },
    "adjOffRank": 56,
    "adjOffRankHistory": {
      "2020-01-19": 56,
      "2020-01-20": 56,
      "2020-01-21": 55,
      "2020-01-22": 56,
      "2020-01-23": 56
    },
    "adjTempo": 68.2,
    "adjTempoHistory": {
      "2020-01-19": 68.3,
      "2020-01-20": 68.3,
      "2020-01-21": 68.2,
      "2020-01-22": 68.2,
      "2020-01-23": 68.2
    },
    "adjTempoRank": 191,
    "adjTempoRankHistory": {
      "2020-01-19": 190,
      "2020-01-20": 190,
      "2020-01-21": 188,
      "2020-01-22": 188,
      "2020-01-23": 191
    },
    "conference": "Sum",
    "rank": 185,
    "rankHistory": {
      "2020-01-19": 188,
      "2020-01-20": 187,
      "2020-01-21": 186,
      "2020-01-22": 187,
      "2020-01-23": 185
    },
    "record": "12-8"
  },
  "South Dakota St.": {
    "adjDef": 105.3,
    "adjDefHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.2,
      "2020-01-22": 105.1,
      "2020-01-23": 105.3
    },
    "adjDefRank": 247,
    "adjDefRankHistory": {
      "2020-01-19": 252,
      "2020-01-20": 252,
      "2020-01-21": 250,
      "2020-01-22": 248,
      "2020-01-23": 247
    },
    "adjEM": 3.6,
    "adjEmHistory": {
      "2020-01-19": 3.38,
      "2020-01-20": 3.38,
      "2020-01-21": 3.43,
      "2020-01-22": 3.45,
      "2020-01-23": 3.6
    },
    "adjOff": 108.9,
    "adjOffHistory": {
      "2020-01-19": 108.4,
      "2020-01-20": 108.4,
      "2020-01-21": 108.6,
      "2020-01-22": 108.5,
      "2020-01-23": 108.9
    },
    "adjOffRank": 46,
    "adjOffRankHistory": {
      "2020-01-19": 46,
      "2020-01-20": 46,
      "2020-01-21": 47,
      "2020-01-22": 48,
      "2020-01-23": 46
    },
    "adjTempo": 67.4,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.3,
      "2020-01-23": 67.4
    },
    "adjTempoRank": 223,
    "adjTempoRankHistory": {
      "2020-01-19": 229,
      "2020-01-20": 229,
      "2020-01-21": 228,
      "2020-01-22": 230,
      "2020-01-23": 223
    },
    "conference": "Sum",
    "rank": 128,
    "rankHistory": {
      "2020-01-19": 129,
      "2020-01-20": 129,
      "2020-01-21": 130,
      "2020-01-22": 129,
      "2020-01-23": 128
    },
    "record": "15-8"
  },
  "South Florida": {
    "adjDef": 95.6,
    "adjDefHistory": {
      "2020-01-19": 95.4,
      "2020-01-20": 95.4,
      "2020-01-21": 95.6,
      "2020-01-22": 95.4,
      "2020-01-23": 95.6
    },
    "adjDefRank": 78,
    "adjDefRankHistory": {
      "2020-01-19": 76,
      "2020-01-20": 76,
      "2020-01-21": 76,
      "2020-01-22": 77,
      "2020-01-23": 78
    },
    "adjEM": 2.59,
    "adjEmHistory": {
      "2020-01-19": 3.52,
      "2020-01-20": 3.52,
      "2020-01-21": 3.44,
      "2020-01-22": 2.7,
      "2020-01-23": 2.59
    },
    "adjOff": 98.2,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 99,
      "2020-01-21": 99.1,
      "2020-01-22": 98.1,
      "2020-01-23": 98.2
    },
    "adjOffRank": 236,
    "adjOffRankHistory": {
      "2020-01-19": 217,
      "2020-01-20": 216,
      "2020-01-21": 219,
      "2020-01-22": 235,
      "2020-01-23": 236
    },
    "adjTempo": 65.1,
    "adjTempoHistory": {
      "2020-01-19": 65.7,
      "2020-01-20": 65.7,
      "2020-01-21": 65.6,
      "2020-01-22": 65.1,
      "2020-01-23": 65.1
    },
    "adjTempoRank": 312,
    "adjTempoRankHistory": {
      "2020-01-19": 299,
      "2020-01-20": 299,
      "2020-01-21": 299,
      "2020-01-22": 313,
      "2020-01-23": 312
    },
    "conference": "Amer",
    "rank": 135,
    "rankHistory": {
      "2020-01-19": 127,
      "2020-01-20": 127,
      "2020-01-21": 129,
      "2020-01-22": 133,
      "2020-01-23": 135
    },
    "record": "8-11"
  },
  "Southeast Missouri St.": {
    "adjDef": 111.2,
    "adjDefHistory": {
      "2020-01-19": 111,
      "2020-01-20": 111,
      "2020-01-21": 111.2,
      "2020-01-22": 111.1,
      "2020-01-23": 111.2
    },
    "adjDefRank": 334,
    "adjDefRankHistory": {
      "2020-01-19": 336,
      "2020-01-20": 336,
      "2020-01-21": 336,
      "2020-01-22": 336,
      "2020-01-23": 334
    },
    "adjEM": -16.39,
    "adjEmHistory": {
      "2020-01-19": -16.42,
      "2020-01-20": -16.41,
      "2020-01-21": -16.43,
      "2020-01-22": -16.45,
      "2020-01-23": -16.39
    },
    "adjOff": 94.8,
    "adjOffHistory": {
      "2020-01-19": 94.6,
      "2020-01-20": 94.6,
      "2020-01-21": 94.7,
      "2020-01-22": 94.6,
      "2020-01-23": 94.8
    },
    "adjOffRank": 300,
    "adjOffRankHistory": {
      "2020-01-19": 300,
      "2020-01-20": 300,
      "2020-01-21": 301,
      "2020-01-22": 301,
      "2020-01-23": 300
    },
    "adjTempo": 66.9,
    "adjTempoHistory": {
      "2020-01-19": 67.1,
      "2020-01-20": 67.1,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 66.9
    },
    "adjTempoRank": 254,
    "adjTempoRankHistory": {
      "2020-01-19": 247,
      "2020-01-20": 247,
      "2020-01-21": 251,
      "2020-01-22": 252,
      "2020-01-23": 254
    },
    "conference": "OVC",
    "rank": 335,
    "rankHistory": {
      "2020-01-19": 334,
      "2020-01-20": 334,
      "2020-01-21": 334,
      "2020-01-22": 334,
      "2020-01-23": 335
    },
    "record": "4-15"
  },
  "Southeastern Louisiana": {
    "adjDef": 107.4,
    "adjDefHistory": {
      "2020-01-19": 107.3,
      "2020-01-20": 107.3,
      "2020-01-21": 107.5,
      "2020-01-22": 107.5,
      "2020-01-23": 107.4
    },
    "adjDefRank": 296,
    "adjDefRankHistory": {
      "2020-01-19": 294,
      "2020-01-20": 294,
      "2020-01-21": 297,
      "2020-01-22": 298,
      "2020-01-23": 296
    },
    "adjEM": -15.61,
    "adjEmHistory": {
      "2020-01-19": -14.46,
      "2020-01-20": -14.46,
      "2020-01-21": -14.46,
      "2020-01-22": -14.47,
      "2020-01-23": -15.61
    },
    "adjOff": 91.8,
    "adjOffHistory": {
      "2020-01-19": 92.9,
      "2020-01-20": 92.9,
      "2020-01-21": 93.1,
      "2020-01-22": 93,
      "2020-01-23": 91.8
    },
    "adjOffRank": 328,
    "adjOffRankHistory": {
      "2020-01-19": 315,
      "2020-01-20": 316,
      "2020-01-21": 313,
      "2020-01-22": 312,
      "2020-01-23": 328
    },
    "adjTempo": 71.5,
    "adjTempoHistory": {
      "2020-01-19": 71.6,
      "2020-01-20": 71.6,
      "2020-01-21": 71.5,
      "2020-01-22": 71.4,
      "2020-01-23": 71.5
    },
    "adjTempoRank": 39,
    "adjTempoRankHistory": {
      "2020-01-19": 44,
      "2020-01-20": 44,
      "2020-01-21": 44,
      "2020-01-22": 44,
      "2020-01-23": 39
    },
    "conference": "Slnd",
    "rank": 332,
    "rankHistory": {
      "2020-01-19": 326,
      "2020-01-20": 326,
      "2020-01-21": 325,
      "2020-01-22": 325,
      "2020-01-23": 332
    },
    "record": "5-14"
  },
  "Southern": {
    "adjDef": 108.9,
    "adjDefHistory": {
      "2020-01-19": 108.5,
      "2020-01-20": 108.5,
      "2020-01-21": 109,
      "2020-01-22": 108.9,
      "2020-01-23": 108.9
    },
    "adjDefRank": 315,
    "adjDefRankHistory": {
      "2020-01-19": 312,
      "2020-01-20": 312,
      "2020-01-21": 317,
      "2020-01-22": 315,
      "2020-01-23": 315
    },
    "adjEM": -13.99,
    "adjEmHistory": {
      "2020-01-19": -13.65,
      "2020-01-20": -13.66,
      "2020-01-21": -13.97,
      "2020-01-22": -13.96,
      "2020-01-23": -13.99
    },
    "adjOff": 95,
    "adjOffHistory": {
      "2020-01-19": 94.8,
      "2020-01-20": 94.8,
      "2020-01-21": 95,
      "2020-01-22": 94.9,
      "2020-01-23": 95
    },
    "adjOffRank": 299,
    "adjOffRankHistory": {
      "2020-01-19": 298,
      "2020-01-20": 298,
      "2020-01-21": 297,
      "2020-01-22": 297,
      "2020-01-23": 299
    },
    "adjTempo": 64.7,
    "adjTempoHistory": {
      "2020-01-19": 65.1,
      "2020-01-20": 65.1,
      "2020-01-21": 64.7,
      "2020-01-22": 64.7,
      "2020-01-23": 64.7
    },
    "adjTempoRank": 326,
    "adjTempoRankHistory": {
      "2020-01-19": 319,
      "2020-01-20": 318,
      "2020-01-21": 323,
      "2020-01-22": 324,
      "2020-01-23": 326
    },
    "conference": "SWAC",
    "rank": 320,
    "rankHistory": {
      "2020-01-19": 317,
      "2020-01-20": 317,
      "2020-01-21": 320,
      "2020-01-22": 320,
      "2020-01-23": 320
    },
    "record": "6-13"
  },
  "Southern Illinois": {
    "adjDef": 97.5,
    "adjDefHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 98,
      "2020-01-22": 97.8,
      "2020-01-23": 97.5
    },
    "adjDefRank": 94,
    "adjDefRankHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 105,
      "2020-01-22": 103,
      "2020-01-23": 94
    },
    "adjEM": -0.34,
    "adjEmHistory": {
      "2020-01-19": -1.01,
      "2020-01-20": -1.01,
      "2020-01-21": -1.02,
      "2020-01-22": -1.06,
      "2020-01-23": -0.34
    },
    "adjOff": 97.1,
    "adjOffHistory": {
      "2020-01-19": 96.8,
      "2020-01-20": 96.8,
      "2020-01-21": 96.9,
      "2020-01-22": 96.8,
      "2020-01-23": 97.1
    },
    "adjOffRank": 262,
    "adjOffRankHistory": {
      "2020-01-19": 263,
      "2020-01-20": 263,
      "2020-01-21": 263,
      "2020-01-22": 264,
      "2020-01-23": 262
    },
    "adjTempo": 63.2,
    "adjTempoHistory": {
      "2020-01-19": 63,
      "2020-01-20": 63,
      "2020-01-21": 62.9,
      "2020-01-22": 62.8,
      "2020-01-23": 63.2
    },
    "adjTempoRank": 344,
    "adjTempoRankHistory": {
      "2020-01-19": 347,
      "2020-01-20": 347,
      "2020-01-21": 347,
      "2020-01-22": 347,
      "2020-01-23": 344
    },
    "conference": "MVC",
    "rank": 173,
    "rankHistory": {
      "2020-01-19": 183,
      "2020-01-20": 183,
      "2020-01-21": 182,
      "2020-01-22": 183,
      "2020-01-23": 173
    },
    "record": "10-10"
  },
  "Southern Miss": {
    "adjDef": 105.1,
    "adjDefHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.1,
      "2020-01-22": 105,
      "2020-01-23": 105.1
    },
    "adjDefRank": 245,
    "adjDefRankHistory": {
      "2020-01-19": 249,
      "2020-01-20": 249,
      "2020-01-21": 248,
      "2020-01-22": 247,
      "2020-01-23": 245
    },
    "adjEM": -8.92,
    "adjEmHistory": {
      "2020-01-19": -8.92,
      "2020-01-20": -8.92,
      "2020-01-21": -8.92,
      "2020-01-22": -8.98,
      "2020-01-23": -8.92
    },
    "adjOff": 96.2,
    "adjOffHistory": {
      "2020-01-19": 96.1,
      "2020-01-20": 96.1,
      "2020-01-21": 96.2,
      "2020-01-22": 96,
      "2020-01-23": 96.2
    },
    "adjOffRank": 281,
    "adjOffRankHistory": {
      "2020-01-19": 280,
      "2020-01-20": 280,
      "2020-01-21": 281,
      "2020-01-22": 281,
      "2020-01-23": 281
    },
    "adjTempo": 67.3,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.3,
      "2020-01-23": 67.3
    },
    "adjTempoRank": 231,
    "adjTempoRankHistory": {
      "2020-01-19": 225,
      "2020-01-20": 225,
      "2020-01-21": 232,
      "2020-01-22": 229,
      "2020-01-23": 231
    },
    "conference": "CUSA",
    "rank": 274,
    "rankHistory": {
      "2020-01-19": 276,
      "2020-01-20": 276,
      "2020-01-21": 277,
      "2020-01-22": 277,
      "2020-01-23": 274
    },
    "record": "5-14"
  },
  "Southern Utah": {
    "adjDef": 95.1,
    "adjDefHistory": {
      "2020-01-19": 95.1,
      "2020-01-20": 95.1,
      "2020-01-21": 95.2,
      "2020-01-22": 95,
      "2020-01-23": 95.1
    },
    "adjDefRank": 68,
    "adjDefRankHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 70,
      "2020-01-22": 70,
      "2020-01-23": 68
    },
    "adjEM": 2.71,
    "adjEmHistory": {
      "2020-01-19": 2.45,
      "2020-01-20": 2.48,
      "2020-01-21": 2.56,
      "2020-01-22": 2.61,
      "2020-01-23": 2.71
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97.5,
      "2020-01-20": 97.6,
      "2020-01-21": 97.8,
      "2020-01-22": 97.6,
      "2020-01-23": 97.8
    },
    "adjOffRank": 245,
    "adjOffRankHistory": {
      "2020-01-19": 246,
      "2020-01-20": 245,
      "2020-01-21": 244,
      "2020-01-22": 244,
      "2020-01-23": 245
    },
    "adjTempo": 69,
    "adjTempoHistory": {
      "2020-01-19": 69.1,
      "2020-01-20": 69.1,
      "2020-01-21": 69,
      "2020-01-22": 69,
      "2020-01-23": 69
    },
    "adjTempoRank": 152,
    "adjTempoRankHistory": {
      "2020-01-19": 149,
      "2020-01-20": 149,
      "2020-01-21": 149,
      "2020-01-22": 150,
      "2020-01-23": 152
    },
    "conference": "BSky",
    "rank": 132,
    "rankHistory": {
      "2020-01-19": 137,
      "2020-01-20": 137,
      "2020-01-21": 135,
      "2020-01-22": 135,
      "2020-01-23": 132
    },
    "record": "11-6"
  },
  "St. Bonaventure": {
    "adjDef": 98.7,
    "adjDefHistory": {
      "2020-01-19": 97.9,
      "2020-01-20": 97.9,
      "2020-01-21": 98.1,
      "2020-01-22": 98,
      "2020-01-23": 98.7
    },
    "adjDefRank": 119,
    "adjDefRankHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 108,
      "2020-01-22": 107,
      "2020-01-23": 119
    },
    "adjEM": 4.33,
    "adjEmHistory": {
      "2020-01-19": 4.93,
      "2020-01-20": 4.93,
      "2020-01-21": 4.9,
      "2020-01-22": 4.89,
      "2020-01-23": 4.33
    },
    "adjOff": 103,
    "adjOffHistory": {
      "2020-01-19": 102.8,
      "2020-01-20": 102.8,
      "2020-01-21": 103,
      "2020-01-22": 102.8,
      "2020-01-23": 103
    },
    "adjOffRank": 151,
    "adjOffRankHistory": {
      "2020-01-19": 150,
      "2020-01-20": 150,
      "2020-01-21": 152,
      "2020-01-22": 153,
      "2020-01-23": 151
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65.1,
      "2020-01-20": 65.1,
      "2020-01-21": 65,
      "2020-01-22": 65,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 319,
    "adjTempoRankHistory": {
      "2020-01-19": 318,
      "2020-01-20": 317,
      "2020-01-21": 316,
      "2020-01-22": 317,
      "2020-01-23": 319
    },
    "conference": "A10",
    "rank": 120,
    "rankHistory": {
      "2020-01-19": 118,
      "2020-01-20": 118,
      "2020-01-21": 118,
      "2020-01-22": 118,
      "2020-01-23": 120
    },
    "record": "12-7"
  },
  "St. Francis NY": {
    "adjDef": 106.4,
    "adjDefHistory": {
      "2020-01-19": 106.7,
      "2020-01-20": 106.7,
      "2020-01-21": 106.3,
      "2020-01-22": 106.2,
      "2020-01-23": 106.4
    },
    "adjDefRank": 276,
    "adjDefRankHistory": {
      "2020-01-19": 288,
      "2020-01-20": 288,
      "2020-01-21": 274,
      "2020-01-22": 274,
      "2020-01-23": 276
    },
    "adjEM": -13.68,
    "adjEmHistory": {
      "2020-01-19": -12.19,
      "2020-01-20": -12.19,
      "2020-01-21": -13.37,
      "2020-01-22": -13.39,
      "2020-01-23": -13.68
    },
    "adjOff": 92.7,
    "adjOffHistory": {
      "2020-01-19": 94.5,
      "2020-01-20": 94.5,
      "2020-01-21": 92.9,
      "2020-01-22": 92.8,
      "2020-01-23": 92.7
    },
    "adjOffRank": 316,
    "adjOffRankHistory": {
      "2020-01-19": 301,
      "2020-01-20": 301,
      "2020-01-21": 317,
      "2020-01-22": 317,
      "2020-01-23": 316
    },
    "adjTempo": 69.7,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.8,
      "2020-01-22": 69.7,
      "2020-01-23": 69.7
    },
    "adjTempoRank": 111,
    "adjTempoRankHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 110,
      "2020-01-22": 110,
      "2020-01-23": 111
    },
    "conference": "NEC",
    "rank": 314,
    "rankHistory": {
      "2020-01-19": 307,
      "2020-01-20": 307,
      "2020-01-21": 313,
      "2020-01-22": 313,
      "2020-01-23": 314
    },
    "record": "8-10"
  },
  "St. Francis PA": {
    "adjDef": 106,
    "adjDefHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 106,
      "2020-01-22": 105.9,
      "2020-01-23": 106
    },
    "adjDefRank": 267,
    "adjDefRankHistory": {
      "2020-01-19": 244,
      "2020-01-20": 244,
      "2020-01-21": 269,
      "2020-01-22": 272,
      "2020-01-23": 267
    },
    "adjEM": -2.36,
    "adjEmHistory": {
      "2020-01-19": -0.12,
      "2020-01-20": -0.11,
      "2020-01-21": -2.47,
      "2020-01-22": -2.46,
      "2020-01-23": -2.36
    },
    "adjOff": 103.6,
    "adjOffHistory": {
      "2020-01-19": 104.7,
      "2020-01-20": 104.7,
      "2020-01-21": 103.5,
      "2020-01-22": 103.5,
      "2020-01-23": 103.6
    },
    "adjOffRank": 131,
    "adjOffRankHistory": {
      "2020-01-19": 106,
      "2020-01-20": 106,
      "2020-01-21": 139,
      "2020-01-22": 136,
      "2020-01-23": 131
    },
    "adjTempo": 68.7,
    "adjTempoHistory": {
      "2020-01-19": 68.7,
      "2020-01-20": 68.7,
      "2020-01-21": 68.8,
      "2020-01-22": 68.7,
      "2020-01-23": 68.7
    },
    "adjTempoRank": 161,
    "adjTempoRankHistory": {
      "2020-01-19": 166,
      "2020-01-20": 166,
      "2020-01-21": 158,
      "2020-01-22": 158,
      "2020-01-23": 161
    },
    "conference": "NEC",
    "rank": 192,
    "rankHistory": {
      "2020-01-19": 171,
      "2020-01-20": 171,
      "2020-01-21": 192,
      "2020-01-22": 194,
      "2020-01-23": 192
    },
    "record": "12-6"
  },
  "St. John's": {
    "adjDef": 94,
    "adjDefHistory": {
      "2020-01-19": 93.5,
      "2020-01-20": 93.5,
      "2020-01-21": 93.6,
      "2020-01-22": 93.9,
      "2020-01-23": 94
    },
    "adjDefRank": 56,
    "adjDefRankHistory": {
      "2020-01-19": 52,
      "2020-01-20": 52,
      "2020-01-21": 50,
      "2020-01-22": 55,
      "2020-01-23": 56
    },
    "adjEM": 9.7,
    "adjEmHistory": {
      "2020-01-19": 10.07,
      "2020-01-20": 10.07,
      "2020-01-21": 10.17,
      "2020-01-22": 9.72,
      "2020-01-23": 9.7
    },
    "adjOff": 103.7,
    "adjOffHistory": {
      "2020-01-19": 103.6,
      "2020-01-20": 103.6,
      "2020-01-21": 103.7,
      "2020-01-22": 103.6,
      "2020-01-23": 103.7
    },
    "adjOffRank": 130,
    "adjOffRankHistory": {
      "2020-01-19": 132,
      "2020-01-20": 132,
      "2020-01-21": 131,
      "2020-01-22": 133,
      "2020-01-23": 130
    },
    "adjTempo": 72.9,
    "adjTempoHistory": {
      "2020-01-19": 73.2,
      "2020-01-20": 73.2,
      "2020-01-21": 73.1,
      "2020-01-22": 72.9,
      "2020-01-23": 72.9
    },
    "adjTempoRank": 19,
    "adjTempoRankHistory": {
      "2020-01-19": 19,
      "2020-01-20": 19,
      "2020-01-21": 19,
      "2020-01-22": 20,
      "2020-01-23": 19
    },
    "conference": "BE",
    "rank": 79,
    "rankHistory": {
      "2020-01-19": 77,
      "2020-01-20": 77,
      "2020-01-21": 76,
      "2020-01-22": 78,
      "2020-01-23": 79
    },
    "record": "12-8"
  },
  "Stanford": {
    "adjDef": 88.8,
    "adjDefHistory": {
      "2020-01-19": 88.6,
      "2020-01-20": 88.7,
      "2020-01-21": 88.9,
      "2020-01-22": 88.8,
      "2020-01-23": 88.8
    },
    "adjDefRank": 13,
    "adjDefRankHistory": {
      "2020-01-19": 15,
      "2020-01-20": 15,
      "2020-01-21": 15,
      "2020-01-22": 16,
      "2020-01-23": 13
    },
    "adjEM": 16.74,
    "adjEmHistory": {
      "2020-01-19": 16.43,
      "2020-01-20": 16.44,
      "2020-01-21": 16.54,
      "2020-01-22": 16.52,
      "2020-01-23": 16.74
    },
    "adjOff": 105.5,
    "adjOffHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105.1,
      "2020-01-21": 105.4,
      "2020-01-22": 105.3,
      "2020-01-23": 105.5
    },
    "adjOffRank": 89,
    "adjOffRankHistory": {
      "2020-01-19": 97,
      "2020-01-20": 92,
      "2020-01-21": 90,
      "2020-01-22": 90,
      "2020-01-23": 89
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 68.2,
      "2020-01-20": 68.3,
      "2020-01-21": 68.1,
      "2020-01-22": 68.1,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 199,
    "adjTempoRankHistory": {
      "2020-01-19": 193,
      "2020-01-20": 193,
      "2020-01-21": 193,
      "2020-01-22": 195,
      "2020-01-23": 199
    },
    "conference": "P12",
    "rank": 35,
    "rankHistory": {
      "2020-01-19": 34,
      "2020-01-20": 34,
      "2020-01-21": 33,
      "2020-01-22": 35,
      "2020-01-23": 35
    },
    "record": "15-3"
  },
  "Stephen F. Austin": {
    "adjDef": 97.4,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.3,
      "2020-01-22": 98.1,
      "2020-01-23": 97.4
    },
    "adjDefRank": 93,
    "adjDefRankHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 111,
      "2020-01-22": 110,
      "2020-01-23": 93
    },
    "adjEM": 4.79,
    "adjEmHistory": {
      "2020-01-19": 4.54,
      "2020-01-20": 4.54,
      "2020-01-21": 4.59,
      "2020-01-22": 4.73,
      "2020-01-23": 4.79
    },
    "adjOff": 102.2,
    "adjOffHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.8,
      "2020-01-23": 102.2
    },
    "adjOffRank": 170,
    "adjOffRankHistory": {
      "2020-01-19": 153,
      "2020-01-20": 153,
      "2020-01-21": 155,
      "2020-01-22": 152,
      "2020-01-23": 170
    },
    "adjTempo": 71.7,
    "adjTempoHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 71.9,
      "2020-01-22": 71.9,
      "2020-01-23": 71.7
    },
    "adjTempoRank": 36,
    "adjTempoRankHistory": {
      "2020-01-19": 32,
      "2020-01-20": 33,
      "2020-01-21": 33,
      "2020-01-22": 33,
      "2020-01-23": 36
    },
    "conference": "Slnd",
    "rank": 116,
    "rankHistory": {
      "2020-01-19": 121,
      "2020-01-20": 121,
      "2020-01-21": 120,
      "2020-01-22": 120,
      "2020-01-23": 116
    },
    "record": "16-3"
  },
  "Stetson": {
    "adjDef": 108.3,
    "adjDefHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108.2,
      "2020-01-22": 108.1,
      "2020-01-23": 108.3
    },
    "adjDefRank": 306,
    "adjDefRankHistory": {
      "2020-01-19": 307,
      "2020-01-20": 307,
      "2020-01-21": 308,
      "2020-01-22": 307,
      "2020-01-23": 306
    },
    "adjEM": -13.2,
    "adjEmHistory": {
      "2020-01-19": -13.07,
      "2020-01-20": -13.07,
      "2020-01-21": -13.06,
      "2020-01-22": -13.12,
      "2020-01-23": -13.2
    },
    "adjOff": 95.1,
    "adjOffHistory": {
      "2020-01-19": 94.9,
      "2020-01-20": 94.9,
      "2020-01-21": 95.1,
      "2020-01-22": 95,
      "2020-01-23": 95.1
    },
    "adjOffRank": 298,
    "adjOffRankHistory": {
      "2020-01-19": 295,
      "2020-01-20": 295,
      "2020-01-21": 296,
      "2020-01-22": 296,
      "2020-01-23": 298
    },
    "adjTempo": 62.7,
    "adjTempoHistory": {
      "2020-01-19": 62.9,
      "2020-01-20": 62.9,
      "2020-01-21": 62.7,
      "2020-01-22": 62.7,
      "2020-01-23": 62.7
    },
    "adjTempoRank": 348,
    "adjTempoRankHistory": {
      "2020-01-19": 348,
      "2020-01-20": 348,
      "2020-01-21": 348,
      "2020-01-22": 348,
      "2020-01-23": 348
    },
    "conference": "ASun",
    "rank": 312,
    "rankHistory": {
      "2020-01-19": 311,
      "2020-01-20": 311,
      "2020-01-21": 311,
      "2020-01-22": 311,
      "2020-01-23": 312
    },
    "record": "9-11"
  },
  "Stony Brook": {
    "adjDef": 98.6,
    "adjDefHistory": {
      "2020-01-19": 97.3,
      "2020-01-20": 97.3,
      "2020-01-21": 97.4,
      "2020-01-22": 97.3,
      "2020-01-23": 98.6
    },
    "adjDefRank": 118,
    "adjDefRankHistory": {
      "2020-01-19": 93,
      "2020-01-20": 93,
      "2020-01-21": 91,
      "2020-01-22": 92,
      "2020-01-23": 118
    },
    "adjEM": 2.45,
    "adjEmHistory": {
      "2020-01-19": 3.99,
      "2020-01-20": 3.99,
      "2020-01-21": 4,
      "2020-01-22": 4.01,
      "2020-01-23": 2.45
    },
    "adjOff": 101,
    "adjOffHistory": {
      "2020-01-19": 101.3,
      "2020-01-20": 101.3,
      "2020-01-21": 101.4,
      "2020-01-22": 101.3,
      "2020-01-23": 101
    },
    "adjOffRank": 183,
    "adjOffRankHistory": {
      "2020-01-19": 179,
      "2020-01-20": 179,
      "2020-01-21": 179,
      "2020-01-22": 178,
      "2020-01-23": 183
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.7,
      "2020-01-22": 69.7,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 105,
    "adjTempoRankHistory": {
      "2020-01-19": 113,
      "2020-01-20": 114,
      "2020-01-21": 113,
      "2020-01-22": 115,
      "2020-01-23": 105
    },
    "conference": "AE",
    "rank": 137,
    "rankHistory": {
      "2020-01-19": 126,
      "2020-01-20": 126,
      "2020-01-21": 127,
      "2020-01-22": 127,
      "2020-01-23": 137
    },
    "record": "13-8"
  },
  "Syracuse": {
    "adjDef": 98.5,
    "adjDefHistory": {
      "2020-01-19": 97.5,
      "2020-01-20": 97.5,
      "2020-01-21": 97.7,
      "2020-01-22": 97.6,
      "2020-01-23": 98.5
    },
    "adjDefRank": 116,
    "adjDefRankHistory": {
      "2020-01-19": 96,
      "2020-01-20": 96,
      "2020-01-21": 96,
      "2020-01-22": 97,
      "2020-01-23": 116
    },
    "adjEM": 13.45,
    "adjEmHistory": {
      "2020-01-19": 13.19,
      "2020-01-20": 13.19,
      "2020-01-21": 13.11,
      "2020-01-22": 13.07,
      "2020-01-23": 13.45
    },
    "adjOff": 112,
    "adjOffHistory": {
      "2020-01-19": 110.7,
      "2020-01-20": 110.7,
      "2020-01-21": 110.8,
      "2020-01-22": 110.7,
      "2020-01-23": 112
    },
    "adjOffRank": 21,
    "adjOffRankHistory": {
      "2020-01-19": 29,
      "2020-01-20": 29,
      "2020-01-21": 29,
      "2020-01-22": 30,
      "2020-01-23": 21
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.2,
      "2020-01-20": 67.2,
      "2020-01-21": 67.1,
      "2020-01-22": 67,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 241,
    "adjTempoRankHistory": {
      "2020-01-19": 245,
      "2020-01-20": 245,
      "2020-01-21": 247,
      "2020-01-22": 248,
      "2020-01-23": 241
    },
    "conference": "ACC",
    "rank": 51,
    "rankHistory": {
      "2020-01-19": 52,
      "2020-01-20": 52,
      "2020-01-21": 52,
      "2020-01-22": 53,
      "2020-01-23": 51
    },
    "record": "12-7"
  },
  "TCU": {
    "adjDef": 92.3,
    "adjDefHistory": {
      "2020-01-19": 92.9,
      "2020-01-20": 92.9,
      "2020-01-21": 93,
      "2020-01-22": 92.1,
      "2020-01-23": 92.3
    },
    "adjDefRank": 34,
    "adjDefRankHistory": {
      "2020-01-19": 44,
      "2020-01-20": 44,
      "2020-01-21": 43,
      "2020-01-22": 33,
      "2020-01-23": 34
    },
    "adjEM": 12.05,
    "adjEmHistory": {
      "2020-01-19": 10.86,
      "2020-01-20": 10.86,
      "2020-01-21": 11.02,
      "2020-01-22": 12.08,
      "2020-01-23": 12.05
    },
    "adjOff": 104.4,
    "adjOffHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.7,
      "2020-01-21": 104,
      "2020-01-22": 104.2,
      "2020-01-23": 104.4
    },
    "adjOffRank": 118,
    "adjOffRankHistory": {
      "2020-01-19": 128,
      "2020-01-20": 129,
      "2020-01-21": 126,
      "2020-01-22": 122,
      "2020-01-23": 118
    },
    "adjTempo": 65.7,
    "adjTempoHistory": {
      "2020-01-19": 65.9,
      "2020-01-20": 65.9,
      "2020-01-21": 65.8,
      "2020-01-22": 65.7,
      "2020-01-23": 65.7
    },
    "adjTempoRank": 291,
    "adjTempoRankHistory": {
      "2020-01-19": 291,
      "2020-01-20": 291,
      "2020-01-21": 290,
      "2020-01-22": 291,
      "2020-01-23": 291
    },
    "conference": "B12",
    "rank": 60,
    "rankHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 64,
      "2020-01-22": 60,
      "2020-01-23": 60
    },
    "record": "13-5"
  },
  "Temple": {
    "adjDef": 91.2,
    "adjDefHistory": {
      "2020-01-19": 89.3,
      "2020-01-20": 89.3,
      "2020-01-21": 89.4,
      "2020-01-22": 89.2,
      "2020-01-23": 91.2
    },
    "adjDefRank": 26,
    "adjDefRankHistory": {
      "2020-01-19": 18,
      "2020-01-20": 18,
      "2020-01-21": 18,
      "2020-01-22": 17,
      "2020-01-23": 26
    },
    "adjEM": 8.23,
    "adjEmHistory": {
      "2020-01-19": 8.41,
      "2020-01-20": 8.41,
      "2020-01-21": 8.38,
      "2020-01-22": 8.44,
      "2020-01-23": 8.23
    },
    "adjOff": 99.4,
    "adjOffHistory": {
      "2020-01-19": 97.7,
      "2020-01-20": 97.7,
      "2020-01-21": 97.8,
      "2020-01-22": 97.6,
      "2020-01-23": 99.4
    },
    "adjOffRank": 212,
    "adjOffRankHistory": {
      "2020-01-19": 242,
      "2020-01-20": 242,
      "2020-01-21": 243,
      "2020-01-22": 245,
      "2020-01-23": 212
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 70.9,
      "2020-01-22": 70.9,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 60,
    "adjTempoRankHistory": {
      "2020-01-19": 58,
      "2020-01-20": 58,
      "2020-01-21": 59,
      "2020-01-22": 59,
      "2020-01-23": 60
    },
    "conference": "Amer",
    "rank": 90,
    "rankHistory": {
      "2020-01-19": 87,
      "2020-01-20": 87,
      "2020-01-21": 87,
      "2020-01-22": 87,
      "2020-01-23": 90
    },
    "record": "10-8"
  },
  "Tennessee": {
    "adjDef": 90,
    "adjDefHistory": {
      "2020-01-19": 90.6,
      "2020-01-20": 90.6,
      "2020-01-21": 90.7,
      "2020-01-22": 89.9,
      "2020-01-23": 90
    },
    "adjDefRank": 21,
    "adjDefRankHistory": {
      "2020-01-19": 25,
      "2020-01-20": 25,
      "2020-01-21": 24,
      "2020-01-22": 22,
      "2020-01-23": 21
    },
    "adjEM": 13.53,
    "adjEmHistory": {
      "2020-01-19": 12.67,
      "2020-01-20": 12.67,
      "2020-01-21": 12.67,
      "2020-01-22": 13.76,
      "2020-01-23": 13.53
    },
    "adjOff": 103.5,
    "adjOffHistory": {
      "2020-01-19": 103.3,
      "2020-01-20": 103.3,
      "2020-01-21": 103.4,
      "2020-01-22": 103.6,
      "2020-01-23": 103.5
    },
    "adjOffRank": 138,
    "adjOffRankHistory": {
      "2020-01-19": 142,
      "2020-01-20": 143,
      "2020-01-21": 145,
      "2020-01-22": 132,
      "2020-01-23": 138
    },
    "adjTempo": 65.3,
    "adjTempoHistory": {
      "2020-01-19": 65.5,
      "2020-01-20": 65.5,
      "2020-01-21": 65.4,
      "2020-01-22": 65.3,
      "2020-01-23": 65.3
    },
    "adjTempoRank": 307,
    "adjTempoRankHistory": {
      "2020-01-19": 306,
      "2020-01-20": 305,
      "2020-01-21": 305,
      "2020-01-22": 308,
      "2020-01-23": 307
    },
    "conference": "SEC",
    "rank": 50,
    "rankHistory": {
      "2020-01-19": 56,
      "2020-01-20": 56,
      "2020-01-21": 55,
      "2020-01-22": 51,
      "2020-01-23": 50
    },
    "record": "12-6"
  },
  "Tennessee Martin": {
    "adjDef": 119.4,
    "adjDefHistory": {
      "2020-01-19": 118.9,
      "2020-01-20": 118.9,
      "2020-01-21": 119.2,
      "2020-01-22": 119.1,
      "2020-01-23": 119.4
    },
    "adjDefRank": 352,
    "adjDefRankHistory": {
      "2020-01-19": 352,
      "2020-01-20": 352,
      "2020-01-21": 352,
      "2020-01-22": 352,
      "2020-01-23": 352
    },
    "adjEM": -14.29,
    "adjEmHistory": {
      "2020-01-19": -14.16,
      "2020-01-20": -14.16,
      "2020-01-21": -14.15,
      "2020-01-22": -14.14,
      "2020-01-23": -14.29
    },
    "adjOff": 105.1,
    "adjOffHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 105,
      "2020-01-22": 105,
      "2020-01-23": 105.1
    },
    "adjOffRank": 97,
    "adjOffRankHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 101,
      "2020-01-22": 96,
      "2020-01-23": 97
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.5,
      "2020-01-21": 68.4,
      "2020-01-22": 68.4,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 179,
    "adjTempoRankHistory": {
      "2020-01-19": 183,
      "2020-01-20": 183,
      "2020-01-21": 181,
      "2020-01-22": 178,
      "2020-01-23": 179
    },
    "conference": "OVC",
    "rank": 323,
    "rankHistory": {
      "2020-01-19": 323,
      "2020-01-20": 323,
      "2020-01-21": 322,
      "2020-01-22": 321,
      "2020-01-23": 323
    },
    "record": "5-12"
  },
  "Tennessee St.": {
    "adjDef": 106.1,
    "adjDefHistory": {
      "2020-01-19": 105.9,
      "2020-01-20": 105.9,
      "2020-01-21": 106,
      "2020-01-22": 105.9,
      "2020-01-23": 106.1
    },
    "adjDefRank": 271,
    "adjDefRankHistory": {
      "2020-01-19": 272,
      "2020-01-20": 272,
      "2020-01-21": 271,
      "2020-01-22": 270,
      "2020-01-23": 271
    },
    "adjEM": -6.4,
    "adjEmHistory": {
      "2020-01-19": -6.39,
      "2020-01-20": -6.39,
      "2020-01-21": -6.37,
      "2020-01-22": -6.41,
      "2020-01-23": -6.4
    },
    "adjOff": 99.7,
    "adjOffHistory": {
      "2020-01-19": 99.5,
      "2020-01-20": 99.5,
      "2020-01-21": 99.6,
      "2020-01-22": 99.5,
      "2020-01-23": 99.7
    },
    "adjOffRank": 207,
    "adjOffRankHistory": {
      "2020-01-19": 209,
      "2020-01-20": 208,
      "2020-01-21": 208,
      "2020-01-22": 210,
      "2020-01-23": 207
    },
    "adjTempo": 70.6,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.7,
      "2020-01-22": 70.7,
      "2020-01-23": 70.6
    },
    "adjTempoRank": 68,
    "adjTempoRankHistory": {
      "2020-01-19": 62,
      "2020-01-20": 63,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 68
    },
    "conference": "OVC",
    "rank": 241,
    "rankHistory": {
      "2020-01-19": 243,
      "2020-01-20": 243,
      "2020-01-21": 242,
      "2020-01-22": 242,
      "2020-01-23": 241
    },
    "record": "12-7"
  },
  "Tennessee Tech": {
    "adjDef": 109,
    "adjDefHistory": {
      "2020-01-19": 108.7,
      "2020-01-20": 108.7,
      "2020-01-21": 108.9,
      "2020-01-22": 108.9,
      "2020-01-23": 109
    },
    "adjDefRank": 316,
    "adjDefRankHistory": {
      "2020-01-19": 315,
      "2020-01-20": 315,
      "2020-01-21": 315,
      "2020-01-22": 316,
      "2020-01-23": 316
    },
    "adjEM": -20.24,
    "adjEmHistory": {
      "2020-01-19": -19.94,
      "2020-01-20": -19.94,
      "2020-01-21": -19.94,
      "2020-01-22": -20.06,
      "2020-01-23": -20.24
    },
    "adjOff": 88.8,
    "adjOffHistory": {
      "2020-01-19": 88.7,
      "2020-01-20": 88.7,
      "2020-01-21": 89,
      "2020-01-22": 88.8,
      "2020-01-23": 88.8
    },
    "adjOffRank": 343,
    "adjOffRankHistory": {
      "2020-01-19": 344,
      "2020-01-20": 344,
      "2020-01-21": 343,
      "2020-01-22": 343,
      "2020-01-23": 343
    },
    "adjTempo": 66.8,
    "adjTempoHistory": {
      "2020-01-19": 66.9,
      "2020-01-20": 66.9,
      "2020-01-21": 66.8,
      "2020-01-22": 66.8,
      "2020-01-23": 66.8
    },
    "adjTempoRank": 257,
    "adjTempoRankHistory": {
      "2020-01-19": 255,
      "2020-01-20": 255,
      "2020-01-21": 259,
      "2020-01-22": 257,
      "2020-01-23": 257
    },
    "conference": "OVC",
    "rank": 343,
    "rankHistory": {
      "2020-01-19": 342,
      "2020-01-20": 342,
      "2020-01-21": 342,
      "2020-01-22": 342,
      "2020-01-23": 343
    },
    "record": "4-15"
  },
  "Texas": {
    "adjDef": 93.7,
    "adjDefHistory": {
      "2020-01-19": 92.3,
      "2020-01-20": 92.4,
      "2020-01-21": 93.8,
      "2020-01-22": 93.6,
      "2020-01-23": 93.7
    },
    "adjDefRank": 52,
    "adjDefRankHistory": {
      "2020-01-19": 37,
      "2020-01-20": 37,
      "2020-01-21": 53,
      "2020-01-22": 51,
      "2020-01-23": 52
    },
    "adjEM": 10.73,
    "adjEmHistory": {
      "2020-01-19": 12.08,
      "2020-01-20": 12.05,
      "2020-01-21": 10.8,
      "2020-01-22": 10.74,
      "2020-01-23": 10.73
    },
    "adjOff": 104.5,
    "adjOffHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.6,
      "2020-01-22": 104.3,
      "2020-01-23": 104.5
    },
    "adjOffRank": 113,
    "adjOffRankHistory": {
      "2020-01-19": 114,
      "2020-01-20": 114,
      "2020-01-21": 111,
      "2020-01-22": 116,
      "2020-01-23": 113
    },
    "adjTempo": 66.3,
    "adjTempoHistory": {
      "2020-01-19": 66.2,
      "2020-01-20": 66.3,
      "2020-01-21": 66.3,
      "2020-01-22": 66.3,
      "2020-01-23": 66.3
    },
    "adjTempoRank": 275,
    "adjTempoRankHistory": {
      "2020-01-19": 280,
      "2020-01-20": 280,
      "2020-01-21": 273,
      "2020-01-22": 273,
      "2020-01-23": 275
    },
    "conference": "B12",
    "rank": 70,
    "rankHistory": {
      "2020-01-19": 60,
      "2020-01-20": 60,
      "2020-01-21": 66,
      "2020-01-22": 69,
      "2020-01-23": 70
    },
    "record": "12-6"
  },
  "Texas A&M": {
    "adjDef": 95.6,
    "adjDefHistory": {
      "2020-01-19": 95.5,
      "2020-01-20": 95.5,
      "2020-01-21": 95.7,
      "2020-01-22": 95.6,
      "2020-01-23": 95.6
    },
    "adjDefRank": 79,
    "adjDefRankHistory": {
      "2020-01-19": 77,
      "2020-01-20": 77,
      "2020-01-21": 77,
      "2020-01-22": 81,
      "2020-01-23": 79
    },
    "adjEM": 1.37,
    "adjEmHistory": {
      "2020-01-19": 0.74,
      "2020-01-20": 0.73,
      "2020-01-21": 0.65,
      "2020-01-22": 1.56,
      "2020-01-23": 1.37
    },
    "adjOff": 97,
    "adjOffHistory": {
      "2020-01-19": 96.3,
      "2020-01-20": 96.3,
      "2020-01-21": 96.3,
      "2020-01-22": 97.1,
      "2020-01-23": 97
    },
    "adjOffRank": 265,
    "adjOffRankHistory": {
      "2020-01-19": 275,
      "2020-01-20": 276,
      "2020-01-21": 278,
      "2020-01-22": 258,
      "2020-01-23": 265
    },
    "adjTempo": 64.2,
    "adjTempoHistory": {
      "2020-01-19": 64.2,
      "2020-01-20": 64.2,
      "2020-01-21": 64.1,
      "2020-01-22": 64.2,
      "2020-01-23": 64.2
    },
    "adjTempoRank": 329,
    "adjTempoRankHistory": {
      "2020-01-19": 337,
      "2020-01-20": 337,
      "2020-01-21": 335,
      "2020-01-22": 329,
      "2020-01-23": 329
    },
    "conference": "SEC",
    "rank": 152,
    "rankHistory": {
      "2020-01-19": 158,
      "2020-01-20": 157,
      "2020-01-21": 160,
      "2020-01-22": 150,
      "2020-01-23": 152
    },
    "record": "9-8"
  },
  "Texas A&M Corpus Chris": {
    "adjDef": 103.7,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.6,
      "2020-01-23": 103.7
    },
    "adjDefRank": 220,
    "adjDefRankHistory": {
      "2020-01-19": 194,
      "2020-01-20": 195,
      "2020-01-21": 193,
      "2020-01-22": 187,
      "2020-01-23": 220
    },
    "adjEM": -11.31,
    "adjEmHistory": {
      "2020-01-19": -10.98,
      "2020-01-20": -10.98,
      "2020-01-21": -10.96,
      "2020-01-22": -10.89,
      "2020-01-23": -11.31
    },
    "adjOff": 92.4,
    "adjOffHistory": {
      "2020-01-19": 91.7,
      "2020-01-20": 91.7,
      "2020-01-21": 91.8,
      "2020-01-22": 91.7,
      "2020-01-23": 92.4
    },
    "adjOffRank": 321,
    "adjOffRankHistory": {
      "2020-01-19": 326,
      "2020-01-20": 326,
      "2020-01-21": 326,
      "2020-01-22": 326,
      "2020-01-23": 321
    },
    "adjTempo": 64.8,
    "adjTempoHistory": {
      "2020-01-19": 65.2,
      "2020-01-20": 65.2,
      "2020-01-21": 65,
      "2020-01-22": 65,
      "2020-01-23": 64.8
    },
    "adjTempoRank": 322,
    "adjTempoRankHistory": {
      "2020-01-19": 316,
      "2020-01-20": 315,
      "2020-01-21": 315,
      "2020-01-22": 315,
      "2020-01-23": 322
    },
    "conference": "Slnd",
    "rank": 301,
    "rankHistory": {
      "2020-01-19": 292,
      "2020-01-20": 292,
      "2020-01-21": 293,
      "2020-01-22": 293,
      "2020-01-23": 301
    },
    "record": "8-12"
  },
  "Texas Southern": {
    "adjDef": 104.1,
    "adjDefHistory": {
      "2020-01-19": 105.2,
      "2020-01-20": 105.2,
      "2020-01-21": 104.3,
      "2020-01-22": 104.1,
      "2020-01-23": 104.1
    },
    "adjDefRank": 229,
    "adjDefRankHistory": {
      "2020-01-19": 254,
      "2020-01-20": 254,
      "2020-01-21": 230,
      "2020-01-22": 231,
      "2020-01-23": 229
    },
    "adjEM": -5.49,
    "adjEmHistory": {
      "2020-01-19": -6.33,
      "2020-01-20": -6.33,
      "2020-01-21": -5.63,
      "2020-01-22": -5.53,
      "2020-01-23": -5.49
    },
    "adjOff": 98.6,
    "adjOffHistory": {
      "2020-01-19": 98.9,
      "2020-01-20": 98.9,
      "2020-01-21": 98.7,
      "2020-01-22": 98.6,
      "2020-01-23": 98.6
    },
    "adjOffRank": 229,
    "adjOffRankHistory": {
      "2020-01-19": 221,
      "2020-01-20": 220,
      "2020-01-21": 226,
      "2020-01-22": 225,
      "2020-01-23": 229
    },
    "adjTempo": 73.1,
    "adjTempoHistory": {
      "2020-01-19": 73.5,
      "2020-01-20": 73.5,
      "2020-01-21": 73.2,
      "2020-01-22": 73.2,
      "2020-01-23": 73.1
    },
    "adjTempoRank": 17,
    "adjTempoRankHistory": {
      "2020-01-19": 17,
      "2020-01-20": 17,
      "2020-01-21": 17,
      "2020-01-22": 17,
      "2020-01-23": 17
    },
    "conference": "SWAC",
    "rank": 230,
    "rankHistory": {
      "2020-01-19": 241,
      "2020-01-20": 241,
      "2020-01-21": 231,
      "2020-01-22": 231,
      "2020-01-23": 230
    },
    "record": "7-11"
  },
  "Texas St.": {
    "adjDef": 99.3,
    "adjDefHistory": {
      "2020-01-19": 99.1,
      "2020-01-20": 99.1,
      "2020-01-21": 99.3,
      "2020-01-22": 99.2,
      "2020-01-23": 99.3
    },
    "adjDefRank": 133,
    "adjDefRankHistory": {
      "2020-01-19": 132,
      "2020-01-20": 132,
      "2020-01-21": 132,
      "2020-01-22": 132,
      "2020-01-23": 133
    },
    "adjEM": 4.21,
    "adjEmHistory": {
      "2020-01-19": 4.15,
      "2020-01-20": 4.15,
      "2020-01-21": 4.2,
      "2020-01-22": 4.21,
      "2020-01-23": 4.21
    },
    "adjOff": 103.5,
    "adjOffHistory": {
      "2020-01-19": 103.3,
      "2020-01-20": 103.3,
      "2020-01-21": 103.5,
      "2020-01-22": 103.4,
      "2020-01-23": 103.5
    },
    "adjOffRank": 137,
    "adjOffRankHistory": {
      "2020-01-19": 140,
      "2020-01-20": 141,
      "2020-01-21": 142,
      "2020-01-22": 139,
      "2020-01-23": 137
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65.2,
      "2020-01-20": 65.2,
      "2020-01-21": 65,
      "2020-01-22": 65,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 318,
    "adjTempoRankHistory": {
      "2020-01-19": 317,
      "2020-01-20": 316,
      "2020-01-21": 317,
      "2020-01-22": 316,
      "2020-01-23": 318
    },
    "conference": "SB",
    "rank": 122,
    "rankHistory": {
      "2020-01-19": 125,
      "2020-01-20": 125,
      "2020-01-21": 125,
      "2020-01-22": 124,
      "2020-01-23": 122
    },
    "record": "12-8"
  },
  "Texas Tech": {
    "adjDef": 87.3,
    "adjDefHistory": {
      "2020-01-19": 86.9,
      "2020-01-20": 86.9,
      "2020-01-21": 87,
      "2020-01-22": 87.3,
      "2020-01-23": 87.3
    },
    "adjDefRank": 7,
    "adjDefRankHistory": {
      "2020-01-19": 7,
      "2020-01-20": 7,
      "2020-01-21": 7,
      "2020-01-22": 8,
      "2020-01-23": 7
    },
    "adjEM": 17.94,
    "adjEmHistory": {
      "2020-01-19": 19.22,
      "2020-01-20": 19.22,
      "2020-01-21": 19.23,
      "2020-01-22": 18.03,
      "2020-01-23": 17.94
    },
    "adjOff": 105.3,
    "adjOffHistory": {
      "2020-01-19": 106.1,
      "2020-01-20": 106.1,
      "2020-01-21": 106.2,
      "2020-01-22": 105.3,
      "2020-01-23": 105.3
    },
    "adjOffRank": 96,
    "adjOffRankHistory": {
      "2020-01-19": 75,
      "2020-01-20": 74,
      "2020-01-21": 75,
      "2020-01-22": 88,
      "2020-01-23": 96
    },
    "adjTempo": 67.5,
    "adjTempoHistory": {
      "2020-01-19": 67.7,
      "2020-01-20": 67.7,
      "2020-01-21": 67.6,
      "2020-01-22": 67.5,
      "2020-01-23": 67.5
    },
    "adjTempoRank": 221,
    "adjTempoRankHistory": {
      "2020-01-19": 221,
      "2020-01-20": 221,
      "2020-01-21": 221,
      "2020-01-22": 221,
      "2020-01-23": 221
    },
    "conference": "B12",
    "rank": 25,
    "rankHistory": {
      "2020-01-19": 19,
      "2020-01-20": 19,
      "2020-01-21": 19,
      "2020-01-22": 24,
      "2020-01-23": 25
    },
    "record": "12-6"
  },
  "The Citadel": {
    "adjDef": 109.2,
    "adjDefHistory": {
      "2020-01-19": 108.6,
      "2020-01-20": 108.6,
      "2020-01-21": 108.8,
      "2020-01-22": 108.6,
      "2020-01-23": 109.2
    },
    "adjDefRank": 319,
    "adjDefRankHistory": {
      "2020-01-19": 313,
      "2020-01-20": 313,
      "2020-01-21": 311,
      "2020-01-22": 311,
      "2020-01-23": 319
    },
    "adjEM": -11.4,
    "adjEmHistory": {
      "2020-01-19": -10.63,
      "2020-01-20": -10.63,
      "2020-01-21": -10.65,
      "2020-01-22": -10.57,
      "2020-01-23": -11.4
    },
    "adjOff": 97.8,
    "adjOffHistory": {
      "2020-01-19": 97.9,
      "2020-01-20": 97.9,
      "2020-01-21": 98.1,
      "2020-01-22": 98,
      "2020-01-23": 97.8
    },
    "adjOffRank": 244,
    "adjOffRankHistory": {
      "2020-01-19": 236,
      "2020-01-20": 236,
      "2020-01-21": 235,
      "2020-01-22": 236,
      "2020-01-23": 244
    },
    "adjTempo": 75.1,
    "adjTempoHistory": {
      "2020-01-19": 75.2,
      "2020-01-20": 75.2,
      "2020-01-21": 75,
      "2020-01-22": 75,
      "2020-01-23": 75.1
    },
    "adjTempoRank": 4,
    "adjTempoRankHistory": {
      "2020-01-19": 6,
      "2020-01-20": 6,
      "2020-01-21": 6,
      "2020-01-22": 6,
      "2020-01-23": 4
    },
    "conference": "SC",
    "rank": 302,
    "rankHistory": {
      "2020-01-19": 290,
      "2020-01-20": 290,
      "2020-01-21": 290,
      "2020-01-22": 291,
      "2020-01-23": 302
    },
    "record": "6-12"
  },
  "Toledo": {
    "adjDef": 101.5,
    "adjDefHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.4,
      "2020-01-22": 101.2,
      "2020-01-23": 101.5
    },
    "adjDefRank": 162,
    "adjDefRankHistory": {
      "2020-01-19": 160,
      "2020-01-20": 160,
      "2020-01-21": 162,
      "2020-01-22": 163,
      "2020-01-23": 162
    },
    "adjEM": 5.37,
    "adjEmHistory": {
      "2020-01-19": 5.06,
      "2020-01-20": 5.06,
      "2020-01-21": 5.14,
      "2020-01-22": 5.53,
      "2020-01-23": 5.37
    },
    "adjOff": 106.8,
    "adjOffHistory": {
      "2020-01-19": 106.3,
      "2020-01-20": 106.3,
      "2020-01-21": 106.5,
      "2020-01-22": 106.8,
      "2020-01-23": 106.8
    },
    "adjOffRank": 67,
    "adjOffRankHistory": {
      "2020-01-19": 69,
      "2020-01-20": 69,
      "2020-01-21": 70,
      "2020-01-22": 66,
      "2020-01-23": 67
    },
    "adjTempo": 68.1,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 67.9,
      "2020-01-21": 67.8,
      "2020-01-22": 68.1,
      "2020-01-23": 68.1
    },
    "adjTempoRank": 198,
    "adjTempoRankHistory": {
      "2020-01-19": 214,
      "2020-01-20": 215,
      "2020-01-21": 213,
      "2020-01-22": 196,
      "2020-01-23": 198
    },
    "conference": "MAC",
    "rank": 112,
    "rankHistory": {
      "2020-01-19": 116,
      "2020-01-20": 116,
      "2020-01-21": 115,
      "2020-01-22": 110,
      "2020-01-23": 112
    },
    "record": "11-8"
  },
  "Towson": {
    "adjDef": 102.2,
    "adjDefHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102,
      "2020-01-21": 102.2,
      "2020-01-22": 102,
      "2020-01-23": 102.2
    },
    "adjDefRank": 178,
    "adjDefRankHistory": {
      "2020-01-19": 172,
      "2020-01-20": 172,
      "2020-01-21": 174,
      "2020-01-22": 174,
      "2020-01-23": 178
    },
    "adjEM": -0.91,
    "adjEmHistory": {
      "2020-01-19": -0.74,
      "2020-01-20": -0.74,
      "2020-01-21": -0.84,
      "2020-01-22": -0.9,
      "2020-01-23": -0.91
    },
    "adjOff": 101.3,
    "adjOffHistory": {
      "2020-01-19": 101.2,
      "2020-01-20": 101.2,
      "2020-01-21": 101.3,
      "2020-01-22": 101.1,
      "2020-01-23": 101.3
    },
    "adjOffRank": 181,
    "adjOffRankHistory": {
      "2020-01-19": 181,
      "2020-01-20": 181,
      "2020-01-21": 182,
      "2020-01-22": 183,
      "2020-01-23": 181
    },
    "adjTempo": 65.9,
    "adjTempoHistory": {
      "2020-01-19": 66.2,
      "2020-01-20": 66.2,
      "2020-01-21": 66,
      "2020-01-22": 66,
      "2020-01-23": 65.9
    },
    "adjTempoRank": 285,
    "adjTempoRankHistory": {
      "2020-01-19": 283,
      "2020-01-20": 283,
      "2020-01-21": 283,
      "2020-01-22": 284,
      "2020-01-23": 285
    },
    "conference": "CAA",
    "rank": 181,
    "rankHistory": {
      "2020-01-19": 181,
      "2020-01-20": 181,
      "2020-01-21": 181,
      "2020-01-22": 181,
      "2020-01-23": 181
    },
    "record": "10-9"
  },
  "Troy": {
    "adjDef": 104.2,
    "adjDefHistory": {
      "2020-01-19": 104,
      "2020-01-20": 104,
      "2020-01-21": 104.2,
      "2020-01-22": 104,
      "2020-01-23": 104.2
    },
    "adjDefRank": 231,
    "adjDefRankHistory": {
      "2020-01-19": 225,
      "2020-01-20": 226,
      "2020-01-21": 227,
      "2020-01-22": 228,
      "2020-01-23": 231
    },
    "adjEM": -7.53,
    "adjEmHistory": {
      "2020-01-19": -7.27,
      "2020-01-20": -7.27,
      "2020-01-21": -7.25,
      "2020-01-22": -7.2,
      "2020-01-23": -7.53
    },
    "adjOff": 96.7,
    "adjOffHistory": {
      "2020-01-19": 96.7,
      "2020-01-20": 96.7,
      "2020-01-21": 96.9,
      "2020-01-22": 96.8,
      "2020-01-23": 96.7
    },
    "adjOffRank": 269,
    "adjOffRankHistory": {
      "2020-01-19": 266,
      "2020-01-20": 266,
      "2020-01-21": 265,
      "2020-01-22": 263,
      "2020-01-23": 269
    },
    "adjTempo": 69.3,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.2,
      "2020-01-23": 69.3
    },
    "adjTempoRank": 125,
    "adjTempoRankHistory": {
      "2020-01-19": 134,
      "2020-01-20": 134,
      "2020-01-21": 134,
      "2020-01-22": 134,
      "2020-01-23": 125
    },
    "conference": "SB",
    "rank": 258,
    "rankHistory": {
      "2020-01-19": 254,
      "2020-01-20": 254,
      "2020-01-21": 254,
      "2020-01-22": 254,
      "2020-01-23": 258
    },
    "record": "8-13"
  },
  "Tulane": {
    "adjDef": 102.4,
    "adjDefHistory": {
      "2020-01-19": 102.4,
      "2020-01-20": 102.4,
      "2020-01-21": 102.5,
      "2020-01-22": 102.4,
      "2020-01-23": 102.4
    },
    "adjDefRank": 184,
    "adjDefRankHistory": {
      "2020-01-19": 182,
      "2020-01-20": 181,
      "2020-01-21": 183,
      "2020-01-22": 181,
      "2020-01-23": 184
    },
    "adjEM": 0.15,
    "adjEmHistory": {
      "2020-01-19": -0.13,
      "2020-01-20": -0.13,
      "2020-01-21": -0.03,
      "2020-01-22": 0.07,
      "2020-01-23": 0.15
    },
    "adjOff": 102.6,
    "adjOffHistory": {
      "2020-01-19": 102.3,
      "2020-01-20": 102.3,
      "2020-01-21": 102.5,
      "2020-01-22": 102.5,
      "2020-01-23": 102.6
    },
    "adjOffRank": 159,
    "adjOffRankHistory": {
      "2020-01-19": 164,
      "2020-01-20": 164,
      "2020-01-21": 163,
      "2020-01-22": 162,
      "2020-01-23": 159
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.2,
      "2020-01-22": 67.1,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 243,
    "adjTempoRankHistory": {
      "2020-01-19": 237,
      "2020-01-20": 236,
      "2020-01-21": 242,
      "2020-01-22": 241,
      "2020-01-23": 243
    },
    "conference": "Amer",
    "rank": 167,
    "rankHistory": {
      "2020-01-19": 172,
      "2020-01-20": 172,
      "2020-01-21": 169,
      "2020-01-22": 166,
      "2020-01-23": 167
    },
    "record": "10-8"
  },
  "Tulsa": {
    "adjDef": 92.7,
    "adjDefHistory": {
      "2020-01-19": 94.7,
      "2020-01-20": 94.7,
      "2020-01-21": 94.9,
      "2020-01-22": 94.7,
      "2020-01-23": 92.7
    },
    "adjDefRank": 41,
    "adjDefRankHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 66,
      "2020-01-22": 66,
      "2020-01-23": 41
    },
    "adjEM": 10.22,
    "adjEmHistory": {
      "2020-01-19": 7.4,
      "2020-01-20": 7.4,
      "2020-01-21": 7.36,
      "2020-01-22": 7.39,
      "2020-01-23": 10.22
    },
    "adjOff": 103,
    "adjOffHistory": {
      "2020-01-19": 102.1,
      "2020-01-20": 102.1,
      "2020-01-21": 102.3,
      "2020-01-22": 102.1,
      "2020-01-23": 103
    },
    "adjOffRank": 153,
    "adjOffRankHistory": {
      "2020-01-19": 167,
      "2020-01-20": 168,
      "2020-01-21": 166,
      "2020-01-22": 169,
      "2020-01-23": 153
    },
    "adjTempo": 65.8,
    "adjTempoHistory": {
      "2020-01-19": 65.9,
      "2020-01-20": 65.9,
      "2020-01-21": 65.8,
      "2020-01-22": 65.7,
      "2020-01-23": 65.8
    },
    "adjTempoRank": 289,
    "adjTempoRankHistory": {
      "2020-01-19": 292,
      "2020-01-20": 292,
      "2020-01-21": 293,
      "2020-01-22": 293,
      "2020-01-23": 289
    },
    "conference": "Amer",
    "rank": 74,
    "rankHistory": {
      "2020-01-19": 96,
      "2020-01-20": 96,
      "2020-01-21": 95,
      "2020-01-22": 95,
      "2020-01-23": 74
    },
    "record": "13-6"
  },
  "UAB": {
    "adjDef": 99,
    "adjDefHistory": {
      "2020-01-19": 98.7,
      "2020-01-20": 98.7,
      "2020-01-21": 98.8,
      "2020-01-22": 98.7,
      "2020-01-23": 99
    },
    "adjDefRank": 129,
    "adjDefRankHistory": {
      "2020-01-19": 126,
      "2020-01-20": 126,
      "2020-01-21": 125,
      "2020-01-22": 122,
      "2020-01-23": 129
    },
    "adjEM": -0.55,
    "adjEmHistory": {
      "2020-01-19": -0.22,
      "2020-01-20": -0.23,
      "2020-01-21": -0.24,
      "2020-01-22": -0.25,
      "2020-01-23": -0.55
    },
    "adjOff": 98.4,
    "adjOffHistory": {
      "2020-01-19": 98.5,
      "2020-01-20": 98.5,
      "2020-01-21": 98.6,
      "2020-01-22": 98.4,
      "2020-01-23": 98.4
    },
    "adjOffRank": 233,
    "adjOffRankHistory": {
      "2020-01-19": 230,
      "2020-01-20": 229,
      "2020-01-21": 229,
      "2020-01-22": 228,
      "2020-01-23": 233
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.2,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 330,
    "adjTempoRankHistory": {
      "2020-01-19": 331,
      "2020-01-20": 331,
      "2020-01-21": 331,
      "2020-01-22": 331,
      "2020-01-23": 330
    },
    "conference": "CUSA",
    "rank": 178,
    "rankHistory": {
      "2020-01-19": 173,
      "2020-01-20": 173,
      "2020-01-21": 172,
      "2020-01-22": 172,
      "2020-01-23": 178
    },
    "record": "12-7"
  },
  "UC Davis": {
    "adjDef": 106.5,
    "adjDefHistory": {
      "2020-01-19": 105.6,
      "2020-01-20": 105.7,
      "2020-01-21": 105.9,
      "2020-01-22": 105.7,
      "2020-01-23": 106.5
    },
    "adjDefRank": 279,
    "adjDefRankHistory": {
      "2020-01-19": 265,
      "2020-01-20": 265,
      "2020-01-21": 264,
      "2020-01-22": 263,
      "2020-01-23": 279
    },
    "adjEM": -6.61,
    "adjEmHistory": {
      "2020-01-19": -6.27,
      "2020-01-20": -6.29,
      "2020-01-21": -6.27,
      "2020-01-22": -6.14,
      "2020-01-23": -6.61
    },
    "adjOff": 99.9,
    "adjOffHistory": {
      "2020-01-19": 99.4,
      "2020-01-20": 99.4,
      "2020-01-21": 99.6,
      "2020-01-22": 99.6,
      "2020-01-23": 99.9
    },
    "adjOffRank": 201,
    "adjOffRankHistory": {
      "2020-01-19": 212,
      "2020-01-20": 210,
      "2020-01-21": 209,
      "2020-01-22": 208,
      "2020-01-23": 201
    },
    "adjTempo": 67.5,
    "adjTempoHistory": {
      "2020-01-19": 67.2,
      "2020-01-20": 67.3,
      "2020-01-21": 67.2,
      "2020-01-22": 67.2,
      "2020-01-23": 67.5
    },
    "adjTempoRank": 219,
    "adjTempoRankHistory": {
      "2020-01-19": 242,
      "2020-01-20": 241,
      "2020-01-21": 239,
      "2020-01-22": 239,
      "2020-01-23": 219
    },
    "conference": "BW",
    "rank": 246,
    "rankHistory": {
      "2020-01-19": 239,
      "2020-01-20": 239,
      "2020-01-21": 241,
      "2020-01-22": 240,
      "2020-01-23": 246
    },
    "record": "8-12"
  },
  "UC Irvine": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 97.8,
      "2020-01-20": 97.8,
      "2020-01-21": 97.9,
      "2020-01-22": 97.8,
      "2020-01-23": 97.7
    },
    "adjDefRank": 99,
    "adjDefRankHistory": {
      "2020-01-19": 101,
      "2020-01-20": 101,
      "2020-01-21": 103,
      "2020-01-22": 104,
      "2020-01-23": 99
    },
    "adjEM": 4.54,
    "adjEmHistory": {
      "2020-01-19": 5.65,
      "2020-01-20": 5.64,
      "2020-01-21": 5.67,
      "2020-01-22": 5.68,
      "2020-01-23": 4.54
    },
    "adjOff": 102.2,
    "adjOffHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.6,
      "2020-01-22": 103.5,
      "2020-01-23": 102.2
    },
    "adjOffRank": 167,
    "adjOffRankHistory": {
      "2020-01-19": 137,
      "2020-01-20": 138,
      "2020-01-21": 135,
      "2020-01-22": 135,
      "2020-01-23": 167
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.6,
      "2020-01-20": 68.6,
      "2020-01-21": 68.5,
      "2020-01-22": 68.4,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 187,
    "adjTempoRankHistory": {
      "2020-01-19": 176,
      "2020-01-20": 177,
      "2020-01-21": 176,
      "2020-01-22": 176,
      "2020-01-23": 187
    },
    "conference": "BW",
    "rank": 118,
    "rankHistory": {
      "2020-01-19": 109,
      "2020-01-20": 109,
      "2020-01-21": 109,
      "2020-01-22": 108,
      "2020-01-23": 118
    },
    "record": "11-9"
  },
  "UC Riverside": {
    "adjDef": 100.9,
    "adjDefHistory": {
      "2020-01-19": 100.9,
      "2020-01-20": 100.9,
      "2020-01-21": 101,
      "2020-01-22": 101,
      "2020-01-23": 100.9
    },
    "adjDefRank": 154,
    "adjDefRankHistory": {
      "2020-01-19": 153,
      "2020-01-20": 154,
      "2020-01-21": 156,
      "2020-01-22": 156,
      "2020-01-23": 154
    },
    "adjEM": -5.66,
    "adjEmHistory": {
      "2020-01-19": -5.72,
      "2020-01-20": -5.71,
      "2020-01-21": -5.71,
      "2020-01-22": -5.77,
      "2020-01-23": -5.66
    },
    "adjOff": 95.2,
    "adjOffHistory": {
      "2020-01-19": 95.2,
      "2020-01-20": 95.2,
      "2020-01-21": 95.3,
      "2020-01-22": 95.2,
      "2020-01-23": 95.2
    },
    "adjOffRank": 296,
    "adjOffRankHistory": {
      "2020-01-19": 294,
      "2020-01-20": 294,
      "2020-01-21": 294,
      "2020-01-22": 294,
      "2020-01-23": 296
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.3,
      "2020-01-20": 64.3,
      "2020-01-21": 64.1,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 334,
    "adjTempoRankHistory": {
      "2020-01-19": 334,
      "2020-01-20": 335,
      "2020-01-21": 334,
      "2020-01-22": 335,
      "2020-01-23": 334
    },
    "conference": "BW",
    "rank": 234,
    "rankHistory": {
      "2020-01-19": 232,
      "2020-01-20": 232,
      "2020-01-21": 232,
      "2020-01-22": 234,
      "2020-01-23": 234
    },
    "record": "12-8"
  },
  "UC Santa Barbara": {
    "adjDef": 105.8,
    "adjDefHistory": {
      "2020-01-19": 103.6,
      "2020-01-20": 103.6,
      "2020-01-21": 103.8,
      "2020-01-22": 103.6,
      "2020-01-23": 105.8
    },
    "adjDefRank": 263,
    "adjDefRankHistory": {
      "2020-01-19": 218,
      "2020-01-20": 218,
      "2020-01-21": 218,
      "2020-01-22": 219,
      "2020-01-23": 263
    },
    "adjEM": 0.13,
    "adjEmHistory": {
      "2020-01-19": 1.48,
      "2020-01-20": 1.51,
      "2020-01-21": 1.59,
      "2020-01-22": 1.62,
      "2020-01-23": 0.13
    },
    "adjOff": 105.9,
    "adjOffHistory": {
      "2020-01-19": 105.1,
      "2020-01-20": 105.1,
      "2020-01-21": 105.4,
      "2020-01-22": 105.3,
      "2020-01-23": 105.9
    },
    "adjOffRank": 81,
    "adjOffRankHistory": {
      "2020-01-19": 95,
      "2020-01-20": 93,
      "2020-01-21": 92,
      "2020-01-22": 91,
      "2020-01-23": 81
    },
    "adjTempo": 63.7,
    "adjTempoHistory": {
      "2020-01-19": 64.2,
      "2020-01-20": 64.2,
      "2020-01-21": 64.1,
      "2020-01-22": 64.1,
      "2020-01-23": 63.7
    },
    "adjTempoRank": 340,
    "adjTempoRankHistory": {
      "2020-01-19": 338,
      "2020-01-20": 338,
      "2020-01-21": 338,
      "2020-01-22": 338,
      "2020-01-23": 340
    },
    "conference": "BW",
    "rank": 168,
    "rankHistory": {
      "2020-01-19": 149,
      "2020-01-20": 149,
      "2020-01-21": 149,
      "2020-01-22": 149,
      "2020-01-23": 168
    },
    "record": "12-7"
  },
  "UCF": {
    "adjDef": 95.1,
    "adjDefHistory": {
      "2020-01-19": 95.2,
      "2020-01-20": 95.2,
      "2020-01-21": 95.4,
      "2020-01-22": 95.3,
      "2020-01-23": 95.1
    },
    "adjDefRank": 70,
    "adjDefRankHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 71,
      "2020-01-22": 72,
      "2020-01-23": 70
    },
    "adjEM": 5.57,
    "adjEmHistory": {
      "2020-01-19": 5.57,
      "2020-01-20": 5.57,
      "2020-01-21": 5.58,
      "2020-01-22": 5.53,
      "2020-01-23": 5.57
    },
    "adjOff": 100.7,
    "adjOffHistory": {
      "2020-01-19": 100.7,
      "2020-01-20": 100.7,
      "2020-01-21": 100.9,
      "2020-01-22": 100.8,
      "2020-01-23": 100.7
    },
    "adjOffRank": 190,
    "adjOffRankHistory": {
      "2020-01-19": 189,
      "2020-01-20": 189,
      "2020-01-21": 188,
      "2020-01-22": 188,
      "2020-01-23": 190
    },
    "adjTempo": 67.4,
    "adjTempoHistory": {
      "2020-01-19": 67.5,
      "2020-01-20": 67.5,
      "2020-01-21": 67.4,
      "2020-01-22": 67.4,
      "2020-01-23": 67.4
    },
    "adjTempoRank": 226,
    "adjTempoRankHistory": {
      "2020-01-19": 228,
      "2020-01-20": 228,
      "2020-01-21": 227,
      "2020-01-22": 225,
      "2020-01-23": 226
    },
    "conference": "Amer",
    "rank": 109,
    "rankHistory": {
      "2020-01-19": 110,
      "2020-01-20": 110,
      "2020-01-21": 110,
      "2020-01-22": 109,
      "2020-01-23": 109
    },
    "record": "11-7"
  },
  "UCLA": {
    "adjDef": 102.8,
    "adjDefHistory": {
      "2020-01-19": 104.3,
      "2020-01-20": 102.7,
      "2020-01-21": 102.8,
      "2020-01-22": 102.8,
      "2020-01-23": 102.8
    },
    "adjDefRank": 199,
    "adjDefRankHistory": {
      "2020-01-19": 231,
      "2020-01-20": 192,
      "2020-01-21": 196,
      "2020-01-22": 199,
      "2020-01-23": 199
    },
    "adjEM": 2.3,
    "adjEmHistory": {
      "2020-01-19": 1.89,
      "2020-01-20": 2.34,
      "2020-01-21": 2.33,
      "2020-01-22": 2.3,
      "2020-01-23": 2.3
    },
    "adjOff": 105.1,
    "adjOffHistory": {
      "2020-01-19": 106.2,
      "2020-01-20": 105,
      "2020-01-21": 105.2,
      "2020-01-22": 105.1,
      "2020-01-23": 105.1
    },
    "adjOffRank": 98,
    "adjOffRankHistory": {
      "2020-01-19": 72,
      "2020-01-20": 98,
      "2020-01-21": 95,
      "2020-01-22": 94,
      "2020-01-23": 98
    },
    "adjTempo": 65.1,
    "adjTempoHistory": {
      "2020-01-19": 65.7,
      "2020-01-20": 65.3,
      "2020-01-21": 65.1,
      "2020-01-22": 65.1,
      "2020-01-23": 65.1
    },
    "adjTempoRank": 311,
    "adjTempoRankHistory": {
      "2020-01-19": 300,
      "2020-01-20": 313,
      "2020-01-21": 313,
      "2020-01-22": 312,
      "2020-01-23": 311
    },
    "conference": "P12",
    "rank": 139,
    "rankHistory": {
      "2020-01-19": 147,
      "2020-01-20": 140,
      "2020-01-21": 139,
      "2020-01-22": 139,
      "2020-01-23": 139
    },
    "record": "9-9"
  },
  "UMBC": {
    "adjDef": 106.5,
    "adjDefHistory": {
      "2020-01-19": 107.1,
      "2020-01-20": 107.1,
      "2020-01-21": 107.3,
      "2020-01-22": 107.3,
      "2020-01-23": 106.5
    },
    "adjDefRank": 280,
    "adjDefRankHistory": {
      "2020-01-19": 293,
      "2020-01-20": 293,
      "2020-01-21": 294,
      "2020-01-22": 295,
      "2020-01-23": 280
    },
    "adjEM": -11.26,
    "adjEmHistory": {
      "2020-01-19": -12.26,
      "2020-01-20": -12.26,
      "2020-01-21": -12.46,
      "2020-01-22": -12.47,
      "2020-01-23": -11.26
    },
    "adjOff": 95.3,
    "adjOffHistory": {
      "2020-01-19": 94.8,
      "2020-01-20": 94.8,
      "2020-01-21": 94.9,
      "2020-01-22": 94.8,
      "2020-01-23": 95.3
    },
    "adjOffRank": 295,
    "adjOffRankHistory": {
      "2020-01-19": 297,
      "2020-01-20": 297,
      "2020-01-21": 299,
      "2020-01-22": 298,
      "2020-01-23": 295
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.3,
      "2020-01-20": 67.3,
      "2020-01-21": 67.2,
      "2020-01-22": 67.1,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 242,
    "adjTempoRankHistory": {
      "2020-01-19": 240,
      "2020-01-20": 240,
      "2020-01-21": 241,
      "2020-01-22": 242,
      "2020-01-23": 242
    },
    "conference": "AE",
    "rank": 299,
    "rankHistory": {
      "2020-01-19": 308,
      "2020-01-20": 308,
      "2020-01-21": 307,
      "2020-01-22": 307,
      "2020-01-23": 299
    },
    "record": "8-12"
  },
  "UMKC": {
    "adjDef": 104.4,
    "adjDefHistory": {
      "2020-01-19": 104.3,
      "2020-01-20": 104.3,
      "2020-01-21": 104.4,
      "2020-01-22": 104.2,
      "2020-01-23": 104.4
    },
    "adjDefRank": 235,
    "adjDefRankHistory": {
      "2020-01-19": 232,
      "2020-01-20": 232,
      "2020-01-21": 234,
      "2020-01-22": 232,
      "2020-01-23": 235
    },
    "adjEM": -7.51,
    "adjEmHistory": {
      "2020-01-19": -7.53,
      "2020-01-20": -7.53,
      "2020-01-21": -7.57,
      "2020-01-22": -7.55,
      "2020-01-23": -7.51
    },
    "adjOff": 96.9,
    "adjOffHistory": {
      "2020-01-19": 96.8,
      "2020-01-20": 96.8,
      "2020-01-21": 96.9,
      "2020-01-22": 96.7,
      "2020-01-23": 96.9
    },
    "adjOffRank": 267,
    "adjOffRankHistory": {
      "2020-01-19": 264,
      "2020-01-20": 264,
      "2020-01-21": 266,
      "2020-01-22": 266,
      "2020-01-23": 267
    },
    "adjTempo": 66.6,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.7,
      "2020-01-22": 66.6,
      "2020-01-23": 66.6
    },
    "adjTempoRank": 262,
    "adjTempoRankHistory": {
      "2020-01-19": 260,
      "2020-01-20": 259,
      "2020-01-21": 260,
      "2020-01-22": 262,
      "2020-01-23": 262
    },
    "conference": "WAC",
    "rank": 256,
    "rankHistory": {
      "2020-01-19": 255,
      "2020-01-20": 255,
      "2020-01-21": 256,
      "2020-01-22": 256,
      "2020-01-23": 256
    },
    "record": "9-11"
  },
  "UMass Lowell": {
    "adjDef": 111.2,
    "adjDefHistory": {
      "2020-01-19": 109.8,
      "2020-01-20": 109.8,
      "2020-01-21": 110.1,
      "2020-01-22": 110,
      "2020-01-23": 111.2
    },
    "adjDefRank": 336,
    "adjDefRankHistory": {
      "2020-01-19": 326,
      "2020-01-20": 326,
      "2020-01-21": 327,
      "2020-01-22": 327,
      "2020-01-23": 336
    },
    "adjEM": -9.47,
    "adjEmHistory": {
      "2020-01-19": -8.27,
      "2020-01-20": -8.27,
      "2020-01-21": -8.36,
      "2020-01-22": -8.39,
      "2020-01-23": -9.47
    },
    "adjOff": 101.7,
    "adjOffHistory": {
      "2020-01-19": 101.5,
      "2020-01-20": 101.6,
      "2020-01-21": 101.7,
      "2020-01-22": 101.6,
      "2020-01-23": 101.7
    },
    "adjOffRank": 176,
    "adjOffRankHistory": {
      "2020-01-19": 177,
      "2020-01-20": 177,
      "2020-01-21": 175,
      "2020-01-22": 175,
      "2020-01-23": 176
    },
    "adjTempo": 71.1,
    "adjTempoHistory": {
      "2020-01-19": 70.9,
      "2020-01-20": 70.9,
      "2020-01-21": 70.8,
      "2020-01-22": 70.8,
      "2020-01-23": 71.1
    },
    "adjTempoRank": 51,
    "adjTempoRankHistory": {
      "2020-01-19": 59,
      "2020-01-20": 59,
      "2020-01-21": 60,
      "2020-01-22": 60,
      "2020-01-23": 51
    },
    "conference": "AE",
    "rank": 281,
    "rankHistory": {
      "2020-01-19": 264,
      "2020-01-20": 264,
      "2020-01-21": 265,
      "2020-01-22": 265,
      "2020-01-23": 281
    },
    "record": "8-12"
  },
  "UNC Asheville": {
    "adjDef": 107.3,
    "adjDefHistory": {
      "2020-01-19": 108.3,
      "2020-01-20": 108.3,
      "2020-01-21": 107.4,
      "2020-01-22": 107.2,
      "2020-01-23": 107.3
    },
    "adjDefRank": 295,
    "adjDefRankHistory": {
      "2020-01-19": 311,
      "2020-01-20": 311,
      "2020-01-21": 295,
      "2020-01-22": 294,
      "2020-01-23": 295
    },
    "adjEM": -7.68,
    "adjEmHistory": {
      "2020-01-19": -8.1,
      "2020-01-20": -8.1,
      "2020-01-21": -7.69,
      "2020-01-22": -7.63,
      "2020-01-23": -7.68
    },
    "adjOff": 99.6,
    "adjOffHistory": {
      "2020-01-19": 100.2,
      "2020-01-20": 100.2,
      "2020-01-21": 99.7,
      "2020-01-22": 99.6,
      "2020-01-23": 99.6
    },
    "adjOffRank": 210,
    "adjOffRankHistory": {
      "2020-01-19": 197,
      "2020-01-20": 197,
      "2020-01-21": 207,
      "2020-01-22": 207,
      "2020-01-23": 210
    },
    "adjTempo": 71.8,
    "adjTempoHistory": {
      "2020-01-19": 71.9,
      "2020-01-20": 71.9,
      "2020-01-21": 71.8,
      "2020-01-22": 71.8,
      "2020-01-23": 71.8
    },
    "adjTempoRank": 33,
    "adjTempoRankHistory": {
      "2020-01-19": 38,
      "2020-01-20": 38,
      "2020-01-21": 34,
      "2020-01-22": 35,
      "2020-01-23": 33
    },
    "conference": "BSth",
    "rank": 259,
    "rankHistory": {
      "2020-01-19": 260,
      "2020-01-20": 260,
      "2020-01-21": 257,
      "2020-01-22": 257,
      "2020-01-23": 259
    },
    "record": "8-9"
  },
  "UNC Greensboro": {
    "adjDef": 92.4,
    "adjDefHistory": {
      "2020-01-19": 92.1,
      "2020-01-20": 92.1,
      "2020-01-21": 92.3,
      "2020-01-22": 92.1,
      "2020-01-23": 92.4
    },
    "adjDefRank": 35,
    "adjDefRankHistory": {
      "2020-01-19": 33,
      "2020-01-20": 33,
      "2020-01-21": 34,
      "2020-01-22": 32,
      "2020-01-23": 35
    },
    "adjEM": 10.77,
    "adjEmHistory": {
      "2020-01-19": 10.71,
      "2020-01-20": 10.71,
      "2020-01-21": 10.75,
      "2020-01-22": 10.78,
      "2020-01-23": 10.77
    },
    "adjOff": 103.1,
    "adjOffHistory": {
      "2020-01-19": 102.8,
      "2020-01-20": 102.8,
      "2020-01-21": 103,
      "2020-01-22": 102.9,
      "2020-01-23": 103.1
    },
    "adjOffRank": 148,
    "adjOffRankHistory": {
      "2020-01-19": 151,
      "2020-01-20": 151,
      "2020-01-21": 151,
      "2020-01-22": 151,
      "2020-01-23": 148
    },
    "adjTempo": 67.1,
    "adjTempoHistory": {
      "2020-01-19": 67.4,
      "2020-01-20": 67.4,
      "2020-01-21": 67.3,
      "2020-01-22": 67.2,
      "2020-01-23": 67.1
    },
    "adjTempoRank": 239,
    "adjTempoRankHistory": {
      "2020-01-19": 234,
      "2020-01-20": 234,
      "2020-01-21": 235,
      "2020-01-22": 235,
      "2020-01-23": 239
    },
    "conference": "SC",
    "rank": 69,
    "rankHistory": {
      "2020-01-19": 69,
      "2020-01-20": 68,
      "2020-01-21": 68,
      "2020-01-22": 68,
      "2020-01-23": 69
    },
    "record": "15-5"
  },
  "UNC Wilmington": {
    "adjDef": 106.8,
    "adjDefHistory": {
      "2020-01-19": 106.8,
      "2020-01-20": 106.8,
      "2020-01-21": 106.9,
      "2020-01-22": 106.7,
      "2020-01-23": 106.8
    },
    "adjDefRank": 287,
    "adjDefRankHistory": {
      "2020-01-19": 290,
      "2020-01-20": 290,
      "2020-01-21": 288,
      "2020-01-22": 287,
      "2020-01-23": 287
    },
    "adjEM": -14.68,
    "adjEmHistory": {
      "2020-01-19": -14.6,
      "2020-01-20": -14.59,
      "2020-01-21": -14.68,
      "2020-01-22": -14.72,
      "2020-01-23": -14.68
    },
    "adjOff": 92.1,
    "adjOffHistory": {
      "2020-01-19": 92.2,
      "2020-01-20": 92.2,
      "2020-01-21": 92.2,
      "2020-01-22": 92,
      "2020-01-23": 92.1
    },
    "adjOffRank": 324,
    "adjOffRankHistory": {
      "2020-01-19": 322,
      "2020-01-20": 322,
      "2020-01-21": 323,
      "2020-01-22": 323,
      "2020-01-23": 324
    },
    "adjTempo": 66.8,
    "adjTempoHistory": {
      "2020-01-19": 67,
      "2020-01-20": 67,
      "2020-01-21": 66.8,
      "2020-01-22": 66.8,
      "2020-01-23": 66.8
    },
    "adjTempoRank": 256,
    "adjTempoRankHistory": {
      "2020-01-19": 252,
      "2020-01-20": 252,
      "2020-01-21": 255,
      "2020-01-22": 254,
      "2020-01-23": 256
    },
    "conference": "CAA",
    "rank": 326,
    "rankHistory": {
      "2020-01-19": 327,
      "2020-01-20": 327,
      "2020-01-21": 326,
      "2020-01-22": 326,
      "2020-01-23": 326
    },
    "record": "6-15"
  },
  "UNLV": {
    "adjDef": 103.2,
    "adjDefHistory": {
      "2020-01-19": 102.3,
      "2020-01-20": 102.4,
      "2020-01-21": 102.6,
      "2020-01-22": 102.5,
      "2020-01-23": 103.2
    },
    "adjDefRank": 208,
    "adjDefRankHistory": {
      "2020-01-19": 180,
      "2020-01-20": 183,
      "2020-01-21": 185,
      "2020-01-22": 186,
      "2020-01-23": 208
    },
    "adjEM": 2.95,
    "adjEmHistory": {
      "2020-01-19": 3.22,
      "2020-01-20": 3.22,
      "2020-01-21": 3.28,
      "2020-01-22": 3.36,
      "2020-01-23": 2.95
    },
    "adjOff": 106.1,
    "adjOffHistory": {
      "2020-01-19": 105.5,
      "2020-01-20": 105.7,
      "2020-01-21": 105.9,
      "2020-01-22": 105.9,
      "2020-01-23": 106.1
    },
    "adjOffRank": 77,
    "adjOffRankHistory": {
      "2020-01-19": 83,
      "2020-01-20": 81,
      "2020-01-21": 80,
      "2020-01-22": 77,
      "2020-01-23": 77
    },
    "adjTempo": 65.9,
    "adjTempoHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 65.9,
      "2020-01-22": 65.9,
      "2020-01-23": 65.9
    },
    "adjTempoRank": 284,
    "adjTempoRankHistory": {
      "2020-01-19": 289,
      "2020-01-20": 287,
      "2020-01-21": 286,
      "2020-01-22": 287,
      "2020-01-23": 284
    },
    "conference": "MWC",
    "rank": 130,
    "rankHistory": {
      "2020-01-19": 131,
      "2020-01-20": 131,
      "2020-01-21": 132,
      "2020-01-22": 131,
      "2020-01-23": 130
    },
    "record": "11-10"
  },
  "USC": {
    "adjDef": 94,
    "adjDefHistory": {
      "2020-01-19": 94,
      "2020-01-20": 94.1,
      "2020-01-21": 94.2,
      "2020-01-22": 94,
      "2020-01-23": 94
    },
    "adjDefRank": 55,
    "adjDefRankHistory": {
      "2020-01-19": 58,
      "2020-01-20": 58,
      "2020-01-21": 59,
      "2020-01-22": 58,
      "2020-01-23": 55
    },
    "adjEM": 11.64,
    "adjEmHistory": {
      "2020-01-19": 11.44,
      "2020-01-20": 11.44,
      "2020-01-21": 11.47,
      "2020-01-22": 11.55,
      "2020-01-23": 11.64
    },
    "adjOff": 105.6,
    "adjOffHistory": {
      "2020-01-19": 105.4,
      "2020-01-20": 105.5,
      "2020-01-21": 105.7,
      "2020-01-22": 105.6,
      "2020-01-23": 105.6
    },
    "adjOffRank": 86,
    "adjOffRankHistory": {
      "2020-01-19": 86,
      "2020-01-20": 83,
      "2020-01-21": 83,
      "2020-01-22": 81,
      "2020-01-23": 86
    },
    "adjTempo": 71.1,
    "adjTempoHistory": {
      "2020-01-19": 71.2,
      "2020-01-20": 71.3,
      "2020-01-21": 71.1,
      "2020-01-22": 71.1,
      "2020-01-23": 71.1
    },
    "adjTempoRank": 53,
    "adjTempoRankHistory": {
      "2020-01-19": 55,
      "2020-01-20": 55,
      "2020-01-21": 55,
      "2020-01-22": 55,
      "2020-01-23": 53
    },
    "conference": "P12",
    "rank": 62,
    "rankHistory": {
      "2020-01-19": 62,
      "2020-01-20": 62,
      "2020-01-21": 61,
      "2020-01-22": 63,
      "2020-01-23": 62
    },
    "record": "15-3"
  },
  "USC Upstate": {
    "adjDef": 109.6,
    "adjDefHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 109.6,
      "2020-01-22": 109.4,
      "2020-01-23": 109.6
    },
    "adjDefRank": 323,
    "adjDefRankHistory": {
      "2020-01-19": 306,
      "2020-01-20": 306,
      "2020-01-21": 320,
      "2020-01-22": 321,
      "2020-01-23": 323
    },
    "adjEM": -15.23,
    "adjEmHistory": {
      "2020-01-19": -13.6,
      "2020-01-20": -13.6,
      "2020-01-21": -15.26,
      "2020-01-22": -15.2,
      "2020-01-23": -15.23
    },
    "adjOff": 94.4,
    "adjOffHistory": {
      "2020-01-19": 94.4,
      "2020-01-20": 94.4,
      "2020-01-21": 94.3,
      "2020-01-22": 94.2,
      "2020-01-23": 94.4
    },
    "adjOffRank": 304,
    "adjOffRankHistory": {
      "2020-01-19": 302,
      "2020-01-20": 303,
      "2020-01-21": 306,
      "2020-01-22": 305,
      "2020-01-23": 304
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.8,
      "2020-01-20": 69.8,
      "2020-01-21": 69.2,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 141,
    "adjTempoRankHistory": {
      "2020-01-19": 117,
      "2020-01-20": 116,
      "2020-01-21": 139,
      "2020-01-22": 139,
      "2020-01-23": 141
    },
    "conference": "BSth",
    "rank": 329,
    "rankHistory": {
      "2020-01-19": 315,
      "2020-01-20": 315,
      "2020-01-21": 330,
      "2020-01-22": 330,
      "2020-01-23": 329
    },
    "record": "7-12"
  },
  "UT Arlington": {
    "adjDef": 98,
    "adjDefHistory": {
      "2020-01-19": 98.2,
      "2020-01-20": 98.2,
      "2020-01-21": 98.3,
      "2020-01-22": 98.1,
      "2020-01-23": 98
    },
    "adjDefRank": 107,
    "adjDefRankHistory": {
      "2020-01-19": 114,
      "2020-01-20": 114,
      "2020-01-21": 112,
      "2020-01-22": 111,
      "2020-01-23": 107
    },
    "adjEM": 5.42,
    "adjEmHistory": {
      "2020-01-19": 5.09,
      "2020-01-20": 5.09,
      "2020-01-21": 5.16,
      "2020-01-22": 5.2,
      "2020-01-23": 5.42
    },
    "adjOff": 103.4,
    "adjOffHistory": {
      "2020-01-19": 103.3,
      "2020-01-20": 103.3,
      "2020-01-21": 103.4,
      "2020-01-22": 103.3,
      "2020-01-23": 103.4
    },
    "adjOffRank": 140,
    "adjOffRankHistory": {
      "2020-01-19": 143,
      "2020-01-20": 142,
      "2020-01-21": 143,
      "2020-01-22": 141,
      "2020-01-23": 140
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 143,
    "adjTempoRankHistory": {
      "2020-01-19": 136,
      "2020-01-20": 136,
      "2020-01-21": 140,
      "2020-01-22": 141,
      "2020-01-23": 143
    },
    "conference": "SB",
    "rank": 111,
    "rankHistory": {
      "2020-01-19": 114,
      "2020-01-20": 114,
      "2020-01-21": 114,
      "2020-01-22": 113,
      "2020-01-23": 111
    },
    "record": "8-12"
  },
  "UT Rio Grande Valley": {
    "adjDef": 104.3,
    "adjDefHistory": {
      "2020-01-19": 104.2,
      "2020-01-20": 104.2,
      "2020-01-21": 104.4,
      "2020-01-22": 104.3,
      "2020-01-23": 104.3
    },
    "adjDefRank": 234,
    "adjDefRankHistory": {
      "2020-01-19": 228,
      "2020-01-20": 229,
      "2020-01-21": 232,
      "2020-01-22": 235,
      "2020-01-23": 234
    },
    "adjEM": -8,
    "adjEmHistory": {
      "2020-01-19": -7.79,
      "2020-01-20": -7.79,
      "2020-01-21": -7.8,
      "2020-01-22": -7.87,
      "2020-01-23": -8
    },
    "adjOff": 96.3,
    "adjOffHistory": {
      "2020-01-19": 96.4,
      "2020-01-20": 96.4,
      "2020-01-21": 96.6,
      "2020-01-22": 96.4,
      "2020-01-23": 96.3
    },
    "adjOffRank": 276,
    "adjOffRankHistory": {
      "2020-01-19": 270,
      "2020-01-20": 270,
      "2020-01-21": 270,
      "2020-01-22": 271,
      "2020-01-23": 276
    },
    "adjTempo": 70,
    "adjTempoHistory": {
      "2020-01-19": 70.1,
      "2020-01-20": 70.1,
      "2020-01-21": 70,
      "2020-01-22": 70,
      "2020-01-23": 70
    },
    "adjTempoRank": 94,
    "adjTempoRankHistory": {
      "2020-01-19": 99,
      "2020-01-20": 99,
      "2020-01-21": 100,
      "2020-01-22": 100,
      "2020-01-23": 94
    },
    "conference": "WAC",
    "rank": 261,
    "rankHistory": {
      "2020-01-19": 256,
      "2020-01-20": 256,
      "2020-01-21": 258,
      "2020-01-22": 259,
      "2020-01-23": 261
    },
    "record": "6-12"
  },
  "UTEP": {
    "adjDef": 94.4,
    "adjDefHistory": {
      "2020-01-19": 94.2,
      "2020-01-20": 94.2,
      "2020-01-21": 94.4,
      "2020-01-22": 94.2,
      "2020-01-23": 94.4
    },
    "adjDefRank": 63,
    "adjDefRankHistory": {
      "2020-01-19": 60,
      "2020-01-20": 60,
      "2020-01-21": 64,
      "2020-01-22": 63,
      "2020-01-23": 63
    },
    "adjEM": 2.1,
    "adjEmHistory": {
      "2020-01-19": 2.12,
      "2020-01-20": 2.12,
      "2020-01-21": 2.08,
      "2020-01-22": 2.12,
      "2020-01-23": 2.1
    },
    "adjOff": 96.5,
    "adjOffHistory": {
      "2020-01-19": 96.3,
      "2020-01-20": 96.3,
      "2020-01-21": 96.4,
      "2020-01-22": 96.3,
      "2020-01-23": 96.5
    },
    "adjOffRank": 273,
    "adjOffRankHistory": {
      "2020-01-19": 274,
      "2020-01-20": 275,
      "2020-01-21": 273,
      "2020-01-22": 274,
      "2020-01-23": 273
    },
    "adjTempo": 67.6,
    "adjTempoHistory": {
      "2020-01-19": 67.9,
      "2020-01-20": 67.9,
      "2020-01-21": 67.7,
      "2020-01-22": 67.6,
      "2020-01-23": 67.6
    },
    "adjTempoRank": 217,
    "adjTempoRankHistory": {
      "2020-01-19": 218,
      "2020-01-20": 218,
      "2020-01-21": 218,
      "2020-01-22": 218,
      "2020-01-23": 217
    },
    "conference": "CUSA",
    "rank": 143,
    "rankHistory": {
      "2020-01-19": 142,
      "2020-01-20": 143,
      "2020-01-21": 144,
      "2020-01-22": 143,
      "2020-01-23": 143
    },
    "record": "11-8"
  },
  "UTSA": {
    "adjDef": 105.7,
    "adjDefHistory": {
      "2020-01-19": 105.6,
      "2020-01-20": 105.6,
      "2020-01-21": 105.7,
      "2020-01-22": 105.7,
      "2020-01-23": 105.7
    },
    "adjDefRank": 262,
    "adjDefRankHistory": {
      "2020-01-19": 263,
      "2020-01-20": 263,
      "2020-01-21": 261,
      "2020-01-22": 262,
      "2020-01-23": 262
    },
    "adjEM": -1.01,
    "adjEmHistory": {
      "2020-01-19": -1.11,
      "2020-01-20": -1.11,
      "2020-01-21": -1.06,
      "2020-01-22": -1.01,
      "2020-01-23": -1.01
    },
    "adjOff": 104.7,
    "adjOffHistory": {
      "2020-01-19": 104.5,
      "2020-01-20": 104.5,
      "2020-01-21": 104.7,
      "2020-01-22": 104.6,
      "2020-01-23": 104.7
    },
    "adjOffRank": 106,
    "adjOffRankHistory": {
      "2020-01-19": 112,
      "2020-01-20": 112,
      "2020-01-21": 108,
      "2020-01-22": 106,
      "2020-01-23": 106
    },
    "adjTempo": 72.9,
    "adjTempoHistory": {
      "2020-01-19": 73.1,
      "2020-01-20": 73.1,
      "2020-01-21": 72.9,
      "2020-01-22": 72.9,
      "2020-01-23": 72.9
    },
    "adjTempoRank": 20,
    "adjTempoRankHistory": {
      "2020-01-19": 22,
      "2020-01-20": 22,
      "2020-01-21": 21,
      "2020-01-22": 19,
      "2020-01-23": 20
    },
    "conference": "CUSA",
    "rank": 182,
    "rankHistory": {
      "2020-01-19": 184,
      "2020-01-20": 184,
      "2020-01-21": 183,
      "2020-01-22": 182,
      "2020-01-23": 182
    },
    "record": "9-10"
  },
  "Utah": {
    "adjDef": 102.7,
    "adjDefHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 102.9,
      "2020-01-22": 102.7,
      "2020-01-23": 102.7
    },
    "adjDefRank": 193,
    "adjDefRankHistory": {
      "2020-01-19": 196,
      "2020-01-20": 196,
      "2020-01-21": 198,
      "2020-01-22": 194,
      "2020-01-23": 193
    },
    "adjEM": 4.28,
    "adjEmHistory": {
      "2020-01-19": 4.28,
      "2020-01-20": 4.28,
      "2020-01-21": 4.28,
      "2020-01-22": 4.25,
      "2020-01-23": 4.28
    },
    "adjOff": 107,
    "adjOffHistory": {
      "2020-01-19": 107,
      "2020-01-20": 107,
      "2020-01-21": 107.2,
      "2020-01-22": 106.9,
      "2020-01-23": 107
    },
    "adjOffRank": 64,
    "adjOffRankHistory": {
      "2020-01-19": 62,
      "2020-01-20": 62,
      "2020-01-21": 63,
      "2020-01-22": 63,
      "2020-01-23": 64
    },
    "adjTempo": 67.8,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 67.9,
      "2020-01-22": 67.9,
      "2020-01-23": 67.8
    },
    "adjTempoRank": 210,
    "adjTempoRankHistory": {
      "2020-01-19": 202,
      "2020-01-20": 202,
      "2020-01-21": 207,
      "2020-01-22": 210,
      "2020-01-23": 210
    },
    "conference": "P12",
    "rank": 121,
    "rankHistory": {
      "2020-01-19": 124,
      "2020-01-20": 124,
      "2020-01-21": 124,
      "2020-01-22": 123,
      "2020-01-23": 121
    },
    "record": "10-7"
  },
  "Utah St.": {
    "adjDef": 94.4,
    "adjDefHistory": {
      "2020-01-19": 95.8,
      "2020-01-20": 95.8,
      "2020-01-21": 95.9,
      "2020-01-22": 94.4,
      "2020-01-23": 94.4
    },
    "adjDefRank": 64,
    "adjDefRankHistory": {
      "2020-01-19": 82,
      "2020-01-20": 82,
      "2020-01-21": 81,
      "2020-01-22": 65,
      "2020-01-23": 64
    },
    "adjEM": 11.49,
    "adjEmHistory": {
      "2020-01-19": 10.64,
      "2020-01-20": 10.64,
      "2020-01-21": 10.68,
      "2020-01-22": 11.42,
      "2020-01-23": 11.49
    },
    "adjOff": 105.9,
    "adjOffHistory": {
      "2020-01-19": 106.4,
      "2020-01-20": 106.4,
      "2020-01-21": 106.5,
      "2020-01-22": 105.8,
      "2020-01-23": 105.9
    },
    "adjOffRank": 83,
    "adjOffRankHistory": {
      "2020-01-19": 68,
      "2020-01-20": 68,
      "2020-01-21": 69,
      "2020-01-22": 78,
      "2020-01-23": 83
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 68.6,
      "2020-01-20": 68.6,
      "2020-01-21": 68.4,
      "2020-01-22": 68.4,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 183,
    "adjTempoRankHistory": {
      "2020-01-19": 178,
      "2020-01-20": 179,
      "2020-01-21": 179,
      "2020-01-22": 181,
      "2020-01-23": 183
    },
    "conference": "MWC",
    "rank": 64,
    "rankHistory": {
      "2020-01-19": 71,
      "2020-01-20": 71,
      "2020-01-21": 70,
      "2020-01-22": 65,
      "2020-01-23": 64
    },
    "record": "15-6"
  },
  "Utah Valley": {
    "adjDef": 104.9,
    "adjDefHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 105,
      "2020-01-22": 104.8,
      "2020-01-23": 104.9
    },
    "adjDefRank": 240,
    "adjDefRankHistory": {
      "2020-01-19": 242,
      "2020-01-20": 241,
      "2020-01-21": 245,
      "2020-01-22": 243,
      "2020-01-23": 240
    },
    "adjEM": -8.79,
    "adjEmHistory": {
      "2020-01-19": -8.86,
      "2020-01-20": -8.86,
      "2020-01-21": -8.84,
      "2020-01-22": -8.84,
      "2020-01-23": -8.79
    },
    "adjOff": 96.1,
    "adjOffHistory": {
      "2020-01-19": 95.9,
      "2020-01-20": 95.9,
      "2020-01-21": 96.1,
      "2020-01-22": 95.9,
      "2020-01-23": 96.1
    },
    "adjOffRank": 283,
    "adjOffRankHistory": {
      "2020-01-19": 282,
      "2020-01-20": 282,
      "2020-01-21": 283,
      "2020-01-22": 284,
      "2020-01-23": 283
    },
    "adjTempo": 70.5,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.6,
      "2020-01-22": 70.6,
      "2020-01-23": 70.5
    },
    "adjTempoRank": 73,
    "adjTempoRankHistory": {
      "2020-01-19": 66,
      "2020-01-20": 66,
      "2020-01-21": 70,
      "2020-01-22": 72,
      "2020-01-23": 73
    },
    "conference": "WAC",
    "rank": 272,
    "rankHistory": {
      "2020-01-19": 275,
      "2020-01-20": 275,
      "2020-01-21": 276,
      "2020-01-22": 276,
      "2020-01-23": 272
    },
    "record": "8-12"
  },
  "VCU": {
    "adjDef": 90.3,
    "adjDefHistory": {
      "2020-01-19": 90.8,
      "2020-01-20": 90.9,
      "2020-01-21": 91,
      "2020-01-22": 90.3,
      "2020-01-23": 90.3
    },
    "adjDefRank": 24,
    "adjDefRankHistory": {
      "2020-01-19": 26,
      "2020-01-20": 26,
      "2020-01-21": 25,
      "2020-01-22": 25,
      "2020-01-23": 24
    },
    "adjEM": 14.34,
    "adjEmHistory": {
      "2020-01-19": 14.29,
      "2020-01-20": 14.29,
      "2020-01-21": 14.19,
      "2020-01-22": 14.29,
      "2020-01-23": 14.34
    },
    "adjOff": 104.6,
    "adjOffHistory": {
      "2020-01-19": 105.1,
      "2020-01-20": 105.1,
      "2020-01-21": 105.2,
      "2020-01-22": 104.6,
      "2020-01-23": 104.6
    },
    "adjOffRank": 107,
    "adjOffRankHistory": {
      "2020-01-19": 93,
      "2020-01-20": 94,
      "2020-01-21": 94,
      "2020-01-22": 107,
      "2020-01-23": 107
    },
    "adjTempo": 70.2,
    "adjTempoHistory": {
      "2020-01-19": 70.5,
      "2020-01-20": 70.5,
      "2020-01-21": 70.4,
      "2020-01-22": 70.3,
      "2020-01-23": 70.2
    },
    "adjTempoRank": 83,
    "adjTempoRankHistory": {
      "2020-01-19": 86,
      "2020-01-20": 86,
      "2020-01-21": 85,
      "2020-01-22": 85,
      "2020-01-23": 83
    },
    "conference": "A10",
    "rank": 47,
    "rankHistory": {
      "2020-01-19": 47,
      "2020-01-20": 47,
      "2020-01-21": 48,
      "2020-01-22": 48,
      "2020-01-23": 47
    },
    "record": "14-5"
  },
  "VMI": {
    "adjDef": 109.6,
    "adjDefHistory": {
      "2020-01-19": 110.6,
      "2020-01-20": 110.6,
      "2020-01-21": 110.7,
      "2020-01-22": 110.6,
      "2020-01-23": 109.6
    },
    "adjDefRank": 322,
    "adjDefRankHistory": {
      "2020-01-19": 334,
      "2020-01-20": 334,
      "2020-01-21": 332,
      "2020-01-22": 333,
      "2020-01-23": 322
    },
    "adjEM": -10.48,
    "adjEmHistory": {
      "2020-01-19": -10.3,
      "2020-01-20": -10.3,
      "2020-01-21": -10.37,
      "2020-01-22": -10.37,
      "2020-01-23": -10.48
    },
    "adjOff": 99.1,
    "adjOffHistory": {
      "2020-01-19": 100.3,
      "2020-01-20": 100.3,
      "2020-01-21": 100.3,
      "2020-01-22": 100.3,
      "2020-01-23": 99.1
    },
    "adjOffRank": 218,
    "adjOffRankHistory": {
      "2020-01-19": 195,
      "2020-01-20": 195,
      "2020-01-21": 196,
      "2020-01-22": 196,
      "2020-01-23": 218
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 66.9,
      "2020-01-20": 66.9,
      "2020-01-21": 66.7,
      "2020-01-22": 66.7,
      "2020-01-23": 67
    },
    "adjTempoRank": 251,
    "adjTempoRankHistory": {
      "2020-01-19": 258,
      "2020-01-20": 258,
      "2020-01-21": 261,
      "2020-01-22": 259,
      "2020-01-23": 251
    },
    "conference": "SC",
    "rank": 289,
    "rankHistory": {
      "2020-01-19": 287,
      "2020-01-20": 287,
      "2020-01-21": 287,
      "2020-01-22": 288,
      "2020-01-23": 289
    },
    "record": "6-15"
  },
  "Valparaiso": {
    "adjDef": 102.9,
    "adjDefHistory": {
      "2020-01-19": 102.9,
      "2020-01-20": 102.9,
      "2020-01-21": 103,
      "2020-01-22": 102.9,
      "2020-01-23": 102.9
    },
    "adjDefRank": 200,
    "adjDefRankHistory": {
      "2020-01-19": 203,
      "2020-01-20": 205,
      "2020-01-21": 201,
      "2020-01-22": 204,
      "2020-01-23": 200
    },
    "adjEM": 0.83,
    "adjEmHistory": {
      "2020-01-19": 0.63,
      "2020-01-20": 0.63,
      "2020-01-21": 0.74,
      "2020-01-22": 0.78,
      "2020-01-23": 0.83
    },
    "adjOff": 103.7,
    "adjOffHistory": {
      "2020-01-19": 103.5,
      "2020-01-20": 103.5,
      "2020-01-21": 103.7,
      "2020-01-22": 103.7,
      "2020-01-23": 103.7
    },
    "adjOffRank": 129,
    "adjOffRankHistory": {
      "2020-01-19": 133,
      "2020-01-20": 133,
      "2020-01-21": 132,
      "2020-01-22": 131,
      "2020-01-23": 129
    },
    "adjTempo": 70.3,
    "adjTempoHistory": {
      "2020-01-19": 70.5,
      "2020-01-20": 70.5,
      "2020-01-21": 70.4,
      "2020-01-22": 70.4,
      "2020-01-23": 70.3
    },
    "adjTempoRank": 82,
    "adjTempoRankHistory": {
      "2020-01-19": 85,
      "2020-01-20": 85,
      "2020-01-21": 83,
      "2020-01-22": 83,
      "2020-01-23": 82
    },
    "conference": "MVC",
    "rank": 155,
    "rankHistory": {
      "2020-01-19": 160,
      "2020-01-20": 160,
      "2020-01-21": 157,
      "2020-01-22": 158,
      "2020-01-23": 155
    },
    "record": "10-9"
  },
  "Vanderbilt": {
    "adjDef": 104.1,
    "adjDefHistory": {
      "2020-01-19": 104.6,
      "2020-01-20": 104.6,
      "2020-01-21": 104.8,
      "2020-01-22": 104.7,
      "2020-01-23": 104.1
    },
    "adjDefRank": 228,
    "adjDefRankHistory": {
      "2020-01-19": 238,
      "2020-01-20": 238,
      "2020-01-21": 240,
      "2020-01-22": 240,
      "2020-01-23": 228
    },
    "adjEM": -0.46,
    "adjEmHistory": {
      "2020-01-19": -0.4,
      "2020-01-20": -0.4,
      "2020-01-21": -0.41,
      "2020-01-22": -0.28,
      "2020-01-23": -0.46
    },
    "adjOff": 103.6,
    "adjOffHistory": {
      "2020-01-19": 104.2,
      "2020-01-20": 104.2,
      "2020-01-21": 104.4,
      "2020-01-22": 104.4,
      "2020-01-23": 103.6
    },
    "adjOffRank": 133,
    "adjOffRankHistory": {
      "2020-01-19": 120,
      "2020-01-20": 121,
      "2020-01-21": 118,
      "2020-01-22": 114,
      "2020-01-23": 133
    },
    "adjTempo": 69.8,
    "adjTempoHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 69.8,
      "2020-01-22": 69.8,
      "2020-01-23": 69.8
    },
    "adjTempoRank": 104,
    "adjTempoRankHistory": {
      "2020-01-19": 107,
      "2020-01-20": 107,
      "2020-01-21": 106,
      "2020-01-22": 107,
      "2020-01-23": 104
    },
    "conference": "SEC",
    "rank": 177,
    "rankHistory": {
      "2020-01-19": 175,
      "2020-01-20": 175,
      "2020-01-21": 175,
      "2020-01-22": 173,
      "2020-01-23": 177
    },
    "record": "8-10"
  },
  "Vermont": {
    "adjDef": 93.8,
    "adjDefHistory": {
      "2020-01-19": 93.7,
      "2020-01-20": 93.7,
      "2020-01-21": 93.8,
      "2020-01-22": 93.7,
      "2020-01-23": 93.8
    },
    "adjDefRank": 53,
    "adjDefRankHistory": {
      "2020-01-19": 55,
      "2020-01-20": 55,
      "2020-01-21": 54,
      "2020-01-22": 53,
      "2020-01-23": 53
    },
    "adjEM": 9.46,
    "adjEmHistory": {
      "2020-01-19": 10.66,
      "2020-01-20": 10.66,
      "2020-01-21": 10.55,
      "2020-01-22": 10.51,
      "2020-01-23": 9.46
    },
    "adjOff": 103.3,
    "adjOffHistory": {
      "2020-01-19": 104.4,
      "2020-01-20": 104.4,
      "2020-01-21": 104.4,
      "2020-01-22": 104.2,
      "2020-01-23": 103.3
    },
    "adjOffRank": 144,
    "adjOffRankHistory": {
      "2020-01-19": 116,
      "2020-01-20": 116,
      "2020-01-21": 120,
      "2020-01-22": 119,
      "2020-01-23": 144
    },
    "adjTempo": 66.5,
    "adjTempoHistory": {
      "2020-01-19": 66.8,
      "2020-01-20": 66.8,
      "2020-01-21": 66.6,
      "2020-01-22": 66.6,
      "2020-01-23": 66.5
    },
    "adjTempoRank": 263,
    "adjTempoRankHistory": {
      "2020-01-19": 264,
      "2020-01-20": 264,
      "2020-01-21": 262,
      "2020-01-22": 261,
      "2020-01-23": 263
    },
    "conference": "AE",
    "rank": 82,
    "rankHistory": {
      "2020-01-19": 70,
      "2020-01-20": 70,
      "2020-01-21": 73,
      "2020-01-22": 73,
      "2020-01-23": 82
    },
    "record": "14-6"
  },
  "Villanova": {
    "adjDef": 95.4,
    "adjDefHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.6,
      "2020-01-21": 95.8,
      "2020-01-22": 95.2,
      "2020-01-23": 95.4
    },
    "adjDefRank": 72,
    "adjDefRankHistory": {
      "2020-01-19": 79,
      "2020-01-20": 79,
      "2020-01-21": 80,
      "2020-01-22": 71,
      "2020-01-23": 72
    },
    "adjEM": 18.6,
    "adjEmHistory": {
      "2020-01-19": 17.45,
      "2020-01-20": 17.45,
      "2020-01-21": 17.37,
      "2020-01-22": 18.53,
      "2020-01-23": 18.6
    },
    "adjOff": 114,
    "adjOffHistory": {
      "2020-01-19": 113,
      "2020-01-20": 113,
      "2020-01-21": 113.2,
      "2020-01-22": 113.8,
      "2020-01-23": 114
    },
    "adjOffRank": 12,
    "adjOffRankHistory": {
      "2020-01-19": 13,
      "2020-01-20": 13,
      "2020-01-21": 13,
      "2020-01-22": 11,
      "2020-01-23": 12
    },
    "adjTempo": 66,
    "adjTempoHistory": {
      "2020-01-19": 65.9,
      "2020-01-20": 65.9,
      "2020-01-21": 65.8,
      "2020-01-22": 66,
      "2020-01-23": 66
    },
    "adjTempoRank": 283,
    "adjTempoRankHistory": {
      "2020-01-19": 290,
      "2020-01-20": 290,
      "2020-01-21": 289,
      "2020-01-22": 282,
      "2020-01-23": 283
    },
    "conference": "BE",
    "rank": 18,
    "rankHistory": {
      "2020-01-19": 27,
      "2020-01-20": 27,
      "2020-01-21": 27,
      "2020-01-22": 19,
      "2020-01-23": 18
    },
    "record": "15-3"
  },
  "Virginia": {
    "adjDef": 83.8,
    "adjDefHistory": {
      "2020-01-19": 83.7,
      "2020-01-20": 83.7,
      "2020-01-21": 83.9,
      "2020-01-22": 83.8,
      "2020-01-23": 83.8
    },
    "adjDefRank": 2,
    "adjDefRankHistory": {
      "2020-01-19": 2,
      "2020-01-20": 2,
      "2020-01-21": 2,
      "2020-01-22": 1,
      "2020-01-23": 2
    },
    "adjEM": 13.67,
    "adjEmHistory": {
      "2020-01-19": 14.61,
      "2020-01-20": 14.61,
      "2020-01-21": 14,
      "2020-01-22": 13.82,
      "2020-01-23": 13.67
    },
    "adjOff": 97.5,
    "adjOffHistory": {
      "2020-01-19": 98.3,
      "2020-01-20": 98.3,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.5
    },
    "adjOffRank": 253,
    "adjOffRankHistory": {
      "2020-01-19": 232,
      "2020-01-20": 232,
      "2020-01-21": 240,
      "2020-01-22": 243,
      "2020-01-23": 253
    },
    "adjTempo": 59.1,
    "adjTempoHistory": {
      "2020-01-19": 59.5,
      "2020-01-20": 59.5,
      "2020-01-21": 59.1,
      "2020-01-22": 59.1,
      "2020-01-23": 59.1
    },
    "adjTempoRank": 353,
    "adjTempoRankHistory": {
      "2020-01-19": 353,
      "2020-01-20": 353,
      "2020-01-21": 353,
      "2020-01-22": 353,
      "2020-01-23": 353
    },
    "conference": "ACC",
    "rank": 49,
    "rankHistory": {
      "2020-01-19": 44,
      "2020-01-20": 44,
      "2020-01-21": 49,
      "2020-01-22": 50,
      "2020-01-23": 49
    },
    "record": "12-6"
  },
  "Virginia Tech": {
    "adjDef": 95.4,
    "adjDefHistory": {
      "2020-01-19": 95.3,
      "2020-01-20": 95.3,
      "2020-01-21": 95.5,
      "2020-01-22": 95.4,
      "2020-01-23": 95.4
    },
    "adjDefRank": 73,
    "adjDefRankHistory": {
      "2020-01-19": 73,
      "2020-01-20": 73,
      "2020-01-21": 73,
      "2020-01-22": 74,
      "2020-01-23": 73
    },
    "adjEM": 12.26,
    "adjEmHistory": {
      "2020-01-19": 12.68,
      "2020-01-20": 12.68,
      "2020-01-21": 12.59,
      "2020-01-22": 12.62,
      "2020-01-23": 12.26
    },
    "adjOff": 107.7,
    "adjOffHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108.1,
      "2020-01-22": 108,
      "2020-01-23": 107.7
    },
    "adjOffRank": 59,
    "adjOffRankHistory": {
      "2020-01-19": 49,
      "2020-01-20": 51,
      "2020-01-21": 51,
      "2020-01-22": 53,
      "2020-01-23": 59
    },
    "adjTempo": 64.9,
    "adjTempoHistory": {
      "2020-01-19": 65.4,
      "2020-01-20": 65.4,
      "2020-01-21": 65.4,
      "2020-01-22": 65.4,
      "2020-01-23": 64.9
    },
    "adjTempoRank": 317,
    "adjTempoRankHistory": {
      "2020-01-19": 310,
      "2020-01-20": 308,
      "2020-01-21": 307,
      "2020-01-22": 304,
      "2020-01-23": 317
    },
    "conference": "ACC",
    "rank": 57,
    "rankHistory": {
      "2020-01-19": 55,
      "2020-01-20": 55,
      "2020-01-21": 56,
      "2020-01-22": 56,
      "2020-01-23": 57
    },
    "record": "14-5"
  },
  "Wagner": {
    "adjDef": 110.5,
    "adjDefHistory": {
      "2020-01-19": 110.1,
      "2020-01-20": 110.1,
      "2020-01-21": 110.4,
      "2020-01-22": 110.4,
      "2020-01-23": 110.5
    },
    "adjDefRank": 330,
    "adjDefRankHistory": {
      "2020-01-19": 329,
      "2020-01-20": 329,
      "2020-01-21": 330,
      "2020-01-22": 330,
      "2020-01-23": 330
    },
    "adjEM": -12.4,
    "adjEmHistory": {
      "2020-01-19": -13.34,
      "2020-01-20": -13.33,
      "2020-01-21": -12.48,
      "2020-01-22": -12.48,
      "2020-01-23": -12.4
    },
    "adjOff": 98.1,
    "adjOffHistory": {
      "2020-01-19": 96.8,
      "2020-01-20": 96.8,
      "2020-01-21": 98,
      "2020-01-22": 97.9,
      "2020-01-23": 98.1
    },
    "adjOffRank": 237,
    "adjOffRankHistory": {
      "2020-01-19": 265,
      "2020-01-20": 265,
      "2020-01-21": 237,
      "2020-01-22": 237,
      "2020-01-23": 237
    },
    "adjTempo": 68.3,
    "adjTempoHistory": {
      "2020-01-19": 69.2,
      "2020-01-20": 69.2,
      "2020-01-21": 68.3,
      "2020-01-22": 68.3,
      "2020-01-23": 68.3
    },
    "adjTempoRank": 186,
    "adjTempoRankHistory": {
      "2020-01-19": 143,
      "2020-01-20": 143,
      "2020-01-21": 185,
      "2020-01-22": 184,
      "2020-01-23": 186
    },
    "conference": "NEC",
    "rank": 308,
    "rankHistory": {
      "2020-01-19": 312,
      "2020-01-20": 312,
      "2020-01-21": 308,
      "2020-01-22": 308,
      "2020-01-23": 308
    },
    "record": "5-12"
  },
  "Wake Forest": {
    "adjDef": 100.2,
    "adjDefHistory": {
      "2020-01-19": 99.7,
      "2020-01-20": 99.7,
      "2020-01-21": 99.9,
      "2020-01-22": 100.1,
      "2020-01-23": 100.2
    },
    "adjDefRank": 141,
    "adjDefRankHistory": {
      "2020-01-19": 141,
      "2020-01-20": 141,
      "2020-01-21": 140,
      "2020-01-22": 142,
      "2020-01-23": 141
    },
    "adjEM": 6.63,
    "adjEmHistory": {
      "2020-01-19": 6.2,
      "2020-01-20": 6.2,
      "2020-01-21": 6.23,
      "2020-01-22": 6.47,
      "2020-01-23": 6.63
    },
    "adjOff": 106.8,
    "adjOffHistory": {
      "2020-01-19": 105.9,
      "2020-01-20": 105.9,
      "2020-01-21": 106.1,
      "2020-01-22": 106.6,
      "2020-01-23": 106.8
    },
    "adjOffRank": 69,
    "adjOffRankHistory": {
      "2020-01-19": 78,
      "2020-01-20": 77,
      "2020-01-21": 77,
      "2020-01-22": 69,
      "2020-01-23": 69
    },
    "adjTempo": 70.2,
    "adjTempoHistory": {
      "2020-01-19": 70.6,
      "2020-01-20": 70.6,
      "2020-01-21": 70.4,
      "2020-01-22": 70.2,
      "2020-01-23": 70.2
    },
    "adjTempoRank": 85,
    "adjTempoRankHistory": {
      "2020-01-19": 78,
      "2020-01-20": 80,
      "2020-01-21": 82,
      "2020-01-22": 89,
      "2020-01-23": 85
    },
    "conference": "ACC",
    "rank": 103,
    "rankHistory": {
      "2020-01-19": 107,
      "2020-01-20": 107,
      "2020-01-21": 107,
      "2020-01-22": 104,
      "2020-01-23": 103
    },
    "record": "9-9"
  },
  "Washington": {
    "adjDef": 89.8,
    "adjDefHistory": {
      "2020-01-19": 89.6,
      "2020-01-20": 89.7,
      "2020-01-21": 89.9,
      "2020-01-22": 89.7,
      "2020-01-23": 89.8
    },
    "adjDefRank": 20,
    "adjDefRankHistory": {
      "2020-01-19": 21,
      "2020-01-20": 21,
      "2020-01-21": 21,
      "2020-01-22": 21,
      "2020-01-23": 20
    },
    "adjEM": 14.71,
    "adjEmHistory": {
      "2020-01-19": 14.56,
      "2020-01-20": 14.56,
      "2020-01-21": 14.59,
      "2020-01-22": 14.67,
      "2020-01-23": 14.71
    },
    "adjOff": 104.5,
    "adjOffHistory": {
      "2020-01-19": 104.1,
      "2020-01-20": 104.3,
      "2020-01-21": 104.5,
      "2020-01-22": 104.4,
      "2020-01-23": 104.5
    },
    "adjOffRank": 110,
    "adjOffRankHistory": {
      "2020-01-19": 121,
      "2020-01-20": 120,
      "2020-01-21": 114,
      "2020-01-22": 112,
      "2020-01-23": 110
    },
    "adjTempo": 68.4,
    "adjTempoHistory": {
      "2020-01-19": 68.5,
      "2020-01-20": 68.6,
      "2020-01-21": 68.5,
      "2020-01-22": 68.5,
      "2020-01-23": 68.4
    },
    "adjTempoRank": 174,
    "adjTempoRankHistory": {
      "2020-01-19": 180,
      "2020-01-20": 178,
      "2020-01-21": 175,
      "2020-01-22": 175,
      "2020-01-23": 174
    },
    "conference": "P12",
    "rank": 44,
    "rankHistory": {
      "2020-01-19": 45,
      "2020-01-20": 45,
      "2020-01-21": 45,
      "2020-01-22": 45,
      "2020-01-23": 44
    },
    "record": "12-7"
  },
  "Washington St.": {
    "adjDef": 97.7,
    "adjDefHistory": {
      "2020-01-19": 97.6,
      "2020-01-20": 97.7,
      "2020-01-21": 97.9,
      "2020-01-22": 97.7,
      "2020-01-23": 97.7
    },
    "adjDefRank": 98,
    "adjDefRankHistory": {
      "2020-01-19": 98,
      "2020-01-20": 99,
      "2020-01-21": 101,
      "2020-01-22": 100,
      "2020-01-23": 98
    },
    "adjEM": 4.51,
    "adjEmHistory": {
      "2020-01-19": 4.35,
      "2020-01-20": 4.35,
      "2020-01-21": 4.35,
      "2020-01-22": 4.38,
      "2020-01-23": 4.51
    },
    "adjOff": 102.2,
    "adjOffHistory": {
      "2020-01-19": 102,
      "2020-01-20": 102.1,
      "2020-01-21": 102.2,
      "2020-01-22": 102.1,
      "2020-01-23": 102.2
    },
    "adjOffRank": 169,
    "adjOffRankHistory": {
      "2020-01-19": 171,
      "2020-01-20": 167,
      "2020-01-21": 167,
      "2020-01-22": 171,
      "2020-01-23": 169
    },
    "adjTempo": 70.6,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.7,
      "2020-01-22": 70.6,
      "2020-01-23": 70.6
    },
    "adjTempoRank": 69,
    "adjTempoRankHistory": {
      "2020-01-19": 64,
      "2020-01-20": 61,
      "2020-01-21": 66,
      "2020-01-22": 69,
      "2020-01-23": 69
    },
    "conference": "P12",
    "rank": 119,
    "rankHistory": {
      "2020-01-19": 123,
      "2020-01-20": 123,
      "2020-01-21": 123,
      "2020-01-22": 121,
      "2020-01-23": 119
    },
    "record": "12-7"
  },
  "Weber St.": {
    "adjDef": 106.9,
    "adjDefHistory": {
      "2020-01-19": 106.5,
      "2020-01-20": 106.5,
      "2020-01-21": 107,
      "2020-01-22": 106.9,
      "2020-01-23": 106.9
    },
    "adjDefRank": 290,
    "adjDefRankHistory": {
      "2020-01-19": 279,
      "2020-01-20": 280,
      "2020-01-21": 291,
      "2020-01-22": 290,
      "2020-01-23": 290
    },
    "adjEM": -11.29,
    "adjEmHistory": {
      "2020-01-19": -10.83,
      "2020-01-20": -10.83,
      "2020-01-21": -11.29,
      "2020-01-22": -11.26,
      "2020-01-23": -11.29
    },
    "adjOff": 95.7,
    "adjOffHistory": {
      "2020-01-19": 95.6,
      "2020-01-20": 95.7,
      "2020-01-21": 95.7,
      "2020-01-22": 95.6,
      "2020-01-23": 95.7
    },
    "adjOffRank": 288,
    "adjOffRankHistory": {
      "2020-01-19": 287,
      "2020-01-20": 287,
      "2020-01-21": 289,
      "2020-01-22": 289,
      "2020-01-23": 288
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 66.5,
      "2020-01-20": 66.5,
      "2020-01-21": 67,
      "2020-01-22": 67,
      "2020-01-23": 67
    },
    "adjTempoRank": 250,
    "adjTempoRankHistory": {
      "2020-01-19": 272,
      "2020-01-20": 272,
      "2020-01-21": 249,
      "2020-01-22": 249,
      "2020-01-23": 250
    },
    "conference": "BSky",
    "rank": 300,
    "rankHistory": {
      "2020-01-19": 291,
      "2020-01-20": 291,
      "2020-01-21": 298,
      "2020-01-22": 299,
      "2020-01-23": 300
    },
    "record": "6-12"
  },
  "West Virginia": {
    "adjDef": 84.7,
    "adjDefHistory": {
      "2020-01-19": 84.9,
      "2020-01-20": 84.9,
      "2020-01-21": 84.9,
      "2020-01-22": 84.7,
      "2020-01-23": 84.7
    },
    "adjDefRank": 3,
    "adjDefRankHistory": {
      "2020-01-19": 3,
      "2020-01-20": 3,
      "2020-01-21": 3,
      "2020-01-22": 3,
      "2020-01-23": 3
    },
    "adjEM": 24.24,
    "adjEmHistory": {
      "2020-01-19": 22.62,
      "2020-01-20": 22.62,
      "2020-01-21": 24.06,
      "2020-01-22": 24.19,
      "2020-01-23": 24.24
    },
    "adjOff": 109,
    "adjOffHistory": {
      "2020-01-19": 107.5,
      "2020-01-20": 107.5,
      "2020-01-21": 109,
      "2020-01-22": 108.9,
      "2020-01-23": 109
    },
    "adjOffRank": 45,
    "adjOffRankHistory": {
      "2020-01-19": 58,
      "2020-01-20": 58,
      "2020-01-21": 44,
      "2020-01-22": 44,
      "2020-01-23": 45
    },
    "adjTempo": 70.7,
    "adjTempoHistory": {
      "2020-01-19": 70.8,
      "2020-01-20": 70.8,
      "2020-01-21": 70.8,
      "2020-01-22": 70.8,
      "2020-01-23": 70.7
    },
    "adjTempoRank": 61,
    "adjTempoRankHistory": {
      "2020-01-19": 65,
      "2020-01-20": 65,
      "2020-01-21": 62,
      "2020-01-22": 61,
      "2020-01-23": 61
    },
    "conference": "B12",
    "rank": 6,
    "rankHistory": {
      "2020-01-19": 9,
      "2020-01-20": 9,
      "2020-01-21": 6,
      "2020-01-22": 6,
      "2020-01-23": 6
    },
    "record": "15-3"
  },
  "Western Carolina": {
    "adjDef": 107.9,
    "adjDefHistory": {
      "2020-01-19": 106.8,
      "2020-01-20": 106.8,
      "2020-01-21": 107,
      "2020-01-22": 106.9,
      "2020-01-23": 107.9
    },
    "adjDefRank": 301,
    "adjDefRankHistory": {
      "2020-01-19": 292,
      "2020-01-20": 292,
      "2020-01-21": 293,
      "2020-01-22": 292,
      "2020-01-23": 301
    },
    "adjEM": 0.37,
    "adjEmHistory": {
      "2020-01-19": 0.87,
      "2020-01-20": 0.88,
      "2020-01-21": 0.96,
      "2020-01-22": 0.99,
      "2020-01-23": 0.37
    },
    "adjOff": 108.3,
    "adjOffHistory": {
      "2020-01-19": 107.7,
      "2020-01-20": 107.7,
      "2020-01-21": 108,
      "2020-01-22": 107.9,
      "2020-01-23": 108.3
    },
    "adjOffRank": 53,
    "adjOffRankHistory": {
      "2020-01-19": 54,
      "2020-01-20": 54,
      "2020-01-21": 54,
      "2020-01-22": 55,
      "2020-01-23": 53
    },
    "adjTempo": 71.5,
    "adjTempoHistory": {
      "2020-01-19": 71.9,
      "2020-01-20": 71.9,
      "2020-01-21": 71.8,
      "2020-01-22": 71.7,
      "2020-01-23": 71.5
    },
    "adjTempoRank": 41,
    "adjTempoRankHistory": {
      "2020-01-19": 35,
      "2020-01-20": 35,
      "2020-01-21": 37,
      "2020-01-22": 37,
      "2020-01-23": 41
    },
    "conference": "SC",
    "rank": 163,
    "rankHistory": {
      "2020-01-19": 154,
      "2020-01-20": 154,
      "2020-01-21": 153,
      "2020-01-22": 154,
      "2020-01-23": 163
    },
    "record": "12-6"
  },
  "Western Illinois": {
    "adjDef": 114.5,
    "adjDefHistory": {
      "2020-01-19": 114.2,
      "2020-01-20": 114.2,
      "2020-01-21": 114.4,
      "2020-01-22": 114.2,
      "2020-01-23": 114.5
    },
    "adjDefRank": 343,
    "adjDefRankHistory": {
      "2020-01-19": 344,
      "2020-01-20": 344,
      "2020-01-21": 343,
      "2020-01-22": 343,
      "2020-01-23": 343
    },
    "adjEM": -16.02,
    "adjEmHistory": {
      "2020-01-19": -15.88,
      "2020-01-20": -15.88,
      "2020-01-21": -15.9,
      "2020-01-22": -15.86,
      "2020-01-23": -16.02
    },
    "adjOff": 98.5,
    "adjOffHistory": {
      "2020-01-19": 98.3,
      "2020-01-20": 98.3,
      "2020-01-21": 98.5,
      "2020-01-22": 98.4,
      "2020-01-23": 98.5
    },
    "adjOffRank": 231,
    "adjOffRankHistory": {
      "2020-01-19": 231,
      "2020-01-20": 230,
      "2020-01-21": 230,
      "2020-01-22": 229,
      "2020-01-23": 231
    },
    "adjTempo": 72.4,
    "adjTempoHistory": {
      "2020-01-19": 72.6,
      "2020-01-20": 72.6,
      "2020-01-21": 72.5,
      "2020-01-22": 72.4,
      "2020-01-23": 72.4
    },
    "adjTempoRank": 24,
    "adjTempoRankHistory": {
      "2020-01-19": 25,
      "2020-01-20": 25,
      "2020-01-21": 24,
      "2020-01-22": 23,
      "2020-01-23": 24
    },
    "conference": "Sum",
    "rank": 334,
    "rankHistory": {
      "2020-01-19": 332,
      "2020-01-20": 332,
      "2020-01-21": 333,
      "2020-01-22": 333,
      "2020-01-23": 334
    },
    "record": "5-11"
  },
  "Western Kentucky": {
    "adjDef": 101.9,
    "adjDefHistory": {
      "2020-01-19": 102.6,
      "2020-01-20": 102.6,
      "2020-01-21": 102.7,
      "2020-01-22": 102.7,
      "2020-01-23": 101.9
    },
    "adjDefRank": 171,
    "adjDefRankHistory": {
      "2020-01-19": 190,
      "2020-01-20": 189,
      "2020-01-21": 187,
      "2020-01-22": 192,
      "2020-01-23": 171
    },
    "adjEM": 5.66,
    "adjEmHistory": {
      "2020-01-19": 5.41,
      "2020-01-20": 5.41,
      "2020-01-21": 5.39,
      "2020-01-22": 5.35,
      "2020-01-23": 5.66
    },
    "adjOff": 107.6,
    "adjOffHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108.1,
      "2020-01-22": 108,
      "2020-01-23": 107.6
    },
    "adjOffRank": 60,
    "adjOffRankHistory": {
      "2020-01-19": 50,
      "2020-01-20": 50,
      "2020-01-21": 52,
      "2020-01-22": 52,
      "2020-01-23": 60
    },
    "adjTempo": 68.7,
    "adjTempoHistory": {
      "2020-01-19": 69.3,
      "2020-01-20": 69.3,
      "2020-01-21": 69.2,
      "2020-01-22": 69.2,
      "2020-01-23": 68.7
    },
    "adjTempoRank": 160,
    "adjTempoRankHistory": {
      "2020-01-19": 135,
      "2020-01-20": 135,
      "2020-01-21": 136,
      "2020-01-22": 136,
      "2020-01-23": 160
    },
    "conference": "CUSA",
    "rank": 108,
    "rankHistory": {
      "2020-01-19": 111,
      "2020-01-20": 111,
      "2020-01-21": 111,
      "2020-01-22": 111,
      "2020-01-23": 108
    },
    "record": "13-6"
  },
  "Western Michigan": {
    "adjDef": 107.1,
    "adjDefHistory": {
      "2020-01-19": 106.3,
      "2020-01-20": 106.3,
      "2020-01-21": 106.4,
      "2020-01-22": 107,
      "2020-01-23": 107.1
    },
    "adjDefRank": 293,
    "adjDefRankHistory": {
      "2020-01-19": 274,
      "2020-01-20": 274,
      "2020-01-21": 277,
      "2020-01-22": 293,
      "2020-01-23": 293
    },
    "adjEM": -6.83,
    "adjEmHistory": {
      "2020-01-19": -6.55,
      "2020-01-20": -6.55,
      "2020-01-21": -6.58,
      "2020-01-22": -6.82,
      "2020-01-23": -6.83
    },
    "adjOff": 100.3,
    "adjOffHistory": {
      "2020-01-19": 99.7,
      "2020-01-20": 99.7,
      "2020-01-21": 99.8,
      "2020-01-22": 100.2,
      "2020-01-23": 100.3
    },
    "adjOffRank": 197,
    "adjOffRankHistory": {
      "2020-01-19": 204,
      "2020-01-20": 203,
      "2020-01-21": 205,
      "2020-01-22": 198,
      "2020-01-23": 197
    },
    "adjTempo": 66.6,
    "adjTempoHistory": {
      "2020-01-19": 66.9,
      "2020-01-20": 66.9,
      "2020-01-21": 66.8,
      "2020-01-22": 66.7,
      "2020-01-23": 66.6
    },
    "adjTempoRank": 261,
    "adjTempoRankHistory": {
      "2020-01-19": 256,
      "2020-01-20": 256,
      "2020-01-21": 257,
      "2020-01-22": 258,
      "2020-01-23": 261
    },
    "conference": "MAC",
    "rank": 250,
    "rankHistory": {
      "2020-01-19": 245,
      "2020-01-20": 245,
      "2020-01-21": 245,
      "2020-01-22": 248,
      "2020-01-23": 250
    },
    "record": "9-10"
  },
  "Wichita St.": {
    "adjDef": 88,
    "adjDefHistory": {
      "2020-01-19": 88.9,
      "2020-01-20": 88.9,
      "2020-01-21": 89,
      "2020-01-22": 88,
      "2020-01-23": 88
    },
    "adjDefRank": 8,
    "adjDefRankHistory": {
      "2020-01-19": 16,
      "2020-01-20": 16,
      "2020-01-21": 16,
      "2020-01-22": 10,
      "2020-01-23": 8
    },
    "adjEM": 15.94,
    "adjEmHistory": {
      "2020-01-19": 15.25,
      "2020-01-20": 15.25,
      "2020-01-21": 15.38,
      "2020-01-22": 16.12,
      "2020-01-23": 15.94
    },
    "adjOff": 104,
    "adjOffHistory": {
      "2020-01-19": 104.1,
      "2020-01-20": 104.1,
      "2020-01-21": 104.3,
      "2020-01-22": 104.1,
      "2020-01-23": 104
    },
    "adjOffRank": 126,
    "adjOffRankHistory": {
      "2020-01-19": 122,
      "2020-01-20": 122,
      "2020-01-21": 121,
      "2020-01-22": 123,
      "2020-01-23": 126
    },
    "adjTempo": 69.1,
    "adjTempoHistory": {
      "2020-01-19": 69.7,
      "2020-01-20": 69.7,
      "2020-01-21": 69.6,
      "2020-01-22": 69.1,
      "2020-01-23": 69.1
    },
    "adjTempoRank": 136,
    "adjTempoRankHistory": {
      "2020-01-19": 121,
      "2020-01-20": 121,
      "2020-01-21": 120,
      "2020-01-22": 140,
      "2020-01-23": 136
    },
    "conference": "Amer",
    "rank": 39,
    "rankHistory": {
      "2020-01-19": 42,
      "2020-01-20": 42,
      "2020-01-21": 41,
      "2020-01-22": 38,
      "2020-01-23": 39
    },
    "record": "16-3"
  },
  "William & Mary": {
    "adjDef": 103.5,
    "adjDefHistory": {
      "2020-01-19": 103.4,
      "2020-01-20": 103.4,
      "2020-01-21": 103.5,
      "2020-01-22": 103.4,
      "2020-01-23": 103.5
    },
    "adjDefRank": 214,
    "adjDefRankHistory": {
      "2020-01-19": 211,
      "2020-01-20": 212,
      "2020-01-21": 213,
      "2020-01-22": 213,
      "2020-01-23": 214
    },
    "adjEM": 1.48,
    "adjEmHistory": {
      "2020-01-19": 1.38,
      "2020-01-20": 1.38,
      "2020-01-21": 1.35,
      "2020-01-22": 1.38,
      "2020-01-23": 1.48
    },
    "adjOff": 104.9,
    "adjOffHistory": {
      "2020-01-19": 104.8,
      "2020-01-20": 104.8,
      "2020-01-21": 104.8,
      "2020-01-22": 104.7,
      "2020-01-23": 104.9
    },
    "adjOffRank": 102,
    "adjOffRankHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 104,
      "2020-01-22": 103,
      "2020-01-23": 102
    },
    "adjTempo": 67.9,
    "adjTempoHistory": {
      "2020-01-19": 68.1,
      "2020-01-20": 68.1,
      "2020-01-21": 68,
      "2020-01-22": 68,
      "2020-01-23": 67.9
    },
    "adjTempoRank": 205,
    "adjTempoRankHistory": {
      "2020-01-19": 204,
      "2020-01-20": 204,
      "2020-01-21": 202,
      "2020-01-22": 203,
      "2020-01-23": 205
    },
    "conference": "CAA",
    "rank": 150,
    "rankHistory": {
      "2020-01-19": 150,
      "2020-01-20": 150,
      "2020-01-21": 150,
      "2020-01-22": 151,
      "2020-01-23": 150
    },
    "record": "14-6"
  },
  "Winthrop": {
    "adjDef": 100.5,
    "adjDefHistory": {
      "2020-01-19": 101.7,
      "2020-01-20": 101.7,
      "2020-01-21": 100.5,
      "2020-01-22": 100.4,
      "2020-01-23": 100.5
    },
    "adjDefRank": 145,
    "adjDefRankHistory": {
      "2020-01-19": 168,
      "2020-01-20": 168,
      "2020-01-21": 147,
      "2020-01-22": 145,
      "2020-01-23": 145
    },
    "adjEM": 4.07,
    "adjEmHistory": {
      "2020-01-19": 3.2,
      "2020-01-20": 3.2,
      "2020-01-21": 4.01,
      "2020-01-22": 4.11,
      "2020-01-23": 4.07
    },
    "adjOff": 104.5,
    "adjOffHistory": {
      "2020-01-19": 104.9,
      "2020-01-20": 104.9,
      "2020-01-21": 104.5,
      "2020-01-22": 104.5,
      "2020-01-23": 104.5
    },
    "adjOffRank": 111,
    "adjOffRankHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 113,
      "2020-01-22": 110,
      "2020-01-23": 111
    },
    "adjTempo": 71.4,
    "adjTempoHistory": {
      "2020-01-19": 72,
      "2020-01-20": 72,
      "2020-01-21": 71.5,
      "2020-01-22": 71.5,
      "2020-01-23": 71.4
    },
    "adjTempoRank": 44,
    "adjTempoRankHistory": {
      "2020-01-19": 34,
      "2020-01-20": 34,
      "2020-01-21": 41,
      "2020-01-22": 42,
      "2020-01-23": 44
    },
    "conference": "BSth",
    "rank": 124,
    "rankHistory": {
      "2020-01-19": 132,
      "2020-01-20": 132,
      "2020-01-21": 126,
      "2020-01-22": 126,
      "2020-01-23": 124
    },
    "record": "12-7"
  },
  "Wisconsin": {
    "adjDef": 90.2,
    "adjDefHistory": {
      "2020-01-19": 89.9,
      "2020-01-20": 89.8,
      "2020-01-21": 90,
      "2020-01-22": 90.1,
      "2020-01-23": 90.2
    },
    "adjDefRank": 23,
    "adjDefRankHistory": {
      "2020-01-19": 22,
      "2020-01-20": 22,
      "2020-01-21": 22,
      "2020-01-22": 24,
      "2020-01-23": 23
    },
    "adjEM": 18.59,
    "adjEmHistory": {
      "2020-01-19": 18.17,
      "2020-01-20": 18.17,
      "2020-01-21": 18.21,
      "2020-01-22": 18.44,
      "2020-01-23": 18.59
    },
    "adjOff": 108.8,
    "adjOffHistory": {
      "2020-01-19": 108,
      "2020-01-20": 108,
      "2020-01-21": 108.2,
      "2020-01-22": 108.6,
      "2020-01-23": 108.8
    },
    "adjOffRank": 48,
    "adjOffRankHistory": {
      "2020-01-19": 48,
      "2020-01-20": 49,
      "2020-01-21": 50,
      "2020-01-22": 46,
      "2020-01-23": 48
    },
    "adjTempo": 62.1,
    "adjTempoHistory": {
      "2020-01-19": 62.1,
      "2020-01-20": 62.1,
      "2020-01-21": 61.9,
      "2020-01-22": 62.2,
      "2020-01-23": 62.1
    },
    "adjTempoRank": 350,
    "adjTempoRankHistory": {
      "2020-01-19": 351,
      "2020-01-20": 351,
      "2020-01-21": 351,
      "2020-01-22": 350,
      "2020-01-23": 350
    },
    "conference": "B10",
    "rank": 19,
    "rankHistory": {
      "2020-01-19": 22,
      "2020-01-20": 22,
      "2020-01-21": 22,
      "2020-01-22": 20,
      "2020-01-23": 19
    },
    "record": "12-7"
  },
  "Wofford": {
    "adjDef": 102.8,
    "adjDefHistory": {
      "2020-01-19": 103.7,
      "2020-01-20": 103.7,
      "2020-01-21": 103.9,
      "2020-01-22": 103.8,
      "2020-01-23": 102.8
    },
    "adjDefRank": 195,
    "adjDefRankHistory": {
      "2020-01-19": 220,
      "2020-01-20": 221,
      "2020-01-21": 222,
      "2020-01-22": 224,
      "2020-01-23": 195
    },
    "adjEM": 2.25,
    "adjEmHistory": {
      "2020-01-19": 2.45,
      "2020-01-20": 2.45,
      "2020-01-21": 2.45,
      "2020-01-22": 2.31,
      "2020-01-23": 2.25
    },
    "adjOff": 105,
    "adjOffHistory": {
      "2020-01-19": 106.1,
      "2020-01-20": 106.1,
      "2020-01-21": 106.3,
      "2020-01-22": 106.2,
      "2020-01-23": 105
    },
    "adjOffRank": 100,
    "adjOffRankHistory": {
      "2020-01-19": 73,
      "2020-01-20": 72,
      "2020-01-21": 72,
      "2020-01-22": 74,
      "2020-01-23": 100
    },
    "adjTempo": 64.8,
    "adjTempoHistory": {
      "2020-01-19": 64.7,
      "2020-01-20": 64.7,
      "2020-01-21": 64.6,
      "2020-01-22": 64.5,
      "2020-01-23": 64.8
    },
    "adjTempoRank": 323,
    "adjTempoRankHistory": {
      "2020-01-19": 326,
      "2020-01-20": 326,
      "2020-01-21": 326,
      "2020-01-22": 326,
      "2020-01-23": 323
    },
    "conference": "SC",
    "rank": 140,
    "rankHistory": {
      "2020-01-19": 138,
      "2020-01-20": 138,
      "2020-01-21": 138,
      "2020-01-22": 138,
      "2020-01-23": 140
    },
    "record": "13-7"
  },
  "Wright St.": {
    "adjDef": 100.3,
    "adjDefHistory": {
      "2020-01-19": 100,
      "2020-01-20": 100,
      "2020-01-21": 100.1,
      "2020-01-22": 100.1,
      "2020-01-23": 100.3
    },
    "adjDefRank": 143,
    "adjDefRankHistory": {
      "2020-01-19": 142,
      "2020-01-20": 142,
      "2020-01-21": 143,
      "2020-01-22": 141,
      "2020-01-23": 143
    },
    "adjEM": 4.2,
    "adjEmHistory": {
      "2020-01-19": 4.58,
      "2020-01-20": 4.58,
      "2020-01-21": 4.55,
      "2020-01-22": 4.37,
      "2020-01-23": 4.2
    },
    "adjOff": 104.5,
    "adjOffHistory": {
      "2020-01-19": 104.6,
      "2020-01-20": 104.6,
      "2020-01-21": 104.7,
      "2020-01-22": 104.4,
      "2020-01-23": 104.5
    },
    "adjOffRank": 112,
    "adjOffRankHistory": {
      "2020-01-19": 111,
      "2020-01-20": 111,
      "2020-01-21": 109,
      "2020-01-22": 111,
      "2020-01-23": 112
    },
    "adjTempo": 71.5,
    "adjTempoHistory": {
      "2020-01-19": 71.7,
      "2020-01-20": 71.7,
      "2020-01-21": 71.5,
      "2020-01-22": 71.5,
      "2020-01-23": 71.5
    },
    "adjTempoRank": 42,
    "adjTempoRankHistory": {
      "2020-01-19": 42,
      "2020-01-20": 42,
      "2020-01-21": 42,
      "2020-01-22": 41,
      "2020-01-23": 42
    },
    "conference": "Horz",
    "rank": 123,
    "rankHistory": {
      "2020-01-19": 120,
      "2020-01-20": 120,
      "2020-01-21": 121,
      "2020-01-22": 122,
      "2020-01-23": 123
    },
    "record": "17-4"
  },
  "Wyoming": {
    "adjDef": 102.4,
    "adjDefHistory": {
      "2020-01-19": 102.2,
      "2020-01-20": 102.1,
      "2020-01-21": 102.3,
      "2020-01-22": 102.5,
      "2020-01-23": 102.4
    },
    "adjDefRank": 182,
    "adjDefRankHistory": {
      "2020-01-19": 178,
      "2020-01-20": 176,
      "2020-01-21": 178,
      "2020-01-22": 182,
      "2020-01-23": 182
    },
    "adjEM": -10.9,
    "adjEmHistory": {
      "2020-01-19": -11.4,
      "2020-01-20": -11.4,
      "2020-01-21": -11.38,
      "2020-01-22": -10.98,
      "2020-01-23": -10.9
    },
    "adjOff": 91.5,
    "adjOffHistory": {
      "2020-01-19": 90.8,
      "2020-01-20": 90.7,
      "2020-01-21": 90.9,
      "2020-01-22": 91.5,
      "2020-01-23": 91.5
    },
    "adjOffRank": 333,
    "adjOffRankHistory": {
      "2020-01-19": 337,
      "2020-01-20": 337,
      "2020-01-21": 337,
      "2020-01-22": 331,
      "2020-01-23": 333
    },
    "adjTempo": 64.1,
    "adjTempoHistory": {
      "2020-01-19": 64.4,
      "2020-01-20": 64.4,
      "2020-01-21": 64.3,
      "2020-01-22": 64.1,
      "2020-01-23": 64.1
    },
    "adjTempoRank": 335,
    "adjTempoRankHistory": {
      "2020-01-19": 329,
      "2020-01-20": 329,
      "2020-01-21": 328,
      "2020-01-22": 333,
      "2020-01-23": 335
    },
    "conference": "MWC",
    "rank": 294,
    "rankHistory": {
      "2020-01-19": 299,
      "2020-01-20": 299,
      "2020-01-21": 300,
      "2020-01-22": 294,
      "2020-01-23": 294
    },
    "record": "5-16"
  },
  "Xavier": {
    "adjDef": 91.8,
    "adjDefHistory": {
      "2020-01-19": 92.9,
      "2020-01-20": 92.9,
      "2020-01-21": 93,
      "2020-01-22": 92.8,
      "2020-01-23": 91.8
    },
    "adjDefRank": 29,
    "adjDefRankHistory": {
      "2020-01-19": 46,
      "2020-01-20": 46,
      "2020-01-21": 45,
      "2020-01-22": 43,
      "2020-01-23": 29
    },
    "adjEM": 12.62,
    "adjEmHistory": {
      "2020-01-19": 12.11,
      "2020-01-20": 12.11,
      "2020-01-21": 12.1,
      "2020-01-22": 12.13,
      "2020-01-23": 12.62
    },
    "adjOff": 104.4,
    "adjOffHistory": {
      "2020-01-19": 105,
      "2020-01-20": 105,
      "2020-01-21": 105.1,
      "2020-01-22": 105,
      "2020-01-23": 104.4
    },
    "adjOffRank": 117,
    "adjOffRankHistory": {
      "2020-01-19": 96,
      "2020-01-20": 97,
      "2020-01-21": 96,
      "2020-01-22": 97,
      "2020-01-23": 117
    },
    "adjTempo": 68.5,
    "adjTempoHistory": {
      "2020-01-19": 68.9,
      "2020-01-20": 68.9,
      "2020-01-21": 68.7,
      "2020-01-22": 68.7,
      "2020-01-23": 68.5
    },
    "adjTempoRank": 166,
    "adjTempoRankHistory": {
      "2020-01-19": 160,
      "2020-01-20": 160,
      "2020-01-21": 161,
      "2020-01-22": 161,
      "2020-01-23": 166
    },
    "conference": "BE",
    "rank": 55,
    "rankHistory": {
      "2020-01-19": 59,
      "2020-01-20": 59,
      "2020-01-21": 58,
      "2020-01-22": 59,
      "2020-01-23": 55
    },
    "record": "13-6"
  },
  "Yale": {
    "adjDef": 91.4,
    "adjDefHistory": {
      "2020-01-19": 90.1,
      "2020-01-20": 90.1,
      "2020-01-21": 91.6,
      "2020-01-22": 91.4,
      "2020-01-23": 91.4
    },
    "adjDefRank": 27,
    "adjDefRankHistory": {
      "2020-01-19": 23,
      "2020-01-20": 23,
      "2020-01-21": 28,
      "2020-01-22": 27,
      "2020-01-23": 27
    },
    "adjEM": 12,
    "adjEmHistory": {
      "2020-01-19": 12.55,
      "2020-01-20": 12.56,
      "2020-01-21": 11.93,
      "2020-01-22": 11.94,
      "2020-01-23": 12
    },
    "adjOff": 103.4,
    "adjOffHistory": {
      "2020-01-19": 102.7,
      "2020-01-20": 102.7,
      "2020-01-21": 103.5,
      "2020-01-22": 103.3,
      "2020-01-23": 103.4
    },
    "adjOffRank": 141,
    "adjOffRankHistory": {
      "2020-01-19": 157,
      "2020-01-20": 157,
      "2020-01-21": 137,
      "2020-01-22": 143,
      "2020-01-23": 141
    },
    "adjTempo": 66.2,
    "adjTempoHistory": {
      "2020-01-19": 66.4,
      "2020-01-20": 66.4,
      "2020-01-21": 66.3,
      "2020-01-22": 66.2,
      "2020-01-23": 66.2
    },
    "adjTempoRank": 278,
    "adjTempoRankHistory": {
      "2020-01-19": 274,
      "2020-01-20": 274,
      "2020-01-21": 275,
      "2020-01-22": 275,
      "2020-01-23": 278
    },
    "conference": "Ivy",
    "rank": 61,
    "rankHistory": {
      "2020-01-19": 57,
      "2020-01-20": 57,
      "2020-01-21": 59,
      "2020-01-22": 61,
      "2020-01-23": 61
    },
    "record": "13-4"
  },
  "Youngstown St.": {
    "adjDef": 105.8,
    "adjDefHistory": {
      "2020-01-19": 105.8,
      "2020-01-20": 105.8,
      "2020-01-21": 105.9,
      "2020-01-22": 105.8,
      "2020-01-23": 105.8
    },
    "adjDefRank": 264,
    "adjDefRankHistory": {
      "2020-01-19": 269,
      "2020-01-20": 268,
      "2020-01-21": 266,
      "2020-01-22": 266,
      "2020-01-23": 264
    },
    "adjEM": -6.05,
    "adjEmHistory": {
      "2020-01-19": -6.01,
      "2020-01-20": -6.01,
      "2020-01-21": -6.01,
      "2020-01-22": -6.02,
      "2020-01-23": -6.05
    },
    "adjOff": 99.8,
    "adjOffHistory": {
      "2020-01-19": 99.8,
      "2020-01-20": 99.8,
      "2020-01-21": 99.9,
      "2020-01-22": 99.8,
      "2020-01-23": 99.8
    },
    "adjOffRank": 204,
    "adjOffRankHistory": {
      "2020-01-19": 202,
      "2020-01-20": 201,
      "2020-01-21": 203,
      "2020-01-22": 204,
      "2020-01-23": 204
    },
    "adjTempo": 67,
    "adjTempoHistory": {
      "2020-01-19": 67.2,
      "2020-01-20": 67.2,
      "2020-01-21": 67.1,
      "2020-01-22": 67.1,
      "2020-01-23": 67
    },
    "adjTempoRank": 247,
    "adjTempoRankHistory": {
      "2020-01-19": 244,
      "2020-01-20": 244,
      "2020-01-21": 246,
      "2020-01-22": 245,
      "2020-01-23": 247
    },
    "conference": "Horz",
    "rank": 237,
    "rankHistory": {
      "2020-01-19": 238,
      "2020-01-20": 238,
      "2020-01-21": 239,
      "2020-01-22": 238,
      "2020-01-23": 237
    },
    "record": "11-9"
  }
}

export const allSeasons = {
  "2002": {
    "Air Force": {
      "adjDef": 105.5,
      "adjDefRank": 214,
      "adjEM": -1.64,
      "adjOff": 103.8,
      "adjOffRank": 149,
      "adjTempo": 54.2,
      "adjTempoRank": 327,
      "conference": "MWC",
      "rank": 181,
      "record": "9-19"
    },
    "Akron": {
      "adjDef": 111.2,
      "adjDefRank": 295,
      "adjEM": -5.94,
      "adjOff": 105.2,
      "adjOffRank": 130,
      "adjTempo": 65.6,
      "adjTempoRank": 251,
      "conference": "MAC",
      "rank": 227,
      "record": "9-21"
    },
    "Alabama": {
      "adjDef": 95.3,
      "adjDefRank": 32,
      "adjEM": 18.73,
      "adjOff": 114,
      "adjOffRank": 24,
      "adjTempo": 68,
      "adjTempoRank": 170,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "27-8",
      "seed": 2
    },
    "Alabama A&M": {
      "adjDef": 101.7,
      "adjDefRank": 136,
      "adjEM": -9.53,
      "adjOff": 92.2,
      "adjOffRank": 304,
      "adjTempo": 79,
      "adjTempoRank": 1,
      "conference": "SWAC",
      "rank": 262,
      "record": "17-10"
    },
    "Alabama St.": {
      "adjDef": 102.7,
      "adjDefRank": 159,
      "adjEM": -12.62,
      "adjOff": 90.1,
      "adjOffRank": 312,
      "adjTempo": 63.1,
      "adjTempoRank": 305,
      "conference": "SWAC",
      "rank": 272,
      "record": "18-12"
    },
    "Albany": {
      "adjDef": 107.3,
      "adjDefRank": 244,
      "adjEM": -20.29,
      "adjOff": 87,
      "adjOffRank": 321,
      "adjTempo": 62.7,
      "adjTempoRank": 308,
      "conference": "AE",
      "rank": 314,
      "record": "8-20"
    },
    "Alcorn St.": {
      "adjDef": 108.8,
      "adjDefRank": 268,
      "adjEM": -8.71,
      "adjOff": 100.1,
      "adjOffRank": 213,
      "adjTempo": 73,
      "adjTempoRank": 23,
      "conference": "SWAC",
      "finish": "Play-in",
      "rank": 252,
      "record": "20-10",
      "seed": 16
    },
    "American": {
      "adjDef": 105.1,
      "adjDefRank": 209,
      "adjEM": -4.01,
      "adjOff": 101,
      "adjOffRank": 198,
      "adjTempo": 62.9,
      "adjTempoRank": 306,
      "conference": "Pat",
      "rank": 206,
      "record": "18-12"
    },
    "Appalachian St.": {
      "adjDef": 108.3,
      "adjDefRank": 263,
      "adjEM": -6.25,
      "adjOff": 102.1,
      "adjOffRank": 181,
      "adjTempo": 72.6,
      "adjTempoRank": 28,
      "conference": "SC",
      "rank": 229,
      "record": "8-18"
    },
    "Arizona": {
      "adjDef": 98.8,
      "adjDefRank": 82,
      "adjEM": 20.54,
      "adjOff": 119.3,
      "adjOffRank": 2,
      "adjTempo": 71.1,
      "adjTempoRank": 59,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "24-10",
      "seed": 3
    },
    "Arizona St.": {
      "adjDef": 98.1,
      "adjDefRank": 69,
      "adjEM": 10.54,
      "adjOff": 108.6,
      "adjOffRank": 78,
      "adjTempo": 71.7,
      "adjTempoRank": 47,
      "conference": "P10",
      "rank": 69,
      "record": "14-15"
    },
    "Arkansas": {
      "adjDef": 96.2,
      "adjDefRank": 39,
      "adjEM": 12.56,
      "adjOff": 108.8,
      "adjOffRank": 76,
      "adjTempo": 71.9,
      "adjTempoRank": 44,
      "conference": "SEC",
      "rank": 57,
      "record": "14-15"
    },
    "Arkansas Little Rock": {
      "adjDef": 101.1,
      "adjDefRank": 124,
      "adjEM": 5.75,
      "adjOff": 106.8,
      "adjOffRank": 105,
      "adjTempo": 69.1,
      "adjTempoRank": 126,
      "conference": "SB",
      "rank": 97,
      "record": "16-11"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 111.1,
      "adjDefRank": 294,
      "adjEM": -28.85,
      "adjOff": 82.2,
      "adjOffRank": 326,
      "adjTempo": 75.5,
      "adjTempoRank": 5,
      "conference": "SWAC",
      "rank": 324,
      "record": "2-26"
    },
    "Arkansas St.": {
      "adjDef": 103.1,
      "adjDefRank": 165,
      "adjEM": 2.13,
      "adjOff": 105.2,
      "adjOffRank": 129,
      "adjTempo": 64.2,
      "adjTempoRank": 289,
      "conference": "SB",
      "rank": 141,
      "record": "13-16"
    },
    "Army": {
      "adjDef": 110.5,
      "adjDefRank": 289,
      "adjEM": -15.11,
      "adjOff": 95.4,
      "adjOffRank": 280,
      "adjTempo": 68,
      "adjTempoRank": 172,
      "conference": "Pat",
      "rank": 289,
      "record": "10-16"
    },
    "Auburn": {
      "adjDef": 92,
      "adjDefRank": 12,
      "adjEM": 6.61,
      "adjOff": 98.6,
      "adjOffRank": 238,
      "adjTempo": 70.1,
      "adjTempoRank": 90,
      "conference": "SEC",
      "rank": 88,
      "record": "12-16"
    },
    "Austin Peay": {
      "adjDef": 102.4,
      "adjDefRank": 151,
      "adjEM": -2.98,
      "adjOff": 99.4,
      "adjOffRank": 221,
      "adjTempo": 67.3,
      "adjTempoRank": 198,
      "conference": "OVC",
      "rank": 197,
      "record": "11-18"
    },
    "BYU": {
      "adjDef": 97.7,
      "adjDefRank": 63,
      "adjEM": 11.55,
      "adjOff": 109.2,
      "adjOffRank": 72,
      "adjTempo": 65.4,
      "adjTempoRank": 261,
      "conference": "MWC",
      "rank": 63,
      "record": "17-12"
    },
    "Ball St.": {
      "adjDef": 100.6,
      "adjDefRank": 117,
      "adjEM": 12.66,
      "adjOff": 113.3,
      "adjOffRank": 32,
      "adjTempo": 69.1,
      "adjTempoRank": 130,
      "conference": "MAC",
      "rank": 55,
      "record": "23-12"
    },
    "Baylor": {
      "adjDef": 103.5,
      "adjDefRank": 178,
      "adjEM": 4.69,
      "adjOff": 108.2,
      "adjOffRank": 80,
      "adjTempo": 68.3,
      "adjTempoRank": 160,
      "conference": "B12",
      "rank": 106,
      "record": "12-16"
    },
    "Belmont": {
      "adjDef": 113.8,
      "adjDefRank": 311,
      "adjEM": -5,
      "adjOff": 108.8,
      "adjOffRank": 75,
      "adjTempo": 65.2,
      "adjTempoRank": 268,
      "conference": "ASun",
      "rank": 217,
      "record": "11-17"
    },
    "Bethune Cookman": {
      "adjDef": 109.4,
      "adjDefRank": 279,
      "adjEM": -14.58,
      "adjOff": 94.8,
      "adjOffRank": 284,
      "adjTempo": 69.3,
      "adjTempoRank": 118,
      "conference": "MEAC",
      "rank": 285,
      "record": "12-17"
    },
    "Binghamton": {
      "adjDef": 108.1,
      "adjDefRank": 256,
      "adjEM": -15.37,
      "adjOff": 92.7,
      "adjOffRank": 302,
      "adjTempo": 67.2,
      "adjTempoRank": 203,
      "conference": "AE",
      "rank": 292,
      "record": "9-19"
    },
    "Birmingham Southern": {
      "adjDef": 110.6,
      "adjDefRank": 291,
      "adjEM": -16.2,
      "adjOff": 94.4,
      "adjOffRank": 288,
      "adjTempo": 62.4,
      "adjTempoRank": 310,
      "conference": "BSth",
      "rank": 297,
      "record": "11-14"
    },
    "Boise St.": {
      "adjDef": 100.4,
      "adjDefRank": 110,
      "adjEM": -2.21,
      "adjOff": 98.2,
      "adjOffRank": 243,
      "adjTempo": 65.2,
      "adjTempoRank": 270,
      "conference": "WAC",
      "rank": 188,
      "record": "12-17"
    },
    "Boston College": {
      "adjDef": 99.4,
      "adjDefRank": 91,
      "adjEM": 9.97,
      "adjOff": 109.4,
      "adjOffRank": 70,
      "adjTempo": 69.1,
      "adjTempoRank": 128,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 71,
      "record": "20-12",
      "seed": 11
    },
    "Boston University": {
      "adjDef": 104,
      "adjDefRank": 187,
      "adjEM": -2.74,
      "adjOff": 101.3,
      "adjOffRank": 192,
      "adjTempo": 64.5,
      "adjTempoRank": 282,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 194,
      "record": "22-10",
      "seed": 16
    },
    "Bowling Green": {
      "adjDef": 99.6,
      "adjDefRank": 94,
      "adjEM": 11.76,
      "adjOff": 111.3,
      "adjOffRank": 46,
      "adjTempo": 66.6,
      "adjTempoRank": 221,
      "conference": "MAC",
      "rank": 60,
      "record": "23-9"
    },
    "Bradley": {
      "adjDef": 99.9,
      "adjDefRank": 99,
      "adjEM": -4.59,
      "adjOff": 95.3,
      "adjOffRank": 281,
      "adjTempo": 63.3,
      "adjTempoRank": 298,
      "conference": "MVC",
      "rank": 212,
      "record": "9-20"
    },
    "Brown": {
      "adjDef": 109.6,
      "adjDefRank": 284,
      "adjEM": -3.69,
      "adjOff": 105.9,
      "adjOffRank": 115,
      "adjTempo": 72.7,
      "adjTempoRank": 25,
      "conference": "Ivy",
      "rank": 205,
      "record": "16-10"
    },
    "Bucknell": {
      "adjDef": 105.8,
      "adjDefRank": 221,
      "adjEM": -11.27,
      "adjOff": 94.5,
      "adjOffRank": 286,
      "adjTempo": 65.5,
      "adjTempoRank": 258,
      "conference": "Pat",
      "rank": 269,
      "record": "13-16"
    },
    "Buffalo": {
      "adjDef": 101.4,
      "adjDefRank": 129,
      "adjEM": -1.84,
      "adjOff": 99.6,
      "adjOffRank": 219,
      "adjTempo": 67.7,
      "adjTempoRank": 187,
      "conference": "MAC",
      "rank": 184,
      "record": "12-18"
    },
    "Butler": {
      "adjDef": 94.3,
      "adjDefRank": 27,
      "adjEM": 17.63,
      "adjOff": 111.9,
      "adjOffRank": 41,
      "adjTempo": 62.1,
      "adjTempoRank": 316,
      "conference": "Horz",
      "rank": 28,
      "record": "26-6"
    },
    "Cal Poly": {
      "adjDef": 102.6,
      "adjDefRank": 156,
      "adjEM": -0.06,
      "adjOff": 102.5,
      "adjOffRank": 173,
      "adjTempo": 68.3,
      "adjTempoRank": 161,
      "conference": "BW",
      "rank": 159,
      "record": "14-12"
    },
    "Cal St. Fullerton": {
      "adjDef": 109.1,
      "adjDefRank": 273,
      "adjEM": -14.32,
      "adjOff": 94.8,
      "adjOffRank": 283,
      "adjTempo": 65.6,
      "adjTempoRank": 254,
      "conference": "BW",
      "rank": 284,
      "record": "4-22"
    },
    "Cal St. Northridge": {
      "adjDef": 100.4,
      "adjDefRank": 108,
      "adjEM": -1.66,
      "adjOff": 98.7,
      "adjOffRank": 235,
      "adjTempo": 69.8,
      "adjTempoRank": 94,
      "conference": "BW",
      "rank": 182,
      "record": "11-16"
    },
    "California": {
      "adjDef": 93.7,
      "adjDefRank": 25,
      "adjEM": 16.03,
      "adjOff": 109.8,
      "adjOffRank": 66,
      "adjTempo": 66.6,
      "adjTempoRank": 220,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 37,
      "record": "23-9",
      "seed": 6
    },
    "Campbell": {
      "adjDef": 117.6,
      "adjDefRank": 325,
      "adjEM": -16.73,
      "adjOff": 100.9,
      "adjOffRank": 199,
      "adjTempo": 68.4,
      "adjTempoRank": 158,
      "conference": "ASun",
      "rank": 301,
      "record": "7-19"
    },
    "Canisius": {
      "adjDef": 105.6,
      "adjDefRank": 217,
      "adjEM": -9.09,
      "adjOff": 96.5,
      "adjOffRank": 270,
      "adjTempo": 66.2,
      "adjTempoRank": 236,
      "conference": "MAAC",
      "rank": 257,
      "record": "10-20"
    },
    "Centenary": {
      "adjDef": 105.8,
      "adjDefRank": 223,
      "adjEM": -4.4,
      "adjOff": 101.4,
      "adjOffRank": 189,
      "adjTempo": 65.6,
      "adjTempoRank": 257,
      "conference": "ind",
      "rank": 209,
      "record": "10-13"
    },
    "Central Connecticut": {
      "adjDef": 99.7,
      "adjDefRank": 96,
      "adjEM": 0.93,
      "adjOff": 100.6,
      "adjOffRank": 202,
      "adjTempo": 64,
      "adjTempoRank": 291,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 150,
      "record": "27-5",
      "seed": 14
    },
    "Central Michigan": {
      "adjDef": 105.1,
      "adjDefRank": 210,
      "adjEM": -3.52,
      "adjOff": 101.6,
      "adjOffRank": 185,
      "adjTempo": 67.9,
      "adjTempoRank": 175,
      "conference": "MAC",
      "rank": 204,
      "record": "8-19"
    },
    "Charleston Southern": {
      "adjDef": 104.2,
      "adjDefRank": 191,
      "adjEM": -13.46,
      "adjOff": 90.7,
      "adjOffRank": 310,
      "adjTempo": 67.3,
      "adjTempoRank": 199,
      "conference": "BSth",
      "rank": 278,
      "record": "10-17"
    },
    "Charlotte": {
      "adjDef": 98.5,
      "adjDefRank": 77,
      "adjEM": 15.73,
      "adjOff": 114.2,
      "adjOffRank": 23,
      "adjTempo": 66.8,
      "adjTempoRank": 214,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 40,
      "record": "18-12",
      "seed": 9
    },
    "Chattanooga": {
      "adjDef": 101.3,
      "adjDefRank": 127,
      "adjEM": 2.36,
      "adjOff": 103.7,
      "adjOffRank": 151,
      "adjTempo": 69.2,
      "adjTempoRank": 119,
      "conference": "SC",
      "rank": 138,
      "record": "14-14"
    },
    "Chicago St.": {
      "adjDef": 114.1,
      "adjDefRank": 313,
      "adjEM": -24.56,
      "adjOff": 89.5,
      "adjOffRank": 315,
      "adjTempo": 69.5,
      "adjTempoRank": 105,
      "conference": "MCon",
      "rank": 320,
      "record": "1-26"
    },
    "Cincinnati": {
      "adjDef": 87.9,
      "adjDefRank": 3,
      "adjEM": 30.19,
      "adjOff": 118.1,
      "adjOffRank": 7,
      "adjTempo": 67.4,
      "adjTempoRank": 194,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 2,
      "record": "31-4",
      "seed": 1
    },
    "Clemson": {
      "adjDef": 104.4,
      "adjDefRank": 196,
      "adjEM": 2.94,
      "adjOff": 107.3,
      "adjOffRank": 99,
      "adjTempo": 69.4,
      "adjTempoRank": 115,
      "conference": "ACC",
      "rank": 132,
      "record": "13-17"
    },
    "Cleveland St.": {
      "adjDef": 100.3,
      "adjDefRank": 103,
      "adjEM": -2.66,
      "adjOff": 97.6,
      "adjOffRank": 253,
      "adjTempo": 68.7,
      "adjTempoRank": 145,
      "conference": "Horz",
      "rank": 193,
      "record": "12-16"
    },
    "Coastal Carolina": {
      "adjDef": 114.1,
      "adjDefRank": 314,
      "adjEM": -18.19,
      "adjOff": 95.9,
      "adjOffRank": 278,
      "adjTempo": 72.2,
      "adjTempoRank": 37,
      "conference": "BSth",
      "rank": 306,
      "record": "8-20"
    },
    "Colgate": {
      "adjDef": 107.4,
      "adjDefRank": 248,
      "adjEM": -5.62,
      "adjOff": 101.8,
      "adjOffRank": 184,
      "adjTempo": 66.9,
      "adjTempoRank": 210,
      "conference": "Pat",
      "rank": 223,
      "record": "15-11"
    },
    "College of Charleston": {
      "adjDef": 96.6,
      "adjDefRank": 46,
      "adjEM": 8.41,
      "adjOff": 105,
      "adjOffRank": 132,
      "adjTempo": 62.1,
      "adjTempoRank": 315,
      "conference": "SC",
      "rank": 79,
      "record": "20-8"
    },
    "Colorado": {
      "adjDef": 104.6,
      "adjDefRank": 201,
      "adjEM": 6.78,
      "adjOff": 111.3,
      "adjOffRank": 45,
      "adjTempo": 71.9,
      "adjTempoRank": 43,
      "conference": "B12",
      "rank": 87,
      "record": "14-14"
    },
    "Colorado St.": {
      "adjDef": 100.5,
      "adjDefRank": 113,
      "adjEM": 3.34,
      "adjOff": 103.8,
      "adjOffRank": 150,
      "adjTempo": 66.5,
      "adjTempoRank": 224,
      "conference": "MWC",
      "rank": 125,
      "record": "11-18"
    },
    "Columbia": {
      "adjDef": 98.1,
      "adjDefRank": 70,
      "adjEM": -6.93,
      "adjOff": 91.2,
      "adjOffRank": 308,
      "adjTempo": 59.3,
      "adjTempoRank": 323,
      "conference": "Ivy",
      "rank": 239,
      "record": "10-16"
    },
    "Connecticut": {
      "adjDef": 92.8,
      "adjDefRank": 18,
      "adjEM": 19.36,
      "adjOff": 112.2,
      "adjOffRank": 38,
      "adjTempo": 68.7,
      "adjTempoRank": 143,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 18,
      "record": "27-7",
      "seed": 2
    },
    "Coppin St.": {
      "adjDef": 109,
      "adjDefRank": 270,
      "adjEM": -21.03,
      "adjOff": 88,
      "adjOffRank": 319,
      "adjTempo": 64.3,
      "adjTempoRank": 285,
      "conference": "MEAC",
      "rank": 315,
      "record": "5-24"
    },
    "Cornell": {
      "adjDef": 106.6,
      "adjDefRank": 236,
      "adjEM": -17.45,
      "adjOff": 89.2,
      "adjOffRank": 318,
      "adjTempo": 65.1,
      "adjTempoRank": 273,
      "conference": "Ivy",
      "rank": 303,
      "record": "4-22"
    },
    "Creighton": {
      "adjDef": 99.5,
      "adjDefRank": 92,
      "adjEM": 11.82,
      "adjOff": 111.3,
      "adjOffRank": 47,
      "adjTempo": 66.9,
      "adjTempoRank": 213,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 59,
      "record": "23-9",
      "seed": 12
    },
    "Dartmouth": {
      "adjDef": 117.8,
      "adjDefRank": 326,
      "adjEM": -17.66,
      "adjOff": 100.2,
      "adjOffRank": 212,
      "adjTempo": 62.5,
      "adjTempoRank": 309,
      "conference": "Ivy",
      "rank": 304,
      "record": "8-18"
    },
    "Davidson": {
      "adjDef": 97.8,
      "adjDefRank": 64,
      "adjEM": 3.7,
      "adjOff": 101.5,
      "adjOffRank": 187,
      "adjTempo": 65.1,
      "adjTempoRank": 271,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 119,
      "record": "18-10",
      "seed": 13
    },
    "Dayton": {
      "adjDef": 97.4,
      "adjDefRank": 60,
      "adjEM": 15.53,
      "adjOff": 112.9,
      "adjOffRank": 35,
      "adjTempo": 66.6,
      "adjTempoRank": 222,
      "conference": "A10",
      "rank": 42,
      "record": "21-11"
    },
    "DePaul": {
      "adjDef": 101.4,
      "adjDefRank": 128,
      "adjEM": -0.96,
      "adjOff": 100.4,
      "adjOffRank": 206,
      "adjTempo": 71.3,
      "adjTempoRank": 54,
      "conference": "CUSA",
      "rank": 170,
      "record": "9-19"
    },
    "Delaware": {
      "adjDef": 102.4,
      "adjDefRank": 152,
      "adjEM": 0.32,
      "adjOff": 102.7,
      "adjOffRank": 167,
      "adjTempo": 66.4,
      "adjTempoRank": 229,
      "conference": "CAA",
      "rank": 155,
      "record": "14-16"
    },
    "Delaware St.": {
      "adjDef": 106.9,
      "adjDefRank": 238,
      "adjEM": -7.34,
      "adjOff": 99.6,
      "adjOffRank": 218,
      "adjTempo": 62.8,
      "adjTempoRank": 307,
      "conference": "MEAC",
      "rank": 242,
      "record": "15-13"
    },
    "Denver": {
      "adjDef": 108.1,
      "adjDefRank": 257,
      "adjEM": -9.06,
      "adjOff": 99,
      "adjOffRank": 231,
      "adjTempo": 66,
      "adjTempoRank": 240,
      "conference": "SB",
      "rank": 256,
      "record": "7-20"
    },
    "Detroit": {
      "adjDef": 99,
      "adjDefRank": 86,
      "adjEM": 5.01,
      "adjOff": 104,
      "adjOffRank": 145,
      "adjTempo": 63.3,
      "adjTempoRank": 300,
      "conference": "Horz",
      "rank": 102,
      "record": "18-13"
    },
    "Drake": {
      "adjDef": 108.5,
      "adjDefRank": 264,
      "adjEM": -1.41,
      "adjOff": 107.1,
      "adjOffRank": 101,
      "adjTempo": 64.5,
      "adjTempoRank": 283,
      "conference": "MVC",
      "rank": 175,
      "record": "14-15"
    },
    "Drexel": {
      "adjDef": 105.9,
      "adjDefRank": 224,
      "adjEM": -1.45,
      "adjOff": 104.4,
      "adjOffRank": 138,
      "adjTempo": 70.4,
      "adjTempoRank": 78,
      "conference": "CAA",
      "rank": 176,
      "record": "14-14"
    },
    "Duke": {
      "adjDef": 86.8,
      "adjDefRank": 1,
      "adjEM": 34.19,
      "adjOff": 121,
      "adjOffRank": 1,
      "adjTempo": 74.5,
      "adjTempoRank": 8,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 1,
      "record": "31-4",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 105.1,
      "adjDefRank": 211,
      "adjEM": -5.85,
      "adjOff": 99.3,
      "adjOffRank": 227,
      "adjTempo": 66.5,
      "adjTempoRank": 227,
      "conference": "A10",
      "rank": 225,
      "record": "9-19"
    },
    "East Carolina": {
      "adjDef": 104.1,
      "adjDefRank": 189,
      "adjEM": -1.78,
      "adjOff": 102.3,
      "adjOffRank": 178,
      "adjTempo": 65,
      "adjTempoRank": 274,
      "conference": "CUSA",
      "rank": 183,
      "record": "10-18"
    },
    "East Tennessee St.": {
      "adjDef": 99.6,
      "adjDefRank": 95,
      "adjEM": 6.6,
      "adjOff": 106.2,
      "adjOffRank": 112,
      "adjTempo": 73.7,
      "adjTempoRank": 14,
      "conference": "SC",
      "rank": 89,
      "record": "15-10"
    },
    "Eastern Illinois": {
      "adjDef": 108.6,
      "adjDefRank": 266,
      "adjEM": -6.39,
      "adjOff": 102.2,
      "adjOffRank": 180,
      "adjTempo": 70.6,
      "adjTempoRank": 70,
      "conference": "OVC",
      "rank": 233,
      "record": "12-16"
    },
    "Eastern Kentucky": {
      "adjDef": 109.1,
      "adjDefRank": 272,
      "adjEM": -15.71,
      "adjOff": 93.4,
      "adjOffRank": 298,
      "adjTempo": 70.2,
      "adjTempoRank": 84,
      "conference": "OVC",
      "rank": 295,
      "record": "4-20"
    },
    "Eastern Michigan": {
      "adjDef": 115.3,
      "adjDefRank": 321,
      "adjEM": -18.22,
      "adjOff": 97.1,
      "adjOffRank": 260,
      "adjTempo": 67.7,
      "adjTempoRank": 181,
      "conference": "MAC",
      "rank": 307,
      "record": "5-24"
    },
    "Eastern Washington": {
      "adjDef": 100.2,
      "adjDefRank": 102,
      "adjEM": -0.02,
      "adjOff": 100.2,
      "adjOffRank": 209,
      "adjTempo": 68.9,
      "adjTempoRank": 139,
      "conference": "BSky",
      "rank": 158,
      "record": "15-13"
    },
    "Elon": {
      "adjDef": 104.7,
      "adjDefRank": 205,
      "adjEM": -13.55,
      "adjOff": 91.1,
      "adjOffRank": 309,
      "adjTempo": 72.1,
      "adjTempoRank": 40,
      "conference": "BSth",
      "rank": 281,
      "record": "11-16"
    },
    "Evansville": {
      "adjDef": 107.9,
      "adjDefRank": 255,
      "adjEM": -9.89,
      "adjOff": 98,
      "adjOffRank": 248,
      "adjTempo": 69.2,
      "adjTempoRank": 124,
      "conference": "MVC",
      "rank": 265,
      "record": "7-21"
    },
    "FIU": {
      "adjDef": 103,
      "adjDefRank": 163,
      "adjEM": -6.56,
      "adjOff": 96.4,
      "adjOffRank": 271,
      "adjTempo": 64.4,
      "adjTempoRank": 284,
      "conference": "SB",
      "rank": 234,
      "record": "9-20"
    },
    "Fairfield": {
      "adjDef": 103.4,
      "adjDefRank": 177,
      "adjEM": -4.3,
      "adjOff": 99.1,
      "adjOffRank": 228,
      "adjTempo": 71.9,
      "adjTempoRank": 45,
      "conference": "MAAC",
      "rank": 208,
      "record": "12-17"
    },
    "Fairleigh Dickinson": {
      "adjDef": 116.3,
      "adjDefRank": 324,
      "adjEM": -22.73,
      "adjOff": 93.5,
      "adjOffRank": 297,
      "adjTempo": 70.4,
      "adjTempoRank": 79,
      "conference": "NEC",
      "rank": 318,
      "record": "4-25"
    },
    "Florida": {
      "adjDef": 90.4,
      "adjDefRank": 8,
      "adjEM": 24.72,
      "adjOff": 115.1,
      "adjOffRank": 19,
      "adjTempo": 69.6,
      "adjTempoRank": 103,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 7,
      "record": "22-9",
      "seed": 5
    },
    "Florida A&M": {
      "adjDef": 107.3,
      "adjDefRank": 243,
      "adjEM": -13.5,
      "adjOff": 93.8,
      "adjOffRank": 294,
      "adjTempo": 69.4,
      "adjTempoRank": 112,
      "conference": "MEAC",
      "rank": 280,
      "record": "9-19"
    },
    "Florida Atlantic": {
      "adjDef": 102.1,
      "adjDefRank": 142,
      "adjEM": -2.5,
      "adjOff": 99.6,
      "adjOffRank": 220,
      "adjTempo": 70.2,
      "adjTempoRank": 82,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 191,
      "record": "18-12",
      "seed": 15
    },
    "Florida St.": {
      "adjDef": 102.3,
      "adjDefRank": 149,
      "adjEM": 3.54,
      "adjOff": 105.8,
      "adjOffRank": 116,
      "adjTempo": 68.3,
      "adjTempoRank": 162,
      "conference": "ACC",
      "rank": 121,
      "record": "12-17"
    },
    "Fordham": {
      "adjDef": 104.1,
      "adjDefRank": 190,
      "adjEM": -4.78,
      "adjOff": 99.3,
      "adjOffRank": 223,
      "adjTempo": 74.7,
      "adjTempoRank": 6,
      "conference": "A10",
      "rank": 214,
      "record": "8-20"
    },
    "Fresno St.": {
      "adjDef": 96.5,
      "adjDefRank": 45,
      "adjEM": 7.82,
      "adjOff": 104.4,
      "adjOffRank": 140,
      "adjTempo": 72.4,
      "adjTempoRank": 32,
      "conference": "WAC",
      "rank": 82,
      "record": "19-15"
    },
    "Furman": {
      "adjDef": 101.2,
      "adjDefRank": 125,
      "adjEM": -1.01,
      "adjOff": 100.2,
      "adjOffRank": 211,
      "adjTempo": 65.8,
      "adjTempoRank": 247,
      "conference": "SC",
      "rank": 171,
      "record": "13-14"
    },
    "Gardner Webb": {
      "adjDef": 104.3,
      "adjDefRank": 193,
      "adjEM": -2.84,
      "adjOff": 101.4,
      "adjOffRank": 188,
      "adjTempo": 73.6,
      "adjTempoRank": 17,
      "conference": "ind",
      "rank": 195,
      "record": "13-8"
    },
    "George Mason": {
      "adjDef": 102.1,
      "adjDefRank": 143,
      "adjEM": 2.67,
      "adjOff": 104.7,
      "adjOffRank": 135,
      "adjTempo": 67.8,
      "adjTempoRank": 178,
      "conference": "CAA",
      "rank": 133,
      "record": "19-10"
    },
    "George Washington": {
      "adjDef": 105.9,
      "adjDefRank": 225,
      "adjEM": -3.19,
      "adjOff": 102.7,
      "adjOffRank": 169,
      "adjTempo": 70.7,
      "adjTempoRank": 67,
      "conference": "A10",
      "rank": 200,
      "record": "12-16"
    },
    "Georgetown": {
      "adjDef": 94.5,
      "adjDefRank": 29,
      "adjEM": 16.13,
      "adjOff": 110.6,
      "adjOffRank": 54,
      "adjTempo": 71.1,
      "adjTempoRank": 60,
      "conference": "BE",
      "rank": 36,
      "record": "18-11"
    },
    "Georgia": {
      "adjDef": 98.3,
      "adjDefRank": 73,
      "adjEM": 18.44,
      "adjOff": 116.7,
      "adjOffRank": 9,
      "adjTempo": 69.2,
      "adjTempoRank": 121,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 25,
      "record": "22-9",
      "seed": 3
    },
    "Georgia Southern": {
      "adjDef": 104.5,
      "adjDefRank": 199,
      "adjEM": 2.55,
      "adjOff": 107,
      "adjOffRank": 103,
      "adjTempo": 74.4,
      "adjTempoRank": 9,
      "conference": "SC",
      "rank": 136,
      "record": "14-12"
    },
    "Georgia St.": {
      "adjDef": 105.2,
      "adjDefRank": 212,
      "adjEM": 2.6,
      "adjOff": 107.8,
      "adjOffRank": 90,
      "adjTempo": 69.6,
      "adjTempoRank": 100,
      "conference": "ASun",
      "rank": 135,
      "record": "19-11"
    },
    "Georgia Tech": {
      "adjDef": 100.6,
      "adjDefRank": 116,
      "adjEM": 10.5,
      "adjOff": 111.1,
      "adjOffRank": 50,
      "adjTempo": 70.5,
      "adjTempoRank": 74,
      "conference": "ACC",
      "rank": 70,
      "record": "15-16"
    },
    "Gonzaga": {
      "adjDef": 97.1,
      "adjDefRank": 53,
      "adjEM": 18.51,
      "adjOff": 115.6,
      "adjOffRank": 18,
      "adjTempo": 68.5,
      "adjTempoRank": 155,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 24,
      "record": "28-4",
      "seed": 6
    },
    "Grambling St.": {
      "adjDef": 116.2,
      "adjDefRank": 323,
      "adjEM": -18.91,
      "adjOff": 97.3,
      "adjOffRank": 257,
      "adjTempo": 74,
      "adjTempoRank": 12,
      "conference": "SWAC",
      "rank": 309,
      "record": "8-19"
    },
    "Green Bay": {
      "adjDef": 109.6,
      "adjDefRank": 283,
      "adjEM": -4.25,
      "adjOff": 105.3,
      "adjOffRank": 126,
      "adjTempo": 62.3,
      "adjTempoRank": 311,
      "conference": "Horz",
      "rank": 207,
      "record": "9-21"
    },
    "Hampton": {
      "adjDef": 105.9,
      "adjDefRank": 227,
      "adjEM": 3.99,
      "adjOff": 109.9,
      "adjOffRank": 64,
      "adjTempo": 69.1,
      "adjTempoRank": 127,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 116,
      "record": "25-7",
      "seed": 15
    },
    "Hartford": {
      "adjDef": 104.7,
      "adjDefRank": 206,
      "adjEM": -12.84,
      "adjOff": 91.9,
      "adjOffRank": 305,
      "adjTempo": 65.5,
      "adjTempoRank": 259,
      "conference": "AE",
      "rank": 274,
      "record": "14-18"
    },
    "Harvard": {
      "adjDef": 101.1,
      "adjDefRank": 123,
      "adjEM": -6.73,
      "adjOff": 94.4,
      "adjOffRank": 287,
      "adjTempo": 67.5,
      "adjTempoRank": 190,
      "conference": "Ivy",
      "rank": 235,
      "record": "14-12"
    },
    "Hawaii": {
      "adjDef": 97.2,
      "adjDefRank": 55,
      "adjEM": 13.15,
      "adjOff": 110.3,
      "adjOffRank": 58,
      "adjTempo": 65.6,
      "adjTempoRank": 253,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 51,
      "record": "26-6",
      "seed": 10
    },
    "High Point": {
      "adjDef": 113.7,
      "adjDefRank": 310,
      "adjEM": -17.03,
      "adjOff": 96.7,
      "adjOffRank": 265,
      "adjTempo": 67.1,
      "adjTempoRank": 205,
      "conference": "BSth",
      "rank": 302,
      "record": "9-19"
    },
    "Hofstra": {
      "adjDef": 100.3,
      "adjDefRank": 104,
      "adjEM": -5.44,
      "adjOff": 94.8,
      "adjOffRank": 282,
      "adjTempo": 70.8,
      "adjTempoRank": 66,
      "conference": "CAA",
      "rank": 221,
      "record": "12-20"
    },
    "Holy Cross": {
      "adjDef": 98.4,
      "adjDefRank": 75,
      "adjEM": -0.24,
      "adjOff": 98.1,
      "adjOffRank": 245,
      "adjTempo": 62.2,
      "adjTempoRank": 313,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 162,
      "record": "18-15",
      "seed": 16
    },
    "Houston": {
      "adjDef": 103.7,
      "adjDefRank": 182,
      "adjEM": 4.99,
      "adjOff": 108.7,
      "adjOffRank": 77,
      "adjTempo": 66,
      "adjTempoRank": 239,
      "conference": "CUSA",
      "rank": 104,
      "record": "17-15"
    },
    "Howard": {
      "adjDef": 107.5,
      "adjDefRank": 250,
      "adjEM": -5.68,
      "adjOff": 101.8,
      "adjOffRank": 183,
      "adjTempo": 70,
      "adjTempoRank": 91,
      "conference": "MEAC",
      "rank": 224,
      "record": "18-13"
    },
    "IPFW": {
      "adjDef": 112.3,
      "adjDefRank": 303,
      "adjEM": -15.26,
      "adjOff": 97,
      "adjOffRank": 261,
      "adjTempo": 74.1,
      "adjTempoRank": 11,
      "conference": "ind",
      "rank": 291,
      "record": "3-21"
    },
    "IUPUI": {
      "adjDef": 103.3,
      "adjDefRank": 171,
      "adjEM": -0.96,
      "adjOff": 102.3,
      "adjOffRank": 177,
      "adjTempo": 69.4,
      "adjTempoRank": 117,
      "conference": "MCon",
      "rank": 169,
      "record": "13-15"
    },
    "Idaho": {
      "adjDef": 105.7,
      "adjDefRank": 220,
      "adjEM": -13.4,
      "adjOff": 92.3,
      "adjOffRank": 303,
      "adjTempo": 59.3,
      "adjTempoRank": 324,
      "conference": "BW",
      "rank": 277,
      "record": "8-19"
    },
    "Idaho St.": {
      "adjDef": 107.3,
      "adjDefRank": 246,
      "adjEM": -10.74,
      "adjOff": 96.6,
      "adjOffRank": 267,
      "adjTempo": 69,
      "adjTempoRank": 135,
      "conference": "BSky",
      "rank": 267,
      "record": "8-17"
    },
    "Illinois": {
      "adjDef": 92.5,
      "adjDefRank": 16,
      "adjEM": 23.21,
      "adjOff": 115.7,
      "adjOffRank": 17,
      "adjTempo": 68.5,
      "adjTempoRank": 153,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "26-9",
      "seed": 4
    },
    "Illinois Chicago": {
      "adjDef": 105,
      "adjDefRank": 208,
      "adjEM": 3.85,
      "adjOff": 108.9,
      "adjOffRank": 74,
      "adjTempo": 67.5,
      "adjTempoRank": 191,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 118,
      "record": "18-14",
      "seed": 15
    },
    "Illinois St.": {
      "adjDef": 100.9,
      "adjDefRank": 121,
      "adjEM": 2.14,
      "adjOff": 103.1,
      "adjOffRank": 159,
      "adjTempo": 66.1,
      "adjTempoRank": 237,
      "conference": "MVC",
      "rank": 140,
      "record": "17-14"
    },
    "Indiana": {
      "adjDef": 89.2,
      "adjDefRank": 5,
      "adjEM": 24.8,
      "adjOff": 114,
      "adjOffRank": 26,
      "adjTempo": 65.6,
      "adjTempoRank": 255,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 6,
      "record": "25-12",
      "seed": 5
    },
    "Indiana St.": {
      "adjDef": 104.5,
      "adjDefRank": 200,
      "adjEM": -6.13,
      "adjOff": 98.4,
      "adjOffRank": 241,
      "adjTempo": 64.1,
      "adjTempoRank": 290,
      "conference": "MVC",
      "rank": 228,
      "record": "6-22"
    },
    "Iona": {
      "adjDef": 103.6,
      "adjDefRank": 179,
      "adjEM": -5.07,
      "adjOff": 98.5,
      "adjOffRank": 239,
      "adjTempo": 70.9,
      "adjTempoRank": 63,
      "conference": "MAAC",
      "rank": 218,
      "record": "13-17"
    },
    "Iowa": {
      "adjDef": 96.8,
      "adjDefRank": 51,
      "adjEM": 13.66,
      "adjOff": 110.4,
      "adjOffRank": 57,
      "adjTempo": 69,
      "adjTempoRank": 134,
      "conference": "B10",
      "rank": 46,
      "record": "19-16"
    },
    "Iowa St.": {
      "adjDef": 103.9,
      "adjDefRank": 185,
      "adjEM": 4.16,
      "adjOff": 108.1,
      "adjOffRank": 85,
      "adjTempo": 65.2,
      "adjTempoRank": 267,
      "conference": "B12",
      "rank": 113,
      "record": "12-19"
    },
    "Jackson St.": {
      "adjDef": 112.4,
      "adjDefRank": 304,
      "adjEM": -15.44,
      "adjOff": 96.9,
      "adjOffRank": 262,
      "adjTempo": 69.8,
      "adjTempoRank": 98,
      "conference": "SWAC",
      "rank": 293,
      "record": "9-19"
    },
    "Jacksonville": {
      "adjDef": 100.7,
      "adjDefRank": 118,
      "adjEM": -1.98,
      "adjOff": 98.7,
      "adjOffRank": 236,
      "adjTempo": 66.7,
      "adjTempoRank": 217,
      "conference": "ASun",
      "rank": 185,
      "record": "16-12"
    },
    "Jacksonville St.": {
      "adjDef": 109.4,
      "adjDefRank": 280,
      "adjEM": -7.92,
      "adjOff": 101.5,
      "adjOffRank": 186,
      "adjTempo": 66.2,
      "adjTempoRank": 234,
      "conference": "ASun",
      "rank": 245,
      "record": "13-16"
    },
    "James Madison": {
      "adjDef": 107.6,
      "adjDefRank": 253,
      "adjEM": -1.21,
      "adjOff": 106.4,
      "adjOffRank": 110,
      "adjTempo": 65.8,
      "adjTempoRank": 245,
      "conference": "CAA",
      "rank": 173,
      "record": "14-15"
    },
    "Kansas": {
      "adjDef": 89.7,
      "adjDefRank": 6,
      "adjEM": 28.99,
      "adjOff": 118.7,
      "adjOffRank": 5,
      "adjTempo": 77.3,
      "adjTempoRank": 3,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 4,
      "record": "33-4",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 100.3,
      "adjDefRank": 105,
      "adjEM": 3.67,
      "adjOff": 104,
      "adjOffRank": 146,
      "adjTempo": 65.9,
      "adjTempoRank": 244,
      "conference": "B12",
      "rank": 120,
      "record": "13-16"
    },
    "Kent St.": {
      "adjDef": 96.4,
      "adjDefRank": 42,
      "adjEM": 20.39,
      "adjOff": 116.7,
      "adjOffRank": 8,
      "adjTempo": 64.7,
      "adjTempoRank": 278,
      "conference": "MAC",
      "finish": "Elite Eight",
      "rank": 14,
      "record": "29-6",
      "seed": 10
    },
    "Kentucky": {
      "adjDef": 91.2,
      "adjDefRank": 9,
      "adjEM": 22.21,
      "adjOff": 113.4,
      "adjOffRank": 29,
      "adjTempo": 68,
      "adjTempoRank": 169,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "22-10",
      "seed": 4
    },
    "LIU Brooklyn": {
      "adjDef": 122.3,
      "adjDefRank": 327,
      "adjEM": -24.88,
      "adjOff": 97.5,
      "adjOffRank": 255,
      "adjTempo": 74.4,
      "adjTempoRank": 10,
      "conference": "NEC",
      "rank": 321,
      "record": "5-22"
    },
    "LSU": {
      "adjDef": 94.3,
      "adjDefRank": 28,
      "adjEM": 11.1,
      "adjOff": 105.4,
      "adjOffRank": 123,
      "adjTempo": 64.9,
      "adjTempoRank": 275,
      "conference": "SEC",
      "rank": 66,
      "record": "18-15"
    },
    "La Salle": {
      "adjDef": 102.6,
      "adjDefRank": 157,
      "adjEM": 1.32,
      "adjOff": 103.9,
      "adjOffRank": 147,
      "adjTempo": 66,
      "adjTempoRank": 242,
      "conference": "A10",
      "rank": 147,
      "record": "15-17"
    },
    "Lafayette": {
      "adjDef": 109.6,
      "adjDefRank": 281,
      "adjEM": -6.83,
      "adjOff": 102.7,
      "adjOffRank": 168,
      "adjTempo": 70.3,
      "adjTempoRank": 81,
      "conference": "Pat",
      "rank": 238,
      "record": "14-14"
    },
    "Lamar": {
      "adjDef": 106.4,
      "adjDefRank": 230,
      "adjEM": -8.02,
      "adjOff": 98.4,
      "adjOffRank": 242,
      "adjTempo": 69.6,
      "adjTempoRank": 104,
      "conference": "Slnd",
      "rank": 247,
      "record": "15-14"
    },
    "Lehigh": {
      "adjDef": 109.3,
      "adjDefRank": 276,
      "adjEM": -16.21,
      "adjOff": 93.1,
      "adjOffRank": 299,
      "adjTempo": 70.2,
      "adjTempoRank": 85,
      "conference": "Pat",
      "rank": 298,
      "record": "4-23"
    },
    "Liberty": {
      "adjDef": 110.7,
      "adjDefRank": 293,
      "adjEM": -25.14,
      "adjOff": 85.6,
      "adjOffRank": 323,
      "adjTempo": 72.7,
      "adjTempoRank": 27,
      "conference": "BSth",
      "rank": 322,
      "record": "3-24"
    },
    "Lipscomb": {
      "adjDef": 112.9,
      "adjDefRank": 308,
      "adjEM": -19.34,
      "adjOff": 93.6,
      "adjOffRank": 296,
      "adjTempo": 69.2,
      "adjTempoRank": 123,
      "conference": "ind",
      "rank": 310,
      "record": "4-21"
    },
    "Long Beach St.": {
      "adjDef": 104.4,
      "adjDefRank": 197,
      "adjEM": -1.48,
      "adjOff": 102.9,
      "adjOffRank": 162,
      "adjTempo": 70.2,
      "adjTempoRank": 83,
      "conference": "BW",
      "rank": 177,
      "record": "12-17"
    },
    "Louisiana Lafayette": {
      "adjDef": 95,
      "adjDefRank": 31,
      "adjEM": 4.63,
      "adjOff": 99.7,
      "adjOffRank": 217,
      "adjTempo": 70.6,
      "adjTempoRank": 69,
      "conference": "SB",
      "rank": 107,
      "record": "19-11"
    },
    "Louisiana Monroe": {
      "adjDef": 101.9,
      "adjDefRank": 140,
      "adjEM": -2,
      "adjOff": 99.9,
      "adjOffRank": 216,
      "adjTempo": 70.1,
      "adjTempoRank": 87,
      "conference": "Slnd",
      "rank": 187,
      "record": "19-12"
    },
    "Louisiana Tech": {
      "adjDef": 98.6,
      "adjDefRank": 79,
      "adjEM": 11.43,
      "adjOff": 110,
      "adjOffRank": 61,
      "adjTempo": 66.3,
      "adjTempoRank": 231,
      "conference": "WAC",
      "rank": 64,
      "record": "20-10"
    },
    "Louisville": {
      "adjDef": 96.4,
      "adjDefRank": 44,
      "adjEM": 11.74,
      "adjOff": 108.1,
      "adjOffRank": 84,
      "adjTempo": 72.6,
      "adjTempoRank": 29,
      "conference": "CUSA",
      "rank": 61,
      "record": "19-13"
    },
    "Loyola Chicago": {
      "adjDef": 100.3,
      "adjDefRank": 106,
      "adjEM": 3.2,
      "adjOff": 103.5,
      "adjOffRank": 153,
      "adjTempo": 69.5,
      "adjTempoRank": 107,
      "conference": "Horz",
      "rank": 126,
      "record": "17-13"
    },
    "Loyola MD": {
      "adjDef": 106.9,
      "adjDefRank": 240,
      "adjEM": -20.27,
      "adjOff": 86.7,
      "adjOffRank": 322,
      "adjTempo": 65.7,
      "adjTempoRank": 250,
      "conference": "MAAC",
      "rank": 313,
      "record": "5-23"
    },
    "Loyola Marymount": {
      "adjDef": 109,
      "adjDefRank": 269,
      "adjEM": -9.86,
      "adjOff": 99.1,
      "adjOffRank": 230,
      "adjTempo": 68.8,
      "adjTempoRank": 142,
      "conference": "WCC",
      "rank": 264,
      "record": "8-20"
    },
    "Maine": {
      "adjDef": 108.1,
      "adjDefRank": 258,
      "adjEM": -13.91,
      "adjOff": 94.2,
      "adjOffRank": 289,
      "adjTempo": 63.1,
      "adjTempoRank": 304,
      "conference": "AE",
      "rank": 283,
      "record": "12-18"
    },
    "Manhattan": {
      "adjDef": 100.4,
      "adjDefRank": 111,
      "adjEM": 4.18,
      "adjOff": 104.6,
      "adjOffRank": 137,
      "adjTempo": 68.9,
      "adjTempoRank": 141,
      "conference": "MAAC",
      "rank": 112,
      "record": "20-9"
    },
    "Marist": {
      "adjDef": 102.8,
      "adjDefRank": 160,
      "adjEM": 3.98,
      "adjOff": 106.7,
      "adjOffRank": 107,
      "adjTempo": 68.4,
      "adjTempoRank": 157,
      "conference": "MAAC",
      "rank": 117,
      "record": "19-9"
    },
    "Marquette": {
      "adjDef": 91.9,
      "adjDefRank": 11,
      "adjEM": 21.47,
      "adjOff": 113.4,
      "adjOffRank": 30,
      "adjTempo": 64.6,
      "adjTempoRank": 281,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 11,
      "record": "26-7",
      "seed": 5
    },
    "Marshall": {
      "adjDef": 110.5,
      "adjDefRank": 290,
      "adjEM": -0.55,
      "adjOff": 110,
      "adjOffRank": 63,
      "adjTempo": 69.6,
      "adjTempoRank": 102,
      "conference": "MAC",
      "rank": 166,
      "record": "15-15"
    },
    "Maryland": {
      "adjDef": 89.9,
      "adjDefRank": 7,
      "adjEM": 29.25,
      "adjOff": 119.2,
      "adjOffRank": 4,
      "adjTempo": 73.7,
      "adjTempoRank": 15,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 3,
      "record": "32-4",
      "seed": 1
    },
    "Maryland Eastern Shore": {
      "adjDef": 114.9,
      "adjDefRank": 319,
      "adjEM": -16.02,
      "adjOff": 98.8,
      "adjOffRank": 234,
      "adjTempo": 64.3,
      "adjTempoRank": 286,
      "conference": "MEAC",
      "rank": 296,
      "record": "11-18"
    },
    "Massachusetts": {
      "adjDef": 98.8,
      "adjDefRank": 83,
      "adjEM": 3.17,
      "adjOff": 102,
      "adjOffRank": 182,
      "adjTempo": 64.3,
      "adjTempoRank": 288,
      "conference": "A10",
      "rank": 127,
      "record": "13-16"
    },
    "McNeese St.": {
      "adjDef": 97.8,
      "adjDefRank": 66,
      "adjEM": 5.39,
      "adjOff": 103.2,
      "adjOffRank": 157,
      "adjTempo": 66.2,
      "adjTempoRank": 235,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 100,
      "record": "20-9",
      "seed": 14
    },
    "Memphis": {
      "adjDef": 95.8,
      "adjDefRank": 35,
      "adjEM": 16.69,
      "adjOff": 112.5,
      "adjOffRank": 37,
      "adjTempo": 70.8,
      "adjTempoRank": 65,
      "conference": "CUSA",
      "rank": 33,
      "record": "27-9"
    },
    "Mercer": {
      "adjDef": 111.8,
      "adjDefRank": 301,
      "adjEM": -15.61,
      "adjOff": 96.2,
      "adjOffRank": 275,
      "adjTempo": 68.5,
      "adjTempoRank": 152,
      "conference": "ASun",
      "rank": 294,
      "record": "5-23"
    },
    "Miami FL": {
      "adjDef": 94.7,
      "adjDefRank": 30,
      "adjEM": 15.77,
      "adjOff": 110.5,
      "adjOffRank": 56,
      "adjTempo": 69.1,
      "adjTempoRank": 129,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 39,
      "record": "24-8",
      "seed": 5
    },
    "Miami OH": {
      "adjDef": 103.3,
      "adjDefRank": 169,
      "adjEM": 2.15,
      "adjOff": 105.4,
      "adjOffRank": 124,
      "adjTempo": 60.5,
      "adjTempoRank": 321,
      "conference": "MAC",
      "rank": 139,
      "record": "13-18"
    },
    "Michigan": {
      "adjDef": 102.3,
      "adjDefRank": 150,
      "adjEM": 3.15,
      "adjOff": 105.4,
      "adjOffRank": 122,
      "adjTempo": 65.6,
      "adjTempoRank": 256,
      "conference": "B10",
      "rank": 128,
      "record": "11-18"
    },
    "Michigan St.": {
      "adjDef": 96.2,
      "adjDefRank": 38,
      "adjEM": 16.86,
      "adjOff": 113,
      "adjOffRank": 34,
      "adjTempo": 63.3,
      "adjTempoRank": 297,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 32,
      "record": "19-12",
      "seed": 10
    },
    "Middle Tennessee": {
      "adjDef": 100.2,
      "adjDefRank": 101,
      "adjEM": -3.38,
      "adjOff": 96.8,
      "adjOffRank": 264,
      "adjTempo": 66.1,
      "adjTempoRank": 238,
      "conference": "SB",
      "rank": 202,
      "record": "13-15"
    },
    "Milwaukee": {
      "adjDef": 98.5,
      "adjDefRank": 78,
      "adjEM": 6.28,
      "adjOff": 104.8,
      "adjOffRank": 133,
      "adjTempo": 71.5,
      "adjTempoRank": 52,
      "conference": "Horz",
      "rank": 93,
      "record": "13-13"
    },
    "Minnesota": {
      "adjDef": 99,
      "adjDefRank": 85,
      "adjEM": 14.54,
      "adjOff": 113.5,
      "adjOffRank": 28,
      "adjTempo": 68.9,
      "adjTempoRank": 137,
      "conference": "B10",
      "rank": 43,
      "record": "18-13"
    },
    "Mississippi": {
      "adjDef": 96.7,
      "adjDefRank": 48,
      "adjEM": 16.68,
      "adjOff": 113.3,
      "adjOffRank": 31,
      "adjTempo": 64.7,
      "adjTempoRank": 279,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 34,
      "record": "19-11",
      "seed": 9
    },
    "Mississippi St.": {
      "adjDef": 93.1,
      "adjDefRank": 20,
      "adjEM": 18.25,
      "adjOff": 111.4,
      "adjOffRank": 43,
      "adjTempo": 67.9,
      "adjTempoRank": 174,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 26,
      "record": "27-8",
      "seed": 3
    },
    "Mississippi Valley St.": {
      "adjDef": 114.8,
      "adjDefRank": 318,
      "adjEM": -13.67,
      "adjOff": 101.1,
      "adjOffRank": 195,
      "adjTempo": 69.4,
      "adjTempoRank": 116,
      "conference": "SWAC",
      "rank": 282,
      "record": "11-16"
    },
    "Missouri": {
      "adjDef": 98.7,
      "adjDefRank": 81,
      "adjEM": 17.49,
      "adjOff": 116.2,
      "adjOffRank": 12,
      "adjTempo": 70.1,
      "adjTempoRank": 89,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 29,
      "record": "24-12",
      "seed": 12
    },
    "Monmouth": {
      "adjDef": 103,
      "adjDefRank": 162,
      "adjEM": -6.33,
      "adjOff": 96.6,
      "adjOffRank": 266,
      "adjTempo": 65.7,
      "adjTempoRank": 249,
      "conference": "NEC",
      "rank": 231,
      "record": "18-12"
    },
    "Montana": {
      "adjDef": 106.9,
      "adjDefRank": 239,
      "adjEM": -4.51,
      "adjOff": 102.4,
      "adjOffRank": 176,
      "adjTempo": 68.5,
      "adjTempoRank": 156,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 210,
      "record": "14-15",
      "seed": 15
    },
    "Montana St.": {
      "adjDef": 104.4,
      "adjDefRank": 198,
      "adjEM": 2.97,
      "adjOff": 107.4,
      "adjOffRank": 97,
      "adjTempo": 63.5,
      "adjTempoRank": 295,
      "conference": "BSky",
      "rank": 131,
      "record": "18-10"
    },
    "Morehead St.": {
      "adjDef": 106.4,
      "adjDefRank": 233,
      "adjEM": -0.91,
      "adjOff": 105.5,
      "adjOffRank": 121,
      "adjTempo": 68.1,
      "adjTempoRank": 168,
      "conference": "OVC",
      "rank": 168,
      "record": "15-11"
    },
    "Morgan St.": {
      "adjDef": 111.3,
      "adjDefRank": 296,
      "adjEM": -21.23,
      "adjOff": 90,
      "adjOffRank": 313,
      "adjTempo": 70.1,
      "adjTempoRank": 86,
      "conference": "MEAC",
      "rank": 316,
      "record": "3-25"
    },
    "Morris Brown": {
      "adjDef": 115.1,
      "adjDefRank": 320,
      "adjEM": -29.95,
      "adjOff": 85.1,
      "adjOffRank": 324,
      "adjTempo": 67.6,
      "adjTempoRank": 188,
      "conference": "ind",
      "rank": 326,
      "record": "4-23"
    },
    "Mount St. Mary's": {
      "adjDef": 114.5,
      "adjDefRank": 316,
      "adjEM": -29.36,
      "adjOff": 85.1,
      "adjOffRank": 325,
      "adjTempo": 67,
      "adjTempoRank": 207,
      "conference": "NEC",
      "rank": 325,
      "record": "3-24"
    },
    "Murray St.": {
      "adjDef": 105.5,
      "adjDefRank": 215,
      "adjEM": 1.35,
      "adjOff": 106.8,
      "adjOffRank": 106,
      "adjTempo": 69.2,
      "adjTempoRank": 122,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 146,
      "record": "17-13",
      "seed": 14
    },
    "N.C. State": {
      "adjDef": 94,
      "adjDefRank": 26,
      "adjEM": 19.15,
      "adjOff": 113.2,
      "adjOffRank": 33,
      "adjTempo": 67.5,
      "adjTempoRank": 193,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 21,
      "record": "23-11",
      "seed": 7
    },
    "Navy": {
      "adjDef": 105.6,
      "adjDefRank": 216,
      "adjEM": -11.52,
      "adjOff": 94.1,
      "adjOffRank": 291,
      "adjTempo": 70.8,
      "adjTempoRank": 64,
      "conference": "Pat",
      "rank": 270,
      "record": "9-20"
    },
    "Nebraska": {
      "adjDef": 100.8,
      "adjDefRank": 119,
      "adjEM": 5,
      "adjOff": 105.8,
      "adjOffRank": 118,
      "adjTempo": 66.5,
      "adjTempoRank": 225,
      "conference": "B12",
      "rank": 103,
      "record": "13-15"
    },
    "Nevada": {
      "adjDef": 100.5,
      "adjDefRank": 115,
      "adjEM": 3.41,
      "adjOff": 103.9,
      "adjOffRank": 148,
      "adjTempo": 71.6,
      "adjTempoRank": 50,
      "conference": "WAC",
      "rank": 123,
      "record": "16-13"
    },
    "New Hampshire": {
      "adjDef": 111.8,
      "adjDefRank": 300,
      "adjEM": -14.87,
      "adjOff": 96.9,
      "adjOffRank": 263,
      "adjTempo": 70.5,
      "adjTempoRank": 73,
      "conference": "AE",
      "rank": 287,
      "record": "10-17"
    },
    "New Mexico": {
      "adjDef": 103.9,
      "adjDefRank": 184,
      "adjEM": 6.36,
      "adjOff": 110.3,
      "adjOffRank": 59,
      "adjTempo": 66.5,
      "adjTempoRank": 226,
      "conference": "MWC",
      "rank": 92,
      "record": "16-14"
    },
    "New Mexico St.": {
      "adjDef": 103.4,
      "adjDefRank": 176,
      "adjEM": 5.87,
      "adjOff": 109.3,
      "adjOffRank": 71,
      "adjTempo": 68.3,
      "adjTempoRank": 159,
      "conference": "SB",
      "rank": 96,
      "record": "18-11"
    },
    "New Orleans": {
      "adjDef": 99.5,
      "adjDefRank": 93,
      "adjEM": -1.15,
      "adjOff": 98.4,
      "adjOffRank": 240,
      "adjTempo": 68,
      "adjTempoRank": 173,
      "conference": "SB",
      "rank": 172,
      "record": "14-14"
    },
    "Niagara": {
      "adjDef": 108.2,
      "adjDefRank": 260,
      "adjEM": -1.64,
      "adjOff": 106.6,
      "adjOffRank": 109,
      "adjTempo": 69.5,
      "adjTempoRank": 109,
      "conference": "MAAC",
      "rank": 180,
      "record": "18-14"
    },
    "Nicholls St.": {
      "adjDef": 113.3,
      "adjDefRank": 309,
      "adjEM": -26.22,
      "adjOff": 87.1,
      "adjOffRank": 320,
      "adjTempo": 68.9,
      "adjTempoRank": 138,
      "conference": "Slnd",
      "rank": 323,
      "record": "1-25"
    },
    "Norfolk St.": {
      "adjDef": 108.7,
      "adjDefRank": 267,
      "adjEM": -15.02,
      "adjOff": 93.7,
      "adjOffRank": 295,
      "adjTempo": 70.5,
      "adjTempoRank": 75,
      "conference": "MEAC",
      "rank": 288,
      "record": "10-19"
    },
    "North Carolina": {
      "adjDef": 103.2,
      "adjDefRank": 168,
      "adjEM": 2.53,
      "adjOff": 105.8,
      "adjOffRank": 119,
      "adjTempo": 70,
      "adjTempoRank": 92,
      "conference": "ACC",
      "rank": 137,
      "record": "8-20"
    },
    "North Carolina A&T": {
      "adjDef": 104.7,
      "adjDefRank": 204,
      "adjEM": -11.86,
      "adjOff": 92.8,
      "adjOffRank": 301,
      "adjTempo": 70.4,
      "adjTempoRank": 77,
      "conference": "MEAC",
      "rank": 271,
      "record": "10-17"
    },
    "North Texas": {
      "adjDef": 108.3,
      "adjDefRank": 262,
      "adjEM": -0.21,
      "adjOff": 108.1,
      "adjOffRank": 83,
      "adjTempo": 72,
      "adjTempoRank": 42,
      "conference": "SB",
      "rank": 161,
      "record": "13-14"
    },
    "Northeastern": {
      "adjDef": 111.6,
      "adjDefRank": 298,
      "adjEM": -13.5,
      "adjOff": 98.1,
      "adjOffRank": 247,
      "adjTempo": 72.3,
      "adjTempoRank": 34,
      "conference": "AE",
      "rank": 279,
      "record": "7-21"
    },
    "Northern Arizona": {
      "adjDef": 101.7,
      "adjDefRank": 135,
      "adjEM": -5.36,
      "adjOff": 96.3,
      "adjOffRank": 273,
      "adjTempo": 67.3,
      "adjTempoRank": 196,
      "conference": "BSky",
      "rank": 220,
      "record": "12-14"
    },
    "Northern Illinois": {
      "adjDef": 107.6,
      "adjDefRank": 251,
      "adjEM": -2,
      "adjOff": 105.6,
      "adjOffRank": 120,
      "adjTempo": 72.1,
      "adjTempoRank": 38,
      "conference": "MAC",
      "rank": 186,
      "record": "12-16"
    },
    "Northern Iowa": {
      "adjDef": 109.7,
      "adjDefRank": 285,
      "adjEM": -1.49,
      "adjOff": 108.2,
      "adjOffRank": 82,
      "adjTempo": 66.9,
      "adjTempoRank": 212,
      "conference": "MVC",
      "rank": 178,
      "record": "14-15"
    },
    "Northwestern": {
      "adjDef": 93.4,
      "adjDefRank": 21,
      "adjEM": 6.55,
      "adjOff": 99.9,
      "adjOffRank": 215,
      "adjTempo": 61.6,
      "adjTempoRank": 317,
      "conference": "B10",
      "rank": 90,
      "record": "16-13"
    },
    "Northwestern St.": {
      "adjDef": 107.6,
      "adjDefRank": 252,
      "adjEM": -7.13,
      "adjOff": 100.5,
      "adjOffRank": 203,
      "adjTempo": 70.6,
      "adjTempoRank": 72,
      "conference": "Slnd",
      "rank": 240,
      "record": "12-18"
    },
    "Notre Dame": {
      "adjDef": 96.8,
      "adjDefRank": 52,
      "adjEM": 19.27,
      "adjOff": 116.1,
      "adjOffRank": 14,
      "adjTempo": 68.6,
      "adjTempoRank": 149,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 19,
      "record": "21-11",
      "seed": 8
    },
    "Oakland": {
      "adjDef": 105.7,
      "adjDefRank": 219,
      "adjEM": 1.91,
      "adjOff": 107.6,
      "adjOffRank": 95,
      "adjTempo": 70.3,
      "adjTempoRank": 80,
      "conference": "MCon",
      "rank": 143,
      "record": "16-13"
    },
    "Ohio": {
      "adjDef": 104.2,
      "adjDefRank": 192,
      "adjEM": 4.09,
      "adjOff": 108.2,
      "adjOffRank": 79,
      "adjTempo": 67.8,
      "adjTempoRank": 176,
      "conference": "MAC",
      "rank": 115,
      "record": "17-11"
    },
    "Ohio St.": {
      "adjDef": 96.6,
      "adjDefRank": 47,
      "adjEM": 19.76,
      "adjOff": 116.4,
      "adjOffRank": 11,
      "adjTempo": 66.8,
      "adjTempoRank": 215,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 17,
      "record": "24-8",
      "seed": 4
    },
    "Oklahoma": {
      "adjDef": 88.9,
      "adjDefRank": 4,
      "adjEM": 26.04,
      "adjOff": 114.9,
      "adjOffRank": 20,
      "adjTempo": 66.5,
      "adjTempoRank": 228,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 5,
      "record": "31-5",
      "seed": 2
    },
    "Oklahoma St.": {
      "adjDef": 92.1,
      "adjDefRank": 13,
      "adjEM": 15.8,
      "adjOff": 107.9,
      "adjOffRank": 88,
      "adjTempo": 66,
      "adjTempoRank": 241,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 38,
      "record": "23-9",
      "seed": 7
    },
    "Old Dominion": {
      "adjDef": 102.9,
      "adjDefRank": 161,
      "adjEM": -0.43,
      "adjOff": 102.5,
      "adjOffRank": 175,
      "adjTempo": 64.8,
      "adjTempoRank": 277,
      "conference": "CAA",
      "rank": 164,
      "record": "13-16"
    },
    "Oral Roberts": {
      "adjDef": 101,
      "adjDefRank": 122,
      "adjEM": 4.33,
      "adjOff": 105.4,
      "adjOffRank": 125,
      "adjTempo": 69.4,
      "adjTempoRank": 111,
      "conference": "MCon",
      "rank": 109,
      "record": "17-13"
    },
    "Oregon": {
      "adjDef": 96.3,
      "adjDefRank": 40,
      "adjEM": 22.97,
      "adjOff": 119.2,
      "adjOffRank": 3,
      "adjTempo": 71.1,
      "adjTempoRank": 61,
      "conference": "P10",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "26-9",
      "seed": 2
    },
    "Oregon St.": {
      "adjDef": 100,
      "adjDefRank": 100,
      "adjEM": 3.36,
      "adjOff": 103.4,
      "adjOffRank": 155,
      "adjTempo": 61.3,
      "adjTempoRank": 318,
      "conference": "P10",
      "rank": 124,
      "record": "10-17"
    },
    "Pacific": {
      "adjDef": 103.8,
      "adjDefRank": 183,
      "adjEM": 5.74,
      "adjOff": 109.6,
      "adjOffRank": 69,
      "adjTempo": 65.2,
      "adjTempoRank": 265,
      "conference": "BW",
      "rank": 98,
      "record": "18-10"
    },
    "Penn": {
      "adjDef": 98,
      "adjDefRank": 68,
      "adjEM": 12.23,
      "adjOff": 110.2,
      "adjOffRank": 60,
      "adjTempo": 65.6,
      "adjTempoRank": 252,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 58,
      "record": "25-7",
      "seed": 11
    },
    "Penn St.": {
      "adjDef": 102.4,
      "adjDefRank": 153,
      "adjEM": -3.16,
      "adjOff": 99.3,
      "adjOffRank": 225,
      "adjTempo": 67.7,
      "adjTempoRank": 180,
      "conference": "B10",
      "rank": 198,
      "record": "7-21"
    },
    "Pepperdine": {
      "adjDef": 99.3,
      "adjDefRank": 89,
      "adjEM": 11.72,
      "adjOff": 111.1,
      "adjOffRank": 49,
      "adjTempo": 72.4,
      "adjTempoRank": 33,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 62,
      "record": "21-9",
      "seed": 10
    },
    "Pittsburgh": {
      "adjDef": 92.2,
      "adjDefRank": 15,
      "adjEM": 19.94,
      "adjOff": 112.1,
      "adjOffRank": 39,
      "adjTempo": 63.8,
      "adjTempoRank": 293,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 16,
      "record": "29-6",
      "seed": 3
    },
    "Portland": {
      "adjDef": 106.4,
      "adjDefRank": 232,
      "adjEM": -9.16,
      "adjOff": 97.3,
      "adjOffRank": 259,
      "adjTempo": 73.1,
      "adjTempoRank": 22,
      "conference": "WCC",
      "rank": 258,
      "record": "6-24"
    },
    "Portland St.": {
      "adjDef": 112.7,
      "adjDefRank": 305,
      "adjEM": -8.1,
      "adjOff": 104.6,
      "adjOffRank": 136,
      "adjTempo": 69.1,
      "adjTempoRank": 131,
      "conference": "BSky",
      "rank": 249,
      "record": "11-16"
    },
    "Prairie View A&M": {
      "adjDef": 112.9,
      "adjDefRank": 307,
      "adjEM": -23.35,
      "adjOff": 89.6,
      "adjOffRank": 314,
      "adjTempo": 73.9,
      "adjTempoRank": 13,
      "conference": "SWAC",
      "rank": 319,
      "record": "9-19"
    },
    "Princeton": {
      "adjDef": 102.2,
      "adjDefRank": 147,
      "adjEM": 1.47,
      "adjOff": 103.7,
      "adjOffRank": 152,
      "adjTempo": 57.7,
      "adjTempoRank": 325,
      "conference": "Ivy",
      "rank": 145,
      "record": "15-12"
    },
    "Providence": {
      "adjDef": 96.7,
      "adjDefRank": 49,
      "adjEM": 6.19,
      "adjOff": 102.9,
      "adjOffRank": 165,
      "adjTempo": 71.1,
      "adjTempoRank": 58,
      "conference": "BE",
      "rank": 94,
      "record": "15-16"
    },
    "Purdue": {
      "adjDef": 102.2,
      "adjDefRank": 144,
      "adjEM": 7.6,
      "adjOff": 109.8,
      "adjOffRank": 67,
      "adjTempo": 68,
      "adjTempoRank": 171,
      "conference": "B10",
      "rank": 83,
      "record": "13-18"
    },
    "Quinnipiac": {
      "adjDef": 110.1,
      "adjDefRank": 287,
      "adjEM": -9.27,
      "adjOff": 100.8,
      "adjOffRank": 200,
      "adjTempo": 74.5,
      "adjTempoRank": 7,
      "conference": "NEC",
      "rank": 259,
      "record": "14-16"
    },
    "Radford": {
      "adjDef": 100.9,
      "adjDefRank": 120,
      "adjEM": -2.88,
      "adjOff": 98,
      "adjOffRank": 249,
      "adjTempo": 69.8,
      "adjTempoRank": 97,
      "conference": "BSth",
      "rank": 196,
      "record": "14-16"
    },
    "Rhode Island": {
      "adjDef": 104.4,
      "adjDefRank": 194,
      "adjEM": -6.8,
      "adjOff": 97.6,
      "adjOffRank": 254,
      "adjTempo": 64.7,
      "adjTempoRank": 280,
      "conference": "A10",
      "rank": 237,
      "record": "8-20"
    },
    "Rice": {
      "adjDef": 101.4,
      "adjDefRank": 130,
      "adjEM": -3.43,
      "adjOff": 98,
      "adjOffRank": 250,
      "adjTempo": 67.7,
      "adjTempoRank": 183,
      "conference": "WAC",
      "rank": 203,
      "record": "10-19"
    },
    "Richmond": {
      "adjDef": 95.8,
      "adjDefRank": 34,
      "adjEM": 8.31,
      "adjOff": 104.1,
      "adjOffRank": 144,
      "adjTempo": 60.8,
      "adjTempoRank": 319,
      "conference": "A10",
      "rank": 80,
      "record": "22-14"
    },
    "Rider": {
      "adjDef": 99.9,
      "adjDefRank": 98,
      "adjEM": 0.35,
      "adjOff": 100.2,
      "adjOffRank": 207,
      "adjTempo": 68.5,
      "adjTempoRank": 154,
      "conference": "MAAC",
      "rank": 154,
      "record": "17-11"
    },
    "Robert Morris": {
      "adjDef": 114.5,
      "adjDefRank": 317,
      "adjEM": -15.17,
      "adjOff": 99.3,
      "adjOffRank": 224,
      "adjTempo": 65.8,
      "adjTempoRank": 248,
      "conference": "NEC",
      "rank": 290,
      "record": "12-18"
    },
    "Rutgers": {
      "adjDef": 93.1,
      "adjDefRank": 19,
      "adjEM": 4.87,
      "adjOff": 98,
      "adjOffRank": 251,
      "adjTempo": 69.6,
      "adjTempoRank": 101,
      "conference": "BE",
      "rank": 105,
      "record": "18-13"
    },
    "SMU": {
      "adjDef": 103.2,
      "adjDefRank": 167,
      "adjEM": 4.44,
      "adjOff": 107.6,
      "adjOffRank": 94,
      "adjTempo": 67.7,
      "adjTempoRank": 182,
      "conference": "WAC",
      "rank": 108,
      "record": "15-14"
    },
    "Sacramento St.": {
      "adjDef": 109.6,
      "adjDefRank": 282,
      "adjEM": -13.03,
      "adjOff": 96.6,
      "adjOffRank": 269,
      "adjTempo": 73.3,
      "adjTempoRank": 19,
      "conference": "BSky",
      "rank": 276,
      "record": "7-19"
    },
    "Sacred Heart": {
      "adjDef": 112.9,
      "adjDefRank": 306,
      "adjEM": -16.6,
      "adjOff": 96.3,
      "adjOffRank": 272,
      "adjTempo": 71.7,
      "adjTempoRank": 49,
      "conference": "NEC",
      "rank": 299,
      "record": "8-20"
    },
    "Saint Joseph's": {
      "adjDef": 103.6,
      "adjDefRank": 180,
      "adjEM": 10.81,
      "adjOff": 114.4,
      "adjOffRank": 22,
      "adjTempo": 67.7,
      "adjTempoRank": 184,
      "conference": "A10",
      "rank": 68,
      "record": "19-12"
    },
    "Saint Louis": {
      "adjDef": 91.2,
      "adjDefRank": 10,
      "adjEM": 6.91,
      "adjOff": 98.1,
      "adjOffRank": 244,
      "adjTempo": 65.3,
      "adjTempoRank": 262,
      "conference": "CUSA",
      "rank": 86,
      "record": "15-16"
    },
    "Saint Mary's": {
      "adjDef": 97.1,
      "adjDefRank": 54,
      "adjEM": -5.85,
      "adjOff": 91.3,
      "adjOffRank": 307,
      "adjTempo": 65.2,
      "adjTempoRank": 269,
      "conference": "WCC",
      "rank": 226,
      "record": "8-20"
    },
    "Saint Peter's": {
      "adjDef": 111.6,
      "adjDefRank": 299,
      "adjEM": -18.57,
      "adjOff": 93,
      "adjOffRank": 300,
      "adjTempo": 71.2,
      "adjTempoRank": 57,
      "conference": "MAAC",
      "rank": 308,
      "record": "4-24"
    },
    "Sam Houston St.": {
      "adjDef": 103.4,
      "adjDefRank": 173,
      "adjEM": -3.21,
      "adjOff": 100.2,
      "adjOffRank": 210,
      "adjTempo": 72.7,
      "adjTempoRank": 26,
      "conference": "Slnd",
      "rank": 201,
      "record": "14-14"
    },
    "Samford": {
      "adjDef": 104,
      "adjDefRank": 186,
      "adjEM": -1.49,
      "adjOff": 102.5,
      "adjOffRank": 172,
      "adjTempo": 56.8,
      "adjTempoRank": 326,
      "conference": "ASun",
      "rank": 179,
      "record": "15-14"
    },
    "San Diego": {
      "adjDef": 102.5,
      "adjDefRank": 154,
      "adjEM": 5.21,
      "adjOff": 107.7,
      "adjOffRank": 92,
      "adjTempo": 68.9,
      "adjTempoRank": 140,
      "conference": "WCC",
      "rank": 101,
      "record": "14-13"
    },
    "San Diego St.": {
      "adjDef": 97.6,
      "adjDefRank": 62,
      "adjEM": 9.29,
      "adjOff": 106.9,
      "adjOffRank": 104,
      "adjTempo": 70,
      "adjTempoRank": 93,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 74,
      "record": "21-12",
      "seed": 13
    },
    "San Francisco": {
      "adjDef": 102.7,
      "adjDefRank": 158,
      "adjEM": 0.21,
      "adjOff": 102.9,
      "adjOffRank": 164,
      "adjTempo": 67.8,
      "adjTempoRank": 179,
      "conference": "WCC",
      "rank": 156,
      "record": "12-15"
    },
    "San Jose St.": {
      "adjDef": 107.1,
      "adjDefRank": 241,
      "adjEM": -8.43,
      "adjOff": 98.7,
      "adjOffRank": 237,
      "adjTempo": 63.2,
      "adjTempoRank": 302,
      "conference": "WAC",
      "rank": 251,
      "record": "9-20"
    },
    "Santa Clara": {
      "adjDef": 105.6,
      "adjDefRank": 218,
      "adjEM": -4.55,
      "adjOff": 101.1,
      "adjOffRank": 196,
      "adjTempo": 66.3,
      "adjTempoRank": 232,
      "conference": "WCC",
      "rank": 211,
      "record": "12-15"
    },
    "Savannah St.": {
      "adjDef": 109.2,
      "adjDefRank": 274,
      "adjEM": -31.15,
      "adjOff": 78.1,
      "adjOffRank": 327,
      "adjTempo": 66.4,
      "adjTempoRank": 230,
      "conference": "ind",
      "rank": 327,
      "record": "2-24"
    },
    "Seton Hall": {
      "adjDef": 97.9,
      "adjDefRank": 67,
      "adjEM": 7.18,
      "adjOff": 105,
      "adjOffRank": 131,
      "adjTempo": 68.6,
      "adjTempoRank": 148,
      "conference": "BE",
      "rank": 84,
      "record": "11-18"
    },
    "Siena": {
      "adjDef": 102,
      "adjDefRank": 141,
      "adjEM": -1.21,
      "adjOff": 100.8,
      "adjOffRank": 201,
      "adjTempo": 66.5,
      "adjTempoRank": 223,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 174,
      "record": "17-19",
      "seed": 16
    },
    "South Alabama": {
      "adjDef": 105,
      "adjDefRank": 207,
      "adjEM": -9,
      "adjOff": 96,
      "adjOffRank": 277,
      "adjTempo": 66.8,
      "adjTempoRank": 216,
      "conference": "SB",
      "rank": 255,
      "record": "5-21"
    },
    "South Carolina": {
      "adjDef": 87.9,
      "adjDefRank": 2,
      "adjEM": 19.18,
      "adjOff": 107,
      "adjOffRank": 102,
      "adjTempo": 65.3,
      "adjTempoRank": 263,
      "conference": "SEC",
      "rank": 20,
      "record": "21-15"
    },
    "South Carolina St.": {
      "adjDef": 106.7,
      "adjDefRank": 237,
      "adjEM": -10.49,
      "adjOff": 96.2,
      "adjOffRank": 274,
      "adjTempo": 68.1,
      "adjTempoRank": 165,
      "conference": "MEAC",
      "rank": 266,
      "record": "14-15"
    },
    "South Florida": {
      "adjDef": 98.4,
      "adjDefRank": 76,
      "adjEM": 9.43,
      "adjOff": 107.9,
      "adjOffRank": 87,
      "adjTempo": 71.8,
      "adjTempoRank": 46,
      "conference": "CUSA",
      "rank": 73,
      "record": "19-13"
    },
    "Southeast Missouri St.": {
      "adjDef": 114,
      "adjDefRank": 312,
      "adjEM": -12.66,
      "adjOff": 101.4,
      "adjOffRank": 190,
      "adjTempo": 67.4,
      "adjTempoRank": 195,
      "conference": "OVC",
      "rank": 273,
      "record": "4-22"
    },
    "Southeastern Louisiana": {
      "adjDef": 105.9,
      "adjDefRank": 226,
      "adjEM": -16.65,
      "adjOff": 89.3,
      "adjOffRank": 317,
      "adjTempo": 60.5,
      "adjTempoRank": 322,
      "conference": "Slnd",
      "rank": 300,
      "record": "6-20"
    },
    "Southern": {
      "adjDef": 115.5,
      "adjDefRank": 322,
      "adjEM": -21.56,
      "adjOff": 93.9,
      "adjOffRank": 292,
      "adjTempo": 72.5,
      "adjTempoRank": 30,
      "conference": "SWAC",
      "rank": 317,
      "record": "7-19"
    },
    "Southern Illinois": {
      "adjDef": 95.7,
      "adjDefRank": 33,
      "adjEM": 13.45,
      "adjOff": 109.2,
      "adjOffRank": 73,
      "adjTempo": 70.6,
      "adjTempoRank": 71,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 49,
      "record": "28-8",
      "seed": 11
    },
    "Southern Miss": {
      "adjDef": 98.1,
      "adjDefRank": 71,
      "adjEM": -0.45,
      "adjOff": 97.7,
      "adjOffRank": 252,
      "adjTempo": 64.3,
      "adjTempoRank": 287,
      "conference": "CUSA",
      "rank": 165,
      "record": "9-17"
    },
    "Southern Utah": {
      "adjDef": 105.2,
      "adjDefRank": 213,
      "adjEM": -4.82,
      "adjOff": 100.4,
      "adjOffRank": 204,
      "adjTempo": 63.5,
      "adjTempoRank": 294,
      "conference": "MCon",
      "rank": 215,
      "record": "9-16"
    },
    "Southwest Missouri St.": {
      "adjDef": 103,
      "adjDefRank": 164,
      "adjEM": 4.15,
      "adjOff": 107.2,
      "adjOffRank": 100,
      "adjTempo": 65.1,
      "adjTempoRank": 272,
      "conference": "MVC",
      "rank": 114,
      "record": "17-15"
    },
    "Southwest Texas St.": {
      "adjDef": 106.5,
      "adjDefRank": 234,
      "adjEM": -6.26,
      "adjOff": 100.2,
      "adjOffRank": 208,
      "adjTempo": 70.5,
      "adjTempoRank": 76,
      "conference": "Slnd",
      "rank": 230,
      "record": "11-16"
    },
    "St. Bonaventure": {
      "adjDef": 107.5,
      "adjDefRank": 249,
      "adjEM": 8.59,
      "adjOff": 116.1,
      "adjOffRank": 13,
      "adjTempo": 71.5,
      "adjTempoRank": 53,
      "conference": "A10",
      "rank": 78,
      "record": "17-13"
    },
    "St. Francis NY": {
      "adjDef": 109.4,
      "adjDefRank": 278,
      "adjEM": -8.96,
      "adjOff": 100.4,
      "adjOffRank": 205,
      "adjTempo": 73.6,
      "adjTempoRank": 16,
      "conference": "NEC",
      "rank": 254,
      "record": "18-11"
    },
    "St. Francis PA": {
      "adjDef": 107.3,
      "adjDefRank": 247,
      "adjEM": -17.99,
      "adjOff": 89.4,
      "adjOffRank": 316,
      "adjTempo": 65.3,
      "adjTempoRank": 264,
      "conference": "NEC",
      "rank": 305,
      "record": "6-21"
    },
    "St. John's": {
      "adjDef": 93.6,
      "adjDefRank": 24,
      "adjEM": 9.06,
      "adjOff": 102.7,
      "adjOffRank": 170,
      "adjTempo": 69.1,
      "adjTempoRank": 125,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 76,
      "record": "20-12",
      "seed": 9
    },
    "Stanford": {
      "adjDef": 96.3,
      "adjDefRank": 41,
      "adjEM": 20.32,
      "adjOff": 116.6,
      "adjOffRank": 10,
      "adjTempo": 69.5,
      "adjTempoRank": 106,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 15,
      "record": "20-10",
      "seed": 8
    },
    "Stephen F. Austin": {
      "adjDef": 103.3,
      "adjDefRank": 170,
      "adjEM": -9.4,
      "adjOff": 93.9,
      "adjOffRank": 293,
      "adjTempo": 63.1,
      "adjTempoRank": 303,
      "conference": "Slnd",
      "rank": 261,
      "record": "12-15"
    },
    "Stetson": {
      "adjDef": 109.1,
      "adjDefRank": 271,
      "adjEM": -11.01,
      "adjOff": 98.1,
      "adjOffRank": 246,
      "adjTempo": 72.3,
      "adjTempoRank": 35,
      "conference": "ASun",
      "rank": 268,
      "record": "9-16"
    },
    "Stony Brook": {
      "adjDef": 111.5,
      "adjDefRank": 297,
      "adjEM": -20.04,
      "adjOff": 91.5,
      "adjOffRank": 306,
      "adjTempo": 65.8,
      "adjTempoRank": 246,
      "conference": "AE",
      "rank": 312,
      "record": "6-22"
    },
    "Syracuse": {
      "adjDef": 93.5,
      "adjDefRank": 22,
      "adjEM": 12.61,
      "adjOff": 106.1,
      "adjOffRank": 114,
      "adjTempo": 69.4,
      "adjTempoRank": 114,
      "conference": "BE",
      "rank": 56,
      "record": "23-13"
    },
    "TCU": {
      "adjDef": 105.8,
      "adjDefRank": 222,
      "adjEM": 4.23,
      "adjOff": 110,
      "adjOffRank": 62,
      "adjTempo": 76.5,
      "adjTempoRank": 4,
      "conference": "CUSA",
      "rank": 110,
      "record": "14-15"
    },
    "Temple": {
      "adjDef": 100.4,
      "adjDefRank": 112,
      "adjEM": 13.57,
      "adjOff": 114,
      "adjOffRank": 25,
      "adjTempo": 62.2,
      "adjTempoRank": 312,
      "conference": "A10",
      "rank": 48,
      "record": "19-15"
    },
    "Tennessee": {
      "adjDef": 98.2,
      "adjDefRank": 72,
      "adjEM": 12.67,
      "adjOff": 110.9,
      "adjOffRank": 52,
      "adjTempo": 66.7,
      "adjTempoRank": 219,
      "conference": "SEC",
      "rank": 54,
      "record": "15-16"
    },
    "Tennessee Martin": {
      "adjDef": 107.3,
      "adjDefRank": 245,
      "adjEM": -7.93,
      "adjOff": 99.4,
      "adjOffRank": 222,
      "adjTempo": 71.3,
      "adjTempoRank": 55,
      "conference": "OVC",
      "rank": 246,
      "record": "11-14"
    },
    "Tennessee St.": {
      "adjDef": 110,
      "adjDefRank": 286,
      "adjEM": -6.77,
      "adjOff": 103.2,
      "adjOffRank": 156,
      "adjTempo": 72.7,
      "adjTempoRank": 24,
      "conference": "OVC",
      "rank": 236,
      "record": "9-17"
    },
    "Tennessee Tech": {
      "adjDef": 100.4,
      "adjDefRank": 109,
      "adjEM": 9.44,
      "adjOff": 109.9,
      "adjOffRank": 65,
      "adjTempo": 67.2,
      "adjTempoRank": 204,
      "conference": "OVC",
      "rank": 72,
      "record": "24-7"
    },
    "Texas": {
      "adjDef": 98.3,
      "adjDefRank": 74,
      "adjEM": 17.69,
      "adjOff": 116,
      "adjOffRank": 15,
      "adjTempo": 68.5,
      "adjTempoRank": 150,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 27,
      "record": "22-12",
      "seed": 6
    },
    "Texas A&M": {
      "adjDef": 102.2,
      "adjDefRank": 145,
      "adjEM": -4.73,
      "adjOff": 97.4,
      "adjOffRank": 256,
      "adjTempo": 68.2,
      "adjTempoRank": 163,
      "conference": "B12",
      "rank": 213,
      "record": "8-22"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 109.3,
      "adjDefRank": 277,
      "adjEM": -8.08,
      "adjOff": 101.3,
      "adjOffRank": 193,
      "adjTempo": 73.5,
      "adjTempoRank": 18,
      "conference": "ind",
      "rank": 248,
      "record": "10-15"
    },
    "Texas Pan American": {
      "adjDef": 101.7,
      "adjDefRank": 137,
      "adjEM": -0.62,
      "adjOff": 101.1,
      "adjOffRank": 197,
      "adjTempo": 71.7,
      "adjTempoRank": 48,
      "conference": "ind",
      "rank": 167,
      "record": "16-10"
    },
    "Texas Southern": {
      "adjDef": 114.1,
      "adjDefRank": 315,
      "adjEM": -19.56,
      "adjOff": 94.6,
      "adjOffRank": 285,
      "adjTempo": 67.8,
      "adjTempoRank": 177,
      "conference": "SWAC",
      "rank": 311,
      "record": "11-17"
    },
    "Texas Tech": {
      "adjDef": 97.4,
      "adjDefRank": 59,
      "adjEM": 17.24,
      "adjOff": 114.6,
      "adjOffRank": 21,
      "adjTempo": 69.8,
      "adjTempoRank": 96,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 31,
      "record": "23-9",
      "seed": 6
    },
    "The Citadel": {
      "adjDef": 104.6,
      "adjDefRank": 203,
      "adjEM": 0.66,
      "adjOff": 105.3,
      "adjOffRank": 127,
      "adjTempo": 67,
      "adjTempoRank": 208,
      "conference": "SC",
      "rank": 151,
      "record": "13-12"
    },
    "Toledo": {
      "adjDef": 98.7,
      "adjDefRank": 80,
      "adjEM": 0.4,
      "adjOff": 99.1,
      "adjOffRank": 229,
      "adjTempo": 66.2,
      "adjTempoRank": 233,
      "conference": "MAC",
      "rank": 153,
      "record": "16-14"
    },
    "Towson": {
      "adjDef": 103.3,
      "adjDefRank": 172,
      "adjEM": -12.9,
      "adjOff": 90.4,
      "adjOffRank": 311,
      "adjTempo": 66.7,
      "adjTempoRank": 218,
      "conference": "CAA",
      "rank": 275,
      "record": "11-18"
    },
    "Troy St.": {
      "adjDef": 104.1,
      "adjDefRank": 188,
      "adjEM": 0.15,
      "adjOff": 104.2,
      "adjOffRank": 143,
      "adjTempo": 69.1,
      "adjTempoRank": 133,
      "conference": "ASun",
      "rank": 157,
      "record": "18-10"
    },
    "Tulane": {
      "adjDef": 101.7,
      "adjDefRank": 134,
      "adjEM": 3.07,
      "adjOff": 104.8,
      "adjOffRank": 134,
      "adjTempo": 69.2,
      "adjTempoRank": 120,
      "conference": "CUSA",
      "rank": 129,
      "record": "13-15"
    },
    "Tulsa": {
      "adjDef": 100.4,
      "adjDefRank": 107,
      "adjEM": 15.59,
      "adjOff": 116,
      "adjOffRank": 16,
      "adjTempo": 69.5,
      "adjTempoRank": 110,
      "conference": "WAC",
      "finish": "Round of 32",
      "rank": 41,
      "record": "26-7",
      "seed": 12
    },
    "UAB": {
      "adjDef": 96.4,
      "adjDefRank": 43,
      "adjEM": 6.18,
      "adjOff": 102.5,
      "adjOffRank": 171,
      "adjTempo": 67.7,
      "adjTempoRank": 185,
      "conference": "CUSA",
      "rank": 95,
      "record": "13-17"
    },
    "UC Irvine": {
      "adjDef": 101.5,
      "adjDefRank": 133,
      "adjEM": 6.47,
      "adjOff": 108,
      "adjOffRank": 86,
      "adjTempo": 64.9,
      "adjTempoRank": 276,
      "conference": "BW",
      "rank": 91,
      "record": "20-11"
    },
    "UC Riverside": {
      "adjDef": 107.9,
      "adjDefRank": 254,
      "adjEM": -8.86,
      "adjOff": 99,
      "adjOffRank": 232,
      "adjTempo": 67.3,
      "adjTempoRank": 197,
      "conference": "BW",
      "rank": 253,
      "record": "7-18"
    },
    "UC Santa Barbara": {
      "adjDef": 99.3,
      "adjDefRank": 90,
      "adjEM": 8.87,
      "adjOff": 108.2,
      "adjOffRank": 81,
      "adjTempo": 63.4,
      "adjTempoRank": 296,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 77,
      "record": "19-11",
      "seed": 14
    },
    "UCF": {
      "adjDef": 103.4,
      "adjDefRank": 174,
      "adjEM": 0.98,
      "adjOff": 104.4,
      "adjOffRank": 139,
      "adjTempo": 69.7,
      "adjTempoRank": 99,
      "conference": "ASun",
      "rank": 149,
      "record": "16-12"
    },
    "UCLA": {
      "adjDef": 97.3,
      "adjDefRank": 58,
      "adjEM": 16.34,
      "adjOff": 113.7,
      "adjOffRank": 27,
      "adjTempo": 68.1,
      "adjTempoRank": 167,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 35,
      "record": "21-12",
      "seed": 8
    },
    "UMBC": {
      "adjDef": 103.4,
      "adjDefRank": 175,
      "adjEM": -7.37,
      "adjOff": 96.1,
      "adjOffRank": 276,
      "adjTempo": 70.6,
      "adjTempoRank": 68,
      "conference": "NEC",
      "rank": 243,
      "record": "20-9"
    },
    "UMKC": {
      "adjDef": 101.5,
      "adjDefRank": 132,
      "adjEM": -0.33,
      "adjOff": 101.2,
      "adjOffRank": 194,
      "adjTempo": 60.7,
      "adjTempoRank": 320,
      "conference": "MCon",
      "rank": 163,
      "record": "17-11"
    },
    "UNC Asheville": {
      "adjDef": 108.6,
      "adjDefRank": 265,
      "adjEM": -9.7,
      "adjOff": 98.9,
      "adjOffRank": 233,
      "adjTempo": 68.5,
      "adjTempoRank": 151,
      "conference": "BSth",
      "rank": 263,
      "record": "12-14"
    },
    "UNC Greensboro": {
      "adjDef": 98.8,
      "adjDefRank": 84,
      "adjEM": 4.21,
      "adjOff": 103.1,
      "adjOffRank": 160,
      "adjTempo": 68.6,
      "adjTempoRank": 147,
      "conference": "SC",
      "rank": 111,
      "record": "20-11"
    },
    "UNC Wilmington": {
      "adjDef": 96.1,
      "adjDefRank": 37,
      "adjEM": 11.32,
      "adjOff": 107.4,
      "adjOffRank": 98,
      "adjTempo": 63.9,
      "adjTempoRank": 292,
      "conference": "CAA",
      "finish": "Round of 32",
      "rank": 65,
      "record": "23-10",
      "seed": 13
    },
    "UNLV": {
      "adjDef": 101.5,
      "adjDefRank": 131,
      "adjEM": 8.26,
      "adjOff": 109.7,
      "adjOffRank": 68,
      "adjTempo": 72.4,
      "adjTempoRank": 31,
      "conference": "MWC",
      "rank": 81,
      "record": "21-11"
    },
    "USC": {
      "adjDef": 92.1,
      "adjDefRank": 14,
      "adjEM": 20.67,
      "adjOff": 112.8,
      "adjOffRank": 36,
      "adjTempo": 70.1,
      "adjTempoRank": 88,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 12,
      "record": "22-10",
      "seed": 4
    },
    "UT Arlington": {
      "adjDef": 110.6,
      "adjDefRank": 292,
      "adjEM": -8.37,
      "adjOff": 102.2,
      "adjOffRank": 179,
      "adjTempo": 72.1,
      "adjTempoRank": 39,
      "conference": "Slnd",
      "rank": 250,
      "record": "11-15"
    },
    "UTEP": {
      "adjDef": 103.7,
      "adjDefRank": 181,
      "adjEM": -2.36,
      "adjOff": 101.3,
      "adjOffRank": 191,
      "adjTempo": 67.7,
      "adjTempoRank": 186,
      "conference": "WAC",
      "rank": 189,
      "record": "9-22"
    },
    "UTSA": {
      "adjDef": 103.2,
      "adjDefRank": 166,
      "adjEM": -0.18,
      "adjOff": 103,
      "adjOffRank": 161,
      "adjTempo": 72.2,
      "adjTempoRank": 36,
      "conference": "Slnd",
      "rank": 160,
      "record": "19-10"
    },
    "Utah": {
      "adjDef": 97.3,
      "adjDefRank": 57,
      "adjEM": 14.08,
      "adjOff": 111.4,
      "adjOffRank": 44,
      "adjTempo": 62.1,
      "adjTempoRank": 314,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 45,
      "record": "19-9",
      "seed": 12
    },
    "Utah St.": {
      "adjDef": 97.2,
      "adjDefRank": 56,
      "adjEM": 9.17,
      "adjOff": 106.3,
      "adjOffRank": 111,
      "adjTempo": 63.3,
      "adjTempoRank": 301,
      "conference": "BW",
      "rank": 75,
      "record": "22-8"
    },
    "VCU": {
      "adjDef": 99.3,
      "adjDefRank": 88,
      "adjEM": 3.53,
      "adjOff": 102.9,
      "adjOffRank": 166,
      "adjTempo": 70.9,
      "adjTempoRank": 62,
      "conference": "CAA",
      "rank": 122,
      "record": "21-11"
    },
    "VMI": {
      "adjDef": 106.5,
      "adjDefRank": 235,
      "adjEM": -7.23,
      "adjOff": 99.3,
      "adjOffRank": 226,
      "adjTempo": 77.7,
      "adjTempoRank": 2,
      "conference": "SC",
      "rank": 241,
      "record": "7-18"
    },
    "Valparaiso": {
      "adjDef": 97.8,
      "adjDefRank": 65,
      "adjEM": 13.63,
      "adjOff": 111.5,
      "adjOffRank": 42,
      "adjTempo": 68.7,
      "adjTempoRank": 144,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 47,
      "record": "24-8",
      "seed": 13
    },
    "Vanderbilt": {
      "adjDef": 100.5,
      "adjDefRank": 114,
      "adjEM": 7.14,
      "adjOff": 107.6,
      "adjOffRank": 93,
      "adjTempo": 69.1,
      "adjTempoRank": 132,
      "conference": "SEC",
      "rank": 85,
      "record": "17-15"
    },
    "Vermont": {
      "adjDef": 108.2,
      "adjDefRank": 259,
      "adjEM": -2.41,
      "adjOff": 105.8,
      "adjOffRank": 117,
      "adjTempo": 68.1,
      "adjTempoRank": 166,
      "conference": "AE",
      "rank": 190,
      "record": "21-8"
    },
    "Villanova": {
      "adjDef": 93.5,
      "adjDefRank": 23,
      "adjEM": 13.14,
      "adjOff": 106.7,
      "adjOffRank": 108,
      "adjTempo": 67.3,
      "adjTempoRank": 201,
      "conference": "BE",
      "rank": 52,
      "record": "19-13"
    },
    "Virginia": {
      "adjDef": 99.1,
      "adjDefRank": 87,
      "adjEM": 12.92,
      "adjOff": 112,
      "adjOffRank": 40,
      "adjTempo": 69.4,
      "adjTempoRank": 113,
      "conference": "ACC",
      "rank": 53,
      "record": "17-12"
    },
    "Virginia Tech": {
      "adjDef": 102.2,
      "adjDefRank": 146,
      "adjEM": 2.1,
      "adjOff": 104.3,
      "adjOffRank": 142,
      "adjTempo": 67.3,
      "adjTempoRank": 202,
      "conference": "BE",
      "rank": 142,
      "record": "10-18"
    },
    "Wagner": {
      "adjDef": 106.2,
      "adjDefRank": 229,
      "adjEM": -3.17,
      "adjOff": 103.1,
      "adjOffRank": 158,
      "adjTempo": 73.3,
      "adjTempoRank": 20,
      "conference": "NEC",
      "rank": 199,
      "record": "19-10"
    },
    "Wake Forest": {
      "adjDef": 101.3,
      "adjDefRank": 126,
      "adjEM": 17.35,
      "adjOff": 118.6,
      "adjOffRank": 6,
      "adjTempo": 69.5,
      "adjTempoRank": 108,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 30,
      "record": "21-13",
      "seed": 7
    },
    "Washington": {
      "adjDef": 99.8,
      "adjDefRank": 97,
      "adjEM": 5.44,
      "adjOff": 105.3,
      "adjOffRank": 128,
      "adjTempo": 71.3,
      "adjTempoRank": 56,
      "conference": "P10",
      "rank": 99,
      "record": "10-18"
    },
    "Washington St.": {
      "adjDef": 109.3,
      "adjDefRank": 275,
      "adjEM": -4.94,
      "adjOff": 104.3,
      "adjOffRank": 141,
      "adjTempo": 69.8,
      "adjTempoRank": 95,
      "conference": "P10",
      "rank": 216,
      "record": "6-21"
    },
    "Weber St.": {
      "adjDef": 104.4,
      "adjDefRank": 195,
      "adjEM": 1.76,
      "adjOff": 106.1,
      "adjOffRank": 113,
      "adjTempo": 68.1,
      "adjTempoRank": 164,
      "conference": "BSky",
      "rank": 144,
      "record": "16-11"
    },
    "West Virginia": {
      "adjDef": 106.1,
      "adjDefRank": 228,
      "adjEM": -2.62,
      "adjOff": 103.4,
      "adjOffRank": 154,
      "adjTempo": 72,
      "adjTempoRank": 41,
      "conference": "BE",
      "rank": 192,
      "record": "7-20"
    },
    "Western Carolina": {
      "adjDef": 106.4,
      "adjDefRank": 231,
      "adjEM": -6.37,
      "adjOff": 100,
      "adjOffRank": 214,
      "adjTempo": 71.6,
      "adjTempoRank": 51,
      "conference": "SC",
      "rank": 232,
      "record": "8-16"
    },
    "Western Illinois": {
      "adjDef": 111.8,
      "adjDefRank": 302,
      "adjEM": -9.31,
      "adjOff": 102.5,
      "adjOffRank": 174,
      "adjTempo": 68.7,
      "adjTempoRank": 146,
      "conference": "MCon",
      "rank": 260,
      "record": "10-16"
    },
    "Western Kentucky": {
      "adjDef": 96,
      "adjDefRank": 36,
      "adjEM": 14.51,
      "adjOff": 110.5,
      "adjOffRank": 55,
      "adjTempo": 67.5,
      "adjTempoRank": 192,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 44,
      "record": "26-4",
      "seed": 9
    },
    "Western Michigan": {
      "adjDef": 104.6,
      "adjDefRank": 202,
      "adjEM": 3,
      "adjOff": 107.6,
      "adjOffRank": 96,
      "adjTempo": 66.9,
      "adjTempoRank": 211,
      "conference": "MAC",
      "rank": 130,
      "record": "17-13"
    },
    "Wichita St.": {
      "adjDef": 107.2,
      "adjDefRank": 242,
      "adjEM": 0.61,
      "adjOff": 107.8,
      "adjOffRank": 89,
      "adjTempo": 66.9,
      "adjTempoRank": 209,
      "conference": "MVC",
      "rank": 152,
      "record": "15-15"
    },
    "William & Mary": {
      "adjDef": 102.2,
      "adjDefRank": 148,
      "adjEM": -5.62,
      "adjOff": 96.6,
      "adjOffRank": 268,
      "adjTempo": 65.4,
      "adjTempoRank": 260,
      "conference": "CAA",
      "rank": 222,
      "record": "9-19"
    },
    "Winthrop": {
      "adjDef": 102.6,
      "adjDefRank": 155,
      "adjEM": -5.31,
      "adjOff": 97.3,
      "adjOffRank": 258,
      "adjTempo": 65.9,
      "adjTempoRank": 243,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 219,
      "record": "17-12",
      "seed": 16
    },
    "Wisconsin": {
      "adjDef": 97.5,
      "adjDefRank": 61,
      "adjEM": 13.22,
      "adjOff": 110.7,
      "adjOffRank": 53,
      "adjTempo": 63.3,
      "adjTempoRank": 299,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 50,
      "record": "18-13",
      "seed": 8
    },
    "Wofford": {
      "adjDef": 101.8,
      "adjDefRank": 138,
      "adjEM": -7.7,
      "adjOff": 94.1,
      "adjOffRank": 290,
      "adjTempo": 67.3,
      "adjTempoRank": 200,
      "conference": "SC",
      "rank": 244,
      "record": "7-18"
    },
    "Wright St.": {
      "adjDef": 108.3,
      "adjDefRank": 261,
      "adjEM": 2.67,
      "adjOff": 110.9,
      "adjOffRank": 51,
      "adjTempo": 65.2,
      "adjTempoRank": 266,
      "conference": "Horz",
      "rank": 134,
      "record": "17-11"
    },
    "Wyoming": {
      "adjDef": 96.7,
      "adjDefRank": 50,
      "adjEM": 11.01,
      "adjOff": 107.8,
      "adjOffRank": 91,
      "adjTempo": 69,
      "adjTempoRank": 136,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 67,
      "record": "22-9",
      "seed": 11
    },
    "Xavier": {
      "adjDef": 92.6,
      "adjDefRank": 17,
      "adjEM": 18.6,
      "adjOff": 111.2,
      "adjOffRank": 48,
      "adjTempo": 67,
      "adjTempoRank": 206,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 23,
      "record": "26-6",
      "seed": 7
    },
    "Yale": {
      "adjDef": 101.8,
      "adjDefRank": 139,
      "adjEM": 1.09,
      "adjOff": 102.9,
      "adjOffRank": 163,
      "adjTempo": 73.1,
      "adjTempoRank": 21,
      "conference": "Ivy",
      "rank": 148,
      "record": "21-10"
    },
    "Youngstown St.": {
      "adjDef": 110.3,
      "adjDefRank": 288,
      "adjEM": -14.67,
      "adjOff": 95.7,
      "adjOffRank": 279,
      "adjTempo": 67.5,
      "adjTempoRank": 189,
      "conference": "Horz",
      "rank": 286,
      "record": "4-23"
    }
  },
  "2003": {
    "Air Force": {
      "adjDef": 104,
      "adjDefRank": 192,
      "adjEM": 1.27,
      "adjOff": 105.2,
      "adjOffRank": 111,
      "adjTempo": 52.9,
      "adjTempoRank": 327,
      "conference": "MWC",
      "rank": 152,
      "record": "12-16"
    },
    "Akron": {
      "adjDef": 110.9,
      "adjDefRank": 302,
      "adjEM": -0.92,
      "adjOff": 110,
      "adjOffRank": 44,
      "adjTempo": 67,
      "adjTempoRank": 168,
      "conference": "MAC",
      "rank": 178,
      "record": "14-14"
    },
    "Alabama": {
      "adjDef": 95.7,
      "adjDefRank": 54,
      "adjEM": 14.6,
      "adjOff": 110.3,
      "adjOffRank": 39,
      "adjTempo": 65.3,
      "adjTempoRank": 243,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 38,
      "record": "17-12",
      "seed": 10
    },
    "Alabama A&M": {
      "adjDef": 108.2,
      "adjDefRank": 275,
      "adjEM": -18.25,
      "adjOff": 90,
      "adjOffRank": 310,
      "adjTempo": 72.3,
      "adjTempoRank": 13,
      "conference": "SWAC",
      "rank": 309,
      "record": "7-18"
    },
    "Alabama St.": {
      "adjDef": 100.9,
      "adjDefRank": 141,
      "adjEM": -9.95,
      "adjOff": 90.9,
      "adjOffRank": 303,
      "adjTempo": 64.2,
      "adjTempoRank": 275,
      "conference": "SWAC",
      "rank": 257,
      "record": "14-15"
    },
    "Albany": {
      "adjDef": 113.3,
      "adjDefRank": 317,
      "adjEM": -18.51,
      "adjOff": 94.8,
      "adjOffRank": 274,
      "adjTempo": 65.9,
      "adjTempoRank": 209,
      "conference": "AE",
      "rank": 311,
      "record": "7-21"
    },
    "Alcorn St.": {
      "adjDef": 104.3,
      "adjDefRank": 202,
      "adjEM": -11.79,
      "adjOff": 92.5,
      "adjOffRank": 294,
      "adjTempo": 70.5,
      "adjTempoRank": 43,
      "conference": "SWAC",
      "rank": 278,
      "record": "14-19"
    },
    "American": {
      "adjDef": 101.6,
      "adjDefRank": 153,
      "adjEM": 1.95,
      "adjOff": 103.6,
      "adjOffRank": 141,
      "adjTempo": 62.9,
      "adjTempoRank": 297,
      "conference": "Pat",
      "rank": 146,
      "record": "16-14"
    },
    "Appalachian St.": {
      "adjDef": 104.2,
      "adjDefRank": 199,
      "adjEM": -0.69,
      "adjOff": 103.5,
      "adjOffRank": 142,
      "adjTempo": 76.1,
      "adjTempoRank": 1,
      "conference": "SC",
      "rank": 173,
      "record": "16-10"
    },
    "Arizona": {
      "adjDef": 88.8,
      "adjDefRank": 8,
      "adjEM": 26.8,
      "adjOff": 115.6,
      "adjOffRank": 10,
      "adjTempo": 72.7,
      "adjTempoRank": 11,
      "conference": "P10",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "28-4",
      "seed": 1
    },
    "Arizona St.": {
      "adjDef": 97.4,
      "adjDefRank": 78,
      "adjEM": 16.49,
      "adjOff": 113.9,
      "adjOffRank": 18,
      "adjTempo": 66.9,
      "adjTempoRank": 169,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 30,
      "record": "20-12",
      "seed": 10
    },
    "Arkansas": {
      "adjDef": 95.1,
      "adjDefRank": 46,
      "adjEM": 2.95,
      "adjOff": 98,
      "adjOffRank": 243,
      "adjTempo": 65.8,
      "adjTempoRank": 211,
      "conference": "SEC",
      "rank": 134,
      "record": "9-19"
    },
    "Arkansas Little Rock": {
      "adjDef": 100.7,
      "adjDefRank": 138,
      "adjEM": 1.63,
      "adjOff": 102.3,
      "adjOffRank": 163,
      "adjTempo": 66.1,
      "adjTempoRank": 205,
      "conference": "SB",
      "rank": 149,
      "record": "16-12"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 102.9,
      "adjDefRank": 174,
      "adjEM": -25.62,
      "adjOff": 77.2,
      "adjOffRank": 327,
      "adjTempo": 67.7,
      "adjTempoRank": 128,
      "conference": "SWAC",
      "rank": 322,
      "record": "4-24"
    },
    "Arkansas St.": {
      "adjDef": 107.2,
      "adjDefRank": 256,
      "adjEM": 0.48,
      "adjOff": 107.7,
      "adjOffRank": 77,
      "adjTempo": 66.5,
      "adjTempoRank": 185,
      "conference": "SB",
      "rank": 160,
      "record": "11-15"
    },
    "Army": {
      "adjDef": 111.3,
      "adjDefRank": 303,
      "adjEM": -25.67,
      "adjOff": 85.6,
      "adjOffRank": 321,
      "adjTempo": 62.2,
      "adjTempoRank": 303,
      "conference": "Pat",
      "rank": 323,
      "record": "3-22"
    },
    "Auburn": {
      "adjDef": 94.1,
      "adjDefRank": 31,
      "adjEM": 13.63,
      "adjOff": 107.7,
      "adjOffRank": 74,
      "adjTempo": 66.6,
      "adjTempoRank": 181,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 46,
      "record": "22-12",
      "seed": 10
    },
    "Austin Peay": {
      "adjDef": 98,
      "adjDefRank": 86,
      "adjEM": 2.65,
      "adjOff": 100.7,
      "adjOffRank": 197,
      "adjTempo": 64.4,
      "adjTempoRank": 273,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 135,
      "record": "20-8",
      "seed": 13
    },
    "BYU": {
      "adjDef": 91.7,
      "adjDefRank": 16,
      "adjEM": 17.68,
      "adjOff": 109.4,
      "adjOffRank": 52,
      "adjTempo": 65.5,
      "adjTempoRank": 234,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 24,
      "record": "23-9",
      "seed": 12
    },
    "Ball St.": {
      "adjDef": 106.8,
      "adjDefRank": 251,
      "adjEM": -0.35,
      "adjOff": 106.5,
      "adjOffRank": 94,
      "adjTempo": 70.1,
      "adjTempoRank": 54,
      "conference": "MAC",
      "rank": 169,
      "record": "13-17"
    },
    "Baylor": {
      "adjDef": 100.7,
      "adjDefRank": 137,
      "adjEM": 5.97,
      "adjOff": 106.6,
      "adjOffRank": 92,
      "adjTempo": 65.6,
      "adjTempoRank": 225,
      "conference": "B12",
      "rank": 102,
      "record": "14-14"
    },
    "Belmont": {
      "adjDef": 103,
      "adjDefRank": 180,
      "adjEM": 0.18,
      "adjOff": 103.2,
      "adjOffRank": 149,
      "adjTempo": 67.2,
      "adjTempoRank": 155,
      "conference": "ASun",
      "rank": 165,
      "record": "17-12"
    },
    "Bethune Cookman": {
      "adjDef": 111.8,
      "adjDefRank": 309,
      "adjEM": -22.4,
      "adjOff": 89.4,
      "adjOffRank": 313,
      "adjTempo": 67.1,
      "adjTempoRank": 163,
      "conference": "MEAC",
      "rank": 314,
      "record": "8-22"
    },
    "Binghamton": {
      "adjDef": 100.3,
      "adjDefRank": 129,
      "adjEM": -3.57,
      "adjOff": 96.7,
      "adjOffRank": 258,
      "adjTempo": 66.1,
      "adjTempoRank": 203,
      "conference": "AE",
      "rank": 198,
      "record": "14-13"
    },
    "Birmingham Southern": {
      "adjDef": 107.1,
      "adjDefRank": 253,
      "adjEM": -6.59,
      "adjOff": 100.5,
      "adjOffRank": 201,
      "adjTempo": 63.4,
      "adjTempoRank": 288,
      "conference": "BSth",
      "rank": 228,
      "record": "17-9"
    },
    "Boise St.": {
      "adjDef": 97.5,
      "adjDefRank": 80,
      "adjEM": -1.56,
      "adjOff": 96,
      "adjOffRank": 263,
      "adjTempo": 66.7,
      "adjTempoRank": 178,
      "conference": "WAC",
      "rank": 183,
      "record": "12-16"
    },
    "Boston College": {
      "adjDef": 104.4,
      "adjDefRank": 203,
      "adjEM": 13.3,
      "adjOff": 117.7,
      "adjOffRank": 4,
      "adjTempo": 68.2,
      "adjTempoRank": 112,
      "conference": "BE",
      "rank": 49,
      "record": "19-12"
    },
    "Boston University": {
      "adjDef": 98.7,
      "adjDefRank": 100,
      "adjEM": 3.64,
      "adjOff": 102.4,
      "adjOffRank": 161,
      "adjTempo": 64.4,
      "adjTempoRank": 274,
      "conference": "AE",
      "rank": 125,
      "record": "20-11"
    },
    "Bowling Green": {
      "adjDef": 100.6,
      "adjDefRank": 134,
      "adjEM": -1.88,
      "adjOff": 98.7,
      "adjOffRank": 230,
      "adjTempo": 66.1,
      "adjTempoRank": 202,
      "conference": "MAC",
      "rank": 184,
      "record": "11-16"
    },
    "Bradley": {
      "adjDef": 101.2,
      "adjDefRank": 145,
      "adjEM": 1.95,
      "adjOff": 103.1,
      "adjOffRank": 152,
      "adjTempo": 68.6,
      "adjTempoRank": 93,
      "conference": "MVC",
      "rank": 145,
      "record": "12-18"
    },
    "Brown": {
      "adjDef": 104.8,
      "adjDefRank": 215,
      "adjEM": 0.76,
      "adjOff": 105.6,
      "adjOffRank": 105,
      "adjTempo": 70.6,
      "adjTempoRank": 41,
      "conference": "Ivy",
      "rank": 158,
      "record": "17-12"
    },
    "Bucknell": {
      "adjDef": 96.8,
      "adjDefRank": 66,
      "adjEM": -5.75,
      "adjOff": 91.1,
      "adjOffRank": 301,
      "adjTempo": 63.3,
      "adjTempoRank": 289,
      "conference": "Pat",
      "rank": 220,
      "record": "14-15"
    },
    "Buffalo": {
      "adjDef": 105.8,
      "adjDefRank": 232,
      "adjEM": -10.33,
      "adjOff": 95.4,
      "adjOffRank": 271,
      "adjTempo": 66.8,
      "adjTempoRank": 175,
      "conference": "MAC",
      "rank": 259,
      "record": "5-23"
    },
    "Butler": {
      "adjDef": 99.3,
      "adjDefRank": 110,
      "adjEM": 16.09,
      "adjOff": 115.4,
      "adjOffRank": 11,
      "adjTempo": 58.6,
      "adjTempoRank": 325,
      "conference": "Horz",
      "finish": "Sweet 16",
      "rank": 31,
      "record": "27-6",
      "seed": 12
    },
    "Cal Poly": {
      "adjDef": 99.7,
      "adjDefRank": 119,
      "adjEM": -1.38,
      "adjOff": 98.4,
      "adjOffRank": 234,
      "adjTempo": 69.2,
      "adjTempoRank": 75,
      "conference": "BW",
      "rank": 181,
      "record": "15-14"
    },
    "Cal St. Fullerton": {
      "adjDef": 108.9,
      "adjDefRank": 284,
      "adjEM": -7.48,
      "adjOff": 101.4,
      "adjOffRank": 181,
      "adjTempo": 59.9,
      "adjTempoRank": 320,
      "conference": "BW",
      "rank": 234,
      "record": "10-19"
    },
    "Cal St. Northridge": {
      "adjDef": 103.1,
      "adjDefRank": 183,
      "adjEM": -2.53,
      "adjOff": 100.6,
      "adjOffRank": 198,
      "adjTempo": 67.8,
      "adjTempoRank": 126,
      "conference": "BW",
      "rank": 189,
      "record": "13-15"
    },
    "California": {
      "adjDef": 96.3,
      "adjDefRank": 63,
      "adjEM": 13.59,
      "adjOff": 109.9,
      "adjOffRank": 45,
      "adjTempo": 65.9,
      "adjTempoRank": 208,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 47,
      "record": "22-9",
      "seed": 8
    },
    "Campbell": {
      "adjDef": 116.7,
      "adjDefRank": 324,
      "adjEM": -24.53,
      "adjOff": 92.2,
      "adjOffRank": 295,
      "adjTempo": 66.4,
      "adjTempoRank": 187,
      "conference": "ASun",
      "rank": 318,
      "record": "4-22"
    },
    "Canisius": {
      "adjDef": 103,
      "adjDefRank": 182,
      "adjEM": -3.77,
      "adjOff": 99.3,
      "adjOffRank": 218,
      "adjTempo": 68.6,
      "adjTempoRank": 97,
      "conference": "MAAC",
      "rank": 202,
      "record": "10-18"
    },
    "Centenary": {
      "adjDef": 111.7,
      "adjDefRank": 306,
      "adjEM": -8.6,
      "adjOff": 103.1,
      "adjOffRank": 151,
      "adjTempo": 68.9,
      "adjTempoRank": 84,
      "conference": "ind",
      "rank": 245,
      "record": "10-14"
    },
    "Central Connecticut": {
      "adjDef": 109.2,
      "adjDefRank": 290,
      "adjEM": -8.04,
      "adjOff": 101.1,
      "adjOffRank": 189,
      "adjTempo": 61.6,
      "adjTempoRank": 312,
      "conference": "NEC",
      "rank": 238,
      "record": "15-13"
    },
    "Central Michigan": {
      "adjDef": 99.4,
      "adjDefRank": 113,
      "adjEM": 10.01,
      "adjOff": 109.5,
      "adjOffRank": 51,
      "adjTempo": 71.7,
      "adjTempoRank": 20,
      "conference": "MAC",
      "finish": "Round of 32",
      "rank": 70,
      "record": "25-7",
      "seed": 11
    },
    "Charleston Southern": {
      "adjDef": 103.3,
      "adjDefRank": 186,
      "adjEM": -9.1,
      "adjOff": 94.2,
      "adjOffRank": 278,
      "adjTempo": 65,
      "adjTempoRank": 255,
      "conference": "BSth",
      "rank": 250,
      "record": "12-14"
    },
    "Charlotte": {
      "adjDef": 98.5,
      "adjDefRank": 94,
      "adjEM": 5.97,
      "adjOff": 104.5,
      "adjOffRank": 125,
      "adjTempo": 65.8,
      "adjTempoRank": 217,
      "conference": "CUSA",
      "rank": 103,
      "record": "13-16"
    },
    "Chattanooga": {
      "adjDef": 107.3,
      "adjDefRank": 258,
      "adjEM": 3.34,
      "adjOff": 110.6,
      "adjOffRank": 36,
      "adjTempo": 69.2,
      "adjTempoRank": 72,
      "conference": "SC",
      "rank": 127,
      "record": "19-9"
    },
    "Chicago St.": {
      "adjDef": 111.4,
      "adjDefRank": 305,
      "adjEM": -18.22,
      "adjOff": 93.2,
      "adjOffRank": 288,
      "adjTempo": 65.7,
      "adjTempoRank": 220,
      "conference": "MCon",
      "rank": 308,
      "record": "3-27"
    },
    "Cincinnati": {
      "adjDef": 90.3,
      "adjDefRank": 13,
      "adjEM": 14.69,
      "adjOff": 105,
      "adjOffRank": 116,
      "adjTempo": 66.4,
      "adjTempoRank": 188,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 36,
      "record": "17-12",
      "seed": 8
    },
    "Clemson": {
      "adjDef": 101.6,
      "adjDefRank": 152,
      "adjEM": 5.75,
      "adjOff": 107.3,
      "adjOffRank": 82,
      "adjTempo": 63.6,
      "adjTempoRank": 285,
      "conference": "ACC",
      "rank": 105,
      "record": "15-13"
    },
    "Cleveland St.": {
      "adjDef": 105.8,
      "adjDefRank": 235,
      "adjEM": -8.12,
      "adjOff": 97.7,
      "adjOffRank": 248,
      "adjTempo": 66.1,
      "adjTempoRank": 200,
      "conference": "Horz",
      "rank": 240,
      "record": "8-22"
    },
    "Coastal Carolina": {
      "adjDef": 108.8,
      "adjDefRank": 283,
      "adjEM": -10.47,
      "adjOff": 98.4,
      "adjOffRank": 235,
      "adjTempo": 65.3,
      "adjTempoRank": 241,
      "conference": "BSth",
      "rank": 261,
      "record": "11-15"
    },
    "Colgate": {
      "adjDef": 105.2,
      "adjDefRank": 223,
      "adjEM": -7.72,
      "adjOff": 97.4,
      "adjOffRank": 251,
      "adjTempo": 66.2,
      "adjTempoRank": 198,
      "conference": "Pat",
      "rank": 235,
      "record": "12-14"
    },
    "College of Charleston": {
      "adjDef": 98.4,
      "adjDefRank": 92,
      "adjEM": 4.08,
      "adjOff": 102.5,
      "adjOffRank": 159,
      "adjTempo": 67.1,
      "adjTempoRank": 161,
      "conference": "SC",
      "rank": 120,
      "record": "23-8"
    },
    "Colorado": {
      "adjDef": 93.8,
      "adjDefRank": 28,
      "adjEM": 15.27,
      "adjOff": 109.1,
      "adjOffRank": 56,
      "adjTempo": 69.9,
      "adjTempoRank": 56,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 34,
      "record": "20-12",
      "seed": 10
    },
    "Colorado St.": {
      "adjDef": 104.6,
      "adjDefRank": 205,
      "adjEM": 5.96,
      "adjOff": 110.6,
      "adjOffRank": 37,
      "adjTempo": 67.1,
      "adjTempoRank": 162,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 104,
      "record": "17-14",
      "seed": 14
    },
    "Columbia": {
      "adjDef": 105.7,
      "adjDefRank": 230,
      "adjEM": -22.93,
      "adjOff": 82.7,
      "adjOffRank": 325,
      "adjTempo": 60,
      "adjTempoRank": 318,
      "conference": "Ivy",
      "rank": 316,
      "record": "2-25"
    },
    "Connecticut": {
      "adjDef": 92,
      "adjDefRank": 17,
      "adjEM": 19.54,
      "adjOff": 111.6,
      "adjOffRank": 26,
      "adjTempo": 71.2,
      "adjTempoRank": 29,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 22,
      "record": "23-10",
      "seed": 5
    },
    "Coppin St.": {
      "adjDef": 107.4,
      "adjDefRank": 261,
      "adjEM": -13.18,
      "adjOff": 94.2,
      "adjOffRank": 279,
      "adjTempo": 59.5,
      "adjTempoRank": 321,
      "conference": "MEAC",
      "rank": 287,
      "record": "11-17"
    },
    "Cornell": {
      "adjDef": 107.6,
      "adjDefRank": 264,
      "adjEM": -15.11,
      "adjOff": 92.5,
      "adjOffRank": 293,
      "adjTempo": 64.9,
      "adjTempoRank": 261,
      "conference": "Ivy",
      "rank": 293,
      "record": "8-18"
    },
    "Creighton": {
      "adjDef": 94.9,
      "adjDefRank": 40,
      "adjEM": 17.54,
      "adjOff": 112.4,
      "adjOffRank": 24,
      "adjTempo": 68.4,
      "adjTempoRank": 103,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 26,
      "record": "29-5",
      "seed": 6
    },
    "Dartmouth": {
      "adjDef": 108.1,
      "adjDefRank": 271,
      "adjEM": -12.45,
      "adjOff": 95.6,
      "adjOffRank": 267,
      "adjTempo": 60.5,
      "adjTempoRank": 316,
      "conference": "Ivy",
      "rank": 284,
      "record": "8-19"
    },
    "Davidson": {
      "adjDef": 101,
      "adjDefRank": 143,
      "adjEM": 2.18,
      "adjOff": 103.2,
      "adjOffRank": 148,
      "adjTempo": 70.3,
      "adjTempoRank": 48,
      "conference": "SC",
      "rank": 143,
      "record": "14-10"
    },
    "Dayton": {
      "adjDef": 101.9,
      "adjDefRank": 161,
      "adjEM": 14.53,
      "adjOff": 116.4,
      "adjOffRank": 8,
      "adjTempo": 64.4,
      "adjTempoRank": 270,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 40,
      "record": "24-6",
      "seed": 4
    },
    "DePaul": {
      "adjDef": 95.1,
      "adjDefRank": 47,
      "adjEM": 10.04,
      "adjOff": 105.1,
      "adjOffRank": 115,
      "adjTempo": 65.8,
      "adjTempoRank": 214,
      "conference": "CUSA",
      "rank": 69,
      "record": "16-13"
    },
    "Delaware": {
      "adjDef": 99.9,
      "adjDefRank": 125,
      "adjEM": -0.43,
      "adjOff": 99.5,
      "adjOffRank": 212,
      "adjTempo": 69,
      "adjTempoRank": 79,
      "conference": "CAA",
      "rank": 171,
      "record": "15-14"
    },
    "Delaware St.": {
      "adjDef": 108.7,
      "adjDefRank": 282,
      "adjEM": -10.69,
      "adjOff": 98.1,
      "adjOffRank": 242,
      "adjTempo": 59.2,
      "adjTempoRank": 322,
      "conference": "MEAC",
      "rank": 264,
      "record": "15-12"
    },
    "Denver": {
      "adjDef": 99.8,
      "adjDefRank": 121,
      "adjEM": 2.53,
      "adjOff": 102.4,
      "adjOffRank": 162,
      "adjTempo": 67.3,
      "adjTempoRank": 153,
      "conference": "SB",
      "rank": 136,
      "record": "17-15"
    },
    "Detroit": {
      "adjDef": 99,
      "adjDefRank": 105,
      "adjEM": 10.6,
      "adjOff": 109.6,
      "adjOffRank": 48,
      "adjTempo": 64.4,
      "adjTempoRank": 272,
      "conference": "Horz",
      "rank": 63,
      "record": "18-12"
    },
    "Drake": {
      "adjDef": 107.9,
      "adjDefRank": 269,
      "adjEM": -6.22,
      "adjOff": 101.7,
      "adjOffRank": 172,
      "adjTempo": 62.3,
      "adjTempoRank": 302,
      "conference": "MVC",
      "rank": 225,
      "record": "8-20"
    },
    "Drexel": {
      "adjDef": 96.7,
      "adjDefRank": 65,
      "adjEM": 5.02,
      "adjOff": 101.7,
      "adjOffRank": 173,
      "adjTempo": 67.6,
      "adjTempoRank": 138,
      "conference": "CAA",
      "rank": 111,
      "record": "18-12"
    },
    "Duke": {
      "adjDef": 91.3,
      "adjDefRank": 15,
      "adjEM": 23.75,
      "adjOff": 115,
      "adjOffRank": 13,
      "adjTempo": 70.7,
      "adjTempoRank": 40,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 6,
      "record": "26-7",
      "seed": 3
    },
    "Duquesne": {
      "adjDef": 103.5,
      "adjDefRank": 190,
      "adjEM": -4.17,
      "adjOff": 99.4,
      "adjOffRank": 215,
      "adjTempo": 68,
      "adjTempoRank": 119,
      "conference": "A10",
      "rank": 206,
      "record": "9-21"
    },
    "East Carolina": {
      "adjDef": 99.1,
      "adjDefRank": 107,
      "adjEM": 0.22,
      "adjOff": 99.3,
      "adjOffRank": 216,
      "adjTempo": 65.2,
      "adjTempoRank": 249,
      "conference": "CUSA",
      "rank": 164,
      "record": "10-15"
    },
    "East Tennessee St.": {
      "adjDef": 99.6,
      "adjDefRank": 116,
      "adjEM": 1.75,
      "adjOff": 101.3,
      "adjOffRank": 186,
      "adjTempo": 75.2,
      "adjTempoRank": 3,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 147,
      "record": "17-11",
      "seed": 15
    },
    "Eastern Illinois": {
      "adjDef": 108.1,
      "adjDefRank": 273,
      "adjEM": -1.29,
      "adjOff": 106.8,
      "adjOffRank": 89,
      "adjTempo": 69.8,
      "adjTempoRank": 62,
      "conference": "OVC",
      "rank": 180,
      "record": "13-15"
    },
    "Eastern Kentucky": {
      "adjDef": 110.4,
      "adjDefRank": 297,
      "adjEM": -10.97,
      "adjOff": 99.5,
      "adjOffRank": 213,
      "adjTempo": 71.3,
      "adjTempoRank": 26,
      "conference": "OVC",
      "rank": 269,
      "record": "7-17"
    },
    "Eastern Michigan": {
      "adjDef": 107.8,
      "adjDefRank": 267,
      "adjEM": -3.86,
      "adjOff": 104,
      "adjOffRank": 132,
      "adjTempo": 72.8,
      "adjTempoRank": 8,
      "conference": "MAC",
      "rank": 203,
      "record": "12-14"
    },
    "Eastern Washington": {
      "adjDef": 97.6,
      "adjDefRank": 81,
      "adjEM": 5.29,
      "adjOff": 102.9,
      "adjOffRank": 155,
      "adjTempo": 67.9,
      "adjTempoRank": 123,
      "conference": "BSky",
      "rank": 109,
      "record": "17-13"
    },
    "Elon": {
      "adjDef": 107.3,
      "adjDefRank": 259,
      "adjEM": -14.35,
      "adjOff": 93,
      "adjOffRank": 291,
      "adjTempo": 63.2,
      "adjTempoRank": 294,
      "conference": "BSth",
      "rank": 292,
      "record": "10-15"
    },
    "Evansville": {
      "adjDef": 107.5,
      "adjDefRank": 263,
      "adjEM": -1.55,
      "adjOff": 105.9,
      "adjOffRank": 101,
      "adjTempo": 67.5,
      "adjTempoRank": 141,
      "conference": "MVC",
      "rank": 182,
      "record": "12-16"
    },
    "FIU": {
      "adjDef": 101.3,
      "adjDefRank": 148,
      "adjEM": -6.29,
      "adjOff": 95,
      "adjOffRank": 272,
      "adjTempo": 61.6,
      "adjTempoRank": 313,
      "conference": "SB",
      "rank": 227,
      "record": "7-21"
    },
    "Fairfield": {
      "adjDef": 98.2,
      "adjDefRank": 89,
      "adjEM": 0.94,
      "adjOff": 99.2,
      "adjOffRank": 220,
      "adjTempo": 69.2,
      "adjTempoRank": 77,
      "conference": "MAAC",
      "rank": 154,
      "record": "19-12"
    },
    "Fairleigh Dickinson": {
      "adjDef": 109,
      "adjDefRank": 286,
      "adjEM": -11.82,
      "adjOff": 97.2,
      "adjOffRank": 254,
      "adjTempo": 68,
      "adjTempoRank": 120,
      "conference": "NEC",
      "rank": 279,
      "record": "15-14"
    },
    "Florida": {
      "adjDef": 94.4,
      "adjDefRank": 36,
      "adjEM": 22.14,
      "adjOff": 116.5,
      "adjOffRank": 6,
      "adjTempo": 65,
      "adjTempoRank": 256,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 11,
      "record": "25-8",
      "seed": 2
    },
    "Florida A&M": {
      "adjDef": 109.1,
      "adjDefRank": 288,
      "adjEM": -10.99,
      "adjOff": 98.1,
      "adjOffRank": 240,
      "adjTempo": 67.5,
      "adjTempoRank": 145,
      "conference": "MEAC",
      "rank": 270,
      "record": "17-12"
    },
    "Florida Atlantic": {
      "adjDef": 105.4,
      "adjDefRank": 228,
      "adjEM": -13.37,
      "adjOff": 92.1,
      "adjOffRank": 297,
      "adjTempo": 72.1,
      "adjTempoRank": 15,
      "conference": "ASun",
      "rank": 288,
      "record": "6-21"
    },
    "Florida St.": {
      "adjDef": 92,
      "adjDefRank": 18,
      "adjEM": 8.84,
      "adjOff": 100.9,
      "adjOffRank": 194,
      "adjTempo": 67.5,
      "adjTempoRank": 144,
      "conference": "ACC",
      "rank": 77,
      "record": "14-15"
    },
    "Fordham": {
      "adjDef": 107.4,
      "adjDefRank": 262,
      "adjEM": -10.22,
      "adjOff": 97.2,
      "adjOffRank": 253,
      "adjTempo": 69.7,
      "adjTempoRank": 64,
      "conference": "A10",
      "rank": 258,
      "record": "2-26"
    },
    "Fresno St.": {
      "adjDef": 98.9,
      "adjDefRank": 104,
      "adjEM": 6.26,
      "adjOff": 105.2,
      "adjOffRank": 112,
      "adjTempo": 65.7,
      "adjTempoRank": 221,
      "conference": "WAC",
      "rank": 98,
      "record": "20-8"
    },
    "Furman": {
      "adjDef": 105.2,
      "adjDefRank": 227,
      "adjEM": -7.37,
      "adjOff": 97.9,
      "adjOffRank": 244,
      "adjTempo": 62.9,
      "adjTempoRank": 296,
      "conference": "SC",
      "rank": 233,
      "record": "11-17"
    },
    "Gardner Webb": {
      "adjDef": 102.3,
      "adjDefRank": 169,
      "adjEM": -11.64,
      "adjOff": 90.7,
      "adjOffRank": 305,
      "adjTempo": 70.8,
      "adjTempoRank": 36,
      "conference": "ASun",
      "rank": 277,
      "record": "4-24"
    },
    "George Mason": {
      "adjDef": 93.8,
      "adjDefRank": 27,
      "adjEM": 4.26,
      "adjOff": 98.1,
      "adjOffRank": 241,
      "adjTempo": 64.9,
      "adjTempoRank": 262,
      "conference": "CAA",
      "rank": 117,
      "record": "16-12"
    },
    "George Washington": {
      "adjDef": 102.1,
      "adjDefRank": 163,
      "adjEM": 5.18,
      "adjOff": 107.2,
      "adjOffRank": 83,
      "adjTempo": 68.6,
      "adjTempoRank": 96,
      "conference": "A10",
      "rank": 110,
      "record": "12-17"
    },
    "Georgetown": {
      "adjDef": 92.2,
      "adjDefRank": 19,
      "adjEM": 14.91,
      "adjOff": 107.1,
      "adjOffRank": 86,
      "adjTempo": 70.7,
      "adjTempoRank": 38,
      "conference": "BE",
      "rank": 35,
      "record": "19-15"
    },
    "Georgia": {
      "adjDef": 99.9,
      "adjDefRank": 124,
      "adjEM": 20.93,
      "adjOff": 120.9,
      "adjOffRank": 1,
      "adjTempo": 67.1,
      "adjTempoRank": 159,
      "conference": "SEC",
      "rank": 16,
      "record": "19-8"
    },
    "Georgia Southern": {
      "adjDef": 109.2,
      "adjDefRank": 291,
      "adjEM": -6.97,
      "adjOff": 102.2,
      "adjOffRank": 164,
      "adjTempo": 71.6,
      "adjTempoRank": 22,
      "conference": "SC",
      "rank": 231,
      "record": "13-13"
    },
    "Georgia St.": {
      "adjDef": 107.6,
      "adjDefRank": 265,
      "adjEM": -6.14,
      "adjOff": 101.5,
      "adjOffRank": 178,
      "adjTempo": 66.7,
      "adjTempoRank": 180,
      "conference": "ASun",
      "rank": 224,
      "record": "13-15"
    },
    "Georgia Tech": {
      "adjDef": 94.8,
      "adjDefRank": 39,
      "adjEM": 14.23,
      "adjOff": 109.1,
      "adjOffRank": 57,
      "adjTempo": 69,
      "adjTempoRank": 80,
      "conference": "ACC",
      "rank": 43,
      "record": "16-15"
    },
    "Gonzaga": {
      "adjDef": 97.7,
      "adjDefRank": 82,
      "adjEM": 15.58,
      "adjOff": 113.2,
      "adjOffRank": 21,
      "adjTempo": 66.9,
      "adjTempoRank": 170,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 33,
      "record": "24-9",
      "seed": 9
    },
    "Grambling St.": {
      "adjDef": 111.8,
      "adjDefRank": 308,
      "adjEM": -16.05,
      "adjOff": 95.7,
      "adjOffRank": 265,
      "adjTempo": 71.9,
      "adjTempoRank": 17,
      "conference": "SWAC",
      "rank": 298,
      "record": "10-18"
    },
    "Green Bay": {
      "adjDef": 107.1,
      "adjDefRank": 255,
      "adjEM": -3.76,
      "adjOff": 103.3,
      "adjOffRank": 145,
      "adjTempo": 64.9,
      "adjTempoRank": 259,
      "conference": "Horz",
      "rank": 201,
      "record": "9-19"
    },
    "Hampton": {
      "adjDef": 102.9,
      "adjDefRank": 177,
      "adjEM": -8.14,
      "adjOff": 94.8,
      "adjOffRank": 275,
      "adjTempo": 69.9,
      "adjTempoRank": 58,
      "conference": "MEAC",
      "rank": 241,
      "record": "19-11"
    },
    "Hartford": {
      "adjDef": 101.9,
      "adjDefRank": 160,
      "adjEM": -3.68,
      "adjOff": 98.2,
      "adjOffRank": 239,
      "adjTempo": 64.1,
      "adjTempoRank": 280,
      "conference": "AE",
      "rank": 199,
      "record": "15-13"
    },
    "Harvard": {
      "adjDef": 104,
      "adjDefRank": 194,
      "adjEM": -5.69,
      "adjOff": 98.3,
      "adjOffRank": 236,
      "adjTempo": 71.9,
      "adjTempoRank": 19,
      "conference": "Ivy",
      "rank": 218,
      "record": "12-15"
    },
    "Hawaii": {
      "adjDef": 98.5,
      "adjDefRank": 95,
      "adjEM": 4.33,
      "adjOff": 102.9,
      "adjOffRank": 156,
      "adjTempo": 65.8,
      "adjTempoRank": 213,
      "conference": "WAC",
      "rank": 115,
      "record": "19-12"
    },
    "High Point": {
      "adjDef": 113.4,
      "adjDefRank": 318,
      "adjEM": -17.75,
      "adjOff": 95.6,
      "adjOffRank": 268,
      "adjTempo": 68.2,
      "adjTempoRank": 111,
      "conference": "BSth",
      "rank": 307,
      "record": "7-20"
    },
    "Hofstra": {
      "adjDef": 104.7,
      "adjDefRank": 211,
      "adjEM": -9.69,
      "adjOff": 95,
      "adjOffRank": 273,
      "adjTempo": 68.3,
      "adjTempoRank": 107,
      "conference": "CAA",
      "rank": 255,
      "record": "8-21"
    },
    "Holy Cross": {
      "adjDef": 95.1,
      "adjDefRank": 45,
      "adjEM": 10.4,
      "adjOff": 105.5,
      "adjOffRank": 107,
      "adjTempo": 65.1,
      "adjTempoRank": 250,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 66,
      "record": "25-5",
      "seed": 14
    },
    "Houston": {
      "adjDef": 98,
      "adjDefRank": 87,
      "adjEM": -4.84,
      "adjOff": 93.2,
      "adjOffRank": 289,
      "adjTempo": 63.2,
      "adjTempoRank": 292,
      "conference": "CUSA",
      "rank": 212,
      "record": "8-20"
    },
    "Howard": {
      "adjDef": 109.2,
      "adjDefRank": 289,
      "adjEM": -11.57,
      "adjOff": 97.6,
      "adjOffRank": 249,
      "adjTempo": 69.8,
      "adjTempoRank": 61,
      "conference": "MEAC",
      "rank": 276,
      "record": "13-17"
    },
    "IPFW": {
      "adjDef": 106.9,
      "adjDefRank": 252,
      "adjEM": -11.14,
      "adjOff": 95.8,
      "adjOffRank": 264,
      "adjTempo": 68.6,
      "adjTempoRank": 94,
      "conference": "ind",
      "rank": 272,
      "record": "9-20"
    },
    "IUPUI": {
      "adjDef": 102.5,
      "adjDefRank": 171,
      "adjEM": 0.46,
      "adjOff": 102.9,
      "adjOffRank": 153,
      "adjTempo": 67,
      "adjTempoRank": 164,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 161,
      "record": "19-14",
      "seed": 16
    },
    "Idaho": {
      "adjDef": 101,
      "adjDefRank": 142,
      "adjEM": -4.61,
      "adjOff": 96.4,
      "adjOffRank": 261,
      "adjTempo": 62.3,
      "adjTempoRank": 301,
      "conference": "BW",
      "rank": 210,
      "record": "12-15"
    },
    "Idaho St.": {
      "adjDef": 101.8,
      "adjDefRank": 158,
      "adjEM": -2.21,
      "adjOff": 99.6,
      "adjOffRank": 208,
      "adjTempo": 70.4,
      "adjTempoRank": 46,
      "conference": "BSky",
      "rank": 186,
      "record": "13-14"
    },
    "Illinois": {
      "adjDef": 88.7,
      "adjDefRank": 7,
      "adjEM": 24.47,
      "adjOff": 113.2,
      "adjOffRank": 22,
      "adjTempo": 67.8,
      "adjTempoRank": 127,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 5,
      "record": "25-7",
      "seed": 4
    },
    "Illinois Chicago": {
      "adjDef": 99.9,
      "adjDefRank": 123,
      "adjEM": 10.64,
      "adjOff": 110.5,
      "adjOffRank": 38,
      "adjTempo": 68.4,
      "adjTempoRank": 106,
      "conference": "Horz",
      "rank": 62,
      "record": "21-9"
    },
    "Illinois St.": {
      "adjDef": 104.9,
      "adjDefRank": 217,
      "adjEM": -5.44,
      "adjOff": 99.4,
      "adjOffRank": 214,
      "adjTempo": 65.2,
      "adjTempoRank": 247,
      "conference": "MVC",
      "rank": 215,
      "record": "8-21"
    },
    "Indiana": {
      "adjDef": 98.9,
      "adjDefRank": 102,
      "adjEM": 14.48,
      "adjOff": 113.4,
      "adjOffRank": 20,
      "adjTempo": 63.7,
      "adjTempoRank": 284,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 41,
      "record": "21-13",
      "seed": 7
    },
    "Indiana St.": {
      "adjDef": 101.7,
      "adjDefRank": 155,
      "adjEM": -10.79,
      "adjOff": 90.9,
      "adjOffRank": 304,
      "adjTempo": 62.1,
      "adjTempoRank": 305,
      "conference": "MVC",
      "rank": 266,
      "record": "7-24"
    },
    "Iona": {
      "adjDef": 101.7,
      "adjDefRank": 154,
      "adjEM": 0.55,
      "adjOff": 102.2,
      "adjOffRank": 165,
      "adjTempo": 69,
      "adjTempoRank": 82,
      "conference": "MAAC",
      "rank": 159,
      "record": "17-12"
    },
    "Iowa": {
      "adjDef": 97.9,
      "adjDefRank": 85,
      "adjEM": 8.5,
      "adjOff": 106.4,
      "adjOffRank": 96,
      "adjTempo": 67.1,
      "adjTempoRank": 160,
      "conference": "B10",
      "rank": 78,
      "record": "17-14"
    },
    "Iowa St.": {
      "adjDef": 95.7,
      "adjDefRank": 53,
      "adjEM": 10.59,
      "adjOff": 106.3,
      "adjOffRank": 98,
      "adjTempo": 66.3,
      "adjTempoRank": 192,
      "conference": "B12",
      "rank": 64,
      "record": "16-14"
    },
    "Jackson St.": {
      "adjDef": 104.9,
      "adjDefRank": 216,
      "adjEM": -10.84,
      "adjOff": 94,
      "adjOffRank": 281,
      "adjTempo": 71.9,
      "adjTempoRank": 18,
      "conference": "SWAC",
      "rank": 267,
      "record": "10-18"
    },
    "Jacksonville": {
      "adjDef": 104,
      "adjDefRank": 193,
      "adjEM": -10.57,
      "adjOff": 93.4,
      "adjOffRank": 286,
      "adjTempo": 66.3,
      "adjTempoRank": 194,
      "conference": "ASun",
      "rank": 263,
      "record": "12-16"
    },
    "Jacksonville St.": {
      "adjDef": 106.2,
      "adjDefRank": 240,
      "adjEM": -2.72,
      "adjOff": 103.5,
      "adjOffRank": 143,
      "adjTempo": 68.4,
      "adjTempoRank": 102,
      "conference": "ASun",
      "rank": 191,
      "record": "19-10"
    },
    "James Madison": {
      "adjDef": 105.2,
      "adjDefRank": 225,
      "adjEM": -5.66,
      "adjOff": 99.5,
      "adjOffRank": 210,
      "adjTempo": 66.2,
      "adjTempoRank": 196,
      "conference": "CAA",
      "rank": 217,
      "record": "12-17"
    },
    "Kansas": {
      "adjDef": 86.4,
      "adjDefRank": 3,
      "adjEM": 28.62,
      "adjOff": 115,
      "adjOffRank": 12,
      "adjTempo": 72.2,
      "adjTempoRank": 14,
      "conference": "B12",
      "finish": "Runner-up",
      "rank": 2,
      "record": "30-8",
      "seed": 2
    },
    "Kansas St.": {
      "adjDef": 97,
      "adjDefRank": 70,
      "adjEM": 10.87,
      "adjOff": 107.9,
      "adjOffRank": 73,
      "adjTempo": 65.7,
      "adjTempoRank": 222,
      "conference": "B12",
      "rank": 60,
      "record": "13-17"
    },
    "Kent St.": {
      "adjDef": 104.7,
      "adjDefRank": 210,
      "adjEM": 6.51,
      "adjOff": 111.2,
      "adjOffRank": 32,
      "adjTempo": 66.7,
      "adjTempoRank": 179,
      "conference": "MAC",
      "rank": 94,
      "record": "20-10"
    },
    "Kentucky": {
      "adjDef": 87.4,
      "adjDefRank": 4,
      "adjEM": 29.18,
      "adjOff": 116.5,
      "adjOffRank": 5,
      "adjTempo": 67.7,
      "adjTempoRank": 132,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 1,
      "record": "32-4",
      "seed": 1
    },
    "LIU Brooklyn": {
      "adjDef": 110.7,
      "adjDefRank": 298,
      "adjEM": -12.85,
      "adjOff": 97.8,
      "adjOffRank": 245,
      "adjTempo": 73.2,
      "adjTempoRank": 6,
      "conference": "NEC",
      "rank": 286,
      "record": "9-19"
    },
    "LSU": {
      "adjDef": 92.8,
      "adjDefRank": 24,
      "adjEM": 18.15,
      "adjOff": 111,
      "adjOffRank": 33,
      "adjTempo": 67.5,
      "adjTempoRank": 140,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "21-11",
      "seed": 8
    },
    "La Salle": {
      "adjDef": 102.1,
      "adjDefRank": 164,
      "adjEM": 0.27,
      "adjOff": 102.4,
      "adjOffRank": 160,
      "adjTempo": 67.6,
      "adjTempoRank": 137,
      "conference": "A10",
      "rank": 163,
      "record": "12-17"
    },
    "Lafayette": {
      "adjDef": 110.3,
      "adjDefRank": 294,
      "adjEM": -8.98,
      "adjOff": 101.3,
      "adjOffRank": 185,
      "adjTempo": 66.4,
      "adjTempoRank": 189,
      "conference": "Pat",
      "rank": 247,
      "record": "11-16"
    },
    "Lamar": {
      "adjDef": 101.3,
      "adjDefRank": 147,
      "adjEM": -8.57,
      "adjOff": 92.7,
      "adjOffRank": 292,
      "adjTempo": 66.1,
      "adjTempoRank": 199,
      "conference": "Slnd",
      "rank": 243,
      "record": "12-14"
    },
    "Lehigh": {
      "adjDef": 104.2,
      "adjDefRank": 201,
      "adjEM": -5.94,
      "adjOff": 98.3,
      "adjOffRank": 237,
      "adjTempo": 65.2,
      "adjTempoRank": 246,
      "conference": "Pat",
      "rank": 223,
      "record": "14-12"
    },
    "Liberty": {
      "adjDef": 104.6,
      "adjDefRank": 206,
      "adjEM": -15.63,
      "adjOff": 89,
      "adjOffRank": 314,
      "adjTempo": 68.5,
      "adjTempoRank": 99,
      "conference": "BSth",
      "rank": 297,
      "record": "14-15"
    },
    "Lipscomb": {
      "adjDef": 115.1,
      "adjDefRank": 321,
      "adjEM": -16.28,
      "adjOff": 98.8,
      "adjOffRank": 226,
      "adjTempo": 69.9,
      "adjTempoRank": 57,
      "conference": "ind",
      "rank": 300,
      "record": "6-19"
    },
    "Long Beach St.": {
      "adjDef": 112.3,
      "adjDefRank": 313,
      "adjEM": -15.41,
      "adjOff": 96.9,
      "adjOffRank": 257,
      "adjTempo": 65.6,
      "adjTempoRank": 227,
      "conference": "BW",
      "rank": 295,
      "record": "4-22"
    },
    "Louisiana Lafayette": {
      "adjDef": 97.4,
      "adjDefRank": 76,
      "adjEM": 10.52,
      "adjOff": 107.9,
      "adjOffRank": 71,
      "adjTempo": 69.8,
      "adjTempoRank": 60,
      "conference": "SB",
      "rank": 65,
      "record": "19-10"
    },
    "Louisiana Monroe": {
      "adjDef": 104,
      "adjDefRank": 196,
      "adjEM": -9.61,
      "adjOff": 94.4,
      "adjOffRank": 276,
      "adjTempo": 72.7,
      "adjTempoRank": 10,
      "conference": "Slnd",
      "rank": 254,
      "record": "11-16"
    },
    "Louisiana Tech": {
      "adjDef": 104.1,
      "adjDefRank": 197,
      "adjEM": -2.35,
      "adjOff": 101.8,
      "adjOffRank": 171,
      "adjTempo": 67.6,
      "adjTempoRank": 135,
      "conference": "WAC",
      "rank": 188,
      "record": "10-15"
    },
    "Louisville": {
      "adjDef": 93,
      "adjDefRank": 25,
      "adjEM": 22.75,
      "adjOff": 115.8,
      "adjOffRank": 9,
      "adjTempo": 72.5,
      "adjTempoRank": 12,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 10,
      "record": "25-7",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 101.9,
      "adjDefRank": 159,
      "adjEM": 3.85,
      "adjOff": 105.7,
      "adjOffRank": 103,
      "adjTempo": 70.1,
      "adjTempoRank": 52,
      "conference": "Horz",
      "rank": 124,
      "record": "15-16"
    },
    "Loyola MD": {
      "adjDef": 110.4,
      "adjDefRank": 295,
      "adjEM": -22.7,
      "adjOff": 87.7,
      "adjOffRank": 317,
      "adjTempo": 63.9,
      "adjTempoRank": 283,
      "conference": "MAAC",
      "rank": 315,
      "record": "4-24"
    },
    "Loyola Marymount": {
      "adjDef": 102.5,
      "adjDefRank": 173,
      "adjEM": -4.09,
      "adjOff": 98.4,
      "adjOffRank": 233,
      "adjTempo": 69.8,
      "adjTempoRank": 59,
      "conference": "WCC",
      "rank": 204,
      "record": "11-19"
    },
    "Maine": {
      "adjDef": 102.9,
      "adjDefRank": 175,
      "adjEM": -3.32,
      "adjOff": 99.5,
      "adjOffRank": 209,
      "adjTempo": 68.3,
      "adjTempoRank": 108,
      "conference": "AE",
      "rank": 197,
      "record": "12-16"
    },
    "Manhattan": {
      "adjDef": 95.4,
      "adjDefRank": 52,
      "adjEM": 10.04,
      "adjOff": 105.5,
      "adjOffRank": 106,
      "adjTempo": 68.1,
      "adjTempoRank": 117,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 68,
      "record": "23-7",
      "seed": 14
    },
    "Marist": {
      "adjDef": 106.7,
      "adjDefRank": 247,
      "adjEM": -5.31,
      "adjOff": 101.4,
      "adjOffRank": 183,
      "adjTempo": 68.6,
      "adjTempoRank": 95,
      "conference": "MAAC",
      "rank": 213,
      "record": "13-16"
    },
    "Marquette": {
      "adjDef": 99.2,
      "adjDefRank": 109,
      "adjEM": 21.3,
      "adjOff": 120.5,
      "adjOffRank": 2,
      "adjTempo": 65.7,
      "adjTempoRank": 219,
      "conference": "CUSA",
      "finish": "Final Four",
      "rank": 15,
      "record": "27-6",
      "seed": 3
    },
    "Marshall": {
      "adjDef": 102.9,
      "adjDefRank": 178,
      "adjEM": -0.77,
      "adjOff": 102.2,
      "adjOffRank": 166,
      "adjTempo": 67.5,
      "adjTempoRank": 143,
      "conference": "MAC",
      "rank": 174,
      "record": "13-15"
    },
    "Maryland": {
      "adjDef": 88.9,
      "adjDefRank": 9,
      "adjEM": 22,
      "adjOff": 110.9,
      "adjOffRank": 34,
      "adjTempo": 71.5,
      "adjTempoRank": 23,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "21-10",
      "seed": 6
    },
    "Maryland Eastern Shore": {
      "adjDef": 116.1,
      "adjDefRank": 322,
      "adjEM": -24.66,
      "adjOff": 91.4,
      "adjOffRank": 300,
      "adjTempo": 64,
      "adjTempoRank": 282,
      "conference": "MEAC",
      "rank": 319,
      "record": "5-23"
    },
    "Massachusetts": {
      "adjDef": 99.8,
      "adjDefRank": 120,
      "adjEM": -3.25,
      "adjOff": 96.5,
      "adjOffRank": 259,
      "adjTempo": 64.9,
      "adjTempoRank": 258,
      "conference": "A10",
      "rank": 195,
      "record": "10-18"
    },
    "McNeese St.": {
      "adjDef": 105.2,
      "adjDefRank": 226,
      "adjEM": -4.78,
      "adjOff": 100.4,
      "adjOffRank": 203,
      "adjTempo": 67.9,
      "adjTempoRank": 122,
      "conference": "Slnd",
      "rank": 211,
      "record": "13-14"
    },
    "Memphis": {
      "adjDef": 94.1,
      "adjDefRank": 33,
      "adjEM": 16.72,
      "adjOff": 110.9,
      "adjOffRank": 35,
      "adjTempo": 68.3,
      "adjTempoRank": 109,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 29,
      "record": "23-7",
      "seed": 7
    },
    "Mercer": {
      "adjDef": 107.3,
      "adjDefRank": 257,
      "adjEM": -0.84,
      "adjOff": 106.4,
      "adjOffRank": 95,
      "adjTempo": 69.5,
      "adjTempoRank": 65,
      "conference": "ASun",
      "rank": 175,
      "record": "22-6"
    },
    "Miami FL": {
      "adjDef": 97.2,
      "adjDefRank": 74,
      "adjEM": 6.1,
      "adjOff": 103.3,
      "adjOffRank": 146,
      "adjTempo": 65.8,
      "adjTempoRank": 212,
      "conference": "BE",
      "rank": 100,
      "record": "11-17"
    },
    "Miami OH": {
      "adjDef": 98.7,
      "adjDefRank": 99,
      "adjEM": 2.42,
      "adjOff": 101.1,
      "adjOffRank": 190,
      "adjTempo": 56.7,
      "adjTempoRank": 326,
      "conference": "MAC",
      "rank": 139,
      "record": "12-15"
    },
    "Michigan": {
      "adjDef": 98.3,
      "adjDefRank": 91,
      "adjEM": 10.71,
      "adjOff": 109,
      "adjOffRank": 59,
      "adjTempo": 65.1,
      "adjTempoRank": 252,
      "conference": "B10",
      "rank": 61,
      "record": "17-13"
    },
    "Michigan St.": {
      "adjDef": 89.9,
      "adjDefRank": 12,
      "adjEM": 20.26,
      "adjOff": 110.1,
      "adjOffRank": 43,
      "adjTempo": 63.6,
      "adjTempoRank": 286,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 19,
      "record": "22-13",
      "seed": 7
    },
    "Middle Tennessee St.": {
      "adjDef": 100.5,
      "adjDefRank": 131,
      "adjEM": 3.36,
      "adjOff": 103.8,
      "adjOffRank": 133,
      "adjTempo": 66,
      "adjTempoRank": 206,
      "conference": "SB",
      "rank": 126,
      "record": "14-14"
    },
    "Milwaukee": {
      "adjDef": 96,
      "adjDefRank": 59,
      "adjEM": 12.97,
      "adjOff": 109,
      "adjOffRank": 60,
      "adjTempo": 71.2,
      "adjTempoRank": 31,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 52,
      "record": "23-8",
      "seed": 12
    },
    "Minnesota": {
      "adjDef": 97.7,
      "adjDefRank": 83,
      "adjEM": 9.68,
      "adjOff": 107.4,
      "adjOffRank": 80,
      "adjTempo": 70.7,
      "adjTempoRank": 39,
      "conference": "B10",
      "rank": 72,
      "record": "19-14"
    },
    "Mississippi": {
      "adjDef": 100.3,
      "adjDefRank": 130,
      "adjEM": 10.89,
      "adjOff": 111.2,
      "adjOffRank": 31,
      "adjTempo": 62.8,
      "adjTempoRank": 298,
      "conference": "SEC",
      "rank": 59,
      "record": "14-15"
    },
    "Mississippi St.": {
      "adjDef": 85.8,
      "adjDefRank": 1,
      "adjEM": 21.53,
      "adjOff": 107.4,
      "adjOffRank": 79,
      "adjTempo": 64.8,
      "adjTempoRank": 263,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 14,
      "record": "21-10",
      "seed": 5
    },
    "Mississippi Valley St.": {
      "adjDef": 112,
      "adjDefRank": 312,
      "adjEM": -7.83,
      "adjOff": 104.2,
      "adjOffRank": 130,
      "adjTempo": 67.6,
      "adjTempoRank": 139,
      "conference": "SWAC",
      "rank": 236,
      "record": "13-13"
    },
    "Missouri": {
      "adjDef": 94.2,
      "adjDefRank": 34,
      "adjEM": 17.32,
      "adjOff": 111.5,
      "adjOffRank": 27,
      "adjTempo": 66.9,
      "adjTempoRank": 173,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 28,
      "record": "22-11",
      "seed": 6
    },
    "Monmouth": {
      "adjDef": 100.1,
      "adjDefRank": 127,
      "adjEM": -8.15,
      "adjOff": 91.9,
      "adjOffRank": 298,
      "adjTempo": 66.8,
      "adjTempoRank": 174,
      "conference": "NEC",
      "rank": 242,
      "record": "15-13"
    },
    "Montana": {
      "adjDef": 107.1,
      "adjDefRank": 254,
      "adjEM": -2.75,
      "adjOff": 104.3,
      "adjOffRank": 127,
      "adjTempo": 66.1,
      "adjTempoRank": 204,
      "conference": "BSky",
      "rank": 193,
      "record": "12-16"
    },
    "Montana St.": {
      "adjDef": 105.9,
      "adjDefRank": 237,
      "adjEM": -5.7,
      "adjOff": 100.2,
      "adjOffRank": 205,
      "adjTempo": 61.9,
      "adjTempoRank": 311,
      "conference": "BSky",
      "rank": 219,
      "record": "9-16"
    },
    "Morehead St.": {
      "adjDef": 108.1,
      "adjDefRank": 272,
      "adjEM": 1.53,
      "adjOff": 109.6,
      "adjOffRank": 47,
      "adjTempo": 67.6,
      "adjTempoRank": 136,
      "conference": "OVC",
      "rank": 151,
      "record": "17-9"
    },
    "Morgan St.": {
      "adjDef": 117.4,
      "adjDefRank": 325,
      "adjEM": -25.36,
      "adjOff": 92.1,
      "adjOffRank": 296,
      "adjTempo": 68.7,
      "adjTempoRank": 88,
      "conference": "MEAC",
      "rank": 320,
      "record": "7-22"
    },
    "Morris Brown": {
      "adjDef": 114.4,
      "adjDefRank": 320,
      "adjEM": -27.55,
      "adjOff": 86.9,
      "adjOffRank": 320,
      "adjTempo": 65.4,
      "adjTempoRank": 240,
      "conference": "ind",
      "rank": 325,
      "record": "4-20"
    },
    "Mount St. Mary's": {
      "adjDef": 106.7,
      "adjDefRank": 248,
      "adjEM": -16.16,
      "adjOff": 90.5,
      "adjOffRank": 306,
      "adjTempo": 64.2,
      "adjTempoRank": 276,
      "conference": "NEC",
      "rank": 299,
      "record": "11-16"
    },
    "Murray St.": {
      "adjDef": 101.8,
      "adjDefRank": 157,
      "adjEM": 5.53,
      "adjOff": 107.3,
      "adjOffRank": 81,
      "adjTempo": 65.5,
      "adjTempoRank": 233,
      "conference": "OVC",
      "rank": 106,
      "record": "15-12"
    },
    "N.C. State": {
      "adjDef": 98.1,
      "adjDefRank": 88,
      "adjEM": 13.18,
      "adjOff": 111.2,
      "adjOffRank": 30,
      "adjTempo": 65,
      "adjTempoRank": 257,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "18-13",
      "seed": 9
    },
    "Navy": {
      "adjDef": 104.7,
      "adjDefRank": 208,
      "adjEM": -14.11,
      "adjOff": 90.5,
      "adjOffRank": 307,
      "adjTempo": 65.6,
      "adjTempoRank": 232,
      "conference": "Pat",
      "rank": 290,
      "record": "7-19"
    },
    "Nebraska": {
      "adjDef": 94.9,
      "adjDefRank": 42,
      "adjEM": 2.47,
      "adjOff": 97.4,
      "adjOffRank": 252,
      "adjTempo": 66.6,
      "adjTempoRank": 183,
      "conference": "B12",
      "rank": 138,
      "record": "11-18"
    },
    "Nevada": {
      "adjDef": 99.9,
      "adjDefRank": 122,
      "adjEM": 8.19,
      "adjOff": 108,
      "adjOffRank": 64,
      "adjTempo": 68.8,
      "adjTempoRank": 85,
      "conference": "WAC",
      "rank": 83,
      "record": "17-14"
    },
    "New Hampshire": {
      "adjDef": 112.5,
      "adjDefRank": 314,
      "adjEM": -18.5,
      "adjOff": 94,
      "adjOffRank": 282,
      "adjTempo": 70.4,
      "adjTempoRank": 45,
      "conference": "AE",
      "rank": 310,
      "record": "5-23"
    },
    "New Mexico": {
      "adjDef": 110,
      "adjDefRank": 293,
      "adjEM": -2.27,
      "adjOff": 107.7,
      "adjOffRank": 75,
      "adjTempo": 65.6,
      "adjTempoRank": 228,
      "conference": "MWC",
      "rank": 187,
      "record": "9-18"
    },
    "New Mexico St.": {
      "adjDef": 100.2,
      "adjDefRank": 128,
      "adjEM": 7.51,
      "adjOff": 107.7,
      "adjOffRank": 78,
      "adjTempo": 67.2,
      "adjTempoRank": 157,
      "conference": "SB",
      "rank": 87,
      "record": "19-9"
    },
    "New Orleans": {
      "adjDef": 102.2,
      "adjDefRank": 167,
      "adjEM": -0.49,
      "adjOff": 101.7,
      "adjOffRank": 175,
      "adjTempo": 66.3,
      "adjTempoRank": 193,
      "conference": "SB",
      "rank": 172,
      "record": "13-14"
    },
    "Niagara": {
      "adjDef": 110.4,
      "adjDefRank": 296,
      "adjEM": -0.9,
      "adjOff": 109.5,
      "adjOffRank": 50,
      "adjTempo": 66.5,
      "adjTempoRank": 184,
      "conference": "MAAC",
      "rank": 177,
      "record": "17-12"
    },
    "Nicholls St.": {
      "adjDef": 113,
      "adjDefRank": 315,
      "adjEM": -25.55,
      "adjOff": 87.5,
      "adjOffRank": 318,
      "adjTempo": 62.1,
      "adjTempoRank": 307,
      "conference": "Slnd",
      "rank": 321,
      "record": "1-25"
    },
    "Norfolk St.": {
      "adjDef": 103.4,
      "adjDefRank": 187,
      "adjEM": -15.22,
      "adjOff": 88.1,
      "adjOffRank": 316,
      "adjTempo": 65.7,
      "adjTempoRank": 218,
      "conference": "MEAC",
      "rank": 294,
      "record": "14-15"
    },
    "North Carolina": {
      "adjDef": 95.8,
      "adjDefRank": 57,
      "adjEM": 12.15,
      "adjOff": 107.9,
      "adjOffRank": 72,
      "adjTempo": 68.3,
      "adjTempoRank": 110,
      "conference": "ACC",
      "rank": 54,
      "record": "19-16"
    },
    "North Carolina A&T": {
      "adjDef": 119.4,
      "adjDefRank": 327,
      "adjEM": -35.37,
      "adjOff": 84.1,
      "adjOffRank": 324,
      "adjTempo": 68.7,
      "adjTempoRank": 92,
      "conference": "MEAC",
      "rank": 327,
      "record": "1-25"
    },
    "North Texas": {
      "adjDef": 106.6,
      "adjDefRank": 246,
      "adjEM": -7.31,
      "adjOff": 99.3,
      "adjOffRank": 217,
      "adjTempo": 70.2,
      "adjTempoRank": 50,
      "conference": "SB",
      "rank": 232,
      "record": "5-21"
    },
    "Northeastern": {
      "adjDef": 104.2,
      "adjDefRank": 200,
      "adjEM": -5.33,
      "adjOff": 98.9,
      "adjOffRank": 225,
      "adjTempo": 70.1,
      "adjTempoRank": 53,
      "conference": "AE",
      "rank": 214,
      "record": "14-15"
    },
    "Northern Arizona": {
      "adjDef": 102.1,
      "adjDefRank": 165,
      "adjEM": -2.6,
      "adjOff": 99.5,
      "adjOffRank": 211,
      "adjTempo": 68.1,
      "adjTempoRank": 115,
      "conference": "BSky",
      "rank": 190,
      "record": "13-13"
    },
    "Northern Illinois": {
      "adjDef": 102.1,
      "adjDefRank": 162,
      "adjEM": 2.52,
      "adjOff": 104.6,
      "adjOffRank": 123,
      "adjTempo": 64.8,
      "adjTempoRank": 264,
      "conference": "MAC",
      "rank": 137,
      "record": "17-14"
    },
    "Northern Iowa": {
      "adjDef": 104.8,
      "adjDefRank": 214,
      "adjEM": -2.78,
      "adjOff": 102,
      "adjOffRank": 169,
      "adjTempo": 64.6,
      "adjTempoRank": 269,
      "conference": "MVC",
      "rank": 194,
      "record": "10-17"
    },
    "Northwestern": {
      "adjDef": 104.9,
      "adjDefRank": 218,
      "adjEM": 0.05,
      "adjOff": 105,
      "adjOffRank": 117,
      "adjTempo": 60.4,
      "adjTempoRank": 317,
      "conference": "B10",
      "rank": 167,
      "record": "12-17"
    },
    "Northwestern St.": {
      "adjDef": 102.1,
      "adjDefRank": 166,
      "adjEM": -16.93,
      "adjOff": 85.2,
      "adjOffRank": 322,
      "adjTempo": 70.6,
      "adjTempoRank": 42,
      "conference": "Slnd",
      "rank": 305,
      "record": "6-21"
    },
    "Notre Dame": {
      "adjDef": 96.2,
      "adjDefRank": 60,
      "adjEM": 20.31,
      "adjOff": 116.5,
      "adjOffRank": 7,
      "adjTempo": 69.2,
      "adjTempoRank": 74,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "24-10",
      "seed": 5
    },
    "Oakland": {
      "adjDef": 105.7,
      "adjDefRank": 231,
      "adjEM": -2.07,
      "adjOff": 103.7,
      "adjOffRank": 136,
      "adjTempo": 71.3,
      "adjTempoRank": 25,
      "conference": "MCon",
      "rank": 185,
      "record": "15-11"
    },
    "Ohio": {
      "adjDef": 104.7,
      "adjDefRank": 212,
      "adjEM": -0.22,
      "adjOff": 104.5,
      "adjOffRank": 124,
      "adjTempo": 65.6,
      "adjTempoRank": 229,
      "conference": "MAC",
      "rank": 168,
      "record": "14-16"
    },
    "Ohio St.": {
      "adjDef": 94.3,
      "adjDefRank": 35,
      "adjEM": 8.93,
      "adjOff": 103.2,
      "adjOffRank": 147,
      "adjTempo": 64.2,
      "adjTempoRank": 277,
      "conference": "B10",
      "rank": 75,
      "record": "17-15"
    },
    "Oklahoma": {
      "adjDef": 89.1,
      "adjDefRank": 10,
      "adjEM": 23.01,
      "adjOff": 112.1,
      "adjOffRank": 25,
      "adjTempo": 62.7,
      "adjTempoRank": 299,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "27-7",
      "seed": 1
    },
    "Oklahoma St.": {
      "adjDef": 88.2,
      "adjDefRank": 5,
      "adjEM": 17.67,
      "adjOff": 105.9,
      "adjOffRank": 100,
      "adjTempo": 66.7,
      "adjTempoRank": 177,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 25,
      "record": "22-10",
      "seed": 6
    },
    "Old Dominion": {
      "adjDef": 98.9,
      "adjDefRank": 103,
      "adjEM": 0.3,
      "adjOff": 99.2,
      "adjOffRank": 219,
      "adjTempo": 66.6,
      "adjTempoRank": 182,
      "conference": "CAA",
      "rank": 162,
      "record": "12-15"
    },
    "Oral Roberts": {
      "adjDef": 103.4,
      "adjDefRank": 188,
      "adjEM": 1.21,
      "adjOff": 104.6,
      "adjOffRank": 121,
      "adjTempo": 68.1,
      "adjTempoRank": 116,
      "conference": "MCon",
      "rank": 153,
      "record": "17-10"
    },
    "Oregon": {
      "adjDef": 97.1,
      "adjDefRank": 73,
      "adjEM": 14.25,
      "adjOff": 111.4,
      "adjOffRank": 29,
      "adjTempo": 71.2,
      "adjTempoRank": 28,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 42,
      "record": "23-10",
      "seed": 8
    },
    "Oregon St.": {
      "adjDef": 97.5,
      "adjDefRank": 79,
      "adjEM": 3.21,
      "adjOff": 100.7,
      "adjOffRank": 196,
      "adjTempo": 66.9,
      "adjTempoRank": 172,
      "conference": "P10",
      "rank": 128,
      "record": "12-15"
    },
    "Pacific": {
      "adjDef": 100.8,
      "adjDefRank": 139,
      "adjEM": -0.86,
      "adjOff": 99.9,
      "adjOffRank": 207,
      "adjTempo": 66.3,
      "adjTempoRank": 191,
      "conference": "BW",
      "rank": 176,
      "record": "12-16"
    },
    "Penn": {
      "adjDef": 96.9,
      "adjDefRank": 68,
      "adjEM": 13.35,
      "adjOff": 110.3,
      "adjOffRank": 41,
      "adjTempo": 63.3,
      "adjTempoRank": 290,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 48,
      "record": "22-6",
      "seed": 11
    },
    "Penn St.": {
      "adjDef": 103.2,
      "adjDefRank": 185,
      "adjEM": -4.58,
      "adjOff": 98.6,
      "adjOffRank": 231,
      "adjTempo": 67.6,
      "adjTempoRank": 133,
      "conference": "B10",
      "rank": 209,
      "record": "7-21"
    },
    "Pepperdine": {
      "adjDef": 108.5,
      "adjDefRank": 279,
      "adjEM": 1.69,
      "adjOff": 110.2,
      "adjOffRank": 42,
      "adjTempo": 70.8,
      "adjTempoRank": 35,
      "conference": "WCC",
      "rank": 148,
      "record": "15-13"
    },
    "Pittsburgh": {
      "adjDef": 86.2,
      "adjDefRank": 2,
      "adjEM": 28.61,
      "adjOff": 114.8,
      "adjOffRank": 14,
      "adjTempo": 64.7,
      "adjTempoRank": 266,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 3,
      "record": "28-5",
      "seed": 2
    },
    "Portland": {
      "adjDef": 103,
      "adjDefRank": 179,
      "adjEM": -4.25,
      "adjOff": 98.7,
      "adjOffRank": 229,
      "adjTempo": 68.2,
      "adjTempoRank": 113,
      "conference": "WCC",
      "rank": 207,
      "record": "10-17"
    },
    "Portland St.": {
      "adjDef": 98.7,
      "adjDefRank": 98,
      "adjEM": -11.46,
      "adjOff": 87.2,
      "adjOffRank": 319,
      "adjTempo": 65.5,
      "adjTempoRank": 238,
      "conference": "BSky",
      "rank": 275,
      "record": "4-21"
    },
    "Prairie View A&M": {
      "adjDef": 106.5,
      "adjDefRank": 244,
      "adjEM": -9.08,
      "adjOff": 97.4,
      "adjOffRank": 250,
      "adjTempo": 67.3,
      "adjTempoRank": 152,
      "conference": "SWAC",
      "rank": 249,
      "record": "17-12"
    },
    "Princeton": {
      "adjDef": 106.2,
      "adjDefRank": 241,
      "adjEM": 2.99,
      "adjOff": 109.2,
      "adjOffRank": 54,
      "adjTempo": 59.9,
      "adjTempoRank": 319,
      "conference": "Ivy",
      "rank": 133,
      "record": "15-11"
    },
    "Providence": {
      "adjDef": 94.1,
      "adjDefRank": 32,
      "adjEM": 13.85,
      "adjOff": 108,
      "adjOffRank": 69,
      "adjTempo": 67,
      "adjTempoRank": 166,
      "conference": "BE",
      "rank": 45,
      "record": "18-14"
    },
    "Purdue": {
      "adjDef": 89.6,
      "adjDefRank": 11,
      "adjEM": 17.42,
      "adjOff": 107,
      "adjOffRank": 87,
      "adjTempo": 69.3,
      "adjTempoRank": 71,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 27,
      "record": "19-11",
      "seed": 9
    },
    "Quinnipiac": {
      "adjDef": 105.8,
      "adjDefRank": 236,
      "adjEM": -6.72,
      "adjOff": 99.1,
      "adjOffRank": 222,
      "adjTempo": 71,
      "adjTempoRank": 33,
      "conference": "NEC",
      "rank": 229,
      "record": "16-12"
    },
    "Radford": {
      "adjDef": 108.2,
      "adjDefRank": 274,
      "adjEM": -19.59,
      "adjOff": 88.6,
      "adjOffRank": 315,
      "adjTempo": 68.5,
      "adjTempoRank": 98,
      "conference": "BSth",
      "rank": 313,
      "record": "10-20"
    },
    "Rhode Island": {
      "adjDef": 97.3,
      "adjDefRank": 75,
      "adjEM": 4.33,
      "adjOff": 101.6,
      "adjOffRank": 176,
      "adjTempo": 68.5,
      "adjTempoRank": 100,
      "conference": "A10",
      "rank": 116,
      "record": "19-12"
    },
    "Rice": {
      "adjDef": 101.1,
      "adjDefRank": 144,
      "adjEM": 4.73,
      "adjOff": 105.8,
      "adjOffRank": 102,
      "adjTempo": 69.2,
      "adjTempoRank": 73,
      "conference": "WAC",
      "rank": 112,
      "record": "17-10"
    },
    "Richmond": {
      "adjDef": 95.2,
      "adjDefRank": 48,
      "adjEM": 6.28,
      "adjOff": 101.4,
      "adjOffRank": 180,
      "adjTempo": 62.1,
      "adjTempoRank": 306,
      "conference": "A10",
      "rank": 97,
      "record": "15-14"
    },
    "Rider": {
      "adjDef": 104.9,
      "adjDefRank": 220,
      "adjEM": -5.79,
      "adjOff": 99.2,
      "adjOffRank": 221,
      "adjTempo": 63.2,
      "adjTempoRank": 295,
      "conference": "MAAC",
      "rank": 221,
      "record": "12-16"
    },
    "Robert Morris": {
      "adjDef": 116.3,
      "adjDefRank": 323,
      "adjEM": -12.74,
      "adjOff": 103.6,
      "adjOffRank": 138,
      "adjTempo": 65,
      "adjTempoRank": 254,
      "conference": "NEC",
      "rank": 285,
      "record": "9-17"
    },
    "Rutgers": {
      "adjDef": 94.9,
      "adjDefRank": 44,
      "adjEM": 6.01,
      "adjOff": 100.9,
      "adjOffRank": 193,
      "adjTempo": 68.7,
      "adjTempoRank": 91,
      "conference": "BE",
      "rank": 101,
      "record": "12-16"
    },
    "SMU": {
      "adjDef": 101.2,
      "adjDefRank": 146,
      "adjEM": 2.36,
      "adjOff": 103.6,
      "adjOffRank": 140,
      "adjTempo": 68.2,
      "adjTempoRank": 114,
      "conference": "WAC",
      "rank": 140,
      "record": "17-13"
    },
    "Sacramento St.": {
      "adjDef": 103,
      "adjDefRank": 181,
      "adjEM": -8.89,
      "adjOff": 94.1,
      "adjOffRank": 280,
      "adjTempo": 69.2,
      "adjTempoRank": 76,
      "conference": "BSky",
      "rank": 246,
      "record": "11-17"
    },
    "Sacred Heart": {
      "adjDef": 110.7,
      "adjDefRank": 299,
      "adjEM": -16.77,
      "adjOff": 93.9,
      "adjOffRank": 283,
      "adjTempo": 69.9,
      "adjTempoRank": 55,
      "conference": "NEC",
      "rank": 303,
      "record": "7-21"
    },
    "Saint Joseph's": {
      "adjDef": 88.3,
      "adjDefRank": 6,
      "adjEM": 20.75,
      "adjOff": 109,
      "adjOffRank": 58,
      "adjTempo": 64.1,
      "adjTempoRank": 281,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 17,
      "record": "23-7",
      "seed": 7
    },
    "Saint Louis": {
      "adjDef": 95.8,
      "adjDefRank": 55,
      "adjEM": 8.34,
      "adjOff": 104.1,
      "adjOffRank": 131,
      "adjTempo": 58.9,
      "adjTempoRank": 323,
      "conference": "CUSA",
      "rank": 79,
      "record": "16-14"
    },
    "Saint Mary's": {
      "adjDef": 96.3,
      "adjDefRank": 62,
      "adjEM": 2.18,
      "adjOff": 98.5,
      "adjOffRank": 232,
      "adjTempo": 65.6,
      "adjTempoRank": 230,
      "conference": "WCC",
      "rank": 142,
      "record": "14-15"
    },
    "Saint Peter's": {
      "adjDef": 108.3,
      "adjDefRank": 277,
      "adjEM": -8.03,
      "adjOff": 100.3,
      "adjOffRank": 204,
      "adjTempo": 71.2,
      "adjTempoRank": 30,
      "conference": "MAAC",
      "rank": 237,
      "record": "10-19"
    },
    "Sam Houston St.": {
      "adjDef": 100.6,
      "adjDefRank": 136,
      "adjEM": 0.82,
      "adjOff": 101.5,
      "adjOffRank": 179,
      "adjTempo": 67.2,
      "adjTempoRank": 158,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 157,
      "record": "21-7",
      "seed": 15
    },
    "Samford": {
      "adjDef": 105.1,
      "adjDefRank": 222,
      "adjEM": -4.16,
      "adjOff": 101,
      "adjOffRank": 192,
      "adjTempo": 58.8,
      "adjTempoRank": 324,
      "conference": "ASun",
      "rank": 205,
      "record": "12-15"
    },
    "San Diego": {
      "adjDef": 96.4,
      "adjDefRank": 64,
      "adjEM": 8.32,
      "adjOff": 104.7,
      "adjOffRank": 119,
      "adjTempo": 70.9,
      "adjTempoRank": 34,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 80,
      "record": "17-12",
      "seed": 13
    },
    "San Diego St.": {
      "adjDef": 101.3,
      "adjDefRank": 149,
      "adjEM": 6.69,
      "adjOff": 108,
      "adjOffRank": 67,
      "adjTempo": 66.9,
      "adjTempoRank": 171,
      "conference": "MWC",
      "rank": 92,
      "record": "15-13"
    },
    "San Francisco": {
      "adjDef": 104.9,
      "adjDefRank": 219,
      "adjEM": 4.19,
      "adjOff": 109.1,
      "adjOffRank": 55,
      "adjTempo": 63.2,
      "adjTempoRank": 293,
      "conference": "WCC",
      "rank": 118,
      "record": "14-14"
    },
    "San Jose St.": {
      "adjDef": 107.9,
      "adjDefRank": 268,
      "adjEM": -11.83,
      "adjOff": 96.1,
      "adjOffRank": 262,
      "adjTempo": 65.2,
      "adjTempoRank": 245,
      "conference": "WAC",
      "rank": 280,
      "record": "5-21"
    },
    "Santa Clara": {
      "adjDef": 103.2,
      "adjDefRank": 184,
      "adjEM": -6.25,
      "adjOff": 96.9,
      "adjOffRank": 256,
      "adjTempo": 65.1,
      "adjTempoRank": 251,
      "conference": "WCC",
      "rank": 226,
      "record": "12-15"
    },
    "Savannah St.": {
      "adjDef": 105.2,
      "adjDefRank": 224,
      "adjEM": -26.95,
      "adjOff": 78.2,
      "adjOffRank": 326,
      "adjTempo": 73.2,
      "adjTempoRank": 7,
      "conference": "ind",
      "rank": 324,
      "record": "3-23"
    },
    "Seton Hall": {
      "adjDef": 92.3,
      "adjDefRank": 20,
      "adjEM": 12.26,
      "adjOff": 104.6,
      "adjOffRank": 122,
      "adjTempo": 65.7,
      "adjTempoRank": 223,
      "conference": "BE",
      "rank": 53,
      "record": "17-13"
    },
    "Siena": {
      "adjDef": 101.4,
      "adjDefRank": 150,
      "adjEM": 3.89,
      "adjOff": 105.3,
      "adjOffRank": 110,
      "adjTempo": 68.9,
      "adjTempoRank": 83,
      "conference": "MAAC",
      "rank": 122,
      "record": "21-11"
    },
    "South Alabama": {
      "adjDef": 104.6,
      "adjDefRank": 204,
      "adjEM": 0.85,
      "adjOff": 105.4,
      "adjOffRank": 108,
      "adjTempo": 65.7,
      "adjTempoRank": 224,
      "conference": "SB",
      "rank": 156,
      "record": "12-14"
    },
    "South Carolina": {
      "adjDef": 94.8,
      "adjDefRank": 38,
      "adjEM": 8.31,
      "adjOff": 103.1,
      "adjOffRank": 150,
      "adjTempo": 65.5,
      "adjTempoRank": 235,
      "conference": "SEC",
      "rank": 81,
      "record": "12-16"
    },
    "South Carolina St.": {
      "adjDef": 105,
      "adjDefRank": 221,
      "adjEM": -8.59,
      "adjOff": 96.4,
      "adjOffRank": 260,
      "adjTempo": 71.7,
      "adjTempoRank": 21,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 244,
      "record": "20-11",
      "seed": 16
    },
    "South Florida": {
      "adjDef": 96.8,
      "adjDefRank": 67,
      "adjEM": 4.49,
      "adjOff": 101.3,
      "adjOffRank": 184,
      "adjTempo": 67.9,
      "adjTempoRank": 124,
      "conference": "CUSA",
      "rank": 114,
      "record": "14-14"
    },
    "Southeast Missouri St.": {
      "adjDef": 111.3,
      "adjDefRank": 304,
      "adjEM": -9.22,
      "adjOff": 102.1,
      "adjOffRank": 168,
      "adjTempo": 65.3,
      "adjTempoRank": 244,
      "conference": "OVC",
      "rank": 251,
      "record": "9-19"
    },
    "Southeastern Louisiana": {
      "adjDef": 106.2,
      "adjDefRank": 242,
      "adjEM": -10.54,
      "adjOff": 95.7,
      "adjOffRank": 266,
      "adjTempo": 65.9,
      "adjTempoRank": 210,
      "conference": "Slnd",
      "rank": 262,
      "record": "9-16"
    },
    "Southern": {
      "adjDef": 109,
      "adjDefRank": 287,
      "adjEM": -18.97,
      "adjOff": 90.1,
      "adjOffRank": 308,
      "adjTempo": 69,
      "adjTempoRank": 81,
      "conference": "SWAC",
      "rank": 312,
      "record": "7-19"
    },
    "Southern Illinois": {
      "adjDef": 98.6,
      "adjDefRank": 96,
      "adjEM": 11.76,
      "adjOff": 110.3,
      "adjOffRank": 40,
      "adjTempo": 67.8,
      "adjTempoRank": 125,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 55,
      "record": "24-7",
      "seed": 11
    },
    "Southern Miss": {
      "adjDef": 98.8,
      "adjDefRank": 101,
      "adjEM": 0.16,
      "adjOff": 98.9,
      "adjOffRank": 224,
      "adjTempo": 67.4,
      "adjTempoRank": 147,
      "conference": "CUSA",
      "rank": 166,
      "record": "13-16"
    },
    "Southern Utah": {
      "adjDef": 111.9,
      "adjDefRank": 311,
      "adjEM": -10.77,
      "adjOff": 101.2,
      "adjOffRank": 188,
      "adjTempo": 64.2,
      "adjTempoRank": 278,
      "conference": "MCon",
      "rank": 265,
      "record": "9-17"
    },
    "Southwest Missouri St.": {
      "adjDef": 92.5,
      "adjDefRank": 22,
      "adjEM": 8.04,
      "adjOff": 100.6,
      "adjOffRank": 199,
      "adjTempo": 63.5,
      "adjTempoRank": 287,
      "conference": "MVC",
      "rank": 85,
      "record": "17-12"
    },
    "Southwest Texas St.": {
      "adjDef": 104.7,
      "adjDefRank": 209,
      "adjEM": -3.28,
      "adjOff": 101.4,
      "adjOffRank": 182,
      "adjTempo": 67.4,
      "adjTempoRank": 148,
      "conference": "Slnd",
      "rank": 196,
      "record": "15-12"
    },
    "St. Bonaventure": {
      "adjDef": 104.8,
      "adjDefRank": 213,
      "adjEM": 8.07,
      "adjOff": 112.8,
      "adjOffRank": 23,
      "adjTempo": 71.3,
      "adjTempoRank": 24,
      "conference": "A10",
      "rank": 84,
      "record": "13-14"
    },
    "St. Francis NY": {
      "adjDef": 108.6,
      "adjDefRank": 280,
      "adjEM": -8.1,
      "adjOff": 100.5,
      "adjOffRank": 200,
      "adjTempo": 70.7,
      "adjTempoRank": 37,
      "conference": "NEC",
      "rank": 239,
      "record": "14-16"
    },
    "St. Francis PA": {
      "adjDef": 104.1,
      "adjDefRank": 198,
      "adjEM": -9.84,
      "adjOff": 94.3,
      "adjOffRank": 277,
      "adjTempo": 69.3,
      "adjTempoRank": 70,
      "conference": "NEC",
      "rank": 256,
      "record": "14-14"
    },
    "St. John's": {
      "adjDef": 95.3,
      "adjDefRank": 50,
      "adjEM": 10.06,
      "adjOff": 105.4,
      "adjOffRank": 109,
      "adjTempo": 67,
      "adjTempoRank": 165,
      "conference": "BE",
      "rank": 67,
      "record": "21-13"
    },
    "Stanford": {
      "adjDef": 95.2,
      "adjDefRank": 49,
      "adjEM": 14.57,
      "adjOff": 109.8,
      "adjOffRank": 46,
      "adjTempo": 65.6,
      "adjTempoRank": 226,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 39,
      "record": "24-9",
      "seed": 4
    },
    "Stephen F. Austin": {
      "adjDef": 103.5,
      "adjDefRank": 189,
      "adjEM": 2.14,
      "adjOff": 105.6,
      "adjOffRank": 104,
      "adjTempo": 62.6,
      "adjTempoRank": 300,
      "conference": "Slnd",
      "rank": 144,
      "record": "19-8"
    },
    "Stetson": {
      "adjDef": 111.8,
      "adjDefRank": 307,
      "adjEM": -16.3,
      "adjOff": 95.5,
      "adjOffRank": 270,
      "adjTempo": 71.9,
      "adjTempoRank": 16,
      "conference": "ASun",
      "rank": 301,
      "record": "6-20"
    },
    "Stony Brook": {
      "adjDef": 106.6,
      "adjDefRank": 245,
      "adjEM": -11.06,
      "adjOff": 95.5,
      "adjOffRank": 269,
      "adjTempo": 65.2,
      "adjTempoRank": 248,
      "conference": "AE",
      "rank": 271,
      "record": "11-15"
    },
    "Syracuse": {
      "adjDef": 91.1,
      "adjDefRank": 14,
      "adjEM": 23.28,
      "adjOff": 114.4,
      "adjOffRank": 17,
      "adjTempo": 71,
      "adjTempoRank": 32,
      "conference": "BE",
      "finish": "Champion",
      "rank": 8,
      "record": "30-5",
      "seed": 3
    },
    "TCU": {
      "adjDef": 106.5,
      "adjDefRank": 243,
      "adjEM": -0.41,
      "adjOff": 106,
      "adjOffRank": 99,
      "adjTempo": 71.3,
      "adjTempoRank": 27,
      "conference": "CUSA",
      "rank": 170,
      "record": "9-19"
    },
    "Temple": {
      "adjDef": 98.6,
      "adjDefRank": 97,
      "adjEM": 9.34,
      "adjOff": 108,
      "adjOffRank": 68,
      "adjTempo": 63.2,
      "adjTempoRank": 291,
      "conference": "A10",
      "rank": 74,
      "record": "18-16"
    },
    "Tennessee": {
      "adjDef": 97,
      "adjDefRank": 71,
      "adjEM": 10.99,
      "adjOff": 108,
      "adjOffRank": 66,
      "adjTempo": 64.7,
      "adjTempoRank": 268,
      "conference": "SEC",
      "rank": 58,
      "record": "17-12"
    },
    "Tennessee Martin": {
      "adjDef": 107.3,
      "adjDefRank": 260,
      "adjEM": -6.88,
      "adjOff": 100.5,
      "adjOffRank": 202,
      "adjTempo": 69.5,
      "adjTempoRank": 66,
      "conference": "OVC",
      "rank": 230,
      "record": "10-14"
    },
    "Tennessee St.": {
      "adjDef": 118.8,
      "adjDefRank": 326,
      "adjEM": -27.8,
      "adjOff": 91,
      "adjOffRank": 302,
      "adjTempo": 75.7,
      "adjTempoRank": 2,
      "conference": "OVC",
      "rank": 326,
      "record": "1-24"
    },
    "Tennessee Tech": {
      "adjDef": 99,
      "adjDefRank": 106,
      "adjEM": 3.86,
      "adjOff": 102.8,
      "adjOffRank": 157,
      "adjTempo": 68.7,
      "adjTempoRank": 87,
      "conference": "OVC",
      "rank": 123,
      "record": "17-12"
    },
    "Texas": {
      "adjDef": 95.8,
      "adjDefRank": 58,
      "adjEM": 23.49,
      "adjOff": 119.3,
      "adjOffRank": 3,
      "adjTempo": 69.4,
      "adjTempoRank": 67,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 7,
      "record": "26-7",
      "seed": 1
    },
    "Texas A&M": {
      "adjDef": 97.4,
      "adjDefRank": 77,
      "adjEM": 6.97,
      "adjOff": 104.4,
      "adjOffRank": 126,
      "adjTempo": 70.4,
      "adjTempoRank": 44,
      "conference": "B12",
      "rank": 89,
      "record": "14-14"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 108.3,
      "adjDefRank": 276,
      "adjEM": -9.28,
      "adjOff": 99,
      "adjOffRank": 223,
      "adjTempo": 72.8,
      "adjTempoRank": 9,
      "conference": "ind",
      "rank": 252,
      "record": "11-15"
    },
    "Texas Pan American": {
      "adjDef": 105.9,
      "adjDefRank": 239,
      "adjEM": -16.39,
      "adjOff": 89.5,
      "adjOffRank": 312,
      "adjTempo": 64.8,
      "adjTempoRank": 265,
      "conference": "ind",
      "rank": 302,
      "record": "6-20"
    },
    "Texas Southern": {
      "adjDef": 108.7,
      "adjDefRank": 281,
      "adjEM": -10.45,
      "adjOff": 98.3,
      "adjOffRank": 238,
      "adjTempo": 68.4,
      "adjTempoRank": 104,
      "conference": "SWAC",
      "finish": "Play-in",
      "rank": 260,
      "record": "18-13",
      "seed": 16
    },
    "Texas Tech": {
      "adjDef": 93.4,
      "adjDefRank": 26,
      "adjEM": 15.89,
      "adjOff": 109.3,
      "adjOffRank": 53,
      "adjTempo": 66.2,
      "adjTempoRank": 197,
      "conference": "B12",
      "rank": 32,
      "record": "21-13"
    },
    "The Citadel": {
      "adjDef": 104,
      "adjDefRank": 195,
      "adjEM": -14.22,
      "adjOff": 89.8,
      "adjOffRank": 311,
      "adjTempo": 66.1,
      "adjTempoRank": 201,
      "conference": "SC",
      "rank": 291,
      "record": "4-20"
    },
    "Toledo": {
      "adjDef": 100.6,
      "adjDefRank": 133,
      "adjEM": 3.03,
      "adjOff": 103.6,
      "adjOffRank": 139,
      "adjTempo": 67.2,
      "adjTempoRank": 154,
      "conference": "MAC",
      "rank": 132,
      "record": "13-16"
    },
    "Towson": {
      "adjDef": 108.4,
      "adjDefRank": 278,
      "adjEM": -23.81,
      "adjOff": 84.6,
      "adjOffRank": 323,
      "adjTempo": 66.4,
      "adjTempoRank": 190,
      "conference": "CAA",
      "rank": 317,
      "record": "4-24"
    },
    "Troy St.": {
      "adjDef": 100.8,
      "adjDefRank": 140,
      "adjEM": 6.15,
      "adjOff": 106.9,
      "adjOffRank": 88,
      "adjTempo": 70.3,
      "adjTempoRank": 47,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 99,
      "record": "25-6",
      "seed": 14
    },
    "Tulane": {
      "adjDef": 101.4,
      "adjDefRank": 151,
      "adjEM": 5.36,
      "adjOff": 106.8,
      "adjOffRank": 90,
      "adjTempo": 65.5,
      "adjTempoRank": 236,
      "conference": "CUSA",
      "rank": 108,
      "record": "15-15"
    },
    "Tulsa": {
      "adjDef": 94.9,
      "adjDefRank": 41,
      "adjEM": 13.04,
      "adjOff": 107.9,
      "adjOffRank": 70,
      "adjTempo": 67.4,
      "adjTempoRank": 150,
      "conference": "WAC",
      "finish": "Round of 32",
      "rank": 51,
      "record": "23-10",
      "seed": 13
    },
    "UAB": {
      "adjDef": 95.8,
      "adjDefRank": 56,
      "adjEM": 9.45,
      "adjOff": 105.2,
      "adjOffRank": 113,
      "adjTempo": 73.9,
      "adjTempoRank": 4,
      "conference": "CUSA",
      "rank": 73,
      "record": "20-13"
    },
    "UC Irvine": {
      "adjDef": 99.5,
      "adjDefRank": 115,
      "adjEM": 4.1,
      "adjOff": 103.6,
      "adjOffRank": 137,
      "adjTempo": 65.5,
      "adjTempoRank": 237,
      "conference": "BW",
      "rank": 119,
      "record": "19-9"
    },
    "UC Riverside": {
      "adjDef": 109.8,
      "adjDefRank": 292,
      "adjEM": -10.94,
      "adjOff": 98.8,
      "adjOffRank": 227,
      "adjTempo": 67.5,
      "adjTempoRank": 146,
      "conference": "BW",
      "rank": 268,
      "record": "5-18"
    },
    "UC Santa Barbara": {
      "adjDef": 97.7,
      "adjDefRank": 84,
      "adjEM": 6.46,
      "adjOff": 104.2,
      "adjOffRank": 129,
      "adjTempo": 61.4,
      "adjTempoRank": 314,
      "conference": "BW",
      "rank": 95,
      "record": "18-14"
    },
    "UCF": {
      "adjDef": 99.7,
      "adjDefRank": 118,
      "adjEM": 3.18,
      "adjOff": 102.9,
      "adjOffRank": 154,
      "adjTempo": 65.4,
      "adjTempoRank": 239,
      "conference": "ASun",
      "rank": 129,
      "record": "20-11"
    },
    "UCLA": {
      "adjDef": 101.7,
      "adjDefRank": 156,
      "adjEM": 5.46,
      "adjOff": 107.2,
      "adjOffRank": 84,
      "adjTempo": 69.3,
      "adjTempoRank": 69,
      "conference": "P10",
      "rank": 107,
      "record": "10-19"
    },
    "UMBC": {
      "adjDef": 106.8,
      "adjDefRank": 250,
      "adjEM": -16.78,
      "adjOff": 90,
      "adjOffRank": 309,
      "adjTempo": 64.9,
      "adjTempoRank": 260,
      "conference": "NEC",
      "rank": 304,
      "record": "7-20"
    },
    "UMKC": {
      "adjDef": 113.1,
      "adjDefRank": 316,
      "adjEM": -11.32,
      "adjOff": 101.8,
      "adjOffRank": 170,
      "adjTempo": 64.7,
      "adjTempoRank": 267,
      "conference": "MCon",
      "rank": 274,
      "record": "9-20"
    },
    "UNC Asheville": {
      "adjDef": 113.5,
      "adjDefRank": 319,
      "adjEM": -12.35,
      "adjOff": 101.2,
      "adjOffRank": 187,
      "adjTempo": 68,
      "adjTempoRank": 118,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 283,
      "record": "14-17",
      "seed": 16
    },
    "UNC Greensboro": {
      "adjDef": 106.8,
      "adjDefRank": 249,
      "adjEM": -9.03,
      "adjOff": 97.7,
      "adjOffRank": 247,
      "adjTempo": 67.5,
      "adjTempoRank": 142,
      "conference": "SC",
      "rank": 248,
      "record": "6-22"
    },
    "UNC Wilmington": {
      "adjDef": 94.9,
      "adjDefRank": 43,
      "adjEM": 14.62,
      "adjOff": 109.5,
      "adjOffRank": 49,
      "adjTempo": 64.1,
      "adjTempoRank": 279,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 37,
      "record": "24-7",
      "seed": 11
    },
    "UNLV": {
      "adjDef": 98.2,
      "adjDefRank": 90,
      "adjEM": 9.84,
      "adjOff": 108.1,
      "adjOffRank": 63,
      "adjTempo": 70.3,
      "adjTempoRank": 49,
      "conference": "MWC",
      "rank": 71,
      "record": "21-11"
    },
    "USC": {
      "adjDef": 98.4,
      "adjDefRank": 93,
      "adjEM": 6.74,
      "adjOff": 105.2,
      "adjOffRank": 114,
      "adjTempo": 73.3,
      "adjTempoRank": 5,
      "conference": "P10",
      "rank": 91,
      "record": "13-17"
    },
    "UT Arlington": {
      "adjDef": 100.5,
      "adjDefRank": 132,
      "adjEM": -2.73,
      "adjOff": 97.8,
      "adjOffRank": 246,
      "adjTempo": 62.2,
      "adjTempoRank": 304,
      "conference": "Slnd",
      "rank": 192,
      "record": "14-13"
    },
    "UTEP": {
      "adjDef": 109,
      "adjDefRank": 285,
      "adjEM": -15.62,
      "adjOff": 93.4,
      "adjOffRank": 287,
      "adjTempo": 66.3,
      "adjTempoRank": 195,
      "conference": "WAC",
      "rank": 296,
      "record": "5-24"
    },
    "UTSA": {
      "adjDef": 105.8,
      "adjDefRank": 234,
      "adjEM": -12.02,
      "adjOff": 93.8,
      "adjOffRank": 284,
      "adjTempo": 69.7,
      "adjTempoRank": 63,
      "conference": "Slnd",
      "rank": 282,
      "record": "9-17"
    },
    "Utah": {
      "adjDef": 92.6,
      "adjDefRank": 23,
      "adjEM": 14.04,
      "adjOff": 106.6,
      "adjOffRank": 91,
      "adjTempo": 62.1,
      "adjTempoRank": 308,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 44,
      "record": "25-8",
      "seed": 9
    },
    "Utah St.": {
      "adjDef": 99.7,
      "adjDefRank": 117,
      "adjEM": 7.41,
      "adjOff": 107.1,
      "adjOffRank": 85,
      "adjTempo": 61,
      "adjTempoRank": 315,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 88,
      "record": "22-9",
      "seed": 15
    },
    "VCU": {
      "adjDef": 99.3,
      "adjDefRank": 111,
      "adjEM": 4.06,
      "adjOff": 103.4,
      "adjOffRank": 144,
      "adjTempo": 69.1,
      "adjTempoRank": 78,
      "conference": "CAA",
      "rank": 121,
      "record": "18-10"
    },
    "VMI": {
      "adjDef": 105.6,
      "adjDefRank": 229,
      "adjEM": -14.1,
      "adjOff": 91.5,
      "adjOffRank": 299,
      "adjTempo": 65.6,
      "adjTempoRank": 231,
      "conference": "SC",
      "rank": 289,
      "record": "8-19"
    },
    "Valparaiso": {
      "adjDef": 97.1,
      "adjDefRank": 72,
      "adjEM": 6.66,
      "adjOff": 103.7,
      "adjOffRank": 134,
      "adjTempo": 65.8,
      "adjTempoRank": 215,
      "conference": "MCon",
      "rank": 93,
      "record": "20-11"
    },
    "Vanderbilt": {
      "adjDef": 96.9,
      "adjDefRank": 69,
      "adjEM": 6.78,
      "adjOff": 103.7,
      "adjOffRank": 135,
      "adjTempo": 69.3,
      "adjTempoRank": 68,
      "conference": "SEC",
      "rank": 90,
      "record": "11-18"
    },
    "Vermont": {
      "adjDef": 99.5,
      "adjDefRank": 114,
      "adjEM": 1.57,
      "adjOff": 101.1,
      "adjOffRank": 191,
      "adjTempo": 64.4,
      "adjTempoRank": 271,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 150,
      "record": "19-11",
      "seed": 16
    },
    "Villanova": {
      "adjDef": 95.4,
      "adjDefRank": 51,
      "adjEM": 11.17,
      "adjOff": 106.6,
      "adjOffRank": 93,
      "adjTempo": 68.8,
      "adjTempoRank": 86,
      "conference": "BE",
      "rank": 57,
      "record": "15-16"
    },
    "Virginia": {
      "adjDef": 100.6,
      "adjDefRank": 135,
      "adjEM": 8.2,
      "adjOff": 108.8,
      "adjOffRank": 61,
      "adjTempo": 68.4,
      "adjTempoRank": 101,
      "conference": "ACC",
      "rank": 82,
      "record": "16-16"
    },
    "Virginia Tech": {
      "adjDef": 104,
      "adjDefRank": 191,
      "adjEM": 2.34,
      "adjOff": 106.3,
      "adjOffRank": 97,
      "adjTempo": 66.8,
      "adjTempoRank": 176,
      "conference": "BE",
      "rank": 141,
      "record": "11-18"
    },
    "Wagner": {
      "adjDef": 105.9,
      "adjDefRank": 238,
      "adjEM": -1.16,
      "adjOff": 104.7,
      "adjOffRank": 118,
      "adjTempo": 67.6,
      "adjTempoRank": 134,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 179,
      "record": "21-11",
      "seed": 15
    },
    "Wake Forest": {
      "adjDef": 94.6,
      "adjDefRank": 37,
      "adjEM": 19.87,
      "adjOff": 114.5,
      "adjOffRank": 15,
      "adjTempo": 67.7,
      "adjTempoRank": 130,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 21,
      "record": "25-6",
      "seed": 2
    },
    "Washington": {
      "adjDef": 99.4,
      "adjDefRank": 112,
      "adjEM": 3.12,
      "adjOff": 102.6,
      "adjOffRank": 158,
      "adjTempo": 70.1,
      "adjTempoRank": 51,
      "conference": "P10",
      "rank": 130,
      "record": "10-17"
    },
    "Washington St.": {
      "adjDef": 105.8,
      "adjDefRank": 233,
      "adjEM": -4.29,
      "adjOff": 101.5,
      "adjOffRank": 177,
      "adjTempo": 68.7,
      "adjTempoRank": 89,
      "conference": "P10",
      "rank": 208,
      "record": "7-20"
    },
    "Weber St.": {
      "adjDef": 102.5,
      "adjDefRank": 172,
      "adjEM": 8.88,
      "adjOff": 111.4,
      "adjOffRank": 28,
      "adjTempo": 66.5,
      "adjTempoRank": 186,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 76,
      "record": "25-5",
      "seed": 12
    },
    "West Virginia": {
      "adjDef": 99.9,
      "adjDefRank": 126,
      "adjEM": 4.71,
      "adjOff": 104.7,
      "adjOffRank": 120,
      "adjTempo": 65,
      "adjTempoRank": 253,
      "conference": "BE",
      "rank": 113,
      "record": "14-15"
    },
    "Western Carolina": {
      "adjDef": 110.7,
      "adjDefRank": 300,
      "adjEM": -11.94,
      "adjOff": 98.8,
      "adjOffRank": 228,
      "adjTempo": 67.3,
      "adjTempoRank": 151,
      "conference": "SC",
      "rank": 281,
      "record": "6-18"
    },
    "Western Illinois": {
      "adjDef": 110.7,
      "adjDefRank": 301,
      "adjEM": -17.26,
      "adjOff": 93.5,
      "adjOffRank": 285,
      "adjTempo": 67,
      "adjTempoRank": 167,
      "conference": "MCon",
      "rank": 306,
      "record": "5-21"
    },
    "Western Kentucky": {
      "adjDef": 96.3,
      "adjDefRank": 61,
      "adjEM": 11.75,
      "adjOff": 108,
      "adjOffRank": 65,
      "adjTempo": 67.2,
      "adjTempoRank": 156,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 56,
      "record": "24-9",
      "seed": 13
    },
    "Western Michigan": {
      "adjDef": 94,
      "adjDefRank": 30,
      "adjEM": 7.66,
      "adjOff": 101.7,
      "adjOffRank": 174,
      "adjTempo": 65.3,
      "adjTempoRank": 242,
      "conference": "MAC",
      "rank": 86,
      "record": "20-11"
    },
    "Wichita St.": {
      "adjDef": 104.6,
      "adjDefRank": 207,
      "adjEM": 3.05,
      "adjOff": 107.7,
      "adjOffRank": 76,
      "adjTempo": 67.7,
      "adjTempoRank": 129,
      "conference": "MVC",
      "rank": 131,
      "record": "18-12"
    },
    "William & Mary": {
      "adjDef": 111.9,
      "adjDefRank": 310,
      "adjEM": -11.16,
      "adjOff": 100.7,
      "adjOffRank": 195,
      "adjTempo": 62,
      "adjTempoRank": 309,
      "conference": "CAA",
      "rank": 273,
      "record": "12-16"
    },
    "Winthrop": {
      "adjDef": 102.9,
      "adjDefRank": 176,
      "adjEM": -5.81,
      "adjOff": 97.1,
      "adjOffRank": 255,
      "adjTempo": 67.4,
      "adjTempoRank": 149,
      "conference": "BSth",
      "rank": 222,
      "record": "18-10"
    },
    "Wisconsin": {
      "adjDef": 92.4,
      "adjDefRank": 21,
      "adjEM": 22.07,
      "adjOff": 114.4,
      "adjOffRank": 16,
      "adjTempo": 61.9,
      "adjTempoRank": 310,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 12,
      "record": "24-8",
      "seed": 5
    },
    "Wofford": {
      "adjDef": 108,
      "adjDefRank": 270,
      "adjEM": -3.75,
      "adjOff": 104.2,
      "adjOffRank": 128,
      "adjTempo": 68.4,
      "adjTempoRank": 105,
      "conference": "SC",
      "rank": 200,
      "record": "10-15"
    },
    "Wright St.": {
      "adjDef": 107.7,
      "adjDefRank": 266,
      "adjEM": -5.65,
      "adjOff": 102.1,
      "adjOffRank": 167,
      "adjTempo": 66,
      "adjTempoRank": 207,
      "conference": "Horz",
      "rank": 216,
      "record": "9-18"
    },
    "Wyoming": {
      "adjDef": 102.2,
      "adjDefRank": 168,
      "adjEM": 6.34,
      "adjOff": 108.5,
      "adjOffRank": 62,
      "adjTempo": 68,
      "adjTempoRank": 121,
      "conference": "MWC",
      "rank": 96,
      "record": "21-11"
    },
    "Xavier": {
      "adjDef": 94,
      "adjDefRank": 29,
      "adjEM": 19.88,
      "adjOff": 113.8,
      "adjOffRank": 19,
      "adjTempo": 67.7,
      "adjTempoRank": 131,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 20,
      "record": "26-6",
      "seed": 3
    },
    "Yale": {
      "adjDef": 99.2,
      "adjDefRank": 108,
      "adjEM": 0.85,
      "adjOff": 100.1,
      "adjOffRank": 206,
      "adjTempo": 68.7,
      "adjTempoRank": 90,
      "conference": "Ivy",
      "rank": 155,
      "record": "13-13"
    },
    "Youngstown St.": {
      "adjDef": 102.4,
      "adjDefRank": 170,
      "adjEM": -9.31,
      "adjOff": 93.1,
      "adjOffRank": 290,
      "adjTempo": 65.8,
      "adjTempoRank": 216,
      "conference": "Horz",
      "rank": 253,
      "record": "7-20"
    }
  },
  "2004": {
    "Air Force": {
      "adjDef": 96,
      "adjDefRank": 43,
      "adjEM": 15.42,
      "adjOff": 111.4,
      "adjOffRank": 47,
      "adjTempo": 51.9,
      "adjTempoRank": 326,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 36,
      "record": "22-7",
      "seed": 11
    },
    "Akron": {
      "adjDef": 106.7,
      "adjDefRank": 233,
      "adjEM": -0.45,
      "adjOff": 106.3,
      "adjOffRank": 104,
      "adjTempo": 64.8,
      "adjTempoRank": 202,
      "conference": "MAC",
      "rank": 159,
      "record": "13-15"
    },
    "Alabama": {
      "adjDef": 98.2,
      "adjDefRank": 67,
      "adjEM": 16.5,
      "adjOff": 114.7,
      "adjOffRank": 17,
      "adjTempo": 64.7,
      "adjTempoRank": 207,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 29,
      "record": "20-13",
      "seed": 8
    },
    "Alabama A&M": {
      "adjDef": 111.6,
      "adjDefRank": 305,
      "adjEM": -16.33,
      "adjOff": 95.3,
      "adjOffRank": 288,
      "adjTempo": 69.6,
      "adjTempoRank": 36,
      "conference": "SWAC",
      "rank": 302,
      "record": "13-17"
    },
    "Alabama St.": {
      "adjDef": 104.5,
      "adjDefRank": 180,
      "adjEM": -10.72,
      "adjOff": 93.8,
      "adjOffRank": 295,
      "adjTempo": 61.4,
      "adjTempoRank": 304,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 266,
      "record": "16-15",
      "seed": 16
    },
    "Albany": {
      "adjDef": 107.4,
      "adjDefRank": 247,
      "adjEM": -19.86,
      "adjOff": 87.6,
      "adjOffRank": 317,
      "adjTempo": 62.5,
      "adjTempoRank": 284,
      "conference": "AE",
      "rank": 310,
      "record": "5-23"
    },
    "Alcorn St.": {
      "adjDef": 112.9,
      "adjDefRank": 315,
      "adjEM": -20.96,
      "adjOff": 91.9,
      "adjOffRank": 304,
      "adjTempo": 67.5,
      "adjTempoRank": 105,
      "conference": "SWAC",
      "rank": 312,
      "record": "11-18"
    },
    "American": {
      "adjDef": 105.8,
      "adjDefRank": 206,
      "adjEM": -3.16,
      "adjOff": 102.7,
      "adjOffRank": 164,
      "adjTempo": 63.4,
      "adjTempoRank": 255,
      "conference": "Pat",
      "rank": 185,
      "record": "18-13"
    },
    "Appalachian St.": {
      "adjDef": 100.7,
      "adjDefRank": 114,
      "adjEM": -5.05,
      "adjOff": 95.6,
      "adjOffRank": 284,
      "adjTempo": 69.9,
      "adjTempoRank": 29,
      "conference": "SC",
      "rank": 213,
      "record": "9-21"
    },
    "Arizona": {
      "adjDef": 102.3,
      "adjDefRank": 151,
      "adjEM": 16.18,
      "adjOff": 118.5,
      "adjOffRank": 7,
      "adjTempo": 73,
      "adjTempoRank": 6,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 32,
      "record": "20-10",
      "seed": 9
    },
    "Arizona St.": {
      "adjDef": 106.3,
      "adjDefRank": 222,
      "adjEM": 1.29,
      "adjOff": 107.6,
      "adjOffRank": 84,
      "adjTempo": 68.5,
      "adjTempoRank": 65,
      "conference": "P10",
      "rank": 142,
      "record": "10-17"
    },
    "Arkansas": {
      "adjDef": 98.6,
      "adjDefRank": 76,
      "adjEM": 6.03,
      "adjOff": 104.6,
      "adjOffRank": 132,
      "adjTempo": 66.1,
      "adjTempoRank": 153,
      "conference": "SEC",
      "rank": 97,
      "record": "12-16"
    },
    "Arkansas Little Rock": {
      "adjDef": 100.4,
      "adjDefRank": 110,
      "adjEM": 0.58,
      "adjOff": 101,
      "adjOffRank": 200,
      "adjTempo": 65.6,
      "adjTempoRank": 171,
      "conference": "SB",
      "rank": 151,
      "record": "17-12"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 112.5,
      "adjDefRank": 314,
      "adjEM": -36.33,
      "adjOff": 76.2,
      "adjOffRank": 326,
      "adjTempo": 67.3,
      "adjTempoRank": 113,
      "conference": "SWAC",
      "rank": 326,
      "record": "1-26"
    },
    "Arkansas St.": {
      "adjDef": 106.3,
      "adjDefRank": 220,
      "adjEM": -0.47,
      "adjOff": 105.8,
      "adjOffRank": 111,
      "adjTempo": 66,
      "adjTempoRank": 158,
      "conference": "SB",
      "rank": 160,
      "record": "17-11"
    },
    "Army": {
      "adjDef": 108.4,
      "adjDefRank": 264,
      "adjEM": -30.49,
      "adjOff": 77.9,
      "adjOffRank": 324,
      "adjTempo": 61.8,
      "adjTempoRank": 296,
      "conference": "Pat",
      "rank": 325,
      "record": "6-21"
    },
    "Auburn": {
      "adjDef": 96.1,
      "adjDefRank": 45,
      "adjEM": 10.16,
      "adjOff": 106.3,
      "adjOffRank": 105,
      "adjTempo": 66.4,
      "adjTempoRank": 136,
      "conference": "SEC",
      "rank": 68,
      "record": "14-14"
    },
    "Austin Peay": {
      "adjDef": 94.6,
      "adjDefRank": 27,
      "adjEM": 5.49,
      "adjOff": 100.1,
      "adjOffRank": 215,
      "adjTempo": 62.1,
      "adjTempoRank": 294,
      "conference": "OVC",
      "rank": 103,
      "record": "22-10"
    },
    "BYU": {
      "adjDef": 97.4,
      "adjDefRank": 59,
      "adjEM": 15.64,
      "adjOff": 113,
      "adjOffRank": 32,
      "adjTempo": 65.9,
      "adjTempoRank": 160,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 35,
      "record": "21-9",
      "seed": 12
    },
    "Ball St.": {
      "adjDef": 101.9,
      "adjDefRank": 138,
      "adjEM": 1.47,
      "adjOff": 103.3,
      "adjOffRank": 152,
      "adjTempo": 67.1,
      "adjTempoRank": 117,
      "conference": "MAC",
      "rank": 138,
      "record": "14-15"
    },
    "Baylor": {
      "adjDef": 108.7,
      "adjDefRank": 269,
      "adjEM": -6.63,
      "adjOff": 102,
      "adjOffRank": 177,
      "adjTempo": 61,
      "adjTempoRank": 307,
      "conference": "B12",
      "rank": 232,
      "record": "8-21"
    },
    "Belmont": {
      "adjDef": 100.8,
      "adjDefRank": 117,
      "adjEM": 5.22,
      "adjOff": 106,
      "adjOffRank": 107,
      "adjTempo": 63.8,
      "adjTempoRank": 241,
      "conference": "ASun",
      "rank": 105,
      "record": "21-9"
    },
    "Bethune Cookman": {
      "adjDef": 112.1,
      "adjDefRank": 311,
      "adjEM": -21.96,
      "adjOff": 90.2,
      "adjOffRank": 309,
      "adjTempo": 63.4,
      "adjTempoRank": 260,
      "conference": "MEAC",
      "rank": 313,
      "record": "8-21"
    },
    "Binghamton": {
      "adjDef": 101.1,
      "adjDefRank": 122,
      "adjEM": -9.69,
      "adjOff": 91.4,
      "adjOffRank": 306,
      "adjTempo": 63.9,
      "adjTempoRank": 238,
      "conference": "AE",
      "rank": 260,
      "record": "14-16"
    },
    "Birmingham Southern": {
      "adjDef": 108.6,
      "adjDefRank": 267,
      "adjEM": -1.1,
      "adjOff": 107.5,
      "adjOffRank": 88,
      "adjTempo": 63.5,
      "adjTempoRank": 253,
      "conference": "BSth",
      "rank": 166,
      "record": "20-7"
    },
    "Boise St.": {
      "adjDef": 96.5,
      "adjDefRank": 47,
      "adjEM": 13.13,
      "adjOff": 109.6,
      "adjOffRank": 65,
      "adjTempo": 66.7,
      "adjTempoRank": 125,
      "conference": "WAC",
      "rank": 49,
      "record": "23-10"
    },
    "Boston College": {
      "adjDef": 95.1,
      "adjDefRank": 34,
      "adjEM": 15.42,
      "adjOff": 110.5,
      "adjOffRank": 55,
      "adjTempo": 62.6,
      "adjTempoRank": 282,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 37,
      "record": "24-10",
      "seed": 6
    },
    "Boston University": {
      "adjDef": 99.5,
      "adjDefRank": 92,
      "adjEM": 3.01,
      "adjOff": 102.5,
      "adjOffRank": 169,
      "adjTempo": 63.4,
      "adjTempoRank": 261,
      "conference": "AE",
      "rank": 127,
      "record": "23-6"
    },
    "Bowling Green": {
      "adjDef": 106.4,
      "adjDefRank": 228,
      "adjEM": -1.71,
      "adjOff": 104.7,
      "adjOffRank": 128,
      "adjTempo": 65.6,
      "adjTempoRank": 174,
      "conference": "MAC",
      "rank": 175,
      "record": "14-17"
    },
    "Bradley": {
      "adjDef": 99.3,
      "adjDefRank": 90,
      "adjEM": -0.69,
      "adjOff": 98.6,
      "adjOffRank": 244,
      "adjTempo": 66.8,
      "adjTempoRank": 123,
      "conference": "MVC",
      "rank": 163,
      "record": "15-16"
    },
    "Brown": {
      "adjDef": 106.2,
      "adjDefRank": 216,
      "adjEM": -4.35,
      "adjOff": 101.8,
      "adjOffRank": 183,
      "adjTempo": 70.4,
      "adjTempoRank": 24,
      "conference": "Ivy",
      "rank": 201,
      "record": "14-13"
    },
    "Bucknell": {
      "adjDef": 101.3,
      "adjDefRank": 125,
      "adjEM": -4.69,
      "adjOff": 96.6,
      "adjOffRank": 273,
      "adjTempo": 64.5,
      "adjTempoRank": 211,
      "conference": "Pat",
      "rank": 207,
      "record": "14-15"
    },
    "Buffalo": {
      "adjDef": 102.9,
      "adjDefRank": 161,
      "adjEM": 4.67,
      "adjOff": 107.6,
      "adjOffRank": 85,
      "adjTempo": 69.5,
      "adjTempoRank": 37,
      "conference": "MAC",
      "rank": 109,
      "record": "17-12"
    },
    "Butler": {
      "adjDef": 104.7,
      "adjDefRank": 184,
      "adjEM": 2.77,
      "adjOff": 107.5,
      "adjOffRank": 87,
      "adjTempo": 56.4,
      "adjTempoRank": 324,
      "conference": "Horz",
      "rank": 130,
      "record": "16-14"
    },
    "Cal Poly": {
      "adjDef": 105.8,
      "adjDefRank": 205,
      "adjEM": -4.69,
      "adjOff": 101.1,
      "adjOffRank": 196,
      "adjTempo": 69.9,
      "adjTempoRank": 30,
      "conference": "BW",
      "rank": 206,
      "record": "11-16"
    },
    "Cal St. Fullerton": {
      "adjDef": 107.8,
      "adjDefRank": 252,
      "adjEM": -4.85,
      "adjOff": 102.9,
      "adjOffRank": 160,
      "adjTempo": 66.5,
      "adjTempoRank": 134,
      "conference": "BW",
      "rank": 210,
      "record": "11-17"
    },
    "Cal St. Northridge": {
      "adjDef": 102,
      "adjDefRank": 139,
      "adjEM": -2.27,
      "adjOff": 99.7,
      "adjOffRank": 225,
      "adjTempo": 68.1,
      "adjTempoRank": 81,
      "conference": "BW",
      "rank": 179,
      "record": "14-16"
    },
    "California": {
      "adjDef": 98.2,
      "adjDefRank": 68,
      "adjEM": 5.51,
      "adjOff": 103.7,
      "adjOffRank": 147,
      "adjTempo": 64,
      "adjTempoRank": 237,
      "conference": "P10",
      "rank": 102,
      "record": "13-15"
    },
    "Campbell": {
      "adjDef": 114.3,
      "adjDefRank": 325,
      "adjEM": -22.61,
      "adjOff": 91.7,
      "adjOffRank": 305,
      "adjTempo": 73.1,
      "adjTempoRank": 5,
      "conference": "ASun",
      "rank": 314,
      "record": "3-24"
    },
    "Canisius": {
      "adjDef": 107.2,
      "adjDefRank": 242,
      "adjEM": -6.68,
      "adjOff": 100.5,
      "adjOffRank": 209,
      "adjTempo": 66.4,
      "adjTempoRank": 135,
      "conference": "MAAC",
      "rank": 235,
      "record": "10-20"
    },
    "Centenary": {
      "adjDef": 104.1,
      "adjDefRank": 177,
      "adjEM": -1.53,
      "adjOff": 102.6,
      "adjOffRank": 168,
      "adjTempo": 63.7,
      "adjTempoRank": 243,
      "conference": "MCon",
      "rank": 173,
      "record": "16-12"
    },
    "Central Connecticut": {
      "adjDef": 108.4,
      "adjDefRank": 263,
      "adjEM": -6.53,
      "adjOff": 101.9,
      "adjOffRank": 180,
      "adjTempo": 64.2,
      "adjTempoRank": 224,
      "conference": "NEC",
      "rank": 230,
      "record": "14-14"
    },
    "Central Michigan": {
      "adjDef": 109.3,
      "adjDefRank": 280,
      "adjEM": -11.69,
      "adjOff": 97.6,
      "adjOffRank": 261,
      "adjTempo": 70.6,
      "adjTempoRank": 21,
      "conference": "MAC",
      "rank": 273,
      "record": "6-24"
    },
    "Charleston Southern": {
      "adjDef": 108.8,
      "adjDefRank": 271,
      "adjEM": -18.21,
      "adjOff": 90.6,
      "adjOffRank": 308,
      "adjTempo": 61.3,
      "adjTempoRank": 306,
      "conference": "BSth",
      "rank": 308,
      "record": "6-22"
    },
    "Charlotte": {
      "adjDef": 98.5,
      "adjDefRank": 73,
      "adjEM": 14.25,
      "adjOff": 112.7,
      "adjOffRank": 34,
      "adjTempo": 69.7,
      "adjTempoRank": 33,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 43,
      "record": "21-9",
      "seed": 9
    },
    "Chattanooga": {
      "adjDef": 110,
      "adjDefRank": 286,
      "adjEM": 1.18,
      "adjOff": 111.2,
      "adjOffRank": 50,
      "adjTempo": 66.8,
      "adjTempoRank": 122,
      "conference": "SC",
      "rank": 145,
      "record": "19-11"
    },
    "Chicago St.": {
      "adjDef": 108.4,
      "adjDefRank": 260,
      "adjEM": -6.66,
      "adjOff": 101.7,
      "adjOffRank": 185,
      "adjTempo": 63.2,
      "adjTempoRank": 263,
      "conference": "MCon",
      "rank": 234,
      "record": "12-20"
    },
    "Cincinnati": {
      "adjDef": 94.5,
      "adjDefRank": 26,
      "adjEM": 21.56,
      "adjOff": 116.1,
      "adjOffRank": 13,
      "adjTempo": 66.7,
      "adjTempoRank": 127,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 16,
      "record": "25-7",
      "seed": 4
    },
    "Clemson": {
      "adjDef": 97.1,
      "adjDefRank": 55,
      "adjEM": 4.93,
      "adjOff": 102.1,
      "adjOffRank": 176,
      "adjTempo": 64.9,
      "adjTempoRank": 198,
      "conference": "ACC",
      "rank": 107,
      "record": "10-18"
    },
    "Cleveland St.": {
      "adjDef": 106,
      "adjDefRank": 210,
      "adjEM": -12.53,
      "adjOff": 93.5,
      "adjOffRank": 297,
      "adjTempo": 69.3,
      "adjTempoRank": 42,
      "conference": "Horz",
      "rank": 283,
      "record": "4-25"
    },
    "Coastal Carolina": {
      "adjDef": 105.5,
      "adjDefRank": 198,
      "adjEM": -7.11,
      "adjOff": 98.4,
      "adjOffRank": 246,
      "adjTempo": 66.6,
      "adjTempoRank": 130,
      "conference": "BSth",
      "rank": 242,
      "record": "14-15"
    },
    "Colgate": {
      "adjDef": 111.5,
      "adjDefRank": 303,
      "adjEM": -12.4,
      "adjOff": 99.1,
      "adjOffRank": 234,
      "adjTempo": 64.2,
      "adjTempoRank": 225,
      "conference": "Pat",
      "rank": 280,
      "record": "15-14"
    },
    "College of Charleston": {
      "adjDef": 101.6,
      "adjDefRank": 130,
      "adjEM": 3.92,
      "adjOff": 105.5,
      "adjOffRank": 117,
      "adjTempo": 67.7,
      "adjTempoRank": 91,
      "conference": "SC",
      "rank": 116,
      "record": "20-9"
    },
    "Colorado": {
      "adjDef": 99.5,
      "adjDefRank": 93,
      "adjEM": 11.77,
      "adjOff": 111.3,
      "adjOffRank": 48,
      "adjTempo": 68.1,
      "adjTempoRank": 80,
      "conference": "B12",
      "rank": 54,
      "record": "18-11"
    },
    "Colorado St.": {
      "adjDef": 101.6,
      "adjDefRank": 133,
      "adjEM": 2.63,
      "adjOff": 104.3,
      "adjOffRank": 142,
      "adjTempo": 68.3,
      "adjTempoRank": 67,
      "conference": "MWC",
      "rank": 131,
      "record": "13-16"
    },
    "Columbia": {
      "adjDef": 105.9,
      "adjDefRank": 208,
      "adjEM": -10.76,
      "adjOff": 95.2,
      "adjOffRank": 290,
      "adjTempo": 67.5,
      "adjTempoRank": 102,
      "conference": "Ivy",
      "rank": 267,
      "record": "10-17"
    },
    "Connecticut": {
      "adjDef": 89.5,
      "adjDefRank": 6,
      "adjEM": 28.3,
      "adjOff": 117.8,
      "adjOffRank": 9,
      "adjTempo": 68.2,
      "adjTempoRank": 75,
      "conference": "BE",
      "finish": "Champion",
      "rank": 2,
      "record": "33-6",
      "seed": 2
    },
    "Coppin St.": {
      "adjDef": 102,
      "adjDefRank": 141,
      "adjEM": -7.05,
      "adjOff": 95,
      "adjOffRank": 291,
      "adjTempo": 61.7,
      "adjTempoRank": 298,
      "conference": "MEAC",
      "rank": 241,
      "record": "18-14"
    },
    "Cornell": {
      "adjDef": 106.7,
      "adjDefRank": 232,
      "adjEM": -6.57,
      "adjOff": 100.1,
      "adjOffRank": 213,
      "adjTempo": 68.3,
      "adjTempoRank": 68,
      "conference": "Ivy",
      "rank": 231,
      "record": "11-16"
    },
    "Creighton": {
      "adjDef": 97.8,
      "adjDefRank": 62,
      "adjEM": 10.36,
      "adjOff": 108.2,
      "adjOffRank": 78,
      "adjTempo": 63.4,
      "adjTempoRank": 256,
      "conference": "MVC",
      "rank": 65,
      "record": "20-9"
    },
    "Dartmouth": {
      "adjDef": 112.3,
      "adjDefRank": 312,
      "adjEM": -24.48,
      "adjOff": 87.8,
      "adjOffRank": 316,
      "adjTempo": 62,
      "adjTempoRank": 295,
      "conference": "Ivy",
      "rank": 319,
      "record": "3-25"
    },
    "Davidson": {
      "adjDef": 102.1,
      "adjDefRank": 143,
      "adjEM": 1.55,
      "adjOff": 103.6,
      "adjOffRank": 148,
      "adjTempo": 66.3,
      "adjTempoRank": 144,
      "conference": "SC",
      "rank": 137,
      "record": "17-12"
    },
    "Dayton": {
      "adjDef": 98.4,
      "adjDefRank": 71,
      "adjEM": 9.75,
      "adjOff": 108.1,
      "adjOffRank": 80,
      "adjTempo": 62.2,
      "adjTempoRank": 289,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 74,
      "record": "24-9",
      "seed": 10
    },
    "DePaul": {
      "adjDef": 101.6,
      "adjDefRank": 132,
      "adjEM": 11.36,
      "adjOff": 113,
      "adjOffRank": 33,
      "adjTempo": 63.2,
      "adjTempoRank": 267,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 58,
      "record": "22-10",
      "seed": 7
    },
    "Delaware": {
      "adjDef": 98.6,
      "adjDefRank": 79,
      "adjEM": 0.62,
      "adjOff": 99.3,
      "adjOffRank": 231,
      "adjTempo": 68.9,
      "adjTempoRank": 56,
      "conference": "CAA",
      "rank": 150,
      "record": "16-12"
    },
    "Delaware St.": {
      "adjDef": 108.4,
      "adjDefRank": 262,
      "adjEM": -8.46,
      "adjOff": 99.9,
      "adjOffRank": 219,
      "adjTempo": 58.7,
      "adjTempoRank": 320,
      "conference": "MEAC",
      "rank": 251,
      "record": "13-15"
    },
    "Denver": {
      "adjDef": 105.9,
      "adjDefRank": 207,
      "adjEM": -2.02,
      "adjOff": 103.9,
      "adjOffRank": 146,
      "adjTempo": 64.8,
      "adjTempoRank": 200,
      "conference": "SB",
      "rank": 178,
      "record": "14-13"
    },
    "Detroit": {
      "adjDef": 97.2,
      "adjDefRank": 57,
      "adjEM": 9.19,
      "adjOff": 106.4,
      "adjOffRank": 103,
      "adjTempo": 59.1,
      "adjTempoRank": 316,
      "conference": "Horz",
      "rank": 80,
      "record": "19-11"
    },
    "Drake": {
      "adjDef": 100.9,
      "adjDefRank": 119,
      "adjEM": -0.03,
      "adjOff": 100.9,
      "adjOffRank": 203,
      "adjTempo": 68.2,
      "adjTempoRank": 73,
      "conference": "MVC",
      "rank": 155,
      "record": "12-16"
    },
    "Drexel": {
      "adjDef": 100.9,
      "adjDefRank": 118,
      "adjEM": 5.78,
      "adjOff": 106.7,
      "adjOffRank": 100,
      "adjTempo": 67.7,
      "adjTempoRank": 95,
      "conference": "CAA",
      "rank": 99,
      "record": "18-11"
    },
    "Duke": {
      "adjDef": 88.5,
      "adjDefRank": 3,
      "adjEM": 32.33,
      "adjOff": 120.8,
      "adjOffRank": 2,
      "adjTempo": 67.7,
      "adjTempoRank": 94,
      "conference": "ACC",
      "finish": "Final Four",
      "rank": 1,
      "record": "31-6",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 103.5,
      "adjDefRank": 169,
      "adjEM": 1.01,
      "adjOff": 104.5,
      "adjOffRank": 136,
      "adjTempo": 67.5,
      "adjTempoRank": 104,
      "conference": "A10",
      "rank": 146,
      "record": "12-17"
    },
    "East Carolina": {
      "adjDef": 100.4,
      "adjDefRank": 108,
      "adjEM": 4.35,
      "adjOff": 104.7,
      "adjOffRank": 127,
      "adjTempo": 65.4,
      "adjTempoRank": 179,
      "conference": "CUSA",
      "rank": 113,
      "record": "13-14"
    },
    "East Tennessee St.": {
      "adjDef": 95.8,
      "adjDefRank": 41,
      "adjEM": 8.6,
      "adjOff": 104.4,
      "adjOffRank": 140,
      "adjTempo": 73.2,
      "adjTempoRank": 3,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 85,
      "record": "27-6",
      "seed": 13
    },
    "Eastern Illinois": {
      "adjDef": 107.9,
      "adjDefRank": 253,
      "adjEM": -12.32,
      "adjOff": 95.6,
      "adjOffRank": 285,
      "adjTempo": 68.2,
      "adjTempoRank": 77,
      "conference": "OVC",
      "rank": 279,
      "record": "6-21"
    },
    "Eastern Kentucky": {
      "adjDef": 106.4,
      "adjDefRank": 227,
      "adjEM": -4.18,
      "adjOff": 102.2,
      "adjOffRank": 172,
      "adjTempo": 67.2,
      "adjTempoRank": 115,
      "conference": "OVC",
      "rank": 197,
      "record": "14-15"
    },
    "Eastern Michigan": {
      "adjDef": 104.4,
      "adjDefRank": 179,
      "adjEM": -5.17,
      "adjOff": 99.2,
      "adjOffRank": 232,
      "adjTempo": 68,
      "adjTempoRank": 87,
      "conference": "MAC",
      "rank": 216,
      "record": "13-15"
    },
    "Eastern Washington": {
      "adjDef": 102.3,
      "adjDefRank": 150,
      "adjEM": 3.09,
      "adjOff": 105.4,
      "adjOffRank": 118,
      "adjTempo": 64.5,
      "adjTempoRank": 215,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 125,
      "record": "17-13",
      "seed": 15
    },
    "Elon": {
      "adjDef": 107.7,
      "adjDefRank": 251,
      "adjEM": -8.69,
      "adjOff": 99,
      "adjOffRank": 237,
      "adjTempo": 65.4,
      "adjTempoRank": 182,
      "conference": "SC",
      "rank": 252,
      "record": "12-18"
    },
    "Evansville": {
      "adjDef": 109.4,
      "adjDefRank": 282,
      "adjEM": -4.76,
      "adjOff": 104.7,
      "adjOffRank": 130,
      "adjTempo": 67.5,
      "adjTempoRank": 100,
      "conference": "MVC",
      "rank": 208,
      "record": "7-22"
    },
    "FIU": {
      "adjDef": 104.8,
      "adjDefRank": 186,
      "adjEM": -20.85,
      "adjOff": 84,
      "adjOffRank": 321,
      "adjTempo": 62.1,
      "adjTempoRank": 291,
      "conference": "SB",
      "rank": 311,
      "record": "5-22"
    },
    "Fairfield": {
      "adjDef": 97.3,
      "adjDefRank": 58,
      "adjEM": 4.95,
      "adjOff": 102.2,
      "adjOffRank": 171,
      "adjTempo": 62.1,
      "adjTempoRank": 290,
      "conference": "MAAC",
      "rank": 106,
      "record": "19-11"
    },
    "Fairleigh Dickinson": {
      "adjDef": 106.3,
      "adjDefRank": 225,
      "adjEM": -6.23,
      "adjOff": 100.1,
      "adjOffRank": 214,
      "adjTempo": 64.1,
      "adjTempoRank": 229,
      "conference": "NEC",
      "rank": 226,
      "record": "17-12"
    },
    "Florida": {
      "adjDef": 98.6,
      "adjDefRank": 78,
      "adjEM": 18.26,
      "adjOff": 116.9,
      "adjOffRank": 12,
      "adjTempo": 65.9,
      "adjTempoRank": 162,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 25,
      "record": "20-11",
      "seed": 5
    },
    "Florida A&M": {
      "adjDef": 108.3,
      "adjDefRank": 257,
      "adjEM": -9.35,
      "adjOff": 98.9,
      "adjOffRank": 239,
      "adjTempo": 70.9,
      "adjTempoRank": 15,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 256,
      "record": "15-17",
      "seed": 16
    },
    "Florida Atlantic": {
      "adjDef": 107.2,
      "adjDefRank": 241,
      "adjEM": -7.28,
      "adjOff": 99.9,
      "adjOffRank": 220,
      "adjTempo": 71.1,
      "adjTempoRank": 13,
      "conference": "ASun",
      "rank": 245,
      "record": "9-19"
    },
    "Florida St.": {
      "adjDef": 91.5,
      "adjDefRank": 14,
      "adjEM": 17.48,
      "adjOff": 109,
      "adjOffRank": 74,
      "adjTempo": 64.3,
      "adjTempoRank": 221,
      "conference": "ACC",
      "rank": 26,
      "record": "19-14"
    },
    "Fordham": {
      "adjDef": 106.4,
      "adjDefRank": 226,
      "adjEM": -9.91,
      "adjOff": 96.5,
      "adjOffRank": 276,
      "adjTempo": 66.4,
      "adjTempoRank": 139,
      "conference": "A10",
      "rank": 262,
      "record": "6-22"
    },
    "Fresno St.": {
      "adjDef": 99.6,
      "adjDefRank": 97,
      "adjEM": 7.09,
      "adjOff": 106.7,
      "adjOffRank": 99,
      "adjTempo": 58.7,
      "adjTempoRank": 319,
      "conference": "WAC",
      "rank": 91,
      "record": "14-15"
    },
    "Furman": {
      "adjDef": 102.4,
      "adjDefRank": 153,
      "adjEM": -0.76,
      "adjOff": 101.6,
      "adjOffRank": 188,
      "adjTempo": 66.6,
      "adjTempoRank": 132,
      "conference": "SC",
      "rank": 164,
      "record": "17-12"
    },
    "Gardner Webb": {
      "adjDef": 109.3,
      "adjDefRank": 279,
      "adjEM": -12.03,
      "adjOff": 97.2,
      "adjOffRank": 264,
      "adjTempo": 65.8,
      "adjTempoRank": 167,
      "conference": "ASun",
      "rank": 277,
      "record": "9-20"
    },
    "George Mason": {
      "adjDef": 96.8,
      "adjDefRank": 52,
      "adjEM": 6.24,
      "adjOff": 103,
      "adjOffRank": 155,
      "adjTempo": 67.5,
      "adjTempoRank": 103,
      "conference": "CAA",
      "rank": 96,
      "record": "23-10"
    },
    "George Washington": {
      "adjDef": 99.5,
      "adjDefRank": 94,
      "adjEM": 13.08,
      "adjOff": 112.6,
      "adjOffRank": 35,
      "adjTempo": 68.3,
      "adjTempoRank": 69,
      "conference": "A10",
      "rank": 50,
      "record": "18-12"
    },
    "Georgetown": {
      "adjDef": 96,
      "adjDefRank": 44,
      "adjEM": 3.1,
      "adjOff": 99.1,
      "adjOffRank": 235,
      "adjTempo": 68.4,
      "adjTempoRank": 66,
      "conference": "BE",
      "rank": 124,
      "record": "13-15"
    },
    "Georgia": {
      "adjDef": 98.5,
      "adjDefRank": 75,
      "adjEM": 9.54,
      "adjOff": 108.1,
      "adjOffRank": 82,
      "adjTempo": 62.3,
      "adjTempoRank": 287,
      "conference": "SEC",
      "rank": 76,
      "record": "16-14"
    },
    "Georgia Southern": {
      "adjDef": 99.6,
      "adjDefRank": 98,
      "adjEM": 1.27,
      "adjOff": 100.9,
      "adjOffRank": 204,
      "adjTempo": 72.5,
      "adjTempoRank": 8,
      "conference": "SC",
      "rank": 143,
      "record": "21-8"
    },
    "Georgia St.": {
      "adjDef": 102.6,
      "adjDefRank": 156,
      "adjEM": 1.88,
      "adjOff": 104.5,
      "adjOffRank": 139,
      "adjTempo": 65.5,
      "adjTempoRank": 175,
      "conference": "ASun",
      "rank": 134,
      "record": "20-9"
    },
    "Georgia Tech": {
      "adjDef": 88.8,
      "adjDefRank": 4,
      "adjEM": 24.69,
      "adjOff": 113.5,
      "adjOffRank": 28,
      "adjTempo": 69.1,
      "adjTempoRank": 49,
      "conference": "ACC",
      "finish": "Runner-up",
      "rank": 8,
      "record": "28-10",
      "seed": 3
    },
    "Gonzaga": {
      "adjDef": 95.5,
      "adjDefRank": 36,
      "adjEM": 25.34,
      "adjOff": 120.8,
      "adjOffRank": 3,
      "adjTempo": 66.6,
      "adjTempoRank": 128,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 7,
      "record": "28-3",
      "seed": 2
    },
    "Grambling St.": {
      "adjDef": 108.9,
      "adjDefRank": 272,
      "adjEM": -15.43,
      "adjOff": 93.4,
      "adjOffRank": 298,
      "adjTempo": 69.1,
      "adjTempoRank": 48,
      "conference": "SWAC",
      "rank": 299,
      "record": "11-18"
    },
    "Green Bay": {
      "adjDef": 101.7,
      "adjDefRank": 136,
      "adjEM": -0.04,
      "adjOff": 101.7,
      "adjOffRank": 186,
      "adjTempo": 63.1,
      "adjTempoRank": 269,
      "conference": "Horz",
      "rank": 156,
      "record": "17-11"
    },
    "Hampton": {
      "adjDef": 109.2,
      "adjDefRank": 276,
      "adjEM": -10.91,
      "adjOff": 98.2,
      "adjOffRank": 249,
      "adjTempo": 68.3,
      "adjTempoRank": 70,
      "conference": "MEAC",
      "rank": 268,
      "record": "13-17"
    },
    "Hartford": {
      "adjDef": 109.7,
      "adjDefRank": 284,
      "adjEM": -14.16,
      "adjOff": 95.6,
      "adjOffRank": 286,
      "adjTempo": 64,
      "adjTempoRank": 235,
      "conference": "AE",
      "rank": 295,
      "record": "12-17"
    },
    "Harvard": {
      "adjDef": 108.1,
      "adjDefRank": 256,
      "adjEM": -18.14,
      "adjOff": 90,
      "adjOffRank": 311,
      "adjTempo": 70.8,
      "adjTempoRank": 18,
      "conference": "Ivy",
      "rank": 307,
      "record": "4-23"
    },
    "Hawaii": {
      "adjDef": 100.6,
      "adjDefRank": 113,
      "adjEM": 9.27,
      "adjOff": 109.8,
      "adjOffRank": 62,
      "adjTempo": 65.3,
      "adjTempoRank": 184,
      "conference": "WAC",
      "rank": 78,
      "record": "21-12"
    },
    "High Point": {
      "adjDef": 107.1,
      "adjDefRank": 240,
      "adjEM": -9.19,
      "adjOff": 97.9,
      "adjOffRank": 256,
      "adjTempo": 69.3,
      "adjTempoRank": 43,
      "conference": "BSth",
      "rank": 255,
      "record": "19-11"
    },
    "Hofstra": {
      "adjDef": 104.3,
      "adjDefRank": 178,
      "adjEM": 1.42,
      "adjOff": 105.7,
      "adjOffRank": 113,
      "adjTempo": 67.4,
      "adjTempoRank": 107,
      "conference": "CAA",
      "rank": 140,
      "record": "14-15"
    },
    "Holy Cross": {
      "adjDef": 98,
      "adjDefRank": 65,
      "adjEM": -1.23,
      "adjOff": 96.8,
      "adjOffRank": 270,
      "adjTempo": 63.4,
      "adjTempoRank": 254,
      "conference": "Pat",
      "rank": 171,
      "record": "13-15"
    },
    "Houston": {
      "adjDef": 99.4,
      "adjDefRank": 91,
      "adjEM": -5.5,
      "adjOff": 93.9,
      "adjOffRank": 294,
      "adjTempo": 61,
      "adjTempoRank": 308,
      "conference": "CUSA",
      "rank": 220,
      "record": "9-18"
    },
    "Howard": {
      "adjDef": 106.8,
      "adjDefRank": 234,
      "adjEM": -19.39,
      "adjOff": 87.4,
      "adjOffRank": 318,
      "adjTempo": 65.9,
      "adjTempoRank": 166,
      "conference": "MEAC",
      "rank": 309,
      "record": "6-22"
    },
    "IPFW": {
      "adjDef": 110.7,
      "adjDefRank": 296,
      "adjEM": -12.89,
      "adjOff": 97.8,
      "adjOffRank": 257,
      "adjTempo": 64.4,
      "adjTempoRank": 218,
      "conference": "ind",
      "rank": 286,
      "record": "3-25"
    },
    "IUPUI": {
      "adjDef": 106.9,
      "adjDefRank": 235,
      "adjEM": 3.85,
      "adjOff": 110.7,
      "adjOffRank": 54,
      "adjTempo": 64.5,
      "adjTempoRank": 213,
      "conference": "MCon",
      "rank": 117,
      "record": "21-11"
    },
    "Idaho": {
      "adjDef": 100,
      "adjDefRank": 103,
      "adjEM": -0.31,
      "adjOff": 99.7,
      "adjOffRank": 224,
      "adjTempo": 63.4,
      "adjTempoRank": 258,
      "conference": "BW",
      "rank": 158,
      "record": "14-16"
    },
    "Idaho St.": {
      "adjDef": 111.6,
      "adjDefRank": 306,
      "adjEM": -10.15,
      "adjOff": 101.5,
      "adjOffRank": 189,
      "adjTempo": 69.4,
      "adjTempoRank": 39,
      "conference": "BSky",
      "rank": 263,
      "record": "13-18"
    },
    "Illinois": {
      "adjDef": 95.7,
      "adjDefRank": 39,
      "adjEM": 22.11,
      "adjOff": 117.8,
      "adjOffRank": 8,
      "adjTempo": 63.5,
      "adjTempoRank": 252,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "26-7",
      "seed": 5
    },
    "Illinois Chicago": {
      "adjDef": 97.1,
      "adjDefRank": 54,
      "adjEM": 10.39,
      "adjOff": 107.5,
      "adjOffRank": 86,
      "adjTempo": 64.6,
      "adjTempoRank": 208,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 63,
      "record": "24-8",
      "seed": 13
    },
    "Illinois St.": {
      "adjDef": 103.4,
      "adjDefRank": 166,
      "adjEM": -1.98,
      "adjOff": 101.4,
      "adjOffRank": 191,
      "adjTempo": 61.5,
      "adjTempoRank": 301,
      "conference": "MVC",
      "rank": 177,
      "record": "10-19"
    },
    "Indiana": {
      "adjDef": 98.4,
      "adjDefRank": 72,
      "adjEM": 6.81,
      "adjOff": 105.3,
      "adjOffRank": 120,
      "adjTempo": 64,
      "adjTempoRank": 234,
      "conference": "B10",
      "rank": 94,
      "record": "14-15"
    },
    "Indiana St.": {
      "adjDef": 100.7,
      "adjDefRank": 115,
      "adjEM": -3.69,
      "adjOff": 97,
      "adjOffRank": 269,
      "adjTempo": 59.9,
      "adjTempoRank": 312,
      "conference": "MVC",
      "rank": 190,
      "record": "9-19"
    },
    "Iona": {
      "adjDef": 105.6,
      "adjDefRank": 199,
      "adjEM": -3.98,
      "adjOff": 101.6,
      "adjOffRank": 187,
      "adjTempo": 69.2,
      "adjTempoRank": 45,
      "conference": "MAAC",
      "rank": 193,
      "record": "11-18"
    },
    "Iowa": {
      "adjDef": 96.6,
      "adjDefRank": 49,
      "adjEM": 10.1,
      "adjOff": 106.7,
      "adjOffRank": 98,
      "adjTempo": 68.5,
      "adjTempoRank": 64,
      "conference": "B10",
      "rank": 69,
      "record": "16-13"
    },
    "Iowa St.": {
      "adjDef": 98.8,
      "adjDefRank": 83,
      "adjEM": 10.37,
      "adjOff": 109.2,
      "adjOffRank": 69,
      "adjTempo": 69.7,
      "adjTempoRank": 34,
      "conference": "B12",
      "rank": 64,
      "record": "20-13"
    },
    "Jackson St.": {
      "adjDef": 102.2,
      "adjDefRank": 147,
      "adjEM": -13.87,
      "adjOff": 88.4,
      "adjOffRank": 314,
      "adjTempo": 68.2,
      "adjTempoRank": 78,
      "conference": "SWAC",
      "rank": 291,
      "record": "12-17"
    },
    "Jacksonville": {
      "adjDef": 110.3,
      "adjDefRank": 291,
      "adjEM": -13.11,
      "adjOff": 97.2,
      "adjOffRank": 267,
      "adjTempo": 67.6,
      "adjTempoRank": 96,
      "conference": "ASun",
      "rank": 289,
      "record": "13-15"
    },
    "Jacksonville St.": {
      "adjDef": 111.7,
      "adjDefRank": 308,
      "adjEM": -6.96,
      "adjOff": 104.8,
      "adjOffRank": 126,
      "adjTempo": 67.3,
      "adjTempoRank": 111,
      "conference": "OVC",
      "rank": 239,
      "record": "14-14"
    },
    "James Madison": {
      "adjDef": 110,
      "adjDefRank": 287,
      "adjEM": -10.19,
      "adjOff": 99.8,
      "adjOffRank": 222,
      "adjTempo": 64.3,
      "adjTempoRank": 219,
      "conference": "CAA",
      "rank": 264,
      "record": "7-21"
    },
    "Kansas": {
      "adjDef": 91.4,
      "adjDefRank": 13,
      "adjEM": 20.89,
      "adjOff": 112.3,
      "adjOffRank": 38,
      "adjTempo": 69.4,
      "adjTempoRank": 40,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 18,
      "record": "24-9",
      "seed": 4
    },
    "Kansas St.": {
      "adjDef": 95.7,
      "adjDefRank": 40,
      "adjEM": 8.89,
      "adjOff": 104.6,
      "adjOffRank": 131,
      "adjTempo": 64.9,
      "adjTempoRank": 199,
      "conference": "B12",
      "rank": 83,
      "record": "14-14"
    },
    "Kent St.": {
      "adjDef": 97.2,
      "adjDefRank": 56,
      "adjEM": 9.68,
      "adjOff": 106.9,
      "adjOffRank": 97,
      "adjTempo": 65.6,
      "adjTempoRank": 172,
      "conference": "MAC",
      "rank": 75,
      "record": "22-9"
    },
    "Kentucky": {
      "adjDef": 90.1,
      "adjDefRank": 7,
      "adjEM": 23.35,
      "adjOff": 113.5,
      "adjOffRank": 26,
      "adjTempo": 66.1,
      "adjTempoRank": 151,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 10,
      "record": "27-5",
      "seed": 1
    },
    "LIU Brooklyn": {
      "adjDef": 109.4,
      "adjDefRank": 281,
      "adjEM": -16.6,
      "adjOff": 92.8,
      "adjOffRank": 302,
      "adjTempo": 71,
      "adjTempoRank": 14,
      "conference": "NEC",
      "rank": 303,
      "record": "8-19"
    },
    "LSU": {
      "adjDef": 96.9,
      "adjDefRank": 53,
      "adjEM": 10.04,
      "adjOff": 107,
      "adjOffRank": 96,
      "adjTempo": 63.6,
      "adjTempoRank": 246,
      "conference": "SEC",
      "rank": 70,
      "record": "18-11"
    },
    "La Salle": {
      "adjDef": 106.1,
      "adjDefRank": 211,
      "adjEM": -3.02,
      "adjOff": 103,
      "adjOffRank": 156,
      "adjTempo": 64.1,
      "adjTempoRank": 231,
      "conference": "A10",
      "rank": 183,
      "record": "10-20"
    },
    "Lafayette": {
      "adjDef": 111.9,
      "adjDefRank": 309,
      "adjEM": -5.28,
      "adjOff": 106.6,
      "adjOffRank": 102,
      "adjTempo": 68.2,
      "adjTempoRank": 74,
      "conference": "Pat",
      "rank": 218,
      "record": "18-10"
    },
    "Lamar": {
      "adjDef": 109.8,
      "adjDefRank": 285,
      "adjEM": -12.63,
      "adjOff": 97.2,
      "adjOffRank": 265,
      "adjTempo": 73.5,
      "adjTempoRank": 2,
      "conference": "Slnd",
      "rank": 284,
      "record": "11-18"
    },
    "Lehigh": {
      "adjDef": 104.7,
      "adjDefRank": 185,
      "adjEM": -7.01,
      "adjOff": 97.7,
      "adjOffRank": 259,
      "adjTempo": 63.4,
      "adjTempoRank": 257,
      "conference": "Pat",
      "finish": "Play-in",
      "rank": 240,
      "record": "20-11",
      "seed": 16
    },
    "Liberty": {
      "adjDef": 100.8,
      "adjDefRank": 116,
      "adjEM": -2.5,
      "adjOff": 98.3,
      "adjOffRank": 248,
      "adjTempo": 67.5,
      "adjTempoRank": 101,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 181,
      "record": "18-15",
      "seed": 16
    },
    "Lipscomb": {
      "adjDef": 114.3,
      "adjDefRank": 324,
      "adjEM": -17.5,
      "adjOff": 96.8,
      "adjOffRank": 271,
      "adjTempo": 66.2,
      "adjTempoRank": 145,
      "conference": "ASun",
      "rank": 306,
      "record": "7-21"
    },
    "Long Beach St.": {
      "adjDef": 110.3,
      "adjDefRank": 290,
      "adjEM": -14.09,
      "adjOff": 96.2,
      "adjOffRank": 278,
      "adjTempo": 65.3,
      "adjTempoRank": 183,
      "conference": "BW",
      "rank": 294,
      "record": "6-21"
    },
    "Louisiana Lafayette": {
      "adjDef": 99,
      "adjDefRank": 85,
      "adjEM": 10.23,
      "adjOff": 109.2,
      "adjOffRank": 68,
      "adjTempo": 69.1,
      "adjTempoRank": 52,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 67,
      "record": "20-9",
      "seed": 14
    },
    "Louisiana Monroe": {
      "adjDef": 105,
      "adjDefRank": 191,
      "adjEM": -11.86,
      "adjOff": 93.2,
      "adjOffRank": 300,
      "adjTempo": 68.5,
      "adjTempoRank": 63,
      "conference": "Slnd",
      "rank": 275,
      "record": "12-19"
    },
    "Louisiana Tech": {
      "adjDef": 98.8,
      "adjDefRank": 82,
      "adjEM": 3.8,
      "adjOff": 102.6,
      "adjOffRank": 167,
      "adjTempo": 64.9,
      "adjTempoRank": 197,
      "conference": "WAC",
      "rank": 119,
      "record": "15-15"
    },
    "Louisville": {
      "adjDef": 86.6,
      "adjDefRank": 1,
      "adjEM": 20.44,
      "adjOff": 107.1,
      "adjOffRank": 94,
      "adjTempo": 67.4,
      "adjTempoRank": 106,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 19,
      "record": "20-10",
      "seed": 10
    },
    "Loyola Chicago": {
      "adjDef": 104.9,
      "adjDefRank": 189,
      "adjEM": -3.94,
      "adjOff": 101,
      "adjOffRank": 198,
      "adjTempo": 69.8,
      "adjTempoRank": 31,
      "conference": "Horz",
      "rank": 191,
      "record": "9-20"
    },
    "Loyola MD": {
      "adjDef": 113,
      "adjDefRank": 318,
      "adjEM": -23.48,
      "adjOff": 89.5,
      "adjOffRank": 312,
      "adjTempo": 66,
      "adjTempoRank": 157,
      "conference": "MAAC",
      "rank": 315,
      "record": "1-27"
    },
    "Loyola Marymount": {
      "adjDef": 100.2,
      "adjDefRank": 105,
      "adjEM": -2.81,
      "adjOff": 97.4,
      "adjOffRank": 263,
      "adjTempo": 66.3,
      "adjTempoRank": 143,
      "conference": "WCC",
      "rank": 182,
      "record": "15-14"
    },
    "Maine": {
      "adjDef": 103.5,
      "adjDefRank": 172,
      "adjEM": -4.57,
      "adjOff": 99,
      "adjOffRank": 238,
      "adjTempo": 65.4,
      "adjTempoRank": 178,
      "conference": "AE",
      "rank": 204,
      "record": "20-10"
    },
    "Manhattan": {
      "adjDef": 95.7,
      "adjDefRank": 38,
      "adjEM": 13.38,
      "adjOff": 109.1,
      "adjOffRank": 71,
      "adjTempo": 65,
      "adjTempoRank": 194,
      "conference": "MAAC",
      "finish": "Round of 32",
      "rank": 48,
      "record": "25-6",
      "seed": 12
    },
    "Marist": {
      "adjDef": 107,
      "adjDefRank": 239,
      "adjEM": -13.91,
      "adjOff": 93.1,
      "adjOffRank": 301,
      "adjTempo": 64,
      "adjTempoRank": 233,
      "conference": "MAAC",
      "rank": 292,
      "record": "6-22"
    },
    "Marquette": {
      "adjDef": 104.6,
      "adjDefRank": 182,
      "adjEM": 7.03,
      "adjOff": 111.7,
      "adjOffRank": 44,
      "adjTempo": 64.2,
      "adjTempoRank": 223,
      "conference": "CUSA",
      "rank": 92,
      "record": "19-12"
    },
    "Marshall": {
      "adjDef": 103.6,
      "adjDefRank": 173,
      "adjEM": -2.44,
      "adjOff": 101.2,
      "adjOffRank": 195,
      "adjTempo": 68.2,
      "adjTempoRank": 72,
      "conference": "MAC",
      "rank": 180,
      "record": "12-17"
    },
    "Maryland": {
      "adjDef": 91.1,
      "adjDefRank": 10,
      "adjEM": 19.81,
      "adjOff": 110.9,
      "adjOffRank": 52,
      "adjTempo": 71.1,
      "adjTempoRank": 12,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "20-12",
      "seed": 4
    },
    "Maryland Eastern Shore": {
      "adjDef": 115.9,
      "adjDefRank": 326,
      "adjEM": -24.91,
      "adjOff": 91,
      "adjOffRank": 307,
      "adjTempo": 61.4,
      "adjTempoRank": 303,
      "conference": "MEAC",
      "rank": 322,
      "record": "8-21"
    },
    "Massachusetts": {
      "adjDef": 106.1,
      "adjDefRank": 215,
      "adjEM": -3.13,
      "adjOff": 103,
      "adjOffRank": 157,
      "adjTempo": 66.5,
      "adjTempoRank": 133,
      "conference": "A10",
      "rank": 184,
      "record": "10-19"
    },
    "McNeese St.": {
      "adjDef": 106.4,
      "adjDefRank": 229,
      "adjEM": -6.92,
      "adjOff": 99.5,
      "adjOffRank": 229,
      "adjTempo": 71.1,
      "adjTempoRank": 11,
      "conference": "Slnd",
      "rank": 238,
      "record": "11-16"
    },
    "Memphis": {
      "adjDef": 95.6,
      "adjDefRank": 37,
      "adjEM": 16.17,
      "adjOff": 111.8,
      "adjOffRank": 43,
      "adjTempo": 66.6,
      "adjTempoRank": 131,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 33,
      "record": "22-8",
      "seed": 7
    },
    "Mercer": {
      "adjDef": 110.3,
      "adjDefRank": 292,
      "adjEM": -7.29,
      "adjOff": 103,
      "adjOffRank": 159,
      "adjTempo": 67.2,
      "adjTempoRank": 114,
      "conference": "ASun",
      "rank": 247,
      "record": "12-18"
    },
    "Miami FL": {
      "adjDef": 102.8,
      "adjDefRank": 160,
      "adjEM": 4.6,
      "adjOff": 107.4,
      "adjOffRank": 89,
      "adjTempo": 65.2,
      "adjTempoRank": 189,
      "conference": "BE",
      "rank": 110,
      "record": "14-16"
    },
    "Miami OH": {
      "adjDef": 101.1,
      "adjDefRank": 121,
      "adjEM": 3.43,
      "adjOff": 104.5,
      "adjOffRank": 138,
      "adjTempo": 58.6,
      "adjTempoRank": 321,
      "conference": "MAC",
      "rank": 122,
      "record": "18-11"
    },
    "Michigan": {
      "adjDef": 93.3,
      "adjDefRank": 19,
      "adjEM": 14.14,
      "adjOff": 107.4,
      "adjOffRank": 91,
      "adjTempo": 66.4,
      "adjTempoRank": 138,
      "conference": "B10",
      "rank": 44,
      "record": "23-11"
    },
    "Michigan St.": {
      "adjDef": 101.5,
      "adjDefRank": 129,
      "adjEM": 15.66,
      "adjOff": 117.2,
      "adjOffRank": 11,
      "adjTempo": 64,
      "adjTempoRank": 232,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 34,
      "record": "18-12",
      "seed": 7
    },
    "Middle Tennessee": {
      "adjDef": 101.6,
      "adjDefRank": 131,
      "adjEM": 1.81,
      "adjOff": 103.4,
      "adjOffRank": 151,
      "adjTempo": 65.3,
      "adjTempoRank": 186,
      "conference": "SB",
      "rank": 135,
      "record": "17-12"
    },
    "Milwaukee": {
      "adjDef": 101.7,
      "adjDefRank": 137,
      "adjEM": 10.52,
      "adjOff": 112.2,
      "adjOffRank": 41,
      "adjTempo": 70.4,
      "adjTempoRank": 25,
      "conference": "Horz",
      "rank": 62,
      "record": "20-11"
    },
    "Minnesota": {
      "adjDef": 103,
      "adjDefRank": 162,
      "adjEM": 2.23,
      "adjOff": 105.2,
      "adjOffRank": 123,
      "adjTempo": 67.3,
      "adjTempoRank": 112,
      "conference": "B10",
      "rank": 133,
      "record": "12-18"
    },
    "Mississippi": {
      "adjDef": 99.7,
      "adjDefRank": 100,
      "adjEM": 2.99,
      "adjOff": 102.7,
      "adjOffRank": 163,
      "adjTempo": 62.9,
      "adjTempoRank": 274,
      "conference": "SEC",
      "rank": 128,
      "record": "13-15"
    },
    "Mississippi St.": {
      "adjDef": 92.9,
      "adjDefRank": 17,
      "adjEM": 21.74,
      "adjOff": 114.7,
      "adjOffRank": 18,
      "adjTempo": 68.1,
      "adjTempoRank": 82,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 14,
      "record": "26-4",
      "seed": 2
    },
    "Mississippi Valley St.": {
      "adjDef": 100.5,
      "adjDefRank": 111,
      "adjEM": -1.13,
      "adjOff": 99.4,
      "adjOffRank": 230,
      "adjTempo": 63.8,
      "adjTempoRank": 242,
      "conference": "SWAC",
      "rank": 168,
      "record": "22-7"
    },
    "Missouri": {
      "adjDef": 100.4,
      "adjDefRank": 109,
      "adjEM": 13.08,
      "adjOff": 113.5,
      "adjOffRank": 27,
      "adjTempo": 65.6,
      "adjTempoRank": 170,
      "conference": "B12",
      "rank": 51,
      "record": "16-14"
    },
    "Monmouth": {
      "adjDef": 102.1,
      "adjDefRank": 145,
      "adjEM": -3.99,
      "adjOff": 98.1,
      "adjOffRank": 251,
      "adjTempo": 66,
      "adjTempoRank": 155,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 194,
      "record": "21-12",
      "seed": 15
    },
    "Montana": {
      "adjDef": 106.3,
      "adjDefRank": 223,
      "adjEM": -7.28,
      "adjOff": 99,
      "adjOffRank": 236,
      "adjTempo": 67.3,
      "adjTempoRank": 110,
      "conference": "BSky",
      "rank": 246,
      "record": "10-18"
    },
    "Montana St.": {
      "adjDef": 102.8,
      "adjDefRank": 159,
      "adjEM": -5.69,
      "adjOff": 97.1,
      "adjOffRank": 268,
      "adjTempo": 66,
      "adjTempoRank": 154,
      "conference": "BSky",
      "rank": 222,
      "record": "14-13"
    },
    "Morehead St.": {
      "adjDef": 111.6,
      "adjDefRank": 304,
      "adjEM": -4.45,
      "adjOff": 107.1,
      "adjOffRank": 93,
      "adjTempo": 66.6,
      "adjTempoRank": 129,
      "conference": "OVC",
      "rank": 202,
      "record": "16-13"
    },
    "Morgan St.": {
      "adjDef": 111.2,
      "adjDefRank": 301,
      "adjEM": -14.78,
      "adjOff": 96.4,
      "adjOffRank": 277,
      "adjTempo": 68.2,
      "adjTempoRank": 71,
      "conference": "MEAC",
      "rank": 297,
      "record": "11-16"
    },
    "Mount St. Mary's": {
      "adjDef": 108.3,
      "adjDefRank": 258,
      "adjEM": -11.6,
      "adjOff": 96.7,
      "adjOffRank": 272,
      "adjTempo": 64.6,
      "adjTempoRank": 209,
      "conference": "NEC",
      "rank": 272,
      "record": "10-19"
    },
    "Murray St.": {
      "adjDef": 99.9,
      "adjDefRank": 101,
      "adjEM": 10.62,
      "adjOff": 110.5,
      "adjOffRank": 56,
      "adjTempo": 68.7,
      "adjTempoRank": 59,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 61,
      "record": "28-6",
      "seed": 12
    },
    "N.C. State": {
      "adjDef": 95.1,
      "adjDefRank": 33,
      "adjEM": 23.98,
      "adjOff": 119.1,
      "adjOffRank": 4,
      "adjTempo": 62.1,
      "adjTempoRank": 292,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 9,
      "record": "21-10",
      "seed": 3
    },
    "Navy": {
      "adjDef": 109.2,
      "adjDefRank": 277,
      "adjEM": -27.26,
      "adjOff": 81.9,
      "adjOffRank": 323,
      "adjTempo": 66.1,
      "adjTempoRank": 150,
      "conference": "Pat",
      "rank": 324,
      "record": "5-23"
    },
    "Nebraska": {
      "adjDef": 98.7,
      "adjDefRank": 80,
      "adjEM": 14.86,
      "adjOff": 113.6,
      "adjOffRank": 24,
      "adjTempo": 62.1,
      "adjTempoRank": 293,
      "conference": "B12",
      "rank": 40,
      "record": "18-13"
    },
    "Nevada": {
      "adjDef": 92,
      "adjDefRank": 15,
      "adjEM": 20.27,
      "adjOff": 112.3,
      "adjOffRank": 39,
      "adjTempo": 68.7,
      "adjTempoRank": 58,
      "conference": "WAC",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "25-9",
      "seed": 10
    },
    "New Hampshire": {
      "adjDef": 107.6,
      "adjDefRank": 249,
      "adjEM": -14.06,
      "adjOff": 93.5,
      "adjOffRank": 296,
      "adjTempo": 64.1,
      "adjTempoRank": 228,
      "conference": "AE",
      "rank": 293,
      "record": "10-20"
    },
    "New Mexico": {
      "adjDef": 106.1,
      "adjDefRank": 213,
      "adjEM": 4.19,
      "adjOff": 110.3,
      "adjOffRank": 59,
      "adjTempo": 61.6,
      "adjTempoRank": 299,
      "conference": "MWC",
      "rank": 115,
      "record": "14-14"
    },
    "New Mexico St.": {
      "adjDef": 102.6,
      "adjDefRank": 157,
      "adjEM": -4.28,
      "adjOff": 98.3,
      "adjOffRank": 247,
      "adjTempo": 63.7,
      "adjTempoRank": 244,
      "conference": "SB",
      "rank": 199,
      "record": "13-14"
    },
    "New Orleans": {
      "adjDef": 103.7,
      "adjDefRank": 174,
      "adjEM": -1.11,
      "adjOff": 102.6,
      "adjOffRank": 166,
      "adjTempo": 64.8,
      "adjTempoRank": 205,
      "conference": "SB",
      "rank": 167,
      "record": "17-14"
    },
    "Niagara": {
      "adjDef": 106.1,
      "adjDefRank": 212,
      "adjEM": 5.45,
      "adjOff": 111.5,
      "adjOffRank": 45,
      "adjTempo": 69.9,
      "adjTempoRank": 28,
      "conference": "MAAC",
      "rank": 104,
      "record": "22-10"
    },
    "Nicholls St.": {
      "adjDef": 114.2,
      "adjDefRank": 323,
      "adjEM": -24,
      "adjOff": 90.2,
      "adjOffRank": 310,
      "adjTempo": 66,
      "adjTempoRank": 156,
      "conference": "Slnd",
      "rank": 317,
      "record": "6-21"
    },
    "Norfolk St.": {
      "adjDef": 108.4,
      "adjDefRank": 261,
      "adjEM": -16.07,
      "adjOff": 92.3,
      "adjOffRank": 303,
      "adjTempo": 63.2,
      "adjTempoRank": 266,
      "conference": "MEAC",
      "rank": 301,
      "record": "12-17"
    },
    "North Carolina": {
      "adjDef": 94.1,
      "adjDefRank": 23,
      "adjEM": 23.23,
      "adjOff": 117.4,
      "adjOffRank": 10,
      "adjTempo": 70.9,
      "adjTempoRank": 16,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 11,
      "record": "19-11",
      "seed": 6
    },
    "North Carolina A&T": {
      "adjDef": 103.4,
      "adjDefRank": 167,
      "adjEM": -26.66,
      "adjOff": 76.8,
      "adjOffRank": 325,
      "adjTempo": 73.1,
      "adjTempoRank": 4,
      "conference": "MEAC",
      "rank": 323,
      "record": "3-25"
    },
    "North Texas": {
      "adjDef": 106.2,
      "adjDefRank": 217,
      "adjEM": -5.06,
      "adjOff": 101.1,
      "adjOffRank": 197,
      "adjTempo": 69,
      "adjTempoRank": 54,
      "conference": "SB",
      "rank": 214,
      "record": "13-15"
    },
    "Northeastern": {
      "adjDef": 107.3,
      "adjDefRank": 244,
      "adjEM": -4.26,
      "adjOff": 103,
      "adjOffRank": 158,
      "adjTempo": 72.9,
      "adjTempoRank": 7,
      "conference": "AE",
      "rank": 198,
      "record": "19-11"
    },
    "Northern Arizona": {
      "adjDef": 105.4,
      "adjDefRank": 197,
      "adjEM": -3.33,
      "adjOff": 102.1,
      "adjOffRank": 175,
      "adjTempo": 69.4,
      "adjTempoRank": 38,
      "conference": "BSky",
      "rank": 186,
      "record": "15-14"
    },
    "Northern Illinois": {
      "adjDef": 106.5,
      "adjDefRank": 231,
      "adjEM": -5.89,
      "adjOff": 100.6,
      "adjOffRank": 208,
      "adjTempo": 64.5,
      "adjTempoRank": 214,
      "conference": "MAC",
      "rank": 223,
      "record": "10-20"
    },
    "Northern Iowa": {
      "adjDef": 98.6,
      "adjDefRank": 77,
      "adjEM": 8.78,
      "adjOff": 107.4,
      "adjOffRank": 92,
      "adjTempo": 64.8,
      "adjTempoRank": 201,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 84,
      "record": "21-10",
      "seed": 14
    },
    "Northwestern": {
      "adjDef": 101.1,
      "adjDefRank": 123,
      "adjEM": 5.65,
      "adjOff": 106.7,
      "adjOffRank": 101,
      "adjTempo": 59.3,
      "adjTempoRank": 315,
      "conference": "B10",
      "rank": 100,
      "record": "14-15"
    },
    "Northwestern St.": {
      "adjDef": 105.3,
      "adjDefRank": 196,
      "adjEM": -9.61,
      "adjOff": 95.7,
      "adjOffRank": 281,
      "adjTempo": 72.5,
      "adjTempoRank": 9,
      "conference": "Slnd",
      "rank": 259,
      "record": "11-17"
    },
    "Notre Dame": {
      "adjDef": 100.4,
      "adjDefRank": 107,
      "adjEM": 13.03,
      "adjOff": 113.4,
      "adjOffRank": 29,
      "adjTempo": 64.6,
      "adjTempoRank": 210,
      "conference": "BE",
      "rank": 52,
      "record": "19-13"
    },
    "Oakland": {
      "adjDef": 105.3,
      "adjDefRank": 193,
      "adjEM": -0.98,
      "adjOff": 104.3,
      "adjOffRank": 141,
      "adjTempo": 69,
      "adjTempoRank": 55,
      "conference": "MCon",
      "rank": 165,
      "record": "13-17"
    },
    "Ohio": {
      "adjDef": 104.6,
      "adjDefRank": 181,
      "adjEM": -3.41,
      "adjOff": 101.2,
      "adjOffRank": 194,
      "adjTempo": 64.3,
      "adjTempoRank": 222,
      "conference": "MAC",
      "rank": 188,
      "record": "10-20"
    },
    "Ohio St.": {
      "adjDef": 107.2,
      "adjDefRank": 243,
      "adjEM": 0.71,
      "adjOff": 107.9,
      "adjOffRank": 83,
      "adjTempo": 62.8,
      "adjTempoRank": 277,
      "conference": "B10",
      "rank": 148,
      "record": "14-16"
    },
    "Oklahoma": {
      "adjDef": 92.9,
      "adjDefRank": 16,
      "adjEM": 11.61,
      "adjOff": 104.5,
      "adjOffRank": 137,
      "adjTempo": 63.6,
      "adjTempoRank": 247,
      "conference": "B12",
      "rank": 56,
      "record": "20-11"
    },
    "Oklahoma St.": {
      "adjDef": 91.1,
      "adjDefRank": 11,
      "adjEM": 27.37,
      "adjOff": 118.5,
      "adjOffRank": 6,
      "adjTempo": 64.8,
      "adjTempoRank": 203,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 4,
      "record": "31-4",
      "seed": 2
    },
    "Old Dominion": {
      "adjDef": 99,
      "adjDefRank": 84,
      "adjEM": 5.57,
      "adjOff": 104.6,
      "adjOffRank": 135,
      "adjTempo": 69.1,
      "adjTempoRank": 53,
      "conference": "CAA",
      "rank": 101,
      "record": "17-12"
    },
    "Oral Roberts": {
      "adjDef": 108.1,
      "adjDefRank": 255,
      "adjEM": 1.3,
      "adjOff": 109.4,
      "adjOffRank": 66,
      "adjTempo": 68.1,
      "adjTempoRank": 83,
      "conference": "MCon",
      "rank": 141,
      "record": "17-11"
    },
    "Oregon": {
      "adjDef": 101.7,
      "adjDefRank": 135,
      "adjEM": 9.21,
      "adjOff": 110.9,
      "adjOffRank": 51,
      "adjTempo": 67.6,
      "adjTempoRank": 97,
      "conference": "P10",
      "rank": 79,
      "record": "18-13"
    },
    "Oregon St.": {
      "adjDef": 102.1,
      "adjDefRank": 144,
      "adjEM": 0.66,
      "adjOff": 102.8,
      "adjOffRank": 162,
      "adjTempo": 67.6,
      "adjTempoRank": 99,
      "conference": "P10",
      "rank": 149,
      "record": "12-16"
    },
    "Pacific": {
      "adjDef": 99.7,
      "adjDefRank": 99,
      "adjEM": 8.93,
      "adjOff": 108.6,
      "adjOffRank": 76,
      "adjTempo": 63.6,
      "adjTempoRank": 249,
      "conference": "BW",
      "finish": "Round of 32",
      "rank": 82,
      "record": "25-8",
      "seed": 12
    },
    "Penn": {
      "adjDef": 99.2,
      "adjDefRank": 88,
      "adjEM": 9.8,
      "adjOff": 109,
      "adjOffRank": 75,
      "adjTempo": 63.6,
      "adjTempoRank": 248,
      "conference": "Ivy",
      "rank": 73,
      "record": "17-10"
    },
    "Penn St.": {
      "adjDef": 103.5,
      "adjDefRank": 171,
      "adjEM": -6.35,
      "adjOff": 97.2,
      "adjOffRank": 266,
      "adjTempo": 62.8,
      "adjTempoRank": 279,
      "conference": "B10",
      "rank": 227,
      "record": "9-19"
    },
    "Pepperdine": {
      "adjDef": 110.8,
      "adjDefRank": 298,
      "adjEM": 2.93,
      "adjOff": 113.7,
      "adjOffRank": 22,
      "adjTempo": 69.3,
      "adjTempoRank": 41,
      "conference": "WCC",
      "rank": 129,
      "record": "15-16"
    },
    "Pittsburgh": {
      "adjDef": 88.2,
      "adjDefRank": 2,
      "adjEM": 25.91,
      "adjOff": 114.1,
      "adjOffRank": 20,
      "adjTempo": 59.7,
      "adjTempoRank": 314,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 5,
      "record": "31-5",
      "seed": 3
    },
    "Portland": {
      "adjDef": 105.6,
      "adjDefRank": 200,
      "adjEM": -0.63,
      "adjOff": 105,
      "adjOffRank": 125,
      "adjTempo": 65.9,
      "adjTempoRank": 163,
      "conference": "WCC",
      "rank": 162,
      "record": "11-17"
    },
    "Portland St.": {
      "adjDef": 101.5,
      "adjDefRank": 128,
      "adjEM": -4.9,
      "adjOff": 96.6,
      "adjOffRank": 274,
      "adjTempo": 65.3,
      "adjTempoRank": 185,
      "conference": "BSky",
      "rank": 211,
      "record": "11-16"
    },
    "Prairie View A&M": {
      "adjDef": 113.8,
      "adjDefRank": 321,
      "adjEM": -24.63,
      "adjOff": 89.2,
      "adjOffRank": 313,
      "adjTempo": 66.4,
      "adjTempoRank": 137,
      "conference": "SWAC",
      "rank": 321,
      "record": "7-20"
    },
    "Princeton": {
      "adjDef": 101.4,
      "adjDefRank": 127,
      "adjEM": 4.86,
      "adjOff": 106.3,
      "adjOffRank": 106,
      "adjTempo": 56.4,
      "adjTempoRank": 325,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 108,
      "record": "20-8",
      "seed": 14
    },
    "Providence": {
      "adjDef": 93.9,
      "adjDefRank": 21,
      "adjEM": 18.32,
      "adjOff": 112.3,
      "adjOffRank": 40,
      "adjTempo": 65,
      "adjTempoRank": 196,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 24,
      "record": "20-9",
      "seed": 5
    },
    "Purdue": {
      "adjDef": 93,
      "adjDefRank": 18,
      "adjEM": 11.6,
      "adjOff": 104.6,
      "adjOffRank": 133,
      "adjTempo": 62.7,
      "adjTempoRank": 280,
      "conference": "B10",
      "rank": 57,
      "record": "17-14"
    },
    "Quinnipiac": {
      "adjDef": 113.8,
      "adjDefRank": 320,
      "adjEM": -15.58,
      "adjOff": 98.2,
      "adjOffRank": 250,
      "adjTempo": 69.8,
      "adjTempoRank": 32,
      "conference": "NEC",
      "rank": 300,
      "record": "9-20"
    },
    "Radford": {
      "adjDef": 109,
      "adjDefRank": 275,
      "adjEM": -13.01,
      "adjOff": 96,
      "adjOffRank": 279,
      "adjTempo": 65.9,
      "adjTempoRank": 164,
      "conference": "BSth",
      "rank": 288,
      "record": "12-16"
    },
    "Rhode Island": {
      "adjDef": 96.7,
      "adjDefRank": 51,
      "adjEM": 8.48,
      "adjOff": 105.2,
      "adjOffRank": 122,
      "adjTempo": 64.4,
      "adjTempoRank": 217,
      "conference": "A10",
      "rank": 86,
      "record": "20-14"
    },
    "Rice": {
      "adjDef": 99.2,
      "adjDefRank": 89,
      "adjEM": 9.83,
      "adjOff": 109,
      "adjOffRank": 73,
      "adjTempo": 68.1,
      "adjTempoRank": 79,
      "conference": "WAC",
      "rank": 71,
      "record": "22-11"
    },
    "Richmond": {
      "adjDef": 90.9,
      "adjDefRank": 9,
      "adjEM": 15.07,
      "adjOff": 105.9,
      "adjOffRank": 109,
      "adjTempo": 62.7,
      "adjTempoRank": 281,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 38,
      "record": "20-13",
      "seed": 11
    },
    "Rider": {
      "adjDef": 106.1,
      "adjDefRank": 214,
      "adjEM": -6.17,
      "adjOff": 100,
      "adjOffRank": 217,
      "adjTempo": 63.8,
      "adjTempoRank": 240,
      "conference": "MAAC",
      "rank": 224,
      "record": "17-14"
    },
    "Robert Morris": {
      "adjDef": 112.9,
      "adjDefRank": 317,
      "adjEM": -11.5,
      "adjOff": 101.4,
      "adjOffRank": 190,
      "adjTempo": 62.8,
      "adjTempoRank": 278,
      "conference": "NEC",
      "rank": 270,
      "record": "14-15"
    },
    "Rutgers": {
      "adjDef": 96.2,
      "adjDefRank": 46,
      "adjEM": 9.83,
      "adjOff": 106,
      "adjOffRank": 108,
      "adjTempo": 66,
      "adjTempoRank": 159,
      "conference": "BE",
      "rank": 72,
      "record": "20-13"
    },
    "SMU": {
      "adjDef": 103.5,
      "adjDefRank": 170,
      "adjEM": 1.72,
      "adjOff": 105.2,
      "adjOffRank": 121,
      "adjTempo": 62.4,
      "adjTempoRank": 286,
      "conference": "WAC",
      "rank": 136,
      "record": "12-18"
    },
    "Sacramento St.": {
      "adjDef": 103.2,
      "adjDefRank": 164,
      "adjEM": -4.57,
      "adjOff": 98.6,
      "adjOffRank": 245,
      "adjTempo": 69.2,
      "adjTempoRank": 44,
      "conference": "BSky",
      "rank": 203,
      "record": "13-15"
    },
    "Sacred Heart": {
      "adjDef": 110.4,
      "adjDefRank": 293,
      "adjEM": -12.44,
      "adjOff": 97.9,
      "adjOffRank": 255,
      "adjTempo": 71.9,
      "adjTempoRank": 10,
      "conference": "NEC",
      "rank": 282,
      "record": "12-15"
    },
    "Saint Joseph's": {
      "adjDef": 91.2,
      "adjDefRank": 12,
      "adjEM": 27.66,
      "adjOff": 118.9,
      "adjOffRank": 5,
      "adjTempo": 66.1,
      "adjTempoRank": 148,
      "conference": "A10",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "30-2",
      "seed": 1
    },
    "Saint Louis": {
      "adjDef": 95.3,
      "adjDefRank": 35,
      "adjEM": 8.21,
      "adjOff": 103.5,
      "adjOffRank": 150,
      "adjTempo": 61.3,
      "adjTempoRank": 305,
      "conference": "CUSA",
      "rank": 87,
      "record": "19-13"
    },
    "Saint Mary's": {
      "adjDef": 99.6,
      "adjDefRank": 95,
      "adjEM": 10.92,
      "adjOff": 110.5,
      "adjOffRank": 57,
      "adjTempo": 66.3,
      "adjTempoRank": 141,
      "conference": "WCC",
      "rank": 59,
      "record": "19-12"
    },
    "Saint Peter's": {
      "adjDef": 108.6,
      "adjDefRank": 268,
      "adjEM": -0.51,
      "adjOff": 108.1,
      "adjOffRank": 81,
      "adjTempo": 67.8,
      "adjTempoRank": 90,
      "conference": "MAAC",
      "rank": 161,
      "record": "17-12"
    },
    "Sam Houston St.": {
      "adjDef": 108.9,
      "adjDefRank": 273,
      "adjEM": -11.53,
      "adjOff": 97.4,
      "adjOffRank": 262,
      "adjTempo": 70.6,
      "adjTempoRank": 22,
      "conference": "Slnd",
      "rank": 271,
      "record": "13-15"
    },
    "Samford": {
      "adjDef": 108.3,
      "adjDefRank": 259,
      "adjEM": -9.45,
      "adjOff": 98.9,
      "adjOffRank": 241,
      "adjTempo": 58.8,
      "adjTempoRank": 318,
      "conference": "OVC",
      "rank": 257,
      "record": "12-16"
    },
    "San Diego": {
      "adjDef": 112.4,
      "adjDefRank": 313,
      "adjEM": -12.41,
      "adjOff": 100,
      "adjOffRank": 218,
      "adjTempo": 69.9,
      "adjTempoRank": 27,
      "conference": "WCC",
      "rank": 281,
      "record": "4-26"
    },
    "San Diego St.": {
      "adjDef": 100.6,
      "adjDefRank": 112,
      "adjEM": 4.49,
      "adjOff": 105,
      "adjOffRank": 124,
      "adjTempo": 66.9,
      "adjTempoRank": 120,
      "conference": "MWC",
      "rank": 111,
      "record": "14-16"
    },
    "San Francisco": {
      "adjDef": 101.7,
      "adjDefRank": 134,
      "adjEM": -1.61,
      "adjOff": 100.1,
      "adjOffRank": 216,
      "adjTempo": 65.1,
      "adjTempoRank": 192,
      "conference": "WCC",
      "rank": 174,
      "record": "17-14"
    },
    "San Jose St.": {
      "adjDef": 106.3,
      "adjDefRank": 224,
      "adjEM": -7.14,
      "adjOff": 99.2,
      "adjOffRank": 233,
      "adjTempo": 60.9,
      "adjTempoRank": 309,
      "conference": "WAC",
      "rank": 243,
      "record": "6-23"
    },
    "Santa Clara": {
      "adjDef": 102,
      "adjDefRank": 140,
      "adjEM": -0.13,
      "adjOff": 101.8,
      "adjOffRank": 182,
      "adjTempo": 61.4,
      "adjTempoRank": 302,
      "conference": "WCC",
      "rank": 157,
      "record": "16-16"
    },
    "Savannah St.": {
      "adjDef": 109,
      "adjDefRank": 274,
      "adjEM": -23.73,
      "adjOff": 85.3,
      "adjOffRank": 320,
      "adjTempo": 70.9,
      "adjTempoRank": 17,
      "conference": "ind",
      "rank": 316,
      "record": "4-24"
    },
    "Seton Hall": {
      "adjDef": 95,
      "adjDefRank": 31,
      "adjEM": 17.35,
      "adjOff": 112.3,
      "adjOffRank": 37,
      "adjTempo": 65.4,
      "adjTempoRank": 180,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 27,
      "record": "21-10",
      "seed": 8
    },
    "Siena": {
      "adjDef": 105.7,
      "adjDefRank": 202,
      "adjEM": -5.03,
      "adjOff": 100.7,
      "adjOffRank": 206,
      "adjTempo": 67.1,
      "adjTempoRank": 116,
      "conference": "MAAC",
      "rank": 212,
      "record": "14-16"
    },
    "South Alabama": {
      "adjDef": 106.4,
      "adjDefRank": 230,
      "adjEM": -3.39,
      "adjOff": 103.1,
      "adjOffRank": 154,
      "adjTempo": 63,
      "adjTempoRank": 273,
      "conference": "SB",
      "rank": 187,
      "record": "12-16"
    },
    "South Carolina": {
      "adjDef": 94.7,
      "adjDefRank": 28,
      "adjEM": 15.02,
      "adjOff": 109.7,
      "adjOffRank": 64,
      "adjTempo": 65.5,
      "adjTempoRank": 176,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 39,
      "record": "23-11",
      "seed": 10
    },
    "South Carolina St.": {
      "adjDef": 106.3,
      "adjDefRank": 221,
      "adjEM": -5.32,
      "adjOff": 101,
      "adjOffRank": 201,
      "adjTempo": 65.5,
      "adjTempoRank": 177,
      "conference": "MEAC",
      "rank": 219,
      "record": "18-11"
    },
    "South Florida": {
      "adjDef": 104.9,
      "adjDefRank": 187,
      "adjEM": -5.21,
      "adjOff": 99.7,
      "adjOffRank": 226,
      "adjTempo": 63.5,
      "adjTempoRank": 250,
      "conference": "CUSA",
      "rank": 217,
      "record": "7-20"
    },
    "Southeast Missouri St.": {
      "adjDef": 113.1,
      "adjDefRank": 319,
      "adjEM": -5.66,
      "adjOff": 107.4,
      "adjOffRank": 90,
      "adjTempo": 63.5,
      "adjTempoRank": 251,
      "conference": "OVC",
      "rank": 221,
      "record": "11-16"
    },
    "Southeastern Louisiana": {
      "adjDef": 105.3,
      "adjDefRank": 194,
      "adjEM": -3.96,
      "adjOff": 101.4,
      "adjOffRank": 192,
      "adjTempo": 66.2,
      "adjTempoRank": 147,
      "conference": "Slnd",
      "rank": 192,
      "record": "20-9"
    },
    "Southern": {
      "adjDef": 110,
      "adjDefRank": 288,
      "adjEM": -13.49,
      "adjOff": 96.6,
      "adjOffRank": 275,
      "adjTempo": 68,
      "adjTempoRank": 84,
      "conference": "SWAC",
      "rank": 290,
      "record": "12-16"
    },
    "Southern Illinois": {
      "adjDef": 94.8,
      "adjDefRank": 29,
      "adjEM": 14.33,
      "adjOff": 109.1,
      "adjOffRank": 70,
      "adjTempo": 65.2,
      "adjTempoRank": 188,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 42,
      "record": "25-5",
      "seed": 9
    },
    "Southern Miss": {
      "adjDef": 102.2,
      "adjDefRank": 146,
      "adjEM": -1.72,
      "adjOff": 100.5,
      "adjOffRank": 210,
      "adjTempo": 64.1,
      "adjTempoRank": 226,
      "conference": "CUSA",
      "rank": 176,
      "record": "13-15"
    },
    "Southern Utah": {
      "adjDef": 110.5,
      "adjDefRank": 294,
      "adjEM": -9.85,
      "adjOff": 100.7,
      "adjOffRank": 207,
      "adjTempo": 62.5,
      "adjTempoRank": 285,
      "conference": "MCon",
      "rank": 261,
      "record": "10-18"
    },
    "Southwest Missouri St.": {
      "adjDef": 99.1,
      "adjDefRank": 86,
      "adjEM": 6.48,
      "adjOff": 105.6,
      "adjOffRank": 116,
      "adjTempo": 63.2,
      "adjTempoRank": 265,
      "conference": "MVC",
      "rank": 95,
      "record": "19-14"
    },
    "St. Bonaventure": {
      "adjDef": 109.7,
      "adjDefRank": 283,
      "adjEM": -8.95,
      "adjOff": 100.8,
      "adjOffRank": 205,
      "adjTempo": 69.1,
      "adjTempoRank": 50,
      "conference": "A10",
      "rank": 254,
      "record": "7-21"
    },
    "St. Francis NY": {
      "adjDef": 105.2,
      "adjDefRank": 192,
      "adjEM": -7.22,
      "adjOff": 98,
      "adjOffRank": 254,
      "adjTempo": 68.6,
      "adjTempoRank": 62,
      "conference": "NEC",
      "rank": 244,
      "record": "15-13"
    },
    "St. Francis PA": {
      "adjDef": 111.4,
      "adjDefRank": 302,
      "adjEM": -11.1,
      "adjOff": 100.3,
      "adjOffRank": 211,
      "adjTempo": 63.6,
      "adjTempoRank": 245,
      "conference": "NEC",
      "rank": 269,
      "record": "13-15"
    },
    "St. John's": {
      "adjDef": 103.2,
      "adjDefRank": 163,
      "adjEM": -4.31,
      "adjOff": 98.9,
      "adjOffRank": 240,
      "adjTempo": 65,
      "adjTempoRank": 195,
      "conference": "BE",
      "rank": 200,
      "record": "6-21"
    },
    "Stanford": {
      "adjDef": 89.3,
      "adjDefRank": 5,
      "adjEM": 22.52,
      "adjOff": 111.9,
      "adjOffRank": 42,
      "adjTempo": 64.5,
      "adjTempoRank": 212,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 12,
      "record": "30-2",
      "seed": 1
    },
    "Stephen F. Austin": {
      "adjDef": 98.2,
      "adjDefRank": 66,
      "adjEM": 1.45,
      "adjOff": 99.6,
      "adjOffRank": 227,
      "adjTempo": 63.4,
      "adjTempoRank": 259,
      "conference": "Slnd",
      "rank": 139,
      "record": "21-9"
    },
    "Stetson": {
      "adjDef": 107.4,
      "adjDefRank": 245,
      "adjEM": -11.74,
      "adjOff": 95.6,
      "adjOffRank": 283,
      "adjTempo": 65.1,
      "adjTempoRank": 190,
      "conference": "ASun",
      "rank": 274,
      "record": "12-15"
    },
    "Stony Brook": {
      "adjDef": 110.1,
      "adjDefRank": 289,
      "adjEM": -14.69,
      "adjOff": 95.4,
      "adjOffRank": 287,
      "adjTempo": 64,
      "adjTempoRank": 236,
      "conference": "AE",
      "rank": 296,
      "record": "10-20"
    },
    "Syracuse": {
      "adjDef": 96.7,
      "adjDefRank": 50,
      "adjEM": 16.34,
      "adjOff": 113.1,
      "adjOffRank": 31,
      "adjTempo": 65.7,
      "adjTempoRank": 169,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 30,
      "record": "23-8",
      "seed": 5
    },
    "TCU": {
      "adjDef": 102.8,
      "adjDefRank": 158,
      "adjEM": 0.08,
      "adjOff": 102.9,
      "adjOffRank": 161,
      "adjTempo": 66.1,
      "adjTempoRank": 149,
      "conference": "CUSA",
      "rank": 154,
      "record": "12-17"
    },
    "Temple": {
      "adjDef": 102.5,
      "adjDefRank": 155,
      "adjEM": 7.23,
      "adjOff": 109.8,
      "adjOffRank": 63,
      "adjTempo": 60.1,
      "adjTempoRank": 311,
      "conference": "A10",
      "rank": 90,
      "record": "15-14"
    },
    "Tennessee": {
      "adjDef": 101.2,
      "adjDefRank": 124,
      "adjEM": 7.3,
      "adjOff": 108.5,
      "adjOffRank": 77,
      "adjTempo": 66.1,
      "adjTempoRank": 152,
      "conference": "SEC",
      "rank": 89,
      "record": "15-14"
    },
    "Tennessee Martin": {
      "adjDef": 108.5,
      "adjDefRank": 265,
      "adjEM": -8.87,
      "adjOff": 99.6,
      "adjOffRank": 228,
      "adjTempo": 68.9,
      "adjTempoRank": 57,
      "conference": "OVC",
      "rank": 253,
      "record": "10-18"
    },
    "Tennessee St.": {
      "adjDef": 113.9,
      "adjDefRank": 322,
      "adjEM": -10.29,
      "adjOff": 103.6,
      "adjOffRank": 149,
      "adjTempo": 66.3,
      "adjTempoRank": 140,
      "conference": "OVC",
      "rank": 265,
      "record": "7-21"
    },
    "Tennessee Tech": {
      "adjDef": 111,
      "adjDefRank": 299,
      "adjEM": -6.76,
      "adjOff": 104.2,
      "adjOffRank": 143,
      "adjTempo": 67.7,
      "adjTempoRank": 93,
      "conference": "OVC",
      "rank": 236,
      "record": "13-15"
    },
    "Texas": {
      "adjDef": 94.1,
      "adjDefRank": 22,
      "adjEM": 21.54,
      "adjOff": 115.6,
      "adjOffRank": 16,
      "adjTempo": 64.7,
      "adjTempoRank": 206,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 17,
      "record": "25-8",
      "seed": 3
    },
    "Texas A&M": {
      "adjDef": 109.2,
      "adjDefRank": 278,
      "adjEM": -3.58,
      "adjOff": 105.6,
      "adjOffRank": 114,
      "adjTempo": 66.8,
      "adjTempoRank": 121,
      "conference": "B12",
      "rank": 189,
      "record": "7-21"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 107.5,
      "adjDefRank": 248,
      "adjEM": -5.13,
      "adjOff": 102.4,
      "adjOffRank": 170,
      "adjTempo": 67.6,
      "adjTempoRank": 98,
      "conference": "ind",
      "rank": 215,
      "record": "15-11"
    },
    "Texas Pan American": {
      "adjDef": 103.5,
      "adjDefRank": 168,
      "adjEM": -8.19,
      "adjOff": 95.3,
      "adjOffRank": 289,
      "adjTempo": 68,
      "adjTempoRank": 86,
      "conference": "ind",
      "rank": 250,
      "record": "14-14"
    },
    "Texas Southern": {
      "adjDef": 103.3,
      "adjDefRank": 165,
      "adjEM": -15.39,
      "adjOff": 87.9,
      "adjOffRank": 315,
      "adjTempo": 69.2,
      "adjTempoRank": 46,
      "conference": "SWAC",
      "rank": 298,
      "record": "14-15"
    },
    "Texas St.": {
      "adjDef": 111.2,
      "adjDefRank": 300,
      "adjEM": -9.46,
      "adjOff": 101.7,
      "adjOffRank": 184,
      "adjTempo": 63,
      "adjTempoRank": 271,
      "conference": "Slnd",
      "rank": 258,
      "record": "13-15"
    },
    "Texas Tech": {
      "adjDef": 94.3,
      "adjDefRank": 24,
      "adjEM": 17.11,
      "adjOff": 111.4,
      "adjOffRank": 46,
      "adjTempo": 66.7,
      "adjTempoRank": 126,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 28,
      "record": "23-11",
      "seed": 8
    },
    "The Citadel": {
      "adjDef": 110.8,
      "adjDefRank": 297,
      "adjEM": -24.04,
      "adjOff": 86.7,
      "adjOffRank": 319,
      "adjTempo": 63.2,
      "adjTempoRank": 264,
      "conference": "SC",
      "rank": 318,
      "record": "6-22"
    },
    "Toledo": {
      "adjDef": 104.7,
      "adjDefRank": 183,
      "adjEM": 4.36,
      "adjOff": 109,
      "adjOffRank": 72,
      "adjTempo": 69.6,
      "adjTempoRank": 35,
      "conference": "MAC",
      "rank": 112,
      "record": "20-11"
    },
    "Towson": {
      "adjDef": 106.9,
      "adjDefRank": 236,
      "adjEM": -12.15,
      "adjOff": 94.7,
      "adjOffRank": 292,
      "adjTempo": 64.3,
      "adjTempoRank": 220,
      "conference": "CAA",
      "rank": 278,
      "record": "8-21"
    },
    "Troy St.": {
      "adjDef": 105.7,
      "adjDefRank": 203,
      "adjEM": 7.82,
      "adjOff": 113.5,
      "adjOffRank": 25,
      "adjTempo": 68,
      "adjTempoRank": 85,
      "conference": "ASun",
      "rank": 88,
      "record": "24-7"
    },
    "Tulane": {
      "adjDef": 104,
      "adjDefRank": 176,
      "adjEM": -6.21,
      "adjOff": 97.8,
      "adjOffRank": 258,
      "adjTempo": 66.8,
      "adjTempoRank": 124,
      "conference": "CUSA",
      "rank": 225,
      "record": "11-17"
    },
    "Tulsa": {
      "adjDef": 102,
      "adjDefRank": 142,
      "adjEM": 0.16,
      "adjOff": 102.2,
      "adjOffRank": 174,
      "adjTempo": 67,
      "adjTempoRank": 119,
      "conference": "WAC",
      "rank": 153,
      "record": "9-20"
    },
    "UAB": {
      "adjDef": 94.5,
      "adjDefRank": 25,
      "adjEM": 13.63,
      "adjOff": 108.1,
      "adjOffRank": 79,
      "adjTempo": 70.7,
      "adjTempoRank": 20,
      "conference": "CUSA",
      "finish": "Sweet 16",
      "rank": 46,
      "record": "22-10",
      "seed": 9
    },
    "UC Irvine": {
      "adjDef": 106.2,
      "adjDefRank": 218,
      "adjEM": -4.79,
      "adjOff": 101.4,
      "adjOffRank": 193,
      "adjTempo": 61.5,
      "adjTempoRank": 300,
      "conference": "BW",
      "rank": 209,
      "record": "11-17"
    },
    "UC Riverside": {
      "adjDef": 106,
      "adjDefRank": 209,
      "adjEM": -4.12,
      "adjOff": 101.9,
      "adjOffRank": 181,
      "adjTempo": 64.8,
      "adjTempoRank": 204,
      "conference": "BW",
      "rank": 196,
      "record": "11-17"
    },
    "UC Santa Barbara": {
      "adjDef": 98.5,
      "adjDefRank": 74,
      "adjEM": 3.45,
      "adjOff": 101.9,
      "adjOffRank": 178,
      "adjTempo": 63.1,
      "adjTempoRank": 270,
      "conference": "BW",
      "rank": 121,
      "record": "16-12"
    },
    "UCF": {
      "adjDef": 97.4,
      "adjDefRank": 60,
      "adjEM": 5.91,
      "adjOff": 103.3,
      "adjOffRank": 153,
      "adjTempo": 62.6,
      "adjTempoRank": 283,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 98,
      "record": "25-6",
      "seed": 14
    },
    "UCLA": {
      "adjDef": 101.4,
      "adjDefRank": 126,
      "adjEM": 1.24,
      "adjOff": 102.7,
      "adjOffRank": 165,
      "adjTempo": 63.2,
      "adjTempoRank": 262,
      "conference": "P10",
      "rank": 144,
      "record": "11-17"
    },
    "UMBC": {
      "adjDef": 107,
      "adjDefRank": 237,
      "adjEM": -12.88,
      "adjOff": 94.1,
      "adjOffRank": 293,
      "adjTempo": 63,
      "adjTempoRank": 272,
      "conference": "AE",
      "rank": 285,
      "record": "7-21"
    },
    "UMKC": {
      "adjDef": 105,
      "adjDefRank": 190,
      "adjEM": 0.72,
      "adjOff": 105.7,
      "adjOffRank": 112,
      "adjTempo": 65.8,
      "adjTempoRank": 168,
      "conference": "MCon",
      "rank": 147,
      "record": "15-14"
    },
    "UNC Asheville": {
      "adjDef": 110.6,
      "adjDefRank": 295,
      "adjEM": -17.15,
      "adjOff": 93.4,
      "adjOffRank": 299,
      "adjTempo": 65.1,
      "adjTempoRank": 191,
      "conference": "BSth",
      "rank": 305,
      "record": "9-20"
    },
    "UNC Greensboro": {
      "adjDef": 108.7,
      "adjDefRank": 270,
      "adjEM": -6.52,
      "adjOff": 102.2,
      "adjOffRank": 173,
      "adjTempo": 67,
      "adjTempoRank": 118,
      "conference": "SC",
      "rank": 229,
      "record": "11-17"
    },
    "UNC Wilmington": {
      "adjDef": 95,
      "adjDefRank": 32,
      "adjEM": 3.66,
      "adjOff": 98.7,
      "adjOffRank": 243,
      "adjTempo": 59.8,
      "adjTempoRank": 313,
      "conference": "CAA",
      "rank": 120,
      "record": "15-15"
    },
    "UNLV": {
      "adjDef": 100.9,
      "adjDefRank": 120,
      "adjEM": 9.35,
      "adjOff": 110.3,
      "adjOffRank": 58,
      "adjTempo": 69.1,
      "adjTempoRank": 51,
      "conference": "MWC",
      "rank": 77,
      "record": "18-13"
    },
    "USC": {
      "adjDef": 102.3,
      "adjDefRank": 148,
      "adjEM": 2.32,
      "adjOff": 104.6,
      "adjOffRank": 134,
      "adjTempo": 70.3,
      "adjTempoRank": 26,
      "conference": "P10",
      "rank": 132,
      "record": "13-15"
    },
    "UT Arlington": {
      "adjDef": 108.5,
      "adjDefRank": 266,
      "adjEM": -4.64,
      "adjOff": 103.9,
      "adjOffRank": 145,
      "adjTempo": 65.4,
      "adjTempoRank": 181,
      "conference": "Slnd",
      "rank": 205,
      "record": "17-12"
    },
    "UTEP": {
      "adjDef": 97.8,
      "adjDefRank": 61,
      "adjEM": 14.69,
      "adjOff": 112.5,
      "adjOffRank": 36,
      "adjTempo": 68.2,
      "adjTempoRank": 76,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 41,
      "record": "24-8",
      "seed": 13
    },
    "UTSA": {
      "adjDef": 105.7,
      "adjDefRank": 201,
      "adjEM": -7.69,
      "adjOff": 98,
      "adjOffRank": 253,
      "adjTempo": 68.7,
      "adjTempoRank": 61,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 248,
      "record": "19-14",
      "seed": 16
    },
    "Utah": {
      "adjDef": 96,
      "adjDefRank": 42,
      "adjEM": 14.13,
      "adjOff": 110.1,
      "adjOffRank": 60,
      "adjTempo": 58.8,
      "adjTempoRank": 317,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 45,
      "record": "24-9",
      "seed": 11
    },
    "Utah St.": {
      "adjDef": 102.3,
      "adjDefRank": 149,
      "adjEM": 13.6,
      "adjOff": 115.9,
      "adjOffRank": 15,
      "adjTempo": 58.2,
      "adjTempoRank": 322,
      "conference": "BW",
      "rank": 47,
      "record": "25-4"
    },
    "VCU": {
      "adjDef": 96.5,
      "adjDefRank": 48,
      "adjEM": 9.09,
      "adjOff": 105.6,
      "adjOffRank": 115,
      "adjTempo": 64.4,
      "adjTempoRank": 216,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 81,
      "record": "23-8",
      "seed": 13
    },
    "VMI": {
      "adjDef": 108,
      "adjDefRank": 254,
      "adjEM": -24.63,
      "adjOff": 83.3,
      "adjOffRank": 322,
      "adjTempo": 64.1,
      "adjTempoRank": 227,
      "conference": "BSth",
      "rank": 320,
      "record": "6-22"
    },
    "Valparaiso": {
      "adjDef": 99.6,
      "adjDefRank": 96,
      "adjEM": 0.53,
      "adjOff": 100.1,
      "adjOffRank": 212,
      "adjTempo": 69.2,
      "adjTempoRank": 47,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 152,
      "record": "18-13",
      "seed": 15
    },
    "Vanderbilt": {
      "adjDef": 95,
      "adjDefRank": 30,
      "adjEM": 18.64,
      "adjOff": 113.6,
      "adjOffRank": 23,
      "adjTempo": 65,
      "adjTempoRank": 193,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 23,
      "record": "23-10",
      "seed": 6
    },
    "Vermont": {
      "adjDef": 102.4,
      "adjDefRank": 154,
      "adjEM": -1.37,
      "adjOff": 101,
      "adjOffRank": 199,
      "adjTempo": 63.9,
      "adjTempoRank": 239,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 172,
      "record": "22-9",
      "seed": 15
    },
    "Villanova": {
      "adjDef": 98.3,
      "adjDefRank": 70,
      "adjEM": 12.43,
      "adjOff": 110.8,
      "adjOffRank": 53,
      "adjTempo": 65.3,
      "adjTempoRank": 187,
      "conference": "BE",
      "rank": 53,
      "record": "18-17"
    },
    "Virginia": {
      "adjDef": 99.1,
      "adjDefRank": 87,
      "adjEM": 10.27,
      "adjOff": 109.3,
      "adjOffRank": 67,
      "adjTempo": 68.7,
      "adjTempoRank": 60,
      "conference": "ACC",
      "rank": 66,
      "record": "18-13"
    },
    "Virginia Tech": {
      "adjDef": 98.8,
      "adjDefRank": 81,
      "adjEM": 3.16,
      "adjOff": 101.9,
      "adjOffRank": 179,
      "adjTempo": 66.3,
      "adjTempoRank": 142,
      "conference": "BE",
      "rank": 123,
      "record": "15-14"
    },
    "Wagner": {
      "adjDef": 107.6,
      "adjDefRank": 250,
      "adjEM": -11.92,
      "adjOff": 95.7,
      "adjOffRank": 282,
      "adjTempo": 65.9,
      "adjTempoRank": 165,
      "conference": "NEC",
      "rank": 276,
      "record": "13-16"
    },
    "Wake Forest": {
      "adjDef": 99.9,
      "adjDefRank": 102,
      "adjEM": 21.69,
      "adjOff": 121.6,
      "adjOffRank": 1,
      "adjTempo": 70.4,
      "adjTempoRank": 23,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 15,
      "record": "21-10",
      "seed": 4
    },
    "Washington": {
      "adjDef": 102.3,
      "adjDefRank": 152,
      "adjEM": 10.78,
      "adjOff": 113.1,
      "adjOffRank": 30,
      "adjTempo": 73.5,
      "adjTempoRank": 1,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 60,
      "record": "19-12",
      "seed": 8
    },
    "Washington St.": {
      "adjDef": 97.9,
      "adjDefRank": 63,
      "adjEM": 3.04,
      "adjOff": 100.9,
      "adjOffRank": 202,
      "adjTempo": 56.5,
      "adjTempoRank": 323,
      "conference": "P10",
      "rank": 126,
      "record": "13-16"
    },
    "Weber St.": {
      "adjDef": 107,
      "adjDefRank": 238,
      "adjEM": -1.17,
      "adjOff": 105.9,
      "adjOffRank": 110,
      "adjTempo": 61.7,
      "adjTempoRank": 297,
      "conference": "BSky",
      "rank": 170,
      "record": "15-14"
    },
    "West Virginia": {
      "adjDef": 100.2,
      "adjDefRank": 104,
      "adjEM": 6.81,
      "adjOff": 107,
      "adjOffRank": 95,
      "adjTempo": 62.3,
      "adjTempoRank": 288,
      "conference": "BE",
      "rank": 93,
      "record": "17-14"
    },
    "Western Carolina": {
      "adjDef": 104.9,
      "adjDefRank": 188,
      "adjEM": -6.88,
      "adjOff": 98,
      "adjOffRank": 252,
      "adjTempo": 67.9,
      "adjTempoRank": 89,
      "conference": "SC",
      "rank": 237,
      "record": "13-15"
    },
    "Western Illinois": {
      "adjDef": 112.9,
      "adjDefRank": 316,
      "adjEM": -17.12,
      "adjOff": 95.8,
      "adjOffRank": 280,
      "adjTempo": 67.3,
      "adjTempoRank": 109,
      "conference": "MCon",
      "rank": 304,
      "record": "3-25"
    },
    "Western Kentucky": {
      "adjDef": 107.4,
      "adjDefRank": 246,
      "adjEM": 3.8,
      "adjOff": 111.2,
      "adjOffRank": 49,
      "adjTempo": 66.2,
      "adjTempoRank": 146,
      "conference": "SB",
      "rank": 118,
      "record": "15-13"
    },
    "Western Michigan": {
      "adjDef": 98,
      "adjDefRank": 64,
      "adjEM": 16.29,
      "adjOff": 114.3,
      "adjOffRank": 19,
      "adjTempo": 65.6,
      "adjTempoRank": 173,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 31,
      "record": "26-5",
      "seed": 11
    },
    "Wichita St.": {
      "adjDef": 98.3,
      "adjDefRank": 69,
      "adjEM": 11.64,
      "adjOff": 109.9,
      "adjOffRank": 61,
      "adjTempo": 65.9,
      "adjTempoRank": 161,
      "conference": "MVC",
      "rank": 55,
      "record": "21-11"
    },
    "William & Mary": {
      "adjDef": 112,
      "adjDefRank": 310,
      "adjEM": -6.66,
      "adjOff": 105.4,
      "adjOffRank": 119,
      "adjTempo": 67.7,
      "adjTempoRank": 92,
      "conference": "CAA",
      "rank": 233,
      "record": "7-21"
    },
    "Winthrop": {
      "adjDef": 106.2,
      "adjDefRank": 219,
      "adjEM": -6.36,
      "adjOff": 99.8,
      "adjOffRank": 221,
      "adjTempo": 62.8,
      "adjTempoRank": 275,
      "conference": "BSth",
      "rank": 228,
      "record": "16-12"
    },
    "Wisconsin": {
      "adjDef": 90.3,
      "adjDefRank": 8,
      "adjEM": 25.74,
      "adjOff": 116,
      "adjOffRank": 14,
      "adjTempo": 60.7,
      "adjTempoRank": 310,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 6,
      "record": "25-7",
      "seed": 6
    },
    "Wofford": {
      "adjDef": 111.7,
      "adjDefRank": 307,
      "adjEM": -12.98,
      "adjOff": 98.7,
      "adjOffRank": 242,
      "adjTempo": 70.7,
      "adjTempoRank": 19,
      "conference": "SC",
      "rank": 287,
      "record": "9-20"
    },
    "Wright St.": {
      "adjDef": 105.3,
      "adjDefRank": 195,
      "adjEM": -1.15,
      "adjOff": 104.2,
      "adjOffRank": 144,
      "adjTempo": 63.1,
      "adjTempoRank": 268,
      "conference": "Horz",
      "rank": 169,
      "record": "14-14"
    },
    "Wyoming": {
      "adjDef": 100.3,
      "adjDefRank": 106,
      "adjEM": 4.34,
      "adjOff": 104.7,
      "adjOffRank": 129,
      "adjTempo": 68,
      "adjTempoRank": 88,
      "conference": "MWC",
      "rank": 114,
      "record": "11-17"
    },
    "Xavier": {
      "adjDef": 93.6,
      "adjDefRank": 20,
      "adjEM": 20.16,
      "adjOff": 113.8,
      "adjOffRank": 21,
      "adjTempo": 64.1,
      "adjTempoRank": 230,
      "conference": "A10",
      "finish": "Elite Eight",
      "rank": 21,
      "record": "26-11",
      "seed": 7
    },
    "Yale": {
      "adjDef": 103.8,
      "adjDefRank": 175,
      "adjEM": -4.07,
      "adjOff": 99.7,
      "adjOffRank": 223,
      "adjTempo": 62.8,
      "adjTempoRank": 276,
      "conference": "Ivy",
      "rank": 195,
      "record": "12-15"
    },
    "Youngstown St.": {
      "adjDef": 105.8,
      "adjDefRank": 204,
      "adjEM": -8.13,
      "adjOff": 97.6,
      "adjOffRank": 260,
      "adjTempo": 67.3,
      "adjTempoRank": 108,
      "conference": "Horz",
      "rank": 249,
      "record": "8-20"
    }
  },
  "2005": {
    "Air Force": {
      "adjDef": 98.9,
      "adjDefRank": 83,
      "adjEM": 13.4,
      "adjOff": 112.2,
      "adjOffRank": 35,
      "adjTempo": 54.3,
      "adjTempoRank": 329,
      "conference": "MWC",
      "rank": 44,
      "record": "18-12"
    },
    "Akron": {
      "adjDef": 95.9,
      "adjDefRank": 44,
      "adjEM": 10.1,
      "adjOff": 106,
      "adjOffRank": 111,
      "adjTempo": 66.2,
      "adjTempoRank": 162,
      "conference": "MAC",
      "rank": 72,
      "record": "19-10"
    },
    "Alabama": {
      "adjDef": 98.3,
      "adjDefRank": 78,
      "adjEM": 20.51,
      "adjOff": 118.8,
      "adjOffRank": 9,
      "adjTempo": 62.8,
      "adjTempoRank": 292,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 18,
      "record": "24-8",
      "seed": 5
    },
    "Alabama A&M": {
      "adjDef": 102.9,
      "adjDefRank": 169,
      "adjEM": -12.49,
      "adjOff": 90.5,
      "adjOffRank": 316,
      "adjTempo": 72.8,
      "adjTempoRank": 10,
      "conference": "SWAC",
      "finish": "Play-in",
      "rank": 278,
      "record": "15-14",
      "seed": 16
    },
    "Alabama St.": {
      "adjDef": 102.8,
      "adjDefRank": 165,
      "adjEM": -15.76,
      "adjOff": 87.1,
      "adjOffRank": 323,
      "adjTempo": 61.8,
      "adjTempoRank": 303,
      "conference": "SWAC",
      "rank": 301,
      "record": "13-14"
    },
    "Albany": {
      "adjDef": 103.4,
      "adjDefRank": 183,
      "adjEM": 0.22,
      "adjOff": 103.6,
      "adjOffRank": 150,
      "adjTempo": 65.1,
      "adjTempoRank": 210,
      "conference": "AE",
      "rank": 161,
      "record": "13-15"
    },
    "Alcorn St.": {
      "adjDef": 106.1,
      "adjDefRank": 230,
      "adjEM": -27.05,
      "adjOff": 79,
      "adjOffRank": 328,
      "adjTempo": 68.9,
      "adjTempoRank": 58,
      "conference": "SWAC",
      "rank": 327,
      "record": "7-22"
    },
    "American": {
      "adjDef": 103.7,
      "adjDefRank": 187,
      "adjEM": -1.95,
      "adjOff": 101.8,
      "adjOffRank": 187,
      "adjTempo": 63.9,
      "adjTempoRank": 256,
      "conference": "Pat",
      "rank": 185,
      "record": "16-12"
    },
    "Appalachian St.": {
      "adjDef": 103.8,
      "adjDefRank": 190,
      "adjEM": -1.84,
      "adjOff": 102,
      "adjOffRank": 183,
      "adjTempo": 67.1,
      "adjTempoRank": 119,
      "conference": "SC",
      "rank": 182,
      "record": "18-12"
    },
    "Arizona": {
      "adjDef": 94.5,
      "adjDefRank": 30,
      "adjEM": 22.22,
      "adjOff": 116.7,
      "adjOffRank": 10,
      "adjTempo": 68.6,
      "adjTempoRank": 75,
      "conference": "P10",
      "finish": "Elite Eight",
      "rank": 13,
      "record": "30-7",
      "seed": 3
    },
    "Arizona St.": {
      "adjDef": 100.8,
      "adjDefRank": 123,
      "adjEM": 10.32,
      "adjOff": 111.1,
      "adjOffRank": 48,
      "adjTempo": 68.6,
      "adjTempoRank": 73,
      "conference": "P10",
      "rank": 70,
      "record": "18-14"
    },
    "Arkansas": {
      "adjDef": 94.2,
      "adjDefRank": 29,
      "adjEM": 11.98,
      "adjOff": 106.2,
      "adjOffRank": 108,
      "adjTempo": 65.4,
      "adjTempoRank": 199,
      "conference": "SEC",
      "rank": 56,
      "record": "18-12"
    },
    "Arkansas Little Rock": {
      "adjDef": 100.9,
      "adjDefRank": 127,
      "adjEM": 1.69,
      "adjOff": 102.6,
      "adjOffRank": 168,
      "adjTempo": 63.3,
      "adjTempoRank": 279,
      "conference": "SB",
      "rank": 146,
      "record": "18-10"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 106.8,
      "adjDefRank": 240,
      "adjEM": -22.97,
      "adjOff": 83.9,
      "adjOffRank": 325,
      "adjTempo": 66.6,
      "adjTempoRank": 138,
      "conference": "SWAC",
      "rank": 322,
      "record": "6-20"
    },
    "Arkansas St.": {
      "adjDef": 105.5,
      "adjDefRank": 225,
      "adjEM": 0.34,
      "adjOff": 105.8,
      "adjOffRank": 115,
      "adjTempo": 68.4,
      "adjTempoRank": 78,
      "conference": "SB",
      "rank": 158,
      "record": "16-13"
    },
    "Army": {
      "adjDef": 114.9,
      "adjDefRank": 328,
      "adjEM": -26.29,
      "adjOff": 88.6,
      "adjOffRank": 321,
      "adjTempo": 61.4,
      "adjTempoRank": 307,
      "conference": "Pat",
      "rank": 325,
      "record": "3-24"
    },
    "Auburn": {
      "adjDef": 105.3,
      "adjDefRank": 222,
      "adjEM": 1.53,
      "adjOff": 106.8,
      "adjOffRank": 99,
      "adjTempo": 67.6,
      "adjTempoRank": 108,
      "conference": "SEC",
      "rank": 149,
      "record": "14-17"
    },
    "Austin Peay": {
      "adjDef": 101.7,
      "adjDefRank": 147,
      "adjEM": -1.93,
      "adjOff": 99.8,
      "adjOffRank": 220,
      "adjTempo": 64.4,
      "adjTempoRank": 235,
      "conference": "OVC",
      "rank": 183,
      "record": "13-19"
    },
    "BYU": {
      "adjDef": 101.1,
      "adjDefRank": 129,
      "adjEM": 0.37,
      "adjOff": 101.4,
      "adjOffRank": 196,
      "adjTempo": 65.4,
      "adjTempoRank": 197,
      "conference": "MWC",
      "rank": 157,
      "record": "9-21"
    },
    "Ball St.": {
      "adjDef": 102.4,
      "adjDefRank": 157,
      "adjEM": 6.59,
      "adjOff": 109,
      "adjOffRank": 70,
      "adjTempo": 65.8,
      "adjTempoRank": 183,
      "conference": "MAC",
      "rank": 96,
      "record": "15-13"
    },
    "Baylor": {
      "adjDef": 111.1,
      "adjDefRank": 300,
      "adjEM": -7.51,
      "adjOff": 103.5,
      "adjOffRank": 151,
      "adjTempo": 63.8,
      "adjTempoRank": 258,
      "conference": "B12",
      "rank": 236,
      "record": "9-19"
    },
    "Belmont": {
      "adjDef": 104.8,
      "adjDefRank": 214,
      "adjEM": -6.13,
      "adjOff": 98.7,
      "adjOffRank": 232,
      "adjTempo": 65.9,
      "adjTempoRank": 177,
      "conference": "ASun",
      "rank": 221,
      "record": "14-16"
    },
    "Bethune Cookman": {
      "adjDef": 109.1,
      "adjDefRank": 280,
      "adjEM": -18.38,
      "adjOff": 90.7,
      "adjOffRank": 313,
      "adjTempo": 67.2,
      "adjTempoRank": 116,
      "conference": "MEAC",
      "rank": 313,
      "record": "13-17"
    },
    "Binghamton": {
      "adjDef": 101.2,
      "adjDefRank": 134,
      "adjEM": -2.73,
      "adjOff": 98.5,
      "adjOffRank": 234,
      "adjTempo": 63.4,
      "adjTempoRank": 274,
      "conference": "AE",
      "rank": 193,
      "record": "12-17"
    },
    "Birmingham Southern": {
      "adjDef": 109.4,
      "adjDefRank": 284,
      "adjEM": -6,
      "adjOff": 103.4,
      "adjOffRank": 154,
      "adjTempo": 59.7,
      "adjTempoRank": 320,
      "conference": "BSth",
      "rank": 220,
      "record": "16-14"
    },
    "Boise St.": {
      "adjDef": 109.8,
      "adjDefRank": 290,
      "adjEM": -5.4,
      "adjOff": 104.4,
      "adjOffRank": 138,
      "adjTempo": 69.1,
      "adjTempoRank": 51,
      "conference": "WAC",
      "rank": 214,
      "record": "16-18"
    },
    "Boston College": {
      "adjDef": 96,
      "adjDefRank": 46,
      "adjEM": 18.3,
      "adjOff": 114.3,
      "adjOffRank": 23,
      "adjTempo": 64.8,
      "adjTempoRank": 226,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 25,
      "record": "25-5",
      "seed": 4
    },
    "Boston University": {
      "adjDef": 93.9,
      "adjDefRank": 24,
      "adjEM": 6.83,
      "adjOff": 100.7,
      "adjOffRank": 207,
      "adjTempo": 62,
      "adjTempoRank": 302,
      "conference": "AE",
      "rank": 92,
      "record": "20-9"
    },
    "Bowling Green": {
      "adjDef": 104.4,
      "adjDefRank": 196,
      "adjEM": 3.23,
      "adjOff": 107.6,
      "adjOffRank": 83,
      "adjTempo": 65.1,
      "adjTempoRank": 211,
      "conference": "MAC",
      "rank": 133,
      "record": "18-11"
    },
    "Bradley": {
      "adjDef": 98.6,
      "adjDefRank": 81,
      "adjEM": 3.73,
      "adjOff": 102.4,
      "adjOffRank": 172,
      "adjTempo": 70.4,
      "adjTempoRank": 33,
      "conference": "MVC",
      "rank": 126,
      "record": "13-15"
    },
    "Brown": {
      "adjDef": 106.8,
      "adjDefRank": 239,
      "adjEM": -7.73,
      "adjOff": 99.1,
      "adjOffRank": 226,
      "adjTempo": 67.8,
      "adjTempoRank": 100,
      "conference": "Ivy",
      "rank": 239,
      "record": "12-16"
    },
    "Bucknell": {
      "adjDef": 95.6,
      "adjDefRank": 41,
      "adjEM": 6.69,
      "adjOff": 102.2,
      "adjOffRank": 176,
      "adjTempo": 63.1,
      "adjTempoRank": 281,
      "conference": "Pat",
      "finish": "Round of 32",
      "rank": 95,
      "record": "23-10",
      "seed": 14
    },
    "Buffalo": {
      "adjDef": 97.5,
      "adjDefRank": 68,
      "adjEM": 9.87,
      "adjOff": 107.4,
      "adjOffRank": 87,
      "adjTempo": 68.6,
      "adjTempoRank": 72,
      "conference": "MAC",
      "rank": 76,
      "record": "23-10"
    },
    "Butler": {
      "adjDef": 107.9,
      "adjDefRank": 263,
      "adjEM": 1.7,
      "adjOff": 109.6,
      "adjOffRank": 60,
      "adjTempo": 58.1,
      "adjTempoRank": 326,
      "conference": "Horz",
      "rank": 145,
      "record": "13-15"
    },
    "Cal Poly": {
      "adjDef": 114.7,
      "adjDefRank": 327,
      "adjEM": -17.87,
      "adjOff": 96.8,
      "adjOffRank": 257,
      "adjTempo": 71.2,
      "adjTempoRank": 18,
      "conference": "BW",
      "rank": 311,
      "record": "5-22"
    },
    "Cal St. Fullerton": {
      "adjDef": 101.9,
      "adjDefRank": 152,
      "adjEM": 4.53,
      "adjOff": 106.5,
      "adjOffRank": 104,
      "adjTempo": 70.6,
      "adjTempoRank": 27,
      "conference": "BW",
      "rank": 117,
      "record": "21-11"
    },
    "Cal St. Northridge": {
      "adjDef": 96.7,
      "adjDefRank": 56,
      "adjEM": 6.56,
      "adjOff": 103.3,
      "adjOffRank": 155,
      "adjTempo": 68.5,
      "adjTempoRank": 76,
      "conference": "BW",
      "rank": 99,
      "record": "18-13"
    },
    "California": {
      "adjDef": 100.2,
      "adjDefRank": 108,
      "adjEM": 1.76,
      "adjOff": 102,
      "adjOffRank": 184,
      "adjTempo": 66.2,
      "adjTempoRank": 158,
      "conference": "P10",
      "rank": 144,
      "record": "13-16"
    },
    "Campbell": {
      "adjDef": 111.4,
      "adjDefRank": 305,
      "adjEM": -23.46,
      "adjOff": 87.9,
      "adjOffRank": 322,
      "adjTempo": 67.4,
      "adjTempoRank": 110,
      "conference": "ASun",
      "rank": 323,
      "record": "2-25"
    },
    "Canisius": {
      "adjDef": 109.4,
      "adjDefRank": 283,
      "adjEM": -9.65,
      "adjOff": 99.8,
      "adjOffRank": 221,
      "adjTempo": 66.6,
      "adjTempoRank": 137,
      "conference": "MAAC",
      "rank": 261,
      "record": "11-18"
    },
    "Centenary": {
      "adjDef": 113.4,
      "adjDefRank": 320,
      "adjEM": -19.89,
      "adjOff": 93.5,
      "adjOffRank": 297,
      "adjTempo": 66,
      "adjTempoRank": 169,
      "conference": "MCon",
      "rank": 319,
      "record": "3-24"
    },
    "Central Connecticut": {
      "adjDef": 110.4,
      "adjDefRank": 296,
      "adjEM": -7.96,
      "adjOff": 102.4,
      "adjOffRank": 171,
      "adjTempo": 63.6,
      "adjTempoRank": 264,
      "conference": "NEC",
      "rank": 240,
      "record": "12-16"
    },
    "Central Michigan": {
      "adjDef": 105.6,
      "adjDefRank": 226,
      "adjEM": -7.71,
      "adjOff": 97.9,
      "adjOffRank": 241,
      "adjTempo": 68.9,
      "adjTempoRank": 57,
      "conference": "MAC",
      "rank": 238,
      "record": "10-18"
    },
    "Charleston Southern": {
      "adjDef": 103.8,
      "adjDefRank": 192,
      "adjEM": -13.52,
      "adjOff": 90.3,
      "adjOffRank": 317,
      "adjTempo": 61.4,
      "adjTempoRank": 308,
      "conference": "BSth",
      "rank": 288,
      "record": "13-17"
    },
    "Charlotte": {
      "adjDef": 99.5,
      "adjDefRank": 90,
      "adjEM": 12.15,
      "adjOff": 111.6,
      "adjOffRank": 42,
      "adjTempo": 70.6,
      "adjTempoRank": 29,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 54,
      "record": "21-8",
      "seed": 7
    },
    "Chattanooga": {
      "adjDef": 98.8,
      "adjDefRank": 82,
      "adjEM": 0.03,
      "adjOff": 98.9,
      "adjOffRank": 229,
      "adjTempo": 67.6,
      "adjTempoRank": 109,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 165,
      "record": "20-11",
      "seed": 15
    },
    "Chicago St.": {
      "adjDef": 105.2,
      "adjDefRank": 221,
      "adjEM": -8.09,
      "adjOff": 97.1,
      "adjOffRank": 253,
      "adjTempo": 65.5,
      "adjTempoRank": 195,
      "conference": "MCon",
      "rank": 241,
      "record": "9-19"
    },
    "Cincinnati": {
      "adjDef": 92,
      "adjDefRank": 14,
      "adjEM": 20.43,
      "adjOff": 112.5,
      "adjOffRank": 33,
      "adjTempo": 68,
      "adjTempoRank": 89,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 19,
      "record": "25-8",
      "seed": 7
    },
    "Clemson": {
      "adjDef": 93.7,
      "adjDefRank": 21,
      "adjEM": 11.92,
      "adjOff": 105.6,
      "adjOffRank": 117,
      "adjTempo": 69.7,
      "adjTempoRank": 44,
      "conference": "ACC",
      "rank": 57,
      "record": "16-16"
    },
    "Cleveland St.": {
      "adjDef": 104.8,
      "adjDefRank": 213,
      "adjEM": -4.25,
      "adjOff": 100.6,
      "adjOffRank": 208,
      "adjTempo": 68.2,
      "adjTempoRank": 85,
      "conference": "Horz",
      "rank": 204,
      "record": "9-17"
    },
    "Coastal Carolina": {
      "adjDef": 111.4,
      "adjDefRank": 303,
      "adjEM": -11.97,
      "adjOff": 99.4,
      "adjOffRank": 223,
      "adjTempo": 67.7,
      "adjTempoRank": 105,
      "conference": "BSth",
      "rank": 273,
      "record": "10-19"
    },
    "Colgate": {
      "adjDef": 108.5,
      "adjDefRank": 272,
      "adjEM": -8.59,
      "adjOff": 99.9,
      "adjOffRank": 217,
      "adjTempo": 64.2,
      "adjTempoRank": 240,
      "conference": "Pat",
      "rank": 248,
      "record": "12-16"
    },
    "College of Charleston": {
      "adjDef": 101,
      "adjDefRank": 128,
      "adjEM": 0.12,
      "adjOff": 101.1,
      "adjOffRank": 203,
      "adjTempo": 67.4,
      "adjTempoRank": 111,
      "conference": "SC",
      "rank": 163,
      "record": "18-10"
    },
    "Colorado": {
      "adjDef": 102.7,
      "adjDefRank": 161,
      "adjEM": 4.67,
      "adjOff": 107.4,
      "adjOffRank": 88,
      "adjTempo": 69.5,
      "adjTempoRank": 48,
      "conference": "B12",
      "rank": 113,
      "record": "14-16"
    },
    "Colorado St.": {
      "adjDef": 102,
      "adjDefRank": 153,
      "adjEM": 1.53,
      "adjOff": 103.5,
      "adjOffRank": 153,
      "adjTempo": 66.7,
      "adjTempoRank": 133,
      "conference": "MWC",
      "rank": 148,
      "record": "11-17"
    },
    "Columbia": {
      "adjDef": 104.4,
      "adjDefRank": 197,
      "adjEM": -8.89,
      "adjOff": 95.5,
      "adjOffRank": 272,
      "adjTempo": 66.1,
      "adjTempoRank": 166,
      "conference": "Ivy",
      "rank": 250,
      "record": "12-15"
    },
    "Connecticut": {
      "adjDef": 90.2,
      "adjDefRank": 10,
      "adjEM": 21.94,
      "adjOff": 112.1,
      "adjOffRank": 38,
      "adjTempo": 70.6,
      "adjTempoRank": 28,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 15,
      "record": "23-8",
      "seed": 2
    },
    "Coppin St.": {
      "adjDef": 101.6,
      "adjDefRank": 143,
      "adjEM": -8.71,
      "adjOff": 92.9,
      "adjOffRank": 299,
      "adjTempo": 64.1,
      "adjTempoRank": 248,
      "conference": "MEAC",
      "rank": 249,
      "record": "13-15"
    },
    "Cornell": {
      "adjDef": 108.7,
      "adjDefRank": 274,
      "adjEM": -6.86,
      "adjOff": 101.8,
      "adjOffRank": 186,
      "adjTempo": 62.3,
      "adjTempoRank": 297,
      "conference": "Ivy",
      "rank": 227,
      "record": "13-14"
    },
    "Creighton": {
      "adjDef": 100,
      "adjDefRank": 104,
      "adjEM": 14.33,
      "adjOff": 114.3,
      "adjOffRank": 22,
      "adjTempo": 63.1,
      "adjTempoRank": 285,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 39,
      "record": "23-11",
      "seed": 10
    },
    "Dartmouth": {
      "adjDef": 107.2,
      "adjDefRank": 244,
      "adjEM": -12.71,
      "adjOff": 94.5,
      "adjOffRank": 286,
      "adjTempo": 61,
      "adjTempoRank": 312,
      "conference": "Ivy",
      "rank": 282,
      "record": "10-17"
    },
    "Davidson": {
      "adjDef": 103.2,
      "adjDefRank": 178,
      "adjEM": 4.61,
      "adjOff": 107.8,
      "adjOffRank": 78,
      "adjTempo": 65.4,
      "adjTempoRank": 200,
      "conference": "SC",
      "rank": 114,
      "record": "23-9"
    },
    "Dayton": {
      "adjDef": 97.5,
      "adjDefRank": 65,
      "adjEM": 4.72,
      "adjOff": 102.2,
      "adjOffRank": 177,
      "adjTempo": 63,
      "adjTempoRank": 287,
      "conference": "A10",
      "rank": 111,
      "record": "18-11"
    },
    "DePaul": {
      "adjDef": 100.5,
      "adjDefRank": 117,
      "adjEM": 11.29,
      "adjOff": 111.8,
      "adjOffRank": 41,
      "adjTempo": 62.5,
      "adjTempoRank": 295,
      "conference": "CUSA",
      "rank": 62,
      "record": "20-11"
    },
    "Delaware": {
      "adjDef": 101.5,
      "adjDefRank": 140,
      "adjEM": -6.46,
      "adjOff": 95.1,
      "adjOffRank": 278,
      "adjTempo": 68.2,
      "adjTempoRank": 84,
      "conference": "CAA",
      "rank": 224,
      "record": "11-20"
    },
    "Delaware St.": {
      "adjDef": 107.2,
      "adjDefRank": 245,
      "adjEM": -4.95,
      "adjOff": 102.3,
      "adjOffRank": 175,
      "adjTempo": 59.3,
      "adjTempoRank": 322,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 209,
      "record": "19-14",
      "seed": 16
    },
    "Denver": {
      "adjDef": 99.9,
      "adjDefRank": 103,
      "adjEM": 7.98,
      "adjOff": 107.9,
      "adjOffRank": 76,
      "adjTempo": 66.6,
      "adjTempoRank": 143,
      "conference": "SB",
      "rank": 87,
      "record": "20-11"
    },
    "Detroit": {
      "adjDef": 99.5,
      "adjDefRank": 91,
      "adjEM": 3.59,
      "adjOff": 103.1,
      "adjOffRank": 160,
      "adjTempo": 59.7,
      "adjTempoRank": 321,
      "conference": "Horz",
      "rank": 131,
      "record": "14-16"
    },
    "Drake": {
      "adjDef": 102.8,
      "adjDefRank": 166,
      "adjEM": 2.47,
      "adjOff": 105.3,
      "adjOffRank": 121,
      "adjTempo": 67,
      "adjTempoRank": 125,
      "conference": "MVC",
      "rank": 138,
      "record": "13-16"
    },
    "Drexel": {
      "adjDef": 100.7,
      "adjDefRank": 122,
      "adjEM": 5.63,
      "adjOff": 106.3,
      "adjOffRank": 107,
      "adjTempo": 65,
      "adjTempoRank": 215,
      "conference": "CAA",
      "rank": 104,
      "record": "17-12"
    },
    "Duke": {
      "adjDef": 88.3,
      "adjDefRank": 2,
      "adjEM": 27.41,
      "adjOff": 115.7,
      "adjOffRank": 13,
      "adjTempo": 68.3,
      "adjTempoRank": 81,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 3,
      "record": "27-6",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 112.2,
      "adjDefRank": 313,
      "adjEM": -9.28,
      "adjOff": 102.9,
      "adjOffRank": 162,
      "adjTempo": 66.2,
      "adjTempoRank": 161,
      "conference": "A10",
      "rank": 256,
      "record": "8-22"
    },
    "East Carolina": {
      "adjDef": 100.3,
      "adjDefRank": 112,
      "adjEM": -2.99,
      "adjOff": 97.4,
      "adjOffRank": 250,
      "adjTempo": 66.3,
      "adjTempoRank": 156,
      "conference": "CUSA",
      "rank": 197,
      "record": "9-19"
    },
    "East Tennessee St.": {
      "adjDef": 111.3,
      "adjDefRank": 302,
      "adjEM": -9.31,
      "adjOff": 102,
      "adjOffRank": 185,
      "adjTempo": 72.3,
      "adjTempoRank": 13,
      "conference": "SC",
      "rank": 257,
      "record": "10-19"
    },
    "Eastern Illinois": {
      "adjDef": 108.7,
      "adjDefRank": 277,
      "adjEM": -7.03,
      "adjOff": 101.7,
      "adjOffRank": 189,
      "adjTempo": 66.2,
      "adjTempoRank": 163,
      "conference": "OVC",
      "rank": 229,
      "record": "12-16"
    },
    "Eastern Kentucky": {
      "adjDef": 100.8,
      "adjDefRank": 124,
      "adjEM": 1.98,
      "adjOff": 102.8,
      "adjOffRank": 164,
      "adjTempo": 66.1,
      "adjTempoRank": 167,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 143,
      "record": "22-9",
      "seed": 15
    },
    "Eastern Michigan": {
      "adjDef": 106.4,
      "adjDefRank": 235,
      "adjEM": -2.54,
      "adjOff": 103.8,
      "adjOffRank": 147,
      "adjTempo": 70.1,
      "adjTempoRank": 37,
      "conference": "MAC",
      "rank": 191,
      "record": "12-18"
    },
    "Eastern Washington": {
      "adjDef": 102.3,
      "adjDefRank": 156,
      "adjEM": -9.83,
      "adjOff": 92.5,
      "adjOffRank": 303,
      "adjTempo": 63.3,
      "adjTempoRank": 278,
      "conference": "BSky",
      "rank": 262,
      "record": "8-20"
    },
    "Elon": {
      "adjDef": 104.7,
      "adjDefRank": 205,
      "adjEM": -12.67,
      "adjOff": 92,
      "adjOffRank": 307,
      "adjTempo": 62.7,
      "adjTempoRank": 294,
      "conference": "SC",
      "rank": 281,
      "record": "8-23"
    },
    "Evansville": {
      "adjDef": 106.8,
      "adjDefRank": 241,
      "adjEM": 0.55,
      "adjOff": 107.4,
      "adjOffRank": 86,
      "adjTempo": 66.6,
      "adjTempoRank": 141,
      "conference": "MVC",
      "rank": 152,
      "record": "11-17"
    },
    "FIU": {
      "adjDef": 102,
      "adjDefRank": 154,
      "adjEM": -3.66,
      "adjOff": 98.4,
      "adjOffRank": 235,
      "adjTempo": 66.6,
      "adjTempoRank": 139,
      "conference": "SB",
      "rank": 201,
      "record": "13-17"
    },
    "Fairfield": {
      "adjDef": 103,
      "adjDefRank": 172,
      "adjEM": -0.71,
      "adjOff": 102.3,
      "adjOffRank": 174,
      "adjTempo": 63.2,
      "adjTempoRank": 280,
      "conference": "MAAC",
      "rank": 175,
      "record": "15-15"
    },
    "Fairleigh Dickinson": {
      "adjDef": 105.2,
      "adjDefRank": 220,
      "adjEM": -2.52,
      "adjOff": 102.7,
      "adjOffRank": 166,
      "adjTempo": 68.6,
      "adjTempoRank": 74,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 190,
      "record": "20-13",
      "seed": 16
    },
    "Florida": {
      "adjDef": 92.5,
      "adjDefRank": 16,
      "adjEM": 23.93,
      "adjOff": 116.4,
      "adjOffRank": 11,
      "adjTempo": 65.2,
      "adjTempoRank": 207,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 10,
      "record": "24-8",
      "seed": 4
    },
    "Florida A&M": {
      "adjDef": 111.8,
      "adjDefRank": 309,
      "adjEM": -15.78,
      "adjOff": 96,
      "adjOffRank": 267,
      "adjTempo": 67.7,
      "adjTempoRank": 102,
      "conference": "MEAC",
      "rank": 302,
      "record": "14-15"
    },
    "Florida Atlantic": {
      "adjDef": 108.5,
      "adjDefRank": 271,
      "adjEM": -8.12,
      "adjOff": 100.3,
      "adjOffRank": 213,
      "adjTempo": 72.9,
      "adjTempoRank": 8,
      "conference": "ASun",
      "rank": 242,
      "record": "10-17"
    },
    "Florida St.": {
      "adjDef": 98.9,
      "adjDefRank": 84,
      "adjEM": 6.24,
      "adjOff": 105.1,
      "adjOffRank": 125,
      "adjTempo": 65.4,
      "adjTempoRank": 198,
      "conference": "ACC",
      "rank": 102,
      "record": "12-19"
    },
    "Fordham": {
      "adjDef": 99.6,
      "adjDefRank": 94,
      "adjEM": -2.17,
      "adjOff": 97.4,
      "adjOffRank": 247,
      "adjTempo": 65.7,
      "adjTempoRank": 186,
      "conference": "A10",
      "rank": 188,
      "record": "13-16"
    },
    "Fresno St.": {
      "adjDef": 101.6,
      "adjDefRank": 141,
      "adjEM": -0.06,
      "adjOff": 101.5,
      "adjOffRank": 194,
      "adjTempo": 69,
      "adjTempoRank": 53,
      "conference": "WAC",
      "rank": 168,
      "record": "16-14"
    },
    "Furman": {
      "adjDef": 104.8,
      "adjDefRank": 209,
      "adjEM": -3.08,
      "adjOff": 101.7,
      "adjOffRank": 190,
      "adjTempo": 63.1,
      "adjTempoRank": 283,
      "conference": "SC",
      "rank": 199,
      "record": "16-13"
    },
    "Gardner Webb": {
      "adjDef": 102.9,
      "adjDefRank": 168,
      "adjEM": -5.12,
      "adjOff": 97.8,
      "adjOffRank": 243,
      "adjTempo": 67.8,
      "adjTempoRank": 99,
      "conference": "ASun",
      "rank": 210,
      "record": "18-12"
    },
    "George Mason": {
      "adjDef": 106.3,
      "adjDefRank": 233,
      "adjEM": 2.12,
      "adjOff": 108.4,
      "adjOffRank": 72,
      "adjTempo": 65.9,
      "adjTempoRank": 176,
      "conference": "CAA",
      "rank": 140,
      "record": "16-13"
    },
    "George Washington": {
      "adjDef": 95.5,
      "adjDefRank": 40,
      "adjEM": 14.41,
      "adjOff": 109.9,
      "adjOffRank": 56,
      "adjTempo": 70.8,
      "adjTempoRank": 25,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 38,
      "record": "22-8",
      "seed": 12
    },
    "Georgetown": {
      "adjDef": 99.8,
      "adjDefRank": 97,
      "adjEM": 12.45,
      "adjOff": 112.2,
      "adjOffRank": 36,
      "adjTempo": 59.2,
      "adjTempoRank": 324,
      "conference": "BE",
      "rank": 52,
      "record": "19-13"
    },
    "Georgia": {
      "adjDef": 99.1,
      "adjDefRank": 87,
      "adjEM": -4.63,
      "adjOff": 94.4,
      "adjOffRank": 287,
      "adjTempo": 64.2,
      "adjTempoRank": 244,
      "conference": "SEC",
      "rank": 205,
      "record": "8-20"
    },
    "Georgia Southern": {
      "adjDef": 101.4,
      "adjDefRank": 136,
      "adjEM": -0.55,
      "adjOff": 100.9,
      "adjOffRank": 205,
      "adjTempo": 75.3,
      "adjTempoRank": 1,
      "conference": "SC",
      "rank": 173,
      "record": "18-13"
    },
    "Georgia St.": {
      "adjDef": 109.6,
      "adjDefRank": 287,
      "adjEM": -7.42,
      "adjOff": 102.2,
      "adjOffRank": 179,
      "adjTempo": 65,
      "adjTempoRank": 214,
      "conference": "ASun",
      "rank": 234,
      "record": "14-15"
    },
    "Georgia Tech": {
      "adjDef": 90,
      "adjDefRank": 8,
      "adjEM": 19.23,
      "adjOff": 109.2,
      "adjOffRank": 66,
      "adjTempo": 68.6,
      "adjTempoRank": 68,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "20-12",
      "seed": 5
    },
    "Gonzaga": {
      "adjDef": 101.4,
      "adjDefRank": 135,
      "adjEM": 17.88,
      "adjOff": 119.3,
      "adjOffRank": 7,
      "adjTempo": 67.1,
      "adjTempoRank": 124,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 26,
      "record": "26-5",
      "seed": 3
    },
    "Grambling St.": {
      "adjDef": 109,
      "adjDefRank": 279,
      "adjEM": -15.43,
      "adjOff": 93.6,
      "adjOffRank": 296,
      "adjTempo": 68.3,
      "adjTempoRank": 80,
      "conference": "SWAC",
      "rank": 300,
      "record": "14-10"
    },
    "Green Bay": {
      "adjDef": 105.2,
      "adjDefRank": 219,
      "adjEM": -0.87,
      "adjOff": 104.3,
      "adjOffRank": 139,
      "adjTempo": 65.9,
      "adjTempoRank": 174,
      "conference": "Horz",
      "rank": 176,
      "record": "17-11"
    },
    "Hampton": {
      "adjDef": 101.8,
      "adjDefRank": 148,
      "adjEM": -7.17,
      "adjOff": 94.6,
      "adjOffRank": 285,
      "adjTempo": 66.2,
      "adjTempoRank": 159,
      "conference": "MEAC",
      "rank": 232,
      "record": "17-13"
    },
    "Hartford": {
      "adjDef": 109.7,
      "adjDefRank": 288,
      "adjEM": -17.6,
      "adjOff": 92.1,
      "adjOffRank": 306,
      "adjTempo": 65,
      "adjTempoRank": 213,
      "conference": "AE",
      "rank": 310,
      "record": "8-20"
    },
    "Harvard": {
      "adjDef": 103.1,
      "adjDefRank": 175,
      "adjEM": -5.66,
      "adjOff": 97.5,
      "adjOffRank": 246,
      "adjTempo": 69,
      "adjTempoRank": 54,
      "conference": "Ivy",
      "rank": 218,
      "record": "12-15"
    },
    "Hawaii": {
      "adjDef": 101.7,
      "adjDefRank": 145,
      "adjEM": 3.68,
      "adjOff": 105.3,
      "adjOffRank": 119,
      "adjTempo": 64.7,
      "adjTempoRank": 227,
      "conference": "WAC",
      "rank": 127,
      "record": "16-13"
    },
    "High Point": {
      "adjDef": 103.8,
      "adjDefRank": 191,
      "adjEM": -6.53,
      "adjOff": 97.3,
      "adjOffRank": 251,
      "adjTempo": 72.4,
      "adjTempoRank": 12,
      "conference": "BSth",
      "rank": 226,
      "record": "13-18"
    },
    "Hofstra": {
      "adjDef": 101.1,
      "adjDefRank": 132,
      "adjEM": 6.18,
      "adjOff": 107.3,
      "adjOffRank": 90,
      "adjTempo": 66.7,
      "adjTempoRank": 134,
      "conference": "CAA",
      "rank": 103,
      "record": "21-9"
    },
    "Holy Cross": {
      "adjDef": 93.8,
      "adjDefRank": 22,
      "adjEM": 10.97,
      "adjOff": 104.7,
      "adjOffRank": 131,
      "adjTempo": 63.5,
      "adjTempoRank": 268,
      "conference": "Pat",
      "rank": 66,
      "record": "25-7"
    },
    "Houston": {
      "adjDef": 99.8,
      "adjDefRank": 101,
      "adjEM": 6.55,
      "adjOff": 106.4,
      "adjOffRank": 105,
      "adjTempo": 66.3,
      "adjTempoRank": 155,
      "conference": "CUSA",
      "rank": 100,
      "record": "18-14"
    },
    "Howard": {
      "adjDef": 114.6,
      "adjDefRank": 326,
      "adjEM": -25.43,
      "adjOff": 89.2,
      "adjOffRank": 320,
      "adjTempo": 68.7,
      "adjTempoRank": 67,
      "conference": "MEAC",
      "rank": 324,
      "record": "5-23"
    },
    "IPFW": {
      "adjDef": 108.7,
      "adjDefRank": 275,
      "adjEM": -14.04,
      "adjOff": 94.7,
      "adjOffRank": 284,
      "adjTempo": 65.6,
      "adjTempoRank": 192,
      "conference": "ind",
      "rank": 292,
      "record": "7-22"
    },
    "IUPUI": {
      "adjDef": 107.3,
      "adjDefRank": 247,
      "adjEM": 2.33,
      "adjOff": 109.6,
      "adjOffRank": 61,
      "adjTempo": 63,
      "adjTempoRank": 288,
      "conference": "MCon",
      "rank": 139,
      "record": "16-13"
    },
    "Idaho": {
      "adjDef": 104.5,
      "adjDefRank": 202,
      "adjEM": -8.55,
      "adjOff": 96,
      "adjOffRank": 266,
      "adjTempo": 63.6,
      "adjTempoRank": 267,
      "conference": "BW",
      "rank": 246,
      "record": "8-22"
    },
    "Idaho St.": {
      "adjDef": 107.6,
      "adjDefRank": 256,
      "adjEM": -17.04,
      "adjOff": 90.6,
      "adjOffRank": 315,
      "adjTempo": 65.8,
      "adjTempoRank": 181,
      "conference": "BSky",
      "rank": 308,
      "record": "9-18"
    },
    "Illinois": {
      "adjDef": 89.6,
      "adjDefRank": 4,
      "adjEM": 32.68,
      "adjOff": 122.3,
      "adjOffRank": 3,
      "adjTempo": 64.1,
      "adjTempoRank": 249,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 2,
      "record": "37-2",
      "seed": 1
    },
    "Illinois Chicago": {
      "adjDef": 104.5,
      "adjDefRank": 203,
      "adjEM": 3.06,
      "adjOff": 107.6,
      "adjOffRank": 84,
      "adjTempo": 65.9,
      "adjTempoRank": 178,
      "conference": "Horz",
      "rank": 136,
      "record": "15-14"
    },
    "Illinois St.": {
      "adjDef": 101.8,
      "adjDefRank": 150,
      "adjEM": 4.11,
      "adjOff": 105.9,
      "adjOffRank": 112,
      "adjTempo": 63.7,
      "adjTempoRank": 262,
      "conference": "MVC",
      "rank": 120,
      "record": "17-13"
    },
    "Indiana": {
      "adjDef": 98.2,
      "adjDefRank": 76,
      "adjEM": 11,
      "adjOff": 109.2,
      "adjOffRank": 68,
      "adjTempo": 61.7,
      "adjTempoRank": 304,
      "conference": "B10",
      "rank": 65,
      "record": "15-14"
    },
    "Indiana St.": {
      "adjDef": 97.1,
      "adjDefRank": 60,
      "adjEM": 0.24,
      "adjOff": 97.4,
      "adjOffRank": 249,
      "adjTempo": 63.4,
      "adjTempoRank": 272,
      "conference": "MVC",
      "rank": 160,
      "record": "11-20"
    },
    "Iona": {
      "adjDef": 102.5,
      "adjDefRank": 159,
      "adjEM": -2.07,
      "adjOff": 100.5,
      "adjOffRank": 209,
      "adjTempo": 67.9,
      "adjTempoRank": 98,
      "conference": "MAAC",
      "rank": 186,
      "record": "15-16"
    },
    "Iowa": {
      "adjDef": 94.7,
      "adjDefRank": 35,
      "adjEM": 17.17,
      "adjOff": 111.9,
      "adjOffRank": 40,
      "adjTempo": 68.1,
      "adjTempoRank": 87,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 27,
      "record": "21-12",
      "seed": 10
    },
    "Iowa St.": {
      "adjDef": 90.1,
      "adjDefRank": 9,
      "adjEM": 13.02,
      "adjOff": 103.1,
      "adjOffRank": 159,
      "adjTempo": 66.8,
      "adjTempoRank": 130,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 48,
      "record": "19-12",
      "seed": 9
    },
    "Jackson St.": {
      "adjDef": 104.8,
      "adjDefRank": 212,
      "adjEM": -15.24,
      "adjOff": 89.6,
      "adjOffRank": 319,
      "adjTempo": 69.6,
      "adjTempoRank": 45,
      "conference": "SWAC",
      "rank": 299,
      "record": "14-16"
    },
    "Jacksonville": {
      "adjDef": 106.5,
      "adjDefRank": 236,
      "adjEM": -13.63,
      "adjOff": 92.9,
      "adjOffRank": 300,
      "adjTempo": 71.1,
      "adjTempoRank": 21,
      "conference": "ASun",
      "rank": 289,
      "record": "16-13"
    },
    "Jacksonville St.": {
      "adjDef": 113.3,
      "adjDefRank": 319,
      "adjEM": -13.41,
      "adjOff": 99.9,
      "adjOffRank": 218,
      "adjTempo": 66.5,
      "adjTempoRank": 144,
      "conference": "OVC",
      "rank": 286,
      "record": "7-22"
    },
    "James Madison": {
      "adjDef": 110.6,
      "adjDefRank": 298,
      "adjEM": -18.32,
      "adjOff": 92.2,
      "adjOffRank": 304,
      "adjTempo": 64.7,
      "adjTempoRank": 228,
      "conference": "CAA",
      "rank": 312,
      "record": "6-22"
    },
    "Kansas": {
      "adjDef": 92.5,
      "adjDefRank": 18,
      "adjEM": 23.08,
      "adjOff": 115.6,
      "adjOffRank": 15,
      "adjTempo": 66.5,
      "adjTempoRank": 147,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 11,
      "record": "23-7",
      "seed": 3
    },
    "Kansas St.": {
      "adjDef": 99.8,
      "adjDefRank": 99,
      "adjEM": 10.14,
      "adjOff": 109.9,
      "adjOffRank": 54,
      "adjTempo": 63.4,
      "adjTempoRank": 275,
      "conference": "B12",
      "rank": 71,
      "record": "17-12"
    },
    "Kent St.": {
      "adjDef": 98,
      "adjDefRank": 74,
      "adjEM": 8.65,
      "adjOff": 106.6,
      "adjOffRank": 101,
      "adjTempo": 65.4,
      "adjTempoRank": 202,
      "conference": "MAC",
      "rank": 83,
      "record": "20-13"
    },
    "Kentucky": {
      "adjDef": 88.9,
      "adjDefRank": 3,
      "adjEM": 24.1,
      "adjOff": 113,
      "adjOffRank": 29,
      "adjTempo": 66.6,
      "adjTempoRank": 142,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "28-6",
      "seed": 2
    },
    "LIU Brooklyn": {
      "adjDef": 106.2,
      "adjDefRank": 232,
      "adjEM": -12.11,
      "adjOff": 94.1,
      "adjOffRank": 292,
      "adjTempo": 68.6,
      "adjTempoRank": 70,
      "conference": "NEC",
      "rank": 274,
      "record": "14-15"
    },
    "LSU": {
      "adjDef": 100.1,
      "adjDefRank": 106,
      "adjEM": 13.8,
      "adjOff": 113.9,
      "adjOffRank": 26,
      "adjTempo": 66.7,
      "adjTempoRank": 131,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 42,
      "record": "20-10",
      "seed": 6
    },
    "La Salle": {
      "adjDef": 104.7,
      "adjDefRank": 206,
      "adjEM": -5.47,
      "adjOff": 99.2,
      "adjOffRank": 225,
      "adjTempo": 63.5,
      "adjTempoRank": 269,
      "conference": "A10",
      "rank": 217,
      "record": "10-19"
    },
    "Lafayette": {
      "adjDef": 107.4,
      "adjDefRank": 249,
      "adjEM": -12.19,
      "adjOff": 95.2,
      "adjOffRank": 277,
      "adjTempo": 71.2,
      "adjTempoRank": 20,
      "conference": "Pat",
      "rank": 275,
      "record": "9-19"
    },
    "Lamar": {
      "adjDef": 103.2,
      "adjDefRank": 177,
      "adjEM": -0.7,
      "adjOff": 102.5,
      "adjOffRank": 170,
      "adjTempo": 73.8,
      "adjTempoRank": 4,
      "conference": "Slnd",
      "rank": 174,
      "record": "18-11"
    },
    "Lehigh": {
      "adjDef": 100.1,
      "adjDefRank": 105,
      "adjEM": -5.17,
      "adjOff": 94.9,
      "adjOffRank": 279,
      "adjTempo": 64.1,
      "adjTempoRank": 245,
      "conference": "Pat",
      "rank": 211,
      "record": "14-15"
    },
    "Liberty": {
      "adjDef": 108.7,
      "adjDefRank": 276,
      "adjEM": -11.42,
      "adjOff": 97.3,
      "adjOffRank": 252,
      "adjTempo": 68.6,
      "adjTempoRank": 71,
      "conference": "BSth",
      "rank": 272,
      "record": "13-15"
    },
    "Lipscomb": {
      "adjDef": 107.8,
      "adjDefRank": 262,
      "adjEM": -12.3,
      "adjOff": 95.5,
      "adjOffRank": 274,
      "adjTempo": 64.1,
      "adjTempoRank": 253,
      "conference": "ASun",
      "rank": 276,
      "record": "16-12"
    },
    "Long Beach St.": {
      "adjDef": 101.7,
      "adjDefRank": 146,
      "adjEM": -7.5,
      "adjOff": 94.2,
      "adjOffRank": 291,
      "adjTempo": 64.1,
      "adjTempoRank": 252,
      "conference": "BW",
      "rank": 235,
      "record": "10-20"
    },
    "Longwood": {
      "adjDef": 112.1,
      "adjDefRank": 310,
      "adjEM": -27.87,
      "adjOff": 84.2,
      "adjOffRank": 324,
      "adjTempo": 71,
      "adjTempoRank": 23,
      "conference": "ind",
      "rank": 328,
      "record": "1-30"
    },
    "Louisiana Lafayette": {
      "adjDef": 97.2,
      "adjDefRank": 62,
      "adjEM": 9.91,
      "adjOff": 107.1,
      "adjOffRank": 94,
      "adjTempo": 67.6,
      "adjTempoRank": 107,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 74,
      "record": "20-11",
      "seed": 13
    },
    "Louisiana Monroe": {
      "adjDef": 110,
      "adjDefRank": 294,
      "adjEM": -14.18,
      "adjOff": 95.9,
      "adjOffRank": 268,
      "adjTempo": 64.8,
      "adjTempoRank": 223,
      "conference": "Slnd",
      "rank": 293,
      "record": "8-19"
    },
    "Louisiana Tech": {
      "adjDef": 101.5,
      "adjDefRank": 137,
      "adjEM": 1.09,
      "adjOff": 102.6,
      "adjOffRank": 169,
      "adjTempo": 67.3,
      "adjTempoRank": 115,
      "conference": "WAC",
      "rank": 150,
      "record": "14-15"
    },
    "Louisville": {
      "adjDef": 93,
      "adjDefRank": 20,
      "adjEM": 25.78,
      "adjOff": 118.8,
      "adjOffRank": 8,
      "adjTempo": 66.3,
      "adjTempoRank": 154,
      "conference": "CUSA",
      "finish": "Final Four",
      "rank": 4,
      "record": "33-5",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 100.7,
      "adjDefRank": 121,
      "adjEM": -0.22,
      "adjOff": 100.5,
      "adjOffRank": 210,
      "adjTempo": 67.4,
      "adjTempoRank": 113,
      "conference": "Horz",
      "rank": 170,
      "record": "13-17"
    },
    "Loyola MD": {
      "adjDef": 107.5,
      "adjDefRank": 252,
      "adjEM": -16.88,
      "adjOff": 90.6,
      "adjOffRank": 314,
      "adjTempo": 69.7,
      "adjTempoRank": 43,
      "conference": "MAAC",
      "rank": 306,
      "record": "6-22"
    },
    "Loyola Marymount": {
      "adjDef": 98.2,
      "adjDefRank": 77,
      "adjEM": 0.54,
      "adjOff": 98.8,
      "adjOffRank": 231,
      "adjTempo": 67.7,
      "adjTempoRank": 101,
      "conference": "WCC",
      "rank": 153,
      "record": "11-17"
    },
    "Maine": {
      "adjDef": 99.5,
      "adjDefRank": 92,
      "adjEM": -5.78,
      "adjOff": 93.8,
      "adjOffRank": 295,
      "adjTempo": 67.1,
      "adjTempoRank": 122,
      "conference": "AE",
      "rank": 219,
      "record": "14-15"
    },
    "Manhattan": {
      "adjDef": 103.3,
      "adjDefRank": 179,
      "adjEM": 0.64,
      "adjOff": 103.9,
      "adjOffRank": 146,
      "adjTempo": 65.8,
      "adjTempoRank": 182,
      "conference": "MAAC",
      "rank": 151,
      "record": "15-14"
    },
    "Marist": {
      "adjDef": 108.6,
      "adjDefRank": 273,
      "adjEM": -7.17,
      "adjOff": 101.4,
      "adjOffRank": 197,
      "adjTempo": 66.1,
      "adjTempoRank": 165,
      "conference": "MAAC",
      "rank": 231,
      "record": "11-17"
    },
    "Marquette": {
      "adjDef": 103,
      "adjDefRank": 171,
      "adjEM": 6.57,
      "adjOff": 109.6,
      "adjOffRank": 62,
      "adjTempo": 61.2,
      "adjTempoRank": 309,
      "conference": "CUSA",
      "rank": 98,
      "record": "19-12"
    },
    "Marshall": {
      "adjDef": 107.8,
      "adjDefRank": 261,
      "adjEM": -3.8,
      "adjOff": 104,
      "adjOffRank": 145,
      "adjTempo": 65.3,
      "adjTempoRank": 206,
      "conference": "MAC",
      "rank": 202,
      "record": "6-22"
    },
    "Maryland": {
      "adjDef": 94.7,
      "adjDefRank": 32,
      "adjEM": 14.62,
      "adjOff": 109.3,
      "adjOffRank": 64,
      "adjTempo": 74.5,
      "adjTempoRank": 2,
      "conference": "ACC",
      "rank": 37,
      "record": "19-13"
    },
    "Maryland Eastern Shore": {
      "adjDef": 116.8,
      "adjDefRank": 330,
      "adjEM": -37.94,
      "adjOff": 78.9,
      "adjOffRank": 329,
      "adjTempo": 70.2,
      "adjTempoRank": 36,
      "conference": "MEAC",
      "rank": 330,
      "record": "2-26"
    },
    "Massachusetts": {
      "adjDef": 100.6,
      "adjDefRank": 120,
      "adjEM": -1.56,
      "adjOff": 99,
      "adjOffRank": 227,
      "adjTempo": 63.6,
      "adjTempoRank": 266,
      "conference": "A10",
      "rank": 180,
      "record": "16-12"
    },
    "McNeese St.": {
      "adjDef": 104.5,
      "adjDefRank": 200,
      "adjEM": -6.89,
      "adjOff": 97.6,
      "adjOffRank": 245,
      "adjTempo": 74,
      "adjTempoRank": 3,
      "conference": "Slnd",
      "rank": 228,
      "record": "13-15"
    },
    "Memphis": {
      "adjDef": 95.7,
      "adjDefRank": 42,
      "adjEM": 12.17,
      "adjOff": 107.8,
      "adjOffRank": 79,
      "adjTempo": 66.9,
      "adjTempoRank": 129,
      "conference": "CUSA",
      "rank": 53,
      "record": "22-16"
    },
    "Mercer": {
      "adjDef": 110.3,
      "adjDefRank": 295,
      "adjEM": -8.26,
      "adjOff": 102.1,
      "adjOffRank": 182,
      "adjTempo": 71.2,
      "adjTempoRank": 19,
      "conference": "ASun",
      "rank": 244,
      "record": "16-12"
    },
    "Miami FL": {
      "adjDef": 100.5,
      "adjDefRank": 118,
      "adjEM": 10.83,
      "adjOff": 111.4,
      "adjOffRank": 45,
      "adjTempo": 64.4,
      "adjTempoRank": 237,
      "conference": "ACC",
      "rank": 67,
      "record": "16-13"
    },
    "Miami OH": {
      "adjDef": 94.9,
      "adjDefRank": 37,
      "adjEM": 11.08,
      "adjOff": 106,
      "adjOffRank": 110,
      "adjTempo": 60.7,
      "adjTempoRank": 314,
      "conference": "MAC",
      "rank": 64,
      "record": "19-11"
    },
    "Michigan": {
      "adjDef": 99.8,
      "adjDefRank": 100,
      "adjEM": 3.68,
      "adjOff": 103.5,
      "adjOffRank": 152,
      "adjTempo": 62.2,
      "adjTempoRank": 299,
      "conference": "B10",
      "rank": 128,
      "record": "13-18"
    },
    "Michigan St.": {
      "adjDef": 94,
      "adjDefRank": 28,
      "adjEM": 25.67,
      "adjOff": 119.7,
      "adjOffRank": 6,
      "adjTempo": 66.4,
      "adjTempoRank": 150,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 5,
      "record": "26-7",
      "seed": 5
    },
    "Middle Tennessee": {
      "adjDef": 100.2,
      "adjDefRank": 109,
      "adjEM": 4.53,
      "adjOff": 104.8,
      "adjOffRank": 130,
      "adjTempo": 65.1,
      "adjTempoRank": 209,
      "conference": "SB",
      "rank": 116,
      "record": "19-12"
    },
    "Milwaukee": {
      "adjDef": 95.4,
      "adjDefRank": 38,
      "adjEM": 14.69,
      "adjOff": 110.1,
      "adjOffRank": 52,
      "adjTempo": 68.8,
      "adjTempoRank": 59,
      "conference": "Horz",
      "finish": "Sweet 16",
      "rank": 36,
      "record": "26-6",
      "seed": 12
    },
    "Minnesota": {
      "adjDef": 89.9,
      "adjDefRank": 7,
      "adjEM": 14.06,
      "adjOff": 104,
      "adjOffRank": 143,
      "adjTempo": 67.3,
      "adjTempoRank": 114,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 41,
      "record": "21-11",
      "seed": 8
    },
    "Mississippi": {
      "adjDef": 97.8,
      "adjDefRank": 71,
      "adjEM": 4.99,
      "adjOff": 102.8,
      "adjOffRank": 163,
      "adjTempo": 63.4,
      "adjTempoRank": 276,
      "conference": "SEC",
      "rank": 107,
      "record": "14-17"
    },
    "Mississippi St.": {
      "adjDef": 94,
      "adjDefRank": 27,
      "adjEM": 15.25,
      "adjOff": 109.3,
      "adjOffRank": 65,
      "adjTempo": 68.1,
      "adjTempoRank": 88,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 34,
      "record": "23-11",
      "seed": 9
    },
    "Mississippi Valley St.": {
      "adjDef": 107.3,
      "adjDefRank": 248,
      "adjEM": -9.48,
      "adjOff": 97.8,
      "adjOffRank": 244,
      "adjTempo": 65.4,
      "adjTempoRank": 204,
      "conference": "SWAC",
      "rank": 259,
      "record": "13-14"
    },
    "Missouri": {
      "adjDef": 97.8,
      "adjDefRank": 70,
      "adjEM": 6.37,
      "adjOff": 104.2,
      "adjOffRank": 141,
      "adjTempo": 64.1,
      "adjTempoRank": 246,
      "conference": "B12",
      "rank": 101,
      "record": "16-17"
    },
    "Monmouth": {
      "adjDef": 103.5,
      "adjDefRank": 185,
      "adjEM": -5.42,
      "adjOff": 98.1,
      "adjOffRank": 236,
      "adjTempo": 62.2,
      "adjTempoRank": 298,
      "conference": "NEC",
      "rank": 215,
      "record": "16-13"
    },
    "Montana": {
      "adjDef": 102.6,
      "adjDefRank": 160,
      "adjEM": -2.73,
      "adjOff": 99.9,
      "adjOffRank": 219,
      "adjTempo": 68.2,
      "adjTempoRank": 86,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 194,
      "record": "18-13",
      "seed": 16
    },
    "Montana St.": {
      "adjDef": 103,
      "adjDefRank": 173,
      "adjEM": -7.67,
      "adjOff": 95.4,
      "adjOffRank": 276,
      "adjTempo": 67.9,
      "adjTempoRank": 94,
      "conference": "BSky",
      "rank": 237,
      "record": "14-14"
    },
    "Morehead St.": {
      "adjDef": 110,
      "adjDefRank": 293,
      "adjEM": -14.26,
      "adjOff": 95.7,
      "adjOffRank": 270,
      "adjTempo": 64.9,
      "adjTempoRank": 221,
      "conference": "OVC",
      "rank": 294,
      "record": "11-16"
    },
    "Morgan St.": {
      "adjDef": 106.3,
      "adjDefRank": 234,
      "adjEM": -14.36,
      "adjOff": 92,
      "adjOffRank": 308,
      "adjTempo": 67.7,
      "adjTempoRank": 103,
      "conference": "MEAC",
      "rank": 297,
      "record": "14-15"
    },
    "Mount St. Mary's": {
      "adjDef": 112.2,
      "adjDefRank": 314,
      "adjEM": -17.48,
      "adjOff": 94.7,
      "adjOffRank": 281,
      "adjTempo": 64.8,
      "adjTempoRank": 224,
      "conference": "NEC",
      "rank": 309,
      "record": "7-20"
    },
    "Murray St.": {
      "adjDef": 103.9,
      "adjDefRank": 193,
      "adjEM": 4.69,
      "adjOff": 108.6,
      "adjOffRank": 71,
      "adjTempo": 63.4,
      "adjTempoRank": 273,
      "conference": "OVC",
      "rank": 112,
      "record": "17-11"
    },
    "N.C. State": {
      "adjDef": 96.1,
      "adjDefRank": 47,
      "adjEM": 18.93,
      "adjOff": 115,
      "adjOffRank": 17,
      "adjTempo": 63.1,
      "adjTempoRank": 282,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 23,
      "record": "21-14",
      "seed": 10
    },
    "Navy": {
      "adjDef": 107.6,
      "adjDefRank": 257,
      "adjEM": -12.94,
      "adjOff": 94.7,
      "adjOffRank": 283,
      "adjTempo": 73.4,
      "adjTempoRank": 5,
      "conference": "Pat",
      "rank": 284,
      "record": "9-19"
    },
    "Nebraska": {
      "adjDef": 96.1,
      "adjDefRank": 48,
      "adjEM": 9.13,
      "adjOff": 105.2,
      "adjOffRank": 122,
      "adjTempo": 64.9,
      "adjTempoRank": 218,
      "conference": "B12",
      "rank": 80,
      "record": "14-14"
    },
    "Nevada": {
      "adjDef": 91.8,
      "adjDefRank": 13,
      "adjEM": 12.11,
      "adjOff": 104,
      "adjOffRank": 144,
      "adjTempo": 65.7,
      "adjTempoRank": 189,
      "conference": "WAC",
      "finish": "Round of 32",
      "rank": 55,
      "record": "25-7",
      "seed": 9
    },
    "New Hampshire": {
      "adjDef": 108.3,
      "adjDefRank": 267,
      "adjEM": -10.5,
      "adjOff": 97.8,
      "adjOffRank": 242,
      "adjTempo": 66.4,
      "adjTempoRank": 149,
      "conference": "AE",
      "rank": 269,
      "record": "9-19"
    },
    "New Mexico": {
      "adjDef": 97.3,
      "adjDefRank": 63,
      "adjEM": 15.54,
      "adjOff": 112.8,
      "adjOffRank": 30,
      "adjTempo": 65.4,
      "adjTempoRank": 196,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 31,
      "record": "26-7",
      "seed": 12
    },
    "New Mexico St.": {
      "adjDef": 108.4,
      "adjDefRank": 270,
      "adjEM": -11.3,
      "adjOff": 97.1,
      "adjOffRank": 254,
      "adjTempo": 67.9,
      "adjTempoRank": 93,
      "conference": "SB",
      "rank": 271,
      "record": "6-24"
    },
    "New Orleans": {
      "adjDef": 105,
      "adjDefRank": 217,
      "adjEM": -1.94,
      "adjOff": 103.1,
      "adjOffRank": 161,
      "adjTempo": 64.2,
      "adjTempoRank": 241,
      "conference": "SB",
      "rank": 184,
      "record": "13-17"
    },
    "Niagara": {
      "adjDef": 109.8,
      "adjDefRank": 289,
      "adjEM": 5.43,
      "adjOff": 115.2,
      "adjOffRank": 16,
      "adjTempo": 70.1,
      "adjTempoRank": 38,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 106,
      "record": "20-10",
      "seed": 14
    },
    "Nicholls St.": {
      "adjDef": 114.3,
      "adjDefRank": 323,
      "adjEM": -18.63,
      "adjOff": 95.6,
      "adjOffRank": 271,
      "adjTempo": 70.8,
      "adjTempoRank": 26,
      "conference": "Slnd",
      "rank": 317,
      "record": "6-21"
    },
    "Norfolk St.": {
      "adjDef": 102.8,
      "adjDefRank": 164,
      "adjEM": -11.23,
      "adjOff": 91.6,
      "adjOffRank": 309,
      "adjTempo": 63.8,
      "adjTempoRank": 259,
      "conference": "MEAC",
      "rank": 270,
      "record": "13-14"
    },
    "North Carolina": {
      "adjDef": 89.7,
      "adjDefRank": 5,
      "adjEM": 32.77,
      "adjOff": 122.5,
      "adjOffRank": 2,
      "adjTempo": 73.1,
      "adjTempoRank": 7,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 1,
      "record": "33-4",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 103.6,
      "adjDefRank": 186,
      "adjEM": -20.49,
      "adjOff": 83.1,
      "adjOffRank": 326,
      "adjTempo": 73.2,
      "adjTempoRank": 6,
      "conference": "MEAC",
      "rank": 320,
      "record": "6-23"
    },
    "North Texas": {
      "adjDef": 105.6,
      "adjDefRank": 228,
      "adjEM": -4.73,
      "adjOff": 100.9,
      "adjOffRank": 206,
      "adjTempo": 71.1,
      "adjTempoRank": 22,
      "conference": "SB",
      "rank": 207,
      "record": "14-14"
    },
    "Northeastern": {
      "adjDef": 101.6,
      "adjDefRank": 144,
      "adjEM": 4.15,
      "adjOff": 105.8,
      "adjOffRank": 116,
      "adjTempo": 71.4,
      "adjTempoRank": 16,
      "conference": "AE",
      "rank": 119,
      "record": "21-10"
    },
    "Northern Arizona": {
      "adjDef": 109.5,
      "adjDefRank": 285,
      "adjEM": -7.17,
      "adjOff": 102.3,
      "adjOffRank": 173,
      "adjTempo": 71.7,
      "adjTempoRank": 15,
      "conference": "BSky",
      "rank": 233,
      "record": "11-17"
    },
    "Northern Colorado": {
      "adjDef": 113,
      "adjDefRank": 318,
      "adjEM": -16.97,
      "adjOff": 96,
      "adjOffRank": 263,
      "adjTempo": 67.1,
      "adjTempoRank": 117,
      "conference": "ind",
      "rank": 307,
      "record": "8-21"
    },
    "Northern Illinois": {
      "adjDef": 105.5,
      "adjDefRank": 224,
      "adjEM": 2.84,
      "adjOff": 108.3,
      "adjOffRank": 73,
      "adjTempo": 62.5,
      "adjTempoRank": 296,
      "conference": "MAC",
      "rank": 137,
      "record": "11-17"
    },
    "Northern Iowa": {
      "adjDef": 98.9,
      "adjDefRank": 85,
      "adjEM": 13.36,
      "adjOff": 112.2,
      "adjOffRank": 37,
      "adjTempo": 64.5,
      "adjTempoRank": 233,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 45,
      "record": "21-11",
      "seed": 11
    },
    "Northwestern": {
      "adjDef": 97.9,
      "adjDefRank": 73,
      "adjEM": 3.81,
      "adjOff": 101.8,
      "adjOffRank": 188,
      "adjTempo": 60.6,
      "adjTempoRank": 315,
      "conference": "B10",
      "rank": 124,
      "record": "15-16"
    },
    "Northwestern St.": {
      "adjDef": 104.1,
      "adjDefRank": 194,
      "adjEM": -0.36,
      "adjOff": 103.7,
      "adjOffRank": 148,
      "adjTempo": 67.9,
      "adjTempoRank": 97,
      "conference": "Slnd",
      "rank": 171,
      "record": "21-12"
    },
    "Notre Dame": {
      "adjDef": 98.3,
      "adjDefRank": 79,
      "adjEM": 11.54,
      "adjOff": 109.9,
      "adjOffRank": 57,
      "adjTempo": 64.3,
      "adjTempoRank": 239,
      "conference": "BE",
      "rank": 59,
      "record": "17-12"
    },
    "Oakland": {
      "adjDef": 109.3,
      "adjDefRank": 282,
      "adjEM": -1.2,
      "adjOff": 108.1,
      "adjOffRank": 75,
      "adjTempo": 65.2,
      "adjTempoRank": 208,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 178,
      "record": "13-19",
      "seed": 16
    },
    "Ohio": {
      "adjDef": 101.5,
      "adjDefRank": 138,
      "adjEM": 8.29,
      "adjOff": 109.8,
      "adjOffRank": 58,
      "adjTempo": 65.7,
      "adjTempoRank": 187,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 85,
      "record": "21-11",
      "seed": 13
    },
    "Ohio St.": {
      "adjDef": 93.9,
      "adjDefRank": 23,
      "adjEM": 15.82,
      "adjOff": 109.7,
      "adjOffRank": 59,
      "adjTempo": 65.5,
      "adjTempoRank": 194,
      "conference": "B10",
      "rank": 30,
      "record": "20-12"
    },
    "Oklahoma": {
      "adjDef": 91.8,
      "adjDefRank": 12,
      "adjEM": 22.55,
      "adjOff": 114.4,
      "adjOffRank": 21,
      "adjTempo": 65.4,
      "adjTempoRank": 201,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 12,
      "record": "25-8",
      "seed": 3
    },
    "Oklahoma St.": {
      "adjDef": 96.2,
      "adjDefRank": 50,
      "adjEM": 25.02,
      "adjOff": 121.2,
      "adjOffRank": 4,
      "adjTempo": 66.2,
      "adjTempoRank": 160,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 6,
      "record": "26-7",
      "seed": 2
    },
    "Old Dominion": {
      "adjDef": 94.6,
      "adjDefRank": 31,
      "adjEM": 10.04,
      "adjOff": 104.7,
      "adjOffRank": 132,
      "adjTempo": 66.4,
      "adjTempoRank": 151,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 73,
      "record": "28-6",
      "seed": 12
    },
    "Oral Roberts": {
      "adjDef": 97.1,
      "adjDefRank": 59,
      "adjEM": 9.88,
      "adjOff": 107,
      "adjOffRank": 96,
      "adjTempo": 69.7,
      "adjTempoRank": 42,
      "conference": "MCon",
      "rank": 75,
      "record": "25-8"
    },
    "Oregon": {
      "adjDef": 100.3,
      "adjDefRank": 111,
      "adjEM": 6.73,
      "adjOff": 107,
      "adjOffRank": 95,
      "adjTempo": 66.3,
      "adjTempoRank": 157,
      "conference": "P10",
      "rank": 93,
      "record": "14-13"
    },
    "Oregon St.": {
      "adjDef": 102.9,
      "adjDefRank": 167,
      "adjEM": 4.1,
      "adjOff": 107,
      "adjOffRank": 97,
      "adjTempo": 68.2,
      "adjTempoRank": 82,
      "conference": "P10",
      "rank": 121,
      "record": "17-15"
    },
    "Pacific": {
      "adjDef": 100.5,
      "adjDefRank": 115,
      "adjEM": 14.08,
      "adjOff": 114.5,
      "adjOffRank": 20,
      "adjTempo": 63.6,
      "adjTempoRank": 263,
      "conference": "BW",
      "finish": "Round of 32",
      "rank": 40,
      "record": "27-4",
      "seed": 8
    },
    "Penn": {
      "adjDef": 92.5,
      "adjDefRank": 17,
      "adjEM": 9.6,
      "adjOff": 102.1,
      "adjOffRank": 181,
      "adjTempo": 68.2,
      "adjTempoRank": 83,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 77,
      "record": "20-9",
      "seed": 13
    },
    "Penn St.": {
      "adjDef": 106.6,
      "adjDefRank": 238,
      "adjEM": -5.44,
      "adjOff": 101.2,
      "adjOffRank": 201,
      "adjTempo": 65.9,
      "adjTempoRank": 175,
      "conference": "B10",
      "rank": 216,
      "record": "7-23"
    },
    "Pepperdine": {
      "adjDef": 104.7,
      "adjDefRank": 207,
      "adjEM": 3.12,
      "adjOff": 107.8,
      "adjOffRank": 77,
      "adjTempo": 69,
      "adjTempoRank": 52,
      "conference": "WCC",
      "rank": 135,
      "record": "17-14"
    },
    "Pittsburgh": {
      "adjDef": 96.3,
      "adjDefRank": 51,
      "adjEM": 18.41,
      "adjOff": 114.8,
      "adjOffRank": 18,
      "adjTempo": 64,
      "adjTempoRank": 254,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 24,
      "record": "20-9",
      "seed": 9
    },
    "Portland": {
      "adjDef": 103.3,
      "adjDefRank": 181,
      "adjEM": 1.54,
      "adjOff": 104.9,
      "adjOffRank": 128,
      "adjTempo": 65.3,
      "adjTempoRank": 205,
      "conference": "WCC",
      "rank": 147,
      "record": "15-15"
    },
    "Portland St.": {
      "adjDef": 104.4,
      "adjDefRank": 199,
      "adjEM": -5.2,
      "adjOff": 99.2,
      "adjOffRank": 224,
      "adjTempo": 68.8,
      "adjTempoRank": 61,
      "conference": "BSky",
      "rank": 213,
      "record": "19-9"
    },
    "Prairie View A&M": {
      "adjDef": 107.5,
      "adjDefRank": 251,
      "adjEM": -26.71,
      "adjOff": 80.7,
      "adjOffRank": 327,
      "adjTempo": 67.9,
      "adjTempoRank": 96,
      "conference": "SWAC",
      "rank": 326,
      "record": "5-23"
    },
    "Princeton": {
      "adjDef": 103.1,
      "adjDefRank": 174,
      "adjEM": 2.06,
      "adjOff": 105.2,
      "adjOffRank": 124,
      "adjTempo": 52.9,
      "adjTempoRank": 330,
      "conference": "Ivy",
      "rank": 142,
      "record": "15-13"
    },
    "Providence": {
      "adjDef": 99.8,
      "adjDefRank": 98,
      "adjEM": 11.36,
      "adjOff": 111.2,
      "adjOffRank": 47,
      "adjTempo": 68.7,
      "adjTempoRank": 65,
      "conference": "BE",
      "rank": 61,
      "record": "14-17"
    },
    "Purdue": {
      "adjDef": 101.9,
      "adjDefRank": 151,
      "adjEM": 3.97,
      "adjOff": 105.9,
      "adjOffRank": 114,
      "adjTempo": 63.3,
      "adjTempoRank": 277,
      "conference": "B10",
      "rank": 123,
      "record": "7-21"
    },
    "Quinnipiac": {
      "adjDef": 114.5,
      "adjDefRank": 324,
      "adjEM": -14.34,
      "adjOff": 100.1,
      "adjOffRank": 214,
      "adjTempo": 69.9,
      "adjTempoRank": 39,
      "conference": "NEC",
      "rank": 296,
      "record": "10-17"
    },
    "Radford": {
      "adjDef": 112.1,
      "adjDefRank": 311,
      "adjEM": -14,
      "adjOff": 98.1,
      "adjOffRank": 237,
      "adjTempo": 65.6,
      "adjTempoRank": 190,
      "conference": "BSth",
      "rank": 291,
      "record": "12-16"
    },
    "Rhode Island": {
      "adjDef": 104.4,
      "adjDefRank": 198,
      "adjEM": -8.91,
      "adjOff": 95.5,
      "adjOffRank": 273,
      "adjTempo": 62,
      "adjTempoRank": 301,
      "conference": "A10",
      "rank": 251,
      "record": "6-22"
    },
    "Rice": {
      "adjDef": 100.1,
      "adjDefRank": 107,
      "adjEM": 7.02,
      "adjOff": 107.2,
      "adjOffRank": 92,
      "adjTempo": 66.9,
      "adjTempoRank": 128,
      "conference": "WAC",
      "rank": 91,
      "record": "19-12"
    },
    "Richmond": {
      "adjDef": 103.8,
      "adjDefRank": 189,
      "adjEM": 0.34,
      "adjOff": 104.1,
      "adjOffRank": 142,
      "adjTempo": 65.9,
      "adjTempoRank": 171,
      "conference": "A10",
      "rank": 159,
      "record": "14-15"
    },
    "Rider": {
      "adjDef": 107.2,
      "adjDefRank": 246,
      "adjEM": 0.08,
      "adjOff": 107.3,
      "adjOffRank": 89,
      "adjTempo": 66.2,
      "adjTempoRank": 164,
      "conference": "MAAC",
      "rank": 164,
      "record": "19-11"
    },
    "Robert Morris": {
      "adjDef": 112.8,
      "adjDefRank": 317,
      "adjEM": -9.65,
      "adjOff": 103.1,
      "adjOffRank": 158,
      "adjTempo": 65.4,
      "adjTempoRank": 203,
      "conference": "NEC",
      "rank": 260,
      "record": "14-15"
    },
    "Rutgers": {
      "adjDef": 104.5,
      "adjDefRank": 201,
      "adjEM": 2.1,
      "adjOff": 106.6,
      "adjOffRank": 102,
      "adjTempo": 63.9,
      "adjTempoRank": 257,
      "conference": "BE",
      "rank": 141,
      "record": "10-19"
    },
    "SMU": {
      "adjDef": 101.2,
      "adjDefRank": 133,
      "adjEM": 3.61,
      "adjOff": 104.8,
      "adjOffRank": 129,
      "adjTempo": 63.8,
      "adjTempoRank": 260,
      "conference": "WAC",
      "rank": 130,
      "record": "14-14"
    },
    "Sacramento St.": {
      "adjDef": 108,
      "adjDefRank": 264,
      "adjEM": -12.53,
      "adjOff": 95.4,
      "adjOffRank": 275,
      "adjTempo": 70.3,
      "adjTempoRank": 34,
      "conference": "BSky",
      "rank": 279,
      "record": "12-16"
    },
    "Sacred Heart": {
      "adjDef": 108.8,
      "adjDefRank": 278,
      "adjEM": -18.48,
      "adjOff": 90.3,
      "adjOffRank": 318,
      "adjTempo": 68.8,
      "adjTempoRank": 62,
      "conference": "NEC",
      "rank": 315,
      "record": "4-23"
    },
    "Saint Joseph's": {
      "adjDef": 93.9,
      "adjDefRank": 26,
      "adjEM": 13.01,
      "adjOff": 106.9,
      "adjOffRank": 98,
      "adjTempo": 61.6,
      "adjTempoRank": 306,
      "conference": "A10",
      "rank": 49,
      "record": "24-12"
    },
    "Saint Louis": {
      "adjDef": 94.7,
      "adjDefRank": 34,
      "adjEM": 0.01,
      "adjOff": 94.7,
      "adjOffRank": 282,
      "adjTempo": 59.8,
      "adjTempoRank": 319,
      "conference": "CUSA",
      "rank": 166,
      "record": "9-21"
    },
    "Saint Mary's": {
      "adjDef": 97.4,
      "adjDefRank": 64,
      "adjEM": 9.41,
      "adjOff": 106.8,
      "adjOffRank": 100,
      "adjTempo": 64.9,
      "adjTempoRank": 220,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 78,
      "record": "25-9",
      "seed": 10
    },
    "Saint Peter's": {
      "adjDef": 105.6,
      "adjDefRank": 227,
      "adjEM": -1.32,
      "adjOff": 104.2,
      "adjOffRank": 140,
      "adjTempo": 72.8,
      "adjTempoRank": 11,
      "conference": "MAAC",
      "rank": 179,
      "record": "15-13"
    },
    "Sam Houston St.": {
      "adjDef": 97.5,
      "adjDefRank": 66,
      "adjEM": -0.06,
      "adjOff": 97.4,
      "adjOffRank": 248,
      "adjTempo": 71.3,
      "adjTempoRank": 17,
      "conference": "Slnd",
      "rank": 167,
      "record": "18-12"
    },
    "Samford": {
      "adjDef": 112.3,
      "adjDefRank": 315,
      "adjEM": -2.44,
      "adjOff": 109.9,
      "adjOffRank": 55,
      "adjTempo": 56,
      "adjTempoRank": 328,
      "conference": "OVC",
      "rank": 189,
      "record": "15-13"
    },
    "San Diego": {
      "adjDef": 103.4,
      "adjDefRank": 182,
      "adjEM": 4.25,
      "adjOff": 107.6,
      "adjOffRank": 82,
      "adjTempo": 70.5,
      "adjTempoRank": 31,
      "conference": "WCC",
      "rank": 118,
      "record": "16-13"
    },
    "San Diego St.": {
      "adjDef": 105.4,
      "adjDefRank": 223,
      "adjEM": 0.53,
      "adjOff": 105.9,
      "adjOffRank": 113,
      "adjTempo": 66.4,
      "adjTempoRank": 152,
      "conference": "MWC",
      "rank": 154,
      "record": "11-18"
    },
    "San Francisco": {
      "adjDef": 97.8,
      "adjDefRank": 69,
      "adjEM": 3.81,
      "adjOff": 101.6,
      "adjOffRank": 192,
      "adjTempo": 65.8,
      "adjTempoRank": 184,
      "conference": "WCC",
      "rank": 125,
      "record": "17-14"
    },
    "San Jose St.": {
      "adjDef": 107.4,
      "adjDefRank": 250,
      "adjEM": -12.57,
      "adjOff": 94.9,
      "adjOffRank": 280,
      "adjTempo": 64.4,
      "adjTempoRank": 236,
      "conference": "WAC",
      "rank": 280,
      "record": "6-23"
    },
    "Santa Clara": {
      "adjDef": 102.7,
      "adjDefRank": 162,
      "adjEM": 3.65,
      "adjOff": 106.4,
      "adjOffRank": 106,
      "adjTempo": 68.8,
      "adjTempoRank": 60,
      "conference": "WCC",
      "rank": 129,
      "record": "15-16"
    },
    "Savannah St.": {
      "adjDef": 115.8,
      "adjDefRank": 329,
      "adjEM": -37.19,
      "adjOff": 78.6,
      "adjOffRank": 330,
      "adjTempo": 70.6,
      "adjTempoRank": 30,
      "conference": "ind",
      "rank": 329,
      "record": "0-28"
    },
    "Seton Hall": {
      "adjDef": 95.4,
      "adjDefRank": 39,
      "adjEM": 4.96,
      "adjOff": 100.4,
      "adjOffRank": 212,
      "adjTempo": 64.2,
      "adjTempoRank": 242,
      "conference": "BE",
      "rank": 109,
      "record": "12-16"
    },
    "Siena": {
      "adjDef": 106.1,
      "adjDefRank": 231,
      "adjEM": -13.22,
      "adjOff": 92.9,
      "adjOffRank": 298,
      "adjTempo": 66.1,
      "adjTempoRank": 168,
      "conference": "MAAC",
      "rank": 285,
      "record": "6-24"
    },
    "South Alabama": {
      "adjDef": 102.3,
      "adjDefRank": 155,
      "adjEM": -6.2,
      "adjOff": 96.1,
      "adjOffRank": 262,
      "adjTempo": 63.4,
      "adjTempoRank": 270,
      "conference": "SB",
      "rank": 222,
      "record": "10-18"
    },
    "South Carolina": {
      "adjDef": 97.5,
      "adjDefRank": 67,
      "adjEM": 12.73,
      "adjOff": 110.2,
      "adjOffRank": 51,
      "adjTempo": 64,
      "adjTempoRank": 255,
      "conference": "SEC",
      "rank": 50,
      "record": "20-13"
    },
    "South Carolina St.": {
      "adjDef": 101.8,
      "adjDefRank": 149,
      "adjEM": -3.02,
      "adjOff": 98.8,
      "adjOffRank": 230,
      "adjTempo": 65,
      "adjTempoRank": 216,
      "conference": "MEAC",
      "rank": 198,
      "record": "18-12"
    },
    "South Florida": {
      "adjDef": 99.9,
      "adjDefRank": 102,
      "adjEM": 3.33,
      "adjOff": 103.2,
      "adjOffRank": 156,
      "adjTempo": 66.6,
      "adjTempoRank": 140,
      "conference": "CUSA",
      "rank": 132,
      "record": "14-16"
    },
    "Southeast Missouri St.": {
      "adjDef": 103.4,
      "adjDefRank": 184,
      "adjEM": 0.21,
      "adjOff": 103.7,
      "adjOffRank": 149,
      "adjTempo": 64.5,
      "adjTempoRank": 232,
      "conference": "OVC",
      "rank": 162,
      "record": "15-14"
    },
    "Southeastern Louisiana": {
      "adjDef": 93.9,
      "adjDefRank": 25,
      "adjEM": 3.2,
      "adjOff": 97.1,
      "adjOffRank": 255,
      "adjTempo": 60,
      "adjTempoRank": 318,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 134,
      "record": "24-9",
      "seed": 15
    },
    "Southern": {
      "adjDef": 107.1,
      "adjDefRank": 243,
      "adjEM": -16.32,
      "adjOff": 90.8,
      "adjOffRank": 311,
      "adjTempo": 67.9,
      "adjTempoRank": 95,
      "conference": "SWAC",
      "rank": 305,
      "record": "12-14"
    },
    "Southern Illinois": {
      "adjDef": 92.4,
      "adjDefRank": 15,
      "adjEM": 16.75,
      "adjOff": 109.1,
      "adjOffRank": 69,
      "adjTempo": 63.4,
      "adjTempoRank": 271,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 29,
      "record": "27-8",
      "seed": 7
    },
    "Southern Miss": {
      "adjDef": 109.8,
      "adjDefRank": 291,
      "adjEM": -7.08,
      "adjOff": 102.7,
      "adjOffRank": 165,
      "adjTempo": 64.8,
      "adjTempoRank": 225,
      "conference": "CUSA",
      "rank": 230,
      "record": "11-17"
    },
    "Southern Utah": {
      "adjDef": 111.8,
      "adjDefRank": 307,
      "adjEM": -10.44,
      "adjOff": 101.3,
      "adjOffRank": 198,
      "adjTempo": 65.7,
      "adjTempoRank": 185,
      "conference": "MCon",
      "rank": 267,
      "record": "13-15"
    },
    "Southwest Missouri St.": {
      "adjDef": 96.8,
      "adjDefRank": 57,
      "adjEM": 11.42,
      "adjOff": 108.2,
      "adjOffRank": 74,
      "adjTempo": 68.8,
      "adjTempoRank": 63,
      "conference": "MVC",
      "rank": 60,
      "record": "19-13"
    },
    "St. Bonaventure": {
      "adjDef": 113.9,
      "adjDefRank": 321,
      "adjEM": -21.17,
      "adjOff": 92.7,
      "adjOffRank": 301,
      "adjTempo": 68,
      "adjTempoRank": 90,
      "conference": "A10",
      "rank": 321,
      "record": "2-26"
    },
    "St. Francis NY": {
      "adjDef": 105,
      "adjDefRank": 216,
      "adjEM": -8.2,
      "adjOff": 96.8,
      "adjOffRank": 258,
      "adjTempo": 69.7,
      "adjTempoRank": 41,
      "conference": "NEC",
      "rank": 243,
      "record": "13-15"
    },
    "St. Francis PA": {
      "adjDef": 113.9,
      "adjDefRank": 322,
      "adjEM": -9.47,
      "adjOff": 104.5,
      "adjOffRank": 137,
      "adjTempo": 64.1,
      "adjTempoRank": 250,
      "conference": "NEC",
      "rank": 258,
      "record": "15-13"
    },
    "St. John's": {
      "adjDef": 96.1,
      "adjDefRank": 49,
      "adjEM": 5.46,
      "adjOff": 101.6,
      "adjOffRank": 193,
      "adjTempo": 66.3,
      "adjTempoRank": 153,
      "conference": "BE",
      "rank": 105,
      "record": "9-18"
    },
    "Stanford": {
      "adjDef": 95.9,
      "adjDefRank": 45,
      "adjEM": 10.63,
      "adjOff": 106.6,
      "adjOffRank": 103,
      "adjTempo": 67.1,
      "adjTempoRank": 121,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 69,
      "record": "18-13",
      "seed": 8
    },
    "Stephen F. Austin": {
      "adjDef": 108.3,
      "adjDefRank": 268,
      "adjEM": -10.43,
      "adjOff": 97.9,
      "adjOffRank": 240,
      "adjTempo": 59.3,
      "adjTempoRank": 323,
      "conference": "Slnd",
      "rank": 266,
      "record": "12-15"
    },
    "Stetson": {
      "adjDef": 109.2,
      "adjDefRank": 281,
      "adjEM": -12.82,
      "adjOff": 96.4,
      "adjOffRank": 261,
      "adjTempo": 66,
      "adjTempoRank": 170,
      "conference": "ASun",
      "rank": 283,
      "record": "10-17"
    },
    "Stony Brook": {
      "adjDef": 104.9,
      "adjDefRank": 215,
      "adjEM": -9.02,
      "adjOff": 95.8,
      "adjOffRank": 269,
      "adjTempo": 64.7,
      "adjTempoRank": 231,
      "conference": "AE",
      "rank": 253,
      "record": "12-17"
    },
    "Syracuse": {
      "adjDef": 94.7,
      "adjDefRank": 33,
      "adjEM": 20.98,
      "adjOff": 115.6,
      "adjOffRank": 14,
      "adjTempo": 65.8,
      "adjTempoRank": 180,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 16,
      "record": "27-7",
      "seed": 4
    },
    "TCU": {
      "adjDef": 96.6,
      "adjDefRank": 54,
      "adjEM": 6.59,
      "adjOff": 103.2,
      "adjOffRank": 157,
      "adjTempo": 68,
      "adjTempoRank": 92,
      "conference": "CUSA",
      "rank": 97,
      "record": "21-14"
    },
    "Temple": {
      "adjDef": 100.9,
      "adjDefRank": 126,
      "adjEM": 8.53,
      "adjOff": 109.4,
      "adjOffRank": 63,
      "adjTempo": 60.3,
      "adjTempoRank": 316,
      "conference": "A10",
      "rank": 84,
      "record": "16-14"
    },
    "Tennessee": {
      "adjDef": 101.1,
      "adjDefRank": 131,
      "adjEM": 8.89,
      "adjOff": 110,
      "adjOffRank": 53,
      "adjTempo": 65,
      "adjTempoRank": 212,
      "conference": "SEC",
      "rank": 82,
      "record": "14-17"
    },
    "Tennessee Martin": {
      "adjDef": 112.5,
      "adjDefRank": 316,
      "adjEM": -15.83,
      "adjOff": 96.7,
      "adjOffRank": 259,
      "adjTempo": 67.1,
      "adjTempoRank": 120,
      "conference": "OVC",
      "rank": 304,
      "record": "6-21"
    },
    "Tennessee St.": {
      "adjDef": 108.2,
      "adjDefRank": 265,
      "adjEM": -2.87,
      "adjOff": 105.3,
      "adjOffRank": 120,
      "adjTempo": 69.6,
      "adjTempoRank": 46,
      "conference": "OVC",
      "rank": 195,
      "record": "14-17"
    },
    "Tennessee Tech": {
      "adjDef": 100.8,
      "adjDefRank": 125,
      "adjEM": 0.43,
      "adjOff": 101.3,
      "adjOffRank": 199,
      "adjTempo": 67.6,
      "adjTempoRank": 106,
      "conference": "OVC",
      "rank": 155,
      "record": "18-11"
    },
    "Texas": {
      "adjDef": 97.1,
      "adjDefRank": 61,
      "adjEM": 17.01,
      "adjOff": 114.1,
      "adjOffRank": 24,
      "adjTempo": 66.5,
      "adjTempoRank": 146,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 28,
      "record": "20-11",
      "seed": 8
    },
    "Texas A&M": {
      "adjDef": 98.9,
      "adjDefRank": 86,
      "adjEM": 13.34,
      "adjOff": 112.3,
      "adjOffRank": 34,
      "adjTempo": 64.9,
      "adjTempoRank": 219,
      "conference": "B12",
      "rank": 46,
      "record": "21-10"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 104.8,
      "adjDefRank": 210,
      "adjEM": -0.22,
      "adjOff": 104.5,
      "adjOffRank": 136,
      "adjTempo": 66.5,
      "adjTempoRank": 145,
      "conference": "ind",
      "rank": 169,
      "record": "20-8"
    },
    "Texas Pan American": {
      "adjDef": 110.6,
      "adjDefRank": 297,
      "adjEM": -12.47,
      "adjOff": 98.1,
      "adjOffRank": 239,
      "adjTempo": 67.4,
      "adjTempoRank": 112,
      "conference": "ind",
      "rank": 277,
      "record": "12-16"
    },
    "Texas Southern": {
      "adjDef": 112.2,
      "adjDefRank": 312,
      "adjEM": -18.42,
      "adjOff": 93.8,
      "adjOffRank": 294,
      "adjTempo": 67.1,
      "adjTempoRank": 123,
      "conference": "SWAC",
      "rank": 314,
      "record": "11-14"
    },
    "Texas St.": {
      "adjDef": 111.4,
      "adjDefRank": 304,
      "adjEM": -9.23,
      "adjOff": 102.1,
      "adjOffRank": 180,
      "adjTempo": 61.7,
      "adjTempoRank": 305,
      "conference": "Slnd",
      "rank": 255,
      "record": "14-14"
    },
    "Texas Tech": {
      "adjDef": 94.8,
      "adjDefRank": 36,
      "adjEM": 15.47,
      "adjOff": 110.3,
      "adjOffRank": 50,
      "adjTempo": 69,
      "adjTempoRank": 55,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 32,
      "record": "22-11",
      "seed": 6
    },
    "The Citadel": {
      "adjDef": 106.5,
      "adjDefRank": 237,
      "adjEM": -15.78,
      "adjOff": 90.7,
      "adjOffRank": 312,
      "adjTempo": 68.3,
      "adjTempoRank": 79,
      "conference": "SC",
      "rank": 303,
      "record": "12-16"
    },
    "Toledo": {
      "adjDef": 99.5,
      "adjDefRank": 93,
      "adjEM": 7.85,
      "adjOff": 107.4,
      "adjOffRank": 85,
      "adjTempo": 65.7,
      "adjTempoRank": 188,
      "conference": "MAC",
      "rank": 88,
      "record": "16-13"
    },
    "Towson": {
      "adjDef": 111,
      "adjDefRank": 299,
      "adjEM": -15.02,
      "adjOff": 96,
      "adjOffRank": 265,
      "adjTempo": 64.3,
      "adjTempoRank": 238,
      "conference": "CAA",
      "rank": 298,
      "record": "5-24"
    },
    "Troy": {
      "adjDef": 114.6,
      "adjDefRank": 325,
      "adjEM": -9.95,
      "adjOff": 104.6,
      "adjOffRank": 134,
      "adjTempo": 68.8,
      "adjTempoRank": 64,
      "conference": "ASun",
      "rank": 263,
      "record": "12-18"
    },
    "Tulane": {
      "adjDef": 107.5,
      "adjDefRank": 253,
      "adjEM": -8.56,
      "adjOff": 98.9,
      "adjOffRank": 228,
      "adjTempo": 67.7,
      "adjTempoRank": 104,
      "conference": "CUSA",
      "rank": 247,
      "record": "10-18"
    },
    "Tulsa": {
      "adjDef": 100.6,
      "adjDefRank": 119,
      "adjEM": -1.1,
      "adjOff": 99.5,
      "adjOffRank": 222,
      "adjTempo": 69.5,
      "adjTempoRank": 47,
      "conference": "WAC",
      "rank": 177,
      "record": "9-20"
    },
    "UAB": {
      "adjDef": 96.7,
      "adjDefRank": 55,
      "adjEM": 12.51,
      "adjOff": 109.2,
      "adjOffRank": 67,
      "adjTempo": 71,
      "adjTempoRank": 24,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 51,
      "record": "22-11",
      "seed": 11
    },
    "UC Davis": {
      "adjDef": 111.8,
      "adjDefRank": 306,
      "adjEM": -10.18,
      "adjOff": 101.6,
      "adjOffRank": 191,
      "adjTempo": 64.1,
      "adjTempoRank": 247,
      "conference": "ind",
      "rank": 265,
      "record": "11-17"
    },
    "UC Irvine": {
      "adjDef": 104.7,
      "adjDefRank": 208,
      "adjEM": -2.13,
      "adjOff": 102.6,
      "adjOffRank": 167,
      "adjTempo": 64.9,
      "adjTempoRank": 222,
      "conference": "BW",
      "rank": 187,
      "record": "16-13"
    },
    "UC Riverside": {
      "adjDef": 104.3,
      "adjDefRank": 195,
      "adjEM": -10.48,
      "adjOff": 93.8,
      "adjOffRank": 293,
      "adjTempo": 64.7,
      "adjTempoRank": 229,
      "conference": "BW",
      "rank": 268,
      "record": "9-19"
    },
    "UC Santa Barbara": {
      "adjDef": 101.6,
      "adjDefRank": 142,
      "adjEM": -5.18,
      "adjOff": 96.4,
      "adjOffRank": 260,
      "adjTempo": 60.8,
      "adjTempoRank": 313,
      "conference": "BW",
      "rank": 212,
      "record": "11-18"
    },
    "UCF": {
      "adjDef": 107.6,
      "adjDefRank": 254,
      "adjEM": -2.56,
      "adjOff": 105,
      "adjOffRank": 126,
      "adjTempo": 60.1,
      "adjTempoRank": 317,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 192,
      "record": "24-9",
      "seed": 15
    },
    "UCLA": {
      "adjDef": 97.1,
      "adjDefRank": 58,
      "adjEM": 10.74,
      "adjOff": 107.8,
      "adjOffRank": 80,
      "adjTempo": 69.2,
      "adjTempoRank": 49,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 68,
      "record": "18-11",
      "seed": 11
    },
    "UMBC": {
      "adjDef": 107.7,
      "adjDefRank": 259,
      "adjEM": -13.48,
      "adjOff": 94.2,
      "adjOffRank": 290,
      "adjTempo": 63.7,
      "adjTempoRank": 261,
      "conference": "AE",
      "rank": 287,
      "record": "11-18"
    },
    "UMKC": {
      "adjDef": 107.7,
      "adjDefRank": 260,
      "adjEM": -1.68,
      "adjOff": 106,
      "adjOffRank": 109,
      "adjTempo": 66.9,
      "adjTempoRank": 127,
      "conference": "MCon",
      "rank": 181,
      "record": "16-12"
    },
    "UNC Asheville": {
      "adjDef": 111.2,
      "adjDefRank": 301,
      "adjEM": -10.12,
      "adjOff": 101,
      "adjOffRank": 204,
      "adjTempo": 68.6,
      "adjTempoRank": 69,
      "conference": "BSth",
      "rank": 264,
      "record": "11-17"
    },
    "UNC Greensboro": {
      "adjDef": 103,
      "adjDefRank": 170,
      "adjEM": -4.89,
      "adjOff": 98.1,
      "adjOffRank": 238,
      "adjTempo": 69.8,
      "adjTempoRank": 40,
      "conference": "SC",
      "rank": 208,
      "record": "18-12"
    },
    "UNC Wilmington": {
      "adjDef": 99.6,
      "adjDefRank": 95,
      "adjEM": 0.39,
      "adjOff": 100,
      "adjOffRank": 216,
      "adjTempo": 62.7,
      "adjTempoRank": 293,
      "conference": "CAA",
      "rank": 156,
      "record": "19-10"
    },
    "UNLV": {
      "adjDef": 100.2,
      "adjDefRank": 110,
      "adjEM": 7.04,
      "adjOff": 107.3,
      "adjOffRank": 91,
      "adjTempo": 69.1,
      "adjTempoRank": 50,
      "conference": "MWC",
      "rank": 90,
      "record": "17-14"
    },
    "USC": {
      "adjDef": 103.2,
      "adjDefRank": 176,
      "adjEM": 4.57,
      "adjOff": 107.7,
      "adjOffRank": 81,
      "adjTempo": 70.3,
      "adjTempoRank": 35,
      "conference": "P10",
      "rank": 115,
      "record": "12-17"
    },
    "UT Arlington": {
      "adjDef": 105.9,
      "adjDefRank": 229,
      "adjEM": -8.94,
      "adjOff": 96.9,
      "adjOffRank": 256,
      "adjTempo": 68.4,
      "adjTempoRank": 77,
      "conference": "Slnd",
      "rank": 252,
      "record": "13-15"
    },
    "UTEP": {
      "adjDef": 101.1,
      "adjDefRank": 130,
      "adjEM": 11.62,
      "adjOff": 112.7,
      "adjOffRank": 32,
      "adjTempo": 65.9,
      "adjTempoRank": 172,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 58,
      "record": "27-8",
      "seed": 11
    },
    "UTSA": {
      "adjDef": 103.8,
      "adjDefRank": 188,
      "adjEM": -3.35,
      "adjOff": 100.4,
      "adjOffRank": 211,
      "adjTempo": 72.8,
      "adjTempoRank": 9,
      "conference": "Slnd",
      "rank": 200,
      "record": "15-13"
    },
    "Utah": {
      "adjDef": 92.7,
      "adjDefRank": 19,
      "adjEM": 20.33,
      "adjOff": 113.1,
      "adjOffRank": 28,
      "adjTempo": 59.1,
      "adjTempoRank": 325,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "29-6",
      "seed": 6
    },
    "Utah St.": {
      "adjDef": 96.4,
      "adjDefRank": 52,
      "adjEM": 19.62,
      "adjOff": 116,
      "adjOffRank": 12,
      "adjTempo": 61.1,
      "adjTempoRank": 311,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 21,
      "record": "24-8",
      "seed": 14
    },
    "Utah Valley St.": {
      "adjDef": 107.7,
      "adjDefRank": 258,
      "adjEM": -6.51,
      "adjOff": 101.2,
      "adjOffRank": 202,
      "adjTempo": 62.1,
      "adjTempoRank": 300,
      "conference": "ind",
      "rank": 225,
      "record": "16-12"
    },
    "VCU": {
      "adjDef": 101.5,
      "adjDefRank": 139,
      "adjEM": 4.04,
      "adjOff": 105.6,
      "adjOffRank": 118,
      "adjTempo": 65.6,
      "adjTempoRank": 191,
      "conference": "CAA",
      "rank": 122,
      "record": "19-13"
    },
    "VMI": {
      "adjDef": 109.9,
      "adjDefRank": 292,
      "adjEM": -18.53,
      "adjOff": 91.4,
      "adjOffRank": 310,
      "adjTempo": 66.7,
      "adjTempoRank": 135,
      "conference": "BSth",
      "rank": 316,
      "record": "9-18"
    },
    "Valparaiso": {
      "adjDef": 107.6,
      "adjDefRank": 255,
      "adjEM": -2.95,
      "adjOff": 104.6,
      "adjOffRank": 133,
      "adjTempo": 65.5,
      "adjTempoRank": 193,
      "conference": "MCon",
      "rank": 196,
      "record": "15-16"
    },
    "Vanderbilt": {
      "adjDef": 95.7,
      "adjDefRank": 43,
      "adjEM": 15.31,
      "adjOff": 111,
      "adjOffRank": 49,
      "adjTempo": 65.8,
      "adjTempoRank": 179,
      "conference": "SEC",
      "rank": 33,
      "record": "20-14"
    },
    "Vermont": {
      "adjDef": 97.8,
      "adjDefRank": 72,
      "adjEM": 13.53,
      "adjOff": 111.3,
      "adjOffRank": 46,
      "adjTempo": 64.7,
      "adjTempoRank": 230,
      "conference": "AE",
      "finish": "Round of 32",
      "rank": 43,
      "record": "25-7",
      "seed": 13
    },
    "Villanova": {
      "adjDef": 89.7,
      "adjDefRank": 6,
      "adjEM": 25.02,
      "adjOff": 114.7,
      "adjOffRank": 19,
      "adjTempo": 67,
      "adjTempoRank": 126,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 7,
      "record": "24-8",
      "seed": 5
    },
    "Virginia": {
      "adjDef": 99.7,
      "adjDefRank": 96,
      "adjEM": 7.47,
      "adjOff": 107.1,
      "adjOffRank": 93,
      "adjTempo": 68,
      "adjTempoRank": 91,
      "conference": "ACC",
      "rank": 89,
      "record": "14-15"
    },
    "Virginia Tech": {
      "adjDef": 98.2,
      "adjDefRank": 75,
      "adjEM": 6.7,
      "adjOff": 104.9,
      "adjOffRank": 127,
      "adjTempo": 65.9,
      "adjTempoRank": 173,
      "conference": "ACC",
      "rank": 94,
      "record": "16-14"
    },
    "Wagner": {
      "adjDef": 103.3,
      "adjDefRank": 180,
      "adjEM": -9.06,
      "adjOff": 94.3,
      "adjOffRank": 289,
      "adjTempo": 62.9,
      "adjTempoRank": 289,
      "conference": "NEC",
      "rank": 254,
      "record": "13-17"
    },
    "Wake Forest": {
      "adjDef": 99.5,
      "adjDefRank": 89,
      "adjEM": 24.86,
      "adjOff": 124.3,
      "adjOffRank": 1,
      "adjTempo": 68.9,
      "adjTempoRank": 56,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 8,
      "record": "27-6",
      "seed": 2
    },
    "Washington": {
      "adjDef": 98.4,
      "adjDefRank": 80,
      "adjEM": 22.18,
      "adjOff": 120.6,
      "adjOffRank": 5,
      "adjTempo": 71.9,
      "adjTempoRank": 14,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "29-6",
      "seed": 1
    },
    "Washington St.": {
      "adjDef": 86.9,
      "adjDefRank": 1,
      "adjEM": 9.14,
      "adjOff": 96,
      "adjOffRank": 264,
      "adjTempo": 57.8,
      "adjTempoRank": 327,
      "conference": "P10",
      "rank": 79,
      "record": "12-16"
    },
    "Weber St.": {
      "adjDef": 109.6,
      "adjDefRank": 286,
      "adjEM": -8.32,
      "adjOff": 101.2,
      "adjOffRank": 200,
      "adjTempo": 62.9,
      "adjTempoRank": 290,
      "conference": "BSky",
      "rank": 245,
      "record": "14-16"
    },
    "West Virginia": {
      "adjDef": 99.1,
      "adjDefRank": 88,
      "adjEM": 15,
      "adjOff": 114.1,
      "adjOffRank": 25,
      "adjTempo": 63.6,
      "adjTempoRank": 265,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 35,
      "record": "24-11",
      "seed": 7
    },
    "Western Carolina": {
      "adjDef": 106.9,
      "adjDefRank": 242,
      "adjEM": -14.34,
      "adjOff": 92.6,
      "adjOffRank": 302,
      "adjTempo": 70.5,
      "adjTempoRank": 32,
      "conference": "SC",
      "rank": 295,
      "record": "8-22"
    },
    "Western Illinois": {
      "adjDef": 104.7,
      "adjDefRank": 204,
      "adjEM": -4.71,
      "adjOff": 100,
      "adjOffRank": 215,
      "adjTempo": 64.1,
      "adjTempoRank": 251,
      "conference": "MCon",
      "rank": 206,
      "record": "11-17"
    },
    "Western Kentucky": {
      "adjDef": 104.8,
      "adjDefRank": 211,
      "adjEM": 8.03,
      "adjOff": 112.8,
      "adjOffRank": 31,
      "adjTempo": 66.4,
      "adjTempoRank": 148,
      "conference": "SB",
      "rank": 86,
      "record": "22-9"
    },
    "Western Michigan": {
      "adjDef": 100.4,
      "adjDefRank": 114,
      "adjEM": 11.14,
      "adjOff": 111.6,
      "adjOffRank": 43,
      "adjTempo": 64.9,
      "adjTempoRank": 217,
      "conference": "MAC",
      "rank": 63,
      "record": "20-13"
    },
    "Wichita St.": {
      "adjDef": 100.5,
      "adjDefRank": 116,
      "adjEM": 13.15,
      "adjOff": 113.6,
      "adjOffRank": 27,
      "adjTempo": 64.4,
      "adjTempoRank": 234,
      "conference": "MVC",
      "rank": 47,
      "record": "22-10"
    },
    "William & Mary": {
      "adjDef": 108.2,
      "adjDefRank": 266,
      "adjEM": -13.85,
      "adjOff": 94.4,
      "adjOffRank": 288,
      "adjTempo": 66.7,
      "adjTempoRank": 132,
      "conference": "CAA",
      "rank": 290,
      "record": "8-21"
    },
    "Winthrop": {
      "adjDef": 96.5,
      "adjDefRank": 53,
      "adjEM": 4.97,
      "adjOff": 101.5,
      "adjOffRank": 195,
      "adjTempo": 63.1,
      "adjTempoRank": 286,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 108,
      "record": "27-6",
      "seed": 14
    },
    "Wisconsin": {
      "adjDef": 91.2,
      "adjDefRank": 11,
      "adjEM": 20.81,
      "adjOff": 112,
      "adjOffRank": 39,
      "adjTempo": 62.8,
      "adjTempoRank": 291,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 17,
      "record": "25-9",
      "seed": 6
    },
    "Wofford": {
      "adjDef": 108.4,
      "adjDefRank": 269,
      "adjEM": -6.24,
      "adjOff": 102.2,
      "adjOffRank": 178,
      "adjTempo": 66.7,
      "adjTempoRank": 136,
      "conference": "SC",
      "rank": 223,
      "record": "14-14"
    },
    "Wright St.": {
      "adjDef": 105.1,
      "adjDefRank": 218,
      "adjEM": -0.52,
      "adjOff": 104.6,
      "adjOffRank": 135,
      "adjTempo": 61.1,
      "adjTempoRank": 310,
      "conference": "Horz",
      "rank": 172,
      "record": "15-15"
    },
    "Wyoming": {
      "adjDef": 100.4,
      "adjDefRank": 113,
      "adjEM": 4.83,
      "adjOff": 105.2,
      "adjOffRank": 123,
      "adjTempo": 68.7,
      "adjTempoRank": 66,
      "conference": "MWC",
      "rank": 110,
      "record": "15-13"
    },
    "Xavier": {
      "adjDef": 102.4,
      "adjDefRank": 158,
      "adjEM": 9.05,
      "adjOff": 111.5,
      "adjOffRank": 44,
      "adjTempo": 63.1,
      "adjTempoRank": 284,
      "conference": "A10",
      "rank": 81,
      "record": "17-12"
    },
    "Yale": {
      "adjDef": 102.8,
      "adjDefRank": 163,
      "adjEM": -4.1,
      "adjOff": 98.7,
      "adjOffRank": 233,
      "adjTempo": 67.1,
      "adjTempoRank": 118,
      "conference": "Ivy",
      "rank": 203,
      "record": "11-16"
    },
    "Youngstown St.": {
      "adjDef": 111.8,
      "adjDefRank": 308,
      "adjEM": -19.54,
      "adjOff": 92.2,
      "adjOffRank": 305,
      "adjTempo": 64.2,
      "adjTempoRank": 243,
      "conference": "Horz",
      "rank": 318,
      "record": "5-23"
    }
  },
  "2006": {
    "Air Force": {
      "adjDef": 98.3,
      "adjDefRank": 89,
      "adjEM": 14.87,
      "adjOff": 113.2,
      "adjOffRank": 21,
      "adjTempo": 54.7,
      "adjTempoRank": 332,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 40,
      "record": "24-7",
      "seed": 13
    },
    "Akron": {
      "adjDef": 101.8,
      "adjDefRank": 146,
      "adjEM": 9.28,
      "adjOff": 111.1,
      "adjOffRank": 45,
      "adjTempo": 64.1,
      "adjTempoRank": 227,
      "conference": "MAC",
      "rank": 83,
      "record": "23-10"
    },
    "Alabama": {
      "adjDef": 100.3,
      "adjDefRank": 118,
      "adjEM": 12.48,
      "adjOff": 112.8,
      "adjOffRank": 28,
      "adjTempo": 63.4,
      "adjTempoRank": 249,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 60,
      "record": "18-13",
      "seed": 10
    },
    "Alabama A&M": {
      "adjDef": 104.1,
      "adjDefRank": 190,
      "adjEM": -9.65,
      "adjOff": 94.5,
      "adjOffRank": 292,
      "adjTempo": 73.4,
      "adjTempoRank": 3,
      "conference": "SWAC",
      "rank": 268,
      "record": "13-13"
    },
    "Alabama St.": {
      "adjDef": 107.5,
      "adjDefRank": 251,
      "adjEM": -13.52,
      "adjOff": 94,
      "adjOffRank": 295,
      "adjTempo": 66.1,
      "adjTempoRank": 129,
      "conference": "SWAC",
      "rank": 290,
      "record": "12-18"
    },
    "Albany": {
      "adjDef": 103.6,
      "adjDefRank": 180,
      "adjEM": 2.63,
      "adjOff": 106.2,
      "adjOffRank": 112,
      "adjTempo": 63.8,
      "adjTempoRank": 236,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 138,
      "record": "21-11",
      "seed": 16
    },
    "Alcorn St.": {
      "adjDef": 109.4,
      "adjDefRank": 282,
      "adjEM": -16.39,
      "adjOff": 93,
      "adjOffRank": 306,
      "adjTempo": 68.4,
      "adjTempoRank": 59,
      "conference": "SWAC",
      "rank": 310,
      "record": "8-20"
    },
    "American": {
      "adjDef": 107.1,
      "adjDefRank": 241,
      "adjEM": -8.62,
      "adjOff": 98.5,
      "adjOffRank": 240,
      "adjTempo": 63.7,
      "adjTempoRank": 242,
      "conference": "Pat",
      "rank": 257,
      "record": "12-17"
    },
    "Appalachian St.": {
      "adjDef": 107.8,
      "adjDefRank": 256,
      "adjEM": -7.25,
      "adjOff": 100.6,
      "adjOffRank": 207,
      "adjTempo": 64.1,
      "adjTempoRank": 229,
      "conference": "SC",
      "rank": 238,
      "record": "14-16"
    },
    "Arizona": {
      "adjDef": 95,
      "adjDefRank": 40,
      "adjEM": 17.23,
      "adjOff": 112.2,
      "adjOffRank": 35,
      "adjTempo": 67.7,
      "adjTempoRank": 78,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 23,
      "record": "20-13",
      "seed": 8
    },
    "Arizona St.": {
      "adjDef": 102.6,
      "adjDefRank": 157,
      "adjEM": 2.26,
      "adjOff": 104.8,
      "adjOffRank": 135,
      "adjTempo": 64.8,
      "adjTempoRank": 189,
      "conference": "P10",
      "rank": 141,
      "record": "11-17"
    },
    "Arkansas": {
      "adjDef": 93.8,
      "adjDefRank": 29,
      "adjEM": 18.47,
      "adjOff": 112.2,
      "adjOffRank": 34,
      "adjTempo": 66,
      "adjTempoRank": 133,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 20,
      "record": "22-10",
      "seed": 8
    },
    "Arkansas Little Rock": {
      "adjDef": 100.9,
      "adjDefRank": 127,
      "adjEM": -5.28,
      "adjOff": 95.6,
      "adjOffRank": 281,
      "adjTempo": 64.6,
      "adjTempoRank": 200,
      "conference": "SB",
      "rank": 215,
      "record": "14-15"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 97.5,
      "adjDefRank": 80,
      "adjEM": -12.43,
      "adjOff": 85,
      "adjOffRank": 332,
      "adjTempo": 66.7,
      "adjTempoRank": 108,
      "conference": "SWAC",
      "rank": 284,
      "record": "13-16"
    },
    "Arkansas St.": {
      "adjDef": 110,
      "adjDefRank": 290,
      "adjEM": -5.35,
      "adjOff": 104.7,
      "adjOffRank": 137,
      "adjTempo": 67.2,
      "adjTempoRank": 95,
      "conference": "SB",
      "rank": 216,
      "record": "12-18"
    },
    "Army": {
      "adjDef": 114.4,
      "adjDefRank": 325,
      "adjEM": -26.5,
      "adjOff": 87.9,
      "adjOffRank": 329,
      "adjTempo": 60.4,
      "adjTempoRank": 313,
      "conference": "Pat",
      "rank": 331,
      "record": "5-22"
    },
    "Auburn": {
      "adjDef": 98.6,
      "adjDefRank": 92,
      "adjEM": 5.58,
      "adjOff": 104.2,
      "adjOffRank": 146,
      "adjTempo": 65.2,
      "adjTempoRank": 168,
      "conference": "SEC",
      "rank": 113,
      "record": "12-16"
    },
    "Austin Peay": {
      "adjDef": 106,
      "adjDefRank": 219,
      "adjEM": -3.21,
      "adjOff": 102.8,
      "adjOffRank": 171,
      "adjTempo": 66.7,
      "adjTempoRank": 110,
      "conference": "OVC",
      "rank": 195,
      "record": "17-14"
    },
    "BYU": {
      "adjDef": 102.2,
      "adjDefRank": 150,
      "adjEM": 7.87,
      "adjOff": 110.1,
      "adjOffRank": 55,
      "adjTempo": 70.7,
      "adjTempoRank": 18,
      "conference": "MWC",
      "rank": 91,
      "record": "20-9"
    },
    "Ball St.": {
      "adjDef": 101.7,
      "adjDefRank": 142,
      "adjEM": -3.5,
      "adjOff": 98.2,
      "adjOffRank": 243,
      "adjTempo": 63.3,
      "adjTempoRank": 252,
      "conference": "MAC",
      "rank": 199,
      "record": "10-18"
    },
    "Baylor": {
      "adjDef": 102.4,
      "adjDefRank": 153,
      "adjEM": 0.18,
      "adjOff": 102.6,
      "adjOffRank": 176,
      "adjTempo": 65.5,
      "adjTempoRank": 157,
      "conference": "B12",
      "rank": 154,
      "record": "4-13"
    },
    "Belmont": {
      "adjDef": 107.8,
      "adjDefRank": 257,
      "adjEM": -0.73,
      "adjOff": 107.1,
      "adjOffRank": 94,
      "adjTempo": 69.1,
      "adjTempoRank": 42,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 165,
      "record": "20-11",
      "seed": 15
    },
    "Bethune Cookman": {
      "adjDef": 109.3,
      "adjDefRank": 280,
      "adjEM": -14.02,
      "adjOff": 95.3,
      "adjOffRank": 286,
      "adjTempo": 66.4,
      "adjTempoRank": 119,
      "conference": "MEAC",
      "rank": 294,
      "record": "15-15"
    },
    "Binghamton": {
      "adjDef": 99,
      "adjDefRank": 96,
      "adjEM": -3.19,
      "adjOff": 95.8,
      "adjOffRank": 276,
      "adjTempo": 63.3,
      "adjTempoRank": 253,
      "conference": "AE",
      "rank": 194,
      "record": "16-13"
    },
    "Birmingham Southern": {
      "adjDef": 111.2,
      "adjDefRank": 303,
      "adjEM": -2.26,
      "adjOff": 109,
      "adjOffRank": 74,
      "adjTempo": 58.2,
      "adjTempoRank": 327,
      "conference": "BSth",
      "rank": 186,
      "record": "19-9"
    },
    "Boise St.": {
      "adjDef": 100,
      "adjDefRank": 115,
      "adjEM": 1.78,
      "adjOff": 101.8,
      "adjOffRank": 189,
      "adjTempo": 69.8,
      "adjTempoRank": 31,
      "conference": "WAC",
      "rank": 143,
      "record": "14-15"
    },
    "Boston College": {
      "adjDef": 99.2,
      "adjDefRank": 99,
      "adjEM": 18.65,
      "adjOff": 117.8,
      "adjOffRank": 4,
      "adjTempo": 61.5,
      "adjTempoRank": 301,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "28-8",
      "seed": 4
    },
    "Boston University": {
      "adjDef": 101,
      "adjDefRank": 128,
      "adjEM": -3.87,
      "adjOff": 97.1,
      "adjOffRank": 261,
      "adjTempo": 59.3,
      "adjTempoRank": 323,
      "conference": "AE",
      "rank": 203,
      "record": "12-16"
    },
    "Bowling Green": {
      "adjDef": 106.3,
      "adjDefRank": 230,
      "adjEM": -8.79,
      "adjOff": 97.5,
      "adjOffRank": 253,
      "adjTempo": 66.4,
      "adjTempoRank": 120,
      "conference": "MAC",
      "rank": 260,
      "record": "9-21"
    },
    "Bradley": {
      "adjDef": 91,
      "adjDefRank": 11,
      "adjEM": 16.5,
      "adjOff": 107.5,
      "adjOffRank": 86,
      "adjTempo": 69.3,
      "adjTempoRank": 37,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 25,
      "record": "22-11",
      "seed": 13
    },
    "Brown": {
      "adjDef": 105.5,
      "adjDefRank": 207,
      "adjEM": -14.18,
      "adjOff": 91.3,
      "adjOffRank": 319,
      "adjTempo": 63.8,
      "adjTempoRank": 241,
      "conference": "Ivy",
      "rank": 296,
      "record": "10-17"
    },
    "Bucknell": {
      "adjDef": 93.1,
      "adjDefRank": 22,
      "adjEM": 12.56,
      "adjOff": 105.7,
      "adjOffRank": 119,
      "adjTempo": 61.8,
      "adjTempoRank": 294,
      "conference": "Pat",
      "finish": "Round of 32",
      "rank": 58,
      "record": "27-5",
      "seed": 9
    },
    "Buffalo": {
      "adjDef": 103.4,
      "adjDefRank": 173,
      "adjEM": 2.56,
      "adjOff": 106,
      "adjOffRank": 114,
      "adjTempo": 67.3,
      "adjTempoRank": 93,
      "conference": "MAC",
      "rank": 139,
      "record": "19-13"
    },
    "Butler": {
      "adjDef": 102.9,
      "adjDefRank": 166,
      "adjEM": 12,
      "adjOff": 114.9,
      "adjOffRank": 15,
      "adjTempo": 58.3,
      "adjTempoRank": 326,
      "conference": "Horz",
      "rank": 61,
      "record": "20-13"
    },
    "Cal Poly": {
      "adjDef": 107.7,
      "adjDefRank": 253,
      "adjEM": -7.88,
      "adjOff": 99.8,
      "adjOffRank": 221,
      "adjTempo": 62.6,
      "adjTempoRank": 268,
      "conference": "BW",
      "rank": 247,
      "record": "10-19"
    },
    "Cal St. Fullerton": {
      "adjDef": 108.1,
      "adjDefRank": 261,
      "adjEM": -0.77,
      "adjOff": 107.3,
      "adjOffRank": 89,
      "adjTempo": 68.2,
      "adjTempoRank": 66,
      "conference": "BW",
      "rank": 166,
      "record": "16-13"
    },
    "Cal St. Northridge": {
      "adjDef": 104.8,
      "adjDefRank": 196,
      "adjEM": -9.16,
      "adjOff": 95.6,
      "adjOffRank": 283,
      "adjTempo": 69.3,
      "adjTempoRank": 38,
      "conference": "BW",
      "rank": 262,
      "record": "11-17"
    },
    "California": {
      "adjDef": 96.8,
      "adjDefRank": 68,
      "adjEM": 13.68,
      "adjOff": 110.5,
      "adjOffRank": 49,
      "adjTempo": 61.9,
      "adjTempoRank": 293,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 47,
      "record": "20-11",
      "seed": 7
    },
    "Campbell": {
      "adjDef": 113.6,
      "adjDefRank": 319,
      "adjEM": -12.59,
      "adjOff": 101,
      "adjOffRank": 201,
      "adjTempo": 75,
      "adjTempoRank": 1,
      "conference": "ASun",
      "rank": 285,
      "record": "10-18"
    },
    "Canisius": {
      "adjDef": 108.5,
      "adjDefRank": 267,
      "adjEM": -4.97,
      "adjOff": 103.6,
      "adjOffRank": 158,
      "adjTempo": 64.6,
      "adjTempoRank": 206,
      "conference": "MAAC",
      "rank": 210,
      "record": "9-20"
    },
    "Centenary": {
      "adjDef": 112.7,
      "adjDefRank": 315,
      "adjEM": -18.67,
      "adjOff": 94.1,
      "adjOffRank": 294,
      "adjTempo": 66.3,
      "adjTempoRank": 122,
      "conference": "MCon",
      "rank": 317,
      "record": "4-23"
    },
    "Central Connecticut": {
      "adjDef": 109.6,
      "adjDefRank": 288,
      "adjEM": -0.07,
      "adjOff": 109.6,
      "adjOffRank": 67,
      "adjTempo": 62,
      "adjTempoRank": 287,
      "conference": "NEC",
      "rank": 158,
      "record": "18-11"
    },
    "Central Michigan": {
      "adjDef": 104.1,
      "adjDefRank": 188,
      "adjEM": -15.05,
      "adjOff": 89.1,
      "adjOffRank": 327,
      "adjTempo": 66.3,
      "adjTempoRank": 123,
      "conference": "MAC",
      "rank": 300,
      "record": "4-24"
    },
    "Charleston Southern": {
      "adjDef": 108.3,
      "adjDefRank": 265,
      "adjEM": -8.67,
      "adjOff": 99.6,
      "adjOffRank": 225,
      "adjTempo": 65.6,
      "adjTempoRank": 146,
      "conference": "BSth",
      "rank": 258,
      "record": "13-16"
    },
    "Charlotte": {
      "adjDef": 100,
      "adjDefRank": 116,
      "adjEM": 7.45,
      "adjOff": 107.5,
      "adjOffRank": 87,
      "adjTempo": 65.5,
      "adjTempoRank": 154,
      "conference": "A10",
      "rank": 94,
      "record": "19-13"
    },
    "Chattanooga": {
      "adjDef": 106,
      "adjDefRank": 223,
      "adjEM": -7.18,
      "adjOff": 98.8,
      "adjOffRank": 234,
      "adjTempo": 67.1,
      "adjTempoRank": 97,
      "conference": "SC",
      "rank": 236,
      "record": "19-13"
    },
    "Chicago St.": {
      "adjDef": 108.1,
      "adjDefRank": 260,
      "adjEM": -6.9,
      "adjOff": 101.2,
      "adjOffRank": 195,
      "adjTempo": 65.3,
      "adjTempoRank": 164,
      "conference": "MCon",
      "rank": 234,
      "record": "11-19"
    },
    "Cincinnati": {
      "adjDef": 96.6,
      "adjDefRank": 63,
      "adjEM": 14.27,
      "adjOff": 110.9,
      "adjOffRank": 47,
      "adjTempo": 68.5,
      "adjTempoRank": 57,
      "conference": "BE",
      "rank": 44,
      "record": "21-13"
    },
    "Clemson": {
      "adjDef": 93.4,
      "adjDefRank": 25,
      "adjEM": 12.51,
      "adjOff": 105.9,
      "adjOffRank": 116,
      "adjTempo": 68.6,
      "adjTempoRank": 52,
      "conference": "ACC",
      "rank": 59,
      "record": "19-13"
    },
    "Cleveland St.": {
      "adjDef": 99.4,
      "adjDefRank": 103,
      "adjEM": -7.75,
      "adjOff": 91.6,
      "adjOffRank": 318,
      "adjTempo": 68.1,
      "adjTempoRank": 68,
      "conference": "Horz",
      "rank": 243,
      "record": "10-18"
    },
    "Coastal Carolina": {
      "adjDef": 107.3,
      "adjDefRank": 244,
      "adjEM": -0.83,
      "adjOff": 106.5,
      "adjOffRank": 106,
      "adjTempo": 61.7,
      "adjTempoRank": 296,
      "conference": "BSth",
      "rank": 167,
      "record": "20-10"
    },
    "Colgate": {
      "adjDef": 105.2,
      "adjDefRank": 201,
      "adjEM": -12.61,
      "adjOff": 92.6,
      "adjOffRank": 309,
      "adjTempo": 62.2,
      "adjTempoRank": 282,
      "conference": "Pat",
      "rank": 286,
      "record": "10-19"
    },
    "College of Charleston": {
      "adjDef": 101.5,
      "adjDefRank": 139,
      "adjEM": -1.95,
      "adjOff": 99.6,
      "adjOffRank": 227,
      "adjTempo": 67.9,
      "adjTempoRank": 73,
      "conference": "SC",
      "rank": 180,
      "record": "17-11"
    },
    "Colorado": {
      "adjDef": 99,
      "adjDefRank": 93,
      "adjEM": 10.27,
      "adjOff": 109.2,
      "adjOffRank": 70,
      "adjTempo": 69.9,
      "adjTempoRank": 27,
      "conference": "B12",
      "rank": 72,
      "record": "20-10"
    },
    "Colorado St.": {
      "adjDef": 103.6,
      "adjDefRank": 179,
      "adjEM": 4.09,
      "adjOff": 107.7,
      "adjOffRank": 82,
      "adjTempo": 68.2,
      "adjTempoRank": 65,
      "conference": "MWC",
      "rank": 128,
      "record": "16-15"
    },
    "Columbia": {
      "adjDef": 111.7,
      "adjDefRank": 310,
      "adjEM": -14.65,
      "adjOff": 97,
      "adjOffRank": 263,
      "adjTempo": 64.7,
      "adjTempoRank": 199,
      "conference": "Ivy",
      "rank": 297,
      "record": "11-16"
    },
    "Connecticut": {
      "adjDef": 92.3,
      "adjDefRank": 18,
      "adjEM": 25.44,
      "adjOff": 117.8,
      "adjOffRank": 5,
      "adjTempo": 68.7,
      "adjTempoRank": 49,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "30-4",
      "seed": 1
    },
    "Coppin St.": {
      "adjDef": 107.2,
      "adjDefRank": 243,
      "adjEM": -11.81,
      "adjOff": 95.4,
      "adjOffRank": 285,
      "adjTempo": 65.6,
      "adjTempoRank": 145,
      "conference": "MEAC",
      "rank": 280,
      "record": "12-18"
    },
    "Cornell": {
      "adjDef": 105.6,
      "adjDefRank": 211,
      "adjEM": -6.71,
      "adjOff": 98.9,
      "adjOffRank": 233,
      "adjTempo": 62.1,
      "adjTempoRank": 285,
      "conference": "Ivy",
      "rank": 233,
      "record": "13-15"
    },
    "Creighton": {
      "adjDef": 94.1,
      "adjDefRank": 30,
      "adjEM": 13.11,
      "adjOff": 107.2,
      "adjOffRank": 91,
      "adjTempo": 62.7,
      "adjTempoRank": 264,
      "conference": "MVC",
      "rank": 51,
      "record": "20-10"
    },
    "Dartmouth": {
      "adjDef": 110.7,
      "adjDefRank": 296,
      "adjEM": -15.87,
      "adjOff": 94.8,
      "adjOffRank": 289,
      "adjTempo": 62.3,
      "adjTempoRank": 281,
      "conference": "Ivy",
      "rank": 306,
      "record": "6-21"
    },
    "Davidson": {
      "adjDef": 105,
      "adjDefRank": 199,
      "adjEM": 4.86,
      "adjOff": 109.9,
      "adjOffRank": 61,
      "adjTempo": 66,
      "adjTempoRank": 132,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 122,
      "record": "20-11",
      "seed": 15
    },
    "Dayton": {
      "adjDef": 99.5,
      "adjDefRank": 104,
      "adjEM": 3.15,
      "adjOff": 102.7,
      "adjOffRank": 173,
      "adjTempo": 62.3,
      "adjTempoRank": 279,
      "conference": "A10",
      "rank": 131,
      "record": "14-17"
    },
    "DePaul": {
      "adjDef": 101.8,
      "adjDefRank": 145,
      "adjEM": 8.42,
      "adjOff": 110.2,
      "adjOffRank": 53,
      "adjTempo": 61.4,
      "adjTempoRank": 302,
      "conference": "BE",
      "rank": 90,
      "record": "12-15"
    },
    "Delaware": {
      "adjDef": 102.6,
      "adjDefRank": 156,
      "adjEM": -5.73,
      "adjOff": 96.8,
      "adjOffRank": 267,
      "adjTempo": 67.6,
      "adjTempoRank": 80,
      "conference": "CAA",
      "rank": 221,
      "record": "9-21"
    },
    "Delaware St.": {
      "adjDef": 105.7,
      "adjDefRank": 213,
      "adjEM": -3.57,
      "adjOff": 102.1,
      "adjOffRank": 184,
      "adjTempo": 54.5,
      "adjTempoRank": 333,
      "conference": "MEAC",
      "rank": 201,
      "record": "21-14"
    },
    "Denver": {
      "adjDef": 99.6,
      "adjDefRank": 105,
      "adjEM": -1.03,
      "adjOff": 98.5,
      "adjOffRank": 238,
      "adjTempo": 64.1,
      "adjTempoRank": 226,
      "conference": "SB",
      "rank": 172,
      "record": "16-15"
    },
    "Detroit": {
      "adjDef": 105.5,
      "adjDefRank": 208,
      "adjEM": -1.29,
      "adjOff": 104.2,
      "adjOffRank": 145,
      "adjTempo": 60,
      "adjTempoRank": 317,
      "conference": "Horz",
      "rank": 176,
      "record": "16-16"
    },
    "Drake": {
      "adjDef": 104.2,
      "adjDefRank": 191,
      "adjEM": 2.87,
      "adjOff": 107,
      "adjOffRank": 96,
      "adjTempo": 64.4,
      "adjTempoRank": 213,
      "conference": "MVC",
      "rank": 136,
      "record": "12-19"
    },
    "Drexel": {
      "adjDef": 93.4,
      "adjDefRank": 24,
      "adjEM": 6.95,
      "adjOff": 100.3,
      "adjOffRank": 213,
      "adjTempo": 62.5,
      "adjTempoRank": 273,
      "conference": "CAA",
      "rank": 100,
      "record": "15-16"
    },
    "Duke": {
      "adjDef": 91.8,
      "adjDefRank": 16,
      "adjEM": 27.54,
      "adjOff": 119.4,
      "adjOffRank": 1,
      "adjTempo": 69.8,
      "adjTempoRank": 29,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 2,
      "record": "32-4",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 114.9,
      "adjDefRank": 327,
      "adjEM": -15.17,
      "adjOff": 99.7,
      "adjOffRank": 222,
      "adjTempo": 67.1,
      "adjTempoRank": 98,
      "conference": "A10",
      "rank": 301,
      "record": "3-24"
    },
    "East Carolina": {
      "adjDef": 106.3,
      "adjDefRank": 228,
      "adjEM": -10.62,
      "adjOff": 95.6,
      "adjOffRank": 282,
      "adjTempo": 63.3,
      "adjTempoRank": 250,
      "conference": "CUSA",
      "rank": 272,
      "record": "8-20"
    },
    "East Tennessee St.": {
      "adjDef": 105.4,
      "adjDefRank": 206,
      "adjEM": -5.73,
      "adjOff": 99.7,
      "adjOffRank": 224,
      "adjTempo": 73,
      "adjTempoRank": 4,
      "conference": "ASun",
      "rank": 220,
      "record": "15-13"
    },
    "Eastern Illinois": {
      "adjDef": 109,
      "adjDefRank": 272,
      "adjEM": -16.96,
      "adjOff": 92.1,
      "adjOffRank": 313,
      "adjTempo": 61.1,
      "adjTempoRank": 304,
      "conference": "OVC",
      "rank": 314,
      "record": "6-21"
    },
    "Eastern Kentucky": {
      "adjDef": 113.9,
      "adjDefRank": 322,
      "adjEM": -8.9,
      "adjOff": 105,
      "adjOffRank": 131,
      "adjTempo": 63.2,
      "adjTempoRank": 254,
      "conference": "OVC",
      "rank": 261,
      "record": "14-16"
    },
    "Eastern Michigan": {
      "adjDef": 109.4,
      "adjDefRank": 283,
      "adjEM": -9.34,
      "adjOff": 100,
      "adjOffRank": 218,
      "adjTempo": 65.4,
      "adjTempoRank": 160,
      "conference": "MAC",
      "rank": 264,
      "record": "7-21"
    },
    "Eastern Washington": {
      "adjDef": 106,
      "adjDefRank": 218,
      "adjEM": 0.73,
      "adjOff": 106.7,
      "adjOffRank": 101,
      "adjTempo": 65.7,
      "adjTempoRank": 139,
      "conference": "BSky",
      "rank": 148,
      "record": "15-15"
    },
    "Elon": {
      "adjDef": 103.8,
      "adjDefRank": 183,
      "adjEM": -6.23,
      "adjOff": 97.6,
      "adjOffRank": 251,
      "adjTempo": 64,
      "adjTempoRank": 231,
      "conference": "SC",
      "rank": 227,
      "record": "15-14"
    },
    "Evansville": {
      "adjDef": 102.9,
      "adjDefRank": 164,
      "adjEM": -2.42,
      "adjOff": 100.4,
      "adjOffRank": 208,
      "adjTempo": 67.4,
      "adjTempoRank": 91,
      "conference": "MVC",
      "rank": 188,
      "record": "10-19"
    },
    "FIU": {
      "adjDef": 100.5,
      "adjDefRank": 121,
      "adjEM": -10.88,
      "adjOff": 89.6,
      "adjOffRank": 326,
      "adjTempo": 65.7,
      "adjTempoRank": 141,
      "conference": "SB",
      "rank": 274,
      "record": "8-20"
    },
    "Fairfield": {
      "adjDef": 112.5,
      "adjDefRank": 312,
      "adjEM": -6.12,
      "adjOff": 106.4,
      "adjOffRank": 109,
      "adjTempo": 65.5,
      "adjTempoRank": 158,
      "conference": "MAAC",
      "rank": 225,
      "record": "9-19"
    },
    "Fairleigh Dickinson": {
      "adjDef": 102.5,
      "adjDefRank": 154,
      "adjEM": -1.05,
      "adjOff": 101.5,
      "adjOffRank": 193,
      "adjTempo": 69.1,
      "adjTempoRank": 46,
      "conference": "NEC",
      "rank": 173,
      "record": "20-12"
    },
    "Florida": {
      "adjDef": 89.7,
      "adjDefRank": 7,
      "adjEM": 28.28,
      "adjOff": 117.9,
      "adjOffRank": 3,
      "adjTempo": 66.9,
      "adjTempoRank": 102,
      "conference": "SEC",
      "finish": "Champion",
      "rank": 1,
      "record": "33-6",
      "seed": 3
    },
    "Florida A&M": {
      "adjDef": 111.5,
      "adjDefRank": 308,
      "adjEM": -13.66,
      "adjOff": 97.8,
      "adjOffRank": 247,
      "adjTempo": 66.7,
      "adjTempoRank": 109,
      "conference": "MEAC",
      "rank": 291,
      "record": "14-17"
    },
    "Florida Atlantic": {
      "adjDef": 110.2,
      "adjDefRank": 291,
      "adjEM": -7.8,
      "adjOff": 102.4,
      "adjOffRank": 180,
      "adjTempo": 65,
      "adjTempoRank": 178,
      "conference": "ASun",
      "rank": 245,
      "record": "15-13"
    },
    "Florida St.": {
      "adjDef": 95.1,
      "adjDefRank": 44,
      "adjEM": 15.01,
      "adjOff": 110.1,
      "adjOffRank": 54,
      "adjTempo": 68.3,
      "adjTempoRank": 62,
      "conference": "ACC",
      "rank": 38,
      "record": "20-10"
    },
    "Fordham": {
      "adjDef": 103.5,
      "adjDefRank": 178,
      "adjEM": 4.12,
      "adjOff": 107.6,
      "adjOffRank": 84,
      "adjTempo": 62.7,
      "adjTempoRank": 266,
      "conference": "A10",
      "rank": 127,
      "record": "16-16"
    },
    "Fresno St.": {
      "adjDef": 102.8,
      "adjDefRank": 163,
      "adjEM": 2.78,
      "adjOff": 105.6,
      "adjOffRank": 120,
      "adjTempo": 69.2,
      "adjTempoRank": 39,
      "conference": "WAC",
      "rank": 137,
      "record": "15-13"
    },
    "Furman": {
      "adjDef": 108.7,
      "adjDefRank": 270,
      "adjEM": -6.04,
      "adjOff": 102.6,
      "adjOffRank": 174,
      "adjTempo": 62.6,
      "adjTempoRank": 272,
      "conference": "SC",
      "rank": 224,
      "record": "15-13"
    },
    "Gardner Webb": {
      "adjDef": 109.6,
      "adjDefRank": 286,
      "adjEM": -9.19,
      "adjOff": 100.4,
      "adjOffRank": 209,
      "adjTempo": 62.7,
      "adjTempoRank": 265,
      "conference": "ASun",
      "rank": 263,
      "record": "17-12"
    },
    "George Mason": {
      "adjDef": 91.5,
      "adjDefRank": 13,
      "adjEM": 18.16,
      "adjOff": 109.7,
      "adjOffRank": 66,
      "adjTempo": 62.6,
      "adjTempoRank": 269,
      "conference": "CAA",
      "finish": "Final Four",
      "rank": 22,
      "record": "27-8",
      "seed": 11
    },
    "George Washington": {
      "adjDef": 95.6,
      "adjDefRank": 48,
      "adjEM": 14.67,
      "adjOff": 110.3,
      "adjOffRank": 52,
      "adjTempo": 71.2,
      "adjTempoRank": 12,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 43,
      "record": "27-3",
      "seed": 8
    },
    "Georgetown": {
      "adjDef": 95.2,
      "adjDefRank": 46,
      "adjEM": 21.29,
      "adjOff": 116.5,
      "adjOffRank": 9,
      "adjTempo": 57.8,
      "adjTempoRank": 329,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "23-10",
      "seed": 7
    },
    "Georgia": {
      "adjDef": 97,
      "adjDefRank": 69,
      "adjEM": 6.33,
      "adjOff": 103.4,
      "adjOffRank": 163,
      "adjTempo": 68.5,
      "adjTempoRank": 56,
      "conference": "SEC",
      "rank": 107,
      "record": "15-15"
    },
    "Georgia Southern": {
      "adjDef": 101.6,
      "adjDefRank": 141,
      "adjEM": -0.43,
      "adjOff": 101.2,
      "adjOffRank": 196,
      "adjTempo": 72.2,
      "adjTempoRank": 6,
      "conference": "SC",
      "rank": 161,
      "record": "20-10"
    },
    "Georgia St.": {
      "adjDef": 107.9,
      "adjDefRank": 258,
      "adjEM": -5.62,
      "adjOff": 102.3,
      "adjOffRank": 181,
      "adjTempo": 63.6,
      "adjTempoRank": 245,
      "conference": "CAA",
      "rank": 219,
      "record": "7-22"
    },
    "Georgia Tech": {
      "adjDef": 98.1,
      "adjDefRank": 85,
      "adjEM": 6.66,
      "adjOff": 104.8,
      "adjOffRank": 136,
      "adjTempo": 68.4,
      "adjTempoRank": 61,
      "conference": "ACC",
      "rank": 103,
      "record": "11-17"
    },
    "Gonzaga": {
      "adjDef": 103.5,
      "adjDefRank": 174,
      "adjEM": 15.64,
      "adjOff": 119.1,
      "adjOffRank": 2,
      "adjTempo": 66.3,
      "adjTempoRank": 121,
      "conference": "WCC",
      "finish": "Sweet 16",
      "rank": 33,
      "record": "29-4",
      "seed": 3
    },
    "Grambling St.": {
      "adjDef": 107.4,
      "adjDefRank": 246,
      "adjEM": -15.7,
      "adjOff": 91.7,
      "adjOffRank": 315,
      "adjTempo": 67.6,
      "adjTempoRank": 81,
      "conference": "SWAC",
      "rank": 305,
      "record": "14-13"
    },
    "Green Bay": {
      "adjDef": 105.8,
      "adjDefRank": 217,
      "adjEM": -3.06,
      "adjOff": 102.8,
      "adjOffRank": 170,
      "adjTempo": 63.1,
      "adjTempoRank": 257,
      "conference": "Horz",
      "rank": 193,
      "record": "15-16"
    },
    "Hampton": {
      "adjDef": 102.3,
      "adjDefRank": 151,
      "adjEM": -10.4,
      "adjOff": 91.9,
      "adjOffRank": 314,
      "adjTempo": 68.5,
      "adjTempoRank": 55,
      "conference": "MEAC",
      "finish": "Play-in",
      "rank": 271,
      "record": "16-16",
      "seed": 16
    },
    "Hartford": {
      "adjDef": 109.1,
      "adjDefRank": 273,
      "adjEM": -7.45,
      "adjOff": 101.6,
      "adjOffRank": 191,
      "adjTempo": 65.1,
      "adjTempoRank": 174,
      "conference": "AE",
      "rank": 241,
      "record": "13-15"
    },
    "Harvard": {
      "adjDef": 105.3,
      "adjDefRank": 204,
      "adjEM": -8.51,
      "adjOff": 96.8,
      "adjOffRank": 269,
      "adjTempo": 69.1,
      "adjTempoRank": 43,
      "conference": "Ivy",
      "rank": 255,
      "record": "13-14"
    },
    "Hawaii": {
      "adjDef": 99.8,
      "adjDefRank": 111,
      "adjEM": 7.1,
      "adjOff": 106.9,
      "adjOffRank": 98,
      "adjTempo": 62.4,
      "adjTempoRank": 277,
      "conference": "WAC",
      "rank": 98,
      "record": "17-11"
    },
    "High Point": {
      "adjDef": 106,
      "adjDefRank": 221,
      "adjEM": -5.21,
      "adjOff": 100.8,
      "adjOffRank": 205,
      "adjTempo": 70.4,
      "adjTempoRank": 20,
      "conference": "BSth",
      "rank": 213,
      "record": "16-13"
    },
    "Hofstra": {
      "adjDef": 99.6,
      "adjDefRank": 108,
      "adjEM": 11.72,
      "adjOff": 111.3,
      "adjOffRank": 41,
      "adjTempo": 66.2,
      "adjTempoRank": 124,
      "conference": "CAA",
      "rank": 63,
      "record": "26-7"
    },
    "Holy Cross": {
      "adjDef": 96.3,
      "adjDefRank": 53,
      "adjEM": 4.06,
      "adjOff": 100.3,
      "adjOffRank": 211,
      "adjTempo": 64.7,
      "adjTempoRank": 198,
      "conference": "Pat",
      "rank": 129,
      "record": "20-12"
    },
    "Houston": {
      "adjDef": 93.3,
      "adjDefRank": 23,
      "adjEM": 10.21,
      "adjOff": 103.5,
      "adjOffRank": 160,
      "adjTempo": 67.5,
      "adjTempoRank": 87,
      "conference": "CUSA",
      "rank": 75,
      "record": "21-10"
    },
    "Howard": {
      "adjDef": 110.9,
      "adjDefRank": 298,
      "adjEM": -17.28,
      "adjOff": 93.6,
      "adjOffRank": 300,
      "adjTempo": 66.1,
      "adjTempoRank": 131,
      "conference": "MEAC",
      "rank": 315,
      "record": "7-22"
    },
    "IPFW": {
      "adjDef": 108,
      "adjDefRank": 259,
      "adjEM": -11.07,
      "adjOff": 97,
      "adjOffRank": 265,
      "adjTempo": 62.8,
      "adjTempoRank": 261,
      "conference": "ind",
      "rank": 276,
      "record": "10-18"
    },
    "IUPUI": {
      "adjDef": 107.2,
      "adjDefRank": 242,
      "adjEM": -1.73,
      "adjOff": 105.4,
      "adjOffRank": 124,
      "adjTempo": 60.8,
      "adjTempoRank": 310,
      "conference": "MCon",
      "rank": 178,
      "record": "19-10"
    },
    "Idaho": {
      "adjDef": 110.9,
      "adjDefRank": 297,
      "adjEM": -13.12,
      "adjOff": 97.8,
      "adjOffRank": 249,
      "adjTempo": 61.6,
      "adjTempoRank": 299,
      "conference": "WAC",
      "rank": 289,
      "record": "4-25"
    },
    "Idaho St.": {
      "adjDef": 108.2,
      "adjDefRank": 263,
      "adjEM": -3.52,
      "adjOff": 104.6,
      "adjOffRank": 138,
      "adjTempo": 71,
      "adjTempoRank": 15,
      "conference": "BSky",
      "rank": 200,
      "record": "13-14"
    },
    "Illinois": {
      "adjDef": 91.3,
      "adjDefRank": 12,
      "adjEM": 23.2,
      "adjOff": 114.5,
      "adjOffRank": 18,
      "adjTempo": 62.1,
      "adjTempoRank": 283,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 9,
      "record": "26-7",
      "seed": 4
    },
    "Illinois Chicago": {
      "adjDef": 106.4,
      "adjDefRank": 231,
      "adjEM": -0.95,
      "adjOff": 105.4,
      "adjOffRank": 125,
      "adjTempo": 63.9,
      "adjTempoRank": 235,
      "conference": "Horz",
      "rank": 170,
      "record": "16-15"
    },
    "Illinois St.": {
      "adjDef": 93.6,
      "adjDefRank": 27,
      "adjEM": -0.89,
      "adjOff": 92.8,
      "adjOffRank": 308,
      "adjTempo": 61.2,
      "adjTempoRank": 303,
      "conference": "MVC",
      "rank": 169,
      "record": "9-19"
    },
    "Indiana": {
      "adjDef": 94.4,
      "adjDefRank": 33,
      "adjEM": 15.53,
      "adjOff": 110,
      "adjOffRank": 58,
      "adjTempo": 66.5,
      "adjTempoRank": 118,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 34,
      "record": "19-12",
      "seed": 6
    },
    "Indiana St.": {
      "adjDef": 102.9,
      "adjDefRank": 165,
      "adjEM": -1.77,
      "adjOff": 101.1,
      "adjOffRank": 197,
      "adjTempo": 64.7,
      "adjTempoRank": 195,
      "conference": "MVC",
      "rank": 179,
      "record": "13-16"
    },
    "Iona": {
      "adjDef": 98.4,
      "adjDefRank": 90,
      "adjEM": 11.33,
      "adjOff": 109.7,
      "adjOffRank": 65,
      "adjTempo": 69.6,
      "adjTempoRank": 34,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 65,
      "record": "23-8",
      "seed": 13
    },
    "Iowa": {
      "adjDef": 85.6,
      "adjDefRank": 1,
      "adjEM": 18.22,
      "adjOff": 103.8,
      "adjOffRank": 154,
      "adjTempo": 64.7,
      "adjTempoRank": 194,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 21,
      "record": "25-9",
      "seed": 3
    },
    "Iowa St.": {
      "adjDef": 101.2,
      "adjDefRank": 132,
      "adjEM": 10.03,
      "adjOff": 111.2,
      "adjOffRank": 44,
      "adjTempo": 69.8,
      "adjTempoRank": 30,
      "conference": "B12",
      "rank": 76,
      "record": "16-14"
    },
    "Jackson St.": {
      "adjDef": 106,
      "adjDefRank": 220,
      "adjEM": -10.25,
      "adjOff": 95.7,
      "adjOffRank": 279,
      "adjTempo": 70.3,
      "adjTempoRank": 21,
      "conference": "SWAC",
      "rank": 269,
      "record": "15-17"
    },
    "Jacksonville": {
      "adjDef": 115.7,
      "adjDefRank": 330,
      "adjEM": -22.53,
      "adjOff": 93.2,
      "adjOffRank": 302,
      "adjTempo": 72.1,
      "adjTempoRank": 8,
      "conference": "ASun",
      "rank": 329,
      "record": "1-26"
    },
    "Jacksonville St.": {
      "adjDef": 111,
      "adjDefRank": 299,
      "adjEM": -4.4,
      "adjOff": 106.6,
      "adjOffRank": 104,
      "adjTempo": 70,
      "adjTempoRank": 24,
      "conference": "OVC",
      "rank": 205,
      "record": "16-13"
    },
    "James Madison": {
      "adjDef": 113.2,
      "adjDefRank": 317,
      "adjEM": -16.74,
      "adjOff": 96.5,
      "adjOffRank": 273,
      "adjTempo": 66.2,
      "adjTempoRank": 126,
      "conference": "CAA",
      "rank": 311,
      "record": "5-23"
    },
    "Kansas": {
      "adjDef": 87.2,
      "adjDefRank": 2,
      "adjEM": 23.22,
      "adjOff": 110.4,
      "adjOffRank": 50,
      "adjTempo": 67.7,
      "adjTempoRank": 75,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 8,
      "record": "25-8",
      "seed": 4
    },
    "Kansas St.": {
      "adjDef": 96.3,
      "adjDefRank": 54,
      "adjEM": 9.59,
      "adjOff": 105.9,
      "adjOffRank": 117,
      "adjTempo": 63.1,
      "adjTempoRank": 258,
      "conference": "B12",
      "rank": 77,
      "record": "15-13"
    },
    "Kennesaw St.": {
      "adjDef": 113.8,
      "adjDefRank": 321,
      "adjEM": -16.02,
      "adjOff": 97.8,
      "adjOffRank": 248,
      "adjTempo": 65.3,
      "adjTempoRank": 161,
      "conference": "ASun",
      "rank": 308,
      "record": "12-17"
    },
    "Kent St.": {
      "adjDef": 97.8,
      "adjDefRank": 82,
      "adjEM": 10.54,
      "adjOff": 108.3,
      "adjOffRank": 77,
      "adjTempo": 65.7,
      "adjTempoRank": 142,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 70,
      "record": "25-9",
      "seed": 12
    },
    "Kentucky": {
      "adjDef": 97.3,
      "adjDefRank": 75,
      "adjEM": 17.13,
      "adjOff": 114.4,
      "adjOffRank": 19,
      "adjTempo": 64.1,
      "adjTempoRank": 228,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 24,
      "record": "22-13",
      "seed": 8
    },
    "LIU Brooklyn": {
      "adjDef": 109.1,
      "adjDefRank": 274,
      "adjEM": -11.97,
      "adjOff": 97.1,
      "adjOffRank": 258,
      "adjTempo": 67.5,
      "adjTempoRank": 84,
      "conference": "NEC",
      "rank": 282,
      "record": "12-16"
    },
    "LSU": {
      "adjDef": 87.8,
      "adjDefRank": 3,
      "adjEM": 22,
      "adjOff": 109.8,
      "adjOffRank": 62,
      "adjTempo": 67.5,
      "adjTempoRank": 90,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 11,
      "record": "27-9",
      "seed": 4
    },
    "La Salle": {
      "adjDef": 99.8,
      "adjDefRank": 112,
      "adjEM": 7.44,
      "adjOff": 107.3,
      "adjOffRank": 90,
      "adjTempo": 64.3,
      "adjTempoRank": 217,
      "conference": "A10",
      "rank": 95,
      "record": "18-10"
    },
    "Lafayette": {
      "adjDef": 111.3,
      "adjDefRank": 304,
      "adjEM": -13.77,
      "adjOff": 97.5,
      "adjOffRank": 254,
      "adjTempo": 67.9,
      "adjTempoRank": 71,
      "conference": "Pat",
      "rank": 292,
      "record": "11-17"
    },
    "Lamar": {
      "adjDef": 108.1,
      "adjDefRank": 262,
      "adjEM": -4.44,
      "adjOff": 103.7,
      "adjOffRank": 157,
      "adjTempo": 72.2,
      "adjTempoRank": 7,
      "conference": "Slnd",
      "rank": 206,
      "record": "17-14"
    },
    "Lehigh": {
      "adjDef": 99.8,
      "adjDefRank": 110,
      "adjEM": -3.05,
      "adjOff": 96.8,
      "adjOffRank": 271,
      "adjTempo": 63.7,
      "adjTempoRank": 243,
      "conference": "Pat",
      "rank": 192,
      "record": "19-12"
    },
    "Liberty": {
      "adjDef": 111.3,
      "adjDefRank": 306,
      "adjEM": -20.15,
      "adjOff": 91.2,
      "adjOffRank": 320,
      "adjTempo": 65,
      "adjTempoRank": 179,
      "conference": "BSth",
      "rank": 323,
      "record": "7-23"
    },
    "Lipscomb": {
      "adjDef": 101.8,
      "adjDefRank": 147,
      "adjEM": -1.63,
      "adjOff": 100.2,
      "adjOffRank": 217,
      "adjTempo": 64.6,
      "adjTempoRank": 201,
      "conference": "ASun",
      "rank": 177,
      "record": "21-11"
    },
    "Long Beach St.": {
      "adjDef": 106.1,
      "adjDefRank": 224,
      "adjEM": 1.54,
      "adjOff": 107.6,
      "adjOffRank": 83,
      "adjTempo": 74.1,
      "adjTempoRank": 2,
      "conference": "BW",
      "rank": 145,
      "record": "18-12"
    },
    "Longwood": {
      "adjDef": 113.6,
      "adjDefRank": 320,
      "adjEM": -16.79,
      "adjOff": 96.9,
      "adjOffRank": 266,
      "adjTempo": 71.1,
      "adjTempoRank": 14,
      "conference": "ind",
      "rank": 312,
      "record": "10-20"
    },
    "Louisiana Lafayette": {
      "adjDef": 98.2,
      "adjDefRank": 87,
      "adjEM": 0.21,
      "adjOff": 98.4,
      "adjOffRank": 241,
      "adjTempo": 66.1,
      "adjTempoRank": 128,
      "conference": "SB",
      "rank": 153,
      "record": "13-16"
    },
    "Louisiana Monroe": {
      "adjDef": 105,
      "adjDefRank": 200,
      "adjEM": -12.19,
      "adjOff": 92.8,
      "adjOffRank": 307,
      "adjTempo": 64.8,
      "adjTempoRank": 192,
      "conference": "Slnd",
      "rank": 283,
      "record": "10-18"
    },
    "Louisiana Tech": {
      "adjDef": 93.6,
      "adjDefRank": 26,
      "adjEM": 7.43,
      "adjOff": 101.1,
      "adjOffRank": 200,
      "adjTempo": 64.8,
      "adjTempoRank": 187,
      "conference": "WAC",
      "rank": 96,
      "record": "20-13"
    },
    "Louisville": {
      "adjDef": 94.2,
      "adjDefRank": 32,
      "adjEM": 13.98,
      "adjOff": 108.2,
      "adjOffRank": 78,
      "adjTempo": 64.8,
      "adjTempoRank": 191,
      "conference": "BE",
      "rank": 46,
      "record": "21-13"
    },
    "Loyola Chicago": {
      "adjDef": 105.8,
      "adjDefRank": 215,
      "adjEM": 1.63,
      "adjOff": 107.5,
      "adjOffRank": 88,
      "adjTempo": 65.9,
      "adjTempoRank": 136,
      "conference": "Horz",
      "rank": 144,
      "record": "19-11"
    },
    "Loyola MD": {
      "adjDef": 107.4,
      "adjDefRank": 247,
      "adjEM": -2.13,
      "adjOff": 105.3,
      "adjOffRank": 128,
      "adjTempo": 68.8,
      "adjTempoRank": 48,
      "conference": "MAAC",
      "rank": 184,
      "record": "15-13"
    },
    "Loyola Marymount": {
      "adjDef": 97.3,
      "adjDefRank": 73,
      "adjEM": 2.95,
      "adjOff": 100.2,
      "adjOffRank": 215,
      "adjTempo": 70.7,
      "adjTempoRank": 19,
      "conference": "WCC",
      "rank": 135,
      "record": "12-18"
    },
    "Maine": {
      "adjDef": 109.2,
      "adjDefRank": 277,
      "adjEM": -13.93,
      "adjOff": 95.3,
      "adjOffRank": 287,
      "adjTempo": 65.4,
      "adjTempoRank": 159,
      "conference": "AE",
      "rank": 293,
      "record": "12-16"
    },
    "Manhattan": {
      "adjDef": 104.8,
      "adjDefRank": 197,
      "adjEM": 3,
      "adjOff": 107.8,
      "adjOffRank": 81,
      "adjTempo": 67.5,
      "adjTempoRank": 86,
      "conference": "MAAC",
      "rank": 134,
      "record": "20-11"
    },
    "Marist": {
      "adjDef": 107,
      "adjDefRank": 239,
      "adjEM": 6.08,
      "adjOff": 113.1,
      "adjOffRank": 24,
      "adjTempo": 64.9,
      "adjTempoRank": 183,
      "conference": "MAAC",
      "rank": 108,
      "record": "19-10"
    },
    "Marquette": {
      "adjDef": 97.4,
      "adjDefRank": 78,
      "adjEM": 15.36,
      "adjOff": 112.8,
      "adjOffRank": 27,
      "adjTempo": 68.1,
      "adjTempoRank": 67,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 36,
      "record": "20-11",
      "seed": 7
    },
    "Marshall": {
      "adjDef": 104.1,
      "adjDefRank": 189,
      "adjEM": -6.02,
      "adjOff": 98.1,
      "adjOffRank": 245,
      "adjTempo": 65.2,
      "adjTempoRank": 167,
      "conference": "CUSA",
      "rank": 223,
      "record": "12-16"
    },
    "Maryland": {
      "adjDef": 95,
      "adjDefRank": 41,
      "adjEM": 12.63,
      "adjOff": 107.6,
      "adjOffRank": 85,
      "adjTempo": 72.4,
      "adjTempoRank": 5,
      "conference": "ACC",
      "rank": 57,
      "record": "19-13"
    },
    "Maryland Eastern Shore": {
      "adjDef": 112,
      "adjDefRank": 311,
      "adjEM": -21.28,
      "adjOff": 90.7,
      "adjOffRank": 324,
      "adjTempo": 69.6,
      "adjTempoRank": 33,
      "conference": "MEAC",
      "rank": 325,
      "record": "7-22"
    },
    "Massachusetts": {
      "adjDef": 94.6,
      "adjDefRank": 36,
      "adjEM": 6.48,
      "adjOff": 101.1,
      "adjOffRank": 199,
      "adjTempo": 65.5,
      "adjTempoRank": 155,
      "conference": "A10",
      "rank": 105,
      "record": "13-15"
    },
    "McNeese St.": {
      "adjDef": 103.5,
      "adjDefRank": 176,
      "adjEM": -5.56,
      "adjOff": 97.9,
      "adjOffRank": 246,
      "adjTempo": 70,
      "adjTempoRank": 25,
      "conference": "Slnd",
      "rank": 218,
      "record": "14-14"
    },
    "Memphis": {
      "adjDef": 89.6,
      "adjDefRank": 6,
      "adjEM": 23.54,
      "adjOff": 113.2,
      "adjOffRank": 22,
      "adjTempo": 70.8,
      "adjTempoRank": 17,
      "conference": "CUSA",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "33-4",
      "seed": 1
    },
    "Mercer": {
      "adjDef": 115.8,
      "adjDefRank": 333,
      "adjEM": -18.73,
      "adjOff": 97.1,
      "adjOffRank": 260,
      "adjTempo": 67.3,
      "adjTempoRank": 94,
      "conference": "ASun",
      "rank": 318,
      "record": "9-19"
    },
    "Miami FL": {
      "adjDef": 98.5,
      "adjDefRank": 91,
      "adjEM": 12.71,
      "adjOff": 111.2,
      "adjOffRank": 43,
      "adjTempo": 61,
      "adjTempoRank": 305,
      "conference": "ACC",
      "rank": 55,
      "record": "18-16"
    },
    "Miami OH": {
      "adjDef": 97.5,
      "adjDefRank": 79,
      "adjEM": 6.89,
      "adjOff": 104.3,
      "adjOffRank": 144,
      "adjTempo": 60,
      "adjTempoRank": 318,
      "conference": "MAC",
      "rank": 101,
      "record": "18-11"
    },
    "Michigan": {
      "adjDef": 97.2,
      "adjDefRank": 71,
      "adjEM": 15.98,
      "adjOff": 113.1,
      "adjOffRank": 23,
      "adjTempo": 67,
      "adjTempoRank": 101,
      "conference": "B10",
      "rank": 28,
      "record": "22-11"
    },
    "Michigan St.": {
      "adjDef": 100.3,
      "adjDefRank": 119,
      "adjEM": 16.02,
      "adjOff": 116.3,
      "adjOffRank": 10,
      "adjTempo": 64.2,
      "adjTempoRank": 223,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 27,
      "record": "22-12",
      "seed": 6
    },
    "Middle Tennessee": {
      "adjDef": 105.3,
      "adjDefRank": 203,
      "adjEM": -0.22,
      "adjOff": 105,
      "adjOffRank": 130,
      "adjTempo": 64.4,
      "adjTempoRank": 212,
      "conference": "SB",
      "rank": 159,
      "record": "16-12"
    },
    "Milwaukee": {
      "adjDef": 96.5,
      "adjDefRank": 58,
      "adjEM": 11.86,
      "adjOff": 108.3,
      "adjOffRank": 76,
      "adjTempo": 67.7,
      "adjTempoRank": 77,
      "conference": "Horz",
      "finish": "Round of 32",
      "rank": 62,
      "record": "22-9",
      "seed": 11
    },
    "Minnesota": {
      "adjDef": 95.1,
      "adjDefRank": 43,
      "adjEM": 9.34,
      "adjOff": 104.4,
      "adjOffRank": 142,
      "adjTempo": 64.2,
      "adjTempoRank": 224,
      "conference": "B10",
      "rank": 82,
      "record": "16-15"
    },
    "Mississippi": {
      "adjDef": 101.6,
      "adjDefRank": 140,
      "adjEM": 0.93,
      "adjOff": 102.5,
      "adjOffRank": 177,
      "adjTempo": 63.8,
      "adjTempoRank": 239,
      "conference": "SEC",
      "rank": 147,
      "record": "14-16"
    },
    "Mississippi St.": {
      "adjDef": 97.3,
      "adjDefRank": 76,
      "adjEM": 5.53,
      "adjOff": 102.9,
      "adjOffRank": 168,
      "adjTempo": 66.7,
      "adjTempoRank": 113,
      "conference": "SEC",
      "rank": 114,
      "record": "15-15"
    },
    "Mississippi Valley St.": {
      "adjDef": 113.9,
      "adjDefRank": 323,
      "adjEM": -20.03,
      "adjOff": 93.9,
      "adjOffRank": 297,
      "adjTempo": 61,
      "adjTempoRank": 307,
      "conference": "SWAC",
      "rank": 322,
      "record": "9-19"
    },
    "Missouri": {
      "adjDef": 103.7,
      "adjDefRank": 182,
      "adjEM": 0.28,
      "adjOff": 103.9,
      "adjOffRank": 151,
      "adjTempo": 64.6,
      "adjTempoRank": 204,
      "conference": "B12",
      "rank": 151,
      "record": "12-16"
    },
    "Missouri St.": {
      "adjDef": 95.5,
      "adjDefRank": 47,
      "adjEM": 15.52,
      "adjOff": 111,
      "adjOffRank": 46,
      "adjTempo": 68.6,
      "adjTempoRank": 51,
      "conference": "MVC",
      "rank": 35,
      "record": "22-9"
    },
    "Monmouth": {
      "adjDef": 96.4,
      "adjDefRank": 57,
      "adjEM": -0.68,
      "adjOff": 95.8,
      "adjOffRank": 278,
      "adjTempo": 64.6,
      "adjTempoRank": 202,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 164,
      "record": "19-15",
      "seed": 16
    },
    "Montana": {
      "adjDef": 102.7,
      "adjDefRank": 161,
      "adjEM": 8.95,
      "adjOff": 111.6,
      "adjOffRank": 39,
      "adjTempo": 66.2,
      "adjTempoRank": 127,
      "conference": "BSky",
      "finish": "Round of 32",
      "rank": 87,
      "record": "24-7",
      "seed": 12
    },
    "Montana St.": {
      "adjDef": 108.7,
      "adjDefRank": 269,
      "adjEM": -5.26,
      "adjOff": 103.4,
      "adjOffRank": 162,
      "adjTempo": 66.7,
      "adjTempoRank": 112,
      "conference": "BSky",
      "rank": 214,
      "record": "15-15"
    },
    "Morehead St.": {
      "adjDef": 112.7,
      "adjDefRank": 314,
      "adjEM": -21.58,
      "adjOff": 91.1,
      "adjOffRank": 321,
      "adjTempo": 63.9,
      "adjTempoRank": 234,
      "conference": "OVC",
      "rank": 328,
      "record": "4-23"
    },
    "Morgan St.": {
      "adjDef": 110.5,
      "adjDefRank": 293,
      "adjEM": -31.06,
      "adjOff": 79.4,
      "adjOffRank": 334,
      "adjTempo": 65.1,
      "adjTempoRank": 176,
      "conference": "MEAC",
      "rank": 333,
      "record": "4-26"
    },
    "Mount St. Mary's": {
      "adjDef": 104.4,
      "adjDefRank": 193,
      "adjEM": -7.57,
      "adjOff": 96.8,
      "adjOffRank": 270,
      "adjTempo": 63.4,
      "adjTempoRank": 247,
      "conference": "NEC",
      "rank": 242,
      "record": "13-17"
    },
    "Murray St.": {
      "adjDef": 96,
      "adjDefRank": 51,
      "adjEM": 8.56,
      "adjOff": 104.5,
      "adjOffRank": 140,
      "adjTempo": 63.2,
      "adjTempoRank": 256,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 89,
      "record": "24-7",
      "seed": 14
    },
    "N.C. State": {
      "adjDef": 96.6,
      "adjDefRank": 60,
      "adjEM": 15.92,
      "adjOff": 112.5,
      "adjOffRank": 32,
      "adjTempo": 64.4,
      "adjTempoRank": 216,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 30,
      "record": "22-10",
      "seed": 10
    },
    "Navy": {
      "adjDef": 109.2,
      "adjDefRank": 278,
      "adjEM": -15.56,
      "adjOff": 93.7,
      "adjOffRank": 299,
      "adjTempo": 69.8,
      "adjTempoRank": 32,
      "conference": "Pat",
      "rank": 304,
      "record": "10-18"
    },
    "Nebraska": {
      "adjDef": 97.1,
      "adjDefRank": 70,
      "adjEM": 5.84,
      "adjOff": 102.9,
      "adjOffRank": 167,
      "adjTempo": 64.6,
      "adjTempoRank": 209,
      "conference": "B12",
      "rank": 111,
      "record": "19-14"
    },
    "Nevada": {
      "adjDef": 94.8,
      "adjDefRank": 39,
      "adjEM": 15.22,
      "adjOff": 110,
      "adjOffRank": 57,
      "adjTempo": 65.8,
      "adjTempoRank": 137,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 37,
      "record": "27-6",
      "seed": 5
    },
    "New Hampshire": {
      "adjDef": 109.3,
      "adjDefRank": 279,
      "adjEM": -11.16,
      "adjOff": 98.2,
      "adjOffRank": 244,
      "adjTempo": 58.7,
      "adjTempoRank": 325,
      "conference": "AE",
      "rank": 277,
      "record": "12-17"
    },
    "New Mexico": {
      "adjDef": 100.1,
      "adjDefRank": 117,
      "adjEM": 5.3,
      "adjOff": 105.4,
      "adjOffRank": 123,
      "adjTempo": 60.2,
      "adjTempoRank": 314,
      "conference": "MWC",
      "rank": 115,
      "record": "17-13"
    },
    "New Mexico St.": {
      "adjDef": 101.4,
      "adjDefRank": 135,
      "adjEM": 4.23,
      "adjOff": 105.6,
      "adjOffRank": 121,
      "adjTempo": 66.9,
      "adjTempoRank": 103,
      "conference": "WAC",
      "rank": 126,
      "record": "16-14"
    },
    "New Orleans": {
      "adjDef": 105.6,
      "adjDefRank": 212,
      "adjEM": -6.53,
      "adjOff": 99.1,
      "adjOffRank": 231,
      "adjTempo": 63,
      "adjTempoRank": 259,
      "conference": "SB",
      "rank": 231,
      "record": "10-19"
    },
    "Niagara": {
      "adjDef": 108.4,
      "adjDefRank": 266,
      "adjEM": -1.99,
      "adjOff": 106.4,
      "adjOffRank": 110,
      "adjTempo": 65.2,
      "adjTempoRank": 170,
      "conference": "MAAC",
      "rank": 181,
      "record": "11-18"
    },
    "Nicholls St.": {
      "adjDef": 112.7,
      "adjDefRank": 313,
      "adjEM": -15.91,
      "adjOff": 96.8,
      "adjOffRank": 268,
      "adjTempo": 69.1,
      "adjTempoRank": 45,
      "conference": "Slnd",
      "rank": 307,
      "record": "9-18"
    },
    "Norfolk St.": {
      "adjDef": 104,
      "adjDefRank": 186,
      "adjEM": -13.1,
      "adjOff": 90.9,
      "adjOffRank": 323,
      "adjTempo": 65.2,
      "adjTempoRank": 172,
      "conference": "MEAC",
      "rank": 288,
      "record": "13-18"
    },
    "North Carolina": {
      "adjDef": 93.7,
      "adjDefRank": 28,
      "adjEM": 22.16,
      "adjOff": 115.9,
      "adjOffRank": 13,
      "adjTempo": 70.2,
      "adjTempoRank": 23,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 10,
      "record": "23-8",
      "seed": 3
    },
    "North Carolina A&T": {
      "adjDef": 105.6,
      "adjDefRank": 210,
      "adjEM": -19.52,
      "adjOff": 86,
      "adjOffRank": 331,
      "adjTempo": 66.9,
      "adjTempoRank": 104,
      "conference": "MEAC",
      "rank": 320,
      "record": "6-23"
    },
    "North Dakota St.": {
      "adjDef": 104.4,
      "adjDefRank": 194,
      "adjEM": -0.65,
      "adjOff": 103.8,
      "adjOffRank": 155,
      "adjTempo": 65,
      "adjTempoRank": 181,
      "conference": "ind",
      "rank": 163,
      "record": "16-12"
    },
    "North Florida": {
      "adjDef": 115.7,
      "adjDefRank": 331,
      "adjEM": -21.22,
      "adjOff": 94.5,
      "adjOffRank": 291,
      "adjTempo": 65.5,
      "adjTempoRank": 150,
      "conference": "ASun",
      "rank": 324,
      "record": "6-22"
    },
    "North Texas": {
      "adjDef": 106.9,
      "adjDefRank": 237,
      "adjEM": -4.45,
      "adjOff": 102.5,
      "adjOffRank": 178,
      "adjTempo": 71.8,
      "adjTempoRank": 9,
      "conference": "SB",
      "rank": 207,
      "record": "14-14"
    },
    "Northeastern": {
      "adjDef": 101.1,
      "adjDefRank": 129,
      "adjEM": 5.9,
      "adjOff": 107,
      "adjOffRank": 97,
      "adjTempo": 69.1,
      "adjTempoRank": 44,
      "conference": "CAA",
      "rank": 110,
      "record": "19-11"
    },
    "Northern Arizona": {
      "adjDef": 104,
      "adjDefRank": 185,
      "adjEM": -0.04,
      "adjOff": 103.9,
      "adjOffRank": 153,
      "adjTempo": 69.2,
      "adjTempoRank": 40,
      "conference": "BSky",
      "rank": 157,
      "record": "21-11"
    },
    "Northern Colorado": {
      "adjDef": 115.8,
      "adjDefRank": 332,
      "adjEM": -15.43,
      "adjOff": 100.3,
      "adjOffRank": 212,
      "adjTempo": 65.1,
      "adjTempoRank": 173,
      "conference": "ind",
      "rank": 302,
      "record": "5-24"
    },
    "Northern Illinois": {
      "adjDef": 107.7,
      "adjDefRank": 252,
      "adjEM": 5.1,
      "adjOff": 112.7,
      "adjOffRank": 30,
      "adjTempo": 61.9,
      "adjTempoRank": 292,
      "conference": "MAC",
      "rank": 120,
      "record": "17-11"
    },
    "Northern Iowa": {
      "adjDef": 93.1,
      "adjDefRank": 21,
      "adjEM": 15.84,
      "adjOff": 109,
      "adjOffRank": 75,
      "adjTempo": 59.6,
      "adjTempoRank": 321,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 32,
      "record": "23-10",
      "seed": 10
    },
    "Northwestern": {
      "adjDef": 97.9,
      "adjDefRank": 84,
      "adjEM": 4.86,
      "adjOff": 102.8,
      "adjOffRank": 169,
      "adjTempo": 58.2,
      "adjTempoRank": 328,
      "conference": "B10",
      "rank": 123,
      "record": "14-15"
    },
    "Northwestern St.": {
      "adjDef": 99.1,
      "adjDefRank": 98,
      "adjEM": 7.51,
      "adjOff": 106.6,
      "adjOffRank": 105,
      "adjTempo": 67.6,
      "adjTempoRank": 79,
      "conference": "Slnd",
      "finish": "Round of 32",
      "rank": 93,
      "record": "26-8",
      "seed": 14
    },
    "Notre Dame": {
      "adjDef": 101.4,
      "adjDefRank": 136,
      "adjEM": 15.97,
      "adjOff": 117.3,
      "adjOffRank": 7,
      "adjTempo": 64.2,
      "adjTempoRank": 222,
      "conference": "BE",
      "rank": 29,
      "record": "16-14"
    },
    "Oakland": {
      "adjDef": 114.3,
      "adjDefRank": 324,
      "adjEM": -10.34,
      "adjOff": 103.9,
      "adjOffRank": 152,
      "adjTempo": 67.8,
      "adjTempoRank": 74,
      "conference": "MCon",
      "rank": 270,
      "record": "11-18"
    },
    "Ohio": {
      "adjDef": 100.4,
      "adjDefRank": 120,
      "adjEM": 6.01,
      "adjOff": 106.4,
      "adjOffRank": 108,
      "adjTempo": 64.3,
      "adjTempoRank": 219,
      "conference": "MAC",
      "rank": 109,
      "record": "19-11"
    },
    "Ohio St.": {
      "adjDef": 92.3,
      "adjDefRank": 19,
      "adjEM": 20.68,
      "adjOff": 113,
      "adjOffRank": 25,
      "adjTempo": 65.2,
      "adjTempoRank": 169,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 14,
      "record": "26-6",
      "seed": 2
    },
    "Oklahoma": {
      "adjDef": 99,
      "adjDefRank": 95,
      "adjEM": 13.99,
      "adjOff": 113,
      "adjOffRank": 26,
      "adjTempo": 62.4,
      "adjTempoRank": 278,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 45,
      "record": "20-9",
      "seed": 6
    },
    "Oklahoma St.": {
      "adjDef": 95.2,
      "adjDefRank": 45,
      "adjEM": 12.76,
      "adjOff": 107.9,
      "adjOffRank": 80,
      "adjTempo": 64.7,
      "adjTempoRank": 193,
      "conference": "B12",
      "rank": 53,
      "record": "17-16"
    },
    "Old Dominion": {
      "adjDef": 95.7,
      "adjDefRank": 50,
      "adjEM": 11.15,
      "adjOff": 106.9,
      "adjOffRank": 99,
      "adjTempo": 65,
      "adjTempoRank": 177,
      "conference": "CAA",
      "rank": 67,
      "record": "24-10"
    },
    "Oral Roberts": {
      "adjDef": 101.4,
      "adjDefRank": 134,
      "adjEM": 5.7,
      "adjOff": 107.1,
      "adjOffRank": 95,
      "adjTempo": 66.9,
      "adjTempoRank": 105,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 112,
      "record": "21-12",
      "seed": 16
    },
    "Oregon": {
      "adjDef": 100.6,
      "adjDefRank": 124,
      "adjEM": 11.31,
      "adjOff": 111.9,
      "adjOffRank": 37,
      "adjTempo": 63.6,
      "adjTempoRank": 244,
      "conference": "P10",
      "rank": 66,
      "record": "15-18"
    },
    "Oregon St.": {
      "adjDef": 103.8,
      "adjDefRank": 184,
      "adjEM": 0.58,
      "adjOff": 104.4,
      "adjOffRank": 143,
      "adjTempo": 64.5,
      "adjTempoRank": 210,
      "conference": "P10",
      "rank": 149,
      "record": "13-18"
    },
    "Pacific": {
      "adjDef": 99,
      "adjDefRank": 94,
      "adjEM": 10.87,
      "adjOff": 109.8,
      "adjOffRank": 63,
      "adjTempo": 62.8,
      "adjTempoRank": 262,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 68,
      "record": "24-8",
      "seed": 13
    },
    "Penn": {
      "adjDef": 94.5,
      "adjDefRank": 34,
      "adjEM": 7.3,
      "adjOff": 101.8,
      "adjOffRank": 188,
      "adjTempo": 64.3,
      "adjTempoRank": 220,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 97,
      "record": "20-9",
      "seed": 15
    },
    "Penn St.": {
      "adjDef": 104.2,
      "adjDefRank": 192,
      "adjEM": 5.27,
      "adjOff": 109.5,
      "adjOffRank": 69,
      "adjTempo": 63.8,
      "adjTempoRank": 240,
      "conference": "B10",
      "rank": 116,
      "record": "15-15"
    },
    "Pepperdine": {
      "adjDef": 101.4,
      "adjDefRank": 137,
      "adjEM": -4.31,
      "adjOff": 97.1,
      "adjOffRank": 259,
      "adjTempo": 66.7,
      "adjTempoRank": 107,
      "conference": "WCC",
      "rank": 204,
      "record": "7-20"
    },
    "Pittsburgh": {
      "adjDef": 90.6,
      "adjDefRank": 9,
      "adjEM": 21.72,
      "adjOff": 112.4,
      "adjOffRank": 33,
      "adjTempo": 64.6,
      "adjTempoRank": 203,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 12,
      "record": "25-8",
      "seed": 5
    },
    "Portland": {
      "adjDef": 107.7,
      "adjDefRank": 255,
      "adjEM": -5.14,
      "adjOff": 102.6,
      "adjOffRank": 175,
      "adjTempo": 65.6,
      "adjTempoRank": 144,
      "conference": "WCC",
      "rank": 212,
      "record": "11-18"
    },
    "Portland St.": {
      "adjDef": 102.6,
      "adjDefRank": 159,
      "adjEM": -0.59,
      "adjOff": 102.1,
      "adjOffRank": 185,
      "adjTempo": 65.6,
      "adjTempoRank": 149,
      "conference": "BSky",
      "rank": 162,
      "record": "12-16"
    },
    "Prairie View A&M": {
      "adjDef": 114.6,
      "adjDefRank": 326,
      "adjEM": -27.79,
      "adjOff": 86.9,
      "adjOffRank": 330,
      "adjTempo": 71.3,
      "adjTempoRank": 11,
      "conference": "SWAC",
      "rank": 332,
      "record": "5-24"
    },
    "Princeton": {
      "adjDef": 106.4,
      "adjDefRank": 232,
      "adjEM": -9.35,
      "adjOff": 97,
      "adjOffRank": 264,
      "adjTempo": 52.9,
      "adjTempoRank": 334,
      "conference": "Ivy",
      "rank": 265,
      "record": "12-15"
    },
    "Providence": {
      "adjDef": 102.7,
      "adjDefRank": 162,
      "adjEM": 9.47,
      "adjOff": 112.2,
      "adjOffRank": 36,
      "adjTempo": 68.7,
      "adjTempoRank": 50,
      "conference": "BE",
      "rank": 79,
      "record": "12-15"
    },
    "Purdue": {
      "adjDef": 101.7,
      "adjDefRank": 143,
      "adjEM": 0.14,
      "adjOff": 101.9,
      "adjOffRank": 186,
      "adjTempo": 65.5,
      "adjTempoRank": 153,
      "conference": "B10",
      "rank": 155,
      "record": "9-19"
    },
    "Quinnipiac": {
      "adjDef": 106.8,
      "adjDefRank": 236,
      "adjEM": -8.19,
      "adjOff": 98.6,
      "adjOffRank": 236,
      "adjTempo": 69.9,
      "adjTempoRank": 26,
      "conference": "NEC",
      "rank": 253,
      "record": "12-16"
    },
    "Radford": {
      "adjDef": 110.6,
      "adjDefRank": 294,
      "adjEM": -6.48,
      "adjOff": 104.1,
      "adjOffRank": 148,
      "adjTempo": 67,
      "adjTempoRank": 100,
      "conference": "BSth",
      "rank": 230,
      "record": "16-13"
    },
    "Rhode Island": {
      "adjDef": 103.4,
      "adjDefRank": 171,
      "adjEM": 0.55,
      "adjOff": 104,
      "adjOffRank": 150,
      "adjTempo": 62.3,
      "adjTempoRank": 280,
      "conference": "A10",
      "rank": 150,
      "record": "14-14"
    },
    "Rice": {
      "adjDef": 105.6,
      "adjDefRank": 209,
      "adjEM": -5.36,
      "adjOff": 100.2,
      "adjOffRank": 216,
      "adjTempo": 65.3,
      "adjTempoRank": 165,
      "conference": "CUSA",
      "rank": 217,
      "record": "12-16"
    },
    "Richmond": {
      "adjDef": 101.4,
      "adjDefRank": 138,
      "adjEM": -7.85,
      "adjOff": 93.6,
      "adjOffRank": 301,
      "adjTempo": 55.2,
      "adjTempoRank": 331,
      "conference": "A10",
      "rank": 246,
      "record": "13-17"
    },
    "Rider": {
      "adjDef": 107.3,
      "adjDefRank": 245,
      "adjEM": -6.39,
      "adjOff": 100.9,
      "adjOffRank": 204,
      "adjTempo": 67.6,
      "adjTempoRank": 82,
      "conference": "MAAC",
      "rank": 229,
      "record": "8-20"
    },
    "Robert Morris": {
      "adjDef": 111.3,
      "adjDefRank": 307,
      "adjEM": -7.3,
      "adjOff": 104,
      "adjOffRank": 149,
      "adjTempo": 66.7,
      "adjTempoRank": 111,
      "conference": "NEC",
      "rank": 240,
      "record": "15-14"
    },
    "Rutgers": {
      "adjDef": 99.1,
      "adjDefRank": 97,
      "adjEM": 10.41,
      "adjOff": 109.5,
      "adjOffRank": 68,
      "adjTempo": 64,
      "adjTempoRank": 230,
      "conference": "BE",
      "rank": 71,
      "record": "19-14"
    },
    "SMU": {
      "adjDef": 100.7,
      "adjDefRank": 125,
      "adjEM": -2.4,
      "adjOff": 98.3,
      "adjOffRank": 242,
      "adjTempo": 64.6,
      "adjTempoRank": 208,
      "conference": "CUSA",
      "rank": 187,
      "record": "13-16"
    },
    "Sacramento St.": {
      "adjDef": 106.7,
      "adjDefRank": 235,
      "adjEM": -0.01,
      "adjOff": 106.7,
      "adjOffRank": 102,
      "adjTempo": 67.9,
      "adjTempoRank": 72,
      "conference": "BSky",
      "rank": 156,
      "record": "15-15"
    },
    "Sacred Heart": {
      "adjDef": 106.2,
      "adjDefRank": 226,
      "adjEM": -8.57,
      "adjOff": 97.6,
      "adjOffRank": 250,
      "adjTempo": 65.5,
      "adjTempoRank": 152,
      "conference": "NEC",
      "rank": 256,
      "record": "11-17"
    },
    "Saint Joseph's": {
      "adjDef": 95,
      "adjDefRank": 42,
      "adjEM": 14.84,
      "adjOff": 109.8,
      "adjOffRank": 64,
      "adjTempo": 61.9,
      "adjTempoRank": 290,
      "conference": "A10",
      "rank": 41,
      "record": "19-14"
    },
    "Saint Louis": {
      "adjDef": 94.6,
      "adjDefRank": 37,
      "adjEM": 4.56,
      "adjOff": 99.2,
      "adjOffRank": 229,
      "adjTempo": 61,
      "adjTempoRank": 306,
      "conference": "A10",
      "rank": 125,
      "record": "16-13"
    },
    "Saint Mary's": {
      "adjDef": 99.3,
      "adjDefRank": 101,
      "adjEM": 6.46,
      "adjOff": 105.8,
      "adjOffRank": 118,
      "adjTempo": 64.2,
      "adjTempoRank": 221,
      "conference": "WCC",
      "rank": 106,
      "record": "17-12"
    },
    "Saint Peter's": {
      "adjDef": 105.2,
      "adjDefRank": 202,
      "adjEM": 1,
      "adjOff": 106.2,
      "adjOffRank": 111,
      "adjTempo": 66.6,
      "adjTempoRank": 115,
      "conference": "MAAC",
      "rank": 146,
      "record": "17-15"
    },
    "Sam Houston St.": {
      "adjDef": 103.5,
      "adjDefRank": 177,
      "adjEM": -2.01,
      "adjOff": 101.5,
      "adjOffRank": 192,
      "adjTempo": 67.9,
      "adjTempoRank": 69,
      "conference": "Slnd",
      "rank": 182,
      "record": "22-9"
    },
    "Samford": {
      "adjDef": 107,
      "adjDefRank": 238,
      "adjEM": 3.05,
      "adjOff": 110,
      "adjOffRank": 56,
      "adjTempo": 55.8,
      "adjTempoRank": 330,
      "conference": "OVC",
      "rank": 133,
      "record": "20-11"
    },
    "San Diego": {
      "adjDef": 101.7,
      "adjDefRank": 144,
      "adjEM": 5.14,
      "adjOff": 106.9,
      "adjOffRank": 100,
      "adjTempo": 69.6,
      "adjTempoRank": 35,
      "conference": "WCC",
      "rank": 119,
      "record": "18-12"
    },
    "San Diego St.": {
      "adjDef": 100,
      "adjDefRank": 114,
      "adjEM": 12.78,
      "adjOff": 112.8,
      "adjOffRank": 29,
      "adjTempo": 66.1,
      "adjTempoRank": 130,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 52,
      "record": "24-9",
      "seed": 11
    },
    "San Francisco": {
      "adjDef": 103.2,
      "adjDefRank": 169,
      "adjEM": 0.28,
      "adjOff": 103.5,
      "adjOffRank": 161,
      "adjTempo": 64.1,
      "adjTempoRank": 225,
      "conference": "WCC",
      "rank": 152,
      "record": "11-17"
    },
    "San Jose St.": {
      "adjDef": 99.3,
      "adjDefRank": 102,
      "adjEM": -3.49,
      "adjOff": 95.8,
      "adjOffRank": 277,
      "adjTempo": 67.9,
      "adjTempoRank": 70,
      "conference": "WAC",
      "rank": 198,
      "record": "6-25"
    },
    "Santa Clara": {
      "adjDef": 101.1,
      "adjDefRank": 130,
      "adjEM": -0.41,
      "adjOff": 100.7,
      "adjOffRank": 206,
      "adjTempo": 64.8,
      "adjTempoRank": 188,
      "conference": "WCC",
      "rank": 160,
      "record": "13-16"
    },
    "Savannah St.": {
      "adjDef": 118.6,
      "adjDefRank": 334,
      "adjEM": -38.11,
      "adjOff": 80.4,
      "adjOffRank": 333,
      "adjTempo": 67.7,
      "adjTempoRank": 76,
      "conference": "ind",
      "rank": 334,
      "record": "2-28"
    },
    "Seton Hall": {
      "adjDef": 97.4,
      "adjDefRank": 77,
      "adjEM": 9.06,
      "adjOff": 106.5,
      "adjOffRank": 107,
      "adjTempo": 65.7,
      "adjTempoRank": 138,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 86,
      "record": "18-12",
      "seed": 10
    },
    "Siena": {
      "adjDef": 101.9,
      "adjDefRank": 148,
      "adjEM": 3.11,
      "adjOff": 105,
      "adjOffRank": 133,
      "adjTempo": 67,
      "adjTempoRank": 99,
      "conference": "MAAC",
      "rank": 132,
      "record": "15-13"
    },
    "South Alabama": {
      "adjDef": 99.9,
      "adjDefRank": 113,
      "adjEM": 9.28,
      "adjOff": 109.1,
      "adjOffRank": 72,
      "adjTempo": 65.7,
      "adjTempoRank": 140,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 84,
      "record": "24-7",
      "seed": 14
    },
    "South Carolina": {
      "adjDef": 96.4,
      "adjDefRank": 55,
      "adjEM": 18.54,
      "adjOff": 114.9,
      "adjOffRank": 14,
      "adjTempo": 59.2,
      "adjTempoRank": 324,
      "conference": "SEC",
      "rank": 19,
      "record": "23-15"
    },
    "South Carolina St.": {
      "adjDef": 103.4,
      "adjDefRank": 172,
      "adjEM": -11.06,
      "adjOff": 92.4,
      "adjOffRank": 310,
      "adjTempo": 64.4,
      "adjTempoRank": 214,
      "conference": "MEAC",
      "rank": 275,
      "record": "14-16"
    },
    "South Dakota St.": {
      "adjDef": 106.7,
      "adjDefRank": 234,
      "adjEM": -15.04,
      "adjOff": 91.6,
      "adjOffRank": 316,
      "adjTempo": 67.2,
      "adjTempoRank": 96,
      "conference": "ind",
      "rank": 299,
      "record": "9-20"
    },
    "South Florida": {
      "adjDef": 96.7,
      "adjDefRank": 64,
      "adjEM": -1.01,
      "adjOff": 95.7,
      "adjOffRank": 280,
      "adjTempo": 61.6,
      "adjTempoRank": 297,
      "conference": "BE",
      "rank": 171,
      "record": "7-22"
    },
    "Southeast Missouri St.": {
      "adjDef": 109.3,
      "adjDefRank": 281,
      "adjEM": -16.32,
      "adjOff": 93,
      "adjOffRank": 305,
      "adjTempo": 64.6,
      "adjTempoRank": 205,
      "conference": "OVC",
      "rank": 309,
      "record": "7-20"
    },
    "Southeastern Louisiana": {
      "adjDef": 103.6,
      "adjDefRank": 181,
      "adjEM": -2.64,
      "adjOff": 101,
      "adjOffRank": 202,
      "adjTempo": 61.6,
      "adjTempoRank": 300,
      "conference": "Slnd",
      "rank": 189,
      "record": "16-12"
    },
    "Southern": {
      "adjDef": 99.6,
      "adjDefRank": 106,
      "adjEM": -3.4,
      "adjOff": 96.2,
      "adjOffRank": 274,
      "adjTempo": 64.8,
      "adjTempoRank": 185,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 197,
      "record": "19-13",
      "seed": 16
    },
    "Southern Illinois": {
      "adjDef": 89.3,
      "adjDefRank": 5,
      "adjEM": 13.6,
      "adjOff": 102.9,
      "adjOffRank": 166,
      "adjTempo": 60.7,
      "adjTempoRank": 311,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 48,
      "record": "22-11",
      "seed": 11
    },
    "Southern Miss": {
      "adjDef": 108.6,
      "adjDefRank": 268,
      "adjEM": -11.24,
      "adjOff": 97.4,
      "adjOffRank": 256,
      "adjTempo": 59.9,
      "adjTempoRank": 319,
      "conference": "CUSA",
      "rank": 278,
      "record": "10-21"
    },
    "Southern Utah": {
      "adjDef": 109.6,
      "adjDefRank": 285,
      "adjEM": -7.93,
      "adjOff": 101.7,
      "adjOffRank": 190,
      "adjTempo": 64.6,
      "adjTempoRank": 207,
      "conference": "MCon",
      "rank": 248,
      "record": "10-20"
    },
    "St. Bonaventure": {
      "adjDef": 107.5,
      "adjDefRank": 250,
      "adjEM": -7.24,
      "adjOff": 100.3,
      "adjOffRank": 214,
      "adjTempo": 67.5,
      "adjTempoRank": 85,
      "conference": "A10",
      "rank": 237,
      "record": "8-19"
    },
    "St. Francis NY": {
      "adjDef": 102.7,
      "adjDefRank": 160,
      "adjEM": -9.51,
      "adjOff": 93.1,
      "adjOffRank": 303,
      "adjTempo": 64.9,
      "adjTempoRank": 184,
      "conference": "NEC",
      "rank": 267,
      "record": "10-17"
    },
    "St. Francis PA": {
      "adjDef": 115.4,
      "adjDefRank": 328,
      "adjEM": -21.49,
      "adjOff": 93.9,
      "adjOffRank": 298,
      "adjTempo": 60.5,
      "adjTempoRank": 312,
      "conference": "NEC",
      "rank": 326,
      "record": "4-24"
    },
    "St. John's": {
      "adjDef": 92.3,
      "adjDefRank": 17,
      "adjEM": 5.27,
      "adjOff": 97.6,
      "adjOffRank": 252,
      "adjTempo": 62.5,
      "adjTempoRank": 274,
      "conference": "BE",
      "rank": 117,
      "record": "12-15"
    },
    "Stanford": {
      "adjDef": 96.4,
      "adjDefRank": 56,
      "adjEM": 9.56,
      "adjOff": 105.9,
      "adjOffRank": 115,
      "adjTempo": 64.3,
      "adjTempoRank": 218,
      "conference": "P10",
      "rank": 78,
      "record": "16-14"
    },
    "Stephen F. Austin": {
      "adjDef": 105,
      "adjDefRank": 198,
      "adjEM": -2.23,
      "adjOff": 102.8,
      "adjOffRank": 172,
      "adjTempo": 63.4,
      "adjTempoRank": 248,
      "conference": "Slnd",
      "rank": 185,
      "record": "17-12"
    },
    "Stetson": {
      "adjDef": 107.5,
      "adjDefRank": 248,
      "adjEM": -11.9,
      "adjOff": 95.6,
      "adjOffRank": 284,
      "adjTempo": 62.7,
      "adjTempoRank": 263,
      "conference": "ASun",
      "rank": 281,
      "record": "14-18"
    },
    "Stony Brook": {
      "adjDef": 109.1,
      "adjDefRank": 275,
      "adjEM": -16.88,
      "adjOff": 92.2,
      "adjOffRank": 311,
      "adjTempo": 64.5,
      "adjTempoRank": 211,
      "conference": "AE",
      "rank": 313,
      "record": "4-24"
    },
    "Syracuse": {
      "adjDef": 96.8,
      "adjDefRank": 67,
      "adjEM": 13.15,
      "adjOff": 110,
      "adjOffRank": 60,
      "adjTempo": 68.3,
      "adjTempoRank": 63,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 50,
      "record": "23-12",
      "seed": 5
    },
    "TCU": {
      "adjDef": 101.2,
      "adjDefRank": 133,
      "adjEM": -7.26,
      "adjOff": 94,
      "adjOffRank": 296,
      "adjTempo": 66.5,
      "adjTempoRank": 117,
      "conference": "MWC",
      "rank": 239,
      "record": "6-25"
    },
    "Temple": {
      "adjDef": 97.7,
      "adjDefRank": 81,
      "adjEM": 11.38,
      "adjOff": 109.1,
      "adjOffRank": 73,
      "adjTempo": 59.9,
      "adjTempoRank": 320,
      "conference": "A10",
      "rank": 64,
      "record": "17-15"
    },
    "Tennessee": {
      "adjDef": 97.2,
      "adjDefRank": 72,
      "adjEM": 19.44,
      "adjOff": 116.6,
      "adjOffRank": 8,
      "adjTempo": 71.1,
      "adjTempoRank": 13,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 17,
      "record": "22-8",
      "seed": 2
    },
    "Tennessee Martin": {
      "adjDef": 109.2,
      "adjDefRank": 276,
      "adjEM": -5.88,
      "adjOff": 103.3,
      "adjOffRank": 164,
      "adjTempo": 67.5,
      "adjTempoRank": 83,
      "conference": "OVC",
      "rank": 222,
      "record": "13-15"
    },
    "Tennessee St.": {
      "adjDef": 108.7,
      "adjDefRank": 271,
      "adjEM": -4.54,
      "adjOff": 104.2,
      "adjOffRank": 147,
      "adjTempo": 65,
      "adjTempoRank": 180,
      "conference": "OVC",
      "rank": 208,
      "record": "13-15"
    },
    "Tennessee Tech": {
      "adjDef": 104,
      "adjDefRank": 187,
      "adjEM": -3.63,
      "adjOff": 100.4,
      "adjOffRank": 210,
      "adjTempo": 68.4,
      "adjTempoRank": 58,
      "conference": "OVC",
      "rank": 202,
      "record": "19-12"
    },
    "Texas": {
      "adjDef": 91.7,
      "adjDefRank": 15,
      "adjEM": 25.79,
      "adjOff": 117.5,
      "adjOffRank": 6,
      "adjTempo": 63.9,
      "adjTempoRank": 233,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "30-7",
      "seed": 2
    },
    "Texas A&M": {
      "adjDef": 90.8,
      "adjDefRank": 10,
      "adjEM": 15.9,
      "adjOff": 106.7,
      "adjOffRank": 103,
      "adjTempo": 63,
      "adjTempoRank": 260,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 31,
      "record": "22-9",
      "seed": 12
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 99.8,
      "adjDefRank": 109,
      "adjEM": 4.83,
      "adjOff": 104.6,
      "adjOffRank": 139,
      "adjTempo": 69.3,
      "adjTempoRank": 36,
      "conference": "ind",
      "rank": 124,
      "record": "20-8"
    },
    "Texas Pan American": {
      "adjDef": 110,
      "adjDefRank": 289,
      "adjEM": -21.53,
      "adjOff": 88.4,
      "adjOffRank": 328,
      "adjTempo": 65.5,
      "adjTempoRank": 151,
      "conference": "ind",
      "rank": 327,
      "record": "7-24"
    },
    "Texas Southern": {
      "adjDef": 111.3,
      "adjDefRank": 305,
      "adjEM": -19.15,
      "adjOff": 92.2,
      "adjOffRank": 312,
      "adjTempo": 69,
      "adjTempoRank": 47,
      "conference": "SWAC",
      "rank": 319,
      "record": "8-22"
    },
    "Texas St.": {
      "adjDef": 113,
      "adjDefRank": 316,
      "adjEM": -19.94,
      "adjOff": 93.1,
      "adjOffRank": 304,
      "adjTempo": 65.5,
      "adjTempoRank": 156,
      "conference": "Slnd",
      "rank": 321,
      "record": "3-24"
    },
    "Texas Tech": {
      "adjDef": 100.6,
      "adjDefRank": 123,
      "adjEM": 5.03,
      "adjOff": 105.6,
      "adjOffRank": 122,
      "adjTempo": 65.9,
      "adjTempoRank": 134,
      "conference": "B12",
      "rank": 121,
      "record": "15-17"
    },
    "The Citadel": {
      "adjDef": 115.4,
      "adjDefRank": 329,
      "adjEM": -24.46,
      "adjOff": 91,
      "adjOffRank": 322,
      "adjTempo": 64.8,
      "adjTempoRank": 186,
      "conference": "SC",
      "rank": 330,
      "record": "10-21"
    },
    "Toledo": {
      "adjDef": 96.2,
      "adjDefRank": 52,
      "adjEM": 5.17,
      "adjOff": 101.4,
      "adjOffRank": 194,
      "adjTempo": 67.5,
      "adjTempoRank": 89,
      "conference": "MAC",
      "rank": 118,
      "record": "20-11"
    },
    "Towson": {
      "adjDef": 111.1,
      "adjDefRank": 301,
      "adjEM": -7.97,
      "adjOff": 103.1,
      "adjOffRank": 165,
      "adjTempo": 67.4,
      "adjTempoRank": 92,
      "conference": "CAA",
      "rank": 249,
      "record": "12-16"
    },
    "Troy": {
      "adjDef": 106.3,
      "adjDefRank": 229,
      "adjEM": -0.89,
      "adjOff": 105.4,
      "adjOffRank": 126,
      "adjTempo": 64.8,
      "adjTempoRank": 190,
      "conference": "SB",
      "rank": 168,
      "record": "14-15"
    },
    "Tulane": {
      "adjDef": 99.6,
      "adjDefRank": 107,
      "adjEM": -4.93,
      "adjOff": 94.7,
      "adjOffRank": 290,
      "adjTempo": 65.9,
      "adjTempoRank": 135,
      "conference": "CUSA",
      "rank": 209,
      "record": "12-17"
    },
    "Tulsa": {
      "adjDef": 99.2,
      "adjDefRank": 100,
      "adjEM": -5.05,
      "adjOff": 94.2,
      "adjOffRank": 293,
      "adjTempo": 64.4,
      "adjTempoRank": 215,
      "conference": "CUSA",
      "rank": 211,
      "record": "11-17"
    },
    "UAB": {
      "adjDef": 96.5,
      "adjDefRank": 59,
      "adjEM": 12.65,
      "adjOff": 109.1,
      "adjOffRank": 71,
      "adjTempo": 68.5,
      "adjTempoRank": 53,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 56,
      "record": "24-7",
      "seed": 9
    },
    "UC Davis": {
      "adjDef": 111.2,
      "adjDefRank": 302,
      "adjEM": -12.64,
      "adjOff": 98.6,
      "adjOffRank": 237,
      "adjTempo": 62.5,
      "adjTempoRank": 275,
      "conference": "ind",
      "rank": 287,
      "record": "8-20"
    },
    "UC Irvine": {
      "adjDef": 106.6,
      "adjDefRank": 233,
      "adjEM": 3.38,
      "adjOff": 110,
      "adjOffRank": 59,
      "adjTempo": 61.7,
      "adjTempoRank": 295,
      "conference": "BW",
      "rank": 130,
      "record": "16-13"
    },
    "UC Riverside": {
      "adjDef": 106.1,
      "adjDefRank": 225,
      "adjEM": -15.5,
      "adjOff": 90.6,
      "adjOffRank": 325,
      "adjTempo": 70.2,
      "adjTempoRank": 22,
      "conference": "BW",
      "rank": 303,
      "record": "5-23"
    },
    "UC Santa Barbara": {
      "adjDef": 103.3,
      "adjDefRank": 170,
      "adjEM": -1.05,
      "adjOff": 102.2,
      "adjOffRank": 182,
      "adjTempo": 62,
      "adjTempoRank": 289,
      "conference": "BW",
      "rank": 174,
      "record": "15-14"
    },
    "UCF": {
      "adjDef": 102.6,
      "adjDefRank": 158,
      "adjEM": -2.74,
      "adjOff": 99.9,
      "adjOffRank": 219,
      "adjTempo": 62,
      "adjTempoRank": 288,
      "conference": "CUSA",
      "rank": 190,
      "record": "14-15"
    },
    "UCLA": {
      "adjDef": 89.3,
      "adjDefRank": 4,
      "adjEM": 23.4,
      "adjOff": 112.7,
      "adjOffRank": 31,
      "adjTempo": 61.6,
      "adjTempoRank": 298,
      "conference": "P10",
      "finish": "Runner-up",
      "rank": 7,
      "record": "32-7",
      "seed": 2
    },
    "UMBC": {
      "adjDef": 110.6,
      "adjDefRank": 295,
      "adjEM": -9.49,
      "adjOff": 101.1,
      "adjOffRank": 198,
      "adjTempo": 65,
      "adjTempoRank": 182,
      "conference": "AE",
      "rank": 266,
      "record": "10-19"
    },
    "UMKC": {
      "adjDef": 113.4,
      "adjDefRank": 318,
      "adjEM": -11.28,
      "adjOff": 102.1,
      "adjOffRank": 183,
      "adjTempo": 65.6,
      "adjTempoRank": 148,
      "conference": "MCon",
      "rank": 279,
      "record": "14-14"
    },
    "UNC Asheville": {
      "adjDef": 111.1,
      "adjDefRank": 300,
      "adjEM": -14.03,
      "adjOff": 97,
      "adjOffRank": 262,
      "adjTempo": 67.5,
      "adjTempoRank": 88,
      "conference": "BSth",
      "rank": 295,
      "record": "9-19"
    },
    "UNC Greensboro": {
      "adjDef": 110.3,
      "adjDefRank": 292,
      "adjEM": -10.86,
      "adjOff": 99.5,
      "adjOffRank": 228,
      "adjTempo": 65.6,
      "adjTempoRank": 147,
      "conference": "SC",
      "rank": 273,
      "record": "12-19"
    },
    "UNC Wilmington": {
      "adjDef": 92.4,
      "adjDefRank": 20,
      "adjEM": 14.77,
      "adjOff": 107.2,
      "adjOffRank": 93,
      "adjTempo": 62.6,
      "adjTempoRank": 270,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 42,
      "record": "25-8",
      "seed": 9
    },
    "UNLV": {
      "adjDef": 96.8,
      "adjDefRank": 66,
      "adjEM": 8.57,
      "adjOff": 105.3,
      "adjOffRank": 127,
      "adjTempo": 66.5,
      "adjTempoRank": 116,
      "conference": "MWC",
      "rank": 88,
      "record": "17-13"
    },
    "USC": {
      "adjDef": 98.3,
      "adjDefRank": 88,
      "adjEM": 6.74,
      "adjOff": 105,
      "adjOffRank": 132,
      "adjTempo": 66.6,
      "adjTempoRank": 114,
      "conference": "P10",
      "rank": 102,
      "record": "17-13"
    },
    "UT Arlington": {
      "adjDef": 105.3,
      "adjDefRank": 205,
      "adjEM": -2.86,
      "adjOff": 102.5,
      "adjOffRank": 179,
      "adjTempo": 66.2,
      "adjTempoRank": 125,
      "conference": "Slnd",
      "rank": 191,
      "record": "14-16"
    },
    "UTEP": {
      "adjDef": 94.6,
      "adjDefRank": 35,
      "adjEM": 9.13,
      "adjOff": 103.7,
      "adjOffRank": 156,
      "adjTempo": 62.1,
      "adjTempoRank": 284,
      "conference": "CUSA",
      "rank": 85,
      "record": "21-10"
    },
    "UTSA": {
      "adjDef": 106,
      "adjDefRank": 222,
      "adjEM": -6.13,
      "adjOff": 99.9,
      "adjOffRank": 220,
      "adjTempo": 69.9,
      "adjTempoRank": 28,
      "conference": "Slnd",
      "rank": 226,
      "record": "11-17"
    },
    "Utah": {
      "adjDef": 102.4,
      "adjDefRank": 152,
      "adjEM": 2.47,
      "adjOff": 104.8,
      "adjOffRank": 134,
      "adjTempo": 60.2,
      "adjTempoRank": 315,
      "conference": "MWC",
      "rank": 140,
      "record": "14-15"
    },
    "Utah St.": {
      "adjDef": 102.6,
      "adjDefRank": 155,
      "adjEM": 10.86,
      "adjOff": 113.4,
      "adjOffRank": 20,
      "adjTempo": 60.9,
      "adjTempoRank": 309,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 69,
      "record": "23-9",
      "seed": 12
    },
    "Utah Valley St.": {
      "adjDef": 108.2,
      "adjDefRank": 264,
      "adjEM": -6.37,
      "adjOff": 101.8,
      "adjOffRank": 187,
      "adjTempo": 59.3,
      "adjTempoRank": 322,
      "conference": "ind",
      "rank": 228,
      "record": "16-13"
    },
    "VCU": {
      "adjDef": 100.5,
      "adjDefRank": 122,
      "adjEM": 6.63,
      "adjOff": 107.2,
      "adjOffRank": 92,
      "adjTempo": 62.1,
      "adjTempoRank": 286,
      "conference": "CAA",
      "rank": 104,
      "record": "19-10"
    },
    "VMI": {
      "adjDef": 109.5,
      "adjDefRank": 284,
      "adjEM": -17.85,
      "adjOff": 91.6,
      "adjOffRank": 317,
      "adjTempo": 71,
      "adjTempoRank": 16,
      "conference": "BSth",
      "rank": 316,
      "record": "7-20"
    },
    "Valparaiso": {
      "adjDef": 106.2,
      "adjDefRank": 227,
      "adjEM": -1.17,
      "adjOff": 105.1,
      "adjOffRank": 129,
      "adjTempo": 69.2,
      "adjTempoRank": 41,
      "conference": "MCon",
      "rank": 175,
      "record": "17-12"
    },
    "Vanderbilt": {
      "adjDef": 101.2,
      "adjDefRank": 131,
      "adjEM": 13.58,
      "adjOff": 114.7,
      "adjOffRank": 17,
      "adjTempo": 61.9,
      "adjTempoRank": 291,
      "conference": "SEC",
      "rank": 49,
      "record": "17-13"
    },
    "Vermont": {
      "adjDef": 101.9,
      "adjDefRank": 149,
      "adjEM": -3.38,
      "adjOff": 98.5,
      "adjOffRank": 239,
      "adjTempo": 64.7,
      "adjTempoRank": 197,
      "conference": "AE",
      "rank": 196,
      "record": "13-17"
    },
    "Villanova": {
      "adjDef": 91.7,
      "adjDefRank": 14,
      "adjEM": 24.59,
      "adjOff": 116.3,
      "adjOffRank": 11,
      "adjTempo": 65.6,
      "adjTempoRank": 143,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 5,
      "record": "28-5",
      "seed": 1
    },
    "Virginia": {
      "adjDef": 96.7,
      "adjDefRank": 65,
      "adjEM": 9.41,
      "adjOff": 106.1,
      "adjOffRank": 113,
      "adjTempo": 63.8,
      "adjTempoRank": 237,
      "conference": "ACC",
      "rank": 81,
      "record": "15-15"
    },
    "Virginia Tech": {
      "adjDef": 97.9,
      "adjDefRank": 83,
      "adjEM": 10.25,
      "adjOff": 108.2,
      "adjOffRank": 79,
      "adjTempo": 63.3,
      "adjTempoRank": 251,
      "conference": "ACC",
      "rank": 74,
      "record": "14-16"
    },
    "Wagner": {
      "adjDef": 105.8,
      "adjDefRank": 216,
      "adjEM": -8.48,
      "adjOff": 97.4,
      "adjOffRank": 257,
      "adjTempo": 65.3,
      "adjTempoRank": 162,
      "conference": "NEC",
      "rank": 254,
      "record": "13-14"
    },
    "Wake Forest": {
      "adjDef": 100.9,
      "adjDefRank": 126,
      "adjEM": 9.46,
      "adjOff": 110.4,
      "adjOffRank": 51,
      "adjTempo": 66.9,
      "adjTempoRank": 106,
      "conference": "ACC",
      "rank": 80,
      "record": "17-17"
    },
    "Washington": {
      "adjDef": 94.7,
      "adjDefRank": 38,
      "adjEM": 20.09,
      "adjOff": 114.8,
      "adjOffRank": 16,
      "adjTempo": 71.6,
      "adjTempoRank": 10,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 15,
      "record": "26-7",
      "seed": 5
    },
    "Washington St.": {
      "adjDef": 90.3,
      "adjDefRank": 8,
      "adjEM": 7.1,
      "adjOff": 97.4,
      "adjOffRank": 255,
      "adjTempo": 60.1,
      "adjTempoRank": 316,
      "conference": "P10",
      "rank": 99,
      "record": "11-17"
    },
    "Weber St.": {
      "adjDef": 107,
      "adjDefRank": 240,
      "adjEM": -8.01,
      "adjOff": 99,
      "adjOffRank": 232,
      "adjTempo": 62.6,
      "adjTempoRank": 271,
      "conference": "BSky",
      "rank": 250,
      "record": "10-17"
    },
    "West Virginia": {
      "adjDef": 96.6,
      "adjDefRank": 62,
      "adjEM": 19.49,
      "adjOff": 116.1,
      "adjOffRank": 12,
      "adjTempo": 62.5,
      "adjTempoRank": 276,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 16,
      "record": "22-11",
      "seed": 6
    },
    "Western Carolina": {
      "adjDef": 107.5,
      "adjDefRank": 249,
      "adjEM": -7.77,
      "adjOff": 99.7,
      "adjOffRank": 223,
      "adjTempo": 63.4,
      "adjTempoRank": 246,
      "conference": "SC",
      "rank": 244,
      "record": "13-17"
    },
    "Western Illinois": {
      "adjDef": 109.6,
      "adjDefRank": 287,
      "adjEM": -14.8,
      "adjOff": 94.8,
      "adjOffRank": 288,
      "adjTempo": 65.1,
      "adjTempoRank": 175,
      "conference": "MCon",
      "rank": 298,
      "record": "7-21"
    },
    "Western Kentucky": {
      "adjDef": 103.5,
      "adjDefRank": 175,
      "adjEM": 7.78,
      "adjOff": 111.3,
      "adjOffRank": 42,
      "adjTempo": 68.5,
      "adjTempoRank": 54,
      "conference": "SB",
      "rank": 92,
      "record": "23-8"
    },
    "Western Michigan": {
      "adjDef": 103.2,
      "adjDefRank": 168,
      "adjEM": -6.55,
      "adjOff": 96.6,
      "adjOffRank": 272,
      "adjTempo": 64.7,
      "adjTempoRank": 196,
      "conference": "MAC",
      "rank": 232,
      "record": "14-17"
    },
    "Wichita St.": {
      "adjDef": 95.7,
      "adjDefRank": 49,
      "adjEM": 16.06,
      "adjOff": 111.8,
      "adjOffRank": 38,
      "adjTempo": 62.6,
      "adjTempoRank": 267,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 26,
      "record": "26-9",
      "seed": 7
    },
    "William & Mary": {
      "adjDef": 105.7,
      "adjDefRank": 214,
      "adjEM": -7.05,
      "adjOff": 98.6,
      "adjOffRank": 235,
      "adjTempo": 65.3,
      "adjTempoRank": 166,
      "conference": "CAA",
      "rank": 235,
      "record": "8-20"
    },
    "Winthrop": {
      "adjDef": 94.2,
      "adjDefRank": 31,
      "adjEM": 10.27,
      "adjOff": 104.4,
      "adjOffRank": 141,
      "adjTempo": 64,
      "adjTempoRank": 232,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 73,
      "record": "23-8",
      "seed": 15
    },
    "Wisconsin": {
      "adjDef": 96.6,
      "adjDefRank": 61,
      "adjEM": 14.94,
      "adjOff": 111.5,
      "adjOffRank": 40,
      "adjTempo": 65.2,
      "adjTempoRank": 171,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 39,
      "record": "19-12",
      "seed": 9
    },
    "Wofford": {
      "adjDef": 107.7,
      "adjDefRank": 254,
      "adjEM": -8.13,
      "adjOff": 99.6,
      "adjOffRank": 226,
      "adjTempo": 63.2,
      "adjTempoRank": 255,
      "conference": "SC",
      "rank": 251,
      "record": "11-18"
    },
    "Wright St.": {
      "adjDef": 103,
      "adjDefRank": 167,
      "adjEM": -2.03,
      "adjOff": 101,
      "adjOffRank": 203,
      "adjTempo": 61,
      "adjTempoRank": 308,
      "conference": "Horz",
      "rank": 183,
      "record": "13-15"
    },
    "Wyoming": {
      "adjDef": 97.3,
      "adjDefRank": 74,
      "adjEM": 1.85,
      "adjOff": 99.1,
      "adjOffRank": 230,
      "adjTempo": 68.3,
      "adjTempoRank": 64,
      "conference": "MWC",
      "rank": 142,
      "record": "14-18"
    },
    "Xavier": {
      "adjDef": 98.2,
      "adjDefRank": 86,
      "adjEM": 12.73,
      "adjOff": 110.9,
      "adjOffRank": 48,
      "adjTempo": 65.3,
      "adjTempoRank": 163,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 54,
      "record": "21-11",
      "seed": 14
    },
    "Yale": {
      "adjDef": 104.7,
      "adjDefRank": 195,
      "adjEM": -8.72,
      "adjOff": 96,
      "adjOffRank": 275,
      "adjTempo": 68.4,
      "adjTempoRank": 60,
      "conference": "Ivy",
      "rank": 259,
      "record": "15-14"
    },
    "Youngstown St.": {
      "adjDef": 111.7,
      "adjDefRank": 309,
      "adjEM": -8.15,
      "adjOff": 103.5,
      "adjOffRank": 159,
      "adjTempo": 63.8,
      "adjTempoRank": 238,
      "conference": "Horz",
      "rank": 252,
      "record": "7-21"
    }
  },
  "2007": {
    "Air Force": {
      "adjDef": 96.3,
      "adjDefRank": 45,
      "adjEM": 20.2,
      "adjOff": 116.5,
      "adjOffRank": 20,
      "adjTempo": 57.5,
      "adjTempoRank": 332,
      "conference": "MWC",
      "rank": 20,
      "record": "26-9"
    },
    "Akron": {
      "adjDef": 99.1,
      "adjDefRank": 76,
      "adjEM": 16.15,
      "adjOff": 115.2,
      "adjOffRank": 31,
      "adjTempo": 63.8,
      "adjTempoRank": 245,
      "conference": "MAC",
      "rank": 45,
      "record": "26-7"
    },
    "Alabama": {
      "adjDef": 101.6,
      "adjDefRank": 116,
      "adjEM": 13.04,
      "adjOff": 114.7,
      "adjOffRank": 33,
      "adjTempo": 65.3,
      "adjTempoRank": 185,
      "conference": "SEC",
      "rank": 62,
      "record": "20-12"
    },
    "Alabama A&M": {
      "adjDef": 106.9,
      "adjDefRank": 212,
      "adjEM": -22.96,
      "adjOff": 83.9,
      "adjOffRank": 334,
      "adjTempo": 70.8,
      "adjTempoRank": 26,
      "conference": "SWAC",
      "rank": 331,
      "record": "10-20"
    },
    "Alabama St.": {
      "adjDef": 109,
      "adjDefRank": 248,
      "adjEM": -17.83,
      "adjOff": 91.1,
      "adjOffRank": 321,
      "adjTempo": 63.9,
      "adjTempoRank": 239,
      "conference": "SWAC",
      "rank": 317,
      "record": "10-20"
    },
    "Albany": {
      "adjDef": 105.2,
      "adjDefRank": 175,
      "adjEM": 2.25,
      "adjOff": 107.4,
      "adjOffRank": 113,
      "adjTempo": 63.7,
      "adjTempoRank": 250,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 138,
      "record": "23-10",
      "seed": 13
    },
    "Alcorn St.": {
      "adjDef": 113.4,
      "adjDefRank": 318,
      "adjEM": -21.49,
      "adjOff": 91.9,
      "adjOffRank": 318,
      "adjTempo": 69.4,
      "adjTempoRank": 48,
      "conference": "SWAC",
      "rank": 328,
      "record": "11-19"
    },
    "American": {
      "adjDef": 101.8,
      "adjDefRank": 120,
      "adjEM": -1.52,
      "adjOff": 100.3,
      "adjOffRank": 228,
      "adjTempo": 64.1,
      "adjTempoRank": 232,
      "conference": "Pat",
      "rank": 167,
      "record": "16-14"
    },
    "Appalachian St.": {
      "adjDef": 100.8,
      "adjDefRank": 99,
      "adjEM": 10.37,
      "adjOff": 111.2,
      "adjOffRank": 69,
      "adjTempo": 66.7,
      "adjTempoRank": 124,
      "conference": "SC",
      "rank": 76,
      "record": "25-8"
    },
    "Arizona": {
      "adjDef": 100.1,
      "adjDefRank": 90,
      "adjEM": 19.43,
      "adjOff": 119.5,
      "adjOffRank": 5,
      "adjTempo": 68.3,
      "adjTempoRank": 73,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 24,
      "record": "20-11",
      "seed": 8
    },
    "Arizona St.": {
      "adjDef": 99.5,
      "adjDefRank": 80,
      "adjEM": 1.83,
      "adjOff": 101.3,
      "adjOffRank": 215,
      "adjTempo": 58.5,
      "adjTempoRank": 329,
      "conference": "P10",
      "rank": 143,
      "record": "8-22"
    },
    "Arkansas": {
      "adjDef": 93.9,
      "adjDefRank": 25,
      "adjEM": 16.62,
      "adjOff": 110.6,
      "adjOffRank": 77,
      "adjTempo": 66.1,
      "adjTempoRank": 156,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 42,
      "record": "21-14",
      "seed": 12
    },
    "Arkansas Little Rock": {
      "adjDef": 110.1,
      "adjDefRank": 275,
      "adjEM": -9.73,
      "adjOff": 100.4,
      "adjOffRank": 227,
      "adjTempo": 63,
      "adjTempoRank": 278,
      "conference": "SB",
      "rank": 255,
      "record": "13-17"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 104.3,
      "adjDefRank": 157,
      "adjEM": -15.54,
      "adjOff": 88.7,
      "adjOffRank": 327,
      "adjTempo": 64.1,
      "adjTempoRank": 234,
      "conference": "SWAC",
      "rank": 300,
      "record": "12-19"
    },
    "Arkansas St.": {
      "adjDef": 108.7,
      "adjDefRank": 243,
      "adjEM": -4.94,
      "adjOff": 103.7,
      "adjOffRank": 177,
      "adjTempo": 67.5,
      "adjTempoRank": 98,
      "conference": "SB",
      "rank": 204,
      "record": "18-15"
    },
    "Army": {
      "adjDef": 102.6,
      "adjDefRank": 134,
      "adjEM": -9.95,
      "adjOff": 92.7,
      "adjOffRank": 315,
      "adjTempo": 63,
      "adjTempoRank": 280,
      "conference": "Pat",
      "rank": 259,
      "record": "15-16"
    },
    "Auburn": {
      "adjDef": 98.5,
      "adjDefRank": 65,
      "adjEM": 8.39,
      "adjOff": 106.9,
      "adjOffRank": 128,
      "adjTempo": 70.2,
      "adjTempoRank": 33,
      "conference": "SEC",
      "rank": 85,
      "record": "17-15"
    },
    "Austin Peay": {
      "adjDef": 106.4,
      "adjDefRank": 202,
      "adjEM": -1.08,
      "adjOff": 105.3,
      "adjOffRank": 147,
      "adjTempo": 66.2,
      "adjTempoRank": 152,
      "conference": "OVC",
      "rank": 163,
      "record": "21-12"
    },
    "BYU": {
      "adjDef": 100.6,
      "adjDefRank": 97,
      "adjEM": 16.11,
      "adjOff": 116.7,
      "adjOffRank": 17,
      "adjTempo": 67.6,
      "adjTempoRank": 95,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 46,
      "record": "25-9",
      "seed": 8
    },
    "Ball St.": {
      "adjDef": 101.4,
      "adjDefRank": 113,
      "adjEM": -5.03,
      "adjOff": 96.4,
      "adjOffRank": 282,
      "adjTempo": 63.1,
      "adjTempoRank": 271,
      "conference": "MAC",
      "rank": 205,
      "record": "9-22"
    },
    "Baylor": {
      "adjDef": 105.1,
      "adjDefRank": 173,
      "adjEM": 5.91,
      "adjOff": 111,
      "adjOffRank": 73,
      "adjTempo": 67,
      "adjTempoRank": 114,
      "conference": "B12",
      "rank": 107,
      "record": "15-16"
    },
    "Belmont": {
      "adjDef": 102.2,
      "adjDefRank": 129,
      "adjEM": 4.38,
      "adjOff": 106.6,
      "adjOffRank": 132,
      "adjTempo": 66.1,
      "adjTempoRank": 155,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 121,
      "record": "23-10",
      "seed": 15
    },
    "Bethune Cookman": {
      "adjDef": 107.8,
      "adjDefRank": 226,
      "adjEM": -24.79,
      "adjOff": 83,
      "adjOffRank": 335,
      "adjTempo": 59.9,
      "adjTempoRank": 323,
      "conference": "MEAC",
      "rank": 333,
      "record": "9-21"
    },
    "Binghamton": {
      "adjDef": 105.4,
      "adjDefRank": 181,
      "adjEM": -6.74,
      "adjOff": 98.7,
      "adjOffRank": 252,
      "adjTempo": 66.7,
      "adjTempoRank": 123,
      "conference": "AE",
      "rank": 222,
      "record": "13-16"
    },
    "Boise St.": {
      "adjDef": 101.4,
      "adjDefRank": 114,
      "adjEM": 5.37,
      "adjOff": 106.8,
      "adjOffRank": 129,
      "adjTempo": 70.5,
      "adjTempoRank": 31,
      "conference": "WAC",
      "rank": 114,
      "record": "17-14"
    },
    "Boston College": {
      "adjDef": 101.1,
      "adjDefRank": 107,
      "adjEM": 16.18,
      "adjOff": 117.3,
      "adjOffRank": 13,
      "adjTempo": 64.3,
      "adjTempoRank": 226,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 44,
      "record": "21-12",
      "seed": 7
    },
    "Boston University": {
      "adjDef": 103.2,
      "adjDefRank": 143,
      "adjEM": -3.78,
      "adjOff": 99.4,
      "adjOffRank": 245,
      "adjTempo": 59.4,
      "adjTempoRank": 326,
      "conference": "AE",
      "rank": 194,
      "record": "12-18"
    },
    "Bowling Green": {
      "adjDef": 105.8,
      "adjDefRank": 187,
      "adjEM": -6.18,
      "adjOff": 99.6,
      "adjOffRank": 236,
      "adjTempo": 67.6,
      "adjTempoRank": 94,
      "conference": "MAC",
      "rank": 217,
      "record": "13-18"
    },
    "Bradley": {
      "adjDef": 101.5,
      "adjDefRank": 115,
      "adjEM": 14.35,
      "adjOff": 115.9,
      "adjOffRank": 23,
      "adjTempo": 67.8,
      "adjTempoRank": 86,
      "conference": "MVC",
      "rank": 53,
      "record": "22-13"
    },
    "Brown": {
      "adjDef": 105.7,
      "adjDefRank": 186,
      "adjEM": -7.19,
      "adjOff": 98.5,
      "adjOffRank": 257,
      "adjTempo": 62.1,
      "adjTempoRank": 299,
      "conference": "Ivy",
      "rank": 227,
      "record": "11-18"
    },
    "Bucknell": {
      "adjDef": 99.7,
      "adjDefRank": 83,
      "adjEM": 6.83,
      "adjOff": 106.6,
      "adjOffRank": 133,
      "adjTempo": 61.3,
      "adjTempoRank": 308,
      "conference": "Pat",
      "rank": 98,
      "record": "22-9"
    },
    "Buffalo": {
      "adjDef": 102.5,
      "adjDefRank": 131,
      "adjEM": -4,
      "adjOff": 98.5,
      "adjOffRank": 254,
      "adjTempo": 70.6,
      "adjTempoRank": 30,
      "conference": "MAC",
      "rank": 198,
      "record": "12-19"
    },
    "Butler": {
      "adjDef": 96.5,
      "adjDefRank": 47,
      "adjEM": 21.45,
      "adjOff": 118,
      "adjOffRank": 11,
      "adjTempo": 58.1,
      "adjTempoRank": 331,
      "conference": "Horz",
      "finish": "Sweet 16",
      "rank": 15,
      "record": "29-7",
      "seed": 5
    },
    "Cal Poly": {
      "adjDef": 107.9,
      "adjDefRank": 228,
      "adjEM": 0.28,
      "adjOff": 108.2,
      "adjOffRank": 105,
      "adjTempo": 66.3,
      "adjTempoRank": 142,
      "conference": "BW",
      "rank": 155,
      "record": "19-11"
    },
    "Cal St. Fullerton": {
      "adjDef": 107.6,
      "adjDefRank": 224,
      "adjEM": -2.55,
      "adjOff": 105.1,
      "adjOffRank": 151,
      "adjTempo": 73.4,
      "adjTempoRank": 5,
      "conference": "BW",
      "rank": 179,
      "record": "20-10"
    },
    "Cal St. Northridge": {
      "adjDef": 105.3,
      "adjDefRank": 176,
      "adjEM": -7.74,
      "adjOff": 97.6,
      "adjOffRank": 271,
      "adjTempo": 71.3,
      "adjTempoRank": 18,
      "conference": "BW",
      "rank": 234,
      "record": "14-17"
    },
    "California": {
      "adjDef": 101.7,
      "adjDefRank": 117,
      "adjEM": 9.99,
      "adjOff": 111.7,
      "adjOffRank": 65,
      "adjTempo": 64.5,
      "adjTempoRank": 217,
      "conference": "P10",
      "rank": 78,
      "record": "16-17"
    },
    "Campbell": {
      "adjDef": 110.2,
      "adjDefRank": 277,
      "adjEM": -10.63,
      "adjOff": 99.6,
      "adjOffRank": 238,
      "adjTempo": 73,
      "adjTempoRank": 7,
      "conference": "ASun",
      "rank": 268,
      "record": "14-17"
    },
    "Canisius": {
      "adjDef": 109.4,
      "adjDefRank": 252,
      "adjEM": -9.13,
      "adjOff": 100.2,
      "adjOffRank": 231,
      "adjTempo": 67,
      "adjTempoRank": 115,
      "conference": "MAAC",
      "rank": 245,
      "record": "12-19"
    },
    "Centenary": {
      "adjDef": 109.4,
      "adjDefRank": 254,
      "adjEM": -9.83,
      "adjOff": 99.6,
      "adjOffRank": 237,
      "adjTempo": 67.1,
      "adjTempoRank": 112,
      "conference": "MCon",
      "rank": 256,
      "record": "10-21"
    },
    "Central Arkansas": {
      "adjDef": 106.9,
      "adjDefRank": 215,
      "adjEM": -11.51,
      "adjOff": 95.4,
      "adjOffRank": 290,
      "adjTempo": 63.7,
      "adjTempoRank": 249,
      "conference": "Slnd",
      "rank": 275,
      "record": "10-20"
    },
    "Central Connecticut": {
      "adjDef": 106.3,
      "adjDefRank": 198,
      "adjEM": -2.24,
      "adjOff": 104.1,
      "adjOffRank": 169,
      "adjTempo": 63.3,
      "adjTempoRank": 266,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 177,
      "record": "22-12",
      "seed": 16
    },
    "Central Michigan": {
      "adjDef": 106.3,
      "adjDefRank": 197,
      "adjEM": -6.03,
      "adjOff": 100.3,
      "adjOffRank": 229,
      "adjTempo": 65.6,
      "adjTempoRank": 174,
      "conference": "MAC",
      "rank": 215,
      "record": "13-18"
    },
    "Charleston Southern": {
      "adjDef": 115.1,
      "adjDefRank": 331,
      "adjEM": -19.49,
      "adjOff": 95.6,
      "adjOffRank": 287,
      "adjTempo": 64.1,
      "adjTempoRank": 235,
      "conference": "BSth",
      "rank": 324,
      "record": "8-22"
    },
    "Charlotte": {
      "adjDef": 106.1,
      "adjDefRank": 192,
      "adjEM": 0.13,
      "adjOff": 106.2,
      "adjOffRank": 140,
      "adjTempo": 66.2,
      "adjTempoRank": 150,
      "conference": "A10",
      "rank": 156,
      "record": "14-16"
    },
    "Chattanooga": {
      "adjDef": 104,
      "adjDefRank": 155,
      "adjEM": -4.26,
      "adjOff": 99.7,
      "adjOffRank": 235,
      "adjTempo": 65.4,
      "adjTempoRank": 182,
      "conference": "SC",
      "rank": 202,
      "record": "15-18"
    },
    "Chicago St.": {
      "adjDef": 112.1,
      "adjDefRank": 306,
      "adjEM": -4.73,
      "adjOff": 107.3,
      "adjOffRank": 115,
      "adjTempo": 68.2,
      "adjTempoRank": 77,
      "conference": "ind",
      "rank": 203,
      "record": "9-20"
    },
    "Cincinnati": {
      "adjDef": 101.9,
      "adjDefRank": 121,
      "adjEM": 1.87,
      "adjOff": 103.7,
      "adjOffRank": 176,
      "adjTempo": 64.8,
      "adjTempoRank": 207,
      "conference": "BE",
      "rank": 141,
      "record": "11-19"
    },
    "Clemson": {
      "adjDef": 93.9,
      "adjDefRank": 24,
      "adjEM": 18.46,
      "adjOff": 112.3,
      "adjOffRank": 55,
      "adjTempo": 66.4,
      "adjTempoRank": 138,
      "conference": "ACC",
      "rank": 29,
      "record": "25-11"
    },
    "Cleveland St.": {
      "adjDef": 107.2,
      "adjDefRank": 218,
      "adjEM": -7.45,
      "adjOff": 99.8,
      "adjOffRank": 234,
      "adjTempo": 63.4,
      "adjTempoRank": 262,
      "conference": "Horz",
      "rank": 232,
      "record": "10-21"
    },
    "Coastal Carolina": {
      "adjDef": 110.4,
      "adjDefRank": 281,
      "adjEM": -7.88,
      "adjOff": 102.5,
      "adjOffRank": 197,
      "adjTempo": 66.4,
      "adjTempoRank": 136,
      "conference": "BSth",
      "rank": 235,
      "record": "15-15"
    },
    "Colgate": {
      "adjDef": 104.4,
      "adjDefRank": 158,
      "adjEM": -12.77,
      "adjOff": 91.6,
      "adjOffRank": 319,
      "adjTempo": 61.6,
      "adjTempoRank": 306,
      "conference": "Pat",
      "rank": 285,
      "record": "10-19"
    },
    "College of Charleston": {
      "adjDef": 99.6,
      "adjDefRank": 82,
      "adjEM": 4.19,
      "adjOff": 103.8,
      "adjOffRank": 174,
      "adjTempo": 65.4,
      "adjTempoRank": 180,
      "conference": "SC",
      "rank": 122,
      "record": "22-11"
    },
    "Colorado": {
      "adjDef": 106.7,
      "adjDefRank": 208,
      "adjEM": -6.04,
      "adjOff": 100.7,
      "adjOffRank": 224,
      "adjTempo": 71.6,
      "adjTempoRank": 16,
      "conference": "B12",
      "rank": 216,
      "record": "7-20"
    },
    "Colorado St.": {
      "adjDef": 102.6,
      "adjDefRank": 133,
      "adjEM": 5.75,
      "adjOff": 108.3,
      "adjOffRank": 102,
      "adjTempo": 67.8,
      "adjTempoRank": 84,
      "conference": "MWC",
      "rank": 108,
      "record": "17-13"
    },
    "Columbia": {
      "adjDef": 110,
      "adjDefRank": 272,
      "adjEM": -5.23,
      "adjOff": 104.7,
      "adjOffRank": 158,
      "adjTempo": 61.9,
      "adjTempoRank": 302,
      "conference": "Ivy",
      "rank": 206,
      "record": "16-12"
    },
    "Connecticut": {
      "adjDef": 90.2,
      "adjDefRank": 9,
      "adjEM": 13.78,
      "adjOff": 104,
      "adjOffRank": 171,
      "adjTempo": 68.4,
      "adjTempoRank": 69,
      "conference": "BE",
      "rank": 56,
      "record": "17-14"
    },
    "Coppin St.": {
      "adjDef": 109.3,
      "adjDefRank": 251,
      "adjEM": -15.69,
      "adjOff": 93.6,
      "adjOffRank": 307,
      "adjTempo": 65.1,
      "adjTempoRank": 192,
      "conference": "MEAC",
      "rank": 301,
      "record": "12-20"
    },
    "Cornell": {
      "adjDef": 107.3,
      "adjDefRank": 220,
      "adjEM": -2.93,
      "adjOff": 104.3,
      "adjOffRank": 168,
      "adjTempo": 63.2,
      "adjTempoRank": 268,
      "conference": "Ivy",
      "rank": 189,
      "record": "16-12"
    },
    "Creighton": {
      "adjDef": 95.3,
      "adjDefRank": 35,
      "adjEM": 18.53,
      "adjOff": 113.8,
      "adjOffRank": 36,
      "adjTempo": 62.7,
      "adjTempoRank": 289,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 28,
      "record": "22-11",
      "seed": 10
    },
    "Dartmouth": {
      "adjDef": 108.2,
      "adjDefRank": 233,
      "adjEM": -14.04,
      "adjOff": 94.2,
      "adjOffRank": 303,
      "adjTempo": 63.9,
      "adjTempoRank": 237,
      "conference": "Ivy",
      "rank": 293,
      "record": "9-18"
    },
    "Davidson": {
      "adjDef": 98.5,
      "adjDefRank": 66,
      "adjEM": 13.32,
      "adjOff": 111.8,
      "adjOffRank": 61,
      "adjTempo": 71.9,
      "adjTempoRank": 14,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 61,
      "record": "29-5",
      "seed": 13
    },
    "Dayton": {
      "adjDef": 100.7,
      "adjDefRank": 98,
      "adjEM": 4.12,
      "adjOff": 104.9,
      "adjOffRank": 155,
      "adjTempo": 64.4,
      "adjTempoRank": 222,
      "conference": "A10",
      "rank": 124,
      "record": "19-12"
    },
    "DePaul": {
      "adjDef": 96.3,
      "adjDefRank": 44,
      "adjEM": 15.27,
      "adjOff": 111.5,
      "adjOffRank": 66,
      "adjTempo": 62.4,
      "adjTempoRank": 295,
      "conference": "BE",
      "rank": 50,
      "record": "20-14"
    },
    "Delaware": {
      "adjDef": 111,
      "adjDefRank": 290,
      "adjEM": -14.22,
      "adjOff": 96.8,
      "adjOffRank": 280,
      "adjTempo": 63.5,
      "adjTempoRank": 260,
      "conference": "CAA",
      "rank": 295,
      "record": "5-26"
    },
    "Delaware St.": {
      "adjDef": 110.7,
      "adjDefRank": 289,
      "adjEM": -4.16,
      "adjOff": 106.5,
      "adjOffRank": 136,
      "adjTempo": 54.5,
      "adjTempoRank": 335,
      "conference": "MEAC",
      "rank": 200,
      "record": "21-13"
    },
    "Denver": {
      "adjDef": 114.6,
      "adjDefRank": 329,
      "adjEM": -21.35,
      "adjOff": 93.2,
      "adjOffRank": 311,
      "adjTempo": 66.3,
      "adjTempoRank": 141,
      "conference": "SB",
      "rank": 327,
      "record": "4-25"
    },
    "Detroit": {
      "adjDef": 106.4,
      "adjDefRank": 201,
      "adjEM": 0.59,
      "adjOff": 107,
      "adjOffRank": 125,
      "adjTempo": 61.1,
      "adjTempoRank": 311,
      "conference": "Horz",
      "rank": 154,
      "record": "11-19"
    },
    "Drake": {
      "adjDef": 108.2,
      "adjDefRank": 232,
      "adjEM": 5.64,
      "adjOff": 113.8,
      "adjOffRank": 37,
      "adjTempo": 67.6,
      "adjTempoRank": 92,
      "conference": "MVC",
      "rank": 110,
      "record": "17-15"
    },
    "Drexel": {
      "adjDef": 92.9,
      "adjDefRank": 17,
      "adjEM": 10.65,
      "adjOff": 103.5,
      "adjOffRank": 180,
      "adjTempo": 64.4,
      "adjTempoRank": 220,
      "conference": "CAA",
      "rank": 73,
      "record": "23-9"
    },
    "Duke": {
      "adjDef": 89.9,
      "adjDefRank": 6,
      "adjEM": 23.3,
      "adjOff": 113.2,
      "adjOffRank": 44,
      "adjTempo": 64.5,
      "adjTempoRank": 218,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 10,
      "record": "22-11",
      "seed": 6
    },
    "Duquesne": {
      "adjDef": 114.5,
      "adjDefRank": 327,
      "adjEM": -6.78,
      "adjOff": 107.7,
      "adjOffRank": 108,
      "adjTempo": 73.3,
      "adjTempoRank": 6,
      "conference": "A10",
      "rank": 223,
      "record": "10-19"
    },
    "East Carolina": {
      "adjDef": 108.5,
      "adjDefRank": 241,
      "adjEM": -15.71,
      "adjOff": 92.8,
      "adjOffRank": 313,
      "adjTempo": 63.6,
      "adjTempoRank": 255,
      "conference": "CUSA",
      "rank": 302,
      "record": "6-24"
    },
    "East Tennessee St.": {
      "adjDef": 100.4,
      "adjDefRank": 95,
      "adjEM": -1.49,
      "adjOff": 98.9,
      "adjOffRank": 248,
      "adjTempo": 69.8,
      "adjTempoRank": 37,
      "conference": "ASun",
      "rank": 166,
      "record": "24-10"
    },
    "Eastern Illinois": {
      "adjDef": 110.5,
      "adjDefRank": 284,
      "adjEM": -15.74,
      "adjOff": 94.7,
      "adjOffRank": 298,
      "adjTempo": 63.2,
      "adjTempoRank": 269,
      "conference": "OVC",
      "rank": 303,
      "record": "10-20"
    },
    "Eastern Kentucky": {
      "adjDef": 105.3,
      "adjDefRank": 177,
      "adjEM": -2.58,
      "adjOff": 102.7,
      "adjOffRank": 191,
      "adjTempo": 61.3,
      "adjTempoRank": 309,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 182,
      "record": "21-12",
      "seed": 16
    },
    "Eastern Michigan": {
      "adjDef": 104.8,
      "adjDefRank": 167,
      "adjEM": -6.85,
      "adjOff": 98,
      "adjOffRank": 265,
      "adjTempo": 65.7,
      "adjTempoRank": 172,
      "conference": "MAC",
      "rank": 224,
      "record": "13-19"
    },
    "Eastern Washington": {
      "adjDef": 115,
      "adjDefRank": 330,
      "adjEM": -2.57,
      "adjOff": 112.4,
      "adjOffRank": 52,
      "adjTempo": 71.9,
      "adjTempoRank": 13,
      "conference": "BSky",
      "rank": 180,
      "record": "15-14"
    },
    "Elon": {
      "adjDef": 109.1,
      "adjDefRank": 249,
      "adjEM": -11.8,
      "adjOff": 97.3,
      "adjOffRank": 272,
      "adjTempo": 63.3,
      "adjTempoRank": 264,
      "conference": "SC",
      "rank": 276,
      "record": "7-23"
    },
    "Evansville": {
      "adjDef": 104.6,
      "adjDefRank": 161,
      "adjEM": 8.54,
      "adjOff": 113.2,
      "adjOffRank": 46,
      "adjTempo": 64.3,
      "adjTempoRank": 225,
      "conference": "MVC",
      "rank": 83,
      "record": "14-17"
    },
    "FIU": {
      "adjDef": 107.7,
      "adjDefRank": 225,
      "adjEM": -12.61,
      "adjOff": 95.1,
      "adjOffRank": 293,
      "adjTempo": 66.8,
      "adjTempoRank": 121,
      "conference": "SB",
      "rank": 284,
      "record": "12-17"
    },
    "Fairfield": {
      "adjDef": 107.5,
      "adjDefRank": 223,
      "adjEM": -5.23,
      "adjOff": 102.3,
      "adjOffRank": 199,
      "adjTempo": 59.8,
      "adjTempoRank": 324,
      "conference": "MAAC",
      "rank": 207,
      "record": "13-19"
    },
    "Fairleigh Dickinson": {
      "adjDef": 111.4,
      "adjDefRank": 296,
      "adjEM": -9.9,
      "adjOff": 101.5,
      "adjOffRank": 211,
      "adjTempo": 68.7,
      "adjTempoRank": 65,
      "conference": "NEC",
      "rank": 258,
      "record": "14-16"
    },
    "Florida": {
      "adjDef": 92,
      "adjDefRank": 13,
      "adjEM": 30.81,
      "adjOff": 122.8,
      "adjOffRank": 1,
      "adjTempo": 65.8,
      "adjTempoRank": 162,
      "conference": "SEC",
      "finish": "Champion",
      "rank": 2,
      "record": "35-5",
      "seed": 1
    },
    "Florida A&M": {
      "adjDef": 110.2,
      "adjDefRank": 276,
      "adjEM": -9.57,
      "adjOff": 100.6,
      "adjOffRank": 225,
      "adjTempo": 67.6,
      "adjTempoRank": 96,
      "conference": "MEAC",
      "finish": "Play-in",
      "rank": 254,
      "record": "21-14",
      "seed": 16
    },
    "Florida Atlantic": {
      "adjDef": 112,
      "adjDefRank": 305,
      "adjEM": -7.11,
      "adjOff": 104.9,
      "adjOffRank": 154,
      "adjTempo": 69.4,
      "adjTempoRank": 46,
      "conference": "SB",
      "rank": 226,
      "record": "16-15"
    },
    "Florida St.": {
      "adjDef": 98.4,
      "adjDefRank": 63,
      "adjEM": 17.79,
      "adjOff": 116.2,
      "adjOffRank": 21,
      "adjTempo": 66.2,
      "adjTempoRank": 147,
      "conference": "ACC",
      "rank": 34,
      "record": "22-13"
    },
    "Fordham": {
      "adjDef": 98.5,
      "adjDefRank": 67,
      "adjEM": 2.32,
      "adjOff": 100.8,
      "adjOffRank": 221,
      "adjTempo": 62.9,
      "adjTempoRank": 284,
      "conference": "A10",
      "rank": 137,
      "record": "18-12"
    },
    "Fresno St.": {
      "adjDef": 96.3,
      "adjDefRank": 46,
      "adjEM": 6.18,
      "adjOff": 102.5,
      "adjOffRank": 196,
      "adjTempo": 68.7,
      "adjTempoRank": 64,
      "conference": "WAC",
      "rank": 106,
      "record": "22-10"
    },
    "Furman": {
      "adjDef": 107.4,
      "adjDefRank": 221,
      "adjEM": -3.55,
      "adjOff": 103.8,
      "adjOffRank": 175,
      "adjTempo": 63.1,
      "adjTempoRank": 276,
      "conference": "SC",
      "rank": 193,
      "record": "15-16"
    },
    "Gardner Webb": {
      "adjDef": 109.5,
      "adjDefRank": 258,
      "adjEM": -12.24,
      "adjOff": 97.3,
      "adjOffRank": 274,
      "adjTempo": 63.6,
      "adjTempoRank": 253,
      "conference": "ASun",
      "rank": 280,
      "record": "9-21"
    },
    "George Mason": {
      "adjDef": 98.6,
      "adjDefRank": 70,
      "adjEM": 7.91,
      "adjOff": 106.5,
      "adjOffRank": 135,
      "adjTempo": 60.8,
      "adjTempoRank": 316,
      "conference": "CAA",
      "rank": 89,
      "record": "18-15"
    },
    "George Washington": {
      "adjDef": 97.7,
      "adjDefRank": 58,
      "adjEM": 7.28,
      "adjOff": 105,
      "adjOffRank": 153,
      "adjTempo": 66.9,
      "adjTempoRank": 117,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 95,
      "record": "23-9",
      "seed": 11
    },
    "Georgetown": {
      "adjDef": 93.5,
      "adjDefRank": 21,
      "adjEM": 28.48,
      "adjOff": 122,
      "adjOffRank": 2,
      "adjTempo": 59.4,
      "adjTempoRank": 327,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 4,
      "record": "30-7",
      "seed": 2
    },
    "Georgia": {
      "adjDef": 96.7,
      "adjDefRank": 49,
      "adjEM": 14.3,
      "adjOff": 111,
      "adjOffRank": 71,
      "adjTempo": 67.4,
      "adjTempoRank": 101,
      "conference": "SEC",
      "rank": 55,
      "record": "19-14"
    },
    "Georgia Southern": {
      "adjDef": 102.1,
      "adjDefRank": 127,
      "adjEM": -4.1,
      "adjOff": 98,
      "adjOffRank": 264,
      "adjTempo": 68.3,
      "adjTempoRank": 70,
      "conference": "SC",
      "rank": 199,
      "record": "15-16"
    },
    "Georgia St.": {
      "adjDef": 109.5,
      "adjDefRank": 259,
      "adjEM": -8.69,
      "adjOff": 100.8,
      "adjOffRank": 222,
      "adjTempo": 64.2,
      "adjTempoRank": 228,
      "conference": "CAA",
      "rank": 241,
      "record": "11-20"
    },
    "Georgia Tech": {
      "adjDef": 97.3,
      "adjDefRank": 51,
      "adjEM": 19.61,
      "adjOff": 116.9,
      "adjOffRank": 16,
      "adjTempo": 67.2,
      "adjTempoRank": 106,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "20-12",
      "seed": 10
    },
    "Gonzaga": {
      "adjDef": 100,
      "adjDefRank": 87,
      "adjEM": 15.31,
      "adjOff": 115.3,
      "adjOffRank": 30,
      "adjTempo": 67.1,
      "adjTempoRank": 113,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 49,
      "record": "23-11",
      "seed": 10
    },
    "Grambling St.": {
      "adjDef": 111.6,
      "adjDefRank": 299,
      "adjEM": -17.66,
      "adjOff": 93.9,
      "adjOffRank": 304,
      "adjTempo": 67.3,
      "adjTempoRank": 104,
      "conference": "SWAC",
      "rank": 315,
      "record": "12-14"
    },
    "Green Bay": {
      "adjDef": 105.6,
      "adjDefRank": 185,
      "adjEM": 1.74,
      "adjOff": 107.4,
      "adjOffRank": 114,
      "adjTempo": 64.2,
      "adjTempoRank": 227,
      "conference": "Horz",
      "rank": 144,
      "record": "18-15"
    },
    "Hampton": {
      "adjDef": 103,
      "adjDefRank": 138,
      "adjEM": -9.33,
      "adjOff": 93.7,
      "adjOffRank": 306,
      "adjTempo": 69.1,
      "adjTempoRank": 56,
      "conference": "MEAC",
      "rank": 247,
      "record": "15-16"
    },
    "Hartford": {
      "adjDef": 106.7,
      "adjDefRank": 207,
      "adjEM": -8.35,
      "adjOff": 98.3,
      "adjOffRank": 260,
      "adjTempo": 63.8,
      "adjTempoRank": 246,
      "conference": "AE",
      "rank": 239,
      "record": "13-18"
    },
    "Harvard": {
      "adjDef": 113.3,
      "adjDefRank": 317,
      "adjEM": -9.9,
      "adjOff": 103.4,
      "adjOffRank": 182,
      "adjTempo": 71.1,
      "adjTempoRank": 21,
      "conference": "Ivy",
      "rank": 257,
      "record": "12-16"
    },
    "Hawaii": {
      "adjDef": 94.6,
      "adjDefRank": 29,
      "adjEM": 9.87,
      "adjOff": 104.5,
      "adjOffRank": 164,
      "adjTempo": 69.2,
      "adjTempoRank": 52,
      "conference": "WAC",
      "rank": 79,
      "record": "18-13"
    },
    "High Point": {
      "adjDef": 103.1,
      "adjDefRank": 142,
      "adjEM": -2.07,
      "adjOff": 101.1,
      "adjOffRank": 220,
      "adjTempo": 66.4,
      "adjTempoRank": 134,
      "conference": "BSth",
      "rank": 173,
      "record": "22-10"
    },
    "Hofstra": {
      "adjDef": 108.5,
      "adjDefRank": 238,
      "adjEM": 6.69,
      "adjOff": 115.2,
      "adjOffRank": 32,
      "adjTempo": 64.7,
      "adjTempoRank": 209,
      "conference": "CAA",
      "rank": 99,
      "record": "22-10"
    },
    "Holy Cross": {
      "adjDef": 95.6,
      "adjDefRank": 39,
      "adjEM": 7.4,
      "adjOff": 103,
      "adjOffRank": 186,
      "adjTempo": 62.5,
      "adjTempoRank": 293,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 93,
      "record": "25-9",
      "seed": 13
    },
    "Houston": {
      "adjDef": 99.8,
      "adjDefRank": 85,
      "adjEM": 7.3,
      "adjOff": 107.1,
      "adjOffRank": 121,
      "adjTempo": 68.8,
      "adjTempoRank": 62,
      "conference": "CUSA",
      "rank": 94,
      "record": "18-15"
    },
    "Howard": {
      "adjDef": 116.8,
      "adjDefRank": 336,
      "adjEM": -22.07,
      "adjOff": 94.7,
      "adjOffRank": 299,
      "adjTempo": 64.4,
      "adjTempoRank": 223,
      "conference": "MEAC",
      "rank": 330,
      "record": "9-22"
    },
    "IPFW": {
      "adjDef": 108.2,
      "adjDefRank": 231,
      "adjEM": -9.34,
      "adjOff": 98.8,
      "adjOffRank": 250,
      "adjTempo": 66.8,
      "adjTempoRank": 122,
      "conference": "ind",
      "rank": 248,
      "record": "12-17"
    },
    "IUPUI": {
      "adjDef": 109.5,
      "adjDefRank": 260,
      "adjEM": -2.62,
      "adjOff": 106.9,
      "adjOffRank": 127,
      "adjTempo": 63,
      "adjTempoRank": 283,
      "conference": "MCon",
      "rank": 183,
      "record": "15-15"
    },
    "Idaho": {
      "adjDef": 109.9,
      "adjDefRank": 266,
      "adjEM": -15.54,
      "adjOff": 94.3,
      "adjOffRank": 301,
      "adjTempo": 66.2,
      "adjTempoRank": 148,
      "conference": "WAC",
      "rank": 299,
      "record": "4-27"
    },
    "Idaho St.": {
      "adjDef": 105,
      "adjDefRank": 170,
      "adjEM": -0.26,
      "adjOff": 104.7,
      "adjOffRank": 159,
      "adjTempo": 60.2,
      "adjTempoRank": 320,
      "conference": "BSky",
      "rank": 158,
      "record": "13-17"
    },
    "Illinois": {
      "adjDef": 89.8,
      "adjDefRank": 5,
      "adjEM": 17.5,
      "adjOff": 107.3,
      "adjOffRank": 116,
      "adjTempo": 62,
      "adjTempoRank": 300,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 36,
      "record": "23-12",
      "seed": 12
    },
    "Illinois Chicago": {
      "adjDef": 106.3,
      "adjDefRank": 196,
      "adjEM": 0.71,
      "adjOff": 107,
      "adjOffRank": 126,
      "adjTempo": 66.2,
      "adjTempoRank": 143,
      "conference": "Horz",
      "rank": 152,
      "record": "14-18"
    },
    "Illinois St.": {
      "adjDef": 98.7,
      "adjDefRank": 75,
      "adjEM": 8.38,
      "adjOff": 107.1,
      "adjOffRank": 122,
      "adjTempo": 65,
      "adjTempoRank": 198,
      "conference": "MVC",
      "rank": 86,
      "record": "15-16"
    },
    "Indiana": {
      "adjDef": 93.8,
      "adjDefRank": 23,
      "adjEM": 21.58,
      "adjOff": 115.4,
      "adjOffRank": 26,
      "adjTempo": 64.9,
      "adjTempoRank": 203,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 14,
      "record": "21-11",
      "seed": 7
    },
    "Indiana St.": {
      "adjDef": 102.1,
      "adjDefRank": 128,
      "adjEM": 1.94,
      "adjOff": 104.1,
      "adjOffRank": 170,
      "adjTempo": 62.5,
      "adjTempoRank": 294,
      "conference": "MVC",
      "rank": 140,
      "record": "13-18"
    },
    "Iona": {
      "adjDef": 107.1,
      "adjDefRank": 217,
      "adjEM": -19.41,
      "adjOff": 87.7,
      "adjOffRank": 329,
      "adjTempo": 65.8,
      "adjTempoRank": 170,
      "conference": "MAAC",
      "rank": 323,
      "record": "2-28"
    },
    "Iowa": {
      "adjDef": 99.6,
      "adjDefRank": 81,
      "adjEM": 11.06,
      "adjOff": 110.6,
      "adjOffRank": 76,
      "adjTempo": 66.5,
      "adjTempoRank": 129,
      "conference": "B10",
      "rank": 70,
      "record": "17-14"
    },
    "Iowa St.": {
      "adjDef": 98.7,
      "adjDefRank": 72,
      "adjEM": 2.67,
      "adjOff": 101.4,
      "adjOffRank": 213,
      "adjTempo": 63.3,
      "adjTempoRank": 265,
      "conference": "B12",
      "rank": 134,
      "record": "15-16"
    },
    "Jackson St.": {
      "adjDef": 109.7,
      "adjDefRank": 262,
      "adjEM": -9.48,
      "adjOff": 100.2,
      "adjOffRank": 230,
      "adjTempo": 69.7,
      "adjTempoRank": 40,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 251,
      "record": "21-14",
      "seed": 16
    },
    "Jacksonville": {
      "adjDef": 109.8,
      "adjDefRank": 264,
      "adjEM": -9.33,
      "adjOff": 100.5,
      "adjOffRank": 226,
      "adjTempo": 70.2,
      "adjTempoRank": 35,
      "conference": "ASun",
      "rank": 246,
      "record": "15-14"
    },
    "Jacksonville St.": {
      "adjDef": 111.8,
      "adjDefRank": 303,
      "adjEM": -14.11,
      "adjOff": 97.7,
      "adjOffRank": 268,
      "adjTempo": 65.2,
      "adjTempoRank": 187,
      "conference": "OVC",
      "rank": 294,
      "record": "9-21"
    },
    "James Madison": {
      "adjDef": 115.9,
      "adjDefRank": 334,
      "adjEM": -13.74,
      "adjOff": 102.2,
      "adjOffRank": 200,
      "adjTempo": 63,
      "adjTempoRank": 279,
      "conference": "CAA",
      "rank": 292,
      "record": "7-23"
    },
    "Kansas": {
      "adjDef": 87.5,
      "adjDefRank": 1,
      "adjEM": 27.81,
      "adjOff": 115.3,
      "adjOffRank": 29,
      "adjTempo": 68.7,
      "adjTempoRank": 63,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 5,
      "record": "33-5",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.6,
      "adjDefRank": 38,
      "adjEM": 13.71,
      "adjOff": 109.3,
      "adjOffRank": 91,
      "adjTempo": 65.8,
      "adjTempoRank": 169,
      "conference": "B12",
      "rank": 57,
      "record": "23-12"
    },
    "Kennesaw St.": {
      "adjDef": 109.5,
      "adjDefRank": 256,
      "adjEM": -10.17,
      "adjOff": 99.3,
      "adjOffRank": 246,
      "adjTempo": 65.1,
      "adjTempoRank": 193,
      "conference": "ASun",
      "rank": 263,
      "record": "13-18"
    },
    "Kent St.": {
      "adjDef": 95.3,
      "adjDefRank": 36,
      "adjEM": 9.85,
      "adjOff": 105.2,
      "adjOffRank": 148,
      "adjTempo": 65.4,
      "adjTempoRank": 183,
      "conference": "MAC",
      "rank": 81,
      "record": "21-11"
    },
    "Kentucky": {
      "adjDef": 95.3,
      "adjDefRank": 34,
      "adjEM": 21.73,
      "adjOff": 117,
      "adjOffRank": 15,
      "adjTempo": 65.8,
      "adjTempoRank": 165,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 13,
      "record": "22-12",
      "seed": 8
    },
    "LIU Brooklyn": {
      "adjDef": 112.1,
      "adjDefRank": 307,
      "adjEM": -15.92,
      "adjOff": 96.2,
      "adjOffRank": 284,
      "adjTempo": 67.3,
      "adjTempoRank": 103,
      "conference": "NEC",
      "rank": 307,
      "record": "10-19"
    },
    "LSU": {
      "adjDef": 93.5,
      "adjDefRank": 20,
      "adjEM": 14.35,
      "adjOff": 107.8,
      "adjOffRank": 106,
      "adjTempo": 63.1,
      "adjTempoRank": 275,
      "conference": "SEC",
      "rank": 54,
      "record": "17-15"
    },
    "La Salle": {
      "adjDef": 112.5,
      "adjDefRank": 311,
      "adjEM": -8.07,
      "adjOff": 104.5,
      "adjOffRank": 166,
      "adjTempo": 66.9,
      "adjTempoRank": 116,
      "conference": "A10",
      "rank": 236,
      "record": "10-20"
    },
    "Lafayette": {
      "adjDef": 115.4,
      "adjDefRank": 333,
      "adjEM": -16.67,
      "adjOff": 98.7,
      "adjOffRank": 251,
      "adjTempo": 67.3,
      "adjTempoRank": 102,
      "conference": "Pat",
      "rank": 310,
      "record": "9-21"
    },
    "Lamar": {
      "adjDef": 109.6,
      "adjDefRank": 261,
      "adjEM": -5.97,
      "adjOff": 103.6,
      "adjOffRank": 179,
      "adjTempo": 68.9,
      "adjTempoRank": 59,
      "conference": "Slnd",
      "rank": 214,
      "record": "15-17"
    },
    "Lehigh": {
      "adjDef": 107.8,
      "adjDefRank": 227,
      "adjEM": -9.49,
      "adjOff": 98.3,
      "adjOffRank": 261,
      "adjTempo": 63,
      "adjTempoRank": 282,
      "conference": "Pat",
      "rank": 252,
      "record": "12-19"
    },
    "Liberty": {
      "adjDef": 108.4,
      "adjDefRank": 237,
      "adjEM": -6.34,
      "adjOff": 102.1,
      "adjOffRank": 204,
      "adjTempo": 68.3,
      "adjTempoRank": 71,
      "conference": "BSth",
      "rank": 219,
      "record": "14-17"
    },
    "Lipscomb": {
      "adjDef": 106.6,
      "adjDefRank": 205,
      "adjEM": -2.77,
      "adjOff": 103.9,
      "adjOffRank": 173,
      "adjTempo": 61.4,
      "adjTempoRank": 307,
      "conference": "ASun",
      "rank": 187,
      "record": "18-13"
    },
    "Long Beach St.": {
      "adjDef": 106.8,
      "adjDefRank": 210,
      "adjEM": 5.44,
      "adjOff": 112.2,
      "adjOffRank": 58,
      "adjTempo": 71,
      "adjTempoRank": 22,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 113,
      "record": "24-8",
      "seed": 12
    },
    "Longwood": {
      "adjDef": 110.1,
      "adjDefRank": 273,
      "adjEM": -18.65,
      "adjOff": 91.4,
      "adjOffRank": 320,
      "adjTempo": 69.2,
      "adjTempoRank": 50,
      "conference": "ind",
      "rank": 320,
      "record": "9-22"
    },
    "Louisiana Lafayette": {
      "adjDef": 109.3,
      "adjDefRank": 250,
      "adjEM": -10.85,
      "adjOff": 98.4,
      "adjOffRank": 258,
      "adjTempo": 65.5,
      "adjTempoRank": 177,
      "conference": "SB",
      "rank": 271,
      "record": "9-21"
    },
    "Louisiana Monroe": {
      "adjDef": 110.5,
      "adjDefRank": 286,
      "adjEM": -8.99,
      "adjOff": 101.5,
      "adjOffRank": 209,
      "adjTempo": 67.3,
      "adjTempoRank": 105,
      "conference": "SB",
      "rank": 243,
      "record": "18-14"
    },
    "Louisiana Tech": {
      "adjDef": 103.7,
      "adjDefRank": 150,
      "adjEM": -3.89,
      "adjOff": 99.8,
      "adjOffRank": 233,
      "adjTempo": 65.8,
      "adjTempoRank": 163,
      "conference": "WAC",
      "rank": 197,
      "record": "10-20"
    },
    "Louisville": {
      "adjDef": 93.1,
      "adjDefRank": 18,
      "adjEM": 20.35,
      "adjOff": 113.5,
      "adjOffRank": 40,
      "adjTempo": 64.2,
      "adjTempoRank": 229,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 19,
      "record": "24-10",
      "seed": 6
    },
    "Loyola Chicago": {
      "adjDef": 101.9,
      "adjDefRank": 122,
      "adjEM": 8.42,
      "adjOff": 110.3,
      "adjOffRank": 78,
      "adjTempo": 65,
      "adjTempoRank": 201,
      "conference": "Horz",
      "rank": 84,
      "record": "21-11"
    },
    "Loyola MD": {
      "adjDef": 110.3,
      "adjDefRank": 278,
      "adjEM": -3.14,
      "adjOff": 107.2,
      "adjOffRank": 120,
      "adjTempo": 65.8,
      "adjTempoRank": 164,
      "conference": "MAAC",
      "rank": 191,
      "record": "18-13"
    },
    "Loyola Marymount": {
      "adjDef": 100.5,
      "adjDefRank": 96,
      "adjEM": -5.5,
      "adjOff": 95,
      "adjOffRank": 294,
      "adjTempo": 67.8,
      "adjTempoRank": 85,
      "conference": "WCC",
      "rank": 211,
      "record": "13-18"
    },
    "Maine": {
      "adjDef": 109.9,
      "adjDefRank": 267,
      "adjEM": -10.46,
      "adjOff": 99.5,
      "adjOffRank": 242,
      "adjTempo": 66.1,
      "adjTempoRank": 153,
      "conference": "AE",
      "rank": 265,
      "record": "12-18"
    },
    "Manhattan": {
      "adjDef": 106.9,
      "adjDefRank": 213,
      "adjEM": -10.08,
      "adjOff": 96.8,
      "adjOffRank": 279,
      "adjTempo": 66.5,
      "adjTempoRank": 128,
      "conference": "MAAC",
      "rank": 262,
      "record": "13-17"
    },
    "Marist": {
      "adjDef": 104.9,
      "adjDefRank": 169,
      "adjEM": 4.56,
      "adjOff": 109.5,
      "adjOffRank": 88,
      "adjTempo": 66.5,
      "adjTempoRank": 132,
      "conference": "MAAC",
      "rank": 119,
      "record": "25-9"
    },
    "Marquette": {
      "adjDef": 94.6,
      "adjDefRank": 30,
      "adjEM": 17.18,
      "adjOff": 111.8,
      "adjOffRank": 62,
      "adjTempo": 67.6,
      "adjTempoRank": 93,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 38,
      "record": "24-10",
      "seed": 8
    },
    "Marshall": {
      "adjDef": 103.7,
      "adjDefRank": 151,
      "adjEM": -2.97,
      "adjOff": 100.7,
      "adjOffRank": 223,
      "adjTempo": 67.2,
      "adjTempoRank": 108,
      "conference": "CUSA",
      "rank": 190,
      "record": "13-19"
    },
    "Maryland": {
      "adjDef": 90,
      "adjDefRank": 7,
      "adjEM": 22.42,
      "adjOff": 112.4,
      "adjOffRank": 53,
      "adjTempo": 71.4,
      "adjTempoRank": 17,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 12,
      "record": "25-9",
      "seed": 4
    },
    "Maryland Eastern Shore": {
      "adjDef": 114,
      "adjDefRank": 323,
      "adjEM": -27.18,
      "adjOff": 86.8,
      "adjOffRank": 330,
      "adjTempo": 67.5,
      "adjTempoRank": 99,
      "conference": "MEAC",
      "rank": 336,
      "record": "4-27"
    },
    "Massachusetts": {
      "adjDef": 96.8,
      "adjDefRank": 50,
      "adjEM": 12.36,
      "adjOff": 109.1,
      "adjOffRank": 94,
      "adjTempo": 70.6,
      "adjTempoRank": 29,
      "conference": "A10",
      "rank": 67,
      "record": "24-9"
    },
    "McNeese St.": {
      "adjDef": 101.1,
      "adjDefRank": 106,
      "adjEM": -10.58,
      "adjOff": 90.5,
      "adjOffRank": 322,
      "adjTempo": 65.5,
      "adjTempoRank": 179,
      "conference": "Slnd",
      "rank": 267,
      "record": "15-17"
    },
    "Memphis": {
      "adjDef": 90.6,
      "adjDefRank": 10,
      "adjEM": 24.81,
      "adjOff": 115.4,
      "adjOffRank": 28,
      "adjTempo": 68.4,
      "adjTempoRank": 68,
      "conference": "CUSA",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "33-4",
      "seed": 2
    },
    "Mercer": {
      "adjDef": 111.9,
      "adjDefRank": 304,
      "adjEM": -15.3,
      "adjOff": 96.6,
      "adjOffRank": 281,
      "adjTempo": 71.9,
      "adjTempoRank": 15,
      "conference": "ASun",
      "rank": 298,
      "record": "13-17"
    },
    "Miami FL": {
      "adjDef": 107.5,
      "adjDefRank": 222,
      "adjEM": 5.73,
      "adjOff": 113.2,
      "adjOffRank": 45,
      "adjTempo": 63.4,
      "adjTempoRank": 261,
      "conference": "ACC",
      "rank": 109,
      "record": "12-20"
    },
    "Miami OH": {
      "adjDef": 97.4,
      "adjDefRank": 53,
      "adjEM": 8.38,
      "adjOff": 105.7,
      "adjOffRank": 141,
      "adjTempo": 58.1,
      "adjTempoRank": 330,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 87,
      "record": "18-15",
      "seed": 14
    },
    "Michigan": {
      "adjDef": 95.3,
      "adjDefRank": 37,
      "adjEM": 13.53,
      "adjOff": 108.9,
      "adjOffRank": 98,
      "adjTempo": 63.1,
      "adjTempoRank": 274,
      "conference": "B10",
      "rank": 59,
      "record": "22-13"
    },
    "Michigan St.": {
      "adjDef": 91.8,
      "adjDefRank": 12,
      "adjEM": 20.97,
      "adjOff": 112.8,
      "adjOffRank": 49,
      "adjTempo": 60.3,
      "adjTempoRank": 319,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 17,
      "record": "23-12",
      "seed": 9
    },
    "Middle Tennessee": {
      "adjDef": 102.7,
      "adjDefRank": 135,
      "adjEM": -4.24,
      "adjOff": 98.5,
      "adjOffRank": 256,
      "adjTempo": 60.7,
      "adjTempoRank": 317,
      "conference": "SB",
      "rank": 201,
      "record": "15-17"
    },
    "Milwaukee": {
      "adjDef": 105.5,
      "adjDefRank": 182,
      "adjEM": -5.38,
      "adjOff": 100.1,
      "adjOffRank": 232,
      "adjTempo": 66.9,
      "adjTempoRank": 118,
      "conference": "Horz",
      "rank": 210,
      "record": "9-22"
    },
    "Minnesota": {
      "adjDef": 101.7,
      "adjDefRank": 118,
      "adjEM": -0.53,
      "adjOff": 101.2,
      "adjOffRank": 216,
      "adjTempo": 65.1,
      "adjTempoRank": 195,
      "conference": "B10",
      "rank": 160,
      "record": "9-22"
    },
    "Mississippi": {
      "adjDef": 99.2,
      "adjDefRank": 77,
      "adjEM": 13.49,
      "adjOff": 112.7,
      "adjOffRank": 50,
      "adjTempo": 67.2,
      "adjTempoRank": 110,
      "conference": "SEC",
      "rank": 60,
      "record": "21-13"
    },
    "Mississippi St.": {
      "adjDef": 94.5,
      "adjDefRank": 27,
      "adjEM": 19.03,
      "adjOff": 113.5,
      "adjOffRank": 39,
      "adjTempo": 69.6,
      "adjTempoRank": 42,
      "conference": "SEC",
      "rank": 26,
      "record": "21-14"
    },
    "Mississippi Valley St.": {
      "adjDef": 102.9,
      "adjDefRank": 136,
      "adjEM": -9.45,
      "adjOff": 93.4,
      "adjOffRank": 308,
      "adjTempo": 63.6,
      "adjTempoRank": 254,
      "conference": "SWAC",
      "rank": 250,
      "record": "18-16"
    },
    "Missouri": {
      "adjDef": 98.6,
      "adjDefRank": 69,
      "adjEM": 12.78,
      "adjOff": 111.4,
      "adjOffRank": 67,
      "adjTempo": 72,
      "adjTempoRank": 12,
      "conference": "B12",
      "rank": 63,
      "record": "18-12"
    },
    "Missouri St.": {
      "adjDef": 95.9,
      "adjDefRank": 42,
      "adjEM": 17.51,
      "adjOff": 113.4,
      "adjOffRank": 41,
      "adjTempo": 67.7,
      "adjTempoRank": 88,
      "conference": "MVC",
      "rank": 35,
      "record": "22-11"
    },
    "Monmouth": {
      "adjDef": 109.5,
      "adjDefRank": 257,
      "adjEM": -9.99,
      "adjOff": 99.5,
      "adjOffRank": 240,
      "adjTempo": 62.7,
      "adjTempoRank": 290,
      "conference": "NEC",
      "rank": 260,
      "record": "12-18"
    },
    "Montana": {
      "adjDef": 110.5,
      "adjDefRank": 287,
      "adjEM": -3.27,
      "adjOff": 107.3,
      "adjOffRank": 118,
      "adjTempo": 62.8,
      "adjTempoRank": 288,
      "conference": "BSky",
      "rank": 192,
      "record": "17-15"
    },
    "Montana St.": {
      "adjDef": 108.7,
      "adjDefRank": 245,
      "adjEM": -11.13,
      "adjOff": 97.6,
      "adjOffRank": 269,
      "adjTempo": 65,
      "adjTempoRank": 199,
      "conference": "BSky",
      "rank": 273,
      "record": "11-19"
    },
    "Morehead St.": {
      "adjDef": 110.1,
      "adjDefRank": 274,
      "adjEM": -12.27,
      "adjOff": 97.8,
      "adjOffRank": 267,
      "adjTempo": 63.5,
      "adjTempoRank": 259,
      "conference": "OVC",
      "rank": 281,
      "record": "12-18"
    },
    "Morgan St.": {
      "adjDef": 110.4,
      "adjDefRank": 283,
      "adjEM": -13.15,
      "adjOff": 97.3,
      "adjOffRank": 273,
      "adjTempo": 66.4,
      "adjTempoRank": 133,
      "conference": "MEAC",
      "rank": 287,
      "record": "13-18"
    },
    "Mount St. Mary's": {
      "adjDef": 104.1,
      "adjDefRank": 156,
      "adjEM": -8.52,
      "adjOff": 95.6,
      "adjOffRank": 288,
      "adjTempo": 64.5,
      "adjTempoRank": 216,
      "conference": "NEC",
      "rank": 240,
      "record": "11-20"
    },
    "Murray St.": {
      "adjDef": 104.7,
      "adjDefRank": 164,
      "adjEM": -6.31,
      "adjOff": 98.4,
      "adjOffRank": 259,
      "adjTempo": 64.9,
      "adjTempoRank": 202,
      "conference": "OVC",
      "rank": 218,
      "record": "16-14"
    },
    "N.C. State": {
      "adjDef": 101.8,
      "adjDefRank": 119,
      "adjEM": 10.46,
      "adjOff": 112.2,
      "adjOffRank": 59,
      "adjTempo": 66.2,
      "adjTempoRank": 149,
      "conference": "ACC",
      "rank": 75,
      "record": "20-16"
    },
    "NJIT": {
      "adjDef": 110.4,
      "adjDefRank": 282,
      "adjEM": -25.11,
      "adjOff": 85.3,
      "adjOffRank": 332,
      "adjTempo": 65.3,
      "adjTempoRank": 184,
      "conference": "ind",
      "rank": 334,
      "record": "5-24"
    },
    "Navy": {
      "adjDef": 106,
      "adjDefRank": 191,
      "adjEM": -10.55,
      "adjOff": 95.5,
      "adjOffRank": 289,
      "adjTempo": 64.8,
      "adjTempoRank": 208,
      "conference": "Pat",
      "rank": 266,
      "record": "14-16"
    },
    "Nebraska": {
      "adjDef": 100,
      "adjDefRank": 89,
      "adjEM": 8.99,
      "adjOff": 109,
      "adjOffRank": 95,
      "adjTempo": 62.2,
      "adjTempoRank": 297,
      "conference": "B12",
      "rank": 82,
      "record": "17-14"
    },
    "Nevada": {
      "adjDef": 101.2,
      "adjDefRank": 109,
      "adjEM": 16.09,
      "adjOff": 117.2,
      "adjOffRank": 14,
      "adjTempo": 66.3,
      "adjTempoRank": 139,
      "conference": "WAC",
      "finish": "Round of 32",
      "rank": 47,
      "record": "29-5",
      "seed": 7
    },
    "New Hampshire": {
      "adjDef": 110.6,
      "adjDefRank": 288,
      "adjEM": -13.67,
      "adjOff": 97,
      "adjOffRank": 277,
      "adjTempo": 60.1,
      "adjTempoRank": 321,
      "conference": "AE",
      "rank": 290,
      "record": "10-20"
    },
    "New Mexico": {
      "adjDef": 108.5,
      "adjDefRank": 239,
      "adjEM": 2.18,
      "adjOff": 110.7,
      "adjOffRank": 75,
      "adjTempo": 65.3,
      "adjTempoRank": 186,
      "conference": "MWC",
      "rank": 139,
      "record": "15-17"
    },
    "New Mexico St.": {
      "adjDef": 101.1,
      "adjDefRank": 105,
      "adjEM": 7.27,
      "adjOff": 108.3,
      "adjOffRank": 101,
      "adjTempo": 69.7,
      "adjTempoRank": 39,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 96,
      "record": "25-9",
      "seed": 13
    },
    "New Orleans": {
      "adjDef": 115.1,
      "adjDefRank": 332,
      "adjEM": -7.33,
      "adjOff": 107.8,
      "adjOffRank": 107,
      "adjTempo": 64.1,
      "adjTempoRank": 230,
      "conference": "SB",
      "rank": 231,
      "record": "14-17"
    },
    "Niagara": {
      "adjDef": 110,
      "adjDefRank": 271,
      "adjEM": -2.76,
      "adjOff": 107.2,
      "adjOffRank": 119,
      "adjTempo": 68.3,
      "adjTempoRank": 72,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 186,
      "record": "23-12",
      "seed": 16
    },
    "Nicholls St.": {
      "adjDef": 113.6,
      "adjDefRank": 319,
      "adjEM": -10.94,
      "adjOff": 102.7,
      "adjOffRank": 192,
      "adjTempo": 69,
      "adjTempoRank": 57,
      "conference": "Slnd",
      "rank": 272,
      "record": "8-22"
    },
    "Norfolk St.": {
      "adjDef": 116.2,
      "adjDefRank": 335,
      "adjEM": -19.14,
      "adjOff": 97.1,
      "adjOffRank": 276,
      "adjTempo": 66.4,
      "adjTempoRank": 135,
      "conference": "MEAC",
      "rank": 322,
      "record": "11-19"
    },
    "North Carolina": {
      "adjDef": 89.6,
      "adjDefRank": 4,
      "adjEM": 31.37,
      "adjOff": 121,
      "adjOffRank": 3,
      "adjTempo": 72.4,
      "adjTempoRank": 8,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 1,
      "record": "31-7",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 108.7,
      "adjDefRank": 244,
      "adjEM": -12.6,
      "adjOff": 96.1,
      "adjOffRank": 285,
      "adjTempo": 70.9,
      "adjTempoRank": 24,
      "conference": "MEAC",
      "rank": 283,
      "record": "15-17"
    },
    "North Dakota St.": {
      "adjDef": 103.4,
      "adjDefRank": 146,
      "adjEM": 6.2,
      "adjOff": 109.6,
      "adjOffRank": 87,
      "adjTempo": 67.2,
      "adjTempoRank": 107,
      "conference": "ind",
      "rank": 104,
      "record": "20-8"
    },
    "North Florida": {
      "adjDef": 108.6,
      "adjDefRank": 242,
      "adjEM": -26.02,
      "adjOff": 82.6,
      "adjOffRank": 336,
      "adjTempo": 62.9,
      "adjTempoRank": 285,
      "conference": "ASun",
      "rank": 335,
      "record": "3-26"
    },
    "North Texas": {
      "adjDef": 104,
      "adjDefRank": 154,
      "adjEM": -0.98,
      "adjOff": 103,
      "adjOffRank": 187,
      "adjTempo": 69.1,
      "adjTempoRank": 55,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 162,
      "record": "23-11",
      "seed": 15
    },
    "Northeastern": {
      "adjDef": 104,
      "adjDefRank": 153,
      "adjEM": -2.8,
      "adjOff": 101.2,
      "adjOffRank": 218,
      "adjTempo": 64.6,
      "adjTempoRank": 211,
      "conference": "CAA",
      "rank": 188,
      "record": "13-19"
    },
    "Northern Arizona": {
      "adjDef": 111.1,
      "adjDefRank": 291,
      "adjEM": -0.94,
      "adjOff": 110.1,
      "adjOffRank": 82,
      "adjTempo": 72.1,
      "adjTempoRank": 10,
      "conference": "BSky",
      "rank": 161,
      "record": "18-12"
    },
    "Northern Colorado": {
      "adjDef": 111.7,
      "adjDefRank": 302,
      "adjEM": -16.8,
      "adjOff": 94.9,
      "adjOffRank": 296,
      "adjTempo": 67.4,
      "adjTempoRank": 100,
      "conference": "BSky",
      "rank": 311,
      "record": "4-24"
    },
    "Northern Illinois": {
      "adjDef": 109.5,
      "adjDefRank": 255,
      "adjEM": -7.32,
      "adjOff": 102.2,
      "adjOffRank": 201,
      "adjTempo": 65.7,
      "adjTempoRank": 171,
      "conference": "MAC",
      "rank": 230,
      "record": "7-23"
    },
    "Northern Iowa": {
      "adjDef": 97.6,
      "adjDefRank": 56,
      "adjEM": 11.43,
      "adjOff": 109,
      "adjOffRank": 96,
      "adjTempo": 61.6,
      "adjTempoRank": 305,
      "conference": "MVC",
      "rank": 69,
      "record": "18-13"
    },
    "Northwestern": {
      "adjDef": 103,
      "adjDefRank": 139,
      "adjEM": 2.51,
      "adjOff": 105.5,
      "adjOffRank": 144,
      "adjTempo": 56.9,
      "adjTempoRank": 333,
      "conference": "B10",
      "rank": 136,
      "record": "13-18"
    },
    "Northwestern St.": {
      "adjDef": 106,
      "adjDefRank": 190,
      "adjEM": -1.31,
      "adjOff": 104.7,
      "adjOffRank": 160,
      "adjTempo": 74.5,
      "adjTempoRank": 4,
      "conference": "Slnd",
      "rank": 165,
      "record": "17-15"
    },
    "Notre Dame": {
      "adjDef": 98.1,
      "adjDefRank": 62,
      "adjEM": 20.02,
      "adjOff": 118.1,
      "adjOffRank": 9,
      "adjTempo": 70.7,
      "adjTempoRank": 28,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 22,
      "record": "24-8",
      "seed": 6
    },
    "Oakland": {
      "adjDef": 106.7,
      "adjDefRank": 209,
      "adjEM": 3.45,
      "adjOff": 110.2,
      "adjOffRank": 81,
      "adjTempo": 63.9,
      "adjTempoRank": 240,
      "conference": "MCon",
      "rank": 128,
      "record": "19-14"
    },
    "Ohio": {
      "adjDef": 105.3,
      "adjDefRank": 178,
      "adjEM": 4.08,
      "adjOff": 109.4,
      "adjOffRank": 90,
      "adjTempo": 66.2,
      "adjTempoRank": 145,
      "conference": "MAC",
      "rank": 125,
      "record": "19-13"
    },
    "Ohio St.": {
      "adjDef": 91.7,
      "adjDefRank": 11,
      "adjEM": 29.33,
      "adjOff": 121,
      "adjOffRank": 4,
      "adjTempo": 64.9,
      "adjTempoRank": 206,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 3,
      "record": "35-4",
      "seed": 1
    },
    "Oklahoma": {
      "adjDef": 93.8,
      "adjDefRank": 22,
      "adjEM": 13.68,
      "adjOff": 107.4,
      "adjOffRank": 112,
      "adjTempo": 62.8,
      "adjTempoRank": 287,
      "conference": "B12",
      "rank": 58,
      "record": "16-15"
    },
    "Oklahoma St.": {
      "adjDef": 98.6,
      "adjDefRank": 68,
      "adjEM": 10.63,
      "adjOff": 109.2,
      "adjOffRank": 92,
      "adjTempo": 65.4,
      "adjTempoRank": 181,
      "conference": "B12",
      "rank": 74,
      "record": "22-13"
    },
    "Old Dominion": {
      "adjDef": 95.9,
      "adjDefRank": 41,
      "adjEM": 12.7,
      "adjOff": 108.6,
      "adjOffRank": 99,
      "adjTempo": 65.8,
      "adjTempoRank": 161,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 64,
      "record": "24-9",
      "seed": 12
    },
    "Oral Roberts": {
      "adjDef": 98.7,
      "adjDefRank": 74,
      "adjEM": 8.36,
      "adjOff": 107.1,
      "adjOffRank": 123,
      "adjTempo": 66.5,
      "adjTempoRank": 130,
      "conference": "MCon",
      "finish": "Round of 64",
      "rank": 88,
      "record": "23-11",
      "seed": 14
    },
    "Oregon": {
      "adjDef": 97.6,
      "adjDefRank": 54,
      "adjEM": 20.76,
      "adjOff": 118.3,
      "adjOffRank": 8,
      "adjTempo": 66,
      "adjTempoRank": 158,
      "conference": "P10",
      "finish": "Elite Eight",
      "rank": 18,
      "record": "29-8",
      "seed": 3
    },
    "Oregon St.": {
      "adjDef": 104.6,
      "adjDefRank": 162,
      "adjEM": -2.09,
      "adjOff": 102.6,
      "adjOffRank": 195,
      "adjTempo": 65.1,
      "adjTempoRank": 194,
      "conference": "P10",
      "rank": 174,
      "record": "11-21"
    },
    "Pacific": {
      "adjDef": 109.9,
      "adjDefRank": 269,
      "adjEM": -5.34,
      "adjOff": 104.6,
      "adjOffRank": 162,
      "adjTempo": 62.3,
      "adjTempoRank": 296,
      "conference": "BW",
      "rank": 209,
      "record": "12-19"
    },
    "Penn": {
      "adjDef": 102.1,
      "adjDefRank": 125,
      "adjEM": 7.69,
      "adjOff": 109.8,
      "adjOffRank": 85,
      "adjTempo": 67.9,
      "adjTempoRank": 82,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 92,
      "record": "22-9",
      "seed": 14
    },
    "Penn St.": {
      "adjDef": 106.9,
      "adjDefRank": 214,
      "adjEM": 1.38,
      "adjOff": 108.3,
      "adjOffRank": 103,
      "adjTempo": 62.9,
      "adjTempoRank": 286,
      "conference": "B10",
      "rank": 147,
      "record": "11-19"
    },
    "Pepperdine": {
      "adjDef": 111.6,
      "adjDefRank": 300,
      "adjEM": -6.57,
      "adjOff": 105,
      "adjOffRank": 152,
      "adjTempo": 72.4,
      "adjTempoRank": 9,
      "conference": "WCC",
      "rank": 220,
      "record": "8-23"
    },
    "Pittsburgh": {
      "adjDef": 95.2,
      "adjDefRank": 33,
      "adjEM": 22.9,
      "adjOff": 118.1,
      "adjOffRank": 10,
      "adjTempo": 62.2,
      "adjTempoRank": 298,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 11,
      "record": "29-8",
      "seed": 3
    },
    "Portland": {
      "adjDef": 106.1,
      "adjDefRank": 194,
      "adjEM": -11.21,
      "adjOff": 94.9,
      "adjOffRank": 297,
      "adjTempo": 63.7,
      "adjTempoRank": 248,
      "conference": "WCC",
      "rank": 274,
      "record": "9-23"
    },
    "Portland St.": {
      "adjDef": 106.8,
      "adjDefRank": 211,
      "adjEM": -2.18,
      "adjOff": 104.7,
      "adjOffRank": 161,
      "adjTempo": 70.9,
      "adjTempoRank": 25,
      "conference": "BSky",
      "rank": 176,
      "record": "19-13"
    },
    "Prairie View A&M": {
      "adjDef": 107.9,
      "adjDefRank": 229,
      "adjEM": -23.69,
      "adjOff": 84.2,
      "adjOffRank": 333,
      "adjTempo": 69.6,
      "adjTempoRank": 41,
      "conference": "SWAC",
      "rank": 332,
      "record": "8-22"
    },
    "Princeton": {
      "adjDef": 103.1,
      "adjDefRank": 141,
      "adjEM": -10.68,
      "adjOff": 92.5,
      "adjOffRank": 316,
      "adjTempo": 51.6,
      "adjTempoRank": 336,
      "conference": "Ivy",
      "rank": 269,
      "record": "11-17"
    },
    "Providence": {
      "adjDef": 100.8,
      "adjDefRank": 100,
      "adjEM": 10.93,
      "adjOff": 111.8,
      "adjOffRank": 63,
      "adjTempo": 68.6,
      "adjTempoRank": 66,
      "conference": "BE",
      "rank": 72,
      "record": "18-13"
    },
    "Purdue": {
      "adjDef": 92.4,
      "adjDefRank": 15,
      "adjEM": 18.31,
      "adjOff": 110.7,
      "adjOffRank": 74,
      "adjTempo": 66.5,
      "adjTempoRank": 131,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 30,
      "record": "22-12",
      "seed": 9
    },
    "Quinnipiac": {
      "adjDef": 108.4,
      "adjDefRank": 236,
      "adjEM": -9.51,
      "adjOff": 98.9,
      "adjOffRank": 249,
      "adjTempo": 69.5,
      "adjTempoRank": 45,
      "conference": "NEC",
      "rank": 253,
      "record": "14-15"
    },
    "Radford": {
      "adjDef": 108.7,
      "adjDefRank": 246,
      "adjEM": -13.6,
      "adjOff": 95.1,
      "adjOffRank": 291,
      "adjTempo": 69.6,
      "adjTempoRank": 44,
      "conference": "BSth",
      "rank": 289,
      "record": "8-22"
    },
    "Rhode Island": {
      "adjDef": 110.5,
      "adjDefRank": 285,
      "adjEM": 2.55,
      "adjOff": 113,
      "adjOffRank": 47,
      "adjTempo": 67.6,
      "adjTempoRank": 91,
      "conference": "A10",
      "rank": 135,
      "record": "19-14"
    },
    "Rice": {
      "adjDef": 105.2,
      "adjDefRank": 174,
      "adjEM": -1.86,
      "adjOff": 103.3,
      "adjOffRank": 185,
      "adjTempo": 65.8,
      "adjTempoRank": 167,
      "conference": "CUSA",
      "rank": 171,
      "record": "16-16"
    },
    "Richmond": {
      "adjDef": 111.4,
      "adjDefRank": 297,
      "adjEM": -11.97,
      "adjOff": 99.4,
      "adjOffRank": 244,
      "adjTempo": 59.2,
      "adjTempoRank": 328,
      "conference": "A10",
      "rank": 278,
      "record": "8-22"
    },
    "Rider": {
      "adjDef": 111.2,
      "adjDefRank": 292,
      "adjEM": -9.08,
      "adjOff": 102.1,
      "adjOffRank": 206,
      "adjTempo": 67.7,
      "adjTempoRank": 87,
      "conference": "MAAC",
      "rank": 244,
      "record": "16-15"
    },
    "Robert Morris": {
      "adjDef": 112.3,
      "adjDefRank": 310,
      "adjEM": -5.54,
      "adjOff": 106.7,
      "adjOffRank": 130,
      "adjTempo": 66.1,
      "adjTempoRank": 154,
      "conference": "NEC",
      "rank": 212,
      "record": "17-11"
    },
    "Rutgers": {
      "adjDef": 106.7,
      "adjDefRank": 206,
      "adjEM": -5.32,
      "adjOff": 101.3,
      "adjOffRank": 214,
      "adjTempo": 60.9,
      "adjTempoRank": 315,
      "conference": "BE",
      "rank": 208,
      "record": "10-19"
    },
    "SMU": {
      "adjDef": 103.1,
      "adjDefRank": 140,
      "adjEM": -1.56,
      "adjOff": 101.5,
      "adjOffRank": 210,
      "adjTempo": 61,
      "adjTempoRank": 313,
      "conference": "CUSA",
      "rank": 169,
      "record": "14-17"
    },
    "Sacramento St.": {
      "adjDef": 108.8,
      "adjDefRank": 247,
      "adjEM": -13.74,
      "adjOff": 95.1,
      "adjOffRank": 292,
      "adjTempo": 74.8,
      "adjTempoRank": 3,
      "conference": "BSky",
      "rank": 291,
      "record": "10-19"
    },
    "Sacred Heart": {
      "adjDef": 110.3,
      "adjDefRank": 280,
      "adjEM": -2.75,
      "adjOff": 107.6,
      "adjOffRank": 110,
      "adjTempo": 71.3,
      "adjTempoRank": 20,
      "conference": "NEC",
      "rank": 185,
      "record": "18-14"
    },
    "Saint Joseph's": {
      "adjDef": 102.1,
      "adjDefRank": 126,
      "adjEM": 7.01,
      "adjOff": 109.1,
      "adjOffRank": 93,
      "adjTempo": 62.5,
      "adjTempoRank": 292,
      "conference": "A10",
      "rank": 97,
      "record": "18-14"
    },
    "Saint Louis": {
      "adjDef": 100.4,
      "adjDefRank": 94,
      "adjEM": 4.39,
      "adjOff": 104.8,
      "adjOffRank": 157,
      "adjTempo": 61.8,
      "adjTempoRank": 303,
      "conference": "A10",
      "rank": 120,
      "record": "20-13"
    },
    "Saint Mary's": {
      "adjDef": 100,
      "adjDefRank": 88,
      "adjEM": 6.49,
      "adjOff": 106.5,
      "adjOffRank": 134,
      "adjTempo": 65.1,
      "adjTempoRank": 196,
      "conference": "WCC",
      "rank": 103,
      "record": "17-15"
    },
    "Saint Peter's": {
      "adjDef": 106.1,
      "adjDefRank": 195,
      "adjEM": -17.22,
      "adjOff": 88.9,
      "adjOffRank": 326,
      "adjTempo": 63.9,
      "adjTempoRank": 238,
      "conference": "MAAC",
      "rank": 314,
      "record": "5-25"
    },
    "Sam Houston St.": {
      "adjDef": 103.3,
      "adjDefRank": 145,
      "adjEM": 2.91,
      "adjOff": 106.2,
      "adjOffRank": 139,
      "adjTempo": 69.6,
      "adjTempoRank": 43,
      "conference": "Slnd",
      "rank": 132,
      "record": "21-10"
    },
    "Samford": {
      "adjDef": 112.8,
      "adjDefRank": 314,
      "adjEM": -6.62,
      "adjOff": 106.2,
      "adjOffRank": 138,
      "adjTempo": 54.5,
      "adjTempoRank": 334,
      "conference": "OVC",
      "rank": 221,
      "record": "16-16"
    },
    "San Diego": {
      "adjDef": 108,
      "adjDefRank": 230,
      "adjEM": -0.45,
      "adjOff": 107.5,
      "adjOffRank": 111,
      "adjTempo": 69.1,
      "adjTempoRank": 54,
      "conference": "WCC",
      "rank": 159,
      "record": "18-14"
    },
    "San Diego St.": {
      "adjDef": 94.6,
      "adjDefRank": 28,
      "adjEM": 9.87,
      "adjOff": 104.4,
      "adjOffRank": 167,
      "adjTempo": 69,
      "adjTempoRank": 58,
      "conference": "MWC",
      "rank": 80,
      "record": "22-11"
    },
    "San Francisco": {
      "adjDef": 105.3,
      "adjDefRank": 179,
      "adjEM": -0.15,
      "adjOff": 105.2,
      "adjOffRank": 149,
      "adjTempo": 68.3,
      "adjTempoRank": 74,
      "conference": "WCC",
      "rank": 157,
      "record": "13-18"
    },
    "San Jose St.": {
      "adjDef": 102.5,
      "adjDefRank": 132,
      "adjEM": -7.57,
      "adjOff": 95,
      "adjOffRank": 295,
      "adjTempo": 65.8,
      "adjTempoRank": 166,
      "conference": "WAC",
      "rank": 233,
      "record": "5-25"
    },
    "Santa Clara": {
      "adjDef": 96.7,
      "adjDefRank": 48,
      "adjEM": 10.03,
      "adjOff": 106.7,
      "adjOffRank": 131,
      "adjTempo": 63.8,
      "adjTempoRank": 244,
      "conference": "WCC",
      "rank": 77,
      "record": "21-10"
    },
    "Savannah St.": {
      "adjDef": 108.4,
      "adjDefRank": 234,
      "adjEM": -20.39,
      "adjOff": 88,
      "adjOffRank": 328,
      "adjTempo": 64.1,
      "adjTempoRank": 233,
      "conference": "ind",
      "rank": 326,
      "record": "12-18"
    },
    "Seton Hall": {
      "adjDef": 102.3,
      "adjDefRank": 130,
      "adjEM": 6.19,
      "adjOff": 108.5,
      "adjOffRank": 100,
      "adjTempo": 70.2,
      "adjTempoRank": 34,
      "conference": "BE",
      "rank": 105,
      "record": "13-16"
    },
    "Siena": {
      "adjDef": 105.9,
      "adjDefRank": 189,
      "adjEM": 1.16,
      "adjOff": 107.1,
      "adjOffRank": 124,
      "adjTempo": 68.6,
      "adjTempoRank": 67,
      "conference": "MAAC",
      "rank": 149,
      "record": "20-12"
    },
    "South Alabama": {
      "adjDef": 103,
      "adjDefRank": 137,
      "adjEM": 1.55,
      "adjOff": 104.5,
      "adjOffRank": 163,
      "adjTempo": 64.6,
      "adjTempoRank": 212,
      "conference": "SB",
      "rank": 146,
      "record": "20-12"
    },
    "South Carolina": {
      "adjDef": 106.4,
      "adjDefRank": 199,
      "adjEM": 3.96,
      "adjOff": 110.3,
      "adjOffRank": 79,
      "adjTempo": 61,
      "adjTempoRank": 314,
      "conference": "SEC",
      "rank": 126,
      "record": "14-16"
    },
    "South Carolina St.": {
      "adjDef": 108.5,
      "adjDefRank": 240,
      "adjEM": -12.92,
      "adjOff": 95.6,
      "adjOffRank": 286,
      "adjTempo": 64.5,
      "adjTempoRank": 213,
      "conference": "MEAC",
      "rank": 286,
      "record": "13-17"
    },
    "South Dakota St.": {
      "adjDef": 112.8,
      "adjDefRank": 313,
      "adjEM": -18.32,
      "adjOff": 94.5,
      "adjOffRank": 300,
      "adjTempo": 64.9,
      "adjTempoRank": 204,
      "conference": "ind",
      "rank": 319,
      "record": "6-24"
    },
    "South Florida": {
      "adjDef": 101.3,
      "adjDefRank": 111,
      "adjEM": 0.81,
      "adjOff": 102.1,
      "adjOffRank": 205,
      "adjTempo": 65.2,
      "adjTempoRank": 189,
      "conference": "BE",
      "rank": 150,
      "record": "12-18"
    },
    "Southeast Missouri St.": {
      "adjDef": 113.7,
      "adjDefRank": 321,
      "adjEM": -14.4,
      "adjOff": 99.3,
      "adjOffRank": 247,
      "adjTempo": 71,
      "adjTempoRank": 23,
      "conference": "OVC",
      "rank": 296,
      "record": "11-20"
    },
    "Southeastern Louisiana": {
      "adjDef": 109.4,
      "adjDefRank": 253,
      "adjEM": -7.27,
      "adjOff": 102.1,
      "adjOffRank": 202,
      "adjTempo": 63.1,
      "adjTempoRank": 273,
      "conference": "Slnd",
      "rank": 229,
      "record": "16-14"
    },
    "Southern": {
      "adjDef": 105,
      "adjDefRank": 171,
      "adjEM": -15.77,
      "adjOff": 89.2,
      "adjOffRank": 325,
      "adjTempo": 66.4,
      "adjTempoRank": 137,
      "conference": "SWAC",
      "rank": 304,
      "record": "10-21"
    },
    "Southern Illinois": {
      "adjDef": 88.8,
      "adjDefRank": 3,
      "adjEM": 21.03,
      "adjOff": 109.8,
      "adjOffRank": 84,
      "adjTempo": 61,
      "adjTempoRank": 312,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 16,
      "record": "29-7",
      "seed": 4
    },
    "Southern Miss": {
      "adjDef": 100.3,
      "adjDefRank": 93,
      "adjEM": 3.65,
      "adjOff": 104,
      "adjOffRank": 172,
      "adjTempo": 65.2,
      "adjTempoRank": 188,
      "conference": "CUSA",
      "rank": 127,
      "record": "20-11"
    },
    "Southern Utah": {
      "adjDef": 106.1,
      "adjDefRank": 193,
      "adjEM": -2.55,
      "adjOff": 103.5,
      "adjOffRank": 181,
      "adjTempo": 63,
      "adjTempoRank": 277,
      "conference": "MCon",
      "rank": 178,
      "record": "16-14"
    },
    "St. Bonaventure": {
      "adjDef": 113.9,
      "adjDefRank": 322,
      "adjEM": -15.3,
      "adjOff": 98.6,
      "adjOffRank": 253,
      "adjTempo": 68,
      "adjTempoRank": 81,
      "conference": "A10",
      "rank": 297,
      "record": "7-22"
    },
    "St. Francis NY": {
      "adjDef": 105.6,
      "adjDefRank": 183,
      "adjEM": -12.23,
      "adjOff": 93.3,
      "adjOffRank": 309,
      "adjTempo": 65,
      "adjTempoRank": 200,
      "conference": "NEC",
      "rank": 279,
      "record": "9-22"
    },
    "St. Francis PA": {
      "adjDef": 114.3,
      "adjDefRank": 325,
      "adjEM": -15.79,
      "adjOff": 98.5,
      "adjOffRank": 255,
      "adjTempo": 64.5,
      "adjTempoRank": 214,
      "conference": "NEC",
      "rank": 305,
      "record": "8-21"
    },
    "St. John's": {
      "adjDef": 99.9,
      "adjDefRank": 86,
      "adjEM": 3.41,
      "adjOff": 103.3,
      "adjOffRank": 184,
      "adjTempo": 63.8,
      "adjTempoRank": 242,
      "conference": "BE",
      "rank": 129,
      "record": "16-15"
    },
    "Stanford": {
      "adjDef": 97.3,
      "adjDefRank": 52,
      "adjEM": 12.37,
      "adjOff": 109.7,
      "adjOffRank": 86,
      "adjTempo": 65.9,
      "adjTempoRank": 160,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 66,
      "record": "18-13",
      "seed": 11
    },
    "Stephen F. Austin": {
      "adjDef": 102,
      "adjDefRank": 123,
      "adjEM": -3.79,
      "adjOff": 98.2,
      "adjOffRank": 262,
      "adjTempo": 60.5,
      "adjTempoRank": 318,
      "conference": "Slnd",
      "rank": 195,
      "record": "15-14"
    },
    "Stetson": {
      "adjDef": 113.6,
      "adjDefRank": 320,
      "adjEM": -16.38,
      "adjOff": 97.2,
      "adjOffRank": 275,
      "adjTempo": 65.1,
      "adjTempoRank": 197,
      "conference": "ASun",
      "rank": 308,
      "record": "11-20"
    },
    "Stony Brook": {
      "adjDef": 104.8,
      "adjDefRank": 166,
      "adjEM": -11.89,
      "adjOff": 92.9,
      "adjOffRank": 312,
      "adjTempo": 64.5,
      "adjTempoRank": 219,
      "conference": "AE",
      "rank": 277,
      "record": "9-20"
    },
    "Syracuse": {
      "adjDef": 95.2,
      "adjDefRank": 32,
      "adjEM": 16.84,
      "adjOff": 112,
      "adjOffRank": 60,
      "adjTempo": 70.4,
      "adjTempoRank": 32,
      "conference": "BE",
      "rank": 40,
      "record": "24-11"
    },
    "TCU": {
      "adjDef": 98.1,
      "adjDefRank": 61,
      "adjEM": -1.94,
      "adjOff": 96.2,
      "adjOffRank": 283,
      "adjTempo": 66.8,
      "adjTempoRank": 120,
      "conference": "MWC",
      "rank": 172,
      "record": "13-17"
    },
    "Temple": {
      "adjDef": 107.2,
      "adjDefRank": 219,
      "adjEM": 3.06,
      "adjOff": 110.3,
      "adjOffRank": 80,
      "adjTempo": 68.8,
      "adjTempoRank": 61,
      "conference": "A10",
      "rank": 131,
      "record": "12-18"
    },
    "Tennessee": {
      "adjDef": 97.6,
      "adjDefRank": 55,
      "adjEM": 18.29,
      "adjOff": 115.9,
      "adjOffRank": 22,
      "adjTempo": 71.3,
      "adjTempoRank": 19,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 31,
      "record": "24-11",
      "seed": 5
    },
    "Tennessee Martin": {
      "adjDef": 111.3,
      "adjDefRank": 294,
      "adjEM": -16.96,
      "adjOff": 94.3,
      "adjOffRank": 302,
      "adjTempo": 66.6,
      "adjTempoRank": 127,
      "conference": "OVC",
      "rank": 312,
      "record": "8-23"
    },
    "Tennessee St.": {
      "adjDef": 109.9,
      "adjDefRank": 265,
      "adjEM": -12.31,
      "adjOff": 97.6,
      "adjOffRank": 270,
      "adjTempo": 64.5,
      "adjTempoRank": 215,
      "conference": "OVC",
      "rank": 282,
      "record": "12-20"
    },
    "Tennessee Tech": {
      "adjDef": 105.1,
      "adjDefRank": 172,
      "adjEM": -1.67,
      "adjOff": 103.4,
      "adjOffRank": 183,
      "adjTempo": 69.4,
      "adjTempoRank": 47,
      "conference": "OVC",
      "rank": 170,
      "record": "19-13"
    },
    "Texas": {
      "adjDef": 100.3,
      "adjDefRank": 92,
      "adjEM": 19.24,
      "adjOff": 119.5,
      "adjOffRank": 6,
      "adjTempo": 67.6,
      "adjTempoRank": 90,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 25,
      "record": "25-10",
      "seed": 4
    },
    "Texas A&M": {
      "adjDef": 92.3,
      "adjDefRank": 14,
      "adjEM": 26.97,
      "adjOff": 119.3,
      "adjOffRank": 7,
      "adjTempo": 63.7,
      "adjTempoRank": 247,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 7,
      "record": "27-7",
      "seed": 3
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 106.4,
      "adjDefRank": 200,
      "adjEM": 7.76,
      "adjOff": 114.1,
      "adjOffRank": 34,
      "adjTempo": 68.1,
      "adjTempoRank": 78,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 91,
      "record": "26-7",
      "seed": 15
    },
    "Texas Pan American": {
      "adjDef": 112.2,
      "adjDefRank": 309,
      "adjEM": -10.43,
      "adjOff": 101.8,
      "adjOffRank": 207,
      "adjTempo": 64.1,
      "adjTempoRank": 231,
      "conference": "ind",
      "rank": 264,
      "record": "14-15"
    },
    "Texas Southern": {
      "adjDef": 108.4,
      "adjDefRank": 235,
      "adjEM": -18.84,
      "adjOff": 89.5,
      "adjOffRank": 324,
      "adjTempo": 69.3,
      "adjTempoRank": 49,
      "conference": "SWAC",
      "rank": 321,
      "record": "14-17"
    },
    "Texas St.": {
      "adjDef": 114.3,
      "adjDefRank": 326,
      "adjEM": -16.42,
      "adjOff": 97.9,
      "adjOffRank": 266,
      "adjTempo": 75,
      "adjTempoRank": 2,
      "conference": "Slnd",
      "rank": 309,
      "record": "9-20"
    },
    "Texas Tech": {
      "adjDef": 101.3,
      "adjDefRank": 110,
      "adjEM": 10.98,
      "adjOff": 112.2,
      "adjOffRank": 57,
      "adjTempo": 64.9,
      "adjTempoRank": 205,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 71,
      "record": "21-13",
      "seed": 10
    },
    "The Citadel": {
      "adjDef": 112.6,
      "adjDefRank": 312,
      "adjEM": -20.18,
      "adjOff": 92.4,
      "adjOffRank": 317,
      "adjTempo": 59.7,
      "adjTempoRank": 325,
      "conference": "SC",
      "rank": 325,
      "record": "7-23"
    },
    "Toledo": {
      "adjDef": 100.1,
      "adjDefRank": 91,
      "adjEM": 5.61,
      "adjOff": 105.7,
      "adjOffRank": 142,
      "adjTempo": 66.9,
      "adjTempoRank": 119,
      "conference": "MAC",
      "rank": 111,
      "record": "19-13"
    },
    "Towson": {
      "adjDef": 104.8,
      "adjDefRank": 165,
      "adjEM": 0.74,
      "adjOff": 105.5,
      "adjOffRank": 145,
      "adjTempo": 64.6,
      "adjTempoRank": 210,
      "conference": "CAA",
      "rank": 151,
      "record": "15-17"
    },
    "Troy": {
      "adjDef": 113,
      "adjDefRank": 316,
      "adjEM": -7.26,
      "adjOff": 105.7,
      "adjOffRank": 143,
      "adjTempo": 67.2,
      "adjTempoRank": 109,
      "conference": "SB",
      "rank": 228,
      "record": "13-17"
    },
    "Tulane": {
      "adjDef": 101,
      "adjDefRank": 101,
      "adjEM": 1.83,
      "adjOff": 102.8,
      "adjOffRank": 190,
      "adjTempo": 69.8,
      "adjTempoRank": 38,
      "conference": "CUSA",
      "rank": 142,
      "record": "17-13"
    },
    "Tulsa": {
      "adjDef": 96.2,
      "adjDefRank": 43,
      "adjEM": 5.25,
      "adjOff": 101.4,
      "adjOffRank": 212,
      "adjTempo": 65.5,
      "adjTempoRank": 176,
      "conference": "CUSA",
      "rank": 115,
      "record": "20-11"
    },
    "UAB": {
      "adjDef": 97.7,
      "adjDefRank": 57,
      "adjEM": 4.92,
      "adjOff": 102.6,
      "adjOffRank": 194,
      "adjTempo": 64,
      "adjTempoRank": 236,
      "conference": "CUSA",
      "rank": 117,
      "record": "15-16"
    },
    "UC Davis": {
      "adjDef": 111.4,
      "adjDefRank": 298,
      "adjEM": -18.08,
      "adjOff": 93.3,
      "adjOffRank": 310,
      "adjTempo": 69.1,
      "adjTempoRank": 53,
      "conference": "ind",
      "rank": 318,
      "record": "5-23"
    },
    "UC Irvine": {
      "adjDef": 101,
      "adjDefRank": 102,
      "adjEM": -1.56,
      "adjOff": 99.5,
      "adjOffRank": 243,
      "adjTempo": 64.4,
      "adjTempoRank": 221,
      "conference": "BW",
      "rank": 168,
      "record": "15-18"
    },
    "UC Riverside": {
      "adjDef": 109.7,
      "adjDefRank": 263,
      "adjEM": -15.84,
      "adjOff": 93.9,
      "adjOffRank": 305,
      "adjTempo": 66.2,
      "adjTempoRank": 144,
      "conference": "BW",
      "rank": 306,
      "record": "7-24"
    },
    "UC Santa Barbara": {
      "adjDef": 104.5,
      "adjDefRank": 160,
      "adjEM": 0.7,
      "adjOff": 105.2,
      "adjOffRank": 150,
      "adjTempo": 63.5,
      "adjTempoRank": 258,
      "conference": "BW",
      "rank": 153,
      "record": "18-11"
    },
    "UCF": {
      "adjDef": 104,
      "adjDefRank": 152,
      "adjEM": 5.54,
      "adjOff": 109.5,
      "adjOffRank": 89,
      "adjTempo": 63.6,
      "adjTempoRank": 256,
      "conference": "CUSA",
      "rank": 112,
      "record": "22-9"
    },
    "UCLA": {
      "adjDef": 88.3,
      "adjDefRank": 2,
      "adjEM": 27.15,
      "adjOff": 115.4,
      "adjOffRank": 27,
      "adjTempo": 63.7,
      "adjTempoRank": 251,
      "conference": "P10",
      "finish": "Final Four",
      "rank": 6,
      "record": "30-6",
      "seed": 2
    },
    "UMBC": {
      "adjDef": 110.3,
      "adjDefRank": 279,
      "adjEM": -10.81,
      "adjOff": 99.5,
      "adjOffRank": 241,
      "adjTempo": 61.6,
      "adjTempoRank": 304,
      "conference": "AE",
      "rank": 270,
      "record": "12-19"
    },
    "UMKC": {
      "adjDef": 112.9,
      "adjDefRank": 315,
      "adjEM": -5.66,
      "adjOff": 107.3,
      "adjOffRank": 117,
      "adjTempo": 66,
      "adjTempoRank": 157,
      "conference": "MCon",
      "rank": 213,
      "record": "12-20"
    },
    "UNC Asheville": {
      "adjDef": 105.9,
      "adjDefRank": 188,
      "adjEM": -8.93,
      "adjOff": 96.9,
      "adjOffRank": 278,
      "adjTempo": 68,
      "adjTempoRank": 79,
      "conference": "BSth",
      "rank": 242,
      "record": "12-19"
    },
    "UNC Greensboro": {
      "adjDef": 103.6,
      "adjDefRank": 149,
      "adjEM": -1.21,
      "adjOff": 102.4,
      "adjOffRank": 198,
      "adjTempo": 66.6,
      "adjTempoRank": 125,
      "conference": "SC",
      "rank": 164,
      "record": "16-14"
    },
    "UNC Wilmington": {
      "adjDef": 106.5,
      "adjDefRank": 203,
      "adjEM": -6.96,
      "adjOff": 99.5,
      "adjOffRank": 239,
      "adjTempo": 65.6,
      "adjTempoRank": 173,
      "conference": "CAA",
      "rank": 225,
      "record": "7-22"
    },
    "UNLV": {
      "adjDef": 95.8,
      "adjDefRank": 40,
      "adjEM": 16.83,
      "adjOff": 112.6,
      "adjOffRank": 51,
      "adjTempo": 65.2,
      "adjTempoRank": 190,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 41,
      "record": "30-7",
      "seed": 7
    },
    "USC": {
      "adjDef": 94.2,
      "adjDefRank": 26,
      "adjEM": 16.86,
      "adjOff": 111,
      "adjOffRank": 72,
      "adjTempo": 67.1,
      "adjTempoRank": 111,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 39,
      "record": "25-12",
      "seed": 5
    },
    "UT Arlington": {
      "adjDef": 111.3,
      "adjDefRank": 293,
      "adjEM": -10.06,
      "adjOff": 101.2,
      "adjOffRank": 217,
      "adjTempo": 69.9,
      "adjTempoRank": 36,
      "conference": "Slnd",
      "rank": 261,
      "record": "13-17"
    },
    "UTEP": {
      "adjDef": 101,
      "adjDefRank": 103,
      "adjEM": 2.68,
      "adjOff": 103.7,
      "adjOffRank": 178,
      "adjTempo": 72.1,
      "adjTempoRank": 11,
      "conference": "CUSA",
      "rank": 133,
      "record": "14-17"
    },
    "UTSA": {
      "adjDef": 104.4,
      "adjDefRank": 159,
      "adjEM": -17.66,
      "adjOff": 86.7,
      "adjOffRank": 331,
      "adjTempo": 63.3,
      "adjTempoRank": 267,
      "conference": "Slnd",
      "rank": 316,
      "record": "7-22"
    },
    "Utah": {
      "adjDef": 112.1,
      "adjDefRank": 308,
      "adjEM": 1.63,
      "adjOff": 113.7,
      "adjOffRank": 38,
      "adjTempo": 61.2,
      "adjTempoRank": 310,
      "conference": "MWC",
      "rank": 145,
      "record": "11-19"
    },
    "Utah St.": {
      "adjDef": 103.4,
      "adjDefRank": 147,
      "adjEM": 7.82,
      "adjOff": 111.2,
      "adjOffRank": 68,
      "adjTempo": 63.5,
      "adjTempoRank": 257,
      "conference": "WAC",
      "rank": 90,
      "record": "23-12"
    },
    "Utah Valley St.": {
      "adjDef": 103.2,
      "adjDefRank": 144,
      "adjEM": 6.61,
      "adjOff": 109.9,
      "adjOffRank": 83,
      "adjTempo": 62.6,
      "adjTempoRank": 291,
      "conference": "ind",
      "rank": 101,
      "record": "22-7"
    },
    "VCU": {
      "adjDef": 102,
      "adjDefRank": 124,
      "adjEM": 14.55,
      "adjOff": 116.6,
      "adjOffRank": 18,
      "adjTempo": 65.5,
      "adjTempoRank": 178,
      "conference": "CAA",
      "finish": "Round of 32",
      "rank": 52,
      "record": "28-7",
      "seed": 11
    },
    "VMI": {
      "adjDef": 114.6,
      "adjDefRank": 328,
      "adjEM": -8.16,
      "adjOff": 106.4,
      "adjOffRank": 137,
      "adjTempo": 89.7,
      "adjTempoRank": 1,
      "conference": "BSth",
      "rank": 237,
      "record": "14-19"
    },
    "Valparaiso": {
      "adjDef": 99.7,
      "adjDefRank": 84,
      "adjEM": 1.32,
      "adjOff": 101.1,
      "adjOffRank": 219,
      "adjTempo": 64.4,
      "adjTempoRank": 224,
      "conference": "MCon",
      "rank": 148,
      "record": "16-15"
    },
    "Vanderbilt": {
      "adjDef": 99.4,
      "adjDefRank": 79,
      "adjEM": 16.23,
      "adjOff": 115.7,
      "adjOffRank": 24,
      "adjTempo": 66.6,
      "adjTempoRank": 126,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 43,
      "record": "22-12",
      "seed": 6
    },
    "Vermont": {
      "adjDef": 101.3,
      "adjDefRank": 112,
      "adjEM": 4.16,
      "adjOff": 105.5,
      "adjOffRank": 146,
      "adjTempo": 66.2,
      "adjTempoRank": 146,
      "conference": "AE",
      "rank": 123,
      "record": "25-8"
    },
    "Villanova": {
      "adjDef": 93.3,
      "adjDefRank": 19,
      "adjEM": 20.11,
      "adjOff": 113.4,
      "adjOffRank": 42,
      "adjTempo": 65.8,
      "adjTempoRank": 168,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 21,
      "record": "22-11",
      "seed": 9
    },
    "Virginia": {
      "adjDef": 98.7,
      "adjDefRank": 71,
      "adjEM": 15.43,
      "adjOff": 114.1,
      "adjOffRank": 35,
      "adjTempo": 67.9,
      "adjTempoRank": 83,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 48,
      "record": "21-11",
      "seed": 4
    },
    "Virginia Tech": {
      "adjDef": 95,
      "adjDefRank": 31,
      "adjEM": 17.38,
      "adjOff": 112.4,
      "adjOffRank": 54,
      "adjTempo": 63.7,
      "adjTempoRank": 252,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 37,
      "record": "22-12",
      "seed": 5
    },
    "Wagner": {
      "adjDef": 111.4,
      "adjDefRank": 295,
      "adjEM": -13.16,
      "adjOff": 98.2,
      "adjOffRank": 263,
      "adjTempo": 68.2,
      "adjTempoRank": 76,
      "conference": "NEC",
      "rank": 288,
      "record": "11-19"
    },
    "Wake Forest": {
      "adjDef": 101.1,
      "adjDefRank": 108,
      "adjEM": 6.5,
      "adjOff": 107.6,
      "adjOffRank": 109,
      "adjTempo": 70.7,
      "adjTempoRank": 27,
      "conference": "ACC",
      "rank": 102,
      "record": "15-16"
    },
    "Washington": {
      "adjDef": 101,
      "adjDefRank": 104,
      "adjEM": 12.17,
      "adjOff": 113.2,
      "adjOffRank": 43,
      "adjTempo": 69.2,
      "adjTempoRank": 51,
      "conference": "P10",
      "rank": 68,
      "record": "19-13"
    },
    "Washington St.": {
      "adjDef": 92.8,
      "adjDefRank": 16,
      "adjEM": 18.87,
      "adjOff": 111.7,
      "adjOffRank": 64,
      "adjTempo": 60,
      "adjTempoRank": 322,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 27,
      "record": "26-8",
      "seed": 3
    },
    "Weber St.": {
      "adjDef": 104.8,
      "adjDefRank": 168,
      "adjEM": -2.71,
      "adjOff": 102.1,
      "adjOffRank": 203,
      "adjTempo": 65.2,
      "adjTempoRank": 191,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 184,
      "record": "20-12",
      "seed": 15
    },
    "West Virginia": {
      "adjDef": 98.5,
      "adjDefRank": 64,
      "adjEM": 18.06,
      "adjOff": 116.5,
      "adjOffRank": 19,
      "adjTempo": 63.2,
      "adjTempoRank": 270,
      "conference": "BE",
      "rank": 33,
      "record": "27-9"
    },
    "Western Carolina": {
      "adjDef": 109.9,
      "adjDefRank": 268,
      "adjEM": -8.33,
      "adjOff": 101.6,
      "adjOffRank": 208,
      "adjTempo": 67.5,
      "adjTempoRank": 97,
      "conference": "SC",
      "rank": 238,
      "record": "11-20"
    },
    "Western Illinois": {
      "adjDef": 109.9,
      "adjDefRank": 270,
      "adjEM": -17.19,
      "adjOff": 92.7,
      "adjOffRank": 314,
      "adjTempo": 63.9,
      "adjTempoRank": 241,
      "conference": "MCon",
      "rank": 313,
      "record": "7-23"
    },
    "Western Kentucky": {
      "adjDef": 107.1,
      "adjDefRank": 216,
      "adjEM": 5.21,
      "adjOff": 112.3,
      "adjOffRank": 56,
      "adjTempo": 68.2,
      "adjTempoRank": 75,
      "conference": "SB",
      "rank": 116,
      "record": "22-11"
    },
    "Western Michigan": {
      "adjDef": 103.5,
      "adjDefRank": 148,
      "adjEM": 4.75,
      "adjOff": 108.2,
      "adjOffRank": 104,
      "adjTempo": 67.6,
      "adjTempoRank": 89,
      "conference": "MAC",
      "rank": 118,
      "record": "16-16"
    },
    "Wichita St.": {
      "adjDef": 98.7,
      "adjDefRank": 73,
      "adjEM": 12.42,
      "adjOff": 111.1,
      "adjOffRank": 70,
      "adjTempo": 63.4,
      "adjTempoRank": 263,
      "conference": "MVC",
      "rank": 65,
      "record": "17-14"
    },
    "William & Mary": {
      "adjDef": 106.6,
      "adjDefRank": 204,
      "adjEM": -3.83,
      "adjOff": 102.8,
      "adjOffRank": 188,
      "adjTempo": 63.1,
      "adjTempoRank": 272,
      "conference": "CAA",
      "rank": 196,
      "record": "15-15"
    },
    "Winston Salem St.": {
      "adjDef": 111.7,
      "adjDefRank": 301,
      "adjEM": -21.59,
      "adjOff": 90.1,
      "adjOffRank": 323,
      "adjTempo": 68,
      "adjTempoRank": 80,
      "conference": "ind",
      "rank": 329,
      "record": "5-24"
    },
    "Winthrop": {
      "adjDef": 98,
      "adjDefRank": 60,
      "adjEM": 14.81,
      "adjOff": 112.9,
      "adjOffRank": 48,
      "adjTempo": 61.9,
      "adjTempoRank": 301,
      "conference": "BSth",
      "finish": "Round of 32",
      "rank": 51,
      "record": "29-5",
      "seed": 11
    },
    "Wisconsin": {
      "adjDef": 90.1,
      "adjDefRank": 8,
      "adjEM": 25.45,
      "adjOff": 115.6,
      "adjOffRank": 25,
      "adjTempo": 63.8,
      "adjTempoRank": 243,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 8,
      "record": "30-6",
      "seed": 2
    },
    "Wofford": {
      "adjDef": 114.2,
      "adjDefRank": 324,
      "adjEM": -9.41,
      "adjOff": 104.8,
      "adjOffRank": 156,
      "adjTempo": 66,
      "adjTempoRank": 159,
      "conference": "SC",
      "rank": 249,
      "record": "10-20"
    },
    "Wright St.": {
      "adjDef": 97.8,
      "adjDefRank": 59,
      "adjEM": 6.66,
      "adjOff": 104.5,
      "adjOffRank": 165,
      "adjTempo": 63,
      "adjTempoRank": 281,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 100,
      "record": "23-10",
      "seed": 14
    },
    "Wyoming": {
      "adjDef": 105.6,
      "adjDefRank": 184,
      "adjEM": 3.34,
      "adjOff": 109,
      "adjOffRank": 97,
      "adjTempo": 68.9,
      "adjTempoRank": 60,
      "conference": "MWC",
      "rank": 130,
      "record": "17-15"
    },
    "Xavier": {
      "adjDef": 99.2,
      "adjDefRank": 78,
      "adjEM": 18.13,
      "adjOff": 117.3,
      "adjOffRank": 12,
      "adjTempo": 66.2,
      "adjTempoRank": 151,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 32,
      "record": "25-9",
      "seed": 9
    },
    "Yale": {
      "adjDef": 104.7,
      "adjDefRank": 163,
      "adjEM": -2.09,
      "adjOff": 102.6,
      "adjOffRank": 193,
      "adjTempo": 66.3,
      "adjTempoRank": 140,
      "conference": "Ivy",
      "rank": 175,
      "record": "14-13"
    },
    "Youngstown St.": {
      "adjDef": 105.4,
      "adjDefRank": 180,
      "adjEM": -2.57,
      "adjOff": 102.8,
      "adjOffRank": 189,
      "adjTempo": 65.6,
      "adjTempoRank": 175,
      "conference": "Horz",
      "rank": 181,
      "record": "14-17"
    }
  },
  "2008": {
    "Air Force": {
      "adjDef": 99.7,
      "adjDefRank": 90,
      "adjEM": 1.18,
      "adjOff": 100.9,
      "adjOffRank": 210,
      "adjTempo": 56.3,
      "adjTempoRank": 338,
      "conference": "MWC",
      "rank": 156,
      "record": "16-14"
    },
    "Akron": {
      "adjDef": 100.4,
      "adjDefRank": 101,
      "adjEM": 10.48,
      "adjOff": 110.9,
      "adjOffRank": 56,
      "adjTempo": 62.8,
      "adjTempoRank": 269,
      "conference": "MAC",
      "rank": 76,
      "record": "24-11"
    },
    "Alabama": {
      "adjDef": 101.7,
      "adjDefRank": 130,
      "adjEM": 9.2,
      "adjOff": 110.9,
      "adjOffRank": 55,
      "adjTempo": 66.7,
      "adjTempoRank": 114,
      "conference": "SEC",
      "rank": 86,
      "record": "17-16"
    },
    "Alabama A&M": {
      "adjDef": 106.2,
      "adjDefRank": 215,
      "adjEM": -16.72,
      "adjOff": 89.4,
      "adjOffRank": 331,
      "adjTempo": 68.2,
      "adjTempoRank": 72,
      "conference": "SWAC",
      "rank": 318,
      "record": "14-15"
    },
    "Alabama St.": {
      "adjDef": 106.5,
      "adjDefRank": 222,
      "adjEM": -4.55,
      "adjOff": 102,
      "adjOffRank": 193,
      "adjTempo": 64.3,
      "adjTempoRank": 200,
      "conference": "SWAC",
      "rank": 206,
      "record": "20-11"
    },
    "Albany": {
      "adjDef": 104.9,
      "adjDefRank": 196,
      "adjEM": -1.82,
      "adjOff": 103.1,
      "adjOffRank": 181,
      "adjTempo": 63.4,
      "adjTempoRank": 245,
      "conference": "AE",
      "rank": 176,
      "record": "15-15"
    },
    "Alcorn St.": {
      "adjDef": 118.3,
      "adjDefRank": 341,
      "adjEM": -24.83,
      "adjOff": 93.5,
      "adjOffRank": 311,
      "adjTempo": 67.8,
      "adjTempoRank": 84,
      "conference": "SWAC",
      "rank": 339,
      "record": "7-24"
    },
    "American": {
      "adjDef": 106.3,
      "adjDefRank": 218,
      "adjEM": 1.67,
      "adjOff": 107.9,
      "adjOffRank": 102,
      "adjTempo": 60.4,
      "adjTempoRank": 319,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 150,
      "record": "21-12",
      "seed": 15
    },
    "Appalachian St.": {
      "adjDef": 103.4,
      "adjDefRank": 167,
      "adjEM": 2.41,
      "adjOff": 105.8,
      "adjOffRank": 137,
      "adjTempo": 65.3,
      "adjTempoRank": 159,
      "conference": "SC",
      "rank": 141,
      "record": "18-13"
    },
    "Arizona": {
      "adjDef": 97.3,
      "adjDefRank": 63,
      "adjEM": 19.14,
      "adjOff": 116.4,
      "adjOffRank": 12,
      "adjTempo": 63.5,
      "adjTempoRank": 239,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 23,
      "record": "19-15",
      "seed": 10
    },
    "Arizona St.": {
      "adjDef": 96.6,
      "adjDefRank": 53,
      "adjEM": 13.62,
      "adjOff": 110.2,
      "adjOffRank": 66,
      "adjTempo": 61.2,
      "adjTempoRank": 305,
      "conference": "P10",
      "rank": 49,
      "record": "21-13"
    },
    "Arkansas": {
      "adjDef": 96.2,
      "adjDefRank": 48,
      "adjEM": 15.19,
      "adjOff": 111.4,
      "adjOffRank": 53,
      "adjTempo": 66.5,
      "adjTempoRank": 119,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 40,
      "record": "23-12",
      "seed": 9
    },
    "Arkansas Little Rock": {
      "adjDef": 98.3,
      "adjDefRank": 72,
      "adjEM": -3.28,
      "adjOff": 95,
      "adjOffRank": 295,
      "adjTempo": 64.7,
      "adjTempoRank": 186,
      "conference": "SB",
      "rank": 193,
      "record": "20-11"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 107.3,
      "adjDefRank": 244,
      "adjEM": -19.61,
      "adjOff": 87.7,
      "adjOffRank": 335,
      "adjTempo": 62.7,
      "adjTempoRank": 273,
      "conference": "SWAC",
      "rank": 329,
      "record": "13-18"
    },
    "Arkansas St.": {
      "adjDef": 115.2,
      "adjDefRank": 335,
      "adjEM": -10.29,
      "adjOff": 104.9,
      "adjOffRank": 146,
      "adjTempo": 64.1,
      "adjTempoRank": 207,
      "conference": "SB",
      "rank": 270,
      "record": "10-20"
    },
    "Army": {
      "adjDef": 98.8,
      "adjDefRank": 81,
      "adjEM": -9.47,
      "adjOff": 89.3,
      "adjOffRank": 332,
      "adjTempo": 62.9,
      "adjTempoRank": 268,
      "conference": "Pat",
      "rank": 264,
      "record": "14-16"
    },
    "Auburn": {
      "adjDef": 107.8,
      "adjDefRank": 248,
      "adjEM": 1.92,
      "adjOff": 109.8,
      "adjOffRank": 70,
      "adjTempo": 65.1,
      "adjTempoRank": 172,
      "conference": "SEC",
      "rank": 149,
      "record": "14-16"
    },
    "Austin Peay": {
      "adjDef": 104.9,
      "adjDefRank": 195,
      "adjEM": -0.3,
      "adjOff": 104.6,
      "adjOffRank": 159,
      "adjTempo": 65.5,
      "adjTempoRank": 155,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 168,
      "record": "24-11",
      "seed": 15
    },
    "BYU": {
      "adjDef": 91.7,
      "adjDefRank": 12,
      "adjEM": 15.76,
      "adjOff": 107.4,
      "adjOffRank": 112,
      "adjTempo": 67.8,
      "adjTempoRank": 83,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 35,
      "record": "27-8",
      "seed": 8
    },
    "Ball St.": {
      "adjDef": 102.9,
      "adjDefRank": 156,
      "adjEM": -7.21,
      "adjOff": 95.7,
      "adjOffRank": 288,
      "adjTempo": 61.1,
      "adjTempoRank": 308,
      "conference": "MAC",
      "rank": 234,
      "record": "6-24"
    },
    "Baylor": {
      "adjDef": 100.9,
      "adjDefRank": 113,
      "adjEM": 14.82,
      "adjOff": 115.7,
      "adjOffRank": 19,
      "adjTempo": 70.1,
      "adjTempoRank": 24,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 41,
      "record": "21-11",
      "seed": 11
    },
    "Belmont": {
      "adjDef": 106.2,
      "adjDefRank": 216,
      "adjEM": 3.53,
      "adjOff": 109.7,
      "adjOffRank": 71,
      "adjTempo": 68.6,
      "adjTempoRank": 53,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 129,
      "record": "25-9",
      "seed": 15
    },
    "Bethune Cookman": {
      "adjDef": 110.5,
      "adjDefRank": 303,
      "adjEM": -18.4,
      "adjOff": 92.1,
      "adjOffRank": 322,
      "adjTempo": 57.8,
      "adjTempoRank": 336,
      "conference": "MEAC",
      "rank": 324,
      "record": "11-21"
    },
    "Binghamton": {
      "adjDef": 105.9,
      "adjDefRank": 211,
      "adjEM": -5.89,
      "adjOff": 100,
      "adjOffRank": 224,
      "adjTempo": 62.3,
      "adjTempoRank": 283,
      "conference": "AE",
      "rank": 224,
      "record": "14-16"
    },
    "Boise St.": {
      "adjDef": 104.8,
      "adjDefRank": 191,
      "adjEM": 3.89,
      "adjOff": 108.7,
      "adjOffRank": 84,
      "adjTempo": 70.7,
      "adjTempoRank": 19,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 126,
      "record": "25-9",
      "seed": 14
    },
    "Boston College": {
      "adjDef": 102.6,
      "adjDefRank": 149,
      "adjEM": 6.37,
      "adjOff": 108.9,
      "adjOffRank": 81,
      "adjTempo": 64.2,
      "adjTempoRank": 203,
      "conference": "ACC",
      "rank": 105,
      "record": "14-17"
    },
    "Boston University": {
      "adjDef": 103.3,
      "adjDefRank": 163,
      "adjEM": -6.08,
      "adjOff": 97.2,
      "adjOffRank": 263,
      "adjTempo": 63.1,
      "adjTempoRank": 257,
      "conference": "AE",
      "rank": 226,
      "record": "14-17"
    },
    "Bowling Green": {
      "adjDef": 104.8,
      "adjDefRank": 194,
      "adjEM": -4.3,
      "adjOff": 100.5,
      "adjOffRank": 217,
      "adjTempo": 63.7,
      "adjTempoRank": 229,
      "conference": "MAC",
      "rank": 204,
      "record": "13-17"
    },
    "Bradley": {
      "adjDef": 102.7,
      "adjDefRank": 152,
      "adjEM": 9.05,
      "adjOff": 111.8,
      "adjOffRank": 50,
      "adjTempo": 68.5,
      "adjTempoRank": 60,
      "conference": "MVC",
      "rank": 87,
      "record": "21-17"
    },
    "Brown": {
      "adjDef": 105.8,
      "adjDefRank": 207,
      "adjEM": 3.07,
      "adjOff": 108.9,
      "adjOffRank": 82,
      "adjTempo": 61.1,
      "adjTempoRank": 309,
      "conference": "Ivy",
      "rank": 134,
      "record": "19-10"
    },
    "Bucknell": {
      "adjDef": 103.3,
      "adjDefRank": 166,
      "adjEM": -7.46,
      "adjOff": 95.8,
      "adjOffRank": 285,
      "adjTempo": 62.2,
      "adjTempoRank": 286,
      "conference": "Pat",
      "rank": 241,
      "record": "12-19"
    },
    "Buffalo": {
      "adjDef": 103.1,
      "adjDefRank": 159,
      "adjEM": -2.46,
      "adjOff": 100.6,
      "adjOffRank": 216,
      "adjTempo": 71.2,
      "adjTempoRank": 15,
      "conference": "MAC",
      "rank": 181,
      "record": "10-20"
    },
    "Butler": {
      "adjDef": 96.2,
      "adjDefRank": 47,
      "adjEM": 19.97,
      "adjOff": 116.2,
      "adjOffRank": 14,
      "adjTempo": 59.5,
      "adjTempoRank": 328,
      "conference": "Horz",
      "finish": "Round of 32",
      "rank": 20,
      "record": "30-4",
      "seed": 7
    },
    "Cal Poly": {
      "adjDef": 105,
      "adjDefRank": 198,
      "adjEM": -9.83,
      "adjOff": 95.1,
      "adjOffRank": 294,
      "adjTempo": 65.2,
      "adjTempoRank": 165,
      "conference": "BW",
      "rank": 267,
      "record": "12-18"
    },
    "Cal St. Bakersfield": {
      "adjDef": 114,
      "adjDefRank": 327,
      "adjEM": -17.31,
      "adjOff": 96.7,
      "adjOffRank": 270,
      "adjTempo": 65.2,
      "adjTempoRank": 164,
      "conference": "ind",
      "rank": 320,
      "record": "8-21"
    },
    "Cal St. Fullerton": {
      "adjDef": 103.9,
      "adjDefRank": 176,
      "adjEM": 8.47,
      "adjOff": 112.3,
      "adjOffRank": 45,
      "adjTempo": 71.6,
      "adjTempoRank": 12,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 88,
      "record": "24-9",
      "seed": 14
    },
    "Cal St. Northridge": {
      "adjDef": 98.7,
      "adjDefRank": 78,
      "adjEM": 3.43,
      "adjOff": 102.2,
      "adjOffRank": 189,
      "adjTempo": 72.2,
      "adjTempoRank": 10,
      "conference": "BW",
      "rank": 131,
      "record": "20-10"
    },
    "California": {
      "adjDef": 104.3,
      "adjDefRank": 184,
      "adjEM": 11.51,
      "adjOff": 115.8,
      "adjOffRank": 18,
      "adjTempo": 67.8,
      "adjTempoRank": 82,
      "conference": "P10",
      "rank": 67,
      "record": "17-16"
    },
    "Campbell": {
      "adjDef": 108.8,
      "adjDefRank": 269,
      "adjEM": -12.69,
      "adjOff": 96.1,
      "adjOffRank": 280,
      "adjTempo": 68.5,
      "adjTempoRank": 61,
      "conference": "ASun",
      "rank": 288,
      "record": "10-20"
    },
    "Canisius": {
      "adjDef": 110.1,
      "adjDefRank": 294,
      "adjEM": -15.08,
      "adjOff": 95,
      "adjOffRank": 297,
      "adjTempo": 64,
      "adjTempoRank": 214,
      "conference": "MAAC",
      "rank": 305,
      "record": "6-25"
    },
    "Centenary": {
      "adjDef": 110.5,
      "adjDefRank": 302,
      "adjEM": -10.57,
      "adjOff": 99.9,
      "adjOffRank": 229,
      "adjTempo": 67.4,
      "adjTempoRank": 94,
      "conference": "Sum",
      "rank": 273,
      "record": "10-21"
    },
    "Central Arkansas": {
      "adjDef": 105.8,
      "adjDefRank": 210,
      "adjEM": -11.66,
      "adjOff": 94.2,
      "adjOffRank": 306,
      "adjTempo": 65.1,
      "adjTempoRank": 175,
      "conference": "Slnd",
      "rank": 279,
      "record": "14-16"
    },
    "Central Connecticut": {
      "adjDef": 108.3,
      "adjDefRank": 257,
      "adjEM": -3.96,
      "adjOff": 104.3,
      "adjOffRank": 163,
      "adjTempo": 62.5,
      "adjTempoRank": 278,
      "conference": "NEC",
      "rank": 201,
      "record": "14-16"
    },
    "Central Michigan": {
      "adjDef": 105.2,
      "adjDefRank": 201,
      "adjEM": -2.06,
      "adjOff": 103.1,
      "adjOffRank": 180,
      "adjTempo": 67.9,
      "adjTempoRank": 79,
      "conference": "MAC",
      "rank": 178,
      "record": "14-17"
    },
    "Charleston Southern": {
      "adjDef": 115.6,
      "adjDefRank": 337,
      "adjEM": -16.55,
      "adjOff": 99.1,
      "adjOffRank": 237,
      "adjTempo": 68.3,
      "adjTempoRank": 65,
      "conference": "BSth",
      "rank": 316,
      "record": "10-20"
    },
    "Charlotte": {
      "adjDef": 95.1,
      "adjDefRank": 36,
      "adjEM": 9.53,
      "adjOff": 104.6,
      "adjOffRank": 160,
      "adjTempo": 66.8,
      "adjTempoRank": 111,
      "conference": "A10",
      "rank": 84,
      "record": "20-14"
    },
    "Chattanooga": {
      "adjDef": 101.5,
      "adjDefRank": 127,
      "adjEM": 0.89,
      "adjOff": 102.4,
      "adjOffRank": 184,
      "adjTempo": 72,
      "adjTempoRank": 11,
      "conference": "SC",
      "rank": 158,
      "record": "18-13"
    },
    "Chicago St.": {
      "adjDef": 111.6,
      "adjDefRank": 315,
      "adjEM": -9.36,
      "adjOff": 102.3,
      "adjOffRank": 185,
      "adjTempo": 70.9,
      "adjTempoRank": 18,
      "conference": "ind",
      "rank": 263,
      "record": "11-17"
    },
    "Cincinnati": {
      "adjDef": 98.2,
      "adjDefRank": 70,
      "adjEM": 6.58,
      "adjOff": 104.8,
      "adjOffRank": 152,
      "adjTempo": 63.5,
      "adjTempoRank": 241,
      "conference": "BE",
      "rank": 102,
      "record": "13-19"
    },
    "Clemson": {
      "adjDef": 91.9,
      "adjDefRank": 15,
      "adjEM": 22.03,
      "adjOff": 114,
      "adjOffRank": 35,
      "adjTempo": 68,
      "adjTempoRank": 78,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 16,
      "record": "24-10",
      "seed": 5
    },
    "Cleveland St.": {
      "adjDef": 96.7,
      "adjDefRank": 55,
      "adjEM": 6.58,
      "adjOff": 103.3,
      "adjOffRank": 177,
      "adjTempo": 64.7,
      "adjTempoRank": 182,
      "conference": "Horz",
      "rank": 103,
      "record": "21-13"
    },
    "Coastal Carolina": {
      "adjDef": 111.5,
      "adjDefRank": 309,
      "adjEM": -12.35,
      "adjOff": 99.1,
      "adjOffRank": 235,
      "adjTempo": 63.7,
      "adjTempoRank": 232,
      "conference": "BSth",
      "rank": 286,
      "record": "13-15"
    },
    "Colgate": {
      "adjDef": 101.3,
      "adjDefRank": 124,
      "adjEM": -3.2,
      "adjOff": 98.1,
      "adjOffRank": 249,
      "adjTempo": 63.9,
      "adjTempoRank": 219,
      "conference": "Pat",
      "rank": 192,
      "record": "18-14"
    },
    "College of Charleston": {
      "adjDef": 106.9,
      "adjDefRank": 232,
      "adjEM": -0.21,
      "adjOff": 106.7,
      "adjOffRank": 124,
      "adjTempo": 65.8,
      "adjTempoRank": 143,
      "conference": "SC",
      "rank": 166,
      "record": "16-17"
    },
    "Colorado": {
      "adjDef": 100.4,
      "adjDefRank": 100,
      "adjEM": 4.29,
      "adjOff": 104.7,
      "adjOffRank": 155,
      "adjTempo": 60.9,
      "adjTempoRank": 313,
      "conference": "B12",
      "rank": 125,
      "record": "12-20"
    },
    "Colorado St.": {
      "adjDef": 111.6,
      "adjDefRank": 314,
      "adjEM": -9.76,
      "adjOff": 101.8,
      "adjOffRank": 195,
      "adjTempo": 63.6,
      "adjTempoRank": 235,
      "conference": "MWC",
      "rank": 265,
      "record": "7-25"
    },
    "Columbia": {
      "adjDef": 106.5,
      "adjDefRank": 221,
      "adjEM": -9.2,
      "adjOff": 97.3,
      "adjOffRank": 262,
      "adjTempo": 61.9,
      "adjTempoRank": 291,
      "conference": "Ivy",
      "rank": 262,
      "record": "14-15"
    },
    "Connecticut": {
      "adjDef": 96.3,
      "adjDefRank": 49,
      "adjEM": 18.9,
      "adjOff": 115.2,
      "adjOffRank": 22,
      "adjTempo": 67.1,
      "adjTempoRank": 101,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 27,
      "record": "24-9",
      "seed": 4
    },
    "Coppin St.": {
      "adjDef": 106.8,
      "adjDefRank": 230,
      "adjEM": -14.41,
      "adjOff": 92.4,
      "adjOffRank": 321,
      "adjTempo": 62.9,
      "adjTempoRank": 261,
      "conference": "MEAC",
      "finish": "Play-in",
      "rank": 298,
      "record": "16-21",
      "seed": 16
    },
    "Cornell": {
      "adjDef": 105.7,
      "adjDefRank": 206,
      "adjEM": 4.41,
      "adjOff": 110.1,
      "adjOffRank": 69,
      "adjTempo": 67.9,
      "adjTempoRank": 80,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 123,
      "record": "22-6",
      "seed": 14
    },
    "Creighton": {
      "adjDef": 97.9,
      "adjDefRank": 68,
      "adjEM": 10.8,
      "adjOff": 108.7,
      "adjOffRank": 85,
      "adjTempo": 65.5,
      "adjTempoRank": 156,
      "conference": "MVC",
      "rank": 74,
      "record": "22-11"
    },
    "Dartmouth": {
      "adjDef": 108.3,
      "adjDefRank": 258,
      "adjEM": -15.91,
      "adjOff": 92.4,
      "adjOffRank": 320,
      "adjTempo": 64.6,
      "adjTempoRank": 192,
      "conference": "Ivy",
      "rank": 313,
      "record": "10-18"
    },
    "Davidson": {
      "adjDef": 93.4,
      "adjDefRank": 24,
      "adjEM": 23.61,
      "adjOff": 117,
      "adjOffRank": 9,
      "adjTempo": 66.2,
      "adjTempoRank": 133,
      "conference": "SC",
      "finish": "Elite Eight",
      "rank": 11,
      "record": "29-7",
      "seed": 10
    },
    "Dayton": {
      "adjDef": 99.7,
      "adjDefRank": 91,
      "adjEM": 12.08,
      "adjOff": 111.8,
      "adjOffRank": 51,
      "adjTempo": 61.6,
      "adjTempoRank": 296,
      "conference": "A10",
      "rank": 60,
      "record": "23-11"
    },
    "DePaul": {
      "adjDef": 106.6,
      "adjDefRank": 224,
      "adjEM": 5.28,
      "adjOff": 111.8,
      "adjOffRank": 49,
      "adjTempo": 65.7,
      "adjTempoRank": 147,
      "conference": "BE",
      "rank": 114,
      "record": "11-19"
    },
    "Delaware": {
      "adjDef": 102.3,
      "adjDefRank": 142,
      "adjEM": -4.57,
      "adjOff": 97.7,
      "adjOffRank": 256,
      "adjTempo": 64.9,
      "adjTempoRank": 177,
      "conference": "CAA",
      "rank": 207,
      "record": "14-17"
    },
    "Delaware St.": {
      "adjDef": 112.5,
      "adjDefRank": 324,
      "adjEM": -11.16,
      "adjOff": 101.3,
      "adjOffRank": 201,
      "adjTempo": 55.5,
      "adjTempoRank": 339,
      "conference": "MEAC",
      "rank": 275,
      "record": "14-16"
    },
    "Denver": {
      "adjDef": 105.5,
      "adjDefRank": 205,
      "adjEM": -7.16,
      "adjOff": 98.4,
      "adjOffRank": 247,
      "adjTempo": 55.2,
      "adjTempoRank": 340,
      "conference": "SB",
      "rank": 233,
      "record": "11-19"
    },
    "Detroit": {
      "adjDef": 109,
      "adjDefRank": 273,
      "adjEM": -8.32,
      "adjOff": 100.7,
      "adjOffRank": 212,
      "adjTempo": 60.3,
      "adjTempoRank": 321,
      "conference": "Horz",
      "rank": 251,
      "record": "7-23"
    },
    "Drake": {
      "adjDef": 98.5,
      "adjDefRank": 76,
      "adjEM": 21.46,
      "adjOff": 120,
      "adjOffRank": 4,
      "adjTempo": 62.9,
      "adjTempoRank": 262,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 17,
      "record": "28-5",
      "seed": 5
    },
    "Drexel": {
      "adjDef": 99.4,
      "adjDefRank": 84,
      "adjEM": -8.68,
      "adjOff": 90.8,
      "adjOffRank": 326,
      "adjTempo": 64.4,
      "adjTempoRank": 199,
      "conference": "CAA",
      "rank": 257,
      "record": "12-20"
    },
    "Duke": {
      "adjDef": 89.9,
      "adjDefRank": 7,
      "adjEM": 26.4,
      "adjOff": 116.3,
      "adjOffRank": 13,
      "adjTempo": 71.5,
      "adjTempoRank": 13,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 6,
      "record": "28-6",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 99,
      "adjDefRank": 83,
      "adjEM": 9.97,
      "adjOff": 108.9,
      "adjOffRank": 80,
      "adjTempo": 73.4,
      "adjTempoRank": 5,
      "conference": "A10",
      "rank": 79,
      "record": "17-13"
    },
    "East Carolina": {
      "adjDef": 109.9,
      "adjDefRank": 287,
      "adjEM": -6.08,
      "adjOff": 103.8,
      "adjOffRank": 172,
      "adjTempo": 65.2,
      "adjTempoRank": 161,
      "conference": "CUSA",
      "rank": 225,
      "record": "11-19"
    },
    "East Tennessee St.": {
      "adjDef": 105.3,
      "adjDefRank": 202,
      "adjEM": -1.38,
      "adjOff": 103.9,
      "adjOffRank": 170,
      "adjTempo": 71.3,
      "adjTempoRank": 14,
      "conference": "ASun",
      "rank": 174,
      "record": "19-13"
    },
    "Eastern Illinois": {
      "adjDef": 114.1,
      "adjDefRank": 329,
      "adjEM": -18.41,
      "adjOff": 95.7,
      "adjOffRank": 287,
      "adjTempo": 61.6,
      "adjTempoRank": 299,
      "conference": "OVC",
      "rank": 325,
      "record": "7-22"
    },
    "Eastern Kentucky": {
      "adjDef": 109.1,
      "adjDefRank": 274,
      "adjEM": -11.48,
      "adjOff": 97.6,
      "adjOffRank": 258,
      "adjTempo": 60,
      "adjTempoRank": 323,
      "conference": "OVC",
      "rank": 278,
      "record": "14-16"
    },
    "Eastern Michigan": {
      "adjDef": 107.2,
      "adjDefRank": 242,
      "adjEM": -2.48,
      "adjOff": 104.7,
      "adjOffRank": 154,
      "adjTempo": 62.4,
      "adjTempoRank": 281,
      "conference": "MAC",
      "rank": 182,
      "record": "14-17"
    },
    "Eastern Washington": {
      "adjDef": 104.4,
      "adjDefRank": 188,
      "adjEM": -8.11,
      "adjOff": 96.3,
      "adjOffRank": 275,
      "adjTempo": 64,
      "adjTempoRank": 215,
      "conference": "BSky",
      "rank": 248,
      "record": "11-19"
    },
    "Elon": {
      "adjDef": 100.6,
      "adjDefRank": 105,
      "adjEM": -2.63,
      "adjOff": 98,
      "adjOffRank": 250,
      "adjTempo": 61.8,
      "adjTempoRank": 292,
      "conference": "SC",
      "rank": 184,
      "record": "14-19"
    },
    "Evansville": {
      "adjDef": 104.3,
      "adjDefRank": 185,
      "adjEM": -5.47,
      "adjOff": 98.8,
      "adjOffRank": 240,
      "adjTempo": 62.8,
      "adjTempoRank": 271,
      "conference": "MVC",
      "rank": 217,
      "record": "9-21"
    },
    "FIU": {
      "adjDef": 102,
      "adjDefRank": 135,
      "adjEM": -8.94,
      "adjOff": 93,
      "adjOffRank": 314,
      "adjTempo": 66.3,
      "adjTempoRank": 127,
      "conference": "SB",
      "rank": 260,
      "record": "9-20"
    },
    "Fairfield": {
      "adjDef": 110.1,
      "adjDefRank": 296,
      "adjEM": -4.97,
      "adjOff": 105.2,
      "adjOffRank": 143,
      "adjTempo": 60.5,
      "adjTempoRank": 317,
      "conference": "MAAC",
      "rank": 210,
      "record": "14-16"
    },
    "Fairleigh Dickinson": {
      "adjDef": 114.7,
      "adjDefRank": 333,
      "adjEM": -14.81,
      "adjOff": 99.9,
      "adjOffRank": 227,
      "adjTempo": 67.6,
      "adjTempoRank": 88,
      "conference": "NEC",
      "rank": 303,
      "record": "8-20"
    },
    "Florida": {
      "adjDef": 100.5,
      "adjDefRank": 104,
      "adjEM": 15.37,
      "adjOff": 115.9,
      "adjOffRank": 16,
      "adjTempo": 65,
      "adjTempoRank": 176,
      "conference": "SEC",
      "rank": 38,
      "record": "24-12"
    },
    "Florida A&M": {
      "adjDef": 109,
      "adjDefRank": 272,
      "adjEM": -14.71,
      "adjOff": 94.3,
      "adjOffRank": 305,
      "adjTempo": 65.6,
      "adjTempoRank": 153,
      "conference": "MEAC",
      "rank": 301,
      "record": "15-17"
    },
    "Florida Atlantic": {
      "adjDef": 108.6,
      "adjDefRank": 264,
      "adjEM": -5.88,
      "adjOff": 102.7,
      "adjOffRank": 182,
      "adjTempo": 68.6,
      "adjTempoRank": 51,
      "conference": "SB",
      "rank": 223,
      "record": "15-18"
    },
    "Florida Gulf Coast": {
      "adjDef": 109.8,
      "adjDefRank": 286,
      "adjEM": -15.29,
      "adjOff": 94.5,
      "adjOffRank": 302,
      "adjTempo": 67.5,
      "adjTempoRank": 90,
      "conference": "ASun",
      "rank": 307,
      "record": "10-21"
    },
    "Florida St.": {
      "adjDef": 96.4,
      "adjDefRank": 50,
      "adjEM": 12.26,
      "adjOff": 108.7,
      "adjOffRank": 83,
      "adjTempo": 64.8,
      "adjTempoRank": 181,
      "conference": "ACC",
      "rank": 59,
      "record": "19-15"
    },
    "Fordham": {
      "adjDef": 102.5,
      "adjDefRank": 147,
      "adjEM": 0.69,
      "adjOff": 103.2,
      "adjOffRank": 178,
      "adjTempo": 61.8,
      "adjTempoRank": 294,
      "conference": "A10",
      "rank": 159,
      "record": "12-17"
    },
    "Fresno St.": {
      "adjDef": 101.8,
      "adjDefRank": 132,
      "adjEM": -3.02,
      "adjOff": 98.8,
      "adjOffRank": 242,
      "adjTempo": 66.6,
      "adjTempoRank": 115,
      "conference": "WAC",
      "rank": 190,
      "record": "13-19"
    },
    "Furman": {
      "adjDef": 108.6,
      "adjDefRank": 265,
      "adjEM": -13.31,
      "adjOff": 95.3,
      "adjOffRank": 291,
      "adjTempo": 64,
      "adjTempoRank": 218,
      "conference": "SC",
      "rank": 291,
      "record": "7-23"
    },
    "Gardner Webb": {
      "adjDef": 106.6,
      "adjDefRank": 225,
      "adjEM": -2.97,
      "adjOff": 103.6,
      "adjOffRank": 174,
      "adjTempo": 70.1,
      "adjTempoRank": 23,
      "conference": "ASun",
      "rank": 188,
      "record": "16-16"
    },
    "George Mason": {
      "adjDef": 101,
      "adjDefRank": 117,
      "adjEM": 9.68,
      "adjOff": 110.7,
      "adjOffRank": 58,
      "adjTempo": 61.6,
      "adjTempoRank": 300,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 80,
      "record": "23-11",
      "seed": 12
    },
    "George Washington": {
      "adjDef": 102,
      "adjDefRank": 138,
      "adjEM": -1.1,
      "adjOff": 100.9,
      "adjOffRank": 207,
      "adjTempo": 63.4,
      "adjTempoRank": 246,
      "conference": "A10",
      "rank": 171,
      "record": "9-17"
    },
    "Georgetown": {
      "adjDef": 89.7,
      "adjDefRank": 6,
      "adjEM": 25.56,
      "adjOff": 115.2,
      "adjOffRank": 21,
      "adjTempo": 60.5,
      "adjTempoRank": 316,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 7,
      "record": "28-6",
      "seed": 2
    },
    "Georgia": {
      "adjDef": 96.2,
      "adjDefRank": 45,
      "adjEM": 8.08,
      "adjOff": 104.3,
      "adjOffRank": 166,
      "adjTempo": 64.7,
      "adjTempoRank": 183,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 91,
      "record": "17-17",
      "seed": 14
    },
    "Georgia Southern": {
      "adjDef": 102.2,
      "adjDefRank": 140,
      "adjEM": 3.82,
      "adjOff": 106,
      "adjOffRank": 133,
      "adjTempo": 69,
      "adjTempoRank": 43,
      "conference": "SC",
      "rank": 128,
      "record": "20-12"
    },
    "Georgia St.": {
      "adjDef": 106.8,
      "adjDefRank": 231,
      "adjEM": -8.47,
      "adjOff": 98.3,
      "adjOffRank": 248,
      "adjTempo": 62.2,
      "adjTempoRank": 287,
      "conference": "CAA",
      "rank": 252,
      "record": "9-21"
    },
    "Georgia Tech": {
      "adjDef": 101.4,
      "adjDefRank": 125,
      "adjEM": 13.1,
      "adjOff": 114.5,
      "adjOffRank": 31,
      "adjTempo": 68.6,
      "adjTempoRank": 54,
      "conference": "ACC",
      "rank": 53,
      "record": "15-17"
    },
    "Gonzaga": {
      "adjDef": 95.5,
      "adjDefRank": 41,
      "adjEM": 19.05,
      "adjOff": 114.5,
      "adjOffRank": 30,
      "adjTempo": 65.9,
      "adjTempoRank": 140,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 25,
      "record": "25-8",
      "seed": 7
    },
    "Grambling St.": {
      "adjDef": 115.2,
      "adjDefRank": 334,
      "adjEM": -24.32,
      "adjOff": 90.9,
      "adjOffRank": 325,
      "adjTempo": 68.4,
      "adjTempoRank": 64,
      "conference": "SWAC",
      "rank": 338,
      "record": "7-19"
    },
    "Green Bay": {
      "adjDef": 105.1,
      "adjDefRank": 199,
      "adjEM": 2.02,
      "adjOff": 107.1,
      "adjOffRank": 118,
      "adjTempo": 65.4,
      "adjTempoRank": 157,
      "conference": "Horz",
      "rank": 145,
      "record": "15-15"
    },
    "Hampton": {
      "adjDef": 99.6,
      "adjDefRank": 87,
      "adjEM": -5.15,
      "adjOff": 94.4,
      "adjOffRank": 303,
      "adjTempo": 66.5,
      "adjTempoRank": 123,
      "conference": "MEAC",
      "rank": 212,
      "record": "18-12"
    },
    "Hartford": {
      "adjDef": 110.1,
      "adjDefRank": 295,
      "adjEM": -7.73,
      "adjOff": 102.4,
      "adjOffRank": 183,
      "adjTempo": 66.5,
      "adjTempoRank": 120,
      "conference": "AE",
      "rank": 245,
      "record": "18-16"
    },
    "Harvard": {
      "adjDef": 111.5,
      "adjDefRank": 310,
      "adjEM": -11.73,
      "adjOff": 99.7,
      "adjOffRank": 230,
      "adjTempo": 67,
      "adjTempoRank": 105,
      "conference": "Ivy",
      "rank": 280,
      "record": "8-22"
    },
    "Hawaii": {
      "adjDef": 109.2,
      "adjDefRank": 275,
      "adjEM": -7.02,
      "adjOff": 102.2,
      "adjOffRank": 188,
      "adjTempo": 66.8,
      "adjTempoRank": 108,
      "conference": "WAC",
      "rank": 231,
      "record": "11-19"
    },
    "High Point": {
      "adjDef": 106,
      "adjDefRank": 212,
      "adjEM": -2.76,
      "adjOff": 103.3,
      "adjOffRank": 176,
      "adjTempo": 61.9,
      "adjTempoRank": 290,
      "conference": "BSth",
      "rank": 187,
      "record": "17-14"
    },
    "Hofstra": {
      "adjDef": 103,
      "adjDefRank": 158,
      "adjEM": -5.17,
      "adjOff": 97.9,
      "adjOffRank": 255,
      "adjTempo": 67.2,
      "adjTempoRank": 98,
      "conference": "CAA",
      "rank": 214,
      "record": "12-18"
    },
    "Holy Cross": {
      "adjDef": 102.3,
      "adjDefRank": 143,
      "adjEM": -1.03,
      "adjOff": 101.3,
      "adjOffRank": 204,
      "adjTempo": 59.6,
      "adjTempoRank": 327,
      "conference": "Pat",
      "rank": 170,
      "record": "15-14"
    },
    "Houston": {
      "adjDef": 102.9,
      "adjDefRank": 155,
      "adjEM": 11.28,
      "adjOff": 114.2,
      "adjOffRank": 33,
      "adjTempo": 69.1,
      "adjTempoRank": 42,
      "conference": "CUSA",
      "rank": 71,
      "record": "24-10"
    },
    "Howard": {
      "adjDef": 109.2,
      "adjDefRank": 277,
      "adjEM": -23.29,
      "adjOff": 85.9,
      "adjOffRank": 339,
      "adjTempo": 62.6,
      "adjTempoRank": 276,
      "conference": "MEAC",
      "rank": 336,
      "record": "6-26"
    },
    "IPFW": {
      "adjDef": 104.4,
      "adjDefRank": 186,
      "adjEM": -3.85,
      "adjOff": 100.5,
      "adjOffRank": 218,
      "adjTempo": 65.6,
      "adjTempoRank": 151,
      "conference": "Sum",
      "rank": 198,
      "record": "13-18"
    },
    "IUPUI": {
      "adjDef": 104.8,
      "adjDefRank": 192,
      "adjEM": 11.68,
      "adjOff": 116.5,
      "adjOffRank": 11,
      "adjTempo": 60.9,
      "adjTempoRank": 312,
      "conference": "Sum",
      "rank": 66,
      "record": "26-7"
    },
    "Idaho": {
      "adjDef": 109.7,
      "adjDefRank": 285,
      "adjEM": -13.4,
      "adjOff": 96.3,
      "adjOffRank": 276,
      "adjTempo": 64.2,
      "adjTempoRank": 205,
      "conference": "WAC",
      "rank": 292,
      "record": "8-21"
    },
    "Idaho St.": {
      "adjDef": 109.9,
      "adjDefRank": 288,
      "adjEM": -14.07,
      "adjOff": 95.8,
      "adjOffRank": 286,
      "adjTempo": 60,
      "adjTempoRank": 324,
      "conference": "BSky",
      "rank": 295,
      "record": "12-19"
    },
    "Illinois": {
      "adjDef": 94,
      "adjDefRank": 29,
      "adjEM": 13.47,
      "adjOff": 107.5,
      "adjOffRank": 111,
      "adjTempo": 61,
      "adjTempoRank": 310,
      "conference": "B10",
      "rank": 51,
      "record": "16-19"
    },
    "Illinois Chicago": {
      "adjDef": 99.5,
      "adjDefRank": 85,
      "adjEM": 5.34,
      "adjOff": 104.8,
      "adjOffRank": 149,
      "adjTempo": 65.7,
      "adjTempoRank": 148,
      "conference": "Horz",
      "rank": 113,
      "record": "18-15"
    },
    "Illinois St.": {
      "adjDef": 93.9,
      "adjDefRank": 27,
      "adjEM": 12.37,
      "adjOff": 106.2,
      "adjOffRank": 129,
      "adjTempo": 62.5,
      "adjTempoRank": 279,
      "conference": "MVC",
      "rank": 58,
      "record": "25-10"
    },
    "Indiana": {
      "adjDef": 96.1,
      "adjDefRank": 44,
      "adjEM": 18.43,
      "adjOff": 114.6,
      "adjOffRank": 29,
      "adjTempo": 66.2,
      "adjTempoRank": 134,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 29,
      "record": "25-8",
      "seed": 8
    },
    "Indiana St.": {
      "adjDef": 102.7,
      "adjDefRank": 150,
      "adjEM": 3.2,
      "adjOff": 105.9,
      "adjOffRank": 136,
      "adjTempo": 64.3,
      "adjTempoRank": 201,
      "conference": "MVC",
      "rank": 132,
      "record": "15-16"
    },
    "Iona": {
      "adjDef": 100.9,
      "adjDefRank": 112,
      "adjEM": -5.7,
      "adjOff": 95.2,
      "adjOffRank": 293,
      "adjTempo": 66.3,
      "adjTempoRank": 128,
      "conference": "MAAC",
      "rank": 219,
      "record": "12-20"
    },
    "Iowa": {
      "adjDef": 96.8,
      "adjDefRank": 56,
      "adjEM": 2.01,
      "adjOff": 98.8,
      "adjOffRank": 241,
      "adjTempo": 58.8,
      "adjTempoRank": 331,
      "conference": "B10",
      "rank": 146,
      "record": "13-19"
    },
    "Iowa St.": {
      "adjDef": 97.4,
      "adjDefRank": 66,
      "adjEM": 4.38,
      "adjOff": 101.7,
      "adjOffRank": 196,
      "adjTempo": 64.1,
      "adjTempoRank": 212,
      "conference": "B12",
      "rank": 124,
      "record": "14-18"
    },
    "Jackson St.": {
      "adjDef": 109.2,
      "adjDefRank": 276,
      "adjEM": -16.17,
      "adjOff": 93,
      "adjOffRank": 316,
      "adjTempo": 70,
      "adjTempoRank": 25,
      "conference": "SWAC",
      "rank": 315,
      "record": "14-20"
    },
    "Jacksonville": {
      "adjDef": 107.1,
      "adjDefRank": 238,
      "adjEM": -5.02,
      "adjOff": 102.1,
      "adjOffRank": 192,
      "adjTempo": 66.1,
      "adjTempoRank": 136,
      "conference": "ASun",
      "rank": 211,
      "record": "18-13"
    },
    "Jacksonville St.": {
      "adjDef": 115.8,
      "adjDefRank": 338,
      "adjEM": -18.47,
      "adjOff": 97.4,
      "adjOffRank": 261,
      "adjTempo": 67.3,
      "adjTempoRank": 96,
      "conference": "OVC",
      "rank": 326,
      "record": "7-22"
    },
    "James Madison": {
      "adjDef": 109.3,
      "adjDefRank": 280,
      "adjEM": -5.33,
      "adjOff": 103.9,
      "adjOffRank": 171,
      "adjTempo": 68.6,
      "adjTempoRank": 55,
      "conference": "CAA",
      "rank": 216,
      "record": "13-17"
    },
    "Kansas": {
      "adjDef": 85.7,
      "adjDefRank": 1,
      "adjEM": 35.21,
      "adjOff": 120.9,
      "adjOffRank": 2,
      "adjTempo": 66.8,
      "adjTempoRank": 109,
      "conference": "B12",
      "finish": "Champion",
      "rank": 1,
      "record": "37-3",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.3,
      "adjDefRank": 40,
      "adjEM": 18.9,
      "adjOff": 114.2,
      "adjOffRank": 34,
      "adjTempo": 69.7,
      "adjTempoRank": 33,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 26,
      "record": "21-12",
      "seed": 11
    },
    "Kennesaw St.": {
      "adjDef": 110,
      "adjDefRank": 291,
      "adjEM": -15.6,
      "adjOff": 94.4,
      "adjOffRank": 304,
      "adjTempo": 68.2,
      "adjTempoRank": 71,
      "conference": "ASun",
      "rank": 311,
      "record": "10-20"
    },
    "Kent St.": {
      "adjDef": 94.8,
      "adjDefRank": 34,
      "adjEM": 13.04,
      "adjOff": 107.8,
      "adjOffRank": 105,
      "adjTempo": 65.1,
      "adjTempoRank": 171,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 55,
      "record": "28-7",
      "seed": 9
    },
    "Kentucky": {
      "adjDef": 97.8,
      "adjDefRank": 67,
      "adjEM": 10.01,
      "adjOff": 107.8,
      "adjOffRank": 104,
      "adjTempo": 62.4,
      "adjTempoRank": 282,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 78,
      "record": "18-13",
      "seed": 11
    },
    "LIU Brooklyn": {
      "adjDef": 105.8,
      "adjDefRank": 209,
      "adjEM": -5.17,
      "adjOff": 100.6,
      "adjOffRank": 215,
      "adjTempo": 69.4,
      "adjTempoRank": 37,
      "conference": "NEC",
      "rank": 213,
      "record": "15-15"
    },
    "LSU": {
      "adjDef": 99.7,
      "adjDefRank": 89,
      "adjEM": 5.2,
      "adjOff": 104.9,
      "adjOffRank": 148,
      "adjTempo": 65.6,
      "adjTempoRank": 152,
      "conference": "SEC",
      "rank": 115,
      "record": "13-18"
    },
    "La Salle": {
      "adjDef": 105.5,
      "adjDefRank": 204,
      "adjEM": 1.93,
      "adjOff": 107.4,
      "adjOffRank": 113,
      "adjTempo": 68.9,
      "adjTempoRank": 45,
      "conference": "A10",
      "rank": 147,
      "record": "15-17"
    },
    "Lafayette": {
      "adjDef": 109.2,
      "adjDefRank": 278,
      "adjEM": -2.64,
      "adjOff": 106.5,
      "adjOffRank": 126,
      "adjTempo": 68.6,
      "adjTempoRank": 56,
      "conference": "Pat",
      "rank": 185,
      "record": "15-15"
    },
    "Lamar": {
      "adjDef": 107.6,
      "adjDefRank": 246,
      "adjEM": 0.56,
      "adjOff": 108.1,
      "adjOffRank": 96,
      "adjTempo": 69.9,
      "adjTempoRank": 30,
      "conference": "Slnd",
      "rank": 160,
      "record": "19-11"
    },
    "Lehigh": {
      "adjDef": 104.8,
      "adjDefRank": 193,
      "adjEM": -8.26,
      "adjOff": 96.6,
      "adjOffRank": 273,
      "adjTempo": 64.2,
      "adjTempoRank": 206,
      "conference": "Pat",
      "rank": 250,
      "record": "14-15"
    },
    "Liberty": {
      "adjDef": 108.2,
      "adjDefRank": 255,
      "adjEM": -4.55,
      "adjOff": 103.6,
      "adjOffRank": 173,
      "adjTempo": 62.3,
      "adjTempoRank": 284,
      "conference": "BSth",
      "rank": 205,
      "record": "16-16"
    },
    "Lipscomb": {
      "adjDef": 104.9,
      "adjDefRank": 197,
      "adjEM": -3.59,
      "adjOff": 101.3,
      "adjOffRank": 203,
      "adjTempo": 66.2,
      "adjTempoRank": 132,
      "conference": "ASun",
      "rank": 197,
      "record": "15-16"
    },
    "Long Beach St.": {
      "adjDef": 111.2,
      "adjDefRank": 306,
      "adjEM": -14.88,
      "adjOff": 96.3,
      "adjOffRank": 277,
      "adjTempo": 62.9,
      "adjTempoRank": 267,
      "conference": "BW",
      "rank": 304,
      "record": "6-25"
    },
    "Longwood": {
      "adjDef": 116.5,
      "adjDefRank": 340,
      "adjEM": -18.61,
      "adjOff": 97.9,
      "adjOffRank": 253,
      "adjTempo": 66.5,
      "adjTempoRank": 122,
      "conference": "ind",
      "rank": 327,
      "record": "9-22"
    },
    "Louisiana Lafayette": {
      "adjDef": 101,
      "adjDefRank": 116,
      "adjEM": -3.42,
      "adjOff": 97.6,
      "adjOffRank": 260,
      "adjTempo": 63.5,
      "adjTempoRank": 238,
      "conference": "SB",
      "rank": 195,
      "record": "15-15"
    },
    "Louisiana Monroe": {
      "adjDef": 112.1,
      "adjDefRank": 319,
      "adjEM": -10.02,
      "adjOff": 102.1,
      "adjOffRank": 191,
      "adjTempo": 65.1,
      "adjTempoRank": 170,
      "conference": "SB",
      "rank": 269,
      "record": "10-21"
    },
    "Louisiana Tech": {
      "adjDef": 112.4,
      "adjDefRank": 322,
      "adjEM": -19.1,
      "adjOff": 93.3,
      "adjOffRank": 312,
      "adjTempo": 63.2,
      "adjTempoRank": 254,
      "conference": "WAC",
      "rank": 328,
      "record": "6-24"
    },
    "Louisville": {
      "adjDef": 87.7,
      "adjDefRank": 4,
      "adjEM": 25.41,
      "adjOff": 113.1,
      "adjOffRank": 40,
      "adjTempo": 65.3,
      "adjTempoRank": 160,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 8,
      "record": "27-9",
      "seed": 3
    },
    "Loyola Chicago": {
      "adjDef": 104,
      "adjDefRank": 178,
      "adjEM": -3.88,
      "adjOff": 100.1,
      "adjOffRank": 220,
      "adjTempo": 60.3,
      "adjTempoRank": 322,
      "conference": "Horz",
      "rank": 199,
      "record": "12-19"
    },
    "Loyola MD": {
      "adjDef": 108,
      "adjDefRank": 253,
      "adjEM": 0.08,
      "adjOff": 108.1,
      "adjOffRank": 99,
      "adjTempo": 64.7,
      "adjTempoRank": 185,
      "conference": "MAAC",
      "rank": 163,
      "record": "19-14"
    },
    "Loyola Marymount": {
      "adjDef": 113.6,
      "adjDefRank": 326,
      "adjEM": -22.33,
      "adjOff": 91.2,
      "adjOffRank": 324,
      "adjTempo": 68.2,
      "adjTempoRank": 70,
      "conference": "WCC",
      "rank": 332,
      "record": "5-26"
    },
    "Maine": {
      "adjDef": 110.4,
      "adjDefRank": 301,
      "adjEM": -17.82,
      "adjOff": 92.6,
      "adjOffRank": 318,
      "adjTempo": 66.3,
      "adjTempoRank": 130,
      "conference": "AE",
      "rank": 322,
      "record": "7-23"
    },
    "Manhattan": {
      "adjDef": 109.3,
      "adjDefRank": 279,
      "adjEM": -7.41,
      "adjOff": 101.8,
      "adjOffRank": 194,
      "adjTempo": 66,
      "adjTempoRank": 139,
      "conference": "MAAC",
      "rank": 238,
      "record": "12-19"
    },
    "Marist": {
      "adjDef": 104.4,
      "adjDefRank": 187,
      "adjEM": 1.92,
      "adjOff": 106.3,
      "adjOffRank": 128,
      "adjTempo": 63.3,
      "adjTempoRank": 250,
      "conference": "MAAC",
      "rank": 148,
      "record": "18-14"
    },
    "Marquette": {
      "adjDef": 93.1,
      "adjDefRank": 21,
      "adjEM": 22.16,
      "adjOff": 115.3,
      "adjOffRank": 20,
      "adjTempo": 66.6,
      "adjTempoRank": 118,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 14,
      "record": "25-10",
      "seed": 6
    },
    "Marshall": {
      "adjDef": 103.6,
      "adjDefRank": 171,
      "adjEM": 2.56,
      "adjOff": 106.2,
      "adjOffRank": 130,
      "adjTempo": 62.9,
      "adjTempoRank": 265,
      "conference": "CUSA",
      "rank": 139,
      "record": "16-14"
    },
    "Maryland": {
      "adjDef": 95.1,
      "adjDefRank": 38,
      "adjEM": 11.99,
      "adjOff": 107.1,
      "adjOffRank": 116,
      "adjTempo": 69.9,
      "adjTempoRank": 26,
      "conference": "ACC",
      "rank": 63,
      "record": "19-15"
    },
    "Maryland Eastern Shore": {
      "adjDef": 114.4,
      "adjDefRank": 332,
      "adjEM": -28.29,
      "adjOff": 86.1,
      "adjOffRank": 338,
      "adjTempo": 67.1,
      "adjTempoRank": 102,
      "conference": "MEAC",
      "rank": 340,
      "record": "4-28"
    },
    "Massachusetts": {
      "adjDef": 97.3,
      "adjDefRank": 65,
      "adjEM": 13.1,
      "adjOff": 110.5,
      "adjOffRank": 63,
      "adjTempo": 74.6,
      "adjTempoRank": 3,
      "conference": "A10",
      "rank": 52,
      "record": "25-11"
    },
    "McNeese St.": {
      "adjDef": 103.8,
      "adjDefRank": 175,
      "adjEM": -7.26,
      "adjOff": 96.6,
      "adjOffRank": 272,
      "adjTempo": 65.2,
      "adjTempoRank": 163,
      "conference": "Slnd",
      "rank": 236,
      "record": "13-16"
    },
    "Memphis": {
      "adjDef": 86.5,
      "adjDefRank": 2,
      "adjEM": 31.51,
      "adjOff": 118,
      "adjOffRank": 6,
      "adjTempo": 68.1,
      "adjTempoRank": 76,
      "conference": "CUSA",
      "finish": "Runner-up",
      "rank": 2,
      "record": "38-2",
      "seed": 1
    },
    "Mercer": {
      "adjDef": 109.5,
      "adjDefRank": 283,
      "adjEM": -11.85,
      "adjOff": 97.6,
      "adjOffRank": 257,
      "adjTempo": 70.9,
      "adjTempoRank": 17,
      "conference": "ASun",
      "rank": 281,
      "record": "11-19"
    },
    "Miami FL": {
      "adjDef": 97.1,
      "adjDefRank": 61,
      "adjEM": 15.38,
      "adjOff": 112.5,
      "adjOffRank": 44,
      "adjTempo": 65.8,
      "adjTempoRank": 145,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 37,
      "record": "23-11",
      "seed": 7
    },
    "Miami OH": {
      "adjDef": 98.7,
      "adjDefRank": 79,
      "adjEM": 9.63,
      "adjOff": 108.4,
      "adjOffRank": 90,
      "adjTempo": 58,
      "adjTempoRank": 335,
      "conference": "MAC",
      "rank": 81,
      "record": "17-16"
    },
    "Michigan": {
      "adjDef": 101.7,
      "adjDefRank": 129,
      "adjEM": 2.7,
      "adjOff": 104.4,
      "adjOffRank": 162,
      "adjTempo": 64.1,
      "adjTempoRank": 210,
      "conference": "B10",
      "rank": 137,
      "record": "10-22"
    },
    "Michigan St.": {
      "adjDef": 94.7,
      "adjDefRank": 33,
      "adjEM": 21.15,
      "adjOff": 115.8,
      "adjOffRank": 17,
      "adjTempo": 63.3,
      "adjTempoRank": 253,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 19,
      "record": "27-9",
      "seed": 5
    },
    "Middle Tennessee": {
      "adjDef": 105.1,
      "adjDefRank": 200,
      "adjEM": -0.16,
      "adjOff": 104.9,
      "adjOffRank": 145,
      "adjTempo": 62.1,
      "adjTempoRank": 288,
      "conference": "SB",
      "rank": 165,
      "record": "17-15"
    },
    "Milwaukee": {
      "adjDef": 100.9,
      "adjDefRank": 114,
      "adjEM": -0.26,
      "adjOff": 100.7,
      "adjOffRank": 214,
      "adjTempo": 67,
      "adjTempoRank": 104,
      "conference": "Horz",
      "rank": 167,
      "record": "14-16"
    },
    "Minnesota": {
      "adjDef": 97.1,
      "adjDefRank": 60,
      "adjEM": 11.34,
      "adjOff": 108.4,
      "adjOffRank": 89,
      "adjTempo": 65.1,
      "adjTempoRank": 169,
      "conference": "B10",
      "rank": 70,
      "record": "20-14"
    },
    "Mississippi": {
      "adjDef": 100.7,
      "adjDefRank": 108,
      "adjEM": 13.49,
      "adjOff": 114.2,
      "adjOffRank": 32,
      "adjTempo": 69.3,
      "adjTempoRank": 40,
      "conference": "SEC",
      "rank": 50,
      "record": "24-11"
    },
    "Mississippi St.": {
      "adjDef": 91.5,
      "adjDefRank": 11,
      "adjEM": 16.77,
      "adjOff": 108.2,
      "adjOffRank": 92,
      "adjTempo": 66.6,
      "adjTempoRank": 117,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 33,
      "record": "23-11",
      "seed": 8
    },
    "Mississippi Valley St.": {
      "adjDef": 107.1,
      "adjDefRank": 236,
      "adjEM": -15.21,
      "adjOff": 91.9,
      "adjOffRank": 323,
      "adjTempo": 63,
      "adjTempoRank": 259,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 306,
      "record": "17-16",
      "seed": 16
    },
    "Missouri": {
      "adjDef": 100.2,
      "adjDefRank": 98,
      "adjEM": 13.04,
      "adjOff": 113.3,
      "adjOffRank": 38,
      "adjTempo": 69.3,
      "adjTempoRank": 39,
      "conference": "B12",
      "rank": 54,
      "record": "16-16"
    },
    "Missouri St.": {
      "adjDef": 99.8,
      "adjDefRank": 92,
      "adjEM": 8.39,
      "adjOff": 108.2,
      "adjOffRank": 95,
      "adjTempo": 65.1,
      "adjTempoRank": 173,
      "conference": "MVC",
      "rank": 89,
      "record": "17-16"
    },
    "Monmouth": {
      "adjDef": 108.5,
      "adjDefRank": 260,
      "adjEM": -15.45,
      "adjOff": 93,
      "adjOffRank": 315,
      "adjTempo": 60.3,
      "adjTempoRank": 320,
      "conference": "NEC",
      "rank": 309,
      "record": "7-24"
    },
    "Montana": {
      "adjDef": 110.4,
      "adjDefRank": 300,
      "adjEM": -3.52,
      "adjOff": 106.9,
      "adjOffRank": 121,
      "adjTempo": 62.2,
      "adjTempoRank": 285,
      "conference": "BSky",
      "rank": 196,
      "record": "14-16"
    },
    "Montana St.": {
      "adjDef": 111.8,
      "adjDefRank": 317,
      "adjEM": -6.95,
      "adjOff": 104.8,
      "adjOffRank": 150,
      "adjTempo": 66.4,
      "adjTempoRank": 125,
      "conference": "BSky",
      "rank": 230,
      "record": "15-15"
    },
    "Morehead St.": {
      "adjDef": 107.9,
      "adjDefRank": 250,
      "adjEM": -12.64,
      "adjOff": 95.3,
      "adjOffRank": 292,
      "adjTempo": 62.6,
      "adjTempoRank": 274,
      "conference": "OVC",
      "rank": 287,
      "record": "15-15"
    },
    "Morgan St.": {
      "adjDef": 101,
      "adjDefRank": 118,
      "adjEM": -1.01,
      "adjOff": 100,
      "adjOffRank": 222,
      "adjTempo": 64.7,
      "adjTempoRank": 188,
      "conference": "MEAC",
      "rank": 169,
      "record": "22-11"
    },
    "Mount St. Mary's": {
      "adjDef": 100.6,
      "adjDefRank": 106,
      "adjEM": -1.32,
      "adjOff": 99.3,
      "adjOffRank": 233,
      "adjTempo": 66.1,
      "adjTempoRank": 138,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 173,
      "record": "19-15",
      "seed": 16
    },
    "Murray St.": {
      "adjDef": 108.3,
      "adjDefRank": 256,
      "adjEM": -3.97,
      "adjOff": 104.3,
      "adjOffRank": 164,
      "adjTempo": 63.8,
      "adjTempoRank": 227,
      "conference": "OVC",
      "rank": 202,
      "record": "18-13"
    },
    "N.C. State": {
      "adjDef": 101.9,
      "adjDefRank": 134,
      "adjEM": 5.65,
      "adjOff": 107.6,
      "adjOffRank": 110,
      "adjTempo": 62.7,
      "adjTempoRank": 272,
      "conference": "ACC",
      "rank": 110,
      "record": "15-16"
    },
    "NJIT": {
      "adjDef": 110,
      "adjDefRank": 293,
      "adjEM": -32.79,
      "adjOff": 77.2,
      "adjOffRank": 341,
      "adjTempo": 69.7,
      "adjTempoRank": 31,
      "conference": "ind",
      "rank": 341,
      "record": "0-29"
    },
    "Navy": {
      "adjDef": 105.4,
      "adjDefRank": 203,
      "adjEM": -3.92,
      "adjOff": 101.5,
      "adjOffRank": 199,
      "adjTempo": 73.3,
      "adjTempoRank": 6,
      "conference": "Pat",
      "rank": 200,
      "record": "16-14"
    },
    "Nebraska": {
      "adjDef": 92.7,
      "adjDefRank": 19,
      "adjEM": 15.31,
      "adjOff": 108,
      "adjOffRank": 101,
      "adjTempo": 61.6,
      "adjTempoRank": 297,
      "conference": "B12",
      "rank": 39,
      "record": "20-13"
    },
    "Nevada": {
      "adjDef": 101.9,
      "adjDefRank": 133,
      "adjEM": 6.59,
      "adjOff": 108.5,
      "adjOffRank": 88,
      "adjTempo": 67.2,
      "adjTempoRank": 97,
      "conference": "WAC",
      "rank": 101,
      "record": "21-12"
    },
    "New Hampshire": {
      "adjDef": 108.5,
      "adjDefRank": 263,
      "adjEM": -11.35,
      "adjOff": 97.2,
      "adjOffRank": 264,
      "adjTempo": 65.1,
      "adjTempoRank": 168,
      "conference": "AE",
      "rank": 276,
      "record": "9-20"
    },
    "New Mexico": {
      "adjDef": 94,
      "adjDefRank": 30,
      "adjEM": 17.08,
      "adjOff": 111.1,
      "adjOffRank": 54,
      "adjTempo": 64.4,
      "adjTempoRank": 196,
      "conference": "MWC",
      "rank": 32,
      "record": "24-9"
    },
    "New Mexico St.": {
      "adjDef": 101.3,
      "adjDefRank": 122,
      "adjEM": 6.85,
      "adjOff": 108.1,
      "adjOffRank": 97,
      "adjTempo": 69.7,
      "adjTempoRank": 32,
      "conference": "WAC",
      "rank": 100,
      "record": "21-14"
    },
    "New Orleans": {
      "adjDef": 106.7,
      "adjDefRank": 228,
      "adjEM": -2.07,
      "adjOff": 104.7,
      "adjOffRank": 158,
      "adjTempo": 68.2,
      "adjTempoRank": 73,
      "conference": "SB",
      "rank": 179,
      "record": "19-13"
    },
    "Niagara": {
      "adjDef": 103.7,
      "adjDefRank": 172,
      "adjEM": 2.92,
      "adjOff": 106.7,
      "adjOffRank": 125,
      "adjTempo": 69.9,
      "adjTempoRank": 29,
      "conference": "MAAC",
      "rank": 135,
      "record": "19-10"
    },
    "Nicholls St.": {
      "adjDef": 102.4,
      "adjDefRank": 146,
      "adjEM": -7.52,
      "adjOff": 94.9,
      "adjOffRank": 299,
      "adjTempo": 64.8,
      "adjTempoRank": 180,
      "conference": "Slnd",
      "rank": 242,
      "record": "10-21"
    },
    "Norfolk St.": {
      "adjDef": 110,
      "adjDefRank": 292,
      "adjEM": -13.04,
      "adjOff": 97,
      "adjOffRank": 267,
      "adjTempo": 68.7,
      "adjTempoRank": 49,
      "conference": "MEAC",
      "rank": 290,
      "record": "16-15"
    },
    "North Carolina": {
      "adjDef": 91.9,
      "adjDefRank": 14,
      "adjEM": 30.22,
      "adjOff": 122.1,
      "adjOffRank": 1,
      "adjTempo": 72.5,
      "adjTempoRank": 9,
      "conference": "ACC",
      "finish": "Final Four",
      "rank": 3,
      "record": "36-3",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 111.5,
      "adjDefRank": 311,
      "adjEM": -15.38,
      "adjOff": 96.1,
      "adjOffRank": 281,
      "adjTempo": 67.3,
      "adjTempoRank": 95,
      "conference": "MEAC",
      "rank": 308,
      "record": "15-16"
    },
    "North Carolina Central": {
      "adjDef": 108.5,
      "adjDefRank": 262,
      "adjEM": -23.17,
      "adjOff": 85.3,
      "adjOffRank": 340,
      "adjTempo": 68.3,
      "adjTempoRank": 66,
      "conference": "ind",
      "rank": 334,
      "record": "4-26"
    },
    "North Dakota St.": {
      "adjDef": 104.1,
      "adjDefRank": 181,
      "adjEM": 2.88,
      "adjOff": 107,
      "adjOffRank": 120,
      "adjTempo": 67,
      "adjTempoRank": 106,
      "conference": "Sum",
      "rank": 136,
      "record": "16-13"
    },
    "North Florida": {
      "adjDef": 109.9,
      "adjDefRank": 289,
      "adjEM": -23.54,
      "adjOff": 86.3,
      "adjOffRank": 337,
      "adjTempo": 64.4,
      "adjTempoRank": 197,
      "conference": "ASun",
      "rank": 337,
      "record": "3-26"
    },
    "North Texas": {
      "adjDef": 102.8,
      "adjDefRank": 153,
      "adjEM": 3.11,
      "adjOff": 105.9,
      "adjOffRank": 135,
      "adjTempo": 68,
      "adjTempoRank": 77,
      "conference": "SB",
      "rank": 133,
      "record": "20-11"
    },
    "Northeastern": {
      "adjDef": 101.7,
      "adjDefRank": 131,
      "adjEM": 1.42,
      "adjOff": 103.2,
      "adjOffRank": 179,
      "adjTempo": 61.4,
      "adjTempoRank": 302,
      "conference": "CAA",
      "rank": 153,
      "record": "14-17"
    },
    "Northern Arizona": {
      "adjDef": 104.2,
      "adjDefRank": 183,
      "adjEM": 1.43,
      "adjOff": 105.6,
      "adjOffRank": 139,
      "adjTempo": 66.2,
      "adjTempoRank": 135,
      "conference": "BSky",
      "rank": 152,
      "record": "21-11"
    },
    "Northern Colorado": {
      "adjDef": 107.3,
      "adjDefRank": 243,
      "adjEM": -7.22,
      "adjOff": 100,
      "adjOffRank": 221,
      "adjTempo": 67.7,
      "adjTempoRank": 85,
      "conference": "BSky",
      "rank": 235,
      "record": "13-16"
    },
    "Northern Illinois": {
      "adjDef": 106.7,
      "adjDefRank": 227,
      "adjEM": -9.81,
      "adjOff": 96.9,
      "adjOffRank": 269,
      "adjTempo": 69.4,
      "adjTempoRank": 36,
      "conference": "MAC",
      "rank": 266,
      "record": "6-22"
    },
    "Northern Iowa": {
      "adjDef": 101.3,
      "adjDefRank": 121,
      "adjEM": 4.62,
      "adjOff": 105.9,
      "adjOffRank": 134,
      "adjTempo": 59.2,
      "adjTempoRank": 329,
      "conference": "MVC",
      "rank": 121,
      "record": "18-14"
    },
    "Northwestern": {
      "adjDef": 108.5,
      "adjDefRank": 261,
      "adjEM": -3.2,
      "adjOff": 105.3,
      "adjOffRank": 142,
      "adjTempo": 61,
      "adjTempoRank": 311,
      "conference": "B10",
      "rank": 191,
      "record": "8-22"
    },
    "Northwestern St.": {
      "adjDef": 107.1,
      "adjDefRank": 241,
      "adjEM": -8.6,
      "adjOff": 98.5,
      "adjOffRank": 245,
      "adjTempo": 72.6,
      "adjTempoRank": 8,
      "conference": "Slnd",
      "rank": 253,
      "record": "15-18"
    },
    "Notre Dame": {
      "adjDef": 95.6,
      "adjDefRank": 43,
      "adjEM": 19.07,
      "adjOff": 114.6,
      "adjOffRank": 26,
      "adjTempo": 69.5,
      "adjTempoRank": 35,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 24,
      "record": "25-8",
      "seed": 5
    },
    "Oakland": {
      "adjDef": 109.3,
      "adjDefRank": 281,
      "adjEM": 2.39,
      "adjOff": 111.7,
      "adjOffRank": 52,
      "adjTempo": 67.1,
      "adjTempoRank": 100,
      "conference": "Sum",
      "rank": 142,
      "record": "17-14"
    },
    "Ohio": {
      "adjDef": 102.1,
      "adjDefRank": 139,
      "adjEM": 7.55,
      "adjOff": 109.7,
      "adjOffRank": 73,
      "adjTempo": 63.2,
      "adjTempoRank": 255,
      "conference": "MAC",
      "rank": 95,
      "record": "20-13"
    },
    "Ohio St.": {
      "adjDef": 92.4,
      "adjDefRank": 18,
      "adjEM": 17.79,
      "adjOff": 110.2,
      "adjOffRank": 65,
      "adjTempo": 64,
      "adjTempoRank": 217,
      "conference": "B10",
      "rank": 31,
      "record": "24-13"
    },
    "Oklahoma": {
      "adjDef": 95.1,
      "adjDefRank": 37,
      "adjEM": 14.47,
      "adjOff": 109.5,
      "adjOffRank": 74,
      "adjTempo": 63.1,
      "adjTempoRank": 256,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 44,
      "record": "23-12",
      "seed": 6
    },
    "Oklahoma St.": {
      "adjDef": 98.4,
      "adjDefRank": 73,
      "adjEM": 10.78,
      "adjOff": 109.1,
      "adjOffRank": 77,
      "adjTempo": 64.2,
      "adjTempoRank": 204,
      "conference": "B12",
      "rank": 75,
      "record": "17-16"
    },
    "Old Dominion": {
      "adjDef": 101.6,
      "adjDefRank": 128,
      "adjEM": 4.9,
      "adjOff": 106.5,
      "adjOffRank": 127,
      "adjTempo": 63,
      "adjTempoRank": 258,
      "conference": "CAA",
      "rank": 118,
      "record": "18-16"
    },
    "Oral Roberts": {
      "adjDef": 94.5,
      "adjDefRank": 31,
      "adjEM": 10.27,
      "adjOff": 104.7,
      "adjOffRank": 153,
      "adjTempo": 63.6,
      "adjTempoRank": 236,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 77,
      "record": "24-9",
      "seed": 13
    },
    "Oregon": {
      "adjDef": 103.1,
      "adjDefRank": 160,
      "adjEM": 15.66,
      "adjOff": 118.8,
      "adjOffRank": 5,
      "adjTempo": 65.8,
      "adjTempoRank": 144,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 36,
      "record": "18-14",
      "seed": 9
    },
    "Oregon St.": {
      "adjDef": 103.3,
      "adjDefRank": 164,
      "adjEM": -7.91,
      "adjOff": 95.3,
      "adjOffRank": 290,
      "adjTempo": 65.1,
      "adjTempoRank": 174,
      "conference": "P10",
      "rank": 246,
      "record": "6-25"
    },
    "Pacific": {
      "adjDef": 104.7,
      "adjDefRank": 190,
      "adjEM": 2.36,
      "adjOff": 107.1,
      "adjOffRank": 117,
      "adjTempo": 63.7,
      "adjTempoRank": 231,
      "conference": "BW",
      "rank": 143,
      "record": "21-10"
    },
    "Penn": {
      "adjDef": 108.8,
      "adjDefRank": 268,
      "adjEM": -12.72,
      "adjOff": 96.1,
      "adjOffRank": 282,
      "adjTempo": 68.4,
      "adjTempoRank": 62,
      "conference": "Ivy",
      "rank": 289,
      "record": "13-18"
    },
    "Penn St.": {
      "adjDef": 104.2,
      "adjDefRank": 182,
      "adjEM": 3.48,
      "adjOff": 107.7,
      "adjOffRank": 107,
      "adjTempo": 63.5,
      "adjTempoRank": 242,
      "conference": "B10",
      "rank": 130,
      "record": "15-16"
    },
    "Pepperdine": {
      "adjDef": 106.3,
      "adjDefRank": 219,
      "adjEM": -6.39,
      "adjOff": 99.9,
      "adjOffRank": 228,
      "adjTempo": 72.7,
      "adjTempoRank": 7,
      "conference": "WCC",
      "rank": 227,
      "record": "11-21"
    },
    "Pittsburgh": {
      "adjDef": 96.6,
      "adjDefRank": 54,
      "adjEM": 19.91,
      "adjOff": 116.5,
      "adjOffRank": 10,
      "adjTempo": 63.9,
      "adjTempoRank": 221,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 22,
      "record": "27-10",
      "seed": 4
    },
    "Portland": {
      "adjDef": 108.8,
      "adjDefRank": 267,
      "adjEM": -9.88,
      "adjOff": 98.9,
      "adjOffRank": 239,
      "adjTempo": 62.6,
      "adjTempoRank": 275,
      "conference": "WCC",
      "rank": 268,
      "record": "9-22"
    },
    "Portland St.": {
      "adjDef": 103.2,
      "adjDefRank": 161,
      "adjEM": 5.4,
      "adjOff": 108.6,
      "adjOffRank": 87,
      "adjTempo": 66.3,
      "adjTempoRank": 126,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 112,
      "record": "23-10",
      "seed": 16
    },
    "Prairie View A&M": {
      "adjDef": 111.6,
      "adjDefRank": 313,
      "adjEM": -21.82,
      "adjOff": 89.8,
      "adjOffRank": 329,
      "adjTempo": 69.4,
      "adjTempoRank": 38,
      "conference": "SWAC",
      "rank": 331,
      "record": "8-22"
    },
    "Presbyterian": {
      "adjDef": 111.2,
      "adjDefRank": 307,
      "adjEM": -13.62,
      "adjOff": 97.6,
      "adjOffRank": 259,
      "adjTempo": 58.5,
      "adjTempoRank": 332,
      "conference": "ind",
      "rank": 293,
      "record": "5-25"
    },
    "Princeton": {
      "adjDef": 108.9,
      "adjDefRank": 271,
      "adjEM": -15.68,
      "adjOff": 93.2,
      "adjOffRank": 313,
      "adjTempo": 58.8,
      "adjTempoRank": 330,
      "conference": "Ivy",
      "rank": 312,
      "record": "6-23"
    },
    "Providence": {
      "adjDef": 100.8,
      "adjDefRank": 109,
      "adjEM": 9.4,
      "adjOff": 110.1,
      "adjOffRank": 68,
      "adjTempo": 66.3,
      "adjTempoRank": 131,
      "conference": "BE",
      "rank": 85,
      "record": "15-16"
    },
    "Purdue": {
      "adjDef": 92.2,
      "adjDefRank": 16,
      "adjEM": 18.3,
      "adjOff": 110.5,
      "adjOffRank": 60,
      "adjTempo": 66.9,
      "adjTempoRank": 107,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 30,
      "record": "25-9",
      "seed": 6
    },
    "Quinnipiac": {
      "adjDef": 112.2,
      "adjDefRank": 321,
      "adjEM": -7.37,
      "adjOff": 104.8,
      "adjOffRank": 151,
      "adjTempo": 66.6,
      "adjTempoRank": 116,
      "conference": "NEC",
      "rank": 237,
      "record": "15-15"
    },
    "Radford": {
      "adjDef": 110.2,
      "adjDefRank": 298,
      "adjEM": -14.36,
      "adjOff": 95.9,
      "adjOffRank": 283,
      "adjTempo": 68.2,
      "adjTempoRank": 68,
      "conference": "BSth",
      "rank": 297,
      "record": "10-20"
    },
    "Rhode Island": {
      "adjDef": 103.9,
      "adjDefRank": 177,
      "adjEM": 11.2,
      "adjOff": 115.1,
      "adjOffRank": 24,
      "adjTempo": 69.6,
      "adjTempoRank": 34,
      "conference": "A10",
      "rank": 72,
      "record": "21-12"
    },
    "Rice": {
      "adjDef": 101.5,
      "adjDefRank": 126,
      "adjEM": -14.74,
      "adjOff": 86.7,
      "adjOffRank": 336,
      "adjTempo": 65.2,
      "adjTempoRank": 167,
      "conference": "CUSA",
      "rank": 302,
      "record": "3-27"
    },
    "Richmond": {
      "adjDef": 99.9,
      "adjDefRank": 93,
      "adjEM": 1.48,
      "adjOff": 101.4,
      "adjOffRank": 200,
      "adjTempo": 61.5,
      "adjTempoRank": 301,
      "conference": "A10",
      "rank": 151,
      "record": "16-15"
    },
    "Rider": {
      "adjDef": 103.6,
      "adjDefRank": 169,
      "adjEM": 4.62,
      "adjOff": 108.2,
      "adjOffRank": 94,
      "adjTempo": 68.7,
      "adjTempoRank": 48,
      "conference": "MAAC",
      "rank": 122,
      "record": "23-11"
    },
    "Robert Morris": {
      "adjDef": 101.1,
      "adjDefRank": 119,
      "adjEM": 2.27,
      "adjOff": 103.3,
      "adjOffRank": 175,
      "adjTempo": 69,
      "adjTempoRank": 44,
      "conference": "NEC",
      "rank": 144,
      "record": "26-8"
    },
    "Rutgers": {
      "adjDef": 98.8,
      "adjDefRank": 80,
      "adjEM": -2.52,
      "adjOff": 96.2,
      "adjOffRank": 278,
      "adjTempo": 64.6,
      "adjTempoRank": 190,
      "conference": "BE",
      "rank": 183,
      "record": "11-20"
    },
    "SMU": {
      "adjDef": 107,
      "adjDefRank": 234,
      "adjEM": -12.04,
      "adjOff": 95,
      "adjOffRank": 296,
      "adjTempo": 64.5,
      "adjTempoRank": 194,
      "conference": "CUSA",
      "rank": 283,
      "record": "10-20"
    },
    "Sacramento St.": {
      "adjDef": 112.4,
      "adjDefRank": 323,
      "adjEM": -22.44,
      "adjOff": 90,
      "adjOffRank": 328,
      "adjTempo": 68.7,
      "adjTempoRank": 50,
      "conference": "BSky",
      "rank": 333,
      "record": "4-24"
    },
    "Sacred Heart": {
      "adjDef": 106.4,
      "adjDefRank": 220,
      "adjEM": -4.74,
      "adjOff": 101.7,
      "adjOffRank": 197,
      "adjTempo": 67.9,
      "adjTempoRank": 81,
      "conference": "NEC",
      "rank": 209,
      "record": "18-14"
    },
    "Saint Joseph's": {
      "adjDef": 100.8,
      "adjDefRank": 110,
      "adjEM": 14.32,
      "adjOff": 115.1,
      "adjOffRank": 25,
      "adjTempo": 64,
      "adjTempoRank": 216,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 45,
      "record": "21-13",
      "seed": 11
    },
    "Saint Louis": {
      "adjDef": 100,
      "adjDefRank": 95,
      "adjEM": 0.95,
      "adjOff": 100.9,
      "adjOffRank": 206,
      "adjTempo": 58.4,
      "adjTempoRank": 333,
      "conference": "A10",
      "rank": 157,
      "record": "16-15"
    },
    "Saint Mary's": {
      "adjDef": 94.6,
      "adjDefRank": 32,
      "adjEM": 14.66,
      "adjOff": 109.2,
      "adjOffRank": 76,
      "adjTempo": 66.4,
      "adjTempoRank": 124,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 42,
      "record": "25-7",
      "seed": 10
    },
    "Saint Peter's": {
      "adjDef": 106.2,
      "adjDefRank": 217,
      "adjEM": -11.42,
      "adjOff": 94.8,
      "adjOffRank": 300,
      "adjTempo": 67.1,
      "adjTempoRank": 103,
      "conference": "MAAC",
      "rank": 277,
      "record": "6-24"
    },
    "Sam Houston St.": {
      "adjDef": 93.8,
      "adjDefRank": 26,
      "adjEM": 2.7,
      "adjOff": 96.5,
      "adjOffRank": 274,
      "adjTempo": 67.6,
      "adjTempoRank": 87,
      "conference": "Slnd",
      "rank": 138,
      "record": "23-8"
    },
    "Samford": {
      "adjDef": 111.4,
      "adjDefRank": 308,
      "adjEM": -14.41,
      "adjOff": 96.9,
      "adjOffRank": 268,
      "adjTempo": 54.6,
      "adjTempoRank": 341,
      "conference": "OVC",
      "rank": 299,
      "record": "14-16"
    },
    "San Diego": {
      "adjDef": 97,
      "adjDefRank": 59,
      "adjEM": 4.64,
      "adjOff": 101.6,
      "adjOffRank": 198,
      "adjTempo": 63,
      "adjTempoRank": 260,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 120,
      "record": "22-14",
      "seed": 13
    },
    "San Diego St.": {
      "adjDef": 96.9,
      "adjDefRank": 57,
      "adjEM": 7.99,
      "adjOff": 104.9,
      "adjOffRank": 147,
      "adjTempo": 64.1,
      "adjTempoRank": 211,
      "conference": "MWC",
      "rank": 92,
      "record": "20-13"
    },
    "San Francisco": {
      "adjDef": 107.7,
      "adjDefRank": 247,
      "adjEM": -8.63,
      "adjOff": 99.1,
      "adjOffRank": 236,
      "adjTempo": 65.9,
      "adjTempoRank": 141,
      "conference": "WCC",
      "rank": 254,
      "record": "10-21"
    },
    "San Jose St.": {
      "adjDef": 106.7,
      "adjDefRank": 229,
      "adjEM": -8.83,
      "adjOff": 97.9,
      "adjOffRank": 254,
      "adjTempo": 66.7,
      "adjTempoRank": 112,
      "conference": "WAC",
      "rank": 259,
      "record": "13-19"
    },
    "Santa Clara": {
      "adjDef": 99.6,
      "adjDefRank": 86,
      "adjEM": 1.34,
      "adjOff": 100.9,
      "adjOffRank": 208,
      "adjTempo": 59.7,
      "adjTempoRank": 325,
      "conference": "WCC",
      "rank": 155,
      "record": "15-16"
    },
    "Savannah St.": {
      "adjDef": 100.4,
      "adjDefRank": 99,
      "adjEM": -12.29,
      "adjOff": 88.1,
      "adjOffRank": 334,
      "adjTempo": 66.3,
      "adjTempoRank": 129,
      "conference": "ind",
      "rank": 285,
      "record": "13-18"
    },
    "Seton Hall": {
      "adjDef": 104.5,
      "adjDefRank": 189,
      "adjEM": 6,
      "adjOff": 110.5,
      "adjOffRank": 62,
      "adjTempo": 69.2,
      "adjTempoRank": 41,
      "conference": "BE",
      "rank": 107,
      "record": "17-15"
    },
    "Siena": {
      "adjDef": 100.7,
      "adjDefRank": 107,
      "adjEM": 7.99,
      "adjOff": 108.6,
      "adjOffRank": 86,
      "adjTempo": 67.6,
      "adjTempoRank": 86,
      "conference": "MAAC",
      "finish": "Round of 32",
      "rank": 93,
      "record": "23-11",
      "seed": 13
    },
    "South Alabama": {
      "adjDef": 100.8,
      "adjDefRank": 111,
      "adjEM": 11.8,
      "adjOff": 112.6,
      "adjOffRank": 42,
      "adjTempo": 64.8,
      "adjTempoRank": 178,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 65,
      "record": "26-7",
      "seed": 10
    },
    "South Carolina": {
      "adjDef": 103,
      "adjDefRank": 157,
      "adjEM": 6.1,
      "adjOff": 109.1,
      "adjOffRank": 79,
      "adjTempo": 64.7,
      "adjTempoRank": 184,
      "conference": "SEC",
      "rank": 106,
      "record": "14-18"
    },
    "South Carolina St.": {
      "adjDef": 112.1,
      "adjDefRank": 320,
      "adjEM": -16.61,
      "adjOff": 95.5,
      "adjOffRank": 289,
      "adjTempo": 70.3,
      "adjTempoRank": 21,
      "conference": "MEAC",
      "rank": 317,
      "record": "13-20"
    },
    "South Dakota St.": {
      "adjDef": 109.6,
      "adjDefRank": 284,
      "adjEM": -10.66,
      "adjOff": 99,
      "adjOffRank": 238,
      "adjTempo": 64.5,
      "adjTempoRank": 195,
      "conference": "Sum",
      "rank": 274,
      "record": "8-21"
    },
    "South Florida": {
      "adjDef": 102,
      "adjDefRank": 136,
      "adjEM": 5.41,
      "adjOff": 107.4,
      "adjOffRank": 114,
      "adjTempo": 63.4,
      "adjTempoRank": 243,
      "conference": "BE",
      "rank": 111,
      "record": "12-19"
    },
    "Southeast Missouri St.": {
      "adjDef": 111.7,
      "adjDefRank": 316,
      "adjEM": -15.56,
      "adjOff": 96.1,
      "adjOffRank": 279,
      "adjTempo": 74.4,
      "adjTempoRank": 4,
      "conference": "OVC",
      "rank": 310,
      "record": "12-19"
    },
    "Southeastern Louisiana": {
      "adjDef": 100.5,
      "adjDefRank": 103,
      "adjEM": -4.59,
      "adjOff": 95.9,
      "adjOffRank": 284,
      "adjTempo": 64.1,
      "adjTempoRank": 213,
      "conference": "Slnd",
      "rank": 208,
      "record": "17-13"
    },
    "Southern": {
      "adjDef": 106.1,
      "adjDefRank": 213,
      "adjEM": -16.09,
      "adjOff": 90,
      "adjOffRank": 327,
      "adjTempo": 63.3,
      "adjTempoRank": 252,
      "conference": "SWAC",
      "rank": 314,
      "record": "11-19"
    },
    "Southern Illinois": {
      "adjDef": 93.7,
      "adjDefRank": 25,
      "adjEM": 12.04,
      "adjOff": 105.7,
      "adjOffRank": 138,
      "adjTempo": 61.1,
      "adjTempoRank": 307,
      "conference": "MVC",
      "rank": 62,
      "record": "18-15"
    },
    "Southern Miss": {
      "adjDef": 101.3,
      "adjDefRank": 123,
      "adjEM": 6.98,
      "adjOff": 108.3,
      "adjOffRank": 91,
      "adjTempo": 63.3,
      "adjTempoRank": 249,
      "conference": "CUSA",
      "rank": 98,
      "record": "19-14"
    },
    "Southern Utah": {
      "adjDef": 108,
      "adjDefRank": 252,
      "adjEM": -5.82,
      "adjOff": 102.2,
      "adjOffRank": 187,
      "adjTempo": 64.7,
      "adjTempoRank": 187,
      "conference": "Sum",
      "rank": 220,
      "record": "11-19"
    },
    "St. Bonaventure": {
      "adjDef": 110.2,
      "adjDefRank": 299,
      "adjEM": -5.58,
      "adjOff": 104.7,
      "adjOffRank": 156,
      "adjTempo": 64.8,
      "adjTempoRank": 179,
      "conference": "A10",
      "rank": 218,
      "record": "8-22"
    },
    "St. Francis NY": {
      "adjDef": 107,
      "adjDefRank": 233,
      "adjEM": -14.19,
      "adjOff": 92.8,
      "adjOffRank": 317,
      "adjTempo": 67.4,
      "adjTempoRank": 92,
      "conference": "NEC",
      "rank": 296,
      "record": "7-22"
    },
    "St. Francis PA": {
      "adjDef": 114.3,
      "adjDefRank": 331,
      "adjEM": -17.22,
      "adjOff": 97.1,
      "adjOffRank": 266,
      "adjTempo": 60.9,
      "adjTempoRank": 314,
      "conference": "NEC",
      "rank": 319,
      "record": "6-23"
    },
    "St. John's": {
      "adjDef": 96.2,
      "adjDefRank": 46,
      "adjEM": 2.44,
      "adjOff": 98.6,
      "adjOffRank": 243,
      "adjTempo": 63.9,
      "adjTempoRank": 224,
      "conference": "BE",
      "rank": 140,
      "record": "11-19"
    },
    "Stanford": {
      "adjDef": 90.9,
      "adjDefRank": 10,
      "adjEM": 22.75,
      "adjOff": 113.6,
      "adjOffRank": 36,
      "adjTempo": 63.4,
      "adjTempoRank": 248,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 12,
      "record": "28-8",
      "seed": 3
    },
    "Stephen F. Austin": {
      "adjDef": 100.1,
      "adjDefRank": 96,
      "adjEM": 7.53,
      "adjOff": 107.6,
      "adjOffRank": 109,
      "adjTempo": 57.6,
      "adjTempoRank": 337,
      "conference": "Slnd",
      "rank": 96,
      "record": "26-6"
    },
    "Stetson": {
      "adjDef": 104,
      "adjDefRank": 179,
      "adjEM": -10.29,
      "adjOff": 93.7,
      "adjOffRank": 308,
      "adjTempo": 64.5,
      "adjTempoRank": 193,
      "conference": "ASun",
      "rank": 271,
      "record": "16-16"
    },
    "Stony Brook": {
      "adjDef": 107.1,
      "adjDefRank": 235,
      "adjEM": -14.6,
      "adjOff": 92.5,
      "adjOffRank": 319,
      "adjTempo": 62.4,
      "adjTempoRank": 280,
      "conference": "AE",
      "rank": 300,
      "record": "7-23"
    },
    "Syracuse": {
      "adjDef": 98.4,
      "adjDefRank": 75,
      "adjEM": 14.12,
      "adjOff": 112.6,
      "adjOffRank": 43,
      "adjTempo": 69.9,
      "adjTempoRank": 27,
      "conference": "BE",
      "rank": 47,
      "record": "21-14"
    },
    "TCU": {
      "adjDef": 102.5,
      "adjDefRank": 148,
      "adjEM": -1.87,
      "adjOff": 100.7,
      "adjOffRank": 213,
      "adjTempo": 66.7,
      "adjTempoRank": 113,
      "conference": "MWC",
      "rank": 177,
      "record": "14-16"
    },
    "Temple": {
      "adjDef": 100.4,
      "adjDefRank": 102,
      "adjEM": 12.37,
      "adjOff": 112.8,
      "adjOffRank": 41,
      "adjTempo": 63.6,
      "adjTempoRank": 233,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 57,
      "record": "21-13",
      "seed": 12
    },
    "Tennessee": {
      "adjDef": 94,
      "adjDefRank": 28,
      "adjEM": 22.17,
      "adjOff": 116.2,
      "adjOffRank": 15,
      "adjTempo": 70.7,
      "adjTempoRank": 20,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "31-5",
      "seed": 2
    },
    "Tennessee Martin": {
      "adjDef": 114.2,
      "adjDefRank": 330,
      "adjEM": -8.1,
      "adjOff": 106.1,
      "adjOffRank": 132,
      "adjTempo": 68.1,
      "adjTempoRank": 75,
      "conference": "OVC",
      "rank": 247,
      "record": "17-16"
    },
    "Tennessee St.": {
      "adjDef": 110.8,
      "adjDefRank": 305,
      "adjEM": -6.76,
      "adjOff": 104.1,
      "adjOffRank": 168,
      "adjTempo": 68.6,
      "adjTempoRank": 58,
      "conference": "OVC",
      "rank": 229,
      "record": "15-17"
    },
    "Tennessee Tech": {
      "adjDef": 108.1,
      "adjDefRank": 254,
      "adjEM": -8.17,
      "adjOff": 99.9,
      "adjOffRank": 225,
      "adjTempo": 69.9,
      "adjTempoRank": 28,
      "conference": "OVC",
      "rank": 249,
      "record": "13-19"
    },
    "Texas": {
      "adjDef": 95.5,
      "adjDefRank": 42,
      "adjEM": 25.09,
      "adjOff": 120.6,
      "adjOffRank": 3,
      "adjTempo": 63.9,
      "adjTempoRank": 222,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "31-7",
      "seed": 2
    },
    "Texas A&M": {
      "adjDef": 92.3,
      "adjDefRank": 17,
      "adjEM": 19.93,
      "adjOff": 112.3,
      "adjOffRank": 46,
      "adjTempo": 61.8,
      "adjTempoRank": 295,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 21,
      "record": "25-11",
      "seed": 9
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 107.1,
      "adjDefRank": 240,
      "adjEM": -10.42,
      "adjOff": 96.7,
      "adjOffRank": 271,
      "adjTempo": 63.6,
      "adjTempoRank": 234,
      "conference": "Slnd",
      "rank": 272,
      "record": "9-20"
    },
    "Texas Pan American": {
      "adjDef": 113.2,
      "adjDefRank": 325,
      "adjEM": -7.73,
      "adjOff": 105.5,
      "adjOffRank": 140,
      "adjTempo": 62.6,
      "adjTempoRank": 277,
      "conference": "ind",
      "rank": 244,
      "record": "18-13"
    },
    "Texas Southern": {
      "adjDef": 111.5,
      "adjDefRank": 312,
      "adjEM": -23.21,
      "adjOff": 88.3,
      "adjOffRank": 333,
      "adjTempo": 67.4,
      "adjTempoRank": 93,
      "conference": "SWAC",
      "rank": 335,
      "record": "7-25"
    },
    "Texas St.": {
      "adjDef": 110,
      "adjDefRank": 290,
      "adjEM": -9.08,
      "adjOff": 100.9,
      "adjOffRank": 209,
      "adjTempo": 78,
      "adjTempoRank": 1,
      "conference": "Slnd",
      "rank": 261,
      "record": "13-16"
    },
    "Texas Tech": {
      "adjDef": 98.1,
      "adjDefRank": 69,
      "adjEM": 9.6,
      "adjOff": 107.7,
      "adjOffRank": 108,
      "adjTempo": 67.6,
      "adjTempoRank": 89,
      "conference": "B12",
      "rank": 83,
      "record": "16-15"
    },
    "The Citadel": {
      "adjDef": 115.9,
      "adjDefRank": 339,
      "adjEM": -20.95,
      "adjOff": 94.9,
      "adjOffRank": 298,
      "adjTempo": 62.1,
      "adjTempoRank": 289,
      "conference": "SC",
      "rank": 330,
      "record": "6-24"
    },
    "Toledo": {
      "adjDef": 99.9,
      "adjDefRank": 94,
      "adjEM": -5.85,
      "adjOff": 94.1,
      "adjOffRank": 307,
      "adjTempo": 64.3,
      "adjTempoRank": 202,
      "conference": "MAC",
      "rank": 221,
      "record": "11-19"
    },
    "Towson": {
      "adjDef": 106.7,
      "adjDefRank": 226,
      "adjEM": -7.45,
      "adjOff": 99.2,
      "adjOffRank": 234,
      "adjTempo": 65.5,
      "adjTempoRank": 154,
      "conference": "CAA",
      "rank": 240,
      "record": "13-18"
    },
    "Troy": {
      "adjDef": 111.9,
      "adjDefRank": 318,
      "adjEM": -7.42,
      "adjOff": 104.5,
      "adjOffRank": 161,
      "adjTempo": 70.2,
      "adjTempoRank": 22,
      "conference": "SB",
      "rank": 239,
      "record": "12-19"
    },
    "Tulane": {
      "adjDef": 102.2,
      "adjDefRank": 141,
      "adjEM": -0.13,
      "adjOff": 102.1,
      "adjOffRank": 190,
      "adjTempo": 63.5,
      "adjTempoRank": 240,
      "conference": "CUSA",
      "rank": 164,
      "record": "17-15"
    },
    "Tulsa": {
      "adjDef": 96.6,
      "adjDefRank": 52,
      "adjEM": 9.63,
      "adjOff": 106.2,
      "adjOffRank": 131,
      "adjTempo": 64.6,
      "adjTempoRank": 191,
      "conference": "CUSA",
      "rank": 82,
      "record": "25-14"
    },
    "UAB": {
      "adjDef": 98.6,
      "adjDefRank": 77,
      "adjEM": 12.07,
      "adjOff": 110.7,
      "adjOffRank": 57,
      "adjTempo": 66.8,
      "adjTempoRank": 110,
      "conference": "CUSA",
      "rank": 61,
      "record": "23-11"
    },
    "UC Davis": {
      "adjDef": 115.6,
      "adjDefRank": 336,
      "adjEM": -17.65,
      "adjOff": 97.9,
      "adjOffRank": 252,
      "adjTempo": 62.9,
      "adjTempoRank": 263,
      "conference": "BW",
      "rank": 321,
      "record": "9-22"
    },
    "UC Irvine": {
      "adjDef": 103.8,
      "adjDefRank": 173,
      "adjEM": 0.48,
      "adjOff": 104.2,
      "adjOffRank": 167,
      "adjTempo": 62.8,
      "adjTempoRank": 270,
      "conference": "BW",
      "rank": 161,
      "record": "18-16"
    },
    "UC Riverside": {
      "adjDef": 107.3,
      "adjDefRank": 245,
      "adjEM": -13.65,
      "adjOff": 93.7,
      "adjOffRank": 310,
      "adjTempo": 61.8,
      "adjTempoRank": 293,
      "conference": "BW",
      "rank": 294,
      "record": "9-21"
    },
    "UC Santa Barbara": {
      "adjDef": 97.3,
      "adjDefRank": 64,
      "adjEM": 4.93,
      "adjOff": 102.3,
      "adjOffRank": 186,
      "adjTempo": 65.2,
      "adjTempoRank": 162,
      "conference": "BW",
      "rank": 117,
      "record": "23-9"
    },
    "UCF": {
      "adjDef": 102,
      "adjDefRank": 137,
      "adjEM": 7.11,
      "adjOff": 109.1,
      "adjOffRank": 78,
      "adjTempo": 64.1,
      "adjTempoRank": 208,
      "conference": "CUSA",
      "rank": 97,
      "record": "16-15"
    },
    "UCLA": {
      "adjDef": 88.1,
      "adjDefRank": 5,
      "adjEM": 29.94,
      "adjOff": 118,
      "adjOffRank": 7,
      "adjTempo": 64.1,
      "adjTempoRank": 209,
      "conference": "P10",
      "finish": "Final Four",
      "rank": 4,
      "record": "35-4",
      "seed": 1
    },
    "UMBC": {
      "adjDef": 109.3,
      "adjDefRank": 282,
      "adjEM": 3.86,
      "adjOff": 113.2,
      "adjOffRank": 39,
      "adjTempo": 63.7,
      "adjTempoRank": 230,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 127,
      "record": "24-9",
      "seed": 15
    },
    "UMKC": {
      "adjDef": 108.7,
      "adjDefRank": 266,
      "adjEM": -8.72,
      "adjOff": 100,
      "adjOffRank": 223,
      "adjTempo": 65.9,
      "adjTempoRank": 142,
      "conference": "Sum",
      "rank": 258,
      "record": "11-21"
    },
    "UNC Asheville": {
      "adjDef": 108.4,
      "adjDefRank": 259,
      "adjEM": -1.21,
      "adjOff": 107.2,
      "adjOffRank": 115,
      "adjTempo": 64.4,
      "adjTempoRank": 198,
      "conference": "BSth",
      "rank": 172,
      "record": "23-10"
    },
    "UNC Greensboro": {
      "adjDef": 101.2,
      "adjDefRank": 120,
      "adjEM": 6.96,
      "adjOff": 108.1,
      "adjOffRank": 98,
      "adjTempo": 64.6,
      "adjTempoRank": 189,
      "conference": "SC",
      "rank": 99,
      "record": "19-12"
    },
    "UNC Wilmington": {
      "adjDef": 107.9,
      "adjDefRank": 249,
      "adjEM": 0.35,
      "adjOff": 108.2,
      "adjOffRank": 93,
      "adjTempo": 66.5,
      "adjTempoRank": 121,
      "conference": "CAA",
      "rank": 162,
      "record": "20-13"
    },
    "UNLV": {
      "adjDef": 96.5,
      "adjDefRank": 51,
      "adjEM": 13.71,
      "adjOff": 110.2,
      "adjOffRank": 67,
      "adjTempo": 63.9,
      "adjTempoRank": 223,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 48,
      "record": "27-8",
      "seed": 8
    },
    "USC": {
      "adjDef": 91.9,
      "adjDefRank": 13,
      "adjEM": 18.67,
      "adjOff": 110.5,
      "adjOffRank": 59,
      "adjTempo": 65.2,
      "adjTempoRank": 166,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 28,
      "record": "21-12",
      "seed": 6
    },
    "USC Upstate": {
      "adjDef": 105.8,
      "adjDefRank": 208,
      "adjEM": -12.15,
      "adjOff": 93.7,
      "adjOffRank": 309,
      "adjTempo": 63.4,
      "adjTempoRank": 247,
      "conference": "ASun",
      "rank": 284,
      "record": "7-23"
    },
    "UT Arlington": {
      "adjDef": 103.6,
      "adjDefRank": 170,
      "adjEM": -2.31,
      "adjOff": 101.3,
      "adjOffRank": 202,
      "adjTempo": 68.6,
      "adjTempoRank": 57,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 180,
      "record": "21-12",
      "seed": 16
    },
    "UTEP": {
      "adjDef": 100.9,
      "adjDefRank": 115,
      "adjEM": 5.74,
      "adjOff": 106.7,
      "adjOffRank": 123,
      "adjTempo": 70.9,
      "adjTempoRank": 16,
      "conference": "CUSA",
      "rank": 108,
      "record": "19-14"
    },
    "UTSA": {
      "adjDef": 106.5,
      "adjDefRank": 223,
      "adjEM": -11.88,
      "adjOff": 94.6,
      "adjOffRank": 301,
      "adjTempo": 63.4,
      "adjTempoRank": 244,
      "conference": "Slnd",
      "rank": 282,
      "record": "13-17"
    },
    "Utah": {
      "adjDef": 98.3,
      "adjDefRank": 71,
      "adjEM": 11.94,
      "adjOff": 110.2,
      "adjOffRank": 64,
      "adjTempo": 62.9,
      "adjTempoRank": 266,
      "conference": "MWC",
      "rank": 64,
      "record": "18-15"
    },
    "Utah St.": {
      "adjDef": 108.9,
      "adjDefRank": 270,
      "adjEM": 5.7,
      "adjOff": 114.6,
      "adjOffRank": 28,
      "adjTempo": 62.9,
      "adjTempoRank": 264,
      "conference": "WAC",
      "rank": 109,
      "record": "24-11"
    },
    "Utah Valley St.": {
      "adjDef": 110.2,
      "adjDefRank": 297,
      "adjEM": -5.22,
      "adjOff": 105,
      "adjOffRank": 144,
      "adjTempo": 60.6,
      "adjTempoRank": 315,
      "conference": "ind",
      "rank": 215,
      "record": "15-14"
    },
    "VCU": {
      "adjDef": 93.2,
      "adjDefRank": 22,
      "adjEM": 11.45,
      "adjOff": 104.7,
      "adjOffRank": 157,
      "adjTempo": 63.6,
      "adjTempoRank": 237,
      "conference": "CAA",
      "rank": 68,
      "record": "24-8"
    },
    "VMI": {
      "adjDef": 114.1,
      "adjDefRank": 328,
      "adjEM": -8.65,
      "adjOff": 105.4,
      "adjOffRank": 141,
      "adjTempo": 77.7,
      "adjTempoRank": 2,
      "conference": "BSth",
      "rank": 255,
      "record": "14-15"
    },
    "Valparaiso": {
      "adjDef": 103.2,
      "adjDefRank": 162,
      "adjEM": 4.7,
      "adjOff": 107.9,
      "adjOffRank": 103,
      "adjTempo": 65.7,
      "adjTempoRank": 149,
      "conference": "Horz",
      "rank": 119,
      "record": "22-14"
    },
    "Vanderbilt": {
      "adjDef": 99.6,
      "adjDefRank": 88,
      "adjEM": 12.65,
      "adjOff": 112.3,
      "adjOffRank": 47,
      "adjTempo": 68.2,
      "adjTempoRank": 67,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 56,
      "record": "26-8",
      "seed": 4
    },
    "Vermont": {
      "adjDef": 102.7,
      "adjDefRank": 151,
      "adjEM": -1.73,
      "adjOff": 101,
      "adjOffRank": 205,
      "adjTempo": 68.8,
      "adjTempoRank": 47,
      "conference": "AE",
      "rank": 175,
      "record": "16-15"
    },
    "Villanova": {
      "adjDef": 95,
      "adjDefRank": 35,
      "adjEM": 14.21,
      "adjOff": 109.2,
      "adjOffRank": 75,
      "adjTempo": 68.1,
      "adjTempoRank": 74,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 46,
      "record": "22-13",
      "seed": 12
    },
    "Virginia": {
      "adjDef": 102.4,
      "adjDefRank": 145,
      "adjEM": 8.11,
      "adjOff": 110.5,
      "adjOffRank": 61,
      "adjTempo": 68.4,
      "adjTempoRank": 63,
      "conference": "ACC",
      "rank": 90,
      "record": "17-16"
    },
    "Virginia Tech": {
      "adjDef": 90.8,
      "adjDefRank": 8,
      "adjEM": 16.29,
      "adjOff": 107.1,
      "adjOffRank": 119,
      "adjTempo": 65.8,
      "adjTempoRank": 146,
      "conference": "ACC",
      "rank": 34,
      "record": "21-14"
    },
    "Wagner": {
      "adjDef": 103.6,
      "adjDefRank": 168,
      "adjEM": -6.44,
      "adjOff": 97.1,
      "adjOffRank": 265,
      "adjTempo": 67.4,
      "adjTempoRank": 91,
      "conference": "NEC",
      "rank": 228,
      "record": "23-8"
    },
    "Wake Forest": {
      "adjDef": 96.9,
      "adjDefRank": 58,
      "adjEM": 10.82,
      "adjOff": 107.7,
      "adjOffRank": 106,
      "adjTempo": 68.8,
      "adjTempoRank": 46,
      "conference": "ACC",
      "rank": 73,
      "record": "17-13"
    },
    "Washington": {
      "adjDef": 98.4,
      "adjDefRank": 74,
      "adjEM": 11.36,
      "adjOff": 109.7,
      "adjOffRank": 72,
      "adjTempo": 68.6,
      "adjTempoRank": 59,
      "conference": "P10",
      "rank": 69,
      "record": "16-17"
    },
    "Washington St.": {
      "adjDef": 90.8,
      "adjDefRank": 9,
      "adjEM": 24.32,
      "adjOff": 115.1,
      "adjOffRank": 23,
      "adjTempo": 58.3,
      "adjTempoRank": 334,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "26-9",
      "seed": 4
    },
    "Weber St.": {
      "adjDef": 102.4,
      "adjDefRank": 144,
      "adjEM": -2.72,
      "adjOff": 99.6,
      "adjOffRank": 231,
      "adjTempo": 63.9,
      "adjTempoRank": 220,
      "conference": "BSky",
      "rank": 186,
      "record": "16-14"
    },
    "West Virginia": {
      "adjDef": 93.3,
      "adjDefRank": 23,
      "adjEM": 21.29,
      "adjOff": 114.6,
      "adjOffRank": 27,
      "adjTempo": 63.8,
      "adjTempoRank": 228,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "26-11",
      "seed": 7
    },
    "Western Carolina": {
      "adjDef": 106.1,
      "adjDefRank": 214,
      "adjEM": -7.58,
      "adjOff": 98.6,
      "adjOffRank": 244,
      "adjTempo": 63.3,
      "adjTempoRank": 251,
      "conference": "SC",
      "rank": 243,
      "record": "10-21"
    },
    "Western Illinois": {
      "adjDef": 107.1,
      "adjDefRank": 237,
      "adjEM": -8.66,
      "adjOff": 98.4,
      "adjOffRank": 246,
      "adjTempo": 66.1,
      "adjTempoRank": 137,
      "conference": "Sum",
      "rank": 256,
      "record": "12-18"
    },
    "Western Kentucky": {
      "adjDef": 97.3,
      "adjDefRank": 62,
      "adjEM": 14.6,
      "adjOff": 111.9,
      "adjOffRank": 48,
      "adjTempo": 68.2,
      "adjTempoRank": 69,
      "conference": "SB",
      "finish": "Sweet 16",
      "rank": 43,
      "record": "29-7",
      "seed": 12
    },
    "Western Michigan": {
      "adjDef": 100.2,
      "adjDefRank": 97,
      "adjEM": 7.92,
      "adjOff": 108.1,
      "adjOffRank": 100,
      "adjTempo": 63.8,
      "adjTempoRank": 226,
      "conference": "MAC",
      "rank": 94,
      "record": "20-12"
    },
    "Wichita St.": {
      "adjDef": 102.8,
      "adjDefRank": 154,
      "adjEM": 1.41,
      "adjOff": 104.3,
      "adjOffRank": 165,
      "adjTempo": 60.5,
      "adjTempoRank": 318,
      "conference": "MVC",
      "rank": 154,
      "record": "11-20"
    },
    "William & Mary": {
      "adjDef": 103.3,
      "adjDefRank": 165,
      "adjEM": -2.99,
      "adjOff": 100.3,
      "adjOffRank": 219,
      "adjTempo": 61.4,
      "adjTempoRank": 303,
      "conference": "CAA",
      "rank": 189,
      "record": "17-16"
    },
    "Winston Salem St.": {
      "adjDef": 107.9,
      "adjDefRank": 251,
      "adjEM": -18.2,
      "adjOff": 89.7,
      "adjOffRank": 330,
      "adjTempo": 65.6,
      "adjTempoRank": 150,
      "conference": "ind",
      "rank": 323,
      "record": "12-18"
    },
    "Winthrop": {
      "adjDef": 93.1,
      "adjDefRank": 20,
      "adjEM": 6.38,
      "adjOff": 99.5,
      "adjOffRank": 232,
      "adjTempo": 61.6,
      "adjTempoRank": 298,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 104,
      "record": "22-12",
      "seed": 13
    },
    "Wisconsin": {
      "adjDef": 86.8,
      "adjDefRank": 3,
      "adjEM": 26.68,
      "adjOff": 113.5,
      "adjOffRank": 37,
      "adjTempo": 61.1,
      "adjTempoRank": 306,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 5,
      "record": "31-5",
      "seed": 3
    },
    "Wofford": {
      "adjDef": 110.8,
      "adjDefRank": 304,
      "adjEM": -4.05,
      "adjOff": 106.7,
      "adjOffRank": 122,
      "adjTempo": 61.2,
      "adjTempoRank": 304,
      "conference": "SC",
      "rank": 203,
      "record": "16-16"
    },
    "Wright St.": {
      "adjDef": 98.9,
      "adjDefRank": 82,
      "adjEM": 5.04,
      "adjOff": 104,
      "adjOffRank": 169,
      "adjTempo": 59.7,
      "adjTempoRank": 326,
      "conference": "Horz",
      "rank": 116,
      "record": "21-10"
    },
    "Wyoming": {
      "adjDef": 104.1,
      "adjDefRank": 180,
      "adjEM": -3.32,
      "adjOff": 100.8,
      "adjOffRank": 211,
      "adjTempo": 68.6,
      "adjTempoRank": 52,
      "conference": "MWC",
      "rank": 194,
      "record": "12-18"
    },
    "Xavier": {
      "adjDef": 95.2,
      "adjDefRank": 39,
      "adjEM": 22.05,
      "adjOff": 117.3,
      "adjOffRank": 8,
      "adjTempo": 63.8,
      "adjTempoRank": 225,
      "conference": "A10",
      "finish": "Elite Eight",
      "rank": 15,
      "record": "30-7",
      "seed": 3
    },
    "Yale": {
      "adjDef": 107.1,
      "adjDefRank": 239,
      "adjEM": -7.15,
      "adjOff": 99.9,
      "adjOffRank": 226,
      "adjTempo": 65.4,
      "adjTempoRank": 158,
      "conference": "Ivy",
      "rank": 232,
      "record": "13-15"
    },
    "Youngstown St.": {
      "adjDef": 103.8,
      "adjDefRank": 174,
      "adjEM": -5.88,
      "adjOff": 97.9,
      "adjOffRank": 251,
      "adjTempo": 67.2,
      "adjTempoRank": 99,
      "conference": "Horz",
      "rank": 222,
      "record": "9-21"
    }
  },
  "2009": {
    "Air Force": {
      "adjDef": 104.3,
      "adjDefRank": 203,
      "adjEM": -5.7,
      "adjOff": 98.6,
      "adjOffRank": 237,
      "adjTempo": 57.8,
      "adjTempoRank": 339,
      "conference": "MWC",
      "rank": 230,
      "record": "10-21"
    },
    "Akron": {
      "adjDef": 96,
      "adjDefRank": 51,
      "adjEM": 6.9,
      "adjOff": 102.9,
      "adjOffRank": 163,
      "adjTempo": 64.8,
      "adjTempoRank": 190,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 95,
      "record": "23-13",
      "seed": 13
    },
    "Alabama": {
      "adjDef": 99.3,
      "adjDefRank": 93,
      "adjEM": 6.63,
      "adjOff": 105.9,
      "adjOffRank": 113,
      "adjTempo": 67.5,
      "adjTempoRank": 81,
      "conference": "SEC",
      "rank": 100,
      "record": "18-14"
    },
    "Alabama A&M": {
      "adjDef": 106.7,
      "adjDefRank": 252,
      "adjEM": -20.67,
      "adjOff": 86,
      "adjOffRank": 341,
      "adjTempo": 70.2,
      "adjTempoRank": 21,
      "conference": "SWAC",
      "rank": 334,
      "record": "8-19"
    },
    "Alabama St.": {
      "adjDef": 103.6,
      "adjDefRank": 184,
      "adjEM": -2.97,
      "adjOff": 100.7,
      "adjOffRank": 214,
      "adjTempo": 63.6,
      "adjTempoRank": 249,
      "conference": "SWAC",
      "finish": "Play-in",
      "rank": 200,
      "record": "22-10",
      "seed": 16
    },
    "Albany": {
      "adjDef": 104.3,
      "adjDefRank": 199,
      "adjEM": -6.99,
      "adjOff": 97.3,
      "adjOffRank": 261,
      "adjTempo": 64.3,
      "adjTempoRank": 217,
      "conference": "AE",
      "rank": 246,
      "record": "15-16"
    },
    "Alcorn St.": {
      "adjDef": 118.5,
      "adjDefRank": 344,
      "adjEM": -26.4,
      "adjOff": 92.1,
      "adjOffRank": 313,
      "adjTempo": 72.4,
      "adjTempoRank": 9,
      "conference": "SWAC",
      "rank": 340,
      "record": "6-25"
    },
    "American": {
      "adjDef": 100.6,
      "adjDefRank": 125,
      "adjEM": 4.7,
      "adjOff": 105.3,
      "adjOffRank": 123,
      "adjTempo": 60.7,
      "adjTempoRank": 319,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 112,
      "record": "24-8",
      "seed": 14
    },
    "Appalachian St.": {
      "adjDef": 109.6,
      "adjDefRank": 296,
      "adjEM": -8.1,
      "adjOff": 101.5,
      "adjOffRank": 203,
      "adjTempo": 69.7,
      "adjTempoRank": 26,
      "conference": "SC",
      "rank": 260,
      "record": "13-18"
    },
    "Arizona": {
      "adjDef": 101.8,
      "adjDefRank": 147,
      "adjEM": 15.96,
      "adjOff": 117.7,
      "adjOffRank": 6,
      "adjTempo": 64.9,
      "adjTempoRank": 187,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 37,
      "record": "21-14",
      "seed": 12
    },
    "Arizona St.": {
      "adjDef": 95.2,
      "adjDefRank": 42,
      "adjEM": 23.03,
      "adjOff": 118.2,
      "adjOffRank": 5,
      "adjTempo": 59.1,
      "adjTempoRank": 334,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 15,
      "record": "25-10",
      "seed": 6
    },
    "Arkansas": {
      "adjDef": 101.9,
      "adjDefRank": 149,
      "adjEM": 3.2,
      "adjOff": 105.1,
      "adjOffRank": 128,
      "adjTempo": 68.6,
      "adjTempoRank": 52,
      "conference": "SEC",
      "rank": 127,
      "record": "14-16"
    },
    "Arkansas Little Rock": {
      "adjDef": 98.7,
      "adjDefRank": 84,
      "adjEM": 1.24,
      "adjOff": 99.9,
      "adjOffRank": 224,
      "adjTempo": 64.9,
      "adjTempoRank": 186,
      "conference": "SB",
      "rank": 148,
      "record": "23-8"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 101.7,
      "adjDefRank": 145,
      "adjEM": -13.77,
      "adjOff": 88,
      "adjOffRank": 338,
      "adjTempo": 69.7,
      "adjTempoRank": 30,
      "conference": "SWAC",
      "rank": 302,
      "record": "13-18"
    },
    "Arkansas St.": {
      "adjDef": 101.1,
      "adjDefRank": 134,
      "adjEM": -5.23,
      "adjOff": 95.9,
      "adjOffRank": 279,
      "adjTempo": 64.3,
      "adjTempoRank": 215,
      "conference": "SB",
      "rank": 225,
      "record": "13-17"
    },
    "Army": {
      "adjDef": 100.7,
      "adjDefRank": 128,
      "adjEM": -12.46,
      "adjOff": 88.3,
      "adjOffRank": 337,
      "adjTempo": 62.8,
      "adjTempoRank": 270,
      "conference": "Pat",
      "rank": 290,
      "record": "11-19"
    },
    "Auburn": {
      "adjDef": 94.3,
      "adjDefRank": 37,
      "adjEM": 12.46,
      "adjOff": 106.8,
      "adjOffRank": 102,
      "adjTempo": 68.3,
      "adjTempoRank": 60,
      "conference": "SEC",
      "rank": 59,
      "record": "24-12"
    },
    "Austin Peay": {
      "adjDef": 109,
      "adjDefRank": 291,
      "adjEM": -1.64,
      "adjOff": 107.4,
      "adjOffRank": 94,
      "adjTempo": 66.7,
      "adjTempoRank": 106,
      "conference": "OVC",
      "rank": 184,
      "record": "19-14"
    },
    "BYU": {
      "adjDef": 93.9,
      "adjDefRank": 35,
      "adjEM": 20.1,
      "adjOff": 114,
      "adjOffRank": 28,
      "adjTempo": 69,
      "adjTempoRank": 41,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 21,
      "record": "25-8",
      "seed": 8
    },
    "Ball St.": {
      "adjDef": 101.7,
      "adjDefRank": 144,
      "adjEM": -6.5,
      "adjOff": 95.2,
      "adjOffRank": 286,
      "adjTempo": 60.6,
      "adjTempoRank": 320,
      "conference": "MAC",
      "rank": 240,
      "record": "14-17"
    },
    "Baylor": {
      "adjDef": 100.1,
      "adjDefRank": 115,
      "adjEM": 16.38,
      "adjOff": 116.5,
      "adjOffRank": 14,
      "adjTempo": 65.8,
      "adjTempoRank": 152,
      "conference": "B12",
      "rank": 33,
      "record": "24-15"
    },
    "Belmont": {
      "adjDef": 102.4,
      "adjDefRank": 164,
      "adjEM": 4.25,
      "adjOff": 106.6,
      "adjOffRank": 106,
      "adjTempo": 69.1,
      "adjTempoRank": 39,
      "conference": "ASun",
      "rank": 115,
      "record": "20-13"
    },
    "Bethune Cookman": {
      "adjDef": 101.3,
      "adjDefRank": 136,
      "adjEM": -11.76,
      "adjOff": 89.5,
      "adjOffRank": 333,
      "adjTempo": 59.8,
      "adjTempoRank": 329,
      "conference": "MEAC",
      "rank": 287,
      "record": "17-16"
    },
    "Binghamton": {
      "adjDef": 103.3,
      "adjDefRank": 178,
      "adjEM": -0.98,
      "adjOff": 102.3,
      "adjOffRank": 181,
      "adjTempo": 67.2,
      "adjTempoRank": 91,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 177,
      "record": "23-9",
      "seed": 15
    },
    "Boise St.": {
      "adjDef": 102.1,
      "adjDefRank": 154,
      "adjEM": 0.97,
      "adjOff": 103.1,
      "adjOffRank": 161,
      "adjTempo": 68.7,
      "adjTempoRank": 51,
      "conference": "WAC",
      "rank": 151,
      "record": "19-13"
    },
    "Boston College": {
      "adjDef": 102,
      "adjDefRank": 150,
      "adjEM": 11.2,
      "adjOff": 113.2,
      "adjOffRank": 33,
      "adjTempo": 65.3,
      "adjTempoRank": 172,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 74,
      "record": "22-12",
      "seed": 7
    },
    "Boston University": {
      "adjDef": 104.9,
      "adjDefRank": 212,
      "adjEM": -0.59,
      "adjOff": 104.4,
      "adjOffRank": 141,
      "adjTempo": 62.7,
      "adjTempoRank": 275,
      "conference": "AE",
      "rank": 171,
      "record": "17-13"
    },
    "Bowling Green": {
      "adjDef": 100.7,
      "adjDefRank": 129,
      "adjEM": 1.79,
      "adjOff": 102.5,
      "adjOffRank": 175,
      "adjTempo": 62.5,
      "adjTempoRank": 287,
      "conference": "MAC",
      "rank": 142,
      "record": "19-14"
    },
    "Bradley": {
      "adjDef": 101,
      "adjDefRank": 133,
      "adjEM": 3.95,
      "adjOff": 104.9,
      "adjOffRank": 134,
      "adjTempo": 64.7,
      "adjTempoRank": 198,
      "conference": "MVC",
      "rank": 120,
      "record": "21-15"
    },
    "Brown": {
      "adjDef": 109.1,
      "adjDefRank": 292,
      "adjEM": -13.78,
      "adjOff": 95.3,
      "adjOffRank": 284,
      "adjTempo": 64.2,
      "adjTempoRank": 222,
      "conference": "Ivy",
      "rank": 303,
      "record": "9-19"
    },
    "Bryant": {
      "adjDef": 114.6,
      "adjDefRank": 340,
      "adjEM": -20.61,
      "adjOff": 94,
      "adjOffRank": 296,
      "adjTempo": 61,
      "adjTempoRank": 314,
      "conference": "ind",
      "rank": 333,
      "record": "8-21"
    },
    "Bucknell": {
      "adjDef": 106.4,
      "adjDefRank": 248,
      "adjEM": -13.02,
      "adjOff": 93.4,
      "adjOffRank": 305,
      "adjTempo": 67.3,
      "adjTempoRank": 87,
      "conference": "Pat",
      "rank": 293,
      "record": "7-23"
    },
    "Buffalo": {
      "adjDef": 98.6,
      "adjDefRank": 82,
      "adjEM": 3.96,
      "adjOff": 102.6,
      "adjOffRank": 174,
      "adjTempo": 64.6,
      "adjTempoRank": 201,
      "conference": "MAC",
      "rank": 119,
      "record": "21-12"
    },
    "Butler": {
      "adjDef": 94.5,
      "adjDefRank": 38,
      "adjEM": 15.78,
      "adjOff": 110.3,
      "adjOffRank": 62,
      "adjTempo": 62.8,
      "adjTempoRank": 274,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 38,
      "record": "26-6",
      "seed": 9
    },
    "Cal Poly": {
      "adjDef": 107.1,
      "adjDefRank": 261,
      "adjEM": -9.52,
      "adjOff": 97.6,
      "adjOffRank": 252,
      "adjTempo": 65.1,
      "adjTempoRank": 179,
      "conference": "BW",
      "rank": 269,
      "record": "7-21"
    },
    "Cal St. Bakersfield": {
      "adjDef": 109.8,
      "adjDefRank": 299,
      "adjEM": -9.69,
      "adjOff": 100.1,
      "adjOffRank": 221,
      "adjTempo": 64.8,
      "adjTempoRank": 193,
      "conference": "ind",
      "rank": 272,
      "record": "8-21"
    },
    "Cal St. Fullerton": {
      "adjDef": 108.8,
      "adjDefRank": 287,
      "adjEM": -3.24,
      "adjOff": 105.6,
      "adjOffRank": 121,
      "adjTempo": 67.2,
      "adjTempoRank": 90,
      "conference": "BW",
      "rank": 201,
      "record": "15-17"
    },
    "Cal St. Northridge": {
      "adjDef": 97,
      "adjDefRank": 64,
      "adjEM": 5.18,
      "adjOff": 102.2,
      "adjOffRank": 182,
      "adjTempo": 71.8,
      "adjTempoRank": 12,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 108,
      "record": "17-14",
      "seed": 15
    },
    "California": {
      "adjDef": 99.4,
      "adjDefRank": 96,
      "adjEM": 17.37,
      "adjOff": 116.8,
      "adjOffRank": 11,
      "adjTempo": 66,
      "adjTempoRank": 140,
      "conference": "P10",
      "finish": "Round of 64",
      "rank": 30,
      "record": "22-11",
      "seed": 7
    },
    "Campbell": {
      "adjDef": 102.6,
      "adjDefRank": 169,
      "adjEM": -6.61,
      "adjOff": 96,
      "adjOffRank": 276,
      "adjTempo": 68.7,
      "adjTempoRank": 49,
      "conference": "ASun",
      "rank": 242,
      "record": "14-16"
    },
    "Canisius": {
      "adjDef": 100,
      "adjDefRank": 111,
      "adjEM": -6.13,
      "adjOff": 93.9,
      "adjOffRank": 300,
      "adjTempo": 66.5,
      "adjTempoRank": 117,
      "conference": "MAAC",
      "rank": 237,
      "record": "11-20"
    },
    "Centenary": {
      "adjDef": 103.2,
      "adjDefRank": 177,
      "adjEM": -7.3,
      "adjOff": 95.9,
      "adjOffRank": 281,
      "adjTempo": 66,
      "adjTempoRank": 138,
      "conference": "Sum",
      "rank": 249,
      "record": "8-23"
    },
    "Central Arkansas": {
      "adjDef": 111.4,
      "adjDefRank": 314,
      "adjEM": -17.11,
      "adjOff": 94.3,
      "adjOffRank": 294,
      "adjTempo": 64.6,
      "adjTempoRank": 202,
      "conference": "Slnd",
      "rank": 318,
      "record": "10-19"
    },
    "Central Connecticut": {
      "adjDef": 109.7,
      "adjDefRank": 297,
      "adjEM": -11.95,
      "adjOff": 97.7,
      "adjOffRank": 250,
      "adjTempo": 63.6,
      "adjTempoRank": 250,
      "conference": "NEC",
      "rank": 288,
      "record": "13-17"
    },
    "Central Michigan": {
      "adjDef": 106.7,
      "adjDefRank": 255,
      "adjEM": -7.71,
      "adjOff": 99,
      "adjOffRank": 233,
      "adjTempo": 62.5,
      "adjTempoRank": 283,
      "conference": "MAC",
      "rank": 252,
      "record": "12-19"
    },
    "Charleston Southern": {
      "adjDef": 112.1,
      "adjDefRank": 322,
      "adjEM": -15.63,
      "adjOff": 96.5,
      "adjOffRank": 270,
      "adjTempo": 65,
      "adjTempoRank": 183,
      "conference": "BSth",
      "rank": 310,
      "record": "9-20"
    },
    "Charlotte": {
      "adjDef": 102,
      "adjDefRank": 151,
      "adjEM": 1.84,
      "adjOff": 103.9,
      "adjOffRank": 149,
      "adjTempo": 65.5,
      "adjTempoRank": 158,
      "conference": "A10",
      "rank": 140,
      "record": "11-20"
    },
    "Chattanooga": {
      "adjDef": 108.4,
      "adjDefRank": 283,
      "adjEM": -4.04,
      "adjOff": 104.3,
      "adjOffRank": 142,
      "adjTempo": 69.5,
      "adjTempoRank": 33,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 213,
      "record": "18-17",
      "seed": 16
    },
    "Chicago St.": {
      "adjDef": 112,
      "adjDefRank": 321,
      "adjEM": -6.02,
      "adjOff": 106,
      "adjOffRank": 112,
      "adjTempo": 74.2,
      "adjTempoRank": 3,
      "conference": "ind",
      "rank": 233,
      "record": "19-13"
    },
    "Cincinnati": {
      "adjDef": 100,
      "adjDefRank": 108,
      "adjEM": 8.72,
      "adjOff": 108.7,
      "adjOffRank": 83,
      "adjTempo": 63.5,
      "adjTempoRank": 254,
      "conference": "BE",
      "rank": 86,
      "record": "18-14"
    },
    "Clemson": {
      "adjDef": 96.7,
      "adjDefRank": 61,
      "adjEM": 19.62,
      "adjOff": 116.4,
      "adjOffRank": 16,
      "adjTempo": 67.9,
      "adjTempoRank": 67,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "23-9",
      "seed": 7
    },
    "Cleveland St.": {
      "adjDef": 93.3,
      "adjDefRank": 29,
      "adjEM": 11.96,
      "adjOff": 105.3,
      "adjOffRank": 124,
      "adjTempo": 63.7,
      "adjTempoRank": 243,
      "conference": "Horz",
      "finish": "Round of 32",
      "rank": 66,
      "record": "26-11",
      "seed": 13
    },
    "Coastal Carolina": {
      "adjDef": 107.6,
      "adjDefRank": 269,
      "adjEM": -11.55,
      "adjOff": 96.1,
      "adjOffRank": 275,
      "adjTempo": 66.2,
      "adjTempoRank": 132,
      "conference": "BSth",
      "rank": 284,
      "record": "11-20"
    },
    "Colgate": {
      "adjDef": 105.8,
      "adjDefRank": 234,
      "adjEM": -15.1,
      "adjOff": 90.7,
      "adjOffRank": 328,
      "adjTempo": 60.9,
      "adjTempoRank": 316,
      "conference": "Pat",
      "rank": 309,
      "record": "10-20"
    },
    "College of Charleston": {
      "adjDef": 108.6,
      "adjDefRank": 285,
      "adjEM": 1.4,
      "adjOff": 110,
      "adjOffRank": 66,
      "adjTempo": 66.2,
      "adjTempoRank": 130,
      "conference": "SC",
      "rank": 147,
      "record": "27-9"
    },
    "Colorado": {
      "adjDef": 102.2,
      "adjDefRank": 157,
      "adjEM": -1,
      "adjOff": 101.2,
      "adjOffRank": 210,
      "adjTempo": 61.6,
      "adjTempoRank": 310,
      "conference": "B12",
      "rank": 178,
      "record": "9-22"
    },
    "Colorado St.": {
      "adjDef": 108.4,
      "adjDefRank": 282,
      "adjEM": -1.37,
      "adjOff": 107,
      "adjOffRank": 100,
      "adjTempo": 66.4,
      "adjTempoRank": 123,
      "conference": "MWC",
      "rank": 181,
      "record": "9-22"
    },
    "Columbia": {
      "adjDef": 101.7,
      "adjDefRank": 146,
      "adjEM": -10.85,
      "adjOff": 90.9,
      "adjOffRank": 326,
      "adjTempo": 64.2,
      "adjTempoRank": 225,
      "conference": "Ivy",
      "rank": 280,
      "record": "12-16"
    },
    "Connecticut": {
      "adjDef": 86.8,
      "adjDefRank": 3,
      "adjEM": 29.25,
      "adjOff": 116,
      "adjOffRank": 17,
      "adjTempo": 67.1,
      "adjTempoRank": 96,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 2,
      "record": "31-5",
      "seed": 1
    },
    "Coppin St.": {
      "adjDef": 107.6,
      "adjDefRank": 268,
      "adjEM": -9.12,
      "adjOff": 98.4,
      "adjOffRank": 242,
      "adjTempo": 64.5,
      "adjTempoRank": 214,
      "conference": "MEAC",
      "rank": 267,
      "record": "13-19"
    },
    "Cornell": {
      "adjDef": 103.3,
      "adjDefRank": 179,
      "adjEM": 5.23,
      "adjOff": 108.5,
      "adjOffRank": 85,
      "adjTempo": 65.4,
      "adjTempoRank": 162,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 107,
      "record": "21-10",
      "seed": 14
    },
    "Creighton": {
      "adjDef": 100.1,
      "adjDefRank": 112,
      "adjEM": 11.79,
      "adjOff": 111.9,
      "adjOffRank": 43,
      "adjTempo": 67,
      "adjTempoRank": 98,
      "conference": "MVC",
      "rank": 68,
      "record": "27-8"
    },
    "Dartmouth": {
      "adjDef": 108.8,
      "adjDefRank": 288,
      "adjEM": -16.08,
      "adjOff": 92.8,
      "adjOffRank": 309,
      "adjTempo": 64.5,
      "adjTempoRank": 212,
      "conference": "Ivy",
      "rank": 314,
      "record": "9-19"
    },
    "Davidson": {
      "adjDef": 93.1,
      "adjDefRank": 26,
      "adjEM": 12.06,
      "adjOff": 105.1,
      "adjOffRank": 129,
      "adjTempo": 70,
      "adjTempoRank": 23,
      "conference": "SC",
      "rank": 64,
      "record": "27-8"
    },
    "Dayton": {
      "adjDef": 93,
      "adjDefRank": 25,
      "adjEM": 10.23,
      "adjOff": 103.3,
      "adjOffRank": 156,
      "adjTempo": 64.5,
      "adjTempoRank": 210,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 78,
      "record": "27-8",
      "seed": 11
    },
    "DePaul": {
      "adjDef": 105.8,
      "adjDefRank": 233,
      "adjEM": -3.72,
      "adjOff": 102,
      "adjOffRank": 190,
      "adjTempo": 64.6,
      "adjTempoRank": 205,
      "conference": "BE",
      "rank": 206,
      "record": "9-24"
    },
    "Delaware": {
      "adjDef": 106.6,
      "adjDefRank": 251,
      "adjEM": -3.98,
      "adjOff": 102.7,
      "adjOffRank": 170,
      "adjTempo": 67,
      "adjTempoRank": 99,
      "conference": "CAA",
      "rank": 211,
      "record": "13-19"
    },
    "Delaware St.": {
      "adjDef": 113.6,
      "adjDefRank": 333,
      "adjEM": -16.53,
      "adjOff": 97.1,
      "adjOffRank": 267,
      "adjTempo": 58.2,
      "adjTempoRank": 337,
      "conference": "MEAC",
      "rank": 316,
      "record": "8-24"
    },
    "Denver": {
      "adjDef": 106.2,
      "adjDefRank": 243,
      "adjEM": -3.5,
      "adjOff": 102.7,
      "adjOffRank": 168,
      "adjTempo": 56.1,
      "adjTempoRank": 344,
      "conference": "SB",
      "rank": 204,
      "record": "15-16"
    },
    "Detroit": {
      "adjDef": 101,
      "adjDefRank": 132,
      "adjEM": -9.54,
      "adjOff": 91.4,
      "adjOffRank": 319,
      "adjTempo": 63.9,
      "adjTempoRank": 233,
      "conference": "Horz",
      "rank": 270,
      "record": "7-23"
    },
    "Drake": {
      "adjDef": 100.6,
      "adjDefRank": 123,
      "adjEM": 1.8,
      "adjOff": 102.4,
      "adjOffRank": 179,
      "adjTempo": 62.9,
      "adjTempoRank": 269,
      "conference": "MVC",
      "rank": 141,
      "record": "17-16"
    },
    "Drexel": {
      "adjDef": 95.9,
      "adjDefRank": 50,
      "adjEM": 2.65,
      "adjOff": 98.6,
      "adjOffRank": 239,
      "adjTempo": 63,
      "adjTempoRank": 267,
      "conference": "CAA",
      "rank": 132,
      "record": "15-14"
    },
    "Duke": {
      "adjDef": 93.2,
      "adjDefRank": 28,
      "adjEM": 24.31,
      "adjOff": 117.5,
      "adjOffRank": 7,
      "adjTempo": 66.3,
      "adjTempoRank": 127,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "30-7",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 106.2,
      "adjDefRank": 244,
      "adjEM": 8.95,
      "adjOff": 115.2,
      "adjOffRank": 18,
      "adjTempo": 68.9,
      "adjTempoRank": 47,
      "conference": "A10",
      "rank": 85,
      "record": "21-13"
    },
    "East Carolina": {
      "adjDef": 114.6,
      "adjDefRank": 339,
      "adjEM": -4.19,
      "adjOff": 110.4,
      "adjOffRank": 61,
      "adjTempo": 64.7,
      "adjTempoRank": 199,
      "conference": "CUSA",
      "rank": 215,
      "record": "13-17"
    },
    "East Tennessee St.": {
      "adjDef": 100.7,
      "adjDefRank": 126,
      "adjEM": 5.67,
      "adjOff": 106.4,
      "adjOffRank": 108,
      "adjTempo": 69.6,
      "adjTempoRank": 31,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 105,
      "record": "23-11",
      "seed": 16
    },
    "Eastern Illinois": {
      "adjDef": 106.8,
      "adjDefRank": 258,
      "adjEM": -9.29,
      "adjOff": 97.5,
      "adjOffRank": 257,
      "adjTempo": 61.6,
      "adjTempoRank": 308,
      "conference": "OVC",
      "rank": 268,
      "record": "12-18"
    },
    "Eastern Kentucky": {
      "adjDef": 111.7,
      "adjDefRank": 318,
      "adjEM": 0.9,
      "adjOff": 112.6,
      "adjOffRank": 38,
      "adjTempo": 59.4,
      "adjTempoRank": 331,
      "conference": "OVC",
      "rank": 155,
      "record": "18-13"
    },
    "Eastern Michigan": {
      "adjDef": 105.1,
      "adjDefRank": 221,
      "adjEM": -14.97,
      "adjOff": 90.2,
      "adjOffRank": 329,
      "adjTempo": 61.2,
      "adjTempoRank": 313,
      "conference": "MAC",
      "rank": 308,
      "record": "8-24"
    },
    "Eastern Washington": {
      "adjDef": 105.4,
      "adjDefRank": 226,
      "adjEM": -7.74,
      "adjOff": 97.7,
      "adjOffRank": 251,
      "adjTempo": 63.6,
      "adjTempoRank": 251,
      "conference": "BSky",
      "rank": 253,
      "record": "12-18"
    },
    "Elon": {
      "adjDef": 107.4,
      "adjDefRank": 265,
      "adjEM": -12.5,
      "adjOff": 94.9,
      "adjOffRank": 289,
      "adjTempo": 63.9,
      "adjTempoRank": 229,
      "conference": "SC",
      "rank": 291,
      "record": "11-20"
    },
    "Evansville": {
      "adjDef": 99.6,
      "adjDefRank": 98,
      "adjEM": 3.1,
      "adjOff": 102.7,
      "adjOffRank": 171,
      "adjTempo": 66.5,
      "adjTempoRank": 116,
      "conference": "MVC",
      "rank": 128,
      "record": "17-14"
    },
    "FIU": {
      "adjDef": 106.1,
      "adjDefRank": 240,
      "adjEM": -8.84,
      "adjOff": 97.3,
      "adjOffRank": 262,
      "adjTempo": 64.2,
      "adjTempoRank": 221,
      "conference": "SB",
      "rank": 266,
      "record": "13-20"
    },
    "Fairfield": {
      "adjDef": 104,
      "adjDefRank": 191,
      "adjEM": -0.77,
      "adjOff": 103.2,
      "adjOffRank": 158,
      "adjTempo": 63.6,
      "adjTempoRank": 246,
      "conference": "MAAC",
      "rank": 173,
      "record": "17-15"
    },
    "Fairleigh Dickinson": {
      "adjDef": 112.9,
      "adjDefRank": 329,
      "adjEM": -21.55,
      "adjOff": 91.4,
      "adjOffRank": 320,
      "adjTempo": 67.8,
      "adjTempoRank": 68,
      "conference": "NEC",
      "rank": 335,
      "record": "7-23"
    },
    "Florida": {
      "adjDef": 98.9,
      "adjDefRank": 88,
      "adjEM": 16.16,
      "adjOff": 115.1,
      "adjOffRank": 21,
      "adjTempo": 65.8,
      "adjTempoRank": 151,
      "conference": "SEC",
      "rank": 35,
      "record": "25-11"
    },
    "Florida A&M": {
      "adjDef": 110.5,
      "adjDefRank": 307,
      "adjEM": -19.87,
      "adjOff": 90.7,
      "adjOffRank": 327,
      "adjTempo": 66.2,
      "adjTempoRank": 134,
      "conference": "MEAC",
      "rank": 330,
      "record": "10-21"
    },
    "Florida Atlantic": {
      "adjDef": 113.1,
      "adjDefRank": 332,
      "adjEM": -11.58,
      "adjOff": 101.5,
      "adjOffRank": 204,
      "adjTempo": 64.1,
      "adjTempoRank": 227,
      "conference": "SB",
      "rank": 285,
      "record": "6-26"
    },
    "Florida Gulf Coast": {
      "adjDef": 105.5,
      "adjDefRank": 227,
      "adjEM": -13.17,
      "adjOff": 92.3,
      "adjOffRank": 312,
      "adjTempo": 69.2,
      "adjTempoRank": 38,
      "conference": "ASun",
      "rank": 295,
      "record": "11-20"
    },
    "Florida St.": {
      "adjDef": 90.1,
      "adjDefRank": 7,
      "adjEM": 15.76,
      "adjOff": 105.9,
      "adjOffRank": 116,
      "adjTempo": 65.9,
      "adjTempoRank": 148,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 39,
      "record": "25-10",
      "seed": 5
    },
    "Fordham": {
      "adjDef": 111.9,
      "adjDefRank": 319,
      "adjEM": -18.68,
      "adjOff": 93.2,
      "adjOffRank": 308,
      "adjTempo": 66.7,
      "adjTempoRank": 108,
      "conference": "A10",
      "rank": 321,
      "record": "3-25"
    },
    "Fresno St.": {
      "adjDef": 103.5,
      "adjDefRank": 182,
      "adjEM": -3.78,
      "adjOff": 99.7,
      "adjOffRank": 227,
      "adjTempo": 66.5,
      "adjTempoRank": 115,
      "conference": "WAC",
      "rank": 207,
      "record": "13-21"
    },
    "Furman": {
      "adjDef": 110.5,
      "adjDefRank": 305,
      "adjEM": -19.21,
      "adjOff": 91.3,
      "adjOffRank": 321,
      "adjTempo": 63.8,
      "adjTempoRank": 237,
      "conference": "SC",
      "rank": 327,
      "record": "6-24"
    },
    "Gardner Webb": {
      "adjDef": 107.5,
      "adjDefRank": 267,
      "adjEM": -5.9,
      "adjOff": 101.6,
      "adjOffRank": 200,
      "adjTempo": 68.5,
      "adjTempoRank": 54,
      "conference": "BSth",
      "rank": 232,
      "record": "13-17"
    },
    "George Mason": {
      "adjDef": 96.6,
      "adjDefRank": 59,
      "adjEM": 8.04,
      "adjOff": 104.6,
      "adjOffRank": 136,
      "adjTempo": 62.2,
      "adjTempoRank": 294,
      "conference": "CAA",
      "rank": 89,
      "record": "22-11"
    },
    "George Washington": {
      "adjDef": 104.4,
      "adjDefRank": 205,
      "adjEM": -2.17,
      "adjOff": 102.2,
      "adjOffRank": 183,
      "adjTempo": 63,
      "adjTempoRank": 265,
      "conference": "A10",
      "rank": 187,
      "record": "10-18"
    },
    "Georgetown": {
      "adjDef": 94.2,
      "adjDefRank": 36,
      "adjEM": 16.96,
      "adjOff": 111.1,
      "adjOffRank": 51,
      "adjTempo": 62.7,
      "adjTempoRank": 277,
      "conference": "BE",
      "rank": 31,
      "record": "16-15"
    },
    "Georgia": {
      "adjDef": 98.3,
      "adjDefRank": 76,
      "adjEM": -2.95,
      "adjOff": 95.4,
      "adjOffRank": 283,
      "adjTempo": 68.1,
      "adjTempoRank": 64,
      "conference": "SEC",
      "rank": 198,
      "record": "12-20"
    },
    "Georgia Southern": {
      "adjDef": 112.7,
      "adjDefRank": 327,
      "adjEM": -14.75,
      "adjOff": 98,
      "adjOffRank": 248,
      "adjTempo": 68.9,
      "adjTempoRank": 48,
      "conference": "SC",
      "rank": 307,
      "record": "8-22"
    },
    "Georgia St.": {
      "adjDef": 104.3,
      "adjDefRank": 201,
      "adjEM": -5.6,
      "adjOff": 98.7,
      "adjOffRank": 236,
      "adjTempo": 60.1,
      "adjTempoRank": 325,
      "conference": "CAA",
      "rank": 228,
      "record": "12-20"
    },
    "Georgia Tech": {
      "adjDef": 93.7,
      "adjDefRank": 32,
      "adjEM": 6.73,
      "adjOff": 100.4,
      "adjOffRank": 218,
      "adjTempo": 70.5,
      "adjTempoRank": 19,
      "conference": "ACC",
      "rank": 98,
      "record": "12-19"
    },
    "Gonzaga": {
      "adjDef": 93,
      "adjDefRank": 24,
      "adjEM": 24.51,
      "adjOff": 117.5,
      "adjOffRank": 8,
      "adjTempo": 67.1,
      "adjTempoRank": 97,
      "conference": "WCC",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "28-6",
      "seed": 4
    },
    "Grambling St.": {
      "adjDef": 116.9,
      "adjDefRank": 343,
      "adjEM": -27.1,
      "adjOff": 89.8,
      "adjOffRank": 330,
      "adjTempo": 61.8,
      "adjTempoRank": 303,
      "conference": "SWAC",
      "rank": 341,
      "record": "6-23"
    },
    "Green Bay": {
      "adjDef": 102.7,
      "adjDefRank": 170,
      "adjEM": 10.16,
      "adjOff": 112.9,
      "adjOffRank": 36,
      "adjTempo": 65,
      "adjTempoRank": 182,
      "conference": "Horz",
      "rank": 79,
      "record": "22-11"
    },
    "Hampton": {
      "adjDef": 96.3,
      "adjDefRank": 55,
      "adjEM": -9.99,
      "adjOff": 86.3,
      "adjOffRank": 339,
      "adjTempo": 63.5,
      "adjTempoRank": 257,
      "conference": "MEAC",
      "rank": 275,
      "record": "16-16"
    },
    "Hartford": {
      "adjDef": 108,
      "adjDefRank": 279,
      "adjEM": -15.94,
      "adjOff": 92.1,
      "adjOffRank": 314,
      "adjTempo": 63.4,
      "adjTempoRank": 259,
      "conference": "AE",
      "rank": 313,
      "record": "7-26"
    },
    "Harvard": {
      "adjDef": 110.1,
      "adjDefRank": 302,
      "adjEM": -7.76,
      "adjOff": 102.4,
      "adjOffRank": 180,
      "adjTempo": 65.1,
      "adjTempoRank": 177,
      "conference": "Ivy",
      "rank": 254,
      "record": "14-14"
    },
    "Hawaii": {
      "adjDef": 101.3,
      "adjDefRank": 138,
      "adjEM": -4.17,
      "adjOff": 97.2,
      "adjOffRank": 265,
      "adjTempo": 63.9,
      "adjTempoRank": 232,
      "conference": "WAC",
      "rank": 214,
      "record": "13-17"
    },
    "High Point": {
      "adjDef": 105.7,
      "adjDefRank": 231,
      "adjEM": -14.72,
      "adjOff": 91,
      "adjOffRank": 324,
      "adjTempo": 63.5,
      "adjTempoRank": 253,
      "conference": "BSth",
      "rank": 306,
      "record": "9-21"
    },
    "Hofstra": {
      "adjDef": 98.4,
      "adjDefRank": 80,
      "adjEM": -0.56,
      "adjOff": 97.9,
      "adjOffRank": 249,
      "adjTempo": 67,
      "adjTempoRank": 102,
      "conference": "CAA",
      "rank": 169,
      "record": "21-11"
    },
    "Holy Cross": {
      "adjDef": 100.1,
      "adjDefRank": 114,
      "adjEM": -1.39,
      "adjOff": 98.7,
      "adjOffRank": 235,
      "adjTempo": 64.5,
      "adjTempoRank": 213,
      "conference": "Pat",
      "rank": 182,
      "record": "18-14"
    },
    "Houston": {
      "adjDef": 97.7,
      "adjDefRank": 69,
      "adjEM": 11.49,
      "adjOff": 109.2,
      "adjOffRank": 76,
      "adjTempo": 68.5,
      "adjTempoRank": 55,
      "conference": "CUSA",
      "rank": 70,
      "record": "21-12"
    },
    "Houston Baptist": {
      "adjDef": 114.1,
      "adjDefRank": 336,
      "adjEM": -18.16,
      "adjOff": 95.9,
      "adjOffRank": 280,
      "adjTempo": 70.3,
      "adjTempoRank": 20,
      "conference": "ind",
      "rank": 319,
      "record": "5-25"
    },
    "Howard": {
      "adjDef": 110.8,
      "adjDefRank": 310,
      "adjEM": -19.82,
      "adjOff": 90.9,
      "adjOffRank": 325,
      "adjTempo": 65.4,
      "adjTempoRank": 166,
      "conference": "MEAC",
      "rank": 329,
      "record": "8-23"
    },
    "IPFW": {
      "adjDef": 106.8,
      "adjDefRank": 257,
      "adjEM": -4.29,
      "adjOff": 102.5,
      "adjOffRank": 177,
      "adjTempo": 65.3,
      "adjTempoRank": 170,
      "conference": "Sum",
      "rank": 216,
      "record": "13-17"
    },
    "IUPUI": {
      "adjDef": 104.6,
      "adjDefRank": 209,
      "adjEM": -0.79,
      "adjOff": 103.8,
      "adjOffRank": 150,
      "adjTempo": 61.2,
      "adjTempoRank": 312,
      "conference": "Sum",
      "rank": 175,
      "record": "16-14"
    },
    "Idaho": {
      "adjDef": 104.1,
      "adjDefRank": 195,
      "adjEM": 0.25,
      "adjOff": 104.4,
      "adjOffRank": 140,
      "adjTempo": 62.4,
      "adjTempoRank": 290,
      "conference": "WAC",
      "rank": 160,
      "record": "17-16"
    },
    "Idaho St.": {
      "adjDef": 105.4,
      "adjDefRank": 225,
      "adjEM": -2.64,
      "adjOff": 102.7,
      "adjOffRank": 167,
      "adjTempo": 62.6,
      "adjTempoRank": 281,
      "conference": "BSky",
      "rank": 193,
      "record": "13-19"
    },
    "Illinois": {
      "adjDef": 88.1,
      "adjDefRank": 4,
      "adjEM": 18.72,
      "adjOff": 106.8,
      "adjOffRank": 104,
      "adjTempo": 62.8,
      "adjTempoRank": 271,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 25,
      "record": "24-10",
      "seed": 5
    },
    "Illinois Chicago": {
      "adjDef": 102.6,
      "adjDefRank": 167,
      "adjEM": 3.01,
      "adjOff": 105.6,
      "adjOffRank": 120,
      "adjTempo": 65.1,
      "adjTempoRank": 178,
      "conference": "Horz",
      "rank": 129,
      "record": "16-15"
    },
    "Illinois St.": {
      "adjDef": 96.5,
      "adjDefRank": 58,
      "adjEM": 12.44,
      "adjOff": 109,
      "adjOffRank": 78,
      "adjTempo": 64.2,
      "adjTempoRank": 223,
      "conference": "MVC",
      "rank": 60,
      "record": "24-10"
    },
    "Indiana": {
      "adjDef": 103,
      "adjDefRank": 174,
      "adjEM": -3.92,
      "adjOff": 99.1,
      "adjOffRank": 231,
      "adjTempo": 65.4,
      "adjTempoRank": 164,
      "conference": "B10",
      "rank": 209,
      "record": "6-25"
    },
    "Indiana St.": {
      "adjDef": 105.9,
      "adjDefRank": 237,
      "adjEM": -3.36,
      "adjOff": 102.6,
      "adjOffRank": 172,
      "adjTempo": 63.3,
      "adjTempoRank": 261,
      "conference": "MVC",
      "rank": 203,
      "record": "11-21"
    },
    "Iona": {
      "adjDef": 98.7,
      "adjDefRank": 85,
      "adjEM": -2.73,
      "adjOff": 96,
      "adjOffRank": 277,
      "adjTempo": 63.8,
      "adjTempoRank": 236,
      "conference": "MAAC",
      "rank": 195,
      "record": "12-19"
    },
    "Iowa": {
      "adjDef": 100.2,
      "adjDefRank": 116,
      "adjEM": 8.44,
      "adjOff": 108.6,
      "adjOffRank": 84,
      "adjTempo": 57.6,
      "adjTempoRank": 341,
      "conference": "B10",
      "rank": 87,
      "record": "15-17"
    },
    "Iowa St.": {
      "adjDef": 97.9,
      "adjDefRank": 71,
      "adjEM": 4.6,
      "adjOff": 102.5,
      "adjOffRank": 176,
      "adjTempo": 63.7,
      "adjTempoRank": 239,
      "conference": "B12",
      "rank": 113,
      "record": "15-17"
    },
    "Jackson St.": {
      "adjDef": 105.2,
      "adjDefRank": 222,
      "adjEM": -7.1,
      "adjOff": 98.1,
      "adjOffRank": 247,
      "adjTempo": 67.8,
      "adjTempoRank": 69,
      "conference": "SWAC",
      "rank": 247,
      "record": "18-15"
    },
    "Jacksonville": {
      "adjDef": 104.4,
      "adjDefRank": 206,
      "adjEM": 3.87,
      "adjOff": 108.2,
      "adjOffRank": 88,
      "adjTempo": 66.2,
      "adjTempoRank": 129,
      "conference": "ASun",
      "rank": 122,
      "record": "18-14"
    },
    "Jacksonville St.": {
      "adjDef": 108.3,
      "adjDefRank": 280,
      "adjEM": -7.87,
      "adjOff": 100.4,
      "adjOffRank": 217,
      "adjTempo": 64.3,
      "adjTempoRank": 219,
      "conference": "OVC",
      "rank": 256,
      "record": "11-17"
    },
    "James Madison": {
      "adjDef": 103.3,
      "adjDefRank": 180,
      "adjEM": 2.42,
      "adjOff": 105.8,
      "adjOffRank": 118,
      "adjTempo": 65.9,
      "adjTempoRank": 146,
      "conference": "CAA",
      "rank": 133,
      "record": "21-15"
    },
    "Kansas": {
      "adjDef": 90.7,
      "adjDefRank": 11,
      "adjEM": 23.83,
      "adjOff": 114.5,
      "adjOffRank": 23,
      "adjTempo": 67.2,
      "adjTempoRank": 93,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "27-8",
      "seed": 3
    },
    "Kansas St.": {
      "adjDef": 95.2,
      "adjDefRank": 40,
      "adjEM": 14.18,
      "adjOff": 109.4,
      "adjOffRank": 72,
      "adjTempo": 67.4,
      "adjTempoRank": 84,
      "conference": "B12",
      "rank": 51,
      "record": "22-12"
    },
    "Kennesaw St.": {
      "adjDef": 113,
      "adjDefRank": 331,
      "adjEM": -23.44,
      "adjOff": 89.6,
      "adjOffRank": 331,
      "adjTempo": 61.6,
      "adjTempoRank": 311,
      "conference": "ASun",
      "rank": 337,
      "record": "7-22"
    },
    "Kent St.": {
      "adjDef": 99.7,
      "adjDefRank": 103,
      "adjEM": 3.47,
      "adjOff": 103.2,
      "adjOffRank": 157,
      "adjTempo": 66.1,
      "adjTempoRank": 135,
      "conference": "MAC",
      "rank": 124,
      "record": "19-15"
    },
    "Kentucky": {
      "adjDef": 93.2,
      "adjDefRank": 27,
      "adjEM": 14.62,
      "adjOff": 107.8,
      "adjOffRank": 91,
      "adjTempo": 66.6,
      "adjTempoRank": 111,
      "conference": "SEC",
      "rank": 48,
      "record": "22-14"
    },
    "LIU Brooklyn": {
      "adjDef": 107.4,
      "adjDefRank": 263,
      "adjEM": -6.74,
      "adjOff": 100.6,
      "adjOffRank": 215,
      "adjTempo": 67.4,
      "adjTempoRank": 83,
      "conference": "NEC",
      "rank": 243,
      "record": "16-14"
    },
    "LSU": {
      "adjDef": 96.6,
      "adjDefRank": 60,
      "adjEM": 14.86,
      "adjOff": 111.5,
      "adjOffRank": 47,
      "adjTempo": 66.1,
      "adjTempoRank": 136,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 46,
      "record": "27-8",
      "seed": 8
    },
    "La Salle": {
      "adjDef": 100.5,
      "adjDefRank": 121,
      "adjEM": 5.35,
      "adjOff": 105.9,
      "adjOffRank": 115,
      "adjTempo": 66.7,
      "adjTempoRank": 107,
      "conference": "A10",
      "rank": 106,
      "record": "18-13"
    },
    "Lafayette": {
      "adjDef": 112.2,
      "adjDefRank": 323,
      "adjEM": -17.09,
      "adjOff": 95.1,
      "adjOffRank": 287,
      "adjTempo": 68.4,
      "adjTempoRank": 57,
      "conference": "Pat",
      "rank": 317,
      "record": "8-22"
    },
    "Lamar": {
      "adjDef": 104.4,
      "adjDefRank": 204,
      "adjEM": -3.96,
      "adjOff": 100.4,
      "adjOffRank": 220,
      "adjTempo": 66.4,
      "adjTempoRank": 120,
      "conference": "Slnd",
      "rank": 210,
      "record": "15-15"
    },
    "Lehigh": {
      "adjDef": 102.8,
      "adjDefRank": 171,
      "adjEM": -8.84,
      "adjOff": 93.9,
      "adjOffRank": 298,
      "adjTempo": 66,
      "adjTempoRank": 141,
      "conference": "Pat",
      "rank": 265,
      "record": "15-14"
    },
    "Liberty": {
      "adjDef": 108,
      "adjDefRank": 274,
      "adjEM": -2.79,
      "adjOff": 105.2,
      "adjOffRank": 126,
      "adjTempo": 65.1,
      "adjTempoRank": 175,
      "conference": "BSth",
      "rank": 196,
      "record": "23-12"
    },
    "Lipscomb": {
      "adjDef": 105.1,
      "adjDefRank": 217,
      "adjEM": 0.44,
      "adjOff": 105.5,
      "adjOffRank": 122,
      "adjTempo": 67.7,
      "adjTempoRank": 74,
      "conference": "ASun",
      "rank": 158,
      "record": "17-14"
    },
    "Long Beach St.": {
      "adjDef": 105,
      "adjDefRank": 216,
      "adjEM": -0.51,
      "adjOff": 104.5,
      "adjOffRank": 138,
      "adjTempo": 65.9,
      "adjTempoRank": 147,
      "conference": "BW",
      "rank": 168,
      "record": "15-15"
    },
    "Longwood": {
      "adjDef": 112.4,
      "adjDefRank": 326,
      "adjEM": -15.67,
      "adjOff": 96.8,
      "adjOffRank": 269,
      "adjTempo": 73.8,
      "adjTempoRank": 5,
      "conference": "ind",
      "rank": 311,
      "record": "17-14"
    },
    "Louisiana Lafayette": {
      "adjDef": 105.4,
      "adjDefRank": 224,
      "adjEM": -8.03,
      "adjOff": 97.3,
      "adjOffRank": 260,
      "adjTempo": 66,
      "adjTempoRank": 142,
      "conference": "SB",
      "rank": 259,
      "record": "10-20"
    },
    "Louisiana Monroe": {
      "adjDef": 110.1,
      "adjDefRank": 301,
      "adjEM": -12.94,
      "adjOff": 97.1,
      "adjOffRank": 266,
      "adjTempo": 67.3,
      "adjTempoRank": 85,
      "conference": "SB",
      "rank": 292,
      "record": "10-20"
    },
    "Louisiana Tech": {
      "adjDef": 101.3,
      "adjDefRank": 139,
      "adjEM": 0,
      "adjOff": 101.3,
      "adjOffRank": 207,
      "adjTempo": 62.5,
      "adjTempoRank": 286,
      "conference": "WAC",
      "rank": 163,
      "record": "15-18"
    },
    "Louisville": {
      "adjDef": 85.2,
      "adjDefRank": 2,
      "adjEM": 26.06,
      "adjOff": 111.2,
      "adjOffRank": 49,
      "adjTempo": 66.9,
      "adjTempoRank": 103,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 5,
      "record": "31-6",
      "seed": 1
    },
    "Loyola Chicago": {
      "adjDef": 105.6,
      "adjDefRank": 228,
      "adjEM": -5.69,
      "adjOff": 99.9,
      "adjOffRank": 225,
      "adjTempo": 63.8,
      "adjTempoRank": 238,
      "conference": "Horz",
      "rank": 229,
      "record": "14-18"
    },
    "Loyola MD": {
      "adjDef": 107.8,
      "adjDefRank": 272,
      "adjEM": -5.05,
      "adjOff": 102.8,
      "adjOffRank": 166,
      "adjTempo": 65.6,
      "adjTempoRank": 156,
      "conference": "MAAC",
      "rank": 221,
      "record": "12-20"
    },
    "Loyola Marymount": {
      "adjDef": 107.9,
      "adjDefRank": 273,
      "adjEM": -18.69,
      "adjOff": 89.2,
      "adjOffRank": 335,
      "adjTempo": 64.2,
      "adjTempoRank": 224,
      "conference": "WCC",
      "rank": 323,
      "record": "3-28"
    },
    "Maine": {
      "adjDef": 110.8,
      "adjDefRank": 311,
      "adjEM": -13.17,
      "adjOff": 97.6,
      "adjOffRank": 253,
      "adjTempo": 64.3,
      "adjTempoRank": 218,
      "conference": "AE",
      "rank": 296,
      "record": "9-21"
    },
    "Manhattan": {
      "adjDef": 100,
      "adjDefRank": 109,
      "adjEM": -2.42,
      "adjOff": 97.6,
      "adjOffRank": 254,
      "adjTempo": 65.4,
      "adjTempoRank": 167,
      "conference": "MAAC",
      "rank": 191,
      "record": "16-14"
    },
    "Marist": {
      "adjDef": 102.1,
      "adjDefRank": 155,
      "adjEM": -7.65,
      "adjOff": 94.5,
      "adjOffRank": 291,
      "adjTempo": 66.8,
      "adjTempoRank": 104,
      "conference": "MAAC",
      "rank": 251,
      "record": "10-23"
    },
    "Marquette": {
      "adjDef": 96.1,
      "adjDefRank": 52,
      "adjEM": 21.02,
      "adjOff": 117.1,
      "adjOffRank": 9,
      "adjTempo": 67.5,
      "adjTempoRank": 78,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 19,
      "record": "25-10",
      "seed": 6
    },
    "Marshall": {
      "adjDef": 106.3,
      "adjDefRank": 246,
      "adjEM": 0.92,
      "adjOff": 107.2,
      "adjOffRank": 98,
      "adjTempo": 64.6,
      "adjTempoRank": 204,
      "conference": "CUSA",
      "rank": 154,
      "record": "15-17"
    },
    "Maryland": {
      "adjDef": 96.4,
      "adjDefRank": 56,
      "adjEM": 13.55,
      "adjOff": 110,
      "adjOffRank": 67,
      "adjTempo": 66.3,
      "adjTempoRank": 126,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 55,
      "record": "21-14",
      "seed": 10
    },
    "Maryland Eastern Shore": {
      "adjDef": 116.9,
      "adjDefRank": 342,
      "adjEM": -27.77,
      "adjOff": 89.2,
      "adjOffRank": 336,
      "adjTempo": 63.5,
      "adjTempoRank": 256,
      "conference": "MEAC",
      "rank": 342,
      "record": "7-23"
    },
    "Massachusetts": {
      "adjDef": 102.9,
      "adjDefRank": 172,
      "adjEM": 2.22,
      "adjOff": 105.1,
      "adjOffRank": 130,
      "adjTempo": 68.4,
      "adjTempoRank": 59,
      "conference": "A10",
      "rank": 138,
      "record": "12-18"
    },
    "McNeese St.": {
      "adjDef": 100,
      "adjDefRank": 110,
      "adjEM": -5.88,
      "adjOff": 94.1,
      "adjOffRank": 295,
      "adjTempo": 64.8,
      "adjTempoRank": 195,
      "conference": "Slnd",
      "rank": 231,
      "record": "11-18"
    },
    "Memphis": {
      "adjDef": 84.2,
      "adjDefRank": 1,
      "adjEM": 28.67,
      "adjOff": 112.8,
      "adjOffRank": 37,
      "adjTempo": 65.9,
      "adjTempoRank": 145,
      "conference": "CUSA",
      "finish": "Sweet 16",
      "rank": 4,
      "record": "33-4",
      "seed": 2
    },
    "Mercer": {
      "adjDef": 109.6,
      "adjDefRank": 295,
      "adjEM": -4.4,
      "adjOff": 105.2,
      "adjOffRank": 127,
      "adjTempo": 67.4,
      "adjTempoRank": 82,
      "conference": "ASun",
      "rank": 218,
      "record": "17-15"
    },
    "Miami FL": {
      "adjDef": 95.9,
      "adjDefRank": 48,
      "adjEM": 15.3,
      "adjOff": 111.2,
      "adjOffRank": 50,
      "adjTempo": 64.3,
      "adjTempoRank": 216,
      "conference": "ACC",
      "rank": 42,
      "record": "19-13"
    },
    "Miami OH": {
      "adjDef": 96.2,
      "adjDefRank": 53,
      "adjEM": 7.38,
      "adjOff": 103.5,
      "adjOffRank": 154,
      "adjTempo": 60.4,
      "adjTempoRank": 322,
      "conference": "MAC",
      "rank": 91,
      "record": "17-13"
    },
    "Michigan": {
      "adjDef": 97.5,
      "adjDefRank": 67,
      "adjEM": 14.75,
      "adjOff": 112.2,
      "adjOffRank": 40,
      "adjTempo": 62.5,
      "adjTempoRank": 285,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 47,
      "record": "21-14",
      "seed": 10
    },
    "Michigan St.": {
      "adjDef": 89.9,
      "adjDefRank": 6,
      "adjEM": 24.37,
      "adjOff": 114.3,
      "adjOffRank": 26,
      "adjTempo": 66.5,
      "adjTempoRank": 112,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 9,
      "record": "31-7",
      "seed": 2
    },
    "Middle Tennessee": {
      "adjDef": 104.6,
      "adjDefRank": 208,
      "adjEM": -0.07,
      "adjOff": 104.5,
      "adjOffRank": 139,
      "adjTempo": 63.3,
      "adjTempoRank": 262,
      "conference": "SB",
      "rank": 164,
      "record": "18-14"
    },
    "Milwaukee": {
      "adjDef": 103.7,
      "adjDefRank": 186,
      "adjEM": 0.93,
      "adjOff": 104.6,
      "adjOffRank": 137,
      "adjTempo": 66.6,
      "adjTempoRank": 110,
      "conference": "Horz",
      "rank": 153,
      "record": "17-14"
    },
    "Minnesota": {
      "adjDef": 92.6,
      "adjDefRank": 21,
      "adjEM": 15.43,
      "adjOff": 108.1,
      "adjOffRank": 90,
      "adjTempo": 64.5,
      "adjTempoRank": 211,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 40,
      "record": "22-11",
      "seed": 10
    },
    "Mississippi": {
      "adjDef": 104.2,
      "adjDefRank": 196,
      "adjEM": 7.19,
      "adjOff": 111.4,
      "adjOffRank": 48,
      "adjTempo": 66.3,
      "adjTempoRank": 128,
      "conference": "SEC",
      "rank": 92,
      "record": "16-15"
    },
    "Mississippi St.": {
      "adjDef": 96.2,
      "adjDefRank": 54,
      "adjEM": 11.55,
      "adjOff": 107.8,
      "adjOffRank": 92,
      "adjTempo": 67.2,
      "adjTempoRank": 95,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 69,
      "record": "23-13",
      "seed": 13
    },
    "Mississippi Valley St.": {
      "adjDef": 112.9,
      "adjDefRank": 328,
      "adjEM": -18.88,
      "adjOff": 94,
      "adjOffRank": 297,
      "adjTempo": 66.3,
      "adjTempoRank": 124,
      "conference": "SWAC",
      "rank": 325,
      "record": "7-25"
    },
    "Missouri": {
      "adjDef": 91.8,
      "adjDefRank": 17,
      "adjEM": 24.81,
      "adjOff": 116.6,
      "adjOffRank": 13,
      "adjTempo": 70.9,
      "adjTempoRank": 15,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "31-7",
      "seed": 3
    },
    "Missouri St.": {
      "adjDef": 102.3,
      "adjDefRank": 159,
      "adjEM": -2.83,
      "adjOff": 99.4,
      "adjOffRank": 230,
      "adjTempo": 62.2,
      "adjTempoRank": 296,
      "conference": "MVC",
      "rank": 197,
      "record": "11-20"
    },
    "Monmouth": {
      "adjDef": 113,
      "adjDefRank": 330,
      "adjEM": -19.1,
      "adjOff": 93.9,
      "adjOffRank": 299,
      "adjTempo": 64,
      "adjTempoRank": 228,
      "conference": "NEC",
      "rank": 326,
      "record": "8-23"
    },
    "Montana": {
      "adjDef": 104.8,
      "adjDefRank": 210,
      "adjEM": -0.7,
      "adjOff": 104.1,
      "adjOffRank": 147,
      "adjTempo": 61.9,
      "adjTempoRank": 301,
      "conference": "BSky",
      "rank": 172,
      "record": "17-12"
    },
    "Montana St.": {
      "adjDef": 107,
      "adjDefRank": 260,
      "adjEM": -6.11,
      "adjOff": 100.9,
      "adjOffRank": 211,
      "adjTempo": 64.8,
      "adjTempoRank": 194,
      "conference": "BSky",
      "rank": 236,
      "record": "15-17"
    },
    "Morehead St.": {
      "adjDef": 101.3,
      "adjDefRank": 137,
      "adjEM": 0.76,
      "adjOff": 102,
      "adjOffRank": 189,
      "adjTempo": 65.3,
      "adjTempoRank": 169,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 157,
      "record": "20-16",
      "seed": 16
    },
    "Morgan St.": {
      "adjDef": 99.1,
      "adjDefRank": 91,
      "adjEM": 0.99,
      "adjOff": 100.1,
      "adjOffRank": 222,
      "adjTempo": 65.5,
      "adjTempoRank": 159,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 150,
      "record": "23-12",
      "seed": 15
    },
    "Mount St. Mary's": {
      "adjDef": 99.9,
      "adjDefRank": 106,
      "adjEM": 1.54,
      "adjOff": 101.4,
      "adjOffRank": 205,
      "adjTempo": 63.9,
      "adjTempoRank": 230,
      "conference": "NEC",
      "rank": 145,
      "record": "19-14"
    },
    "Murray St.": {
      "adjDef": 100.3,
      "adjDefRank": 118,
      "adjEM": 2.76,
      "adjOff": 103.1,
      "adjOffRank": 160,
      "adjTempo": 62.4,
      "adjTempoRank": 291,
      "conference": "OVC",
      "rank": 131,
      "record": "19-12"
    },
    "N.C. State": {
      "adjDef": 103.6,
      "adjDefRank": 185,
      "adjEM": 9.84,
      "adjOff": 113.5,
      "adjOffRank": 30,
      "adjTempo": 63,
      "adjTempoRank": 266,
      "conference": "ACC",
      "rank": 81,
      "record": "16-14"
    },
    "NJIT": {
      "adjDef": 106.7,
      "adjDefRank": 254,
      "adjEM": -35.2,
      "adjOff": 71.5,
      "adjOffRank": 344,
      "adjTempo": 64.6,
      "adjTempoRank": 200,
      "conference": "ind",
      "rank": 344,
      "record": "1-30"
    },
    "Navy": {
      "adjDef": 103.9,
      "adjDefRank": 190,
      "adjEM": -5.2,
      "adjOff": 98.7,
      "adjOffRank": 234,
      "adjTempo": 68.7,
      "adjTempoRank": 50,
      "conference": "Pat",
      "rank": 224,
      "record": "19-11"
    },
    "Nebraska": {
      "adjDef": 91.3,
      "adjDefRank": 15,
      "adjEM": 12.21,
      "adjOff": 103.5,
      "adjOffRank": 153,
      "adjTempo": 62.9,
      "adjTempoRank": 268,
      "conference": "B12",
      "rank": 63,
      "record": "18-13"
    },
    "Nevada": {
      "adjDef": 99.8,
      "adjDefRank": 104,
      "adjEM": 6.07,
      "adjOff": 105.8,
      "adjOffRank": 117,
      "adjTempo": 66.3,
      "adjTempoRank": 125,
      "conference": "WAC",
      "rank": 103,
      "record": "21-13"
    },
    "New Hampshire": {
      "adjDef": 105.6,
      "adjDefRank": 230,
      "adjEM": -8.46,
      "adjOff": 97.2,
      "adjOffRank": 264,
      "adjTempo": 62.1,
      "adjTempoRank": 300,
      "conference": "AE",
      "rank": 261,
      "record": "14-16"
    },
    "New Mexico": {
      "adjDef": 96.9,
      "adjDefRank": 62,
      "adjEM": 15.29,
      "adjOff": 112.2,
      "adjOffRank": 41,
      "adjTempo": 64.8,
      "adjTempoRank": 196,
      "conference": "MWC",
      "rank": 43,
      "record": "22-12"
    },
    "New Mexico St.": {
      "adjDef": 106,
      "adjDefRank": 239,
      "adjEM": 3.31,
      "adjOff": 109.4,
      "adjOffRank": 73,
      "adjTempo": 69.1,
      "adjTempoRank": 40,
      "conference": "WAC",
      "rank": 125,
      "record": "17-15"
    },
    "New Orleans": {
      "adjDef": 103.1,
      "adjDefRank": 175,
      "adjEM": -11.32,
      "adjOff": 91.8,
      "adjOffRank": 317,
      "adjTempo": 65.4,
      "adjTempoRank": 163,
      "conference": "SB",
      "rank": 282,
      "record": "11-19"
    },
    "Niagara": {
      "adjDef": 98.6,
      "adjDefRank": 81,
      "adjEM": 10.62,
      "adjOff": 109.2,
      "adjOffRank": 75,
      "adjTempo": 68.4,
      "adjTempoRank": 58,
      "conference": "MAAC",
      "rank": 75,
      "record": "26-9"
    },
    "Nicholls St.": {
      "adjDef": 102.3,
      "adjDefRank": 162,
      "adjEM": -0.46,
      "adjOff": 101.9,
      "adjOffRank": 192,
      "adjTempo": 59.1,
      "adjTempoRank": 332,
      "conference": "Slnd",
      "rank": 167,
      "record": "20-11"
    },
    "Norfolk St.": {
      "adjDef": 111.4,
      "adjDefRank": 315,
      "adjEM": -13.91,
      "adjOff": 97.5,
      "adjOffRank": 256,
      "adjTempo": 70,
      "adjTempoRank": 24,
      "conference": "MEAC",
      "rank": 304,
      "record": "14-18"
    },
    "North Carolina": {
      "adjDef": 92.1,
      "adjDefRank": 18,
      "adjEM": 31.14,
      "adjOff": 123.2,
      "adjOffRank": 1,
      "adjTempo": 72.9,
      "adjTempoRank": 6,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 1,
      "record": "34-4",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 105,
      "adjDefRank": 214,
      "adjEM": -13.45,
      "adjOff": 91.5,
      "adjOffRank": 318,
      "adjTempo": 69.7,
      "adjTempoRank": 27,
      "conference": "MEAC",
      "rank": 299,
      "record": "16-16"
    },
    "North Carolina Central": {
      "adjDef": 114.5,
      "adjDefRank": 338,
      "adjEM": -28.86,
      "adjOff": 85.6,
      "adjOffRank": 342,
      "adjTempo": 69,
      "adjTempoRank": 43,
      "conference": "ind",
      "rank": 343,
      "record": "4-27"
    },
    "North Dakota St.": {
      "adjDef": 102,
      "adjDefRank": 152,
      "adjEM": 12.29,
      "adjOff": 114.3,
      "adjOffRank": 25,
      "adjTempo": 67.5,
      "adjTempoRank": 79,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 61,
      "record": "26-7",
      "seed": 14
    },
    "North Florida": {
      "adjDef": 109.4,
      "adjDefRank": 294,
      "adjEM": -20.03,
      "adjOff": 89.3,
      "adjOffRank": 334,
      "adjTempo": 62.1,
      "adjTempoRank": 298,
      "conference": "ASun",
      "rank": 332,
      "record": "8-22"
    },
    "North Texas": {
      "adjDef": 108,
      "adjDefRank": 277,
      "adjEM": 0.95,
      "adjOff": 109,
      "adjOffRank": 79,
      "adjTempo": 68.1,
      "adjTempoRank": 65,
      "conference": "SB",
      "rank": 152,
      "record": "20-12"
    },
    "Northeastern": {
      "adjDef": 99.6,
      "adjDefRank": 99,
      "adjEM": 6.84,
      "adjOff": 106.4,
      "adjOffRank": 107,
      "adjTempo": 59.7,
      "adjTempoRank": 330,
      "conference": "CAA",
      "rank": 97,
      "record": "19-13"
    },
    "Northern Arizona": {
      "adjDef": 108.3,
      "adjDefRank": 281,
      "adjEM": -6.47,
      "adjOff": 101.8,
      "adjOffRank": 195,
      "adjTempo": 62.2,
      "adjTempoRank": 297,
      "conference": "BSky",
      "rank": 239,
      "record": "8-19"
    },
    "Northern Colorado": {
      "adjDef": 106.7,
      "adjDefRank": 253,
      "adjEM": -3.34,
      "adjOff": 103.3,
      "adjOffRank": 155,
      "adjTempo": 66.5,
      "adjTempoRank": 118,
      "conference": "BSky",
      "rank": 202,
      "record": "14-18"
    },
    "Northern Illinois": {
      "adjDef": 106.9,
      "adjDefRank": 259,
      "adjEM": -12.27,
      "adjOff": 94.6,
      "adjOffRank": 290,
      "adjTempo": 68.9,
      "adjTempoRank": 46,
      "conference": "MAC",
      "rank": 289,
      "record": "10-20"
    },
    "Northern Iowa": {
      "adjDef": 100.9,
      "adjDefRank": 131,
      "adjEM": 9.7,
      "adjOff": 110.6,
      "adjOffRank": 59,
      "adjTempo": 60.8,
      "adjTempoRank": 318,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 83,
      "record": "23-11",
      "seed": 12
    },
    "Northwestern": {
      "adjDef": 99.7,
      "adjDefRank": 102,
      "adjEM": 12.01,
      "adjOff": 111.7,
      "adjOffRank": 45,
      "adjTempo": 60.2,
      "adjTempoRank": 324,
      "conference": "B10",
      "rank": 65,
      "record": "17-14"
    },
    "Northwestern St.": {
      "adjDef": 108,
      "adjDefRank": 275,
      "adjEM": -16.18,
      "adjOff": 91.8,
      "adjOffRank": 316,
      "adjTempo": 74.8,
      "adjTempoRank": 2,
      "conference": "Slnd",
      "rank": 315,
      "record": "11-20"
    },
    "Notre Dame": {
      "adjDef": 99.6,
      "adjDefRank": 100,
      "adjEM": 15.38,
      "adjOff": 115,
      "adjOffRank": 22,
      "adjTempo": 67.2,
      "adjTempoRank": 94,
      "conference": "BE",
      "rank": 41,
      "record": "21-15"
    },
    "Oakland": {
      "adjDef": 107.8,
      "adjDefRank": 271,
      "adjEM": 6.36,
      "adjOff": 114.2,
      "adjOffRank": 27,
      "adjTempo": 64.8,
      "adjTempoRank": 192,
      "conference": "Sum",
      "rank": 102,
      "record": "23-13"
    },
    "Ohio": {
      "adjDef": 102.4,
      "adjDefRank": 165,
      "adjEM": -0.58,
      "adjOff": 101.8,
      "adjOffRank": 194,
      "adjTempo": 65.1,
      "adjTempoRank": 181,
      "conference": "MAC",
      "rank": 170,
      "record": "15-17"
    },
    "Ohio St.": {
      "adjDef": 96.9,
      "adjDefRank": 63,
      "adjEM": 16.34,
      "adjOff": 113.3,
      "adjOffRank": 32,
      "adjTempo": 62.5,
      "adjTempoRank": 284,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 34,
      "record": "22-11",
      "seed": 8
    },
    "Oklahoma": {
      "adjDef": 93.7,
      "adjDefRank": 33,
      "adjEM": 24.59,
      "adjOff": 118.3,
      "adjOffRank": 4,
      "adjTempo": 66.6,
      "adjTempoRank": 109,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 7,
      "record": "30-6",
      "seed": 2
    },
    "Oklahoma St.": {
      "adjDef": 98.1,
      "adjDefRank": 73,
      "adjEM": 18.33,
      "adjOff": 116.4,
      "adjOffRank": 15,
      "adjTempo": 69.3,
      "adjTempoRank": 36,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 26,
      "record": "23-12",
      "seed": 8
    },
    "Old Dominion": {
      "adjDef": 98,
      "adjDefRank": 72,
      "adjEM": 7.02,
      "adjOff": 105,
      "adjOffRank": 132,
      "adjTempo": 62.2,
      "adjTempoRank": 295,
      "conference": "CAA",
      "rank": 93,
      "record": "25-10"
    },
    "Oral Roberts": {
      "adjDef": 95.2,
      "adjDefRank": 41,
      "adjEM": 7.85,
      "adjOff": 103,
      "adjOffRank": 162,
      "adjTempo": 67.6,
      "adjTempoRank": 77,
      "conference": "Sum",
      "rank": 90,
      "record": "16-15"
    },
    "Oregon": {
      "adjDef": 103.8,
      "adjDefRank": 188,
      "adjEM": 0.43,
      "adjOff": 104.2,
      "adjOffRank": 143,
      "adjTempo": 68.2,
      "adjTempoRank": 63,
      "conference": "P10",
      "rank": 159,
      "record": "8-23"
    },
    "Oregon St.": {
      "adjDef": 101.2,
      "adjDefRank": 135,
      "adjEM": 4.91,
      "adjOff": 106.1,
      "adjOffRank": 111,
      "adjTempo": 57.4,
      "adjTempoRank": 343,
      "conference": "P10",
      "rank": 111,
      "record": "18-18"
    },
    "Pacific": {
      "adjDef": 103.9,
      "adjDefRank": 189,
      "adjEM": 4.92,
      "adjOff": 108.8,
      "adjOffRank": 80,
      "adjTempo": 58.5,
      "adjTempoRank": 335,
      "conference": "BW",
      "rank": 110,
      "record": "21-13"
    },
    "Penn": {
      "adjDef": 105.8,
      "adjDefRank": 235,
      "adjEM": -9.7,
      "adjOff": 96.1,
      "adjOffRank": 274,
      "adjTempo": 67.2,
      "adjTempoRank": 88,
      "conference": "Ivy",
      "rank": 273,
      "record": "10-18"
    },
    "Penn St.": {
      "adjDef": 98.9,
      "adjDefRank": 87,
      "adjEM": 12.91,
      "adjOff": 111.8,
      "adjOffRank": 44,
      "adjTempo": 61.8,
      "adjTempoRank": 304,
      "conference": "B10",
      "rank": 57,
      "record": "27-11"
    },
    "Pepperdine": {
      "adjDef": 105.7,
      "adjDefRank": 232,
      "adjEM": -13.04,
      "adjOff": 92.7,
      "adjOffRank": 310,
      "adjTempo": 66.5,
      "adjTempoRank": 113,
      "conference": "WCC",
      "rank": 294,
      "record": "9-23"
    },
    "Pittsburgh": {
      "adjDef": 92.3,
      "adjDefRank": 19,
      "adjEM": 28.96,
      "adjOff": 121.2,
      "adjOffRank": 2,
      "adjTempo": 65.2,
      "adjTempoRank": 174,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "31-5",
      "seed": 1
    },
    "Portland": {
      "adjDef": 102.3,
      "adjDefRank": 160,
      "adjEM": 5.01,
      "adjOff": 107.3,
      "adjOffRank": 97,
      "adjTempo": 64.6,
      "adjTempoRank": 209,
      "conference": "WCC",
      "rank": 109,
      "record": "19-13"
    },
    "Portland St.": {
      "adjDef": 105.1,
      "adjDefRank": 219,
      "adjEM": 4.04,
      "adjOff": 109.2,
      "adjOffRank": 77,
      "adjTempo": 64.8,
      "adjTempoRank": 191,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 118,
      "record": "23-10",
      "seed": 13
    },
    "Prairie View A&M": {
      "adjDef": 106.1,
      "adjDefRank": 241,
      "adjEM": -11.72,
      "adjOff": 94.4,
      "adjOffRank": 292,
      "adjTempo": 67,
      "adjTempoRank": 100,
      "conference": "SWAC",
      "rank": 286,
      "record": "17-16"
    },
    "Presbyterian": {
      "adjDef": 101.6,
      "adjDefRank": 142,
      "adjEM": -7.84,
      "adjOff": 93.7,
      "adjOffRank": 301,
      "adjTempo": 60.4,
      "adjTempoRank": 323,
      "conference": "BSth",
      "rank": 255,
      "record": "12-17"
    },
    "Princeton": {
      "adjDef": 104.3,
      "adjDefRank": 198,
      "adjEM": -8.72,
      "adjOff": 95.5,
      "adjOffRank": 282,
      "adjTempo": 57.6,
      "adjTempoRank": 342,
      "conference": "Ivy",
      "rank": 264,
      "record": "13-14"
    },
    "Providence": {
      "adjDef": 100.7,
      "adjDefRank": 127,
      "adjEM": 10.26,
      "adjOff": 111,
      "adjOffRank": 54,
      "adjTempo": 72.1,
      "adjTempoRank": 11,
      "conference": "BE",
      "rank": 77,
      "record": "19-14"
    },
    "Purdue": {
      "adjDef": 88.7,
      "adjDefRank": 5,
      "adjEM": 21.84,
      "adjOff": 110.5,
      "adjOffRank": 60,
      "adjTempo": 65.9,
      "adjTempoRank": 144,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "27-10",
      "seed": 5
    },
    "Quinnipiac": {
      "adjDef": 108,
      "adjDefRank": 278,
      "adjEM": -10.81,
      "adjOff": 97.2,
      "adjOffRank": 263,
      "adjTempo": 64.6,
      "adjTempoRank": 206,
      "conference": "NEC",
      "rank": 279,
      "record": "15-16"
    },
    "Radford": {
      "adjDef": 102.1,
      "adjDefRank": 153,
      "adjEM": -0.86,
      "adjOff": 101.2,
      "adjOffRank": 208,
      "adjTempo": 69.2,
      "adjTempoRank": 37,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 176,
      "record": "21-12",
      "seed": 16
    },
    "Rhode Island": {
      "adjDef": 101.5,
      "adjDefRank": 141,
      "adjEM": 12.26,
      "adjOff": 113.7,
      "adjOffRank": 29,
      "adjTempo": 67.7,
      "adjTempoRank": 76,
      "conference": "A10",
      "rank": 62,
      "record": "23-11"
    },
    "Rice": {
      "adjDef": 106.5,
      "adjDefRank": 249,
      "adjEM": -6.1,
      "adjOff": 100.4,
      "adjOffRank": 219,
      "adjTempo": 63.5,
      "adjTempoRank": 258,
      "conference": "CUSA",
      "rank": 235,
      "record": "10-22"
    },
    "Richmond": {
      "adjDef": 102.4,
      "adjDefRank": 163,
      "adjEM": 4.43,
      "adjOff": 106.8,
      "adjOffRank": 103,
      "adjTempo": 63.9,
      "adjTempoRank": 234,
      "conference": "A10",
      "rank": 114,
      "record": "20-16"
    },
    "Rider": {
      "adjDef": 106.3,
      "adjDefRank": 247,
      "adjEM": -1.25,
      "adjOff": 105.1,
      "adjOffRank": 131,
      "adjTempo": 67.8,
      "adjTempoRank": 70,
      "conference": "MAAC",
      "rank": 179,
      "record": "19-13"
    },
    "Robert Morris": {
      "adjDef": 100.6,
      "adjDefRank": 122,
      "adjEM": 1.49,
      "adjOff": 102,
      "adjOffRank": 187,
      "adjTempo": 66,
      "adjTempoRank": 139,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 146,
      "record": "24-11",
      "seed": 15
    },
    "Rutgers": {
      "adjDef": 97.4,
      "adjDefRank": 66,
      "adjEM": 1.13,
      "adjOff": 98.6,
      "adjOffRank": 241,
      "adjTempo": 64.2,
      "adjTempoRank": 220,
      "conference": "BE",
      "rank": 149,
      "record": "11-21"
    },
    "SIU Edwardsville": {
      "adjDef": 104.9,
      "adjDefRank": 213,
      "adjEM": -13.72,
      "adjOff": 91.2,
      "adjOffRank": 323,
      "adjTempo": 69.5,
      "adjTempoRank": 34,
      "conference": "ind",
      "rank": 300,
      "record": "3-12"
    },
    "SMU": {
      "adjDef": 105.1,
      "adjDefRank": 218,
      "adjEM": -3.52,
      "adjOff": 101.6,
      "adjOffRank": 199,
      "adjTempo": 63.6,
      "adjTempoRank": 247,
      "conference": "CUSA",
      "rank": 205,
      "record": "9-21"
    },
    "Sacramento St.": {
      "adjDef": 114.5,
      "adjDefRank": 337,
      "adjEM": -24.88,
      "adjOff": 89.6,
      "adjOffRank": 332,
      "adjTempo": 63.6,
      "adjTempoRank": 245,
      "conference": "BSky",
      "rank": 339,
      "record": "2-27"
    },
    "Sacred Heart": {
      "adjDef": 106,
      "adjDefRank": 238,
      "adjEM": -2.13,
      "adjOff": 103.9,
      "adjOffRank": 148,
      "adjTempo": 68,
      "adjTempoRank": 66,
      "conference": "NEC",
      "rank": 186,
      "record": "17-14"
    },
    "Saint Joseph's": {
      "adjDef": 99,
      "adjDefRank": 90,
      "adjEM": 6.71,
      "adjOff": 105.7,
      "adjOffRank": 119,
      "adjTempo": 63.9,
      "adjTempoRank": 231,
      "conference": "A10",
      "rank": 99,
      "record": "17-15"
    },
    "Saint Louis": {
      "adjDef": 100.1,
      "adjDefRank": 113,
      "adjEM": 1.75,
      "adjOff": 101.8,
      "adjOffRank": 193,
      "adjTempo": 60,
      "adjTempoRank": 326,
      "conference": "A10",
      "rank": 143,
      "record": "18-14"
    },
    "Saint Mary's": {
      "adjDef": 97.2,
      "adjDefRank": 65,
      "adjEM": 13.36,
      "adjOff": 110.6,
      "adjOffRank": 58,
      "adjTempo": 66.5,
      "adjTempoRank": 114,
      "conference": "WCC",
      "rank": 56,
      "record": "28-7"
    },
    "Saint Peter's": {
      "adjDef": 103.2,
      "adjDefRank": 176,
      "adjEM": -9.56,
      "adjOff": 93.6,
      "adjOffRank": 303,
      "adjTempo": 62.6,
      "adjTempoRank": 279,
      "conference": "MAAC",
      "rank": 271,
      "record": "11-19"
    },
    "Sam Houston St.": {
      "adjDef": 103.7,
      "adjDefRank": 187,
      "adjEM": 3.64,
      "adjOff": 107.3,
      "adjOffRank": 95,
      "adjTempo": 66.1,
      "adjTempoRank": 137,
      "conference": "Slnd",
      "rank": 123,
      "record": "18-12"
    },
    "Samford": {
      "adjDef": 108.8,
      "adjDefRank": 286,
      "adjEM": -7.34,
      "adjOff": 101.4,
      "adjOffRank": 206,
      "adjTempo": 57.8,
      "adjTempoRank": 338,
      "conference": "SC",
      "rank": 250,
      "record": "16-16"
    },
    "San Diego": {
      "adjDef": 98.3,
      "adjDefRank": 75,
      "adjEM": 0.11,
      "adjOff": 98.4,
      "adjOffRank": 243,
      "adjTempo": 62.1,
      "adjTempoRank": 299,
      "conference": "WCC",
      "rank": 162,
      "record": "16-16"
    },
    "San Diego St.": {
      "adjDef": 93.3,
      "adjDefRank": 30,
      "adjEM": 16.05,
      "adjOff": 109.4,
      "adjOffRank": 71,
      "adjTempo": 61.8,
      "adjTempoRank": 305,
      "conference": "MWC",
      "rank": 36,
      "record": "26-10"
    },
    "San Francisco": {
      "adjDef": 111,
      "adjDefRank": 312,
      "adjEM": -6.93,
      "adjOff": 104.1,
      "adjOffRank": 146,
      "adjTempo": 64.6,
      "adjTempoRank": 208,
      "conference": "WCC",
      "rank": 244,
      "record": "11-19"
    },
    "San Jose St.": {
      "adjDef": 104.2,
      "adjDefRank": 197,
      "adjEM": -2.64,
      "adjOff": 101.6,
      "adjOffRank": 196,
      "adjTempo": 66.2,
      "adjTempoRank": 133,
      "conference": "WAC",
      "rank": 192,
      "record": "13-17"
    },
    "Santa Clara": {
      "adjDef": 99.3,
      "adjDefRank": 94,
      "adjEM": 2.25,
      "adjOff": 101.6,
      "adjOffRank": 197,
      "adjTempo": 63.7,
      "adjTempoRank": 241,
      "conference": "WCC",
      "rank": 137,
      "record": "16-17"
    },
    "Savannah St.": {
      "adjDef": 101.8,
      "adjDefRank": 148,
      "adjEM": -15.76,
      "adjOff": 86,
      "adjOffRank": 340,
      "adjTempo": 63.4,
      "adjTempoRank": 260,
      "conference": "ind",
      "rank": 312,
      "record": "15-14"
    },
    "Seton Hall": {
      "adjDef": 100.4,
      "adjDefRank": 119,
      "adjEM": 10.3,
      "adjOff": 110.7,
      "adjOffRank": 57,
      "adjTempo": 67.7,
      "adjTempoRank": 75,
      "conference": "BE",
      "rank": 76,
      "record": "17-15"
    },
    "Siena": {
      "adjDef": 98.4,
      "adjDefRank": 78,
      "adjEM": 11.93,
      "adjOff": 110.3,
      "adjOffRank": 63,
      "adjTempo": 70.7,
      "adjTempoRank": 16,
      "conference": "MAAC",
      "finish": "Round of 32",
      "rank": 67,
      "record": "27-8",
      "seed": 9
    },
    "South Alabama": {
      "adjDef": 104.1,
      "adjDefRank": 194,
      "adjEM": -1.28,
      "adjOff": 102.8,
      "adjOffRank": 165,
      "adjTempo": 63.2,
      "adjTempoRank": 264,
      "conference": "SB",
      "rank": 180,
      "record": "20-13"
    },
    "South Carolina": {
      "adjDef": 95.9,
      "adjDefRank": 49,
      "adjEM": 11.44,
      "adjOff": 107.3,
      "adjOffRank": 96,
      "adjTempo": 72.6,
      "adjTempoRank": 8,
      "conference": "SEC",
      "rank": 71,
      "record": "21-10"
    },
    "South Carolina St.": {
      "adjDef": 106.6,
      "adjDefRank": 250,
      "adjEM": -10.24,
      "adjOff": 96.4,
      "adjOffRank": 272,
      "adjTempo": 68.5,
      "adjTempoRank": 56,
      "conference": "MEAC",
      "rank": 276,
      "record": "17-14"
    },
    "South Dakota St.": {
      "adjDef": 108.9,
      "adjDefRank": 289,
      "adjEM": -4,
      "adjOff": 104.9,
      "adjOffRank": 135,
      "adjTempo": 64.6,
      "adjTempoRank": 207,
      "conference": "Sum",
      "rank": 212,
      "record": "13-20"
    },
    "South Florida": {
      "adjDef": 99.2,
      "adjDefRank": 92,
      "adjEM": 2.3,
      "adjOff": 101.5,
      "adjOffRank": 202,
      "adjTempo": 60.9,
      "adjTempoRank": 317,
      "conference": "BE",
      "rank": 136,
      "record": "9-22"
    },
    "Southeast Missouri St.": {
      "adjDef": 116.8,
      "adjDefRank": 341,
      "adjEM": -21.81,
      "adjOff": 95,
      "adjOffRank": 288,
      "adjTempo": 69.7,
      "adjTempoRank": 28,
      "conference": "OVC",
      "rank": 336,
      "record": "3-27"
    },
    "Southeastern Louisiana": {
      "adjDef": 103.5,
      "adjDefRank": 181,
      "adjEM": -2.97,
      "adjOff": 100.5,
      "adjOffRank": 216,
      "adjTempo": 65.1,
      "adjTempoRank": 180,
      "conference": "Slnd",
      "rank": 199,
      "record": "13-17"
    },
    "Southern": {
      "adjDef": 111.2,
      "adjDefRank": 313,
      "adjEM": -19.91,
      "adjOff": 91.2,
      "adjOffRank": 322,
      "adjTempo": 64.8,
      "adjTempoRank": 197,
      "conference": "SWAC",
      "rank": 331,
      "record": "8-23"
    },
    "Southern Illinois": {
      "adjDef": 101.4,
      "adjDefRank": 140,
      "adjEM": 0.81,
      "adjOff": 102.2,
      "adjOffRank": 184,
      "adjTempo": 63.7,
      "adjTempoRank": 244,
      "conference": "MVC",
      "rank": 156,
      "record": "13-18"
    },
    "Southern Miss": {
      "adjDef": 105.1,
      "adjDefRank": 220,
      "adjEM": 1.58,
      "adjOff": 106.7,
      "adjOffRank": 105,
      "adjTempo": 62.4,
      "adjTempoRank": 288,
      "conference": "CUSA",
      "rank": 144,
      "record": "15-17"
    },
    "Southern Utah": {
      "adjDef": 107.5,
      "adjDefRank": 266,
      "adjEM": -5.39,
      "adjOff": 102.1,
      "adjOffRank": 186,
      "adjTempo": 62.7,
      "adjTempoRank": 276,
      "conference": "Sum",
      "rank": 227,
      "record": "11-20"
    },
    "St. Bonaventure": {
      "adjDef": 100.8,
      "adjDefRank": 130,
      "adjEM": -2.22,
      "adjOff": 98.6,
      "adjOffRank": 240,
      "adjTempo": 70.1,
      "adjTempoRank": 22,
      "conference": "A10",
      "rank": 188,
      "record": "15-15"
    },
    "St. Francis NY": {
      "adjDef": 109.7,
      "adjDefRank": 298,
      "adjEM": -13.74,
      "adjOff": 96,
      "adjOffRank": 278,
      "adjTempo": 66.2,
      "adjTempoRank": 131,
      "conference": "NEC",
      "rank": 301,
      "record": "10-20"
    },
    "St. Francis PA": {
      "adjDef": 113.7,
      "adjDefRank": 334,
      "adjEM": -19.29,
      "adjOff": 94.4,
      "adjOffRank": 293,
      "adjTempo": 62.3,
      "adjTempoRank": 292,
      "conference": "NEC",
      "rank": 328,
      "record": "6-23"
    },
    "St. John's": {
      "adjDef": 98.8,
      "adjDefRank": 86,
      "adjEM": 3.88,
      "adjOff": 102.7,
      "adjOffRank": 169,
      "adjTempo": 65.4,
      "adjTempoRank": 165,
      "conference": "BE",
      "rank": 121,
      "record": "16-18"
    },
    "Stanford": {
      "adjDef": 98.3,
      "adjDefRank": 77,
      "adjEM": 14.13,
      "adjOff": 112.5,
      "adjOffRank": 39,
      "adjTempo": 67.5,
      "adjTempoRank": 80,
      "conference": "P10",
      "rank": 52,
      "record": "20-14"
    },
    "Stephen F. Austin": {
      "adjDef": 90.4,
      "adjDefRank": 9,
      "adjEM": 6.52,
      "adjOff": 97,
      "adjOffRank": 268,
      "adjTempo": 62.4,
      "adjTempoRank": 289,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 101,
      "record": "24-8",
      "seed": 14
    },
    "Stetson": {
      "adjDef": 104,
      "adjDefRank": 193,
      "adjEM": -7.87,
      "adjOff": 96.2,
      "adjOffRank": 273,
      "adjTempo": 67.2,
      "adjTempoRank": 92,
      "conference": "ASun",
      "rank": 257,
      "record": "13-17"
    },
    "Stony Brook": {
      "adjDef": 100.6,
      "adjDefRank": 124,
      "adjEM": -2.36,
      "adjOff": 98.2,
      "adjOffRank": 245,
      "adjTempo": 62.8,
      "adjTempoRank": 273,
      "conference": "AE",
      "rank": 190,
      "record": "16-14"
    },
    "Syracuse": {
      "adjDef": 94.8,
      "adjDefRank": 39,
      "adjEM": 22.21,
      "adjOff": 117,
      "adjOffRank": 10,
      "adjTempo": 69.7,
      "adjTempoRank": 29,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 16,
      "record": "28-10",
      "seed": 3
    },
    "TCU": {
      "adjDef": 100.3,
      "adjDefRank": 117,
      "adjEM": 3.23,
      "adjOff": 103.5,
      "adjOffRank": 152,
      "adjTempo": 62.8,
      "adjTempoRank": 272,
      "conference": "MWC",
      "rank": 126,
      "record": "14-17"
    },
    "Temple": {
      "adjDef": 95.5,
      "adjDefRank": 43,
      "adjEM": 14.38,
      "adjOff": 109.9,
      "adjOffRank": 68,
      "adjTempo": 63.5,
      "adjTempoRank": 255,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 50,
      "record": "22-12",
      "seed": 11
    },
    "Tennessee": {
      "adjDef": 98.7,
      "adjDefRank": 83,
      "adjEM": 16.48,
      "adjOff": 115.1,
      "adjOffRank": 20,
      "adjTempo": 67.7,
      "adjTempoRank": 72,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 32,
      "record": "21-13",
      "seed": 9
    },
    "Tennessee Martin": {
      "adjDef": 104.3,
      "adjDefRank": 200,
      "adjEM": 4.2,
      "adjOff": 108.5,
      "adjOffRank": 86,
      "adjTempo": 68.3,
      "adjTempoRank": 61,
      "conference": "OVC",
      "rank": 117,
      "record": "22-10"
    },
    "Tennessee St.": {
      "adjDef": 111.4,
      "adjDefRank": 316,
      "adjEM": -9.86,
      "adjOff": 101.6,
      "adjOffRank": 198,
      "adjTempo": 68.6,
      "adjTempoRank": 53,
      "conference": "OVC",
      "rank": 274,
      "record": "12-18"
    },
    "Tennessee Tech": {
      "adjDef": 104.4,
      "adjDefRank": 207,
      "adjEM": -6.35,
      "adjOff": 98.1,
      "adjOffRank": 246,
      "adjTempo": 71.4,
      "adjTempoRank": 13,
      "conference": "OVC",
      "rank": 238,
      "record": "12-18"
    },
    "Texas": {
      "adjDef": 92.5,
      "adjDefRank": 20,
      "adjEM": 19.65,
      "adjOff": 112.2,
      "adjOffRank": 42,
      "adjTempo": 65.4,
      "adjTempoRank": 160,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 22,
      "record": "23-12",
      "seed": 7
    },
    "Texas A&M": {
      "adjDef": 98.1,
      "adjDefRank": 74,
      "adjEM": 15.01,
      "adjOff": 113.1,
      "adjOffRank": 35,
      "adjTempo": 63.9,
      "adjTempoRank": 235,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 45,
      "record": "24-10",
      "seed": 9
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 99.8,
      "adjDefRank": 105,
      "adjEM": 0.2,
      "adjOff": 100,
      "adjOffRank": 223,
      "adjTempo": 66.4,
      "adjTempoRank": 121,
      "conference": "Slnd",
      "rank": 161,
      "record": "18-15"
    },
    "Texas Pan American": {
      "adjDef": 112.3,
      "adjDefRank": 325,
      "adjEM": -18.68,
      "adjOff": 93.7,
      "adjOffRank": 302,
      "adjTempo": 62.6,
      "adjTempoRank": 280,
      "conference": "ind",
      "rank": 322,
      "record": "10-17"
    },
    "Texas Southern": {
      "adjDef": 111.9,
      "adjDefRank": 320,
      "adjEM": -18.64,
      "adjOff": 93.2,
      "adjOffRank": 307,
      "adjTempo": 66.8,
      "adjTempoRank": 105,
      "conference": "SWAC",
      "rank": 320,
      "record": "7-25"
    },
    "Texas St.": {
      "adjDef": 106.3,
      "adjDefRank": 245,
      "adjEM": -3.89,
      "adjOff": 102.4,
      "adjOffRank": 178,
      "adjTempo": 73.9,
      "adjTempoRank": 4,
      "conference": "Slnd",
      "rank": 208,
      "record": "14-16"
    },
    "Texas Tech": {
      "adjDef": 102.3,
      "adjDefRank": 161,
      "adjEM": 7.01,
      "adjOff": 109.3,
      "adjOffRank": 74,
      "adjTempo": 71.4,
      "adjTempoRank": 14,
      "conference": "B12",
      "rank": 94,
      "record": "14-19"
    },
    "The Citadel": {
      "adjDef": 105.3,
      "adjDefRank": 223,
      "adjEM": -2.72,
      "adjOff": 102.6,
      "adjOffRank": 173,
      "adjTempo": 61.7,
      "adjTempoRank": 307,
      "conference": "SC",
      "rank": 194,
      "record": "20-13"
    },
    "Toledo": {
      "adjDef": 105.9,
      "adjDefRank": 236,
      "adjEM": -13.31,
      "adjOff": 92.6,
      "adjOffRank": 311,
      "adjTempo": 64.9,
      "adjTempoRank": 188,
      "conference": "MAC",
      "rank": 298,
      "record": "7-25"
    },
    "Towson": {
      "adjDef": 109.2,
      "adjDefRank": 293,
      "adjEM": -7.99,
      "adjOff": 101.2,
      "adjOffRank": 209,
      "adjTempo": 65.7,
      "adjTempoRank": 154,
      "conference": "CAA",
      "rank": 258,
      "record": "12-22"
    },
    "Troy": {
      "adjDef": 110.2,
      "adjDefRank": 303,
      "adjEM": -1.42,
      "adjOff": 108.8,
      "adjOffRank": 81,
      "adjTempo": 69.9,
      "adjTempoRank": 25,
      "conference": "SB",
      "rank": 183,
      "record": "19-13"
    },
    "Tulane": {
      "adjDef": 99.4,
      "adjDefRank": 95,
      "adjEM": -0.78,
      "adjOff": 98.6,
      "adjOffRank": 238,
      "adjTempo": 64.6,
      "adjTempoRank": 203,
      "conference": "CUSA",
      "rank": 174,
      "record": "14-17"
    },
    "Tulsa": {
      "adjDef": 93.4,
      "adjDefRank": 31,
      "adjEM": 12.75,
      "adjOff": 106.2,
      "adjOffRank": 110,
      "adjTempo": 64.1,
      "adjTempoRank": 226,
      "conference": "CUSA",
      "rank": 58,
      "record": "25-11"
    },
    "UAB": {
      "adjDef": 95.7,
      "adjDefRank": 47,
      "adjEM": 13.73,
      "adjOff": 109.4,
      "adjOffRank": 70,
      "adjTempo": 66.4,
      "adjTempoRank": 122,
      "conference": "CUSA",
      "rank": 53,
      "record": "22-12"
    },
    "UC Davis": {
      "adjDef": 110,
      "adjDefRank": 300,
      "adjEM": -4.81,
      "adjOff": 105.2,
      "adjOffRank": 125,
      "adjTempo": 69.4,
      "adjTempoRank": 35,
      "conference": "BW",
      "rank": 220,
      "record": "13-19"
    },
    "UC Irvine": {
      "adjDef": 108.5,
      "adjDefRank": 284,
      "adjEM": -4.39,
      "adjOff": 104.1,
      "adjOffRank": 145,
      "adjTempo": 62.7,
      "adjTempoRank": 278,
      "conference": "BW",
      "rank": 217,
      "record": "12-19"
    },
    "UC Riverside": {
      "adjDef": 102.2,
      "adjDefRank": 156,
      "adjEM": -4.68,
      "adjOff": 97.5,
      "adjOffRank": 258,
      "adjTempo": 59.9,
      "adjTempoRank": 328,
      "conference": "BW",
      "rank": 219,
      "record": "17-13"
    },
    "UC Santa Barbara": {
      "adjDef": 99.9,
      "adjDefRank": 107,
      "adjEM": -0.09,
      "adjOff": 99.8,
      "adjOffRank": 226,
      "adjTempo": 65,
      "adjTempoRank": 185,
      "conference": "BW",
      "rank": 165,
      "record": "16-15"
    },
    "UCF": {
      "adjDef": 103.6,
      "adjDefRank": 183,
      "adjEM": 2.32,
      "adjOff": 105.9,
      "adjOffRank": 114,
      "adjTempo": 65.9,
      "adjTempoRank": 143,
      "conference": "CUSA",
      "rank": 135,
      "record": "17-14"
    },
    "UCLA": {
      "adjDef": 95.6,
      "adjDefRank": 44,
      "adjEM": 23.93,
      "adjOff": 119.5,
      "adjOffRank": 3,
      "adjTempo": 65.3,
      "adjTempoRank": 171,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 12,
      "record": "26-9",
      "seed": 6
    },
    "UMBC": {
      "adjDef": 110.5,
      "adjDefRank": 306,
      "adjEM": -8.56,
      "adjOff": 101.9,
      "adjOffRank": 191,
      "adjTempo": 63.6,
      "adjTempoRank": 248,
      "conference": "AE",
      "rank": 262,
      "record": "15-17"
    },
    "UMKC": {
      "adjDef": 110.4,
      "adjDefRank": 304,
      "adjEM": -10.9,
      "adjOff": 99.4,
      "adjOffRank": 229,
      "adjTempo": 65.5,
      "adjTempoRank": 157,
      "conference": "Sum",
      "rank": 281,
      "record": "7-24"
    },
    "UNC Asheville": {
      "adjDef": 110.7,
      "adjDefRank": 309,
      "adjEM": -8.64,
      "adjOff": 102.1,
      "adjOffRank": 185,
      "adjTempo": 67.7,
      "adjTempoRank": 71,
      "conference": "BSth",
      "rank": 263,
      "record": "15-16"
    },
    "UNC Greensboro": {
      "adjDef": 112.3,
      "adjDefRank": 324,
      "adjEM": -18.85,
      "adjOff": 93.5,
      "adjOffRank": 304,
      "adjTempo": 64.9,
      "adjTempoRank": 189,
      "conference": "SC",
      "rank": 324,
      "record": "5-25"
    },
    "UNC Wilmington": {
      "adjDef": 113.7,
      "adjDefRank": 335,
      "adjEM": -14.59,
      "adjOff": 99.1,
      "adjOffRank": 232,
      "adjTempo": 70.6,
      "adjTempoRank": 17,
      "conference": "CAA",
      "rank": 305,
      "record": "7-25"
    },
    "UNLV": {
      "adjDef": 95.6,
      "adjDefRank": 45,
      "adjEM": 11.29,
      "adjOff": 106.9,
      "adjOffRank": 101,
      "adjTempo": 65.1,
      "adjTempoRank": 176,
      "conference": "MWC",
      "rank": 73,
      "record": "21-11"
    },
    "USC": {
      "adjDef": 91.8,
      "adjDefRank": 16,
      "adjEM": 18.26,
      "adjOff": 110,
      "adjOffRank": 65,
      "adjTempo": 63.7,
      "adjTempoRank": 242,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 27,
      "record": "22-13",
      "seed": 10
    },
    "USC Upstate": {
      "adjDef": 104.8,
      "adjDefRank": 211,
      "adjEM": -11.44,
      "adjOff": 93.4,
      "adjOffRank": 306,
      "adjTempo": 62.6,
      "adjTempoRank": 282,
      "conference": "ASun",
      "rank": 283,
      "record": "9-21"
    },
    "UT Arlington": {
      "adjDef": 107.4,
      "adjDefRank": 264,
      "adjEM": 2.05,
      "adjOff": 109.4,
      "adjOffRank": 69,
      "adjTempo": 69,
      "adjTempoRank": 44,
      "conference": "Slnd",
      "rank": 139,
      "record": "16-14"
    },
    "UTEP": {
      "adjDef": 98.4,
      "adjDefRank": 79,
      "adjEM": 9.84,
      "adjOff": 108.2,
      "adjOffRank": 89,
      "adjTempo": 70.5,
      "adjTempoRank": 18,
      "conference": "CUSA",
      "rank": 82,
      "record": "23-14"
    },
    "UTSA": {
      "adjDef": 104.3,
      "adjDefRank": 202,
      "adjEM": -2.26,
      "adjOff": 102,
      "adjOffRank": 188,
      "adjTempo": 68.9,
      "adjTempoRank": 45,
      "conference": "Slnd",
      "rank": 189,
      "record": "19-13"
    },
    "Utah": {
      "adjDef": 92.9,
      "adjDefRank": 23,
      "adjEM": 17.8,
      "adjOff": 110.8,
      "adjOffRank": 56,
      "adjTempo": 65,
      "adjTempoRank": 184,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 28,
      "record": "24-10",
      "seed": 5
    },
    "Utah St.": {
      "adjDef": 101.6,
      "adjDefRank": 143,
      "adjEM": 15.12,
      "adjOff": 116.7,
      "adjOffRank": 12,
      "adjTempo": 60,
      "adjTempoRank": 327,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 44,
      "record": "30-5",
      "seed": 11
    },
    "Utah Valley": {
      "adjDef": 106.2,
      "adjDefRank": 242,
      "adjEM": -2.01,
      "adjOff": 104.1,
      "adjOffRank": 144,
      "adjTempo": 60.5,
      "adjTempoRank": 321,
      "conference": "ind",
      "rank": 185,
      "record": "17-11"
    },
    "VCU": {
      "adjDef": 96.4,
      "adjDefRank": 57,
      "adjEM": 13.62,
      "adjOff": 110.1,
      "adjOffRank": 64,
      "adjTempo": 65.8,
      "adjTempoRank": 150,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 54,
      "record": "24-10",
      "seed": 11
    },
    "VMI": {
      "adjDef": 108.9,
      "adjDefRank": 290,
      "adjEM": -0.44,
      "adjOff": 108.5,
      "adjOffRank": 87,
      "adjTempo": 80.1,
      "adjTempoRank": 1,
      "conference": "BSth",
      "rank": 166,
      "record": "24-8"
    },
    "Valparaiso": {
      "adjDef": 105,
      "adjDefRank": 215,
      "adjEM": -5.27,
      "adjOff": 99.7,
      "adjOffRank": 228,
      "adjTempo": 63.2,
      "adjTempoRank": 263,
      "conference": "Horz",
      "rank": 226,
      "record": "9-22"
    },
    "Vanderbilt": {
      "adjDef": 97.6,
      "adjDefRank": 68,
      "adjEM": 10.01,
      "adjOff": 107.6,
      "adjOffRank": 93,
      "adjTempo": 65.3,
      "adjTempoRank": 173,
      "conference": "SEC",
      "rank": 80,
      "record": "19-12"
    },
    "Vermont": {
      "adjDef": 99.6,
      "adjDefRank": 101,
      "adjEM": 9.1,
      "adjOff": 108.7,
      "adjOffRank": 82,
      "adjTempo": 69,
      "adjTempoRank": 42,
      "conference": "AE",
      "rank": 84,
      "record": "24-9"
    },
    "Villanova": {
      "adjDef": 90.5,
      "adjDefRank": 10,
      "adjEM": 23.89,
      "adjOff": 114.4,
      "adjOffRank": 24,
      "adjTempo": 68.3,
      "adjTempoRank": 62,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 13,
      "record": "30-8",
      "seed": 3
    },
    "Virginia": {
      "adjDef": 97.8,
      "adjDefRank": 70,
      "adjEM": 5.77,
      "adjOff": 103.5,
      "adjOffRank": 151,
      "adjTempo": 67.2,
      "adjTempoRank": 89,
      "conference": "ACC",
      "rank": 104,
      "record": "10-18"
    },
    "Virginia Tech": {
      "adjDef": 99.5,
      "adjDefRank": 97,
      "adjEM": 11.37,
      "adjOff": 110.9,
      "adjOffRank": 55,
      "adjTempo": 65.4,
      "adjTempoRank": 168,
      "conference": "ACC",
      "rank": 72,
      "record": "19-15"
    },
    "Wagner": {
      "adjDef": 108,
      "adjDefRank": 276,
      "adjEM": -10.42,
      "adjOff": 97.6,
      "adjOffRank": 255,
      "adjTempo": 66.5,
      "adjTempoRank": 119,
      "conference": "NEC",
      "rank": 277,
      "record": "16-14"
    },
    "Wake Forest": {
      "adjDef": 92.7,
      "adjDefRank": 22,
      "adjEM": 18.88,
      "adjOff": 111.6,
      "adjOffRank": 46,
      "adjTempo": 72.8,
      "adjTempoRank": 7,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 24,
      "record": "24-7",
      "seed": 4
    },
    "Washington": {
      "adjDef": 91,
      "adjDefRank": 14,
      "adjEM": 22.09,
      "adjOff": 113.1,
      "adjOffRank": 34,
      "adjTempo": 72.2,
      "adjTempoRank": 10,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 17,
      "record": "26-9",
      "seed": 4
    },
    "Washington St.": {
      "adjDef": 90.4,
      "adjDefRank": 8,
      "adjEM": 14.58,
      "adjOff": 105,
      "adjOffRank": 133,
      "adjTempo": 57.6,
      "adjTempoRank": 340,
      "conference": "P10",
      "rank": 49,
      "record": "17-16"
    },
    "Weber St.": {
      "adjDef": 99,
      "adjDefRank": 89,
      "adjEM": 4.2,
      "adjOff": 103.2,
      "adjOffRank": 159,
      "adjTempo": 67,
      "adjTempoRank": 101,
      "conference": "BSky",
      "rank": 116,
      "record": "21-10"
    },
    "West Virginia": {
      "adjDef": 90.9,
      "adjDefRank": 13,
      "adjEM": 24.21,
      "adjOff": 115.1,
      "adjOffRank": 19,
      "adjTempo": 63.7,
      "adjTempoRank": 240,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 11,
      "record": "23-12",
      "seed": 6
    },
    "Western Carolina": {
      "adjDef": 107.3,
      "adjDefRank": 262,
      "adjEM": -6.58,
      "adjOff": 100.7,
      "adjOffRank": 212,
      "adjTempo": 67.7,
      "adjTempoRank": 73,
      "conference": "SC",
      "rank": 241,
      "record": "16-15"
    },
    "Western Illinois": {
      "adjDef": 110.6,
      "adjDefRank": 308,
      "adjEM": -13.2,
      "adjOff": 97.4,
      "adjOffRank": 259,
      "adjTempo": 58.5,
      "adjTempoRank": 336,
      "conference": "Sum",
      "rank": 297,
      "record": "9-20"
    },
    "Western Kentucky": {
      "adjDef": 103,
      "adjDefRank": 173,
      "adjEM": 8.1,
      "adjOff": 111.1,
      "adjOffRank": 52,
      "adjTempo": 65.6,
      "adjTempoRank": 155,
      "conference": "SB",
      "finish": "Round of 32",
      "rank": 88,
      "record": "25-9",
      "seed": 12
    },
    "Western Michigan": {
      "adjDef": 105.6,
      "adjDefRank": 229,
      "adjEM": -7.26,
      "adjOff": 98.3,
      "adjOffRank": 244,
      "adjTempo": 63.6,
      "adjTempoRank": 252,
      "conference": "MAC",
      "rank": 248,
      "record": "10-21"
    },
    "Wichita St.": {
      "adjDef": 100.4,
      "adjDefRank": 120,
      "adjEM": 2.39,
      "adjOff": 102.8,
      "adjOffRank": 164,
      "adjTempo": 62.3,
      "adjTempoRank": 293,
      "conference": "MVC",
      "rank": 134,
      "record": "17-17"
    },
    "William & Mary": {
      "adjDef": 102.5,
      "adjDefRank": 166,
      "adjEM": -6.08,
      "adjOff": 96.5,
      "adjOffRank": 271,
      "adjTempo": 60.9,
      "adjTempoRank": 315,
      "conference": "CAA",
      "rank": 234,
      "record": "10-20"
    },
    "Winston Salem St.": {
      "adjDef": 107.8,
      "adjDefRank": 270,
      "adjEM": -23.92,
      "adjOff": 83.9,
      "adjOffRank": 343,
      "adjTempo": 67.3,
      "adjTempoRank": 86,
      "conference": "ind",
      "rank": 338,
      "record": "8-22"
    },
    "Winthrop": {
      "adjDef": 102.6,
      "adjDefRank": 168,
      "adjEM": -10.61,
      "adjOff": 92,
      "adjOffRank": 315,
      "adjTempo": 61.7,
      "adjTempoRank": 306,
      "conference": "BSth",
      "rank": 278,
      "record": "11-19"
    },
    "Wisconsin": {
      "adjDef": 95.7,
      "adjDefRank": 46,
      "adjEM": 17.62,
      "adjOff": 113.3,
      "adjOffRank": 31,
      "adjTempo": 59.1,
      "adjTempoRank": 333,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 29,
      "record": "20-13",
      "seed": 12
    },
    "Wofford": {
      "adjDef": 111.4,
      "adjDefRank": 317,
      "adjEM": -5.14,
      "adjOff": 106.3,
      "adjOffRank": 109,
      "adjTempo": 61.9,
      "adjTempoRank": 302,
      "conference": "SC",
      "rank": 222,
      "record": "16-14"
    },
    "Wright St.": {
      "adjDef": 93.8,
      "adjDefRank": 34,
      "adjEM": 6.9,
      "adjOff": 100.7,
      "adjOffRank": 213,
      "adjTempo": 61.6,
      "adjTempoRank": 309,
      "conference": "Horz",
      "rank": 96,
      "record": "20-13"
    },
    "Wyoming": {
      "adjDef": 104,
      "adjDefRank": 192,
      "adjEM": 2.98,
      "adjOff": 107,
      "adjOffRank": 99,
      "adjTempo": 69.5,
      "adjTempoRank": 32,
      "conference": "MWC",
      "rank": 130,
      "record": "19-14"
    },
    "Xavier": {
      "adjDef": 90.8,
      "adjDefRank": 12,
      "adjEM": 20.24,
      "adjOff": 111,
      "adjOffRank": 53,
      "adjTempo": 65.8,
      "adjTempoRank": 149,
      "conference": "A10",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "27-8",
      "seed": 4
    },
    "Yale": {
      "adjDef": 102.2,
      "adjDefRank": 158,
      "adjEM": -6.95,
      "adjOff": 95.3,
      "adjOffRank": 285,
      "adjTempo": 65.4,
      "adjTempoRank": 161,
      "conference": "Ivy",
      "rank": 245,
      "record": "13-15"
    },
    "Youngstown St.": {
      "adjDef": 106.7,
      "adjDefRank": 256,
      "adjEM": -5.19,
      "adjOff": 101.5,
      "adjOffRank": 201,
      "adjTempo": 65.7,
      "adjTempoRank": 153,
      "conference": "Horz",
      "rank": 223,
      "record": "11-19"
    }
  },
  "2010": {
    "Air Force": {
      "adjDef": 105.5,
      "adjDefRank": 229,
      "adjEM": -8.64,
      "adjOff": 96.8,
      "adjOffRank": 265,
      "adjTempo": 57.3,
      "adjTempoRank": 346,
      "conference": "MWC",
      "rank": 254,
      "record": "10-21"
    },
    "Akron": {
      "adjDef": 100.1,
      "adjDefRank": 116,
      "adjEM": 5.2,
      "adjOff": 105.3,
      "adjOffRank": 141,
      "adjTempo": 65.1,
      "adjTempoRank": 214,
      "conference": "MAC",
      "rank": 121,
      "record": "24-11"
    },
    "Alabama": {
      "adjDef": 95.3,
      "adjDefRank": 53,
      "adjEM": 12.55,
      "adjOff": 107.9,
      "adjOffRank": 88,
      "adjTempo": 64.2,
      "adjTempoRank": 247,
      "conference": "SEC",
      "rank": 63,
      "record": "17-15"
    },
    "Alabama A&M": {
      "adjDef": 107.2,
      "adjDefRank": 264,
      "adjEM": -19.7,
      "adjOff": 87.5,
      "adjOffRank": 338,
      "adjTempo": 70.2,
      "adjTempoRank": 25,
      "conference": "SWAC",
      "rank": 327,
      "record": "11-16"
    },
    "Alabama St.": {
      "adjDef": 106.2,
      "adjDefRank": 242,
      "adjEM": -12.63,
      "adjOff": 93.5,
      "adjOffRank": 298,
      "adjTempo": 65.2,
      "adjTempoRank": 212,
      "conference": "SWAC",
      "rank": 289,
      "record": "16-15"
    },
    "Albany": {
      "adjDef": 110.3,
      "adjDefRank": 302,
      "adjEM": -13.16,
      "adjOff": 97.2,
      "adjOffRank": 259,
      "adjTempo": 63.6,
      "adjTempoRank": 271,
      "conference": "AE",
      "rank": 295,
      "record": "7-25"
    },
    "Alcorn St.": {
      "adjDef": 113.9,
      "adjDefRank": 341,
      "adjEM": -32.65,
      "adjOff": 81.3,
      "adjOffRank": 346,
      "adjTempo": 72.1,
      "adjTempoRank": 9,
      "conference": "SWAC",
      "rank": 347,
      "record": "2-29"
    },
    "American": {
      "adjDef": 107.2,
      "adjDefRank": 259,
      "adjEM": -13.09,
      "adjOff": 94.1,
      "adjOffRank": 294,
      "adjTempo": 62.4,
      "adjTempoRank": 303,
      "conference": "Pat",
      "rank": 294,
      "record": "11-20"
    },
    "Appalachian St.": {
      "adjDef": 104,
      "adjDefRank": 202,
      "adjEM": 6.18,
      "adjOff": 110.1,
      "adjOffRank": 58,
      "adjTempo": 65.3,
      "adjTempoRank": 207,
      "conference": "SC",
      "rank": 109,
      "record": "24-13"
    },
    "Arizona": {
      "adjDef": 99.5,
      "adjDefRank": 108,
      "adjEM": 8.83,
      "adjOff": 108.4,
      "adjOffRank": 83,
      "adjTempo": 67.6,
      "adjTempoRank": 88,
      "conference": "P10",
      "rank": 87,
      "record": "16-15"
    },
    "Arizona St.": {
      "adjDef": 94.8,
      "adjDefRank": 50,
      "adjEM": 15.18,
      "adjOff": 110,
      "adjOffRank": 61,
      "adjTempo": 61,
      "adjTempoRank": 326,
      "conference": "P10",
      "rank": 52,
      "record": "22-11"
    },
    "Arkansas": {
      "adjDef": 100.6,
      "adjDefRank": 124,
      "adjEM": 5.3,
      "adjOff": 105.9,
      "adjOffRank": 129,
      "adjTempo": 69.7,
      "adjTempoRank": 31,
      "conference": "SEC",
      "rank": 119,
      "record": "14-18"
    },
    "Arkansas Little Rock": {
      "adjDef": 111.6,
      "adjDefRank": 324,
      "adjEM": -11.99,
      "adjOff": 99.6,
      "adjOffRank": 220,
      "adjTempo": 65.5,
      "adjTempoRank": 196,
      "conference": "SB",
      "rank": 281,
      "record": "8-22"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 99.5,
      "adjDefRank": 107,
      "adjEM": -6.44,
      "adjOff": 93.1,
      "adjOffRank": 303,
      "adjTempo": 65.1,
      "adjTempoRank": 215,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 231,
      "record": "18-16",
      "seed": 16
    },
    "Arkansas St.": {
      "adjDef": 102,
      "adjDefRank": 157,
      "adjEM": -2.95,
      "adjOff": 99.1,
      "adjOffRank": 229,
      "adjTempo": 66.5,
      "adjTempoRank": 144,
      "conference": "SB",
      "rank": 202,
      "record": "17-14"
    },
    "Army": {
      "adjDef": 99,
      "adjDefRank": 99,
      "adjEM": -9.68,
      "adjOff": 89.3,
      "adjOffRank": 330,
      "adjTempo": 62.8,
      "adjTempoRank": 291,
      "conference": "Pat",
      "rank": 259,
      "record": "14-15"
    },
    "Auburn": {
      "adjDef": 102.9,
      "adjDefRank": 174,
      "adjEM": 6.98,
      "adjOff": 109.9,
      "adjOffRank": 63,
      "adjTempo": 69,
      "adjTempoRank": 45,
      "conference": "SEC",
      "rank": 103,
      "record": "15-17"
    },
    "Austin Peay": {
      "adjDef": 107.2,
      "adjDefRank": 261,
      "adjEM": -3.52,
      "adjOff": 103.7,
      "adjOffRank": 157,
      "adjTempo": 66,
      "adjTempoRank": 173,
      "conference": "OVC",
      "rank": 207,
      "record": "17-15"
    },
    "BYU": {
      "adjDef": 94,
      "adjDefRank": 37,
      "adjEM": 23.27,
      "adjOff": 117.3,
      "adjOffRank": 10,
      "adjTempo": 71.1,
      "adjTempoRank": 15,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 10,
      "record": "30-6",
      "seed": 7
    },
    "Ball St.": {
      "adjDef": 100.1,
      "adjDefRank": 117,
      "adjEM": -5.45,
      "adjOff": 94.6,
      "adjOffRank": 291,
      "adjTempo": 62,
      "adjTempoRank": 309,
      "conference": "MAC",
      "rank": 222,
      "record": "15-15"
    },
    "Baylor": {
      "adjDef": 94.3,
      "adjDefRank": 43,
      "adjEM": 24.95,
      "adjOff": 119.2,
      "adjOffRank": 5,
      "adjTempo": 64.5,
      "adjTempoRank": 235,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 8,
      "record": "28-8",
      "seed": 3
    },
    "Belmont": {
      "adjDef": 99.6,
      "adjDefRank": 110,
      "adjEM": 3.67,
      "adjOff": 103.3,
      "adjOffRank": 166,
      "adjTempo": 68.8,
      "adjTempoRank": 52,
      "conference": "ASun",
      "rank": 132,
      "record": "19-12"
    },
    "Bethune Cookman": {
      "adjDef": 106.9,
      "adjDefRank": 256,
      "adjEM": -12.98,
      "adjOff": 93.9,
      "adjOffRank": 295,
      "adjTempo": 58.9,
      "adjTempoRank": 341,
      "conference": "MEAC",
      "rank": 292,
      "record": "17-16"
    },
    "Binghamton": {
      "adjDef": 109.2,
      "adjDefRank": 290,
      "adjEM": -11.07,
      "adjOff": 98.1,
      "adjOffRank": 244,
      "adjTempo": 62.5,
      "adjTempoRank": 300,
      "conference": "AE",
      "rank": 272,
      "record": "13-18"
    },
    "Boise St.": {
      "adjDef": 101.3,
      "adjDefRank": 142,
      "adjEM": 0.94,
      "adjOff": 102.2,
      "adjOffRank": 182,
      "adjTempo": 69.5,
      "adjTempoRank": 35,
      "conference": "WAC",
      "rank": 161,
      "record": "15-17"
    },
    "Boston College": {
      "adjDef": 99.8,
      "adjDefRank": 114,
      "adjEM": 10.87,
      "adjOff": 110.7,
      "adjOffRank": 51,
      "adjTempo": 64.1,
      "adjTempoRank": 256,
      "conference": "ACC",
      "rank": 74,
      "record": "15-16"
    },
    "Boston University": {
      "adjDef": 100.3,
      "adjDefRank": 120,
      "adjEM": 3.51,
      "adjOff": 103.8,
      "adjOffRank": 155,
      "adjTempo": 69.8,
      "adjTempoRank": 28,
      "conference": "AE",
      "rank": 133,
      "record": "21-14"
    },
    "Bowling Green": {
      "adjDef": 104.2,
      "adjDefRank": 206,
      "adjEM": -2.9,
      "adjOff": 101.3,
      "adjOffRank": 191,
      "adjTempo": 63.1,
      "adjTempoRank": 282,
      "conference": "MAC",
      "rank": 200,
      "record": "14-16"
    },
    "Bradley": {
      "adjDef": 100.6,
      "adjDefRank": 125,
      "adjEM": 5.69,
      "adjOff": 106.3,
      "adjOffRank": 119,
      "adjTempo": 64.9,
      "adjTempoRank": 222,
      "conference": "MVC",
      "rank": 114,
      "record": "16-15"
    },
    "Brown": {
      "adjDef": 112.1,
      "adjDefRank": 334,
      "adjEM": -12.16,
      "adjOff": 100,
      "adjOffRank": 215,
      "adjTempo": 66.1,
      "adjTempoRank": 169,
      "conference": "Ivy",
      "rank": 284,
      "record": "11-20"
    },
    "Bryant": {
      "adjDef": 111.7,
      "adjDefRank": 327,
      "adjEM": -32.2,
      "adjOff": 79.5,
      "adjOffRank": 347,
      "adjTempo": 62.1,
      "adjTempoRank": 308,
      "conference": "NEC",
      "rank": 346,
      "record": "1-29"
    },
    "Bucknell": {
      "adjDef": 105.9,
      "adjDefRank": 239,
      "adjEM": -7.96,
      "adjOff": 98,
      "adjOffRank": 249,
      "adjTempo": 63.8,
      "adjTempoRank": 265,
      "conference": "Pat",
      "rank": 246,
      "record": "14-17"
    },
    "Buffalo": {
      "adjDef": 105.1,
      "adjDefRank": 226,
      "adjEM": 1.47,
      "adjOff": 106.6,
      "adjOffRank": 112,
      "adjTempo": 68.2,
      "adjTempoRank": 68,
      "conference": "MAC",
      "rank": 158,
      "record": "18-12"
    },
    "Butler": {
      "adjDef": 88.5,
      "adjDefRank": 7,
      "adjEM": 22.45,
      "adjOff": 111,
      "adjOffRank": 49,
      "adjTempo": 63,
      "adjTempoRank": 286,
      "conference": "Horz",
      "finish": "Runner-up",
      "rank": 12,
      "record": "33-5",
      "seed": 5
    },
    "Cal Poly": {
      "adjDef": 111,
      "adjDefRank": 315,
      "adjEM": -7.76,
      "adjOff": 103.2,
      "adjOffRank": 167,
      "adjTempo": 66.4,
      "adjTempoRank": 145,
      "conference": "BW",
      "rank": 243,
      "record": "12-19"
    },
    "Cal St. Bakersfield": {
      "adjDef": 108.2,
      "adjDefRank": 276,
      "adjEM": -12.17,
      "adjOff": 96.1,
      "adjOffRank": 280,
      "adjTempo": 67,
      "adjTempoRank": 120,
      "conference": "ind",
      "rank": 285,
      "record": "7-22"
    },
    "Cal St. Fullerton": {
      "adjDef": 106.2,
      "adjDefRank": 244,
      "adjEM": -3.79,
      "adjOff": 102.5,
      "adjOffRank": 177,
      "adjTempo": 67.9,
      "adjTempoRank": 77,
      "conference": "BW",
      "rank": 210,
      "record": "16-15"
    },
    "Cal St. Northridge": {
      "adjDef": 103.4,
      "adjDefRank": 182,
      "adjEM": -5.57,
      "adjOff": 97.8,
      "adjOffRank": 251,
      "adjTempo": 70.7,
      "adjTempoRank": 18,
      "conference": "BW",
      "rank": 223,
      "record": "11-21"
    },
    "California": {
      "adjDef": 97.4,
      "adjDefRank": 76,
      "adjEM": 22.15,
      "adjOff": 119.6,
      "adjOffRank": 3,
      "adjTempo": 67.1,
      "adjTempoRank": 107,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 14,
      "record": "24-11",
      "seed": 8
    },
    "Campbell": {
      "adjDef": 99.7,
      "adjDefRank": 112,
      "adjEM": 0.85,
      "adjOff": 100.5,
      "adjOffRank": 202,
      "adjTempo": 64.2,
      "adjTempoRank": 251,
      "conference": "ASun",
      "rank": 163,
      "record": "19-11"
    },
    "Canisius": {
      "adjDef": 103.1,
      "adjDefRank": 178,
      "adjEM": -2.93,
      "adjOff": 100.1,
      "adjOffRank": 208,
      "adjTempo": 65.6,
      "adjTempoRank": 193,
      "conference": "MAAC",
      "rank": 201,
      "record": "15-17"
    },
    "Centenary": {
      "adjDef": 113.5,
      "adjDefRank": 338,
      "adjEM": -18.48,
      "adjOff": 95,
      "adjOffRank": 288,
      "adjTempo": 69.7,
      "adjTempoRank": 30,
      "conference": "Sum",
      "rank": 318,
      "record": "8-21"
    },
    "Central Arkansas": {
      "adjDef": 106.3,
      "adjDefRank": 246,
      "adjEM": -14.29,
      "adjOff": 92,
      "adjOffRank": 311,
      "adjTempo": 64.1,
      "adjTempoRank": 255,
      "conference": "Slnd",
      "rank": 304,
      "record": "9-21"
    },
    "Central Connecticut": {
      "adjDef": 107.8,
      "adjDefRank": 272,
      "adjEM": -15.5,
      "adjOff": 92.3,
      "adjOffRank": 308,
      "adjTempo": 63.4,
      "adjTempoRank": 276,
      "conference": "NEC",
      "rank": 309,
      "record": "12-18"
    },
    "Central Michigan": {
      "adjDef": 103.4,
      "adjDefRank": 184,
      "adjEM": -1.85,
      "adjOff": 101.6,
      "adjOffRank": 187,
      "adjTempo": 63.5,
      "adjTempoRank": 272,
      "conference": "MAC",
      "rank": 196,
      "record": "15-15"
    },
    "Charleston Southern": {
      "adjDef": 107.8,
      "adjDefRank": 271,
      "adjEM": -13.06,
      "adjOff": 94.7,
      "adjOffRank": 290,
      "adjTempo": 62.6,
      "adjTempoRank": 297,
      "conference": "BSth",
      "rank": 293,
      "record": "13-17"
    },
    "Charlotte": {
      "adjDef": 100.8,
      "adjDefRank": 130,
      "adjEM": 7.91,
      "adjOff": 108.7,
      "adjOffRank": 79,
      "adjTempo": 67.1,
      "adjTempoRank": 110,
      "conference": "A10",
      "rank": 96,
      "record": "19-12"
    },
    "Chattanooga": {
      "adjDef": 105.1,
      "adjDefRank": 225,
      "adjEM": -8.19,
      "adjOff": 96.9,
      "adjOffRank": 261,
      "adjTempo": 68.9,
      "adjTempoRank": 49,
      "conference": "SC",
      "rank": 248,
      "record": "15-18"
    },
    "Chicago St.": {
      "adjDef": 109.9,
      "adjDefRank": 298,
      "adjEM": -24.62,
      "adjOff": 85.3,
      "adjOffRank": 342,
      "adjTempo": 67.1,
      "adjTempoRank": 112,
      "conference": "GWC",
      "rank": 342,
      "record": "9-23"
    },
    "Cincinnati": {
      "adjDef": 94.4,
      "adjDefRank": 44,
      "adjEM": 11.92,
      "adjOff": 106.3,
      "adjOffRank": 120,
      "adjTempo": 65.3,
      "adjTempoRank": 206,
      "conference": "BE",
      "rank": 68,
      "record": "19-16"
    },
    "Clemson": {
      "adjDef": 91.6,
      "adjDefRank": 20,
      "adjEM": 19.28,
      "adjOff": 110.9,
      "adjOffRank": 50,
      "adjTempo": 67.6,
      "adjTempoRank": 87,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 25,
      "record": "21-11",
      "seed": 7
    },
    "Cleveland St.": {
      "adjDef": 103.7,
      "adjDefRank": 197,
      "adjEM": 2.16,
      "adjOff": 105.9,
      "adjOffRank": 130,
      "adjTempo": 64.5,
      "adjTempoRank": 242,
      "conference": "Horz",
      "rank": 152,
      "record": "16-17"
    },
    "Coastal Carolina": {
      "adjDef": 99.3,
      "adjDefRank": 102,
      "adjEM": 2.51,
      "adjOff": 101.8,
      "adjOffRank": 185,
      "adjTempo": 63.1,
      "adjTempoRank": 281,
      "conference": "BSth",
      "rank": 148,
      "record": "28-7"
    },
    "Colgate": {
      "adjDef": 110.5,
      "adjDefRank": 306,
      "adjEM": -12.67,
      "adjOff": 97.8,
      "adjOffRank": 250,
      "adjTempo": 65.6,
      "adjTempoRank": 191,
      "conference": "Pat",
      "rank": 290,
      "record": "10-19"
    },
    "College of Charleston": {
      "adjDef": 107.1,
      "adjDefRank": 257,
      "adjEM": 2.3,
      "adjOff": 109.4,
      "adjOffRank": 68,
      "adjTempo": 68.6,
      "adjTempoRank": 61,
      "conference": "SC",
      "rank": 151,
      "record": "22-12"
    },
    "Colorado": {
      "adjDef": 104.5,
      "adjDefRank": 211,
      "adjEM": 8.63,
      "adjOff": 113.1,
      "adjOffRank": 29,
      "adjTempo": 67,
      "adjTempoRank": 118,
      "conference": "B12",
      "rank": 92,
      "record": "15-16"
    },
    "Colorado St.": {
      "adjDef": 98.7,
      "adjDefRank": 95,
      "adjEM": 2.83,
      "adjOff": 101.5,
      "adjOffRank": 188,
      "adjTempo": 66,
      "adjTempoRank": 171,
      "conference": "MWC",
      "rank": 141,
      "record": "16-16"
    },
    "Columbia": {
      "adjDef": 105.7,
      "adjDefRank": 234,
      "adjEM": -13.5,
      "adjOff": 92.2,
      "adjOffRank": 309,
      "adjTempo": 63.9,
      "adjTempoRank": 263,
      "conference": "Ivy",
      "rank": 297,
      "record": "11-17"
    },
    "Connecticut": {
      "adjDef": 92.5,
      "adjDefRank": 25,
      "adjEM": 15.19,
      "adjOff": 107.7,
      "adjOffRank": 96,
      "adjTempo": 67.5,
      "adjTempoRank": 94,
      "conference": "BE",
      "rank": 51,
      "record": "18-16"
    },
    "Coppin St.": {
      "adjDef": 108.7,
      "adjDefRank": 286,
      "adjEM": -19.43,
      "adjOff": 89.3,
      "adjOffRank": 331,
      "adjTempo": 65,
      "adjTempoRank": 220,
      "conference": "MEAC",
      "rank": 323,
      "record": "8-22"
    },
    "Cornell": {
      "adjDef": 101.5,
      "adjDefRank": 149,
      "adjEM": 15.92,
      "adjOff": 117.4,
      "adjOffRank": 9,
      "adjTempo": 63.9,
      "adjTempoRank": 261,
      "conference": "Ivy",
      "finish": "Sweet 16",
      "rank": 46,
      "record": "29-5",
      "seed": 12
    },
    "Creighton": {
      "adjDef": 102.2,
      "adjDefRank": 162,
      "adjEM": 5.56,
      "adjOff": 107.8,
      "adjOffRank": 94,
      "adjTempo": 65.5,
      "adjTempoRank": 198,
      "conference": "MVC",
      "rank": 116,
      "record": "18-16"
    },
    "Dartmouth": {
      "adjDef": 106.7,
      "adjDefRank": 250,
      "adjEM": -24.24,
      "adjOff": 82.4,
      "adjOffRank": 345,
      "adjTempo": 63.4,
      "adjTempoRank": 274,
      "conference": "Ivy",
      "rank": 341,
      "record": "5-23"
    },
    "Davidson": {
      "adjDef": 104.9,
      "adjDefRank": 221,
      "adjEM": -1.31,
      "adjOff": 103.6,
      "adjOffRank": 159,
      "adjTempo": 65.2,
      "adjTempoRank": 210,
      "conference": "SC",
      "rank": 190,
      "record": "16-15"
    },
    "Dayton": {
      "adjDef": 89.1,
      "adjDefRank": 9,
      "adjEM": 18.92,
      "adjOff": 108.1,
      "adjOffRank": 86,
      "adjTempo": 65.5,
      "adjTempoRank": 195,
      "conference": "A10",
      "rank": 26,
      "record": "25-12"
    },
    "DePaul": {
      "adjDef": 100.1,
      "adjDefRank": 119,
      "adjEM": -0.08,
      "adjOff": 100,
      "adjOffRank": 213,
      "adjTempo": 61.8,
      "adjTempoRank": 315,
      "conference": "BE",
      "rank": 170,
      "record": "8-23"
    },
    "Delaware": {
      "adjDef": 108.8,
      "adjDefRank": 287,
      "adjEM": -9.3,
      "adjOff": 99.5,
      "adjOffRank": 222,
      "adjTempo": 63.9,
      "adjTempoRank": 264,
      "conference": "CAA",
      "rank": 257,
      "record": "7-24"
    },
    "Delaware St.": {
      "adjDef": 107.1,
      "adjDefRank": 258,
      "adjEM": -10.2,
      "adjOff": 96.9,
      "adjOffRank": 264,
      "adjTempo": 58.2,
      "adjTempoRank": 345,
      "conference": "MEAC",
      "rank": 262,
      "record": "17-12"
    },
    "Denver": {
      "adjDef": 107.7,
      "adjDefRank": 269,
      "adjEM": -1.43,
      "adjOff": 106.2,
      "adjOffRank": 122,
      "adjTempo": 58.5,
      "adjTempoRank": 344,
      "conference": "SB",
      "rank": 192,
      "record": "19-13"
    },
    "Detroit": {
      "adjDef": 96.4,
      "adjDefRank": 66,
      "adjEM": 4.58,
      "adjOff": 101,
      "adjOffRank": 196,
      "adjTempo": 67.5,
      "adjTempoRank": 92,
      "conference": "Horz",
      "rank": 126,
      "record": "20-14"
    },
    "Drake": {
      "adjDef": 107.2,
      "adjDefRank": 265,
      "adjEM": -0.01,
      "adjOff": 107.2,
      "adjOffRank": 104,
      "adjTempo": 63,
      "adjTempoRank": 287,
      "conference": "MVC",
      "rank": 169,
      "record": "14-19"
    },
    "Drexel": {
      "adjDef": 97.7,
      "adjDefRank": 81,
      "adjEM": 2.74,
      "adjOff": 100.5,
      "adjOffRank": 205,
      "adjTempo": 63.6,
      "adjTempoRank": 269,
      "conference": "CAA",
      "rank": 145,
      "record": "16-16"
    },
    "Duke": {
      "adjDef": 87.7,
      "adjDefRank": 5,
      "adjEM": 33.29,
      "adjOff": 121,
      "adjOffRank": 1,
      "adjTempo": 64.7,
      "adjTempoRank": 229,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 1,
      "record": "35-5",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 99.7,
      "adjDefRank": 111,
      "adjEM": 2.82,
      "adjOff": 102.5,
      "adjOffRank": 176,
      "adjTempo": 69.6,
      "adjTempoRank": 32,
      "conference": "A10",
      "rank": 142,
      "record": "16-16"
    },
    "East Carolina": {
      "adjDef": 108.4,
      "adjDefRank": 280,
      "adjEM": -6.53,
      "adjOff": 101.9,
      "adjOffRank": 184,
      "adjTempo": 66.9,
      "adjTempoRank": 123,
      "conference": "CUSA",
      "rank": 232,
      "record": "10-21"
    },
    "East Tennessee St.": {
      "adjDef": 98.4,
      "adjDefRank": 93,
      "adjEM": 2.47,
      "adjOff": 100.9,
      "adjOffRank": 199,
      "adjTempo": 66.8,
      "adjTempoRank": 125,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 150,
      "record": "20-15",
      "seed": 16
    },
    "Eastern Illinois": {
      "adjDef": 103.9,
      "adjDefRank": 201,
      "adjEM": -3.9,
      "adjOff": 100,
      "adjOffRank": 214,
      "adjTempo": 63.3,
      "adjTempoRank": 280,
      "conference": "OVC",
      "rank": 211,
      "record": "19-12"
    },
    "Eastern Kentucky": {
      "adjDef": 111.3,
      "adjDefRank": 318,
      "adjEM": -0.6,
      "adjOff": 110.7,
      "adjOffRank": 52,
      "adjTempo": 61.2,
      "adjTempoRank": 323,
      "conference": "OVC",
      "rank": 178,
      "record": "20-13"
    },
    "Eastern Michigan": {
      "adjDef": 101.4,
      "adjDefRank": 146,
      "adjEM": -1.19,
      "adjOff": 100.3,
      "adjOffRank": 207,
      "adjTempo": 65.2,
      "adjTempoRank": 209,
      "conference": "MAC",
      "rank": 189,
      "record": "17-15"
    },
    "Eastern Washington": {
      "adjDef": 106.8,
      "adjDefRank": 253,
      "adjEM": -10.36,
      "adjOff": 96.4,
      "adjOffRank": 270,
      "adjTempo": 67.9,
      "adjTempoRank": 74,
      "conference": "BSky",
      "rank": 265,
      "record": "9-21"
    },
    "Elon": {
      "adjDef": 106.3,
      "adjDefRank": 245,
      "adjEM": -10.06,
      "adjOff": 96.2,
      "adjOffRank": 274,
      "adjTempo": 66.9,
      "adjTempoRank": 122,
      "conference": "SC",
      "rank": 261,
      "record": "9-23"
    },
    "Evansville": {
      "adjDef": 103.1,
      "adjDefRank": 177,
      "adjEM": -5.31,
      "adjOff": 97.8,
      "adjOffRank": 253,
      "adjTempo": 65.4,
      "adjTempoRank": 200,
      "conference": "MVC",
      "rank": 220,
      "record": "9-21"
    },
    "FIU": {
      "adjDef": 113.1,
      "adjDefRank": 337,
      "adjEM": -14.45,
      "adjOff": 98.7,
      "adjOffRank": 234,
      "adjTempo": 67.1,
      "adjTempoRank": 113,
      "conference": "SB",
      "rank": 306,
      "record": "7-25"
    },
    "Fairfield": {
      "adjDef": 101.3,
      "adjDefRank": 144,
      "adjEM": 5.36,
      "adjOff": 106.7,
      "adjOffRank": 110,
      "adjTempo": 65.7,
      "adjTempoRank": 187,
      "conference": "MAAC",
      "rank": 118,
      "record": "23-11"
    },
    "Fairleigh Dickinson": {
      "adjDef": 109.6,
      "adjDefRank": 296,
      "adjEM": -17.42,
      "adjOff": 92.2,
      "adjOffRank": 310,
      "adjTempo": 67,
      "adjTempoRank": 117,
      "conference": "NEC",
      "rank": 316,
      "record": "11-21"
    },
    "Florida": {
      "adjDef": 97.8,
      "adjDefRank": 83,
      "adjEM": 15.23,
      "adjOff": 113,
      "adjOffRank": 31,
      "adjTempo": 63.9,
      "adjTempoRank": 262,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "21-13",
      "seed": 10
    },
    "Florida A&M": {
      "adjDef": 113.7,
      "adjDefRank": 339,
      "adjEM": -20.95,
      "adjOff": 92.8,
      "adjOffRank": 305,
      "adjTempo": 67,
      "adjTempoRank": 121,
      "conference": "MEAC",
      "rank": 333,
      "record": "9-22"
    },
    "Florida Atlantic": {
      "adjDef": 107.2,
      "adjDefRank": 260,
      "adjEM": -3.36,
      "adjOff": 103.8,
      "adjOffRank": 154,
      "adjTempo": 67.7,
      "adjTempoRank": 84,
      "conference": "SB",
      "rank": 206,
      "record": "14-16"
    },
    "Florida Gulf Coast": {
      "adjDef": 108.6,
      "adjDefRank": 283,
      "adjEM": -13.84,
      "adjOff": 94.8,
      "adjOffRank": 289,
      "adjTempo": 66.6,
      "adjTempoRank": 134,
      "conference": "ASun",
      "rank": 301,
      "record": "8-21"
    },
    "Florida St.": {
      "adjDef": 87,
      "adjDefRank": 2,
      "adjEM": 18.02,
      "adjOff": 105,
      "adjOffRank": 145,
      "adjTempo": 65.3,
      "adjTempoRank": 208,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "22-10",
      "seed": 9
    },
    "Fordham": {
      "adjDef": 107.7,
      "adjDefRank": 270,
      "adjEM": -14.41,
      "adjOff": 93.3,
      "adjOffRank": 301,
      "adjTempo": 70.5,
      "adjTempoRank": 22,
      "conference": "A10",
      "rank": 305,
      "record": "2-26"
    },
    "Fresno St.": {
      "adjDef": 99.5,
      "adjDefRank": 109,
      "adjEM": 0.58,
      "adjOff": 100.1,
      "adjOffRank": 210,
      "adjTempo": 65.4,
      "adjTempoRank": 199,
      "conference": "WAC",
      "rank": 165,
      "record": "15-18"
    },
    "Furman": {
      "adjDef": 104.8,
      "adjDefRank": 220,
      "adjEM": -6.86,
      "adjOff": 98,
      "adjOffRank": 248,
      "adjTempo": 67.5,
      "adjTempoRank": 90,
      "conference": "SC",
      "rank": 234,
      "record": "13-17"
    },
    "Gardner Webb": {
      "adjDef": 111,
      "adjDefRank": 314,
      "adjEM": -20.81,
      "adjOff": 90.1,
      "adjOffRank": 324,
      "adjTempo": 69.7,
      "adjTempoRank": 29,
      "conference": "BSth",
      "rank": 331,
      "record": "8-21"
    },
    "George Mason": {
      "adjDef": 101.9,
      "adjDefRank": 156,
      "adjEM": 0.91,
      "adjOff": 102.8,
      "adjOffRank": 171,
      "adjTempo": 64.6,
      "adjTempoRank": 233,
      "conference": "CAA",
      "rank": 162,
      "record": "17-15"
    },
    "George Washington": {
      "adjDef": 98.3,
      "adjDefRank": 89,
      "adjEM": 5.15,
      "adjOff": 103.4,
      "adjOffRank": 162,
      "adjTempo": 67,
      "adjTempoRank": 115,
      "conference": "A10",
      "rank": 122,
      "record": "16-15"
    },
    "Georgetown": {
      "adjDef": 94.2,
      "adjDefRank": 42,
      "adjEM": 22.64,
      "adjOff": 116.8,
      "adjOffRank": 13,
      "adjTempo": 65.8,
      "adjTempoRank": 182,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 11,
      "record": "23-11",
      "seed": 3
    },
    "Georgia": {
      "adjDef": 101.1,
      "adjDefRank": 137,
      "adjEM": 8.68,
      "adjOff": 109.8,
      "adjOffRank": 64,
      "adjTempo": 64.1,
      "adjTempoRank": 252,
      "conference": "SEC",
      "rank": 91,
      "record": "14-17"
    },
    "Georgia Southern": {
      "adjDef": 111.9,
      "adjDefRank": 331,
      "adjEM": -13.77,
      "adjOff": 98.1,
      "adjOffRank": 243,
      "adjTempo": 70.5,
      "adjTempoRank": 21,
      "conference": "SC",
      "rank": 300,
      "record": "9-23"
    },
    "Georgia St.": {
      "adjDef": 103.5,
      "adjDefRank": 186,
      "adjEM": -3.71,
      "adjOff": 99.8,
      "adjOffRank": 218,
      "adjTempo": 61.6,
      "adjTempoRank": 317,
      "conference": "CAA",
      "rank": 209,
      "record": "12-20"
    },
    "Georgia Tech": {
      "adjDef": 91,
      "adjDefRank": 15,
      "adjEM": 18.22,
      "adjOff": 109.2,
      "adjOffRank": 71,
      "adjTempo": 68.2,
      "adjTempoRank": 67,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 31,
      "record": "23-13",
      "seed": 10
    },
    "Gonzaga": {
      "adjDef": 96.1,
      "adjDefRank": 63,
      "adjEM": 16.14,
      "adjOff": 112.3,
      "adjOffRank": 38,
      "adjTempo": 67.5,
      "adjTempoRank": 95,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 44,
      "record": "27-7",
      "seed": 8
    },
    "Grambling St.": {
      "adjDef": 114,
      "adjDefRank": 342,
      "adjEM": -24.99,
      "adjOff": 89,
      "adjOffRank": 333,
      "adjTempo": 67.4,
      "adjTempoRank": 100,
      "conference": "SWAC",
      "rank": 344,
      "record": "7-21"
    },
    "Green Bay": {
      "adjDef": 103.7,
      "adjDefRank": 195,
      "adjEM": 3,
      "adjOff": 106.7,
      "adjOffRank": 109,
      "adjTempo": 63.4,
      "adjTempoRank": 278,
      "conference": "Horz",
      "rank": 139,
      "record": "22-13"
    },
    "Hampton": {
      "adjDef": 100.4,
      "adjDefRank": 121,
      "adjEM": -6.21,
      "adjOff": 94.1,
      "adjOffRank": 293,
      "adjTempo": 66.7,
      "adjTempoRank": 130,
      "conference": "MEAC",
      "rank": 228,
      "record": "14-18"
    },
    "Hartford": {
      "adjDef": 111.4,
      "adjDefRank": 322,
      "adjEM": -15.81,
      "adjOff": 95.6,
      "adjOffRank": 283,
      "adjTempo": 62.8,
      "adjTempoRank": 292,
      "conference": "AE",
      "rank": 312,
      "record": "8-22"
    },
    "Harvard": {
      "adjDef": 101.4,
      "adjDefRank": 145,
      "adjEM": 6.06,
      "adjOff": 107.5,
      "adjOffRank": 99,
      "adjTempo": 67,
      "adjTempoRank": 119,
      "conference": "Ivy",
      "rank": 111,
      "record": "21-8"
    },
    "Hawaii": {
      "adjDef": 108,
      "adjDefRank": 275,
      "adjEM": -6.78,
      "adjOff": 101.3,
      "adjOffRank": 192,
      "adjTempo": 64,
      "adjTempoRank": 260,
      "conference": "WAC",
      "rank": 233,
      "record": "10-20"
    },
    "High Point": {
      "adjDef": 109.6,
      "adjDefRank": 295,
      "adjEM": -12.43,
      "adjOff": 97.1,
      "adjOffRank": 260,
      "adjTempo": 67.4,
      "adjTempoRank": 97,
      "conference": "BSth",
      "rank": 287,
      "record": "15-15"
    },
    "Hofstra": {
      "adjDef": 97.4,
      "adjDefRank": 73,
      "adjEM": 3.77,
      "adjOff": 101.1,
      "adjOffRank": 194,
      "adjTempo": 69.2,
      "adjTempoRank": 41,
      "conference": "CAA",
      "rank": 131,
      "record": "19-15"
    },
    "Holy Cross": {
      "adjDef": 109.4,
      "adjDefRank": 294,
      "adjEM": -7.21,
      "adjOff": 102.2,
      "adjOffRank": 181,
      "adjTempo": 65,
      "adjTempoRank": 219,
      "conference": "Pat",
      "rank": 235,
      "record": "9-22"
    },
    "Houston": {
      "adjDef": 103.2,
      "adjDefRank": 179,
      "adjEM": 8.79,
      "adjOff": 112,
      "adjOffRank": 42,
      "adjTempo": 71.1,
      "adjTempoRank": 14,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 88,
      "record": "19-16",
      "seed": 13
    },
    "Houston Baptist": {
      "adjDef": 113,
      "adjDefRank": 336,
      "adjEM": -19.24,
      "adjOff": 93.8,
      "adjOffRank": 297,
      "adjTempo": 72.2,
      "adjTempoRank": 8,
      "conference": "GWC",
      "rank": 321,
      "record": "12-21"
    },
    "Howard": {
      "adjDef": 110.4,
      "adjDefRank": 303,
      "adjEM": -22.04,
      "adjOff": 88.4,
      "adjOffRank": 336,
      "adjTempo": 64.3,
      "adjTempoRank": 244,
      "conference": "MEAC",
      "rank": 337,
      "record": "7-25"
    },
    "IPFW": {
      "adjDef": 104.2,
      "adjDefRank": 205,
      "adjEM": -7.33,
      "adjOff": 96.9,
      "adjOffRank": 263,
      "adjTempo": 65.8,
      "adjTempoRank": 183,
      "conference": "Sum",
      "rank": 236,
      "record": "16-15"
    },
    "IUPUI": {
      "adjDef": 102.2,
      "adjDefRank": 161,
      "adjEM": 6.34,
      "adjOff": 108.5,
      "adjOffRank": 81,
      "adjTempo": 64.7,
      "adjTempoRank": 225,
      "conference": "Sum",
      "rank": 108,
      "record": "25-11"
    },
    "Idaho": {
      "adjDef": 102.9,
      "adjDefRank": 175,
      "adjEM": 2.67,
      "adjOff": 105.6,
      "adjOffRank": 133,
      "adjTempo": 64.9,
      "adjTempoRank": 221,
      "conference": "WAC",
      "rank": 146,
      "record": "15-16"
    },
    "Idaho St.": {
      "adjDef": 115.6,
      "adjDefRank": 345,
      "adjEM": -11.85,
      "adjOff": 103.8,
      "adjOffRank": 156,
      "adjTempo": 62.5,
      "adjTempoRank": 299,
      "conference": "BSky",
      "rank": 278,
      "record": "7-22"
    },
    "Illinois": {
      "adjDef": 94.8,
      "adjDefRank": 49,
      "adjEM": 14.38,
      "adjOff": 109.2,
      "adjOffRank": 73,
      "adjTempo": 66.6,
      "adjTempoRank": 136,
      "conference": "B10",
      "rank": 57,
      "record": "21-15"
    },
    "Illinois Chicago": {
      "adjDef": 104.5,
      "adjDefRank": 215,
      "adjEM": -7.94,
      "adjOff": 96.6,
      "adjOffRank": 268,
      "adjTempo": 67.3,
      "adjTempoRank": 104,
      "conference": "Horz",
      "rank": 245,
      "record": "8-22"
    },
    "Illinois St.": {
      "adjDef": 98.7,
      "adjDefRank": 96,
      "adjEM": 8.77,
      "adjOff": 107.5,
      "adjOffRank": 100,
      "adjTempo": 64.6,
      "adjTempoRank": 234,
      "conference": "MVC",
      "rank": 89,
      "record": "22-11"
    },
    "Indiana": {
      "adjDef": 103,
      "adjDefRank": 176,
      "adjEM": -1.64,
      "adjOff": 101.4,
      "adjOffRank": 190,
      "adjTempo": 67.3,
      "adjTempoRank": 101,
      "conference": "B10",
      "rank": 194,
      "record": "10-21"
    },
    "Indiana St.": {
      "adjDef": 101.5,
      "adjDefRank": 148,
      "adjEM": 3.02,
      "adjOff": 104.5,
      "adjOffRank": 149,
      "adjTempo": 64.2,
      "adjTempoRank": 250,
      "conference": "MVC",
      "rank": 138,
      "record": "17-15"
    },
    "Iona": {
      "adjDef": 94,
      "adjDefRank": 39,
      "adjEM": 6.11,
      "adjOff": 100.1,
      "adjOffRank": 209,
      "adjTempo": 64.7,
      "adjTempoRank": 230,
      "conference": "MAAC",
      "rank": 110,
      "record": "21-10"
    },
    "Iowa": {
      "adjDef": 103.7,
      "adjDefRank": 196,
      "adjEM": -0.36,
      "adjOff": 103.4,
      "adjOffRank": 165,
      "adjTempo": 61.2,
      "adjTempoRank": 322,
      "conference": "B10",
      "rank": 174,
      "record": "10-22"
    },
    "Iowa St.": {
      "adjDef": 96.1,
      "adjDefRank": 61,
      "adjEM": 11.09,
      "adjOff": 107.2,
      "adjOffRank": 105,
      "adjTempo": 67.5,
      "adjTempoRank": 93,
      "conference": "B12",
      "rank": 73,
      "record": "15-17"
    },
    "Jackson St.": {
      "adjDef": 104.9,
      "adjDefRank": 223,
      "adjEM": -11.38,
      "adjOff": 93.5,
      "adjOffRank": 299,
      "adjTempo": 64.1,
      "adjTempoRank": 253,
      "conference": "SWAC",
      "rank": 274,
      "record": "19-13"
    },
    "Jacksonville": {
      "adjDef": 99.5,
      "adjDefRank": 106,
      "adjEM": 2.75,
      "adjOff": 102.2,
      "adjOffRank": 183,
      "adjTempo": 68,
      "adjTempoRank": 71,
      "conference": "ASun",
      "rank": 144,
      "record": "20-13"
    },
    "Jacksonville St.": {
      "adjDef": 107.9,
      "adjDefRank": 273,
      "adjEM": -10.35,
      "adjOff": 97.5,
      "adjOffRank": 254,
      "adjTempo": 66.7,
      "adjTempoRank": 129,
      "conference": "OVC",
      "rank": 264,
      "record": "11-19"
    },
    "James Madison": {
      "adjDef": 105.4,
      "adjDefRank": 228,
      "adjEM": -4.25,
      "adjOff": 101.1,
      "adjOffRank": 193,
      "adjTempo": 66.8,
      "adjTempoRank": 126,
      "conference": "CAA",
      "rank": 215,
      "record": "13-20"
    },
    "Kansas": {
      "adjDef": 88.5,
      "adjDefRank": 8,
      "adjEM": 31.85,
      "adjOff": 120.4,
      "adjOffRank": 2,
      "adjTempo": 67.2,
      "adjTempoRank": 105,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 2,
      "record": "33-3",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 90.6,
      "adjDefRank": 12,
      "adjEM": 25.52,
      "adjOff": 116.1,
      "adjOffRank": 15,
      "adjTempo": 69.4,
      "adjTempoRank": 36,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "29-8",
      "seed": 2
    },
    "Kennesaw St.": {
      "adjDef": 108.7,
      "adjDefRank": 284,
      "adjEM": -10.59,
      "adjOff": 98.1,
      "adjOffRank": 245,
      "adjTempo": 64.2,
      "adjTempoRank": 248,
      "conference": "ASun",
      "rank": 268,
      "record": "13-20"
    },
    "Kent St.": {
      "adjDef": 97.8,
      "adjDefRank": 84,
      "adjEM": 8.84,
      "adjOff": 106.6,
      "adjOffRank": 111,
      "adjTempo": 66.8,
      "adjTempoRank": 128,
      "conference": "MAC",
      "rank": 86,
      "record": "24-10"
    },
    "Kentucky": {
      "adjDef": 88.3,
      "adjDefRank": 6,
      "adjEM": 26.54,
      "adjOff": 114.9,
      "adjOffRank": 22,
      "adjTempo": 68.3,
      "adjTempoRank": 66,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "35-3",
      "seed": 1
    },
    "LIU Brooklyn": {
      "adjDef": 110.8,
      "adjDefRank": 311,
      "adjEM": -10.97,
      "adjOff": 99.8,
      "adjOffRank": 217,
      "adjTempo": 67.1,
      "adjTempoRank": 111,
      "conference": "NEC",
      "rank": 270,
      "record": "14-17"
    },
    "LSU": {
      "adjDef": 101.2,
      "adjDefRank": 139,
      "adjEM": -1.42,
      "adjOff": 99.7,
      "adjOffRank": 219,
      "adjTempo": 61.2,
      "adjTempoRank": 324,
      "conference": "SEC",
      "rank": 191,
      "record": "11-20"
    },
    "La Salle": {
      "adjDef": 105.1,
      "adjDefRank": 224,
      "adjEM": 1.18,
      "adjOff": 106.2,
      "adjOffRank": 121,
      "adjTempo": 67.3,
      "adjTempoRank": 102,
      "conference": "A10",
      "rank": 160,
      "record": "12-18"
    },
    "Lafayette": {
      "adjDef": 111.8,
      "adjDefRank": 328,
      "adjEM": -7.69,
      "adjOff": 104.1,
      "adjOffRank": 150,
      "adjTempo": 66.6,
      "adjTempoRank": 133,
      "conference": "Pat",
      "rank": 240,
      "record": "19-13"
    },
    "Lamar": {
      "adjDef": 108.5,
      "adjDefRank": 282,
      "adjEM": -12.07,
      "adjOff": 96.4,
      "adjOffRank": 271,
      "adjTempo": 67.7,
      "adjTempoRank": 83,
      "conference": "Slnd",
      "rank": 283,
      "record": "14-18"
    },
    "Lehigh": {
      "adjDef": 106.6,
      "adjDefRank": 249,
      "adjEM": -0.97,
      "adjOff": 105.6,
      "adjOffRank": 134,
      "adjTempo": 68.9,
      "adjTempoRank": 48,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 186,
      "record": "22-11",
      "seed": 16
    },
    "Liberty": {
      "adjDef": 108.4,
      "adjDefRank": 278,
      "adjEM": -12.03,
      "adjOff": 96.3,
      "adjOffRank": 273,
      "adjTempo": 65.7,
      "adjTempoRank": 186,
      "conference": "BSth",
      "rank": 282,
      "record": "15-16"
    },
    "Lipscomb": {
      "adjDef": 109.4,
      "adjDefRank": 292,
      "adjEM": -0.12,
      "adjOff": 109.3,
      "adjOffRank": 70,
      "adjTempo": 69.3,
      "adjTempoRank": 38,
      "conference": "ASun",
      "rank": 172,
      "record": "17-13"
    },
    "Long Beach St.": {
      "adjDef": 98.6,
      "adjDefRank": 94,
      "adjEM": 2,
      "adjOff": 100.6,
      "adjOffRank": 201,
      "adjTempo": 69.3,
      "adjTempoRank": 39,
      "conference": "BW",
      "rank": 154,
      "record": "17-16"
    },
    "Longwood": {
      "adjDef": 111.6,
      "adjDefRank": 325,
      "adjEM": -13.56,
      "adjOff": 98,
      "adjOffRank": 246,
      "adjTempo": 72.4,
      "adjTempoRank": 7,
      "conference": "ind",
      "rank": 298,
      "record": "12-19"
    },
    "Louisiana Lafayette": {
      "adjDef": 103.7,
      "adjDefRank": 198,
      "adjEM": -4.31,
      "adjOff": 99.4,
      "adjOffRank": 225,
      "adjTempo": 65.8,
      "adjTempoRank": 181,
      "conference": "SB",
      "rank": 217,
      "record": "13-17"
    },
    "Louisiana Monroe": {
      "adjDef": 110.3,
      "adjDefRank": 301,
      "adjEM": -11.87,
      "adjOff": 98.4,
      "adjOffRank": 240,
      "adjTempo": 66.1,
      "adjTempoRank": 168,
      "conference": "SB",
      "rank": 280,
      "record": "12-19"
    },
    "Louisiana Tech": {
      "adjDef": 99.4,
      "adjDefRank": 103,
      "adjEM": 5.99,
      "adjOff": 105.4,
      "adjOffRank": 139,
      "adjTempo": 67.6,
      "adjTempoRank": 89,
      "conference": "WAC",
      "rank": 113,
      "record": "24-11"
    },
    "Louisville": {
      "adjDef": 97.6,
      "adjDefRank": 78,
      "adjEM": 16.91,
      "adjOff": 114.5,
      "adjOffRank": 23,
      "adjTempo": 66.3,
      "adjTempoRank": 151,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 38,
      "record": "20-13",
      "seed": 9
    },
    "Loyola Chicago": {
      "adjDef": 106.1,
      "adjDefRank": 241,
      "adjEM": -4.29,
      "adjOff": 101.8,
      "adjOffRank": 186,
      "adjTempo": 62.9,
      "adjTempoRank": 289,
      "conference": "Horz",
      "rank": 216,
      "record": "14-16"
    },
    "Loyola MD": {
      "adjDef": 103.6,
      "adjDefRank": 189,
      "adjEM": -4.12,
      "adjOff": 99.5,
      "adjOffRank": 223,
      "adjTempo": 65.2,
      "adjTempoRank": 213,
      "conference": "MAAC",
      "rank": 214,
      "record": "13-17"
    },
    "Loyola Marymount": {
      "adjDef": 108.4,
      "adjDefRank": 281,
      "adjEM": -0.01,
      "adjOff": 108.4,
      "adjOffRank": 82,
      "adjTempo": 69,
      "adjTempoRank": 46,
      "conference": "WCC",
      "rank": 168,
      "record": "18-16"
    },
    "Maine": {
      "adjDef": 98.3,
      "adjDefRank": 90,
      "adjEM": -4.07,
      "adjOff": 94.2,
      "adjOffRank": 292,
      "adjTempo": 64.5,
      "adjTempoRank": 236,
      "conference": "AE",
      "rank": 213,
      "record": "19-11"
    },
    "Manhattan": {
      "adjDef": 101.7,
      "adjDefRank": 154,
      "adjEM": -2.38,
      "adjOff": 99.3,
      "adjOffRank": 227,
      "adjTempo": 66.1,
      "adjTempoRank": 164,
      "conference": "MAAC",
      "rank": 199,
      "record": "11-20"
    },
    "Marist": {
      "adjDef": 112.8,
      "adjDefRank": 335,
      "adjEM": -22.01,
      "adjOff": 90.8,
      "adjOffRank": 319,
      "adjTempo": 62.6,
      "adjTempoRank": 298,
      "conference": "MAAC",
      "rank": 336,
      "record": "1-29"
    },
    "Marquette": {
      "adjDef": 95.5,
      "adjDefRank": 55,
      "adjEM": 19.65,
      "adjOff": 115.1,
      "adjOffRank": 21,
      "adjTempo": 62.3,
      "adjTempoRank": 305,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 24,
      "record": "22-12",
      "seed": 6
    },
    "Marshall": {
      "adjDef": 99.9,
      "adjDefRank": 115,
      "adjEM": 10.18,
      "adjOff": 110.1,
      "adjOffRank": 59,
      "adjTempo": 68.5,
      "adjTempoRank": 65,
      "conference": "CUSA",
      "rank": 81,
      "record": "24-10"
    },
    "Maryland": {
      "adjDef": 95.9,
      "adjDefRank": 57,
      "adjEM": 22.37,
      "adjOff": 118.3,
      "adjOffRank": 7,
      "adjTempo": 68.7,
      "adjTempoRank": 53,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 13,
      "record": "24-9",
      "seed": 4
    },
    "Maryland Eastern Shore": {
      "adjDef": 110.1,
      "adjDefRank": 299,
      "adjEM": -18.54,
      "adjOff": 91.6,
      "adjOffRank": 315,
      "adjTempo": 65.1,
      "adjTempoRank": 216,
      "conference": "MEAC",
      "rank": 319,
      "record": "11-21"
    },
    "Massachusetts": {
      "adjDef": 102.6,
      "adjDefRank": 168,
      "adjEM": 0.12,
      "adjOff": 102.7,
      "adjOffRank": 172,
      "adjTempo": 68.6,
      "adjTempoRank": 63,
      "conference": "A10",
      "rank": 167,
      "record": "12-20"
    },
    "McNeese St.": {
      "adjDef": 110.9,
      "adjDefRank": 313,
      "adjEM": -11.86,
      "adjOff": 99,
      "adjOffRank": 232,
      "adjTempo": 65.4,
      "adjTempoRank": 202,
      "conference": "Slnd",
      "rank": 279,
      "record": "10-20"
    },
    "Memphis": {
      "adjDef": 100.1,
      "adjDefRank": 118,
      "adjEM": 15.24,
      "adjOff": 115.3,
      "adjOffRank": 19,
      "adjTempo": 65.2,
      "adjTempoRank": 211,
      "conference": "CUSA",
      "rank": 49,
      "record": "24-10"
    },
    "Mercer": {
      "adjDef": 111.2,
      "adjDefRank": 317,
      "adjEM": -3.33,
      "adjOff": 107.8,
      "adjOffRank": 91,
      "adjTempo": 68.1,
      "adjTempoRank": 70,
      "conference": "ASun",
      "rank": 205,
      "record": "16-17"
    },
    "Miami FL": {
      "adjDef": 97.4,
      "adjDefRank": 75,
      "adjEM": 14.79,
      "adjOff": 112.2,
      "adjOffRank": 39,
      "adjTempo": 65.3,
      "adjTempoRank": 204,
      "conference": "ACC",
      "rank": 55,
      "record": "20-13"
    },
    "Miami OH": {
      "adjDef": 101.1,
      "adjDefRank": 138,
      "adjEM": 2.79,
      "adjOff": 103.9,
      "adjOffRank": 152,
      "adjTempo": 59.7,
      "adjTempoRank": 337,
      "conference": "MAC",
      "rank": 143,
      "record": "14-18"
    },
    "Michigan": {
      "adjDef": 95.9,
      "adjDefRank": 58,
      "adjEM": 11.48,
      "adjOff": 107.4,
      "adjOffRank": 101,
      "adjTempo": 61.8,
      "adjTempoRank": 314,
      "conference": "B10",
      "rank": 70,
      "record": "15-17"
    },
    "Michigan St.": {
      "adjDef": 92.7,
      "adjDefRank": 27,
      "adjEM": 20.13,
      "adjOff": 112.8,
      "adjOffRank": 33,
      "adjTempo": 65.3,
      "adjTempoRank": 203,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 20,
      "record": "28-9",
      "seed": 5
    },
    "Middle Tennessee": {
      "adjDef": 100.7,
      "adjDefRank": 129,
      "adjEM": 0.65,
      "adjOff": 101.4,
      "adjOffRank": 189,
      "adjTempo": 64.7,
      "adjTempoRank": 231,
      "conference": "SB",
      "rank": 164,
      "record": "19-14"
    },
    "Milwaukee": {
      "adjDef": 101.6,
      "adjDefRank": 151,
      "adjEM": 3.89,
      "adjOff": 105.5,
      "adjOffRank": 136,
      "adjTempo": 66.3,
      "adjTempoRank": 154,
      "conference": "Horz",
      "rank": 130,
      "record": "20-14"
    },
    "Minnesota": {
      "adjDef": 94.1,
      "adjDefRank": 41,
      "adjEM": 17.97,
      "adjOff": 112,
      "adjOffRank": 41,
      "adjTempo": 65.6,
      "adjTempoRank": 190,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 34,
      "record": "21-14",
      "seed": 11
    },
    "Mississippi": {
      "adjDef": 97.2,
      "adjDefRank": 70,
      "adjEM": 15.77,
      "adjOff": 112.9,
      "adjOffRank": 32,
      "adjTempo": 68.7,
      "adjTempoRank": 55,
      "conference": "SEC",
      "rank": 48,
      "record": "24-11"
    },
    "Mississippi St.": {
      "adjDef": 93.7,
      "adjDefRank": 34,
      "adjEM": 15.98,
      "adjOff": 109.7,
      "adjOffRank": 66,
      "adjTempo": 66.5,
      "adjTempoRank": 141,
      "conference": "SEC",
      "rank": 45,
      "record": "24-12"
    },
    "Mississippi Valley St.": {
      "adjDef": 112,
      "adjDefRank": 333,
      "adjEM": -20.4,
      "adjOff": 91.6,
      "adjOffRank": 314,
      "adjTempo": 67.2,
      "adjTempoRank": 106,
      "conference": "SWAC",
      "rank": 328,
      "record": "9-23"
    },
    "Missouri": {
      "adjDef": 91.5,
      "adjDefRank": 18,
      "adjEM": 19.99,
      "adjOff": 111.5,
      "adjOffRank": 46,
      "adjTempo": 68.8,
      "adjTempoRank": 51,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 22,
      "record": "23-11",
      "seed": 10
    },
    "Missouri St.": {
      "adjDef": 100.9,
      "adjDefRank": 134,
      "adjEM": 10.85,
      "adjOff": 111.8,
      "adjOffRank": 43,
      "adjTempo": 64.5,
      "adjTempoRank": 241,
      "conference": "MVC",
      "rank": 75,
      "record": "24-12"
    },
    "Monmouth": {
      "adjDef": 105.9,
      "adjDefRank": 238,
      "adjEM": -13.46,
      "adjOff": 92.5,
      "adjOffRank": 307,
      "adjTempo": 65.6,
      "adjTempoRank": 189,
      "conference": "NEC",
      "rank": 296,
      "record": "12-19"
    },
    "Montana": {
      "adjDef": 98.4,
      "adjDefRank": 92,
      "adjEM": 8.08,
      "adjOff": 106.5,
      "adjOffRank": 116,
      "adjTempo": 62,
      "adjTempoRank": 312,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 95,
      "record": "22-10",
      "seed": 14
    },
    "Montana St.": {
      "adjDef": 105.2,
      "adjDefRank": 227,
      "adjEM": -1.78,
      "adjOff": 103.4,
      "adjOffRank": 161,
      "adjTempo": 63.4,
      "adjTempoRank": 277,
      "conference": "BSky",
      "rank": 195,
      "record": "15-14"
    },
    "Morehead St.": {
      "adjDef": 98.1,
      "adjDefRank": 88,
      "adjEM": 7,
      "adjOff": 105.1,
      "adjOffRank": 143,
      "adjTempo": 64.7,
      "adjTempoRank": 227,
      "conference": "OVC",
      "rank": 102,
      "record": "24-11"
    },
    "Morgan St.": {
      "adjDef": 104,
      "adjDefRank": 203,
      "adjEM": 1.58,
      "adjOff": 105.5,
      "adjOffRank": 135,
      "adjTempo": 68.6,
      "adjTempoRank": 62,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 156,
      "record": "27-10",
      "seed": 15
    },
    "Mount St. Mary's": {
      "adjDef": 97.6,
      "adjDefRank": 79,
      "adjEM": -0.83,
      "adjOff": 96.8,
      "adjOffRank": 266,
      "adjTempo": 65.9,
      "adjTempoRank": 178,
      "conference": "NEC",
      "rank": 182,
      "record": "16-15"
    },
    "Murray St.": {
      "adjDef": 94,
      "adjDefRank": 36,
      "adjEM": 15.06,
      "adjOff": 109,
      "adjOffRank": 74,
      "adjTempo": 65.6,
      "adjTempoRank": 192,
      "conference": "OVC",
      "finish": "Round of 32",
      "rank": 53,
      "record": "31-5",
      "seed": 13
    },
    "N.C. State": {
      "adjDef": 94.6,
      "adjDefRank": 48,
      "adjEM": 11.49,
      "adjOff": 106.1,
      "adjOffRank": 125,
      "adjTempo": 64.5,
      "adjTempoRank": 240,
      "conference": "ACC",
      "rank": 69,
      "record": "20-16"
    },
    "NJIT": {
      "adjDef": 106.8,
      "adjDefRank": 254,
      "adjEM": -22.16,
      "adjOff": 84.7,
      "adjOffRank": 343,
      "adjTempo": 66.6,
      "adjTempoRank": 131,
      "conference": "GWC",
      "rank": 338,
      "record": "10-21"
    },
    "Navy": {
      "adjDef": 109,
      "adjDefRank": 289,
      "adjEM": -12.7,
      "adjOff": 96.3,
      "adjOffRank": 272,
      "adjTempo": 70.7,
      "adjTempoRank": 20,
      "conference": "Pat",
      "rank": 291,
      "record": "13-17"
    },
    "Nebraska": {
      "adjDef": 98.7,
      "adjDefRank": 97,
      "adjEM": 8.28,
      "adjOff": 107,
      "adjOffRank": 107,
      "adjTempo": 62.8,
      "adjTempoRank": 293,
      "conference": "B12",
      "rank": 93,
      "record": "15-18"
    },
    "Nevada": {
      "adjDef": 102.6,
      "adjDefRank": 169,
      "adjEM": 10.2,
      "adjOff": 112.8,
      "adjOffRank": 34,
      "adjTempo": 68.7,
      "adjTempoRank": 54,
      "conference": "WAC",
      "rank": 80,
      "record": "21-13"
    },
    "New Hampshire": {
      "adjDef": 101.3,
      "adjDefRank": 143,
      "adjEM": -9.76,
      "adjOff": 91.5,
      "adjOffRank": 316,
      "adjTempo": 64.5,
      "adjTempoRank": 239,
      "conference": "AE",
      "rank": 260,
      "record": "13-17"
    },
    "New Mexico": {
      "adjDef": 97.4,
      "adjDefRank": 74,
      "adjEM": 16.18,
      "adjOff": 113.6,
      "adjOffRank": 26,
      "adjTempo": 67.4,
      "adjTempoRank": 98,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 43,
      "record": "30-5",
      "seed": 3
    },
    "New Mexico St.": {
      "adjDef": 105.5,
      "adjDefRank": 231,
      "adjEM": 6.03,
      "adjOff": 111.6,
      "adjOffRank": 45,
      "adjTempo": 70.3,
      "adjTempoRank": 24,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 112,
      "record": "22-12",
      "seed": 12
    },
    "New Orleans": {
      "adjDef": 105.7,
      "adjDefRank": 233,
      "adjEM": -16.68,
      "adjOff": 89,
      "adjOffRank": 334,
      "adjTempo": 63.7,
      "adjTempoRank": 268,
      "conference": "SB",
      "rank": 315,
      "record": "8-22"
    },
    "Niagara": {
      "adjDef": 101.6,
      "adjDefRank": 152,
      "adjEM": 4.06,
      "adjOff": 105.7,
      "adjOffRank": 132,
      "adjTempo": 68,
      "adjTempoRank": 73,
      "conference": "MAAC",
      "rank": 128,
      "record": "18-15"
    },
    "Nicholls St.": {
      "adjDef": 110.7,
      "adjDefRank": 310,
      "adjEM": -11.17,
      "adjOff": 99.6,
      "adjOffRank": 221,
      "adjTempo": 61.4,
      "adjTempoRank": 318,
      "conference": "Slnd",
      "rank": 273,
      "record": "11-19"
    },
    "Norfolk St.": {
      "adjDef": 107.2,
      "adjDefRank": 262,
      "adjEM": -11.06,
      "adjOff": 96.2,
      "adjOffRank": 278,
      "adjTempo": 71.2,
      "adjTempoRank": 13,
      "conference": "MEAC",
      "rank": 271,
      "record": "11-19"
    },
    "North Carolina": {
      "adjDef": 94,
      "adjDefRank": 38,
      "adjEM": 13.39,
      "adjOff": 107.4,
      "adjOffRank": 102,
      "adjTempo": 70.4,
      "adjTempoRank": 23,
      "conference": "ACC",
      "rank": 61,
      "record": "20-17"
    },
    "North Carolina A&T": {
      "adjDef": 107.6,
      "adjDefRank": 268,
      "adjEM": -13.86,
      "adjOff": 93.8,
      "adjOffRank": 296,
      "adjTempo": 67.7,
      "adjTempoRank": 82,
      "conference": "MEAC",
      "rank": 302,
      "record": "11-22"
    },
    "North Carolina Central": {
      "adjDef": 108.4,
      "adjDefRank": 279,
      "adjEM": -22.46,
      "adjOff": 85.9,
      "adjOffRank": 340,
      "adjTempo": 66.6,
      "adjTempoRank": 138,
      "conference": "ind",
      "rank": 339,
      "record": "7-22"
    },
    "North Dakota": {
      "adjDef": 117.6,
      "adjDefRank": 346,
      "adjEM": -24.81,
      "adjOff": 92.8,
      "adjOffRank": 306,
      "adjTempo": 64.3,
      "adjTempoRank": 245,
      "conference": "GWC",
      "rank": 343,
      "record": "8-23"
    },
    "North Dakota St.": {
      "adjDef": 104.3,
      "adjDefRank": 207,
      "adjEM": -7.74,
      "adjOff": 96.6,
      "adjOffRank": 269,
      "adjTempo": 67.3,
      "adjTempoRank": 103,
      "conference": "Sum",
      "rank": 242,
      "record": "11-18"
    },
    "North Florida": {
      "adjDef": 102.3,
      "adjDefRank": 166,
      "adjEM": -10.47,
      "adjOff": 91.8,
      "adjOffRank": 313,
      "adjTempo": 62.9,
      "adjTempoRank": 288,
      "conference": "ASun",
      "rank": 266,
      "record": "13-18"
    },
    "North Texas": {
      "adjDef": 103.8,
      "adjDefRank": 199,
      "adjEM": -0.47,
      "adjOff": 103.4,
      "adjOffRank": 164,
      "adjTempo": 67.4,
      "adjTempoRank": 99,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 175,
      "record": "24-9",
      "seed": 15
    },
    "Northeastern": {
      "adjDef": 95.2,
      "adjDefRank": 52,
      "adjEM": 11.32,
      "adjOff": 106.5,
      "adjOffRank": 113,
      "adjTempo": 60.3,
      "adjTempoRank": 333,
      "conference": "CAA",
      "rank": 71,
      "record": "20-13"
    },
    "Northern Arizona": {
      "adjDef": 104.5,
      "adjDefRank": 214,
      "adjEM": -5.42,
      "adjOff": 99.1,
      "adjOffRank": 228,
      "adjTempo": 66.2,
      "adjTempoRank": 163,
      "conference": "BSky",
      "rank": 221,
      "record": "14-14"
    },
    "Northern Colorado": {
      "adjDef": 100.7,
      "adjDefRank": 127,
      "adjEM": 4.82,
      "adjOff": 105.5,
      "adjOffRank": 138,
      "adjTempo": 66.8,
      "adjTempoRank": 127,
      "conference": "BSky",
      "rank": 125,
      "record": "25-8"
    },
    "Northern Illinois": {
      "adjDef": 106.4,
      "adjDefRank": 247,
      "adjEM": -7.38,
      "adjOff": 99,
      "adjOffRank": 231,
      "adjTempo": 69.2,
      "adjTempoRank": 40,
      "conference": "MAC",
      "rank": 237,
      "record": "10-20"
    },
    "Northern Iowa": {
      "adjDef": 90.8,
      "adjDefRank": 13,
      "adjEM": 18.87,
      "adjOff": 109.6,
      "adjOffRank": 67,
      "adjTempo": 58.9,
      "adjTempoRank": 340,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 27,
      "record": "30-5",
      "seed": 9
    },
    "Northwestern": {
      "adjDef": 103.2,
      "adjDefRank": 180,
      "adjEM": 9.77,
      "adjOff": 113,
      "adjOffRank": 30,
      "adjTempo": 62.8,
      "adjTempoRank": 294,
      "conference": "B10",
      "rank": 84,
      "record": "20-14"
    },
    "Northwestern St.": {
      "adjDef": 110.9,
      "adjDefRank": 312,
      "adjEM": -13.96,
      "adjOff": 96.9,
      "adjOffRank": 262,
      "adjTempo": 74.1,
      "adjTempoRank": 4,
      "conference": "Slnd",
      "rank": 303,
      "record": "10-19"
    },
    "Notre Dame": {
      "adjDef": 101.7,
      "adjDefRank": 153,
      "adjEM": 17.71,
      "adjOff": 119.4,
      "adjOffRank": 4,
      "adjTempo": 62.4,
      "adjTempoRank": 302,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 35,
      "record": "23-12",
      "seed": 6
    },
    "Oakland": {
      "adjDef": 104.3,
      "adjDefRank": 209,
      "adjEM": 3.19,
      "adjOff": 107.5,
      "adjOffRank": 98,
      "adjTempo": 69.5,
      "adjTempoRank": 33,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 135,
      "record": "26-9",
      "seed": 14
    },
    "Ohio": {
      "adjDef": 99,
      "adjDefRank": 100,
      "adjEM": 8.25,
      "adjOff": 107.3,
      "adjOffRank": 103,
      "adjTempo": 68.8,
      "adjTempoRank": 50,
      "conference": "MAC",
      "finish": "Round of 32",
      "rank": 94,
      "record": "22-15",
      "seed": 14
    },
    "Ohio St.": {
      "adjDef": 92.5,
      "adjDefRank": 26,
      "adjEM": 24.99,
      "adjOff": 117.5,
      "adjOffRank": 8,
      "adjTempo": 64.6,
      "adjTempoRank": 232,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 7,
      "record": "29-8",
      "seed": 2
    },
    "Oklahoma": {
      "adjDef": 103.2,
      "adjDefRank": 181,
      "adjEM": 6.98,
      "adjOff": 110.2,
      "adjOffRank": 56,
      "adjTempo": 65.3,
      "adjTempoRank": 205,
      "conference": "B12",
      "rank": 104,
      "record": "13-18"
    },
    "Oklahoma St.": {
      "adjDef": 95.4,
      "adjDefRank": 54,
      "adjEM": 16.72,
      "adjOff": 112.2,
      "adjOffRank": 40,
      "adjTempo": 66.2,
      "adjTempoRank": 159,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 39,
      "record": "22-11",
      "seed": 7
    },
    "Old Dominion": {
      "adjDef": 90.3,
      "adjDefRank": 10,
      "adjEM": 17.51,
      "adjOff": 107.8,
      "adjOffRank": 90,
      "adjTempo": 62.6,
      "adjTempoRank": 295,
      "conference": "CAA",
      "finish": "Round of 32",
      "rank": 37,
      "record": "27-9",
      "seed": 11
    },
    "Oral Roberts": {
      "adjDef": 99.4,
      "adjDefRank": 104,
      "adjEM": 2.84,
      "adjOff": 102.3,
      "adjOffRank": 180,
      "adjTempo": 65.6,
      "adjTempoRank": 194,
      "conference": "Sum",
      "rank": 140,
      "record": "20-13"
    },
    "Oregon": {
      "adjDef": 101.8,
      "adjDefRank": 155,
      "adjEM": 3.99,
      "adjOff": 105.8,
      "adjOffRank": 131,
      "adjTempo": 65.7,
      "adjTempoRank": 188,
      "conference": "P10",
      "rank": 129,
      "record": "16-16"
    },
    "Oregon St.": {
      "adjDef": 96.4,
      "adjDefRank": 67,
      "adjEM": 2.54,
      "adjOff": 99,
      "adjOffRank": 233,
      "adjTempo": 61.3,
      "adjTempoRank": 320,
      "conference": "P10",
      "rank": 147,
      "record": "14-18"
    },
    "Pacific": {
      "adjDef": 98.3,
      "adjDefRank": 91,
      "adjEM": 7.84,
      "adjOff": 106.2,
      "adjOffRank": 124,
      "adjTempo": 60.2,
      "adjTempoRank": 334,
      "conference": "BW",
      "rank": 97,
      "record": "23-12"
    },
    "Penn": {
      "adjDef": 111.9,
      "adjDefRank": 332,
      "adjEM": -14.61,
      "adjOff": 97.3,
      "adjOffRank": 257,
      "adjTempo": 64,
      "adjTempoRank": 258,
      "conference": "Ivy",
      "rank": 308,
      "record": "6-22"
    },
    "Penn St.": {
      "adjDef": 100.8,
      "adjDefRank": 131,
      "adjEM": 7.04,
      "adjOff": 107.9,
      "adjOffRank": 89,
      "adjTempo": 62.2,
      "adjTempoRank": 306,
      "conference": "B10",
      "rank": 101,
      "record": "11-20"
    },
    "Pepperdine": {
      "adjDef": 110.2,
      "adjDefRank": 300,
      "adjEM": -12.2,
      "adjOff": 98,
      "adjOffRank": 247,
      "adjTempo": 65.5,
      "adjTempoRank": 197,
      "conference": "WCC",
      "rank": 286,
      "record": "7-24"
    },
    "Pittsburgh": {
      "adjDef": 92.8,
      "adjDefRank": 28,
      "adjEM": 18.66,
      "adjOff": 111.4,
      "adjOffRank": 47,
      "adjTempo": 61.3,
      "adjTempoRank": 319,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 28,
      "record": "25-9",
      "seed": 3
    },
    "Portland": {
      "adjDef": 100.4,
      "adjDefRank": 122,
      "adjEM": 10.28,
      "adjOff": 110.6,
      "adjOffRank": 54,
      "adjTempo": 63.1,
      "adjTempoRank": 283,
      "conference": "WCC",
      "rank": 79,
      "record": "21-11"
    },
    "Portland St.": {
      "adjDef": 114.2,
      "adjDefRank": 343,
      "adjEM": -0.94,
      "adjOff": 113.3,
      "adjOffRank": 27,
      "adjTempo": 65.9,
      "adjTempoRank": 177,
      "conference": "BSky",
      "rank": 184,
      "record": "13-19"
    },
    "Prairie View A&M": {
      "adjDef": 101.2,
      "adjDefRank": 140,
      "adjEM": -15.63,
      "adjOff": 85.6,
      "adjOffRank": 341,
      "adjTempo": 66.3,
      "adjTempoRank": 155,
      "conference": "SWAC",
      "rank": 310,
      "record": "16-14"
    },
    "Presbyterian": {
      "adjDef": 111.6,
      "adjDefRank": 323,
      "adjEM": -20.64,
      "adjOff": 90.9,
      "adjOffRank": 318,
      "adjTempo": 62,
      "adjTempoRank": 310,
      "conference": "BSth",
      "rank": 329,
      "record": "5-26"
    },
    "Princeton": {
      "adjDef": 93.3,
      "adjDefRank": 32,
      "adjEM": 5.29,
      "adjOff": 98.6,
      "adjOffRank": 236,
      "adjTempo": 58.7,
      "adjTempoRank": 343,
      "conference": "Ivy",
      "rank": 120,
      "record": "22-9"
    },
    "Providence": {
      "adjDef": 107.3,
      "adjDefRank": 266,
      "adjEM": 8.7,
      "adjOff": 116,
      "adjOffRank": 17,
      "adjTempo": 73.3,
      "adjTempoRank": 5,
      "conference": "BE",
      "rank": 90,
      "record": "12-19"
    },
    "Purdue": {
      "adjDef": 87.7,
      "adjDefRank": 4,
      "adjEM": 22.05,
      "adjOff": 109.8,
      "adjOffRank": 65,
      "adjTempo": 66.2,
      "adjTempoRank": 161,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 15,
      "record": "29-6",
      "seed": 4
    },
    "Quinnipiac": {
      "adjDef": 104.5,
      "adjDefRank": 212,
      "adjEM": -1.01,
      "adjOff": 103.5,
      "adjOffRank": 160,
      "adjTempo": 67.7,
      "adjTempoRank": 81,
      "conference": "NEC",
      "rank": 187,
      "record": "23-10"
    },
    "Radford": {
      "adjDef": 100.5,
      "adjDefRank": 123,
      "adjEM": -5.01,
      "adjOff": 95.5,
      "adjOffRank": 284,
      "adjTempo": 67.9,
      "adjTempoRank": 75,
      "conference": "BSth",
      "rank": 218,
      "record": "19-12"
    },
    "Rhode Island": {
      "adjDef": 99.1,
      "adjDefRank": 101,
      "adjEM": 13.54,
      "adjOff": 112.6,
      "adjOffRank": 36,
      "adjTempo": 67.6,
      "adjTempoRank": 86,
      "conference": "A10",
      "rank": 60,
      "record": "26-10"
    },
    "Rice": {
      "adjDef": 104.4,
      "adjDefRank": 210,
      "adjEM": -8.48,
      "adjOff": 95.9,
      "adjOffRank": 281,
      "adjTempo": 65.7,
      "adjTempoRank": 184,
      "conference": "CUSA",
      "rank": 249,
      "record": "8-23"
    },
    "Richmond": {
      "adjDef": 93.8,
      "adjDefRank": 35,
      "adjEM": 15.01,
      "adjOff": 108.8,
      "adjOffRank": 77,
      "adjTempo": 62.5,
      "adjTempoRank": 301,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 54,
      "record": "26-9",
      "seed": 7
    },
    "Rider": {
      "adjDef": 104.6,
      "adjDefRank": 216,
      "adjEM": -1.45,
      "adjOff": 103.1,
      "adjOffRank": 168,
      "adjTempo": 67.1,
      "adjTempoRank": 109,
      "conference": "MAAC",
      "rank": 193,
      "record": "17-16"
    },
    "Robert Morris": {
      "adjDef": 100.8,
      "adjDefRank": 132,
      "adjEM": -0.97,
      "adjOff": 99.9,
      "adjOffRank": 216,
      "adjTempo": 66.1,
      "adjTempoRank": 170,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 185,
      "record": "23-12",
      "seed": 15
    },
    "Rutgers": {
      "adjDef": 102,
      "adjDefRank": 158,
      "adjEM": 1.37,
      "adjOff": 103.4,
      "adjOffRank": 163,
      "adjTempo": 67.5,
      "adjTempoRank": 96,
      "conference": "BE",
      "rank": 159,
      "record": "15-17"
    },
    "SIU Edwardsville": {
      "adjDef": 111.3,
      "adjDefRank": 319,
      "adjEM": -23.22,
      "adjOff": 88.1,
      "adjOffRank": 337,
      "adjTempo": 66.5,
      "adjTempoRank": 142,
      "conference": "ind",
      "rank": 340,
      "record": "5-23"
    },
    "SMU": {
      "adjDef": 103.6,
      "adjDefRank": 190,
      "adjEM": 2.49,
      "adjOff": 106.1,
      "adjOffRank": 126,
      "adjTempo": 60.8,
      "adjTempoRank": 329,
      "conference": "CUSA",
      "rank": 149,
      "record": "14-17"
    },
    "Sacramento St.": {
      "adjDef": 105.8,
      "adjDefRank": 235,
      "adjEM": -12.53,
      "adjOff": 93.3,
      "adjOffRank": 302,
      "adjTempo": 66.5,
      "adjTempoRank": 143,
      "conference": "BSky",
      "rank": 288,
      "record": "9-21"
    },
    "Sacred Heart": {
      "adjDef": 110.5,
      "adjDefRank": 305,
      "adjEM": -11.41,
      "adjOff": 99.1,
      "adjOffRank": 230,
      "adjTempo": 69.1,
      "adjTempoRank": 44,
      "conference": "NEC",
      "rank": 275,
      "record": "14-15"
    },
    "Saint Joseph's": {
      "adjDef": 102.7,
      "adjDefRank": 173,
      "adjEM": -0.17,
      "adjOff": 102.6,
      "adjOffRank": 174,
      "adjTempo": 68.7,
      "adjTempoRank": 60,
      "conference": "A10",
      "rank": 173,
      "record": "11-20"
    },
    "Saint Louis": {
      "adjDef": 92.4,
      "adjDefRank": 23,
      "adjEM": 7.72,
      "adjOff": 100.1,
      "adjOffRank": 211,
      "adjTempo": 62.4,
      "adjTempoRank": 304,
      "conference": "A10",
      "rank": 98,
      "record": "23-13"
    },
    "Saint Mary's": {
      "adjDef": 97.9,
      "adjDefRank": 85,
      "adjEM": 18.59,
      "adjOff": 116.5,
      "adjOffRank": 14,
      "adjTempo": 66.2,
      "adjTempoRank": 158,
      "conference": "WCC",
      "finish": "Sweet 16",
      "rank": 29,
      "record": "28-6",
      "seed": 10
    },
    "Saint Peter's": {
      "adjDef": 93.3,
      "adjDefRank": 31,
      "adjEM": -0.49,
      "adjOff": 92.8,
      "adjOffRank": 304,
      "adjTempo": 64.1,
      "adjTempoRank": 254,
      "conference": "MAAC",
      "rank": 176,
      "record": "16-14"
    },
    "Sam Houston St.": {
      "adjDef": 102.7,
      "adjDefRank": 172,
      "adjEM": 7.42,
      "adjOff": 110.2,
      "adjOffRank": 57,
      "adjTempo": 69.3,
      "adjTempoRank": 37,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 100,
      "record": "25-8",
      "seed": 14
    },
    "Samford": {
      "adjDef": 109,
      "adjDefRank": 288,
      "adjEM": -8.5,
      "adjOff": 100.5,
      "adjOffRank": 203,
      "adjTempo": 56.2,
      "adjTempoRank": 347,
      "conference": "SC",
      "rank": 250,
      "record": "11-20"
    },
    "San Diego": {
      "adjDef": 102.1,
      "adjDefRank": 160,
      "adjEM": -3.68,
      "adjOff": 98.4,
      "adjOffRank": 239,
      "adjTempo": 62,
      "adjTempoRank": 311,
      "conference": "WCC",
      "rank": 208,
      "record": "11-21"
    },
    "San Diego St.": {
      "adjDef": 94.9,
      "adjDefRank": 51,
      "adjEM": 16.58,
      "adjOff": 111.4,
      "adjOffRank": 48,
      "adjTempo": 63.1,
      "adjTempoRank": 284,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 40,
      "record": "25-9",
      "seed": 11
    },
    "San Francisco": {
      "adjDef": 103.6,
      "adjDefRank": 192,
      "adjEM": -3.14,
      "adjOff": 100.5,
      "adjOffRank": 204,
      "adjTempo": 66.1,
      "adjTempoRank": 166,
      "conference": "WCC",
      "rank": 204,
      "record": "12-18"
    },
    "San Jose St.": {
      "adjDef": 110.5,
      "adjDefRank": 307,
      "adjEM": -0.62,
      "adjOff": 109.9,
      "adjOffRank": 62,
      "adjTempo": 66,
      "adjTempoRank": 174,
      "conference": "WAC",
      "rank": 179,
      "record": "14-17"
    },
    "Santa Clara": {
      "adjDef": 103.6,
      "adjDefRank": 191,
      "adjEM": -7.41,
      "adjOff": 96.2,
      "adjOffRank": 275,
      "adjTempo": 65.9,
      "adjTempoRank": 180,
      "conference": "WCC",
      "rank": 238,
      "record": "11-21"
    },
    "Savannah St.": {
      "adjDef": 104.3,
      "adjDefRank": 208,
      "adjEM": -14.47,
      "adjOff": 89.8,
      "adjOffRank": 325,
      "adjTempo": 61.2,
      "adjTempoRank": 321,
      "conference": "ind",
      "rank": 307,
      "record": "11-15"
    },
    "Seattle": {
      "adjDef": 101.2,
      "adjDefRank": 141,
      "adjEM": -0.58,
      "adjOff": 100.6,
      "adjOffRank": 200,
      "adjTempo": 77.7,
      "adjTempoRank": 2,
      "conference": "ind",
      "rank": 177,
      "record": "17-14"
    },
    "Seton Hall": {
      "adjDef": 100.7,
      "adjDefRank": 128,
      "adjEM": 12.05,
      "adjOff": 112.7,
      "adjOffRank": 35,
      "adjTempo": 69.5,
      "adjTempoRank": 34,
      "conference": "BE",
      "rank": 66,
      "record": "19-13"
    },
    "Siena": {
      "adjDef": 95.5,
      "adjDefRank": 56,
      "adjEM": 13.86,
      "adjOff": 109.4,
      "adjOffRank": 69,
      "adjTempo": 69.2,
      "adjTempoRank": 43,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 59,
      "record": "27-7",
      "seed": 13
    },
    "South Alabama": {
      "adjDef": 104.7,
      "adjDefRank": 217,
      "adjEM": -8.52,
      "adjOff": 96.2,
      "adjOffRank": 276,
      "adjTempo": 64.7,
      "adjTempoRank": 226,
      "conference": "SB",
      "rank": 251,
      "record": "17-15"
    },
    "South Carolina": {
      "adjDef": 97.4,
      "adjDefRank": 77,
      "adjEM": 9.06,
      "adjOff": 106.5,
      "adjOffRank": 114,
      "adjTempo": 68.7,
      "adjTempoRank": 57,
      "conference": "SEC",
      "rank": 85,
      "record": "15-16"
    },
    "South Carolina St.": {
      "adjDef": 106.2,
      "adjDefRank": 243,
      "adjEM": -8.88,
      "adjOff": 97.4,
      "adjOffRank": 256,
      "adjTempo": 68,
      "adjTempoRank": 72,
      "conference": "MEAC",
      "rank": 255,
      "record": "18-14"
    },
    "South Dakota": {
      "adjDef": 106.8,
      "adjDefRank": 252,
      "adjEM": -8.11,
      "adjOff": 98.6,
      "adjOffRank": 235,
      "adjTempo": 72.9,
      "adjTempoRank": 6,
      "conference": "GWC",
      "rank": 247,
      "record": "22-10"
    },
    "South Dakota St.": {
      "adjDef": 110.6,
      "adjDefRank": 309,
      "adjEM": -5.15,
      "adjOff": 105.5,
      "adjOffRank": 137,
      "adjTempo": 66.9,
      "adjTempoRank": 124,
      "conference": "Sum",
      "rank": 219,
      "record": "14-16"
    },
    "South Florida": {
      "adjDef": 97.3,
      "adjDefRank": 72,
      "adjEM": 10.52,
      "adjOff": 107.8,
      "adjOffRank": 92,
      "adjTempo": 63.6,
      "adjTempoRank": 270,
      "conference": "BE",
      "rank": 78,
      "record": "20-13"
    },
    "Southeast Missouri St.": {
      "adjDef": 106.8,
      "adjDefRank": 255,
      "adjEM": -16.14,
      "adjOff": 90.7,
      "adjOffRank": 321,
      "adjTempo": 66.6,
      "adjTempoRank": 139,
      "conference": "OVC",
      "rank": 313,
      "record": "7-23"
    },
    "Southeastern Louisiana": {
      "adjDef": 104,
      "adjDefRank": 204,
      "adjEM": -3.09,
      "adjOff": 100.9,
      "adjOffRank": 198,
      "adjTempo": 66.5,
      "adjTempoRank": 140,
      "conference": "Slnd",
      "rank": 203,
      "record": "19-12"
    },
    "Southern": {
      "adjDef": 110.6,
      "adjDefRank": 308,
      "adjEM": -26.71,
      "adjOff": 83.9,
      "adjOffRank": 344,
      "adjTempo": 68.5,
      "adjTempoRank": 64,
      "conference": "SWAC",
      "rank": 345,
      "record": "5-25"
    },
    "Southern Illinois": {
      "adjDef": 101,
      "adjDefRank": 135,
      "adjEM": 5.14,
      "adjOff": 106.1,
      "adjOffRank": 127,
      "adjTempo": 66.2,
      "adjTempoRank": 160,
      "conference": "MVC",
      "rank": 123,
      "record": "15-15"
    },
    "Southern Miss": {
      "adjDef": 96.1,
      "adjDefRank": 60,
      "adjEM": 7.6,
      "adjOff": 103.7,
      "adjOffRank": 158,
      "adjTempo": 61,
      "adjTempoRank": 327,
      "conference": "CUSA",
      "rank": 99,
      "record": "20-14"
    },
    "Southern Utah": {
      "adjDef": 109.3,
      "adjDefRank": 291,
      "adjEM": -19.47,
      "adjOff": 89.8,
      "adjOffRank": 327,
      "adjTempo": 66.2,
      "adjTempoRank": 157,
      "conference": "Sum",
      "rank": 324,
      "record": "7-22"
    },
    "St. Bonaventure": {
      "adjDef": 102.7,
      "adjDefRank": 171,
      "adjEM": 3.5,
      "adjOff": 106.2,
      "adjOffRank": 123,
      "adjTempo": 66.6,
      "adjTempoRank": 135,
      "conference": "A10",
      "rank": 134,
      "record": "15-16"
    },
    "St. Francis NY": {
      "adjDef": 105.5,
      "adjDefRank": 230,
      "adjEM": -15.76,
      "adjOff": 89.8,
      "adjOffRank": 328,
      "adjTempo": 63.7,
      "adjTempoRank": 267,
      "conference": "NEC",
      "rank": 311,
      "record": "11-18"
    },
    "St. Francis PA": {
      "adjDef": 111.9,
      "adjDefRank": 329,
      "adjEM": -16.39,
      "adjOff": 95.5,
      "adjOffRank": 286,
      "adjTempo": 63.8,
      "adjTempoRank": 266,
      "conference": "NEC",
      "rank": 314,
      "record": "11-19"
    },
    "St. John's": {
      "adjDef": 94.5,
      "adjDefRank": 46,
      "adjEM": 11.97,
      "adjOff": 106.5,
      "adjOffRank": 115,
      "adjTempo": 64.2,
      "adjTempoRank": 249,
      "conference": "BE",
      "rank": 67,
      "record": "17-16"
    },
    "Stanford": {
      "adjDef": 99.7,
      "adjDefRank": 113,
      "adjEM": 6.75,
      "adjOff": 106.5,
      "adjOffRank": 117,
      "adjTempo": 66.3,
      "adjTempoRank": 152,
      "conference": "P10",
      "rank": 106,
      "record": "14-18"
    },
    "Stephen F. Austin": {
      "adjDef": 101.5,
      "adjDefRank": 147,
      "adjEM": 1.47,
      "adjOff": 103,
      "adjOffRank": 169,
      "adjTempo": 58.7,
      "adjTempoRank": 342,
      "conference": "Slnd",
      "rank": 157,
      "record": "23-9"
    },
    "Stetson": {
      "adjDef": 111.3,
      "adjDefRank": 320,
      "adjEM": -20.85,
      "adjOff": 90.5,
      "adjOffRank": 322,
      "adjTempo": 66,
      "adjTempoRank": 176,
      "conference": "ASun",
      "rank": 332,
      "record": "7-22"
    },
    "Stony Brook": {
      "adjDef": 103.4,
      "adjDefRank": 183,
      "adjEM": -1.12,
      "adjOff": 102.3,
      "adjOffRank": 179,
      "adjTempo": 65.1,
      "adjTempoRank": 217,
      "conference": "AE",
      "rank": 188,
      "record": "22-10"
    },
    "Syracuse": {
      "adjDef": 90.9,
      "adjDefRank": 14,
      "adjEM": 27.62,
      "adjOff": 118.5,
      "adjOffRank": 6,
      "adjTempo": 68.7,
      "adjTempoRank": 59,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 3,
      "record": "30-5",
      "seed": 1
    },
    "TCU": {
      "adjDef": 105.9,
      "adjDefRank": 237,
      "adjEM": -0.72,
      "adjOff": 105.2,
      "adjOffRank": 142,
      "adjTempo": 63.1,
      "adjTempoRank": 285,
      "conference": "MWC",
      "rank": 181,
      "record": "13-19"
    },
    "Temple": {
      "adjDef": 87.7,
      "adjDefRank": 3,
      "adjEM": 20.12,
      "adjOff": 107.8,
      "adjOffRank": 93,
      "adjTempo": 60.4,
      "adjTempoRank": 332,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 21,
      "record": "29-6",
      "seed": 5
    },
    "Tennessee": {
      "adjDef": 90.4,
      "adjDefRank": 11,
      "adjEM": 18.5,
      "adjOff": 108.9,
      "adjOffRank": 75,
      "adjTempo": 67.5,
      "adjTempoRank": 91,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 30,
      "record": "28-9",
      "seed": 6
    },
    "Tennessee Martin": {
      "adjDef": 111.4,
      "adjDefRank": 321,
      "adjEM": -21.2,
      "adjOff": 90.2,
      "adjOffRank": 323,
      "adjTempo": 69.8,
      "adjTempoRank": 27,
      "conference": "OVC",
      "rank": 334,
      "record": "4-25"
    },
    "Tennessee St.": {
      "adjDef": 107.2,
      "adjDefRank": 263,
      "adjEM": -9.44,
      "adjOff": 97.8,
      "adjOffRank": 252,
      "adjTempo": 67.1,
      "adjTempoRank": 108,
      "conference": "OVC",
      "rank": 258,
      "record": "9-23"
    },
    "Tennessee Tech": {
      "adjDef": 113.8,
      "adjDefRank": 340,
      "adjEM": -9.08,
      "adjOff": 104.7,
      "adjOffRank": 146,
      "adjTempo": 66.6,
      "adjTempoRank": 137,
      "conference": "OVC",
      "rank": 256,
      "record": "15-17"
    },
    "Texas": {
      "adjDef": 93.1,
      "adjDefRank": 30,
      "adjEM": 20.75,
      "adjOff": 113.9,
      "adjOffRank": 24,
      "adjTempo": 71.5,
      "adjTempoRank": 11,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 19,
      "record": "24-10",
      "seed": 8
    },
    "Texas A&M": {
      "adjDef": 91.2,
      "adjDefRank": 17,
      "adjEM": 21.33,
      "adjOff": 112.6,
      "adjOffRank": 37,
      "adjTempo": 64.3,
      "adjTempoRank": 243,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 16,
      "record": "24-10",
      "seed": 5
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 99.5,
      "adjDefRank": 105,
      "adjEM": -0.09,
      "adjOff": 99.4,
      "adjOffRank": 226,
      "adjTempo": 64.7,
      "adjTempoRank": 228,
      "conference": "Slnd",
      "rank": 171,
      "record": "17-15"
    },
    "Texas Pan American": {
      "adjDef": 108,
      "adjDefRank": 274,
      "adjEM": -18.56,
      "adjOff": 89.5,
      "adjOffRank": 329,
      "adjTempo": 66.3,
      "adjTempoRank": 153,
      "conference": "GWC",
      "rank": 320,
      "record": "6-27"
    },
    "Texas Southern": {
      "adjDef": 103.9,
      "adjDefRank": 200,
      "adjEM": -10.52,
      "adjOff": 93.4,
      "adjOffRank": 300,
      "adjTempo": 66.4,
      "adjTempoRank": 147,
      "conference": "SWAC",
      "rank": 267,
      "record": "17-16"
    },
    "Texas St.": {
      "adjDef": 104.8,
      "adjDefRank": 218,
      "adjEM": -6.32,
      "adjOff": 98.5,
      "adjOffRank": 238,
      "adjTempo": 74.9,
      "adjTempoRank": 3,
      "conference": "Slnd",
      "rank": 229,
      "record": "15-16"
    },
    "Texas Tech": {
      "adjDef": 97.6,
      "adjDefRank": 80,
      "adjEM": 11.14,
      "adjOff": 108.8,
      "adjOffRank": 78,
      "adjTempo": 70.7,
      "adjTempoRank": 19,
      "conference": "B12",
      "rank": 72,
      "record": "19-16"
    },
    "The Citadel": {
      "adjDef": 102.3,
      "adjDefRank": 165,
      "adjEM": -1.96,
      "adjOff": 100.3,
      "adjOffRank": 206,
      "adjTempo": 59.4,
      "adjTempoRank": 338,
      "conference": "SC",
      "rank": 197,
      "record": "16-16"
    },
    "Toledo": {
      "adjDef": 108.3,
      "adjDefRank": 277,
      "adjEM": -19.54,
      "adjOff": 88.8,
      "adjOffRank": 335,
      "adjTempo": 60.6,
      "adjTempoRank": 331,
      "conference": "MAC",
      "rank": 325,
      "record": "4-28"
    },
    "Towson": {
      "adjDef": 110.5,
      "adjDefRank": 304,
      "adjEM": -7.91,
      "adjOff": 102.5,
      "adjOffRank": 175,
      "adjTempo": 67.8,
      "adjTempoRank": 78,
      "conference": "CAA",
      "rank": 244,
      "record": "10-21"
    },
    "Troy": {
      "adjDef": 105.8,
      "adjDefRank": 236,
      "adjEM": -0.69,
      "adjOff": 105.1,
      "adjOffRank": 144,
      "adjTempo": 66,
      "adjTempoRank": 175,
      "conference": "SB",
      "rank": 180,
      "record": "20-13"
    },
    "Tulane": {
      "adjDef": 100.7,
      "adjDefRank": 126,
      "adjEM": -5.58,
      "adjOff": 95.1,
      "adjOffRank": 287,
      "adjTempo": 65.4,
      "adjTempoRank": 201,
      "conference": "CUSA",
      "rank": 224,
      "record": "8-22"
    },
    "Tulsa": {
      "adjDef": 97.7,
      "adjDefRank": 82,
      "adjEM": 9.87,
      "adjOff": 107.6,
      "adjOffRank": 97,
      "adjTempo": 66,
      "adjTempoRank": 172,
      "conference": "CUSA",
      "rank": 83,
      "record": "23-12"
    },
    "UAB": {
      "adjDef": 91.8,
      "adjDefRank": 22,
      "adjEM": 12.73,
      "adjOff": 104.6,
      "adjOffRank": 148,
      "adjTempo": 64.1,
      "adjTempoRank": 257,
      "conference": "CUSA",
      "rank": 62,
      "record": "25-9"
    },
    "UC Davis": {
      "adjDef": 106.7,
      "adjDefRank": 251,
      "adjEM": -5.77,
      "adjOff": 101,
      "adjOffRank": 197,
      "adjTempo": 66.4,
      "adjTempoRank": 148,
      "conference": "BW",
      "rank": 226,
      "record": "14-18"
    },
    "UC Irvine": {
      "adjDef": 109.6,
      "adjDefRank": 297,
      "adjEM": -8.52,
      "adjOff": 101.1,
      "adjOffRank": 195,
      "adjTempo": 63.4,
      "adjTempoRank": 275,
      "conference": "BW",
      "rank": 252,
      "record": "14-18"
    },
    "UC Riverside": {
      "adjDef": 104.5,
      "adjDefRank": 213,
      "adjEM": -6.38,
      "adjOff": 98.2,
      "adjOffRank": 242,
      "adjTempo": 60.9,
      "adjTempoRank": 328,
      "conference": "BW",
      "rank": 230,
      "record": "12-17"
    },
    "UC Santa Barbara": {
      "adjDef": 97.9,
      "adjDefRank": 86,
      "adjEM": 2.11,
      "adjOff": 100,
      "adjOffRank": 212,
      "adjTempo": 66.3,
      "adjTempoRank": 150,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 153,
      "record": "20-10",
      "seed": 15
    },
    "UCF": {
      "adjDef": 102.3,
      "adjDefRank": 164,
      "adjEM": 0.33,
      "adjOff": 102.6,
      "adjOffRank": 173,
      "adjTempo": 63.4,
      "adjTempoRank": 279,
      "conference": "CUSA",
      "rank": 166,
      "record": "15-17"
    },
    "UCLA": {
      "adjDef": 100.9,
      "adjDefRank": 133,
      "adjEM": 5.48,
      "adjOff": 106.4,
      "adjOffRank": 118,
      "adjTempo": 64.3,
      "adjTempoRank": 246,
      "conference": "P10",
      "rank": 117,
      "record": "14-18"
    },
    "UMBC": {
      "adjDef": 114.7,
      "adjDefRank": 344,
      "adjEM": -19.27,
      "adjOff": 95.5,
      "adjOffRank": 285,
      "adjTempo": 66.2,
      "adjTempoRank": 162,
      "conference": "AE",
      "rank": 322,
      "record": "4-26"
    },
    "UMKC": {
      "adjDef": 111.9,
      "adjDefRank": 330,
      "adjEM": -13.67,
      "adjOff": 98.2,
      "adjOffRank": 241,
      "adjTempo": 64.9,
      "adjTempoRank": 223,
      "conference": "Sum",
      "rank": 299,
      "record": "12-18"
    },
    "UNC Asheville": {
      "adjDef": 108.7,
      "adjDefRank": 285,
      "adjEM": -11.43,
      "adjOff": 97.2,
      "adjOffRank": 258,
      "adjTempo": 70.8,
      "adjTempoRank": 17,
      "conference": "BSth",
      "rank": 276,
      "record": "15-16"
    },
    "UNC Greensboro": {
      "adjDef": 104.8,
      "adjDefRank": 219,
      "adjEM": -8.61,
      "adjOff": 96.2,
      "adjOffRank": 277,
      "adjTempo": 66.6,
      "adjTempoRank": 132,
      "conference": "SC",
      "rank": 253,
      "record": "8-23"
    },
    "UNC Wilmington": {
      "adjDef": 102.6,
      "adjDefRank": 170,
      "adjEM": -4.02,
      "adjOff": 98.6,
      "adjOffRank": 237,
      "adjTempo": 67.8,
      "adjTempoRank": 79,
      "conference": "CAA",
      "rank": 212,
      "record": "9-22"
    },
    "UNLV": {
      "adjDef": 94,
      "adjDefRank": 40,
      "adjEM": 16.56,
      "adjOff": 110.6,
      "adjOffRank": 55,
      "adjTempo": 66.3,
      "adjTempoRank": 156,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 41,
      "record": "25-9",
      "seed": 8
    },
    "USC": {
      "adjDef": 86.1,
      "adjDefRank": 1,
      "adjEM": 10.63,
      "adjOff": 96.7,
      "adjOffRank": 267,
      "adjTempo": 62.2,
      "adjTempoRank": 307,
      "conference": "P10",
      "rank": 77,
      "record": "16-14"
    },
    "USC Upstate": {
      "adjDef": 103.6,
      "adjDefRank": 188,
      "adjEM": -11.63,
      "adjOff": 92,
      "adjOffRank": 312,
      "adjTempo": 65.1,
      "adjTempoRank": 218,
      "conference": "ASun",
      "rank": 277,
      "record": "6-23"
    },
    "UT Arlington": {
      "adjDef": 103.6,
      "adjDefRank": 193,
      "adjEM": -7.73,
      "adjOff": 95.9,
      "adjOffRank": 282,
      "adjTempo": 70.9,
      "adjTempoRank": 16,
      "conference": "Slnd",
      "rank": 241,
      "record": "16-14"
    },
    "UTEP": {
      "adjDef": 91.2,
      "adjDefRank": 16,
      "adjEM": 16.53,
      "adjOff": 107.7,
      "adjOffRank": 95,
      "adjTempo": 67.9,
      "adjTempoRank": 76,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 42,
      "record": "26-7",
      "seed": 12
    },
    "UTSA": {
      "adjDef": 106,
      "adjDefRank": 240,
      "adjEM": -2.1,
      "adjOff": 103.9,
      "adjOffRank": 153,
      "adjTempo": 62.9,
      "adjTempoRank": 290,
      "conference": "Slnd",
      "rank": 198,
      "record": "19-11"
    },
    "Utah": {
      "adjDef": 98,
      "adjDefRank": 87,
      "adjEM": 4.86,
      "adjOff": 102.9,
      "adjOffRank": 170,
      "adjTempo": 64.8,
      "adjTempoRank": 224,
      "conference": "MWC",
      "rank": 124,
      "record": "14-17"
    },
    "Utah St.": {
      "adjDef": 96.1,
      "adjDefRank": 62,
      "adjEM": 19.9,
      "adjOff": 116,
      "adjOffRank": 16,
      "adjTempo": 59.8,
      "adjTempoRank": 336,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "27-8",
      "seed": 12
    },
    "Utah Valley": {
      "adjDef": 109.4,
      "adjDefRank": 293,
      "adjEM": -19.59,
      "adjOff": 89.8,
      "adjOffRank": 326,
      "adjTempo": 61.1,
      "adjTempoRank": 325,
      "conference": "GWC",
      "rank": 326,
      "record": "12-18"
    },
    "VCU": {
      "adjDef": 98.8,
      "adjDefRank": 98,
      "adjEM": 14.39,
      "adjOff": 113.2,
      "adjOffRank": 28,
      "adjTempo": 68.2,
      "adjTempoRank": 69,
      "conference": "CAA",
      "rank": 56,
      "record": "27-9"
    },
    "VMI": {
      "adjDef": 120.5,
      "adjDefRank": 347,
      "adjEM": -18.1,
      "adjOff": 102.4,
      "adjOffRank": 178,
      "adjTempo": 83.4,
      "adjTempoRank": 1,
      "conference": "BSth",
      "rank": 317,
      "record": "10-19"
    },
    "Valparaiso": {
      "adjDef": 111.6,
      "adjDefRank": 326,
      "adjEM": -0.92,
      "adjOff": 110.7,
      "adjOffRank": 53,
      "adjTempo": 67,
      "adjTempoRank": 116,
      "conference": "Horz",
      "rank": 183,
      "record": "15-17"
    },
    "Vanderbilt": {
      "adjDef": 96.2,
      "adjDefRank": 64,
      "adjEM": 17.54,
      "adjOff": 113.7,
      "adjOffRank": 25,
      "adjTempo": 68.7,
      "adjTempoRank": 56,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 36,
      "record": "24-9",
      "seed": 4
    },
    "Vermont": {
      "adjDef": 101,
      "adjDefRank": 136,
      "adjEM": 3.08,
      "adjOff": 104.1,
      "adjOffRank": 151,
      "adjTempo": 66.1,
      "adjTempoRank": 167,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 136,
      "record": "25-10",
      "seed": 16
    },
    "Villanova": {
      "adjDef": 96,
      "adjDefRank": 59,
      "adjEM": 21.24,
      "adjOff": 117.2,
      "adjOffRank": 11,
      "adjTempo": 71.2,
      "adjTempoRank": 12,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 17,
      "record": "25-8",
      "seed": 2
    },
    "Virginia": {
      "adjDef": 97.2,
      "adjDefRank": 71,
      "adjEM": 10.76,
      "adjOff": 108,
      "adjOffRank": 87,
      "adjTempo": 61.7,
      "adjTempoRank": 316,
      "conference": "ACC",
      "rank": 76,
      "record": "15-16"
    },
    "Virginia Tech": {
      "adjDef": 92.4,
      "adjDefRank": 24,
      "adjEM": 15.86,
      "adjOff": 108.3,
      "adjOffRank": 84,
      "adjTempo": 67.8,
      "adjTempoRank": 80,
      "conference": "ACC",
      "rank": 47,
      "record": "25-9"
    },
    "Wagner": {
      "adjDef": 111.1,
      "adjDefRank": 316,
      "adjEM": -22,
      "adjOff": 89.1,
      "adjOffRank": 332,
      "adjTempo": 65.7,
      "adjTempoRank": 185,
      "conference": "NEC",
      "rank": 335,
      "record": "5-26"
    },
    "Wake Forest": {
      "adjDef": 93,
      "adjDefRank": 29,
      "adjEM": 14.12,
      "adjOff": 107.1,
      "adjOffRank": 106,
      "adjTempo": 68.7,
      "adjTempoRank": 58,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 58,
      "record": "20-11",
      "seed": 9
    },
    "Washington": {
      "adjDef": 93.6,
      "adjDefRank": 33,
      "adjEM": 18.19,
      "adjOff": 111.7,
      "adjOffRank": 44,
      "adjTempo": 71.7,
      "adjTempoRank": 10,
      "conference": "P10",
      "finish": "Sweet 16",
      "rank": 32,
      "record": "26-10",
      "seed": 11
    },
    "Washington St.": {
      "adjDef": 102.5,
      "adjDefRank": 167,
      "adjEM": 5.67,
      "adjOff": 108.2,
      "adjOffRank": 85,
      "adjTempo": 66.1,
      "adjTempoRank": 165,
      "conference": "P10",
      "rank": 115,
      "record": "16-15"
    },
    "Weber St.": {
      "adjDef": 102.2,
      "adjDefRank": 163,
      "adjEM": 6.96,
      "adjOff": 109.2,
      "adjOffRank": 72,
      "adjTempo": 67.7,
      "adjTempoRank": 85,
      "conference": "BSky",
      "rank": 105,
      "record": "20-11"
    },
    "West Virginia": {
      "adjDef": 91.7,
      "adjDefRank": 21,
      "adjEM": 25.54,
      "adjOff": 117.2,
      "adjOffRank": 12,
      "adjTempo": 62.6,
      "adjTempoRank": 296,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 5,
      "record": "31-7",
      "seed": 2
    },
    "Western Carolina": {
      "adjDef": 103.6,
      "adjDefRank": 187,
      "adjEM": 1.76,
      "adjOff": 105.3,
      "adjOffRank": 140,
      "adjTempo": 69.2,
      "adjTempoRank": 42,
      "conference": "SC",
      "rank": 155,
      "record": "22-12"
    },
    "Western Illinois": {
      "adjDef": 102,
      "adjDefRank": 159,
      "adjEM": -10.65,
      "adjOff": 91.4,
      "adjOffRank": 317,
      "adjTempo": 60.7,
      "adjTempoRank": 330,
      "conference": "Sum",
      "rank": 269,
      "record": "13-17"
    },
    "Western Kentucky": {
      "adjDef": 101.5,
      "adjDefRank": 150,
      "adjEM": 4.39,
      "adjOff": 105.9,
      "adjOffRank": 128,
      "adjTempo": 64.5,
      "adjTempoRank": 237,
      "conference": "SB",
      "rank": 127,
      "record": "21-13"
    },
    "Western Michigan": {
      "adjDef": 103.7,
      "adjDefRank": 194,
      "adjEM": 3.07,
      "adjOff": 106.7,
      "adjOffRank": 108,
      "adjTempo": 65.9,
      "adjTempoRank": 179,
      "conference": "MAC",
      "rank": 137,
      "record": "18-15"
    },
    "Wichita St.": {
      "adjDef": 96.6,
      "adjDefRank": 69,
      "adjEM": 12.26,
      "adjOff": 108.9,
      "adjOffRank": 76,
      "adjTempo": 64.5,
      "adjTempoRank": 238,
      "conference": "MVC",
      "rank": 64,
      "record": "25-10"
    },
    "William & Mary": {
      "adjDef": 103.4,
      "adjDefRank": 185,
      "adjEM": 6.58,
      "adjOff": 110,
      "adjOffRank": 60,
      "adjTempo": 60.2,
      "adjTempoRank": 335,
      "conference": "CAA",
      "rank": 107,
      "record": "22-11"
    },
    "Winston Salem St.": {
      "adjDef": 107.6,
      "adjDefRank": 267,
      "adjEM": -20.66,
      "adjOff": 86.9,
      "adjOffRank": 339,
      "adjTempo": 66.4,
      "adjTempoRank": 146,
      "conference": "ind",
      "rank": 330,
      "record": "12-17"
    },
    "Winthrop": {
      "adjDef": 96.3,
      "adjDefRank": 65,
      "adjEM": -5.63,
      "adjOff": 90.7,
      "adjOffRank": 320,
      "adjTempo": 64,
      "adjTempoRank": 259,
      "conference": "BSth",
      "finish": "Play-in",
      "rank": 225,
      "record": "19-14",
      "seed": 16
    },
    "Wisconsin": {
      "adjDef": 91.6,
      "adjDefRank": 19,
      "adjEM": 23.56,
      "adjOff": 115.2,
      "adjOffRank": 20,
      "adjTempo": 59.2,
      "adjTempoRank": 339,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 9,
      "record": "24-9",
      "seed": 4
    },
    "Wofford": {
      "adjDef": 94.4,
      "adjDefRank": 45,
      "adjEM": 10.12,
      "adjOff": 104.6,
      "adjOffRank": 147,
      "adjTempo": 63.5,
      "adjTempoRank": 273,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 82,
      "record": "26-9",
      "seed": 13
    },
    "Wright St.": {
      "adjDef": 96.5,
      "adjDefRank": 68,
      "adjEM": 12.1,
      "adjOff": 108.6,
      "adjOffRank": 80,
      "adjTempo": 61.8,
      "adjTempoRank": 313,
      "conference": "Horz",
      "rank": 65,
      "record": "20-12"
    },
    "Wyoming": {
      "adjDef": 104.9,
      "adjDefRank": 222,
      "adjEM": -7.5,
      "adjOff": 97.4,
      "adjOffRank": 255,
      "adjTempo": 70.1,
      "adjTempoRank": 26,
      "conference": "MWC",
      "rank": 239,
      "record": "10-21"
    },
    "Xavier": {
      "adjDef": 94.6,
      "adjDefRank": 47,
      "adjEM": 20.87,
      "adjOff": 115.5,
      "adjOffRank": 18,
      "adjTempo": 68.9,
      "adjTempoRank": 47,
      "conference": "A10",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "26-9",
      "seed": 6
    },
    "Yale": {
      "adjDef": 106.5,
      "adjDefRank": 248,
      "adjEM": -10.34,
      "adjOff": 96.1,
      "adjOffRank": 279,
      "adjTempo": 66.4,
      "adjTempoRank": 149,
      "conference": "Ivy",
      "rank": 263,
      "record": "12-19"
    },
    "Youngstown St.": {
      "adjDef": 105.6,
      "adjDefRank": 232,
      "adjEM": -6.2,
      "adjOff": 99.4,
      "adjOffRank": 224,
      "adjTempo": 67.1,
      "adjTempoRank": 114,
      "conference": "Horz",
      "rank": 227,
      "record": "8-22"
    }
  },
  "2011": {
    "Air Force": {
      "adjDef": 103.8,
      "adjDefRank": 182,
      "adjEM": 3.43,
      "adjOff": 107.2,
      "adjOffRank": 95,
      "adjTempo": 61.1,
      "adjTempoRank": 325,
      "conference": "MWC",
      "rank": 126,
      "record": "16-16"
    },
    "Akron": {
      "adjDef": 99.6,
      "adjDefRank": 94,
      "adjEM": 4.09,
      "adjOff": 103.7,
      "adjOffRank": 163,
      "adjTempo": 65.9,
      "adjTempoRank": 144,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 118,
      "record": "23-13",
      "seed": 15
    },
    "Alabama": {
      "adjDef": 90.9,
      "adjDefRank": 7,
      "adjEM": 13.7,
      "adjOff": 104.6,
      "adjOffRank": 144,
      "adjTempo": 63.9,
      "adjTempoRank": 245,
      "conference": "SEC",
      "rank": 51,
      "record": "25-12"
    },
    "Alabama A&M": {
      "adjDef": 103.7,
      "adjDefRank": 179,
      "adjEM": -16.65,
      "adjOff": 87,
      "adjOffRank": 339,
      "adjTempo": 69.7,
      "adjTempoRank": 26,
      "conference": "SWAC",
      "rank": 323,
      "record": "13-15"
    },
    "Alabama St.": {
      "adjDef": 106,
      "adjDefRank": 235,
      "adjEM": -13.19,
      "adjOff": 92.8,
      "adjOffRank": 318,
      "adjTempo": 63.6,
      "adjTempoRank": 256,
      "conference": "SWAC",
      "finish": "First Four",
      "rank": 303,
      "record": "17-18",
      "seed": 16
    },
    "Albany": {
      "adjDef": 108.6,
      "adjDefRank": 276,
      "adjEM": -6.86,
      "adjOff": 101.7,
      "adjOffRank": 203,
      "adjTempo": 59.9,
      "adjTempoRank": 333,
      "conference": "AE",
      "rank": 243,
      "record": "16-16"
    },
    "Alcorn St.": {
      "adjDef": 111.5,
      "adjDefRank": 317,
      "adjEM": -25.27,
      "adjOff": 86.2,
      "adjOffRank": 342,
      "adjTempo": 75.7,
      "adjTempoRank": 1,
      "conference": "SWAC",
      "rank": 341,
      "record": "4-24"
    },
    "American": {
      "adjDef": 104.7,
      "adjDefRank": 205,
      "adjEM": -0.64,
      "adjOff": 104.1,
      "adjOffRank": 156,
      "adjTempo": 60.3,
      "adjTempoRank": 331,
      "conference": "Pat",
      "rank": 173,
      "record": "22-9"
    },
    "Appalachian St.": {
      "adjDef": 109,
      "adjDefRank": 283,
      "adjEM": -4.38,
      "adjOff": 104.7,
      "adjOffRank": 143,
      "adjTempo": 66,
      "adjTempoRank": 134,
      "conference": "SC",
      "rank": 212,
      "record": "16-15"
    },
    "Arizona": {
      "adjDef": 97.8,
      "adjDefRank": 67,
      "adjEM": 19.65,
      "adjOff": 117.4,
      "adjOffRank": 12,
      "adjTempo": 65.3,
      "adjTempoRank": 170,
      "conference": "P10",
      "finish": "Elite Eight",
      "rank": 20,
      "record": "30-8",
      "seed": 5
    },
    "Arizona St.": {
      "adjDef": 101.3,
      "adjDefRank": 124,
      "adjEM": 2.68,
      "adjOff": 104,
      "adjOffRank": 160,
      "adjTempo": 62.6,
      "adjTempoRank": 296,
      "conference": "P10",
      "rank": 139,
      "record": "12-19"
    },
    "Arkansas": {
      "adjDef": 101,
      "adjDefRank": 120,
      "adjEM": 5.15,
      "adjOff": 106.2,
      "adjOffRank": 110,
      "adjTempo": 66,
      "adjTempoRank": 139,
      "conference": "SEC",
      "rank": 113,
      "record": "18-13"
    },
    "Arkansas Little Rock": {
      "adjDef": 105.6,
      "adjDefRank": 229,
      "adjEM": -4.53,
      "adjOff": 101.1,
      "adjOffRank": 215,
      "adjTempo": 64.1,
      "adjTempoRank": 236,
      "conference": "SB",
      "finish": "First Four",
      "rank": 215,
      "record": "19-17",
      "seed": 16
    },
    "Arkansas Pine Bluff": {
      "adjDef": 111.2,
      "adjDefRank": 311,
      "adjEM": -22.25,
      "adjOff": 88.9,
      "adjOffRank": 334,
      "adjTempo": 65.7,
      "adjTempoRank": 151,
      "conference": "SWAC",
      "rank": 338,
      "record": "7-24"
    },
    "Arkansas St.": {
      "adjDef": 103.6,
      "adjDefRank": 177,
      "adjEM": -2.73,
      "adjOff": 100.9,
      "adjOffRank": 217,
      "adjTempo": 64.1,
      "adjTempoRank": 239,
      "conference": "SB",
      "rank": 195,
      "record": "17-15"
    },
    "Army": {
      "adjDef": 110,
      "adjDefRank": 299,
      "adjEM": -10.54,
      "adjOff": 99.5,
      "adjOffRank": 243,
      "adjTempo": 66.9,
      "adjTempoRank": 86,
      "conference": "Pat",
      "rank": 279,
      "record": "11-19"
    },
    "Auburn": {
      "adjDef": 102.7,
      "adjDefRank": 150,
      "adjEM": -5.75,
      "adjOff": 96.9,
      "adjOffRank": 279,
      "adjTempo": 64.4,
      "adjTempoRank": 225,
      "conference": "SEC",
      "rank": 234,
      "record": "11-20"
    },
    "Austin Peay": {
      "adjDef": 100.9,
      "adjDefRank": 118,
      "adjEM": 2.66,
      "adjOff": 103.6,
      "adjOffRank": 166,
      "adjTempo": 66.6,
      "adjTempoRank": 99,
      "conference": "OVC",
      "rank": 140,
      "record": "20-14"
    },
    "BYU": {
      "adjDef": 94.3,
      "adjDefRank": 29,
      "adjEM": 23.22,
      "adjOff": 117.5,
      "adjOffRank": 11,
      "adjTempo": 70.2,
      "adjTempoRank": 17,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 12,
      "record": "32-5",
      "seed": 3
    },
    "Ball St.": {
      "adjDef": 103.3,
      "adjDefRank": 167,
      "adjEM": 0.03,
      "adjOff": 103.4,
      "adjOffRank": 171,
      "adjTempo": 63.6,
      "adjTempoRank": 260,
      "conference": "MAC",
      "rank": 164,
      "record": "19-13"
    },
    "Baylor": {
      "adjDef": 98.1,
      "adjDefRank": 74,
      "adjEM": 9.04,
      "adjOff": 107.1,
      "adjOffRank": 99,
      "adjTempo": 64.9,
      "adjTempoRank": 192,
      "conference": "B12",
      "rank": 80,
      "record": "18-13"
    },
    "Belmont": {
      "adjDef": 94.1,
      "adjDefRank": 28,
      "adjEM": 18.48,
      "adjOff": 112.6,
      "adjOffRank": 40,
      "adjTempo": 68.4,
      "adjTempoRank": 44,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 23,
      "record": "30-5",
      "seed": 13
    },
    "Bethune Cookman": {
      "adjDef": 112.1,
      "adjDefRank": 322,
      "adjEM": -12.02,
      "adjOff": 100,
      "adjOffRank": 234,
      "adjTempo": 61,
      "adjTempoRank": 327,
      "conference": "MEAC",
      "rank": 291,
      "record": "21-13"
    },
    "Binghamton": {
      "adjDef": 113.6,
      "adjDefRank": 335,
      "adjEM": -15.89,
      "adjOff": 97.7,
      "adjOffRank": 271,
      "adjTempo": 61.6,
      "adjTempoRank": 319,
      "conference": "AE",
      "rank": 315,
      "record": "8-23"
    },
    "Boise St.": {
      "adjDef": 100,
      "adjDefRank": 101,
      "adjEM": 8.22,
      "adjOff": 108.2,
      "adjOffRank": 85,
      "adjTempo": 67.3,
      "adjTempoRank": 67,
      "conference": "WAC",
      "rank": 83,
      "record": "22-13"
    },
    "Boston College": {
      "adjDef": 105.5,
      "adjDefRank": 225,
      "adjEM": 11.36,
      "adjOff": 116.8,
      "adjOffRank": 14,
      "adjTempo": 62.8,
      "adjTempoRank": 286,
      "conference": "ACC",
      "rank": 67,
      "record": "21-13"
    },
    "Boston University": {
      "adjDef": 103.9,
      "adjDefRank": 186,
      "adjEM": -0.5,
      "adjOff": 103.4,
      "adjOffRank": 169,
      "adjTempo": 62.6,
      "adjTempoRank": 291,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 170,
      "record": "21-14",
      "seed": 16
    },
    "Bowling Green": {
      "adjDef": 104.5,
      "adjDefRank": 201,
      "adjEM": -6.76,
      "adjOff": 97.8,
      "adjOffRank": 269,
      "adjTempo": 64.1,
      "adjTempoRank": 237,
      "conference": "MAC",
      "rank": 242,
      "record": "14-19"
    },
    "Bradley": {
      "adjDef": 103.7,
      "adjDefRank": 180,
      "adjEM": -2.45,
      "adjOff": 101.3,
      "adjOffRank": 211,
      "adjTempo": 65.7,
      "adjTempoRank": 152,
      "conference": "MVC",
      "rank": 193,
      "record": "12-20"
    },
    "Brown": {
      "adjDef": 109.4,
      "adjDefRank": 287,
      "adjEM": -6.48,
      "adjOff": 102.9,
      "adjOffRank": 179,
      "adjTempo": 68,
      "adjTempoRank": 51,
      "conference": "Ivy",
      "rank": 238,
      "record": "11-17"
    },
    "Bryant": {
      "adjDef": 114.3,
      "adjDefRank": 339,
      "adjEM": -13.81,
      "adjOff": 100.5,
      "adjOffRank": 225,
      "adjTempo": 64.7,
      "adjTempoRank": 208,
      "conference": "NEC",
      "rank": 305,
      "record": "9-21"
    },
    "Bucknell": {
      "adjDef": 99.2,
      "adjDefRank": 88,
      "adjEM": 6.57,
      "adjOff": 105.8,
      "adjOffRank": 116,
      "adjTempo": 64.5,
      "adjTempoRank": 216,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 96,
      "record": "25-9",
      "seed": 14
    },
    "Buffalo": {
      "adjDef": 99.1,
      "adjDefRank": 85,
      "adjEM": 4.92,
      "adjOff": 104,
      "adjOffRank": 157,
      "adjTempo": 65.7,
      "adjTempoRank": 154,
      "conference": "MAC",
      "rank": 115,
      "record": "20-14"
    },
    "Butler": {
      "adjDef": 95.9,
      "adjDefRank": 46,
      "adjEM": 16.46,
      "adjOff": 112.3,
      "adjOffRank": 43,
      "adjTempo": 63.5,
      "adjTempoRank": 262,
      "conference": "Horz",
      "finish": "Runner-up",
      "rank": 36,
      "record": "28-10",
      "seed": 8
    },
    "Cal Poly": {
      "adjDef": 98,
      "adjDefRank": 70,
      "adjEM": -0.13,
      "adjOff": 97.9,
      "adjOffRank": 265,
      "adjTempo": 59.6,
      "adjTempoRank": 335,
      "conference": "BW",
      "rank": 167,
      "record": "15-15"
    },
    "Cal St. Bakersfield": {
      "adjDef": 111.4,
      "adjDefRank": 314,
      "adjEM": -14.32,
      "adjOff": 97.1,
      "adjOffRank": 277,
      "adjTempo": 67.3,
      "adjTempoRank": 69,
      "conference": "ind",
      "rank": 307,
      "record": "9-19"
    },
    "Cal St. Fullerton": {
      "adjDef": 113.3,
      "adjDefRank": 332,
      "adjEM": -8.53,
      "adjOff": 104.8,
      "adjOffRank": 138,
      "adjTempo": 67.5,
      "adjTempoRank": 64,
      "conference": "BW",
      "rank": 258,
      "record": "11-20"
    },
    "Cal St. Northridge": {
      "adjDef": 105.7,
      "adjDefRank": 230,
      "adjEM": -5.08,
      "adjOff": 100.6,
      "adjOffRank": 223,
      "adjTempo": 67.7,
      "adjTempoRank": 57,
      "conference": "BW",
      "rank": 223,
      "record": "14-18"
    },
    "California": {
      "adjDef": 99.8,
      "adjDefRank": 99,
      "adjEM": 10.02,
      "adjOff": 109.9,
      "adjOffRank": 68,
      "adjTempo": 67.5,
      "adjTempoRank": 61,
      "conference": "P10",
      "rank": 75,
      "record": "18-15"
    },
    "Campbell": {
      "adjDef": 105.5,
      "adjDefRank": 224,
      "adjEM": -7.71,
      "adjOff": 97.8,
      "adjOffRank": 270,
      "adjTempo": 64,
      "adjTempoRank": 242,
      "conference": "ASun",
      "rank": 248,
      "record": "12-19"
    },
    "Canisius": {
      "adjDef": 104.6,
      "adjDefRank": 202,
      "adjEM": -1.61,
      "adjOff": 103,
      "adjOffRank": 177,
      "adjTempo": 64.7,
      "adjTempoRank": 207,
      "conference": "MAAC",
      "rank": 183,
      "record": "15-15"
    },
    "Centenary": {
      "adjDef": 113.2,
      "adjDefRank": 331,
      "adjEM": -29.25,
      "adjOff": 84,
      "adjOffRank": 344,
      "adjTempo": 68.7,
      "adjTempoRank": 38,
      "conference": "Sum",
      "rank": 344,
      "record": "1-29"
    },
    "Central Arkansas": {
      "adjDef": 112.6,
      "adjDefRank": 326,
      "adjEM": -22.05,
      "adjOff": 90.5,
      "adjOffRank": 330,
      "adjTempo": 69.6,
      "adjTempoRank": 27,
      "conference": "Slnd",
      "rank": 336,
      "record": "5-24"
    },
    "Central Connecticut": {
      "adjDef": 100.1,
      "adjDefRank": 104,
      "adjEM": 0.75,
      "adjOff": 100.8,
      "adjOffRank": 219,
      "adjTempo": 66,
      "adjTempoRank": 141,
      "conference": "NEC",
      "rank": 157,
      "record": "19-12"
    },
    "Central Michigan": {
      "adjDef": 102,
      "adjDefRank": 133,
      "adjEM": -10.04,
      "adjOff": 92,
      "adjOffRank": 323,
      "adjTempo": 63.1,
      "adjTempoRank": 276,
      "conference": "MAC",
      "rank": 273,
      "record": "10-21"
    },
    "Charleston Southern": {
      "adjDef": 107.2,
      "adjDefRank": 252,
      "adjEM": -4.37,
      "adjOff": 102.9,
      "adjOffRank": 180,
      "adjTempo": 65.9,
      "adjTempoRank": 147,
      "conference": "BSth",
      "rank": 211,
      "record": "16-16"
    },
    "Charlotte": {
      "adjDef": 105.5,
      "adjDefRank": 228,
      "adjEM": -4.04,
      "adjOff": 101.5,
      "adjOffRank": 206,
      "adjTempo": 63.7,
      "adjTempoRank": 254,
      "conference": "A10",
      "rank": 207,
      "record": "10-20"
    },
    "Chattanooga": {
      "adjDef": 108.5,
      "adjDefRank": 274,
      "adjEM": -9.36,
      "adjOff": 99.1,
      "adjOffRank": 249,
      "adjTempo": 68.1,
      "adjTempoRank": 49,
      "conference": "SC",
      "rank": 266,
      "record": "16-16"
    },
    "Chicago St.": {
      "adjDef": 118.4,
      "adjDefRank": 344,
      "adjEM": -28.6,
      "adjOff": 89.8,
      "adjOffRank": 331,
      "adjTempo": 70.1,
      "adjTempoRank": 19,
      "conference": "GWC",
      "rank": 343,
      "record": "6-26"
    },
    "Cincinnati": {
      "adjDef": 91.7,
      "adjDefRank": 13,
      "adjEM": 19.41,
      "adjOff": 111.1,
      "adjOffRank": 55,
      "adjTempo": 62.6,
      "adjTempoRank": 293,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 21,
      "record": "26-9",
      "seed": 6
    },
    "Clemson": {
      "adjDef": 91.5,
      "adjDefRank": 11,
      "adjEM": 18.16,
      "adjOff": 109.6,
      "adjOffRank": 71,
      "adjTempo": 63.9,
      "adjTempoRank": 248,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 25,
      "record": "22-12",
      "seed": 12
    },
    "Cleveland St.": {
      "adjDef": 98,
      "adjDefRank": 72,
      "adjEM": 11.06,
      "adjOff": 109.1,
      "adjOffRank": 74,
      "adjTempo": 65,
      "adjTempoRank": 185,
      "conference": "Horz",
      "rank": 68,
      "record": "27-9"
    },
    "Coastal Carolina": {
      "adjDef": 100.4,
      "adjDefRank": 110,
      "adjEM": 4.52,
      "adjOff": 104.9,
      "adjOffRank": 134,
      "adjTempo": 65.8,
      "adjTempoRank": 149,
      "conference": "BSth",
      "rank": 117,
      "record": "28-6"
    },
    "Colgate": {
      "adjDef": 112.5,
      "adjDefRank": 325,
      "adjEM": -15.36,
      "adjOff": 97.2,
      "adjOffRank": 275,
      "adjTempo": 66,
      "adjTempoRank": 140,
      "conference": "Pat",
      "rank": 314,
      "record": "7-23"
    },
    "College of Charleston": {
      "adjDef": 103.5,
      "adjDefRank": 173,
      "adjEM": 10.99,
      "adjOff": 114.5,
      "adjOffRank": 26,
      "adjTempo": 65.9,
      "adjTempoRank": 146,
      "conference": "SC",
      "rank": 70,
      "record": "26-11"
    },
    "Colorado": {
      "adjDef": 104.3,
      "adjDefRank": 191,
      "adjEM": 13.45,
      "adjOff": 117.7,
      "adjOffRank": 9,
      "adjTempo": 66.8,
      "adjTempoRank": 92,
      "conference": "B12",
      "rank": 54,
      "record": "24-14"
    },
    "Colorado St.": {
      "adjDef": 99.1,
      "adjDefRank": 84,
      "adjEM": 9.55,
      "adjOff": 108.6,
      "adjOffRank": 81,
      "adjTempo": 66.1,
      "adjTempoRank": 132,
      "conference": "MWC",
      "rank": 77,
      "record": "19-13"
    },
    "Columbia": {
      "adjDef": 105.8,
      "adjDefRank": 231,
      "adjEM": -4.5,
      "adjOff": 101.3,
      "adjOffRank": 209,
      "adjTempo": 66.8,
      "adjTempoRank": 90,
      "conference": "Ivy",
      "rank": 214,
      "record": "15-13"
    },
    "Connecticut": {
      "adjDef": 91.9,
      "adjDefRank": 15,
      "adjEM": 23.93,
      "adjOff": 115.8,
      "adjOffRank": 19,
      "adjTempo": 64.5,
      "adjTempoRank": 221,
      "conference": "BE",
      "finish": "Champion",
      "rank": 10,
      "record": "32-9",
      "seed": 3
    },
    "Coppin St.": {
      "adjDef": 108,
      "adjDefRank": 265,
      "adjEM": -9.6,
      "adjOff": 98.5,
      "adjOffRank": 261,
      "adjTempo": 68.2,
      "adjTempoRank": 48,
      "conference": "MEAC",
      "rank": 268,
      "record": "16-14"
    },
    "Cornell": {
      "adjDef": 107.6,
      "adjDefRank": 259,
      "adjEM": -2.42,
      "adjOff": 105.2,
      "adjOffRank": 127,
      "adjTempo": 65.1,
      "adjTempoRank": 179,
      "conference": "Ivy",
      "rank": 192,
      "record": "10-18"
    },
    "Creighton": {
      "adjDef": 103.5,
      "adjDefRank": 174,
      "adjEM": 6.38,
      "adjOff": 109.9,
      "adjOffRank": 66,
      "adjTempo": 63.6,
      "adjTempoRank": 258,
      "conference": "MVC",
      "rank": 98,
      "record": "23-16"
    },
    "Dartmouth": {
      "adjDef": 107.4,
      "adjDefRank": 256,
      "adjEM": -16.61,
      "adjOff": 90.8,
      "adjOffRank": 329,
      "adjTempo": 65.2,
      "adjTempoRank": 174,
      "conference": "Ivy",
      "rank": 321,
      "record": "5-23"
    },
    "Davidson": {
      "adjDef": 103.4,
      "adjDefRank": 169,
      "adjEM": 2.17,
      "adjOff": 105.6,
      "adjOffRank": 121,
      "adjTempo": 67.1,
      "adjTempoRank": 74,
      "conference": "SC",
      "rank": 146,
      "record": "18-15"
    },
    "Dayton": {
      "adjDef": 98.3,
      "adjDefRank": 77,
      "adjEM": 6.47,
      "adjOff": 104.8,
      "adjOffRank": 137,
      "adjTempo": 65.2,
      "adjTempoRank": 175,
      "conference": "A10",
      "rank": 97,
      "record": "22-14"
    },
    "DePaul": {
      "adjDef": 106.4,
      "adjDefRank": 239,
      "adjEM": -3.28,
      "adjOff": 103.1,
      "adjOffRank": 174,
      "adjTempo": 68.3,
      "adjTempoRank": 46,
      "conference": "BE",
      "rank": 197,
      "record": "7-24"
    },
    "Delaware": {
      "adjDef": 103.5,
      "adjDefRank": 171,
      "adjEM": -3.1,
      "adjOff": 100.4,
      "adjOffRank": 228,
      "adjTempo": 63.4,
      "adjTempoRank": 266,
      "conference": "CAA",
      "rank": 196,
      "record": "14-17"
    },
    "Delaware St.": {
      "adjDef": 113.1,
      "adjDefRank": 329,
      "adjEM": -13.99,
      "adjOff": 99.1,
      "adjOffRank": 247,
      "adjTempo": 59.4,
      "adjTempoRank": 337,
      "conference": "MEAC",
      "rank": 306,
      "record": "9-21"
    },
    "Denver": {
      "adjDef": 104.7,
      "adjDefRank": 203,
      "adjEM": -4.7,
      "adjOff": 100,
      "adjOffRank": 236,
      "adjTempo": 56.2,
      "adjTempoRank": 345,
      "conference": "SB",
      "rank": 219,
      "record": "13-17"
    },
    "Detroit": {
      "adjDef": 103.8,
      "adjDefRank": 183,
      "adjEM": 3.17,
      "adjOff": 107,
      "adjOffRank": 101,
      "adjTempo": 69.7,
      "adjTempoRank": 25,
      "conference": "Horz",
      "rank": 131,
      "record": "17-16"
    },
    "Drake": {
      "adjDef": 104.9,
      "adjDefRank": 211,
      "adjEM": -3.5,
      "adjOff": 101.4,
      "adjOffRank": 207,
      "adjTempo": 64.5,
      "adjTempoRank": 220,
      "conference": "MVC",
      "rank": 201,
      "record": "13-18"
    },
    "Drexel": {
      "adjDef": 92.9,
      "adjDefRank": 20,
      "adjEM": 6.82,
      "adjOff": 99.7,
      "adjOffRank": 239,
      "adjTempo": 62.9,
      "adjTempoRank": 281,
      "conference": "CAA",
      "rank": 93,
      "record": "21-10"
    },
    "Duke": {
      "adjDef": 91.3,
      "adjDefRank": 9,
      "adjEM": 28.42,
      "adjOff": 119.7,
      "adjOffRank": 5,
      "adjTempo": 68.6,
      "adjTempoRank": 40,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 2,
      "record": "32-5",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 94.7,
      "adjDefRank": 34,
      "adjEM": 13.36,
      "adjOff": 108,
      "adjOffRank": 89,
      "adjTempo": 70.9,
      "adjTempoRank": 11,
      "conference": "A10",
      "rank": 55,
      "record": "19-13"
    },
    "East Carolina": {
      "adjDef": 103.9,
      "adjDefRank": 184,
      "adjEM": 1.41,
      "adjOff": 105.3,
      "adjOffRank": 126,
      "adjTempo": 65,
      "adjTempoRank": 188,
      "conference": "CUSA",
      "rank": 154,
      "record": "18-16"
    },
    "East Tennessee St.": {
      "adjDef": 98.9,
      "adjDefRank": 82,
      "adjEM": 5.16,
      "adjOff": 104.1,
      "adjOffRank": 155,
      "adjTempo": 64.3,
      "adjTempoRank": 230,
      "conference": "ASun",
      "rank": 112,
      "record": "24-12"
    },
    "Eastern Illinois": {
      "adjDef": 106.4,
      "adjDefRank": 241,
      "adjEM": -13.32,
      "adjOff": 93.1,
      "adjOffRank": 312,
      "adjTempo": 62.7,
      "adjTempoRank": 290,
      "conference": "OVC",
      "rank": 304,
      "record": "9-20"
    },
    "Eastern Kentucky": {
      "adjDef": 108.9,
      "adjDefRank": 279,
      "adjEM": -6.56,
      "adjOff": 102.3,
      "adjOffRank": 190,
      "adjTempo": 60.8,
      "adjTempoRank": 329,
      "conference": "OVC",
      "rank": 239,
      "record": "15-16"
    },
    "Eastern Michigan": {
      "adjDef": 100.3,
      "adjDefRank": 109,
      "adjEM": -10.75,
      "adjOff": 89.5,
      "adjOffRank": 333,
      "adjTempo": 66.1,
      "adjTempoRank": 130,
      "conference": "MAC",
      "rank": 283,
      "record": "9-22"
    },
    "Eastern Washington": {
      "adjDef": 108.1,
      "adjDefRank": 267,
      "adjEM": -9.04,
      "adjOff": 99.1,
      "adjOffRank": 251,
      "adjTempo": 63.7,
      "adjTempoRank": 252,
      "conference": "BSky",
      "rank": 262,
      "record": "10-20"
    },
    "Elon": {
      "adjDef": 110.9,
      "adjDefRank": 307,
      "adjEM": -6.6,
      "adjOff": 104.3,
      "adjOffRank": 152,
      "adjTempo": 67.1,
      "adjTempoRank": 72,
      "conference": "SC",
      "rank": 240,
      "record": "14-17"
    },
    "Evansville": {
      "adjDef": 102.1,
      "adjDefRank": 135,
      "adjEM": -0.15,
      "adjOff": 101.9,
      "adjOffRank": 198,
      "adjTempo": 66.2,
      "adjTempoRank": 121,
      "conference": "MVC",
      "rank": 168,
      "record": "16-16"
    },
    "FIU": {
      "adjDef": 111.9,
      "adjDefRank": 321,
      "adjEM": -11.24,
      "adjOff": 100.6,
      "adjOffRank": 222,
      "adjTempo": 68.4,
      "adjTempoRank": 43,
      "conference": "SB",
      "rank": 286,
      "record": "11-19"
    },
    "Fairfield": {
      "adjDef": 92.8,
      "adjDefRank": 19,
      "adjEM": 5.88,
      "adjOff": 98.7,
      "adjOffRank": 257,
      "adjTempo": 65.2,
      "adjTempoRank": 176,
      "conference": "MAAC",
      "rank": 104,
      "record": "25-8"
    },
    "Fairleigh Dickinson": {
      "adjDef": 109.8,
      "adjDefRank": 296,
      "adjEM": -16.66,
      "adjOff": 93.2,
      "adjOffRank": 310,
      "adjTempo": 66.1,
      "adjTempoRank": 123,
      "conference": "NEC",
      "rank": 324,
      "record": "5-24"
    },
    "Florida": {
      "adjDef": 95.2,
      "adjDefRank": 38,
      "adjEM": 20.61,
      "adjOff": 115.8,
      "adjOffRank": 20,
      "adjTempo": 62.5,
      "adjTempoRank": 300,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 17,
      "record": "29-8",
      "seed": 2
    },
    "Florida A&M": {
      "adjDef": 107.1,
      "adjDefRank": 247,
      "adjEM": -18.59,
      "adjOff": 88.5,
      "adjOffRank": 335,
      "adjTempo": 66.4,
      "adjTempoRank": 108,
      "conference": "MEAC",
      "rank": 325,
      "record": "12-20"
    },
    "Florida Atlantic": {
      "adjDef": 104.8,
      "adjDefRank": 209,
      "adjEM": -1.94,
      "adjOff": 102.9,
      "adjOffRank": 181,
      "adjTempo": 62.4,
      "adjTempoRank": 303,
      "conference": "SB",
      "rank": 186,
      "record": "21-11"
    },
    "Florida Gulf Coast": {
      "adjDef": 105.8,
      "adjDefRank": 232,
      "adjEM": -11.11,
      "adjOff": 94.7,
      "adjOffRank": 300,
      "adjTempo": 67.5,
      "adjTempoRank": 62,
      "conference": "ASun",
      "rank": 285,
      "record": "10-20"
    },
    "Florida St.": {
      "adjDef": 87.7,
      "adjDefRank": 1,
      "adjEM": 16.76,
      "adjOff": 104.5,
      "adjOffRank": 147,
      "adjTempo": 66.7,
      "adjTempoRank": 95,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 35,
      "record": "23-11",
      "seed": 10
    },
    "Fordham": {
      "adjDef": 109.8,
      "adjDefRank": 294,
      "adjEM": -11.72,
      "adjOff": 98,
      "adjOffRank": 264,
      "adjTempo": 66.2,
      "adjTempoRank": 118,
      "conference": "A10",
      "rank": 290,
      "record": "7-21"
    },
    "Fresno St.": {
      "adjDef": 102.9,
      "adjDefRank": 156,
      "adjEM": -0.97,
      "adjOff": 101.9,
      "adjOffRank": 196,
      "adjTempo": 65.5,
      "adjTempoRank": 162,
      "conference": "WAC",
      "rank": 177,
      "record": "14-17"
    },
    "Furman": {
      "adjDef": 102.9,
      "adjDefRank": 154,
      "adjEM": 2.44,
      "adjOff": 105.3,
      "adjOffRank": 125,
      "adjTempo": 62.8,
      "adjTempoRank": 284,
      "conference": "SC",
      "rank": 142,
      "record": "22-11"
    },
    "Gardner Webb": {
      "adjDef": 105,
      "adjDefRank": 214,
      "adjEM": -9.79,
      "adjOff": 95.2,
      "adjOffRank": 295,
      "adjTempo": 63.3,
      "adjTempoRank": 271,
      "conference": "BSth",
      "rank": 270,
      "record": "11-21"
    },
    "George Mason": {
      "adjDef": 95.8,
      "adjDefRank": 45,
      "adjEM": 18.3,
      "adjOff": 114.1,
      "adjOffRank": 29,
      "adjTempo": 64.5,
      "adjTempoRank": 217,
      "conference": "CAA",
      "finish": "Round of 32",
      "rank": 24,
      "record": "27-7",
      "seed": 8
    },
    "George Washington": {
      "adjDef": 102,
      "adjDefRank": 134,
      "adjEM": -0.73,
      "adjOff": 101.3,
      "adjOffRank": 210,
      "adjTempo": 63.6,
      "adjTempoRank": 259,
      "conference": "A10",
      "rank": 175,
      "record": "17-14"
    },
    "Georgetown": {
      "adjDef": 96.6,
      "adjDefRank": 55,
      "adjEM": 17.65,
      "adjOff": 114.2,
      "adjOffRank": 27,
      "adjTempo": 63.4,
      "adjTempoRank": 265,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 31,
      "record": "21-11",
      "seed": 6
    },
    "Georgia": {
      "adjDef": 95.9,
      "adjDefRank": 47,
      "adjEM": 12.24,
      "adjOff": 108.2,
      "adjOffRank": 86,
      "adjTempo": 63.5,
      "adjTempoRank": 261,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 63,
      "record": "21-12",
      "seed": 10
    },
    "Georgia Southern": {
      "adjDef": 109,
      "adjDefRank": 281,
      "adjEM": -19.33,
      "adjOff": 89.7,
      "adjOffRank": 332,
      "adjTempo": 66,
      "adjTempoRank": 137,
      "conference": "SC",
      "rank": 328,
      "record": "5-27"
    },
    "Georgia St.": {
      "adjDef": 99.5,
      "adjDefRank": 91,
      "adjEM": -4.58,
      "adjOff": 94.9,
      "adjOffRank": 296,
      "adjTempo": 63.7,
      "adjTempoRank": 251,
      "conference": "CAA",
      "rank": 217,
      "record": "12-19"
    },
    "Georgia Tech": {
      "adjDef": 96,
      "adjDefRank": 50,
      "adjEM": 6.25,
      "adjOff": 102.3,
      "adjOffRank": 191,
      "adjTempo": 67.8,
      "adjTempoRank": 55,
      "conference": "ACC",
      "rank": 100,
      "record": "13-18"
    },
    "Gonzaga": {
      "adjDef": 95.6,
      "adjDefRank": 44,
      "adjEM": 16.45,
      "adjOff": 112.1,
      "adjOffRank": 45,
      "adjTempo": 66.1,
      "adjTempoRank": 126,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 37,
      "record": "25-10",
      "seed": 11
    },
    "Grambling St.": {
      "adjDef": 107,
      "adjDefRank": 246,
      "adjEM": -19.64,
      "adjOff": 87.4,
      "adjOffRank": 338,
      "adjTempo": 66.1,
      "adjTempoRank": 128,
      "conference": "SWAC",
      "rank": 329,
      "record": "12-21"
    },
    "Green Bay": {
      "adjDef": 103.8,
      "adjDefRank": 181,
      "adjEM": 0.69,
      "adjOff": 104.5,
      "adjOffRank": 148,
      "adjTempo": 64.8,
      "adjTempoRank": 203,
      "conference": "Horz",
      "rank": 158,
      "record": "14-18"
    },
    "Hampton": {
      "adjDef": 98.7,
      "adjDefRank": 81,
      "adjEM": -4.21,
      "adjOff": 94.4,
      "adjOffRank": 304,
      "adjTempo": 66.1,
      "adjTempoRank": 129,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 208,
      "record": "24-9",
      "seed": 16
    },
    "Hartford": {
      "adjDef": 104.5,
      "adjDefRank": 198,
      "adjEM": -11.68,
      "adjOff": 92.8,
      "adjOffRank": 317,
      "adjTempo": 63,
      "adjTempoRank": 279,
      "conference": "AE",
      "rank": 289,
      "record": "11-20"
    },
    "Harvard": {
      "adjDef": 101.8,
      "adjDefRank": 129,
      "adjEM": 10.61,
      "adjOff": 112.4,
      "adjOffRank": 42,
      "adjTempo": 63.4,
      "adjTempoRank": 264,
      "conference": "Ivy",
      "rank": 71,
      "record": "23-7"
    },
    "Hawaii": {
      "adjDef": 100.2,
      "adjDefRank": 107,
      "adjEM": 0.4,
      "adjOff": 100.6,
      "adjOffRank": 221,
      "adjTempo": 66.9,
      "adjTempoRank": 82,
      "conference": "WAC",
      "rank": 163,
      "record": "19-13"
    },
    "High Point": {
      "adjDef": 109,
      "adjDefRank": 280,
      "adjEM": -14.43,
      "adjOff": 94.5,
      "adjOffRank": 301,
      "adjTempo": 66.8,
      "adjTempoRank": 87,
      "conference": "BSth",
      "rank": 308,
      "record": "12-19"
    },
    "Hofstra": {
      "adjDef": 106.1,
      "adjDefRank": 236,
      "adjEM": 2.89,
      "adjOff": 108.9,
      "adjOffRank": 77,
      "adjTempo": 65,
      "adjTempoRank": 187,
      "conference": "CAA",
      "rank": 135,
      "record": "21-12"
    },
    "Holy Cross": {
      "adjDef": 106.9,
      "adjDefRank": 244,
      "adjEM": -5.32,
      "adjOff": 101.6,
      "adjOffRank": 204,
      "adjTempo": 64.7,
      "adjTempoRank": 206,
      "conference": "Pat",
      "rank": 228,
      "record": "8-21"
    },
    "Houston": {
      "adjDef": 106.4,
      "adjDefRank": 240,
      "adjEM": -5.04,
      "adjOff": 101.4,
      "adjOffRank": 208,
      "adjTempo": 65.5,
      "adjTempoRank": 160,
      "conference": "CUSA",
      "rank": 222,
      "record": "12-18"
    },
    "Houston Baptist": {
      "adjDef": 113.3,
      "adjDefRank": 333,
      "adjEM": -22.15,
      "adjOff": 91.2,
      "adjOffRank": 326,
      "adjTempo": 70.5,
      "adjTempoRank": 16,
      "conference": "GWC",
      "rank": 337,
      "record": "5-26"
    },
    "Howard": {
      "adjDef": 112.2,
      "adjDefRank": 323,
      "adjEM": -24.24,
      "adjOff": 87.9,
      "adjOffRank": 336,
      "adjTempo": 64.6,
      "adjTempoRank": 215,
      "conference": "MEAC",
      "rank": 340,
      "record": "6-24"
    },
    "IPFW": {
      "adjDef": 106.7,
      "adjDefRank": 243,
      "adjEM": -0.87,
      "adjOff": 105.8,
      "adjOffRank": 114,
      "adjTempo": 65,
      "adjTempoRank": 183,
      "conference": "Sum",
      "rank": 176,
      "record": "18-12"
    },
    "IUPUI": {
      "adjDef": 107.7,
      "adjDefRank": 261,
      "adjEM": 3.77,
      "adjOff": 111.5,
      "adjOffRank": 51,
      "adjTempo": 61.6,
      "adjTempoRank": 321,
      "conference": "Sum",
      "rank": 122,
      "record": "19-14"
    },
    "Idaho": {
      "adjDef": 102.4,
      "adjDefRank": 141,
      "adjEM": 2.25,
      "adjOff": 104.7,
      "adjOffRank": 142,
      "adjTempo": 63.9,
      "adjTempoRank": 247,
      "conference": "WAC",
      "rank": 143,
      "record": "18-14"
    },
    "Idaho St.": {
      "adjDef": 111.4,
      "adjDefRank": 316,
      "adjEM": -13.06,
      "adjOff": 98.4,
      "adjOffRank": 262,
      "adjTempo": 63.9,
      "adjTempoRank": 246,
      "conference": "BSky",
      "rank": 302,
      "record": "9-20"
    },
    "Illinois": {
      "adjDef": 93.1,
      "adjDefRank": 23,
      "adjEM": 20.14,
      "adjOff": 113.3,
      "adjOffRank": 34,
      "adjTempo": 64.8,
      "adjTempoRank": 202,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 18,
      "record": "20-14",
      "seed": 9
    },
    "Illinois Chicago": {
      "adjDef": 104.5,
      "adjDefRank": 199,
      "adjEM": -7.99,
      "adjOff": 96.5,
      "adjOffRank": 283,
      "adjTempo": 64.2,
      "adjTempoRank": 234,
      "conference": "Horz",
      "rank": 254,
      "record": "7-24"
    },
    "Illinois St.": {
      "adjDef": 102.5,
      "adjDefRank": 142,
      "adjEM": -6.01,
      "adjOff": 96.4,
      "adjOffRank": 284,
      "adjTempo": 62.3,
      "adjTempoRank": 307,
      "conference": "MVC",
      "rank": 237,
      "record": "12-19"
    },
    "Indiana": {
      "adjDef": 101.7,
      "adjDefRank": 128,
      "adjEM": 8.57,
      "adjOff": 110.3,
      "adjOffRank": 61,
      "adjTempo": 64.6,
      "adjTempoRank": 210,
      "conference": "B10",
      "rank": 82,
      "record": "12-20"
    },
    "Indiana St.": {
      "adjDef": 97.4,
      "adjDefRank": 63,
      "adjEM": 5.45,
      "adjOff": 102.8,
      "adjOffRank": 183,
      "adjTempo": 65,
      "adjTempoRank": 189,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 108,
      "record": "20-14",
      "seed": 14
    },
    "Iona": {
      "adjDef": 102,
      "adjDefRank": 132,
      "adjEM": 11.61,
      "adjOff": 113.6,
      "adjOffRank": 32,
      "adjTempo": 68.3,
      "adjTempoRank": 45,
      "conference": "MAAC",
      "rank": 66,
      "record": "25-12"
    },
    "Iowa": {
      "adjDef": 96.8,
      "adjDefRank": 56,
      "adjEM": 7.12,
      "adjOff": 103.9,
      "adjOffRank": 161,
      "adjTempo": 66.6,
      "adjTempoRank": 100,
      "conference": "B10",
      "rank": 88,
      "record": "11-20"
    },
    "Iowa St.": {
      "adjDef": 99.4,
      "adjDefRank": 89,
      "adjEM": 8.78,
      "adjOff": 108.1,
      "adjOffRank": 87,
      "adjTempo": 69,
      "adjTempoRank": 34,
      "conference": "B12",
      "rank": 81,
      "record": "16-16"
    },
    "Jackson St.": {
      "adjDef": 106.1,
      "adjDefRank": 237,
      "adjEM": -10.49,
      "adjOff": 95.7,
      "adjOffRank": 291,
      "adjTempo": 63.2,
      "adjTempoRank": 273,
      "conference": "SWAC",
      "rank": 277,
      "record": "17-15"
    },
    "Jacksonville": {
      "adjDef": 98,
      "adjDefRank": 73,
      "adjEM": 0.82,
      "adjOff": 98.8,
      "adjOffRank": 253,
      "adjTempo": 67.1,
      "adjTempoRank": 77,
      "conference": "ASun",
      "rank": 156,
      "record": "20-12"
    },
    "Jacksonville St.": {
      "adjDef": 108.1,
      "adjDefRank": 266,
      "adjEM": -12.2,
      "adjOff": 95.9,
      "adjOffRank": 290,
      "adjTempo": 61.4,
      "adjTempoRank": 323,
      "conference": "OVC",
      "rank": 292,
      "record": "5-25"
    },
    "James Madison": {
      "adjDef": 102.6,
      "adjDefRank": 145,
      "adjEM": 5.74,
      "adjOff": 108.3,
      "adjOffRank": 84,
      "adjTempo": 66.7,
      "adjTempoRank": 97,
      "conference": "CAA",
      "rank": 106,
      "record": "21-12"
    },
    "Kansas": {
      "adjDef": 90.6,
      "adjDefRank": 6,
      "adjEM": 28.38,
      "adjOff": 118.9,
      "adjOffRank": 6,
      "adjTempo": 68.4,
      "adjTempoRank": 42,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "35-3",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 93.6,
      "adjDefRank": 25,
      "adjEM": 17.42,
      "adjOff": 111.1,
      "adjOffRank": 56,
      "adjTempo": 66.4,
      "adjTempoRank": 106,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 32,
      "record": "23-11",
      "seed": 5
    },
    "Kennesaw St.": {
      "adjDef": 114.9,
      "adjDefRank": 340,
      "adjEM": -16.36,
      "adjOff": 98.5,
      "adjOffRank": 258,
      "adjTempo": 63.6,
      "adjTempoRank": 255,
      "conference": "ASun",
      "rank": 317,
      "record": "8-23"
    },
    "Kent St.": {
      "adjDef": 99.5,
      "adjDefRank": 92,
      "adjEM": 6.25,
      "adjOff": 105.7,
      "adjOffRank": 117,
      "adjTempo": 66.4,
      "adjTempoRank": 111,
      "conference": "MAC",
      "rank": 99,
      "record": "25-12"
    },
    "Kentucky": {
      "adjDef": 92,
      "adjDefRank": 16,
      "adjEM": 25.82,
      "adjOff": 117.8,
      "adjOffRank": 8,
      "adjTempo": 65,
      "adjTempoRank": 184,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 7,
      "record": "29-9",
      "seed": 4
    },
    "LIU Brooklyn": {
      "adjDef": 104.2,
      "adjDefRank": 190,
      "adjEM": 5.43,
      "adjOff": 109.6,
      "adjOffRank": 72,
      "adjTempo": 73.3,
      "adjTempoRank": 4,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 109,
      "record": "27-6",
      "seed": 15
    },
    "LSU": {
      "adjDef": 101.2,
      "adjDefRank": 123,
      "adjEM": -5.68,
      "adjOff": 95.6,
      "adjOffRank": 293,
      "adjTempo": 64.7,
      "adjTempoRank": 204,
      "conference": "SEC",
      "rank": 232,
      "record": "11-21"
    },
    "La Salle": {
      "adjDef": 107.3,
      "adjDefRank": 253,
      "adjEM": -0.61,
      "adjOff": 106.7,
      "adjOffRank": 105,
      "adjTempo": 70.9,
      "adjTempoRank": 12,
      "conference": "A10",
      "rank": 172,
      "record": "15-18"
    },
    "Lafayette": {
      "adjDef": 108.2,
      "adjDefRank": 269,
      "adjEM": -5.21,
      "adjOff": 103,
      "adjOffRank": 176,
      "adjTempo": 64.4,
      "adjTempoRank": 226,
      "conference": "Pat",
      "rank": 225,
      "record": "13-19"
    },
    "Lamar": {
      "adjDef": 107.2,
      "adjDefRank": 250,
      "adjEM": -6.91,
      "adjOff": 100.3,
      "adjOffRank": 231,
      "adjTempo": 73.4,
      "adjTempoRank": 3,
      "conference": "Slnd",
      "rank": 244,
      "record": "13-17"
    },
    "Lehigh": {
      "adjDef": 104.9,
      "adjDefRank": 212,
      "adjEM": -2.2,
      "adjOff": 102.7,
      "adjOffRank": 185,
      "adjTempo": 69.9,
      "adjTempoRank": 20,
      "conference": "Pat",
      "rank": 190,
      "record": "16-15"
    },
    "Liberty": {
      "adjDef": 103.1,
      "adjDefRank": 164,
      "adjEM": -5.52,
      "adjOff": 97.6,
      "adjOffRank": 272,
      "adjTempo": 64.6,
      "adjTempoRank": 213,
      "conference": "BSth",
      "rank": 231,
      "record": "19-13"
    },
    "Lipscomb": {
      "adjDef": 105.3,
      "adjDefRank": 220,
      "adjEM": 1.56,
      "adjOff": 106.8,
      "adjOffRank": 102,
      "adjTempo": 69.3,
      "adjTempoRank": 32,
      "conference": "ASun",
      "rank": 153,
      "record": "17-13"
    },
    "Long Beach St.": {
      "adjDef": 100.1,
      "adjDefRank": 103,
      "adjEM": 6.66,
      "adjOff": 106.7,
      "adjOffRank": 103,
      "adjTempo": 68.7,
      "adjTempoRank": 39,
      "conference": "BW",
      "rank": 94,
      "record": "22-12"
    },
    "Longwood": {
      "adjDef": 118.6,
      "adjDefRank": 345,
      "adjEM": -16.42,
      "adjOff": 102.2,
      "adjOffRank": 192,
      "adjTempo": 69.9,
      "adjTempoRank": 22,
      "conference": "ind",
      "rank": 318,
      "record": "12-19"
    },
    "Louisiana Lafayette": {
      "adjDef": 104.7,
      "adjDefRank": 204,
      "adjEM": -6,
      "adjOff": 98.7,
      "adjOffRank": 256,
      "adjTempo": 67.7,
      "adjTempoRank": 58,
      "conference": "SB",
      "rank": 236,
      "record": "14-15"
    },
    "Louisiana Monroe": {
      "adjDef": 113.5,
      "adjDefRank": 334,
      "adjEM": -18.99,
      "adjOff": 94.5,
      "adjOffRank": 303,
      "adjTempo": 61.9,
      "adjTempoRank": 313,
      "conference": "SB",
      "rank": 326,
      "record": "7-24"
    },
    "Louisiana Tech": {
      "adjDef": 102.7,
      "adjDefRank": 148,
      "adjEM": -7.06,
      "adjOff": 95.6,
      "adjOffRank": 292,
      "adjTempo": 65.4,
      "adjTempoRank": 165,
      "conference": "WAC",
      "rank": 245,
      "record": "12-20"
    },
    "Louisville": {
      "adjDef": 89.2,
      "adjDefRank": 3,
      "adjEM": 22.26,
      "adjOff": 111.4,
      "adjOffRank": 52,
      "adjTempo": 66.8,
      "adjTempoRank": 88,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 14,
      "record": "25-10",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 103.1,
      "adjDefRank": 163,
      "adjEM": 2.83,
      "adjOff": 105.9,
      "adjOffRank": 113,
      "adjTempo": 64.4,
      "adjTempoRank": 224,
      "conference": "Horz",
      "rank": 136,
      "record": "16-15"
    },
    "Loyola MD": {
      "adjDef": 105,
      "adjDefRank": 213,
      "adjEM": -1.53,
      "adjOff": 103.4,
      "adjOffRank": 170,
      "adjTempo": 64.8,
      "adjTempoRank": 201,
      "conference": "MAAC",
      "rank": 181,
      "record": "15-15"
    },
    "Loyola Marymount": {
      "adjDef": 105.4,
      "adjDefRank": 223,
      "adjEM": -3.32,
      "adjOff": 102.1,
      "adjOffRank": 194,
      "adjTempo": 66.2,
      "adjTempoRank": 117,
      "conference": "WCC",
      "rank": 198,
      "record": "11-21"
    },
    "Maine": {
      "adjDef": 108.2,
      "adjDefRank": 270,
      "adjEM": -3.46,
      "adjOff": 104.8,
      "adjOffRank": 139,
      "adjTempo": 64.7,
      "adjTempoRank": 205,
      "conference": "AE",
      "rank": 200,
      "record": "15-15"
    },
    "Manhattan": {
      "adjDef": 111.1,
      "adjDefRank": 310,
      "adjEM": -14.69,
      "adjOff": 96.4,
      "adjOffRank": 286,
      "adjTempo": 62.4,
      "adjTempoRank": 304,
      "conference": "MAAC",
      "rank": 312,
      "record": "6-25"
    },
    "Marist": {
      "adjDef": 111.4,
      "adjDefRank": 315,
      "adjEM": -16.63,
      "adjOff": 94.8,
      "adjOffRank": 299,
      "adjTempo": 64.8,
      "adjTempoRank": 199,
      "conference": "MAAC",
      "rank": 322,
      "record": "6-27"
    },
    "Marquette": {
      "adjDef": 97.3,
      "adjDefRank": 62,
      "adjEM": 18.14,
      "adjOff": 115.5,
      "adjOffRank": 22,
      "adjTempo": 66.2,
      "adjTempoRank": 116,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 26,
      "record": "22-15",
      "seed": 11
    },
    "Marshall": {
      "adjDef": 100.6,
      "adjDefRank": 114,
      "adjEM": 7.99,
      "adjOff": 108.6,
      "adjOffRank": 83,
      "adjTempo": 66.7,
      "adjTempoRank": 96,
      "conference": "CUSA",
      "rank": 85,
      "record": "22-12"
    },
    "Maryland": {
      "adjDef": 94.4,
      "adjDefRank": 31,
      "adjEM": 15.27,
      "adjOff": 109.7,
      "adjOffRank": 69,
      "adjTempo": 69.6,
      "adjTempoRank": 28,
      "conference": "ACC",
      "rank": 44,
      "record": "19-14"
    },
    "Maryland Eastern Shore": {
      "adjDef": 114.1,
      "adjDefRank": 338,
      "adjEM": -22.02,
      "adjOff": 92.1,
      "adjOffRank": 322,
      "adjTempo": 68.8,
      "adjTempoRank": 36,
      "conference": "MEAC",
      "rank": 335,
      "record": "9-22"
    },
    "Massachusetts": {
      "adjDef": 99.7,
      "adjDefRank": 95,
      "adjEM": -1.32,
      "adjOff": 98.4,
      "adjOffRank": 263,
      "adjTempo": 66.5,
      "adjTempoRank": 105,
      "conference": "A10",
      "rank": 180,
      "record": "15-15"
    },
    "McNeese St.": {
      "adjDef": 109.4,
      "adjDefRank": 288,
      "adjEM": -3.88,
      "adjOff": 105.5,
      "adjOffRank": 122,
      "adjTempo": 65.1,
      "adjTempoRank": 180,
      "conference": "Slnd",
      "rank": 206,
      "record": "21-12"
    },
    "Memphis": {
      "adjDef": 97.6,
      "adjDefRank": 65,
      "adjEM": 6.98,
      "adjOff": 104.5,
      "adjOffRank": 146,
      "adjTempo": 67.6,
      "adjTempoRank": 59,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 90,
      "record": "25-10",
      "seed": 12
    },
    "Mercer": {
      "adjDef": 104.8,
      "adjDefRank": 210,
      "adjEM": -3.67,
      "adjOff": 101.1,
      "adjOffRank": 213,
      "adjTempo": 65.3,
      "adjTempoRank": 173,
      "conference": "ASun",
      "rank": 202,
      "record": "15-18"
    },
    "Miami FL": {
      "adjDef": 100.2,
      "adjDefRank": 105,
      "adjEM": 12.59,
      "adjOff": 112.8,
      "adjOffRank": 38,
      "adjTempo": 64.4,
      "adjTempoRank": 228,
      "conference": "ACC",
      "rank": 61,
      "record": "21-15"
    },
    "Miami OH": {
      "adjDef": 107.6,
      "adjDefRank": 258,
      "adjEM": -2.11,
      "adjOff": 105.5,
      "adjOffRank": 123,
      "adjTempo": 62.5,
      "adjTempoRank": 299,
      "conference": "MAC",
      "rank": 188,
      "record": "16-17"
    },
    "Michigan": {
      "adjDef": 95.1,
      "adjDefRank": 37,
      "adjEM": 17.69,
      "adjOff": 112.8,
      "adjOffRank": 39,
      "adjTempo": 61.5,
      "adjTempoRank": 322,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 30,
      "record": "21-14",
      "seed": 8
    },
    "Michigan St.": {
      "adjDef": 94.4,
      "adjDefRank": 30,
      "adjEM": 15.08,
      "adjOff": 109.5,
      "adjOffRank": 73,
      "adjTempo": 65,
      "adjTempoRank": 186,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 45,
      "record": "19-15",
      "seed": 10
    },
    "Middle Tennessee": {
      "adjDef": 100.7,
      "adjDefRank": 115,
      "adjEM": -2.19,
      "adjOff": 98.5,
      "adjOffRank": 260,
      "adjTempo": 65.6,
      "adjTempoRank": 158,
      "conference": "SB",
      "rank": 189,
      "record": "16-16"
    },
    "Milwaukee": {
      "adjDef": 102.1,
      "adjDefRank": 137,
      "adjEM": 2.69,
      "adjOff": 104.8,
      "adjOffRank": 135,
      "adjTempo": 65.4,
      "adjTempoRank": 167,
      "conference": "Horz",
      "rank": 138,
      "record": "19-14"
    },
    "Minnesota": {
      "adjDef": 97.4,
      "adjDefRank": 64,
      "adjEM": 13.15,
      "adjOff": 110.5,
      "adjOffRank": 59,
      "adjTempo": 64.9,
      "adjTempoRank": 194,
      "conference": "B10",
      "rank": 58,
      "record": "17-14"
    },
    "Mississippi": {
      "adjDef": 103.1,
      "adjDefRank": 162,
      "adjEM": 9.91,
      "adjOff": 113,
      "adjOffRank": 36,
      "adjTempo": 66,
      "adjTempoRank": 138,
      "conference": "SEC",
      "rank": 76,
      "record": "20-14"
    },
    "Mississippi St.": {
      "adjDef": 104.4,
      "adjDefRank": 194,
      "adjEM": 3.09,
      "adjOff": 107.5,
      "adjOffRank": 93,
      "adjTempo": 66.1,
      "adjTempoRank": 124,
      "conference": "SEC",
      "rank": 133,
      "record": "17-14"
    },
    "Mississippi Valley St.": {
      "adjDef": 108.3,
      "adjDefRank": 272,
      "adjEM": -14.44,
      "adjOff": 93.8,
      "adjOffRank": 306,
      "adjTempo": 71.3,
      "adjTempoRank": 8,
      "conference": "SWAC",
      "rank": 309,
      "record": "13-19"
    },
    "Missouri": {
      "adjDef": 97.8,
      "adjDefRank": 68,
      "adjEM": 15.54,
      "adjOff": 113.4,
      "adjOffRank": 33,
      "adjTempo": 70.7,
      "adjTempoRank": 14,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 42,
      "record": "23-11",
      "seed": 11
    },
    "Missouri St.": {
      "adjDef": 102.9,
      "adjDefRank": 157,
      "adjEM": 11.06,
      "adjOff": 114,
      "adjOffRank": 30,
      "adjTempo": 62,
      "adjTempoRank": 311,
      "conference": "MVC",
      "rank": 69,
      "record": "26-9"
    },
    "Monmouth": {
      "adjDef": 105.1,
      "adjDefRank": 216,
      "adjEM": -10.57,
      "adjOff": 94.5,
      "adjOffRank": 302,
      "adjTempo": 62.6,
      "adjTempoRank": 295,
      "conference": "NEC",
      "rank": 281,
      "record": "9-21"
    },
    "Montana": {
      "adjDef": 96.8,
      "adjDefRank": 57,
      "adjEM": 5.04,
      "adjOff": 101.8,
      "adjOffRank": 200,
      "adjTempo": 62.7,
      "adjTempoRank": 289,
      "conference": "BSky",
      "rank": 114,
      "record": "21-11"
    },
    "Montana St.": {
      "adjDef": 108,
      "adjDefRank": 263,
      "adjEM": -7.8,
      "adjOff": 100.2,
      "adjOffRank": 232,
      "adjTempo": 64.3,
      "adjTempoRank": 231,
      "conference": "BSky",
      "rank": 250,
      "record": "13-18"
    },
    "Morehead St.": {
      "adjDef": 98.6,
      "adjDefRank": 80,
      "adjEM": 7.01,
      "adjOff": 105.6,
      "adjOffRank": 119,
      "adjTempo": 63.4,
      "adjTempoRank": 267,
      "conference": "OVC",
      "finish": "Round of 32",
      "rank": 89,
      "record": "25-10",
      "seed": 13
    },
    "Morgan St.": {
      "adjDef": 102.1,
      "adjDefRank": 136,
      "adjEM": -7.3,
      "adjOff": 94.8,
      "adjOffRank": 298,
      "adjTempo": 65.5,
      "adjTempoRank": 164,
      "conference": "MEAC",
      "rank": 247,
      "record": "17-14"
    },
    "Mount St. Mary's": {
      "adjDef": 102.6,
      "adjDefRank": 146,
      "adjEM": -7.75,
      "adjOff": 94.9,
      "adjOffRank": 297,
      "adjTempo": 64,
      "adjTempoRank": 244,
      "conference": "NEC",
      "rank": 249,
      "record": "11-21"
    },
    "Murray St.": {
      "adjDef": 102,
      "adjDefRank": 131,
      "adjEM": 3.01,
      "adjOff": 105,
      "adjOffRank": 131,
      "adjTempo": 62.4,
      "adjTempoRank": 301,
      "conference": "OVC",
      "rank": 134,
      "record": "23-9"
    },
    "N.C. State": {
      "adjDef": 102.7,
      "adjDefRank": 149,
      "adjEM": 7.19,
      "adjOff": 109.9,
      "adjOffRank": 67,
      "adjTempo": 65.9,
      "adjTempoRank": 143,
      "conference": "ACC",
      "rank": 87,
      "record": "15-16"
    },
    "NJIT": {
      "adjDef": 107.3,
      "adjDefRank": 255,
      "adjEM": -14.44,
      "adjOff": 92.9,
      "adjOffRank": 316,
      "adjTempo": 65.5,
      "adjTempoRank": 159,
      "conference": "GWC",
      "rank": 310,
      "record": "15-15"
    },
    "Navy": {
      "adjDef": 103,
      "adjDefRank": 160,
      "adjEM": -9.84,
      "adjOff": 93.2,
      "adjOffRank": 309,
      "adjTempo": 69.4,
      "adjTempoRank": 30,
      "conference": "Pat",
      "rank": 272,
      "record": "11-20"
    },
    "Nebraska": {
      "adjDef": 93.1,
      "adjDefRank": 22,
      "adjEM": 11.82,
      "adjOff": 104.9,
      "adjOffRank": 133,
      "adjTempo": 62.2,
      "adjTempoRank": 308,
      "conference": "B12",
      "rank": 64,
      "record": "19-13"
    },
    "Nevada": {
      "adjDef": 103.9,
      "adjDefRank": 185,
      "adjEM": 0.44,
      "adjOff": 104.3,
      "adjOffRank": 151,
      "adjTempo": 66.8,
      "adjTempoRank": 94,
      "conference": "WAC",
      "rank": 160,
      "record": "13-19"
    },
    "New Hampshire": {
      "adjDef": 102.6,
      "adjDefRank": 144,
      "adjEM": -11.39,
      "adjOff": 91.2,
      "adjOffRank": 327,
      "adjTempo": 61.7,
      "adjTempoRank": 317,
      "conference": "AE",
      "rank": 287,
      "record": "12-18"
    },
    "New Mexico": {
      "adjDef": 97.2,
      "adjDefRank": 61,
      "adjEM": 14.7,
      "adjOff": 111.9,
      "adjOffRank": 48,
      "adjTempo": 64.9,
      "adjTempoRank": 191,
      "conference": "MWC",
      "rank": 47,
      "record": "22-13"
    },
    "New Mexico St.": {
      "adjDef": 103.3,
      "adjDefRank": 165,
      "adjEM": 2.2,
      "adjOff": 105.5,
      "adjOffRank": 124,
      "adjTempo": 66.5,
      "adjTempoRank": 104,
      "conference": "WAC",
      "rank": 145,
      "record": "16-17"
    },
    "Niagara": {
      "adjDef": 105.2,
      "adjDefRank": 219,
      "adjEM": -13.02,
      "adjOff": 92.2,
      "adjOffRank": 321,
      "adjTempo": 67.1,
      "adjTempoRank": 73,
      "conference": "MAAC",
      "rank": 301,
      "record": "9-23"
    },
    "Nicholls St.": {
      "adjDef": 103,
      "adjDefRank": 159,
      "adjEM": -5.82,
      "adjOff": 97.2,
      "adjOffRank": 274,
      "adjTempo": 59.9,
      "adjTempoRank": 332,
      "conference": "Slnd",
      "rank": 235,
      "record": "14-14"
    },
    "Norfolk St.": {
      "adjDef": 109.8,
      "adjDefRank": 295,
      "adjEM": -12.65,
      "adjOff": 97.1,
      "adjOffRank": 276,
      "adjTempo": 68.7,
      "adjTempoRank": 37,
      "conference": "MEAC",
      "rank": 298,
      "record": "12-20"
    },
    "North Carolina": {
      "adjDef": 89.6,
      "adjDefRank": 5,
      "adjEM": 22.18,
      "adjOff": 111.8,
      "adjOffRank": 49,
      "adjTempo": 70.6,
      "adjTempoRank": 15,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 15,
      "record": "29-8",
      "seed": 2
    },
    "North Carolina A&T": {
      "adjDef": 111.4,
      "adjDefRank": 312,
      "adjEM": -12.29,
      "adjOff": 99.1,
      "adjOffRank": 248,
      "adjTempo": 67.8,
      "adjTempoRank": 54,
      "conference": "MEAC",
      "rank": 293,
      "record": "15-17"
    },
    "North Carolina Central": {
      "adjDef": 111,
      "adjDefRank": 309,
      "adjEM": -15.03,
      "adjOff": 96,
      "adjOffRank": 289,
      "adjTempo": 65.6,
      "adjTempoRank": 157,
      "conference": "ind",
      "rank": 313,
      "record": "15-15"
    },
    "North Dakota": {
      "adjDef": 105.5,
      "adjDefRank": 226,
      "adjEM": -12.82,
      "adjOff": 92.7,
      "adjOffRank": 319,
      "adjTempo": 66.6,
      "adjTempoRank": 102,
      "conference": "GWC",
      "rank": 299,
      "record": "19-15"
    },
    "North Dakota St.": {
      "adjDef": 104.4,
      "adjDefRank": 197,
      "adjEM": 0.4,
      "adjOff": 104.8,
      "adjOffRank": 136,
      "adjTempo": 67,
      "adjTempoRank": 81,
      "conference": "Sum",
      "rank": 162,
      "record": "14-15"
    },
    "North Florida": {
      "adjDef": 104.5,
      "adjDefRank": 200,
      "adjEM": -4.79,
      "adjOff": 99.7,
      "adjOffRank": 238,
      "adjTempo": 65.3,
      "adjTempoRank": 171,
      "conference": "ASun",
      "rank": 220,
      "record": "15-19"
    },
    "North Texas": {
      "adjDef": 109.8,
      "adjDefRank": 293,
      "adjEM": -0.72,
      "adjOff": 109,
      "adjOffRank": 75,
      "adjTempo": 67.1,
      "adjTempoRank": 76,
      "conference": "SB",
      "rank": 174,
      "record": "22-11"
    },
    "Northeastern": {
      "adjDef": 111.4,
      "adjDefRank": 313,
      "adjEM": -2.59,
      "adjOff": 108.8,
      "adjOffRank": 79,
      "adjTempo": 62.6,
      "adjTempoRank": 294,
      "conference": "CAA",
      "rank": 194,
      "record": "11-20"
    },
    "Northern Arizona": {
      "adjDef": 102.4,
      "adjDefRank": 140,
      "adjEM": 3.59,
      "adjOff": 106,
      "adjOffRank": 112,
      "adjTempo": 65,
      "adjTempoRank": 181,
      "conference": "BSky",
      "rank": 124,
      "record": "19-13"
    },
    "Northern Colorado": {
      "adjDef": 102.2,
      "adjDefRank": 138,
      "adjEM": 4.05,
      "adjOff": 106.3,
      "adjOffRank": 109,
      "adjTempo": 66.6,
      "adjTempoRank": 101,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 119,
      "record": "21-11",
      "seed": 15
    },
    "Northern Illinois": {
      "adjDef": 109.2,
      "adjDefRank": 286,
      "adjEM": -10.09,
      "adjOff": 99.1,
      "adjOffRank": 250,
      "adjTempo": 67.5,
      "adjTempoRank": 63,
      "conference": "MAC",
      "rank": 274,
      "record": "9-21"
    },
    "Northern Iowa": {
      "adjDef": 103.3,
      "adjDefRank": 168,
      "adjEM": 5.66,
      "adjOff": 109,
      "adjOffRank": 76,
      "adjTempo": 59.1,
      "adjTempoRank": 341,
      "conference": "MVC",
      "rank": 107,
      "record": "20-14"
    },
    "Northwestern": {
      "adjDef": 102.7,
      "adjDefRank": 152,
      "adjEM": 14.03,
      "adjOff": 116.8,
      "adjOffRank": 15,
      "adjTempo": 62.8,
      "adjTempoRank": 283,
      "conference": "B10",
      "rank": 50,
      "record": "20-14"
    },
    "Northwestern St.": {
      "adjDef": 110.1,
      "adjDefRank": 300,
      "adjEM": -8.98,
      "adjOff": 101.1,
      "adjOffRank": 214,
      "adjTempo": 71.1,
      "adjTempoRank": 10,
      "conference": "Slnd",
      "rank": 261,
      "record": "18-14"
    },
    "Notre Dame": {
      "adjDef": 97.7,
      "adjDefRank": 66,
      "adjEM": 23.25,
      "adjOff": 120.9,
      "adjOffRank": 3,
      "adjTempo": 63.1,
      "adjTempoRank": 277,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 11,
      "record": "27-7",
      "seed": 2
    },
    "Oakland": {
      "adjDef": 105.3,
      "adjDefRank": 221,
      "adjEM": 13.31,
      "adjOff": 118.6,
      "adjOffRank": 7,
      "adjTempo": 71.1,
      "adjTempoRank": 9,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 56,
      "record": "25-10",
      "seed": 13
    },
    "Ohio": {
      "adjDef": 105.5,
      "adjDefRank": 227,
      "adjEM": 1.91,
      "adjOff": 107.4,
      "adjOffRank": 94,
      "adjTempo": 67.1,
      "adjTempoRank": 79,
      "conference": "MAC",
      "rank": 149,
      "record": "19-16"
    },
    "Ohio St.": {
      "adjDef": 91.5,
      "adjDefRank": 12,
      "adjEM": 33.47,
      "adjOff": 125,
      "adjOffRank": 1,
      "adjTempo": 63.8,
      "adjTempoRank": 250,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 1,
      "record": "34-3",
      "seed": 1
    },
    "Oklahoma": {
      "adjDef": 102.5,
      "adjDefRank": 143,
      "adjEM": 2.24,
      "adjOff": 104.7,
      "adjOffRank": 140,
      "adjTempo": 64.2,
      "adjTempoRank": 235,
      "conference": "B12",
      "rank": 144,
      "record": "14-18"
    },
    "Oklahoma St.": {
      "adjDef": 96.5,
      "adjDefRank": 53,
      "adjEM": 10.22,
      "adjOff": 106.7,
      "adjOffRank": 104,
      "adjTempo": 64.5,
      "adjTempoRank": 218,
      "conference": "B12",
      "rank": 73,
      "record": "20-14"
    },
    "Old Dominion": {
      "adjDef": 95.4,
      "adjDefRank": 41,
      "adjEM": 14.66,
      "adjOff": 110,
      "adjOffRank": 64,
      "adjTempo": 60.4,
      "adjTempoRank": 330,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 48,
      "record": "27-7",
      "seed": 9
    },
    "Oral Roberts": {
      "adjDef": 106.4,
      "adjDefRank": 242,
      "adjEM": 3.75,
      "adjOff": 110.2,
      "adjOffRank": 62,
      "adjTempo": 65.8,
      "adjTempoRank": 148,
      "conference": "Sum",
      "rank": 123,
      "record": "19-16"
    },
    "Oregon": {
      "adjDef": 99.7,
      "adjDefRank": 96,
      "adjEM": 6.89,
      "adjOff": 106.6,
      "adjOffRank": 106,
      "adjTempo": 66.3,
      "adjTempoRank": 114,
      "conference": "P10",
      "rank": 92,
      "record": "21-18"
    },
    "Oregon St.": {
      "adjDef": 102.3,
      "adjDefRank": 139,
      "adjEM": 0.45,
      "adjOff": 102.8,
      "adjOffRank": 184,
      "adjTempo": 69.1,
      "adjTempoRank": 33,
      "conference": "P10",
      "rank": 159,
      "record": "11-20"
    },
    "Pacific": {
      "adjDef": 103.6,
      "adjDefRank": 176,
      "adjEM": 2.05,
      "adjOff": 105.6,
      "adjOffRank": 120,
      "adjTempo": 59.4,
      "adjTempoRank": 338,
      "conference": "BW",
      "rank": 147,
      "record": "16-15"
    },
    "Penn": {
      "adjDef": 103.7,
      "adjDefRank": 178,
      "adjEM": -1.88,
      "adjOff": 101.8,
      "adjOffRank": 202,
      "adjTempo": 63.3,
      "adjTempoRank": 269,
      "conference": "Ivy",
      "rank": 185,
      "record": "13-15"
    },
    "Penn St.": {
      "adjDef": 98.3,
      "adjDefRank": 76,
      "adjEM": 14.88,
      "adjOff": 113.1,
      "adjOffRank": 35,
      "adjTempo": 59.4,
      "adjTempoRank": 339,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 46,
      "record": "19-15",
      "seed": 10
    },
    "Pepperdine": {
      "adjDef": 109.7,
      "adjDefRank": 291,
      "adjEM": -5.09,
      "adjOff": 104.6,
      "adjOffRank": 145,
      "adjTempo": 65.4,
      "adjTempoRank": 166,
      "conference": "WCC",
      "rank": 224,
      "record": "12-21"
    },
    "Pittsburgh": {
      "adjDef": 93.5,
      "adjDefRank": 24,
      "adjEM": 27.08,
      "adjOff": 120.6,
      "adjOffRank": 4,
      "adjTempo": 61.8,
      "adjTempoRank": 315,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 4,
      "record": "28-6",
      "seed": 1
    },
    "Portland": {
      "adjDef": 105.3,
      "adjDefRank": 222,
      "adjEM": 6.21,
      "adjOff": 111.5,
      "adjOffRank": 50,
      "adjTempo": 63.1,
      "adjTempoRank": 278,
      "conference": "WCC",
      "rank": 101,
      "record": "20-12"
    },
    "Portland St.": {
      "adjDef": 113.7,
      "adjDefRank": 336,
      "adjEM": -9.51,
      "adjOff": 104.2,
      "adjOffRank": 153,
      "adjTempo": 66.8,
      "adjTempoRank": 89,
      "conference": "BSky",
      "rank": 267,
      "record": "14-16"
    },
    "Prairie View A&M": {
      "adjDef": 107.7,
      "adjDefRank": 260,
      "adjEM": -21.68,
      "adjOff": 86,
      "adjOffRank": 343,
      "adjTempo": 66.2,
      "adjTempoRank": 119,
      "conference": "SWAC",
      "rank": 334,
      "record": "10-22"
    },
    "Presbyterian": {
      "adjDef": 109.5,
      "adjDefRank": 289,
      "adjEM": -7.99,
      "adjOff": 101.5,
      "adjOffRank": 205,
      "adjTempo": 59,
      "adjTempoRank": 342,
      "conference": "BSth",
      "rank": 253,
      "record": "13-18"
    },
    "Princeton": {
      "adjDef": 98.1,
      "adjDefRank": 75,
      "adjEM": 9.09,
      "adjOff": 107.2,
      "adjOffRank": 97,
      "adjTempo": 62.6,
      "adjTempoRank": 292,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 79,
      "record": "25-7",
      "seed": 13
    },
    "Providence": {
      "adjDef": 102.8,
      "adjDefRank": 153,
      "adjEM": 7.25,
      "adjOff": 110.1,
      "adjOffRank": 63,
      "adjTempo": 71.4,
      "adjTempoRank": 7,
      "conference": "BE",
      "rank": 86,
      "record": "15-17"
    },
    "Purdue": {
      "adjDef": 91.1,
      "adjDefRank": 8,
      "adjEM": 24.79,
      "adjOff": 115.9,
      "adjOffRank": 18,
      "adjTempo": 66,
      "adjTempoRank": 136,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 9,
      "record": "26-8",
      "seed": 3
    },
    "Quinnipiac": {
      "adjDef": 104.4,
      "adjDefRank": 195,
      "adjEM": 0,
      "adjOff": 104.4,
      "adjOffRank": 149,
      "adjTempo": 67.1,
      "adjTempoRank": 78,
      "conference": "NEC",
      "rank": 165,
      "record": "22-10"
    },
    "Radford": {
      "adjDef": 107,
      "adjDefRank": 245,
      "adjEM": -20.27,
      "adjOff": 86.7,
      "adjOffRank": 341,
      "adjTempo": 66.5,
      "adjTempoRank": 103,
      "conference": "BSth",
      "rank": 331,
      "record": "5-24"
    },
    "Rhode Island": {
      "adjDef": 99.5,
      "adjDefRank": 93,
      "adjEM": 4,
      "adjOff": 103.5,
      "adjOffRank": 167,
      "adjTempo": 65.8,
      "adjTempoRank": 150,
      "conference": "A10",
      "rank": 120,
      "record": "20-14"
    },
    "Rice": {
      "adjDef": 104.3,
      "adjDefRank": 192,
      "adjEM": 0.88,
      "adjOff": 105.2,
      "adjOffRank": 128,
      "adjTempo": 63.5,
      "adjTempoRank": 263,
      "conference": "CUSA",
      "rank": 155,
      "record": "14-18"
    },
    "Richmond": {
      "adjDef": 96.5,
      "adjDefRank": 54,
      "adjEM": 16.01,
      "adjOff": 112.6,
      "adjOffRank": 41,
      "adjTempo": 62.1,
      "adjTempoRank": 309,
      "conference": "A10",
      "finish": "Sweet 16",
      "rank": 39,
      "record": "29-8",
      "seed": 12
    },
    "Rider": {
      "adjDef": 103.5,
      "adjDefRank": 172,
      "adjEM": 3.51,
      "adjOff": 107,
      "adjOffRank": 100,
      "adjTempo": 67.7,
      "adjTempoRank": 56,
      "conference": "MAAC",
      "rank": 125,
      "record": "23-11"
    },
    "Robert Morris": {
      "adjDef": 101.4,
      "adjDefRank": 125,
      "adjEM": 1.83,
      "adjOff": 103.2,
      "adjOffRank": 172,
      "adjTempo": 63.3,
      "adjTempoRank": 272,
      "conference": "NEC",
      "rank": 152,
      "record": "18-14"
    },
    "Rutgers": {
      "adjDef": 99.1,
      "adjDefRank": 86,
      "adjEM": 9.49,
      "adjOff": 108.6,
      "adjOffRank": 82,
      "adjTempo": 62.5,
      "adjTempoRank": 297,
      "conference": "BE",
      "rank": 78,
      "record": "15-17"
    },
    "SIU Edwardsville": {
      "adjDef": 113.2,
      "adjDefRank": 330,
      "adjEM": -26.31,
      "adjOff": 86.9,
      "adjOffRank": 340,
      "adjTempo": 68.3,
      "adjTempoRank": 47,
      "conference": "ind",
      "rank": 342,
      "record": "8-21"
    },
    "SMU": {
      "adjDef": 103.1,
      "adjDefRank": 161,
      "adjEM": 0.42,
      "adjOff": 103.5,
      "adjOffRank": 168,
      "adjTempo": 59.5,
      "adjTempoRank": 336,
      "conference": "CUSA",
      "rank": 161,
      "record": "20-15"
    },
    "Sacramento St.": {
      "adjDef": 109.9,
      "adjDefRank": 298,
      "adjEM": -16.49,
      "adjOff": 93.5,
      "adjOffRank": 308,
      "adjTempo": 63.3,
      "adjTempoRank": 268,
      "conference": "BSky",
      "rank": 320,
      "record": "7-21"
    },
    "Sacred Heart": {
      "adjDef": 108.6,
      "adjDefRank": 275,
      "adjEM": -9.35,
      "adjOff": 99.2,
      "adjOffRank": 245,
      "adjTempo": 65.4,
      "adjTempoRank": 168,
      "conference": "NEC",
      "rank": 264,
      "record": "11-18"
    },
    "Saint Joseph's": {
      "adjDef": 108.2,
      "adjDefRank": 268,
      "adjEM": -2.07,
      "adjOff": 106.1,
      "adjOffRank": 111,
      "adjTempo": 62.8,
      "adjTempoRank": 285,
      "conference": "A10",
      "rank": 187,
      "record": "11-22"
    },
    "Saint Louis": {
      "adjDef": 96,
      "adjDefRank": 51,
      "adjEM": 3.42,
      "adjOff": 99.5,
      "adjOffRank": 244,
      "adjTempo": 63.3,
      "adjTempoRank": 270,
      "conference": "A10",
      "rank": 127,
      "record": "12-19"
    },
    "Saint Mary's": {
      "adjDef": 101.7,
      "adjDefRank": 127,
      "adjEM": 15.49,
      "adjOff": 117.2,
      "adjOffRank": 13,
      "adjTempo": 64.9,
      "adjTempoRank": 190,
      "conference": "WCC",
      "rank": 43,
      "record": "25-9"
    },
    "Saint Peter's": {
      "adjDef": 92.5,
      "adjDefRank": 18,
      "adjEM": 2.73,
      "adjOff": 95.2,
      "adjOffRank": 294,
      "adjTempo": 64.1,
      "adjTempoRank": 240,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 137,
      "record": "20-14",
      "seed": 14
    },
    "Sam Houston St.": {
      "adjDef": 104.4,
      "adjDefRank": 196,
      "adjEM": -1.29,
      "adjOff": 103.1,
      "adjOffRank": 173,
      "adjTempo": 65.3,
      "adjTempoRank": 172,
      "conference": "Slnd",
      "rank": 179,
      "record": "18-13"
    },
    "Samford": {
      "adjDef": 110.9,
      "adjDefRank": 308,
      "adjEM": -14.5,
      "adjOff": 96.4,
      "adjOffRank": 285,
      "adjTempo": 59.8,
      "adjTempoRank": 334,
      "conference": "SC",
      "rank": 311,
      "record": "12-19"
    },
    "San Diego": {
      "adjDef": 105.2,
      "adjDefRank": 218,
      "adjEM": -12.3,
      "adjOff": 92.9,
      "adjOffRank": 314,
      "adjTempo": 63.6,
      "adjTempoRank": 257,
      "conference": "WCC",
      "rank": 294,
      "record": "6-24"
    },
    "San Diego St.": {
      "adjDef": 89.1,
      "adjDefRank": 2,
      "adjEM": 24.98,
      "adjOff": 114.1,
      "adjOffRank": 28,
      "adjTempo": 62.3,
      "adjTempoRank": 306,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "34-3",
      "seed": 2
    },
    "San Francisco": {
      "adjDef": 100.9,
      "adjDefRank": 116,
      "adjEM": 1.99,
      "adjOff": 102.9,
      "adjOffRank": 178,
      "adjTempo": 67.3,
      "adjTempoRank": 68,
      "conference": "WCC",
      "rank": 148,
      "record": "19-15"
    },
    "San Jose St.": {
      "adjDef": 106.3,
      "adjDefRank": 238,
      "adjEM": -1.2,
      "adjOff": 105.1,
      "adjOffRank": 129,
      "adjTempo": 66.2,
      "adjTempoRank": 120,
      "conference": "WAC",
      "rank": 178,
      "record": "17-16"
    },
    "Santa Clara": {
      "adjDef": 103.3,
      "adjDefRank": 166,
      "adjEM": 4.59,
      "adjOff": 107.9,
      "adjOffRank": 92,
      "adjTempo": 66.9,
      "adjTempoRank": 83,
      "conference": "WCC",
      "rank": 116,
      "record": "24-14"
    },
    "Savannah St.": {
      "adjDef": 100.3,
      "adjDefRank": 108,
      "adjEM": -9.14,
      "adjOff": 91.1,
      "adjOffRank": 328,
      "adjTempo": 62,
      "adjTempoRank": 310,
      "conference": "ind",
      "rank": 263,
      "record": "12-18"
    },
    "Seattle": {
      "adjDef": 102.6,
      "adjDefRank": 147,
      "adjEM": -9.7,
      "adjOff": 92.9,
      "adjOffRank": 315,
      "adjTempo": 71.9,
      "adjTempoRank": 6,
      "conference": "ind",
      "rank": 269,
      "record": "11-20"
    },
    "Seton Hall": {
      "adjDef": 91.5,
      "adjDefRank": 10,
      "adjEM": 12.93,
      "adjOff": 104.4,
      "adjOffRank": 150,
      "adjTempo": 67.6,
      "adjTempoRank": 60,
      "conference": "BE",
      "rank": 59,
      "record": "13-18"
    },
    "Siena": {
      "adjDef": 102.9,
      "adjDefRank": 155,
      "adjEM": -3.77,
      "adjOff": 99.1,
      "adjOffRank": 246,
      "adjTempo": 67.9,
      "adjTempoRank": 53,
      "conference": "MAAC",
      "rank": 203,
      "record": "13-18"
    },
    "South Alabama": {
      "adjDef": 112.2,
      "adjDefRank": 324,
      "adjEM": -12.45,
      "adjOff": 99.8,
      "adjOffRank": 237,
      "adjTempo": 66.9,
      "adjTempoRank": 84,
      "conference": "SB",
      "rank": 295,
      "record": "12-16"
    },
    "South Carolina": {
      "adjDef": 98,
      "adjDefRank": 69,
      "adjEM": 3.2,
      "adjOff": 101.2,
      "adjOffRank": 212,
      "adjTempo": 67,
      "adjTempoRank": 80,
      "conference": "SEC",
      "rank": 130,
      "record": "14-16"
    },
    "South Carolina St.": {
      "adjDef": 110.9,
      "adjDefRank": 306,
      "adjEM": -19.21,
      "adjOff": 91.7,
      "adjOffRank": 325,
      "adjTempo": 66.3,
      "adjTempoRank": 113,
      "conference": "MEAC",
      "rank": 327,
      "record": "10-22"
    },
    "South Dakota": {
      "adjDef": 111.5,
      "adjDefRank": 318,
      "adjEM": -11.46,
      "adjOff": 100.1,
      "adjOffRank": 233,
      "adjTempo": 70.9,
      "adjTempoRank": 13,
      "conference": "GWC",
      "rank": 288,
      "record": "18-15"
    },
    "South Dakota St.": {
      "adjDef": 110.5,
      "adjDefRank": 305,
      "adjEM": 5.39,
      "adjOff": 115.9,
      "adjOffRank": 17,
      "adjTempo": 67.2,
      "adjTempoRank": 71,
      "conference": "Sum",
      "rank": 110,
      "record": "19-12"
    },
    "South Florida": {
      "adjDef": 100.4,
      "adjDefRank": 111,
      "adjEM": 3.34,
      "adjOff": 103.7,
      "adjOffRank": 162,
      "adjTempo": 61.1,
      "adjTempoRank": 326,
      "conference": "BE",
      "rank": 129,
      "record": "10-23"
    },
    "Southeast Missouri St.": {
      "adjDef": 110.3,
      "adjDefRank": 303,
      "adjEM": -12.51,
      "adjOff": 97.8,
      "adjOffRank": 268,
      "adjTempo": 66.1,
      "adjTempoRank": 127,
      "conference": "OVC",
      "rank": 296,
      "record": "10-22"
    },
    "Southeastern Louisiana": {
      "adjDef": 107.3,
      "adjDefRank": 254,
      "adjEM": -8.49,
      "adjOff": 98.8,
      "adjOffRank": 254,
      "adjTempo": 64.6,
      "adjTempoRank": 211,
      "conference": "Slnd",
      "rank": 257,
      "record": "15-14"
    },
    "Southern": {
      "adjDef": 112.7,
      "adjDefRank": 328,
      "adjEM": -30.44,
      "adjOff": 82.3,
      "adjOffRank": 345,
      "adjTempo": 64.6,
      "adjTempoRank": 214,
      "conference": "SWAC",
      "rank": 345,
      "record": "4-26"
    },
    "Southern Illinois": {
      "adjDef": 101.4,
      "adjDefRank": 126,
      "adjEM": -4.39,
      "adjOff": 97,
      "adjOffRank": 278,
      "adjTempo": 63.2,
      "adjTempoRank": 274,
      "conference": "MVC",
      "rank": 213,
      "record": "13-19"
    },
    "Southern Miss": {
      "adjDef": 101.8,
      "adjDefRank": 130,
      "adjEM": 10.21,
      "adjOff": 112,
      "adjOffRank": 46,
      "adjTempo": 64.5,
      "adjTempoRank": 222,
      "conference": "CUSA",
      "rank": 74,
      "record": "22-10"
    },
    "Southern Utah": {
      "adjDef": 109.5,
      "adjDefRank": 290,
      "adjEM": -8.93,
      "adjOff": 100.6,
      "adjOffRank": 224,
      "adjTempo": 66.3,
      "adjTempoRank": 112,
      "conference": "Sum",
      "rank": 260,
      "record": "11-19"
    },
    "St. Bonaventure": {
      "adjDef": 104.7,
      "adjDefRank": 207,
      "adjEM": 1.85,
      "adjOff": 106.6,
      "adjOffRank": 107,
      "adjTempo": 62.8,
      "adjTempoRank": 287,
      "conference": "A10",
      "rank": 151,
      "record": "16-15"
    },
    "St. Francis NY": {
      "adjDef": 102.7,
      "adjDefRank": 151,
      "adjEM": -3.84,
      "adjOff": 98.9,
      "adjOffRank": 252,
      "adjTempo": 65.9,
      "adjTempoRank": 145,
      "conference": "NEC",
      "rank": 205,
      "record": "15-15"
    },
    "St. Francis PA": {
      "adjDef": 110.1,
      "adjDefRank": 301,
      "adjEM": -10.57,
      "adjOff": 99.6,
      "adjOffRank": 240,
      "adjTempo": 64.9,
      "adjTempoRank": 195,
      "conference": "NEC",
      "rank": 280,
      "record": "9-21"
    },
    "St. John's": {
      "adjDef": 95.3,
      "adjDefRank": 40,
      "adjEM": 15.87,
      "adjOff": 111.2,
      "adjOffRank": 53,
      "adjTempo": 66,
      "adjTempoRank": 133,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 41,
      "record": "21-12",
      "seed": 6
    },
    "Stanford": {
      "adjDef": 98.6,
      "adjDefRank": 79,
      "adjEM": 6.16,
      "adjOff": 104.7,
      "adjOffRank": 141,
      "adjTempo": 64,
      "adjTempoRank": 243,
      "conference": "P10",
      "rank": 102,
      "record": "15-16"
    },
    "Stephen F. Austin": {
      "adjDef": 100.9,
      "adjDefRank": 117,
      "adjEM": -4.26,
      "adjOff": 96.6,
      "adjOffRank": 282,
      "adjTempo": 59,
      "adjTempoRank": 343,
      "conference": "Slnd",
      "rank": 209,
      "record": "18-11"
    },
    "Stetson": {
      "adjDef": 109.7,
      "adjDefRank": 292,
      "adjEM": -12.94,
      "adjOff": 96.8,
      "adjOffRank": 280,
      "adjTempo": 65.1,
      "adjTempoRank": 177,
      "conference": "ASun",
      "rank": 300,
      "record": "8-23"
    },
    "Stony Brook": {
      "adjDef": 99.4,
      "adjDefRank": 90,
      "adjEM": -5.69,
      "adjOff": 93.7,
      "adjOffRank": 307,
      "adjTempo": 62.3,
      "adjTempoRank": 305,
      "conference": "AE",
      "rank": 233,
      "record": "15-17"
    },
    "Syracuse": {
      "adjDef": 92.4,
      "adjDefRank": 17,
      "adjEM": 22.49,
      "adjOff": 114.9,
      "adjOffRank": 24,
      "adjTempo": 64.6,
      "adjTempoRank": 212,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 13,
      "record": "27-8",
      "seed": 3
    },
    "TCU": {
      "adjDef": 102.9,
      "adjDefRank": 158,
      "adjEM": -0.54,
      "adjOff": 102.4,
      "adjOffRank": 188,
      "adjTempo": 65.9,
      "adjTempoRank": 142,
      "conference": "MWC",
      "rank": 171,
      "record": "11-22"
    },
    "Temple": {
      "adjDef": 93.7,
      "adjDefRank": 26,
      "adjEM": 16.84,
      "adjOff": 110.5,
      "adjOffRank": 60,
      "adjTempo": 64.2,
      "adjTempoRank": 232,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 34,
      "record": "26-8",
      "seed": 7
    },
    "Tennessee": {
      "adjDef": 95.6,
      "adjDefRank": 43,
      "adjEM": 12.41,
      "adjOff": 108,
      "adjOffRank": 90,
      "adjTempo": 66.1,
      "adjTempoRank": 131,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 62,
      "record": "19-15",
      "seed": 9
    },
    "Tennessee Martin": {
      "adjDef": 110.1,
      "adjDefRank": 302,
      "adjEM": -12.6,
      "adjOff": 97.5,
      "adjOffRank": 273,
      "adjTempo": 68,
      "adjTempoRank": 52,
      "conference": "OVC",
      "rank": 297,
      "record": "12-21"
    },
    "Tennessee St.": {
      "adjDef": 108.8,
      "adjDefRank": 277,
      "adjEM": -6.71,
      "adjOff": 102.1,
      "adjOffRank": 193,
      "adjTempo": 64.1,
      "adjTempoRank": 238,
      "conference": "OVC",
      "rank": 241,
      "record": "14-16"
    },
    "Tennessee Tech": {
      "adjDef": 105.2,
      "adjDefRank": 217,
      "adjEM": -4.36,
      "adjOff": 100.9,
      "adjOffRank": 218,
      "adjTempo": 69.8,
      "adjTempoRank": 24,
      "conference": "OVC",
      "rank": 210,
      "record": "20-13"
    },
    "Texas": {
      "adjDef": 89.6,
      "adjDefRank": 4,
      "adjEM": 25.93,
      "adjOff": 115.5,
      "adjOffRank": 21,
      "adjTempo": 65.5,
      "adjTempoRank": 161,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 5,
      "record": "28-8",
      "seed": 4
    },
    "Texas A&M": {
      "adjDef": 95.4,
      "adjDefRank": 42,
      "adjEM": 14.62,
      "adjOff": 110,
      "adjOffRank": 65,
      "adjTempo": 60.9,
      "adjTempoRank": 328,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 49,
      "record": "24-9",
      "seed": 7
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 109.1,
      "adjDefRank": 285,
      "adjEM": -16.12,
      "adjOff": 92.9,
      "adjOffRank": 313,
      "adjTempo": 64.5,
      "adjTempoRank": 223,
      "conference": "Slnd",
      "rank": 316,
      "record": "10-21"
    },
    "Texas Pan American": {
      "adjDef": 115,
      "adjDefRank": 341,
      "adjEM": -20.94,
      "adjOff": 94.1,
      "adjOffRank": 305,
      "adjTempo": 64.8,
      "adjTempoRank": 198,
      "conference": "GWC",
      "rank": 333,
      "record": "6-25"
    },
    "Texas Southern": {
      "adjDef": 103.5,
      "adjDefRank": 175,
      "adjEM": -10.37,
      "adjOff": 93.2,
      "adjOffRank": 311,
      "adjTempo": 64.8,
      "adjTempoRank": 197,
      "conference": "SWAC",
      "rank": 276,
      "record": "19-13"
    },
    "Texas St.": {
      "adjDef": 108.3,
      "adjDefRank": 271,
      "adjEM": -7.96,
      "adjOff": 100.3,
      "adjOffRank": 230,
      "adjTempo": 72.9,
      "adjTempoRank": 5,
      "conference": "Slnd",
      "rank": 251,
      "record": "16-16"
    },
    "Texas Tech": {
      "adjDef": 103.4,
      "adjDefRank": 170,
      "adjEM": 3.83,
      "adjOff": 107.2,
      "adjOffRank": 96,
      "adjTempo": 69.3,
      "adjTempoRank": 31,
      "conference": "B12",
      "rank": 121,
      "record": "13-19"
    },
    "The Citadel": {
      "adjDef": 111.6,
      "adjDefRank": 319,
      "adjEM": -10.61,
      "adjOff": 100.9,
      "adjOffRank": 216,
      "adjTempo": 61.9,
      "adjTempoRank": 312,
      "conference": "SC",
      "rank": 282,
      "record": "10-22"
    },
    "Toledo": {
      "adjDef": 112.6,
      "adjDefRank": 327,
      "adjEM": -20.65,
      "adjOff": 91.9,
      "adjOffRank": 324,
      "adjTempo": 62.8,
      "adjTempoRank": 288,
      "conference": "MAC",
      "rank": 332,
      "record": "4-28"
    },
    "Towson": {
      "adjDef": 116.3,
      "adjDefRank": 342,
      "adjEM": -10.51,
      "adjOff": 105.8,
      "adjOffRank": 115,
      "adjTempo": 65.1,
      "adjTempoRank": 178,
      "conference": "CAA",
      "rank": 278,
      "record": "4-26"
    },
    "Troy": {
      "adjDef": 108,
      "adjDefRank": 264,
      "adjEM": -10.19,
      "adjOff": 97.8,
      "adjOffRank": 266,
      "adjTempo": 70.2,
      "adjTempoRank": 18,
      "conference": "SB",
      "rank": 275,
      "record": "8-21"
    },
    "Tulane": {
      "adjDef": 104.7,
      "adjDefRank": 206,
      "adjEM": -2.34,
      "adjOff": 102.4,
      "adjOffRank": 189,
      "adjTempo": 64.7,
      "adjTempoRank": 209,
      "conference": "CUSA",
      "rank": 191,
      "record": "13-17"
    },
    "Tulsa": {
      "adjDef": 100.2,
      "adjDefRank": 106,
      "adjEM": 6.92,
      "adjOff": 107.1,
      "adjOffRank": 98,
      "adjTempo": 65.7,
      "adjTempoRank": 153,
      "conference": "CUSA",
      "rank": 91,
      "record": "19-13"
    },
    "UAB": {
      "adjDef": 97.2,
      "adjDefRank": 60,
      "adjEM": 11.64,
      "adjOff": 108.8,
      "adjOffRank": 78,
      "adjTempo": 61.8,
      "adjTempoRank": 314,
      "conference": "CUSA",
      "finish": "First Four",
      "rank": 65,
      "record": "22-9",
      "seed": 12
    },
    "UC Davis": {
      "adjDef": 109.9,
      "adjDefRank": 297,
      "adjEM": -7.08,
      "adjOff": 102.8,
      "adjOffRank": 182,
      "adjTempo": 66.4,
      "adjTempoRank": 109,
      "conference": "BW",
      "rank": 246,
      "record": "10-20"
    },
    "UC Irvine": {
      "adjDef": 107.2,
      "adjDefRank": 251,
      "adjEM": -4.62,
      "adjOff": 102.6,
      "adjOffRank": 186,
      "adjTempo": 69.8,
      "adjTempoRank": 23,
      "conference": "BW",
      "rank": 218,
      "record": "13-19"
    },
    "UC Riverside": {
      "adjDef": 107.2,
      "adjDefRank": 249,
      "adjEM": -9.36,
      "adjOff": 97.8,
      "adjOffRank": 267,
      "adjTempo": 64.8,
      "adjTempoRank": 200,
      "conference": "BW",
      "rank": 265,
      "record": "12-19"
    },
    "UC Santa Barbara": {
      "adjDef": 100.5,
      "adjDefRank": 112,
      "adjEM": 3.15,
      "adjOff": 103.7,
      "adjOffRank": 164,
      "adjTempo": 64.1,
      "adjTempoRank": 241,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 132,
      "record": "18-14",
      "seed": 15
    },
    "UCF": {
      "adjDef": 99.9,
      "adjDefRank": 100,
      "adjEM": 5.87,
      "adjOff": 105.7,
      "adjOffRank": 118,
      "adjTempo": 64.2,
      "adjTempoRank": 233,
      "conference": "CUSA",
      "rank": 105,
      "record": "21-12"
    },
    "UCLA": {
      "adjDef": 95.1,
      "adjDefRank": 36,
      "adjEM": 13.57,
      "adjOff": 108.7,
      "adjOffRank": 80,
      "adjTempo": 65.5,
      "adjTempoRank": 163,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 52,
      "record": "23-11",
      "seed": 7
    },
    "UMBC": {
      "adjDef": 118.3,
      "adjDefRank": 343,
      "adjEM": -22.38,
      "adjOff": 96,
      "adjOffRank": 288,
      "adjTempo": 66.9,
      "adjTempoRank": 85,
      "conference": "AE",
      "rank": 339,
      "record": "5-25"
    },
    "UMKC": {
      "adjDef": 111.6,
      "adjDefRank": 320,
      "adjEM": -9.79,
      "adjOff": 101.9,
      "adjOffRank": 199,
      "adjTempo": 63.8,
      "adjTempoRank": 249,
      "conference": "Sum",
      "rank": 271,
      "record": "16-14"
    },
    "UNC Asheville": {
      "adjDef": 100,
      "adjDefRank": 102,
      "adjEM": 1.91,
      "adjOff": 101.9,
      "adjOffRank": 197,
      "adjTempo": 67.4,
      "adjTempoRank": 66,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 150,
      "record": "20-14",
      "seed": 16
    },
    "UNC Greensboro": {
      "adjDef": 110.4,
      "adjDefRank": 304,
      "adjEM": -10.86,
      "adjOff": 99.6,
      "adjOffRank": 241,
      "adjTempo": 69.5,
      "adjTempoRank": 29,
      "conference": "SC",
      "rank": 284,
      "record": "7-24"
    },
    "UNC Wilmington": {
      "adjDef": 105.9,
      "adjDefRank": 234,
      "adjEM": -3.82,
      "adjOff": 102,
      "adjOffRank": 195,
      "adjTempo": 61.8,
      "adjTempoRank": 316,
      "conference": "CAA",
      "rank": 204,
      "record": "13-18"
    },
    "UNLV": {
      "adjDef": 92.9,
      "adjDefRank": 21,
      "adjEM": 17.75,
      "adjOff": 110.6,
      "adjOffRank": 57,
      "adjTempo": 66.4,
      "adjTempoRank": 110,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 29,
      "record": "24-9",
      "seed": 8
    },
    "USC": {
      "adjDef": 94.6,
      "adjDefRank": 33,
      "adjEM": 13.29,
      "adjOff": 107.9,
      "adjOffRank": 91,
      "adjTempo": 61.7,
      "adjTempoRank": 318,
      "conference": "P10",
      "finish": "First Four",
      "rank": 57,
      "record": "19-15",
      "seed": 11
    },
    "USC Upstate": {
      "adjDef": 109.1,
      "adjDefRank": 284,
      "adjEM": -16.44,
      "adjOff": 92.6,
      "adjOffRank": 320,
      "adjTempo": 63,
      "adjTempoRank": 280,
      "conference": "ASun",
      "rank": 319,
      "record": "5-25"
    },
    "UT Arlington": {
      "adjDef": 104.8,
      "adjDefRank": 208,
      "adjEM": -8.13,
      "adjOff": 96.7,
      "adjOffRank": 281,
      "adjTempo": 67.1,
      "adjTempoRank": 75,
      "conference": "Slnd",
      "rank": 256,
      "record": "13-16"
    },
    "UTEP": {
      "adjDef": 94.6,
      "adjDefRank": 32,
      "adjEM": 10.39,
      "adjOff": 105,
      "adjOffRank": 132,
      "adjTempo": 66.4,
      "adjTempoRank": 107,
      "conference": "CUSA",
      "rank": 72,
      "record": "25-10"
    },
    "UTSA": {
      "adjDef": 107.1,
      "adjDefRank": 248,
      "adjEM": -5.32,
      "adjOff": 101.8,
      "adjOffRank": 201,
      "adjTempo": 66.7,
      "adjTempoRank": 98,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 227,
      "record": "20-14",
      "seed": 16
    },
    "Utah": {
      "adjDef": 101,
      "adjDefRank": 119,
      "adjEM": 2.61,
      "adjOff": 103.6,
      "adjOffRank": 165,
      "adjTempo": 67.2,
      "adjTempoRank": 70,
      "conference": "MWC",
      "rank": 141,
      "record": "13-18"
    },
    "Utah St.": {
      "adjDef": 91.8,
      "adjDefRank": 14,
      "adjEM": 19.32,
      "adjOff": 111.2,
      "adjOffRank": 54,
      "adjTempo": 62.4,
      "adjTempoRank": 302,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 22,
      "record": "30-4",
      "seed": 12
    },
    "Utah Valley": {
      "adjDef": 108.8,
      "adjDefRank": 278,
      "adjEM": -8.02,
      "adjOff": 100.8,
      "adjOffRank": 220,
      "adjTempo": 65.3,
      "adjTempoRank": 169,
      "conference": "GWC",
      "rank": 255,
      "record": "19-11"
    },
    "VCU": {
      "adjDef": 98.5,
      "adjDefRank": 78,
      "adjEM": 13.49,
      "adjOff": 112,
      "adjOffRank": 47,
      "adjTempo": 65.6,
      "adjTempoRank": 156,
      "conference": "CAA",
      "finish": "Final Four",
      "rank": 53,
      "record": "28-12",
      "seed": 11
    },
    "VMI": {
      "adjDef": 113.9,
      "adjDefRank": 337,
      "adjEM": -3.34,
      "adjOff": 110.6,
      "adjOffRank": 58,
      "adjTempo": 74.7,
      "adjTempoRank": 2,
      "conference": "BSth",
      "rank": 199,
      "record": "18-13"
    },
    "Valparaiso": {
      "adjDef": 99.8,
      "adjDefRank": 97,
      "adjEM": 6.6,
      "adjOff": 106.4,
      "adjOffRank": 108,
      "adjTempo": 66.2,
      "adjTempoRank": 122,
      "conference": "Horz",
      "rank": 95,
      "record": "23-12"
    },
    "Vanderbilt": {
      "adjDef": 99.2,
      "adjDefRank": 87,
      "adjEM": 17.33,
      "adjOff": 116.6,
      "adjOffRank": 16,
      "adjTempo": 66.1,
      "adjTempoRank": 125,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "23-11",
      "seed": 5
    },
    "Vermont": {
      "adjDef": 99.1,
      "adjDefRank": 83,
      "adjEM": 3.36,
      "adjOff": 102.4,
      "adjOffRank": 187,
      "adjTempo": 64.5,
      "adjTempoRank": 219,
      "conference": "AE",
      "rank": 128,
      "record": "23-9"
    },
    "Villanova": {
      "adjDef": 97,
      "adjDefRank": 59,
      "adjEM": 17.83,
      "adjOff": 114.9,
      "adjOffRank": 25,
      "adjTempo": 65,
      "adjTempoRank": 182,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 28,
      "record": "21-12",
      "seed": 9
    },
    "Virginia": {
      "adjDef": 98,
      "adjDefRank": 71,
      "adjEM": 6.01,
      "adjOff": 104,
      "adjOffRank": 159,
      "adjTempo": 59.1,
      "adjTempoRank": 340,
      "conference": "ACC",
      "rank": 103,
      "record": "16-15"
    },
    "Virginia Tech": {
      "adjDef": 93.7,
      "adjDefRank": 27,
      "adjEM": 15.91,
      "adjOff": 109.7,
      "adjOffRank": 70,
      "adjTempo": 63.7,
      "adjTempoRank": 253,
      "conference": "ACC",
      "rank": 40,
      "record": "22-12"
    },
    "Wagner": {
      "adjDef": 105.9,
      "adjDefRank": 233,
      "adjEM": -5.41,
      "adjOff": 100.4,
      "adjOffRank": 227,
      "adjTempo": 66.3,
      "adjTempoRank": 115,
      "conference": "NEC",
      "rank": 230,
      "record": "13-17"
    },
    "Wake Forest": {
      "adjDef": 109,
      "adjDefRank": 282,
      "adjEM": -8.64,
      "adjOff": 100.4,
      "adjOffRank": 229,
      "adjTempo": 68.9,
      "adjTempoRank": 35,
      "conference": "ACC",
      "rank": 259,
      "record": "8-24"
    },
    "Washington": {
      "adjDef": 96,
      "adjDefRank": 49,
      "adjEM": 21.69,
      "adjOff": 117.7,
      "adjOffRank": 10,
      "adjTempo": 69.9,
      "adjTempoRank": 21,
      "conference": "P10",
      "finish": "Round of 32",
      "rank": 16,
      "record": "24-11",
      "seed": 7
    },
    "Washington St.": {
      "adjDef": 95.3,
      "adjDefRank": 39,
      "adjEM": 12.78,
      "adjOff": 108.1,
      "adjOffRank": 88,
      "adjTempo": 67.4,
      "adjTempoRank": 65,
      "conference": "P10",
      "rank": 60,
      "record": "22-13"
    },
    "Weber St.": {
      "adjDef": 104.4,
      "adjDefRank": 193,
      "adjEM": -0.21,
      "adjOff": 104.1,
      "adjOffRank": 154,
      "adjTempo": 64.4,
      "adjTempoRank": 227,
      "conference": "BSky",
      "rank": 169,
      "record": "18-14"
    },
    "West Virginia": {
      "adjDef": 94.8,
      "adjDefRank": 35,
      "adjEM": 20.11,
      "adjOff": 114.9,
      "adjOffRank": 23,
      "adjTempo": 62.5,
      "adjTempoRank": 298,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 19,
      "record": "21-12",
      "seed": 5
    },
    "Western Carolina": {
      "adjDef": 100.5,
      "adjDefRank": 113,
      "adjEM": -1.81,
      "adjOff": 98.7,
      "adjOffRank": 255,
      "adjTempo": 68.1,
      "adjTempoRank": 50,
      "conference": "SC",
      "rank": 184,
      "record": "18-15"
    },
    "Western Illinois": {
      "adjDef": 107.6,
      "adjDefRank": 257,
      "adjEM": -20.01,
      "adjOff": 87.6,
      "adjOffRank": 337,
      "adjTempo": 61.2,
      "adjTempoRank": 324,
      "conference": "Sum",
      "rank": 330,
      "record": "7-23"
    },
    "Western Kentucky": {
      "adjDef": 105.1,
      "adjDefRank": 215,
      "adjEM": -4.57,
      "adjOff": 100.5,
      "adjOffRank": 226,
      "adjTempo": 66.8,
      "adjTempoRank": 93,
      "conference": "SB",
      "rank": 216,
      "record": "16-16"
    },
    "Western Michigan": {
      "adjDef": 104.1,
      "adjDefRank": 188,
      "adjEM": -0.12,
      "adjOff": 104,
      "adjOffRank": 158,
      "adjTempo": 66.8,
      "adjTempoRank": 91,
      "conference": "MAC",
      "rank": 166,
      "record": "21-13"
    },
    "Wichita St.": {
      "adjDef": 95.9,
      "adjDefRank": 48,
      "adjEM": 17.94,
      "adjOff": 113.9,
      "adjOffRank": 31,
      "adjTempo": 64.9,
      "adjTempoRank": 193,
      "conference": "MVC",
      "rank": 27,
      "record": "29-8"
    },
    "William & Mary": {
      "adjDef": 108.3,
      "adjDefRank": 273,
      "adjEM": -5.31,
      "adjOff": 103,
      "adjOffRank": 175,
      "adjTempo": 62.9,
      "adjTempoRank": 282,
      "conference": "CAA",
      "rank": 226,
      "record": "10-22"
    },
    "Winthrop": {
      "adjDef": 101.1,
      "adjDefRank": 122,
      "adjEM": -4.85,
      "adjOff": 96.3,
      "adjOffRank": 287,
      "adjTempo": 64.9,
      "adjTempoRank": 196,
      "conference": "BSth",
      "rank": 221,
      "record": "13-17"
    },
    "Wisconsin": {
      "adjDef": 96.5,
      "adjDefRank": 52,
      "adjEM": 25.84,
      "adjOff": 122.3,
      "adjOffRank": 2,
      "adjTempo": 56.9,
      "adjTempoRank": 344,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 6,
      "record": "25-9",
      "seed": 4
    },
    "Wofford": {
      "adjDef": 104.2,
      "adjDefRank": 189,
      "adjEM": 8.17,
      "adjOff": 112.3,
      "adjOffRank": 44,
      "adjTempo": 63.2,
      "adjTempoRank": 275,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 84,
      "record": "21-13",
      "seed": 14
    },
    "Wright St.": {
      "adjDef": 99.8,
      "adjDefRank": 98,
      "adjEM": 5.23,
      "adjOff": 105,
      "adjOffRank": 130,
      "adjTempo": 61.6,
      "adjTempoRank": 320,
      "conference": "Horz",
      "rank": 111,
      "record": "19-14"
    },
    "Wyoming": {
      "adjDef": 103.9,
      "adjDefRank": 187,
      "adjEM": -5.4,
      "adjOff": 98.5,
      "adjOffRank": 259,
      "adjTempo": 66,
      "adjTempoRank": 135,
      "conference": "MWC",
      "rank": 229,
      "record": "10-21"
    },
    "Xavier": {
      "adjDef": 96.8,
      "adjDefRank": 58,
      "adjEM": 16.02,
      "adjOff": 112.8,
      "adjOffRank": 37,
      "adjTempo": 64.3,
      "adjTempoRank": 229,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 38,
      "record": "24-8",
      "seed": 6
    },
    "Yale": {
      "adjDef": 101.1,
      "adjDefRank": 121,
      "adjEM": -1.6,
      "adjOff": 99.5,
      "adjOffRank": 242,
      "adjTempo": 65.7,
      "adjTempoRank": 155,
      "conference": "Ivy",
      "rank": 182,
      "record": "15-13"
    },
    "Youngstown St.": {
      "adjDef": 108,
      "adjDefRank": 262,
      "adjEM": -7.96,
      "adjOff": 100,
      "adjOffRank": 235,
      "adjTempo": 68.5,
      "adjTempoRank": 41,
      "conference": "Horz",
      "rank": 252,
      "record": "9-21"
    }
  },
  "2012": {
    "Air Force": {
      "adjDef": 102.2,
      "adjDefRank": 155,
      "adjEM": -2.2,
      "adjOff": 100,
      "adjOffRank": 229,
      "adjTempo": 61.1,
      "adjTempoRank": 319,
      "conference": "MWC",
      "rank": 188,
      "record": "13-16"
    },
    "Akron": {
      "adjDef": 97.7,
      "adjDefRank": 74,
      "adjEM": 10.66,
      "adjOff": 108.3,
      "adjOffRank": 81,
      "adjTempo": 66.4,
      "adjTempoRank": 103,
      "conference": "MAC",
      "rank": 70,
      "record": "22-12"
    },
    "Alabama": {
      "adjDef": 89.5,
      "adjDefRank": 6,
      "adjEM": 16.69,
      "adjOff": 106.1,
      "adjOffRank": 115,
      "adjTempo": 61.9,
      "adjTempoRank": 302,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 27,
      "record": "21-12",
      "seed": 9
    },
    "Alabama A&M": {
      "adjDef": 111.4,
      "adjDefRank": 318,
      "adjEM": -21.42,
      "adjOff": 90,
      "adjOffRank": 329,
      "adjTempo": 66.8,
      "adjTempoRank": 91,
      "conference": "SWAC",
      "rank": 337,
      "record": "7-21"
    },
    "Alabama St.": {
      "adjDef": 104.7,
      "adjDefRank": 213,
      "adjEM": -16.87,
      "adjOff": 87.8,
      "adjOffRank": 338,
      "adjTempo": 63.5,
      "adjTempoRank": 252,
      "conference": "SWAC",
      "rank": 315,
      "record": "12-19"
    },
    "Albany": {
      "adjDef": 111.6,
      "adjDefRank": 320,
      "adjEM": -2.8,
      "adjOff": 108.8,
      "adjOffRank": 71,
      "adjTempo": 64.7,
      "adjTempoRank": 189,
      "conference": "AE",
      "rank": 199,
      "record": "19-15"
    },
    "Alcorn St.": {
      "adjDef": 108.6,
      "adjDefRank": 280,
      "adjEM": -20.18,
      "adjOff": 88.4,
      "adjOffRank": 337,
      "adjTempo": 67.4,
      "adjTempoRank": 61,
      "conference": "SWAC",
      "rank": 331,
      "record": "9-22"
    },
    "American": {
      "adjDef": 102.2,
      "adjDefRank": 153,
      "adjEM": -0.91,
      "adjOff": 101.3,
      "adjOffRank": 199,
      "adjTempo": 61.7,
      "adjTempoRank": 306,
      "conference": "Pat",
      "rank": 177,
      "record": "20-12"
    },
    "Appalachian St.": {
      "adjDef": 108,
      "adjDefRank": 275,
      "adjEM": -8.4,
      "adjOff": 99.6,
      "adjOffRank": 235,
      "adjTempo": 65.5,
      "adjTempoRank": 142,
      "conference": "SC",
      "rank": 258,
      "record": "13-18"
    },
    "Arizona": {
      "adjDef": 94.9,
      "adjDefRank": 37,
      "adjEM": 12.77,
      "adjOff": 107.6,
      "adjOffRank": 87,
      "adjTempo": 64.3,
      "adjTempoRank": 205,
      "conference": "P12",
      "rank": 51,
      "record": "23-12"
    },
    "Arizona St.": {
      "adjDef": 106.1,
      "adjDefRank": 245,
      "adjEM": -5.55,
      "adjOff": 100.6,
      "adjOffRank": 219,
      "adjTempo": 62.5,
      "adjTempoRank": 285,
      "conference": "P12",
      "rank": 230,
      "record": "10-21"
    },
    "Arkansas": {
      "adjDef": 102.4,
      "adjDefRank": 156,
      "adjEM": 4.07,
      "adjOff": 106.4,
      "adjOffRank": 107,
      "adjTempo": 69.5,
      "adjTempoRank": 21,
      "conference": "SEC",
      "rank": 127,
      "record": "18-14"
    },
    "Arkansas Little Rock": {
      "adjDef": 99.9,
      "adjDefRank": 113,
      "adjEM": -1.56,
      "adjOff": 98.3,
      "adjOffRank": 251,
      "adjTempo": 63.7,
      "adjTempoRank": 245,
      "conference": "SB",
      "rank": 180,
      "record": "15-16"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 111.5,
      "adjDefRank": 319,
      "adjEM": -17.97,
      "adjOff": 93.5,
      "adjOffRank": 307,
      "adjTempo": 65.2,
      "adjTempoRank": 165,
      "conference": "SWAC",
      "rank": 321,
      "record": "11-22"
    },
    "Arkansas St.": {
      "adjDef": 105.3,
      "adjDefRank": 227,
      "adjEM": -2.68,
      "adjOff": 102.6,
      "adjOffRank": 172,
      "adjTempo": 62,
      "adjTempoRank": 297,
      "conference": "SB",
      "rank": 194,
      "record": "14-20"
    },
    "Army": {
      "adjDef": 106.7,
      "adjDefRank": 254,
      "adjEM": -12.92,
      "adjOff": 93.7,
      "adjOffRank": 306,
      "adjTempo": 68.2,
      "adjTempoRank": 38,
      "conference": "Pat",
      "rank": 297,
      "record": "12-18"
    },
    "Auburn": {
      "adjDef": 97.5,
      "adjDefRank": 70,
      "adjEM": 2.1,
      "adjOff": 99.6,
      "adjOffRank": 236,
      "adjTempo": 64,
      "adjTempoRank": 224,
      "conference": "SEC",
      "rank": 147,
      "record": "15-16"
    },
    "Austin Peay": {
      "adjDef": 106,
      "adjDefRank": 237,
      "adjEM": -3.5,
      "adjOff": 102.5,
      "adjOffRank": 174,
      "adjTempo": 67.5,
      "adjTempoRank": 58,
      "conference": "OVC",
      "rank": 209,
      "record": "12-20"
    },
    "BYU": {
      "adjDef": 93.5,
      "adjDefRank": 21,
      "adjEM": 12.79,
      "adjOff": 106.3,
      "adjOffRank": 111,
      "adjTempo": 72.6,
      "adjTempoRank": 5,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "26-9",
      "seed": 14
    },
    "Ball St.": {
      "adjDef": 102.8,
      "adjDefRank": 171,
      "adjEM": -2.69,
      "adjOff": 100.1,
      "adjOffRank": 227,
      "adjTempo": 62.3,
      "adjTempoRank": 290,
      "conference": "MAC",
      "rank": 195,
      "record": "15-15"
    },
    "Baylor": {
      "adjDef": 94.7,
      "adjDefRank": 34,
      "adjEM": 21.29,
      "adjOff": 116,
      "adjOffRank": 13,
      "adjTempo": 65.6,
      "adjTempoRank": 138,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 13,
      "record": "30-8",
      "seed": 3
    },
    "Belmont": {
      "adjDef": 100.1,
      "adjDefRank": 121,
      "adjEM": 16.96,
      "adjOff": 117.1,
      "adjOffRank": 9,
      "adjTempo": 67.2,
      "adjTempoRank": 72,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 25,
      "record": "27-8",
      "seed": 14
    },
    "Bethune Cookman": {
      "adjDef": 109.7,
      "adjDefRank": 293,
      "adjEM": -8.23,
      "adjOff": 101.4,
      "adjOffRank": 194,
      "adjTempo": 62.4,
      "adjTempoRank": 289,
      "conference": "MEAC",
      "rank": 256,
      "record": "18-17"
    },
    "Binghamton": {
      "adjDef": 112.5,
      "adjDefRank": 325,
      "adjEM": -25.99,
      "adjOff": 86.5,
      "adjOffRank": 342,
      "adjTempo": 64.6,
      "adjTempoRank": 195,
      "conference": "AE",
      "rank": 344,
      "record": "2-29"
    },
    "Boise St.": {
      "adjDef": 103.9,
      "adjDefRank": 194,
      "adjEM": 1.38,
      "adjOff": 105.3,
      "adjOffRank": 130,
      "adjTempo": 64.3,
      "adjTempoRank": 203,
      "conference": "MWC",
      "rank": 154,
      "record": "13-17"
    },
    "Boston College": {
      "adjDef": 104.3,
      "adjDefRank": 204,
      "adjEM": -8.67,
      "adjOff": 95.7,
      "adjOffRank": 287,
      "adjTempo": 63.8,
      "adjTempoRank": 241,
      "conference": "ACC",
      "rank": 261,
      "record": "9-22"
    },
    "Boston University": {
      "adjDef": 101.6,
      "adjDefRank": 145,
      "adjEM": -1.6,
      "adjOff": 100,
      "adjOffRank": 230,
      "adjTempo": 63.8,
      "adjTempoRank": 240,
      "conference": "AE",
      "rank": 181,
      "record": "16-16"
    },
    "Bowling Green": {
      "adjDef": 100,
      "adjDefRank": 117,
      "adjEM": 3.75,
      "adjOff": 103.8,
      "adjOffRank": 153,
      "adjTempo": 63.9,
      "adjTempoRank": 235,
      "conference": "MAC",
      "rank": 132,
      "record": "16-16"
    },
    "Bradley": {
      "adjDef": 103.4,
      "adjDefRank": 184,
      "adjEM": -8.46,
      "adjOff": 94.9,
      "adjOffRank": 296,
      "adjTempo": 67.4,
      "adjTempoRank": 63,
      "conference": "MVC",
      "rank": 260,
      "record": "7-25"
    },
    "Brown": {
      "adjDef": 110.9,
      "adjDefRank": 311,
      "adjEM": -15.73,
      "adjOff": 95.2,
      "adjOffRank": 293,
      "adjTempo": 64.1,
      "adjTempoRank": 222,
      "conference": "Ivy",
      "rank": 310,
      "record": "8-23"
    },
    "Bryant": {
      "adjDef": 116.4,
      "adjDefRank": 341,
      "adjEM": -22.37,
      "adjOff": 94,
      "adjOffRank": 304,
      "adjTempo": 64.6,
      "adjTempoRank": 196,
      "conference": "NEC",
      "rank": 338,
      "record": "2-28"
    },
    "Bucknell": {
      "adjDef": 99.3,
      "adjDefRank": 97,
      "adjEM": 8.11,
      "adjOff": 107.4,
      "adjOffRank": 90,
      "adjTempo": 63.2,
      "adjTempoRank": 262,
      "conference": "Pat",
      "rank": 89,
      "record": "25-10"
    },
    "Buffalo": {
      "adjDef": 99.4,
      "adjDefRank": 102,
      "adjEM": 7.92,
      "adjOff": 107.3,
      "adjOffRank": 91,
      "adjTempo": 67.3,
      "adjTempoRank": 66,
      "conference": "MAC",
      "rank": 92,
      "record": "20-11"
    },
    "Butler": {
      "adjDef": 94.4,
      "adjDefRank": 28,
      "adjEM": 5.31,
      "adjOff": 99.7,
      "adjOffRank": 232,
      "adjTempo": 64.1,
      "adjTempoRank": 218,
      "conference": "Horz",
      "rank": 116,
      "record": "22-15"
    },
    "Cal Poly": {
      "adjDef": 103.9,
      "adjDefRank": 193,
      "adjEM": 0.49,
      "adjOff": 104.4,
      "adjOffRank": 143,
      "adjTempo": 59.1,
      "adjTempoRank": 341,
      "conference": "BW",
      "rank": 166,
      "record": "18-15"
    },
    "Cal St. Bakersfield": {
      "adjDef": 108.6,
      "adjDefRank": 281,
      "adjEM": -6.72,
      "adjOff": 101.9,
      "adjOffRank": 188,
      "adjTempo": 65,
      "adjTempoRank": 175,
      "conference": "ind",
      "rank": 244,
      "record": "16-15"
    },
    "Cal St. Fullerton": {
      "adjDef": 108.2,
      "adjDefRank": 276,
      "adjEM": 0.9,
      "adjOff": 109.1,
      "adjOffRank": 69,
      "adjTempo": 68.3,
      "adjTempoRank": 36,
      "conference": "BW",
      "rank": 159,
      "record": "21-10"
    },
    "Cal St. Northridge": {
      "adjDef": 112,
      "adjDefRank": 323,
      "adjEM": -13.77,
      "adjOff": 98.2,
      "adjOffRank": 255,
      "adjTempo": 67.9,
      "adjTempoRank": 49,
      "conference": "BW",
      "rank": 303,
      "record": "7-21"
    },
    "California": {
      "adjDef": 94.9,
      "adjDefRank": 38,
      "adjEM": 16.37,
      "adjOff": 111.3,
      "adjOffRank": 43,
      "adjTempo": 65.7,
      "adjTempoRank": 135,
      "conference": "P12",
      "finish": "First Four",
      "rank": 29,
      "record": "24-10",
      "seed": 12
    },
    "Campbell": {
      "adjDef": 109.7,
      "adjDefRank": 294,
      "adjEM": -6,
      "adjOff": 103.7,
      "adjOffRank": 154,
      "adjTempo": 67.1,
      "adjTempoRank": 79,
      "conference": "BSth",
      "rank": 233,
      "record": "17-15"
    },
    "Canisius": {
      "adjDef": 113.5,
      "adjDefRank": 333,
      "adjEM": -18.63,
      "adjOff": 94.9,
      "adjOffRank": 297,
      "adjTempo": 65.3,
      "adjTempoRank": 159,
      "conference": "MAAC",
      "rank": 326,
      "record": "5-25"
    },
    "Central Arkansas": {
      "adjDef": 111,
      "adjDefRank": 314,
      "adjEM": -17.91,
      "adjOff": 93.1,
      "adjOffRank": 312,
      "adjTempo": 71.7,
      "adjTempoRank": 7,
      "conference": "Slnd",
      "rank": 320,
      "record": "8-21"
    },
    "Central Connecticut": {
      "adjDef": 103.8,
      "adjDefRank": 192,
      "adjEM": -4.11,
      "adjOff": 99.7,
      "adjOffRank": 233,
      "adjTempo": 67,
      "adjTempoRank": 84,
      "conference": "NEC",
      "rank": 216,
      "record": "13-16"
    },
    "Central Michigan": {
      "adjDef": 106.5,
      "adjDefRank": 251,
      "adjEM": -7.99,
      "adjOff": 98.5,
      "adjOffRank": 249,
      "adjTempo": 63.9,
      "adjTempoRank": 228,
      "conference": "MAC",
      "rank": 253,
      "record": "11-21"
    },
    "Charleston Southern": {
      "adjDef": 105.2,
      "adjDefRank": 225,
      "adjEM": -0.71,
      "adjOff": 104.5,
      "adjOffRank": 142,
      "adjTempo": 69.5,
      "adjTempoRank": 22,
      "conference": "BSth",
      "rank": 172,
      "record": "19-12"
    },
    "Charlotte": {
      "adjDef": 98.4,
      "adjDefRank": 85,
      "adjEM": 1.65,
      "adjOff": 100,
      "adjOffRank": 228,
      "adjTempo": 67.6,
      "adjTempoRank": 57,
      "conference": "A10",
      "rank": 151,
      "record": "13-17"
    },
    "Chattanooga": {
      "adjDef": 106.7,
      "adjDefRank": 255,
      "adjEM": -8.93,
      "adjOff": 97.7,
      "adjOffRank": 267,
      "adjTempo": 67.3,
      "adjTempoRank": 68,
      "conference": "SC",
      "rank": 264,
      "record": "11-21"
    },
    "Chicago St.": {
      "adjDef": 109.6,
      "adjDefRank": 291,
      "adjEM": -20.89,
      "adjOff": 88.7,
      "adjOffRank": 335,
      "adjTempo": 69.2,
      "adjTempoRank": 25,
      "conference": "GWC",
      "rank": 334,
      "record": "4-26"
    },
    "Cincinnati": {
      "adjDef": 93.5,
      "adjDefRank": 22,
      "adjEM": 16.03,
      "adjOff": 109.5,
      "adjOffRank": 62,
      "adjTempo": 62.9,
      "adjTempoRank": 272,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 31,
      "record": "26-11",
      "seed": 6
    },
    "Clemson": {
      "adjDef": 96.8,
      "adjDefRank": 58,
      "adjEM": 8.65,
      "adjOff": 105.5,
      "adjOffRank": 124,
      "adjTempo": 63.1,
      "adjTempoRank": 265,
      "conference": "ACC",
      "rank": 82,
      "record": "16-15"
    },
    "Cleveland St.": {
      "adjDef": 96.3,
      "adjDefRank": 50,
      "adjEM": 9.22,
      "adjOff": 105.5,
      "adjOffRank": 123,
      "adjTempo": 63.8,
      "adjTempoRank": 239,
      "conference": "Horz",
      "rank": 80,
      "record": "22-11"
    },
    "Coastal Carolina": {
      "adjDef": 104.7,
      "adjDefRank": 212,
      "adjEM": -1.91,
      "adjOff": 102.8,
      "adjOffRank": 169,
      "adjTempo": 63.8,
      "adjTempoRank": 237,
      "conference": "BSth",
      "rank": 185,
      "record": "19-12"
    },
    "Colgate": {
      "adjDef": 115.9,
      "adjDefRank": 339,
      "adjEM": -18.21,
      "adjOff": 97.7,
      "adjOffRank": 269,
      "adjTempo": 64.8,
      "adjTempoRank": 187,
      "conference": "Pat",
      "rank": 324,
      "record": "8-22"
    },
    "College of Charleston": {
      "adjDef": 101.6,
      "adjDefRank": 147,
      "adjEM": 0.84,
      "adjOff": 102.4,
      "adjOffRank": 176,
      "adjTempo": 66.4,
      "adjTempoRank": 106,
      "conference": "SC",
      "rank": 160,
      "record": "19-12"
    },
    "Colorado": {
      "adjDef": 95.9,
      "adjDefRank": 46,
      "adjEM": 8.89,
      "adjOff": 104.8,
      "adjOffRank": 137,
      "adjTempo": 65.1,
      "adjTempoRank": 170,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 81,
      "record": "24-12",
      "seed": 11
    },
    "Colorado St.": {
      "adjDef": 103.7,
      "adjDefRank": 189,
      "adjEM": 8.17,
      "adjOff": 111.9,
      "adjOffRank": 35,
      "adjTempo": 63.6,
      "adjTempoRank": 250,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 88,
      "record": "20-12",
      "seed": 11
    },
    "Columbia": {
      "adjDef": 103,
      "adjDefRank": 178,
      "adjEM": -1.85,
      "adjOff": 101.1,
      "adjOffRank": 203,
      "adjTempo": 62.4,
      "adjTempoRank": 288,
      "conference": "Ivy",
      "rank": 184,
      "record": "15-15"
    },
    "Connecticut": {
      "adjDef": 96.8,
      "adjDefRank": 57,
      "adjEM": 14.53,
      "adjOff": 111.3,
      "adjOffRank": 42,
      "adjTempo": 63,
      "adjTempoRank": 270,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 38,
      "record": "20-14",
      "seed": 9
    },
    "Coppin St.": {
      "adjDef": 116.1,
      "adjDefRank": 340,
      "adjEM": -9.52,
      "adjOff": 106.6,
      "adjOffRank": 104,
      "adjTempo": 68.3,
      "adjTempoRank": 35,
      "conference": "MEAC",
      "rank": 273,
      "record": "14-16"
    },
    "Cornell": {
      "adjDef": 101.6,
      "adjDefRank": 143,
      "adjEM": -3.43,
      "adjOff": 98.1,
      "adjOffRank": 257,
      "adjTempo": 67.3,
      "adjTempoRank": 69,
      "conference": "Ivy",
      "rank": 207,
      "record": "12-16"
    },
    "Creighton": {
      "adjDef": 102.7,
      "adjDefRank": 166,
      "adjEM": 16.47,
      "adjOff": 119.2,
      "adjOffRank": 5,
      "adjTempo": 65.9,
      "adjTempoRank": 125,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 28,
      "record": "29-6",
      "seed": 8
    },
    "Dartmouth": {
      "adjDef": 104.1,
      "adjDefRank": 199,
      "adjEM": -12.92,
      "adjOff": 91.2,
      "adjOffRank": 322,
      "adjTempo": 62.6,
      "adjTempoRank": 278,
      "conference": "Ivy",
      "rank": 296,
      "record": "5-25"
    },
    "Davidson": {
      "adjDef": 101.4,
      "adjDefRank": 141,
      "adjEM": 10.65,
      "adjOff": 112,
      "adjOffRank": 33,
      "adjTempo": 67.4,
      "adjTempoRank": 65,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 71,
      "record": "25-8",
      "seed": 13
    },
    "Dayton": {
      "adjDef": 102.5,
      "adjDefRank": 160,
      "adjEM": 10.78,
      "adjOff": 113.3,
      "adjOffRank": 29,
      "adjTempo": 64.2,
      "adjTempoRank": 213,
      "conference": "A10",
      "rank": 69,
      "record": "20-13"
    },
    "DePaul": {
      "adjDef": 106.9,
      "adjDefRank": 261,
      "adjEM": 1.69,
      "adjOff": 108.5,
      "adjOffRank": 77,
      "adjTempo": 71.4,
      "adjTempoRank": 8,
      "conference": "BE",
      "rank": 150,
      "record": "12-19"
    },
    "Delaware": {
      "adjDef": 103.4,
      "adjDefRank": 185,
      "adjEM": -0.02,
      "adjOff": 103.4,
      "adjOffRank": 159,
      "adjTempo": 67.2,
      "adjTempoRank": 73,
      "conference": "CAA",
      "rank": 169,
      "record": "18-14"
    },
    "Delaware St.": {
      "adjDef": 107.8,
      "adjDefRank": 269,
      "adjEM": -6.55,
      "adjOff": 101.3,
      "adjOffRank": 200,
      "adjTempo": 60.3,
      "adjTempoRank": 327,
      "conference": "MEAC",
      "rank": 240,
      "record": "15-14"
    },
    "Denver": {
      "adjDef": 100.5,
      "adjDefRank": 128,
      "adjEM": 10.41,
      "adjOff": 110.9,
      "adjOffRank": 49,
      "adjTempo": 60.2,
      "adjTempoRank": 328,
      "conference": "SB",
      "rank": 73,
      "record": "22-9"
    },
    "Detroit": {
      "adjDef": 102.6,
      "adjDefRank": 164,
      "adjEM": 5.48,
      "adjOff": 108.1,
      "adjOffRank": 82,
      "adjTempo": 67.1,
      "adjTempoRank": 76,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 115,
      "record": "22-14",
      "seed": 15
    },
    "Drake": {
      "adjDef": 98.5,
      "adjDefRank": 87,
      "adjEM": 2.63,
      "adjOff": 101.1,
      "adjOffRank": 205,
      "adjTempo": 65.8,
      "adjTempoRank": 132,
      "conference": "MVC",
      "rank": 141,
      "record": "18-16"
    },
    "Drexel": {
      "adjDef": 96.4,
      "adjDefRank": 54,
      "adjEM": 13.53,
      "adjOff": 109.9,
      "adjOffRank": 58,
      "adjTempo": 60.4,
      "adjTempoRank": 326,
      "conference": "CAA",
      "rank": 45,
      "record": "29-7"
    },
    "Duke": {
      "adjDef": 98,
      "adjDefRank": 79,
      "adjEM": 19.7,
      "adjOff": 117.7,
      "adjOffRank": 8,
      "adjTempo": 66.4,
      "adjTempoRank": 108,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 19,
      "record": "27-7",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 102.9,
      "adjDefRank": 176,
      "adjEM": 4.29,
      "adjOff": 107.2,
      "adjOffRank": 93,
      "adjTempo": 67.1,
      "adjTempoRank": 77,
      "conference": "A10",
      "rank": 124,
      "record": "16-15"
    },
    "East Carolina": {
      "adjDef": 101.4,
      "adjDefRank": 140,
      "adjEM": 3.23,
      "adjOff": 104.6,
      "adjOffRank": 141,
      "adjTempo": 65.1,
      "adjTempoRank": 167,
      "conference": "CUSA",
      "rank": 135,
      "record": "15-16"
    },
    "East Tennessee St.": {
      "adjDef": 99.6,
      "adjDefRank": 110,
      "adjEM": 2.21,
      "adjOff": 101.8,
      "adjOffRank": 189,
      "adjTempo": 64.1,
      "adjTempoRank": 220,
      "conference": "ASun",
      "rank": 146,
      "record": "17-14"
    },
    "Eastern Illinois": {
      "adjDef": 110.6,
      "adjDefRank": 308,
      "adjEM": -13.07,
      "adjOff": 97.6,
      "adjOffRank": 270,
      "adjTempo": 63.1,
      "adjTempoRank": 263,
      "conference": "OVC",
      "rank": 298,
      "record": "12-17"
    },
    "Eastern Kentucky": {
      "adjDef": 109.7,
      "adjDefRank": 292,
      "adjEM": -8.94,
      "adjOff": 100.7,
      "adjOffRank": 216,
      "adjTempo": 61.4,
      "adjTempoRank": 316,
      "conference": "OVC",
      "rank": 265,
      "record": "16-16"
    },
    "Eastern Michigan": {
      "adjDef": 99.4,
      "adjDefRank": 99,
      "adjEM": -8.83,
      "adjOff": 90.5,
      "adjOffRank": 326,
      "adjTempo": 59.2,
      "adjTempoRank": 340,
      "conference": "MAC",
      "rank": 263,
      "record": "14-18"
    },
    "Eastern Washington": {
      "adjDef": 104.9,
      "adjDefRank": 218,
      "adjEM": -3.49,
      "adjOff": 101.4,
      "adjOffRank": 197,
      "adjTempo": 67.6,
      "adjTempoRank": 55,
      "conference": "BSky",
      "rank": 208,
      "record": "15-17"
    },
    "Elon": {
      "adjDef": 106.1,
      "adjDefRank": 241,
      "adjEM": -8.22,
      "adjOff": 97.9,
      "adjOffRank": 262,
      "adjTempo": 68.3,
      "adjTempoRank": 37,
      "conference": "SC",
      "rank": 255,
      "record": "15-16"
    },
    "Evansville": {
      "adjDef": 105.6,
      "adjDefRank": 230,
      "adjEM": 5.17,
      "adjOff": 110.7,
      "adjOffRank": 52,
      "adjTempo": 66.4,
      "adjTempoRank": 105,
      "conference": "MVC",
      "rank": 119,
      "record": "16-16"
    },
    "FIU": {
      "adjDef": 104.9,
      "adjDefRank": 217,
      "adjEM": -5.45,
      "adjOff": 99.4,
      "adjOffRank": 239,
      "adjTempo": 63.7,
      "adjTempoRank": 246,
      "conference": "SB",
      "rank": 228,
      "record": "8-21"
    },
    "Fairfield": {
      "adjDef": 95,
      "adjDefRank": 39,
      "adjEM": 5.66,
      "adjOff": 100.6,
      "adjOffRank": 218,
      "adjTempo": 64,
      "adjTempoRank": 225,
      "conference": "MAAC",
      "rank": 113,
      "record": "22-15"
    },
    "Fairleigh Dickinson": {
      "adjDef": 109,
      "adjDefRank": 284,
      "adjEM": -21.19,
      "adjOff": 87.8,
      "adjOffRank": 339,
      "adjTempo": 66.5,
      "adjTempoRank": 99,
      "conference": "NEC",
      "rank": 335,
      "record": "3-26"
    },
    "Florida": {
      "adjDef": 98,
      "adjDefRank": 80,
      "adjEM": 22.47,
      "adjOff": 120.4,
      "adjOffRank": 3,
      "adjTempo": 64.2,
      "adjTempoRank": 210,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 10,
      "record": "26-11",
      "seed": 7
    },
    "Florida A&M": {
      "adjDef": 110.6,
      "adjDefRank": 307,
      "adjEM": -17.29,
      "adjOff": 93.4,
      "adjOffRank": 309,
      "adjTempo": 67.8,
      "adjTempoRank": 50,
      "conference": "MEAC",
      "rank": 317,
      "record": "10-23"
    },
    "Florida Atlantic": {
      "adjDef": 104.4,
      "adjDefRank": 206,
      "adjEM": -2.99,
      "adjOff": 101.5,
      "adjOffRank": 193,
      "adjTempo": 63.4,
      "adjTempoRank": 255,
      "conference": "SB",
      "rank": 201,
      "record": "11-19"
    },
    "Florida Gulf Coast": {
      "adjDef": 106.1,
      "adjDefRank": 244,
      "adjEM": -0.88,
      "adjOff": 105.2,
      "adjOffRank": 132,
      "adjTempo": 66.5,
      "adjTempoRank": 100,
      "conference": "ASun",
      "rank": 176,
      "record": "15-17"
    },
    "Florida St.": {
      "adjDef": 90.4,
      "adjDefRank": 10,
      "adjEM": 17.14,
      "adjOff": 107.6,
      "adjOffRank": 89,
      "adjTempo": 66.6,
      "adjTempoRank": 95,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 24,
      "record": "25-10",
      "seed": 3
    },
    "Fordham": {
      "adjDef": 104,
      "adjDefRank": 196,
      "adjEM": -9.21,
      "adjOff": 94.8,
      "adjOffRank": 298,
      "adjTempo": 66.4,
      "adjTempoRank": 101,
      "conference": "A10",
      "rank": 270,
      "record": "10-19"
    },
    "Fresno St.": {
      "adjDef": 102.8,
      "adjDefRank": 173,
      "adjEM": -1.41,
      "adjOff": 101.4,
      "adjOffRank": 195,
      "adjTempo": 61.7,
      "adjTempoRank": 307,
      "conference": "WAC",
      "rank": 179,
      "record": "13-20"
    },
    "Furman": {
      "adjDef": 105,
      "adjDefRank": 221,
      "adjEM": -6.85,
      "adjOff": 98.1,
      "adjOffRank": 256,
      "adjTempo": 63.9,
      "adjTempoRank": 229,
      "conference": "SC",
      "rank": 247,
      "record": "15-16"
    },
    "Gardner Webb": {
      "adjDef": 104.6,
      "adjDefRank": 208,
      "adjEM": -9.2,
      "adjOff": 95.3,
      "adjOffRank": 290,
      "adjTempo": 63.9,
      "adjTempoRank": 232,
      "conference": "BSth",
      "rank": 269,
      "record": "12-20"
    },
    "George Mason": {
      "adjDef": 98.1,
      "adjDefRank": 82,
      "adjEM": 6.08,
      "adjOff": 104.2,
      "adjOffRank": 146,
      "adjTempo": 67.1,
      "adjTempoRank": 82,
      "conference": "CAA",
      "rank": 110,
      "record": "24-9"
    },
    "George Washington": {
      "adjDef": 102.5,
      "adjDefRank": 161,
      "adjEM": -0.68,
      "adjOff": 101.8,
      "adjOffRank": 190,
      "adjTempo": 62.6,
      "adjTempoRank": 280,
      "conference": "A10",
      "rank": 171,
      "record": "10-21"
    },
    "Georgetown": {
      "adjDef": 90.1,
      "adjDefRank": 9,
      "adjEM": 21.05,
      "adjOff": 111.2,
      "adjOffRank": 45,
      "adjTempo": 62.1,
      "adjTempoRank": 294,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 15,
      "record": "24-9",
      "seed": 3
    },
    "Georgia": {
      "adjDef": 98.7,
      "adjDefRank": 89,
      "adjEM": 5.97,
      "adjOff": 104.7,
      "adjOffRank": 139,
      "adjTempo": 61.5,
      "adjTempoRank": 313,
      "conference": "SEC",
      "rank": 112,
      "record": "15-17"
    },
    "Georgia Southern": {
      "adjDef": 104.6,
      "adjDefRank": 209,
      "adjEM": -6.82,
      "adjOff": 97.8,
      "adjOffRank": 266,
      "adjTempo": 65.2,
      "adjTempoRank": 163,
      "conference": "SC",
      "rank": 246,
      "record": "15-15"
    },
    "Georgia St.": {
      "adjDef": 93.5,
      "adjDefRank": 24,
      "adjEM": 8.57,
      "adjOff": 102.1,
      "adjOffRank": 180,
      "adjTempo": 63.9,
      "adjTempoRank": 231,
      "conference": "CAA",
      "rank": 84,
      "record": "22-12"
    },
    "Georgia Tech": {
      "adjDef": 99.3,
      "adjDefRank": 98,
      "adjEM": -0.86,
      "adjOff": 98.5,
      "adjOffRank": 250,
      "adjTempo": 62.2,
      "adjTempoRank": 293,
      "conference": "ACC",
      "rank": 174,
      "record": "11-20"
    },
    "Gonzaga": {
      "adjDef": 94.7,
      "adjDefRank": 33,
      "adjEM": 18.39,
      "adjOff": 113.1,
      "adjOffRank": 30,
      "adjTempo": 64.8,
      "adjTempoRank": 183,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 21,
      "record": "26-7",
      "seed": 7
    },
    "Grambling St.": {
      "adjDef": 115.5,
      "adjDefRank": 338,
      "adjEM": -30.22,
      "adjOff": 85.3,
      "adjOffRank": 345,
      "adjTempo": 64.3,
      "adjTempoRank": 208,
      "conference": "SWAC",
      "rank": 345,
      "record": "4-24"
    },
    "Green Bay": {
      "adjDef": 102.2,
      "adjDefRank": 154,
      "adjEM": -0.88,
      "adjOff": 101.3,
      "adjOffRank": 198,
      "adjTempo": 66.1,
      "adjTempoRank": 118,
      "conference": "Horz",
      "rank": 175,
      "record": "15-15"
    },
    "Hampton": {
      "adjDef": 102.6,
      "adjDefRank": 163,
      "adjEM": -10.53,
      "adjOff": 92.1,
      "adjOffRank": 317,
      "adjTempo": 67.1,
      "adjTempoRank": 81,
      "conference": "MEAC",
      "rank": 286,
      "record": "12-21"
    },
    "Hartford": {
      "adjDef": 106,
      "adjDefRank": 239,
      "adjEM": -14.72,
      "adjOff": 91.3,
      "adjOffRank": 320,
      "adjTempo": 64.2,
      "adjTempoRank": 216,
      "conference": "AE",
      "rank": 305,
      "record": "9-22"
    },
    "Harvard": {
      "adjDef": 94.4,
      "adjDefRank": 29,
      "adjEM": 14.34,
      "adjOff": 108.8,
      "adjOffRank": 72,
      "adjTempo": 60.7,
      "adjTempoRank": 325,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 41,
      "record": "26-5",
      "seed": 12
    },
    "Hawaii": {
      "adjDef": 107.3,
      "adjDefRank": 266,
      "adjEM": -4.03,
      "adjOff": 103.3,
      "adjOffRank": 164,
      "adjTempo": 69.6,
      "adjTempoRank": 20,
      "conference": "WAC",
      "rank": 213,
      "record": "16-16"
    },
    "High Point": {
      "adjDef": 111.6,
      "adjDefRank": 321,
      "adjEM": -6.17,
      "adjOff": 105.5,
      "adjOffRank": 126,
      "adjTempo": 63.7,
      "adjTempoRank": 244,
      "conference": "BSth",
      "rank": 234,
      "record": "13-18"
    },
    "Hofstra": {
      "adjDef": 103.5,
      "adjDefRank": 186,
      "adjEM": -4.48,
      "adjOff": 99,
      "adjOffRank": 245,
      "adjTempo": 65.4,
      "adjTempoRank": 149,
      "conference": "CAA",
      "rank": 219,
      "record": "10-22"
    },
    "Holy Cross": {
      "adjDef": 104,
      "adjDefRank": 198,
      "adjEM": -5,
      "adjOff": 99,
      "adjOffRank": 244,
      "adjTempo": 65.3,
      "adjTempoRank": 157,
      "conference": "Pat",
      "rank": 224,
      "record": "15-14"
    },
    "Houston": {
      "adjDef": 107.9,
      "adjDefRank": 273,
      "adjEM": -2.31,
      "adjOff": 105.6,
      "adjOffRank": 121,
      "adjTempo": 66.1,
      "adjTempoRank": 117,
      "conference": "CUSA",
      "rank": 190,
      "record": "15-15"
    },
    "Houston Baptist": {
      "adjDef": 110.7,
      "adjDefRank": 310,
      "adjEM": -17.48,
      "adjOff": 93.2,
      "adjOffRank": 311,
      "adjTempo": 70.2,
      "adjTempoRank": 14,
      "conference": "GWC",
      "rank": 319,
      "record": "10-20"
    },
    "Howard": {
      "adjDef": 106.1,
      "adjDefRank": 243,
      "adjEM": -15.16,
      "adjOff": 91,
      "adjOffRank": 323,
      "adjTempo": 65.4,
      "adjTempoRank": 151,
      "conference": "MEAC",
      "rank": 308,
      "record": "10-21"
    },
    "IPFW": {
      "adjDef": 107.8,
      "adjDefRank": 270,
      "adjEM": -9.52,
      "adjOff": 98.3,
      "adjOffRank": 252,
      "adjTempo": 68.4,
      "adjTempoRank": 34,
      "conference": "Sum",
      "rank": 272,
      "record": "11-19"
    },
    "IUPUI": {
      "adjDef": 110.9,
      "adjDefRank": 313,
      "adjEM": -3.04,
      "adjOff": 107.9,
      "adjOffRank": 85,
      "adjTempo": 65.3,
      "adjTempoRank": 153,
      "conference": "Sum",
      "rank": 202,
      "record": "14-18"
    },
    "Idaho": {
      "adjDef": 105.3,
      "adjDefRank": 226,
      "adjEM": 1.29,
      "adjOff": 106.6,
      "adjOffRank": 103,
      "adjTempo": 62.5,
      "adjTempoRank": 282,
      "conference": "WAC",
      "rank": 156,
      "record": "19-14"
    },
    "Idaho St.": {
      "adjDef": 111,
      "adjDefRank": 315,
      "adjEM": -13.93,
      "adjOff": 97.1,
      "adjOffRank": 279,
      "adjTempo": 64.9,
      "adjTempoRank": 177,
      "conference": "BSky",
      "rank": 304,
      "record": "9-21"
    },
    "Illinois": {
      "adjDef": 94.8,
      "adjDefRank": 36,
      "adjEM": 9.49,
      "adjOff": 104.3,
      "adjOffRank": 145,
      "adjTempo": 64.2,
      "adjTempoRank": 217,
      "conference": "B10",
      "rank": 76,
      "record": "17-15"
    },
    "Illinois Chicago": {
      "adjDef": 108.3,
      "adjDefRank": 279,
      "adjEM": -10.42,
      "adjOff": 97.9,
      "adjOffRank": 263,
      "adjTempo": 64.3,
      "adjTempoRank": 206,
      "conference": "Horz",
      "rank": 282,
      "record": "8-22"
    },
    "Illinois St.": {
      "adjDef": 101.5,
      "adjDefRank": 142,
      "adjEM": 7.79,
      "adjOff": 109.3,
      "adjOffRank": 66,
      "adjTempo": 63.4,
      "adjTempoRank": 254,
      "conference": "MVC",
      "rank": 93,
      "record": "21-14"
    },
    "Indiana": {
      "adjDef": 97.6,
      "adjDefRank": 72,
      "adjEM": 22.55,
      "adjOff": 120.2,
      "adjOffRank": 4,
      "adjTempo": 66.7,
      "adjTempoRank": 92,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 9,
      "record": "27-9",
      "seed": 4
    },
    "Indiana St.": {
      "adjDef": 98.9,
      "adjDefRank": 94,
      "adjEM": 3.09,
      "adjOff": 102,
      "adjOffRank": 184,
      "adjTempo": 64,
      "adjTempoRank": 227,
      "conference": "MVC",
      "rank": 137,
      "record": "18-15"
    },
    "Iona": {
      "adjDef": 103.2,
      "adjDefRank": 181,
      "adjEM": 12.26,
      "adjOff": 115.5,
      "adjOffRank": 15,
      "adjTempo": 70,
      "adjTempoRank": 17,
      "conference": "MAAC",
      "finish": "First Four",
      "rank": 54,
      "record": "25-8",
      "seed": 14
    },
    "Iowa": {
      "adjDef": 104.4,
      "adjDefRank": 205,
      "adjEM": 7.15,
      "adjOff": 111.6,
      "adjOffRank": 39,
      "adjTempo": 67.3,
      "adjTempoRank": 70,
      "conference": "B10",
      "rank": 101,
      "record": "18-17"
    },
    "Iowa St.": {
      "adjDef": 97.2,
      "adjDefRank": 66,
      "adjEM": 16.69,
      "adjOff": 113.9,
      "adjOffRank": 24,
      "adjTempo": 65.8,
      "adjTempoRank": 128,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 26,
      "record": "23-11",
      "seed": 8
    },
    "Jackson St.": {
      "adjDef": 108.9,
      "adjDefRank": 283,
      "adjEM": -20.45,
      "adjOff": 88.5,
      "adjOffRank": 336,
      "adjTempo": 60.7,
      "adjTempoRank": 324,
      "conference": "SWAC",
      "rank": 332,
      "record": "7-24"
    },
    "Jacksonville": {
      "adjDef": 106.1,
      "adjDefRank": 246,
      "adjEM": -6.68,
      "adjOff": 99.5,
      "adjOffRank": 237,
      "adjTempo": 65.4,
      "adjTempoRank": 145,
      "conference": "ASun",
      "rank": 242,
      "record": "8-22"
    },
    "Jacksonville St.": {
      "adjDef": 101.8,
      "adjDefRank": 150,
      "adjEM": -3.24,
      "adjOff": 98.6,
      "adjOffRank": 248,
      "adjTempo": 63.3,
      "adjTempoRank": 260,
      "conference": "OVC",
      "rank": 203,
      "record": "15-18"
    },
    "James Madison": {
      "adjDef": 108,
      "adjDefRank": 274,
      "adjEM": -7.01,
      "adjOff": 101,
      "adjOffRank": 209,
      "adjTempo": 65.5,
      "adjTempoRank": 144,
      "conference": "CAA",
      "rank": 248,
      "record": "12-20"
    },
    "Kansas": {
      "adjDef": 87.1,
      "adjDefRank": 3,
      "adjEM": 26.91,
      "adjOff": 114,
      "adjOffRank": 23,
      "adjTempo": 66.3,
      "adjTempoRank": 111,
      "conference": "B12",
      "finish": "Runner-up",
      "rank": 4,
      "record": "32-7",
      "seed": 2
    },
    "Kansas St.": {
      "adjDef": 92.3,
      "adjDefRank": 18,
      "adjEM": 17.52,
      "adjOff": 109.8,
      "adjOffRank": 59,
      "adjTempo": 66.5,
      "adjTempoRank": 97,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 23,
      "record": "22-11",
      "seed": 8
    },
    "Kennesaw St.": {
      "adjDef": 114.8,
      "adjDefRank": 336,
      "adjEM": -17.4,
      "adjOff": 97.4,
      "adjOffRank": 273,
      "adjTempo": 62,
      "adjTempoRank": 299,
      "conference": "ASun",
      "rank": 318,
      "record": "3-28"
    },
    "Kent St.": {
      "adjDef": 100.2,
      "adjDefRank": 122,
      "adjEM": 5.29,
      "adjOff": 105.5,
      "adjOffRank": 122,
      "adjTempo": 67.1,
      "adjTempoRank": 75,
      "conference": "MAC",
      "rank": 117,
      "record": "21-12"
    },
    "Kentucky": {
      "adjDef": 89.6,
      "adjDefRank": 7,
      "adjEM": 32.59,
      "adjOff": 122.2,
      "adjOffRank": 2,
      "adjTempo": 65.4,
      "adjTempoRank": 150,
      "conference": "SEC",
      "finish": "Champion",
      "rank": 1,
      "record": "38-2",
      "seed": 1
    },
    "LIU Brooklyn": {
      "adjDef": 107.9,
      "adjDefRank": 272,
      "adjEM": 0.75,
      "adjOff": 108.7,
      "adjOffRank": 75,
      "adjTempo": 73.4,
      "adjTempoRank": 2,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 162,
      "record": "25-9",
      "seed": 16
    },
    "LSU": {
      "adjDef": 94.5,
      "adjDefRank": 30,
      "adjEM": 7.34,
      "adjOff": 101.8,
      "adjOffRank": 191,
      "adjTempo": 65.1,
      "adjTempoRank": 168,
      "conference": "SEC",
      "rank": 98,
      "record": "18-15"
    },
    "La Salle": {
      "adjDef": 95.8,
      "adjDefRank": 43,
      "adjEM": 11.5,
      "adjOff": 107.3,
      "adjOffRank": 92,
      "adjTempo": 67.7,
      "adjTempoRank": 54,
      "conference": "A10",
      "rank": 62,
      "record": "21-13"
    },
    "Lafayette": {
      "adjDef": 112.5,
      "adjDefRank": 324,
      "adjEM": -6.34,
      "adjOff": 106.2,
      "adjOffRank": 113,
      "adjTempo": 65.4,
      "adjTempoRank": 146,
      "conference": "Pat",
      "rank": 239,
      "record": "13-18"
    },
    "Lamar": {
      "adjDef": 101.3,
      "adjDefRank": 138,
      "adjEM": 6.33,
      "adjOff": 107.6,
      "adjOffRank": 88,
      "adjTempo": 66,
      "adjTempoRank": 123,
      "conference": "Slnd",
      "finish": "First Four",
      "rank": 108,
      "record": "23-12",
      "seed": 16
    },
    "Lehigh": {
      "adjDef": 99.3,
      "adjDefRank": 96,
      "adjEM": 9.29,
      "adjOff": 108.6,
      "adjOffRank": 76,
      "adjTempo": 67.6,
      "adjTempoRank": 56,
      "conference": "Pat",
      "finish": "Round of 32",
      "rank": 79,
      "record": "27-8",
      "seed": 15
    },
    "Liberty": {
      "adjDef": 111.3,
      "adjDefRank": 316,
      "adjEM": -10.8,
      "adjOff": 100.5,
      "adjOffRank": 222,
      "adjTempo": 64.3,
      "adjTempoRank": 207,
      "conference": "BSth",
      "rank": 289,
      "record": "14-18"
    },
    "Lipscomb": {
      "adjDef": 104.9,
      "adjDefRank": 220,
      "adjEM": -3.89,
      "adjOff": 101,
      "adjOffRank": 208,
      "adjTempo": 68.7,
      "adjTempoRank": 30,
      "conference": "ASun",
      "rank": 212,
      "record": "13-18"
    },
    "Long Beach St.": {
      "adjDef": 96.4,
      "adjDefRank": 53,
      "adjEM": 15.27,
      "adjOff": 111.7,
      "adjOffRank": 38,
      "adjTempo": 66.4,
      "adjTempoRank": 109,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 36,
      "record": "25-9",
      "seed": 12
    },
    "Longwood": {
      "adjDef": 122.6,
      "adjDefRank": 345,
      "adjEM": -22.94,
      "adjOff": 99.7,
      "adjOffRank": 234,
      "adjTempo": 69.1,
      "adjTempoRank": 26,
      "conference": "ind",
      "rank": 341,
      "record": "10-21"
    },
    "Louisiana Lafayette": {
      "adjDef": 97.8,
      "adjDefRank": 75,
      "adjEM": -3.27,
      "adjOff": 94.5,
      "adjOffRank": 302,
      "adjTempo": 68.7,
      "adjTempoRank": 32,
      "conference": "SB",
      "rank": 204,
      "record": "16-16"
    },
    "Louisiana Monroe": {
      "adjDef": 112.9,
      "adjDefRank": 329,
      "adjEM": -15.97,
      "adjOff": 96.9,
      "adjOffRank": 283,
      "adjTempo": 63.5,
      "adjTempoRank": 253,
      "conference": "SB",
      "rank": 311,
      "record": "3-26"
    },
    "Louisiana Tech": {
      "adjDef": 103.7,
      "adjDefRank": 190,
      "adjEM": -2.62,
      "adjOff": 101.1,
      "adjOffRank": 204,
      "adjTempo": 67.2,
      "adjTempoRank": 71,
      "conference": "WAC",
      "rank": 192,
      "record": "18-16"
    },
    "Louisville": {
      "adjDef": 85.5,
      "adjDefRank": 1,
      "adjEM": 20.67,
      "adjOff": 106.2,
      "adjOffRank": 112,
      "adjTempo": 66.1,
      "adjTempoRank": 115,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 17,
      "record": "30-10",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 106.1,
      "adjDefRank": 242,
      "adjEM": -10.75,
      "adjOff": 95.3,
      "adjOffRank": 291,
      "adjTempo": 59.5,
      "adjTempoRank": 336,
      "conference": "Horz",
      "rank": 288,
      "record": "7-23"
    },
    "Loyola MD": {
      "adjDef": 100,
      "adjDefRank": 116,
      "adjEM": 3.91,
      "adjOff": 103.9,
      "adjOffRank": 150,
      "adjTempo": 63.6,
      "adjTempoRank": 251,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 129,
      "record": "24-9",
      "seed": 15
    },
    "Loyola Marymount": {
      "adjDef": 99.4,
      "adjDefRank": 104,
      "adjEM": 2.73,
      "adjOff": 102.1,
      "adjOffRank": 178,
      "adjTempo": 66.1,
      "adjTempoRank": 116,
      "conference": "WCC",
      "rank": 140,
      "record": "21-13"
    },
    "Maine": {
      "adjDef": 106.2,
      "adjDefRank": 248,
      "adjEM": -9.32,
      "adjOff": 96.9,
      "adjOffRank": 284,
      "adjTempo": 66.9,
      "adjTempoRank": 89,
      "conference": "AE",
      "rank": 271,
      "record": "12-17"
    },
    "Manhattan": {
      "adjDef": 99.4,
      "adjDefRank": 105,
      "adjEM": 3.91,
      "adjOff": 103.3,
      "adjOffRank": 163,
      "adjTempo": 67,
      "adjTempoRank": 85,
      "conference": "MAAC",
      "rank": 128,
      "record": "21-13"
    },
    "Marist": {
      "adjDef": 106.8,
      "adjDefRank": 259,
      "adjEM": -8.05,
      "adjOff": 98.7,
      "adjOffRank": 246,
      "adjTempo": 67.5,
      "adjTempoRank": 59,
      "conference": "MAAC",
      "rank": 254,
      "record": "14-18"
    },
    "Marquette": {
      "adjDef": 91.3,
      "adjDefRank": 14,
      "adjEM": 20.22,
      "adjOff": 111.5,
      "adjOffRank": 40,
      "adjTempo": 70,
      "adjTempoRank": 15,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "27-8",
      "seed": 3
    },
    "Marshall": {
      "adjDef": 100.1,
      "adjDefRank": 120,
      "adjEM": 9.32,
      "adjOff": 109.4,
      "adjOffRank": 63,
      "adjTempo": 65.2,
      "adjTempoRank": 164,
      "conference": "CUSA",
      "rank": 78,
      "record": "21-14"
    },
    "Maryland": {
      "adjDef": 103.4,
      "adjDefRank": 183,
      "adjEM": 3.37,
      "adjOff": 106.7,
      "adjOffRank": 101,
      "adjTempo": 66.2,
      "adjTempoRank": 114,
      "conference": "ACC",
      "rank": 134,
      "record": "17-15"
    },
    "Maryland Eastern Shore": {
      "adjDef": 105.9,
      "adjDefRank": 236,
      "adjEM": -18.74,
      "adjOff": 87.1,
      "adjOffRank": 341,
      "adjTempo": 66.9,
      "adjTempoRank": 87,
      "conference": "MEAC",
      "rank": 327,
      "record": "7-23"
    },
    "Massachusetts": {
      "adjDef": 96.3,
      "adjDefRank": 51,
      "adjEM": 9.83,
      "adjOff": 106.2,
      "adjOffRank": 114,
      "adjTempo": 73.2,
      "adjTempoRank": 3,
      "conference": "A10",
      "rank": 74,
      "record": "25-12"
    },
    "McNeese St.": {
      "adjDef": 105.7,
      "adjDefRank": 233,
      "adjEM": -4.75,
      "adjOff": 100.9,
      "adjOffRank": 210,
      "adjTempo": 64.2,
      "adjTempoRank": 212,
      "conference": "Slnd",
      "rank": 221,
      "record": "17-16"
    },
    "Memphis": {
      "adjDef": 92.3,
      "adjDefRank": 17,
      "adjEM": 21.9,
      "adjOff": 114.2,
      "adjOffRank": 22,
      "adjTempo": 67.5,
      "adjTempoRank": 60,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 12,
      "record": "26-9",
      "seed": 8
    },
    "Mercer": {
      "adjDef": 97.4,
      "adjDefRank": 69,
      "adjEM": 7.71,
      "adjOff": 105.1,
      "adjOffRank": 134,
      "adjTempo": 64.2,
      "adjTempoRank": 214,
      "conference": "ASun",
      "rank": 96,
      "record": "27-11"
    },
    "Miami FL": {
      "adjDef": 97.9,
      "adjDefRank": 76,
      "adjEM": 13.18,
      "adjOff": 111,
      "adjOffRank": 47,
      "adjTempo": 63.6,
      "adjTempoRank": 249,
      "conference": "ACC",
      "rank": 47,
      "record": "20-13"
    },
    "Miami OH": {
      "adjDef": 105.1,
      "adjDefRank": 223,
      "adjEM": -2.61,
      "adjOff": 102.4,
      "adjOffRank": 175,
      "adjTempo": 59.5,
      "adjTempoRank": 335,
      "conference": "MAC",
      "rank": 191,
      "record": "9-21"
    },
    "Michigan": {
      "adjDef": 96.8,
      "adjDefRank": 56,
      "adjEM": 17.52,
      "adjOff": 114.3,
      "adjOffRank": 19,
      "adjTempo": 60.1,
      "adjTempoRank": 330,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 22,
      "record": "24-10",
      "seed": 4
    },
    "Michigan St.": {
      "adjDef": 86.9,
      "adjDefRank": 2,
      "adjEM": 27.87,
      "adjOff": 114.7,
      "adjOffRank": 17,
      "adjTempo": 64.3,
      "adjTempoRank": 209,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 3,
      "record": "29-8",
      "seed": 1
    },
    "Middle Tennessee": {
      "adjDef": 95.6,
      "adjDefRank": 41,
      "adjEM": 12.92,
      "adjOff": 108.5,
      "adjOffRank": 78,
      "adjTempo": 65.4,
      "adjTempoRank": 147,
      "conference": "SB",
      "rank": 49,
      "record": "27-7"
    },
    "Milwaukee": {
      "adjDef": 98.3,
      "adjDefRank": 84,
      "adjEM": 2.3,
      "adjOff": 100.6,
      "adjOffRank": 220,
      "adjTempo": 65.2,
      "adjTempoRank": 166,
      "conference": "Horz",
      "rank": 144,
      "record": "20-14"
    },
    "Minnesota": {
      "adjDef": 95.8,
      "adjDefRank": 42,
      "adjEM": 12.77,
      "adjOff": 108.5,
      "adjOffRank": 79,
      "adjTempo": 63.9,
      "adjTempoRank": 230,
      "conference": "B10",
      "rank": 52,
      "record": "23-15"
    },
    "Mississippi": {
      "adjDef": 96.9,
      "adjDefRank": 61,
      "adjEM": 7.75,
      "adjOff": 104.7,
      "adjOffRank": 140,
      "adjTempo": 65.4,
      "adjTempoRank": 148,
      "conference": "SEC",
      "rank": 95,
      "record": "20-14"
    },
    "Mississippi St.": {
      "adjDef": 102.4,
      "adjDefRank": 158,
      "adjEM": 9.35,
      "adjOff": 111.7,
      "adjOffRank": 37,
      "adjTempo": 63.9,
      "adjTempoRank": 233,
      "conference": "SEC",
      "rank": 77,
      "record": "21-12"
    },
    "Mississippi Valley St.": {
      "adjDef": 104.2,
      "adjDefRank": 200,
      "adjEM": -6.3,
      "adjOff": 98,
      "adjOffRank": 260,
      "adjTempo": 68.9,
      "adjTempoRank": 29,
      "conference": "SWAC",
      "finish": "First Four",
      "rank": 237,
      "record": "21-13",
      "seed": 16
    },
    "Missouri": {
      "adjDef": 99.8,
      "adjDefRank": 111,
      "adjEM": 25.28,
      "adjOff": 125.1,
      "adjOffRank": 1,
      "adjTempo": 65.4,
      "adjTempoRank": 152,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 7,
      "record": "30-5",
      "seed": 2
    },
    "Missouri St.": {
      "adjDef": 97.1,
      "adjDefRank": 65,
      "adjEM": 8.23,
      "adjOff": 105.3,
      "adjOffRank": 129,
      "adjTempo": 62.4,
      "adjTempoRank": 287,
      "conference": "MVC",
      "rank": 86,
      "record": "16-16"
    },
    "Monmouth": {
      "adjDef": 108.3,
      "adjDefRank": 278,
      "adjEM": -11.29,
      "adjOff": 97,
      "adjOffRank": 282,
      "adjTempo": 68,
      "adjTempoRank": 42,
      "conference": "NEC",
      "rank": 290,
      "record": "12-20"
    },
    "Montana": {
      "adjDef": 96.9,
      "adjDefRank": 63,
      "adjEM": 6.76,
      "adjOff": 103.7,
      "adjOffRank": 155,
      "adjTempo": 64.2,
      "adjTempoRank": 211,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 104,
      "record": "25-7",
      "seed": 13
    },
    "Montana St.": {
      "adjDef": 109.1,
      "adjDefRank": 286,
      "adjEM": -11.84,
      "adjOff": 97.3,
      "adjOffRank": 277,
      "adjTempo": 66.2,
      "adjTempoRank": 113,
      "conference": "BSky",
      "rank": 291,
      "record": "12-17"
    },
    "Morehead St.": {
      "adjDef": 103.2,
      "adjDefRank": 180,
      "adjEM": -2.75,
      "adjOff": 100.5,
      "adjOffRank": 224,
      "adjTempo": 59.8,
      "adjTempoRank": 333,
      "conference": "OVC",
      "rank": 198,
      "record": "18-15"
    },
    "Morgan St.": {
      "adjDef": 107.2,
      "adjDefRank": 264,
      "adjEM": -7.93,
      "adjOff": 99.3,
      "adjOffRank": 241,
      "adjTempo": 65.8,
      "adjTempoRank": 133,
      "conference": "MEAC",
      "rank": 252,
      "record": "9-20"
    },
    "Mount St. Mary's": {
      "adjDef": 105.6,
      "adjDefRank": 231,
      "adjEM": -12.21,
      "adjOff": 93.4,
      "adjOffRank": 308,
      "adjTempo": 62.6,
      "adjTempoRank": 279,
      "conference": "NEC",
      "rank": 294,
      "record": "8-21"
    },
    "Murray St.": {
      "adjDef": 93.2,
      "adjDefRank": 20,
      "adjEM": 15.34,
      "adjOff": 108.5,
      "adjOffRank": 80,
      "adjTempo": 65,
      "adjTempoRank": 172,
      "conference": "OVC",
      "finish": "Round of 32",
      "rank": 35,
      "record": "31-2",
      "seed": 6
    },
    "N.C. State": {
      "adjDef": 97.9,
      "adjDefRank": 78,
      "adjEM": 14.22,
      "adjOff": 112.1,
      "adjOffRank": 32,
      "adjTempo": 67,
      "adjTempoRank": 83,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 43,
      "record": "24-13",
      "seed": 11
    },
    "NJIT": {
      "adjDef": 109.7,
      "adjDefRank": 295,
      "adjEM": -13.11,
      "adjOff": 96.6,
      "adjOffRank": 285,
      "adjTempo": 64.1,
      "adjTempoRank": 219,
      "conference": "GWC",
      "rank": 299,
      "record": "15-17"
    },
    "Navy": {
      "adjDef": 110.6,
      "adjDefRank": 305,
      "adjEM": -19.15,
      "adjOff": 91.4,
      "adjOffRank": 318,
      "adjTempo": 59.5,
      "adjTempoRank": 334,
      "conference": "Pat",
      "rank": 329,
      "record": "3-26"
    },
    "Nebraska": {
      "adjDef": 101.7,
      "adjDefRank": 148,
      "adjEM": 1.62,
      "adjOff": 103.4,
      "adjOffRank": 162,
      "adjTempo": 61.5,
      "adjTempoRank": 314,
      "conference": "B10",
      "rank": 152,
      "record": "12-18"
    },
    "Nebraska Omaha": {
      "adjDef": 117.6,
      "adjDefRank": 343,
      "adjEM": -20.56,
      "adjOff": 97.1,
      "adjOffRank": 281,
      "adjTempo": 70.4,
      "adjTempoRank": 13,
      "conference": "ind",
      "rank": 333,
      "record": "11-18"
    },
    "Nevada": {
      "adjDef": 99.4,
      "adjDefRank": 100,
      "adjEM": 6.52,
      "adjOff": 105.9,
      "adjOffRank": 118,
      "adjTempo": 64.2,
      "adjTempoRank": 215,
      "conference": "WAC",
      "rank": 106,
      "record": "28-7"
    },
    "New Hampshire": {
      "adjDef": 102.8,
      "adjDefRank": 172,
      "adjEM": -12.1,
      "adjOff": 90.7,
      "adjOffRank": 324,
      "adjTempo": 64.5,
      "adjTempoRank": 198,
      "conference": "AE",
      "rank": 293,
      "record": "13-16"
    },
    "New Mexico": {
      "adjDef": 91.4,
      "adjDefRank": 15,
      "adjEM": 19.45,
      "adjOff": 110.8,
      "adjOffRank": 51,
      "adjTempo": 65.5,
      "adjTempoRank": 141,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 20,
      "record": "28-7",
      "seed": 5
    },
    "New Mexico St.": {
      "adjDef": 98.1,
      "adjDefRank": 81,
      "adjEM": 11.22,
      "adjOff": 109.3,
      "adjOffRank": 65,
      "adjTempo": 69.4,
      "adjTempoRank": 23,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 65,
      "record": "26-10",
      "seed": 13
    },
    "Niagara": {
      "adjDef": 109.9,
      "adjDefRank": 299,
      "adjEM": -6.79,
      "adjOff": 103.1,
      "adjOffRank": 165,
      "adjTempo": 67.3,
      "adjTempoRank": 67,
      "conference": "MAAC",
      "rank": 245,
      "record": "14-19"
    },
    "Nicholls St.": {
      "adjDef": 119.2,
      "adjDefRank": 344,
      "adjEM": -18.45,
      "adjOff": 100.8,
      "adjOffRank": 213,
      "adjTempo": 64.8,
      "adjTempoRank": 184,
      "conference": "Slnd",
      "rank": 325,
      "record": "10-20"
    },
    "Norfolk St.": {
      "adjDef": 102.7,
      "adjDefRank": 165,
      "adjEM": -1.82,
      "adjOff": 100.9,
      "adjOffRank": 211,
      "adjTempo": 66.6,
      "adjTempoRank": 93,
      "conference": "MEAC",
      "finish": "Round of 32",
      "rank": 183,
      "record": "26-10",
      "seed": 15
    },
    "North Carolina": {
      "adjDef": 90.7,
      "adjDefRank": 11,
      "adjEM": 25.44,
      "adjOff": 116.1,
      "adjOffRank": 12,
      "adjTempo": 70.9,
      "adjTempoRank": 10,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "32-6",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 107.2,
      "adjDefRank": 263,
      "adjEM": -13.18,
      "adjOff": 94,
      "adjOffRank": 305,
      "adjTempo": 68.1,
      "adjTempoRank": 41,
      "conference": "MEAC",
      "rank": 300,
      "record": "12-20"
    },
    "North Carolina Central": {
      "adjDef": 101.8,
      "adjDefRank": 149,
      "adjEM": -4.09,
      "adjOff": 97.7,
      "adjOffRank": 268,
      "adjTempo": 65.5,
      "adjTempoRank": 140,
      "conference": "MEAC",
      "rank": 215,
      "record": "17-15"
    },
    "North Dakota": {
      "adjDef": 104.3,
      "adjDefRank": 203,
      "adjEM": -8.45,
      "adjOff": 95.9,
      "adjOffRank": 286,
      "adjTempo": 66.6,
      "adjTempoRank": 94,
      "conference": "GWC",
      "rank": 259,
      "record": "17-15"
    },
    "North Dakota St.": {
      "adjDef": 105.4,
      "adjDefRank": 228,
      "adjEM": 0.69,
      "adjOff": 106,
      "adjOffRank": 116,
      "adjTempo": 66,
      "adjTempoRank": 124,
      "conference": "Sum",
      "rank": 163,
      "record": "17-14"
    },
    "North Florida": {
      "adjDef": 105.1,
      "adjDefRank": 224,
      "adjEM": -1.93,
      "adjOff": 103.1,
      "adjOffRank": 166,
      "adjTempo": 65.3,
      "adjTempoRank": 156,
      "conference": "ASun",
      "rank": 186,
      "record": "16-16"
    },
    "North Texas": {
      "adjDef": 100.3,
      "adjDefRank": 123,
      "adjEM": 0.41,
      "adjOff": 100.7,
      "adjOffRank": 215,
      "adjTempo": 67.8,
      "adjTempoRank": 51,
      "conference": "SB",
      "rank": 167,
      "record": "18-14"
    },
    "Northeastern": {
      "adjDef": 102.7,
      "adjDefRank": 167,
      "adjEM": -2.22,
      "adjOff": 100.5,
      "adjOffRank": 221,
      "adjTempo": 63.6,
      "adjTempoRank": 247,
      "conference": "CAA",
      "rank": 189,
      "record": "14-17"
    },
    "Northern Arizona": {
      "adjDef": 112.6,
      "adjDefRank": 327,
      "adjEM": -22.81,
      "adjOff": 89.8,
      "adjOffRank": 332,
      "adjTempo": 64.6,
      "adjTempoRank": 192,
      "conference": "BSky",
      "rank": 340,
      "record": "5-24"
    },
    "Northern Colorado": {
      "adjDef": 113.1,
      "adjDefRank": 330,
      "adjEM": -8.31,
      "adjOff": 104.8,
      "adjOffRank": 138,
      "adjTempo": 65.8,
      "adjTempoRank": 127,
      "conference": "BSky",
      "rank": 257,
      "record": "9-19"
    },
    "Northern Illinois": {
      "adjDef": 109.9,
      "adjDefRank": 298,
      "adjEM": -19.58,
      "adjOff": 90.3,
      "adjOffRank": 327,
      "adjTempo": 62.1,
      "adjTempoRank": 295,
      "conference": "MAC",
      "rank": 330,
      "record": "5-26"
    },
    "Northern Iowa": {
      "adjDef": 97.3,
      "adjDefRank": 68,
      "adjEM": 9.57,
      "adjOff": 106.9,
      "adjOffRank": 97,
      "adjTempo": 61.6,
      "adjTempoRank": 310,
      "conference": "MVC",
      "rank": 75,
      "record": "20-14"
    },
    "Northwestern": {
      "adjDef": 104.3,
      "adjDefRank": 202,
      "adjEM": 10.53,
      "adjOff": 114.8,
      "adjOffRank": 16,
      "adjTempo": 62.5,
      "adjTempoRank": 283,
      "conference": "B10",
      "rank": 72,
      "record": "19-14"
    },
    "Northwestern St.": {
      "adjDef": 102.7,
      "adjDefRank": 169,
      "adjEM": -5.39,
      "adjOff": 97.3,
      "adjOffRank": 275,
      "adjTempo": 69.3,
      "adjTempoRank": 24,
      "conference": "Slnd",
      "rank": 227,
      "record": "16-16"
    },
    "Notre Dame": {
      "adjDef": 96.7,
      "adjDefRank": 55,
      "adjEM": 14.22,
      "adjOff": 110.9,
      "adjOffRank": 50,
      "adjTempo": 60.2,
      "adjTempoRank": 329,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 42,
      "record": "22-12",
      "seed": 7
    },
    "Oakland": {
      "adjDef": 112.7,
      "adjDefRank": 328,
      "adjEM": 1.72,
      "adjOff": 114.4,
      "adjOffRank": 18,
      "adjTempo": 69.7,
      "adjTempoRank": 19,
      "conference": "Sum",
      "rank": 149,
      "record": "20-16"
    },
    "Ohio": {
      "adjDef": 94.7,
      "adjDefRank": 32,
      "adjEM": 11.88,
      "adjOff": 106.6,
      "adjOffRank": 102,
      "adjTempo": 66,
      "adjTempoRank": 121,
      "conference": "MAC",
      "finish": "Sweet 16",
      "rank": 57,
      "record": "29-8",
      "seed": 13
    },
    "Ohio St.": {
      "adjDef": 88.1,
      "adjDefRank": 4,
      "adjEM": 30.07,
      "adjOff": 118.1,
      "adjOffRank": 6,
      "adjTempo": 66.9,
      "adjTempoRank": 88,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 2,
      "record": "31-8",
      "seed": 2
    },
    "Oklahoma": {
      "adjDef": 98.8,
      "adjDefRank": 90,
      "adjEM": 6.64,
      "adjOff": 105.4,
      "adjOffRank": 128,
      "adjTempo": 66.6,
      "adjTempoRank": 96,
      "conference": "B12",
      "rank": 105,
      "record": "15-16"
    },
    "Oklahoma St.": {
      "adjDef": 99.5,
      "adjDefRank": 106,
      "adjEM": 7.62,
      "adjOff": 107.1,
      "adjOffRank": 94,
      "adjTempo": 65.5,
      "adjTempoRank": 143,
      "conference": "B12",
      "rank": 97,
      "record": "15-18"
    },
    "Old Dominion": {
      "adjDef": 95.9,
      "adjDefRank": 47,
      "adjEM": 6.08,
      "adjOff": 102,
      "adjOffRank": 183,
      "adjTempo": 64.5,
      "adjTempoRank": 197,
      "conference": "CAA",
      "rank": 111,
      "record": "22-14"
    },
    "Oral Roberts": {
      "adjDef": 103.7,
      "adjDefRank": 191,
      "adjEM": 8.04,
      "adjOff": 111.8,
      "adjOffRank": 36,
      "adjTempo": 63.2,
      "adjTempoRank": 261,
      "conference": "Sum",
      "rank": 91,
      "record": "27-7"
    },
    "Oregon": {
      "adjDef": 102.4,
      "adjDefRank": 159,
      "adjEM": 11.81,
      "adjOff": 114.2,
      "adjOffRank": 21,
      "adjTempo": 66.8,
      "adjTempoRank": 90,
      "conference": "P12",
      "rank": 59,
      "record": "24-10"
    },
    "Oregon St.": {
      "adjDef": 102.7,
      "adjDefRank": 170,
      "adjEM": 8.22,
      "adjOff": 111,
      "adjOffRank": 48,
      "adjTempo": 70,
      "adjTempoRank": 18,
      "conference": "P12",
      "rank": 87,
      "record": "21-15"
    },
    "Pacific": {
      "adjDef": 107.8,
      "adjDefRank": 271,
      "adjEM": -10.07,
      "adjOff": 97.8,
      "adjOffRank": 265,
      "adjTempo": 61.5,
      "adjTempoRank": 311,
      "conference": "BW",
      "rank": 278,
      "record": "11-19"
    },
    "Penn": {
      "adjDef": 101.1,
      "adjDefRank": 137,
      "adjEM": 2.88,
      "adjOff": 104,
      "adjOffRank": 148,
      "adjTempo": 62,
      "adjTempoRank": 298,
      "conference": "Ivy",
      "rank": 139,
      "record": "20-13"
    },
    "Penn St.": {
      "adjDef": 99.5,
      "adjDefRank": 107,
      "adjEM": 3.38,
      "adjOff": 102.9,
      "adjOffRank": 168,
      "adjTempo": 63.1,
      "adjTempoRank": 264,
      "conference": "B10",
      "rank": 133,
      "record": "12-20"
    },
    "Pepperdine": {
      "adjDef": 105,
      "adjDefRank": 222,
      "adjEM": -10.41,
      "adjOff": 94.6,
      "adjOffRank": 300,
      "adjTempo": 60.8,
      "adjTempoRank": 323,
      "conference": "WCC",
      "rank": 281,
      "record": "10-19"
    },
    "Pittsburgh": {
      "adjDef": 102,
      "adjDefRank": 151,
      "adjEM": 11.34,
      "adjOff": 113.4,
      "adjOffRank": 27,
      "adjTempo": 62.3,
      "adjTempoRank": 292,
      "conference": "BE",
      "rank": 64,
      "record": "22-17"
    },
    "Portland": {
      "adjDef": 109.5,
      "adjDefRank": 290,
      "adjEM": -10.21,
      "adjOff": 99.3,
      "adjOffRank": 240,
      "adjTempo": 66.4,
      "adjTempoRank": 107,
      "conference": "WCC",
      "rank": 279,
      "record": "7-24"
    },
    "Portland St.": {
      "adjDef": 111.4,
      "adjDefRank": 317,
      "adjEM": -3.36,
      "adjOff": 108,
      "adjOffRank": 83,
      "adjTempo": 66.1,
      "adjTempoRank": 119,
      "conference": "BSky",
      "rank": 205,
      "record": "17-15"
    },
    "Prairie View A&M": {
      "adjDef": 103.5,
      "adjDefRank": 187,
      "adjEM": -18.01,
      "adjOff": 85.5,
      "adjOffRank": 344,
      "adjTempo": 70,
      "adjTempoRank": 16,
      "conference": "SWAC",
      "rank": 322,
      "record": "14-18"
    },
    "Presbyterian": {
      "adjDef": 110.4,
      "adjDefRank": 302,
      "adjEM": -7.61,
      "adjOff": 102.8,
      "adjOffRank": 170,
      "adjTempo": 59.4,
      "adjTempoRank": 337,
      "conference": "BSth",
      "rank": 251,
      "record": "14-15"
    },
    "Princeton": {
      "adjDef": 100.5,
      "adjDefRank": 129,
      "adjEM": 7.26,
      "adjOff": 107.8,
      "adjOffRank": 86,
      "adjTempo": 62.5,
      "adjTempoRank": 284,
      "conference": "Ivy",
      "rank": 100,
      "record": "20-12"
    },
    "Providence": {
      "adjDef": 106,
      "adjDefRank": 238,
      "adjEM": 4.09,
      "adjOff": 110.1,
      "adjOffRank": 57,
      "adjTempo": 65.2,
      "adjTempoRank": 161,
      "conference": "BE",
      "rank": 126,
      "record": "15-17"
    },
    "Purdue": {
      "adjDef": 99.9,
      "adjDefRank": 114,
      "adjEM": 16.25,
      "adjOff": 116.2,
      "adjOffRank": 10,
      "adjTempo": 64.3,
      "adjTempoRank": 204,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 30,
      "record": "22-13",
      "seed": 10
    },
    "Quinnipiac": {
      "adjDef": 102.5,
      "adjDefRank": 162,
      "adjEM": 1.44,
      "adjOff": 104,
      "adjOffRank": 149,
      "adjTempo": 65.6,
      "adjTempoRank": 137,
      "conference": "NEC",
      "rank": 153,
      "record": "18-14"
    },
    "Radford": {
      "adjDef": 106.8,
      "adjDefRank": 258,
      "adjEM": -16.7,
      "adjOff": 90.1,
      "adjOffRank": 328,
      "adjTempo": 64.1,
      "adjTempoRank": 221,
      "conference": "BSth",
      "rank": 313,
      "record": "6-26"
    },
    "Rhode Island": {
      "adjDef": 107.8,
      "adjDefRank": 268,
      "adjEM": -5.13,
      "adjOff": 102.7,
      "adjOffRank": 171,
      "adjTempo": 66.5,
      "adjTempoRank": 98,
      "conference": "A10",
      "rank": 225,
      "record": "7-24"
    },
    "Rice": {
      "adjDef": 99,
      "adjDefRank": 95,
      "adjEM": 1.85,
      "adjOff": 100.9,
      "adjOffRank": 212,
      "adjTempo": 67.9,
      "adjTempoRank": 45,
      "conference": "CUSA",
      "rank": 148,
      "record": "19-16"
    },
    "Richmond": {
      "adjDef": 103.2,
      "adjDefRank": 179,
      "adjEM": 6.13,
      "adjOff": 109.3,
      "adjOffRank": 64,
      "adjTempo": 62.8,
      "adjTempoRank": 275,
      "conference": "A10",
      "rank": 109,
      "record": "16-16"
    },
    "Rider": {
      "adjDef": 109.3,
      "adjDefRank": 287,
      "adjEM": -4.96,
      "adjOff": 104.3,
      "adjOffRank": 144,
      "adjTempo": 67.4,
      "adjTempoRank": 62,
      "conference": "MAAC",
      "rank": 223,
      "record": "13-19"
    },
    "Robert Morris": {
      "adjDef": 100.9,
      "adjDefRank": 135,
      "adjEM": 4.96,
      "adjOff": 105.9,
      "adjOffRank": 119,
      "adjTempo": 63,
      "adjTempoRank": 268,
      "conference": "NEC",
      "rank": 123,
      "record": "26-11"
    },
    "Rutgers": {
      "adjDef": 96.9,
      "adjDefRank": 59,
      "adjEM": 5.14,
      "adjOff": 102,
      "adjOffRank": 182,
      "adjTempo": 65.3,
      "adjTempoRank": 160,
      "conference": "BE",
      "rank": 120,
      "record": "14-18"
    },
    "SIU Edwardsville": {
      "adjDef": 110.5,
      "adjDefRank": 303,
      "adjEM": -15.1,
      "adjOff": 95.4,
      "adjOffRank": 289,
      "adjTempo": 67.7,
      "adjTempoRank": 53,
      "conference": "OVC",
      "rank": 307,
      "record": "10-17"
    },
    "SMU": {
      "adjDef": 100.9,
      "adjDefRank": 134,
      "adjEM": -2.92,
      "adjOff": 98,
      "adjOffRank": 259,
      "adjTempo": 59.3,
      "adjTempoRank": 338,
      "conference": "CUSA",
      "rank": 200,
      "record": "13-19"
    },
    "Sacramento St.": {
      "adjDef": 110.2,
      "adjDefRank": 300,
      "adjEM": -12.03,
      "adjOff": 98.2,
      "adjOffRank": 254,
      "adjTempo": 64.9,
      "adjTempoRank": 178,
      "conference": "BSky",
      "rank": 292,
      "record": "10-18"
    },
    "Sacred Heart": {
      "adjDef": 108.6,
      "adjDefRank": 282,
      "adjEM": -5.99,
      "adjOff": 102.6,
      "adjOffRank": 173,
      "adjTempo": 66,
      "adjTempoRank": 122,
      "conference": "NEC",
      "rank": 232,
      "record": "14-18"
    },
    "Saint Joseph's": {
      "adjDef": 99.6,
      "adjDefRank": 109,
      "adjEM": 10.92,
      "adjOff": 110.5,
      "adjOffRank": 54,
      "adjTempo": 65,
      "adjTempoRank": 174,
      "conference": "A10",
      "rank": 67,
      "record": "20-14"
    },
    "Saint Louis": {
      "adjDef": 90.7,
      "adjDefRank": 12,
      "adjEM": 21.2,
      "adjOff": 111.9,
      "adjOffRank": 34,
      "adjTempo": 61.9,
      "adjTempoRank": 303,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 14,
      "record": "26-8",
      "seed": 9
    },
    "Saint Mary's": {
      "adjDef": 98.8,
      "adjDefRank": 91,
      "adjEM": 15.48,
      "adjOff": 114.3,
      "adjOffRank": 20,
      "adjTempo": 63.4,
      "adjTempoRank": 258,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 34,
      "record": "27-6",
      "seed": 7
    },
    "Saint Peter's": {
      "adjDef": 106.7,
      "adjDefRank": 252,
      "adjEM": -17.05,
      "adjOff": 89.6,
      "adjOffRank": 334,
      "adjTempo": 63.3,
      "adjTempoRank": 259,
      "conference": "MAAC",
      "rank": 316,
      "record": "5-26"
    },
    "Sam Houston St.": {
      "adjDef": 100.4,
      "adjDefRank": 126,
      "adjEM": -10.62,
      "adjOff": 89.8,
      "adjOffRank": 333,
      "adjTempo": 62.5,
      "adjTempoRank": 286,
      "conference": "Slnd",
      "rank": 287,
      "record": "13-19"
    },
    "Samford": {
      "adjDef": 112.6,
      "adjDefRank": 326,
      "adjEM": -10.53,
      "adjOff": 102.1,
      "adjOffRank": 181,
      "adjTempo": 61.6,
      "adjTempoRank": 309,
      "conference": "SC",
      "rank": 285,
      "record": "11-19"
    },
    "San Diego": {
      "adjDef": 106.3,
      "adjDefRank": 249,
      "adjEM": -4.29,
      "adjOff": 102,
      "adjOffRank": 185,
      "adjTempo": 65.3,
      "adjTempoRank": 154,
      "conference": "WCC",
      "rank": 218,
      "record": "13-18"
    },
    "San Diego St.": {
      "adjDef": 95.8,
      "adjDefRank": 44,
      "adjEM": 11.16,
      "adjOff": 107,
      "adjOffRank": 95,
      "adjTempo": 65.8,
      "adjTempoRank": 130,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 66,
      "record": "26-8",
      "seed": 6
    },
    "San Francisco": {
      "adjDef": 106.7,
      "adjDefRank": 256,
      "adjEM": 2.28,
      "adjOff": 109,
      "adjOffRank": 70,
      "adjTempo": 67.9,
      "adjTempoRank": 44,
      "conference": "WCC",
      "rank": 145,
      "record": "20-14"
    },
    "San Jose St.": {
      "adjDef": 109.8,
      "adjDefRank": 296,
      "adjEM": -9.6,
      "adjOff": 100.2,
      "adjOffRank": 226,
      "adjTempo": 63.9,
      "adjTempoRank": 236,
      "conference": "WAC",
      "rank": 275,
      "record": "9-22"
    },
    "Santa Clara": {
      "adjDef": 113.5,
      "adjDefRank": 332,
      "adjEM": -9.91,
      "adjOff": 103.6,
      "adjOffRank": 156,
      "adjTempo": 65.2,
      "adjTempoRank": 162,
      "conference": "WCC",
      "rank": 276,
      "record": "8-22"
    },
    "Savannah St.": {
      "adjDef": 96.9,
      "adjDefRank": 60,
      "adjEM": -1.73,
      "adjOff": 95.2,
      "adjOffRank": 294,
      "adjTempo": 62.8,
      "adjTempoRank": 276,
      "conference": "MEAC",
      "rank": 182,
      "record": "21-12"
    },
    "Seattle": {
      "adjDef": 104,
      "adjDefRank": 195,
      "adjEM": -5.25,
      "adjOff": 98.7,
      "adjOffRank": 247,
      "adjTempo": 74.4,
      "adjTempoRank": 1,
      "conference": "ind",
      "rank": 226,
      "record": "12-15"
    },
    "Seton Hall": {
      "adjDef": 94.6,
      "adjDefRank": 31,
      "adjEM": 12.19,
      "adjOff": 106.7,
      "adjOffRank": 99,
      "adjTempo": 64.8,
      "adjTempoRank": 186,
      "conference": "BE",
      "rank": 55,
      "record": "21-13"
    },
    "Siena": {
      "adjDef": 103,
      "adjDefRank": 177,
      "adjEM": -5.62,
      "adjOff": 97.3,
      "adjOffRank": 274,
      "adjTempo": 64.5,
      "adjTempoRank": 199,
      "conference": "MAAC",
      "rank": 231,
      "record": "14-17"
    },
    "South Alabama": {
      "adjDef": 104.6,
      "adjDefRank": 210,
      "adjEM": -3.57,
      "adjOff": 101.1,
      "adjOffRank": 207,
      "adjTempo": 64.8,
      "adjTempoRank": 181,
      "conference": "SB",
      "rank": 210,
      "record": "17-12"
    },
    "South Carolina": {
      "adjDef": 102.9,
      "adjDefRank": 174,
      "adjEM": 0.63,
      "adjOff": 103.5,
      "adjOffRank": 157,
      "adjTempo": 61.7,
      "adjTempoRank": 308,
      "conference": "SEC",
      "rank": 164,
      "record": "10-21"
    },
    "South Carolina St.": {
      "adjDef": 116.9,
      "adjDefRank": 342,
      "adjEM": -24.49,
      "adjOff": 92.4,
      "adjOffRank": 316,
      "adjTempo": 68.5,
      "adjTempoRank": 33,
      "conference": "MEAC",
      "rank": 343,
      "record": "5-26"
    },
    "South Dakota": {
      "adjDef": 109.5,
      "adjDefRank": 289,
      "adjEM": -10.47,
      "adjOff": 99,
      "adjOffRank": 243,
      "adjTempo": 68,
      "adjTempoRank": 43,
      "conference": "Sum",
      "rank": 283,
      "record": "10-18"
    },
    "South Dakota St.": {
      "adjDef": 102.1,
      "adjDefRank": 152,
      "adjEM": 11.62,
      "adjOff": 113.7,
      "adjOffRank": 26,
      "adjTempo": 64.8,
      "adjTempoRank": 182,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 61,
      "record": "27-8",
      "seed": 14
    },
    "South Florida": {
      "adjDef": 90.8,
      "adjDefRank": 13,
      "adjEM": 13.13,
      "adjOff": 103.9,
      "adjOffRank": 151,
      "adjTempo": 58.5,
      "adjTempoRank": 343,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 48,
      "record": "22-14",
      "seed": 12
    },
    "Southeast Missouri St.": {
      "adjDef": 110.9,
      "adjDefRank": 312,
      "adjEM": -5.46,
      "adjOff": 105.5,
      "adjOffRank": 125,
      "adjTempo": 64.6,
      "adjTempoRank": 193,
      "conference": "OVC",
      "rank": 229,
      "record": "15-16"
    },
    "Southeastern Louisiana": {
      "adjDef": 99.9,
      "adjDefRank": 115,
      "adjEM": -13.46,
      "adjOff": 86.5,
      "adjOffRank": 343,
      "adjTempo": 63,
      "adjTempoRank": 267,
      "conference": "Slnd",
      "rank": 301,
      "record": "12-17"
    },
    "Southern": {
      "adjDef": 106.7,
      "adjDefRank": 257,
      "adjEM": -16.73,
      "adjOff": 90,
      "adjOffRank": 330,
      "adjTempo": 63.4,
      "adjTempoRank": 256,
      "conference": "SWAC",
      "rank": 314,
      "record": "17-14"
    },
    "Southern Illinois": {
      "adjDef": 104,
      "adjDefRank": 197,
      "adjEM": -4.79,
      "adjOff": 99.2,
      "adjOffRank": 242,
      "adjTempo": 64.8,
      "adjTempoRank": 179,
      "conference": "MVC",
      "rank": 222,
      "record": "8-23"
    },
    "Southern Miss": {
      "adjDef": 100.4,
      "adjDefRank": 125,
      "adjEM": 10.83,
      "adjOff": 111.2,
      "adjOffRank": 44,
      "adjTempo": 63.8,
      "adjTempoRank": 238,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 68,
      "record": "25-9",
      "seed": 9
    },
    "Southern Utah": {
      "adjDef": 104.6,
      "adjDefRank": 207,
      "adjEM": -6.27,
      "adjOff": 98.3,
      "adjOffRank": 253,
      "adjTempo": 62.8,
      "adjTempoRank": 273,
      "conference": "Sum",
      "rank": 236,
      "record": "14-17"
    },
    "St. Bonaventure": {
      "adjDef": 98.9,
      "adjDefRank": 93,
      "adjEM": 11.78,
      "adjOff": 110.6,
      "adjOffRank": 53,
      "adjTempo": 63,
      "adjTempoRank": 269,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 60,
      "record": "20-12",
      "seed": 14
    },
    "St. Francis NY": {
      "adjDef": 101.6,
      "adjDefRank": 144,
      "adjEM": -4.11,
      "adjOff": 97.5,
      "adjOffRank": 271,
      "adjTempo": 68.1,
      "adjTempoRank": 40,
      "conference": "NEC",
      "rank": 217,
      "record": "15-15"
    },
    "St. Francis PA": {
      "adjDef": 110.5,
      "adjDefRank": 304,
      "adjEM": -15.24,
      "adjOff": 95.3,
      "adjOffRank": 292,
      "adjTempo": 63,
      "adjTempoRank": 271,
      "conference": "NEC",
      "rank": 309,
      "record": "6-23"
    },
    "St. John's": {
      "adjDef": 102.7,
      "adjDefRank": 168,
      "adjEM": 1.2,
      "adjOff": 103.9,
      "adjOffRank": 152,
      "adjTempo": 66.2,
      "adjTempoRank": 112,
      "conference": "BE",
      "rank": 157,
      "record": "13-19"
    },
    "Stanford": {
      "adjDef": 92.4,
      "adjDefRank": 19,
      "adjEM": 14.36,
      "adjOff": 106.7,
      "adjOffRank": 100,
      "adjTempo": 67.2,
      "adjTempoRank": 74,
      "conference": "P12",
      "rank": 40,
      "record": "26-11"
    },
    "Stephen F. Austin": {
      "adjDef": 96,
      "adjDefRank": 48,
      "adjEM": 1.29,
      "adjOff": 97.3,
      "adjOffRank": 276,
      "adjTempo": 58.6,
      "adjTempoRank": 342,
      "conference": "Slnd",
      "rank": 155,
      "record": "20-12"
    },
    "Stetson": {
      "adjDef": 108.2,
      "adjDefRank": 277,
      "adjEM": -7.5,
      "adjOff": 100.7,
      "adjOffRank": 214,
      "adjTempo": 70.4,
      "adjTempoRank": 12,
      "conference": "ASun",
      "rank": 250,
      "record": "9-20"
    },
    "Stony Brook": {
      "adjDef": 100.9,
      "adjDefRank": 133,
      "adjEM": 2.6,
      "adjOff": 103.5,
      "adjOffRank": 158,
      "adjTempo": 61.4,
      "adjTempoRank": 315,
      "conference": "AE",
      "rank": 142,
      "record": "22-10"
    },
    "Syracuse": {
      "adjDef": 91.7,
      "adjDefRank": 16,
      "adjEM": 26.04,
      "adjOff": 117.7,
      "adjOffRank": 7,
      "adjTempo": 64.4,
      "adjTempoRank": 202,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 5,
      "record": "34-3",
      "seed": 1
    },
    "TCU": {
      "adjDef": 105.6,
      "adjDefRank": 232,
      "adjEM": 0.76,
      "adjOff": 106.3,
      "adjOffRank": 109,
      "adjTempo": 67.9,
      "adjTempoRank": 48,
      "conference": "MWC",
      "rank": 161,
      "record": "18-15"
    },
    "Temple": {
      "adjDef": 98.9,
      "adjDefRank": 92,
      "adjEM": 14.49,
      "adjOff": 113.3,
      "adjOffRank": 28,
      "adjTempo": 65.9,
      "adjTempoRank": 126,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 39,
      "record": "24-8",
      "seed": 5
    },
    "Tennessee": {
      "adjDef": 95.1,
      "adjDefRank": 40,
      "adjEM": 11.38,
      "adjOff": 106.5,
      "adjOffRank": 106,
      "adjTempo": 64.4,
      "adjTempoRank": 201,
      "conference": "SEC",
      "rank": 63,
      "record": "19-15"
    },
    "Tennessee Martin": {
      "adjDef": 115.3,
      "adjDefRank": 337,
      "adjEM": -21.2,
      "adjOff": 94.1,
      "adjOffRank": 303,
      "adjTempo": 65.1,
      "adjTempoRank": 169,
      "conference": "OVC",
      "rank": 336,
      "record": "4-27"
    },
    "Tennessee St.": {
      "adjDef": 101,
      "adjDefRank": 136,
      "adjEM": 0.12,
      "adjOff": 101.1,
      "adjOffRank": 206,
      "adjTempo": 65.3,
      "adjTempoRank": 155,
      "conference": "OVC",
      "rank": 168,
      "record": "20-13"
    },
    "Tennessee Tech": {
      "adjDef": 105.4,
      "adjDefRank": 229,
      "adjEM": -2.07,
      "adjOff": 103.4,
      "adjOffRank": 161,
      "adjTempo": 67.8,
      "adjTempoRank": 52,
      "conference": "OVC",
      "rank": 187,
      "record": "19-14"
    },
    "Texas": {
      "adjDef": 97,
      "adjDefRank": 64,
      "adjEM": 15.9,
      "adjOff": 112.9,
      "adjOffRank": 31,
      "adjTempo": 64.9,
      "adjTempoRank": 176,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 32,
      "record": "20-14",
      "seed": 11
    },
    "Texas A&M": {
      "adjDef": 96.4,
      "adjDefRank": 52,
      "adjEM": 5.07,
      "adjOff": 101.4,
      "adjOffRank": 196,
      "adjTempo": 61.4,
      "adjTempoRank": 317,
      "conference": "B12",
      "rank": 122,
      "record": "14-18"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 104.8,
      "adjDefRank": 215,
      "adjEM": -14.94,
      "adjOff": 89.9,
      "adjOffRank": 331,
      "adjTempo": 64.8,
      "adjTempoRank": 180,
      "conference": "Slnd",
      "rank": 306,
      "record": "6-24"
    },
    "Texas Pan American": {
      "adjDef": 109.1,
      "adjDefRank": 285,
      "adjEM": -16.22,
      "adjOff": 92.9,
      "adjOffRank": 313,
      "adjTempo": 63.7,
      "adjTempoRank": 243,
      "conference": "GWC",
      "rank": 312,
      "record": "11-21"
    },
    "Texas Southern": {
      "adjDef": 101.6,
      "adjDefRank": 146,
      "adjEM": -8.99,
      "adjOff": 92.6,
      "adjOffRank": 314,
      "adjTempo": 65.3,
      "adjTempoRank": 158,
      "conference": "SWAC",
      "rank": 267,
      "record": "15-18"
    },
    "Texas St.": {
      "adjDef": 109.4,
      "adjDefRank": 288,
      "adjEM": -8.95,
      "adjOff": 100.5,
      "adjOffRank": 223,
      "adjTempo": 71.9,
      "adjTempoRank": 6,
      "conference": "Slnd",
      "rank": 266,
      "record": "13-17"
    },
    "Texas Tech": {
      "adjDef": 101.3,
      "adjDefRank": 139,
      "adjEM": -6.18,
      "adjOff": 95.1,
      "adjOffRank": 295,
      "adjTempo": 64.1,
      "adjTempoRank": 223,
      "conference": "B12",
      "rank": 235,
      "record": "8-23"
    },
    "The Citadel": {
      "adjDef": 110.6,
      "adjDefRank": 306,
      "adjEM": -18.01,
      "adjOff": 92.6,
      "adjOffRank": 315,
      "adjTempo": 63.4,
      "adjTempoRank": 257,
      "conference": "SC",
      "rank": 323,
      "record": "6-24"
    },
    "Toledo": {
      "adjDef": 106.8,
      "adjDefRank": 260,
      "adjEM": -3.41,
      "adjOff": 103.4,
      "adjOffRank": 160,
      "adjTempo": 65.7,
      "adjTempoRank": 136,
      "conference": "MAC",
      "rank": 206,
      "record": "19-17"
    },
    "Towson": {
      "adjDef": 109.9,
      "adjDefRank": 297,
      "adjEM": -22.63,
      "adjOff": 87.3,
      "adjOffRank": 340,
      "adjTempo": 61.5,
      "adjTempoRank": 312,
      "conference": "CAA",
      "rank": 339,
      "record": "1-31"
    },
    "Troy": {
      "adjDef": 114.2,
      "adjDefRank": 334,
      "adjEM": -9.08,
      "adjOff": 105.1,
      "adjOffRank": 135,
      "adjTempo": 67.1,
      "adjTempoRank": 80,
      "conference": "SB",
      "rank": 268,
      "record": "10-18"
    },
    "Tulane": {
      "adjDef": 100.6,
      "adjDefRank": 131,
      "adjEM": -2.7,
      "adjOff": 97.9,
      "adjOffRank": 261,
      "adjTempo": 64.7,
      "adjTempoRank": 190,
      "conference": "CUSA",
      "rank": 196,
      "record": "15-16"
    },
    "Tulsa": {
      "adjDef": 98.5,
      "adjDefRank": 88,
      "adjEM": 7.75,
      "adjOff": 106.3,
      "adjOffRank": 110,
      "adjTempo": 63.8,
      "adjTempoRank": 242,
      "conference": "CUSA",
      "rank": 94,
      "record": "17-14"
    },
    "UAB": {
      "adjDef": 97.3,
      "adjDefRank": 67,
      "adjEM": 3.89,
      "adjOff": 101.2,
      "adjOffRank": 201,
      "adjTempo": 61.1,
      "adjTempoRank": 320,
      "conference": "CUSA",
      "rank": 130,
      "record": "15-16"
    },
    "UC Davis": {
      "adjDef": 113.4,
      "adjDefRank": 331,
      "adjEM": -18.82,
      "adjOff": 94.6,
      "adjOffRank": 301,
      "adjTempo": 64.8,
      "adjTempoRank": 185,
      "conference": "BW",
      "rank": 328,
      "record": "5-26"
    },
    "UC Irvine": {
      "adjDef": 104.3,
      "adjDefRank": 201,
      "adjEM": -7.2,
      "adjOff": 97.1,
      "adjOffRank": 280,
      "adjTempo": 69.1,
      "adjTempoRank": 28,
      "conference": "BW",
      "rank": 249,
      "record": "12-20"
    },
    "UC Riverside": {
      "adjDef": 100.5,
      "adjDefRank": 130,
      "adjEM": -10,
      "adjOff": 90.5,
      "adjOffRank": 325,
      "adjTempo": 62.6,
      "adjTempoRank": 281,
      "conference": "BW",
      "rank": 277,
      "record": "14-17"
    },
    "UC Santa Barbara": {
      "adjDef": 104.7,
      "adjDefRank": 214,
      "adjEM": 6.37,
      "adjOff": 111.1,
      "adjOffRank": 46,
      "adjTempo": 63.6,
      "adjTempoRank": 248,
      "conference": "BW",
      "rank": 107,
      "record": "20-11"
    },
    "UCF": {
      "adjDef": 99.4,
      "adjDefRank": 103,
      "adjEM": 7.11,
      "adjOff": 106.5,
      "adjOffRank": 105,
      "adjTempo": 63.1,
      "adjTempoRank": 266,
      "conference": "CUSA",
      "rank": 103,
      "record": "22-11"
    },
    "UCLA": {
      "adjDef": 96.9,
      "adjDefRank": 62,
      "adjEM": 11.85,
      "adjOff": 108.8,
      "adjOffRank": 73,
      "adjTempo": 64.7,
      "adjTempoRank": 191,
      "conference": "P12",
      "rank": 58,
      "record": "19-14"
    },
    "UMBC": {
      "adjDef": 114.8,
      "adjDefRank": 335,
      "adjEM": -23.39,
      "adjOff": 91.4,
      "adjOffRank": 319,
      "adjTempo": 67.9,
      "adjTempoRank": 46,
      "conference": "AE",
      "rank": 342,
      "record": "4-26"
    },
    "UMKC": {
      "adjDef": 110.3,
      "adjDefRank": 301,
      "adjEM": -10.48,
      "adjOff": 99.8,
      "adjOffRank": 231,
      "adjTempo": 62.7,
      "adjTempoRank": 277,
      "conference": "Sum",
      "rank": 284,
      "record": "10-21"
    },
    "UNC Asheville": {
      "adjDef": 104.9,
      "adjDefRank": 219,
      "adjEM": 5.5,
      "adjOff": 110.4,
      "adjOffRank": 55,
      "adjTempo": 68.2,
      "adjTempoRank": 39,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 114,
      "record": "24-10",
      "seed": 16
    },
    "UNC Greensboro": {
      "adjDef": 107.7,
      "adjDefRank": 267,
      "adjEM": -10.28,
      "adjOff": 97.5,
      "adjOffRank": 272,
      "adjTempo": 71.2,
      "adjTempoRank": 9,
      "conference": "SC",
      "rank": 280,
      "record": "13-19"
    },
    "UNC Wilmington": {
      "adjDef": 106.7,
      "adjDefRank": 253,
      "adjEM": -6.33,
      "adjOff": 100.3,
      "adjOffRank": 225,
      "adjTempo": 65.8,
      "adjTempoRank": 129,
      "conference": "CAA",
      "rank": 238,
      "record": "10-21"
    },
    "UNLV": {
      "adjDef": 94.2,
      "adjDefRank": 27,
      "adjEM": 14.97,
      "adjOff": 109.2,
      "adjOffRank": 67,
      "adjTempo": 68.7,
      "adjTempoRank": 31,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 37,
      "record": "26-9",
      "seed": 6
    },
    "USC": {
      "adjDef": 95.9,
      "adjDefRank": 45,
      "adjEM": -4.69,
      "adjOff": 91.2,
      "adjOffRank": 321,
      "adjTempo": 59.8,
      "adjTempoRank": 332,
      "conference": "P12",
      "rank": 220,
      "record": "6-26"
    },
    "USC Upstate": {
      "adjDef": 99.9,
      "adjDefRank": 112,
      "adjEM": 3.22,
      "adjOff": 103.1,
      "adjOffRank": 167,
      "adjTempo": 67,
      "adjTempoRank": 86,
      "conference": "ASun",
      "rank": 136,
      "record": "21-13"
    },
    "UT Arlington": {
      "adjDef": 100.3,
      "adjDefRank": 124,
      "adjEM": 5.1,
      "adjOff": 105.4,
      "adjOffRank": 127,
      "adjTempo": 70.8,
      "adjTempoRank": 11,
      "conference": "Slnd",
      "rank": 121,
      "record": "24-9"
    },
    "UTEP": {
      "adjDef": 99.4,
      "adjDefRank": 101,
      "adjEM": 2.55,
      "adjOff": 102,
      "adjOffRank": 186,
      "adjTempo": 61.4,
      "adjTempoRank": 318,
      "conference": "CUSA",
      "rank": 143,
      "record": "15-17"
    },
    "UTSA": {
      "adjDef": 104.9,
      "adjDefRank": 216,
      "adjEM": -0.81,
      "adjOff": 104,
      "adjOffRank": 147,
      "adjTempo": 66.4,
      "adjTempoRank": 102,
      "conference": "Slnd",
      "rank": 173,
      "record": "18-14"
    },
    "Utah": {
      "adjDef": 107,
      "adjDefRank": 262,
      "adjEM": -13.69,
      "adjOff": 93.3,
      "adjOffRank": 310,
      "adjTempo": 60.8,
      "adjTempoRank": 322,
      "conference": "P12",
      "rank": 302,
      "record": "6-25"
    },
    "Utah St.": {
      "adjDef": 103.7,
      "adjDefRank": 188,
      "adjEM": 4.24,
      "adjOff": 107.9,
      "adjOffRank": 84,
      "adjTempo": 61.9,
      "adjTempoRank": 304,
      "conference": "WAC",
      "rank": 125,
      "record": "21-16"
    },
    "Utah Valley": {
      "adjDef": 106.2,
      "adjDefRank": 247,
      "adjEM": -6.71,
      "adjOff": 99.5,
      "adjOffRank": 238,
      "adjTempo": 64.6,
      "adjTempoRank": 194,
      "conference": "GWC",
      "rank": 243,
      "record": "20-13"
    },
    "VCU": {
      "adjDef": 93.5,
      "adjDefRank": 23,
      "adjEM": 13.45,
      "adjOff": 106.9,
      "adjOffRank": 96,
      "adjTempo": 65,
      "adjTempoRank": 173,
      "conference": "CAA",
      "finish": "Round of 32",
      "rank": 46,
      "record": "29-7",
      "seed": 12
    },
    "VMI": {
      "adjDef": 111.7,
      "adjDefRank": 322,
      "adjEM": -9.53,
      "adjOff": 102.1,
      "adjOffRank": 179,
      "adjTempo": 72.7,
      "adjTempoRank": 4,
      "conference": "BSth",
      "rank": 274,
      "record": "17-16"
    },
    "Valparaiso": {
      "adjDef": 102.9,
      "adjDefRank": 175,
      "adjEM": 3.07,
      "adjOff": 106,
      "adjOffRank": 117,
      "adjTempo": 63.9,
      "adjTempoRank": 234,
      "conference": "Horz",
      "rank": 138,
      "record": "22-12"
    },
    "Vanderbilt": {
      "adjDef": 94.7,
      "adjDefRank": 35,
      "adjEM": 20.9,
      "adjOff": 115.6,
      "adjOffRank": 14,
      "adjTempo": 64.7,
      "adjTempoRank": 188,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 16,
      "record": "25-11",
      "seed": 5
    },
    "Vermont": {
      "adjDef": 100.1,
      "adjDefRank": 119,
      "adjEM": 5.17,
      "adjOff": 105.3,
      "adjOffRank": 131,
      "adjTempo": 61.9,
      "adjTempoRank": 300,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 118,
      "record": "24-12",
      "seed": 16
    },
    "Villanova": {
      "adjDef": 100.7,
      "adjDefRank": 132,
      "adjEM": 8.5,
      "adjOff": 109.2,
      "adjOffRank": 68,
      "adjTempo": 67.4,
      "adjTempoRank": 64,
      "conference": "BE",
      "rank": 85,
      "record": "13-19"
    },
    "Virginia": {
      "adjDef": 89.3,
      "adjDefRank": 5,
      "adjEM": 15.85,
      "adjOff": 105.2,
      "adjOffRank": 133,
      "adjTempo": 59.3,
      "adjTempoRank": 339,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "22-10",
      "seed": 10
    },
    "Virginia Tech": {
      "adjDef": 98.1,
      "adjDefRank": 83,
      "adjEM": 8.64,
      "adjOff": 106.8,
      "adjOffRank": 98,
      "adjTempo": 61.9,
      "adjTempoRank": 301,
      "conference": "ACC",
      "rank": 83,
      "record": "16-17"
    },
    "Wagner": {
      "adjDef": 97.6,
      "adjDefRank": 73,
      "adjEM": 7.14,
      "adjOff": 104.8,
      "adjOffRank": 136,
      "adjTempo": 67.1,
      "adjTempoRank": 78,
      "conference": "NEC",
      "rank": 102,
      "record": "25-6"
    },
    "Wake Forest": {
      "adjDef": 106.1,
      "adjDefRank": 240,
      "adjEM": -3.85,
      "adjOff": 102.2,
      "adjOffRank": 177,
      "adjTempo": 66,
      "adjTempoRank": 120,
      "conference": "ACC",
      "rank": 211,
      "record": "13-18"
    },
    "Washington": {
      "adjDef": 97.5,
      "adjDefRank": 71,
      "adjEM": 11.97,
      "adjOff": 109.5,
      "adjOffRank": 61,
      "adjTempo": 69.1,
      "adjTempoRank": 27,
      "conference": "P12",
      "rank": 56,
      "record": "24-11"
    },
    "Washington St.": {
      "adjDef": 102.4,
      "adjDefRank": 157,
      "adjEM": 7.33,
      "adjOff": 109.7,
      "adjOffRank": 60,
      "adjTempo": 62.8,
      "adjTempoRank": 274,
      "conference": "P12",
      "rank": 99,
      "record": "19-18"
    },
    "Weber St.": {
      "adjDef": 106.5,
      "adjDefRank": 250,
      "adjEM": 3.77,
      "adjOff": 110.2,
      "adjOffRank": 56,
      "adjTempo": 65.5,
      "adjTempoRank": 139,
      "conference": "BSky",
      "rank": 131,
      "record": "25-7"
    },
    "West Virginia": {
      "adjDef": 97.9,
      "adjDefRank": 77,
      "adjEM": 13.56,
      "adjOff": 111.4,
      "adjOffRank": 41,
      "adjTempo": 64,
      "adjTempoRank": 226,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 44,
      "record": "19-14",
      "seed": 10
    },
    "Western Carolina": {
      "adjDef": 107.3,
      "adjDefRank": 265,
      "adjEM": -6.56,
      "adjOff": 100.7,
      "adjOffRank": 217,
      "adjTempo": 65.8,
      "adjTempoRank": 131,
      "conference": "SC",
      "rank": 241,
      "record": "17-18"
    },
    "Western Illinois": {
      "adjDef": 98.4,
      "adjDefRank": 86,
      "adjEM": -1.3,
      "adjOff": 97.1,
      "adjOffRank": 278,
      "adjTempo": 57.8,
      "adjTempoRank": 344,
      "conference": "Sum",
      "rank": 178,
      "record": "18-15"
    },
    "Western Kentucky": {
      "adjDef": 100.5,
      "adjDefRank": 127,
      "adjEM": -2.67,
      "adjOff": 97.8,
      "adjOffRank": 264,
      "adjTempo": 67.9,
      "adjTempoRank": 47,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 193,
      "record": "16-19",
      "seed": 16
    },
    "Western Michigan": {
      "adjDef": 105.8,
      "adjDefRank": 235,
      "adjEM": -0.05,
      "adjOff": 105.7,
      "adjOffRank": 120,
      "adjTempo": 65.1,
      "adjTempoRank": 171,
      "conference": "MAC",
      "rank": 170,
      "record": "14-20"
    },
    "Wichita St.": {
      "adjDef": 93.8,
      "adjDefRank": 26,
      "adjEM": 22.36,
      "adjOff": 116.1,
      "adjOffRank": 11,
      "adjTempo": 65.7,
      "adjTempoRank": 134,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 11,
      "record": "27-6",
      "seed": 5
    },
    "William & Mary": {
      "adjDef": 110.6,
      "adjDefRank": 309,
      "adjEM": -12.55,
      "adjOff": 98.1,
      "adjOffRank": 258,
      "adjTempo": 62.3,
      "adjTempoRank": 291,
      "conference": "CAA",
      "rank": 295,
      "record": "6-26"
    },
    "Winthrop": {
      "adjDef": 103.4,
      "adjDefRank": 182,
      "adjEM": -8.73,
      "adjOff": 94.6,
      "adjOffRank": 299,
      "adjTempo": 61.8,
      "adjTempoRank": 305,
      "conference": "BSth",
      "rank": 262,
      "record": "12-20"
    },
    "Wisconsin": {
      "adjDef": 89.6,
      "adjDefRank": 8,
      "adjEM": 24.09,
      "adjOff": 113.7,
      "adjOffRank": 25,
      "adjTempo": 57.5,
      "adjTempoRank": 345,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "26-10",
      "seed": 4
    },
    "Wofford": {
      "adjDef": 104.7,
      "adjDefRank": 211,
      "adjEM": -2.75,
      "adjOff": 101.9,
      "adjOffRank": 187,
      "adjTempo": 60.9,
      "adjTempoRank": 321,
      "conference": "SC",
      "rank": 197,
      "record": "19-14"
    },
    "Wright St.": {
      "adjDef": 99.6,
      "adjDefRank": 108,
      "adjEM": -4.06,
      "adjOff": 95.5,
      "adjOffRank": 288,
      "adjTempo": 62.1,
      "adjTempoRank": 296,
      "conference": "Horz",
      "rank": 214,
      "record": "13-19"
    },
    "Wyoming": {
      "adjDef": 93.7,
      "adjDefRank": 25,
      "adjEM": 8.05,
      "adjOff": 101.7,
      "adjOffRank": 192,
      "adjTempo": 60,
      "adjTempoRank": 331,
      "conference": "MWC",
      "rank": 90,
      "record": "21-12"
    },
    "Xavier": {
      "adjDef": 96.1,
      "adjDefRank": 49,
      "adjEM": 12.59,
      "adjOff": 108.7,
      "adjOffRank": 74,
      "adjTempo": 66.4,
      "adjTempoRank": 104,
      "conference": "A10",
      "finish": "Sweet 16",
      "rank": 53,
      "record": "23-13",
      "seed": 10
    },
    "Yale": {
      "adjDef": 100.1,
      "adjDefRank": 118,
      "adjEM": 1.09,
      "adjOff": 101.2,
      "adjOffRank": 202,
      "adjTempo": 66.3,
      "adjTempoRank": 110,
      "conference": "Ivy",
      "rank": 158,
      "record": "19-10"
    },
    "Youngstown St.": {
      "adjDef": 105.8,
      "adjDefRank": 234,
      "adjEM": 0.59,
      "adjOff": 106.3,
      "adjOffRank": 108,
      "adjTempo": 64.4,
      "adjTempoRank": 200,
      "conference": "Horz",
      "rank": 165,
      "record": "16-15"
    }
  },
  "2013": {
    "Air Force": {
      "adjDef": 104.9,
      "adjDefRank": 217,
      "adjEM": 6.74,
      "adjOff": 111.7,
      "adjOffRank": 36,
      "adjTempo": 63.2,
      "adjTempoRank": 233,
      "conference": "MWC",
      "rank": 99,
      "record": "18-14"
    },
    "Akron": {
      "adjDef": 95.2,
      "adjDefRank": 50,
      "adjEM": 11.52,
      "adjOff": 106.8,
      "adjOffRank": 101,
      "adjTempo": 65.2,
      "adjTempoRank": 140,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 62,
      "record": "26-7",
      "seed": 12
    },
    "Alabama": {
      "adjDef": 92.2,
      "adjDefRank": 27,
      "adjEM": 11.47,
      "adjOff": 103.6,
      "adjOffRank": 151,
      "adjTempo": 60.5,
      "adjTempoRank": 315,
      "conference": "SEC",
      "rank": 64,
      "record": "23-13"
    },
    "Alabama A&M": {
      "adjDef": 111.5,
      "adjDefRank": 321,
      "adjEM": -20.7,
      "adjOff": 90.8,
      "adjOffRank": 328,
      "adjTempo": 64.9,
      "adjTempoRank": 161,
      "conference": "SWAC",
      "rank": 336,
      "record": "11-20"
    },
    "Alabama St.": {
      "adjDef": 112,
      "adjDefRank": 323,
      "adjEM": -19.71,
      "adjOff": 92.3,
      "adjOffRank": 318,
      "adjTempo": 64.8,
      "adjTempoRank": 165,
      "conference": "SWAC",
      "rank": 331,
      "record": "10-22"
    },
    "Albany": {
      "adjDef": 101.3,
      "adjDefRank": 144,
      "adjEM": 1.36,
      "adjOff": 102.6,
      "adjOffRank": 171,
      "adjTempo": 61.7,
      "adjTempoRank": 289,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 150,
      "record": "24-11",
      "seed": 15
    },
    "Alcorn St.": {
      "adjDef": 102.2,
      "adjDefRank": 160,
      "adjEM": -16.88,
      "adjOff": 85.4,
      "adjOffRank": 345,
      "adjTempo": 66.6,
      "adjTempoRank": 79,
      "conference": "SWAC",
      "rank": 323,
      "record": "10-24"
    },
    "American": {
      "adjDef": 108.5,
      "adjDefRank": 280,
      "adjEM": -10.51,
      "adjOff": 98,
      "adjOffRank": 258,
      "adjTempo": 58.5,
      "adjTempoRank": 343,
      "conference": "Pat",
      "rank": 277,
      "record": "10-20"
    },
    "Appalachian St.": {
      "adjDef": 108.3,
      "adjDefRank": 279,
      "adjEM": -8.9,
      "adjOff": 99.4,
      "adjOffRank": 228,
      "adjTempo": 66.5,
      "adjTempoRank": 82,
      "conference": "SC",
      "rank": 264,
      "record": "15-16"
    },
    "Arizona": {
      "adjDef": 93.5,
      "adjDefRank": 32,
      "adjEM": 21.88,
      "adjOff": 115.4,
      "adjOffRank": 13,
      "adjTempo": 65.5,
      "adjTempoRank": 133,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "27-8",
      "seed": 6
    },
    "Arizona St.": {
      "adjDef": 97.8,
      "adjDefRank": 85,
      "adjEM": 10.5,
      "adjOff": 108.3,
      "adjOffRank": 72,
      "adjTempo": 64.8,
      "adjTempoRank": 167,
      "conference": "P12",
      "rank": 72,
      "record": "22-13"
    },
    "Arkansas": {
      "adjDef": 97.4,
      "adjDefRank": 81,
      "adjEM": 9.29,
      "adjOff": 106.7,
      "adjOffRank": 103,
      "adjTempo": 69.6,
      "adjTempoRank": 11,
      "conference": "SEC",
      "rank": 80,
      "record": "19-13"
    },
    "Arkansas Little Rock": {
      "adjDef": 102.4,
      "adjDefRank": 162,
      "adjEM": -4.74,
      "adjOff": 97.6,
      "adjOffRank": 264,
      "adjTempo": 66.4,
      "adjTempoRank": 87,
      "conference": "SB",
      "rank": 220,
      "record": "17-15"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 103.7,
      "adjDefRank": 191,
      "adjEM": -11.19,
      "adjOff": 92.5,
      "adjOffRank": 316,
      "adjTempo": 66.6,
      "adjTempoRank": 77,
      "conference": "SWAC",
      "rank": 281,
      "record": "16-14"
    },
    "Arkansas St.": {
      "adjDef": 97.1,
      "adjDefRank": 77,
      "adjEM": 1.74,
      "adjOff": 98.8,
      "adjOffRank": 239,
      "adjTempo": 64.2,
      "adjTempoRank": 192,
      "conference": "SB",
      "rank": 148,
      "record": "19-12"
    },
    "Army": {
      "adjDef": 104.7,
      "adjDefRank": 209,
      "adjEM": 0.6,
      "adjOff": 105.3,
      "adjOffRank": 126,
      "adjTempo": 66.7,
      "adjTempoRank": 71,
      "conference": "Pat",
      "rank": 158,
      "record": "16-15"
    },
    "Auburn": {
      "adjDef": 101.6,
      "adjDefRank": 150,
      "adjEM": -2.83,
      "adjOff": 98.8,
      "adjOffRank": 240,
      "adjTempo": 65.6,
      "adjTempoRank": 124,
      "conference": "SEC",
      "rank": 197,
      "record": "9-23"
    },
    "Austin Peay": {
      "adjDef": 113.3,
      "adjDefRank": 333,
      "adjEM": -14.41,
      "adjOff": 98.8,
      "adjOffRank": 237,
      "adjTempo": 67.5,
      "adjTempoRank": 50,
      "conference": "OVC",
      "rank": 311,
      "record": "8-23"
    },
    "BYU": {
      "adjDef": 97.2,
      "adjDefRank": 79,
      "adjEM": 13.04,
      "adjOff": 110.2,
      "adjOffRank": 50,
      "adjTempo": 69.9,
      "adjTempoRank": 7,
      "conference": "WCC",
      "rank": 57,
      "record": "24-12"
    },
    "Ball St.": {
      "adjDef": 107.3,
      "adjDefRank": 264,
      "adjEM": -7.84,
      "adjOff": 99.5,
      "adjOffRank": 227,
      "adjTempo": 64.9,
      "adjTempoRank": 156,
      "conference": "MAC",
      "rank": 253,
      "record": "15-15"
    },
    "Baylor": {
      "adjDef": 96.4,
      "adjDefRank": 62,
      "adjEM": 17.89,
      "adjOff": 114.3,
      "adjOffRank": 19,
      "adjTempo": 66.4,
      "adjTempoRank": 85,
      "conference": "B12",
      "rank": 28,
      "record": "23-14"
    },
    "Belmont": {
      "adjDef": 96.6,
      "adjDefRank": 69,
      "adjEM": 14.92,
      "adjOff": 111.6,
      "adjOffRank": 37,
      "adjTempo": 65.9,
      "adjTempoRank": 114,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 43,
      "record": "26-7",
      "seed": 11
    },
    "Bethune Cookman": {
      "adjDef": 107.8,
      "adjDefRank": 268,
      "adjEM": -9.75,
      "adjOff": 98,
      "adjOffRank": 254,
      "adjTempo": 65.1,
      "adjTempoRank": 149,
      "conference": "MEAC",
      "rank": 270,
      "record": "14-20"
    },
    "Binghamton": {
      "adjDef": 110.4,
      "adjDefRank": 308,
      "adjEM": -24.06,
      "adjOff": 86.3,
      "adjOffRank": 342,
      "adjTempo": 65.1,
      "adjTempoRank": 148,
      "conference": "AE",
      "rank": 343,
      "record": "3-27"
    },
    "Boise St.": {
      "adjDef": 99.7,
      "adjDefRank": 119,
      "adjEM": 13.54,
      "adjOff": 113.2,
      "adjOffRank": 25,
      "adjTempo": 64.8,
      "adjTempoRank": 166,
      "conference": "MWC",
      "finish": "First Four",
      "rank": 54,
      "record": "21-11",
      "seed": 13
    },
    "Boston College": {
      "adjDef": 103.9,
      "adjDefRank": 196,
      "adjEM": 6.04,
      "adjOff": 109.9,
      "adjOffRank": 54,
      "adjTempo": 62.6,
      "adjTempoRank": 260,
      "conference": "ACC",
      "rank": 107,
      "record": "16-17"
    },
    "Boston University": {
      "adjDef": 103,
      "adjDefRank": 178,
      "adjEM": 2.23,
      "adjOff": 105.3,
      "adjOffRank": 127,
      "adjTempo": 64.4,
      "adjTempoRank": 185,
      "conference": "AE",
      "rank": 143,
      "record": "17-13"
    },
    "Bowling Green": {
      "adjDef": 100.8,
      "adjDefRank": 138,
      "adjEM": -3.87,
      "adjOff": 96.9,
      "adjOffRank": 277,
      "adjTempo": 62.8,
      "adjTempoRank": 250,
      "conference": "MAC",
      "rank": 213,
      "record": "13-19"
    },
    "Bradley": {
      "adjDef": 103.8,
      "adjDefRank": 194,
      "adjEM": 0.59,
      "adjOff": 104.4,
      "adjOffRank": 144,
      "adjTempo": 65.6,
      "adjTempoRank": 122,
      "conference": "MVC",
      "rank": 159,
      "record": "18-17"
    },
    "Brown": {
      "adjDef": 101.7,
      "adjDefRank": 152,
      "adjEM": -5.94,
      "adjOff": 95.8,
      "adjOffRank": 287,
      "adjTempo": 63.3,
      "adjTempoRank": 231,
      "conference": "Ivy",
      "rank": 232,
      "record": "13-15"
    },
    "Bryant": {
      "adjDef": 109.9,
      "adjDefRank": 301,
      "adjEM": -0.53,
      "adjOff": 109.4,
      "adjOffRank": 63,
      "adjTempo": 65.1,
      "adjTempoRank": 150,
      "conference": "NEC",
      "rank": 176,
      "record": "19-12"
    },
    "Bucknell": {
      "adjDef": 94.6,
      "adjDefRank": 43,
      "adjEM": 12.72,
      "adjOff": 107.3,
      "adjOffRank": 87,
      "adjTempo": 61.9,
      "adjTempoRank": 282,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 58,
      "record": "28-6",
      "seed": 11
    },
    "Buffalo": {
      "adjDef": 104.7,
      "adjDefRank": 211,
      "adjEM": -1.4,
      "adjOff": 103.3,
      "adjOffRank": 155,
      "adjTempo": 63.9,
      "adjTempoRank": 201,
      "conference": "MAC",
      "rank": 189,
      "record": "14-20"
    },
    "Butler": {
      "adjDef": 95.1,
      "adjDefRank": 48,
      "adjEM": 15.41,
      "adjOff": 110.5,
      "adjOffRank": 47,
      "adjTempo": 63.6,
      "adjTempoRank": 217,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 42,
      "record": "27-9",
      "seed": 6
    },
    "Cal Poly": {
      "adjDef": 105.3,
      "adjDefRank": 224,
      "adjEM": 1.16,
      "adjOff": 106.4,
      "adjOffRank": 112,
      "adjTempo": 58.3,
      "adjTempoRank": 344,
      "conference": "BW",
      "rank": 152,
      "record": "18-14"
    },
    "Cal St. Bakersfield": {
      "adjDef": 104.8,
      "adjDefRank": 215,
      "adjEM": -6.52,
      "adjOff": 98.3,
      "adjOffRank": 251,
      "adjTempo": 64.5,
      "adjTempoRank": 182,
      "conference": "ind",
      "rank": 237,
      "record": "14-16"
    },
    "Cal St. Fullerton": {
      "adjDef": 110.3,
      "adjDefRank": 306,
      "adjEM": -3.09,
      "adjOff": 107.2,
      "adjOffRank": 92,
      "adjTempo": 68.4,
      "adjTempoRank": 29,
      "conference": "BW",
      "rank": 201,
      "record": "14-18"
    },
    "Cal St. Northridge": {
      "adjDef": 108.6,
      "adjDefRank": 286,
      "adjEM": -4.56,
      "adjOff": 104.1,
      "adjOffRank": 147,
      "adjTempo": 69.2,
      "adjTempoRank": 18,
      "conference": "BW",
      "rank": 219,
      "record": "14-17"
    },
    "California": {
      "adjDef": 94.2,
      "adjDefRank": 39,
      "adjEM": 13.11,
      "adjOff": 107.3,
      "adjOffRank": 88,
      "adjTempo": 64.9,
      "adjTempoRank": 159,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 56,
      "record": "21-12",
      "seed": 12
    },
    "Campbell": {
      "adjDef": 110.9,
      "adjDefRank": 315,
      "adjEM": -13.11,
      "adjOff": 97.8,
      "adjOffRank": 261,
      "adjTempo": 64.8,
      "adjTempoRank": 164,
      "conference": "BSth",
      "rank": 300,
      "record": "13-20"
    },
    "Canisius": {
      "adjDef": 106.2,
      "adjDefRank": 248,
      "adjEM": 5.06,
      "adjOff": 111.2,
      "adjOffRank": 40,
      "adjTempo": 65.1,
      "adjTempoRank": 146,
      "conference": "MAAC",
      "rank": 116,
      "record": "20-14"
    },
    "Central Arkansas": {
      "adjDef": 112.9,
      "adjDefRank": 330,
      "adjEM": -12.55,
      "adjOff": 100.4,
      "adjOffRank": 214,
      "adjTempo": 71.1,
      "adjTempoRank": 2,
      "conference": "Slnd",
      "rank": 294,
      "record": "13-17"
    },
    "Central Connecticut": {
      "adjDef": 109.9,
      "adjDefRank": 302,
      "adjEM": -8.18,
      "adjOff": 101.8,
      "adjOffRank": 186,
      "adjTempo": 68.8,
      "adjTempoRank": 23,
      "conference": "NEC",
      "rank": 257,
      "record": "13-17"
    },
    "Central Michigan": {
      "adjDef": 112.5,
      "adjDefRank": 326,
      "adjEM": -7.43,
      "adjOff": 105.1,
      "adjOffRank": 132,
      "adjTempo": 64.2,
      "adjTempoRank": 190,
      "conference": "MAC",
      "rank": 247,
      "record": "11-20"
    },
    "Charleston Southern": {
      "adjDef": 103.1,
      "adjDefRank": 179,
      "adjEM": -0.78,
      "adjOff": 102.3,
      "adjOffRank": 180,
      "adjTempo": 66.6,
      "adjTempoRank": 78,
      "conference": "BSth",
      "rank": 179,
      "record": "19-13"
    },
    "Charlotte": {
      "adjDef": 97.5,
      "adjDefRank": 83,
      "adjEM": 3.9,
      "adjOff": 101.4,
      "adjOffRank": 195,
      "adjTempo": 66.4,
      "adjTempoRank": 84,
      "conference": "A10",
      "rank": 129,
      "record": "21-12"
    },
    "Chattanooga": {
      "adjDef": 111.8,
      "adjDefRank": 322,
      "adjEM": -14.12,
      "adjOff": 97.7,
      "adjOffRank": 263,
      "adjTempo": 66.2,
      "adjTempoRank": 96,
      "conference": "SC",
      "rank": 310,
      "record": "13-19"
    },
    "Chicago St.": {
      "adjDef": 103.7,
      "adjDefRank": 192,
      "adjEM": -12.67,
      "adjOff": 91.1,
      "adjOffRank": 327,
      "adjTempo": 65.5,
      "adjTempoRank": 129,
      "conference": "GWC",
      "rank": 297,
      "record": "11-22"
    },
    "Cincinnati": {
      "adjDef": 90.4,
      "adjDefRank": 15,
      "adjEM": 15.57,
      "adjOff": 105.9,
      "adjOffRank": 118,
      "adjTempo": 63,
      "adjTempoRank": 243,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 40,
      "record": "22-12",
      "seed": 10
    },
    "Clemson": {
      "adjDef": 95.8,
      "adjDefRank": 59,
      "adjEM": 5.33,
      "adjOff": 101.2,
      "adjOffRank": 198,
      "adjTempo": 61,
      "adjTempoRank": 303,
      "conference": "ACC",
      "rank": 112,
      "record": "13-18"
    },
    "Cleveland St.": {
      "adjDef": 108,
      "adjDefRank": 274,
      "adjEM": -8.42,
      "adjOff": 99.6,
      "adjOffRank": 223,
      "adjTempo": 63.6,
      "adjTempoRank": 220,
      "conference": "Horz",
      "rank": 260,
      "record": "14-18"
    },
    "Coastal Carolina": {
      "adjDef": 103.6,
      "adjDefRank": 189,
      "adjEM": -5.83,
      "adjOff": 97.8,
      "adjOffRank": 260,
      "adjTempo": 63.8,
      "adjTempoRank": 209,
      "conference": "BSth",
      "rank": 231,
      "record": "14-15"
    },
    "Colgate": {
      "adjDef": 107.3,
      "adjDefRank": 262,
      "adjEM": -9.66,
      "adjOff": 97.6,
      "adjOffRank": 265,
      "adjTempo": 65.6,
      "adjTempoRank": 126,
      "conference": "Pat",
      "rank": 269,
      "record": "11-21"
    },
    "College of Charleston": {
      "adjDef": 101.6,
      "adjDefRank": 151,
      "adjEM": 1.1,
      "adjOff": 102.7,
      "adjOffRank": 167,
      "adjTempo": 62.9,
      "adjTempoRank": 247,
      "conference": "SC",
      "rank": 153,
      "record": "24-11"
    },
    "Colorado": {
      "adjDef": 91.9,
      "adjDefRank": 23,
      "adjEM": 14.62,
      "adjOff": 106.5,
      "adjOffRank": 109,
      "adjTempo": 63.7,
      "adjTempoRank": 215,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 45,
      "record": "21-12",
      "seed": 10
    },
    "Colorado St.": {
      "adjDef": 98.8,
      "adjDefRank": 104,
      "adjEM": 19.12,
      "adjOff": 117.9,
      "adjOffRank": 6,
      "adjTempo": 63.8,
      "adjTempoRank": 210,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "26-9",
      "seed": 8
    },
    "Columbia": {
      "adjDef": 103.5,
      "adjDefRank": 187,
      "adjEM": -2.42,
      "adjOff": 101.1,
      "adjOffRank": 200,
      "adjTempo": 60.3,
      "adjTempoRank": 321,
      "conference": "Ivy",
      "rank": 196,
      "record": "12-16"
    },
    "Connecticut": {
      "adjDef": 95.9,
      "adjDefRank": 60,
      "adjEM": 14.05,
      "adjOff": 109.9,
      "adjOffRank": 55,
      "adjTempo": 63.8,
      "adjTempoRank": 206,
      "conference": "BE",
      "rank": 49,
      "record": "20-10"
    },
    "Coppin St.": {
      "adjDef": 107.7,
      "adjDefRank": 267,
      "adjEM": -14.53,
      "adjOff": 93.2,
      "adjOffRank": 310,
      "adjTempo": 66.9,
      "adjTempoRank": 65,
      "conference": "MEAC",
      "rank": 313,
      "record": "8-24"
    },
    "Cornell": {
      "adjDef": 108.6,
      "adjDefRank": 284,
      "adjEM": -8.68,
      "adjOff": 99.9,
      "adjOffRank": 221,
      "adjTempo": 66.1,
      "adjTempoRank": 99,
      "conference": "Ivy",
      "rank": 261,
      "record": "13-18"
    },
    "Creighton": {
      "adjDef": 96.5,
      "adjDefRank": 66,
      "adjEM": 21.75,
      "adjOff": 118.3,
      "adjOffRank": 5,
      "adjTempo": 63.8,
      "adjTempoRank": 211,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 15,
      "record": "28-8",
      "seed": 7
    },
    "Dartmouth": {
      "adjDef": 106.7,
      "adjDefRank": 255,
      "adjEM": -10.34,
      "adjOff": 96.4,
      "adjOffRank": 280,
      "adjTempo": 63.1,
      "adjTempoRank": 234,
      "conference": "Ivy",
      "rank": 275,
      "record": "9-19"
    },
    "Davidson": {
      "adjDef": 100.7,
      "adjDefRank": 136,
      "adjEM": 11.36,
      "adjOff": 112.1,
      "adjOffRank": 32,
      "adjTempo": 63.9,
      "adjTempoRank": 205,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 66,
      "record": "26-8",
      "seed": 14
    },
    "Dayton": {
      "adjDef": 101.4,
      "adjDefRank": 145,
      "adjEM": 11.31,
      "adjOff": 112.7,
      "adjOffRank": 28,
      "adjTempo": 64.7,
      "adjTempoRank": 174,
      "conference": "A10",
      "rank": 67,
      "record": "17-14"
    },
    "DePaul": {
      "adjDef": 105.9,
      "adjDefRank": 244,
      "adjEM": -0.43,
      "adjOff": 105.5,
      "adjOffRank": 124,
      "adjTempo": 70.7,
      "adjTempoRank": 4,
      "conference": "BE",
      "rank": 174,
      "record": "11-21"
    },
    "Delaware": {
      "adjDef": 104.3,
      "adjDefRank": 204,
      "adjEM": 0.11,
      "adjOff": 104.4,
      "adjOffRank": 143,
      "adjTempo": 65.9,
      "adjTempoRank": 108,
      "conference": "CAA",
      "rank": 167,
      "record": "19-14"
    },
    "Delaware St.": {
      "adjDef": 108.1,
      "adjDefRank": 277,
      "adjEM": -11.43,
      "adjOff": 96.7,
      "adjOffRank": 279,
      "adjTempo": 59.8,
      "adjTempoRank": 327,
      "conference": "MEAC",
      "rank": 284,
      "record": "15-18"
    },
    "Denver": {
      "adjDef": 95.7,
      "adjDefRank": 56,
      "adjEM": 14.3,
      "adjOff": 110,
      "adjOffRank": 52,
      "adjTempo": 57.5,
      "adjTempoRank": 346,
      "conference": "WAC",
      "rank": 47,
      "record": "22-10"
    },
    "Detroit": {
      "adjDef": 104.1,
      "adjDefRank": 200,
      "adjEM": 9.65,
      "adjOff": 113.7,
      "adjOffRank": 21,
      "adjTempo": 69,
      "adjTempoRank": 20,
      "conference": "Horz",
      "rank": 77,
      "record": "20-13"
    },
    "Drake": {
      "adjDef": 102.6,
      "adjDefRank": 167,
      "adjEM": 4.55,
      "adjOff": 107.2,
      "adjOffRank": 93,
      "adjTempo": 67.4,
      "adjTempoRank": 53,
      "conference": "MVC",
      "rank": 118,
      "record": "15-17"
    },
    "Drexel": {
      "adjDef": 102,
      "adjDefRank": 155,
      "adjEM": 0.08,
      "adjOff": 102.1,
      "adjOffRank": 183,
      "adjTempo": 60.5,
      "adjTempoRank": 317,
      "conference": "CAA",
      "rank": 168,
      "record": "13-18"
    },
    "Duke": {
      "adjDef": 92.1,
      "adjDefRank": 26,
      "adjEM": 26.98,
      "adjOff": 119.1,
      "adjOffRank": 4,
      "adjTempo": 66.7,
      "adjTempoRank": 75,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "30-6",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 107.5,
      "adjDefRank": 266,
      "adjEM": -6.75,
      "adjOff": 100.7,
      "adjOffRank": 207,
      "adjTempo": 67.9,
      "adjTempoRank": 40,
      "conference": "A10",
      "rank": 240,
      "record": "8-22"
    },
    "East Carolina": {
      "adjDef": 104.9,
      "adjDefRank": 216,
      "adjEM": 4.02,
      "adjOff": 108.9,
      "adjOffRank": 69,
      "adjTempo": 67.5,
      "adjTempoRank": 49,
      "conference": "CUSA",
      "rank": 125,
      "record": "23-12"
    },
    "East Tennessee St.": {
      "adjDef": 110.8,
      "adjDefRank": 313,
      "adjEM": -15.69,
      "adjOff": 95.1,
      "adjOffRank": 294,
      "adjTempo": 63.4,
      "adjTempoRank": 225,
      "conference": "ASun",
      "rank": 319,
      "record": "10-22"
    },
    "Eastern Illinois": {
      "adjDef": 110.2,
      "adjDefRank": 304,
      "adjEM": -10.18,
      "adjOff": 100,
      "adjOffRank": 219,
      "adjTempo": 58.6,
      "adjTempoRank": 342,
      "conference": "OVC",
      "rank": 272,
      "record": "11-21"
    },
    "Eastern Kentucky": {
      "adjDef": 105.3,
      "adjDefRank": 225,
      "adjEM": 5.39,
      "adjOff": 110.7,
      "adjOffRank": 45,
      "adjTempo": 63.9,
      "adjTempoRank": 202,
      "conference": "OVC",
      "rank": 110,
      "record": "25-10"
    },
    "Eastern Michigan": {
      "adjDef": 99,
      "adjDefRank": 105,
      "adjEM": -5.94,
      "adjOff": 93,
      "adjOffRank": 312,
      "adjTempo": 58.9,
      "adjTempoRank": 339,
      "conference": "MAC",
      "rank": 233,
      "record": "16-18"
    },
    "Eastern Washington": {
      "adjDef": 110.1,
      "adjDefRank": 303,
      "adjEM": -12.98,
      "adjOff": 97.2,
      "adjOffRank": 272,
      "adjTempo": 67,
      "adjTempoRank": 62,
      "conference": "BSky",
      "rank": 299,
      "record": "10-21"
    },
    "Elon": {
      "adjDef": 102.5,
      "adjDefRank": 165,
      "adjEM": -3.51,
      "adjOff": 99,
      "adjOffRank": 234,
      "adjTempo": 64.8,
      "adjTempoRank": 169,
      "conference": "SC",
      "rank": 206,
      "record": "21-12"
    },
    "Evansville": {
      "adjDef": 98.3,
      "adjDefRank": 92,
      "adjEM": 8.08,
      "adjOff": 106.4,
      "adjOffRank": 110,
      "adjTempo": 64.6,
      "adjTempoRank": 176,
      "conference": "MVC",
      "rank": 84,
      "record": "21-15"
    },
    "FIU": {
      "adjDef": 102.9,
      "adjDefRank": 175,
      "adjEM": -1.39,
      "adjOff": 101.6,
      "adjOffRank": 192,
      "adjTempo": 67.8,
      "adjTempoRank": 43,
      "conference": "SB",
      "rank": 188,
      "record": "18-14"
    },
    "Fairfield": {
      "adjDef": 96.8,
      "adjDefRank": 74,
      "adjEM": 1.2,
      "adjOff": 98,
      "adjOffRank": 255,
      "adjTempo": 60.5,
      "adjTempoRank": 316,
      "conference": "MAAC",
      "rank": 151,
      "record": "19-16"
    },
    "Fairleigh Dickinson": {
      "adjDef": 114.5,
      "adjDefRank": 339,
      "adjEM": -20.47,
      "adjOff": 94,
      "adjOffRank": 301,
      "adjTempo": 66.1,
      "adjTempoRank": 102,
      "conference": "NEC",
      "rank": 334,
      "record": "7-24"
    },
    "Florida": {
      "adjDef": 86,
      "adjDefRank": 3,
      "adjEM": 31.18,
      "adjOff": 117.2,
      "adjOffRank": 9,
      "adjTempo": 61.9,
      "adjTempoRank": 280,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 2,
      "record": "29-8",
      "seed": 3
    },
    "Florida A&M": {
      "adjDef": 107.9,
      "adjDefRank": 269,
      "adjEM": -18.34,
      "adjOff": 89.5,
      "adjOffRank": 333,
      "adjTempo": 67.5,
      "adjTempoRank": 51,
      "conference": "MEAC",
      "rank": 328,
      "record": "8-22"
    },
    "Florida Atlantic": {
      "adjDef": 105.7,
      "adjDefRank": 239,
      "adjEM": -6.11,
      "adjOff": 99.5,
      "adjOffRank": 225,
      "adjTempo": 63.8,
      "adjTempoRank": 207,
      "conference": "SB",
      "rank": 235,
      "record": "14-18"
    },
    "Florida Gulf Coast": {
      "adjDef": 98.6,
      "adjDefRank": 99,
      "adjEM": 6.27,
      "adjOff": 104.9,
      "adjOffRank": 136,
      "adjTempo": 67.9,
      "adjTempoRank": 41,
      "conference": "ASun",
      "finish": "Sweet 16",
      "rank": 105,
      "record": "26-11",
      "seed": 15
    },
    "Florida St.": {
      "adjDef": 102.7,
      "adjDefRank": 170,
      "adjEM": 4.45,
      "adjOff": 107.1,
      "adjOffRank": 94,
      "adjTempo": 64.2,
      "adjTempoRank": 191,
      "conference": "ACC",
      "rank": 120,
      "record": "18-16"
    },
    "Fordham": {
      "adjDef": 108,
      "adjDefRank": 273,
      "adjEM": -7.82,
      "adjOff": 100.2,
      "adjOffRank": 216,
      "adjTempo": 67.3,
      "adjTempoRank": 56,
      "conference": "A10",
      "rank": 252,
      "record": "7-24"
    },
    "Fresno St.": {
      "adjDef": 94.5,
      "adjDefRank": 42,
      "adjEM": 5.46,
      "adjOff": 100,
      "adjOffRank": 220,
      "adjTempo": 60.5,
      "adjTempoRank": 314,
      "conference": "MWC",
      "rank": 109,
      "record": "11-19"
    },
    "Furman": {
      "adjDef": 115,
      "adjDefRank": 340,
      "adjEM": -23.3,
      "adjOff": 91.7,
      "adjOffRank": 324,
      "adjTempo": 63.7,
      "adjTempoRank": 216,
      "conference": "SC",
      "rank": 342,
      "record": "7-24"
    },
    "Gardner Webb": {
      "adjDef": 99.6,
      "adjDefRank": 118,
      "adjEM": -1.63,
      "adjOff": 98,
      "adjOffRank": 257,
      "adjTempo": 61.5,
      "adjTempoRank": 294,
      "conference": "BSth",
      "rank": 190,
      "record": "21-13"
    },
    "George Mason": {
      "adjDef": 100.3,
      "adjDefRank": 130,
      "adjEM": 3.21,
      "adjOff": 103.5,
      "adjOffRank": 152,
      "adjTempo": 65.6,
      "adjTempoRank": 123,
      "conference": "CAA",
      "rank": 133,
      "record": "22-16"
    },
    "George Washington": {
      "adjDef": 95.1,
      "adjDefRank": 49,
      "adjEM": 5.34,
      "adjOff": 100.4,
      "adjOffRank": 211,
      "adjTempo": 66.5,
      "adjTempoRank": 81,
      "conference": "A10",
      "rank": 111,
      "record": "13-17"
    },
    "Georgetown": {
      "adjDef": 86.4,
      "adjDefRank": 4,
      "adjEM": 21.24,
      "adjOff": 107.6,
      "adjOffRank": 80,
      "adjTempo": 61.2,
      "adjTempoRank": 300,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 16,
      "record": "25-7",
      "seed": 2
    },
    "Georgia": {
      "adjDef": 96.7,
      "adjDefRank": 71,
      "adjEM": 5.27,
      "adjOff": 101.9,
      "adjOffRank": 184,
      "adjTempo": 60.9,
      "adjTempoRank": 308,
      "conference": "SEC",
      "rank": 113,
      "record": "15-17"
    },
    "Georgia Southern": {
      "adjDef": 107,
      "adjDefRank": 257,
      "adjEM": -11.19,
      "adjOff": 95.8,
      "adjOffRank": 286,
      "adjTempo": 60.9,
      "adjTempoRank": 307,
      "conference": "SC",
      "rank": 282,
      "record": "14-19"
    },
    "Georgia St.": {
      "adjDef": 107.1,
      "adjDefRank": 259,
      "adjEM": -1.33,
      "adjOff": 105.8,
      "adjOffRank": 119,
      "adjTempo": 62.6,
      "adjTempoRank": 262,
      "conference": "CAA",
      "rank": 187,
      "record": "15-16"
    },
    "Georgia Tech": {
      "adjDef": 93.5,
      "adjDefRank": 33,
      "adjEM": 6.37,
      "adjOff": 99.9,
      "adjOffRank": 222,
      "adjTempo": 65.2,
      "adjTempoRank": 142,
      "conference": "ACC",
      "rank": 104,
      "record": "16-15"
    },
    "Gonzaga": {
      "adjDef": 92.7,
      "adjDefRank": 30,
      "adjEM": 27.81,
      "adjOff": 120.5,
      "adjOffRank": 3,
      "adjTempo": 63.3,
      "adjTempoRank": 232,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 5,
      "record": "32-3",
      "seed": 1
    },
    "Grambling St.": {
      "adjDef": 122.1,
      "adjDefRank": 347,
      "adjEM": -46.1,
      "adjOff": 76,
      "adjOffRank": 347,
      "adjTempo": 62.5,
      "adjTempoRank": 266,
      "conference": "SWAC",
      "rank": 347,
      "record": "0-28"
    },
    "Green Bay": {
      "adjDef": 100.5,
      "adjDefRank": 133,
      "adjEM": 3.99,
      "adjOff": 104.5,
      "adjOffRank": 142,
      "adjTempo": 62.5,
      "adjTempoRank": 264,
      "conference": "Horz",
      "rank": 126,
      "record": "18-16"
    },
    "Hampton": {
      "adjDef": 99.4,
      "adjDefRank": 113,
      "adjEM": -10.21,
      "adjOff": 89.2,
      "adjOffRank": 336,
      "adjTempo": 68.7,
      "adjTempoRank": 26,
      "conference": "MEAC",
      "rank": 273,
      "record": "14-17"
    },
    "Hartford": {
      "adjDef": 99.2,
      "adjDefRank": 109,
      "adjEM": -5.24,
      "adjOff": 94,
      "adjOffRank": 302,
      "adjTempo": 62.5,
      "adjTempoRank": 267,
      "conference": "AE",
      "rank": 226,
      "record": "17-14"
    },
    "Harvard": {
      "adjDef": 101.1,
      "adjDefRank": 140,
      "adjEM": 7.07,
      "adjOff": 108.1,
      "adjOffRank": 74,
      "adjTempo": 63.3,
      "adjTempoRank": 229,
      "conference": "Ivy",
      "finish": "Round of 32",
      "rank": 93,
      "record": "20-10",
      "seed": 14
    },
    "Hawaii": {
      "adjDef": 106.6,
      "adjDefRank": 252,
      "adjEM": -3.72,
      "adjOff": 102.8,
      "adjOffRank": 165,
      "adjTempo": 67.9,
      "adjTempoRank": 39,
      "conference": "BW",
      "rank": 210,
      "record": "17-15"
    },
    "High Point": {
      "adjDef": 104.5,
      "adjDefRank": 206,
      "adjEM": -4.37,
      "adjOff": 100.2,
      "adjOffRank": 217,
      "adjTempo": 64.5,
      "adjTempoRank": 183,
      "conference": "BSth",
      "rank": 218,
      "record": "17-14"
    },
    "Hofstra": {
      "adjDef": 103,
      "adjDefRank": 177,
      "adjEM": -13.42,
      "adjOff": 89.6,
      "adjOffRank": 332,
      "adjTempo": 63.3,
      "adjTempoRank": 228,
      "conference": "CAA",
      "rank": 303,
      "record": "7-25"
    },
    "Holy Cross": {
      "adjDef": 105.5,
      "adjDefRank": 235,
      "adjEM": -6.48,
      "adjOff": 99,
      "adjOffRank": 235,
      "adjTempo": 61.5,
      "adjTempoRank": 293,
      "conference": "Pat",
      "rank": 236,
      "record": "12-18"
    },
    "Houston": {
      "adjDef": 108.8,
      "adjDefRank": 287,
      "adjEM": -0.82,
      "adjOff": 108,
      "adjOffRank": 75,
      "adjTempo": 69,
      "adjTempoRank": 21,
      "conference": "CUSA",
      "rank": 180,
      "record": "20-13"
    },
    "Houston Baptist": {
      "adjDef": 105.3,
      "adjDefRank": 227,
      "adjEM": -17.55,
      "adjOff": 87.7,
      "adjOffRank": 340,
      "adjTempo": 67.2,
      "adjTempoRank": 58,
      "conference": "GWC",
      "rank": 326,
      "record": "14-17"
    },
    "Howard": {
      "adjDef": 102.5,
      "adjDefRank": 164,
      "adjEM": -18.42,
      "adjOff": 84.1,
      "adjOffRank": 346,
      "adjTempo": 61,
      "adjTempoRank": 302,
      "conference": "MEAC",
      "rank": 329,
      "record": "7-24"
    },
    "IPFW": {
      "adjDef": 105.4,
      "adjDefRank": 232,
      "adjEM": -7.22,
      "adjOff": 98.2,
      "adjOffRank": 252,
      "adjTempo": 64.7,
      "adjTempoRank": 171,
      "conference": "Sum",
      "rank": 243,
      "record": "16-17"
    },
    "IUPUI": {
      "adjDef": 118,
      "adjDefRank": 344,
      "adjEM": -20.3,
      "adjOff": 97.7,
      "adjOffRank": 262,
      "adjTempo": 64.2,
      "adjTempoRank": 194,
      "conference": "Sum",
      "rank": 333,
      "record": "6-26"
    },
    "Idaho": {
      "adjDef": 112.8,
      "adjDefRank": 329,
      "adjEM": -2.97,
      "adjOff": 109.9,
      "adjOffRank": 57,
      "adjTempo": 62,
      "adjTempoRank": 279,
      "conference": "WAC",
      "rank": 199,
      "record": "12-18"
    },
    "Idaho St.": {
      "adjDef": 108.1,
      "adjDefRank": 276,
      "adjEM": -16.18,
      "adjOff": 91.9,
      "adjOffRank": 320,
      "adjTempo": 59.3,
      "adjTempoRank": 338,
      "conference": "BSky",
      "rank": 320,
      "record": "6-24"
    },
    "Illinois": {
      "adjDef": 94.8,
      "adjDefRank": 44,
      "adjEM": 15.82,
      "adjOff": 110.6,
      "adjOffRank": 46,
      "adjTempo": 64.6,
      "adjTempoRank": 180,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 39,
      "record": "23-13",
      "seed": 7
    },
    "Illinois Chicago": {
      "adjDef": 99.9,
      "adjDefRank": 125,
      "adjEM": -1.11,
      "adjOff": 98.8,
      "adjOffRank": 244,
      "adjTempo": 63,
      "adjTempoRank": 240,
      "conference": "Horz",
      "rank": 183,
      "record": "18-16"
    },
    "Illinois St.": {
      "adjDef": 98.2,
      "adjDefRank": 89,
      "adjEM": 11.49,
      "adjOff": 109.7,
      "adjOffRank": 61,
      "adjTempo": 68.8,
      "adjTempoRank": 24,
      "conference": "MVC",
      "rank": 63,
      "record": "18-15"
    },
    "Indiana": {
      "adjDef": 91.5,
      "adjDefRank": 19,
      "adjEM": 29.31,
      "adjOff": 120.8,
      "adjOffRank": 2,
      "adjTempo": 66,
      "adjTempoRank": 105,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 3,
      "record": "29-7",
      "seed": 1
    },
    "Indiana St.": {
      "adjDef": 95.7,
      "adjDefRank": 55,
      "adjEM": 7.31,
      "adjOff": 103,
      "adjOffRank": 161,
      "adjTempo": 64,
      "adjTempoRank": 199,
      "conference": "MVC",
      "rank": 92,
      "record": "18-15"
    },
    "Iona": {
      "adjDef": 106.9,
      "adjDefRank": 256,
      "adjEM": 7.45,
      "adjOff": 114.4,
      "adjOffRank": 18,
      "adjTempo": 69.7,
      "adjTempoRank": 10,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 89,
      "record": "20-14",
      "seed": 15
    },
    "Iowa": {
      "adjDef": 91.9,
      "adjDefRank": 24,
      "adjEM": 17.83,
      "adjOff": 109.8,
      "adjOffRank": 58,
      "adjTempo": 66.3,
      "adjTempoRank": 95,
      "conference": "B10",
      "rank": 29,
      "record": "25-13"
    },
    "Iowa St.": {
      "adjDef": 99.8,
      "adjDefRank": 123,
      "adjEM": 17.63,
      "adjOff": 117.5,
      "adjOffRank": 8,
      "adjTempo": 68.3,
      "adjTempoRank": 30,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 30,
      "record": "23-12",
      "seed": 10
    },
    "Jackson St.": {
      "adjDef": 108.8,
      "adjDefRank": 290,
      "adjEM": -14.94,
      "adjOff": 93.9,
      "adjOffRank": 305,
      "adjTempo": 67.4,
      "adjTempoRank": 54,
      "conference": "SWAC",
      "rank": 316,
      "record": "11-18"
    },
    "Jacksonville": {
      "adjDef": 107.9,
      "adjDefRank": 272,
      "adjEM": -12.07,
      "adjOff": 95.8,
      "adjOffRank": 285,
      "adjTempo": 67.7,
      "adjTempoRank": 48,
      "conference": "ASun",
      "rank": 288,
      "record": "14-18"
    },
    "Jacksonville St.": {
      "adjDef": 106,
      "adjDefRank": 246,
      "adjEM": -3.43,
      "adjOff": 102.6,
      "adjOffRank": 172,
      "adjTempo": 60.9,
      "adjTempoRank": 309,
      "conference": "OVC",
      "rank": 204,
      "record": "17-11"
    },
    "James Madison": {
      "adjDef": 101.1,
      "adjDefRank": 141,
      "adjEM": -0.92,
      "adjOff": 100.2,
      "adjOffRank": 218,
      "adjTempo": 62.9,
      "adjTempoRank": 248,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 182,
      "record": "21-15",
      "seed": 16
    },
    "Kansas": {
      "adjDef": 87.5,
      "adjDefRank": 5,
      "adjEM": 25.23,
      "adjOff": 112.7,
      "adjOffRank": 27,
      "adjTempo": 66.4,
      "adjTempoRank": 86,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "31-6",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.3,
      "adjDefRank": 52,
      "adjEM": 18.16,
      "adjOff": 113.5,
      "adjOffRank": 23,
      "adjTempo": 61.4,
      "adjTempoRank": 296,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 27,
      "record": "27-8",
      "seed": 4
    },
    "Kennesaw St.": {
      "adjDef": 112.5,
      "adjDefRank": 325,
      "adjEM": -20.69,
      "adjOff": 91.8,
      "adjOffRank": 323,
      "adjTempo": 63,
      "adjTempoRank": 241,
      "conference": "ASun",
      "rank": 335,
      "record": "3-27"
    },
    "Kent St.": {
      "adjDef": 103.4,
      "adjDefRank": 182,
      "adjEM": 4.43,
      "adjOff": 107.8,
      "adjOffRank": 77,
      "adjTempo": 65.6,
      "adjTempoRank": 127,
      "conference": "MAC",
      "rank": 121,
      "record": "21-14"
    },
    "Kentucky": {
      "adjDef": 98.1,
      "adjDefRank": 88,
      "adjEM": 13.25,
      "adjOff": 111.4,
      "adjOffRank": 38,
      "adjTempo": 65.4,
      "adjTempoRank": 135,
      "conference": "SEC",
      "rank": 55,
      "record": "21-12"
    },
    "LIU Brooklyn": {
      "adjDef": 112.6,
      "adjDefRank": 327,
      "adjEM": -2.13,
      "adjOff": 110.5,
      "adjOffRank": 48,
      "adjTempo": 67.3,
      "adjTempoRank": 57,
      "conference": "NEC",
      "finish": "First Four",
      "rank": 192,
      "record": "20-14",
      "seed": 16
    },
    "LSU": {
      "adjDef": 99.3,
      "adjDefRank": 110,
      "adjEM": 6.38,
      "adjOff": 105.6,
      "adjOffRank": 122,
      "adjTempo": 67.7,
      "adjTempoRank": 46,
      "conference": "SEC",
      "rank": 102,
      "record": "19-12"
    },
    "La Salle": {
      "adjDef": 98,
      "adjDefRank": 87,
      "adjEM": 13.89,
      "adjOff": 111.8,
      "adjOffRank": 34,
      "adjTempo": 64.6,
      "adjTempoRank": 177,
      "conference": "A10",
      "finish": "Sweet 16",
      "rank": 50,
      "record": "24-10",
      "seed": 13
    },
    "Lafayette": {
      "adjDef": 106.2,
      "adjDefRank": 249,
      "adjEM": -0.25,
      "adjOff": 106,
      "adjOffRank": 117,
      "adjTempo": 64.5,
      "adjTempoRank": 184,
      "conference": "Pat",
      "rank": 171,
      "record": "19-15"
    },
    "Lamar": {
      "adjDef": 111.3,
      "adjDefRank": 318,
      "adjEM": -25.4,
      "adjOff": 85.9,
      "adjOffRank": 343,
      "adjTempo": 69,
      "adjTempoRank": 22,
      "conference": "Slnd",
      "rank": 346,
      "record": "3-28"
    },
    "Lehigh": {
      "adjDef": 101.6,
      "adjDefRank": 149,
      "adjEM": 7.32,
      "adjOff": 108.9,
      "adjOffRank": 68,
      "adjTempo": 65.8,
      "adjTempoRank": 115,
      "conference": "Pat",
      "rank": 91,
      "record": "21-10"
    },
    "Liberty": {
      "adjDef": 110.6,
      "adjDefRank": 312,
      "adjEM": -9.38,
      "adjOff": 101.3,
      "adjOffRank": 196,
      "adjTempo": 64.4,
      "adjTempoRank": 186,
      "conference": "BSth",
      "finish": "First Four",
      "rank": 266,
      "record": "15-21",
      "seed": 16
    },
    "Lipscomb": {
      "adjDef": 110.2,
      "adjDefRank": 305,
      "adjEM": -12.16,
      "adjOff": 98,
      "adjOffRank": 253,
      "adjTempo": 69.4,
      "adjTempoRank": 13,
      "conference": "ASun",
      "rank": 289,
      "record": "12-18"
    },
    "Long Beach St.": {
      "adjDef": 105.1,
      "adjDefRank": 220,
      "adjEM": 0.62,
      "adjOff": 105.7,
      "adjOffRank": 120,
      "adjTempo": 65.7,
      "adjTempoRank": 120,
      "conference": "BW",
      "rank": 157,
      "record": "19-14"
    },
    "Longwood": {
      "adjDef": 116.2,
      "adjDefRank": 341,
      "adjEM": -21.61,
      "adjOff": 94.6,
      "adjOffRank": 298,
      "adjTempo": 69.2,
      "adjTempoRank": 17,
      "conference": "BSth",
      "rank": 338,
      "record": "8-25"
    },
    "Louisiana Lafayette": {
      "adjDef": 106.4,
      "adjDefRank": 251,
      "adjEM": -5.73,
      "adjOff": 100.6,
      "adjOffRank": 208,
      "adjTempo": 68.6,
      "adjTempoRank": 27,
      "conference": "SB",
      "rank": 229,
      "record": "13-20"
    },
    "Louisiana Monroe": {
      "adjDef": 110.4,
      "adjDefRank": 310,
      "adjEM": -18.03,
      "adjOff": 92.4,
      "adjOffRank": 317,
      "adjTempo": 65,
      "adjTempoRank": 152,
      "conference": "SB",
      "rank": 327,
      "record": "4-23"
    },
    "Louisiana Tech": {
      "adjDef": 93.7,
      "adjDefRank": 35,
      "adjEM": 8.05,
      "adjOff": 101.8,
      "adjOffRank": 187,
      "adjTempo": 68.7,
      "adjTempoRank": 25,
      "conference": "WAC",
      "rank": 85,
      "record": "27-7"
    },
    "Louisville": {
      "adjDef": 84.8,
      "adjDefRank": 1,
      "adjEM": 32.92,
      "adjOff": 117.7,
      "adjOffRank": 7,
      "adjTempo": 65.8,
      "adjTempoRank": 116,
      "conference": "BE",
      "finish": "Champion",
      "rank": 1,
      "record": "35-5",
      "seed": 1
    },
    "Loyola Chicago": {
      "adjDef": 103.1,
      "adjDefRank": 180,
      "adjEM": 0.12,
      "adjOff": 103.2,
      "adjOffRank": 156,
      "adjTempo": 61.8,
      "adjTempoRank": 284,
      "conference": "Horz",
      "rank": 166,
      "record": "15-16"
    },
    "Loyola MD": {
      "adjDef": 100.4,
      "adjDefRank": 132,
      "adjEM": 4.13,
      "adjOff": 104.6,
      "adjOffRank": 140,
      "adjTempo": 62.7,
      "adjTempoRank": 254,
      "conference": "MAAC",
      "rank": 124,
      "record": "23-12"
    },
    "Loyola Marymount": {
      "adjDef": 101.9,
      "adjDefRank": 153,
      "adjEM": -3.44,
      "adjOff": 98.4,
      "adjOffRank": 249,
      "adjTempo": 63.4,
      "adjTempoRank": 224,
      "conference": "WCC",
      "rank": 205,
      "record": "11-23"
    },
    "Maine": {
      "adjDef": 106.1,
      "adjDefRank": 247,
      "adjEM": -8.8,
      "adjOff": 97.3,
      "adjOffRank": 268,
      "adjTempo": 67.8,
      "adjTempoRank": 44,
      "conference": "AE",
      "rank": 262,
      "record": "11-19"
    },
    "Manhattan": {
      "adjDef": 94.4,
      "adjDefRank": 41,
      "adjEM": 0.9,
      "adjOff": 95.3,
      "adjOffRank": 290,
      "adjTempo": 60.7,
      "adjTempoRank": 311,
      "conference": "MAAC",
      "rank": 154,
      "record": "14-18"
    },
    "Marist": {
      "adjDef": 105.4,
      "adjDefRank": 231,
      "adjEM": -6.6,
      "adjOff": 98.8,
      "adjOffRank": 243,
      "adjTempo": 66.8,
      "adjTempoRank": 68,
      "conference": "MAAC",
      "rank": 238,
      "record": "10-21"
    },
    "Marquette": {
      "adjDef": 94.9,
      "adjDefRank": 45,
      "adjEM": 18.32,
      "adjOff": 113.2,
      "adjOffRank": 24,
      "adjTempo": 63.3,
      "adjTempoRank": 230,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 26,
      "record": "26-9",
      "seed": 3
    },
    "Marshall": {
      "adjDef": 106.6,
      "adjDefRank": 254,
      "adjEM": -4.35,
      "adjOff": 102.3,
      "adjOffRank": 179,
      "adjTempo": 66.4,
      "adjTempoRank": 89,
      "conference": "CUSA",
      "rank": 217,
      "record": "13-19"
    },
    "Maryland": {
      "adjDef": 93.4,
      "adjDefRank": 31,
      "adjEM": 13.62,
      "adjOff": 107.1,
      "adjOffRank": 96,
      "adjTempo": 66.2,
      "adjTempoRank": 97,
      "conference": "ACC",
      "rank": 52,
      "record": "25-13"
    },
    "Maryland Eastern Shore": {
      "adjDef": 111.3,
      "adjDefRank": 319,
      "adjEM": -24.68,
      "adjOff": 86.6,
      "adjOffRank": 341,
      "adjTempo": 62.6,
      "adjTempoRank": 259,
      "conference": "MEAC",
      "rank": 344,
      "record": "2-26"
    },
    "Massachusetts": {
      "adjDef": 99.5,
      "adjDefRank": 114,
      "adjEM": 7.91,
      "adjOff": 107.4,
      "adjOffRank": 84,
      "adjTempo": 69.8,
      "adjTempoRank": 8,
      "conference": "A10",
      "rank": 86,
      "record": "21-12"
    },
    "McNeese St.": {
      "adjDef": 108.2,
      "adjDefRank": 278,
      "adjEM": -12.23,
      "adjOff": 96,
      "adjOffRank": 283,
      "adjTempo": 62.9,
      "adjTempoRank": 245,
      "conference": "Slnd",
      "rank": 291,
      "record": "14-16"
    },
    "Memphis": {
      "adjDef": 91,
      "adjDefRank": 17,
      "adjEM": 16.33,
      "adjOff": 107.3,
      "adjOffRank": 85,
      "adjTempo": 68.3,
      "adjTempoRank": 31,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 37,
      "record": "31-5",
      "seed": 6
    },
    "Mercer": {
      "adjDef": 100.1,
      "adjDefRank": 128,
      "adjEM": 3.96,
      "adjOff": 104,
      "adjOffRank": 148,
      "adjTempo": 61.3,
      "adjTempoRank": 298,
      "conference": "ASun",
      "rank": 127,
      "record": "24-12"
    },
    "Miami FL": {
      "adjDef": 91.3,
      "adjDefRank": 18,
      "adjEM": 22.37,
      "adjOff": 113.6,
      "adjOffRank": 22,
      "adjTempo": 61.9,
      "adjTempoRank": 281,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "29-7",
      "seed": 2
    },
    "Miami OH": {
      "adjDef": 105.7,
      "adjDefRank": 241,
      "adjEM": -7.03,
      "adjOff": 98.7,
      "adjOffRank": 245,
      "adjTempo": 64.1,
      "adjTempoRank": 195,
      "conference": "MAC",
      "rank": 241,
      "record": "9-22"
    },
    "Michigan": {
      "adjDef": 94,
      "adjDefRank": 37,
      "adjEM": 27.86,
      "adjOff": 121.9,
      "adjOffRank": 1,
      "adjTempo": 64,
      "adjTempoRank": 198,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 4,
      "record": "31-8",
      "seed": 4
    },
    "Michigan St.": {
      "adjDef": 88.5,
      "adjDefRank": 7,
      "adjEM": 24.17,
      "adjOff": 112.6,
      "adjOffRank": 29,
      "adjTempo": 63.1,
      "adjTempoRank": 238,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "27-9",
      "seed": 3
    },
    "Middle Tennessee": {
      "adjDef": 92.5,
      "adjDefRank": 28,
      "adjEM": 14.77,
      "adjOff": 107.3,
      "adjOffRank": 89,
      "adjTempo": 63.4,
      "adjTempoRank": 223,
      "conference": "SB",
      "finish": "First Four",
      "rank": 44,
      "record": "28-6",
      "seed": 11
    },
    "Milwaukee": {
      "adjDef": 108.6,
      "adjDefRank": 283,
      "adjEM": -13.34,
      "adjOff": 95.2,
      "adjOffRank": 293,
      "adjTempo": 64.6,
      "adjTempoRank": 175,
      "conference": "Horz",
      "rank": 301,
      "record": "8-24"
    },
    "Minnesota": {
      "adjDef": 95,
      "adjDefRank": 46,
      "adjEM": 19.85,
      "adjOff": 114.8,
      "adjOffRank": 16,
      "adjTempo": 62.3,
      "adjTempoRank": 274,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 20,
      "record": "21-13",
      "seed": 11
    },
    "Mississippi": {
      "adjDef": 95.3,
      "adjDefRank": 51,
      "adjEM": 16.95,
      "adjOff": 112.3,
      "adjOffRank": 30,
      "adjTempo": 69.2,
      "adjTempoRank": 19,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 34,
      "record": "27-9",
      "seed": 12
    },
    "Mississippi St.": {
      "adjDef": 99.9,
      "adjDefRank": 124,
      "adjEM": -8.01,
      "adjOff": 91.8,
      "adjOffRank": 322,
      "adjTempo": 66.8,
      "adjTempoRank": 67,
      "conference": "SEC",
      "rank": 256,
      "record": "10-22"
    },
    "Mississippi Valley St.": {
      "adjDef": 112.1,
      "adjDefRank": 324,
      "adjEM": -22.01,
      "adjOff": 90.1,
      "adjOffRank": 330,
      "adjTempo": 69.4,
      "adjTempoRank": 15,
      "conference": "SWAC",
      "rank": 339,
      "record": "5-23"
    },
    "Missouri": {
      "adjDef": 96.4,
      "adjDefRank": 63,
      "adjEM": 18.53,
      "adjOff": 114.9,
      "adjOffRank": 15,
      "adjTempo": 66.3,
      "adjTempoRank": 93,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 25,
      "record": "23-11",
      "seed": 9
    },
    "Missouri St.": {
      "adjDef": 104.8,
      "adjDefRank": 214,
      "adjEM": -3.82,
      "adjOff": 101,
      "adjOffRank": 202,
      "adjTempo": 59.6,
      "adjTempoRank": 330,
      "conference": "MVC",
      "rank": 212,
      "record": "11-22"
    },
    "Monmouth": {
      "adjDef": 105.6,
      "adjDefRank": 238,
      "adjEM": -13.9,
      "adjOff": 91.7,
      "adjOffRank": 325,
      "adjTempo": 68.4,
      "adjTempoRank": 28,
      "conference": "NEC",
      "rank": 309,
      "record": "10-21"
    },
    "Montana": {
      "adjDef": 102.8,
      "adjDefRank": 173,
      "adjEM": 2.14,
      "adjOff": 104.9,
      "adjOffRank": 134,
      "adjTempo": 62.7,
      "adjTempoRank": 253,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 145,
      "record": "25-7",
      "seed": 13
    },
    "Montana St.": {
      "adjDef": 113.8,
      "adjDefRank": 336,
      "adjEM": -13.38,
      "adjOff": 100.4,
      "adjOffRank": 213,
      "adjTempo": 64.8,
      "adjTempoRank": 168,
      "conference": "BSky",
      "rank": 302,
      "record": "13-17"
    },
    "Morehead St.": {
      "adjDef": 104.6,
      "adjDefRank": 207,
      "adjEM": -5.04,
      "adjOff": 99.6,
      "adjOffRank": 224,
      "adjTempo": 68.2,
      "adjTempoRank": 33,
      "conference": "OVC",
      "rank": 225,
      "record": "15-18"
    },
    "Morgan St.": {
      "adjDef": 102.6,
      "adjDefRank": 166,
      "adjEM": -3.19,
      "adjOff": 99.4,
      "adjOffRank": 229,
      "adjTempo": 65.9,
      "adjTempoRank": 110,
      "conference": "MEAC",
      "rank": 202,
      "record": "16-15"
    },
    "Mount St. Mary's": {
      "adjDef": 106.3,
      "adjDefRank": 250,
      "adjEM": -3.78,
      "adjOff": 102.5,
      "adjOffRank": 175,
      "adjTempo": 66,
      "adjTempoRank": 107,
      "conference": "NEC",
      "rank": 211,
      "record": "18-14"
    },
    "Murray St.": {
      "adjDef": 102.3,
      "adjDefRank": 161,
      "adjEM": 3.37,
      "adjOff": 105.6,
      "adjOffRank": 123,
      "adjTempo": 64.9,
      "adjTempoRank": 157,
      "conference": "OVC",
      "rank": 131,
      "record": "21-10"
    },
    "N.C. State": {
      "adjDef": 99.8,
      "adjDefRank": 122,
      "adjEM": 16.99,
      "adjOff": 116.8,
      "adjOffRank": 10,
      "adjTempo": 66.8,
      "adjTempoRank": 69,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "24-11",
      "seed": 8
    },
    "NJIT": {
      "adjDef": 103.4,
      "adjDefRank": 185,
      "adjEM": -8.82,
      "adjOff": 94.6,
      "adjOffRank": 297,
      "adjTempo": 65.9,
      "adjTempoRank": 109,
      "conference": "GWC",
      "rank": 263,
      "record": "16-13"
    },
    "Navy": {
      "adjDef": 105.2,
      "adjDefRank": 221,
      "adjEM": -15.2,
      "adjOff": 90,
      "adjOffRank": 331,
      "adjTempo": 59.4,
      "adjTempoRank": 334,
      "conference": "Pat",
      "rank": 317,
      "record": "8-23"
    },
    "Nebraska": {
      "adjDef": 99.1,
      "adjDefRank": 107,
      "adjEM": 3.03,
      "adjOff": 102.1,
      "adjOffRank": 181,
      "adjTempo": 60.5,
      "adjTempoRank": 318,
      "conference": "B10",
      "rank": 136,
      "record": "15-18"
    },
    "Nebraska Omaha": {
      "adjDef": 118.1,
      "adjDefRank": 345,
      "adjEM": -16.33,
      "adjOff": 101.8,
      "adjOffRank": 185,
      "adjTempo": 70.9,
      "adjTempoRank": 3,
      "conference": "Sum",
      "rank": 321,
      "record": "11-20"
    },
    "Nevada": {
      "adjDef": 105.4,
      "adjDefRank": 233,
      "adjEM": -0.28,
      "adjOff": 105.1,
      "adjOffRank": 131,
      "adjTempo": 65.5,
      "adjTempoRank": 130,
      "conference": "MWC",
      "rank": 172,
      "record": "12-19"
    },
    "New Hampshire": {
      "adjDef": 102.2,
      "adjDefRank": 159,
      "adjEM": -9.41,
      "adjOff": 92.8,
      "adjOffRank": 315,
      "adjTempo": 62.9,
      "adjTempoRank": 249,
      "conference": "AE",
      "rank": 267,
      "record": "9-20"
    },
    "New Mexico": {
      "adjDef": 90.9,
      "adjDefRank": 16,
      "adjEM": 19.02,
      "adjOff": 109.9,
      "adjOffRank": 53,
      "adjTempo": 63.7,
      "adjTempoRank": 214,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "29-6",
      "seed": 3
    },
    "New Mexico St.": {
      "adjDef": 97.1,
      "adjDefRank": 76,
      "adjEM": 7.68,
      "adjOff": 104.7,
      "adjOffRank": 137,
      "adjTempo": 63.8,
      "adjTempoRank": 212,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 88,
      "record": "24-11",
      "seed": 13
    },
    "New Orleans": {
      "adjDef": 113.2,
      "adjDefRank": 332,
      "adjEM": -25.21,
      "adjOff": 87.9,
      "adjOffRank": 339,
      "adjTempo": 69.4,
      "adjTempoRank": 14,
      "conference": "ind",
      "rank": 345,
      "record": "8-18"
    },
    "Niagara": {
      "adjDef": 104.2,
      "adjDefRank": 202,
      "adjEM": 2.81,
      "adjOff": 107,
      "adjOffRank": 97,
      "adjTempo": 67.7,
      "adjTempoRank": 47,
      "conference": "MAAC",
      "rank": 139,
      "record": "19-14"
    },
    "Nicholls St.": {
      "adjDef": 110.8,
      "adjDefRank": 314,
      "adjEM": -12.17,
      "adjOff": 98.6,
      "adjOffRank": 247,
      "adjTempo": 66.1,
      "adjTempoRank": 103,
      "conference": "Slnd",
      "rank": 290,
      "record": "9-21"
    },
    "Norfolk St.": {
      "adjDef": 100.6,
      "adjDefRank": 135,
      "adjEM": -4.26,
      "adjOff": 96.4,
      "adjOffRank": 281,
      "adjTempo": 67.1,
      "adjTempoRank": 60,
      "conference": "MEAC",
      "rank": 216,
      "record": "21-12"
    },
    "North Carolina": {
      "adjDef": 95.1,
      "adjDefRank": 47,
      "adjEM": 17.14,
      "adjOff": 112.2,
      "adjOffRank": 31,
      "adjTempo": 69.6,
      "adjTempoRank": 12,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 32,
      "record": "25-11",
      "seed": 8
    },
    "North Carolina A&T": {
      "adjDef": 98.5,
      "adjDefRank": 96,
      "adjEM": -5.3,
      "adjOff": 93.2,
      "adjOffRank": 311,
      "adjTempo": 65.5,
      "adjTempoRank": 134,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 227,
      "record": "20-17",
      "seed": 16
    },
    "North Carolina Central": {
      "adjDef": 99,
      "adjDefRank": 106,
      "adjEM": 0.32,
      "adjOff": 99.3,
      "adjOffRank": 230,
      "adjTempo": 62.8,
      "adjTempoRank": 252,
      "conference": "MEAC",
      "rank": 164,
      "record": "22-9"
    },
    "North Dakota": {
      "adjDef": 105,
      "adjDefRank": 219,
      "adjEM": -7.78,
      "adjOff": 97.2,
      "adjOffRank": 269,
      "adjTempo": 66.4,
      "adjTempoRank": 90,
      "conference": "BSky",
      "rank": 250,
      "record": "16-17"
    },
    "North Dakota St.": {
      "adjDef": 95.4,
      "adjDefRank": 53,
      "adjEM": 10.03,
      "adjOff": 105.5,
      "adjOffRank": 125,
      "adjTempo": 59.6,
      "adjTempoRank": 329,
      "conference": "Sum",
      "rank": 75,
      "record": "24-10"
    },
    "North Florida": {
      "adjDef": 108.5,
      "adjDefRank": 281,
      "adjEM": -7.51,
      "adjOff": 101,
      "adjOffRank": 203,
      "adjTempo": 64.8,
      "adjTempoRank": 170,
      "conference": "ASun",
      "rank": 248,
      "record": "13-19"
    },
    "North Texas": {
      "adjDef": 105.5,
      "adjDefRank": 237,
      "adjEM": -8.3,
      "adjOff": 97.2,
      "adjOffRank": 270,
      "adjTempo": 65.3,
      "adjTempoRank": 138,
      "conference": "SB",
      "rank": 259,
      "record": "12-20"
    },
    "Northeastern": {
      "adjDef": 107.2,
      "adjDefRank": 260,
      "adjEM": -0.76,
      "adjOff": 106.4,
      "adjOffRank": 111,
      "adjTempo": 62.7,
      "adjTempoRank": 257,
      "conference": "CAA",
      "rank": 178,
      "record": "20-13"
    },
    "Northern Arizona": {
      "adjDef": 109.3,
      "adjDefRank": 297,
      "adjEM": -12.39,
      "adjOff": 96.9,
      "adjOffRank": 278,
      "adjTempo": 66.7,
      "adjTempoRank": 73,
      "conference": "BSky",
      "rank": 292,
      "record": "11-21"
    },
    "Northern Colorado": {
      "adjDef": 109.8,
      "adjDefRank": 300,
      "adjEM": -7.09,
      "adjOff": 102.8,
      "adjOffRank": 166,
      "adjTempo": 64.9,
      "adjTempoRank": 158,
      "conference": "BSky",
      "rank": 242,
      "record": "13-18"
    },
    "Northern Illinois": {
      "adjDef": 102.4,
      "adjDefRank": 163,
      "adjEM": -16.72,
      "adjOff": 85.7,
      "adjOffRank": 344,
      "adjTempo": 61.1,
      "adjTempoRank": 301,
      "conference": "MAC",
      "rank": 322,
      "record": "5-25"
    },
    "Northern Iowa": {
      "adjDef": 96,
      "adjDefRank": 61,
      "adjEM": 10.84,
      "adjOff": 106.8,
      "adjOffRank": 100,
      "adjTempo": 61.8,
      "adjTempoRank": 288,
      "conference": "MVC",
      "rank": 68,
      "record": "21-15"
    },
    "Northern Kentucky": {
      "adjDef": 104.7,
      "adjDefRank": 212,
      "adjEM": -7.26,
      "adjOff": 97.5,
      "adjOffRank": 266,
      "adjTempo": 58.1,
      "adjTempoRank": 345,
      "conference": "ASun",
      "rank": 244,
      "record": "11-16"
    },
    "Northwestern": {
      "adjDef": 101.2,
      "adjDefRank": 143,
      "adjEM": 3.27,
      "adjOff": 104.5,
      "adjOffRank": 141,
      "adjTempo": 60,
      "adjTempoRank": 323,
      "conference": "B10",
      "rank": 132,
      "record": "13-19"
    },
    "Northwestern St.": {
      "adjDef": 103.8,
      "adjDefRank": 195,
      "adjEM": 2.82,
      "adjOff": 106.6,
      "adjOffRank": 106,
      "adjTempo": 71.9,
      "adjTempoRank": 1,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 138,
      "record": "23-9",
      "seed": 14
    },
    "Notre Dame": {
      "adjDef": 97.9,
      "adjDefRank": 86,
      "adjEM": 16.65,
      "adjOff": 114.6,
      "adjOffRank": 17,
      "adjTempo": 60.3,
      "adjTempoRank": 320,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 36,
      "record": "25-10",
      "seed": 7
    },
    "Oakland": {
      "adjDef": 110.4,
      "adjDefRank": 309,
      "adjEM": -3.09,
      "adjOff": 107.3,
      "adjOffRank": 86,
      "adjTempo": 68.1,
      "adjTempoRank": 34,
      "conference": "Sum",
      "rank": 200,
      "record": "16-17"
    },
    "Ohio": {
      "adjDef": 97.5,
      "adjDefRank": 84,
      "adjEM": 9.06,
      "adjOff": 106.6,
      "adjOffRank": 107,
      "adjTempo": 66.5,
      "adjTempoRank": 83,
      "conference": "MAC",
      "rank": 82,
      "record": "24-10"
    },
    "Ohio St.": {
      "adjDef": 89.7,
      "adjDefRank": 9,
      "adjEM": 25.42,
      "adjOff": 115.1,
      "adjOffRank": 14,
      "adjTempo": 63.6,
      "adjTempoRank": 218,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 7,
      "record": "29-8",
      "seed": 2
    },
    "Oklahoma": {
      "adjDef": 97.3,
      "adjDefRank": 80,
      "adjEM": 13.8,
      "adjOff": 111.1,
      "adjOffRank": 41,
      "adjTempo": 65.2,
      "adjTempoRank": 141,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 51,
      "record": "20-12",
      "seed": 10
    },
    "Oklahoma St.": {
      "adjDef": 90.3,
      "adjDefRank": 14,
      "adjEM": 18.69,
      "adjOff": 109,
      "adjOffRank": 66,
      "adjTempo": 66.1,
      "adjTempoRank": 104,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 24,
      "record": "24-9",
      "seed": 5
    },
    "Old Dominion": {
      "adjDef": 109.1,
      "adjDefRank": 296,
      "adjEM": -10.49,
      "adjOff": 98.6,
      "adjOffRank": 246,
      "adjTempo": 65.3,
      "adjTempoRank": 136,
      "conference": "CAA",
      "rank": 276,
      "record": "5-25"
    },
    "Oral Roberts": {
      "adjDef": 104.4,
      "adjDefRank": 205,
      "adjEM": 2.23,
      "adjOff": 106.6,
      "adjOffRank": 104,
      "adjTempo": 64.9,
      "adjTempoRank": 163,
      "conference": "Slnd",
      "rank": 144,
      "record": "20-15"
    },
    "Oregon": {
      "adjDef": 89.8,
      "adjDefRank": 11,
      "adjEM": 16.78,
      "adjOff": 106.6,
      "adjOffRank": 105,
      "adjTempo": 67.9,
      "adjTempoRank": 37,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 35,
      "record": "28-9",
      "seed": 12
    },
    "Oregon St.": {
      "adjDef": 102.7,
      "adjDefRank": 169,
      "adjEM": 6.77,
      "adjOff": 109.4,
      "adjOffRank": 62,
      "adjTempo": 67.1,
      "adjTempoRank": 59,
      "conference": "P12",
      "rank": 97,
      "record": "14-18"
    },
    "Pacific": {
      "adjDef": 102.2,
      "adjDefRank": 158,
      "adjEM": 3.96,
      "adjOff": 106.1,
      "adjOffRank": 114,
      "adjTempo": 62.2,
      "adjTempoRank": 275,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 128,
      "record": "22-13",
      "seed": 15
    },
    "Penn": {
      "adjDef": 102.6,
      "adjDefRank": 168,
      "adjEM": -7.74,
      "adjOff": 94.9,
      "adjOffRank": 296,
      "adjTempo": 66.7,
      "adjTempoRank": 74,
      "conference": "Ivy",
      "rank": 249,
      "record": "9-22"
    },
    "Penn St.": {
      "adjDef": 101.5,
      "adjDefRank": 148,
      "adjEM": 0.57,
      "adjOff": 102.1,
      "adjOffRank": 182,
      "adjTempo": 64,
      "adjTempoRank": 200,
      "conference": "B10",
      "rank": 161,
      "record": "10-21"
    },
    "Pepperdine": {
      "adjDef": 103.6,
      "adjDefRank": 190,
      "adjEM": -4.77,
      "adjOff": 98.9,
      "adjOffRank": 236,
      "adjTempo": 62.2,
      "adjTempoRank": 276,
      "conference": "WCC",
      "rank": 221,
      "record": "12-18"
    },
    "Pittsburgh": {
      "adjDef": 91.5,
      "adjDefRank": 21,
      "adjEM": 24.08,
      "adjOff": 115.6,
      "adjOffRank": 12,
      "adjTempo": 59.3,
      "adjTempoRank": 337,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 11,
      "record": "24-9",
      "seed": 8
    },
    "Portland": {
      "adjDef": 103.4,
      "adjDefRank": 183,
      "adjEM": -8.18,
      "adjOff": 95.2,
      "adjOffRank": 292,
      "adjTempo": 63.6,
      "adjTempoRank": 219,
      "conference": "WCC",
      "rank": 258,
      "record": "11-21"
    },
    "Portland St.": {
      "adjDef": 117,
      "adjDefRank": 342,
      "adjEM": -13.47,
      "adjOff": 103.5,
      "adjOffRank": 153,
      "adjTempo": 63.9,
      "adjTempoRank": 204,
      "conference": "BSky",
      "rank": 304,
      "record": "8-20"
    },
    "Prairie View A&M": {
      "adjDef": 105.3,
      "adjDefRank": 226,
      "adjEM": -17.29,
      "adjOff": 88,
      "adjOffRank": 338,
      "adjTempo": 66,
      "adjTempoRank": 106,
      "conference": "SWAC",
      "rank": 324,
      "record": "15-19"
    },
    "Presbyterian": {
      "adjDef": 117.8,
      "adjDefRank": 343,
      "adjEM": -22.92,
      "adjOff": 94.9,
      "adjOffRank": 295,
      "adjTempo": 60.9,
      "adjTempoRank": 305,
      "conference": "BSth",
      "rank": 341,
      "record": "8-24"
    },
    "Princeton": {
      "adjDef": 100.9,
      "adjDefRank": 139,
      "adjEM": 7.88,
      "adjOff": 108.8,
      "adjOffRank": 70,
      "adjTempo": 59.4,
      "adjTempoRank": 335,
      "conference": "Ivy",
      "rank": 87,
      "record": "17-11"
    },
    "Providence": {
      "adjDef": 97.2,
      "adjDefRank": 78,
      "adjEM": 10.75,
      "adjOff": 107.9,
      "adjOffRank": 76,
      "adjTempo": 64.9,
      "adjTempoRank": 160,
      "conference": "BE",
      "rank": 69,
      "record": "19-15"
    },
    "Purdue": {
      "adjDef": 96.6,
      "adjDefRank": 70,
      "adjEM": 10.33,
      "adjOff": 107,
      "adjOffRank": 98,
      "adjTempo": 65.6,
      "adjTempoRank": 125,
      "conference": "B10",
      "rank": 74,
      "record": "16-18"
    },
    "Quinnipiac": {
      "adjDef": 105.9,
      "adjDefRank": 243,
      "adjEM": -3.54,
      "adjOff": 102.3,
      "adjOffRank": 177,
      "adjTempo": 65.3,
      "adjTempoRank": 139,
      "conference": "NEC",
      "rank": 207,
      "record": "15-16"
    },
    "Radford": {
      "adjDef": 104,
      "adjDefRank": 199,
      "adjEM": -12.66,
      "adjOff": 91.3,
      "adjOffRank": 326,
      "adjTempo": 66.1,
      "adjTempoRank": 101,
      "conference": "BSth",
      "rank": 296,
      "record": "13-19"
    },
    "Rhode Island": {
      "adjDef": 102.8,
      "adjDefRank": 172,
      "adjEM": -2.24,
      "adjOff": 100.5,
      "adjOffRank": 209,
      "adjTempo": 61.6,
      "adjTempoRank": 292,
      "conference": "A10",
      "rank": 193,
      "record": "8-21"
    },
    "Rice": {
      "adjDef": 113.4,
      "adjDefRank": 334,
      "adjEM": -17.45,
      "adjOff": 96,
      "adjOffRank": 284,
      "adjTempo": 61.5,
      "adjTempoRank": 295,
      "conference": "CUSA",
      "rank": 325,
      "record": "5-26"
    },
    "Richmond": {
      "adjDef": 102,
      "adjDefRank": 154,
      "adjEM": 8.08,
      "adjOff": 110,
      "adjOffRank": 51,
      "adjTempo": 62.4,
      "adjTempoRank": 270,
      "conference": "A10",
      "rank": 83,
      "record": "19-15"
    },
    "Rider": {
      "adjDef": 98.2,
      "adjDefRank": 91,
      "adjEM": 0.59,
      "adjOff": 98.8,
      "adjOffRank": 241,
      "adjTempo": 64.6,
      "adjTempoRank": 178,
      "conference": "MAAC",
      "rank": 160,
      "record": "19-15"
    },
    "Robert Morris": {
      "adjDef": 102.9,
      "adjDefRank": 174,
      "adjEM": 2.28,
      "adjOff": 105.1,
      "adjOffRank": 130,
      "adjTempo": 63.3,
      "adjTempoRank": 226,
      "conference": "NEC",
      "rank": 142,
      "record": "24-11"
    },
    "Rutgers": {
      "adjDef": 101.4,
      "adjDefRank": 147,
      "adjEM": 6.07,
      "adjOff": 107.5,
      "adjOffRank": 82,
      "adjTempo": 63.1,
      "adjTempoRank": 237,
      "conference": "BE",
      "rank": 106,
      "record": "15-16"
    },
    "SIU Edwardsville": {
      "adjDef": 106.6,
      "adjDefRank": 253,
      "adjEM": -13.72,
      "adjOff": 92.9,
      "adjOffRank": 313,
      "adjTempo": 64.9,
      "adjTempoRank": 162,
      "conference": "OVC",
      "rank": 307,
      "record": "9-18"
    },
    "SMU": {
      "adjDef": 102.1,
      "adjDefRank": 156,
      "adjEM": -1.25,
      "adjOff": 100.8,
      "adjOffRank": 206,
      "adjTempo": 62.4,
      "adjTempoRank": 269,
      "conference": "CUSA",
      "rank": 185,
      "record": "15-17"
    },
    "Sacramento St.": {
      "adjDef": 107.9,
      "adjDefRank": 270,
      "adjEM": -10.81,
      "adjOff": 97,
      "adjOffRank": 276,
      "adjTempo": 61.3,
      "adjTempoRank": 299,
      "conference": "BSky",
      "rank": 279,
      "record": "14-15"
    },
    "Sacred Heart": {
      "adjDef": 111.1,
      "adjDefRank": 317,
      "adjEM": -9.66,
      "adjOff": 101.5,
      "adjOffRank": 193,
      "adjTempo": 66.9,
      "adjTempoRank": 64,
      "conference": "NEC",
      "rank": 268,
      "record": "9-20"
    },
    "Saint Joseph's": {
      "adjDef": 99.6,
      "adjDefRank": 117,
      "adjEM": 9.35,
      "adjOff": 109,
      "adjOffRank": 67,
      "adjTempo": 61.6,
      "adjTempoRank": 290,
      "conference": "A10",
      "rank": 79,
      "record": "18-14"
    },
    "Saint Louis": {
      "adjDef": 88.6,
      "adjDefRank": 8,
      "adjEM": 20.01,
      "adjOff": 108.6,
      "adjOffRank": 71,
      "adjTempo": 63.8,
      "adjTempoRank": 213,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 19,
      "record": "28-7",
      "seed": 4
    },
    "Saint Mary's": {
      "adjDef": 96.8,
      "adjDefRank": 73,
      "adjEM": 19.33,
      "adjOff": 116.1,
      "adjOffRank": 11,
      "adjTempo": 62.9,
      "adjTempoRank": 246,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 21,
      "record": "28-7",
      "seed": 11
    },
    "Saint Peter's": {
      "adjDef": 103.9,
      "adjDefRank": 197,
      "adjEM": -11.07,
      "adjOff": 92.8,
      "adjOffRank": 314,
      "adjTempo": 62.7,
      "adjTempoRank": 256,
      "conference": "MAAC",
      "rank": 280,
      "record": "9-21"
    },
    "Sam Houston St.": {
      "adjDef": 99.5,
      "adjDefRank": 115,
      "adjEM": -5.46,
      "adjOff": 94,
      "adjOffRank": 300,
      "adjTempo": 61.3,
      "adjTempoRank": 297,
      "conference": "Slnd",
      "rank": 228,
      "record": "17-17"
    },
    "Samford": {
      "adjDef": 110.3,
      "adjDefRank": 307,
      "adjEM": -12.41,
      "adjOff": 97.9,
      "adjOffRank": 259,
      "adjTempo": 58.8,
      "adjTempoRank": 340,
      "conference": "SC",
      "rank": 293,
      "record": "11-21"
    },
    "San Diego": {
      "adjDef": 103,
      "adjDefRank": 176,
      "adjEM": -0.14,
      "adjOff": 102.8,
      "adjOffRank": 164,
      "adjTempo": 62.5,
      "adjTempoRank": 268,
      "conference": "WCC",
      "rank": 170,
      "record": "16-18"
    },
    "San Diego St.": {
      "adjDef": 90.3,
      "adjDefRank": 13,
      "adjEM": 17.43,
      "adjOff": 107.7,
      "adjOffRank": 78,
      "adjTempo": 65.5,
      "adjTempoRank": 132,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 31,
      "record": "23-11",
      "seed": 7
    },
    "San Francisco": {
      "adjDef": 104,
      "adjDefRank": 198,
      "adjEM": 3.13,
      "adjOff": 107.1,
      "adjOffRank": 95,
      "adjTempo": 65.5,
      "adjTempoRank": 131,
      "conference": "WCC",
      "rank": 134,
      "record": "15-16"
    },
    "San Jose St.": {
      "adjDef": 104.3,
      "adjDefRank": 203,
      "adjEM": -13.5,
      "adjOff": 90.8,
      "adjOffRank": 329,
      "adjTempo": 65.8,
      "adjTempoRank": 119,
      "conference": "WAC",
      "rank": 305,
      "record": "9-20"
    },
    "Santa Clara": {
      "adjDef": 101.2,
      "adjDefRank": 142,
      "adjEM": 9.72,
      "adjOff": 110.9,
      "adjOffRank": 43,
      "adjTempo": 67.5,
      "adjTempoRank": 52,
      "conference": "WCC",
      "rank": 76,
      "record": "26-12"
    },
    "Savannah St.": {
      "adjDef": 91.8,
      "adjDefRank": 22,
      "adjEM": -3.58,
      "adjOff": 88.2,
      "adjOffRank": 337,
      "adjTempo": 61.8,
      "adjTempoRank": 286,
      "conference": "MEAC",
      "rank": 208,
      "record": "19-15"
    },
    "Seattle": {
      "adjDef": 99.7,
      "adjDefRank": 120,
      "adjEM": -5.73,
      "adjOff": 94,
      "adjOffRank": 304,
      "adjTempo": 68.2,
      "adjTempoRank": 32,
      "conference": "WAC",
      "rank": 230,
      "record": "8-22"
    },
    "Seton Hall": {
      "adjDef": 99.4,
      "adjDefRank": 112,
      "adjEM": 4.94,
      "adjOff": 104.3,
      "adjOffRank": 145,
      "adjTempo": 63.5,
      "adjTempoRank": 221,
      "conference": "BE",
      "rank": 117,
      "record": "15-18"
    },
    "Siena": {
      "adjDef": 108.1,
      "adjDefRank": 275,
      "adjEM": -12.74,
      "adjOff": 95.4,
      "adjOffRank": 289,
      "adjTempo": 62.6,
      "adjTempoRank": 258,
      "conference": "MAAC",
      "rank": 298,
      "record": "8-24"
    },
    "South Alabama": {
      "adjDef": 101.4,
      "adjDefRank": 146,
      "adjEM": -2.34,
      "adjOff": 99.1,
      "adjOffRank": 233,
      "adjTempo": 67,
      "adjTempoRank": 61,
      "conference": "SB",
      "rank": 194,
      "record": "17-13"
    },
    "South Carolina": {
      "adjDef": 105.3,
      "adjDefRank": 229,
      "adjEM": -3.58,
      "adjOff": 101.7,
      "adjOffRank": 189,
      "adjTempo": 65.2,
      "adjTempoRank": 145,
      "conference": "SEC",
      "rank": 209,
      "record": "14-18"
    },
    "South Carolina St.": {
      "adjDef": 113.9,
      "adjDefRank": 337,
      "adjEM": -22.02,
      "adjOff": 91.9,
      "adjOffRank": 321,
      "adjTempo": 63.9,
      "adjTempoRank": 203,
      "conference": "MEAC",
      "rank": 340,
      "record": "6-24"
    },
    "South Dakota": {
      "adjDef": 113,
      "adjDefRank": 331,
      "adjEM": -7.79,
      "adjOff": 105.2,
      "adjOffRank": 128,
      "adjTempo": 65.8,
      "adjTempoRank": 117,
      "conference": "Sum",
      "rank": 251,
      "record": "10-20"
    },
    "South Dakota St.": {
      "adjDef": 105.2,
      "adjDefRank": 223,
      "adjEM": 6.79,
      "adjOff": 112,
      "adjOffRank": 33,
      "adjTempo": 62.6,
      "adjTempoRank": 261,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 96,
      "record": "25-10",
      "seed": 13
    },
    "South Florida": {
      "adjDef": 99.7,
      "adjDefRank": 121,
      "adjEM": 2.63,
      "adjOff": 102.4,
      "adjOffRank": 176,
      "adjTempo": 58.7,
      "adjTempoRank": 341,
      "conference": "BE",
      "rank": 141,
      "record": "12-19"
    },
    "Southeast Missouri St.": {
      "adjDef": 108.8,
      "adjDefRank": 291,
      "adjEM": -4.87,
      "adjOff": 103.9,
      "adjOffRank": 149,
      "adjTempo": 67,
      "adjTempoRank": 63,
      "conference": "OVC",
      "rank": 224,
      "record": "17-16"
    },
    "Southeastern Louisiana": {
      "adjDef": 105.5,
      "adjDefRank": 234,
      "adjEM": -11.49,
      "adjOff": 94,
      "adjOffRank": 303,
      "adjTempo": 65,
      "adjTempoRank": 154,
      "conference": "Slnd",
      "rank": 285,
      "record": "12-18"
    },
    "Southern": {
      "adjDef": 98.8,
      "adjDefRank": 103,
      "adjEM": 0.5,
      "adjOff": 99.3,
      "adjOffRank": 231,
      "adjTempo": 63.4,
      "adjTempoRank": 222,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 162,
      "record": "23-10",
      "seed": 16
    },
    "Southern Illinois": {
      "adjDef": 103.4,
      "adjDefRank": 184,
      "adjEM": -0.73,
      "adjOff": 102.7,
      "adjOffRank": 169,
      "adjTempo": 61.6,
      "adjTempoRank": 291,
      "conference": "MVC",
      "rank": 177,
      "record": "14-17"
    },
    "Southern Miss": {
      "adjDef": 98.2,
      "adjDefRank": 90,
      "adjEM": 12.19,
      "adjOff": 110.4,
      "adjOffRank": 49,
      "adjTempo": 63.3,
      "adjTempoRank": 227,
      "conference": "CUSA",
      "rank": 61,
      "record": "27-10"
    },
    "Southern Utah": {
      "adjDef": 105.7,
      "adjDefRank": 242,
      "adjEM": -13.6,
      "adjOff": 92.1,
      "adjOffRank": 319,
      "adjTempo": 67.9,
      "adjTempoRank": 36,
      "conference": "BSky",
      "rank": 306,
      "record": "11-20"
    },
    "St. Bonaventure": {
      "adjDef": 105.2,
      "adjDefRank": 222,
      "adjEM": 4.53,
      "adjOff": 109.7,
      "adjOffRank": 59,
      "adjTempo": 65.1,
      "adjTempoRank": 147,
      "conference": "A10",
      "rank": 119,
      "record": "14-15"
    },
    "St. Francis NY": {
      "adjDef": 107.9,
      "adjDefRank": 271,
      "adjEM": -4.79,
      "adjOff": 103.1,
      "adjOffRank": 158,
      "adjTempo": 65.2,
      "adjTempoRank": 144,
      "conference": "NEC",
      "rank": 222,
      "record": "12-18"
    },
    "St. Francis PA": {
      "adjDef": 112.7,
      "adjDefRank": 328,
      "adjEM": -19.29,
      "adjOff": 93.4,
      "adjOffRank": 308,
      "adjTempo": 62.2,
      "adjTempoRank": 277,
      "conference": "NEC",
      "rank": 330,
      "record": "5-24"
    },
    "St. John's": {
      "adjDef": 94,
      "adjDefRank": 38,
      "adjEM": 6.38,
      "adjOff": 100.4,
      "adjOffRank": 212,
      "adjTempo": 66.3,
      "adjTempoRank": 94,
      "conference": "BE",
      "rank": 103,
      "record": "17-16"
    },
    "Stanford": {
      "adjDef": 95.7,
      "adjDefRank": 57,
      "adjEM": 14.18,
      "adjOff": 109.9,
      "adjOffRank": 56,
      "adjTempo": 66.7,
      "adjTempoRank": 72,
      "conference": "P12",
      "rank": 48,
      "record": "19-15"
    },
    "Stephen F. Austin": {
      "adjDef": 89.7,
      "adjDefRank": 10,
      "adjEM": 10.68,
      "adjOff": 100.4,
      "adjOffRank": 215,
      "adjTempo": 59.5,
      "adjTempoRank": 332,
      "conference": "Slnd",
      "rank": 70,
      "record": "27-5"
    },
    "Stetson": {
      "adjDef": 107.2,
      "adjDefRank": 261,
      "adjEM": -5.95,
      "adjOff": 101.2,
      "adjOffRank": 197,
      "adjTempo": 67.3,
      "adjTempoRank": 55,
      "conference": "ASun",
      "rank": 234,
      "record": "15-16"
    },
    "Stony Brook": {
      "adjDef": 94.4,
      "adjDefRank": 40,
      "adjEM": 12.25,
      "adjOff": 106.7,
      "adjOffRank": 102,
      "adjTempo": 62.9,
      "adjTempoRank": 244,
      "conference": "AE",
      "rank": 60,
      "record": "25-8"
    },
    "Syracuse": {
      "adjDef": 88.3,
      "adjDefRank": 6,
      "adjEM": 24.83,
      "adjOff": 113.1,
      "adjOffRank": 26,
      "adjTempo": 63.1,
      "adjTempoRank": 235,
      "conference": "BE",
      "finish": "Final Four",
      "rank": 9,
      "record": "30-10",
      "seed": 4
    },
    "TCU": {
      "adjDef": 102.7,
      "adjDefRank": 171,
      "adjEM": -9.36,
      "adjOff": 93.4,
      "adjOffRank": 309,
      "adjTempo": 59.5,
      "adjTempoRank": 331,
      "conference": "B12",
      "rank": 265,
      "record": "11-21"
    },
    "Temple": {
      "adjDef": 98.6,
      "adjDefRank": 100,
      "adjEM": 12.67,
      "adjOff": 111.3,
      "adjOffRank": 39,
      "adjTempo": 65.6,
      "adjTempoRank": 121,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 59,
      "record": "24-10",
      "seed": 9
    },
    "Tennessee": {
      "adjDef": 98.6,
      "adjDefRank": 101,
      "adjEM": 10.47,
      "adjOff": 109.1,
      "adjOffRank": 64,
      "adjTempo": 62.4,
      "adjTempoRank": 271,
      "conference": "SEC",
      "rank": 73,
      "record": "20-13"
    },
    "Tennessee Martin": {
      "adjDef": 113.9,
      "adjDefRank": 338,
      "adjEM": -20.18,
      "adjOff": 93.8,
      "adjOffRank": 306,
      "adjTempo": 66.6,
      "adjTempoRank": 76,
      "conference": "OVC",
      "rank": 332,
      "record": "9-21"
    },
    "Tennessee St.": {
      "adjDef": 104.7,
      "adjDefRank": 210,
      "adjEM": 0.18,
      "adjOff": 104.9,
      "adjOffRank": 135,
      "adjTempo": 66.7,
      "adjTempoRank": 70,
      "conference": "OVC",
      "rank": 165,
      "record": "18-15"
    },
    "Tennessee Tech": {
      "adjDef": 107.3,
      "adjDefRank": 263,
      "adjEM": -10.15,
      "adjOff": 97.1,
      "adjOffRank": 274,
      "adjTempo": 64.2,
      "adjTempoRank": 193,
      "conference": "OVC",
      "rank": 271,
      "record": "12-17"
    },
    "Texas": {
      "adjDef": 95.5,
      "adjDefRank": 54,
      "adjEM": 7.02,
      "adjOff": 102.5,
      "adjOffRank": 174,
      "adjTempo": 64.7,
      "adjTempoRank": 173,
      "conference": "B12",
      "rank": 94,
      "record": "16-18"
    },
    "Texas A&M": {
      "adjDef": 98.4,
      "adjDefRank": 93,
      "adjEM": 6.83,
      "adjOff": 105.2,
      "adjOffRank": 129,
      "adjTempo": 59.8,
      "adjTempoRank": 325,
      "conference": "SEC",
      "rank": 95,
      "record": "18-15"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 111,
      "adjDefRank": 316,
      "adjEM": -14.88,
      "adjOff": 96.1,
      "adjOffRank": 282,
      "adjTempo": 60.8,
      "adjTempoRank": 310,
      "conference": "Slnd",
      "rank": 315,
      "record": "6-23"
    },
    "Texas Pan American": {
      "adjDef": 108.6,
      "adjDefRank": 282,
      "adjEM": -14.41,
      "adjOff": 94.1,
      "adjOffRank": 299,
      "adjTempo": 62.5,
      "adjTempoRank": 263,
      "conference": "GWC",
      "rank": 312,
      "record": "16-16"
    },
    "Texas Southern": {
      "adjDef": 105,
      "adjDefRank": 218,
      "adjEM": 0.68,
      "adjOff": 105.7,
      "adjOffRank": 121,
      "adjTempo": 62.4,
      "adjTempoRank": 272,
      "conference": "SWAC",
      "rank": 155,
      "record": "17-14"
    },
    "Texas St.": {
      "adjDef": 109.1,
      "adjDefRank": 295,
      "adjEM": -8,
      "adjOff": 101.1,
      "adjOffRank": 199,
      "adjTempo": 70.5,
      "adjTempoRank": 6,
      "conference": "WAC",
      "rank": 255,
      "record": "12-22"
    },
    "Texas Tech": {
      "adjDef": 107.1,
      "adjDefRank": 258,
      "adjEM": -6.63,
      "adjOff": 100.5,
      "adjOffRank": 210,
      "adjTempo": 66.6,
      "adjTempoRank": 80,
      "conference": "B12",
      "rank": 239,
      "record": "11-20"
    },
    "The Citadel": {
      "adjDef": 119.2,
      "adjDefRank": 346,
      "adjEM": -20.88,
      "adjOff": 98.4,
      "adjOffRank": 250,
      "adjTempo": 64,
      "adjTempoRank": 197,
      "conference": "SC",
      "rank": 337,
      "record": "8-22"
    },
    "Toledo": {
      "adjDef": 105.5,
      "adjDefRank": 236,
      "adjEM": -1.31,
      "adjOff": 104.2,
      "adjOffRank": 146,
      "adjTempo": 66.1,
      "adjTempoRank": 98,
      "conference": "MAC",
      "rank": 186,
      "record": "15-13"
    },
    "Towson": {
      "adjDef": 103.6,
      "adjDefRank": 188,
      "adjEM": -0.45,
      "adjOff": 103.1,
      "adjOffRank": 157,
      "adjTempo": 64.7,
      "adjTempoRank": 172,
      "conference": "CAA",
      "rank": 175,
      "record": "18-13"
    },
    "Troy": {
      "adjDef": 109.4,
      "adjDefRank": 298,
      "adjEM": -10.31,
      "adjOff": 99.1,
      "adjOffRank": 232,
      "adjTempo": 59.4,
      "adjTempoRank": 333,
      "conference": "SB",
      "rank": 274,
      "record": "12-21"
    },
    "Tulane": {
      "adjDef": 105.3,
      "adjDefRank": 228,
      "adjEM": 1.88,
      "adjOff": 107.2,
      "adjOffRank": 91,
      "adjTempo": 64.3,
      "adjTempoRank": 189,
      "conference": "CUSA",
      "rank": 146,
      "record": "20-15"
    },
    "Tulsa": {
      "adjDef": 100.3,
      "adjDefRank": 131,
      "adjEM": -0.87,
      "adjOff": 99.5,
      "adjOffRank": 226,
      "adjTempo": 66.3,
      "adjTempoRank": 92,
      "conference": "CUSA",
      "rank": 181,
      "record": "17-16"
    },
    "UAB": {
      "adjDef": 100.6,
      "adjDefRank": 134,
      "adjEM": 0.35,
      "adjOff": 100.9,
      "adjOffRank": 205,
      "adjTempo": 69.3,
      "adjTempoRank": 16,
      "conference": "CUSA",
      "rank": 163,
      "record": "16-17"
    },
    "UC Davis": {
      "adjDef": 108.6,
      "adjDefRank": 285,
      "adjEM": -4.06,
      "adjOff": 104.6,
      "adjOffRank": 139,
      "adjTempo": 66.1,
      "adjTempoRank": 100,
      "conference": "BW",
      "rank": 215,
      "record": "14-17"
    },
    "UC Irvine": {
      "adjDef": 99.2,
      "adjDefRank": 108,
      "adjEM": 1.86,
      "adjOff": 101.1,
      "adjOffRank": 201,
      "adjTempo": 65.2,
      "adjTempoRank": 143,
      "conference": "BW",
      "rank": 147,
      "record": "21-16"
    },
    "UC Riverside": {
      "adjDef": 103.8,
      "adjDefRank": 193,
      "adjEM": -14.57,
      "adjOff": 89.2,
      "adjOffRank": 335,
      "adjTempo": 61.8,
      "adjTempoRank": 285,
      "conference": "BW",
      "rank": 314,
      "record": "6-25"
    },
    "UC Santa Barbara": {
      "adjDef": 103.3,
      "adjDefRank": 181,
      "adjEM": -4.8,
      "adjOff": 98.5,
      "adjOffRank": 248,
      "adjTempo": 62.5,
      "adjTempoRank": 265,
      "conference": "BW",
      "rank": 223,
      "record": "11-20"
    },
    "UCF": {
      "adjDef": 103.5,
      "adjDefRank": 186,
      "adjEM": 3.39,
      "adjOff": 106.8,
      "adjOffRank": 99,
      "adjTempo": 64.5,
      "adjTempoRank": 181,
      "conference": "CUSA",
      "rank": 130,
      "record": "20-11"
    },
    "UCLA": {
      "adjDef": 96.6,
      "adjDefRank": 67,
      "adjEM": 14.53,
      "adjOff": 111.1,
      "adjOffRank": 42,
      "adjTempo": 68.1,
      "adjTempoRank": 35,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 46,
      "record": "25-10",
      "seed": 6
    },
    "UMBC": {
      "adjDef": 109.6,
      "adjDefRank": 299,
      "adjEM": -13.9,
      "adjOff": 95.7,
      "adjOffRank": 288,
      "adjTempo": 65.9,
      "adjTempoRank": 112,
      "conference": "AE",
      "rank": 308,
      "record": "8-23"
    },
    "UMKC": {
      "adjDef": 109.1,
      "adjDefRank": 294,
      "adjEM": -15.39,
      "adjOff": 93.7,
      "adjOffRank": 307,
      "adjTempo": 63.1,
      "adjTempoRank": 236,
      "conference": "Sum",
      "rank": 318,
      "record": "8-24"
    },
    "UNC Asheville": {
      "adjDef": 104.7,
      "adjDefRank": 208,
      "adjEM": -3.25,
      "adjOff": 101.5,
      "adjOffRank": 194,
      "adjTempo": 67.9,
      "adjTempoRank": 38,
      "conference": "BSth",
      "rank": 203,
      "record": "16-16"
    },
    "UNC Greensboro": {
      "adjDef": 108.8,
      "adjDefRank": 288,
      "adjEM": -11.64,
      "adjOff": 97.1,
      "adjOffRank": 273,
      "adjTempo": 70.5,
      "adjTempoRank": 5,
      "conference": "SC",
      "rank": 286,
      "record": "9-22"
    },
    "UNC Wilmington": {
      "adjDef": 108.9,
      "adjDefRank": 292,
      "adjEM": -11.83,
      "adjOff": 97.1,
      "adjOffRank": 275,
      "adjTempo": 65.3,
      "adjTempoRank": 137,
      "conference": "CAA",
      "rank": 287,
      "record": "10-20"
    },
    "UNLV": {
      "adjDef": 90,
      "adjDefRank": 12,
      "adjEM": 16.07,
      "adjOff": 106,
      "adjOffRank": 115,
      "adjTempo": 67.9,
      "adjTempoRank": 42,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 38,
      "record": "25-10",
      "seed": 5
    },
    "USC": {
      "adjDef": 97,
      "adjDefRank": 75,
      "adjEM": 6.39,
      "adjOff": 103.4,
      "adjOffRank": 154,
      "adjTempo": 65.8,
      "adjTempoRank": 118,
      "conference": "P12",
      "rank": 101,
      "record": "14-18"
    },
    "USC Upstate": {
      "adjDef": 105.3,
      "adjDefRank": 230,
      "adjEM": -2.37,
      "adjOff": 103,
      "adjOffRank": 162,
      "adjTempo": 64.9,
      "adjTempoRank": 155,
      "conference": "ASun",
      "rank": 195,
      "record": "16-17"
    },
    "UT Arlington": {
      "adjDef": 97.5,
      "adjDefRank": 82,
      "adjEM": 1.37,
      "adjOff": 98.8,
      "adjOffRank": 238,
      "adjTempo": 65.6,
      "adjTempoRank": 128,
      "conference": "WAC",
      "rank": 149,
      "record": "19-14"
    },
    "UTEP": {
      "adjDef": 98.5,
      "adjDefRank": 97,
      "adjEM": 5.25,
      "adjOff": 103.7,
      "adjOffRank": 150,
      "adjTempo": 59.9,
      "adjTempoRank": 324,
      "conference": "CUSA",
      "rank": 114,
      "record": "18-14"
    },
    "UTSA": {
      "adjDef": 108.8,
      "adjDefRank": 289,
      "adjEM": -7.85,
      "adjOff": 100.9,
      "adjOffRank": 204,
      "adjTempo": 62.7,
      "adjTempoRank": 255,
      "conference": "WAC",
      "rank": 254,
      "record": "10-22"
    },
    "Utah": {
      "adjDef": 99.5,
      "adjDefRank": 116,
      "adjEM": 5.13,
      "adjOff": 104.7,
      "adjOffRank": 138,
      "adjTempo": 60.9,
      "adjTempoRank": 304,
      "conference": "P12",
      "rank": 115,
      "record": "15-18"
    },
    "Utah St.": {
      "adjDef": 104.2,
      "adjDefRank": 201,
      "adjEM": 5.53,
      "adjOff": 109.7,
      "adjOffRank": 60,
      "adjTempo": 60.6,
      "adjTempoRank": 313,
      "conference": "WAC",
      "rank": 108,
      "record": "21-10"
    },
    "Utah Valley": {
      "adjDef": 111.4,
      "adjDefRank": 320,
      "adjEM": -12.58,
      "adjOff": 98.8,
      "adjOffRank": 242,
      "adjTempo": 62.8,
      "adjTempoRank": 251,
      "conference": "GWC",
      "rank": 295,
      "record": "14-18"
    },
    "VCU": {
      "adjDef": 93.7,
      "adjDefRank": 36,
      "adjEM": 20.28,
      "adjOff": 114,
      "adjOffRank": 20,
      "adjTempo": 66.4,
      "adjTempoRank": 91,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 18,
      "record": "27-9",
      "seed": 5
    },
    "VMI": {
      "adjDef": 113.6,
      "adjDefRank": 335,
      "adjEM": -11.33,
      "adjOff": 102.3,
      "adjOffRank": 178,
      "adjTempo": 69.7,
      "adjTempoRank": 9,
      "conference": "BSth",
      "rank": 283,
      "record": "14-17"
    },
    "Valparaiso": {
      "adjDef": 99.3,
      "adjDefRank": 111,
      "adjEM": 11.42,
      "adjOff": 110.7,
      "adjOffRank": 44,
      "adjTempo": 64.3,
      "adjTempoRank": 188,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 65,
      "record": "26-8",
      "seed": 14
    },
    "Vanderbilt": {
      "adjDef": 95.8,
      "adjDefRank": 58,
      "adjEM": 6.77,
      "adjOff": 102.6,
      "adjOffRank": 173,
      "adjTempo": 59.7,
      "adjTempoRank": 328,
      "conference": "SEC",
      "rank": 98,
      "record": "16-17"
    },
    "Vermont": {
      "adjDef": 99.9,
      "adjDefRank": 126,
      "adjEM": 3.12,
      "adjOff": 103,
      "adjOffRank": 159,
      "adjTempo": 60.9,
      "adjTempoRank": 306,
      "conference": "AE",
      "rank": 135,
      "record": "21-12"
    },
    "Villanova": {
      "adjDef": 92.7,
      "adjDefRank": 29,
      "adjEM": 13.57,
      "adjOff": 106.3,
      "adjOffRank": 113,
      "adjTempo": 66.4,
      "adjTempoRank": 88,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 53,
      "record": "20-14",
      "seed": 9
    },
    "Virginia": {
      "adjDef": 92,
      "adjDefRank": 25,
      "adjEM": 15.51,
      "adjOff": 107.5,
      "adjOffRank": 83,
      "adjTempo": 59.3,
      "adjTempoRank": 336,
      "conference": "ACC",
      "rank": 41,
      "record": "23-12"
    },
    "Virginia Tech": {
      "adjDef": 107.3,
      "adjDefRank": 265,
      "adjEM": -0.1,
      "adjOff": 107.2,
      "adjOffRank": 90,
      "adjTempo": 65.9,
      "adjTempoRank": 111,
      "conference": "ACC",
      "rank": 169,
      "record": "13-19"
    },
    "Wagner": {
      "adjDef": 98.4,
      "adjDefRank": 94,
      "adjEM": -1.17,
      "adjOff": 97.2,
      "adjOffRank": 271,
      "adjTempo": 65.9,
      "adjTempoRank": 113,
      "conference": "NEC",
      "rank": 184,
      "record": "19-12"
    },
    "Wake Forest": {
      "adjDef": 98.7,
      "adjDefRank": 102,
      "adjEM": 2.88,
      "adjOff": 101.6,
      "adjOffRank": 191,
      "adjTempo": 67.8,
      "adjTempoRank": 45,
      "conference": "ACC",
      "rank": 137,
      "record": "13-18"
    },
    "Washington": {
      "adjDef": 98.5,
      "adjDefRank": 98,
      "adjEM": 9.16,
      "adjOff": 107.7,
      "adjOffRank": 79,
      "adjTempo": 64.3,
      "adjTempoRank": 187,
      "conference": "P12",
      "rank": 81,
      "record": "18-16"
    },
    "Washington St.": {
      "adjDef": 100.2,
      "adjDefRank": 129,
      "adjEM": 7.45,
      "adjOff": 107.6,
      "adjOffRank": 81,
      "adjTempo": 60.7,
      "adjTempoRank": 312,
      "conference": "P12",
      "rank": 90,
      "record": "13-19"
    },
    "Weber St.": {
      "adjDef": 98.4,
      "adjDefRank": 95,
      "adjEM": 10.65,
      "adjOff": 109.1,
      "adjOffRank": 65,
      "adjTempo": 64.1,
      "adjTempoRank": 196,
      "conference": "BSky",
      "rank": 71,
      "record": "30-7"
    },
    "West Virginia": {
      "adjDef": 100.8,
      "adjDefRank": 137,
      "adjEM": 4.22,
      "adjOff": 105,
      "adjOffRank": 133,
      "adjTempo": 63,
      "adjTempoRank": 242,
      "conference": "B12",
      "rank": 123,
      "record": "13-19"
    },
    "Western Carolina": {
      "adjDef": 109,
      "adjDefRank": 293,
      "adjEM": -7.39,
      "adjOff": 101.6,
      "adjOffRank": 190,
      "adjTempo": 65,
      "adjTempoRank": 151,
      "conference": "SC",
      "rank": 245,
      "record": "14-19"
    },
    "Western Illinois": {
      "adjDef": 96.6,
      "adjDefRank": 68,
      "adjEM": 0.66,
      "adjOff": 97.3,
      "adjOffRank": 267,
      "adjTempo": 56.7,
      "adjTempoRank": 347,
      "conference": "Sum",
      "rank": 156,
      "record": "22-9"
    },
    "Western Kentucky": {
      "adjDef": 102.2,
      "adjDefRank": 157,
      "adjEM": -0.41,
      "adjOff": 101.8,
      "adjOffRank": 188,
      "adjTempo": 64.6,
      "adjTempoRank": 179,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 173,
      "record": "20-16",
      "seed": 16
    },
    "Western Michigan": {
      "adjDef": 100,
      "adjDefRank": 127,
      "adjEM": 2.63,
      "adjOff": 102.6,
      "adjOffRank": 170,
      "adjTempo": 65,
      "adjTempoRank": 153,
      "conference": "MAC",
      "rank": 140,
      "record": "22-13"
    },
    "Wichita St.": {
      "adjDef": 91.5,
      "adjDefRank": 20,
      "adjEM": 20.31,
      "adjOff": 111.8,
      "adjOffRank": 35,
      "adjTempo": 63.8,
      "adjTempoRank": 208,
      "conference": "MVC",
      "finish": "Final Four",
      "rank": 17,
      "record": "30-9",
      "seed": 9
    },
    "William & Mary": {
      "adjDef": 110.6,
      "adjDefRank": 311,
      "adjEM": -4.01,
      "adjOff": 106.6,
      "adjOffRank": 108,
      "adjTempo": 62.2,
      "adjTempoRank": 278,
      "conference": "CAA",
      "rank": 214,
      "record": "13-17"
    },
    "Winthrop": {
      "adjDef": 105.9,
      "adjDefRank": 245,
      "adjEM": -10.64,
      "adjOff": 95.3,
      "adjOffRank": 291,
      "adjTempo": 59.8,
      "adjTempoRank": 326,
      "conference": "BSth",
      "rank": 278,
      "record": "14-17"
    },
    "Wisconsin": {
      "adjDef": 85.7,
      "adjDefRank": 2,
      "adjEM": 22.57,
      "adjOff": 108.3,
      "adjOffRank": 73,
      "adjTempo": 60.4,
      "adjTempoRank": 319,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 12,
      "record": "23-12",
      "seed": 5
    },
    "Wofford": {
      "adjDef": 96.7,
      "adjDefRank": 72,
      "adjEM": -7.42,
      "adjOff": 89.2,
      "adjOffRank": 334,
      "adjTempo": 62.3,
      "adjTempoRank": 273,
      "conference": "SC",
      "rank": 246,
      "record": "13-19"
    },
    "Wright St.": {
      "adjDef": 93.7,
      "adjDefRank": 34,
      "adjEM": 4.33,
      "adjOff": 98,
      "adjOffRank": 256,
      "adjTempo": 61.8,
      "adjTempoRank": 283,
      "conference": "Horz",
      "rank": 122,
      "record": "23-13"
    },
    "Wyoming": {
      "adjDef": 96.5,
      "adjDefRank": 65,
      "adjEM": 6.53,
      "adjOff": 103,
      "adjOffRank": 160,
      "adjTempo": 60,
      "adjTempoRank": 322,
      "conference": "MWC",
      "rank": 100,
      "record": "20-14"
    },
    "Xavier": {
      "adjDef": 96.4,
      "adjDefRank": 64,
      "adjEM": 9.55,
      "adjOff": 106,
      "adjOffRank": 116,
      "adjTempo": 61.8,
      "adjTempoRank": 287,
      "conference": "A10",
      "rank": 78,
      "record": "17-14"
    },
    "Yale": {
      "adjDef": 105.7,
      "adjDefRank": 240,
      "adjEM": -2.95,
      "adjOff": 102.7,
      "adjOffRank": 168,
      "adjTempo": 63,
      "adjTempoRank": 239,
      "conference": "Ivy",
      "rank": 198,
      "record": "14-17"
    },
    "Youngstown St.": {
      "adjDef": 104.8,
      "adjDefRank": 213,
      "adjEM": -1.91,
      "adjOff": 102.9,
      "adjOffRank": 163,
      "adjTempo": 66.9,
      "adjTempoRank": 66,
      "conference": "Horz",
      "rank": 191,
      "record": "18-16"
    }
  },
  "2014": {
    "Abilene Christian": {
      "adjDef": 117.3,
      "adjDefRank": 342,
      "adjEM": -23.35,
      "adjOff": 94,
      "adjOffRank": 330,
      "adjTempo": 63.2,
      "adjTempoRank": 251,
      "conference": "Slnd",
      "rank": 348,
      "record": "11-20"
    },
    "Air Force": {
      "adjDef": 106.6,
      "adjDefRank": 204,
      "adjEM": -7,
      "adjOff": 99.6,
      "adjOffRank": 269,
      "adjTempo": 63.6,
      "adjTempoRank": 236,
      "conference": "MWC",
      "rank": 252,
      "record": "12-18"
    },
    "Akron": {
      "adjDef": 102.9,
      "adjDefRank": 128,
      "adjEM": 2.1,
      "adjOff": 105,
      "adjOffRank": 184,
      "adjTempo": 64,
      "adjTempoRank": 216,
      "conference": "MAC",
      "rank": 145,
      "record": "21-13"
    },
    "Alabama": {
      "adjDef": 99.2,
      "adjDefRank": 63,
      "adjEM": 8.54,
      "adjOff": 107.8,
      "adjOffRank": 128,
      "adjTempo": 62,
      "adjTempoRank": 301,
      "conference": "SEC",
      "rank": 86,
      "record": "13-19"
    },
    "Alabama A&M": {
      "adjDef": 105.8,
      "adjDefRank": 183,
      "adjEM": -13.83,
      "adjOff": 92,
      "adjOffRank": 343,
      "adjTempo": 64.3,
      "adjTempoRank": 195,
      "conference": "SWAC",
      "rank": 306,
      "record": "14-16"
    },
    "Alabama St.": {
      "adjDef": 110.5,
      "adjDefRank": 271,
      "adjEM": -13.22,
      "adjOff": 97.3,
      "adjOffRank": 300,
      "adjTempo": 65.9,
      "adjTempoRank": 109,
      "conference": "SWAC",
      "rank": 298,
      "record": "19-13"
    },
    "Albany": {
      "adjDef": 103.9,
      "adjDefRank": 148,
      "adjEM": -1.04,
      "adjOff": 102.9,
      "adjOffRank": 217,
      "adjTempo": 61.6,
      "adjTempoRank": 314,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 181,
      "record": "19-15",
      "seed": 16
    },
    "Alcorn St.": {
      "adjDef": 109.8,
      "adjDefRank": 262,
      "adjEM": -16.47,
      "adjOff": 93.4,
      "adjOffRank": 335,
      "adjTempo": 62,
      "adjTempoRank": 300,
      "conference": "SWAC",
      "rank": 324,
      "record": "12-19"
    },
    "American": {
      "adjDef": 98.9,
      "adjDefRank": 61,
      "adjEM": 4.57,
      "adjOff": 103.4,
      "adjOffRank": 206,
      "adjTempo": 59.7,
      "adjTempoRank": 343,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 120,
      "record": "20-13",
      "seed": 15
    },
    "Appalachian St.": {
      "adjDef": 110.7,
      "adjDefRank": 273,
      "adjEM": -18.63,
      "adjOff": 92,
      "adjOffRank": 341,
      "adjTempo": 64.7,
      "adjTempoRank": 173,
      "conference": "SC",
      "rank": 331,
      "record": "9-21"
    },
    "Arizona": {
      "adjDef": 86.4,
      "adjDefRank": 1,
      "adjEM": 30.11,
      "adjOff": 116.5,
      "adjOffRank": 20,
      "adjTempo": 62.8,
      "adjTempoRank": 272,
      "conference": "P12",
      "finish": "Elite Eight",
      "rank": 2,
      "record": "33-5",
      "seed": 1
    },
    "Arizona St.": {
      "adjDef": 96.4,
      "adjDefRank": 41,
      "adjEM": 14.82,
      "adjOff": 111.2,
      "adjOffRank": 67,
      "adjTempo": 67.4,
      "adjTempoRank": 58,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 39,
      "record": "21-12",
      "seed": 10
    },
    "Arkansas": {
      "adjDef": 96.9,
      "adjDefRank": 44,
      "adjEM": 14.14,
      "adjOff": 111.1,
      "adjOffRank": 71,
      "adjTempo": 70.7,
      "adjTempoRank": 7,
      "conference": "SEC",
      "rank": 44,
      "record": "22-12"
    },
    "Arkansas Little Rock": {
      "adjDef": 105.8,
      "adjDefRank": 184,
      "adjEM": -4.7,
      "adjOff": 101.1,
      "adjOffRank": 243,
      "adjTempo": 66.3,
      "adjTempoRank": 95,
      "conference": "SB",
      "rank": 219,
      "record": "15-17"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 108.7,
      "adjDefRank": 244,
      "adjEM": -17.01,
      "adjOff": 91.7,
      "adjOffRank": 344,
      "adjTempo": 65,
      "adjTempoRank": 159,
      "conference": "SWAC",
      "rank": 326,
      "record": "13-18"
    },
    "Arkansas St.": {
      "adjDef": 106,
      "adjDefRank": 188,
      "adjEM": 0.59,
      "adjOff": 106.6,
      "adjOffRank": 143,
      "adjTempo": 66.6,
      "adjTempoRank": 85,
      "conference": "SB",
      "rank": 161,
      "record": "19-13"
    },
    "Army": {
      "adjDef": 110.4,
      "adjDefRank": 269,
      "adjEM": -5.31,
      "adjOff": 105.1,
      "adjOffRank": 181,
      "adjTempo": 67,
      "adjTempoRank": 64,
      "conference": "Pat",
      "rank": 228,
      "record": "15-16"
    },
    "Auburn": {
      "adjDef": 105.4,
      "adjDefRank": 174,
      "adjEM": 4.39,
      "adjOff": 109.8,
      "adjOffRank": 95,
      "adjTempo": 66,
      "adjTempoRank": 108,
      "conference": "SEC",
      "rank": 122,
      "record": "14-16"
    },
    "Austin Peay": {
      "adjDef": 111.2,
      "adjDefRank": 281,
      "adjEM": -11.64,
      "adjOff": 99.6,
      "adjOffRank": 270,
      "adjTempo": 68.9,
      "adjTempoRank": 23,
      "conference": "OVC",
      "rank": 287,
      "record": "12-18"
    },
    "BYU": {
      "adjDef": 100.7,
      "adjDefRank": 93,
      "adjEM": 13.77,
      "adjOff": 114.5,
      "adjOffRank": 31,
      "adjTempo": 70.8,
      "adjTempoRank": 6,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 49,
      "record": "23-12",
      "seed": 10
    },
    "Ball St.": {
      "adjDef": 109.6,
      "adjDefRank": 259,
      "adjEM": -13.09,
      "adjOff": 96.5,
      "adjOffRank": 305,
      "adjTempo": 65.9,
      "adjTempoRank": 112,
      "conference": "MAC",
      "rank": 296,
      "record": "5-25"
    },
    "Baylor": {
      "adjDef": 99.5,
      "adjDefRank": 70,
      "adjEM": 19.39,
      "adjOff": 118.9,
      "adjOffRank": 10,
      "adjTempo": 61.3,
      "adjTempoRank": 319,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 26,
      "record": "26-12",
      "seed": 6
    },
    "Belmont": {
      "adjDef": 106.8,
      "adjDefRank": 207,
      "adjEM": 7.1,
      "adjOff": 113.9,
      "adjOffRank": 36,
      "adjTempo": 67.7,
      "adjTempoRank": 47,
      "conference": "OVC",
      "rank": 97,
      "record": "26-10"
    },
    "Bethune Cookman": {
      "adjDef": 116.8,
      "adjDefRank": 341,
      "adjEM": -20.31,
      "adjOff": 96.5,
      "adjOffRank": 306,
      "adjTempo": 60.7,
      "adjTempoRank": 332,
      "conference": "MEAC",
      "rank": 337,
      "record": "7-25"
    },
    "Binghamton": {
      "adjDef": 111.1,
      "adjDefRank": 278,
      "adjEM": -19.39,
      "adjOff": 91.7,
      "adjOffRank": 346,
      "adjTempo": 64.2,
      "adjTempoRank": 208,
      "conference": "AE",
      "rank": 333,
      "record": "7-23"
    },
    "Boise St.": {
      "adjDef": 104.1,
      "adjDefRank": 152,
      "adjEM": 10.53,
      "adjOff": 114.6,
      "adjOffRank": 29,
      "adjTempo": 63.9,
      "adjTempoRank": 219,
      "conference": "MWC",
      "rank": 73,
      "record": "21-13"
    },
    "Boston College": {
      "adjDef": 111.8,
      "adjDefRank": 294,
      "adjEM": 2.44,
      "adjOff": 114.3,
      "adjOffRank": 32,
      "adjTempo": 61.2,
      "adjTempoRank": 321,
      "conference": "ACC",
      "rank": 142,
      "record": "8-24"
    },
    "Boston University": {
      "adjDef": 102.5,
      "adjDefRank": 123,
      "adjEM": 3.82,
      "adjOff": 106.3,
      "adjOffRank": 153,
      "adjTempo": 65.1,
      "adjTempoRank": 153,
      "conference": "Pat",
      "rank": 126,
      "record": "24-11"
    },
    "Bowling Green": {
      "adjDef": 96.9,
      "adjDefRank": 45,
      "adjEM": -3.9,
      "adjOff": 93,
      "adjOffRank": 337,
      "adjTempo": 65,
      "adjTempoRank": 157,
      "conference": "MAC",
      "rank": 207,
      "record": "12-20"
    },
    "Bradley": {
      "adjDef": 104.6,
      "adjDefRank": 163,
      "adjEM": -4.38,
      "adjOff": 100.2,
      "adjOffRank": 264,
      "adjTempo": 62.5,
      "adjTempoRank": 284,
      "conference": "MVC",
      "rank": 214,
      "record": "12-20"
    },
    "Brown": {
      "adjDef": 100.5,
      "adjDefRank": 87,
      "adjEM": 0.59,
      "adjOff": 101.1,
      "adjOffRank": 246,
      "adjTempo": 68.3,
      "adjTempoRank": 36,
      "conference": "Ivy",
      "rank": 162,
      "record": "15-14"
    },
    "Bryant": {
      "adjDef": 108.5,
      "adjDefRank": 239,
      "adjEM": -2.54,
      "adjOff": 106,
      "adjOffRank": 161,
      "adjTempo": 65.3,
      "adjTempoRank": 141,
      "conference": "NEC",
      "rank": 196,
      "record": "18-14"
    },
    "Bucknell": {
      "adjDef": 102.1,
      "adjDefRank": 113,
      "adjEM": 0.91,
      "adjOff": 103,
      "adjOffRank": 214,
      "adjTempo": 62.9,
      "adjTempoRank": 267,
      "conference": "Pat",
      "rank": 160,
      "record": "16-14"
    },
    "Buffalo": {
      "adjDef": 103.4,
      "adjDefRank": 138,
      "adjEM": 6.36,
      "adjOff": 109.7,
      "adjOffRank": 96,
      "adjTempo": 66.6,
      "adjTempoRank": 82,
      "conference": "MAC",
      "rank": 101,
      "record": "19-10"
    },
    "Butler": {
      "adjDef": 99.3,
      "adjDefRank": 64,
      "adjEM": 6.74,
      "adjOff": 106,
      "adjOffRank": 160,
      "adjTempo": 61.9,
      "adjTempoRank": 304,
      "conference": "BE",
      "rank": 99,
      "record": "14-17"
    },
    "Cal Poly": {
      "adjDef": 104.4,
      "adjDefRank": 159,
      "adjEM": -0.82,
      "adjOff": 103.6,
      "adjOffRank": 202,
      "adjTempo": 58.8,
      "adjTempoRank": 347,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 176,
      "record": "14-20",
      "seed": 16
    },
    "Cal St. Bakersfield": {
      "adjDef": 105.9,
      "adjDefRank": 186,
      "adjEM": -5.12,
      "adjOff": 100.8,
      "adjOffRank": 250,
      "adjTempo": 63.6,
      "adjTempoRank": 232,
      "conference": "WAC",
      "rank": 225,
      "record": "13-19"
    },
    "Cal St. Fullerton": {
      "adjDef": 107.5,
      "adjDefRank": 221,
      "adjEM": -6.64,
      "adjOff": 100.9,
      "adjOffRank": 249,
      "adjTempo": 65.5,
      "adjTempoRank": 132,
      "conference": "BW",
      "rank": 249,
      "record": "11-20"
    },
    "Cal St. Northridge": {
      "adjDef": 111.4,
      "adjDefRank": 287,
      "adjEM": -5.1,
      "adjOff": 106.3,
      "adjOffRank": 151,
      "adjTempo": 67.5,
      "adjTempoRank": 54,
      "conference": "BW",
      "rank": 223,
      "record": "17-18"
    },
    "California": {
      "adjDef": 99.7,
      "adjDefRank": 74,
      "adjEM": 12,
      "adjOff": 111.7,
      "adjOffRank": 63,
      "adjTempo": 64.6,
      "adjTempoRank": 178,
      "conference": "P12",
      "rank": 60,
      "record": "21-14"
    },
    "Campbell": {
      "adjDef": 115.9,
      "adjDefRank": 334,
      "adjEM": -17.42,
      "adjOff": 98.5,
      "adjOffRank": 285,
      "adjTempo": 61.9,
      "adjTempoRank": 303,
      "conference": "BSth",
      "rank": 327,
      "record": "12-20"
    },
    "Canisius": {
      "adjDef": 108.3,
      "adjDefRank": 233,
      "adjEM": 4.14,
      "adjOff": 112.5,
      "adjOffRank": 53,
      "adjTempo": 65.3,
      "adjTempoRank": 140,
      "conference": "MAAC",
      "rank": 124,
      "record": "21-13"
    },
    "Central Arkansas": {
      "adjDef": 113.1,
      "adjDefRank": 319,
      "adjEM": -17.82,
      "adjOff": 95.3,
      "adjOffRank": 319,
      "adjTempo": 70.7,
      "adjTempoRank": 8,
      "conference": "Slnd",
      "rank": 329,
      "record": "8-21"
    },
    "Central Connecticut": {
      "adjDef": 111.1,
      "adjDefRank": 277,
      "adjEM": -13.65,
      "adjOff": 97.4,
      "adjOffRank": 299,
      "adjTempo": 68.4,
      "adjTempoRank": 32,
      "conference": "NEC",
      "rank": 305,
      "record": "11-19"
    },
    "Central Michigan": {
      "adjDef": 117.7,
      "adjDefRank": 343,
      "adjEM": -11.43,
      "adjOff": 106.3,
      "adjOffRank": 155,
      "adjTempo": 63.7,
      "adjTempoRank": 228,
      "conference": "MAC",
      "rank": 286,
      "record": "10-21"
    },
    "Charleston Southern": {
      "adjDef": 111,
      "adjDefRank": 275,
      "adjEM": -4.88,
      "adjOff": 106.1,
      "adjOffRank": 158,
      "adjTempo": 65.7,
      "adjTempoRank": 121,
      "conference": "BSth",
      "rank": 220,
      "record": "13-18"
    },
    "Charlotte": {
      "adjDef": 103.2,
      "adjDefRank": 136,
      "adjEM": -0.54,
      "adjOff": 102.7,
      "adjOffRank": 221,
      "adjTempo": 67.5,
      "adjTempoRank": 49,
      "conference": "CUSA",
      "rank": 171,
      "record": "17-14"
    },
    "Chattanooga": {
      "adjDef": 110.1,
      "adjDefRank": 264,
      "adjEM": -9.48,
      "adjOff": 100.6,
      "adjOffRank": 253,
      "adjTempo": 66.8,
      "adjTempoRank": 74,
      "conference": "SC",
      "rank": 279,
      "record": "18-15"
    },
    "Chicago St.": {
      "adjDef": 109.4,
      "adjDefRank": 255,
      "adjEM": -11.26,
      "adjOff": 98.1,
      "adjOffRank": 291,
      "adjTempo": 67.8,
      "adjTempoRank": 44,
      "conference": "WAC",
      "rank": 284,
      "record": "13-19"
    },
    "Cincinnati": {
      "adjDef": 90.4,
      "adjDefRank": 9,
      "adjEM": 19.24,
      "adjOff": 109.7,
      "adjOffRank": 98,
      "adjTempo": 61.1,
      "adjTempoRank": 328,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 27,
      "record": "27-7",
      "seed": 5
    },
    "Clemson": {
      "adjDef": 93.7,
      "adjDefRank": 17,
      "adjEM": 13.35,
      "adjOff": 107.1,
      "adjOffRank": 136,
      "adjTempo": 58.7,
      "adjTempoRank": 348,
      "conference": "ACC",
      "rank": 51,
      "record": "23-13"
    },
    "Cleveland St.": {
      "adjDef": 102,
      "adjDefRank": 111,
      "adjEM": 8.67,
      "adjOff": 110.6,
      "adjOffRank": 80,
      "adjTempo": 62.4,
      "adjTempoRank": 287,
      "conference": "Horz",
      "rank": 85,
      "record": "21-12"
    },
    "Coastal Carolina": {
      "adjDef": 103.2,
      "adjDefRank": 133,
      "adjEM": -4.98,
      "adjOff": 98.2,
      "adjOffRank": 290,
      "adjTempo": 65.6,
      "adjTempoRank": 125,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 221,
      "record": "21-13",
      "seed": 16
    },
    "Colgate": {
      "adjDef": 109.7,
      "adjDefRank": 261,
      "adjEM": -1.28,
      "adjOff": 108.5,
      "adjOffRank": 117,
      "adjTempo": 62.4,
      "adjTempoRank": 285,
      "conference": "Pat",
      "rank": 184,
      "record": "13-18"
    },
    "College of Charleston": {
      "adjDef": 100.9,
      "adjDefRank": 97,
      "adjEM": -2.83,
      "adjOff": 98.1,
      "adjOffRank": 292,
      "adjTempo": 60.7,
      "adjTempoRank": 333,
      "conference": "CAA",
      "rank": 200,
      "record": "14-18"
    },
    "Colorado": {
      "adjDef": 96.5,
      "adjDefRank": 42,
      "adjEM": 11.2,
      "adjOff": 107.7,
      "adjOffRank": 129,
      "adjTempo": 64.8,
      "adjTempoRank": 167,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 67,
      "record": "23-12",
      "seed": 8
    },
    "Colorado St.": {
      "adjDef": 107,
      "adjDefRank": 212,
      "adjEM": 3.51,
      "adjOff": 110.5,
      "adjOffRank": 84,
      "adjTempo": 64.9,
      "adjTempoRank": 161,
      "conference": "MWC",
      "rank": 128,
      "record": "16-16"
    },
    "Columbia": {
      "adjDef": 103,
      "adjDefRank": 129,
      "adjEM": 4.67,
      "adjOff": 107.6,
      "adjOffRank": 130,
      "adjTempo": 59.9,
      "adjTempoRank": 339,
      "conference": "Ivy",
      "rank": 119,
      "record": "21-13"
    },
    "Connecticut": {
      "adjDef": 91.5,
      "adjDefRank": 10,
      "adjEM": 22.13,
      "adjOff": 113.6,
      "adjOffRank": 39,
      "adjTempo": 63.2,
      "adjTempoRank": 254,
      "conference": "Amer",
      "finish": "Champion",
      "rank": 15,
      "record": "32-8",
      "seed": 7
    },
    "Coppin St.": {
      "adjDef": 112.6,
      "adjDefRank": 310,
      "adjEM": -12.31,
      "adjOff": 100.3,
      "adjOffRank": 261,
      "adjTempo": 66.8,
      "adjTempoRank": 71,
      "conference": "MEAC",
      "rank": 293,
      "record": "12-20"
    },
    "Cornell": {
      "adjDef": 120.9,
      "adjDefRank": 350,
      "adjEM": -20.58,
      "adjOff": 100.4,
      "adjOffRank": 260,
      "adjTempo": 63.6,
      "adjTempoRank": 234,
      "conference": "Ivy",
      "rank": 340,
      "record": "2-26"
    },
    "Creighton": {
      "adjDef": 102.5,
      "adjDefRank": 124,
      "adjEM": 21.72,
      "adjOff": 124.2,
      "adjOffRank": 2,
      "adjTempo": 63.6,
      "adjTempoRank": 237,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 17,
      "record": "27-8",
      "seed": 3
    },
    "Dartmouth": {
      "adjDef": 108.6,
      "adjDefRank": 240,
      "adjEM": -4.99,
      "adjOff": 103.6,
      "adjOffRank": 201,
      "adjTempo": 62.8,
      "adjTempoRank": 270,
      "conference": "Ivy",
      "rank": 222,
      "record": "12-16"
    },
    "Davidson": {
      "adjDef": 109.1,
      "adjDefRank": 251,
      "adjEM": 4.18,
      "adjOff": 113.3,
      "adjOffRank": 42,
      "adjTempo": 66.1,
      "adjTempoRank": 101,
      "conference": "SC",
      "rank": 123,
      "record": "20-13"
    },
    "Dayton": {
      "adjDef": 99.6,
      "adjDefRank": 72,
      "adjEM": 14.25,
      "adjOff": 113.8,
      "adjOffRank": 37,
      "adjTempo": 63.8,
      "adjTempoRank": 223,
      "conference": "A10",
      "finish": "Elite Eight",
      "rank": 42,
      "record": "26-11",
      "seed": 11
    },
    "DePaul": {
      "adjDef": 107.3,
      "adjDefRank": 217,
      "adjEM": -1.19,
      "adjOff": 106.1,
      "adjOffRank": 156,
      "adjTempo": 65.6,
      "adjTempoRank": 126,
      "conference": "BE",
      "rank": 183,
      "record": "12-21"
    },
    "Delaware": {
      "adjDef": 106.2,
      "adjDefRank": 192,
      "adjEM": 5.83,
      "adjOff": 112,
      "adjOffRank": 60,
      "adjTempo": 70.4,
      "adjTempoRank": 10,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 106,
      "record": "25-10",
      "seed": 13
    },
    "Delaware St.": {
      "adjDef": 112.7,
      "adjDefRank": 314,
      "adjEM": -15.63,
      "adjOff": 97.1,
      "adjOffRank": 302,
      "adjTempo": 58.3,
      "adjTempoRank": 349,
      "conference": "MEAC",
      "rank": 320,
      "record": "9-21"
    },
    "Denver": {
      "adjDef": 107.7,
      "adjDefRank": 224,
      "adjEM": 2.86,
      "adjOff": 110.6,
      "adjOffRank": 83,
      "adjTempo": 57.8,
      "adjTempoRank": 350,
      "conference": "Sum",
      "rank": 139,
      "record": "16-15"
    },
    "Detroit": {
      "adjDef": 103.6,
      "adjDefRank": 142,
      "adjEM": -2.23,
      "adjOff": 101.4,
      "adjOffRank": 238,
      "adjTempo": 66,
      "adjTempoRank": 104,
      "conference": "Horz",
      "rank": 195,
      "record": "13-19"
    },
    "Drake": {
      "adjDef": 106.5,
      "adjDefRank": 199,
      "adjEM": -1.46,
      "adjOff": 105,
      "adjOffRank": 183,
      "adjTempo": 62.9,
      "adjTempoRank": 266,
      "conference": "MVC",
      "rank": 187,
      "record": "15-16"
    },
    "Drexel": {
      "adjDef": 101.8,
      "adjDefRank": 110,
      "adjEM": 3.47,
      "adjOff": 105.2,
      "adjOffRank": 179,
      "adjTempo": 62.4,
      "adjTempoRank": 288,
      "conference": "CAA",
      "rank": 129,
      "record": "16-14"
    },
    "Duke": {
      "adjDef": 100.4,
      "adjDefRank": 86,
      "adjEM": 24.25,
      "adjOff": 124.7,
      "adjOffRank": 1,
      "adjTempo": 64.6,
      "adjTempoRank": 179,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 8,
      "record": "26-9",
      "seed": 3
    },
    "Duquesne": {
      "adjDef": 109,
      "adjDefRank": 248,
      "adjEM": 1.53,
      "adjOff": 110.6,
      "adjOffRank": 82,
      "adjTempo": 66.5,
      "adjTempoRank": 88,
      "conference": "A10",
      "rank": 152,
      "record": "13-17"
    },
    "East Carolina": {
      "adjDef": 111.8,
      "adjDefRank": 295,
      "adjEM": -5.88,
      "adjOff": 106,
      "adjOffRank": 163,
      "adjTempo": 63.7,
      "adjTempoRank": 227,
      "conference": "CUSA",
      "rank": 239,
      "record": "17-17"
    },
    "East Tennessee St.": {
      "adjDef": 109.6,
      "adjDefRank": 260,
      "adjEM": -6.16,
      "adjOff": 103.5,
      "adjOffRank": 204,
      "adjTempo": 68.6,
      "adjTempoRank": 26,
      "conference": "ASun",
      "rank": 243,
      "record": "19-16"
    },
    "Eastern Illinois": {
      "adjDef": 112,
      "adjDefRank": 298,
      "adjEM": -13.28,
      "adjOff": 98.7,
      "adjOffRank": 279,
      "adjTempo": 64.2,
      "adjTempoRank": 204,
      "conference": "OVC",
      "rank": 300,
      "record": "11-19"
    },
    "Eastern Kentucky": {
      "adjDef": 108.4,
      "adjDefRank": 235,
      "adjEM": 3.96,
      "adjOff": 112.4,
      "adjOffRank": 55,
      "adjTempo": 64.2,
      "adjTempoRank": 203,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 125,
      "record": "24-10",
      "seed": 15
    },
    "Eastern Michigan": {
      "adjDef": 95.3,
      "adjDefRank": 28,
      "adjEM": 3.17,
      "adjOff": 98.5,
      "adjOffRank": 284,
      "adjTempo": 63,
      "adjTempoRank": 259,
      "conference": "MAC",
      "rank": 136,
      "record": "22-15"
    },
    "Eastern Washington": {
      "adjDef": 111.3,
      "adjDefRank": 282,
      "adjEM": -5.56,
      "adjOff": 105.7,
      "adjOffRank": 169,
      "adjTempo": 69,
      "adjTempoRank": 19,
      "conference": "BSky",
      "rank": 233,
      "record": "15-16"
    },
    "Elon": {
      "adjDef": 107.5,
      "adjDefRank": 222,
      "adjEM": -2.95,
      "adjOff": 104.6,
      "adjOffRank": 189,
      "adjTempo": 64.4,
      "adjTempoRank": 189,
      "conference": "SC",
      "rank": 202,
      "record": "18-14"
    },
    "Evansville": {
      "adjDef": 107.3,
      "adjDefRank": 216,
      "adjEM": -4.19,
      "adjOff": 103.1,
      "adjOffRank": 212,
      "adjTempo": 65.6,
      "adjTempoRank": 128,
      "conference": "MVC",
      "rank": 211,
      "record": "14-19"
    },
    "FIU": {
      "adjDef": 106.9,
      "adjDefRank": 210,
      "adjEM": -5.48,
      "adjOff": 101.4,
      "adjOffRank": 236,
      "adjTempo": 64.7,
      "adjTempoRank": 174,
      "conference": "CUSA",
      "rank": 231,
      "record": "15-16"
    },
    "Fairfield": {
      "adjDef": 104.5,
      "adjDefRank": 160,
      "adjEM": -8.84,
      "adjOff": 95.6,
      "adjOffRank": 315,
      "adjTempo": 64.5,
      "adjTempoRank": 185,
      "conference": "MAAC",
      "rank": 271,
      "record": "7-25"
    },
    "Fairleigh Dickinson": {
      "adjDef": 112.8,
      "adjDefRank": 316,
      "adjEM": -10.57,
      "adjOff": 102.2,
      "adjOffRank": 226,
      "adjTempo": 65,
      "adjTempoRank": 160,
      "conference": "NEC",
      "rank": 282,
      "record": "10-21"
    },
    "Florida": {
      "adjDef": 88,
      "adjDefRank": 3,
      "adjEM": 28.57,
      "adjOff": 116.5,
      "adjOffRank": 19,
      "adjTempo": 61.3,
      "adjTempoRank": 318,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 3,
      "record": "36-3",
      "seed": 1
    },
    "Florida A&M": {
      "adjDef": 108.1,
      "adjDefRank": 231,
      "adjEM": -13.19,
      "adjOff": 94.9,
      "adjOffRank": 322,
      "adjTempo": 70,
      "adjTempoRank": 14,
      "conference": "MEAC",
      "rank": 297,
      "record": "14-18"
    },
    "Florida Atlantic": {
      "adjDef": 107.5,
      "adjDefRank": 219,
      "adjEM": -6.4,
      "adjOff": 101.1,
      "adjOffRank": 244,
      "adjTempo": 63,
      "adjTempoRank": 257,
      "conference": "CUSA",
      "rank": 244,
      "record": "10-22"
    },
    "Florida Gulf Coast": {
      "adjDef": 101.1,
      "adjDefRank": 99,
      "adjEM": -1.08,
      "adjOff": 100,
      "adjOffRank": 266,
      "adjTempo": 66.2,
      "adjTempoRank": 98,
      "conference": "ASun",
      "rank": 182,
      "record": "22-13"
    },
    "Florida St.": {
      "adjDef": 97.4,
      "adjDefRank": 54,
      "adjEM": 14.86,
      "adjOff": 112.2,
      "adjOffRank": 59,
      "adjTempo": 65.2,
      "adjTempoRank": 149,
      "conference": "ACC",
      "rank": 38,
      "record": "22-14"
    },
    "Fordham": {
      "adjDef": 111.7,
      "adjDefRank": 291,
      "adjEM": -2.86,
      "adjOff": 108.8,
      "adjOffRank": 109,
      "adjTempo": 67.5,
      "adjTempoRank": 55,
      "conference": "A10",
      "rank": 201,
      "record": "10-21"
    },
    "Fresno St.": {
      "adjDef": 105.2,
      "adjDefRank": 170,
      "adjEM": 5.05,
      "adjOff": 110.2,
      "adjOffRank": 88,
      "adjTempo": 65.7,
      "adjTempoRank": 118,
      "conference": "MWC",
      "rank": 116,
      "record": "21-18"
    },
    "Furman": {
      "adjDef": 115.6,
      "adjDefRank": 332,
      "adjEM": -20.69,
      "adjOff": 94.9,
      "adjOffRank": 323,
      "adjTempo": 63.7,
      "adjTempoRank": 229,
      "conference": "SC",
      "rank": 342,
      "record": "9-21"
    },
    "Gardner Webb": {
      "adjDef": 105.6,
      "adjDefRank": 179,
      "adjEM": -6.59,
      "adjOff": 99,
      "adjOffRank": 276,
      "adjTempo": 64.8,
      "adjTempoRank": 168,
      "conference": "BSth",
      "rank": 247,
      "record": "18-15"
    },
    "George Mason": {
      "adjDef": 103.2,
      "adjDefRank": 132,
      "adjEM": 2.67,
      "adjOff": 105.8,
      "adjOffRank": 167,
      "adjTempo": 65,
      "adjTempoRank": 155,
      "conference": "A10",
      "rank": 141,
      "record": "11-20"
    },
    "George Washington": {
      "adjDef": 97,
      "adjDefRank": 47,
      "adjEM": 13.82,
      "adjOff": 110.8,
      "adjOffRank": 74,
      "adjTempo": 65.3,
      "adjTempoRank": 144,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 47,
      "record": "24-9",
      "seed": 9
    },
    "Georgetown": {
      "adjDef": 100.6,
      "adjDefRank": 91,
      "adjEM": 12.39,
      "adjOff": 113,
      "adjOffRank": 43,
      "adjTempo": 63.3,
      "adjTempoRank": 249,
      "conference": "BE",
      "rank": 57,
      "record": "18-15"
    },
    "Georgia": {
      "adjDef": 99.6,
      "adjDefRank": 71,
      "adjEM": 9.4,
      "adjOff": 109,
      "adjOffRank": 108,
      "adjTempo": 62.8,
      "adjTempoRank": 273,
      "conference": "SEC",
      "rank": 78,
      "record": "20-14"
    },
    "Georgia Southern": {
      "adjDef": 110.4,
      "adjDefRank": 268,
      "adjEM": -12.09,
      "adjOff": 98.3,
      "adjOffRank": 287,
      "adjTempo": 64.8,
      "adjTempoRank": 169,
      "conference": "SC",
      "rank": 291,
      "record": "15-19"
    },
    "Georgia St.": {
      "adjDef": 104.3,
      "adjDefRank": 156,
      "adjEM": 11.28,
      "adjOff": 115.6,
      "adjOffRank": 23,
      "adjTempo": 64.3,
      "adjTempoRank": 200,
      "conference": "SB",
      "rank": 66,
      "record": "25-9"
    },
    "Georgia Tech": {
      "adjDef": 99.6,
      "adjDefRank": 73,
      "adjEM": 5.17,
      "adjOff": 104.8,
      "adjOffRank": 187,
      "adjTempo": 64.2,
      "adjTempoRank": 205,
      "conference": "ACC",
      "rank": 114,
      "record": "16-17"
    },
    "Gonzaga": {
      "adjDef": 93.8,
      "adjDefRank": 18,
      "adjEM": 20.14,
      "adjOff": 114,
      "adjOffRank": 35,
      "adjTempo": 65,
      "adjTempoRank": 154,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 24,
      "record": "29-7",
      "seed": 8
    },
    "Grambling St.": {
      "adjDef": 122.1,
      "adjDefRank": 351,
      "adjEM": -26.6,
      "adjOff": 95.5,
      "adjOffRank": 316,
      "adjTempo": 66,
      "adjTempoRank": 107,
      "conference": "SWAC",
      "rank": 350,
      "record": "5-24"
    },
    "Grand Canyon": {
      "adjDef": 111.3,
      "adjDefRank": 283,
      "adjEM": -6.04,
      "adjOff": 105.3,
      "adjOffRank": 176,
      "adjTempo": 65.4,
      "adjTempoRank": 138,
      "conference": "WAC",
      "rank": 241,
      "record": "15-15"
    },
    "Green Bay": {
      "adjDef": 98,
      "adjDefRank": 56,
      "adjEM": 10.77,
      "adjOff": 108.7,
      "adjOffRank": 113,
      "adjTempo": 65.5,
      "adjTempoRank": 131,
      "conference": "Horz",
      "rank": 71,
      "record": "24-7"
    },
    "Hampton": {
      "adjDef": 100.8,
      "adjDefRank": 96,
      "adjEM": -4.38,
      "adjOff": 96.4,
      "adjOffRank": 307,
      "adjTempo": 69.7,
      "adjTempoRank": 15,
      "conference": "MEAC",
      "rank": 213,
      "record": "18-13"
    },
    "Hartford": {
      "adjDef": 107.8,
      "adjDefRank": 226,
      "adjEM": -5.62,
      "adjOff": 102.2,
      "adjOffRank": 227,
      "adjTempo": 61.9,
      "adjTempoRank": 307,
      "conference": "AE",
      "rank": 234,
      "record": "17-16"
    },
    "Harvard": {
      "adjDef": 95.3,
      "adjDefRank": 27,
      "adjEM": 17.58,
      "adjOff": 112.9,
      "adjOffRank": 47,
      "adjTempo": 64.1,
      "adjTempoRank": 211,
      "conference": "Ivy",
      "finish": "Round of 32",
      "rank": 32,
      "record": "27-5",
      "seed": 12
    },
    "Hawaii": {
      "adjDef": 108,
      "adjDefRank": 229,
      "adjEM": 3.03,
      "adjOff": 111,
      "adjOffRank": 72,
      "adjTempo": 66.9,
      "adjTempoRank": 68,
      "conference": "BW",
      "rank": 137,
      "record": "20-11"
    },
    "High Point": {
      "adjDef": 112.6,
      "adjDefRank": 311,
      "adjEM": -6.63,
      "adjOff": 106,
      "adjOffRank": 162,
      "adjTempo": 62.7,
      "adjTempoRank": 281,
      "conference": "BSth",
      "rank": 248,
      "record": "16-15"
    },
    "Hofstra": {
      "adjDef": 113.2,
      "adjDefRank": 320,
      "adjEM": -8.14,
      "adjOff": 105.1,
      "adjOffRank": 182,
      "adjTempo": 63.8,
      "adjTempoRank": 226,
      "conference": "CAA",
      "rank": 266,
      "record": "10-23"
    },
    "Holy Cross": {
      "adjDef": 103.5,
      "adjDefRank": 139,
      "adjEM": 0.98,
      "adjOff": 104.5,
      "adjOffRank": 191,
      "adjTempo": 62.7,
      "adjTempoRank": 277,
      "conference": "Pat",
      "rank": 158,
      "record": "20-14"
    },
    "Houston": {
      "adjDef": 107.7,
      "adjDefRank": 225,
      "adjEM": 3.35,
      "adjOff": 111.1,
      "adjOffRank": 70,
      "adjTempo": 65.4,
      "adjTempoRank": 136,
      "conference": "Amer",
      "rank": 132,
      "record": "17-16"
    },
    "Houston Baptist": {
      "adjDef": 112.2,
      "adjDefRank": 301,
      "adjEM": -20.66,
      "adjOff": 91.5,
      "adjOffRank": 347,
      "adjTempo": 67.6,
      "adjTempoRank": 48,
      "conference": "Slnd",
      "rank": 341,
      "record": "6-25"
    },
    "Howard": {
      "adjDef": 106.6,
      "adjDefRank": 201,
      "adjEM": -18.88,
      "adjOff": 87.7,
      "adjOffRank": 350,
      "adjTempo": 65.9,
      "adjTempoRank": 110,
      "conference": "MEAC",
      "rank": 332,
      "record": "8-25"
    },
    "IPFW": {
      "adjDef": 108,
      "adjDefRank": 228,
      "adjEM": 2.08,
      "adjOff": 110.1,
      "adjOffRank": 90,
      "adjTempo": 65.7,
      "adjTempoRank": 119,
      "conference": "Sum",
      "rank": 146,
      "record": "25-11"
    },
    "IUPUI": {
      "adjDef": 114.3,
      "adjDefRank": 328,
      "adjEM": -18.16,
      "adjOff": 96.2,
      "adjOffRank": 310,
      "adjTempo": 64.2,
      "adjTempoRank": 210,
      "conference": "Sum",
      "rank": 330,
      "record": "6-26"
    },
    "Idaho": {
      "adjDef": 112.5,
      "adjDefRank": 308,
      "adjEM": -7.12,
      "adjOff": 105.4,
      "adjOffRank": 174,
      "adjTempo": 65.6,
      "adjTempoRank": 123,
      "conference": "WAC",
      "rank": 254,
      "record": "16-18"
    },
    "Idaho St.": {
      "adjDef": 111.6,
      "adjDefRank": 289,
      "adjEM": -7.58,
      "adjOff": 104,
      "adjOffRank": 198,
      "adjTempo": 63,
      "adjTempoRank": 258,
      "conference": "BSky",
      "rank": 260,
      "record": "11-18"
    },
    "Illinois": {
      "adjDef": 93.4,
      "adjDefRank": 14,
      "adjEM": 13.1,
      "adjOff": 106.5,
      "adjOffRank": 146,
      "adjTempo": 61.2,
      "adjTempoRank": 324,
      "conference": "B10",
      "rank": 53,
      "record": "20-15"
    },
    "Illinois Chicago": {
      "adjDef": 109.5,
      "adjDefRank": 256,
      "adjEM": -9.07,
      "adjOff": 100.4,
      "adjOffRank": 257,
      "adjTempo": 64.5,
      "adjTempoRank": 184,
      "conference": "Horz",
      "rank": 275,
      "record": "6-25"
    },
    "Illinois St.": {
      "adjDef": 99.9,
      "adjDefRank": 80,
      "adjEM": 1.98,
      "adjOff": 101.9,
      "adjOffRank": 230,
      "adjTempo": 66.7,
      "adjTempoRank": 75,
      "conference": "MVC",
      "rank": 147,
      "record": "18-16"
    },
    "Incarnate Word": {
      "adjDef": 110.3,
      "adjDefRank": 265,
      "adjEM": -7.59,
      "adjOff": 102.7,
      "adjOffRank": 222,
      "adjTempo": 68.7,
      "adjTempoRank": 25,
      "conference": "Slnd",
      "rank": 261,
      "record": "21-6"
    },
    "Indiana": {
      "adjDef": 96.2,
      "adjDefRank": 38,
      "adjEM": 11.55,
      "adjOff": 107.8,
      "adjOffRank": 126,
      "adjTempo": 67.1,
      "adjTempoRank": 63,
      "conference": "B10",
      "rank": 63,
      "record": "17-15"
    },
    "Indiana St.": {
      "adjDef": 101.2,
      "adjDefRank": 100,
      "adjEM": 5.61,
      "adjOff": 106.8,
      "adjOffRank": 140,
      "adjTempo": 65.3,
      "adjTempoRank": 146,
      "conference": "MVC",
      "rank": 108,
      "record": "23-11"
    },
    "Iona": {
      "adjDef": 108.5,
      "adjDefRank": 237,
      "adjEM": 10.14,
      "adjOff": 118.6,
      "adjOffRank": 12,
      "adjTempo": 68.6,
      "adjTempoRank": 28,
      "conference": "MAAC",
      "rank": 76,
      "record": "22-11"
    },
    "Iowa": {
      "adjDef": 99.8,
      "adjDefRank": 77,
      "adjEM": 20.44,
      "adjOff": 120.2,
      "adjOffRank": 5,
      "adjTempo": 68.6,
      "adjTempoRank": 29,
      "conference": "B10",
      "finish": "First Four",
      "rank": 22,
      "record": "20-13",
      "seed": 11
    },
    "Iowa St.": {
      "adjDef": 97.8,
      "adjDefRank": 55,
      "adjEM": 21.07,
      "adjOff": 118.9,
      "adjOffRank": 9,
      "adjTempo": 70.2,
      "adjTempoRank": 12,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "28-8",
      "seed": 3
    },
    "Jackson St.": {
      "adjDef": 106.3,
      "adjDefRank": 195,
      "adjEM": -12.63,
      "adjOff": 93.7,
      "adjOffRank": 334,
      "adjTempo": 65,
      "adjTempoRank": 156,
      "conference": "SWAC",
      "rank": 294,
      "record": "11-20"
    },
    "Jacksonville": {
      "adjDef": 116.8,
      "adjDefRank": 339,
      "adjEM": -13.55,
      "adjOff": 103.2,
      "adjOffRank": 209,
      "adjTempo": 67.8,
      "adjTempoRank": 43,
      "conference": "ASun",
      "rank": 303,
      "record": "12-18"
    },
    "Jacksonville St.": {
      "adjDef": 112.3,
      "adjDefRank": 303,
      "adjEM": -14.04,
      "adjOff": 98.2,
      "adjOffRank": 289,
      "adjTempo": 60.6,
      "adjTempoRank": 334,
      "conference": "OVC",
      "rank": 309,
      "record": "10-21"
    },
    "James Madison": {
      "adjDef": 106.8,
      "adjDefRank": 206,
      "adjEM": -8.25,
      "adjOff": 98.5,
      "adjOffRank": 283,
      "adjTempo": 63.5,
      "adjTempoRank": 241,
      "conference": "CAA",
      "rank": 267,
      "record": "11-20"
    },
    "Kansas": {
      "adjDef": 94.7,
      "adjDefRank": 22,
      "adjEM": 24.6,
      "adjOff": 119.3,
      "adjOffRank": 6,
      "adjTempo": 66.6,
      "adjTempoRank": 83,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 7,
      "record": "25-10",
      "seed": 2
    },
    "Kansas St.": {
      "adjDef": 93.7,
      "adjDefRank": 16,
      "adjEM": 14.1,
      "adjOff": 107.8,
      "adjOffRank": 125,
      "adjTempo": 63.3,
      "adjTempoRank": 247,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 45,
      "record": "20-13",
      "seed": 9
    },
    "Kennesaw St.": {
      "adjDef": 112.3,
      "adjDefRank": 304,
      "adjEM": -20.32,
      "adjOff": 92,
      "adjOffRank": 342,
      "adjTempo": 66,
      "adjTempoRank": 106,
      "conference": "ASun",
      "rank": 338,
      "record": "6-25"
    },
    "Kent St.": {
      "adjDef": 106.2,
      "adjDefRank": 193,
      "adjEM": -2.11,
      "adjOff": 104.1,
      "adjOffRank": 195,
      "adjTempo": 64.7,
      "adjTempoRank": 171,
      "conference": "MAC",
      "rank": 193,
      "record": "16-16"
    },
    "Kentucky": {
      "adjDef": 95.8,
      "adjDefRank": 32,
      "adjEM": 22.55,
      "adjOff": 118.3,
      "adjOffRank": 14,
      "adjTempo": 64,
      "adjTempoRank": 217,
      "conference": "SEC",
      "finish": "Runner-up",
      "rank": 13,
      "record": "29-11",
      "seed": 8
    },
    "LIU Brooklyn": {
      "adjDef": 118.6,
      "adjDefRank": 347,
      "adjEM": -15.55,
      "adjOff": 103,
      "adjOffRank": 213,
      "adjTempo": 66.4,
      "adjTempoRank": 92,
      "conference": "NEC",
      "rank": 319,
      "record": "9-20"
    },
    "LSU": {
      "adjDef": 98.5,
      "adjDefRank": 58,
      "adjEM": 12.12,
      "adjOff": 110.7,
      "adjOffRank": 78,
      "adjTempo": 68.6,
      "adjTempoRank": 30,
      "conference": "SEC",
      "rank": 59,
      "record": "20-14"
    },
    "La Salle": {
      "adjDef": 100.4,
      "adjDefRank": 85,
      "adjEM": 5.88,
      "adjOff": 106.3,
      "adjOffRank": 154,
      "adjTempo": 62.7,
      "adjTempoRank": 279,
      "conference": "A10",
      "rank": 105,
      "record": "15-16"
    },
    "Lafayette": {
      "adjDef": 115.7,
      "adjDefRank": 333,
      "adjEM": -6.43,
      "adjOff": 109.3,
      "adjOffRank": 102,
      "adjTempo": 64.6,
      "adjTempoRank": 181,
      "conference": "Pat",
      "rank": 245,
      "record": "11-20"
    },
    "Lamar": {
      "adjDef": 113.8,
      "adjDefRank": 324,
      "adjEM": -20.71,
      "adjOff": 93.1,
      "adjOffRank": 336,
      "adjTempo": 66.5,
      "adjTempoRank": 90,
      "conference": "Slnd",
      "rank": 343,
      "record": "4-26"
    },
    "Lehigh": {
      "adjDef": 104.1,
      "adjDefRank": 151,
      "adjEM": -6.06,
      "adjOff": 98,
      "adjOffRank": 294,
      "adjTempo": 64.3,
      "adjTempoRank": 196,
      "conference": "Pat",
      "rank": 242,
      "record": "14-18"
    },
    "Liberty": {
      "adjDef": 112.6,
      "adjDefRank": 309,
      "adjEM": -11.25,
      "adjOff": 101.3,
      "adjOffRank": 240,
      "adjTempo": 62.8,
      "adjTempoRank": 268,
      "conference": "BSth",
      "rank": 283,
      "record": "11-21"
    },
    "Lipscomb": {
      "adjDef": 111.5,
      "adjDefRank": 288,
      "adjEM": -8.04,
      "adjOff": 103.4,
      "adjOffRank": 207,
      "adjTempo": 68.6,
      "adjTempoRank": 27,
      "conference": "ASun",
      "rank": 265,
      "record": "15-15"
    },
    "Long Beach St.": {
      "adjDef": 102.7,
      "adjDefRank": 127,
      "adjEM": 2.17,
      "adjOff": 104.9,
      "adjOffRank": 186,
      "adjTempo": 64.6,
      "adjTempoRank": 177,
      "conference": "BW",
      "rank": 144,
      "record": "15-17"
    },
    "Longwood": {
      "adjDef": 118.5,
      "adjDefRank": 344,
      "adjEM": -22.88,
      "adjOff": 95.6,
      "adjOffRank": 314,
      "adjTempo": 66.6,
      "adjTempoRank": 80,
      "conference": "BSth",
      "rank": 346,
      "record": "8-24"
    },
    "Louisiana Lafayette": {
      "adjDef": 105,
      "adjDefRank": 167,
      "adjEM": 5.19,
      "adjOff": 110.2,
      "adjOffRank": 89,
      "adjTempo": 68.5,
      "adjTempoRank": 31,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 113,
      "record": "23-12",
      "seed": 14
    },
    "Louisiana Monroe": {
      "adjDef": 104.5,
      "adjDefRank": 161,
      "adjEM": -7,
      "adjOff": 97.5,
      "adjOffRank": 298,
      "adjTempo": 61.8,
      "adjTempoRank": 310,
      "conference": "SB",
      "rank": 251,
      "record": "10-17"
    },
    "Louisiana Tech": {
      "adjDef": 95.3,
      "adjDefRank": 29,
      "adjEM": 15.1,
      "adjOff": 110.4,
      "adjOffRank": 85,
      "adjTempo": 69.6,
      "adjTempoRank": 16,
      "conference": "CUSA",
      "rank": 37,
      "record": "29-8"
    },
    "Louisville": {
      "adjDef": 88.5,
      "adjDefRank": 5,
      "adjEM": 30.41,
      "adjOff": 118.9,
      "adjOffRank": 7,
      "adjTempo": 67.2,
      "adjTempoRank": 60,
      "conference": "Amer",
      "finish": "Sweet 16",
      "rank": 1,
      "record": "31-6",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 108.7,
      "adjDefRank": 243,
      "adjEM": -6.47,
      "adjOff": 102.2,
      "adjOffRank": 225,
      "adjTempo": 61.9,
      "adjTempoRank": 305,
      "conference": "MVC",
      "rank": 246,
      "record": "10-22"
    },
    "Loyola MD": {
      "adjDef": 109.3,
      "adjDefRank": 253,
      "adjEM": -12.17,
      "adjOff": 97.1,
      "adjOffRank": 301,
      "adjTempo": 64.6,
      "adjTempoRank": 176,
      "conference": "Pat",
      "rank": 292,
      "record": "11-19"
    },
    "Loyola Marymount": {
      "adjDef": 106.6,
      "adjDefRank": 203,
      "adjEM": -0.94,
      "adjOff": 105.7,
      "adjOffRank": 170,
      "adjTempo": 67.8,
      "adjTempoRank": 45,
      "conference": "WCC",
      "rank": 178,
      "record": "13-19"
    },
    "Maine": {
      "adjDef": 116,
      "adjDefRank": 336,
      "adjEM": -20.55,
      "adjOff": 95.5,
      "adjOffRank": 317,
      "adjTempo": 73,
      "adjTempoRank": 3,
      "conference": "AE",
      "rank": 339,
      "record": "6-23"
    },
    "Manhattan": {
      "adjDef": 97,
      "adjDefRank": 46,
      "adjEM": 10.21,
      "adjOff": 107.2,
      "adjOffRank": 134,
      "adjTempo": 67.7,
      "adjTempoRank": 46,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 74,
      "record": "25-8",
      "seed": 13
    },
    "Marist": {
      "adjDef": 105.5,
      "adjDefRank": 176,
      "adjEM": -3.87,
      "adjOff": 101.6,
      "adjOffRank": 235,
      "adjTempo": 64.4,
      "adjTempoRank": 192,
      "conference": "MAAC",
      "rank": 206,
      "record": "12-19"
    },
    "Marquette": {
      "adjDef": 98.6,
      "adjDefRank": 60,
      "adjEM": 11.05,
      "adjOff": 109.7,
      "adjOffRank": 97,
      "adjTempo": 64.5,
      "adjTempoRank": 186,
      "conference": "BE",
      "rank": 68,
      "record": "17-15"
    },
    "Marshall": {
      "adjDef": 105.7,
      "adjDefRank": 181,
      "adjEM": -5.82,
      "adjOff": 99.9,
      "adjOffRank": 267,
      "adjTempo": 66.9,
      "adjTempoRank": 66,
      "conference": "CUSA",
      "rank": 236,
      "record": "11-22"
    },
    "Maryland": {
      "adjDef": 95.2,
      "adjDefRank": 26,
      "adjEM": 14.2,
      "adjOff": 109.4,
      "adjOffRank": 101,
      "adjTempo": 67.4,
      "adjTempoRank": 57,
      "conference": "ACC",
      "rank": 43,
      "record": "17-15"
    },
    "Maryland Eastern Shore": {
      "adjDef": 119.3,
      "adjDefRank": 348,
      "adjEM": -20.22,
      "adjOff": 99.1,
      "adjOffRank": 275,
      "adjTempo": 65.1,
      "adjTempoRank": 152,
      "conference": "MEAC",
      "rank": 336,
      "record": "6-24"
    },
    "Massachusetts": {
      "adjDef": 96.5,
      "adjDefRank": 43,
      "adjEM": 13.46,
      "adjOff": 110,
      "adjOffRank": 91,
      "adjTempo": 70.3,
      "adjTempoRank": 11,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 50,
      "record": "24-9",
      "seed": 6
    },
    "McNeese St.": {
      "adjDef": 112,
      "adjDefRank": 297,
      "adjEM": -14.07,
      "adjOff": 97.9,
      "adjOffRank": 296,
      "adjTempo": 65.8,
      "adjTempoRank": 116,
      "conference": "Slnd",
      "rank": 310,
      "record": "11-20"
    },
    "Memphis": {
      "adjDef": 97.1,
      "adjDefRank": 50,
      "adjEM": 15.22,
      "adjOff": 112.4,
      "adjOffRank": 56,
      "adjTempo": 67.5,
      "adjTempoRank": 53,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 36,
      "record": "24-10",
      "seed": 8
    },
    "Mercer": {
      "adjDef": 102.4,
      "adjDefRank": 119,
      "adjEM": 8.34,
      "adjOff": 110.8,
      "adjOffRank": 75,
      "adjTempo": 63.6,
      "adjTempoRank": 233,
      "conference": "ASun",
      "finish": "Round of 32",
      "rank": 87,
      "record": "27-9",
      "seed": 14
    },
    "Miami FL": {
      "adjDef": 99.3,
      "adjDefRank": 66,
      "adjEM": 9.88,
      "adjOff": 109.2,
      "adjOffRank": 105,
      "adjTempo": 57,
      "adjTempoRank": 351,
      "conference": "ACC",
      "rank": 77,
      "record": "17-16"
    },
    "Miami OH": {
      "adjDef": 103.5,
      "adjDefRank": 140,
      "adjEM": -4.63,
      "adjOff": 98.9,
      "adjOffRank": 277,
      "adjTempo": 65.4,
      "adjTempoRank": 135,
      "conference": "MAC",
      "rank": 218,
      "record": "13-18"
    },
    "Michigan": {
      "adjDef": 100.5,
      "adjDefRank": 89,
      "adjEM": 23.44,
      "adjOff": 123.9,
      "adjOffRank": 3,
      "adjTempo": 61,
      "adjTempoRank": 330,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 12,
      "record": "28-9",
      "seed": 2
    },
    "Michigan St.": {
      "adjDef": 94.7,
      "adjDefRank": 21,
      "adjEM": 23.92,
      "adjOff": 118.6,
      "adjOffRank": 13,
      "adjTempo": 64.4,
      "adjTempoRank": 188,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "29-9",
      "seed": 4
    },
    "Middle Tennessee": {
      "adjDef": 97,
      "adjDefRank": 48,
      "adjEM": 8.24,
      "adjOff": 105.3,
      "adjOffRank": 177,
      "adjTempo": 62.6,
      "adjTempoRank": 282,
      "conference": "CUSA",
      "rank": 89,
      "record": "24-9"
    },
    "Milwaukee": {
      "adjDef": 104.6,
      "adjDefRank": 164,
      "adjEM": 0.08,
      "adjOff": 104.7,
      "adjOffRank": 188,
      "adjTempo": 65.8,
      "adjTempoRank": 115,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 168,
      "record": "21-14",
      "seed": 15
    },
    "Minnesota": {
      "adjDef": 99.9,
      "adjDefRank": 78,
      "adjEM": 13.93,
      "adjOff": 113.8,
      "adjOffRank": 38,
      "adjTempo": 63.3,
      "adjTempoRank": 250,
      "conference": "B10",
      "rank": 46,
      "record": "25-13"
    },
    "Mississippi": {
      "adjDef": 102.3,
      "adjDefRank": 116,
      "adjEM": 8.87,
      "adjOff": 111.2,
      "adjOffRank": 68,
      "adjTempo": 68.1,
      "adjTempoRank": 41,
      "conference": "SEC",
      "rank": 83,
      "record": "19-14"
    },
    "Mississippi St.": {
      "adjDef": 103.6,
      "adjDefRank": 143,
      "adjEM": -3.35,
      "adjOff": 100.3,
      "adjOffRank": 262,
      "adjTempo": 66.8,
      "adjTempoRank": 69,
      "conference": "SEC",
      "rank": 205,
      "record": "14-19"
    },
    "Mississippi Valley St.": {
      "adjDef": 115.9,
      "adjDefRank": 335,
      "adjEM": -22.93,
      "adjOff": 93,
      "adjOffRank": 338,
      "adjTempo": 70.9,
      "adjTempoRank": 5,
      "conference": "SWAC",
      "rank": 347,
      "record": "9-23"
    },
    "Missouri": {
      "adjDef": 103.2,
      "adjDefRank": 137,
      "adjEM": 10.84,
      "adjOff": 114.1,
      "adjOffRank": 33,
      "adjTempo": 64.8,
      "adjTempoRank": 165,
      "conference": "SEC",
      "rank": 69,
      "record": "23-12"
    },
    "Missouri St.": {
      "adjDef": 105.5,
      "adjDefRank": 177,
      "adjEM": 1.01,
      "adjOff": 106.5,
      "adjOffRank": 145,
      "adjTempo": 63,
      "adjTempoRank": 261,
      "conference": "MVC",
      "rank": 157,
      "record": "20-13"
    },
    "Monmouth": {
      "adjDef": 105.3,
      "adjDefRank": 171,
      "adjEM": -8.45,
      "adjOff": 96.8,
      "adjOffRank": 303,
      "adjTempo": 66.6,
      "adjTempoRank": 79,
      "conference": "MAAC",
      "rank": 269,
      "record": "11-21"
    },
    "Montana": {
      "adjDef": 111.9,
      "adjDefRank": 296,
      "adjEM": -4.45,
      "adjOff": 107.4,
      "adjOffRank": 132,
      "adjTempo": 59.8,
      "adjTempoRank": 342,
      "conference": "BSky",
      "rank": 216,
      "record": "17-13"
    },
    "Montana St.": {
      "adjDef": 108.5,
      "adjDefRank": 238,
      "adjEM": -13.51,
      "adjOff": 95,
      "adjOffRank": 320,
      "adjTempo": 63.9,
      "adjTempoRank": 221,
      "conference": "BSky",
      "rank": 302,
      "record": "14-17"
    },
    "Morehead St.": {
      "adjDef": 103.9,
      "adjDefRank": 149,
      "adjEM": -1.02,
      "adjOff": 102.9,
      "adjOffRank": 215,
      "adjTempo": 67.5,
      "adjTempoRank": 51,
      "conference": "OVC",
      "rank": 180,
      "record": "20-14"
    },
    "Morgan St.": {
      "adjDef": 105.5,
      "adjDefRank": 178,
      "adjEM": -5.11,
      "adjOff": 100.4,
      "adjOffRank": 255,
      "adjTempo": 67.5,
      "adjTempoRank": 52,
      "conference": "MEAC",
      "rank": 224,
      "record": "15-16"
    },
    "Mount St. Mary's": {
      "adjDef": 111.7,
      "adjDefRank": 292,
      "adjEM": -3.96,
      "adjOff": 107.8,
      "adjOffRank": 127,
      "adjTempo": 68.2,
      "adjTempoRank": 39,
      "conference": "NEC",
      "finish": "First Four",
      "rank": 209,
      "record": "16-17",
      "seed": 16
    },
    "Murray St.": {
      "adjDef": 105.8,
      "adjDefRank": 182,
      "adjEM": 3.23,
      "adjOff": 109,
      "adjOffRank": 107,
      "adjTempo": 66.2,
      "adjTempoRank": 100,
      "conference": "OVC",
      "rank": 135,
      "record": "23-11"
    },
    "N.C. State": {
      "adjDef": 102.4,
      "adjDefRank": 121,
      "adjEM": 11.6,
      "adjOff": 114,
      "adjOffRank": 34,
      "adjTempo": 64.7,
      "adjTempoRank": 172,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 62,
      "record": "22-14",
      "seed": 12
    },
    "NJIT": {
      "adjDef": 110.5,
      "adjDefRank": 270,
      "adjEM": -13.86,
      "adjOff": 96.6,
      "adjOffRank": 304,
      "adjTempo": 66.5,
      "adjTempoRank": 87,
      "conference": "ind",
      "rank": 307,
      "record": "13-16"
    },
    "Navy": {
      "adjDef": 106.8,
      "adjDefRank": 208,
      "adjEM": -13.87,
      "adjOff": 93,
      "adjOffRank": 339,
      "adjTempo": 61.8,
      "adjTempoRank": 309,
      "conference": "Pat",
      "rank": 308,
      "record": "9-21"
    },
    "Nebraska": {
      "adjDef": 96.1,
      "adjDefRank": 36,
      "adjEM": 12.64,
      "adjOff": 108.8,
      "adjOffRank": 111,
      "adjTempo": 64.7,
      "adjTempoRank": 170,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 56,
      "record": "19-13",
      "seed": 11
    },
    "Nebraska Omaha": {
      "adjDef": 105.4,
      "adjDefRank": 175,
      "adjEM": -0.86,
      "adjOff": 104.5,
      "adjOffRank": 190,
      "adjTempo": 72.2,
      "adjTempoRank": 4,
      "conference": "Sum",
      "rank": 177,
      "record": "17-15"
    },
    "Nevada": {
      "adjDef": 107.3,
      "adjDefRank": 215,
      "adjEM": 2.21,
      "adjOff": 109.5,
      "adjOffRank": 100,
      "adjTempo": 63.4,
      "adjTempoRank": 246,
      "conference": "MWC",
      "rank": 143,
      "record": "15-17"
    },
    "New Hampshire": {
      "adjDef": 106.4,
      "adjDefRank": 197,
      "adjEM": -17.67,
      "adjOff": 88.7,
      "adjOffRank": 349,
      "adjTempo": 61.2,
      "adjTempoRank": 322,
      "conference": "AE",
      "rank": 328,
      "record": "6-24"
    },
    "New Mexico": {
      "adjDef": 96.3,
      "adjDefRank": 39,
      "adjEM": 17.15,
      "adjOff": 113.5,
      "adjOffRank": 41,
      "adjTempo": 64.4,
      "adjTempoRank": 191,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "27-7",
      "seed": 7
    },
    "New Mexico St.": {
      "adjDef": 102.4,
      "adjDefRank": 117,
      "adjEM": 10.54,
      "adjOff": 112.9,
      "adjOffRank": 45,
      "adjTempo": 65.2,
      "adjTempoRank": 148,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 72,
      "record": "26-10",
      "seed": 13
    },
    "New Orleans": {
      "adjDef": 111.4,
      "adjDefRank": 284,
      "adjEM": -15.25,
      "adjOff": 96.1,
      "adjOffRank": 311,
      "adjTempo": 70.1,
      "adjTempoRank": 13,
      "conference": "Slnd",
      "rank": 317,
      "record": "11-15"
    },
    "Niagara": {
      "adjDef": 110.7,
      "adjDefRank": 274,
      "adjEM": -8.75,
      "adjOff": 101.9,
      "adjOffRank": 229,
      "adjTempo": 70.6,
      "adjTempoRank": 9,
      "conference": "MAAC",
      "rank": 270,
      "record": "7-26"
    },
    "Nicholls St.": {
      "adjDef": 111.6,
      "adjDefRank": 290,
      "adjEM": -13.64,
      "adjOff": 98,
      "adjOffRank": 295,
      "adjTempo": 63.6,
      "adjTempoRank": 235,
      "conference": "Slnd",
      "rank": 304,
      "record": "14-15"
    },
    "Norfolk St.": {
      "adjDef": 110.3,
      "adjDefRank": 267,
      "adjEM": -7.48,
      "adjOff": 102.9,
      "adjOffRank": 216,
      "adjTempo": 62.7,
      "adjTempoRank": 278,
      "conference": "MEAC",
      "rank": 258,
      "record": "19-15"
    },
    "North Carolina": {
      "adjDef": 94.2,
      "adjDefRank": 20,
      "adjEM": 18.44,
      "adjOff": 112.6,
      "adjOffRank": 48,
      "adjTempo": 69.2,
      "adjTempoRank": 18,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 29,
      "record": "24-10",
      "seed": 6
    },
    "North Carolina A&T": {
      "adjDef": 113.3,
      "adjDefRank": 321,
      "adjEM": -19.56,
      "adjOff": 93.7,
      "adjOffRank": 332,
      "adjTempo": 65.3,
      "adjTempoRank": 142,
      "conference": "MEAC",
      "rank": 334,
      "record": "9-23"
    },
    "North Carolina Central": {
      "adjDef": 100.7,
      "adjDefRank": 92,
      "adjEM": 7.98,
      "adjOff": 108.6,
      "adjOffRank": 114,
      "adjTempo": 62.3,
      "adjTempoRank": 293,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 92,
      "record": "28-6",
      "seed": 14
    },
    "North Dakota": {
      "adjDef": 112.1,
      "adjDefRank": 300,
      "adjEM": -7.79,
      "adjOff": 104.3,
      "adjOffRank": 193,
      "adjTempo": 67.9,
      "adjTempoRank": 42,
      "conference": "BSky",
      "rank": 264,
      "record": "17-17"
    },
    "North Dakota St.": {
      "adjDef": 103.7,
      "adjDefRank": 144,
      "adjEM": 11.76,
      "adjOff": 115.5,
      "adjOffRank": 25,
      "adjTempo": 62.3,
      "adjTempoRank": 292,
      "conference": "Sum",
      "finish": "Round of 32",
      "rank": 61,
      "record": "26-7",
      "seed": 12
    },
    "North Florida": {
      "adjDef": 111.1,
      "adjDefRank": 276,
      "adjEM": -7.75,
      "adjOff": 103.3,
      "adjOffRank": 208,
      "adjTempo": 66.7,
      "adjTempoRank": 77,
      "conference": "ASun",
      "rank": 263,
      "record": "16-16"
    },
    "North Texas": {
      "adjDef": 104.9,
      "adjDefRank": 166,
      "adjEM": -5.56,
      "adjOff": 99.4,
      "adjOffRank": 272,
      "adjTempo": 66.9,
      "adjTempoRank": 67,
      "conference": "CUSA",
      "rank": 232,
      "record": "16-16"
    },
    "Northeastern": {
      "adjDef": 104.5,
      "adjDefRank": 162,
      "adjEM": -3.16,
      "adjOff": 101.4,
      "adjOffRank": 237,
      "adjTempo": 63.2,
      "adjTempoRank": 253,
      "conference": "CAA",
      "rank": 203,
      "record": "11-21"
    },
    "Northern Arizona": {
      "adjDef": 108.1,
      "adjDefRank": 230,
      "adjEM": -7.72,
      "adjOff": 100.4,
      "adjOffRank": 258,
      "adjTempo": 62.3,
      "adjTempoRank": 291,
      "conference": "BSky",
      "rank": 262,
      "record": "15-17"
    },
    "Northern Colorado": {
      "adjDef": 114.1,
      "adjDefRank": 325,
      "adjEM": -5.3,
      "adjOff": 108.8,
      "adjOffRank": 110,
      "adjTempo": 62.7,
      "adjTempoRank": 275,
      "conference": "BSky",
      "rank": 227,
      "record": "18-14"
    },
    "Northern Illinois": {
      "adjDef": 98.3,
      "adjDefRank": 57,
      "adjEM": -3.94,
      "adjOff": 94.4,
      "adjOffRank": 327,
      "adjTempo": 61.5,
      "adjTempoRank": 315,
      "conference": "MAC",
      "rank": 208,
      "record": "15-17"
    },
    "Northern Iowa": {
      "adjDef": 105.1,
      "adjDefRank": 169,
      "adjEM": 7.86,
      "adjOff": 113,
      "adjOffRank": 44,
      "adjTempo": 64.1,
      "adjTempoRank": 212,
      "conference": "MVC",
      "rank": 95,
      "record": "16-15"
    },
    "Northern Kentucky": {
      "adjDef": 116.8,
      "adjDefRank": 340,
      "adjEM": -14.08,
      "adjOff": 102.7,
      "adjOffRank": 220,
      "adjTempo": 61.1,
      "adjTempoRank": 325,
      "conference": "ASun",
      "rank": 311,
      "record": "9-21"
    },
    "Northwestern": {
      "adjDef": 94.9,
      "adjDefRank": 23,
      "adjEM": 3.33,
      "adjOff": 98.3,
      "adjOffRank": 288,
      "adjTempo": 59.9,
      "adjTempoRank": 341,
      "conference": "B10",
      "rank": 134,
      "record": "14-19"
    },
    "Northwestern St.": {
      "adjDef": 109.9,
      "adjDefRank": 263,
      "adjEM": -1.31,
      "adjOff": 108.6,
      "adjOffRank": 115,
      "adjTempo": 74.2,
      "adjTempoRank": 1,
      "conference": "Slnd",
      "rank": 185,
      "record": "17-14"
    },
    "Notre Dame": {
      "adjDef": 105.8,
      "adjDefRank": 185,
      "adjEM": 7.7,
      "adjOff": 113.5,
      "adjOffRank": 40,
      "adjTempo": 62.7,
      "adjTempoRank": 276,
      "conference": "ACC",
      "rank": 96,
      "record": "15-17"
    },
    "Oakland": {
      "adjDef": 112,
      "adjDefRank": 299,
      "adjEM": -2.16,
      "adjOff": 109.9,
      "adjOffRank": 93,
      "adjTempo": 67.1,
      "adjTempoRank": 62,
      "conference": "Horz",
      "rank": 194,
      "record": "13-20"
    },
    "Ohio": {
      "adjDef": 102.7,
      "adjDefRank": 126,
      "adjEM": 3.76,
      "adjOff": 106.5,
      "adjOffRank": 147,
      "adjTempo": 64.3,
      "adjTempoRank": 197,
      "conference": "MAC",
      "rank": 127,
      "record": "25-12"
    },
    "Ohio St.": {
      "adjDef": 88,
      "adjDefRank": 2,
      "adjEM": 21.3,
      "adjOff": 109.3,
      "adjOffRank": 103,
      "adjTempo": 64,
      "adjTempoRank": 218,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 19,
      "record": "25-10",
      "seed": 6
    },
    "Oklahoma": {
      "adjDef": 100,
      "adjDefRank": 82,
      "adjEM": 17.9,
      "adjOff": 117.9,
      "adjOffRank": 16,
      "adjTempo": 69.6,
      "adjTempoRank": 17,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 30,
      "record": "23-10",
      "seed": 5
    },
    "Oklahoma St.": {
      "adjDef": 95,
      "adjDefRank": 24,
      "adjEM": 20.98,
      "adjOff": 115.9,
      "adjOffRank": 22,
      "adjTempo": 67.2,
      "adjTempoRank": 59,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 21,
      "record": "21-13",
      "seed": 9
    },
    "Old Dominion": {
      "adjDef": 101.4,
      "adjDefRank": 104,
      "adjEM": -0.72,
      "adjOff": 100.7,
      "adjOffRank": 251,
      "adjTempo": 62.5,
      "adjTempoRank": 283,
      "conference": "CUSA",
      "rank": 175,
      "record": "18-18"
    },
    "Oral Roberts": {
      "adjDef": 109.6,
      "adjDefRank": 258,
      "adjEM": -1.53,
      "adjOff": 108,
      "adjOffRank": 122,
      "adjTempo": 62.8,
      "adjTempoRank": 271,
      "conference": "Slnd",
      "rank": 188,
      "record": "17-16"
    },
    "Oregon": {
      "adjDef": 99.9,
      "adjDefRank": 79,
      "adjEM": 19.02,
      "adjOff": 118.9,
      "adjOffRank": 8,
      "adjTempo": 67.5,
      "adjTempoRank": 50,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 28,
      "record": "24-10",
      "seed": 7
    },
    "Oregon St.": {
      "adjDef": 106.7,
      "adjDefRank": 205,
      "adjEM": 5.81,
      "adjOff": 112.5,
      "adjOffRank": 52,
      "adjTempo": 65.5,
      "adjTempoRank": 130,
      "conference": "P12",
      "rank": 107,
      "record": "16-16"
    },
    "Pacific": {
      "adjDef": 107.5,
      "adjDefRank": 220,
      "adjEM": 1.98,
      "adjOff": 109.5,
      "adjOffRank": 99,
      "adjTempo": 66.1,
      "adjTempoRank": 102,
      "conference": "WCC",
      "rank": 148,
      "record": "18-16"
    },
    "Penn": {
      "adjDef": 106.6,
      "adjDefRank": 202,
      "adjEM": -7.46,
      "adjOff": 99.1,
      "adjOffRank": 274,
      "adjTempo": 68.2,
      "adjTempoRank": 38,
      "conference": "Ivy",
      "rank": 257,
      "record": "8-20"
    },
    "Penn St.": {
      "adjDef": 101.3,
      "adjDefRank": 103,
      "adjEM": 9,
      "adjOff": 110.3,
      "adjOffRank": 87,
      "adjTempo": 64.4,
      "adjTempoRank": 194,
      "conference": "B10",
      "rank": 81,
      "record": "16-18"
    },
    "Pepperdine": {
      "adjDef": 106.9,
      "adjDefRank": 211,
      "adjEM": 0.93,
      "adjOff": 107.8,
      "adjOffRank": 124,
      "adjTempo": 64.9,
      "adjTempoRank": 163,
      "conference": "WCC",
      "rank": 159,
      "record": "15-16"
    },
    "Pittsburgh": {
      "adjDef": 95,
      "adjDefRank": 25,
      "adjEM": 21.89,
      "adjOff": 116.9,
      "adjOffRank": 18,
      "adjTempo": 62.2,
      "adjTempoRank": 295,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 16,
      "record": "26-10",
      "seed": 9
    },
    "Portland": {
      "adjDef": 103.8,
      "adjDefRank": 147,
      "adjEM": 5.4,
      "adjOff": 109.2,
      "adjOffRank": 104,
      "adjTempo": 64.9,
      "adjTempoRank": 164,
      "conference": "WCC",
      "rank": 111,
      "record": "15-16"
    },
    "Portland St.": {
      "adjDef": 111.7,
      "adjDefRank": 293,
      "adjEM": -9.47,
      "adjOff": 102.3,
      "adjOffRank": 224,
      "adjTempo": 64.9,
      "adjTempoRank": 162,
      "conference": "BSky",
      "rank": 278,
      "record": "17-15"
    },
    "Prairie View A&M": {
      "adjDef": 113,
      "adjDefRank": 318,
      "adjEM": -16.86,
      "adjOff": 96.1,
      "adjOffRank": 312,
      "adjTempo": 65.9,
      "adjTempoRank": 113,
      "conference": "SWAC",
      "rank": 325,
      "record": "11-23"
    },
    "Presbyterian": {
      "adjDef": 120,
      "adjDefRank": 349,
      "adjEM": -25.87,
      "adjOff": 94.2,
      "adjOffRank": 328,
      "adjTempo": 63.5,
      "adjTempoRank": 239,
      "conference": "BSth",
      "rank": 349,
      "record": "6-26"
    },
    "Princeton": {
      "adjDef": 99.9,
      "adjDefRank": 81,
      "adjEM": 6.46,
      "adjOff": 106.4,
      "adjOffRank": 150,
      "adjTempo": 63.2,
      "adjTempoRank": 252,
      "conference": "Ivy",
      "rank": 100,
      "record": "21-9"
    },
    "Providence": {
      "adjDef": 100.8,
      "adjDefRank": 94,
      "adjEM": 13.81,
      "adjOff": 114.6,
      "adjOffRank": 30,
      "adjTempo": 62.9,
      "adjTempoRank": 264,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 48,
      "record": "23-12",
      "seed": 11
    },
    "Purdue": {
      "adjDef": 100.5,
      "adjDefRank": 90,
      "adjEM": 7.89,
      "adjOff": 108.4,
      "adjOffRank": 119,
      "adjTempo": 67.2,
      "adjTempoRank": 61,
      "conference": "B10",
      "rank": 93,
      "record": "15-17"
    },
    "Quinnipiac": {
      "adjDef": 109.4,
      "adjDefRank": 254,
      "adjEM": 1.89,
      "adjOff": 111.3,
      "adjOffRank": 66,
      "adjTempo": 68.3,
      "adjTempoRank": 37,
      "conference": "MAAC",
      "rank": 149,
      "record": "20-12"
    },
    "Radford": {
      "adjDef": 114.3,
      "adjDefRank": 327,
      "adjEM": -7.36,
      "adjOff": 106.9,
      "adjOffRank": 138,
      "adjTempo": 66.5,
      "adjTempoRank": 89,
      "conference": "BSth",
      "rank": 256,
      "record": "22-13"
    },
    "Rhode Island": {
      "adjDef": 99.3,
      "adjDefRank": 67,
      "adjEM": 5.06,
      "adjOff": 104.4,
      "adjOffRank": 192,
      "adjTempo": 64.1,
      "adjTempoRank": 213,
      "conference": "A10",
      "rank": 115,
      "record": "14-18"
    },
    "Rice": {
      "adjDef": 107.9,
      "adjDefRank": 227,
      "adjEM": -13.23,
      "adjOff": 94.7,
      "adjOffRank": 324,
      "adjTempo": 62.4,
      "adjTempoRank": 289,
      "conference": "CUSA",
      "rank": 299,
      "record": "7-23"
    },
    "Richmond": {
      "adjDef": 97.3,
      "adjDefRank": 52,
      "adjEM": 7.99,
      "adjOff": 105.3,
      "adjOffRank": 178,
      "adjTempo": 61.8,
      "adjTempoRank": 308,
      "conference": "A10",
      "rank": 91,
      "record": "19-14"
    },
    "Rider": {
      "adjDef": 111.4,
      "adjDefRank": 286,
      "adjEM": -4.52,
      "adjOff": 106.9,
      "adjOffRank": 139,
      "adjTempo": 65.4,
      "adjTempoRank": 137,
      "conference": "MAAC",
      "rank": 217,
      "record": "14-17"
    },
    "Robert Morris": {
      "adjDef": 106.5,
      "adjDefRank": 198,
      "adjEM": -0.65,
      "adjOff": 105.8,
      "adjOffRank": 165,
      "adjTempo": 64.2,
      "adjTempoRank": 201,
      "conference": "NEC",
      "rank": 173,
      "record": "22-14"
    },
    "Rutgers": {
      "adjDef": 106.9,
      "adjDefRank": 209,
      "adjEM": -0.37,
      "adjOff": 106.5,
      "adjOffRank": 148,
      "adjTempo": 66.4,
      "adjTempoRank": 91,
      "conference": "Amer",
      "rank": 170,
      "record": "12-21"
    },
    "SIU Edwardsville": {
      "adjDef": 112.5,
      "adjDefRank": 307,
      "adjEM": -11.94,
      "adjOff": 100.6,
      "adjOffRank": 254,
      "adjTempo": 65.4,
      "adjTempoRank": 139,
      "conference": "OVC",
      "rank": 290,
      "record": "11-20"
    },
    "SMU": {
      "adjDef": 93.7,
      "adjDefRank": 15,
      "adjEM": 17.66,
      "adjOff": 111.3,
      "adjOffRank": 65,
      "adjTempo": 64.5,
      "adjTempoRank": 183,
      "conference": "Amer",
      "rank": 31,
      "record": "27-10"
    },
    "Sacramento St.": {
      "adjDef": 112.8,
      "adjDefRank": 315,
      "adjEM": -9.33,
      "adjOff": 103.4,
      "adjOffRank": 205,
      "adjTempo": 62.8,
      "adjTempoRank": 274,
      "conference": "BSky",
      "rank": 276,
      "record": "14-16"
    },
    "Sacred Heart": {
      "adjDef": 112.7,
      "adjDefRank": 313,
      "adjEM": -13.4,
      "adjOff": 99.3,
      "adjOffRank": 273,
      "adjTempo": 65.9,
      "adjTempoRank": 111,
      "conference": "NEC",
      "rank": 301,
      "record": "5-26"
    },
    "Saint Joseph's": {
      "adjDef": 99.4,
      "adjDefRank": 68,
      "adjEM": 12.93,
      "adjOff": 112.3,
      "adjOffRank": 57,
      "adjTempo": 64.3,
      "adjTempoRank": 198,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 55,
      "record": "24-10",
      "seed": 10
    },
    "Saint Louis": {
      "adjDef": 90,
      "adjDefRank": 8,
      "adjEM": 15.72,
      "adjOff": 105.7,
      "adjOffRank": 168,
      "adjTempo": 65.6,
      "adjTempoRank": 124,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 35,
      "record": "27-7",
      "seed": 5
    },
    "Saint Mary's": {
      "adjDef": 101.2,
      "adjDefRank": 101,
      "adjEM": 11.39,
      "adjOff": 112.6,
      "adjOffRank": 49,
      "adjTempo": 61.4,
      "adjTempoRank": 317,
      "conference": "WCC",
      "rank": 65,
      "record": "23-12"
    },
    "Saint Peter's": {
      "adjDef": 103.9,
      "adjDefRank": 150,
      "adjEM": -5.86,
      "adjOff": 98.1,
      "adjOffRank": 293,
      "adjTempo": 60.5,
      "adjTempoRank": 335,
      "conference": "MAAC",
      "rank": 238,
      "record": "14-17"
    },
    "Sam Houston St.": {
      "adjDef": 108.4,
      "adjDefRank": 234,
      "adjEM": -2.57,
      "adjOff": 105.8,
      "adjOffRank": 166,
      "adjTempo": 64.5,
      "adjTempoRank": 182,
      "conference": "Slnd",
      "rank": 197,
      "record": "24-11"
    },
    "Samford": {
      "adjDef": 116.6,
      "adjDefRank": 338,
      "adjEM": -15.64,
      "adjOff": 100.9,
      "adjOffRank": 247,
      "adjTempo": 65.3,
      "adjTempoRank": 145,
      "conference": "SC",
      "rank": 321,
      "record": "13-20"
    },
    "San Diego": {
      "adjDef": 102.5,
      "adjDefRank": 122,
      "adjEM": 2.98,
      "adjOff": 105.4,
      "adjOffRank": 172,
      "adjTempo": 61.7,
      "adjTempoRank": 312,
      "conference": "WCC",
      "rank": 138,
      "record": "18-17"
    },
    "San Diego St.": {
      "adjDef": 90,
      "adjDefRank": 7,
      "adjEM": 20.38,
      "adjOff": 110.4,
      "adjOffRank": 86,
      "adjTempo": 62.3,
      "adjTempoRank": 294,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 23,
      "record": "31-5",
      "seed": 4
    },
    "San Francisco": {
      "adjDef": 104.3,
      "adjDefRank": 154,
      "adjEM": 8.26,
      "adjOff": 112.5,
      "adjOffRank": 51,
      "adjTempo": 63.6,
      "adjTempoRank": 231,
      "conference": "WCC",
      "rank": 88,
      "record": "21-12"
    },
    "San Jose St.": {
      "adjDef": 106.3,
      "adjDefRank": 194,
      "adjEM": -11.75,
      "adjOff": 94.5,
      "adjOffRank": 325,
      "adjTempo": 64.1,
      "adjTempoRank": 214,
      "conference": "MWC",
      "rank": 289,
      "record": "7-24"
    },
    "Santa Clara": {
      "adjDef": 106.1,
      "adjDefRank": 191,
      "adjEM": 1.18,
      "adjOff": 107.3,
      "adjOffRank": 133,
      "adjTempo": 63.8,
      "adjTempoRank": 225,
      "conference": "WCC",
      "rank": 154,
      "record": "14-19"
    },
    "Savannah St.": {
      "adjDef": 101.6,
      "adjDefRank": 109,
      "adjEM": -9.93,
      "adjOff": 91.7,
      "adjOffRank": 345,
      "adjTempo": 63,
      "adjTempoRank": 260,
      "conference": "MEAC",
      "rank": 281,
      "record": "13-19"
    },
    "Seattle": {
      "adjDef": 106.3,
      "adjDefRank": 196,
      "adjEM": -5.95,
      "adjOff": 100.4,
      "adjOffRank": 259,
      "adjTempo": 65.3,
      "adjTempoRank": 143,
      "conference": "WAC",
      "rank": 240,
      "record": "13-17"
    },
    "Seton Hall": {
      "adjDef": 100.9,
      "adjDefRank": 98,
      "adjEM": 7.86,
      "adjOff": 108.8,
      "adjOffRank": 112,
      "adjTempo": 64.2,
      "adjTempoRank": 206,
      "conference": "BE",
      "rank": 94,
      "record": "17-17"
    },
    "Siena": {
      "adjDef": 101.3,
      "adjDefRank": 102,
      "adjEM": 0.33,
      "adjOff": 101.6,
      "adjOffRank": 234,
      "adjTempo": 64.6,
      "adjTempoRank": 175,
      "conference": "MAAC",
      "rank": 164,
      "record": "20-18"
    },
    "South Alabama": {
      "adjDef": 108.1,
      "adjDefRank": 232,
      "adjEM": -7.06,
      "adjOff": 101.1,
      "adjOffRank": 245,
      "adjTempo": 64.5,
      "adjTempoRank": 187,
      "conference": "SB",
      "rank": 253,
      "record": "11-20"
    },
    "South Carolina": {
      "adjDef": 102.2,
      "adjDefRank": 115,
      "adjEM": 5.3,
      "adjOff": 107.5,
      "adjOffRank": 131,
      "adjTempo": 65.4,
      "adjTempoRank": 134,
      "conference": "SEC",
      "rank": 112,
      "record": "14-20"
    },
    "South Carolina St.": {
      "adjDef": 112.7,
      "adjDefRank": 312,
      "adjEM": -21.14,
      "adjOff": 91.5,
      "adjOffRank": 348,
      "adjTempo": 64.6,
      "adjTempoRank": 180,
      "conference": "MEAC",
      "rank": 344,
      "record": "9-21"
    },
    "South Dakota": {
      "adjDef": 108.9,
      "adjDefRank": 246,
      "adjEM": -7.13,
      "adjOff": 101.7,
      "adjOffRank": 232,
      "adjTempo": 65.5,
      "adjTempoRank": 129,
      "conference": "Sum",
      "rank": 255,
      "record": "12-18"
    },
    "South Dakota St.": {
      "adjDef": 104.2,
      "adjDefRank": 153,
      "adjEM": 2.77,
      "adjOff": 107,
      "adjOffRank": 137,
      "adjTempo": 65.5,
      "adjTempoRank": 133,
      "conference": "Sum",
      "rank": 140,
      "record": "19-13"
    },
    "South Florida": {
      "adjDef": 104.3,
      "adjDefRank": 155,
      "adjEM": -1.9,
      "adjOff": 102.4,
      "adjOffRank": 223,
      "adjTempo": 63.9,
      "adjTempoRank": 222,
      "conference": "Amer",
      "rank": 191,
      "record": "12-20"
    },
    "Southeast Missouri St.": {
      "adjDef": 113.7,
      "adjDefRank": 323,
      "adjEM": -5.22,
      "adjOff": 108.4,
      "adjOffRank": 118,
      "adjTempo": 69,
      "adjTempoRank": 20,
      "conference": "OVC",
      "rank": 226,
      "record": "18-14"
    },
    "Southeastern Louisiana": {
      "adjDef": 112.8,
      "adjDefRank": 317,
      "adjEM": -14.15,
      "adjOff": 98.7,
      "adjOffRank": 280,
      "adjTempo": 63.2,
      "adjTempoRank": 255,
      "conference": "Slnd",
      "rank": 312,
      "record": "12-18"
    },
    "Southern": {
      "adjDef": 100.2,
      "adjDefRank": 83,
      "adjEM": -2.58,
      "adjOff": 97.6,
      "adjOffRank": 297,
      "adjTempo": 65.6,
      "adjTempoRank": 122,
      "conference": "SWAC",
      "rank": 198,
      "record": "19-13"
    },
    "Southern Illinois": {
      "adjDef": 106,
      "adjDefRank": 187,
      "adjEM": 0.17,
      "adjOff": 106.1,
      "adjOffRank": 157,
      "adjTempo": 61.5,
      "adjTempoRank": 316,
      "conference": "MVC",
      "rank": 165,
      "record": "14-19"
    },
    "Southern Miss": {
      "adjDef": 99.4,
      "adjDefRank": 69,
      "adjEM": 11.51,
      "adjOff": 110.9,
      "adjOffRank": 73,
      "adjTempo": 62.9,
      "adjTempoRank": 265,
      "conference": "CUSA",
      "rank": 64,
      "record": "29-7"
    },
    "Southern Utah": {
      "adjDef": 114.6,
      "adjDefRank": 330,
      "adjEM": -27.14,
      "adjOff": 87.4,
      "adjOffRank": 351,
      "adjTempo": 64.2,
      "adjTempoRank": 209,
      "conference": "BSky",
      "rank": 351,
      "record": "2-27"
    },
    "St. Bonaventure": {
      "adjDef": 102.4,
      "adjDefRank": 118,
      "adjEM": 8.75,
      "adjOff": 111.2,
      "adjOffRank": 69,
      "adjTempo": 65.8,
      "adjTempoRank": 117,
      "conference": "A10",
      "rank": 84,
      "record": "18-15"
    },
    "St. Francis NY": {
      "adjDef": 101.5,
      "adjDefRank": 107,
      "adjEM": -2.65,
      "adjOff": 98.9,
      "adjOffRank": 278,
      "adjTempo": 65,
      "adjTempoRank": 158,
      "conference": "NEC",
      "rank": 199,
      "record": "18-14"
    },
    "St. Francis PA": {
      "adjDef": 111.1,
      "adjDefRank": 279,
      "adjEM": -14.76,
      "adjOff": 96.4,
      "adjOffRank": 308,
      "adjTempo": 61.1,
      "adjTempoRank": 326,
      "conference": "NEC",
      "rank": 314,
      "record": "10-21"
    },
    "St. John's": {
      "adjDef": 95.4,
      "adjDefRank": 30,
      "adjEM": 13.1,
      "adjOff": 108.5,
      "adjOffRank": 116,
      "adjTempo": 66.6,
      "adjTempoRank": 78,
      "conference": "BE",
      "rank": 54,
      "record": "20-13"
    },
    "Stanford": {
      "adjDef": 96.4,
      "adjDefRank": 40,
      "adjEM": 16.06,
      "adjOff": 112.4,
      "adjOffRank": 54,
      "adjTempo": 65.3,
      "adjTempoRank": 147,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 34,
      "record": "23-13",
      "seed": 10
    },
    "Stephen F. Austin": {
      "adjDef": 101.5,
      "adjDefRank": 105,
      "adjEM": 10.83,
      "adjOff": 112.3,
      "adjOffRank": 58,
      "adjTempo": 61.2,
      "adjTempoRank": 323,
      "conference": "Slnd",
      "finish": "Round of 32",
      "rank": 70,
      "record": "32-3",
      "seed": 12
    },
    "Stetson": {
      "adjDef": 114.5,
      "adjDefRank": 329,
      "adjEM": -20.02,
      "adjOff": 94.5,
      "adjOffRank": 326,
      "adjTempo": 61.3,
      "adjTempoRank": 320,
      "conference": "ASun",
      "rank": 335,
      "record": "7-24"
    },
    "Stony Brook": {
      "adjDef": 105.4,
      "adjDefRank": 173,
      "adjEM": 0,
      "adjOff": 105.4,
      "adjOffRank": 173,
      "adjTempo": 64.4,
      "adjTempoRank": 193,
      "conference": "AE",
      "rank": 169,
      "record": "23-11"
    },
    "Syracuse": {
      "adjDef": 93,
      "adjDefRank": 13,
      "adjEM": 21.62,
      "adjOff": 114.6,
      "adjOffRank": 28,
      "adjTempo": 59.6,
      "adjTempoRank": 344,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 18,
      "record": "28-6",
      "seed": 3
    },
    "TCU": {
      "adjDef": 103.7,
      "adjDefRank": 146,
      "adjEM": -4.34,
      "adjOff": 99.4,
      "adjOffRank": 271,
      "adjTempo": 63.8,
      "adjTempoRank": 224,
      "conference": "B12",
      "rank": 212,
      "record": "9-22"
    },
    "Temple": {
      "adjDef": 109.1,
      "adjDefRank": 249,
      "adjEM": 1.68,
      "adjOff": 110.7,
      "adjOffRank": 76,
      "adjTempo": 66.3,
      "adjTempoRank": 94,
      "conference": "Amer",
      "rank": 150,
      "record": "9-22"
    },
    "Tennessee": {
      "adjDef": 94.2,
      "adjDefRank": 19,
      "adjEM": 23.69,
      "adjOff": 117.9,
      "adjOffRank": 15,
      "adjTempo": 61.6,
      "adjTempoRank": 313,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "24-13",
      "seed": 11
    },
    "Tennessee Martin": {
      "adjDef": 118.6,
      "adjDefRank": 346,
      "adjEM": -15.4,
      "adjOff": 103.2,
      "adjOffRank": 211,
      "adjTempo": 67.4,
      "adjTempoRank": 56,
      "conference": "OVC",
      "rank": 318,
      "record": "8-23"
    },
    "Tennessee St.": {
      "adjDef": 113.6,
      "adjDefRank": 322,
      "adjEM": -11.71,
      "adjOff": 101.9,
      "adjOffRank": 231,
      "adjTempo": 63.2,
      "adjTempoRank": 256,
      "conference": "OVC",
      "rank": 288,
      "record": "5-25"
    },
    "Tennessee Tech": {
      "adjDef": 108.5,
      "adjDefRank": 236,
      "adjEM": -8.28,
      "adjOff": 100.2,
      "adjOffRank": 263,
      "adjTempo": 66.2,
      "adjTempoRank": 99,
      "conference": "OVC",
      "rank": 268,
      "record": "17-16"
    },
    "Texas": {
      "adjDef": 97.3,
      "adjDefRank": 53,
      "adjEM": 14.52,
      "adjOff": 111.8,
      "adjOffRank": 61,
      "adjTempo": 66.8,
      "adjTempoRank": 70,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 41,
      "record": "24-11",
      "seed": 7
    },
    "Texas A&M": {
      "adjDef": 95.9,
      "adjDefRank": 34,
      "adjEM": 5.41,
      "adjOff": 101.4,
      "adjOffRank": 239,
      "adjTempo": 61.8,
      "adjTempoRank": 311,
      "conference": "SEC",
      "rank": 110,
      "record": "18-16"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 110.6,
      "adjDefRank": 272,
      "adjEM": -5.47,
      "adjOff": 105.2,
      "adjOffRank": 180,
      "adjTempo": 60.5,
      "adjTempoRank": 336,
      "conference": "Slnd",
      "rank": 230,
      "record": "18-16"
    },
    "Texas Pan American": {
      "adjDef": 106.5,
      "adjDefRank": 200,
      "adjEM": -12.8,
      "adjOff": 93.7,
      "adjOffRank": 333,
      "adjTempo": 64.1,
      "adjTempoRank": 215,
      "conference": "WAC",
      "rank": 295,
      "record": "9-23"
    },
    "Texas Southern": {
      "adjDef": 114.1,
      "adjDefRank": 326,
      "adjEM": -7.49,
      "adjOff": 106.7,
      "adjOffRank": 142,
      "adjTempo": 65.7,
      "adjTempoRank": 120,
      "conference": "SWAC",
      "finish": "First Four",
      "rank": 259,
      "record": "19-15",
      "seed": 16
    },
    "Texas St.": {
      "adjDef": 104.3,
      "adjDefRank": 157,
      "adjEM": -9.41,
      "adjOff": 94.9,
      "adjOffRank": 321,
      "adjTempo": 61.1,
      "adjTempoRank": 327,
      "conference": "SB",
      "rank": 277,
      "record": "8-23"
    },
    "Texas Tech": {
      "adjDef": 102.4,
      "adjDefRank": 120,
      "adjEM": 9.01,
      "adjOff": 111.4,
      "adjOffRank": 64,
      "adjTempo": 59.9,
      "adjTempoRank": 340,
      "conference": "B12",
      "rank": 80,
      "record": "14-18"
    },
    "The Citadel": {
      "adjDef": 118.5,
      "adjDefRank": 345,
      "adjEM": -22.24,
      "adjOff": 96.3,
      "adjOffRank": 309,
      "adjTempo": 63.5,
      "adjTempoRank": 240,
      "conference": "SC",
      "rank": 345,
      "record": "7-26"
    },
    "Toledo": {
      "adjDef": 109.1,
      "adjDefRank": 250,
      "adjEM": 5.97,
      "adjOff": 115,
      "adjOffRank": 26,
      "adjTempo": 67,
      "adjTempoRank": 65,
      "conference": "MAC",
      "rank": 104,
      "record": "27-7"
    },
    "Towson": {
      "adjDef": 107.2,
      "adjDefRank": 214,
      "adjEM": 3.45,
      "adjOff": 110.6,
      "adjOffRank": 79,
      "adjTempo": 63.9,
      "adjTempoRank": 220,
      "conference": "CAA",
      "rank": 130,
      "record": "25-11"
    },
    "Troy": {
      "adjDef": 108.8,
      "adjDefRank": 245,
      "adjEM": -6.82,
      "adjOff": 101.9,
      "adjOffRank": 228,
      "adjTempo": 63.3,
      "adjTempoRank": 248,
      "conference": "SB",
      "rank": 250,
      "record": "11-20"
    },
    "Tulane": {
      "adjDef": 111.1,
      "adjDefRank": 280,
      "adjEM": -11.41,
      "adjOff": 99.7,
      "adjOffRank": 268,
      "adjTempo": 61.9,
      "adjTempoRank": 306,
      "conference": "CUSA",
      "rank": 285,
      "record": "17-17"
    },
    "Tulsa": {
      "adjDef": 97.2,
      "adjDefRank": 51,
      "adjEM": 8.93,
      "adjOff": 106.1,
      "adjOffRank": 159,
      "adjTempo": 66.8,
      "adjTempoRank": 72,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 82,
      "record": "21-13",
      "seed": 13
    },
    "UAB": {
      "adjDef": 103.1,
      "adjDefRank": 131,
      "adjEM": 1.02,
      "adjOff": 104.1,
      "adjOffRank": 197,
      "adjTempo": 66.8,
      "adjTempoRank": 73,
      "conference": "CUSA",
      "rank": 156,
      "record": "18-13"
    },
    "UC Davis": {
      "adjDef": 116.4,
      "adjDefRank": 337,
      "adjEM": -15.16,
      "adjOff": 101.3,
      "adjOffRank": 241,
      "adjTempo": 64.8,
      "adjTempoRank": 166,
      "conference": "BW",
      "rank": 316,
      "record": "9-22"
    },
    "UC Irvine": {
      "adjDef": 95.7,
      "adjDefRank": 31,
      "adjEM": 8.16,
      "adjOff": 103.9,
      "adjOffRank": 199,
      "adjTempo": 62.8,
      "adjTempoRank": 269,
      "conference": "BW",
      "rank": 90,
      "record": "23-12"
    },
    "UC Riverside": {
      "adjDef": 107.7,
      "adjDefRank": 223,
      "adjEM": -9,
      "adjOff": 98.7,
      "adjOffRank": 281,
      "adjTempo": 65.8,
      "adjTempoRank": 114,
      "conference": "BW",
      "rank": 273,
      "record": "10-21"
    },
    "UC Santa Barbara": {
      "adjDef": 104.4,
      "adjDefRank": 158,
      "adjEM": 6.25,
      "adjOff": 110.6,
      "adjOffRank": 81,
      "adjTempo": 62.1,
      "adjTempoRank": 298,
      "conference": "BW",
      "rank": 102,
      "record": "21-9"
    },
    "UCF": {
      "adjDef": 106.1,
      "adjDefRank": 190,
      "adjEM": 1.05,
      "adjOff": 107.1,
      "adjOffRank": 135,
      "adjTempo": 66.3,
      "adjTempoRank": 96,
      "conference": "Amer",
      "rank": 155,
      "record": "13-18"
    },
    "UCLA": {
      "adjDef": 96.1,
      "adjDefRank": 37,
      "adjEM": 22.53,
      "adjOff": 118.7,
      "adjOffRank": 11,
      "adjTempo": 68.4,
      "adjTempoRank": 33,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "28-9",
      "seed": 4
    },
    "UMBC": {
      "adjDef": 108.7,
      "adjDefRank": 242,
      "adjEM": -16.07,
      "adjOff": 92.6,
      "adjOffRank": 340,
      "adjTempo": 66.6,
      "adjTempoRank": 84,
      "conference": "AE",
      "rank": 323,
      "record": "9-21"
    },
    "UMKC": {
      "adjDef": 107.4,
      "adjDefRank": 218,
      "adjEM": -8.94,
      "adjOff": 98.4,
      "adjOffRank": 286,
      "adjTempo": 68.3,
      "adjTempoRank": 35,
      "conference": "WAC",
      "rank": 272,
      "record": "10-20"
    },
    "UMass Lowell": {
      "adjDef": 111.4,
      "adjDefRank": 285,
      "adjEM": -16.04,
      "adjOff": 95.3,
      "adjOffRank": 318,
      "adjTempo": 62.7,
      "adjTempoRank": 280,
      "conference": "AE",
      "rank": 322,
      "record": "10-18"
    },
    "UNC Asheville": {
      "adjDef": 112.5,
      "adjDefRank": 306,
      "adjEM": -5.75,
      "adjOff": 106.7,
      "adjOffRank": 141,
      "adjTempo": 69,
      "adjTempoRank": 21,
      "conference": "BSth",
      "rank": 235,
      "record": "17-15"
    },
    "UNC Greensboro": {
      "adjDef": 108.9,
      "adjDefRank": 247,
      "adjEM": -15,
      "adjOff": 93.9,
      "adjOffRank": 331,
      "adjTempo": 68.8,
      "adjTempoRank": 24,
      "conference": "SC",
      "rank": 315,
      "record": "14-18"
    },
    "UNC Wilmington": {
      "adjDef": 105.7,
      "adjDefRank": 180,
      "adjEM": -9.67,
      "adjOff": 96,
      "adjOffRank": 313,
      "adjTempo": 62.2,
      "adjTempoRank": 296,
      "conference": "CAA",
      "rank": 280,
      "record": "9-23"
    },
    "UNLV": {
      "adjDef": 99,
      "adjDefRank": 62,
      "adjEM": 9.15,
      "adjOff": 108.2,
      "adjOffRank": 121,
      "adjTempo": 65.2,
      "adjTempoRank": 150,
      "conference": "MWC",
      "rank": 79,
      "record": "20-13"
    },
    "USC": {
      "adjDef": 102,
      "adjDefRank": 112,
      "adjEM": 1.53,
      "adjOff": 103.5,
      "adjOffRank": 203,
      "adjTempo": 68.4,
      "adjTempoRank": 34,
      "conference": "P12",
      "rank": 151,
      "record": "11-21"
    },
    "USC Upstate": {
      "adjDef": 104.9,
      "adjDefRank": 165,
      "adjEM": 0.1,
      "adjOff": 105,
      "adjOffRank": 185,
      "adjTempo": 64.2,
      "adjTempoRank": 207,
      "conference": "ASun",
      "rank": 167,
      "record": "19-15"
    },
    "UT Arlington": {
      "adjDef": 110.3,
      "adjDefRank": 266,
      "adjEM": -4.42,
      "adjOff": 105.9,
      "adjOffRank": 164,
      "adjTempo": 68.2,
      "adjTempoRank": 40,
      "conference": "SB",
      "rank": 215,
      "record": "15-17"
    },
    "UTEP": {
      "adjDef": 99.3,
      "adjDefRank": 65,
      "adjEM": 6.02,
      "adjOff": 105.3,
      "adjOffRank": 175,
      "adjTempo": 63,
      "adjTempoRank": 262,
      "conference": "CUSA",
      "rank": 103,
      "record": "23-11"
    },
    "UTSA": {
      "adjDef": 115.6,
      "adjDefRank": 331,
      "adjEM": -14.68,
      "adjOff": 100.9,
      "adjOffRank": 248,
      "adjTempo": 66.5,
      "adjTempoRank": 86,
      "conference": "CUSA",
      "rank": 313,
      "record": "8-22"
    },
    "Utah": {
      "adjDef": 95.9,
      "adjDefRank": 33,
      "adjEM": 14.81,
      "adjOff": 110.7,
      "adjOffRank": 77,
      "adjTempo": 63.6,
      "adjTempoRank": 230,
      "conference": "P12",
      "rank": 40,
      "record": "21-12"
    },
    "Utah St.": {
      "adjDef": 105.3,
      "adjDefRank": 172,
      "adjEM": 4.48,
      "adjOff": 109.8,
      "adjOffRank": 94,
      "adjTempo": 62.2,
      "adjTempoRank": 297,
      "conference": "MWC",
      "rank": 121,
      "record": "18-14"
    },
    "Utah Valley": {
      "adjDef": 106,
      "adjDefRank": 189,
      "adjEM": -3.19,
      "adjOff": 102.8,
      "adjOffRank": 218,
      "adjTempo": 61.1,
      "adjTempoRank": 329,
      "conference": "WAC",
      "rank": 204,
      "record": "20-12"
    },
    "VCU": {
      "adjDef": 89.4,
      "adjDefRank": 6,
      "adjEM": 19.8,
      "adjOff": 109.2,
      "adjOffRank": 106,
      "adjTempo": 68.9,
      "adjTempoRank": 22,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 25,
      "record": "26-9",
      "seed": 5
    },
    "VMI": {
      "adjDef": 112.2,
      "adjDefRank": 302,
      "adjEM": -3.97,
      "adjOff": 108.3,
      "adjOffRank": 120,
      "adjTempo": 73.3,
      "adjTempoRank": 2,
      "conference": "BSth",
      "rank": 210,
      "record": "22-13"
    },
    "Valparaiso": {
      "adjDef": 103.7,
      "adjDefRank": 145,
      "adjEM": 0.5,
      "adjOff": 104.2,
      "adjOffRank": 194,
      "adjTempo": 63.4,
      "adjTempoRank": 245,
      "conference": "Horz",
      "rank": 163,
      "record": "18-16"
    },
    "Vanderbilt": {
      "adjDef": 98.6,
      "adjDefRank": 59,
      "adjEM": 5.49,
      "adjOff": 104.1,
      "adjOffRank": 196,
      "adjTempo": 61.9,
      "adjTempoRank": 302,
      "conference": "SEC",
      "rank": 109,
      "record": "15-16"
    },
    "Vermont": {
      "adjDef": 99.7,
      "adjDefRank": 75,
      "adjEM": 10.16,
      "adjOff": 109.9,
      "adjOffRank": 92,
      "adjTempo": 62.1,
      "adjTempoRank": 299,
      "conference": "AE",
      "rank": 75,
      "record": "22-11"
    },
    "Villanova": {
      "adjDef": 92.6,
      "adjDefRank": 12,
      "adjEM": 23.68,
      "adjOff": 116.3,
      "adjOffRank": 21,
      "adjTempo": 66,
      "adjTempoRank": 103,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 11,
      "record": "29-5",
      "seed": 2
    },
    "Virginia": {
      "adjDef": 88.3,
      "adjDefRank": 4,
      "adjEM": 26.46,
      "adjOff": 114.8,
      "adjOffRank": 27,
      "adjTempo": 59.5,
      "adjTempoRank": 345,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 4,
      "record": "30-7",
      "seed": 1
    },
    "Virginia Tech": {
      "adjDef": 100.8,
      "adjDefRank": 95,
      "adjEM": -0.68,
      "adjOff": 100.1,
      "adjOffRank": 265,
      "adjTempo": 62.3,
      "adjTempoRank": 290,
      "conference": "ACC",
      "rank": 174,
      "record": "9-22"
    },
    "Wagner": {
      "adjDef": 100.3,
      "adjDefRank": 84,
      "adjEM": -1.7,
      "adjOff": 98.6,
      "adjOffRank": 282,
      "adjTempo": 66.6,
      "adjTempoRank": 81,
      "conference": "NEC",
      "rank": 189,
      "record": "19-12"
    },
    "Wake Forest": {
      "adjDef": 101.6,
      "adjDefRank": 108,
      "adjEM": 4.99,
      "adjOff": 106.6,
      "adjOffRank": 144,
      "adjTempo": 66.4,
      "adjTempoRank": 93,
      "conference": "ACC",
      "rank": 117,
      "record": "17-16"
    },
    "Washington": {
      "adjDef": 105,
      "adjDefRank": 168,
      "adjEM": 6.78,
      "adjOff": 111.8,
      "adjOffRank": 62,
      "adjTempo": 66.7,
      "adjTempoRank": 76,
      "conference": "P12",
      "rank": 98,
      "record": "17-15"
    },
    "Washington St.": {
      "adjDef": 103.6,
      "adjDefRank": 141,
      "adjEM": -1.84,
      "adjOff": 101.7,
      "adjOffRank": 233,
      "adjTempo": 60.5,
      "adjTempoRank": 338,
      "conference": "P12",
      "rank": 190,
      "record": "10-21"
    },
    "Weber St.": {
      "adjDef": 107,
      "adjDefRank": 213,
      "adjEM": -0.62,
      "adjOff": 106.4,
      "adjOffRank": 149,
      "adjTempo": 62.9,
      "adjTempoRank": 263,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 172,
      "record": "19-12",
      "seed": 16
    },
    "West Virginia": {
      "adjDef": 103.2,
      "adjDefRank": 135,
      "adjEM": 12.29,
      "adjOff": 115.5,
      "adjOffRank": 24,
      "adjTempo": 66.3,
      "adjTempoRank": 97,
      "conference": "B12",
      "rank": 58,
      "record": "17-16"
    },
    "Western Carolina": {
      "adjDef": 109.5,
      "adjDefRank": 257,
      "adjEM": -5.82,
      "adjOff": 103.7,
      "adjOffRank": 200,
      "adjTempo": 65.6,
      "adjTempoRank": 127,
      "conference": "SC",
      "rank": 237,
      "record": "19-15"
    },
    "Western Illinois": {
      "adjDef": 103.2,
      "adjDefRank": 134,
      "adjEM": -9.06,
      "adjOff": 94.1,
      "adjOffRank": 329,
      "adjTempo": 60.9,
      "adjTempoRank": 331,
      "conference": "Sum",
      "rank": 274,
      "record": "10-20"
    },
    "Western Kentucky": {
      "adjDef": 102.1,
      "adjDefRank": 114,
      "adjEM": -0.97,
      "adjOff": 101.1,
      "adjOffRank": 242,
      "adjTempo": 64.4,
      "adjTempoRank": 190,
      "conference": "SB",
      "rank": 179,
      "record": "20-12"
    },
    "Western Michigan": {
      "adjDef": 103,
      "adjDefRank": 130,
      "adjEM": 3.36,
      "adjOff": 106.3,
      "adjOffRank": 152,
      "adjTempo": 65.2,
      "adjTempoRank": 151,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 131,
      "record": "23-10",
      "seed": 14
    },
    "Wichita St.": {
      "adjDef": 92.4,
      "adjDefRank": 11,
      "adjEM": 25.36,
      "adjOff": 117.8,
      "adjOffRank": 17,
      "adjTempo": 63.5,
      "adjTempoRank": 243,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 6,
      "record": "35-1",
      "seed": 1
    },
    "William & Mary": {
      "adjDef": 112.4,
      "adjDefRank": 305,
      "adjEM": 0.12,
      "adjOff": 112.6,
      "adjOffRank": 50,
      "adjTempo": 63.5,
      "adjTempoRank": 242,
      "conference": "CAA",
      "rank": 166,
      "record": "20-12"
    },
    "Winthrop": {
      "adjDef": 108.6,
      "adjDefRank": 241,
      "adjEM": -5.41,
      "adjOff": 103.2,
      "adjOffRank": 210,
      "adjTempo": 64.3,
      "adjTempoRank": 199,
      "conference": "BSth",
      "rank": 229,
      "record": "20-13"
    },
    "Wisconsin": {
      "adjDef": 96.1,
      "adjDefRank": 35,
      "adjEM": 25.89,
      "adjOff": 122,
      "adjOffRank": 4,
      "adjTempo": 62.4,
      "adjTempoRank": 286,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 5,
      "record": "30-8",
      "seed": 2
    },
    "Wofford": {
      "adjDef": 102.6,
      "adjDefRank": 125,
      "adjEM": -1.94,
      "adjOff": 100.6,
      "adjOffRank": 252,
      "adjTempo": 60.5,
      "adjTempoRank": 337,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 192,
      "record": "20-13",
      "seed": 15
    },
    "Wright St.": {
      "adjDef": 97.1,
      "adjDefRank": 49,
      "adjEM": 3.35,
      "adjOff": 100.4,
      "adjOffRank": 256,
      "adjTempo": 63.4,
      "adjTempoRank": 244,
      "conference": "Horz",
      "rank": 133,
      "record": "21-15"
    },
    "Wyoming": {
      "adjDef": 100.5,
      "adjDefRank": 88,
      "adjEM": 4.99,
      "adjOff": 105.5,
      "adjOffRank": 171,
      "adjTempo": 59.4,
      "adjTempoRank": 346,
      "conference": "MWC",
      "rank": 118,
      "record": "18-15"
    },
    "Xavier": {
      "adjDef": 99.8,
      "adjDefRank": 76,
      "adjEM": 13.11,
      "adjOff": 112.9,
      "adjOffRank": 46,
      "adjTempo": 64.2,
      "adjTempoRank": 202,
      "conference": "BE",
      "finish": "First Four",
      "rank": 52,
      "record": "21-13",
      "seed": 12
    },
    "Yale": {
      "adjDef": 101.5,
      "adjDefRank": 106,
      "adjEM": 1.29,
      "adjOff": 102.8,
      "adjOffRank": 219,
      "adjTempo": 63.5,
      "adjTempoRank": 238,
      "conference": "Ivy",
      "rank": 153,
      "record": "19-14"
    },
    "Youngstown St.": {
      "adjDef": 109.3,
      "adjDefRank": 252,
      "adjEM": -1.33,
      "adjOff": 107.9,
      "adjOffRank": 123,
      "adjTempo": 66,
      "adjTempoRank": 105,
      "conference": "Horz",
      "rank": 186,
      "record": "15-17"
    }
  },
  "2015": {
    "Abilene Christian": {
      "adjDef": 113.8,
      "adjDefRank": 330,
      "adjEM": -20.63,
      "adjOff": 93.2,
      "adjOffRank": 332,
      "adjTempo": 63.8,
      "adjTempoRank": 202,
      "conference": "Slnd",
      "rank": 342,
      "record": "10-21"
    },
    "Air Force": {
      "adjDef": 111.2,
      "adjDefRank": 305,
      "adjEM": -3.01,
      "adjOff": 108.1,
      "adjOffRank": 95,
      "adjTempo": 61.7,
      "adjTempoRank": 303,
      "conference": "MWC",
      "rank": 204,
      "record": "14-17"
    },
    "Akron": {
      "adjDef": 99.4,
      "adjDefRank": 85,
      "adjEM": 5.77,
      "adjOff": 105.1,
      "adjOffRank": 159,
      "adjTempo": 63.6,
      "adjTempoRank": 214,
      "conference": "MAC",
      "rank": 108,
      "record": "21-14"
    },
    "Alabama": {
      "adjDef": 99.3,
      "adjDefRank": 84,
      "adjEM": 12.28,
      "adjOff": 111.6,
      "adjOffRank": 53,
      "adjTempo": 62.2,
      "adjTempoRank": 283,
      "conference": "SEC",
      "rank": 55,
      "record": "19-15"
    },
    "Alabama A&M": {
      "adjDef": 114.1,
      "adjDefRank": 332,
      "adjEM": -19.09,
      "adjOff": 95,
      "adjOffRank": 316,
      "adjTempo": 63.5,
      "adjTempoRank": 225,
      "conference": "SWAC",
      "rank": 336,
      "record": "9-20"
    },
    "Alabama St.": {
      "adjDef": 106.1,
      "adjDefRank": 202,
      "adjEM": -8.31,
      "adjOff": 97.8,
      "adjOffRank": 290,
      "adjTempo": 67.5,
      "adjTempoRank": 38,
      "conference": "SWAC",
      "rank": 264,
      "record": "19-10"
    },
    "Albany": {
      "adjDef": 103.9,
      "adjDefRank": 162,
      "adjEM": 3.29,
      "adjOff": 107.2,
      "adjOffRank": 116,
      "adjTempo": 60.8,
      "adjTempoRank": 323,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 131,
      "record": "24-9",
      "seed": 14
    },
    "Alcorn St.": {
      "adjDef": 117.3,
      "adjDefRank": 347,
      "adjEM": -24.34,
      "adjOff": 92.9,
      "adjOffRank": 335,
      "adjTempo": 66.8,
      "adjTempoRank": 60,
      "conference": "SWAC",
      "rank": 346,
      "record": "6-26"
    },
    "American": {
      "adjDef": 104.2,
      "adjDefRank": 168,
      "adjEM": -1.77,
      "adjOff": 102.5,
      "adjOffRank": 213,
      "adjTempo": 56.8,
      "adjTempoRank": 351,
      "conference": "Pat",
      "rank": 188,
      "record": "17-16"
    },
    "Appalachian St.": {
      "adjDef": 107.9,
      "adjDefRank": 242,
      "adjEM": -9.63,
      "adjOff": 98.3,
      "adjOffRank": 286,
      "adjTempo": 65.3,
      "adjTempoRank": 125,
      "conference": "SB",
      "rank": 283,
      "record": "12-17"
    },
    "Arizona": {
      "adjDef": 87.5,
      "adjDefRank": 3,
      "adjEM": 32.36,
      "adjOff": 119.9,
      "adjOffRank": 7,
      "adjTempo": 65.8,
      "adjTempoRank": 102,
      "conference": "P12",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "34-4",
      "seed": 2
    },
    "Arizona St.": {
      "adjDef": 98.7,
      "adjDefRank": 73,
      "adjEM": 11.13,
      "adjOff": 109.8,
      "adjOffRank": 69,
      "adjTempo": 65.8,
      "adjTempoRank": 98,
      "conference": "P12",
      "rank": 65,
      "record": "18-16"
    },
    "Arkansas": {
      "adjDef": 98.3,
      "adjDefRank": 67,
      "adjEM": 16.44,
      "adjOff": 114.7,
      "adjOffRank": 26,
      "adjTempo": 69.3,
      "adjTempoRank": 13,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 29,
      "record": "27-9",
      "seed": 5
    },
    "Arkansas Little Rock": {
      "adjDef": 109.9,
      "adjDefRank": 288,
      "adjEM": -5.63,
      "adjOff": 104.3,
      "adjOffRank": 171,
      "adjTempo": 64.3,
      "adjTempoRank": 174,
      "conference": "SB",
      "rank": 235,
      "record": "13-18"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 109.4,
      "adjDefRank": 276,
      "adjEM": -16.89,
      "adjOff": 92.5,
      "adjOffRank": 336,
      "adjTempo": 63.2,
      "adjTempoRank": 242,
      "conference": "SWAC",
      "rank": 324,
      "record": "12-20"
    },
    "Arkansas St.": {
      "adjDef": 107,
      "adjDefRank": 222,
      "adjEM": -10.83,
      "adjOff": 96.2,
      "adjOffRank": 308,
      "adjTempo": 65.7,
      "adjTempoRank": 108,
      "conference": "SB",
      "rank": 295,
      "record": "11-18"
    },
    "Army": {
      "adjDef": 107.8,
      "adjDefRank": 236,
      "adjEM": -7.5,
      "adjOff": 100.2,
      "adjOffRank": 252,
      "adjTempo": 69.2,
      "adjTempoRank": 14,
      "conference": "Pat",
      "rank": 253,
      "record": "15-15"
    },
    "Auburn": {
      "adjDef": 103.3,
      "adjDefRank": 153,
      "adjEM": 2.45,
      "adjOff": 105.8,
      "adjOffRank": 141,
      "adjTempo": 67.2,
      "adjTempoRank": 48,
      "conference": "SEC",
      "rank": 139,
      "record": "15-20"
    },
    "Austin Peay": {
      "adjDef": 109,
      "adjDefRank": 264,
      "adjEM": -14.2,
      "adjOff": 94.8,
      "adjOffRank": 317,
      "adjTempo": 66.6,
      "adjTempoRank": 67,
      "conference": "OVC",
      "rank": 317,
      "record": "8-22"
    },
    "BYU": {
      "adjDef": 102.7,
      "adjDefRank": 139,
      "adjEM": 16.69,
      "adjOff": 119.4,
      "adjOffRank": 10,
      "adjTempo": 70.4,
      "adjTempoRank": 5,
      "conference": "WCC",
      "finish": "First Four",
      "rank": 28,
      "record": "25-10",
      "seed": 11
    },
    "Ball St.": {
      "adjDef": 108,
      "adjDefRank": 247,
      "adjEM": -7.65,
      "adjOff": 100.4,
      "adjOffRank": 249,
      "adjTempo": 63.5,
      "adjTempoRank": 220,
      "conference": "MAC",
      "rank": 257,
      "record": "7-23"
    },
    "Baylor": {
      "adjDef": 94.5,
      "adjDefRank": 23,
      "adjEM": 22.14,
      "adjOff": 116.6,
      "adjOffRank": 13,
      "adjTempo": 61.5,
      "adjTempoRank": 307,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 14,
      "record": "24-10",
      "seed": 3
    },
    "Belmont": {
      "adjDef": 107.9,
      "adjDefRank": 240,
      "adjEM": 2.27,
      "adjOff": 110.2,
      "adjOffRank": 65,
      "adjTempo": 66.9,
      "adjTempoRank": 57,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 142,
      "record": "22-11",
      "seed": 15
    },
    "Bethune Cookman": {
      "adjDef": 109.9,
      "adjDefRank": 287,
      "adjEM": -18.66,
      "adjOff": 91.2,
      "adjOffRank": 340,
      "adjTempo": 60.2,
      "adjTempoRank": 335,
      "conference": "MEAC",
      "rank": 330,
      "record": "11-21"
    },
    "Binghamton": {
      "adjDef": 110.4,
      "adjDefRank": 293,
      "adjEM": -16.41,
      "adjOff": 94,
      "adjOffRank": 323,
      "adjTempo": 63,
      "adjTempoRank": 253,
      "conference": "AE",
      "rank": 322,
      "record": "6-26"
    },
    "Boise St.": {
      "adjDef": 98.1,
      "adjDefRank": 63,
      "adjEM": 13.85,
      "adjOff": 111.9,
      "adjOffRank": 50,
      "adjTempo": 62.8,
      "adjTempoRank": 259,
      "conference": "MWC",
      "finish": "First Four",
      "rank": 47,
      "record": "25-9",
      "seed": 11
    },
    "Boston College": {
      "adjDef": 102.3,
      "adjDefRank": 132,
      "adjEM": 5.61,
      "adjOff": 107.9,
      "adjOffRank": 98,
      "adjTempo": 63.4,
      "adjTempoRank": 231,
      "conference": "ACC",
      "rank": 110,
      "record": "13-19"
    },
    "Boston University": {
      "adjDef": 112.7,
      "adjDefRank": 324,
      "adjEM": -6.29,
      "adjOff": 106.4,
      "adjOffRank": 127,
      "adjTempo": 64.2,
      "adjTempoRank": 181,
      "conference": "Pat",
      "rank": 239,
      "record": "13-17"
    },
    "Bowling Green": {
      "adjDef": 100.7,
      "adjDefRank": 105,
      "adjEM": 5.2,
      "adjOff": 105.9,
      "adjOffRank": 137,
      "adjTempo": 63.7,
      "adjTempoRank": 208,
      "conference": "MAC",
      "rank": 115,
      "record": "21-12"
    },
    "Bradley": {
      "adjDef": 101.3,
      "adjDefRank": 111,
      "adjEM": -7.51,
      "adjOff": 93.7,
      "adjOffRank": 325,
      "adjTempo": 62.1,
      "adjTempoRank": 288,
      "conference": "MVC",
      "rank": 254,
      "record": "9-24"
    },
    "Brown": {
      "adjDef": 107.8,
      "adjDefRank": 238,
      "adjEM": -8.42,
      "adjOff": 99.4,
      "adjOffRank": 265,
      "adjTempo": 67.4,
      "adjTempoRank": 40,
      "conference": "Ivy",
      "rank": 268,
      "record": "13-18"
    },
    "Bryant": {
      "adjDef": 109.9,
      "adjDefRank": 290,
      "adjEM": -5.85,
      "adjOff": 104.1,
      "adjOffRank": 176,
      "adjTempo": 63.1,
      "adjTempoRank": 249,
      "conference": "NEC",
      "rank": 236,
      "record": "16-15"
    },
    "Bucknell": {
      "adjDef": 108.6,
      "adjDefRank": 259,
      "adjEM": -2.8,
      "adjOff": 105.8,
      "adjOffRank": 140,
      "adjTempo": 64.2,
      "adjTempoRank": 175,
      "conference": "Pat",
      "rank": 200,
      "record": "19-15"
    },
    "Buffalo": {
      "adjDef": 99.4,
      "adjDefRank": 87,
      "adjEM": 11.87,
      "adjOff": 111.3,
      "adjOffRank": 57,
      "adjTempo": 68.3,
      "adjTempoRank": 23,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 58,
      "record": "23-10",
      "seed": 12
    },
    "Butler": {
      "adjDef": 90.1,
      "adjDefRank": 8,
      "adjEM": 20.09,
      "adjOff": 110.2,
      "adjOffRank": 64,
      "adjTempo": 63.2,
      "adjTempoRank": 243,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 20,
      "record": "23-11",
      "seed": 6
    },
    "Cal Poly": {
      "adjDef": 101.7,
      "adjDefRank": 120,
      "adjEM": 1.24,
      "adjOff": 102.9,
      "adjOffRank": 203,
      "adjTempo": 58.6,
      "adjTempoRank": 346,
      "conference": "BW",
      "rank": 153,
      "record": "13-16"
    },
    "Cal St. Bakersfield": {
      "adjDef": 106.2,
      "adjDefRank": 203,
      "adjEM": -6.44,
      "adjOff": 99.7,
      "adjOffRank": 263,
      "adjTempo": 60.5,
      "adjTempoRank": 330,
      "conference": "WAC",
      "rank": 241,
      "record": "14-19"
    },
    "Cal St. Fullerton": {
      "adjDef": 109.7,
      "adjDefRank": 284,
      "adjEM": -10.62,
      "adjOff": 99.1,
      "adjOffRank": 269,
      "adjTempo": 65.7,
      "adjTempoRank": 107,
      "conference": "BW",
      "rank": 292,
      "record": "9-22"
    },
    "Cal St. Northridge": {
      "adjDef": 109.2,
      "adjDefRank": 270,
      "adjEM": -8.44,
      "adjOff": 100.8,
      "adjOffRank": 242,
      "adjTempo": 64.8,
      "adjTempoRank": 145,
      "conference": "BW",
      "rank": 269,
      "record": "9-24"
    },
    "California": {
      "adjDef": 99,
      "adjDefRank": 79,
      "adjEM": 5.25,
      "adjOff": 104.2,
      "adjOffRank": 172,
      "adjTempo": 65.3,
      "adjTempoRank": 123,
      "conference": "P12",
      "rank": 113,
      "record": "18-15"
    },
    "Campbell": {
      "adjDef": 109.5,
      "adjDefRank": 279,
      "adjEM": -11.85,
      "adjOff": 97.7,
      "adjOffRank": 292,
      "adjTempo": 61.3,
      "adjTempoRank": 311,
      "conference": "BSth",
      "rank": 301,
      "record": "10-22"
    },
    "Canisius": {
      "adjDef": 101.8,
      "adjDefRank": 124,
      "adjEM": 1.81,
      "adjOff": 103.6,
      "adjOffRank": 184,
      "adjTempo": 63.4,
      "adjTempoRank": 230,
      "conference": "MAAC",
      "rank": 149,
      "record": "18-15"
    },
    "Central Arkansas": {
      "adjDef": 118.7,
      "adjDefRank": 350,
      "adjEM": -27.67,
      "adjOff": 91,
      "adjOffRank": 341,
      "adjTempo": 69,
      "adjTempoRank": 17,
      "conference": "Slnd",
      "rank": 349,
      "record": "2-27"
    },
    "Central Connecticut": {
      "adjDef": 113.6,
      "adjDefRank": 329,
      "adjEM": -20.7,
      "adjOff": 92.9,
      "adjOffRank": 334,
      "adjTempo": 62.8,
      "adjTempoRank": 262,
      "conference": "NEC",
      "rank": 344,
      "record": "5-26"
    },
    "Central Michigan": {
      "adjDef": 106.6,
      "adjDefRank": 217,
      "adjEM": 8.23,
      "adjOff": 114.9,
      "adjOffRank": 24,
      "adjTempo": 64.4,
      "adjTempoRank": 171,
      "conference": "MAC",
      "rank": 80,
      "record": "23-9"
    },
    "Charleston Southern": {
      "adjDef": 109.2,
      "adjDefRank": 269,
      "adjEM": -2.17,
      "adjOff": 107,
      "adjOffRank": 118,
      "adjTempo": 65.2,
      "adjTempoRank": 126,
      "conference": "BSth",
      "rank": 195,
      "record": "19-12"
    },
    "Charlotte": {
      "adjDef": 108,
      "adjDefRank": 245,
      "adjEM": 1.92,
      "adjOff": 109.9,
      "adjOffRank": 68,
      "adjTempo": 66.8,
      "adjTempoRank": 59,
      "conference": "CUSA",
      "rank": 146,
      "record": "14-18"
    },
    "Chattanooga": {
      "adjDef": 105.5,
      "adjDefRank": 188,
      "adjEM": 0.09,
      "adjOff": 105.6,
      "adjOffRank": 146,
      "adjTempo": 64.5,
      "adjTempoRank": 162,
      "conference": "SC",
      "rank": 166,
      "record": "22-10"
    },
    "Chicago St.": {
      "adjDef": 107.5,
      "adjDefRank": 232,
      "adjEM": -18.17,
      "adjOff": 89.3,
      "adjOffRank": 346,
      "adjTempo": 62.6,
      "adjTempoRank": 269,
      "conference": "WAC",
      "rank": 327,
      "record": "8-24"
    },
    "Cincinnati": {
      "adjDef": 94,
      "adjDefRank": 17,
      "adjEM": 14.15,
      "adjOff": 108.2,
      "adjOffRank": 94,
      "adjTempo": 59.8,
      "adjTempoRank": 339,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 43,
      "record": "23-11",
      "seed": 8
    },
    "Clemson": {
      "adjDef": 96.7,
      "adjDefRank": 51,
      "adjEM": 7.82,
      "adjOff": 104.5,
      "adjOffRank": 169,
      "adjTempo": 61.6,
      "adjTempoRank": 305,
      "conference": "ACC",
      "rank": 84,
      "record": "16-15"
    },
    "Cleveland St.": {
      "adjDef": 100.9,
      "adjDefRank": 107,
      "adjEM": 7.42,
      "adjOff": 108.3,
      "adjOffRank": 92,
      "adjTempo": 61.1,
      "adjTempoRank": 318,
      "conference": "Horz",
      "rank": 89,
      "record": "19-15"
    },
    "Coastal Carolina": {
      "adjDef": 104.3,
      "adjDefRank": 170,
      "adjEM": 2.38,
      "adjOff": 106.7,
      "adjOffRank": 119,
      "adjTempo": 64.1,
      "adjTempoRank": 190,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 140,
      "record": "24-10",
      "seed": 16
    },
    "Colgate": {
      "adjDef": 109.2,
      "adjDefRank": 271,
      "adjEM": -1.24,
      "adjOff": 108,
      "adjOffRank": 97,
      "adjTempo": 60.7,
      "adjTempoRank": 325,
      "conference": "Pat",
      "rank": 180,
      "record": "16-17"
    },
    "College of Charleston": {
      "adjDef": 105.5,
      "adjDefRank": 185,
      "adjEM": -9.32,
      "adjOff": 96.1,
      "adjOffRank": 309,
      "adjTempo": 60.8,
      "adjTempoRank": 322,
      "conference": "CAA",
      "rank": 279,
      "record": "9-24"
    },
    "Colorado": {
      "adjDef": 100.5,
      "adjDefRank": 101,
      "adjEM": 7.09,
      "adjOff": 107.5,
      "adjOffRank": 106,
      "adjTempo": 65,
      "adjTempoRank": 138,
      "conference": "P12",
      "rank": 92,
      "record": "16-18"
    },
    "Colorado St.": {
      "adjDef": 101.6,
      "adjDefRank": 118,
      "adjEM": 10.01,
      "adjOff": 111.6,
      "adjOffRank": 54,
      "adjTempo": 66.6,
      "adjTempoRank": 64,
      "conference": "MWC",
      "rank": 72,
      "record": "27-7"
    },
    "Columbia": {
      "adjDef": 107.4,
      "adjDefRank": 229,
      "adjEM": 0.12,
      "adjOff": 107.5,
      "adjOffRank": 107,
      "adjTempo": 60.2,
      "adjTempoRank": 336,
      "conference": "Ivy",
      "rank": 165,
      "record": "13-15"
    },
    "Connecticut": {
      "adjDef": 97.7,
      "adjDefRank": 60,
      "adjEM": 9.98,
      "adjOff": 107.7,
      "adjOffRank": 102,
      "adjTempo": 62.1,
      "adjTempoRank": 287,
      "conference": "Amer",
      "rank": 73,
      "record": "20-15"
    },
    "Coppin St.": {
      "adjDef": 116.1,
      "adjDefRank": 342,
      "adjEM": -17.04,
      "adjOff": 99.1,
      "adjOffRank": 268,
      "adjTempo": 73.7,
      "adjTempoRank": 2,
      "conference": "MEAC",
      "rank": 325,
      "record": "8-23"
    },
    "Cornell": {
      "adjDef": 99.7,
      "adjDefRank": 92,
      "adjEM": -2.87,
      "adjOff": 96.9,
      "adjOffRank": 302,
      "adjTempo": 64.2,
      "adjTempoRank": 177,
      "conference": "Ivy",
      "rank": 202,
      "record": "13-17"
    },
    "Creighton": {
      "adjDef": 102.6,
      "adjDefRank": 138,
      "adjEM": 8.36,
      "adjOff": 111,
      "adjOffRank": 59,
      "adjTempo": 62.1,
      "adjTempoRank": 286,
      "conference": "BE",
      "rank": 79,
      "record": "14-19"
    },
    "Dartmouth": {
      "adjDef": 102.7,
      "adjDefRank": 141,
      "adjEM": -0.67,
      "adjOff": 102,
      "adjOffRank": 218,
      "adjTempo": 63.4,
      "adjTempoRank": 234,
      "conference": "Ivy",
      "rank": 168,
      "record": "14-15"
    },
    "Davidson": {
      "adjDef": 104.7,
      "adjDefRank": 176,
      "adjEM": 15,
      "adjOff": 119.7,
      "adjOffRank": 9,
      "adjTempo": 66.7,
      "adjTempoRank": 62,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 36,
      "record": "24-8",
      "seed": 10
    },
    "Dayton": {
      "adjDef": 95,
      "adjDefRank": 30,
      "adjEM": 14.24,
      "adjOff": 109.2,
      "adjOffRank": 75,
      "adjTempo": 63.4,
      "adjTempoRank": 229,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 42,
      "record": "27-9",
      "seed": 11
    },
    "DePaul": {
      "adjDef": 106.8,
      "adjDefRank": 219,
      "adjEM": 1.77,
      "adjOff": 108.6,
      "adjOffRank": 86,
      "adjTempo": 67,
      "adjTempoRank": 54,
      "conference": "BE",
      "rank": 150,
      "record": "12-20"
    },
    "Delaware": {
      "adjDef": 109.6,
      "adjDefRank": 282,
      "adjEM": -7.92,
      "adjOff": 101.7,
      "adjOffRank": 226,
      "adjTempo": 65.3,
      "adjTempoRank": 120,
      "conference": "CAA",
      "rank": 260,
      "record": "10-20"
    },
    "Delaware St.": {
      "adjDef": 109.7,
      "adjDefRank": 286,
      "adjEM": -8.25,
      "adjOff": 101.5,
      "adjOffRank": 231,
      "adjTempo": 67.1,
      "adjTempoRank": 52,
      "conference": "MEAC",
      "rank": 262,
      "record": "18-18"
    },
    "Denver": {
      "adjDef": 110.6,
      "adjDefRank": 295,
      "adjEM": -4.58,
      "adjOff": 106,
      "adjOffRank": 134,
      "adjTempo": 57.1,
      "adjTempoRank": 350,
      "conference": "Sum",
      "rank": 222,
      "record": "12-18"
    },
    "Detroit": {
      "adjDef": 104.6,
      "adjDefRank": 175,
      "adjEM": -1.34,
      "adjOff": 103.3,
      "adjOffRank": 192,
      "adjTempo": 67.3,
      "adjTempoRank": 45,
      "conference": "Horz",
      "rank": 182,
      "record": "15-18"
    },
    "Drake": {
      "adjDef": 114.3,
      "adjDefRank": 333,
      "adjEM": -9.03,
      "adjOff": 105.3,
      "adjOffRank": 157,
      "adjTempo": 58.9,
      "adjTempoRank": 344,
      "conference": "MVC",
      "rank": 275,
      "record": "9-22"
    },
    "Drexel": {
      "adjDef": 106.5,
      "adjDefRank": 213,
      "adjEM": -7.24,
      "adjOff": 99.3,
      "adjOffRank": 266,
      "adjTempo": 60.7,
      "adjTempoRank": 326,
      "conference": "CAA",
      "rank": 250,
      "record": "11-19"
    },
    "Duke": {
      "adjDef": 92,
      "adjDefRank": 11,
      "adjEM": 32.48,
      "adjOff": 124.5,
      "adjOffRank": 3,
      "adjTempo": 65.8,
      "adjTempoRank": 104,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 3,
      "record": "35-4",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 112.9,
      "adjDefRank": 326,
      "adjEM": -4.26,
      "adjOff": 108.6,
      "adjOffRank": 85,
      "adjTempo": 67.3,
      "adjTempoRank": 46,
      "conference": "A10",
      "rank": 217,
      "record": "12-19"
    },
    "East Carolina": {
      "adjDef": 108.6,
      "adjDefRank": 261,
      "adjEM": -4.89,
      "adjOff": 103.7,
      "adjOffRank": 183,
      "adjTempo": 61.2,
      "adjTempoRank": 314,
      "conference": "Amer",
      "rank": 224,
      "record": "14-19"
    },
    "East Tennessee St.": {
      "adjDef": 107.5,
      "adjDefRank": 231,
      "adjEM": -4.29,
      "adjOff": 103.2,
      "adjOffRank": 194,
      "adjTempo": 67.7,
      "adjTempoRank": 32,
      "conference": "SC",
      "rank": 218,
      "record": "16-14"
    },
    "Eastern Illinois": {
      "adjDef": 105.2,
      "adjDefRank": 182,
      "adjEM": -4.95,
      "adjOff": 100.3,
      "adjOffRank": 251,
      "adjTempo": 63.2,
      "adjTempoRank": 246,
      "conference": "OVC",
      "rank": 225,
      "record": "18-15"
    },
    "Eastern Kentucky": {
      "adjDef": 102.8,
      "adjDefRank": 145,
      "adjEM": 3.46,
      "adjOff": 106.3,
      "adjOffRank": 130,
      "adjTempo": 63.5,
      "adjTempoRank": 223,
      "conference": "OVC",
      "rank": 129,
      "record": "21-12"
    },
    "Eastern Michigan": {
      "adjDef": 99.2,
      "adjDefRank": 81,
      "adjEM": 2.69,
      "adjOff": 101.8,
      "adjOffRank": 225,
      "adjTempo": 66,
      "adjTempoRank": 91,
      "conference": "MAC",
      "rank": 138,
      "record": "21-14"
    },
    "Eastern Washington": {
      "adjDef": 109.6,
      "adjDefRank": 283,
      "adjEM": 3.25,
      "adjOff": 112.9,
      "adjOffRank": 39,
      "adjTempo": 68.2,
      "adjTempoRank": 24,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 133,
      "record": "26-9",
      "seed": 13
    },
    "Elon": {
      "adjDef": 105.7,
      "adjDefRank": 192,
      "adjEM": -7.23,
      "adjOff": 98.5,
      "adjOffRank": 281,
      "adjTempo": 68,
      "adjTempoRank": 27,
      "conference": "CAA",
      "rank": 249,
      "record": "15-18"
    },
    "Evansville": {
      "adjDef": 101.3,
      "adjDefRank": 115,
      "adjEM": 5.25,
      "adjOff": 106.6,
      "adjOffRank": 122,
      "adjTempo": 66.2,
      "adjTempoRank": 83,
      "conference": "MVC",
      "rank": 114,
      "record": "24-12"
    },
    "FIU": {
      "adjDef": 104.6,
      "adjDefRank": 174,
      "adjEM": -8.57,
      "adjOff": 96,
      "adjOffRank": 310,
      "adjTempo": 64.6,
      "adjTempoRank": 159,
      "conference": "CUSA",
      "rank": 272,
      "record": "16-17"
    },
    "Fairfield": {
      "adjDef": 106.3,
      "adjDefRank": 206,
      "adjEM": -10.9,
      "adjOff": 95.4,
      "adjOffRank": 314,
      "adjTempo": 61.2,
      "adjTempoRank": 313,
      "conference": "MAAC",
      "rank": 296,
      "record": "7-24"
    },
    "Fairleigh Dickinson": {
      "adjDef": 112.5,
      "adjDefRank": 320,
      "adjEM": -15.07,
      "adjOff": 97.4,
      "adjOffRank": 296,
      "adjTempo": 67.4,
      "adjTempoRank": 42,
      "conference": "NEC",
      "rank": 319,
      "record": "8-21"
    },
    "Florida": {
      "adjDef": 91.9,
      "adjDefRank": 10,
      "adjEM": 15.39,
      "adjOff": 107.3,
      "adjOffRank": 115,
      "adjTempo": 62.7,
      "adjTempoRank": 266,
      "conference": "SEC",
      "rank": 34,
      "record": "16-17"
    },
    "Florida A&M": {
      "adjDef": 117.1,
      "adjDefRank": 345,
      "adjEM": -31.73,
      "adjOff": 85.4,
      "adjOffRank": 350,
      "adjTempo": 65.3,
      "adjTempoRank": 122,
      "conference": "MEAC",
      "rank": 350,
      "record": "2-27"
    },
    "Florida Atlantic": {
      "adjDef": 109,
      "adjDefRank": 265,
      "adjEM": -10.28,
      "adjOff": 98.7,
      "adjOffRank": 277,
      "adjTempo": 63.9,
      "adjTempoRank": 200,
      "conference": "CUSA",
      "rank": 290,
      "record": "9-20"
    },
    "Florida Gulf Coast": {
      "adjDef": 103.6,
      "adjDefRank": 159,
      "adjEM": -1.92,
      "adjOff": 101.6,
      "adjOffRank": 227,
      "adjTempo": 64.2,
      "adjTempoRank": 176,
      "conference": "ASun",
      "rank": 190,
      "record": "22-11"
    },
    "Florida St.": {
      "adjDef": 99.3,
      "adjDefRank": 83,
      "adjEM": 6.37,
      "adjOff": 105.6,
      "adjOffRank": 144,
      "adjTempo": 65.9,
      "adjTempoRank": 93,
      "conference": "ACC",
      "rank": 99,
      "record": "17-16"
    },
    "Fordham": {
      "adjDef": 105.5,
      "adjDefRank": 186,
      "adjEM": -3.49,
      "adjOff": 102,
      "adjOffRank": 219,
      "adjTempo": 65.4,
      "adjTempoRank": 115,
      "conference": "A10",
      "rank": 208,
      "record": "10-21"
    },
    "Fresno St.": {
      "adjDef": 105.7,
      "adjDefRank": 190,
      "adjEM": -2.92,
      "adjOff": 102.8,
      "adjOffRank": 208,
      "adjTempo": 64.9,
      "adjTempoRank": 144,
      "conference": "MWC",
      "rank": 203,
      "record": "15-17"
    },
    "Furman": {
      "adjDef": 111.5,
      "adjDefRank": 313,
      "adjEM": -13.18,
      "adjOff": 98.3,
      "adjOffRank": 284,
      "adjTempo": 62.4,
      "adjTempoRank": 277,
      "conference": "SC",
      "rank": 309,
      "record": "11-22"
    },
    "Gardner Webb": {
      "adjDef": 111.4,
      "adjDefRank": 312,
      "adjEM": -3.84,
      "adjOff": 107.6,
      "adjOffRank": 105,
      "adjTempo": 67.4,
      "adjTempoRank": 41,
      "conference": "BSth",
      "rank": 212,
      "record": "20-15"
    },
    "George Mason": {
      "adjDef": 106,
      "adjDefRank": 199,
      "adjEM": -3.08,
      "adjOff": 102.9,
      "adjOffRank": 204,
      "adjTempo": 64,
      "adjTempoRank": 191,
      "conference": "A10",
      "rank": 205,
      "record": "9-22"
    },
    "George Washington": {
      "adjDef": 98.2,
      "adjDefRank": 65,
      "adjEM": 10.19,
      "adjOff": 108.4,
      "adjOffRank": 90,
      "adjTempo": 61.9,
      "adjTempoRank": 299,
      "conference": "A10",
      "rank": 69,
      "record": "22-13"
    },
    "Georgetown": {
      "adjDef": 95.1,
      "adjDefRank": 33,
      "adjEM": 19.13,
      "adjOff": 114.3,
      "adjOffRank": 29,
      "adjTempo": 64,
      "adjTempoRank": 192,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 22,
      "record": "22-11",
      "seed": 4
    },
    "Georgia": {
      "adjDef": 94.5,
      "adjDefRank": 22,
      "adjEM": 15.09,
      "adjOff": 109.6,
      "adjOffRank": 70,
      "adjTempo": 64.1,
      "adjTempoRank": 184,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 35,
      "record": "21-12",
      "seed": 10
    },
    "Georgia Southern": {
      "adjDef": 98.6,
      "adjDefRank": 72,
      "adjEM": 1.89,
      "adjOff": 100.5,
      "adjOffRank": 248,
      "adjTempo": 63.4,
      "adjTempoRank": 228,
      "conference": "SB",
      "rank": 147,
      "record": "22-9"
    },
    "Georgia St.": {
      "adjDef": 101.3,
      "adjDefRank": 112,
      "adjEM": 10.32,
      "adjOff": 111.6,
      "adjOffRank": 52,
      "adjTempo": 62.4,
      "adjTempoRank": 275,
      "conference": "SB",
      "finish": "Round of 32",
      "rank": 68,
      "record": "25-10",
      "seed": 14
    },
    "Georgia Tech": {
      "adjDef": 96,
      "adjDefRank": 43,
      "adjEM": 7.67,
      "adjOff": 103.7,
      "adjOffRank": 182,
      "adjTempo": 63.7,
      "adjTempoRank": 210,
      "conference": "ACC",
      "rank": 86,
      "record": "12-19"
    },
    "Gonzaga": {
      "adjDef": 93.9,
      "adjDefRank": 16,
      "adjEM": 27.78,
      "adjOff": 121.7,
      "adjOffRank": 5,
      "adjTempo": 64.7,
      "adjTempoRank": 154,
      "conference": "WCC",
      "finish": "Elite Eight",
      "rank": 7,
      "record": "35-3",
      "seed": 2
    },
    "Grambling St.": {
      "adjDef": 116.6,
      "adjDefRank": 344,
      "adjEM": -37.65,
      "adjOff": 78.9,
      "adjOffRank": 351,
      "adjTempo": 65.4,
      "adjTempoRank": 117,
      "conference": "SWAC",
      "rank": 351,
      "record": "2-27"
    },
    "Grand Canyon": {
      "adjDef": 114.8,
      "adjDefRank": 336,
      "adjEM": -8.29,
      "adjOff": 106.5,
      "adjOffRank": 123,
      "adjTempo": 64.5,
      "adjTempoRank": 164,
      "conference": "WAC",
      "rank": 263,
      "record": "17-15"
    },
    "Green Bay": {
      "adjDef": 94.6,
      "adjDefRank": 24,
      "adjEM": 10.11,
      "adjOff": 104.7,
      "adjOffRank": 167,
      "adjTempo": 64.9,
      "adjTempoRank": 141,
      "conference": "Horz",
      "rank": 71,
      "record": "24-9"
    },
    "Hampton": {
      "adjDef": 104,
      "adjDefRank": 164,
      "adjEM": -7.4,
      "adjOff": 96.6,
      "adjOffRank": 303,
      "adjTempo": 69.2,
      "adjTempoRank": 15,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 251,
      "record": "17-18",
      "seed": 16
    },
    "Hartford": {
      "adjDef": 107.1,
      "adjDefRank": 223,
      "adjEM": -9.65,
      "adjOff": 97.4,
      "adjOffRank": 294,
      "adjTempo": 63,
      "adjTempoRank": 256,
      "conference": "AE",
      "rank": 284,
      "record": "14-16"
    },
    "Harvard": {
      "adjDef": 95.6,
      "adjDefRank": 39,
      "adjEM": 9.23,
      "adjOff": 104.8,
      "adjOffRank": 165,
      "adjTempo": 61.1,
      "adjTempoRank": 317,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 75,
      "record": "22-8",
      "seed": 13
    },
    "Hawaii": {
      "adjDef": 101.4,
      "adjDefRank": 117,
      "adjEM": 3.73,
      "adjOff": 105.1,
      "adjOffRank": 160,
      "adjTempo": 68.9,
      "adjTempoRank": 18,
      "conference": "BW",
      "rank": 127,
      "record": "22-13"
    },
    "High Point": {
      "adjDef": 106.4,
      "adjDefRank": 208,
      "adjEM": 2.03,
      "adjOff": 108.4,
      "adjOffRank": 89,
      "adjTempo": 62.2,
      "adjTempoRank": 284,
      "conference": "BSth",
      "rank": 145,
      "record": "23-10"
    },
    "Hofstra": {
      "adjDef": 106.6,
      "adjDefRank": 216,
      "adjEM": 5.43,
      "adjOff": 112,
      "adjOffRank": 49,
      "adjTempo": 68.7,
      "adjTempoRank": 19,
      "conference": "CAA",
      "rank": 111,
      "record": "20-14"
    },
    "Holy Cross": {
      "adjDef": 107.7,
      "adjDefRank": 235,
      "adjEM": -6.49,
      "adjOff": 101.2,
      "adjOffRank": 235,
      "adjTempo": 63.6,
      "adjTempoRank": 213,
      "conference": "Pat",
      "rank": 242,
      "record": "14-16"
    },
    "Houston": {
      "adjDef": 105.2,
      "adjDefRank": 184,
      "adjEM": -3.85,
      "adjOff": 101.4,
      "adjOffRank": 232,
      "adjTempo": 64.2,
      "adjTempoRank": 182,
      "conference": "Amer",
      "rank": 214,
      "record": "13-19"
    },
    "Houston Baptist": {
      "adjDef": 115.5,
      "adjDefRank": 339,
      "adjEM": -14.21,
      "adjOff": 101.3,
      "adjOffRank": 234,
      "adjTempo": 66.2,
      "adjTempoRank": 85,
      "conference": "Slnd",
      "rank": 318,
      "record": "12-16"
    },
    "Howard": {
      "adjDef": 101.9,
      "adjDefRank": 127,
      "adjEM": -7.91,
      "adjOff": 93.9,
      "adjOffRank": 324,
      "adjTempo": 62.7,
      "adjTempoRank": 264,
      "conference": "MEAC",
      "rank": 259,
      "record": "16-16"
    },
    "IPFW": {
      "adjDef": 108.5,
      "adjDefRank": 258,
      "adjEM": -0.82,
      "adjOff": 107.7,
      "adjOffRank": 103,
      "adjTempo": 64.5,
      "adjTempoRank": 161,
      "conference": "Sum",
      "rank": 172,
      "record": "16-15"
    },
    "IUPUI": {
      "adjDef": 103.4,
      "adjDefRank": 157,
      "adjEM": -9.43,
      "adjOff": 94,
      "adjOffRank": 321,
      "adjTempo": 63.2,
      "adjTempoRank": 244,
      "conference": "Sum",
      "rank": 280,
      "record": "10-21"
    },
    "Idaho": {
      "adjDef": 112.9,
      "adjDefRank": 327,
      "adjEM": -5.29,
      "adjOff": 107.7,
      "adjOffRank": 104,
      "adjTempo": 67.5,
      "adjTempoRank": 35,
      "conference": "BSky",
      "rank": 231,
      "record": "13-17"
    },
    "Idaho St.": {
      "adjDef": 111.3,
      "adjDefRank": 308,
      "adjEM": -13.77,
      "adjOff": 97.5,
      "adjOffRank": 293,
      "adjTempo": 63.4,
      "adjTempoRank": 232,
      "conference": "BSky",
      "rank": 313,
      "record": "7-23"
    },
    "Illinois": {
      "adjDef": 96.7,
      "adjDefRank": 50,
      "adjEM": 11.68,
      "adjOff": 108.3,
      "adjOffRank": 91,
      "adjTempo": 64.8,
      "adjTempoRank": 146,
      "conference": "B10",
      "rank": 61,
      "record": "19-14"
    },
    "Illinois Chicago": {
      "adjDef": 110.8,
      "adjDefRank": 299,
      "adjEM": -9.61,
      "adjOff": 101.2,
      "adjOffRank": 236,
      "adjTempo": 64.4,
      "adjTempoRank": 168,
      "conference": "Horz",
      "rank": 282,
      "record": "10-24"
    },
    "Illinois St.": {
      "adjDef": 96.1,
      "adjDefRank": 44,
      "adjEM": 11.67,
      "adjOff": 107.7,
      "adjOffRank": 101,
      "adjTempo": 64.7,
      "adjTempoRank": 148,
      "conference": "MVC",
      "rank": 62,
      "record": "22-13"
    },
    "Incarnate Word": {
      "adjDef": 112.6,
      "adjDefRank": 322,
      "adjEM": -5.06,
      "adjOff": 107.5,
      "adjOffRank": 108,
      "adjTempo": 67.4,
      "adjTempoRank": 39,
      "conference": "Slnd",
      "rank": 228,
      "record": "18-11"
    },
    "Indiana": {
      "adjDef": 106,
      "adjDefRank": 200,
      "adjEM": 13.83,
      "adjOff": 119.8,
      "adjOffRank": 8,
      "adjTempo": 66.6,
      "adjTempoRank": 69,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 48,
      "record": "20-14",
      "seed": 10
    },
    "Indiana St.": {
      "adjDef": 103.4,
      "adjDefRank": 154,
      "adjEM": -0.7,
      "adjOff": 102.7,
      "adjOffRank": 210,
      "adjTempo": 66.6,
      "adjTempoRank": 65,
      "conference": "MVC",
      "rank": 169,
      "record": "15-16"
    },
    "Iona": {
      "adjDef": 107.4,
      "adjDefRank": 230,
      "adjEM": 6.18,
      "adjOff": 113.6,
      "adjOffRank": 34,
      "adjTempo": 69.7,
      "adjTempoRank": 9,
      "conference": "MAAC",
      "rank": 100,
      "record": "26-9"
    },
    "Iowa": {
      "adjDef": 95.1,
      "adjDefRank": 34,
      "adjEM": 19.02,
      "adjOff": 114.2,
      "adjOffRank": 30,
      "adjTempo": 63.7,
      "adjTempoRank": 209,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 23,
      "record": "22-12",
      "seed": 7
    },
    "Iowa St.": {
      "adjDef": 97.5,
      "adjDefRank": 59,
      "adjEM": 21.49,
      "adjOff": 119,
      "adjOffRank": 12,
      "adjTempo": 69.6,
      "adjTempoRank": 10,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 16,
      "record": "25-9",
      "seed": 3
    },
    "Jackson St.": {
      "adjDef": 104.7,
      "adjDefRank": 177,
      "adjEM": -13.5,
      "adjOff": 91.2,
      "adjOffRank": 339,
      "adjTempo": 63.2,
      "adjTempoRank": 245,
      "conference": "SWAC",
      "rank": 312,
      "record": "11-21"
    },
    "Jacksonville": {
      "adjDef": 116.1,
      "adjDefRank": 341,
      "adjEM": -19.74,
      "adjOff": 96.3,
      "adjOffRank": 306,
      "adjTempo": 63.8,
      "adjTempoRank": 204,
      "conference": "ASun",
      "rank": 340,
      "record": "10-22"
    },
    "Jacksonville St.": {
      "adjDef": 108.4,
      "adjDefRank": 256,
      "adjEM": -13.27,
      "adjOff": 95.2,
      "adjOffRank": 315,
      "adjTempo": 63.1,
      "adjTempoRank": 251,
      "conference": "OVC",
      "rank": 311,
      "record": "12-19"
    },
    "James Madison": {
      "adjDef": 108.2,
      "adjDefRank": 249,
      "adjEM": -2.69,
      "adjOff": 105.5,
      "adjOffRank": 151,
      "adjTempo": 63.9,
      "adjTempoRank": 195,
      "conference": "CAA",
      "rank": 199,
      "record": "19-14"
    },
    "Kansas": {
      "adjDef": 91.8,
      "adjDefRank": 9,
      "adjEM": 22.72,
      "adjOff": 114.5,
      "adjOffRank": 27,
      "adjTempo": 67,
      "adjTempoRank": 56,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 12,
      "record": "27-9",
      "seed": 2
    },
    "Kansas St.": {
      "adjDef": 98.4,
      "adjDefRank": 68,
      "adjEM": 8.18,
      "adjOff": 106.6,
      "adjOffRank": 120,
      "adjTempo": 62.1,
      "adjTempoRank": 285,
      "conference": "B12",
      "rank": 81,
      "record": "15-17"
    },
    "Kennesaw St.": {
      "adjDef": 114.3,
      "adjDefRank": 334,
      "adjEM": -20.61,
      "adjOff": 93.7,
      "adjOffRank": 327,
      "adjTempo": 65.1,
      "adjTempoRank": 134,
      "conference": "ASun",
      "rank": 341,
      "record": "10-22"
    },
    "Kent St.": {
      "adjDef": 102.3,
      "adjDefRank": 133,
      "adjEM": 5.04,
      "adjOff": 107.4,
      "adjOffRank": 113,
      "adjTempo": 61.5,
      "adjTempoRank": 308,
      "conference": "MAC",
      "rank": 118,
      "record": "23-12"
    },
    "Kentucky": {
      "adjDef": 84.4,
      "adjDefRank": 1,
      "adjEM": 36.91,
      "adjOff": 121.3,
      "adjOffRank": 6,
      "adjTempo": 62.4,
      "adjTempoRank": 274,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 1,
      "record": "38-1",
      "seed": 1
    },
    "LIU Brooklyn": {
      "adjDef": 106.8,
      "adjDefRank": 218,
      "adjEM": -9.82,
      "adjOff": 97,
      "adjOffRank": 299,
      "adjTempo": 67.4,
      "adjTempoRank": 43,
      "conference": "NEC",
      "rank": 288,
      "record": "12-18"
    },
    "LSU": {
      "adjDef": 95.1,
      "adjDefRank": 32,
      "adjEM": 13.76,
      "adjOff": 108.9,
      "adjOffRank": 81,
      "adjTempo": 69.3,
      "adjTempoRank": 12,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 49,
      "record": "22-11",
      "seed": 9
    },
    "La Salle": {
      "adjDef": 95.3,
      "adjDefRank": 38,
      "adjEM": 6.02,
      "adjOff": 101.3,
      "adjOffRank": 233,
      "adjTempo": 64.1,
      "adjTempoRank": 185,
      "conference": "A10",
      "rank": 103,
      "record": "17-16"
    },
    "Lafayette": {
      "adjDef": 115.1,
      "adjDefRank": 338,
      "adjEM": -2.14,
      "adjOff": 112.9,
      "adjOffRank": 38,
      "adjTempo": 64.6,
      "adjTempoRank": 160,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 193,
      "record": "20-13",
      "seed": 16
    },
    "Lamar": {
      "adjDef": 109.1,
      "adjDefRank": 267,
      "adjEM": -9.03,
      "adjOff": 100,
      "adjOffRank": 253,
      "adjTempo": 65.9,
      "adjTempoRank": 92,
      "conference": "Slnd",
      "rank": 276,
      "record": "15-15"
    },
    "Lehigh": {
      "adjDef": 102.7,
      "adjDefRank": 142,
      "adjEM": -2.81,
      "adjOff": 99.9,
      "adjOffRank": 256,
      "adjTempo": 66.2,
      "adjTempoRank": 79,
      "conference": "Pat",
      "rank": 201,
      "record": "16-14"
    },
    "Liberty": {
      "adjDef": 109.7,
      "adjDefRank": 285,
      "adjEM": -18.87,
      "adjOff": 90.8,
      "adjOffRank": 342,
      "adjTempo": 65.8,
      "adjTempoRank": 97,
      "conference": "BSth",
      "rank": 333,
      "record": "8-24"
    },
    "Lipscomb": {
      "adjDef": 112,
      "adjDefRank": 316,
      "adjEM": -11.29,
      "adjOff": 100.7,
      "adjOffRank": 244,
      "adjTempo": 67.7,
      "adjTempoRank": 31,
      "conference": "ASun",
      "rank": 298,
      "record": "14-17"
    },
    "Long Beach St.": {
      "adjDef": 100.9,
      "adjDefRank": 108,
      "adjEM": 3.05,
      "adjOff": 104,
      "adjOffRank": 179,
      "adjTempo": 65.2,
      "adjTempoRank": 128,
      "conference": "BW",
      "rank": 136,
      "record": "16-17"
    },
    "Longwood": {
      "adjDef": 111.2,
      "adjDefRank": 306,
      "adjEM": -13.8,
      "adjOff": 97.4,
      "adjOffRank": 297,
      "adjTempo": 66.4,
      "adjTempoRank": 74,
      "conference": "BSth",
      "rank": 316,
      "record": "11-23"
    },
    "Louisiana Lafayette": {
      "adjDef": 102.4,
      "adjDefRank": 136,
      "adjEM": 3.59,
      "adjOff": 106,
      "adjOffRank": 133,
      "adjTempo": 69.9,
      "adjTempoRank": 7,
      "conference": "SB",
      "rank": 128,
      "record": "22-14"
    },
    "Louisiana Monroe": {
      "adjDef": 99.7,
      "adjDefRank": 91,
      "adjEM": -0.71,
      "adjOff": 99,
      "adjOffRank": 270,
      "adjTempo": 61.8,
      "adjTempoRank": 302,
      "conference": "SB",
      "rank": 170,
      "record": "24-14"
    },
    "Louisiana Tech": {
      "adjDef": 99.8,
      "adjDefRank": 93,
      "adjEM": 9.18,
      "adjOff": 109,
      "adjOffRank": 80,
      "adjTempo": 67.4,
      "adjTempoRank": 44,
      "conference": "CUSA",
      "rank": 76,
      "record": "27-9"
    },
    "Louisville": {
      "adjDef": 88.9,
      "adjDefRank": 4,
      "adjEM": 21.21,
      "adjOff": 110.2,
      "adjOffRank": 66,
      "adjTempo": 64.6,
      "adjTempoRank": 155,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 17,
      "record": "27-9",
      "seed": 4
    },
    "Loyola Chicago": {
      "adjDef": 102.6,
      "adjDefRank": 137,
      "adjEM": 3.28,
      "adjOff": 105.9,
      "adjOffRank": 139,
      "adjTempo": 61.1,
      "adjTempoRank": 315,
      "conference": "MVC",
      "rank": 132,
      "record": "24-13"
    },
    "Loyola MD": {
      "adjDef": 106.4,
      "adjDefRank": 210,
      "adjEM": -13.13,
      "adjOff": 93.2,
      "adjOffRank": 331,
      "adjTempo": 62.7,
      "adjTempoRank": 263,
      "conference": "Pat",
      "rank": 308,
      "record": "11-19"
    },
    "Loyola Marymount": {
      "adjDef": 111.6,
      "adjDefRank": 314,
      "adjEM": -8.56,
      "adjOff": 103,
      "adjOffRank": 199,
      "adjTempo": 62.3,
      "adjTempoRank": 280,
      "conference": "WCC",
      "rank": 271,
      "record": "8-23"
    },
    "Maine": {
      "adjDef": 115.5,
      "adjDefRank": 340,
      "adjEM": -21.87,
      "adjOff": 93.7,
      "adjOffRank": 328,
      "adjTempo": 65.9,
      "adjTempoRank": 95,
      "conference": "AE",
      "rank": 345,
      "record": "3-27"
    },
    "Manhattan": {
      "adjDef": 101.6,
      "adjDefRank": 119,
      "adjEM": 0.31,
      "adjOff": 101.9,
      "adjOffRank": 224,
      "adjTempo": 67.7,
      "adjTempoRank": 30,
      "conference": "MAAC",
      "finish": "First Four",
      "rank": 162,
      "record": "19-14",
      "seed": 16
    },
    "Marist": {
      "adjDef": 107.4,
      "adjDefRank": 228,
      "adjEM": -12.74,
      "adjOff": 94.6,
      "adjOffRank": 318,
      "adjTempo": 65,
      "adjTempoRank": 135,
      "conference": "MAAC",
      "rank": 305,
      "record": "7-25"
    },
    "Marquette": {
      "adjDef": 98.5,
      "adjDefRank": 69,
      "adjEM": 6.88,
      "adjOff": 105.3,
      "adjOffRank": 154,
      "adjTempo": 63.7,
      "adjTempoRank": 205,
      "conference": "BE",
      "rank": 93,
      "record": "13-19"
    },
    "Marshall": {
      "adjDef": 104.3,
      "adjDefRank": 169,
      "adjEM": -8.05,
      "adjOff": 96.3,
      "adjOffRank": 307,
      "adjTempo": 69.9,
      "adjTempoRank": 8,
      "conference": "CUSA",
      "rank": 261,
      "record": "11-21"
    },
    "Maryland": {
      "adjDef": 95.7,
      "adjDefRank": 41,
      "adjEM": 15.68,
      "adjOff": 111.3,
      "adjOffRank": 56,
      "adjTempo": 63.9,
      "adjTempoRank": 199,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 33,
      "record": "28-7",
      "seed": 4
    },
    "Maryland Eastern Shore": {
      "adjDef": 107.8,
      "adjDefRank": 239,
      "adjEM": -3.33,
      "adjOff": 104.5,
      "adjOffRank": 170,
      "adjTempo": 64.4,
      "adjTempoRank": 166,
      "conference": "MEAC",
      "rank": 207,
      "record": "18-15"
    },
    "Massachusetts": {
      "adjDef": 102.3,
      "adjDefRank": 131,
      "adjEM": 4.16,
      "adjOff": 106.5,
      "adjOffRank": 125,
      "adjTempo": 67,
      "adjTempoRank": 53,
      "conference": "A10",
      "rank": 125,
      "record": "17-15"
    },
    "McNeese St.": {
      "adjDef": 112.1,
      "adjDefRank": 317,
      "adjEM": -11.58,
      "adjOff": 100.6,
      "adjOffRank": 246,
      "adjTempo": 62.8,
      "adjTempoRank": 260,
      "conference": "Slnd",
      "rank": 300,
      "record": "15-16"
    },
    "Memphis": {
      "adjDef": 98,
      "adjDefRank": 61,
      "adjEM": 7.51,
      "adjOff": 105.5,
      "adjOffRank": 149,
      "adjTempo": 66.2,
      "adjTempoRank": 80,
      "conference": "Amer",
      "rank": 87,
      "record": "18-14"
    },
    "Mercer": {
      "adjDef": 106,
      "adjDefRank": 201,
      "adjEM": -1.52,
      "adjOff": 104.5,
      "adjOffRank": 168,
      "adjTempo": 60.9,
      "adjTempoRank": 321,
      "conference": "SC",
      "rank": 185,
      "record": "19-16"
    },
    "Miami FL": {
      "adjDef": 98.1,
      "adjDefRank": 64,
      "adjEM": 14.49,
      "adjOff": 112.5,
      "adjOffRank": 44,
      "adjTempo": 62.5,
      "adjTempoRank": 272,
      "conference": "ACC",
      "rank": 41,
      "record": "25-13"
    },
    "Miami OH": {
      "adjDef": 106.6,
      "adjDefRank": 214,
      "adjEM": -4.02,
      "adjOff": 102.5,
      "adjOffRank": 212,
      "adjTempo": 64.6,
      "adjTempoRank": 156,
      "conference": "MAC",
      "rank": 215,
      "record": "13-19"
    },
    "Michigan": {
      "adjDef": 100.5,
      "adjDefRank": 100,
      "adjEM": 9.55,
      "adjOff": 110,
      "adjOffRank": 67,
      "adjTempo": 59.7,
      "adjTempoRank": 340,
      "conference": "B10",
      "rank": 74,
      "record": "16-16"
    },
    "Michigan St.": {
      "adjDef": 94.9,
      "adjDefRank": 27,
      "adjEM": 21.72,
      "adjOff": 116.6,
      "adjOffRank": 14,
      "adjTempo": 63.1,
      "adjTempoRank": 248,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 15,
      "record": "27-12",
      "seed": 7
    },
    "Middle Tennessee": {
      "adjDef": 101.7,
      "adjDefRank": 121,
      "adjEM": 0.59,
      "adjOff": 102.3,
      "adjOffRank": 216,
      "adjTempo": 61,
      "adjTempoRank": 320,
      "conference": "CUSA",
      "rank": 158,
      "record": "19-17"
    },
    "Milwaukee": {
      "adjDef": 106.9,
      "adjDefRank": 220,
      "adjEM": -3.76,
      "adjOff": 103.1,
      "adjOffRank": 196,
      "adjTempo": 64.4,
      "adjTempoRank": 172,
      "conference": "Horz",
      "rank": 211,
      "record": "14-16"
    },
    "Minnesota": {
      "adjDef": 99.7,
      "adjDefRank": 90,
      "adjEM": 13.06,
      "adjOff": 112.7,
      "adjOffRank": 41,
      "adjTempo": 67.3,
      "adjTempoRank": 47,
      "conference": "B10",
      "rank": 52,
      "record": "18-15"
    },
    "Mississippi": {
      "adjDef": 100.6,
      "adjDefRank": 104,
      "adjEM": 13.88,
      "adjOff": 114.5,
      "adjOffRank": 28,
      "adjTempo": 66.2,
      "adjTempoRank": 78,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 46,
      "record": "21-13",
      "seed": 11
    },
    "Mississippi St.": {
      "adjDef": 100.1,
      "adjDefRank": 97,
      "adjEM": 0.58,
      "adjOff": 100.7,
      "adjOffRank": 245,
      "adjTempo": 63.8,
      "adjTempoRank": 203,
      "conference": "SEC",
      "rank": 159,
      "record": "13-19"
    },
    "Mississippi Valley St.": {
      "adjDef": 117.3,
      "adjDefRank": 346,
      "adjEM": -25.69,
      "adjOff": 91.6,
      "adjOffRank": 337,
      "adjTempo": 70.1,
      "adjTempoRank": 6,
      "conference": "SWAC",
      "rank": 348,
      "record": "6-26"
    },
    "Missouri": {
      "adjDef": 104.1,
      "adjDefRank": 166,
      "adjEM": -2.09,
      "adjOff": 102,
      "adjOffRank": 220,
      "adjTempo": 62,
      "adjTempoRank": 291,
      "conference": "SEC",
      "rank": 192,
      "record": "9-23"
    },
    "Missouri St.": {
      "adjDef": 106.5,
      "adjDefRank": 212,
      "adjEM": -7.52,
      "adjOff": 98.9,
      "adjOffRank": 272,
      "adjTempo": 61.3,
      "adjTempoRank": 310,
      "conference": "MVC",
      "rank": 255,
      "record": "11-20"
    },
    "Monmouth": {
      "adjDef": 102.1,
      "adjDefRank": 129,
      "adjEM": -1.38,
      "adjOff": 100.8,
      "adjOffRank": 243,
      "adjTempo": 63.6,
      "adjTempoRank": 218,
      "conference": "MAAC",
      "rank": 183,
      "record": "18-15"
    },
    "Montana": {
      "adjDef": 105.8,
      "adjDefRank": 195,
      "adjEM": 0.15,
      "adjOff": 106,
      "adjOffRank": 136,
      "adjTempo": 62,
      "adjTempoRank": 294,
      "conference": "BSky",
      "rank": 164,
      "record": "20-13"
    },
    "Montana St.": {
      "adjDef": 110.8,
      "adjDefRank": 300,
      "adjEM": -15.16,
      "adjOff": 95.7,
      "adjOffRank": 313,
      "adjTempo": 65.3,
      "adjTempoRank": 124,
      "conference": "BSky",
      "rank": 320,
      "record": "7-23"
    },
    "Morehead St.": {
      "adjDef": 103.1,
      "adjDefRank": 147,
      "adjEM": 0.98,
      "adjOff": 104.1,
      "adjOffRank": 175,
      "adjTempo": 64.9,
      "adjTempoRank": 140,
      "conference": "OVC",
      "rank": 154,
      "record": "17-17"
    },
    "Morgan St.": {
      "adjDef": 113.2,
      "adjDefRank": 328,
      "adjEM": -18.87,
      "adjOff": 94.3,
      "adjOffRank": 319,
      "adjTempo": 64.4,
      "adjTempoRank": 169,
      "conference": "MEAC",
      "rank": 332,
      "record": "7-24"
    },
    "Mount St. Mary's": {
      "adjDef": 103.4,
      "adjDefRank": 156,
      "adjEM": -4.97,
      "adjOff": 98.5,
      "adjOffRank": 282,
      "adjTempo": 62.4,
      "adjTempoRank": 276,
      "conference": "NEC",
      "rank": 226,
      "record": "15-15"
    },
    "Murray St.": {
      "adjDef": 104.5,
      "adjDefRank": 173,
      "adjEM": 12.04,
      "adjOff": 116.5,
      "adjOffRank": 16,
      "adjTempo": 66.8,
      "adjTempoRank": 58,
      "conference": "OVC",
      "rank": 57,
      "record": "29-6"
    },
    "N.C. State": {
      "adjDef": 98.9,
      "adjDefRank": 76,
      "adjEM": 15.93,
      "adjOff": 114.9,
      "adjOffRank": 25,
      "adjTempo": 64.1,
      "adjTempoRank": 188,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 32,
      "record": "22-14",
      "seed": 8
    },
    "NJIT": {
      "adjDef": 106.4,
      "adjDefRank": 211,
      "adjEM": -0.85,
      "adjOff": 105.5,
      "adjOffRank": 148,
      "adjTempo": 66.2,
      "adjTempoRank": 81,
      "conference": "ind",
      "rank": 173,
      "record": "21-12"
    },
    "Navy": {
      "adjDef": 107.9,
      "adjDefRank": 243,
      "adjEM": -9.04,
      "adjOff": 98.9,
      "adjOffRank": 273,
      "adjTempo": 61,
      "adjTempoRank": 319,
      "conference": "Pat",
      "rank": 277,
      "record": "13-19"
    },
    "Nebraska": {
      "adjDef": 95,
      "adjDefRank": 31,
      "adjEM": 4.62,
      "adjOff": 99.6,
      "adjOffRank": 264,
      "adjTempo": 63.3,
      "adjTempoRank": 235,
      "conference": "B10",
      "rank": 121,
      "record": "13-18"
    },
    "Nebraska Omaha": {
      "adjDef": 110.5,
      "adjDefRank": 294,
      "adjEM": -7.57,
      "adjOff": 102.9,
      "adjOffRank": 201,
      "adjTempo": 73.4,
      "adjTempoRank": 3,
      "conference": "Sum",
      "rank": 256,
      "record": "12-17"
    },
    "Nevada": {
      "adjDef": 106.4,
      "adjDefRank": 209,
      "adjEM": -8.54,
      "adjOff": 97.8,
      "adjOffRank": 289,
      "adjTempo": 63.7,
      "adjTempoRank": 206,
      "conference": "MWC",
      "rank": 270,
      "record": "9-22"
    },
    "New Hampshire": {
      "adjDef": 102.8,
      "adjDefRank": 144,
      "adjEM": -1.86,
      "adjOff": 100.9,
      "adjOffRank": 239,
      "adjTempo": 63.9,
      "adjTempoRank": 194,
      "conference": "AE",
      "rank": 189,
      "record": "19-13"
    },
    "New Mexico": {
      "adjDef": 98.7,
      "adjDefRank": 74,
      "adjEM": 2.82,
      "adjOff": 101.6,
      "adjOffRank": 228,
      "adjTempo": 62,
      "adjTempoRank": 289,
      "conference": "MWC",
      "rank": 137,
      "record": "15-16"
    },
    "New Mexico St.": {
      "adjDef": 100.8,
      "adjDefRank": 106,
      "adjEM": 6.63,
      "adjOff": 107.4,
      "adjOffRank": 111,
      "adjTempo": 63,
      "adjTempoRank": 254,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 98,
      "record": "23-11",
      "seed": 15
    },
    "New Orleans": {
      "adjDef": 110.2,
      "adjDefRank": 292,
      "adjEM": -13.22,
      "adjOff": 96.9,
      "adjOffRank": 300,
      "adjTempo": 67.6,
      "adjTempoRank": 33,
      "conference": "Slnd",
      "rank": 310,
      "record": "11-18"
    },
    "Niagara": {
      "adjDef": 111.2,
      "adjDefRank": 307,
      "adjEM": -11.41,
      "adjOff": 99.8,
      "adjOffRank": 262,
      "adjTempo": 64.7,
      "adjTempoRank": 149,
      "conference": "MAAC",
      "rank": 299,
      "record": "8-22"
    },
    "Nicholls St.": {
      "adjDef": 108.3,
      "adjDefRank": 253,
      "adjEM": -18.36,
      "adjOff": 90,
      "adjOffRank": 344,
      "adjTempo": 66.1,
      "adjTempoRank": 88,
      "conference": "Slnd",
      "rank": 328,
      "record": "10-19"
    },
    "Norfolk St.": {
      "adjDef": 107.5,
      "adjDefRank": 234,
      "adjEM": -2.01,
      "adjOff": 105.5,
      "adjOffRank": 150,
      "adjTempo": 64.6,
      "adjTempoRank": 157,
      "conference": "MEAC",
      "rank": 191,
      "record": "20-14"
    },
    "North Carolina": {
      "adjDef": 95.3,
      "adjDefRank": 37,
      "adjEM": 23.85,
      "adjOff": 119.1,
      "adjOffRank": 11,
      "adjTempo": 69.2,
      "adjTempoRank": 16,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "26-12",
      "seed": 4
    },
    "North Carolina A&T": {
      "adjDef": 112.9,
      "adjDefRank": 325,
      "adjEM": -19.42,
      "adjOff": 93.5,
      "adjOffRank": 330,
      "adjTempo": 61.6,
      "adjTempoRank": 304,
      "conference": "MEAC",
      "rank": 337,
      "record": "9-23"
    },
    "North Carolina Central": {
      "adjDef": 99.4,
      "adjDefRank": 86,
      "adjEM": 6,
      "adjOff": 105.4,
      "adjOffRank": 153,
      "adjTempo": 59.4,
      "adjTempoRank": 342,
      "conference": "MEAC",
      "rank": 104,
      "record": "25-8"
    },
    "North Dakota": {
      "adjDef": 110.6,
      "adjDefRank": 296,
      "adjEM": -13.77,
      "adjOff": 96.9,
      "adjOffRank": 301,
      "adjTempo": 67.6,
      "adjTempoRank": 34,
      "conference": "BSky",
      "rank": 314,
      "record": "8-22"
    },
    "North Dakota St.": {
      "adjDef": 102.4,
      "adjDefRank": 134,
      "adjEM": 1.41,
      "adjOff": 103.8,
      "adjOffRank": 181,
      "adjTempo": 60.8,
      "adjTempoRank": 324,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 152,
      "record": "23-10",
      "seed": 15
    },
    "North Florida": {
      "adjDef": 106.2,
      "adjDefRank": 204,
      "adjEM": 2.26,
      "adjOff": 108.4,
      "adjOffRank": 88,
      "adjTempo": 67.1,
      "adjTempoRank": 51,
      "conference": "ASun",
      "finish": "First Four",
      "rank": 143,
      "record": "23-12",
      "seed": 16
    },
    "North Texas": {
      "adjDef": 105.2,
      "adjDefRank": 183,
      "adjEM": -6.66,
      "adjOff": 98.6,
      "adjOffRank": 279,
      "adjTempo": 65.5,
      "adjTempoRank": 113,
      "conference": "CUSA",
      "rank": 245,
      "record": "14-17"
    },
    "Northeastern": {
      "adjDef": 103.1,
      "adjDefRank": 148,
      "adjEM": 5.12,
      "adjOff": 108.3,
      "adjOffRank": 93,
      "adjTempo": 63.3,
      "adjTempoRank": 237,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 117,
      "record": "23-12",
      "seed": 14
    },
    "Northern Arizona": {
      "adjDef": 103.4,
      "adjDefRank": 155,
      "adjEM": -1.12,
      "adjOff": 102.2,
      "adjOffRank": 217,
      "adjTempo": 64.8,
      "adjTempoRank": 147,
      "conference": "BSky",
      "rank": 178,
      "record": "23-15"
    },
    "Northern Colorado": {
      "adjDef": 117.9,
      "adjDefRank": 349,
      "adjEM": -7.45,
      "adjOff": 110.4,
      "adjOffRank": 63,
      "adjTempo": 63.2,
      "adjTempoRank": 247,
      "conference": "BSky",
      "rank": 252,
      "record": "15-15"
    },
    "Northern Illinois": {
      "adjDef": 104,
      "adjDefRank": 163,
      "adjEM": -1.2,
      "adjOff": 102.8,
      "adjOffRank": 207,
      "adjTempo": 62.5,
      "adjTempoRank": 273,
      "conference": "MAC",
      "rank": 179,
      "record": "14-16"
    },
    "Northern Iowa": {
      "adjDef": 95.3,
      "adjDefRank": 36,
      "adjEM": 20.98,
      "adjOff": 116.3,
      "adjOffRank": 18,
      "adjTempo": 58.2,
      "adjTempoRank": 348,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 18,
      "record": "31-4",
      "seed": 5
    },
    "Northern Kentucky": {
      "adjDef": 110.9,
      "adjDefRank": 301,
      "adjEM": -6.72,
      "adjOff": 104.2,
      "adjOffRank": 174,
      "adjTempo": 63.6,
      "adjTempoRank": 217,
      "conference": "ASun",
      "rank": 246,
      "record": "13-17"
    },
    "Northwestern": {
      "adjDef": 103.2,
      "adjDefRank": 151,
      "adjEM": 4.59,
      "adjOff": 107.8,
      "adjOffRank": 100,
      "adjTempo": 59.7,
      "adjTempoRank": 341,
      "conference": "B10",
      "rank": 122,
      "record": "15-17"
    },
    "Northwestern St.": {
      "adjDef": 117.6,
      "adjDefRank": 348,
      "adjEM": -5.11,
      "adjOff": 112.5,
      "adjOffRank": 45,
      "adjTempo": 73.2,
      "adjTempoRank": 4,
      "conference": "Slnd",
      "rank": 229,
      "record": "19-13"
    },
    "Notre Dame": {
      "adjDef": 100.4,
      "adjDefRank": 99,
      "adjEM": 24.96,
      "adjOff": 125.4,
      "adjOffRank": 2,
      "adjTempo": 63.4,
      "adjTempoRank": 226,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "32-6",
      "seed": 3
    },
    "Oakland": {
      "adjDef": 111.3,
      "adjDefRank": 309,
      "adjEM": 0.06,
      "adjOff": 111.4,
      "adjOffRank": 55,
      "adjTempo": 66.5,
      "adjTempoRank": 71,
      "conference": "Horz",
      "rank": 167,
      "record": "16-17"
    },
    "Ohio": {
      "adjDef": 108.5,
      "adjDefRank": 257,
      "adjEM": -5.47,
      "adjOff": 103,
      "adjOffRank": 198,
      "adjTempo": 66.4,
      "adjTempoRank": 73,
      "conference": "MAC",
      "rank": 232,
      "record": "10-20"
    },
    "Ohio St.": {
      "adjDef": 94.8,
      "adjDefRank": 25,
      "adjEM": 20.75,
      "adjOff": 115.6,
      "adjOffRank": 22,
      "adjTempo": 65.2,
      "adjTempoRank": 132,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 19,
      "record": "24-11",
      "seed": 10
    },
    "Oklahoma": {
      "adjDef": 89.7,
      "adjDefRank": 7,
      "adjEM": 22.8,
      "adjOff": 112.5,
      "adjOffRank": 46,
      "adjTempo": 66.7,
      "adjTempoRank": 63,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 11,
      "record": "24-11",
      "seed": 3
    },
    "Oklahoma St.": {
      "adjDef": 96.8,
      "adjDefRank": 53,
      "adjEM": 14.92,
      "adjOff": 111.8,
      "adjOffRank": 51,
      "adjTempo": 63.3,
      "adjTempoRank": 238,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 38,
      "record": "18-14",
      "seed": 9
    },
    "Old Dominion": {
      "adjDef": 96.3,
      "adjDefRank": 46,
      "adjEM": 10.19,
      "adjOff": 106.5,
      "adjOffRank": 124,
      "adjTempo": 59.9,
      "adjTempoRank": 338,
      "conference": "CUSA",
      "rank": 70,
      "record": "27-8"
    },
    "Oral Roberts": {
      "adjDef": 109.4,
      "adjDefRank": 274,
      "adjEM": -3.17,
      "adjOff": 106.2,
      "adjOffRank": 132,
      "adjTempo": 65.5,
      "adjTempoRank": 112,
      "conference": "Sum",
      "rank": 206,
      "record": "19-15"
    },
    "Oregon": {
      "adjDef": 101.3,
      "adjDefRank": 114,
      "adjEM": 13.88,
      "adjOff": 115.2,
      "adjOffRank": 23,
      "adjTempo": 66.6,
      "adjTempoRank": 68,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 45,
      "record": "26-10",
      "seed": 8
    },
    "Oregon St.": {
      "adjDef": 94.1,
      "adjDefRank": 18,
      "adjEM": 5.9,
      "adjOff": 100,
      "adjOffRank": 255,
      "adjTempo": 60.5,
      "adjTempoRank": 329,
      "conference": "P12",
      "rank": 107,
      "record": "17-14"
    },
    "Pacific": {
      "adjDef": 109.5,
      "adjDefRank": 277,
      "adjEM": -6.31,
      "adjOff": 103.1,
      "adjOffRank": 195,
      "adjTempo": 61.4,
      "adjTempoRank": 309,
      "conference": "WCC",
      "rank": 240,
      "record": "12-19"
    },
    "Penn": {
      "adjDef": 108.2,
      "adjDefRank": 250,
      "adjEM": -10.46,
      "adjOff": 97.7,
      "adjOffRank": 291,
      "adjTempo": 63.3,
      "adjTempoRank": 241,
      "conference": "Ivy",
      "rank": 291,
      "record": "9-19"
    },
    "Penn St.": {
      "adjDef": 97.2,
      "adjDefRank": 58,
      "adjEM": 8.08,
      "adjOff": 105.3,
      "adjOffRank": 156,
      "adjTempo": 65.4,
      "adjTempoRank": 119,
      "conference": "B10",
      "rank": 82,
      "record": "18-16"
    },
    "Pepperdine": {
      "adjDef": 96.4,
      "adjDefRank": 47,
      "adjEM": 4.1,
      "adjOff": 100.5,
      "adjOffRank": 247,
      "adjTempo": 63.5,
      "adjTempoRank": 222,
      "conference": "WCC",
      "rank": 126,
      "record": "18-14"
    },
    "Pittsburgh": {
      "adjDef": 104.8,
      "adjDefRank": 180,
      "adjEM": 8.65,
      "adjOff": 113.5,
      "adjOffRank": 35,
      "adjTempo": 60.6,
      "adjTempoRank": 327,
      "conference": "ACC",
      "rank": 78,
      "record": "19-15"
    },
    "Portland": {
      "adjDef": 105.8,
      "adjDefRank": 193,
      "adjEM": 3.23,
      "adjOff": 109,
      "adjOffRank": 79,
      "adjTempo": 64.2,
      "adjTempoRank": 180,
      "conference": "WCC",
      "rank": 134,
      "record": "17-16"
    },
    "Portland St.": {
      "adjDef": 110.7,
      "adjDefRank": 297,
      "adjEM": -7.77,
      "adjOff": 102.9,
      "adjOffRank": 202,
      "adjTempo": 65.9,
      "adjTempoRank": 96,
      "conference": "BSky",
      "rank": 258,
      "record": "15-14"
    },
    "Prairie View A&M": {
      "adjDef": 109.4,
      "adjDefRank": 275,
      "adjEM": -11.98,
      "adjOff": 97.4,
      "adjOffRank": 295,
      "adjTempo": 68.6,
      "adjTempoRank": 20,
      "conference": "SWAC",
      "rank": 302,
      "record": "15-18"
    },
    "Presbyterian": {
      "adjDef": 114.7,
      "adjDefRank": 335,
      "adjEM": -18.09,
      "adjOff": 96.6,
      "adjOffRank": 305,
      "adjTempo": 62,
      "adjTempoRank": 293,
      "conference": "BSth",
      "rank": 326,
      "record": "10-22"
    },
    "Princeton": {
      "adjDef": 106.9,
      "adjDefRank": 221,
      "adjEM": 0.55,
      "adjOff": 107.4,
      "adjOffRank": 110,
      "adjTempo": 64.6,
      "adjTempoRank": 158,
      "conference": "Ivy",
      "rank": 160,
      "record": "16-14"
    },
    "Providence": {
      "adjDef": 95.9,
      "adjDefRank": 42,
      "adjEM": 16.72,
      "adjOff": 112.6,
      "adjOffRank": 42,
      "adjTempo": 65.2,
      "adjTempoRank": 129,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 27,
      "record": "22-12",
      "seed": 6
    },
    "Purdue": {
      "adjDef": 97,
      "adjDefRank": 54,
      "adjEM": 13.96,
      "adjOff": 110.9,
      "adjOffRank": 61,
      "adjTempo": 64.3,
      "adjTempoRank": 173,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 44,
      "record": "21-13",
      "seed": 9
    },
    "Quinnipiac": {
      "adjDef": 104.1,
      "adjDefRank": 165,
      "adjEM": -1.09,
      "adjOff": 103,
      "adjOffRank": 200,
      "adjTempo": 66.3,
      "adjTempoRank": 76,
      "conference": "MAAC",
      "rank": 177,
      "record": "15-15"
    },
    "Radford": {
      "adjDef": 106.3,
      "adjDefRank": 207,
      "adjEM": -0.77,
      "adjOff": 105.6,
      "adjOffRank": 147,
      "adjTempo": 65,
      "adjTempoRank": 139,
      "conference": "BSth",
      "rank": 171,
      "record": "22-12"
    },
    "Rhode Island": {
      "adjDef": 92.2,
      "adjDefRank": 13,
      "adjEM": 11.74,
      "adjOff": 103.9,
      "adjOffRank": 180,
      "adjTempo": 66,
      "adjTempoRank": 89,
      "conference": "A10",
      "rank": 60,
      "record": "23-10"
    },
    "Rice": {
      "adjDef": 105.6,
      "adjDefRank": 189,
      "adjEM": -3.62,
      "adjOff": 102,
      "adjOffRank": 221,
      "adjTempo": 62,
      "adjTempoRank": 296,
      "conference": "CUSA",
      "rank": 210,
      "record": "12-20"
    },
    "Richmond": {
      "adjDef": 96.8,
      "adjDefRank": 52,
      "adjEM": 11.76,
      "adjOff": 108.6,
      "adjOffRank": 87,
      "adjTempo": 61.8,
      "adjTempoRank": 301,
      "conference": "A10",
      "rank": 59,
      "record": "21-14"
    },
    "Rider": {
      "adjDef": 101,
      "adjDefRank": 109,
      "adjEM": 0.17,
      "adjOff": 101.2,
      "adjOffRank": 237,
      "adjTempo": 64.2,
      "adjTempoRank": 179,
      "conference": "MAAC",
      "rank": 163,
      "record": "21-12"
    },
    "Robert Morris": {
      "adjDef": 104.5,
      "adjDefRank": 172,
      "adjEM": -1.39,
      "adjOff": 103.1,
      "adjOffRank": 197,
      "adjTempo": 65.4,
      "adjTempoRank": 118,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 184,
      "record": "20-15",
      "seed": 16
    },
    "Rutgers": {
      "adjDef": 101.1,
      "adjDefRank": 110,
      "adjEM": -2.45,
      "adjOff": 98.7,
      "adjOffRank": 278,
      "adjTempo": 64.9,
      "adjTempoRank": 143,
      "conference": "B10",
      "rank": 198,
      "record": "10-22"
    },
    "SIU Edwardsville": {
      "adjDef": 104.8,
      "adjDefRank": 179,
      "adjEM": -6.9,
      "adjOff": 97.9,
      "adjOffRank": 288,
      "adjTempo": 66.3,
      "adjTempoRank": 77,
      "conference": "OVC",
      "rank": 248,
      "record": "12-16"
    },
    "SMU": {
      "adjDef": 95.6,
      "adjDefRank": 40,
      "adjEM": 17.51,
      "adjOff": 113.1,
      "adjOffRank": 37,
      "adjTempo": 63.1,
      "adjTempoRank": 250,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 26,
      "record": "27-7",
      "seed": 6
    },
    "Sacramento St.": {
      "adjDef": 111.3,
      "adjDefRank": 310,
      "adjEM": -2.33,
      "adjOff": 109,
      "adjOffRank": 78,
      "adjTempo": 61.9,
      "adjTempoRank": 297,
      "conference": "BSky",
      "rank": 196,
      "record": "21-12"
    },
    "Sacred Heart": {
      "adjDef": 109.6,
      "adjDefRank": 281,
      "adjEM": -6.16,
      "adjOff": 103.4,
      "adjOffRank": 189,
      "adjTempo": 68.5,
      "adjTempoRank": 22,
      "conference": "NEC",
      "rank": 238,
      "record": "15-17"
    },
    "Saint Joseph's": {
      "adjDef": 100,
      "adjDefRank": 94,
      "adjEM": 0.34,
      "adjOff": 100.3,
      "adjOffRank": 250,
      "adjTempo": 63.3,
      "adjTempoRank": 239,
      "conference": "A10",
      "rank": 161,
      "record": "13-18"
    },
    "Saint Louis": {
      "adjDef": 105.8,
      "adjDefRank": 194,
      "adjEM": -9.85,
      "adjOff": 95.9,
      "adjOffRank": 311,
      "adjTempo": 62.8,
      "adjTempoRank": 261,
      "conference": "A10",
      "rank": 289,
      "record": "11-21"
    },
    "Saint Mary's": {
      "adjDef": 100.6,
      "adjDefRank": 102,
      "adjEM": 10.36,
      "adjOff": 110.9,
      "adjOffRank": 60,
      "adjTempo": 62.6,
      "adjTempoRank": 271,
      "conference": "WCC",
      "rank": 67,
      "record": "21-10"
    },
    "Saint Peter's": {
      "adjDef": 101.7,
      "adjDefRank": 123,
      "adjEM": -1.72,
      "adjOff": 100,
      "adjOffRank": 254,
      "adjTempo": 60.2,
      "adjTempoRank": 337,
      "conference": "MAAC",
      "rank": 187,
      "record": "16-18"
    },
    "Sam Houston St.": {
      "adjDef": 96.5,
      "adjDefRank": 48,
      "adjEM": 6.84,
      "adjOff": 103.4,
      "adjOffRank": 190,
      "adjTempo": 64.9,
      "adjTempoRank": 142,
      "conference": "Slnd",
      "rank": 95,
      "record": "26-9"
    },
    "Samford": {
      "adjDef": 112.6,
      "adjDefRank": 323,
      "adjEM": -10.69,
      "adjOff": 102,
      "adjOffRank": 222,
      "adjTempo": 66.1,
      "adjTempoRank": 86,
      "conference": "SC",
      "rank": 293,
      "record": "13-19"
    },
    "San Diego": {
      "adjDef": 98,
      "adjDefRank": 62,
      "adjEM": 5.95,
      "adjOff": 104,
      "adjOffRank": 178,
      "adjTempo": 62.9,
      "adjTempoRank": 257,
      "conference": "WCC",
      "rank": 106,
      "record": "15-16"
    },
    "San Diego St.": {
      "adjDef": 89,
      "adjDefRank": 5,
      "adjEM": 15.95,
      "adjOff": 104.9,
      "adjOffRank": 162,
      "adjTempo": 60.4,
      "adjTempoRank": 333,
      "conference": "MWC",
      "finish": "Round of 32",
      "rank": 31,
      "record": "27-9",
      "seed": 8
    },
    "San Francisco": {
      "adjDef": 103.5,
      "adjDefRank": 158,
      "adjEM": 2.16,
      "adjOff": 105.7,
      "adjOffRank": 142,
      "adjTempo": 65.5,
      "adjTempoRank": 111,
      "conference": "WCC",
      "rank": 144,
      "record": "14-18"
    },
    "San Jose St.": {
      "adjDef": 111,
      "adjDefRank": 302,
      "adjEM": -24.59,
      "adjOff": 86.5,
      "adjOffRank": 349,
      "adjTempo": 63.8,
      "adjTempoRank": 201,
      "conference": "MWC",
      "rank": 347,
      "record": "2-28"
    },
    "Santa Clara": {
      "adjDef": 108,
      "adjDefRank": 246,
      "adjEM": -2.14,
      "adjOff": 105.9,
      "adjOffRank": 138,
      "adjTempo": 61.1,
      "adjTempoRank": 316,
      "conference": "WCC",
      "rank": 194,
      "record": "14-18"
    },
    "Savannah St.": {
      "adjDef": 107.1,
      "adjDefRank": 224,
      "adjEM": -19.67,
      "adjOff": 87.4,
      "adjOffRank": 348,
      "adjTempo": 64.5,
      "adjTempoRank": 163,
      "conference": "MEAC",
      "rank": 339,
      "record": "9-22"
    },
    "Seattle": {
      "adjDef": 104.9,
      "adjDefRank": 181,
      "adjEM": -6.64,
      "adjOff": 98.3,
      "adjOffRank": 285,
      "adjTempo": 62.3,
      "adjTempoRank": 281,
      "conference": "WAC",
      "rank": 244,
      "record": "18-16"
    },
    "Seton Hall": {
      "adjDef": 101.3,
      "adjDefRank": 113,
      "adjEM": 7.98,
      "adjOff": 109.3,
      "adjOffRank": 73,
      "adjTempo": 65.7,
      "adjTempoRank": 105,
      "conference": "BE",
      "rank": 83,
      "record": "16-15"
    },
    "Siena": {
      "adjDef": 114,
      "adjDefRank": 331,
      "adjEM": -8.31,
      "adjOff": 105.7,
      "adjOffRank": 143,
      "adjTempo": 66,
      "adjTempoRank": 90,
      "conference": "MAAC",
      "rank": 265,
      "record": "11-20"
    },
    "South Alabama": {
      "adjDef": 112.2,
      "adjDefRank": 318,
      "adjEM": -11.28,
      "adjOff": 101,
      "adjOffRank": 238,
      "adjTempo": 68.6,
      "adjTempoRank": 21,
      "conference": "SB",
      "rank": 297,
      "record": "12-21"
    },
    "South Carolina": {
      "adjDef": 94.3,
      "adjDefRank": 21,
      "adjEM": 11.32,
      "adjOff": 105.6,
      "adjOffRank": 145,
      "adjTempo": 64.1,
      "adjTempoRank": 187,
      "conference": "SEC",
      "rank": 63,
      "record": "17-16"
    },
    "South Carolina St.": {
      "adjDef": 109.1,
      "adjDefRank": 268,
      "adjEM": -18.69,
      "adjOff": 90.4,
      "adjOffRank": 343,
      "adjTempo": 64.1,
      "adjTempoRank": 189,
      "conference": "MEAC",
      "rank": 331,
      "record": "11-22"
    },
    "South Dakota": {
      "adjDef": 107.8,
      "adjDefRank": 237,
      "adjEM": -4.36,
      "adjOff": 103.4,
      "adjOffRank": 188,
      "adjTempo": 65.2,
      "adjTempoRank": 130,
      "conference": "Sum",
      "rank": 219,
      "record": "17-16"
    },
    "South Dakota St.": {
      "adjDef": 102,
      "adjDefRank": 128,
      "adjEM": 6.85,
      "adjOff": 108.8,
      "adjOffRank": 84,
      "adjTempo": 66.1,
      "adjTempoRank": 87,
      "conference": "Sum",
      "rank": 94,
      "record": "24-11"
    },
    "South Florida": {
      "adjDef": 108.3,
      "adjDefRank": 251,
      "adjEM": -9.02,
      "adjOff": 99.2,
      "adjOffRank": 267,
      "adjTempo": 63.7,
      "adjTempoRank": 212,
      "conference": "Amer",
      "rank": 274,
      "record": "9-23"
    },
    "Southeast Missouri St.": {
      "adjDef": 108.4,
      "adjDefRank": 254,
      "adjEM": -5.54,
      "adjOff": 102.8,
      "adjOffRank": 205,
      "adjTempo": 65.6,
      "adjTempoRank": 110,
      "conference": "OVC",
      "rank": 233,
      "record": "13-17"
    },
    "Southeastern Louisiana": {
      "adjDef": 108.8,
      "adjDefRank": 263,
      "adjEM": -12.89,
      "adjOff": 95.9,
      "adjOffRank": 312,
      "adjTempo": 66.2,
      "adjTempoRank": 82,
      "conference": "Slnd",
      "rank": 306,
      "record": "9-23"
    },
    "Southern": {
      "adjDef": 103.1,
      "adjDefRank": 149,
      "adjEM": -9.15,
      "adjOff": 94,
      "adjOffRank": 322,
      "adjTempo": 64.7,
      "adjTempoRank": 153,
      "conference": "SWAC",
      "rank": 278,
      "record": "18-17"
    },
    "Southern Illinois": {
      "adjDef": 103.6,
      "adjDefRank": 160,
      "adjEM": -4.79,
      "adjOff": 98.8,
      "adjOffRank": 275,
      "adjTempo": 62.2,
      "adjTempoRank": 282,
      "conference": "MVC",
      "rank": 223,
      "record": "12-21"
    },
    "Southern Miss": {
      "adjDef": 111,
      "adjDefRank": 303,
      "adjEM": -13.78,
      "adjOff": 97.3,
      "adjOffRank": 298,
      "adjTempo": 62,
      "adjTempoRank": 295,
      "conference": "CUSA",
      "rank": 315,
      "record": "9-20"
    },
    "Southern Utah": {
      "adjDef": 111.8,
      "adjDefRank": 315,
      "adjEM": -13.12,
      "adjOff": 98.7,
      "adjOffRank": 276,
      "adjTempo": 67.5,
      "adjTempoRank": 36,
      "conference": "BSky",
      "rank": 307,
      "record": "10-19"
    },
    "St. Bonaventure": {
      "adjDef": 100.1,
      "adjDefRank": 96,
      "adjEM": 5.31,
      "adjOff": 105.4,
      "adjOffRank": 152,
      "adjTempo": 64.4,
      "adjTempoRank": 167,
      "conference": "A10",
      "rank": 112,
      "record": "18-13"
    },
    "St. Francis NY": {
      "adjDef": 103,
      "adjDefRank": 146,
      "adjEM": 0.62,
      "adjOff": 103.6,
      "adjOffRank": 185,
      "adjTempo": 64.5,
      "adjTempoRank": 165,
      "conference": "NEC",
      "rank": 157,
      "record": "23-12"
    },
    "St. Francis PA": {
      "adjDef": 104.4,
      "adjDefRank": 171,
      "adjEM": -4.58,
      "adjOff": 99.8,
      "adjOffRank": 261,
      "adjTempo": 62.7,
      "adjTempoRank": 268,
      "conference": "NEC",
      "rank": 221,
      "record": "16-16"
    },
    "St. John's": {
      "adjDef": 97,
      "adjDefRank": 55,
      "adjEM": 13.7,
      "adjOff": 110.7,
      "adjOffRank": 62,
      "adjTempo": 66.7,
      "adjTempoRank": 61,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 50,
      "record": "21-12",
      "seed": 9
    },
    "Stanford": {
      "adjDef": 98.9,
      "adjDefRank": 75,
      "adjEM": 14.72,
      "adjOff": 113.6,
      "adjOffRank": 33,
      "adjTempo": 65.2,
      "adjTempoRank": 131,
      "conference": "P12",
      "rank": 39,
      "record": "24-13"
    },
    "Stephen F. Austin": {
      "adjDef": 100.3,
      "adjDefRank": 98,
      "adjEM": 13.64,
      "adjOff": 113.9,
      "adjOffRank": 32,
      "adjTempo": 65.4,
      "adjTempoRank": 114,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 51,
      "record": "29-5",
      "seed": 12
    },
    "Stetson": {
      "adjDef": 116.3,
      "adjDefRank": 343,
      "adjEM": -19.66,
      "adjOff": 96.6,
      "adjOffRank": 304,
      "adjTempo": 65.8,
      "adjTempoRank": 99,
      "conference": "ASun",
      "rank": 338,
      "record": "9-22"
    },
    "Stony Brook": {
      "adjDef": 99.5,
      "adjDefRank": 88,
      "adjEM": 3.35,
      "adjOff": 102.8,
      "adjOffRank": 206,
      "adjTempo": 64.1,
      "adjTempoRank": 186,
      "conference": "AE",
      "rank": 130,
      "record": "23-12"
    },
    "Syracuse": {
      "adjDef": 94.2,
      "adjDefRank": 20,
      "adjEM": 12.78,
      "adjOff": 107,
      "adjOffRank": 117,
      "adjTempo": 65.8,
      "adjTempoRank": 100,
      "conference": "ACC",
      "rank": 53,
      "record": "18-13"
    },
    "TCU": {
      "adjDef": 96.6,
      "adjDefRank": 49,
      "adjEM": 12.25,
      "adjOff": 108.8,
      "adjOffRank": 83,
      "adjTempo": 63.6,
      "adjTempoRank": 215,
      "conference": "B12",
      "rank": 56,
      "record": "18-15"
    },
    "Temple": {
      "adjDef": 94.2,
      "adjDefRank": 19,
      "adjEM": 11.1,
      "adjOff": 105.3,
      "adjOffRank": 155,
      "adjTempo": 65.4,
      "adjTempoRank": 116,
      "conference": "Amer",
      "rank": 66,
      "record": "26-11"
    },
    "Tennessee": {
      "adjDef": 102.3,
      "adjDefRank": 130,
      "adjEM": 7.24,
      "adjOff": 109.5,
      "adjOffRank": 71,
      "adjTempo": 60.4,
      "adjTempoRank": 332,
      "conference": "SEC",
      "rank": 90,
      "record": "16-16"
    },
    "Tennessee Martin": {
      "adjDef": 107.3,
      "adjDefRank": 227,
      "adjEM": 1.84,
      "adjOff": 109.2,
      "adjOffRank": 76,
      "adjTempo": 62,
      "adjTempoRank": 292,
      "conference": "OVC",
      "rank": 148,
      "record": "21-13"
    },
    "Tennessee St.": {
      "adjDef": 108.4,
      "adjDefRank": 255,
      "adjEM": -18.9,
      "adjOff": 89.5,
      "adjOffRank": 345,
      "adjTempo": 63.5,
      "adjTempoRank": 221,
      "conference": "OVC",
      "rank": 334,
      "record": "5-26"
    },
    "Tennessee Tech": {
      "adjDef": 111.1,
      "adjDefRank": 304,
      "adjEM": -6.88,
      "adjOff": 104.2,
      "adjOffRank": 173,
      "adjTempo": 66.2,
      "adjTempoRank": 84,
      "conference": "OVC",
      "rank": 247,
      "record": "12-18"
    },
    "Texas": {
      "adjDef": 93.4,
      "adjDefRank": 14,
      "adjEM": 18.94,
      "adjOff": 112.4,
      "adjOffRank": 47,
      "adjTempo": 62,
      "adjTempoRank": 290,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 24,
      "record": "20-14",
      "seed": 11
    },
    "Texas A&M": {
      "adjDef": 97.1,
      "adjDefRank": 57,
      "adjEM": 12.31,
      "adjOff": 109.4,
      "adjOffRank": 72,
      "adjTempo": 63.7,
      "adjTempoRank": 207,
      "conference": "SEC",
      "rank": 54,
      "record": "21-12"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 105.9,
      "adjDefRank": 198,
      "adjEM": -5.05,
      "adjOff": 100.8,
      "adjOffRank": 241,
      "adjTempo": 62.7,
      "adjTempoRank": 267,
      "conference": "Slnd",
      "rank": 227,
      "record": "20-14"
    },
    "Texas Pan American": {
      "adjDef": 112.5,
      "adjDefRank": 321,
      "adjEM": -18.99,
      "adjOff": 93.5,
      "adjOffRank": 329,
      "adjTempo": 63.9,
      "adjTempoRank": 196,
      "conference": "WAC",
      "rank": 335,
      "record": "10-21"
    },
    "Texas Southern": {
      "adjDef": 107.9,
      "adjDefRank": 241,
      "adjEM": -3.84,
      "adjOff": 104.1,
      "adjOffRank": 177,
      "adjTempo": 63.3,
      "adjTempoRank": 240,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 213,
      "record": "22-13",
      "seed": 15
    },
    "Texas St.": {
      "adjDef": 98.9,
      "adjDefRank": 77,
      "adjEM": -5.2,
      "adjOff": 93.7,
      "adjOffRank": 326,
      "adjTempo": 61.2,
      "adjTempoRank": 312,
      "conference": "SB",
      "rank": 230,
      "record": "14-17"
    },
    "Texas Tech": {
      "adjDef": 101.3,
      "adjDefRank": 116,
      "adjEM": 0.97,
      "adjOff": 102.3,
      "adjOffRank": 215,
      "adjTempo": 61.6,
      "adjTempoRank": 306,
      "conference": "B12",
      "rank": 155,
      "record": "13-19"
    },
    "The Citadel": {
      "adjDef": 121.8,
      "adjDefRank": 351,
      "adjEM": -18.59,
      "adjOff": 103.2,
      "adjOffRank": 193,
      "adjTempo": 58.6,
      "adjTempoRank": 347,
      "conference": "SC",
      "rank": 329,
      "record": "11-19"
    },
    "Toledo": {
      "adjDef": 105.8,
      "adjDefRank": 196,
      "adjEM": 7.46,
      "adjOff": 113.3,
      "adjOffRank": 36,
      "adjTempo": 66.4,
      "adjTempoRank": 72,
      "conference": "MAC",
      "rank": 88,
      "record": "20-13"
    },
    "Towson": {
      "adjDef": 109.3,
      "adjDefRank": 273,
      "adjEM": -8.38,
      "adjOff": 100.9,
      "adjOffRank": 240,
      "adjTempo": 60.3,
      "adjTempoRank": 334,
      "conference": "CAA",
      "rank": 267,
      "record": "12-20"
    },
    "Troy": {
      "adjDef": 109.5,
      "adjDefRank": 280,
      "adjEM": -9.75,
      "adjOff": 99.8,
      "adjOffRank": 260,
      "adjTempo": 64.7,
      "adjTempoRank": 152,
      "conference": "SB",
      "rank": 287,
      "record": "10-19"
    },
    "Tulane": {
      "adjDef": 104.2,
      "adjDefRank": 167,
      "adjEM": -4.37,
      "adjOff": 99.8,
      "adjOffRank": 258,
      "adjTempo": 63,
      "adjTempoRank": 255,
      "conference": "Amer",
      "rank": 220,
      "record": "15-16"
    },
    "Tulsa": {
      "adjDef": 94.9,
      "adjDefRank": 28,
      "adjEM": 7.75,
      "adjOff": 102.6,
      "adjOffRank": 211,
      "adjTempo": 63.9,
      "adjTempoRank": 197,
      "conference": "Amer",
      "rank": 85,
      "record": "23-11"
    },
    "UAB": {
      "adjDef": 101.9,
      "adjDefRank": 126,
      "adjEM": 3.06,
      "adjOff": 104.9,
      "adjOffRank": 163,
      "adjTempo": 64.7,
      "adjTempoRank": 151,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 135,
      "record": "20-16",
      "seed": 14
    },
    "UC Davis": {
      "adjDef": 106.2,
      "adjDefRank": 205,
      "adjEM": 6.14,
      "adjOff": 112.3,
      "adjOffRank": 48,
      "adjTempo": 62.9,
      "adjTempoRank": 258,
      "conference": "BW",
      "rank": 101,
      "record": "25-7"
    },
    "UC Irvine": {
      "adjDef": 99.2,
      "adjDefRank": 82,
      "adjEM": 7.09,
      "adjOff": 106.3,
      "adjOffRank": 129,
      "adjTempo": 63.4,
      "adjTempoRank": 227,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 91,
      "record": "21-13",
      "seed": 13
    },
    "UC Riverside": {
      "adjDef": 102.8,
      "adjDefRank": 143,
      "adjEM": -4.22,
      "adjOff": 98.5,
      "adjOffRank": 280,
      "adjTempo": 65.3,
      "adjTempoRank": 121,
      "conference": "BW",
      "rank": 216,
      "record": "14-17"
    },
    "UC Santa Barbara": {
      "adjDef": 103.3,
      "adjDefRank": 152,
      "adjEM": 6,
      "adjOff": 109.3,
      "adjOffRank": 74,
      "adjTempo": 62.6,
      "adjTempoRank": 270,
      "conference": "BW",
      "rank": 105,
      "record": "19-14"
    },
    "UCF": {
      "adjDef": 112.3,
      "adjDefRank": 319,
      "adjEM": -9.58,
      "adjOff": 102.7,
      "adjOffRank": 209,
      "adjTempo": 64,
      "adjTempoRank": 193,
      "conference": "Amer",
      "rank": 281,
      "record": "12-18"
    },
    "UCLA": {
      "adjDef": 98.2,
      "adjDefRank": 66,
      "adjEM": 14.65,
      "adjOff": 112.9,
      "adjOffRank": 40,
      "adjTempo": 65.8,
      "adjTempoRank": 101,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 40,
      "record": "22-14",
      "seed": 11
    },
    "UMBC": {
      "adjDef": 109.9,
      "adjDefRank": 289,
      "adjEM": -20.68,
      "adjOff": 89.2,
      "adjOffRank": 347,
      "adjTempo": 64.2,
      "adjTempoRank": 178,
      "conference": "AE",
      "rank": 343,
      "record": "4-26"
    },
    "UMKC": {
      "adjDef": 107.9,
      "adjDefRank": 244,
      "adjEM": -9.74,
      "adjOff": 98.2,
      "adjOffRank": 287,
      "adjTempo": 63.5,
      "adjTempoRank": 219,
      "conference": "WAC",
      "rank": 286,
      "record": "14-19"
    },
    "UMass Lowell": {
      "adjDef": 106.6,
      "adjDefRank": 215,
      "adjEM": -12.46,
      "adjOff": 94.1,
      "adjOffRank": 320,
      "adjTempo": 63.7,
      "adjTempoRank": 211,
      "conference": "AE",
      "rank": 303,
      "record": "12-17"
    },
    "UNC Asheville": {
      "adjDef": 108.6,
      "adjDefRank": 260,
      "adjEM": -6.14,
      "adjOff": 102.4,
      "adjOffRank": 214,
      "adjTempo": 67.8,
      "adjTempoRank": 29,
      "conference": "BSth",
      "rank": 237,
      "record": "15-16"
    },
    "UNC Greensboro": {
      "adjDef": 109.5,
      "adjDefRank": 278,
      "adjEM": -9.7,
      "adjOff": 99.8,
      "adjOffRank": 259,
      "adjTempo": 65.7,
      "adjTempoRank": 109,
      "conference": "SC",
      "rank": 285,
      "record": "11-22"
    },
    "UNC Wilmington": {
      "adjDef": 102.4,
      "adjDefRank": 135,
      "adjEM": -0.9,
      "adjOff": 101.5,
      "adjOffRank": 230,
      "adjTempo": 67.5,
      "adjTempoRank": 37,
      "conference": "CAA",
      "rank": 174,
      "record": "18-14"
    },
    "UNLV": {
      "adjDef": 101.7,
      "adjDefRank": 122,
      "adjEM": 6.13,
      "adjOff": 107.9,
      "adjOffRank": 99,
      "adjTempo": 65.1,
      "adjTempoRank": 133,
      "conference": "MWC",
      "rank": 102,
      "record": "18-15"
    },
    "USC": {
      "adjDef": 100.1,
      "adjDefRank": 95,
      "adjEM": 1.46,
      "adjOff": 101.6,
      "adjOffRank": 229,
      "adjTempo": 68,
      "adjTempoRank": 25,
      "conference": "P12",
      "rank": 151,
      "record": "12-20"
    },
    "USC Upstate": {
      "adjDef": 104.7,
      "adjDefRank": 178,
      "adjEM": -1.25,
      "adjOff": 103.5,
      "adjOffRank": 186,
      "adjTempo": 63.3,
      "adjTempoRank": 236,
      "conference": "ASun",
      "rank": 181,
      "record": "24-12"
    },
    "UT Arlington": {
      "adjDef": 105.9,
      "adjDefRank": 197,
      "adjEM": -2.41,
      "adjOff": 103.4,
      "adjOffRank": 187,
      "adjTempo": 69.6,
      "adjTempoRank": 11,
      "conference": "SB",
      "rank": 197,
      "record": "16-15"
    },
    "UTEP": {
      "adjDef": 99.6,
      "adjDefRank": 89,
      "adjEM": 6.64,
      "adjOff": 106.2,
      "adjOffRank": 131,
      "adjTempo": 64.7,
      "adjTempoRank": 150,
      "conference": "CUSA",
      "rank": 97,
      "record": "22-11"
    },
    "UTSA": {
      "adjDef": 110,
      "adjDefRank": 291,
      "adjEM": -3.59,
      "adjOff": 106.4,
      "adjOffRank": 126,
      "adjTempo": 65.9,
      "adjTempoRank": 94,
      "conference": "CUSA",
      "rank": 209,
      "record": "14-16"
    },
    "Utah": {
      "adjDef": 89.2,
      "adjDefRank": 6,
      "adjEM": 27.24,
      "adjOff": 116.4,
      "adjOffRank": 17,
      "adjTempo": 60.5,
      "adjTempoRank": 328,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 8,
      "record": "26-9",
      "seed": 5
    },
    "Utah St.": {
      "adjDef": 103.2,
      "adjDefRank": 150,
      "adjEM": 4.27,
      "adjOff": 107.5,
      "adjOffRank": 109,
      "adjTempo": 63.5,
      "adjTempoRank": 224,
      "conference": "MWC",
      "rank": 124,
      "record": "18-13"
    },
    "Utah Valley": {
      "adjDef": 109,
      "adjDefRank": 266,
      "adjEM": -16.1,
      "adjOff": 92.9,
      "adjOffRank": 333,
      "adjTempo": 61.9,
      "adjTempoRank": 300,
      "conference": "WAC",
      "rank": 321,
      "record": "11-19"
    },
    "VCU": {
      "adjDef": 94.9,
      "adjDefRank": 26,
      "adjEM": 16.27,
      "adjOff": 111.1,
      "adjOffRank": 58,
      "adjTempo": 66.5,
      "adjTempoRank": 70,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 30,
      "record": "26-10",
      "seed": 7
    },
    "VMI": {
      "adjDef": 109.2,
      "adjDefRank": 272,
      "adjEM": -10.78,
      "adjOff": 98.4,
      "adjOffRank": 283,
      "adjTempo": 76.5,
      "adjTempoRank": 1,
      "conference": "SC",
      "rank": 294,
      "record": "11-19"
    },
    "Valparaiso": {
      "adjDef": 96.3,
      "adjDefRank": 45,
      "adjEM": 11.13,
      "adjOff": 107.4,
      "adjOffRank": 112,
      "adjTempo": 62.3,
      "adjTempoRank": 278,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 64,
      "record": "28-6",
      "seed": 13
    },
    "Vanderbilt": {
      "adjDef": 100.6,
      "adjDefRank": 103,
      "adjEM": 14.99,
      "adjOff": 115.6,
      "adjOffRank": 20,
      "adjTempo": 63.4,
      "adjTempoRank": 233,
      "conference": "SEC",
      "rank": 37,
      "record": "21-14"
    },
    "Vermont": {
      "adjDef": 99,
      "adjDefRank": 78,
      "adjEM": 4.38,
      "adjOff": 103.3,
      "adjOffRank": 191,
      "adjTempo": 64.4,
      "adjTempoRank": 170,
      "conference": "AE",
      "rank": 123,
      "record": "20-14"
    },
    "Villanova": {
      "adjDef": 92,
      "adjDefRank": 12,
      "adjEM": 30.65,
      "adjOff": 122.7,
      "adjOffRank": 4,
      "adjTempo": 64.2,
      "adjTempoRank": 183,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 5,
      "record": "33-3",
      "seed": 1
    },
    "Virginia": {
      "adjDef": 85.5,
      "adjDefRank": 2,
      "adjEM": 30.06,
      "adjOff": 115.6,
      "adjOffRank": 21,
      "adjTempo": 58,
      "adjTempoRank": 349,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 6,
      "record": "30-4",
      "seed": 2
    },
    "Virginia Tech": {
      "adjDef": 107.2,
      "adjDefRank": 226,
      "adjEM": -0.92,
      "adjOff": 106.3,
      "adjOffRank": 128,
      "adjTempo": 63.6,
      "adjTempoRank": 216,
      "conference": "ACC",
      "rank": 175,
      "record": "11-22"
    },
    "Wagner": {
      "adjDef": 111.4,
      "adjDefRank": 311,
      "adjEM": -12.49,
      "adjOff": 98.9,
      "adjOffRank": 274,
      "adjTempo": 67.2,
      "adjTempoRank": 50,
      "conference": "NEC",
      "rank": 304,
      "record": "10-20"
    },
    "Wake Forest": {
      "adjDef": 101.8,
      "adjDefRank": 125,
      "adjEM": 4.76,
      "adjOff": 106.6,
      "adjOffRank": 121,
      "adjTempo": 68,
      "adjTempoRank": 26,
      "conference": "ACC",
      "rank": 120,
      "record": "13-19"
    },
    "Washington": {
      "adjDef": 103.8,
      "adjDefRank": 161,
      "adjEM": 5.04,
      "adjOff": 108.8,
      "adjOffRank": 82,
      "adjTempo": 65.2,
      "adjTempoRank": 127,
      "conference": "P12",
      "rank": 119,
      "record": "16-15"
    },
    "Washington St.": {
      "adjDef": 110.8,
      "adjDefRank": 298,
      "adjEM": -1.67,
      "adjOff": 109.1,
      "adjOffRank": 77,
      "adjTempo": 67.2,
      "adjTempoRank": 49,
      "conference": "P12",
      "rank": 186,
      "record": "13-18"
    },
    "Weber St.": {
      "adjDef": 108.3,
      "adjDefRank": 252,
      "adjEM": -8.35,
      "adjOff": 99.9,
      "adjOffRank": 257,
      "adjTempo": 62.7,
      "adjTempoRank": 265,
      "conference": "BSky",
      "rank": 266,
      "record": "13-17"
    },
    "West Virginia": {
      "adjDef": 94.9,
      "adjDefRank": 29,
      "adjEM": 17.64,
      "adjOff": 112.6,
      "adjOffRank": 43,
      "adjTempo": 67.9,
      "adjTempoRank": 28,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 25,
      "record": "25-10",
      "seed": 5
    },
    "Western Carolina": {
      "adjDef": 107.5,
      "adjDefRank": 233,
      "adjEM": -5.6,
      "adjOff": 101.9,
      "adjOffRank": 223,
      "adjTempo": 65.7,
      "adjTempoRank": 106,
      "conference": "SC",
      "rank": 234,
      "record": "15-17"
    },
    "Western Illinois": {
      "adjDef": 108.1,
      "adjDefRank": 248,
      "adjEM": -16.66,
      "adjOff": 91.5,
      "adjOffRank": 338,
      "adjTempo": 66.6,
      "adjTempoRank": 66,
      "conference": "Sum",
      "rank": 323,
      "record": "8-20"
    },
    "Western Kentucky": {
      "adjDef": 102.7,
      "adjDefRank": 140,
      "adjEM": 2.28,
      "adjOff": 105,
      "adjOffRank": 161,
      "adjTempo": 66.3,
      "adjTempoRank": 75,
      "conference": "CUSA",
      "rank": 141,
      "record": "20-12"
    },
    "Western Michigan": {
      "adjDef": 107.1,
      "adjDefRank": 225,
      "adjEM": 0.86,
      "adjOff": 108,
      "adjOffRank": 96,
      "adjTempo": 65,
      "adjTempoRank": 136,
      "conference": "MAC",
      "rank": 156,
      "record": "20-14"
    },
    "Wichita St.": {
      "adjDef": 93.6,
      "adjDefRank": 15,
      "adjEM": 22.21,
      "adjOff": 115.8,
      "adjOffRank": 19,
      "adjTempo": 62.3,
      "adjTempoRank": 279,
      "conference": "MVC",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "30-5",
      "seed": 7
    },
    "William & Mary": {
      "adjDef": 108.8,
      "adjDefRank": 262,
      "adjEM": 5.17,
      "adjOff": 113.9,
      "adjOffRank": 31,
      "adjTempo": 63.9,
      "adjTempoRank": 198,
      "conference": "CAA",
      "rank": 116,
      "record": "20-13"
    },
    "Winthrop": {
      "adjDef": 105.7,
      "adjDefRank": 191,
      "adjEM": -0.94,
      "adjOff": 104.8,
      "adjOffRank": 166,
      "adjTempo": 65.8,
      "adjTempoRank": 103,
      "conference": "BSth",
      "rank": 176,
      "record": "19-13"
    },
    "Wisconsin": {
      "adjDef": 95.2,
      "adjDefRank": 35,
      "adjEM": 33.72,
      "adjOff": 129,
      "adjOffRank": 1,
      "adjTempo": 58.7,
      "adjTempoRank": 345,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 2,
      "record": "36-4",
      "seed": 1
    },
    "Wofford": {
      "adjDef": 98.5,
      "adjDefRank": 70,
      "adjEM": 6.72,
      "adjOff": 105.2,
      "adjOffRank": 158,
      "adjTempo": 60.5,
      "adjTempoRank": 331,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 96,
      "record": "28-7",
      "seed": 12
    },
    "Wright St.": {
      "adjDef": 105.5,
      "adjDefRank": 187,
      "adjEM": -6.53,
      "adjOff": 99,
      "adjOffRank": 271,
      "adjTempo": 63,
      "adjTempoRank": 252,
      "conference": "Horz",
      "rank": 243,
      "record": "11-20"
    },
    "Wyoming": {
      "adjDef": 99.1,
      "adjDefRank": 80,
      "adjEM": 5.67,
      "adjOff": 104.8,
      "adjOffRank": 164,
      "adjTempo": 59.1,
      "adjTempoRank": 343,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 109,
      "record": "25-10",
      "seed": 12
    },
    "Xavier": {
      "adjDef": 97.1,
      "adjDefRank": 56,
      "adjEM": 19.49,
      "adjOff": 116.6,
      "adjOffRank": 15,
      "adjTempo": 65,
      "adjTempoRank": 137,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 21,
      "record": "23-14",
      "seed": 6
    },
    "Yale": {
      "adjDef": 98.6,
      "adjDefRank": 71,
      "adjEM": 8.78,
      "adjOff": 107.3,
      "adjOffRank": 114,
      "adjTempo": 61.9,
      "adjTempoRank": 298,
      "conference": "Ivy",
      "rank": 77,
      "record": "22-10"
    },
    "Youngstown St.": {
      "adjDef": 114.9,
      "adjDefRank": 337,
      "adjEM": -8.93,
      "adjOff": 106,
      "adjOffRank": 135,
      "adjTempo": 67,
      "adjTempoRank": 55,
      "conference": "Horz",
      "rank": 273,
      "record": "11-21"
    }
  },
  "2016": {
    "Abilene Christian": {
      "adjDef": 112.3,
      "adjDefRank": 307,
      "adjEM": -15.24,
      "adjOff": 97.1,
      "adjOffRank": 308,
      "adjTempo": 67.8,
      "adjTempoRank": 186,
      "conference": "Slnd",
      "rank": 317,
      "record": "13-18"
    },
    "Air Force": {
      "adjDef": 106,
      "adjDefRank": 195,
      "adjEM": -6.92,
      "adjOff": 99.1,
      "adjOffRank": 283,
      "adjTempo": 67,
      "adjTempoRank": 239,
      "conference": "MWC",
      "rank": 242,
      "record": "14-18"
    },
    "Akron": {
      "adjDef": 101.5,
      "adjDefRank": 109,
      "adjEM": 8.63,
      "adjOff": 110.1,
      "adjOffRank": 90,
      "adjTempo": 67.5,
      "adjTempoRank": 210,
      "conference": "MAC",
      "rank": 88,
      "record": "26-9"
    },
    "Alabama": {
      "adjDef": 97.4,
      "adjDefRank": 48,
      "adjEM": 8.07,
      "adjOff": 105.5,
      "adjOffRank": 164,
      "adjTempo": 64.8,
      "adjTempoRank": 322,
      "conference": "SEC",
      "rank": 96,
      "record": "18-15"
    },
    "Alabama A&M": {
      "adjDef": 113.4,
      "adjDefRank": 323,
      "adjEM": -13.28,
      "adjOff": 100.1,
      "adjOffRank": 257,
      "adjTempo": 65.2,
      "adjTempoRank": 309,
      "conference": "SWAC",
      "rank": 301,
      "record": "11-18"
    },
    "Alabama St.": {
      "adjDef": 110.7,
      "adjDefRank": 284,
      "adjEM": -11.59,
      "adjOff": 99.1,
      "adjOffRank": 282,
      "adjTempo": 69,
      "adjTempoRank": 124,
      "conference": "SWAC",
      "rank": 286,
      "record": "14-17"
    },
    "Albany": {
      "adjDef": 102.7,
      "adjDefRank": 131,
      "adjEM": 4.25,
      "adjOff": 106.9,
      "adjOffRank": 141,
      "adjTempo": 66.2,
      "adjTempoRank": 264,
      "conference": "AE",
      "rank": 121,
      "record": "24-9"
    },
    "Alcorn St.": {
      "adjDef": 112.6,
      "adjDefRank": 309,
      "adjEM": -14.55,
      "adjOff": 98,
      "adjOffRank": 296,
      "adjTempo": 65.5,
      "adjTempoRank": 299,
      "conference": "SWAC",
      "rank": 313,
      "record": "15-15"
    },
    "American": {
      "adjDef": 107.4,
      "adjDefRank": 219,
      "adjEM": -15.34,
      "adjOff": 92.1,
      "adjOffRank": 336,
      "adjTempo": 61.3,
      "adjTempoRank": 349,
      "conference": "Pat",
      "rank": 319,
      "record": "12-19"
    },
    "Appalachian St.": {
      "adjDef": 110.9,
      "adjDefRank": 287,
      "adjEM": -8.17,
      "adjOff": 102.7,
      "adjOffRank": 206,
      "adjTempo": 70.3,
      "adjTempoRank": 69,
      "conference": "SB",
      "rank": 258,
      "record": "9-22"
    },
    "Arizona": {
      "adjDef": 95.8,
      "adjDefRank": 29,
      "adjEM": 20.97,
      "adjOff": 116.8,
      "adjOffRank": 20,
      "adjTempo": 68.3,
      "adjTempoRank": 154,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 19,
      "record": "25-9",
      "seed": 6
    },
    "Arizona St.": {
      "adjDef": 101.2,
      "adjDefRank": 106,
      "adjEM": 8.38,
      "adjOff": 109.6,
      "adjOffRank": 95,
      "adjTempo": 67.9,
      "adjTempoRank": 181,
      "conference": "P12",
      "rank": 93,
      "record": "15-17"
    },
    "Arkansas": {
      "adjDef": 100.3,
      "adjDefRank": 90,
      "adjEM": 10.45,
      "adjOff": 110.7,
      "adjOffRank": 78,
      "adjTempo": 69.7,
      "adjTempoRank": 94,
      "conference": "SEC",
      "rank": 73,
      "record": "16-16"
    },
    "Arkansas Little Rock": {
      "adjDef": 96,
      "adjDefRank": 33,
      "adjEM": 13.24,
      "adjOff": 109.2,
      "adjOffRank": 98,
      "adjTempo": 63.4,
      "adjTempoRank": 342,
      "conference": "SB",
      "finish": "Round of 32",
      "rank": 56,
      "record": "30-5",
      "seed": 12
    },
    "Arkansas Pine Bluff": {
      "adjDef": 109.7,
      "adjDefRank": 268,
      "adjEM": -20.69,
      "adjOff": 89,
      "adjOffRank": 345,
      "adjTempo": 64.1,
      "adjTempoRank": 332,
      "conference": "SWAC",
      "rank": 344,
      "record": "8-25"
    },
    "Arkansas St.": {
      "adjDef": 107.8,
      "adjDefRank": 229,
      "adjEM": -7.51,
      "adjOff": 100.3,
      "adjOffRank": 254,
      "adjTempo": 72,
      "adjTempoRank": 30,
      "conference": "SB",
      "rank": 251,
      "record": "11-20"
    },
    "Army": {
      "adjDef": 107,
      "adjDefRank": 213,
      "adjEM": -7.57,
      "adjOff": 99.5,
      "adjOffRank": 274,
      "adjTempo": 72.2,
      "adjTempoRank": 25,
      "conference": "Pat",
      "rank": 254,
      "record": "19-14"
    },
    "Auburn": {
      "adjDef": 104.2,
      "adjDefRank": 162,
      "adjEM": -2.56,
      "adjOff": 101.7,
      "adjOffRank": 222,
      "adjTempo": 71.8,
      "adjTempoRank": 35,
      "conference": "SEC",
      "rank": 189,
      "record": "11-20"
    },
    "Austin Peay": {
      "adjDef": 109.6,
      "adjDefRank": 266,
      "adjEM": -5.1,
      "adjOff": 104.5,
      "adjOffRank": 176,
      "adjTempo": 69.5,
      "adjTempoRank": 101,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 217,
      "record": "18-18",
      "seed": 16
    },
    "BYU": {
      "adjDef": 99.2,
      "adjDefRank": 68,
      "adjEM": 13.89,
      "adjOff": 113.1,
      "adjOffRank": 48,
      "adjTempo": 73.7,
      "adjTempoRank": 10,
      "conference": "WCC",
      "rank": 52,
      "record": "26-11"
    },
    "Ball St.": {
      "adjDef": 102.6,
      "adjDefRank": 130,
      "adjEM": 0.84,
      "adjOff": 103.5,
      "adjOffRank": 190,
      "adjTempo": 65.3,
      "adjTempoRank": 304,
      "conference": "MAC",
      "rank": 155,
      "record": "21-14"
    },
    "Baylor": {
      "adjDef": 99.8,
      "adjDefRank": 84,
      "adjEM": 19.06,
      "adjOff": 118.9,
      "adjOffRank": 14,
      "adjTempo": 66.6,
      "adjTempoRank": 252,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 24,
      "record": "22-12",
      "seed": 5
    },
    "Belmont": {
      "adjDef": 110.5,
      "adjDefRank": 282,
      "adjEM": 3.51,
      "adjOff": 114,
      "adjOffRank": 34,
      "adjTempo": 71.6,
      "adjTempoRank": 41,
      "conference": "OVC",
      "rank": 129,
      "record": "20-12"
    },
    "Bethune Cookman": {
      "adjDef": 111.9,
      "adjDefRank": 303,
      "adjEM": -13.96,
      "adjOff": 97.9,
      "adjOffRank": 299,
      "adjTempo": 66.5,
      "adjTempoRank": 255,
      "conference": "MEAC",
      "rank": 306,
      "record": "14-18"
    },
    "Binghamton": {
      "adjDef": 104.4,
      "adjDefRank": 164,
      "adjEM": -16.16,
      "adjOff": 88.2,
      "adjOffRank": 348,
      "adjTempo": 65.2,
      "adjTempoRank": 308,
      "conference": "AE",
      "rank": 325,
      "record": "8-22"
    },
    "Boise St.": {
      "adjDef": 100.9,
      "adjDefRank": 101,
      "adjEM": 8.61,
      "adjOff": 109.5,
      "adjOffRank": 96,
      "adjTempo": 69.2,
      "adjTempoRank": 112,
      "conference": "MWC",
      "rank": 90,
      "record": "20-12"
    },
    "Boston College": {
      "adjDef": 100.8,
      "adjDefRank": 100,
      "adjEM": -5.56,
      "adjOff": 95.3,
      "adjOffRank": 320,
      "adjTempo": 66.7,
      "adjTempoRank": 246,
      "conference": "ACC",
      "rank": 225,
      "record": "7-25"
    },
    "Boston University": {
      "adjDef": 108.7,
      "adjDefRank": 251,
      "adjEM": -5.88,
      "adjOff": 102.8,
      "adjOffRank": 205,
      "adjTempo": 69.7,
      "adjTempoRank": 90,
      "conference": "Pat",
      "rank": 231,
      "record": "19-15"
    },
    "Bowling Green": {
      "adjDef": 106.7,
      "adjDefRank": 205,
      "adjEM": -5.78,
      "adjOff": 100.9,
      "adjOffRank": 239,
      "adjTempo": 68.3,
      "adjTempoRank": 156,
      "conference": "MAC",
      "rank": 230,
      "record": "16-18"
    },
    "Bradley": {
      "adjDef": 102.6,
      "adjDefRank": 129,
      "adjEM": -16.03,
      "adjOff": 86.6,
      "adjOffRank": 350,
      "adjTempo": 67,
      "adjTempoRank": 236,
      "conference": "MVC",
      "rank": 323,
      "record": "5-27"
    },
    "Brown": {
      "adjDef": 111.6,
      "adjDefRank": 300,
      "adjEM": -9.26,
      "adjOff": 102.4,
      "adjOffRank": 211,
      "adjTempo": 70.4,
      "adjTempoRank": 67,
      "conference": "Ivy",
      "rank": 265,
      "record": "8-20"
    },
    "Bryant": {
      "adjDef": 114.9,
      "adjDefRank": 333,
      "adjEM": -21.7,
      "adjOff": 93.2,
      "adjOffRank": 332,
      "adjTempo": 67.4,
      "adjTempoRank": 215,
      "conference": "NEC",
      "rank": 346,
      "record": "8-23"
    },
    "Bucknell": {
      "adjDef": 106.5,
      "adjDefRank": 202,
      "adjEM": -0.89,
      "adjOff": 105.6,
      "adjOffRank": 163,
      "adjTempo": 71.2,
      "adjTempoRank": 51,
      "conference": "Pat",
      "rank": 172,
      "record": "17-14"
    },
    "Buffalo": {
      "adjDef": 102.9,
      "adjDefRank": 134,
      "adjEM": 2.95,
      "adjOff": 105.9,
      "adjOffRank": 158,
      "adjTempo": 72.1,
      "adjTempoRank": 27,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 138,
      "record": "20-15",
      "seed": 14
    },
    "Butler": {
      "adjDef": 100.7,
      "adjDefRank": 97,
      "adjEM": 17.27,
      "adjOff": 118,
      "adjOffRank": 15,
      "adjTempo": 67.9,
      "adjTempoRank": 178,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 30,
      "record": "22-11",
      "seed": 9
    },
    "Cal Poly": {
      "adjDef": 111.3,
      "adjDefRank": 294,
      "adjEM": -3.65,
      "adjOff": 107.6,
      "adjOffRank": 122,
      "adjTempo": 66.2,
      "adjTempoRank": 262,
      "conference": "BW",
      "rank": 203,
      "record": "10-20"
    },
    "Cal St. Bakersfield": {
      "adjDef": 97.8,
      "adjDefRank": 50,
      "adjEM": 6.26,
      "adjOff": 104,
      "adjOffRank": 181,
      "adjTempo": 67.6,
      "adjTempoRank": 201,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 110,
      "record": "24-9",
      "seed": 15
    },
    "Cal St. Fullerton": {
      "adjDef": 110.5,
      "adjDefRank": 281,
      "adjEM": -8.82,
      "adjOff": 101.7,
      "adjOffRank": 223,
      "adjTempo": 68.6,
      "adjTempoRank": 137,
      "conference": "BW",
      "rank": 264,
      "record": "10-20"
    },
    "Cal St. Northridge": {
      "adjDef": 107.4,
      "adjDefRank": 218,
      "adjEM": -6.52,
      "adjOff": 100.9,
      "adjOffRank": 238,
      "adjTempo": 70.4,
      "adjTempoRank": 66,
      "conference": "BW",
      "rank": 239,
      "record": "10-20"
    },
    "California": {
      "adjDef": 94.2,
      "adjDefRank": 19,
      "adjEM": 18.32,
      "adjOff": 112.5,
      "adjOffRank": 53,
      "adjTempo": 68.1,
      "adjTempoRank": 162,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 28,
      "record": "23-11",
      "seed": 4
    },
    "Campbell": {
      "adjDef": 113,
      "adjDefRank": 317,
      "adjEM": -12.82,
      "adjOff": 100.2,
      "adjOffRank": 255,
      "adjTempo": 68.1,
      "adjTempoRank": 168,
      "conference": "BSth",
      "rank": 297,
      "record": "12-18"
    },
    "Canisius": {
      "adjDef": 114.4,
      "adjDefRank": 329,
      "adjEM": -6.29,
      "adjOff": 108.1,
      "adjOffRank": 115,
      "adjTempo": 67.5,
      "adjTempoRank": 206,
      "conference": "MAAC",
      "rank": 237,
      "record": "14-19"
    },
    "Central Arkansas": {
      "adjDef": 116.9,
      "adjDefRank": 344,
      "adjEM": -15.98,
      "adjOff": 100.9,
      "adjOffRank": 237,
      "adjTempo": 71.8,
      "adjTempoRank": 36,
      "conference": "Slnd",
      "rank": 321,
      "record": "7-21"
    },
    "Central Connecticut": {
      "adjDef": 119.3,
      "adjDefRank": 351,
      "adjEM": -26.79,
      "adjOff": 92.5,
      "adjOffRank": 333,
      "adjTempo": 67,
      "adjTempoRank": 235,
      "conference": "NEC",
      "rank": 351,
      "record": "4-25"
    },
    "Central Michigan": {
      "adjDef": 110,
      "adjDefRank": 271,
      "adjEM": 0.51,
      "adjOff": 110.5,
      "adjOffRank": 83,
      "adjTempo": 66.7,
      "adjTempoRank": 249,
      "conference": "MAC",
      "rank": 158,
      "record": "17-16"
    },
    "Charleston Southern": {
      "adjDef": 112.1,
      "adjDefRank": 306,
      "adjEM": -12.94,
      "adjOff": 99.2,
      "adjOffRank": 280,
      "adjTempo": 68.2,
      "adjTempoRank": 161,
      "conference": "BSth",
      "rank": 298,
      "record": "9-21"
    },
    "Charlotte": {
      "adjDef": 108.7,
      "adjDefRank": 250,
      "adjEM": -2.84,
      "adjOff": 105.8,
      "adjOffRank": 159,
      "adjTempo": 70.3,
      "adjTempoRank": 74,
      "conference": "CUSA",
      "rank": 193,
      "record": "14-19"
    },
    "Chattanooga": {
      "adjDef": 101,
      "adjDefRank": 104,
      "adjEM": 6.5,
      "adjOff": 107.5,
      "adjOffRank": 126,
      "adjTempo": 66.3,
      "adjTempoRank": 260,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 109,
      "record": "29-6",
      "seed": 12
    },
    "Chicago St.": {
      "adjDef": 114,
      "adjDefRank": 327,
      "adjEM": -22.21,
      "adjOff": 91.8,
      "adjOffRank": 338,
      "adjTempo": 69,
      "adjTempoRank": 118,
      "conference": "WAC",
      "rank": 347,
      "record": "4-28"
    },
    "Cincinnati": {
      "adjDef": 94.1,
      "adjDefRank": 16,
      "adjEM": 17.06,
      "adjOff": 111.2,
      "adjOffRank": 70,
      "adjTempo": 64,
      "adjTempoRank": 333,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 32,
      "record": "22-11",
      "seed": 9
    },
    "Clemson": {
      "adjDef": 98.9,
      "adjDefRank": 62,
      "adjEM": 14.38,
      "adjOff": 113.3,
      "adjOffRank": 45,
      "adjTempo": 63.7,
      "adjTempoRank": 337,
      "conference": "ACC",
      "rank": 45,
      "record": "17-14"
    },
    "Cleveland St.": {
      "adjDef": 103.5,
      "adjDefRank": 147,
      "adjEM": -11.89,
      "adjOff": 91.6,
      "adjOffRank": 340,
      "adjTempo": 63.8,
      "adjTempoRank": 336,
      "conference": "Horz",
      "rank": 290,
      "record": "9-23"
    },
    "Coastal Carolina": {
      "adjDef": 102.8,
      "adjDefRank": 132,
      "adjEM": -1.09,
      "adjOff": 101.7,
      "adjOffRank": 221,
      "adjTempo": 66.7,
      "adjTempoRank": 247,
      "conference": "BSth",
      "rank": 176,
      "record": "21-12"
    },
    "Colgate": {
      "adjDef": 111.7,
      "adjDefRank": 301,
      "adjEM": -7.71,
      "adjOff": 104,
      "adjOffRank": 183,
      "adjTempo": 64.9,
      "adjTempoRank": 318,
      "conference": "Pat",
      "rank": 256,
      "record": "13-17"
    },
    "College of Charleston": {
      "adjDef": 94.4,
      "adjDefRank": 20,
      "adjEM": 4.16,
      "adjOff": 98.6,
      "adjOffRank": 288,
      "adjTempo": 63.8,
      "adjTempoRank": 335,
      "conference": "CAA",
      "rank": 122,
      "record": "17-14"
    },
    "Colorado": {
      "adjDef": 95.9,
      "adjDefRank": 31,
      "adjEM": 12.91,
      "adjOff": 108.8,
      "adjOffRank": 100,
      "adjTempo": 69,
      "adjTempoRank": 120,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 57,
      "record": "22-12",
      "seed": 8
    },
    "Colorado St.": {
      "adjDef": 110.2,
      "adjDefRank": 274,
      "adjEM": 2.17,
      "adjOff": 112.3,
      "adjOffRank": 55,
      "adjTempo": 68.1,
      "adjTempoRank": 165,
      "conference": "MWC",
      "rank": 144,
      "record": "18-16"
    },
    "Columbia": {
      "adjDef": 106.7,
      "adjDefRank": 209,
      "adjEM": 5.67,
      "adjOff": 112.4,
      "adjOffRank": 54,
      "adjTempo": 65.1,
      "adjTempoRank": 313,
      "conference": "Ivy",
      "rank": 113,
      "record": "25-10"
    },
    "Connecticut": {
      "adjDef": 93.2,
      "adjDefRank": 12,
      "adjEM": 18.71,
      "adjOff": 111.9,
      "adjOffRank": 59,
      "adjTempo": 65.9,
      "adjTempoRank": 277,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 26,
      "record": "25-11",
      "seed": 9
    },
    "Coppin St.": {
      "adjDef": 113.1,
      "adjDefRank": 318,
      "adjEM": -17.19,
      "adjOff": 95.9,
      "adjOffRank": 315,
      "adjTempo": 69.9,
      "adjTempoRank": 87,
      "conference": "MEAC",
      "rank": 333,
      "record": "9-22"
    },
    "Cornell": {
      "adjDef": 108.5,
      "adjDefRank": 246,
      "adjEM": -10.34,
      "adjOff": 98.2,
      "adjOffRank": 295,
      "adjTempo": 72.7,
      "adjTempoRank": 19,
      "conference": "Ivy",
      "rank": 276,
      "record": "10-18"
    },
    "Creighton": {
      "adjDef": 97.6,
      "adjDefRank": 49,
      "adjEM": 15.82,
      "adjOff": 113.4,
      "adjOffRank": 43,
      "adjTempo": 70.2,
      "adjTempoRank": 76,
      "conference": "BE",
      "rank": 40,
      "record": "20-15"
    },
    "Dartmouth": {
      "adjDef": 105.5,
      "adjDefRank": 186,
      "adjEM": -5.99,
      "adjOff": 99.5,
      "adjOffRank": 273,
      "adjTempo": 67.5,
      "adjTempoRank": 208,
      "conference": "Ivy",
      "rank": 234,
      "record": "10-18"
    },
    "Davidson": {
      "adjDef": 107.6,
      "adjDefRank": 223,
      "adjEM": 6.73,
      "adjOff": 114.3,
      "adjOffRank": 31,
      "adjTempo": 70.2,
      "adjTempoRank": 77,
      "conference": "A10",
      "rank": 106,
      "record": "20-13"
    },
    "Dayton": {
      "adjDef": 94,
      "adjDefRank": 15,
      "adjEM": 12.63,
      "adjOff": 106.6,
      "adjOffRank": 146,
      "adjTempo": 67.8,
      "adjTempoRank": 183,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 59,
      "record": "25-8",
      "seed": 7
    },
    "DePaul": {
      "adjDef": 106.2,
      "adjDefRank": 197,
      "adjEM": -1.69,
      "adjOff": 104.5,
      "adjOffRank": 175,
      "adjTempo": 66.9,
      "adjTempoRank": 241,
      "conference": "BE",
      "rank": 183,
      "record": "9-22"
    },
    "Delaware": {
      "adjDef": 111.7,
      "adjDefRank": 302,
      "adjEM": -10.22,
      "adjOff": 101.5,
      "adjOffRank": 229,
      "adjTempo": 67.4,
      "adjTempoRank": 211,
      "conference": "CAA",
      "rank": 275,
      "record": "7-23"
    },
    "Delaware St.": {
      "adjDef": 113.3,
      "adjDefRank": 321,
      "adjEM": -24.71,
      "adjOff": 88.5,
      "adjOffRank": 346,
      "adjTempo": 66.2,
      "adjTempoRank": 266,
      "conference": "MEAC",
      "rank": 350,
      "record": "7-25"
    },
    "Denver": {
      "adjDef": 105.9,
      "adjDefRank": 189,
      "adjEM": -2.92,
      "adjOff": 102.9,
      "adjOffRank": 203,
      "adjTempo": 60.8,
      "adjTempoRank": 351,
      "conference": "Sum",
      "rank": 195,
      "record": "16-15"
    },
    "Detroit": {
      "adjDef": 111.5,
      "adjDefRank": 296,
      "adjEM": -0.9,
      "adjOff": 110.6,
      "adjOffRank": 82,
      "adjTempo": 73.7,
      "adjTempoRank": 11,
      "conference": "Horz",
      "rank": 173,
      "record": "16-15"
    },
    "Drake": {
      "adjDef": 112.7,
      "adjDefRank": 311,
      "adjEM": -8.6,
      "adjOff": 104.1,
      "adjOffRank": 179,
      "adjTempo": 65.4,
      "adjTempoRank": 300,
      "conference": "MVC",
      "rank": 262,
      "record": "7-24"
    },
    "Drexel": {
      "adjDef": 106.7,
      "adjDefRank": 206,
      "adjEM": -7.16,
      "adjOff": 99.5,
      "adjOffRank": 272,
      "adjTempo": 64.5,
      "adjTempoRank": 327,
      "conference": "CAA",
      "rank": 245,
      "record": "6-25"
    },
    "Duke": {
      "adjDef": 100,
      "adjDefRank": 86,
      "adjEM": 21.87,
      "adjOff": 121.9,
      "adjOffRank": 4,
      "adjTempo": 67.7,
      "adjTempoRank": 194,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 17,
      "record": "25-11",
      "seed": 4
    },
    "Duquesne": {
      "adjDef": 106.9,
      "adjDefRank": 212,
      "adjEM": 0.72,
      "adjOff": 107.6,
      "adjOffRank": 123,
      "adjTempo": 72.9,
      "adjTempoRank": 16,
      "conference": "A10",
      "rank": 156,
      "record": "17-17"
    },
    "East Carolina": {
      "adjDef": 105.9,
      "adjDefRank": 191,
      "adjEM": -2.7,
      "adjOff": 103.2,
      "adjOffRank": 197,
      "adjTempo": 67.2,
      "adjTempoRank": 225,
      "conference": "Amer",
      "rank": 190,
      "record": "12-20"
    },
    "East Tennessee St.": {
      "adjDef": 106.8,
      "adjDefRank": 211,
      "adjEM": 0.88,
      "adjOff": 107.7,
      "adjOffRank": 120,
      "adjTempo": 69.7,
      "adjTempoRank": 91,
      "conference": "SC",
      "rank": 153,
      "record": "24-12"
    },
    "Eastern Illinois": {
      "adjDef": 109.8,
      "adjDefRank": 270,
      "adjEM": -9.34,
      "adjOff": 100.5,
      "adjOffRank": 249,
      "adjTempo": 67.2,
      "adjTempoRank": 227,
      "conference": "OVC",
      "rank": 266,
      "record": "13-17"
    },
    "Eastern Kentucky": {
      "adjDef": 111,
      "adjDefRank": 290,
      "adjEM": -3.58,
      "adjOff": 107.4,
      "adjOffRank": 128,
      "adjTempo": 72.1,
      "adjTempoRank": 28,
      "conference": "OVC",
      "rank": 201,
      "record": "15-16"
    },
    "Eastern Michigan": {
      "adjDef": 105.4,
      "adjDefRank": 185,
      "adjEM": 1.27,
      "adjOff": 106.7,
      "adjOffRank": 144,
      "adjTempo": 70.3,
      "adjTempoRank": 70,
      "conference": "MAC",
      "rank": 150,
      "record": "18-15"
    },
    "Eastern Washington": {
      "adjDef": 115.1,
      "adjDefRank": 336,
      "adjEM": -3.26,
      "adjOff": 111.8,
      "adjOffRank": 62,
      "adjTempo": 68.7,
      "adjTempoRank": 133,
      "conference": "BSky",
      "rank": 197,
      "record": "18-16"
    },
    "Elon": {
      "adjDef": 106.4,
      "adjDefRank": 200,
      "adjEM": 0.2,
      "adjOff": 106.6,
      "adjOffRank": 147,
      "adjTempo": 71.1,
      "adjTempoRank": 53,
      "conference": "CAA",
      "rank": 160,
      "record": "16-16"
    },
    "Evansville": {
      "adjDef": 97.8,
      "adjDefRank": 51,
      "adjEM": 10.56,
      "adjOff": 108.3,
      "adjOffRank": 110,
      "adjTempo": 68.6,
      "adjTempoRank": 140,
      "conference": "MVC",
      "rank": 72,
      "record": "25-9"
    },
    "FIU": {
      "adjDef": 108.8,
      "adjDefRank": 252,
      "adjEM": -5.67,
      "adjOff": 103.2,
      "adjOffRank": 199,
      "adjTempo": 65.3,
      "adjTempoRank": 307,
      "conference": "CUSA",
      "rank": 228,
      "record": "13-19"
    },
    "Fairfield": {
      "adjDef": 107.9,
      "adjDefRank": 232,
      "adjEM": -4.06,
      "adjOff": 103.8,
      "adjOffRank": 184,
      "adjTempo": 71.4,
      "adjTempoRank": 46,
      "conference": "MAAC",
      "rank": 208,
      "record": "19-14"
    },
    "Fairleigh Dickinson": {
      "adjDef": 115,
      "adjDefRank": 334,
      "adjEM": -10.63,
      "adjOff": 104.4,
      "adjOffRank": 177,
      "adjTempo": 71.5,
      "adjTempoRank": 43,
      "conference": "NEC",
      "finish": "First Four",
      "rank": 280,
      "record": "18-15",
      "seed": 16
    },
    "Florida": {
      "adjDef": 93.9,
      "adjDefRank": 14,
      "adjEM": 16.64,
      "adjOff": 110.5,
      "adjOffRank": 85,
      "adjTempo": 69.2,
      "adjTempoRank": 110,
      "conference": "SEC",
      "rank": 35,
      "record": "21-15"
    },
    "Florida A&M": {
      "adjDef": 108.3,
      "adjDefRank": 239,
      "adjEM": -24.58,
      "adjOff": 83.7,
      "adjOffRank": 351,
      "adjTempo": 69.4,
      "adjTempoRank": 106,
      "conference": "MEAC",
      "rank": 349,
      "record": "8-21"
    },
    "Florida Atlantic": {
      "adjDef": 108.1,
      "adjDefRank": 235,
      "adjEM": -12.36,
      "adjOff": 95.7,
      "adjOffRank": 317,
      "adjTempo": 67.5,
      "adjTempoRank": 204,
      "conference": "CUSA",
      "rank": 293,
      "record": "8-25"
    },
    "Florida Gulf Coast": {
      "adjDef": 103.7,
      "adjDefRank": 148,
      "adjEM": -0.08,
      "adjOff": 103.6,
      "adjOffRank": 189,
      "adjTempo": 67,
      "adjTempoRank": 240,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 162,
      "record": "21-14",
      "seed": 16
    },
    "Florida St.": {
      "adjDef": 98.9,
      "adjDefRank": 61,
      "adjEM": 14.26,
      "adjOff": 113.1,
      "adjOffRank": 47,
      "adjTempo": 71.6,
      "adjTempoRank": 42,
      "conference": "ACC",
      "rank": 48,
      "record": "20-14"
    },
    "Fordham": {
      "adjDef": 102.4,
      "adjDefRank": 125,
      "adjEM": -0.06,
      "adjOff": 102.3,
      "adjOffRank": 212,
      "adjTempo": 66.4,
      "adjTempoRank": 257,
      "conference": "A10",
      "rank": 161,
      "record": "17-14"
    },
    "Fresno St.": {
      "adjDef": 100.8,
      "adjDefRank": 99,
      "adjEM": 6.63,
      "adjOff": 107.4,
      "adjOffRank": 127,
      "adjTempo": 69,
      "adjTempoRank": 121,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 108,
      "record": "25-10",
      "seed": 14
    },
    "Furman": {
      "adjDef": 106,
      "adjDefRank": 194,
      "adjEM": -1.01,
      "adjOff": 105,
      "adjOffRank": 168,
      "adjTempo": 65,
      "adjTempoRank": 315,
      "conference": "SC",
      "rank": 175,
      "record": "19-16"
    },
    "Gardner Webb": {
      "adjDef": 106.8,
      "adjDefRank": 210,
      "adjEM": -6.02,
      "adjOff": 100.7,
      "adjOffRank": 243,
      "adjTempo": 69,
      "adjTempoRank": 119,
      "conference": "BSth",
      "rank": 236,
      "record": "17-16"
    },
    "George Mason": {
      "adjDef": 104.8,
      "adjDefRank": 178,
      "adjEM": -3.32,
      "adjOff": 101.5,
      "adjOffRank": 232,
      "adjTempo": 67.8,
      "adjTempoRank": 191,
      "conference": "A10",
      "rank": 198,
      "record": "11-21"
    },
    "George Washington": {
      "adjDef": 101,
      "adjDefRank": 103,
      "adjEM": 13.86,
      "adjOff": 114.8,
      "adjOffRank": 29,
      "adjTempo": 66.2,
      "adjTempoRank": 265,
      "conference": "A10",
      "rank": 53,
      "record": "28-10"
    },
    "Georgetown": {
      "adjDef": 99.7,
      "adjDefRank": 81,
      "adjEM": 11.71,
      "adjOff": 111.4,
      "adjOffRank": 65,
      "adjTempo": 67.9,
      "adjTempoRank": 180,
      "conference": "BE",
      "rank": 62,
      "record": "15-18"
    },
    "Georgia": {
      "adjDef": 97.3,
      "adjDefRank": 46,
      "adjEM": 10.59,
      "adjOff": 107.9,
      "adjOffRank": 118,
      "adjTempo": 67.3,
      "adjTempoRank": 216,
      "conference": "SEC",
      "rank": 71,
      "record": "20-14"
    },
    "Georgia Southern": {
      "adjDef": 109.3,
      "adjDefRank": 264,
      "adjEM": -4.31,
      "adjOff": 105,
      "adjOffRank": 167,
      "adjTempo": 68.7,
      "adjTempoRank": 136,
      "conference": "SB",
      "rank": 210,
      "record": "14-17"
    },
    "Georgia St.": {
      "adjDef": 101.2,
      "adjDefRank": 107,
      "adjEM": -1.39,
      "adjOff": 99.9,
      "adjOffRank": 264,
      "adjTempo": 64.9,
      "adjTempoRank": 320,
      "conference": "SB",
      "rank": 180,
      "record": "16-14"
    },
    "Georgia Tech": {
      "adjDef": 99.6,
      "adjDefRank": 78,
      "adjEM": 14.13,
      "adjOff": 113.8,
      "adjOffRank": 38,
      "adjTempo": 67.1,
      "adjTempoRank": 232,
      "conference": "ACC",
      "rank": 51,
      "record": "21-15"
    },
    "Gonzaga": {
      "adjDef": 95.5,
      "adjDefRank": 27,
      "adjEM": 20.82,
      "adjOff": 116.3,
      "adjOffRank": 22,
      "adjTempo": 67.3,
      "adjTempoRank": 218,
      "conference": "WCC",
      "finish": "Sweet 16",
      "rank": 21,
      "record": "28-8",
      "seed": 11
    },
    "Grambling St.": {
      "adjDef": 109.7,
      "adjDefRank": 269,
      "adjEM": -21.29,
      "adjOff": 88.4,
      "adjOffRank": 347,
      "adjTempo": 65.8,
      "adjTempoRank": 291,
      "conference": "SWAC",
      "rank": 345,
      "record": "6-24"
    },
    "Grand Canyon": {
      "adjDef": 101.5,
      "adjDefRank": 111,
      "adjEM": 3.49,
      "adjOff": 105,
      "adjOffRank": 166,
      "adjTempo": 68.8,
      "adjTempoRank": 129,
      "conference": "WAC",
      "rank": 130,
      "record": "27-7"
    },
    "Green Bay": {
      "adjDef": 104.6,
      "adjDefRank": 172,
      "adjEM": 3.74,
      "adjOff": 108.3,
      "adjOffRank": 111,
      "adjTempo": 76.2,
      "adjTempoRank": 6,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 127,
      "record": "23-13",
      "seed": 14
    },
    "Hampton": {
      "adjDef": 107.2,
      "adjDefRank": 216,
      "adjEM": -7.4,
      "adjOff": 99.8,
      "adjOffRank": 266,
      "adjTempo": 71.2,
      "adjTempoRank": 50,
      "conference": "MEAC",
      "finish": "Round of 64",
      "rank": 248,
      "record": "21-11",
      "seed": 16
    },
    "Hartford": {
      "adjDef": 115.8,
      "adjDefRank": 338,
      "adjEM": -16.06,
      "adjOff": 99.7,
      "adjOffRank": 267,
      "adjTempo": 69.2,
      "adjTempoRank": 114,
      "conference": "AE",
      "rank": 324,
      "record": "10-23"
    },
    "Harvard": {
      "adjDef": 101,
      "adjDefRank": 105,
      "adjEM": -0.69,
      "adjOff": 100.3,
      "adjOffRank": 253,
      "adjTempo": 65.9,
      "adjTempoRank": 278,
      "conference": "Ivy",
      "rank": 171,
      "record": "14-16"
    },
    "Hawaii": {
      "adjDef": 96.1,
      "adjDefRank": 35,
      "adjEM": 12.16,
      "adjOff": 108.2,
      "adjOffRank": 112,
      "adjTempo": 70.6,
      "adjTempoRank": 58,
      "conference": "BW",
      "finish": "Round of 32",
      "rank": 60,
      "record": "28-6",
      "seed": 13
    },
    "High Point": {
      "adjDef": 109.2,
      "adjDefRank": 262,
      "adjEM": 1.54,
      "adjOff": 110.8,
      "adjOffRank": 76,
      "adjTempo": 66.1,
      "adjTempoRank": 269,
      "conference": "BSth",
      "rank": 149,
      "record": "21-11"
    },
    "Hofstra": {
      "adjDef": 103.2,
      "adjDefRank": 142,
      "adjEM": 8.43,
      "adjOff": 111.6,
      "adjOffRank": 64,
      "adjTempo": 69.5,
      "adjTempoRank": 103,
      "conference": "CAA",
      "rank": 92,
      "record": "24-10"
    },
    "Holy Cross": {
      "adjDef": 108.5,
      "adjDefRank": 241,
      "adjEM": -11.39,
      "adjOff": 97.1,
      "adjOffRank": 307,
      "adjTempo": 63.6,
      "adjTempoRank": 339,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 285,
      "record": "15-20",
      "seed": 16
    },
    "Houston": {
      "adjDef": 104.9,
      "adjDefRank": 179,
      "adjEM": 11.81,
      "adjOff": 116.7,
      "adjOffRank": 21,
      "adjTempo": 66.3,
      "adjTempoRank": 259,
      "conference": "Amer",
      "rank": 61,
      "record": "22-10"
    },
    "Houston Baptist": {
      "adjDef": 112,
      "adjDefRank": 305,
      "adjEM": -10.39,
      "adjOff": 101.6,
      "adjOffRank": 225,
      "adjTempo": 70.3,
      "adjTempoRank": 71,
      "conference": "Slnd",
      "rank": 277,
      "record": "17-17"
    },
    "Howard": {
      "adjDef": 110.1,
      "adjDefRank": 273,
      "adjEM": -16.61,
      "adjOff": 93.5,
      "adjOffRank": 331,
      "adjTempo": 70.4,
      "adjTempoRank": 64,
      "conference": "MEAC",
      "rank": 329,
      "record": "12-20"
    },
    "IPFW": {
      "adjDef": 108.6,
      "adjDefRank": 248,
      "adjEM": 3.17,
      "adjOff": 111.7,
      "adjOffRank": 63,
      "adjTempo": 69.5,
      "adjTempoRank": 105,
      "conference": "Sum",
      "rank": 135,
      "record": "24-10"
    },
    "IUPUI": {
      "adjDef": 103.9,
      "adjDefRank": 156,
      "adjEM": -4.05,
      "adjOff": 99.9,
      "adjOffRank": 262,
      "adjTempo": 68.4,
      "adjTempoRank": 148,
      "conference": "Sum",
      "rank": 207,
      "record": "13-19"
    },
    "Idaho": {
      "adjDef": 103.5,
      "adjDefRank": 146,
      "adjEM": -4.87,
      "adjOff": 98.6,
      "adjOffRank": 287,
      "adjTempo": 65.8,
      "adjTempoRank": 292,
      "conference": "BSky",
      "rank": 214,
      "record": "21-13"
    },
    "Idaho St.": {
      "adjDef": 109,
      "adjDefRank": 257,
      "adjEM": -9.86,
      "adjOff": 99.1,
      "adjOffRank": 281,
      "adjTempo": 70.1,
      "adjTempoRank": 85,
      "conference": "BSky",
      "rank": 270,
      "record": "16-15"
    },
    "Illinois": {
      "adjDef": 103,
      "adjDefRank": 136,
      "adjEM": 3.91,
      "adjOff": 106.9,
      "adjOffRank": 142,
      "adjTempo": 68.1,
      "adjTempoRank": 169,
      "conference": "B10",
      "rank": 125,
      "record": "15-19"
    },
    "Illinois Chicago": {
      "adjDef": 107.7,
      "adjDefRank": 227,
      "adjEM": -17.83,
      "adjOff": 89.9,
      "adjOffRank": 343,
      "adjTempo": 71.6,
      "adjTempoRank": 40,
      "conference": "Horz",
      "rank": 335,
      "record": "5-25"
    },
    "Illinois St.": {
      "adjDef": 99.4,
      "adjDefRank": 73,
      "adjEM": 4.63,
      "adjOff": 104,
      "adjOffRank": 180,
      "adjTempo": 67.2,
      "adjTempoRank": 226,
      "conference": "MVC",
      "rank": 120,
      "record": "18-14"
    },
    "Incarnate Word": {
      "adjDef": 109.1,
      "adjDefRank": 259,
      "adjEM": -7.06,
      "adjOff": 102.1,
      "adjOffRank": 216,
      "adjTempo": 67.2,
      "adjTempoRank": 224,
      "conference": "Slnd",
      "rank": 243,
      "record": "17-12"
    },
    "Indiana": {
      "adjDef": 98.7,
      "adjDefRank": 59,
      "adjEM": 23.05,
      "adjOff": 121.7,
      "adjOffRank": 6,
      "adjTempo": 68.2,
      "adjTempoRank": 159,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 11,
      "record": "27-8",
      "seed": 5
    },
    "Indiana St.": {
      "adjDef": 99.8,
      "adjDefRank": 83,
      "adjEM": -0.37,
      "adjOff": 99.4,
      "adjOffRank": 276,
      "adjTempo": 70.2,
      "adjTempoRank": 79,
      "conference": "MVC",
      "rank": 168,
      "record": "15-17"
    },
    "Iona": {
      "adjDef": 103.4,
      "adjDefRank": 145,
      "adjEM": 7.13,
      "adjOff": 110.5,
      "adjOffRank": 84,
      "adjTempo": 71.9,
      "adjTempoRank": 33,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 104,
      "record": "22-11",
      "seed": 13
    },
    "Iowa": {
      "adjDef": 95.9,
      "adjDefRank": 30,
      "adjEM": 20.24,
      "adjOff": 116.1,
      "adjOffRank": 25,
      "adjTempo": 68.8,
      "adjTempoRank": 131,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 23,
      "record": "22-11",
      "seed": 7
    },
    "Iowa St.": {
      "adjDef": 100.3,
      "adjDefRank": 91,
      "adjEM": 20.95,
      "adjOff": 121.2,
      "adjOffRank": 7,
      "adjTempo": 71.2,
      "adjTempoRank": 49,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "23-12",
      "seed": 4
    },
    "Jackson St.": {
      "adjDef": 102.3,
      "adjDefRank": 123,
      "adjEM": -6.53,
      "adjOff": 95.7,
      "adjOffRank": 318,
      "adjTempo": 67.3,
      "adjTempoRank": 219,
      "conference": "SWAC",
      "rank": 240,
      "record": "20-16"
    },
    "Jacksonville": {
      "adjDef": 110.1,
      "adjDefRank": 272,
      "adjEM": -9.9,
      "adjOff": 100.2,
      "adjOffRank": 256,
      "adjTempo": 70.4,
      "adjTempoRank": 63,
      "conference": "ASun",
      "rank": 271,
      "record": "16-16"
    },
    "Jacksonville St.": {
      "adjDef": 110.9,
      "adjDefRank": 288,
      "adjEM": -12.58,
      "adjOff": 98.3,
      "adjOffRank": 290,
      "adjTempo": 64.9,
      "adjTempoRank": 317,
      "conference": "OVC",
      "rank": 296,
      "record": "8-23"
    },
    "James Madison": {
      "adjDef": 99.6,
      "adjDefRank": 77,
      "adjEM": 8.5,
      "adjOff": 108.1,
      "adjOffRank": 114,
      "adjTempo": 66.8,
      "adjTempoRank": 244,
      "conference": "CAA",
      "rank": 91,
      "record": "21-11"
    },
    "Kansas": {
      "adjDef": 90.4,
      "adjDefRank": 3,
      "adjEM": 29.67,
      "adjOff": 120,
      "adjOffRank": 10,
      "adjTempo": 69,
      "adjTempoRank": 123,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "33-5",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.4,
      "adjDefRank": 25,
      "adjEM": 13.46,
      "adjOff": 108.8,
      "adjOffRank": 101,
      "adjTempo": 66.7,
      "adjTempoRank": 250,
      "conference": "B12",
      "rank": 55,
      "record": "17-16"
    },
    "Kennesaw St.": {
      "adjDef": 110.2,
      "adjDefRank": 275,
      "adjEM": -8.57,
      "adjOff": 101.6,
      "adjOffRank": 224,
      "adjTempo": 68.6,
      "adjTempoRank": 139,
      "conference": "ASun",
      "rank": 261,
      "record": "11-20"
    },
    "Kent St.": {
      "adjDef": 107.8,
      "adjDefRank": 230,
      "adjEM": -1.37,
      "adjOff": 106.4,
      "adjOffRank": 150,
      "adjTempo": 67.2,
      "adjTempoRank": 221,
      "conference": "MAC",
      "rank": 179,
      "record": "19-13"
    },
    "Kentucky": {
      "adjDef": 96.6,
      "adjDefRank": 39,
      "adjEM": 25.14,
      "adjOff": 121.8,
      "adjOffRank": 5,
      "adjTempo": 67.4,
      "adjTempoRank": 214,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 6,
      "record": "27-9",
      "seed": 4
    },
    "LIU Brooklyn": {
      "adjDef": 111.9,
      "adjDefRank": 304,
      "adjEM": -11.78,
      "adjOff": 100.1,
      "adjOffRank": 260,
      "adjTempo": 72.3,
      "adjTempoRank": 21,
      "conference": "NEC",
      "rank": 287,
      "record": "16-15"
    },
    "LSU": {
      "adjDef": 102.3,
      "adjDefRank": 124,
      "adjEM": 9.56,
      "adjOff": 111.9,
      "adjOffRank": 60,
      "adjTempo": 72.3,
      "adjTempoRank": 22,
      "conference": "SEC",
      "rank": 81,
      "record": "19-14"
    },
    "La Salle": {
      "adjDef": 108.5,
      "adjDefRank": 243,
      "adjEM": -9.34,
      "adjOff": 99.2,
      "adjOffRank": 279,
      "adjTempo": 66.3,
      "adjTempoRank": 261,
      "conference": "A10",
      "rank": 267,
      "record": "9-22"
    },
    "Lafayette": {
      "adjDef": 116,
      "adjDefRank": 340,
      "adjEM": -16.42,
      "adjOff": 99.6,
      "adjOffRank": 271,
      "adjTempo": 69.4,
      "adjTempoRank": 107,
      "conference": "Pat",
      "rank": 326,
      "record": "6-24"
    },
    "Lamar": {
      "adjDef": 112.8,
      "adjDefRank": 314,
      "adjEM": -15.48,
      "adjOff": 97.3,
      "adjOffRank": 305,
      "adjTempo": 72,
      "adjTempoRank": 31,
      "conference": "Slnd",
      "rank": 320,
      "record": "11-19"
    },
    "Lehigh": {
      "adjDef": 107.6,
      "adjDefRank": 224,
      "adjEM": -2.71,
      "adjOff": 104.9,
      "adjOffRank": 170,
      "adjTempo": 67.8,
      "adjTempoRank": 185,
      "conference": "Pat",
      "rank": 191,
      "record": "17-15"
    },
    "Liberty": {
      "adjDef": 110.8,
      "adjDefRank": 286,
      "adjEM": -12.11,
      "adjOff": 98.7,
      "adjOffRank": 286,
      "adjTempo": 63.2,
      "adjTempoRank": 345,
      "conference": "BSth",
      "rank": 292,
      "record": "13-19"
    },
    "Lipscomb": {
      "adjDef": 111.6,
      "adjDefRank": 297,
      "adjEM": -10.14,
      "adjOff": 101.5,
      "adjOffRank": 233,
      "adjTempo": 72.8,
      "adjTempoRank": 17,
      "conference": "ASun",
      "rank": 274,
      "record": "12-21"
    },
    "Long Beach St.": {
      "adjDef": 101.9,
      "adjDefRank": 119,
      "adjEM": 6.64,
      "adjOff": 108.5,
      "adjOffRank": 104,
      "adjTempo": 70.2,
      "adjTempoRank": 80,
      "conference": "BW",
      "rank": 107,
      "record": "20-15"
    },
    "Longwood": {
      "adjDef": 112.9,
      "adjDefRank": 316,
      "adjEM": -12.51,
      "adjOff": 100.4,
      "adjOffRank": 250,
      "adjTempo": 69.2,
      "adjTempoRank": 111,
      "conference": "BSth",
      "rank": 295,
      "record": "10-23"
    },
    "Louisiana Lafayette": {
      "adjDef": 105.1,
      "adjDefRank": 180,
      "adjEM": 5.96,
      "adjOff": 111,
      "adjOffRank": 73,
      "adjTempo": 70.3,
      "adjTempoRank": 75,
      "conference": "SB",
      "rank": 112,
      "record": "19-15"
    },
    "Louisiana Monroe": {
      "adjDef": 103.1,
      "adjDefRank": 141,
      "adjEM": 3.31,
      "adjOff": 106.5,
      "adjOffRank": 149,
      "adjTempo": 65.8,
      "adjTempoRank": 286,
      "conference": "SB",
      "rank": 133,
      "record": "20-14"
    },
    "Louisiana Tech": {
      "adjDef": 103.7,
      "adjDefRank": 153,
      "adjEM": 0.87,
      "adjOff": 104.6,
      "adjOffRank": 174,
      "adjTempo": 71.7,
      "adjTempoRank": 37,
      "conference": "CUSA",
      "rank": 154,
      "record": "23-10"
    },
    "Louisville": {
      "adjDef": 88.8,
      "adjDefRank": 2,
      "adjEM": 24.68,
      "adjOff": 113.4,
      "adjOffRank": 42,
      "adjTempo": 66.1,
      "adjTempoRank": 268,
      "conference": "ACC",
      "rank": 7,
      "record": "23-8"
    },
    "Loyola Chicago": {
      "adjDef": 101.8,
      "adjDefRank": 116,
      "adjEM": -1.73,
      "adjOff": 100,
      "adjOffRank": 261,
      "adjTempo": 65,
      "adjTempoRank": 316,
      "conference": "MVC",
      "rank": 184,
      "record": "15-17"
    },
    "Loyola MD": {
      "adjDef": 112.6,
      "adjDefRank": 310,
      "adjEM": -11.85,
      "adjOff": 100.8,
      "adjOffRank": 241,
      "adjTempo": 66.7,
      "adjTempoRank": 251,
      "conference": "Pat",
      "rank": 288,
      "record": "9-21"
    },
    "Loyola Marymount": {
      "adjDef": 108.9,
      "adjDefRank": 254,
      "adjEM": -5.21,
      "adjOff": 103.7,
      "adjOffRank": 186,
      "adjTempo": 67.5,
      "adjTempoRank": 202,
      "conference": "WCC",
      "rank": 218,
      "record": "14-17"
    },
    "Maine": {
      "adjDef": 114.5,
      "adjDefRank": 331,
      "adjEM": -18.23,
      "adjOff": 96.3,
      "adjOffRank": 314,
      "adjTempo": 76.8,
      "adjTempoRank": 4,
      "conference": "AE",
      "rank": 336,
      "record": "8-22"
    },
    "Manhattan": {
      "adjDef": 106.6,
      "adjDefRank": 204,
      "adjEM": -7.77,
      "adjOff": 98.8,
      "adjOffRank": 284,
      "adjTempo": 67.9,
      "adjTempoRank": 175,
      "conference": "MAAC",
      "rank": 257,
      "record": "13-18"
    },
    "Marist": {
      "adjDef": 116,
      "adjDefRank": 341,
      "adjEM": -13.97,
      "adjOff": 102,
      "adjOffRank": 218,
      "adjTempo": 68.1,
      "adjTempoRank": 163,
      "conference": "MAAC",
      "rank": 307,
      "record": "7-23"
    },
    "Marquette": {
      "adjDef": 100.1,
      "adjDefRank": 88,
      "adjEM": 7.91,
      "adjOff": 108,
      "adjOffRank": 116,
      "adjTempo": 70.1,
      "adjTempoRank": 83,
      "conference": "BE",
      "rank": 97,
      "record": "20-13"
    },
    "Marshall": {
      "adjDef": 109.1,
      "adjDefRank": 260,
      "adjEM": 1.84,
      "adjOff": 111,
      "adjOffRank": 75,
      "adjTempo": 77.3,
      "adjTempoRank": 3,
      "conference": "CUSA",
      "rank": 147,
      "record": "17-16"
    },
    "Maryland": {
      "adjDef": 95.9,
      "adjDefRank": 32,
      "adjEM": 20.25,
      "adjOff": 116.2,
      "adjOffRank": 24,
      "adjTempo": 67.4,
      "adjTempoRank": 213,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 22,
      "record": "27-9",
      "seed": 5
    },
    "Maryland Eastern Shore": {
      "adjDef": 113.8,
      "adjDefRank": 325,
      "adjEM": -15.28,
      "adjOff": 98.5,
      "adjOffRank": 289,
      "adjTempo": 67.2,
      "adjTempoRank": 222,
      "conference": "MEAC",
      "rank": 318,
      "record": "10-22"
    },
    "Massachusetts": {
      "adjDef": 103.1,
      "adjDefRank": 140,
      "adjEM": -0.29,
      "adjOff": 102.8,
      "adjOffRank": 204,
      "adjTempo": 70.8,
      "adjTempoRank": 57,
      "conference": "A10",
      "rank": 167,
      "record": "14-18"
    },
    "McNeese St.": {
      "adjDef": 114.3,
      "adjDefRank": 328,
      "adjEM": -16.44,
      "adjOff": 97.8,
      "adjOffRank": 302,
      "adjTempo": 68,
      "adjTempoRank": 174,
      "conference": "Slnd",
      "rank": 327,
      "record": "9-20"
    },
    "Memphis": {
      "adjDef": 97.4,
      "adjDefRank": 47,
      "adjEM": 10.1,
      "adjOff": 107.5,
      "adjOffRank": 124,
      "adjTempo": 73.4,
      "adjTempoRank": 12,
      "conference": "Amer",
      "rank": 75,
      "record": "19-15"
    },
    "Mercer": {
      "adjDef": 107.5,
      "adjDefRank": 220,
      "adjEM": -1.67,
      "adjOff": 105.8,
      "adjOffRank": 160,
      "adjTempo": 63.4,
      "adjTempoRank": 341,
      "conference": "SC",
      "rank": 182,
      "record": "19-15"
    },
    "Miami FL": {
      "adjDef": 96.9,
      "adjDefRank": 42,
      "adjEM": 22.44,
      "adjOff": 119.4,
      "adjOffRank": 11,
      "adjTempo": 65.9,
      "adjTempoRank": 284,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 15,
      "record": "27-8",
      "seed": 3
    },
    "Miami OH": {
      "adjDef": 104.7,
      "adjDefRank": 174,
      "adjEM": -7.25,
      "adjOff": 97.4,
      "adjOffRank": 304,
      "adjTempo": 64.3,
      "adjTempoRank": 331,
      "conference": "MAC",
      "rank": 247,
      "record": "13-20"
    },
    "Michigan": {
      "adjDef": 100.5,
      "adjDefRank": 92,
      "adjEM": 14.15,
      "adjOff": 114.6,
      "adjOffRank": 30,
      "adjTempo": 65.8,
      "adjTempoRank": 289,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 50,
      "record": "23-13",
      "seed": 11
    },
    "Michigan St.": {
      "adjDef": 95.5,
      "adjDefRank": 26,
      "adjEM": 27.97,
      "adjOff": 123.4,
      "adjOffRank": 2,
      "adjTempo": 66.5,
      "adjTempoRank": 254,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 5,
      "record": "29-6",
      "seed": 2
    },
    "Middle Tennessee": {
      "adjDef": 99.9,
      "adjDefRank": 85,
      "adjEM": 4.76,
      "adjOff": 104.6,
      "adjOffRank": 173,
      "adjTempo": 67.6,
      "adjTempoRank": 200,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 119,
      "record": "25-10",
      "seed": 15
    },
    "Milwaukee": {
      "adjDef": 107.3,
      "adjDefRank": 217,
      "adjEM": 5.2,
      "adjOff": 112.5,
      "adjOffRank": 52,
      "adjTempo": 65.9,
      "adjTempoRank": 280,
      "conference": "Horz",
      "rank": 114,
      "record": "20-13"
    },
    "Minnesota": {
      "adjDef": 104.3,
      "adjDefRank": 163,
      "adjEM": -2.77,
      "adjOff": 101.5,
      "adjOffRank": 230,
      "adjTempo": 68.4,
      "adjTempoRank": 147,
      "conference": "B10",
      "rank": 192,
      "record": "8-23"
    },
    "Mississippi": {
      "adjDef": 102,
      "adjDefRank": 121,
      "adjEM": 9.88,
      "adjOff": 111.9,
      "adjOffRank": 61,
      "adjTempo": 68.5,
      "adjTempoRank": 144,
      "conference": "SEC",
      "rank": 77,
      "record": "20-12"
    },
    "Mississippi St.": {
      "adjDef": 100.5,
      "adjDefRank": 93,
      "adjEM": 9.62,
      "adjOff": 110.1,
      "adjOffRank": 91,
      "adjTempo": 69.3,
      "adjTempoRank": 108,
      "conference": "SEC",
      "rank": 78,
      "record": "14-17"
    },
    "Mississippi Valley St.": {
      "adjDef": 110.8,
      "adjDefRank": 285,
      "adjEM": -19.33,
      "adjOff": 91.5,
      "adjOffRank": 341,
      "adjTempo": 71.2,
      "adjTempoRank": 52,
      "conference": "SWAC",
      "rank": 341,
      "record": "8-27"
    },
    "Missouri": {
      "adjDef": 104.5,
      "adjDefRank": 169,
      "adjEM": 0.2,
      "adjOff": 104.7,
      "adjOffRank": 172,
      "adjTempo": 68,
      "adjTempoRank": 173,
      "conference": "SEC",
      "rank": 159,
      "record": "10-21"
    },
    "Missouri St.": {
      "adjDef": 105.8,
      "adjDefRank": 187,
      "adjEM": -5.38,
      "adjOff": 100.4,
      "adjOffRank": 251,
      "adjTempo": 68.9,
      "adjTempoRank": 125,
      "conference": "MVC",
      "rank": 224,
      "record": "13-19"
    },
    "Monmouth": {
      "adjDef": 99.2,
      "adjDefRank": 69,
      "adjEM": 8.1,
      "adjOff": 107.3,
      "adjOffRank": 130,
      "adjTempo": 73,
      "adjTempoRank": 15,
      "conference": "MAAC",
      "rank": 95,
      "record": "28-8"
    },
    "Montana": {
      "adjDef": 104.6,
      "adjDefRank": 173,
      "adjEM": 2.28,
      "adjOff": 106.9,
      "adjOffRank": 143,
      "adjTempo": 65.4,
      "adjTempoRank": 301,
      "conference": "BSky",
      "rank": 143,
      "record": "21-12"
    },
    "Montana St.": {
      "adjDef": 113.7,
      "adjDefRank": 324,
      "adjEM": -7.2,
      "adjOff": 106.5,
      "adjOffRank": 148,
      "adjTempo": 68.5,
      "adjTempoRank": 142,
      "conference": "BSky",
      "rank": 246,
      "record": "14-17"
    },
    "Morehead St.": {
      "adjDef": 102.8,
      "adjDefRank": 133,
      "adjEM": 3.78,
      "adjOff": 106.6,
      "adjOffRank": 145,
      "adjTempo": 65.7,
      "adjTempoRank": 295,
      "conference": "OVC",
      "rank": 126,
      "record": "23-14"
    },
    "Morgan St.": {
      "adjDef": 108.5,
      "adjDefRank": 242,
      "adjEM": -13.58,
      "adjOff": 94.9,
      "adjOffRank": 323,
      "adjTempo": 69.1,
      "adjTempoRank": 116,
      "conference": "MEAC",
      "rank": 304,
      "record": "9-22"
    },
    "Mount St. Mary's": {
      "adjDef": 102.4,
      "adjDefRank": 127,
      "adjEM": -8.78,
      "adjOff": 93.7,
      "adjOffRank": 330,
      "adjTempo": 68.5,
      "adjTempoRank": 145,
      "conference": "NEC",
      "rank": 263,
      "record": "14-19"
    },
    "Murray St.": {
      "adjDef": 106.4,
      "adjDefRank": 201,
      "adjEM": -0.1,
      "adjOff": 106.3,
      "adjOffRank": 154,
      "adjTempo": 64.3,
      "adjTempoRank": 329,
      "conference": "OVC",
      "rank": 163,
      "record": "17-14"
    },
    "N.C. State": {
      "adjDef": 103.7,
      "adjDefRank": 152,
      "adjEM": 10.44,
      "adjOff": 114.1,
      "adjOffRank": 33,
      "adjTempo": 67.7,
      "adjTempoRank": 195,
      "conference": "ACC",
      "rank": 74,
      "record": "16-17"
    },
    "NJIT": {
      "adjDef": 105.9,
      "adjDefRank": 192,
      "adjEM": -3.61,
      "adjOff": 102.3,
      "adjOffRank": 213,
      "adjTempo": 69,
      "adjTempoRank": 122,
      "conference": "ASun",
      "rank": 202,
      "record": "20-15"
    },
    "Navy": {
      "adjDef": 103.1,
      "adjDefRank": 139,
      "adjEM": -3.68,
      "adjOff": 99.4,
      "adjOffRank": 277,
      "adjTempo": 64.9,
      "adjTempoRank": 319,
      "conference": "Pat",
      "rank": 204,
      "record": "19-14"
    },
    "Nebraska": {
      "adjDef": 101.6,
      "adjDefRank": 113,
      "adjEM": 8.82,
      "adjOff": 110.4,
      "adjOffRank": 86,
      "adjTempo": 67.1,
      "adjTempoRank": 231,
      "conference": "B10",
      "rank": 85,
      "record": "16-18"
    },
    "Nebraska Omaha": {
      "adjDef": 107.9,
      "adjDefRank": 231,
      "adjEM": 1.83,
      "adjOff": 109.7,
      "adjOffRank": 93,
      "adjTempo": 76.2,
      "adjTempoRank": 5,
      "conference": "Sum",
      "rank": 148,
      "record": "18-14"
    },
    "Nevada": {
      "adjDef": 98.5,
      "adjDefRank": 55,
      "adjEM": 3.94,
      "adjOff": 102.4,
      "adjOffRank": 210,
      "adjTempo": 71.3,
      "adjTempoRank": 47,
      "conference": "MWC",
      "rank": 124,
      "record": "24-14"
    },
    "New Hampshire": {
      "adjDef": 105.9,
      "adjDefRank": 190,
      "adjEM": -6.01,
      "adjOff": 99.9,
      "adjOffRank": 265,
      "adjTempo": 67.4,
      "adjTempoRank": 212,
      "conference": "AE",
      "rank": 235,
      "record": "20-13"
    },
    "New Mexico": {
      "adjDef": 103.2,
      "adjDefRank": 143,
      "adjEM": 5.14,
      "adjOff": 108.3,
      "adjOffRank": 109,
      "adjTempo": 70.9,
      "adjTempoRank": 55,
      "conference": "MWC",
      "rank": 115,
      "record": "17-15"
    },
    "New Mexico St.": {
      "adjDef": 97.2,
      "adjDefRank": 44,
      "adjEM": 6.13,
      "adjOff": 103.3,
      "adjOffRank": 196,
      "adjTempo": 65.3,
      "adjTempoRank": 302,
      "conference": "WAC",
      "rank": 111,
      "record": "23-11"
    },
    "New Orleans": {
      "adjDef": 111.4,
      "adjDefRank": 295,
      "adjEM": -15.01,
      "adjOff": 96.4,
      "adjOffRank": 313,
      "adjTempo": 72.2,
      "adjTempoRank": 24,
      "conference": "Slnd",
      "rank": 316,
      "record": "10-20"
    },
    "Niagara": {
      "adjDef": 108.2,
      "adjDefRank": 237,
      "adjEM": -14.17,
      "adjOff": 94.1,
      "adjOffRank": 325,
      "adjTempo": 65.7,
      "adjTempoRank": 296,
      "conference": "MAAC",
      "rank": 308,
      "record": "7-25"
    },
    "Nicholls St.": {
      "adjDef": 108.9,
      "adjDefRank": 255,
      "adjEM": -16.75,
      "adjOff": 92.2,
      "adjOffRank": 335,
      "adjTempo": 68.4,
      "adjTempoRank": 152,
      "conference": "Slnd",
      "rank": 331,
      "record": "11-23"
    },
    "Norfolk St.": {
      "adjDef": 113.1,
      "adjDefRank": 319,
      "adjEM": -7.52,
      "adjOff": 105.6,
      "adjOffRank": 162,
      "adjTempo": 67.7,
      "adjTempoRank": 192,
      "conference": "MEAC",
      "rank": 252,
      "record": "17-17"
    },
    "North Carolina": {
      "adjDef": 94.6,
      "adjDefRank": 21,
      "adjEM": 29.82,
      "adjOff": 124.4,
      "adjOffRank": 1,
      "adjTempo": 70.5,
      "adjTempoRank": 60,
      "conference": "ACC",
      "finish": "Runner-up",
      "rank": 2,
      "record": "33-7",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 114.7,
      "adjDefRank": 332,
      "adjEM": -19.05,
      "adjOff": 95.7,
      "adjOffRank": 319,
      "adjTempo": 67.6,
      "adjTempoRank": 199,
      "conference": "MEAC",
      "rank": 339,
      "record": "10-22"
    },
    "North Carolina Central": {
      "adjDef": 111.6,
      "adjDefRank": 298,
      "adjEM": -13.35,
      "adjOff": 98.3,
      "adjOffRank": 293,
      "adjTempo": 65.9,
      "adjTempoRank": 281,
      "conference": "MEAC",
      "rank": 302,
      "record": "13-19"
    },
    "North Dakota": {
      "adjDef": 103,
      "adjDefRank": 135,
      "adjEM": -3.35,
      "adjOff": 99.6,
      "adjOffRank": 269,
      "adjTempo": 71.6,
      "adjTempoRank": 39,
      "conference": "BSky",
      "rank": 199,
      "record": "17-16"
    },
    "North Dakota St.": {
      "adjDef": 99.8,
      "adjDefRank": 82,
      "adjEM": 2.07,
      "adjOff": 101.9,
      "adjOffRank": 219,
      "adjTempo": 64.5,
      "adjTempoRank": 325,
      "conference": "Sum",
      "rank": 145,
      "record": "20-13"
    },
    "North Florida": {
      "adjDef": 115,
      "adjDefRank": 335,
      "adjEM": -1.19,
      "adjOff": 113.9,
      "adjOffRank": 36,
      "adjTempo": 70.4,
      "adjTempoRank": 65,
      "conference": "ASun",
      "rank": 177,
      "record": "22-12"
    },
    "North Texas": {
      "adjDef": 112.7,
      "adjDefRank": 312,
      "adjEM": -11.89,
      "adjOff": 100.8,
      "adjOffRank": 240,
      "adjTempo": 69.7,
      "adjTempoRank": 93,
      "conference": "CUSA",
      "rank": 289,
      "record": "12-20"
    },
    "Northeastern": {
      "adjDef": 103.7,
      "adjDefRank": 149,
      "adjEM": 3.38,
      "adjOff": 107,
      "adjOffRank": 136,
      "adjTempo": 65.2,
      "adjTempoRank": 311,
      "conference": "CAA",
      "rank": 132,
      "record": "18-15"
    },
    "Northern Arizona": {
      "adjDef": 113.1,
      "adjDefRank": 320,
      "adjEM": -20.65,
      "adjOff": 92.5,
      "adjOffRank": 334,
      "adjTempo": 70.3,
      "adjTempoRank": 72,
      "conference": "BSky",
      "rank": 343,
      "record": "5-25"
    },
    "Northern Colorado": {
      "adjDef": 118,
      "adjDefRank": 348,
      "adjEM": -14.65,
      "adjOff": 103.3,
      "adjOffRank": 195,
      "adjTempo": 68.3,
      "adjTempoRank": 155,
      "conference": "BSky",
      "rank": 314,
      "record": "10-21"
    },
    "Northern Illinois": {
      "adjDef": 101.7,
      "adjDefRank": 115,
      "adjEM": -0.18,
      "adjOff": 101.6,
      "adjOffRank": 228,
      "adjTempo": 67.9,
      "adjTempoRank": 177,
      "conference": "MAC",
      "rank": 165,
      "record": "21-13"
    },
    "Northern Iowa": {
      "adjDef": 98.5,
      "adjDefRank": 56,
      "adjEM": 11.13,
      "adjOff": 109.6,
      "adjOffRank": 94,
      "adjTempo": 62.9,
      "adjTempoRank": 346,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 67,
      "record": "23-13",
      "seed": 11
    },
    "Northern Kentucky": {
      "adjDef": 108.4,
      "adjDefRank": 240,
      "adjEM": -7.66,
      "adjOff": 100.7,
      "adjOffRank": 244,
      "adjTempo": 67.3,
      "adjTempoRank": 220,
      "conference": "Horz",
      "rank": 255,
      "record": "9-21"
    },
    "Northwestern": {
      "adjDef": 100.1,
      "adjDefRank": 87,
      "adjEM": 11.04,
      "adjOff": 111.1,
      "adjOffRank": 71,
      "adjTempo": 63.7,
      "adjTempoRank": 338,
      "conference": "B10",
      "rank": 68,
      "record": "20-12"
    },
    "Northwestern St.": {
      "adjDef": 118.2,
      "adjDefRank": 349,
      "adjEM": -16.62,
      "adjOff": 101.6,
      "adjOffRank": 227,
      "adjTempo": 73.8,
      "adjTempoRank": 8,
      "conference": "Slnd",
      "rank": 330,
      "record": "8-20"
    },
    "Notre Dame": {
      "adjDef": 104,
      "adjDefRank": 158,
      "adjEM": 16.6,
      "adjOff": 120.6,
      "adjOffRank": 9,
      "adjTempo": 64.6,
      "adjTempoRank": 324,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 36,
      "record": "24-12",
      "seed": 6
    },
    "Oakland": {
      "adjDef": 106.7,
      "adjDefRank": 208,
      "adjEM": 9.6,
      "adjOff": 116.3,
      "adjOffRank": 23,
      "adjTempo": 73.8,
      "adjTempoRank": 9,
      "conference": "Horz",
      "rank": 79,
      "record": "23-12"
    },
    "Ohio": {
      "adjDef": 108.1,
      "adjDefRank": 234,
      "adjEM": 2.56,
      "adjOff": 110.6,
      "adjOffRank": 80,
      "adjTempo": 70,
      "adjTempoRank": 86,
      "conference": "MAC",
      "rank": 141,
      "record": "23-12"
    },
    "Ohio St.": {
      "adjDef": 97,
      "adjDefRank": 43,
      "adjEM": 10,
      "adjOff": 107,
      "adjOffRank": 138,
      "adjTempo": 67.5,
      "adjTempoRank": 203,
      "conference": "B10",
      "rank": 76,
      "record": "21-14"
    },
    "Oklahoma": {
      "adjDef": 94.1,
      "adjDefRank": 17,
      "adjEM": 23.78,
      "adjOff": 117.9,
      "adjOffRank": 16,
      "adjTempo": 69.8,
      "adjTempoRank": 88,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 10,
      "record": "29-8",
      "seed": 2
    },
    "Oklahoma St.": {
      "adjDef": 98.6,
      "adjDefRank": 58,
      "adjEM": 7.75,
      "adjOff": 106.4,
      "adjOffRank": 151,
      "adjTempo": 63.9,
      "adjTempoRank": 334,
      "conference": "B12",
      "rank": 98,
      "record": "12-20"
    },
    "Old Dominion": {
      "adjDef": 99.5,
      "adjDefRank": 76,
      "adjEM": 7.52,
      "adjOff": 107,
      "adjOffRank": 137,
      "adjTempo": 61.1,
      "adjTempoRank": 350,
      "conference": "CUSA",
      "rank": 100,
      "record": "25-13"
    },
    "Oral Roberts": {
      "adjDef": 108.6,
      "adjDefRank": 249,
      "adjEM": -0.99,
      "adjOff": 107.6,
      "adjOffRank": 121,
      "adjTempo": 68.8,
      "adjTempoRank": 130,
      "conference": "Sum",
      "rank": 174,
      "record": "14-17"
    },
    "Oregon": {
      "adjDef": 96.4,
      "adjDefRank": 37,
      "adjEM": 22.68,
      "adjOff": 119.1,
      "adjOffRank": 13,
      "adjTempo": 68.1,
      "adjTempoRank": 167,
      "conference": "P12",
      "finish": "Elite Eight",
      "rank": 12,
      "record": "31-7",
      "seed": 1
    },
    "Oregon St.": {
      "adjDef": 99.1,
      "adjDefRank": 66,
      "adjEM": 11.65,
      "adjOff": 110.7,
      "adjOffRank": 77,
      "adjTempo": 67.1,
      "adjTempoRank": 229,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 64,
      "record": "19-13",
      "seed": 7
    },
    "Pacific": {
      "adjDef": 106.3,
      "adjDefRank": 199,
      "adjEM": -5.63,
      "adjOff": 100.6,
      "adjOffRank": 245,
      "adjTempo": 66.5,
      "adjTempoRank": 256,
      "conference": "WCC",
      "rank": 227,
      "record": "8-20"
    },
    "Penn": {
      "adjDef": 107.7,
      "adjDefRank": 226,
      "adjEM": -7.56,
      "adjOff": 100.1,
      "adjOffRank": 259,
      "adjTempo": 67.8,
      "adjTempoRank": 190,
      "conference": "Ivy",
      "rank": 253,
      "record": "11-17"
    },
    "Penn St.": {
      "adjDef": 100.8,
      "adjDefRank": 98,
      "adjEM": 2.58,
      "adjOff": 103.4,
      "adjOffRank": 193,
      "adjTempo": 66.1,
      "adjTempoRank": 270,
      "conference": "B10",
      "rank": 140,
      "record": "16-16"
    },
    "Pepperdine": {
      "adjDef": 103,
      "adjDefRank": 137,
      "adjEM": 3.02,
      "adjOff": 106.1,
      "adjOffRank": 155,
      "adjTempo": 67.5,
      "adjTempoRank": 207,
      "conference": "WCC",
      "rank": 137,
      "record": "18-14"
    },
    "Pittsburgh": {
      "adjDef": 98.3,
      "adjDefRank": 53,
      "adjEM": 16.6,
      "adjOff": 114.9,
      "adjOffRank": 28,
      "adjTempo": 65.3,
      "adjTempoRank": 303,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 37,
      "record": "21-12",
      "seed": 10
    },
    "Portland": {
      "adjDef": 111.1,
      "adjDefRank": 292,
      "adjEM": -4.01,
      "adjOff": 107.1,
      "adjOffRank": 135,
      "adjTempo": 71.8,
      "adjTempoRank": 34,
      "conference": "WCC",
      "rank": 206,
      "record": "12-20"
    },
    "Portland St.": {
      "adjDef": 110.4,
      "adjDefRank": 279,
      "adjEM": -8.35,
      "adjOff": 102.1,
      "adjOffRank": 217,
      "adjTempo": 72.1,
      "adjTempoRank": 29,
      "conference": "BSky",
      "rank": 260,
      "record": "13-18"
    },
    "Prairie View A&M": {
      "adjDef": 107.6,
      "adjDefRank": 225,
      "adjEM": -18.57,
      "adjOff": 89,
      "adjOffRank": 344,
      "adjTempo": 68.7,
      "adjTempoRank": 135,
      "conference": "SWAC",
      "rank": 337,
      "record": "7-24"
    },
    "Presbyterian": {
      "adjDef": 110.2,
      "adjDefRank": 276,
      "adjEM": -16.58,
      "adjOff": 93.7,
      "adjOffRank": 329,
      "adjTempo": 67.2,
      "adjTempoRank": 223,
      "conference": "BSth",
      "rank": 328,
      "record": "11-20"
    },
    "Princeton": {
      "adjDef": 101.7,
      "adjDefRank": 114,
      "adjEM": 10.63,
      "adjOff": 112.3,
      "adjOffRank": 56,
      "adjTempo": 68.6,
      "adjTempoRank": 138,
      "conference": "Ivy",
      "rank": 70,
      "record": "22-7"
    },
    "Providence": {
      "adjDef": 95.5,
      "adjDefRank": 28,
      "adjEM": 14.49,
      "adjOff": 110,
      "adjOffRank": 92,
      "adjTempo": 68.4,
      "adjTempoRank": 150,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 44,
      "record": "24-11",
      "seed": 9
    },
    "Purdue": {
      "adjDef": 93.1,
      "adjDefRank": 11,
      "adjEM": 23.85,
      "adjOff": 116.9,
      "adjOffRank": 19,
      "adjTempo": 67.9,
      "adjTempoRank": 182,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 9,
      "record": "26-9",
      "seed": 5
    },
    "Quinnipiac": {
      "adjDef": 103.8,
      "adjDefRank": 154,
      "adjEM": -11.89,
      "adjOff": 91.9,
      "adjOffRank": 337,
      "adjTempo": 68.5,
      "adjTempoRank": 143,
      "conference": "MAAC",
      "rank": 291,
      "record": "9-21"
    },
    "Radford": {
      "adjDef": 109.5,
      "adjDefRank": 265,
      "adjEM": -5.29,
      "adjOff": 104.2,
      "adjOffRank": 178,
      "adjTempo": 65.8,
      "adjTempoRank": 290,
      "conference": "BSth",
      "rank": 222,
      "record": "16-15"
    },
    "Rhode Island": {
      "adjDef": 99,
      "adjDefRank": 65,
      "adjEM": 9.37,
      "adjOff": 108.4,
      "adjOffRank": 106,
      "adjTempo": 64.4,
      "adjTempoRank": 328,
      "conference": "A10",
      "rank": 82,
      "record": "17-15"
    },
    "Rice": {
      "adjDef": 115.8,
      "adjDefRank": 339,
      "adjEM": -10.12,
      "adjOff": 105.7,
      "adjOffRank": 161,
      "adjTempo": 69.7,
      "adjTempoRank": 89,
      "conference": "CUSA",
      "rank": 272,
      "record": "12-20"
    },
    "Richmond": {
      "adjDef": 105.9,
      "adjDefRank": 193,
      "adjEM": 7.7,
      "adjOff": 113.6,
      "adjOffRank": 41,
      "adjTempo": 68,
      "adjTempoRank": 170,
      "conference": "A10",
      "rank": 99,
      "record": "16-16"
    },
    "Rider": {
      "adjDef": 104.7,
      "adjDefRank": 176,
      "adjEM": -5.33,
      "adjOff": 99.3,
      "adjOffRank": 278,
      "adjTempo": 64.3,
      "adjTempoRank": 330,
      "conference": "MAAC",
      "rank": 223,
      "record": "13-20"
    },
    "Robert Morris": {
      "adjDef": 105.2,
      "adjDefRank": 183,
      "adjEM": -13.36,
      "adjOff": 91.8,
      "adjOffRank": 339,
      "adjTempo": 67.8,
      "adjTempoRank": 188,
      "conference": "NEC",
      "rank": 303,
      "record": "10-22"
    },
    "Rutgers": {
      "adjDef": 108.1,
      "adjDefRank": 236,
      "adjEM": -10.63,
      "adjOff": 97.5,
      "adjOffRank": 303,
      "adjTempo": 70.4,
      "adjTempoRank": 68,
      "conference": "B10",
      "rank": 279,
      "record": "7-25"
    },
    "SIU Edwardsville": {
      "adjDef": 109.6,
      "adjDefRank": 267,
      "adjEM": -14.46,
      "adjOff": 95.2,
      "adjOffRank": 321,
      "adjTempo": 66.1,
      "adjTempoRank": 267,
      "conference": "OVC",
      "rank": 311,
      "record": "6-22"
    },
    "SMU": {
      "adjDef": 97.3,
      "adjDefRank": 45,
      "adjEM": 21.98,
      "adjOff": 119.3,
      "adjOffRank": 12,
      "adjTempo": 65.8,
      "adjTempoRank": 288,
      "conference": "Amer",
      "rank": 16,
      "record": "25-5"
    },
    "Sacramento St.": {
      "adjDef": 108.9,
      "adjDefRank": 256,
      "adjEM": -10.13,
      "adjOff": 98.8,
      "adjOffRank": 285,
      "adjTempo": 68.5,
      "adjTempoRank": 146,
      "conference": "BSky",
      "rank": 273,
      "record": "14-17"
    },
    "Sacred Heart": {
      "adjDef": 112.7,
      "adjDefRank": 313,
      "adjEM": -13.11,
      "adjOff": 99.6,
      "adjOffRank": 270,
      "adjTempo": 71.2,
      "adjTempoRank": 48,
      "conference": "NEC",
      "rank": 300,
      "record": "12-18"
    },
    "Saint Joseph's": {
      "adjDef": 98.9,
      "adjDefRank": 63,
      "adjEM": 15.37,
      "adjOff": 114.3,
      "adjOffRank": 32,
      "adjTempo": 70.2,
      "adjTempoRank": 78,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 43,
      "record": "28-8",
      "seed": 8
    },
    "Saint Louis": {
      "adjDef": 102.4,
      "adjDefRank": 126,
      "adjEM": -5.56,
      "adjOff": 96.9,
      "adjOffRank": 310,
      "adjTempo": 69,
      "adjTempoRank": 117,
      "conference": "A10",
      "rank": 226,
      "record": "11-21"
    },
    "Saint Mary's": {
      "adjDef": 100.6,
      "adjDefRank": 96,
      "adjEM": 16.74,
      "adjOff": 117.3,
      "adjOffRank": 17,
      "adjTempo": 61.8,
      "adjTempoRank": 347,
      "conference": "WCC",
      "rank": 34,
      "record": "29-6"
    },
    "Saint Peter's": {
      "adjDef": 102,
      "adjDefRank": 120,
      "adjEM": -5.24,
      "adjOff": 96.7,
      "adjOffRank": 311,
      "adjTempo": 64.5,
      "adjTempoRank": 326,
      "conference": "MAAC",
      "rank": 219,
      "record": "14-16"
    },
    "Sam Houston St.": {
      "adjDef": 107.2,
      "adjDefRank": 215,
      "adjEM": -4.61,
      "adjOff": 102.6,
      "adjOffRank": 209,
      "adjTempo": 67,
      "adjTempoRank": 237,
      "conference": "Slnd",
      "rank": 213,
      "record": "18-16"
    },
    "Samford": {
      "adjDef": 108.2,
      "adjDefRank": 238,
      "adjEM": -5.28,
      "adjOff": 103,
      "adjOffRank": 202,
      "adjTempo": 67.9,
      "adjTempoRank": 176,
      "conference": "SC",
      "rank": 221,
      "record": "14-19"
    },
    "San Diego": {
      "adjDef": 103.7,
      "adjDefRank": 151,
      "adjEM": -9.77,
      "adjOff": 93.9,
      "adjOffRank": 327,
      "adjTempo": 66.1,
      "adjTempoRank": 272,
      "conference": "WCC",
      "rank": 269,
      "record": "9-21"
    },
    "San Diego St.": {
      "adjDef": 90.5,
      "adjDefRank": 4,
      "adjEM": 14.35,
      "adjOff": 104.8,
      "adjOffRank": 171,
      "adjTempo": 64.8,
      "adjTempoRank": 321,
      "conference": "MWC",
      "rank": 46,
      "record": "28-10"
    },
    "San Francisco": {
      "adjDef": 111.1,
      "adjDefRank": 291,
      "adjEM": -3.75,
      "adjOff": 107.4,
      "adjOffRank": 129,
      "adjTempo": 70.5,
      "adjTempoRank": 62,
      "conference": "WCC",
      "rank": 205,
      "record": "15-15"
    },
    "San Jose St.": {
      "adjDef": 104.7,
      "adjDefRank": 175,
      "adjEM": -7.46,
      "adjOff": 97.2,
      "adjOffRank": 306,
      "adjTempo": 69.5,
      "adjTempoRank": 100,
      "conference": "MWC",
      "rank": 249,
      "record": "9-22"
    },
    "Santa Clara": {
      "adjDef": 108.9,
      "adjDefRank": 253,
      "adjEM": -7.07,
      "adjOff": 101.8,
      "adjOffRank": 220,
      "adjTempo": 65.5,
      "adjTempoRank": 298,
      "conference": "WCC",
      "rank": 244,
      "record": "11-20"
    },
    "Savannah St.": {
      "adjDef": 104.2,
      "adjDefRank": 161,
      "adjEM": -16.85,
      "adjOff": 87.4,
      "adjOffRank": 349,
      "adjTempo": 65.9,
      "adjTempoRank": 285,
      "conference": "MEAC",
      "rank": 332,
      "record": "16-16"
    },
    "Seattle": {
      "adjDef": 106.7,
      "adjDefRank": 207,
      "adjEM": -12.37,
      "adjOff": 94.3,
      "adjOffRank": 324,
      "adjTempo": 65.8,
      "adjTempoRank": 294,
      "conference": "WAC",
      "rank": 294,
      "record": "15-17"
    },
    "Seton Hall": {
      "adjDef": 92.9,
      "adjDefRank": 10,
      "adjEM": 18.27,
      "adjOff": 111.1,
      "adjOffRank": 72,
      "adjTempo": 69.7,
      "adjTempoRank": 92,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 29,
      "record": "25-9",
      "seed": 6
    },
    "Siena": {
      "adjDef": 104.5,
      "adjDefRank": 168,
      "adjEM": 3.67,
      "adjOff": 108.1,
      "adjOffRank": 113,
      "adjTempo": 70.1,
      "adjTempoRank": 82,
      "conference": "MAAC",
      "rank": 128,
      "record": "21-13"
    },
    "South Alabama": {
      "adjDef": 104.1,
      "adjDefRank": 159,
      "adjEM": -8.22,
      "adjOff": 95.8,
      "adjOffRank": 316,
      "adjTempo": 69.6,
      "adjTempoRank": 95,
      "conference": "SB",
      "rank": 259,
      "record": "14-19"
    },
    "South Carolina": {
      "adjDef": 96.2,
      "adjDefRank": 36,
      "adjEM": 12.87,
      "adjOff": 109,
      "adjOffRank": 99,
      "adjTempo": 70.1,
      "adjTempoRank": 84,
      "conference": "SEC",
      "rank": 58,
      "record": "25-9"
    },
    "South Carolina St.": {
      "adjDef": 112.8,
      "adjDefRank": 315,
      "adjEM": -9.48,
      "adjOff": 103.3,
      "adjOffRank": 194,
      "adjTempo": 67.6,
      "adjTempoRank": 198,
      "conference": "MEAC",
      "rank": 268,
      "record": "19-15"
    },
    "South Dakota": {
      "adjDef": 111,
      "adjDefRank": 289,
      "adjEM": -4.96,
      "adjOff": 106,
      "adjOffRank": 156,
      "adjTempo": 70.5,
      "adjTempoRank": 61,
      "conference": "Sum",
      "rank": 216,
      "record": "14-18"
    },
    "South Dakota St.": {
      "adjDef": 101.8,
      "adjDefRank": 117,
      "adjEM": 9.57,
      "adjOff": 111.4,
      "adjOffRank": 66,
      "adjTempo": 68.3,
      "adjTempoRank": 157,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 80,
      "record": "26-8",
      "seed": 12
    },
    "South Florida": {
      "adjDef": 104.8,
      "adjDefRank": 177,
      "adjEM": -6.49,
      "adjOff": 98.3,
      "adjOffRank": 292,
      "adjTempo": 66.7,
      "adjTempoRank": 248,
      "conference": "Amer",
      "rank": 238,
      "record": "8-25"
    },
    "Southeast Missouri St.": {
      "adjDef": 110.5,
      "adjDefRank": 280,
      "adjEM": -19.25,
      "adjOff": 91.2,
      "adjOffRank": 342,
      "adjTempo": 70.8,
      "adjTempoRank": 56,
      "conference": "OVC",
      "rank": 340,
      "record": "5-24"
    },
    "Southeastern Louisiana": {
      "adjDef": 112.4,
      "adjDefRank": 308,
      "adjEM": -14.46,
      "adjOff": 98,
      "adjOffRank": 297,
      "adjTempo": 68,
      "adjTempoRank": 171,
      "conference": "Slnd",
      "rank": 310,
      "record": "12-21"
    },
    "Southern": {
      "adjDef": 106.3,
      "adjDefRank": 198,
      "adjEM": -5.75,
      "adjOff": 100.5,
      "adjOffRank": 248,
      "adjTempo": 68.9,
      "adjTempoRank": 126,
      "conference": "SWAC",
      "finish": "First Four",
      "rank": 229,
      "record": "22-13",
      "seed": 16
    },
    "Southern Illinois": {
      "adjDef": 104.4,
      "adjDefRank": 166,
      "adjEM": 2.8,
      "adjOff": 107.2,
      "adjOffRank": 134,
      "adjTempo": 69.3,
      "adjTempoRank": 109,
      "conference": "MVC",
      "rank": 139,
      "record": "22-10"
    },
    "Southern Miss": {
      "adjDef": 110.3,
      "adjDefRank": 277,
      "adjEM": -13.84,
      "adjOff": 96.5,
      "adjOffRank": 312,
      "adjTempo": 63.3,
      "adjTempoRank": 343,
      "conference": "CUSA",
      "rank": 305,
      "record": "8-21"
    },
    "Southern Utah": {
      "adjDef": 118.5,
      "adjDefRank": 350,
      "adjEM": -20.3,
      "adjOff": 98.2,
      "adjOffRank": 294,
      "adjTempo": 68.2,
      "adjTempoRank": 158,
      "conference": "BSky",
      "rank": 342,
      "record": "6-24"
    },
    "St. Bonaventure": {
      "adjDef": 105.1,
      "adjDefRank": 181,
      "adjEM": 8.63,
      "adjOff": 113.7,
      "adjOffRank": 39,
      "adjTempo": 68,
      "adjTempoRank": 172,
      "conference": "A10",
      "rank": 89,
      "record": "22-9"
    },
    "St. Francis NY": {
      "adjDef": 105.2,
      "adjDefRank": 182,
      "adjEM": -11.23,
      "adjOff": 93.9,
      "adjOffRank": 326,
      "adjTempo": 65.8,
      "adjTempoRank": 287,
      "conference": "NEC",
      "rank": 284,
      "record": "15-17"
    },
    "St. Francis PA": {
      "adjDef": 113.4,
      "adjDefRank": 322,
      "adjEM": -13.05,
      "adjOff": 100.4,
      "adjOffRank": 252,
      "adjTempo": 66.1,
      "adjTempoRank": 271,
      "conference": "NEC",
      "rank": 299,
      "record": "13-17"
    },
    "St. John's": {
      "adjDef": 102.2,
      "adjDefRank": 122,
      "adjEM": -4.4,
      "adjOff": 97.8,
      "adjOffRank": 301,
      "adjTempo": 72.1,
      "adjTempoRank": 26,
      "conference": "BE",
      "rank": 211,
      "record": "8-24"
    },
    "Stanford": {
      "adjDef": 100.5,
      "adjDefRank": 94,
      "adjEM": 7.46,
      "adjOff": 108,
      "adjOffRank": 117,
      "adjTempo": 65.8,
      "adjTempoRank": 293,
      "conference": "P12",
      "rank": 101,
      "record": "15-15"
    },
    "Stephen F. Austin": {
      "adjDef": 96.6,
      "adjDefRank": 38,
      "adjEM": 15.62,
      "adjOff": 112.2,
      "adjOffRank": 57,
      "adjTempo": 67.3,
      "adjTempoRank": 217,
      "conference": "Slnd",
      "finish": "Round of 32",
      "rank": 41,
      "record": "28-6",
      "seed": 14
    },
    "Stetson": {
      "adjDef": 114.5,
      "adjDefRank": 330,
      "adjEM": -10.44,
      "adjOff": 104,
      "adjOffRank": 182,
      "adjTempo": 70.6,
      "adjTempoRank": 59,
      "conference": "ASun",
      "rank": 278,
      "record": "12-22"
    },
    "Stony Brook": {
      "adjDef": 99.1,
      "adjDefRank": 67,
      "adjEM": 8.13,
      "adjOff": 107.3,
      "adjOffRank": 132,
      "adjTempo": 67.2,
      "adjTempoRank": 228,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 94,
      "record": "26-7",
      "seed": 13
    },
    "Syracuse": {
      "adjDef": 94.1,
      "adjDefRank": 18,
      "adjEM": 18.57,
      "adjOff": 112.7,
      "adjOffRank": 50,
      "adjTempo": 65,
      "adjTempoRank": 314,
      "conference": "ACC",
      "finish": "Final Four",
      "rank": 27,
      "record": "23-14",
      "seed": 10
    },
    "TCU": {
      "adjDef": 98.5,
      "adjDefRank": 57,
      "adjEM": 3.02,
      "adjOff": 101.6,
      "adjOffRank": 226,
      "adjTempo": 69.6,
      "adjTempoRank": 97,
      "conference": "B12",
      "rank": 136,
      "record": "12-21"
    },
    "Temple": {
      "adjDef": 98.1,
      "adjDefRank": 52,
      "adjEM": 8.82,
      "adjOff": 107,
      "adjOffRank": 139,
      "adjTempo": 65.9,
      "adjTempoRank": 283,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 86,
      "record": "21-12",
      "seed": 10
    },
    "Tennessee": {
      "adjDef": 103.7,
      "adjDefRank": 150,
      "adjEM": 7.31,
      "adjOff": 111,
      "adjOffRank": 74,
      "adjTempo": 68.8,
      "adjTempoRank": 128,
      "conference": "SEC",
      "rank": 103,
      "record": "15-19"
    },
    "Tennessee Martin": {
      "adjDef": 109.2,
      "adjDefRank": 261,
      "adjEM": -4.26,
      "adjOff": 104.9,
      "adjOffRank": 169,
      "adjTempo": 66.6,
      "adjTempoRank": 253,
      "conference": "OVC",
      "rank": 209,
      "record": "20-15"
    },
    "Tennessee St.": {
      "adjDef": 101.6,
      "adjDefRank": 112,
      "adjEM": -0.11,
      "adjOff": 101.5,
      "adjOffRank": 231,
      "adjTempo": 68.1,
      "adjTempoRank": 164,
      "conference": "OVC",
      "rank": 164,
      "record": "20-11"
    },
    "Tennessee Tech": {
      "adjDef": 111.6,
      "adjDefRank": 299,
      "adjEM": -3.14,
      "adjOff": 108.5,
      "adjOffRank": 105,
      "adjTempo": 69.2,
      "adjTempoRank": 113,
      "conference": "OVC",
      "rank": 196,
      "record": "19-12"
    },
    "Texas": {
      "adjDef": 96.8,
      "adjDefRank": 40,
      "adjEM": 16.19,
      "adjOff": 113,
      "adjOffRank": 49,
      "adjTempo": 65.3,
      "adjTempoRank": 306,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 39,
      "record": "20-13",
      "seed": 6
    },
    "Texas A&M": {
      "adjDef": 92.5,
      "adjDefRank": 9,
      "adjEM": 21.26,
      "adjOff": 113.8,
      "adjOffRank": 37,
      "adjTempo": 67.1,
      "adjTempoRank": 230,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "28-9",
      "seed": 3
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 103.3,
      "adjDefRank": 144,
      "adjEM": -0.66,
      "adjOff": 102.6,
      "adjOffRank": 208,
      "adjTempo": 68.5,
      "adjTempoRank": 141,
      "conference": "Slnd",
      "rank": 170,
      "record": "25-8"
    },
    "Texas Southern": {
      "adjDef": 108.5,
      "adjDefRank": 244,
      "adjEM": -1.26,
      "adjOff": 107.3,
      "adjOffRank": 133,
      "adjTempo": 67.8,
      "adjTempoRank": 187,
      "conference": "SWAC",
      "rank": 178,
      "record": "18-15"
    },
    "Texas St.": {
      "adjDef": 103.9,
      "adjDefRank": 155,
      "adjEM": -4.43,
      "adjOff": 99.5,
      "adjOffRank": 275,
      "adjTempo": 63.6,
      "adjTempoRank": 340,
      "conference": "SB",
      "rank": 212,
      "record": "15-16"
    },
    "Texas Tech": {
      "adjDef": 99.4,
      "adjDefRank": 72,
      "adjEM": 13.78,
      "adjOff": 113.2,
      "adjOffRank": 46,
      "adjTempo": 66,
      "adjTempoRank": 273,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 54,
      "record": "19-13",
      "seed": 8
    },
    "The Citadel": {
      "adjDef": 117.6,
      "adjDefRank": 346,
      "adjEM": -14.51,
      "adjOff": 103.1,
      "adjOffRank": 201,
      "adjTempo": 80,
      "adjTempoRank": 1,
      "conference": "SC",
      "rank": 312,
      "record": "10-22"
    },
    "Toledo": {
      "adjDef": 104.5,
      "adjDefRank": 170,
      "adjEM": 4.02,
      "adjOff": 108.6,
      "adjOffRank": 103,
      "adjTempo": 68.9,
      "adjTempoRank": 127,
      "conference": "MAC",
      "rank": 123,
      "record": "17-15"
    },
    "Towson": {
      "adjDef": 104.4,
      "adjDefRank": 167,
      "adjEM": 0.98,
      "adjOff": 105.4,
      "adjOffRank": 165,
      "adjTempo": 65.7,
      "adjTempoRank": 297,
      "conference": "CAA",
      "rank": 152,
      "record": "20-13"
    },
    "Troy": {
      "adjDef": 109,
      "adjDefRank": 258,
      "adjEM": -10.69,
      "adjOff": 98.3,
      "adjOffRank": 291,
      "adjTempo": 68.4,
      "adjTempoRank": 151,
      "conference": "SB",
      "rank": 281,
      "record": "9-22"
    },
    "Tulane": {
      "adjDef": 101.3,
      "adjDefRank": 108,
      "adjEM": -3.39,
      "adjOff": 97.9,
      "adjOffRank": 298,
      "adjTempo": 67.7,
      "adjTempoRank": 196,
      "conference": "Amer",
      "rank": 200,
      "record": "12-22"
    },
    "Tulsa": {
      "adjDef": 99.7,
      "adjDefRank": 79,
      "adjEM": 11.5,
      "adjOff": 111.2,
      "adjOffRank": 69,
      "adjTempo": 68.4,
      "adjTempoRank": 149,
      "conference": "Amer",
      "finish": "First Four",
      "rank": 65,
      "record": "20-12",
      "seed": 11
    },
    "UAB": {
      "adjDef": 104.2,
      "adjDefRank": 160,
      "adjEM": 5.12,
      "adjOff": 109.3,
      "adjOffRank": 97,
      "adjTempo": 68.2,
      "adjTempoRank": 160,
      "conference": "CUSA",
      "rank": 116,
      "record": "26-7"
    },
    "UC Davis": {
      "adjDef": 100.5,
      "adjDefRank": 95,
      "adjEM": -6.64,
      "adjOff": 93.9,
      "adjOffRank": 328,
      "adjTempo": 65.9,
      "adjTempoRank": 279,
      "conference": "BW",
      "rank": 241,
      "record": "11-19"
    },
    "UC Irvine": {
      "adjDef": 96.9,
      "adjDefRank": 41,
      "adjEM": 9.1,
      "adjOff": 106,
      "adjOffRank": 157,
      "adjTempo": 67.5,
      "adjTempoRank": 205,
      "conference": "BW",
      "rank": 84,
      "record": "28-10"
    },
    "UC Riverside": {
      "adjDef": 106.1,
      "adjDefRank": 196,
      "adjEM": -5.94,
      "adjOff": 100.1,
      "adjOffRank": 258,
      "adjTempo": 67,
      "adjTempoRank": 234,
      "conference": "BW",
      "rank": 233,
      "record": "14-19"
    },
    "UC Santa Barbara": {
      "adjDef": 99.5,
      "adjDefRank": 74,
      "adjEM": 6.83,
      "adjOff": 106.3,
      "adjOffRank": 153,
      "adjTempo": 66.9,
      "adjTempoRank": 242,
      "conference": "BW",
      "rank": 105,
      "record": "19-14"
    },
    "UCF": {
      "adjDef": 104,
      "adjDefRank": 157,
      "adjEM": -1.89,
      "adjOff": 102.1,
      "adjOffRank": 215,
      "adjTempo": 67.9,
      "adjTempoRank": 179,
      "conference": "Amer",
      "rank": 185,
      "record": "12-18"
    },
    "UCLA": {
      "adjDef": 101.9,
      "adjDefRank": 118,
      "adjEM": 10.75,
      "adjOff": 112.6,
      "adjOffRank": 51,
      "adjTempo": 69.5,
      "adjTempoRank": 98,
      "conference": "P12",
      "rank": 69,
      "record": "15-17"
    },
    "UMBC": {
      "adjDef": 117.7,
      "adjDefRank": 347,
      "adjEM": -17.79,
      "adjOff": 99.9,
      "adjOffRank": 263,
      "adjTempo": 69.5,
      "adjTempoRank": 99,
      "conference": "AE",
      "rank": 334,
      "record": "7-25"
    },
    "UMKC": {
      "adjDef": 110.7,
      "adjDefRank": 283,
      "adjEM": -7.48,
      "adjOff": 103.2,
      "adjOffRank": 198,
      "adjTempo": 66.4,
      "adjTempoRank": 258,
      "conference": "WAC",
      "rank": 250,
      "record": "12-19"
    },
    "UMass Lowell": {
      "adjDef": 115.6,
      "adjDefRank": 337,
      "adjEM": -14.99,
      "adjOff": 100.6,
      "adjOffRank": 246,
      "adjTempo": 73.2,
      "adjTempoRank": 13,
      "conference": "AE",
      "rank": 315,
      "record": "11-18"
    },
    "UNC Asheville": {
      "adjDef": 100.1,
      "adjDefRank": 89,
      "adjEM": 3.44,
      "adjOff": 103.6,
      "adjOffRank": 188,
      "adjTempo": 70.1,
      "adjTempoRank": 81,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 131,
      "record": "22-12",
      "seed": 15
    },
    "UNC Greensboro": {
      "adjDef": 107.5,
      "adjDefRank": 221,
      "adjEM": -5.27,
      "adjOff": 102.2,
      "adjOffRank": 214,
      "adjTempo": 67.7,
      "adjTempoRank": 193,
      "conference": "SC",
      "rank": 220,
      "record": "15-19"
    },
    "UNC Wilmington": {
      "adjDef": 101.5,
      "adjDefRank": 110,
      "adjEM": 9.21,
      "adjOff": 110.7,
      "adjOffRank": 79,
      "adjTempo": 70.3,
      "adjTempoRank": 73,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 83,
      "record": "25-8",
      "seed": 13
    },
    "UNLV": {
      "adjDef": 98.3,
      "adjDefRank": 54,
      "adjEM": 5.09,
      "adjOff": 103.4,
      "adjOffRank": 192,
      "adjTempo": 73,
      "adjTempoRank": 14,
      "conference": "MWC",
      "rank": 117,
      "record": "18-15"
    },
    "USC": {
      "adjDef": 99.7,
      "adjDefRank": 80,
      "adjEM": 14.25,
      "adjOff": 113.9,
      "adjOffRank": 35,
      "adjTempo": 71,
      "adjTempoRank": 54,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 49,
      "record": "21-13",
      "seed": 8
    },
    "USC Upstate": {
      "adjDef": 116.6,
      "adjDefRank": 342,
      "adjEM": -16.03,
      "adjOff": 100.6,
      "adjOffRank": 247,
      "adjTempo": 66.2,
      "adjTempoRank": 263,
      "conference": "ASun",
      "rank": 322,
      "record": "10-22"
    },
    "UT Arlington": {
      "adjDef": 99.5,
      "adjDefRank": 75,
      "adjEM": 7.45,
      "adjOff": 106.9,
      "adjOffRank": 140,
      "adjTempo": 72.2,
      "adjTempoRank": 23,
      "conference": "SB",
      "rank": 102,
      "record": "24-11"
    },
    "UT Rio Grande Valley": {
      "adjDef": 117.4,
      "adjDefRank": 345,
      "adjEM": -22.36,
      "adjOff": 95.1,
      "adjOffRank": 322,
      "adjTempo": 67.5,
      "adjTempoRank": 209,
      "conference": "WAC",
      "rank": 348,
      "record": "8-22"
    },
    "UTEP": {
      "adjDef": 107.6,
      "adjDefRank": 222,
      "adjEM": -4.93,
      "adjOff": 102.6,
      "adjOffRank": 207,
      "adjTempo": 71.6,
      "adjTempoRank": 38,
      "conference": "CUSA",
      "rank": 215,
      "record": "19-14"
    },
    "UTSA": {
      "adjDef": 116.9,
      "adjDefRank": 343,
      "adjEM": -18.98,
      "adjOff": 97.9,
      "adjOffRank": 300,
      "adjTempo": 71.4,
      "adjTempoRank": 44,
      "conference": "CUSA",
      "rank": 338,
      "record": "5-27"
    },
    "Utah": {
      "adjDef": 98.8,
      "adjDefRank": 60,
      "adjEM": 16.79,
      "adjOff": 115.6,
      "adjOffRank": 26,
      "adjTempo": 65.3,
      "adjTempoRank": 305,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 33,
      "record": "27-9",
      "seed": 3
    },
    "Utah St.": {
      "adjDef": 108,
      "adjDefRank": 233,
      "adjEM": 3.25,
      "adjOff": 111.2,
      "adjOffRank": 68,
      "adjTempo": 67,
      "adjTempoRank": 238,
      "conference": "MWC",
      "rank": 134,
      "record": "16-15"
    },
    "Utah Valley": {
      "adjDef": 110.3,
      "adjDefRank": 278,
      "adjEM": -10.71,
      "adjOff": 99.6,
      "adjOffRank": 268,
      "adjTempo": 74.2,
      "adjTempoRank": 7,
      "conference": "WAC",
      "rank": 283,
      "record": "12-18"
    },
    "VCU": {
      "adjDef": 95,
      "adjDefRank": 24,
      "adjEM": 17.07,
      "adjOff": 112.1,
      "adjOffRank": 58,
      "adjTempo": 69.6,
      "adjTempoRank": 96,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 31,
      "record": "25-11",
      "seed": 10
    },
    "VMI": {
      "adjDef": 111.2,
      "adjDefRank": 293,
      "adjEM": -14.19,
      "adjOff": 97,
      "adjOffRank": 309,
      "adjTempo": 68.7,
      "adjTempoRank": 134,
      "conference": "SC",
      "rank": 309,
      "record": "9-21"
    },
    "Valparaiso": {
      "adjDef": 92,
      "adjDefRank": 8,
      "adjEM": 15.46,
      "adjOff": 107.5,
      "adjOffRank": 125,
      "adjTempo": 66.8,
      "adjTempoRank": 245,
      "conference": "Horz",
      "rank": 42,
      "record": "30-7"
    },
    "Vanderbilt": {
      "adjDef": 94.9,
      "adjDefRank": 23,
      "adjEM": 18.76,
      "adjOff": 113.6,
      "adjOffRank": 40,
      "adjTempo": 67.8,
      "adjTempoRank": 184,
      "conference": "SEC",
      "finish": "First Four",
      "rank": 25,
      "record": "19-14",
      "seed": 11
    },
    "Vermont": {
      "adjDef": 106.6,
      "adjDefRank": 203,
      "adjEM": 1.19,
      "adjOff": 107.8,
      "adjOffRank": 119,
      "adjTempo": 67.6,
      "adjTempoRank": 197,
      "conference": "AE",
      "rank": 151,
      "record": "23-14"
    },
    "Villanova": {
      "adjDef": 90.8,
      "adjDefRank": 5,
      "adjEM": 32.01,
      "adjOff": 122.8,
      "adjOffRank": 3,
      "adjTempo": 66,
      "adjTempoRank": 274,
      "conference": "BE",
      "finish": "Champion",
      "rank": 1,
      "record": "35-5",
      "seed": 2
    },
    "Virginia": {
      "adjDef": 91,
      "adjDefRank": 7,
      "adjEM": 29.64,
      "adjOff": 120.6,
      "adjOffRank": 8,
      "adjTempo": 61.3,
      "adjTempoRank": 348,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "29-8",
      "seed": 1
    },
    "Virginia Tech": {
      "adjDef": 98.9,
      "adjDefRank": 64,
      "adjEM": 11.68,
      "adjOff": 110.6,
      "adjOffRank": 81,
      "adjTempo": 69.5,
      "adjTempoRank": 102,
      "conference": "ACC",
      "rank": 63,
      "record": "20-15"
    },
    "Wagner": {
      "adjDef": 103.1,
      "adjDefRank": 138,
      "adjEM": -2.32,
      "adjOff": 100.7,
      "adjOffRank": 242,
      "adjTempo": 65.9,
      "adjTempoRank": 282,
      "conference": "NEC",
      "rank": 188,
      "record": "23-11"
    },
    "Wake Forest": {
      "adjDef": 102.5,
      "adjDefRank": 128,
      "adjEM": 4.78,
      "adjOff": 107.3,
      "adjOffRank": 131,
      "adjTempo": 71.4,
      "adjTempoRank": 45,
      "conference": "ACC",
      "rank": 118,
      "record": "11-20"
    },
    "Washington": {
      "adjDef": 99.2,
      "adjDefRank": 70,
      "adjEM": 11.22,
      "adjOff": 110.4,
      "adjOffRank": 87,
      "adjTempo": 77.5,
      "adjTempoRank": 2,
      "conference": "P12",
      "rank": 66,
      "record": "19-15"
    },
    "Washington St.": {
      "adjDef": 105.9,
      "adjDefRank": 188,
      "adjEM": -2.19,
      "adjOff": 103.7,
      "adjOffRank": 187,
      "adjTempo": 68.8,
      "adjTempoRank": 132,
      "conference": "P12",
      "rank": 186,
      "record": "9-22"
    },
    "Weber St.": {
      "adjDef": 100.9,
      "adjDefRank": 102,
      "adjEM": 2.45,
      "adjOff": 103.4,
      "adjOffRank": 191,
      "adjTempo": 66.8,
      "adjTempoRank": 243,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 142,
      "record": "26-9",
      "seed": 15
    },
    "West Virginia": {
      "adjDef": 90.9,
      "adjDefRank": 6,
      "adjEM": 24.45,
      "adjOff": 115.3,
      "adjOffRank": 27,
      "adjTempo": 69.5,
      "adjTempoRank": 104,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 8,
      "record": "26-9",
      "seed": 3
    },
    "Western Carolina": {
      "adjDef": 104.4,
      "adjDefRank": 165,
      "adjEM": -2.92,
      "adjOff": 101.5,
      "adjOffRank": 234,
      "adjTempo": 69.1,
      "adjTempoRank": 115,
      "conference": "SC",
      "rank": 194,
      "record": "16-18"
    },
    "Western Illinois": {
      "adjDef": 107.1,
      "adjDefRank": 214,
      "adjEM": -5.91,
      "adjOff": 101.2,
      "adjOffRank": 236,
      "adjTempo": 67.8,
      "adjTempoRank": 189,
      "conference": "Sum",
      "rank": 232,
      "record": "10-17"
    },
    "Western Kentucky": {
      "adjDef": 109.2,
      "adjDefRank": 263,
      "adjEM": -0.66,
      "adjOff": 108.6,
      "adjOffRank": 102,
      "adjTempo": 67,
      "adjTempoRank": 233,
      "conference": "CUSA",
      "rank": 169,
      "record": "18-16"
    },
    "Western Michigan": {
      "adjDef": 105.4,
      "adjDefRank": 184,
      "adjEM": -1.65,
      "adjOff": 103.7,
      "adjOffRank": 185,
      "adjTempo": 68.1,
      "adjTempoRank": 166,
      "conference": "MAC",
      "rank": 181,
      "record": "13-19"
    },
    "Wichita St.": {
      "adjDef": 88.7,
      "adjDefRank": 1,
      "adjEM": 22.53,
      "adjOff": 111.3,
      "adjOffRank": 67,
      "adjTempo": 66,
      "adjTempoRank": 276,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 13,
      "record": "26-9",
      "seed": 11
    },
    "William & Mary": {
      "adjDef": 104.5,
      "adjDefRank": 171,
      "adjEM": 8.79,
      "adjOff": 113.3,
      "adjOffRank": 44,
      "adjTempo": 68.3,
      "adjTempoRank": 153,
      "conference": "CAA",
      "rank": 87,
      "record": "20-11"
    },
    "Winthrop": {
      "adjDef": 107.7,
      "adjDefRank": 228,
      "adjEM": 0.69,
      "adjOff": 108.4,
      "adjOffRank": 107,
      "adjTempo": 72.8,
      "adjTempoRank": 18,
      "conference": "BSth",
      "rank": 157,
      "record": "23-9"
    },
    "Wisconsin": {
      "adjDef": 93.6,
      "adjDefRank": 13,
      "adjEM": 16.55,
      "adjOff": 110.1,
      "adjOffRank": 89,
      "adjTempo": 63.3,
      "adjTempoRank": 344,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 38,
      "record": "22-13",
      "seed": 7
    },
    "Wofford": {
      "adjDef": 108.5,
      "adjDefRank": 247,
      "adjEM": -2.22,
      "adjOff": 106.3,
      "adjOffRank": 152,
      "adjTempo": 65.2,
      "adjTempoRank": 310,
      "conference": "SC",
      "rank": 187,
      "record": "15-17"
    },
    "Wright St.": {
      "adjDef": 99.4,
      "adjDefRank": 71,
      "adjEM": 2.01,
      "adjOff": 101.4,
      "adjOffRank": 235,
      "adjTempo": 65.1,
      "adjTempoRank": 312,
      "conference": "Horz",
      "rank": 146,
      "record": "22-13"
    },
    "Wyoming": {
      "adjDef": 108.5,
      "adjDefRank": 245,
      "adjEM": -0.19,
      "adjOff": 108.3,
      "adjOffRank": 108,
      "adjTempo": 64.6,
      "adjTempoRank": 323,
      "conference": "MWC",
      "rank": 166,
      "record": "14-18"
    },
    "Xavier": {
      "adjDef": 94.6,
      "adjDefRank": 22,
      "adjEM": 22.52,
      "adjOff": 117.1,
      "adjOffRank": 18,
      "adjTempo": 71.9,
      "adjTempoRank": 32,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 14,
      "record": "28-6",
      "seed": 2
    },
    "Yale": {
      "adjDef": 96,
      "adjDefRank": 34,
      "adjEM": 14.33,
      "adjOff": 110.4,
      "adjOffRank": 88,
      "adjTempo": 66,
      "adjTempoRank": 275,
      "conference": "Ivy",
      "finish": "Round of 32",
      "rank": 47,
      "record": "23-7",
      "seed": 12
    },
    "Youngstown St.": {
      "adjDef": 113.9,
      "adjDefRank": 326,
      "adjEM": -10.7,
      "adjOff": 103.2,
      "adjOffRank": 200,
      "adjTempo": 72.4,
      "adjTempoRank": 20,
      "conference": "Horz",
      "rank": 282,
      "record": "11-21"
    }
  },
  "2017": {
    "Abilene Christian": {
      "adjDef": 108.8,
      "adjDefRank": 252,
      "adjEM": -11.86,
      "adjOff": 97,
      "adjOffRank": 306,
      "adjTempo": 65.8,
      "adjTempoRank": 284,
      "conference": "Slnd",
      "rank": 298,
      "record": "13-16"
    },
    "Air Force": {
      "adjDef": 109.5,
      "adjDefRank": 270,
      "adjEM": -5.22,
      "adjOff": 104.3,
      "adjOffRank": 175,
      "adjTempo": 67.1,
      "adjTempoRank": 232,
      "conference": "MWC",
      "rank": 222,
      "record": "12-21"
    },
    "Akron": {
      "adjDef": 106.4,
      "adjDefRank": 209,
      "adjEM": 6.1,
      "adjOff": 112.6,
      "adjOffRank": 47,
      "adjTempo": 66.3,
      "adjTempoRank": 266,
      "conference": "MAC",
      "rank": 100,
      "record": "27-9"
    },
    "Alabama": {
      "adjDef": 92.2,
      "adjDefRank": 10,
      "adjEM": 13.08,
      "adjOff": 105.3,
      "adjOffRank": 152,
      "adjTempo": 65.1,
      "adjTempoRank": 302,
      "conference": "SEC",
      "rank": 56,
      "record": "19-15"
    },
    "Alabama A&M": {
      "adjDef": 121.8,
      "adjDefRank": 350,
      "adjEM": -30.34,
      "adjOff": 91.4,
      "adjOffRank": 342,
      "adjTempo": 64.3,
      "adjTempoRank": 316,
      "conference": "SWAC",
      "rank": 351,
      "record": "2-27"
    },
    "Alabama St.": {
      "adjDef": 112.8,
      "adjDefRank": 316,
      "adjEM": -21.65,
      "adjOff": 91.1,
      "adjOffRank": 345,
      "adjTempo": 68.4,
      "adjTempoRank": 164,
      "conference": "SWAC",
      "rank": 342,
      "record": "8-23"
    },
    "Albany": {
      "adjDef": 104.9,
      "adjDefRank": 180,
      "adjEM": 2.66,
      "adjOff": 107.5,
      "adjOffRank": 116,
      "adjTempo": 64.2,
      "adjTempoRank": 320,
      "conference": "AE",
      "rank": 129,
      "record": "21-14"
    },
    "Alcorn St.": {
      "adjDef": 106,
      "adjDefRank": 198,
      "adjEM": -10.5,
      "adjOff": 95.5,
      "adjOffRank": 321,
      "adjTempo": 67.8,
      "adjTempoRank": 201,
      "conference": "SWAC",
      "rank": 285,
      "record": "18-14"
    },
    "American": {
      "adjDef": 108.1,
      "adjDefRank": 240,
      "adjEM": -12.94,
      "adjOff": 95.2,
      "adjOffRank": 325,
      "adjTempo": 64.3,
      "adjTempoRank": 318,
      "conference": "Pat",
      "rank": 307,
      "record": "8-22"
    },
    "Appalachian St.": {
      "adjDef": 109.2,
      "adjDefRank": 260,
      "adjEM": -9.61,
      "adjOff": 99.5,
      "adjOffRank": 268,
      "adjTempo": 70.5,
      "adjTempoRank": 62,
      "conference": "SB",
      "rank": 274,
      "record": "9-21"
    },
    "Arizona": {
      "adjDef": 95.1,
      "adjDefRank": 29,
      "adjEM": 23.31,
      "adjOff": 118.4,
      "adjOffRank": 15,
      "adjTempo": 66,
      "adjTempoRank": 277,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 18,
      "record": "32-5",
      "seed": 2
    },
    "Arizona St.": {
      "adjDef": 109.1,
      "adjDefRank": 257,
      "adjEM": 2.81,
      "adjOff": 111.9,
      "adjOffRank": 55,
      "adjTempo": 70,
      "adjTempoRank": 79,
      "conference": "P12",
      "rank": 126,
      "record": "15-18"
    },
    "Arkansas": {
      "adjDef": 99.8,
      "adjDefRank": 76,
      "adjEM": 16.25,
      "adjOff": 116.1,
      "adjOffRank": 28,
      "adjTempo": 69.7,
      "adjTempoRank": 87,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 35,
      "record": "26-10",
      "seed": 8
    },
    "Arkansas Pine Bluff": {
      "adjDef": 111,
      "adjDefRank": 300,
      "adjEM": -25.28,
      "adjOff": 85.7,
      "adjOffRank": 351,
      "adjTempo": 67.6,
      "adjTempoRank": 209,
      "conference": "SWAC",
      "rank": 348,
      "record": "7-25"
    },
    "Arkansas St.": {
      "adjDef": 103.3,
      "adjDefRank": 146,
      "adjEM": 2.99,
      "adjOff": 106.3,
      "adjOffRank": 138,
      "adjTempo": 67.3,
      "adjTempoRank": 217,
      "conference": "SB",
      "rank": 124,
      "record": "20-12"
    },
    "Army": {
      "adjDef": 104.7,
      "adjDefRank": 175,
      "adjEM": -6.04,
      "adjOff": 98.6,
      "adjOffRank": 281,
      "adjTempo": 71.4,
      "adjTempoRank": 38,
      "conference": "Pat",
      "rank": 230,
      "record": "13-19"
    },
    "Auburn": {
      "adjDef": 103.3,
      "adjDefRank": 147,
      "adjEM": 8.52,
      "adjOff": 111.9,
      "adjOffRank": 54,
      "adjTempo": 73.6,
      "adjTempoRank": 10,
      "conference": "SEC",
      "rank": 82,
      "record": "18-14"
    },
    "Austin Peay": {
      "adjDef": 117.3,
      "adjDefRank": 342,
      "adjEM": -11.08,
      "adjOff": 106.2,
      "adjOffRank": 140,
      "adjTempo": 70,
      "adjTempoRank": 80,
      "conference": "OVC",
      "rank": 289,
      "record": "11-19"
    },
    "BYU": {
      "adjDef": 100.5,
      "adjDefRank": 87,
      "adjEM": 8.66,
      "adjOff": 109.1,
      "adjOffRank": 86,
      "adjTempo": 75.6,
      "adjTempoRank": 4,
      "conference": "WCC",
      "rank": 80,
      "record": "22-12"
    },
    "Ball St.": {
      "adjDef": 105.3,
      "adjDefRank": 188,
      "adjEM": -0.62,
      "adjOff": 104.7,
      "adjOffRank": 164,
      "adjTempo": 71.1,
      "adjTempoRank": 50,
      "conference": "MAC",
      "rank": 180,
      "record": "21-13"
    },
    "Baylor": {
      "adjDef": 92.9,
      "adjDefRank": 16,
      "adjEM": 24.23,
      "adjOff": 117.1,
      "adjOffRank": 23,
      "adjTempo": 63.7,
      "adjTempoRank": 328,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "27-8",
      "seed": 3
    },
    "Belmont": {
      "adjDef": 102.7,
      "adjDefRank": 125,
      "adjEM": 8.17,
      "adjOff": 110.8,
      "adjOffRank": 65,
      "adjTempo": 68,
      "adjTempoRank": 188,
      "conference": "OVC",
      "rank": 83,
      "record": "23-7"
    },
    "Bethune Cookman": {
      "adjDef": 115.4,
      "adjDefRank": 335,
      "adjEM": -20.45,
      "adjOff": 95,
      "adjOffRank": 328,
      "adjTempo": 68.3,
      "adjTempoRank": 177,
      "conference": "MEAC",
      "rank": 340,
      "record": "10-22"
    },
    "Binghamton": {
      "adjDef": 110,
      "adjDefRank": 282,
      "adjEM": -13.77,
      "adjOff": 96.3,
      "adjOffRank": 314,
      "adjTempo": 65.9,
      "adjTempoRank": 279,
      "conference": "AE",
      "rank": 314,
      "record": "12-20"
    },
    "Boise St.": {
      "adjDef": 102.2,
      "adjDefRank": 112,
      "adjEM": 7,
      "adjOff": 109.2,
      "adjOffRank": 85,
      "adjTempo": 69.5,
      "adjTempoRank": 97,
      "conference": "MWC",
      "rank": 94,
      "record": "20-12"
    },
    "Boston College": {
      "adjDef": 104.2,
      "adjDefRank": 163,
      "adjEM": -0.27,
      "adjOff": 103.9,
      "adjOffRank": 186,
      "adjTempo": 71.1,
      "adjTempoRank": 46,
      "conference": "ACC",
      "rank": 173,
      "record": "9-23"
    },
    "Boston University": {
      "adjDef": 103.4,
      "adjDefRank": 149,
      "adjEM": -1.68,
      "adjOff": 101.7,
      "adjOffRank": 226,
      "adjTempo": 69.6,
      "adjTempoRank": 94,
      "conference": "Pat",
      "rank": 192,
      "record": "18-14"
    },
    "Bowling Green": {
      "adjDef": 107.2,
      "adjDefRank": 220,
      "adjEM": -7.16,
      "adjOff": 100.1,
      "adjOffRank": 257,
      "adjTempo": 70,
      "adjTempoRank": 82,
      "conference": "MAC",
      "rank": 249,
      "record": "13-19"
    },
    "Bradley": {
      "adjDef": 102.7,
      "adjDefRank": 130,
      "adjEM": -4.29,
      "adjOff": 98.4,
      "adjOffRank": 283,
      "adjTempo": 67.7,
      "adjTempoRank": 205,
      "conference": "MVC",
      "rank": 217,
      "record": "13-20"
    },
    "Brown": {
      "adjDef": 114,
      "adjDefRank": 325,
      "adjEM": -9.84,
      "adjOff": 104.1,
      "adjOffRank": 180,
      "adjTempo": 71.3,
      "adjTempoRank": 42,
      "conference": "Ivy",
      "rank": 277,
      "record": "13-17"
    },
    "Bryant": {
      "adjDef": 112,
      "adjDefRank": 307,
      "adjEM": -10.27,
      "adjOff": 101.7,
      "adjOffRank": 225,
      "adjTempo": 69.1,
      "adjTempoRank": 110,
      "conference": "NEC",
      "rank": 281,
      "record": "12-20"
    },
    "Bucknell": {
      "adjDef": 100.3,
      "adjDefRank": 83,
      "adjEM": 9.38,
      "adjOff": 109.7,
      "adjOffRank": 78,
      "adjTempo": 70,
      "adjTempoRank": 83,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 76,
      "record": "26-9",
      "seed": 13
    },
    "Buffalo": {
      "adjDef": 102.2,
      "adjDefRank": 114,
      "adjEM": 3.14,
      "adjOff": 105.3,
      "adjOffRank": 153,
      "adjTempo": 71.4,
      "adjTempoRank": 37,
      "conference": "MAC",
      "rank": 122,
      "record": "17-15"
    },
    "Butler": {
      "adjDef": 97,
      "adjDefRank": 49,
      "adjEM": 20.76,
      "adjOff": 117.7,
      "adjOffRank": 20,
      "adjTempo": 65.9,
      "adjTempoRank": 278,
      "conference": "BE",
      "finish": "Sweet 16",
      "rank": 25,
      "record": "25-9",
      "seed": 4
    },
    "Cal Poly": {
      "adjDef": 113,
      "adjDefRank": 320,
      "adjEM": -12.93,
      "adjOff": 100.1,
      "adjOffRank": 256,
      "adjTempo": 65.3,
      "adjTempoRank": 301,
      "conference": "BW",
      "rank": 306,
      "record": "11-20"
    },
    "Cal St. Bakersfield": {
      "adjDef": 93.7,
      "adjDefRank": 20,
      "adjEM": 7.24,
      "adjOff": 100.9,
      "adjOffRank": 244,
      "adjTempo": 67.2,
      "adjTempoRank": 221,
      "conference": "WAC",
      "rank": 90,
      "record": "25-10"
    },
    "Cal St. Fullerton": {
      "adjDef": 105.5,
      "adjDefRank": 192,
      "adjEM": -8.54,
      "adjOff": 97,
      "adjOffRank": 305,
      "adjTempo": 68.8,
      "adjTempoRank": 129,
      "conference": "BW",
      "rank": 261,
      "record": "17-15"
    },
    "Cal St. Northridge": {
      "adjDef": 114.6,
      "adjDefRank": 332,
      "adjEM": -10.47,
      "adjOff": 104.1,
      "adjOffRank": 181,
      "adjTempo": 72.7,
      "adjTempoRank": 16,
      "conference": "BW",
      "rank": 284,
      "record": "11-19"
    },
    "California": {
      "adjDef": 92.7,
      "adjDefRank": 14,
      "adjEM": 12.13,
      "adjOff": 104.9,
      "adjOffRank": 160,
      "adjTempo": 65.7,
      "adjTempoRank": 290,
      "conference": "P12",
      "rank": 62,
      "record": "21-13"
    },
    "Campbell": {
      "adjDef": 110.4,
      "adjDefRank": 290,
      "adjEM": -10.04,
      "adjOff": 100.4,
      "adjOffRank": 251,
      "adjTempo": 65.7,
      "adjTempoRank": 289,
      "conference": "BSth",
      "rank": 278,
      "record": "19-18"
    },
    "Canisius": {
      "adjDef": 109.9,
      "adjDefRank": 281,
      "adjEM": -1.27,
      "adjOff": 108.7,
      "adjOffRank": 97,
      "adjTempo": 69.6,
      "adjTempoRank": 95,
      "conference": "MAAC",
      "rank": 187,
      "record": "18-16"
    },
    "Central Arkansas": {
      "adjDef": 117.5,
      "adjDefRank": 344,
      "adjEM": -13.47,
      "adjOff": 104,
      "adjOffRank": 184,
      "adjTempo": 71.1,
      "adjTempoRank": 49,
      "conference": "Slnd",
      "rank": 308,
      "record": "8-24"
    },
    "Central Connecticut": {
      "adjDef": 113.9,
      "adjDefRank": 324,
      "adjEM": -20.99,
      "adjOff": 93,
      "adjOffRank": 334,
      "adjTempo": 64.9,
      "adjTempoRank": 307,
      "conference": "NEC",
      "rank": 341,
      "record": "6-23"
    },
    "Central Michigan": {
      "adjDef": 117.6,
      "adjDefRank": 345,
      "adjEM": -6.01,
      "adjOff": 111.6,
      "adjOffRank": 56,
      "adjTempo": 75.3,
      "adjTempoRank": 5,
      "conference": "MAC",
      "rank": 229,
      "record": "16-16"
    },
    "Charleston Southern": {
      "adjDef": 116.7,
      "adjDefRank": 341,
      "adjEM": -13.49,
      "adjOff": 103.2,
      "adjOffRank": 192,
      "adjTempo": 68.2,
      "adjTempoRank": 178,
      "conference": "BSth",
      "rank": 309,
      "record": "12-19"
    },
    "Charlotte": {
      "adjDef": 109.5,
      "adjDefRank": 269,
      "adjEM": -6.35,
      "adjOff": 103.1,
      "adjOffRank": 194,
      "adjTempo": 72.8,
      "adjTempoRank": 15,
      "conference": "CUSA",
      "rank": 237,
      "record": "13-17"
    },
    "Chattanooga": {
      "adjDef": 102.3,
      "adjDefRank": 117,
      "adjEM": 4.32,
      "adjOff": 106.6,
      "adjOffRank": 134,
      "adjTempo": 65.5,
      "adjTempoRank": 295,
      "conference": "SC",
      "rank": 114,
      "record": "19-12"
    },
    "Chicago St.": {
      "adjDef": 110.9,
      "adjDefRank": 298,
      "adjEM": -18.6,
      "adjOff": 92.3,
      "adjOffRank": 338,
      "adjTempo": 67.1,
      "adjTempoRank": 231,
      "conference": "WAC",
      "rank": 335,
      "record": "6-26"
    },
    "Cincinnati": {
      "adjDef": 92.8,
      "adjDefRank": 15,
      "adjEM": 22.18,
      "adjOff": 114.9,
      "adjOffRank": 34,
      "adjTempo": 63.5,
      "adjTempoRank": 330,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 23,
      "record": "30-6",
      "seed": 6
    },
    "Clemson": {
      "adjDef": 100.5,
      "adjDefRank": 86,
      "adjEM": 14.46,
      "adjOff": 114.9,
      "adjOffRank": 35,
      "adjTempo": 66.4,
      "adjTempoRank": 261,
      "conference": "ACC",
      "rank": 45,
      "record": "17-16"
    },
    "Cleveland St.": {
      "adjDef": 103.1,
      "adjDefRank": 139,
      "adjEM": -6.65,
      "adjOff": 96.5,
      "adjOffRank": 312,
      "adjTempo": 65.4,
      "adjTempoRank": 297,
      "conference": "Horz",
      "rank": 242,
      "record": "9-22"
    },
    "Coastal Carolina": {
      "adjDef": 105.4,
      "adjDefRank": 190,
      "adjEM": -3.56,
      "adjOff": 101.8,
      "adjOffRank": 220,
      "adjTempo": 68.8,
      "adjTempoRank": 128,
      "conference": "SB",
      "rank": 207,
      "record": "20-19"
    },
    "Colgate": {
      "adjDef": 107.6,
      "adjDefRank": 231,
      "adjEM": -8.85,
      "adjOff": 98.8,
      "adjOffRank": 276,
      "adjTempo": 65.1,
      "adjTempoRank": 303,
      "conference": "Pat",
      "rank": 268,
      "record": "10-22"
    },
    "College of Charleston": {
      "adjDef": 98.9,
      "adjDefRank": 66,
      "adjEM": 7.25,
      "adjOff": 106.2,
      "adjOffRank": 142,
      "adjTempo": 64.5,
      "adjTempoRank": 314,
      "conference": "CAA",
      "rank": 89,
      "record": "25-10"
    },
    "Colorado": {
      "adjDef": 102.3,
      "adjDefRank": 116,
      "adjEM": 10.3,
      "adjOff": 112.6,
      "adjOffRank": 45,
      "adjTempo": 67.7,
      "adjTempoRank": 202,
      "conference": "P12",
      "rank": 72,
      "record": "19-15"
    },
    "Colorado St.": {
      "adjDef": 100.9,
      "adjDefRank": 94,
      "adjEM": 7.67,
      "adjOff": 108.5,
      "adjOffRank": 99,
      "adjTempo": 66.7,
      "adjTempoRank": 251,
      "conference": "MWC",
      "rank": 86,
      "record": "24-12"
    },
    "Columbia": {
      "adjDef": 107.3,
      "adjDefRank": 223,
      "adjEM": -6.26,
      "adjOff": 101.1,
      "adjOffRank": 238,
      "adjTempo": 68.9,
      "adjTempoRank": 123,
      "conference": "Ivy",
      "rank": 235,
      "record": "11-16"
    },
    "Connecticut": {
      "adjDef": 98.7,
      "adjDefRank": 63,
      "adjEM": 6.53,
      "adjOff": 105.2,
      "adjOffRank": 154,
      "adjTempo": 65,
      "adjTempoRank": 304,
      "conference": "Amer",
      "rank": 96,
      "record": "16-17"
    },
    "Coppin St.": {
      "adjDef": 115.6,
      "adjDefRank": 336,
      "adjEM": -21.99,
      "adjOff": 93.6,
      "adjOffRank": 331,
      "adjTempo": 68.8,
      "adjTempoRank": 135,
      "conference": "MEAC",
      "rank": 343,
      "record": "8-24"
    },
    "Cornell": {
      "adjDef": 108.4,
      "adjDefRank": 245,
      "adjEM": -8.67,
      "adjOff": 99.7,
      "adjOffRank": 265,
      "adjTempo": 69.7,
      "adjTempoRank": 89,
      "conference": "Ivy",
      "rank": 263,
      "record": "8-21"
    },
    "Creighton": {
      "adjDef": 96.5,
      "adjDefRank": 46,
      "adjEM": 18.83,
      "adjOff": 115.3,
      "adjOffRank": 32,
      "adjTempo": 71.2,
      "adjTempoRank": 45,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 28,
      "record": "25-10",
      "seed": 6
    },
    "Dartmouth": {
      "adjDef": 112.1,
      "adjDefRank": 309,
      "adjEM": -12.89,
      "adjOff": 99.2,
      "adjOffRank": 270,
      "adjTempo": 67.4,
      "adjTempoRank": 216,
      "conference": "Ivy",
      "rank": 305,
      "record": "7-20"
    },
    "Davidson": {
      "adjDef": 100.7,
      "adjDefRank": 92,
      "adjEM": 7.7,
      "adjOff": 108.4,
      "adjOffRank": 103,
      "adjTempo": 68.5,
      "adjTempoRank": 161,
      "conference": "A10",
      "rank": 85,
      "record": "17-15"
    },
    "Dayton": {
      "adjDef": 96.3,
      "adjDefRank": 43,
      "adjEM": 15.57,
      "adjOff": 111.9,
      "adjOffRank": 53,
      "adjTempo": 68.4,
      "adjTempoRank": 163,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 39,
      "record": "24-8",
      "seed": 7
    },
    "DePaul": {
      "adjDef": 104.5,
      "adjDefRank": 172,
      "adjEM": -1.05,
      "adjOff": 103.5,
      "adjOffRank": 190,
      "adjTempo": 67.7,
      "adjTempoRank": 207,
      "conference": "BE",
      "rank": 183,
      "record": "9-23"
    },
    "Delaware": {
      "adjDef": 108.5,
      "adjDefRank": 248,
      "adjEM": -11.12,
      "adjOff": 97.3,
      "adjOffRank": 298,
      "adjTempo": 64.8,
      "adjTempoRank": 310,
      "conference": "CAA",
      "rank": 290,
      "record": "13-20"
    },
    "Delaware St.": {
      "adjDef": 114.3,
      "adjDefRank": 328,
      "adjEM": -20.1,
      "adjOff": 94.2,
      "adjOffRank": 330,
      "adjTempo": 65.8,
      "adjTempoRank": 288,
      "conference": "MEAC",
      "rank": 339,
      "record": "10-22"
    },
    "Denver": {
      "adjDef": 109.2,
      "adjDefRank": 261,
      "adjEM": -1.41,
      "adjOff": 107.7,
      "adjOffRank": 112,
      "adjTempo": 66.8,
      "adjTempoRank": 246,
      "conference": "Sum",
      "rank": 189,
      "record": "16-14"
    },
    "Detroit": {
      "adjDef": 114.5,
      "adjDefRank": 331,
      "adjEM": -12.21,
      "adjOff": 102.3,
      "adjOffRank": 213,
      "adjTempo": 72.6,
      "adjTempoRank": 18,
      "conference": "Horz",
      "rank": 302,
      "record": "8-23"
    },
    "Drake": {
      "adjDef": 111.1,
      "adjDefRank": 302,
      "adjEM": -8.17,
      "adjOff": 103,
      "adjOffRank": 200,
      "adjTempo": 69.7,
      "adjTempoRank": 92,
      "conference": "MVC",
      "rank": 258,
      "record": "7-24"
    },
    "Drexel": {
      "adjDef": 110.1,
      "adjDefRank": 283,
      "adjEM": -7.19,
      "adjOff": 102.9,
      "adjOffRank": 202,
      "adjTempo": 70.3,
      "adjTempoRank": 68,
      "conference": "CAA",
      "rank": 250,
      "record": "9-23"
    },
    "Duke": {
      "adjDef": 96.8,
      "adjDefRank": 47,
      "adjEM": 24.17,
      "adjOff": 121,
      "adjOffRank": 6,
      "adjTempo": 68.3,
      "adjTempoRank": 175,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 14,
      "record": "28-9",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 107.3,
      "adjDefRank": 221,
      "adjEM": -5.32,
      "adjOff": 101.9,
      "adjOffRank": 219,
      "adjTempo": 70,
      "adjTempoRank": 77,
      "conference": "A10",
      "rank": 224,
      "record": "10-22"
    },
    "East Carolina": {
      "adjDef": 98.5,
      "adjDefRank": 62,
      "adjEM": -1.49,
      "adjOff": 97,
      "adjOffRank": 304,
      "adjTempo": 66.9,
      "adjTempoRank": 239,
      "conference": "Amer",
      "rank": 191,
      "record": "15-18"
    },
    "East Tennessee St.": {
      "adjDef": 96.9,
      "adjDefRank": 48,
      "adjEM": 11.1,
      "adjOff": 108,
      "adjOffRank": 106,
      "adjTempo": 70.1,
      "adjTempoRank": 74,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 67,
      "record": "27-8",
      "seed": 13
    },
    "Eastern Illinois": {
      "adjDef": 105,
      "adjDefRank": 182,
      "adjEM": -4.83,
      "adjOff": 100.1,
      "adjOffRank": 255,
      "adjTempo": 68.6,
      "adjTempoRank": 149,
      "conference": "OVC",
      "rank": 220,
      "record": "14-15"
    },
    "Eastern Kentucky": {
      "adjDef": 107.9,
      "adjDefRank": 236,
      "adjEM": -9.44,
      "adjOff": 98.4,
      "adjOffRank": 285,
      "adjTempo": 67.6,
      "adjTempoRank": 213,
      "conference": "OVC",
      "rank": 272,
      "record": "12-19"
    },
    "Eastern Michigan": {
      "adjDef": 104.9,
      "adjDefRank": 179,
      "adjEM": 1.2,
      "adjOff": 106.1,
      "adjOffRank": 146,
      "adjTempo": 68.1,
      "adjTempoRank": 184,
      "conference": "MAC",
      "rank": 148,
      "record": "16-17"
    },
    "Eastern Washington": {
      "adjDef": 110.8,
      "adjDefRank": 296,
      "adjEM": -1.25,
      "adjOff": 109.6,
      "adjOffRank": 82,
      "adjTempo": 66.7,
      "adjTempoRank": 256,
      "conference": "BSky",
      "rank": 186,
      "record": "22-12"
    },
    "Elon": {
      "adjDef": 102.2,
      "adjDefRank": 113,
      "adjEM": 2.19,
      "adjOff": 104.4,
      "adjOffRank": 170,
      "adjTempo": 67.8,
      "adjTempoRank": 200,
      "conference": "CAA",
      "rank": 136,
      "record": "18-14"
    },
    "Evansville": {
      "adjDef": 103.2,
      "adjDefRank": 141,
      "adjEM": 0.83,
      "adjOff": 104.1,
      "adjOffRank": 182,
      "adjTempo": 66.8,
      "adjTempoRank": 248,
      "conference": "MVC",
      "rank": 154,
      "record": "16-17"
    },
    "FIU": {
      "adjDef": 112.5,
      "adjDefRank": 313,
      "adjEM": -9.62,
      "adjOff": 102.9,
      "adjOffRank": 201,
      "adjTempo": 65,
      "adjTempoRank": 306,
      "conference": "CUSA",
      "rank": 276,
      "record": "7-24"
    },
    "Fairfield": {
      "adjDef": 103.1,
      "adjDefRank": 138,
      "adjEM": -3.3,
      "adjOff": 99.8,
      "adjOffRank": 263,
      "adjTempo": 69.2,
      "adjTempoRank": 108,
      "conference": "MAAC",
      "rank": 204,
      "record": "16-15"
    },
    "Fairleigh Dickinson": {
      "adjDef": 111.9,
      "adjDefRank": 305,
      "adjEM": -8.78,
      "adjOff": 103.1,
      "adjOffRank": 196,
      "adjTempo": 69,
      "adjTempoRank": 115,
      "conference": "NEC",
      "rank": 267,
      "record": "11-19"
    },
    "Florida": {
      "adjDef": 89.5,
      "adjDefRank": 5,
      "adjEM": 27.5,
      "adjOff": 116.9,
      "adjOffRank": 25,
      "adjTempo": 69,
      "adjTempoRank": 117,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 5,
      "record": "27-9",
      "seed": 4
    },
    "Florida A&M": {
      "adjDef": 116.2,
      "adjDefRank": 339,
      "adjEM": -22.61,
      "adjOff": 93.6,
      "adjOffRank": 332,
      "adjTempo": 69.3,
      "adjTempoRank": 104,
      "conference": "MEAC",
      "rank": 345,
      "record": "6-23"
    },
    "Florida Atlantic": {
      "adjDef": 107.9,
      "adjDefRank": 237,
      "adjEM": -9.33,
      "adjOff": 98.6,
      "adjOffRank": 282,
      "adjTempo": 68.9,
      "adjTempoRank": 124,
      "conference": "CUSA",
      "rank": 271,
      "record": "10-20"
    },
    "Florida Gulf Coast": {
      "adjDef": 104.4,
      "adjDefRank": 168,
      "adjEM": 5.07,
      "adjOff": 109.4,
      "adjOffRank": 83,
      "adjTempo": 65.9,
      "adjTempoRank": 280,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 108,
      "record": "26-8",
      "seed": 14
    },
    "Florida St.": {
      "adjDef": 95.5,
      "adjDefRank": 31,
      "adjEM": 20.06,
      "adjOff": 115.5,
      "adjOffRank": 31,
      "adjTempo": 71.3,
      "adjTempoRank": 41,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 26,
      "record": "26-9",
      "seed": 3
    },
    "Fordham": {
      "adjDef": 100,
      "adjDefRank": 82,
      "adjEM": -3.05,
      "adjOff": 97,
      "adjOffRank": 307,
      "adjTempo": 64.3,
      "adjTempoRank": 319,
      "conference": "A10",
      "rank": 202,
      "record": "13-19"
    },
    "Fort Wayne": {
      "adjDef": 108,
      "adjDefRank": 238,
      "adjEM": 0.84,
      "adjOff": 108.8,
      "adjOffRank": 94,
      "adjTempo": 71.6,
      "adjTempoRank": 34,
      "conference": "Sum",
      "rank": 153,
      "record": "20-13"
    },
    "Fresno St.": {
      "adjDef": 98.2,
      "adjDefRank": 60,
      "adjEM": 6.37,
      "adjOff": 104.6,
      "adjOffRank": 167,
      "adjTempo": 68.5,
      "adjTempoRank": 158,
      "conference": "MWC",
      "rank": 98,
      "record": "20-13"
    },
    "Furman": {
      "adjDef": 101.7,
      "adjDefRank": 106,
      "adjEM": 5.21,
      "adjOff": 107,
      "adjOffRank": 128,
      "adjTempo": 66.1,
      "adjTempoRank": 273,
      "conference": "SC",
      "rank": 106,
      "record": "23-12"
    },
    "Gardner Webb": {
      "adjDef": 103.5,
      "adjDefRank": 154,
      "adjEM": -0.76,
      "adjOff": 102.7,
      "adjOffRank": 205,
      "adjTempo": 70.8,
      "adjTempoRank": 54,
      "conference": "BSth",
      "rank": 181,
      "record": "19-14"
    },
    "George Mason": {
      "adjDef": 104.2,
      "adjDefRank": 164,
      "adjEM": 3.14,
      "adjOff": 107.3,
      "adjOffRank": 120,
      "adjTempo": 68.8,
      "adjTempoRank": 131,
      "conference": "A10",
      "rank": 123,
      "record": "20-14"
    },
    "George Washington": {
      "adjDef": 105.3,
      "adjDefRank": 189,
      "adjEM": 2.32,
      "adjOff": 107.7,
      "adjOffRank": 114,
      "adjTempo": 65.8,
      "adjTempoRank": 286,
      "conference": "A10",
      "rank": 132,
      "record": "20-15"
    },
    "Georgetown": {
      "adjDef": 97.7,
      "adjDefRank": 58,
      "adjEM": 10.72,
      "adjOff": 108.4,
      "adjOffRank": 102,
      "adjTempo": 68.6,
      "adjTempoRank": 147,
      "conference": "BE",
      "rank": 69,
      "record": "14-18"
    },
    "Georgia": {
      "adjDef": 96.3,
      "adjDefRank": 44,
      "adjEM": 12.39,
      "adjOff": 108.7,
      "adjOffRank": 96,
      "adjTempo": 67.1,
      "adjTempoRank": 228,
      "conference": "SEC",
      "rank": 61,
      "record": "19-15"
    },
    "Georgia Southern": {
      "adjDef": 107.3,
      "adjDefRank": 222,
      "adjEM": -2.08,
      "adjOff": 105.2,
      "adjOffRank": 155,
      "adjTempo": 68.5,
      "adjTempoRank": 155,
      "conference": "SB",
      "rank": 195,
      "record": "18-15"
    },
    "Georgia St.": {
      "adjDef": 101.5,
      "adjDefRank": 102,
      "adjEM": 2.4,
      "adjOff": 103.9,
      "adjOffRank": 185,
      "adjTempo": 68.6,
      "adjTempoRank": 152,
      "conference": "SB",
      "rank": 131,
      "record": "20-13"
    },
    "Georgia Tech": {
      "adjDef": 91,
      "adjDefRank": 6,
      "adjEM": 9,
      "adjOff": 100,
      "adjOffRank": 259,
      "adjTempo": 68.2,
      "adjTempoRank": 179,
      "conference": "ACC",
      "rank": 77,
      "record": "21-16"
    },
    "Gonzaga": {
      "adjDef": 86.3,
      "adjDefRank": 1,
      "adjEM": 32.05,
      "adjOff": 118.4,
      "adjOffRank": 16,
      "adjTempo": 70.1,
      "adjTempoRank": 76,
      "conference": "WCC",
      "finish": "Runner-up",
      "rank": 1,
      "record": "37-2",
      "seed": 1
    },
    "Grambling St.": {
      "adjDef": 112.7,
      "adjDefRank": 315,
      "adjEM": -16.24,
      "adjOff": 96.5,
      "adjOffRank": 311,
      "adjTempo": 67.1,
      "adjTempoRank": 225,
      "conference": "SWAC",
      "rank": 327,
      "record": "15-17"
    },
    "Grand Canyon": {
      "adjDef": 100.6,
      "adjDefRank": 90,
      "adjEM": 0.97,
      "adjOff": 101.6,
      "adjOffRank": 229,
      "adjTempo": 68.9,
      "adjTempoRank": 122,
      "conference": "WAC",
      "rank": 152,
      "record": "22-9"
    },
    "Green Bay": {
      "adjDef": 103.4,
      "adjDefRank": 150,
      "adjEM": -1.45,
      "adjOff": 102,
      "adjOffRank": 218,
      "adjTempo": 74,
      "adjTempoRank": 7,
      "conference": "Horz",
      "rank": 190,
      "record": "18-14"
    },
    "Hampton": {
      "adjDef": 106.9,
      "adjDefRank": 215,
      "adjEM": -13.54,
      "adjOff": 93.4,
      "adjOffRank": 333,
      "adjTempo": 68.6,
      "adjTempoRank": 144,
      "conference": "MEAC",
      "rank": 310,
      "record": "14-17"
    },
    "Hartford": {
      "adjDef": 110.9,
      "adjDefRank": 299,
      "adjEM": -18.41,
      "adjOff": 92.5,
      "adjOffRank": 337,
      "adjTempo": 68.5,
      "adjTempoRank": 159,
      "conference": "AE",
      "rank": 334,
      "record": "9-23"
    },
    "Harvard": {
      "adjDef": 101.9,
      "adjDefRank": 110,
      "adjEM": 4.73,
      "adjOff": 106.7,
      "adjOffRank": 133,
      "adjTempo": 67,
      "adjTempoRank": 233,
      "conference": "Ivy",
      "rank": 112,
      "record": "18-10"
    },
    "Hawaii": {
      "adjDef": 106,
      "adjDefRank": 200,
      "adjEM": -8.98,
      "adjOff": 97,
      "adjOffRank": 302,
      "adjTempo": 67.1,
      "adjTempoRank": 226,
      "conference": "BW",
      "rank": 269,
      "record": "14-16"
    },
    "High Point": {
      "adjDef": 108.1,
      "adjDefRank": 241,
      "adjEM": -9.22,
      "adjOff": 98.9,
      "adjOffRank": 274,
      "adjTempo": 65.7,
      "adjTempoRank": 291,
      "conference": "BSth",
      "rank": 270,
      "record": "15-16"
    },
    "Hofstra": {
      "adjDef": 112.4,
      "adjDefRank": 311,
      "adjEM": -1.35,
      "adjOff": 111.1,
      "adjOffRank": 64,
      "adjTempo": 67.7,
      "adjTempoRank": 206,
      "conference": "CAA",
      "rank": 188,
      "record": "15-17"
    },
    "Holy Cross": {
      "adjDef": 102.9,
      "adjDefRank": 136,
      "adjEM": -4.23,
      "adjOff": 98.7,
      "adjOffRank": 278,
      "adjTempo": 60.4,
      "adjTempoRank": 349,
      "conference": "Pat",
      "rank": 215,
      "record": "15-17"
    },
    "Houston": {
      "adjDef": 99.9,
      "adjDefRank": 79,
      "adjEM": 13.61,
      "adjOff": 113.5,
      "adjOffRank": 39,
      "adjTempo": 65.4,
      "adjTempoRank": 300,
      "conference": "Amer",
      "rank": 52,
      "record": "21-11"
    },
    "Houston Baptist": {
      "adjDef": 110.2,
      "adjDefRank": 287,
      "adjEM": -5.08,
      "adjOff": 105.2,
      "adjOffRank": 156,
      "adjTempo": 70.3,
      "adjTempoRank": 70,
      "conference": "Slnd",
      "rank": 221,
      "record": "17-14"
    },
    "Howard": {
      "adjDef": 110.2,
      "adjDefRank": 286,
      "adjEM": -19.79,
      "adjOff": 90.4,
      "adjOffRank": 347,
      "adjTempo": 68.1,
      "adjTempoRank": 187,
      "conference": "MEAC",
      "rank": 338,
      "record": "10-24"
    },
    "IUPUI": {
      "adjDef": 109.8,
      "adjDefRank": 276,
      "adjEM": -2.76,
      "adjOff": 107,
      "adjOffRank": 126,
      "adjTempo": 69.3,
      "adjTempoRank": 102,
      "conference": "Sum",
      "rank": 198,
      "record": "14-18"
    },
    "Idaho": {
      "adjDef": 106,
      "adjDefRank": 199,
      "adjEM": -4.23,
      "adjOff": 101.8,
      "adjOffRank": 224,
      "adjTempo": 66.5,
      "adjTempoRank": 260,
      "conference": "BSky",
      "rank": 216,
      "record": "19-14"
    },
    "Idaho St.": {
      "adjDef": 115.8,
      "adjDefRank": 338,
      "adjEM": -17.91,
      "adjOff": 97.9,
      "adjOffRank": 291,
      "adjTempo": 68.9,
      "adjTempoRank": 119,
      "conference": "BSky",
      "rank": 332,
      "record": "6-26"
    },
    "Illinois": {
      "adjDef": 95.8,
      "adjDefRank": 35,
      "adjEM": 11.31,
      "adjOff": 107.2,
      "adjOffRank": 123,
      "adjTempo": 66.7,
      "adjTempoRank": 249,
      "conference": "B10",
      "rank": 66,
      "record": "20-15"
    },
    "Illinois Chicago": {
      "adjDef": 107.5,
      "adjDefRank": 226,
      "adjEM": -6.4,
      "adjOff": 101.1,
      "adjOffRank": 236,
      "adjTempo": 72.1,
      "adjTempoRank": 25,
      "conference": "Horz",
      "rank": 238,
      "record": "17-19"
    },
    "Illinois St.": {
      "adjDef": 93.6,
      "adjDefRank": 19,
      "adjEM": 14,
      "adjOff": 107.6,
      "adjOffRank": 115,
      "adjTempo": 64.9,
      "adjTempoRank": 308,
      "conference": "MVC",
      "rank": 49,
      "record": "28-7"
    },
    "Incarnate Word": {
      "adjDef": 114.3,
      "adjDefRank": 329,
      "adjEM": -12.61,
      "adjOff": 101.7,
      "adjOffRank": 227,
      "adjTempo": 71.8,
      "adjTempoRank": 31,
      "conference": "Slnd",
      "rank": 304,
      "record": "12-17"
    },
    "Indiana": {
      "adjDef": 101.6,
      "adjDefRank": 104,
      "adjEM": 14.49,
      "adjOff": 116.1,
      "adjOffRank": 27,
      "adjTempo": 68.4,
      "adjTempoRank": 168,
      "conference": "B10",
      "rank": 44,
      "record": "18-16"
    },
    "Indiana St.": {
      "adjDef": 102.3,
      "adjDefRank": 118,
      "adjEM": -2.78,
      "adjOff": 99.6,
      "adjOffRank": 266,
      "adjTempo": 69.7,
      "adjTempoRank": 91,
      "conference": "MVC",
      "rank": 199,
      "record": "11-20"
    },
    "Iona": {
      "adjDef": 106.6,
      "adjDefRank": 211,
      "adjEM": 4.2,
      "adjOff": 110.8,
      "adjOffRank": 67,
      "adjTempo": 70.6,
      "adjTempoRank": 60,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 117,
      "record": "22-13",
      "seed": 14
    },
    "Iowa": {
      "adjDef": 102.5,
      "adjDefRank": 123,
      "adjEM": 10.48,
      "adjOff": 113,
      "adjOffRank": 43,
      "adjTempo": 71.2,
      "adjTempoRank": 44,
      "conference": "B10",
      "rank": 71,
      "record": "19-15"
    },
    "Iowa St.": {
      "adjDef": 96.3,
      "adjDefRank": 42,
      "adjEM": 23.45,
      "adjOff": 119.7,
      "adjOffRank": 11,
      "adjTempo": 68.4,
      "adjTempoRank": 170,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 17,
      "record": "24-11",
      "seed": 5
    },
    "Jackson St.": {
      "adjDef": 109.1,
      "adjDefRank": 258,
      "adjEM": -16.36,
      "adjOff": 92.8,
      "adjOffRank": 336,
      "adjTempo": 64.3,
      "adjTempoRank": 317,
      "conference": "SWAC",
      "rank": 329,
      "record": "14-18"
    },
    "Jacksonville": {
      "adjDef": 111.9,
      "adjDefRank": 306,
      "adjEM": -9.61,
      "adjOff": 102.3,
      "adjOffRank": 212,
      "adjTempo": 67.9,
      "adjTempoRank": 197,
      "conference": "ASun",
      "rank": 275,
      "record": "17-16"
    },
    "Jacksonville St.": {
      "adjDef": 105.2,
      "adjDefRank": 186,
      "adjEM": 0.63,
      "adjOff": 105.8,
      "adjOffRank": 147,
      "adjTempo": 63.6,
      "adjTempoRank": 329,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 157,
      "record": "20-15",
      "seed": 15
    },
    "James Madison": {
      "adjDef": 104.1,
      "adjDefRank": 162,
      "adjEM": -5.31,
      "adjOff": 98.8,
      "adjOffRank": 275,
      "adjTempo": 64,
      "adjTempoRank": 326,
      "conference": "CAA",
      "rank": 223,
      "record": "10-23"
    },
    "Kansas": {
      "adjDef": 94.5,
      "adjDefRank": 24,
      "adjEM": 27.45,
      "adjOff": 121.9,
      "adjOffRank": 5,
      "adjTempo": 70.2,
      "adjTempoRank": 72,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 6,
      "record": "31-5",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.6,
      "adjDefRank": 33,
      "adjEM": 17.44,
      "adjOff": 113,
      "adjOffRank": 42,
      "adjTempo": 66.2,
      "adjTempoRank": 268,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 30,
      "record": "21-14",
      "seed": 11
    },
    "Kennesaw St.": {
      "adjDef": 110.1,
      "adjDefRank": 285,
      "adjEM": -7.52,
      "adjOff": 102.6,
      "adjOffRank": 208,
      "adjTempo": 69.4,
      "adjTempoRank": 101,
      "conference": "ASun",
      "rank": 252,
      "record": "14-18"
    },
    "Kent St.": {
      "adjDef": 103.3,
      "adjDefRank": 142,
      "adjEM": 1.68,
      "adjOff": 104.9,
      "adjOffRank": 159,
      "adjTempo": 68.3,
      "adjTempoRank": 176,
      "conference": "MAC",
      "finish": "Round of 64",
      "rank": 141,
      "record": "22-14",
      "seed": 14
    },
    "Kentucky": {
      "adjDef": 91.4,
      "adjDefRank": 7,
      "adjEM": 27.72,
      "adjOff": 119.1,
      "adjOffRank": 12,
      "adjTempo": 72,
      "adjTempoRank": 26,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "32-6",
      "seed": 2
    },
    "LIU Brooklyn": {
      "adjDef": 107.7,
      "adjDefRank": 234,
      "adjEM": -7.52,
      "adjOff": 100.2,
      "adjOffRank": 254,
      "adjTempo": 67,
      "adjTempoRank": 235,
      "conference": "NEC",
      "rank": 253,
      "record": "20-12"
    },
    "LSU": {
      "adjDef": 109.9,
      "adjDefRank": 280,
      "adjEM": -0.25,
      "adjOff": 109.7,
      "adjOffRank": 80,
      "adjTempo": 71.9,
      "adjTempoRank": 27,
      "conference": "SEC",
      "rank": 172,
      "record": "10-21"
    },
    "La Salle": {
      "adjDef": 108.7,
      "adjDefRank": 251,
      "adjEM": 1.8,
      "adjOff": 110.6,
      "adjOffRank": 69,
      "adjTempo": 68.7,
      "adjTempoRank": 139,
      "conference": "A10",
      "rank": 140,
      "record": "15-15"
    },
    "Lafayette": {
      "adjDef": 113.3,
      "adjDefRank": 321,
      "adjEM": -16.79,
      "adjOff": 96.6,
      "adjOffRank": 310,
      "adjTempo": 69.3,
      "adjTempoRank": 105,
      "conference": "Pat",
      "rank": 330,
      "record": "9-21"
    },
    "Lamar": {
      "adjDef": 108.7,
      "adjDefRank": 250,
      "adjEM": -5.58,
      "adjOff": 103.1,
      "adjOffRank": 193,
      "adjTempo": 68.1,
      "adjTempoRank": 183,
      "conference": "Slnd",
      "rank": 226,
      "record": "19-15"
    },
    "Lehigh": {
      "adjDef": 103.3,
      "adjDefRank": 144,
      "adjEM": 4.23,
      "adjOff": 107.5,
      "adjOffRank": 117,
      "adjTempo": 69.6,
      "adjTempoRank": 93,
      "conference": "Pat",
      "rank": 115,
      "record": "20-12"
    },
    "Liberty": {
      "adjDef": 103.8,
      "adjDefRank": 160,
      "adjEM": -3.93,
      "adjOff": 99.9,
      "adjOffRank": 260,
      "adjTempo": 62.7,
      "adjTempoRank": 342,
      "conference": "BSth",
      "rank": 211,
      "record": "21-14"
    },
    "Lipscomb": {
      "adjDef": 107,
      "adjDefRank": 217,
      "adjEM": 0.3,
      "adjOff": 107.3,
      "adjOffRank": 121,
      "adjTempo": 74.2,
      "adjTempoRank": 6,
      "conference": "ASun",
      "rank": 165,
      "record": "20-13"
    },
    "Little Rock": {
      "adjDef": 105.2,
      "adjDefRank": 185,
      "adjEM": -6.93,
      "adjOff": 98.3,
      "adjOffRank": 288,
      "adjTempo": 63.3,
      "adjTempoRank": 336,
      "conference": "SB",
      "rank": 245,
      "record": "15-17"
    },
    "Long Beach St.": {
      "adjDef": 110.1,
      "adjDefRank": 284,
      "adjEM": -5.48,
      "adjOff": 104.6,
      "adjOffRank": 165,
      "adjTempo": 69.5,
      "adjTempoRank": 96,
      "conference": "BW",
      "rank": 225,
      "record": "15-19"
    },
    "Longwood": {
      "adjDef": 117.3,
      "adjDefRank": 343,
      "adjEM": -25.15,
      "adjOff": 92.2,
      "adjOffRank": 340,
      "adjTempo": 70.5,
      "adjTempoRank": 61,
      "conference": "BSth",
      "rank": 347,
      "record": "6-24"
    },
    "Louisiana Lafayette": {
      "adjDef": 109.8,
      "adjDefRank": 279,
      "adjEM": 1.29,
      "adjOff": 111.1,
      "adjOffRank": 61,
      "adjTempo": 72.2,
      "adjTempoRank": 23,
      "conference": "SB",
      "rank": 146,
      "record": "21-12"
    },
    "Louisiana Monroe": {
      "adjDef": 106.8,
      "adjDefRank": 214,
      "adjEM": -8.54,
      "adjOff": 98.3,
      "adjOffRank": 287,
      "adjTempo": 65,
      "adjTempoRank": 305,
      "conference": "SB",
      "rank": 262,
      "record": "9-24"
    },
    "Louisiana Tech": {
      "adjDef": 100.9,
      "adjDefRank": 95,
      "adjEM": 7.06,
      "adjOff": 107.9,
      "adjOffRank": 107,
      "adjTempo": 68.5,
      "adjTempoRank": 156,
      "conference": "CUSA",
      "rank": 93,
      "record": "23-10"
    },
    "Louisville": {
      "adjDef": 91.7,
      "adjDefRank": 8,
      "adjEM": 25.51,
      "adjOff": 117.2,
      "adjOffRank": 22,
      "adjTempo": 67.9,
      "adjTempoRank": 194,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 9,
      "record": "25-9",
      "seed": 2
    },
    "Loyola Chicago": {
      "adjDef": 102.5,
      "adjDefRank": 121,
      "adjEM": 6.45,
      "adjOff": 108.9,
      "adjOffRank": 92,
      "adjTempo": 65.8,
      "adjTempoRank": 287,
      "conference": "MVC",
      "rank": 97,
      "record": "18-14"
    },
    "Loyola MD": {
      "adjDef": 104.2,
      "adjDefRank": 166,
      "adjEM": -5.82,
      "adjOff": 98.4,
      "adjOffRank": 284,
      "adjTempo": 66.2,
      "adjTempoRank": 269,
      "conference": "Pat",
      "rank": 227,
      "record": "16-17"
    },
    "Loyola Marymount": {
      "adjDef": 103.4,
      "adjDefRank": 153,
      "adjEM": -0.4,
      "adjOff": 103,
      "adjOffRank": 197,
      "adjTempo": 67.2,
      "adjTempoRank": 218,
      "conference": "WCC",
      "rank": 175,
      "record": "15-15"
    },
    "Maine": {
      "adjDef": 110.7,
      "adjDefRank": 292,
      "adjEM": -19.62,
      "adjOff": 91.1,
      "adjOffRank": 346,
      "adjTempo": 68,
      "adjTempoRank": 193,
      "conference": "AE",
      "rank": 337,
      "record": "7-25"
    },
    "Manhattan": {
      "adjDef": 107.6,
      "adjDefRank": 230,
      "adjEM": -10.64,
      "adjOff": 97,
      "adjOffRank": 303,
      "adjTempo": 68.9,
      "adjTempoRank": 121,
      "conference": "MAAC",
      "rank": 287,
      "record": "10-22"
    },
    "Marist": {
      "adjDef": 114.3,
      "adjDefRank": 330,
      "adjEM": -13.63,
      "adjOff": 100.7,
      "adjOffRank": 246,
      "adjTempo": 66.9,
      "adjTempoRank": 242,
      "conference": "MAAC",
      "rank": 312,
      "record": "8-24"
    },
    "Marquette": {
      "adjDef": 104.2,
      "adjDefRank": 165,
      "adjEM": 16.58,
      "adjOff": 120.8,
      "adjOffRank": 8,
      "adjTempo": 70.1,
      "adjTempoRank": 75,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 32,
      "record": "19-13",
      "seed": 10
    },
    "Marshall": {
      "adjDef": 109.7,
      "adjDefRank": 273,
      "adjEM": 1.13,
      "adjOff": 110.8,
      "adjOffRank": 66,
      "adjTempo": 77.6,
      "adjTempoRank": 3,
      "conference": "CUSA",
      "rank": 149,
      "record": "20-15"
    },
    "Maryland": {
      "adjDef": 98.7,
      "adjDefRank": 64,
      "adjEM": 14.34,
      "adjOff": 113.1,
      "adjOffRank": 41,
      "adjTempo": 67.2,
      "adjTempoRank": 219,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 46,
      "record": "24-9",
      "seed": 6
    },
    "Maryland Eastern Shore": {
      "adjDef": 112.7,
      "adjDefRank": 314,
      "adjEM": -14.83,
      "adjOff": 97.8,
      "adjOffRank": 292,
      "adjTempo": 68.5,
      "adjTempoRank": 157,
      "conference": "MEAC",
      "rank": 321,
      "record": "14-20"
    },
    "Massachusetts": {
      "adjDef": 100,
      "adjDefRank": 80,
      "adjEM": 0.63,
      "adjOff": 100.6,
      "adjOffRank": 249,
      "adjTempo": 71.9,
      "adjTempoRank": 29,
      "conference": "A10",
      "rank": 158,
      "record": "15-18"
    },
    "McNeese St.": {
      "adjDef": 110.3,
      "adjDefRank": 288,
      "adjEM": -14.01,
      "adjOff": 96.3,
      "adjOffRank": 316,
      "adjTempo": 69.9,
      "adjTempoRank": 85,
      "conference": "Slnd",
      "rank": 316,
      "record": "7-22"
    },
    "Memphis": {
      "adjDef": 101,
      "adjDefRank": 98,
      "adjEM": 6.02,
      "adjOff": 107,
      "adjOffRank": 127,
      "adjTempo": 68.4,
      "adjTempoRank": 165,
      "conference": "Amer",
      "rank": 101,
      "record": "19-13"
    },
    "Mercer": {
      "adjDef": 108.2,
      "adjDefRank": 243,
      "adjEM": 1.37,
      "adjOff": 109.6,
      "adjOffRank": 81,
      "adjTempo": 61.7,
      "adjTempoRank": 346,
      "conference": "SC",
      "rank": 145,
      "record": "15-17"
    },
    "Miami FL": {
      "adjDef": 94.9,
      "adjDefRank": 25,
      "adjEM": 15.03,
      "adjOff": 109.9,
      "adjOffRank": 76,
      "adjTempo": 63,
      "adjTempoRank": 339,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 42,
      "record": "21-12",
      "seed": 8
    },
    "Miami OH": {
      "adjDef": 106,
      "adjDefRank": 197,
      "adjEM": -10.05,
      "adjOff": 95.9,
      "adjOffRank": 319,
      "adjTempo": 68.6,
      "adjTempoRank": 148,
      "conference": "MAC",
      "rank": 279,
      "record": "11-21"
    },
    "Michigan": {
      "adjDef": 99.2,
      "adjDefRank": 69,
      "adjEM": 23.05,
      "adjOff": 122.3,
      "adjOffRank": 4,
      "adjTempo": 63.1,
      "adjTempoRank": 338,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 20,
      "record": "26-12",
      "seed": 7
    },
    "Michigan St.": {
      "adjDef": 95.9,
      "adjDefRank": 37,
      "adjEM": 15.51,
      "adjOff": 111.4,
      "adjOffRank": 58,
      "adjTempo": 66.9,
      "adjTempoRank": 241,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 40,
      "record": "20-15",
      "seed": 9
    },
    "Middle Tennessee": {
      "adjDef": 97.2,
      "adjDefRank": 53,
      "adjEM": 14.95,
      "adjOff": 112.2,
      "adjOffRank": 51,
      "adjTempo": 64.6,
      "adjTempoRank": 311,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 43,
      "record": "31-5",
      "seed": 12
    },
    "Milwaukee": {
      "adjDef": 108.3,
      "adjDefRank": 244,
      "adjEM": -8.37,
      "adjOff": 99.9,
      "adjOffRank": 261,
      "adjTempo": 62.5,
      "adjTempoRank": 344,
      "conference": "Horz",
      "rank": 259,
      "record": "11-24"
    },
    "Minnesota": {
      "adjDef": 93.7,
      "adjDefRank": 22,
      "adjEM": 16,
      "adjOff": 109.7,
      "adjOffRank": 77,
      "adjTempo": 69.5,
      "adjTempoRank": 98,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 37,
      "record": "24-10",
      "seed": 5
    },
    "Mississippi": {
      "adjDef": 100.9,
      "adjDefRank": 96,
      "adjEM": 10.16,
      "adjOff": 111.1,
      "adjOffRank": 63,
      "adjTempo": 71.6,
      "adjTempoRank": 33,
      "conference": "SEC",
      "rank": 74,
      "record": "22-14"
    },
    "Mississippi St.": {
      "adjDef": 99.3,
      "adjDefRank": 71,
      "adjEM": 7.27,
      "adjOff": 106.6,
      "adjOffRank": 135,
      "adjTempo": 68.9,
      "adjTempoRank": 118,
      "conference": "SEC",
      "rank": 88,
      "record": "16-16"
    },
    "Mississippi Valley St.": {
      "adjDef": 118.3,
      "adjDefRank": 347,
      "adjEM": -22.54,
      "adjOff": 95.8,
      "adjOffRank": 320,
      "adjTempo": 71.3,
      "adjTempoRank": 43,
      "conference": "SWAC",
      "rank": 344,
      "record": "7-25"
    },
    "Missouri": {
      "adjDef": 100.9,
      "adjDefRank": 97,
      "adjEM": 0.64,
      "adjOff": 101.5,
      "adjOffRank": 230,
      "adjTempo": 68,
      "adjTempoRank": 190,
      "conference": "SEC",
      "rank": 156,
      "record": "8-24"
    },
    "Missouri St.": {
      "adjDef": 106,
      "adjDefRank": 201,
      "adjEM": 2.27,
      "adjOff": 108.3,
      "adjOffRank": 104,
      "adjTempo": 66.2,
      "adjTempoRank": 271,
      "conference": "MVC",
      "rank": 134,
      "record": "17-16"
    },
    "Monmouth": {
      "adjDef": 100.5,
      "adjDefRank": 89,
      "adjEM": 8.61,
      "adjOff": 109.1,
      "adjOffRank": 87,
      "adjTempo": 72.4,
      "adjTempoRank": 21,
      "conference": "MAAC",
      "rank": 81,
      "record": "27-7"
    },
    "Montana": {
      "adjDef": 108.2,
      "adjDefRank": 242,
      "adjEM": -2.06,
      "adjOff": 106.1,
      "adjOffRank": 145,
      "adjTempo": 66.7,
      "adjTempoRank": 250,
      "conference": "BSky",
      "rank": 194,
      "record": "16-16"
    },
    "Montana St.": {
      "adjDef": 109.6,
      "adjDefRank": 271,
      "adjEM": -6.51,
      "adjOff": 103.1,
      "adjOffRank": 195,
      "adjTempo": 69.3,
      "adjTempoRank": 103,
      "conference": "BSky",
      "rank": 240,
      "record": "16-16"
    },
    "Morehead St.": {
      "adjDef": 109.8,
      "adjDefRank": 278,
      "adjEM": -3.06,
      "adjOff": 106.7,
      "adjOffRank": 131,
      "adjTempo": 68.7,
      "adjTempoRank": 141,
      "conference": "OVC",
      "rank": 203,
      "record": "14-16"
    },
    "Morgan St.": {
      "adjDef": 106.6,
      "adjDefRank": 212,
      "adjEM": -15.47,
      "adjOff": 91.2,
      "adjOffRank": 344,
      "adjTempo": 67.7,
      "adjTempoRank": 203,
      "conference": "MEAC",
      "rank": 324,
      "record": "14-16"
    },
    "Mount St. Mary's": {
      "adjDef": 103.4,
      "adjDefRank": 151,
      "adjEM": -3.86,
      "adjOff": 99.5,
      "adjOffRank": 269,
      "adjTempo": 66.8,
      "adjTempoRank": 245,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 209,
      "record": "20-16",
      "seed": 16
    },
    "Murray St.": {
      "adjDef": 109.8,
      "adjDefRank": 277,
      "adjEM": -2.61,
      "adjOff": 107.2,
      "adjOffRank": 122,
      "adjTempo": 68.7,
      "adjTempoRank": 140,
      "conference": "OVC",
      "rank": 197,
      "record": "16-17"
    },
    "N.C. State": {
      "adjDef": 107.6,
      "adjDefRank": 229,
      "adjEM": 4.98,
      "adjOff": 112.6,
      "adjOffRank": 46,
      "adjTempo": 70.3,
      "adjTempoRank": 71,
      "conference": "ACC",
      "rank": 109,
      "record": "15-17"
    },
    "NJIT": {
      "adjDef": 109.2,
      "adjDefRank": 265,
      "adjEM": -10.55,
      "adjOff": 98.7,
      "adjOffRank": 279,
      "adjTempo": 67.6,
      "adjTempoRank": 211,
      "conference": "ASun",
      "rank": 286,
      "record": "11-20"
    },
    "Navy": {
      "adjDef": 103.4,
      "adjDefRank": 148,
      "adjEM": -3.47,
      "adjOff": 99.9,
      "adjOffRank": 262,
      "adjTempo": 64.2,
      "adjTempoRank": 321,
      "conference": "Pat",
      "rank": 206,
      "record": "16-16"
    },
    "Nebraska": {
      "adjDef": 99.8,
      "adjDefRank": 77,
      "adjEM": 5.14,
      "adjOff": 105,
      "adjOffRank": 157,
      "adjTempo": 68.1,
      "adjTempoRank": 185,
      "conference": "B10",
      "rank": 107,
      "record": "12-19"
    },
    "Nebraska Omaha": {
      "adjDef": 109,
      "adjDefRank": 255,
      "adjEM": -0.4,
      "adjOff": 108.6,
      "adjOffRank": 98,
      "adjTempo": 73.8,
      "adjTempoRank": 9,
      "conference": "Sum",
      "rank": 176,
      "record": "18-14"
    },
    "Nevada": {
      "adjDef": 101.3,
      "adjDefRank": 101,
      "adjEM": 13.31,
      "adjOff": 114.6,
      "adjOffRank": 36,
      "adjTempo": 69.7,
      "adjTempoRank": 90,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 54,
      "record": "28-7",
      "seed": 12
    },
    "New Hampshire": {
      "adjDef": 103.2,
      "adjDefRank": 140,
      "adjEM": -0.8,
      "adjOff": 102.3,
      "adjOffRank": 211,
      "adjTempo": 65.4,
      "adjTempoRank": 296,
      "conference": "AE",
      "rank": 182,
      "record": "20-12"
    },
    "New Mexico": {
      "adjDef": 103.1,
      "adjDefRank": 137,
      "adjEM": 3.44,
      "adjOff": 106.5,
      "adjOffRank": 137,
      "adjTempo": 68.8,
      "adjTempoRank": 126,
      "conference": "MWC",
      "rank": 119,
      "record": "17-14"
    },
    "New Mexico St.": {
      "adjDef": 102.6,
      "adjDefRank": 124,
      "adjEM": 7.89,
      "adjOff": 110.5,
      "adjOffRank": 70,
      "adjTempo": 67.9,
      "adjTempoRank": 195,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 84,
      "record": "28-6",
      "seed": 14
    },
    "New Orleans": {
      "adjDef": 101.8,
      "adjDefRank": 107,
      "adjEM": -0.52,
      "adjOff": 101.3,
      "adjOffRank": 235,
      "adjTempo": 66.2,
      "adjTempoRank": 272,
      "conference": "Slnd",
      "finish": "First Four",
      "rank": 178,
      "record": "20-12",
      "seed": 16
    },
    "Niagara": {
      "adjDef": 109.3,
      "adjDefRank": 267,
      "adjEM": -7.9,
      "adjOff": 101.4,
      "adjOffRank": 232,
      "adjTempo": 69.1,
      "adjTempoRank": 114,
      "conference": "MAAC",
      "rank": 256,
      "record": "10-23"
    },
    "Nicholls St.": {
      "adjDef": 114.2,
      "adjDefRank": 326,
      "adjEM": -13.77,
      "adjOff": 100.4,
      "adjOffRank": 250,
      "adjTempo": 69.4,
      "adjTempoRank": 99,
      "conference": "Slnd",
      "rank": 315,
      "record": "14-17"
    },
    "Norfolk St.": {
      "adjDef": 106.1,
      "adjDefRank": 204,
      "adjEM": -11.92,
      "adjOff": 94.2,
      "adjOffRank": 329,
      "adjTempo": 68.8,
      "adjTempoRank": 127,
      "conference": "MEAC",
      "rank": 299,
      "record": "17-17"
    },
    "North Carolina": {
      "adjDef": 92.5,
      "adjDefRank": 11,
      "adjEM": 28.22,
      "adjOff": 120.7,
      "adjOffRank": 9,
      "adjTempo": 71.3,
      "adjTempoRank": 40,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 3,
      "record": "33-7",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 123.1,
      "adjDefRank": 351,
      "adjEM": -27.71,
      "adjOff": 95.4,
      "adjOffRank": 322,
      "adjTempo": 62.8,
      "adjTempoRank": 341,
      "conference": "MEAC",
      "rank": 349,
      "record": "3-29"
    },
    "North Carolina Central": {
      "adjDef": 101.9,
      "adjDefRank": 109,
      "adjEM": 0.36,
      "adjOff": 102.2,
      "adjOffRank": 214,
      "adjTempo": 66.7,
      "adjTempoRank": 254,
      "conference": "MEAC",
      "finish": "First Four",
      "rank": 161,
      "record": "25-9",
      "seed": 16
    },
    "North Dakota": {
      "adjDef": 103.5,
      "adjDefRank": 157,
      "adjEM": 0.04,
      "adjOff": 103.6,
      "adjOffRank": 188,
      "adjTempo": 71.5,
      "adjTempoRank": 35,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 168,
      "record": "22-10",
      "seed": 15
    },
    "North Dakota St.": {
      "adjDef": 104.4,
      "adjDefRank": 169,
      "adjEM": 0.19,
      "adjOff": 104.6,
      "adjOffRank": 166,
      "adjTempo": 66.7,
      "adjTempoRank": 253,
      "conference": "Sum",
      "rank": 167,
      "record": "19-11"
    },
    "North Florida": {
      "adjDef": 109.5,
      "adjDefRank": 268,
      "adjEM": -7.64,
      "adjOff": 101.8,
      "adjOffRank": 221,
      "adjTempo": 69.9,
      "adjTempoRank": 86,
      "conference": "ASun",
      "rank": 255,
      "record": "15-19"
    },
    "North Texas": {
      "adjDef": 110.8,
      "adjDefRank": 294,
      "adjEM": -14.52,
      "adjOff": 96.3,
      "adjOffRank": 315,
      "adjTempo": 70.2,
      "adjTempoRank": 73,
      "conference": "CUSA",
      "rank": 320,
      "record": "8-22"
    },
    "Northeastern": {
      "adjDef": 106.4,
      "adjDefRank": 208,
      "adjEM": 0.63,
      "adjOff": 107,
      "adjOffRank": 125,
      "adjTempo": 66.1,
      "adjTempoRank": 274,
      "conference": "CAA",
      "rank": 159,
      "record": "15-16"
    },
    "Northern Arizona": {
      "adjDef": 110.4,
      "adjDefRank": 289,
      "adjEM": -15.28,
      "adjOff": 95.1,
      "adjOffRank": 326,
      "adjTempo": 69.2,
      "adjTempoRank": 106,
      "conference": "BSky",
      "rank": 323,
      "record": "9-23"
    },
    "Northern Colorado": {
      "adjDef": 106.9,
      "adjDefRank": 216,
      "adjEM": -10.33,
      "adjOff": 96.6,
      "adjOffRank": 309,
      "adjTempo": 68.5,
      "adjTempoRank": 160,
      "conference": "BSky",
      "rank": 283,
      "record": "11-18"
    },
    "Northern Illinois": {
      "adjDef": 102.4,
      "adjDefRank": 120,
      "adjEM": -3.47,
      "adjOff": 98.9,
      "adjOffRank": 273,
      "adjTempo": 66.8,
      "adjTempoRank": 247,
      "conference": "MAC",
      "rank": 205,
      "record": "15-17"
    },
    "Northern Iowa": {
      "adjDef": 97.6,
      "adjDefRank": 57,
      "adjEM": 0.01,
      "adjOff": 97.6,
      "adjOffRank": 295,
      "adjTempo": 63.5,
      "adjTempoRank": 332,
      "conference": "MVC",
      "rank": 169,
      "record": "14-16"
    },
    "Northern Kentucky": {
      "adjDef": 104.9,
      "adjDefRank": 178,
      "adjEM": 1.91,
      "adjOff": 106.8,
      "adjOffRank": 130,
      "adjTempo": 68,
      "adjTempoRank": 189,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 138,
      "record": "24-11",
      "seed": 15
    },
    "Northwestern": {
      "adjDef": 95.5,
      "adjDefRank": 32,
      "adjEM": 15.81,
      "adjOff": 111.3,
      "adjOffRank": 59,
      "adjTempo": 65.6,
      "adjTempoRank": 292,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 38,
      "record": "24-12",
      "seed": 8
    },
    "Northwestern St.": {
      "adjDef": 112.5,
      "adjDefRank": 312,
      "adjEM": -11.4,
      "adjOff": 101.1,
      "adjOffRank": 237,
      "adjTempo": 71.3,
      "adjTempoRank": 39,
      "conference": "Slnd",
      "rank": 293,
      "record": "13-16"
    },
    "Notre Dame": {
      "adjDef": 98.4,
      "adjDefRank": 61,
      "adjEM": 19.74,
      "adjOff": 118.1,
      "adjOffRank": 18,
      "adjTempo": 67,
      "adjTempoRank": 236,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 27,
      "record": "26-10",
      "seed": 5
    },
    "Oakland": {
      "adjDef": 99.1,
      "adjDefRank": 68,
      "adjEM": 5.9,
      "adjOff": 105,
      "adjOffRank": 158,
      "adjTempo": 71.9,
      "adjTempoRank": 28,
      "conference": "Horz",
      "rank": 103,
      "record": "25-9"
    },
    "Ohio": {
      "adjDef": 99.9,
      "adjDefRank": 78,
      "adjEM": 5.73,
      "adjOff": 105.6,
      "adjOffRank": 148,
      "adjTempo": 70.4,
      "adjTempoRank": 65,
      "conference": "MAC",
      "rank": 105,
      "record": "20-11"
    },
    "Ohio St.": {
      "adjDef": 101,
      "adjDefRank": 99,
      "adjEM": 10.2,
      "adjOff": 111.2,
      "adjOffRank": 60,
      "adjTempo": 68,
      "adjTempoRank": 192,
      "conference": "B10",
      "rank": 73,
      "record": "17-15"
    },
    "Oklahoma": {
      "adjDef": 95.9,
      "adjDefRank": 39,
      "adjEM": 11.49,
      "adjOff": 107.4,
      "adjOffRank": 118,
      "adjTempo": 70,
      "adjTempoRank": 81,
      "conference": "B12",
      "rank": 65,
      "record": "11-20"
    },
    "Oklahoma St.": {
      "adjDef": 103.5,
      "adjDefRank": 155,
      "adjEM": 22.48,
      "adjOff": 126,
      "adjOffRank": 1,
      "adjTempo": 70.4,
      "adjTempoRank": 66,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 22,
      "record": "20-13",
      "seed": 10
    },
    "Old Dominion": {
      "adjDef": 97.2,
      "adjDefRank": 52,
      "adjEM": 3.44,
      "adjOff": 100.7,
      "adjOffRank": 247,
      "adjTempo": 61.1,
      "adjTempoRank": 348,
      "conference": "CUSA",
      "rank": 120,
      "record": "19-12"
    },
    "Oral Roberts": {
      "adjDef": 110.7,
      "adjDefRank": 293,
      "adjEM": -6.3,
      "adjOff": 104.4,
      "adjOffRank": 169,
      "adjTempo": 69.1,
      "adjTempoRank": 111,
      "conference": "Sum",
      "rank": 236,
      "record": "8-22"
    },
    "Oregon": {
      "adjDef": 93.2,
      "adjDefRank": 17,
      "adjEM": 25.02,
      "adjOff": 118.2,
      "adjOffRank": 17,
      "adjTempo": 67,
      "adjTempoRank": 234,
      "conference": "P12",
      "finish": "Final Four",
      "rank": 10,
      "record": "33-6",
      "seed": 3
    },
    "Oregon St.": {
      "adjDef": 105.8,
      "adjDefRank": 194,
      "adjEM": -8.72,
      "adjOff": 97,
      "adjOffRank": 301,
      "adjTempo": 64.6,
      "adjTempoRank": 312,
      "conference": "P12",
      "rank": 264,
      "record": "5-27"
    },
    "Pacific": {
      "adjDef": 106.5,
      "adjDefRank": 210,
      "adjEM": -6.98,
      "adjOff": 99.5,
      "adjOffRank": 267,
      "adjTempo": 66.5,
      "adjTempoRank": 259,
      "conference": "WCC",
      "rank": 246,
      "record": "11-22"
    },
    "Penn": {
      "adjDef": 102.8,
      "adjDefRank": 133,
      "adjEM": -0.2,
      "adjOff": 102.6,
      "adjOffRank": 206,
      "adjTempo": 66.7,
      "adjTempoRank": 255,
      "conference": "Ivy",
      "rank": 171,
      "record": "13-15"
    },
    "Penn St.": {
      "adjDef": 97.2,
      "adjDefRank": 51,
      "adjEM": 7.3,
      "adjOff": 104.5,
      "adjOffRank": 168,
      "adjTempo": 70.4,
      "adjTempoRank": 63,
      "conference": "B10",
      "rank": 87,
      "record": "15-18"
    },
    "Pepperdine": {
      "adjDef": 114.2,
      "adjDefRank": 327,
      "adjEM": -12.26,
      "adjOff": 102,
      "adjOffRank": 217,
      "adjTempo": 68.5,
      "adjTempoRank": 154,
      "conference": "WCC",
      "rank": 303,
      "record": "9-22"
    },
    "Pittsburgh": {
      "adjDef": 103.7,
      "adjDefRank": 158,
      "adjEM": 8.72,
      "adjOff": 112.4,
      "adjOffRank": 49,
      "adjTempo": 65.4,
      "adjTempoRank": 298,
      "conference": "ACC",
      "rank": 79,
      "record": "16-17"
    },
    "Portland": {
      "adjDef": 108.9,
      "adjDefRank": 254,
      "adjEM": -10.24,
      "adjOff": 98.7,
      "adjOffRank": 280,
      "adjTempo": 65.8,
      "adjTempoRank": 283,
      "conference": "WCC",
      "rank": 280,
      "record": "11-22"
    },
    "Portland St.": {
      "adjDef": 110.8,
      "adjDefRank": 295,
      "adjEM": -6.5,
      "adjOff": 104.3,
      "adjOffRank": 174,
      "adjTempo": 72.6,
      "adjTempoRank": 19,
      "conference": "BSky",
      "rank": 239,
      "record": "15-16"
    },
    "Prairie View A&M": {
      "adjDef": 111.6,
      "adjDefRank": 304,
      "adjEM": -13.64,
      "adjOff": 98,
      "adjOffRank": 289,
      "adjTempo": 67.6,
      "adjTempoRank": 210,
      "conference": "SWAC",
      "rank": 313,
      "record": "13-20"
    },
    "Presbyterian": {
      "adjDef": 118.1,
      "adjDefRank": 346,
      "adjEM": -29.2,
      "adjOff": 88.9,
      "adjOffRank": 349,
      "adjTempo": 64.1,
      "adjTempoRank": 322,
      "conference": "BSth",
      "rank": 350,
      "record": "5-25"
    },
    "Princeton": {
      "adjDef": 96.4,
      "adjDefRank": 45,
      "adjEM": 12.61,
      "adjOff": 109,
      "adjOffRank": 89,
      "adjTempo": 63.2,
      "adjTempoRank": 337,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 58,
      "record": "23-7",
      "seed": 12
    },
    "Providence": {
      "adjDef": 96,
      "adjDefRank": 40,
      "adjEM": 12.43,
      "adjOff": 108.5,
      "adjOffRank": 101,
      "adjTempo": 66.2,
      "adjTempoRank": 270,
      "conference": "BE",
      "finish": "First Four",
      "rank": 60,
      "record": "20-13",
      "seed": 11
    },
    "Purdue": {
      "adjDef": 93.9,
      "adjDefRank": 23,
      "adjEM": 23.12,
      "adjOff": 117,
      "adjOffRank": 24,
      "adjTempo": 69.1,
      "adjTempoRank": 113,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 19,
      "record": "27-8",
      "seed": 4
    },
    "Quinnipiac": {
      "adjDef": 113,
      "adjDefRank": 319,
      "adjEM": -11.44,
      "adjOff": 101.6,
      "adjOffRank": 228,
      "adjTempo": 73.2,
      "adjTempoRank": 12,
      "conference": "MAAC",
      "rank": 294,
      "record": "10-21"
    },
    "Radford": {
      "adjDef": 107.8,
      "adjDefRank": 235,
      "adjEM": -11.7,
      "adjOff": 96.1,
      "adjOffRank": 317,
      "adjTempo": 64,
      "adjTempoRank": 325,
      "conference": "BSth",
      "rank": 296,
      "record": "14-18"
    },
    "Rhode Island": {
      "adjDef": 95.2,
      "adjDefRank": 30,
      "adjEM": 16.26,
      "adjOff": 111.5,
      "adjOffRank": 57,
      "adjTempo": 66.4,
      "adjTempoRank": 262,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 34,
      "record": "25-10",
      "seed": 11
    },
    "Rice": {
      "adjDef": 107.1,
      "adjDefRank": 219,
      "adjEM": 0.73,
      "adjOff": 107.8,
      "adjOffRank": 109,
      "adjTempo": 72.7,
      "adjTempoRank": 17,
      "conference": "CUSA",
      "rank": 155,
      "record": "23-12"
    },
    "Richmond": {
      "adjDef": 102.8,
      "adjDefRank": 132,
      "adjEM": 7.21,
      "adjOff": 110,
      "adjOffRank": 74,
      "adjTempo": 68.9,
      "adjTempoRank": 120,
      "conference": "A10",
      "rank": 92,
      "record": "22-13"
    },
    "Rider": {
      "adjDef": 104.6,
      "adjDefRank": 174,
      "adjEM": -3.7,
      "adjOff": 100.9,
      "adjOffRank": 245,
      "adjTempo": 72.2,
      "adjTempoRank": 24,
      "conference": "MAAC",
      "rank": 208,
      "record": "18-15"
    },
    "Robert Morris": {
      "adjDef": 100.4,
      "adjDefRank": 84,
      "adjEM": -8.77,
      "adjOff": 91.6,
      "adjOffRank": 341,
      "adjTempo": 68.8,
      "adjTempoRank": 133,
      "conference": "NEC",
      "rank": 266,
      "record": "14-19"
    },
    "Rutgers": {
      "adjDef": 99.3,
      "adjDefRank": 70,
      "adjEM": 2.23,
      "adjOff": 101.5,
      "adjOffRank": 231,
      "adjTempo": 66.2,
      "adjTempoRank": 267,
      "conference": "B10",
      "rank": 135,
      "record": "15-18"
    },
    "SIU Edwardsville": {
      "adjDef": 109.2,
      "adjDefRank": 263,
      "adjEM": -14.15,
      "adjOff": 95,
      "adjOffRank": 327,
      "adjTempo": 69.2,
      "adjTempoRank": 107,
      "conference": "OVC",
      "rank": 318,
      "record": "6-24"
    },
    "SMU": {
      "adjDef": 95,
      "adjDefRank": 27,
      "adjEM": 24.73,
      "adjOff": 119.8,
      "adjOffRank": 10,
      "adjTempo": 63.5,
      "adjTempoRank": 333,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 11,
      "record": "30-5",
      "seed": 6
    },
    "Sacramento St.": {
      "adjDef": 112.2,
      "adjDefRank": 310,
      "adjEM": -11.24,
      "adjOff": 100.9,
      "adjOffRank": 241,
      "adjTempo": 65.5,
      "adjTempoRank": 293,
      "conference": "BSky",
      "rank": 291,
      "record": "13-18"
    },
    "Sacred Heart": {
      "adjDef": 109.7,
      "adjDefRank": 274,
      "adjEM": -12.12,
      "adjOff": 97.6,
      "adjOffRank": 296,
      "adjTempo": 71.1,
      "adjTempoRank": 48,
      "conference": "NEC",
      "rank": 301,
      "record": "13-19"
    },
    "Saint Joseph's": {
      "adjDef": 103.3,
      "adjDefRank": 143,
      "adjEM": -1.05,
      "adjOff": 102.2,
      "adjOffRank": 215,
      "adjTempo": 68.4,
      "adjTempoRank": 162,
      "conference": "A10",
      "rank": 184,
      "record": "11-20"
    },
    "Saint Louis": {
      "adjDef": 105,
      "adjDefRank": 181,
      "adjEM": -9.58,
      "adjOff": 95.4,
      "adjOffRank": 324,
      "adjTempo": 63.5,
      "adjTempoRank": 331,
      "conference": "A10",
      "rank": 273,
      "record": "12-21"
    },
    "Saint Mary's": {
      "adjDef": 95,
      "adjDefRank": 26,
      "adjEM": 23.67,
      "adjOff": 118.7,
      "adjOffRank": 14,
      "adjTempo": 60.2,
      "adjTempoRank": 350,
      "conference": "WCC",
      "finish": "Round of 32",
      "rank": 15,
      "record": "29-5",
      "seed": 7
    },
    "Saint Peter's": {
      "adjDef": 95.9,
      "adjDefRank": 36,
      "adjEM": 6.67,
      "adjOff": 102.5,
      "adjOffRank": 210,
      "adjTempo": 61.3,
      "adjTempoRank": 347,
      "conference": "MAAC",
      "rank": 95,
      "record": "23-13"
    },
    "Sam Houston St.": {
      "adjDef": 103.4,
      "adjDefRank": 152,
      "adjEM": -6.19,
      "adjOff": 97.2,
      "adjOffRank": 299,
      "adjTempo": 70,
      "adjTempoRank": 78,
      "conference": "Slnd",
      "rank": 234,
      "record": "21-13"
    },
    "Samford": {
      "adjDef": 107.5,
      "adjDefRank": 227,
      "adjEM": 0.26,
      "adjOff": 107.8,
      "adjOffRank": 110,
      "adjTempo": 68.6,
      "adjTempoRank": 150,
      "conference": "SC",
      "rank": 166,
      "record": "20-16"
    },
    "San Diego": {
      "adjDef": 108.4,
      "adjDefRank": 247,
      "adjEM": -8.38,
      "adjOff": 100,
      "adjOffRank": 258,
      "adjTempo": 63.3,
      "adjTempoRank": 335,
      "conference": "WCC",
      "rank": 260,
      "record": "13-18"
    },
    "San Diego St.": {
      "adjDef": 95.1,
      "adjDefRank": 28,
      "adjEM": 8.99,
      "adjOff": 104,
      "adjOffRank": 183,
      "adjTempo": 64.9,
      "adjTempoRank": 309,
      "conference": "MWC",
      "rank": 78,
      "record": "19-14"
    },
    "San Francisco": {
      "adjDef": 98,
      "adjDefRank": 59,
      "adjEM": 4.74,
      "adjOff": 102.8,
      "adjOffRank": 204,
      "adjTempo": 69.1,
      "adjTempoRank": 109,
      "conference": "WCC",
      "rank": 111,
      "record": "20-13"
    },
    "San Jose St.": {
      "adjDef": 104.5,
      "adjDefRank": 171,
      "adjEM": -4.1,
      "adjOff": 100.4,
      "adjOffRank": 252,
      "adjTempo": 70.8,
      "adjTempoRank": 55,
      "conference": "MWC",
      "rank": 214,
      "record": "14-16"
    },
    "Santa Clara": {
      "adjDef": 102.7,
      "adjDefRank": 129,
      "adjEM": 2.81,
      "adjOff": 105.5,
      "adjOffRank": 149,
      "adjTempo": 62.6,
      "adjTempoRank": 343,
      "conference": "WCC",
      "rank": 125,
      "record": "17-16"
    },
    "Savannah St.": {
      "adjDef": 114.7,
      "adjDefRank": 333,
      "adjEM": -14.14,
      "adjOff": 100.6,
      "adjOffRank": 248,
      "adjTempo": 81,
      "adjTempoRank": 1,
      "conference": "MEAC",
      "rank": 317,
      "record": "13-16"
    },
    "Seattle": {
      "adjDef": 104.7,
      "adjDefRank": 177,
      "adjEM": -8.75,
      "adjOff": 96,
      "adjOffRank": 318,
      "adjTempo": 67.6,
      "adjTempoRank": 208,
      "conference": "WAC",
      "rank": 265,
      "record": "13-17"
    },
    "Seton Hall": {
      "adjDef": 95.8,
      "adjDefRank": 34,
      "adjEM": 13.63,
      "adjOff": 109.4,
      "adjOffRank": 84,
      "adjTempo": 67.5,
      "adjTempoRank": 214,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 51,
      "record": "21-12",
      "seed": 9
    },
    "Siena": {
      "adjDef": 106.3,
      "adjDefRank": 206,
      "adjEM": -0.16,
      "adjOff": 106.2,
      "adjOffRank": 143,
      "adjTempo": 68.7,
      "adjTempoRank": 143,
      "conference": "MAAC",
      "rank": 170,
      "record": "17-17"
    },
    "South Alabama": {
      "adjDef": 105.5,
      "adjDefRank": 191,
      "adjEM": -6.76,
      "adjOff": 98.7,
      "adjOffRank": 277,
      "adjTempo": 69.7,
      "adjTempoRank": 88,
      "conference": "SB",
      "rank": 243,
      "record": "14-18"
    },
    "South Carolina": {
      "adjDef": 88.1,
      "adjDefRank": 3,
      "adjEM": 20.8,
      "adjOff": 108.9,
      "adjOffRank": 91,
      "adjTempo": 68.6,
      "adjTempoRank": 145,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 24,
      "record": "26-11",
      "seed": 7
    },
    "South Carolina St.": {
      "adjDef": 118.5,
      "adjDefRank": 348,
      "adjEM": -19.47,
      "adjOff": 99,
      "adjOffRank": 271,
      "adjTempo": 68.3,
      "adjTempoRank": 172,
      "conference": "MEAC",
      "rank": 336,
      "record": "11-20"
    },
    "South Dakota": {
      "adjDef": 102.7,
      "adjDefRank": 126,
      "adjEM": 1.48,
      "adjOff": 104.2,
      "adjOffRank": 179,
      "adjTempo": 70.7,
      "adjTempoRank": 56,
      "conference": "Sum",
      "rank": 143,
      "record": "22-12"
    },
    "South Dakota St.": {
      "adjDef": 111.4,
      "adjDefRank": 303,
      "adjEM": -1.19,
      "adjOff": 110.2,
      "adjOffRank": 71,
      "adjTempo": 67.2,
      "adjTempoRank": 222,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 185,
      "record": "18-17",
      "seed": 16
    },
    "South Florida": {
      "adjDef": 107.7,
      "adjDefRank": 233,
      "adjEM": -11.28,
      "adjOff": 96.4,
      "adjOffRank": 313,
      "adjTempo": 68.8,
      "adjTempoRank": 137,
      "conference": "Amer",
      "rank": 292,
      "record": "7-23"
    },
    "Southeast Missouri St.": {
      "adjDef": 109.3,
      "adjDefRank": 266,
      "adjEM": -5.87,
      "adjOff": 103.4,
      "adjOffRank": 191,
      "adjTempo": 68.6,
      "adjTempoRank": 151,
      "conference": "OVC",
      "rank": 228,
      "record": "15-18"
    },
    "Southeastern Louisiana": {
      "adjDef": 103.9,
      "adjDefRank": 161,
      "adjEM": -6.06,
      "adjOff": 97.8,
      "adjOffRank": 294,
      "adjTempo": 67.1,
      "adjTempoRank": 227,
      "conference": "Slnd",
      "rank": 231,
      "record": "16-16"
    },
    "Southern": {
      "adjDef": 113.6,
      "adjDefRank": 322,
      "adjEM": -15.7,
      "adjOff": 97.9,
      "adjOffRank": 290,
      "adjTempo": 68.5,
      "adjTempoRank": 153,
      "conference": "SWAC",
      "rank": 326,
      "record": "15-18"
    },
    "Southern Illinois": {
      "adjDef": 103.7,
      "adjDefRank": 159,
      "adjEM": 1.09,
      "adjOff": 104.8,
      "adjOffRank": 162,
      "adjTempo": 65.4,
      "adjTempoRank": 299,
      "conference": "MVC",
      "rank": 150,
      "record": "17-16"
    },
    "Southern Miss": {
      "adjDef": 108.5,
      "adjDefRank": 249,
      "adjEM": -15.52,
      "adjOff": 93,
      "adjOffRank": 335,
      "adjTempo": 65.5,
      "adjTempoRank": 294,
      "conference": "CUSA",
      "rank": 325,
      "record": "9-22"
    },
    "Southern Utah": {
      "adjDef": 119.7,
      "adjDefRank": 349,
      "adjEM": -18.33,
      "adjOff": 101.3,
      "adjOffRank": 233,
      "adjTempo": 71.4,
      "adjTempoRank": 36,
      "conference": "BSky",
      "rank": 333,
      "record": "6-27"
    },
    "St. Bonaventure": {
      "adjDef": 101.8,
      "adjDefRank": 108,
      "adjEM": 7.23,
      "adjOff": 109,
      "adjOffRank": 88,
      "adjTempo": 68.8,
      "adjTempoRank": 136,
      "conference": "A10",
      "rank": 91,
      "record": "20-12"
    },
    "St. Francis NY": {
      "adjDef": 112.1,
      "adjDefRank": 308,
      "adjEM": -24.13,
      "adjOff": 87.9,
      "adjOffRank": 350,
      "adjTempo": 67.2,
      "adjTempoRank": 224,
      "conference": "NEC",
      "rank": 346,
      "record": "4-27"
    },
    "St. Francis PA": {
      "adjDef": 109.7,
      "adjDefRank": 275,
      "adjEM": -7.61,
      "adjOff": 102.1,
      "adjOffRank": 216,
      "adjTempo": 70.3,
      "adjTempoRank": 69,
      "conference": "NEC",
      "rank": 254,
      "record": "17-17"
    },
    "St. John's": {
      "adjDef": 102.7,
      "adjDefRank": 131,
      "adjEM": 6.26,
      "adjOff": 109,
      "adjOffRank": 90,
      "adjTempo": 73.3,
      "adjTempoRank": 11,
      "conference": "BE",
      "rank": 99,
      "record": "14-19"
    },
    "Stanford": {
      "adjDef": 98.9,
      "adjDefRank": 65,
      "adjEM": 5.91,
      "adjOff": 104.8,
      "adjOffRank": 161,
      "adjTempo": 68.3,
      "adjTempoRank": 171,
      "conference": "P12",
      "rank": 102,
      "record": "14-17"
    },
    "Stephen F. Austin": {
      "adjDef": 102.9,
      "adjDefRank": 134,
      "adjEM": -6.19,
      "adjOff": 96.7,
      "adjOffRank": 308,
      "adjTempo": 66.3,
      "adjTempoRank": 264,
      "conference": "Slnd",
      "rank": 233,
      "record": "18-15"
    },
    "Stetson": {
      "adjDef": 115.4,
      "adjDefRank": 334,
      "adjEM": -14.45,
      "adjOff": 100.9,
      "adjOffRank": 243,
      "adjTempo": 72.4,
      "adjTempoRank": 22,
      "conference": "ASun",
      "rank": 319,
      "record": "11-21"
    },
    "Stony Brook": {
      "adjDef": 105.9,
      "adjDefRank": 195,
      "adjEM": -4.1,
      "adjOff": 101.8,
      "adjOffRank": 223,
      "adjTempo": 65.9,
      "adjTempoRank": 281,
      "conference": "AE",
      "rank": 213,
      "record": "18-14"
    },
    "Syracuse": {
      "adjDef": 102.3,
      "adjDefRank": 119,
      "adjEM": 13.31,
      "adjOff": 115.7,
      "adjOffRank": 30,
      "adjTempo": 66.8,
      "adjTempoRank": 243,
      "conference": "ACC",
      "rank": 55,
      "record": "19-15"
    },
    "TCU": {
      "adjDef": 97,
      "adjDefRank": 50,
      "adjEM": 17.51,
      "adjOff": 114.5,
      "adjOffRank": 37,
      "adjTempo": 67.1,
      "adjTempoRank": 230,
      "conference": "B12",
      "rank": 29,
      "record": "24-15"
    },
    "Temple": {
      "adjDef": 104.3,
      "adjDefRank": 167,
      "adjEM": 3.93,
      "adjOff": 108.3,
      "adjOffRank": 105,
      "adjTempo": 67.1,
      "adjTempoRank": 229,
      "conference": "Amer",
      "rank": 118,
      "record": "16-16"
    },
    "Tennessee": {
      "adjDef": 97.5,
      "adjDefRank": 55,
      "adjEM": 12.62,
      "adjOff": 110.1,
      "adjOffRank": 73,
      "adjTempo": 69.4,
      "adjTempoRank": 100,
      "conference": "SEC",
      "rank": 57,
      "record": "16-16"
    },
    "Tennessee Martin": {
      "adjDef": 110.6,
      "adjDefRank": 291,
      "adjEM": -3.92,
      "adjOff": 106.7,
      "adjOffRank": 132,
      "adjTempo": 67.5,
      "adjTempoRank": 215,
      "conference": "OVC",
      "rank": 210,
      "record": "22-13"
    },
    "Tennessee St.": {
      "adjDef": 99.7,
      "adjDefRank": 74,
      "adjEM": 0.59,
      "adjOff": 100.3,
      "adjOffRank": 253,
      "adjTempo": 67,
      "adjTempoRank": 237,
      "conference": "OVC",
      "rank": 160,
      "record": "17-13"
    },
    "Tennessee Tech": {
      "adjDef": 107,
      "adjDefRank": 218,
      "adjEM": -8.03,
      "adjOff": 98.9,
      "adjOffRank": 272,
      "adjTempo": 68.3,
      "adjTempoRank": 173,
      "conference": "OVC",
      "rank": 257,
      "record": "12-20"
    },
    "Texas": {
      "adjDef": 93.7,
      "adjDefRank": 21,
      "adjEM": 10.51,
      "adjOff": 104.2,
      "adjOffRank": 177,
      "adjTempo": 67.6,
      "adjTempoRank": 212,
      "conference": "B12",
      "rank": 70,
      "record": "11-22"
    },
    "Texas A&M": {
      "adjDef": 97.3,
      "adjDefRank": 54,
      "adjEM": 11.49,
      "adjOff": 108.7,
      "adjOffRank": 95,
      "adjTempo": 64.5,
      "adjTempoRank": 313,
      "conference": "SEC",
      "rank": 64,
      "record": "16-15"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 103.3,
      "adjDefRank": 145,
      "adjEM": -0.49,
      "adjOff": 102.8,
      "adjOffRank": 203,
      "adjTempo": 68.8,
      "adjTempoRank": 132,
      "conference": "Slnd",
      "rank": 177,
      "record": "24-12"
    },
    "Texas Southern": {
      "adjDef": 107.5,
      "adjDefRank": 228,
      "adjEM": -4.04,
      "adjOff": 103.5,
      "adjOffRank": 189,
      "adjTempo": 68.6,
      "adjTempoRank": 146,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 212,
      "record": "23-12",
      "seed": 16
    },
    "Texas St.": {
      "adjDef": 101.7,
      "adjDefRank": 105,
      "adjEM": -0.36,
      "adjOff": 101.3,
      "adjOffRank": 234,
      "adjTempo": 62.3,
      "adjTempoRank": 345,
      "conference": "SB",
      "rank": 174,
      "record": "22-14"
    },
    "Texas Tech": {
      "adjDef": 97.5,
      "adjDefRank": 56,
      "adjEM": 15.11,
      "adjOff": 112.6,
      "adjOffRank": 44,
      "adjTempo": 63.8,
      "adjTempoRank": 327,
      "conference": "B12",
      "rank": 41,
      "record": "18-14"
    },
    "The Citadel": {
      "adjDef": 116.5,
      "adjDefRank": 340,
      "adjEM": -11.03,
      "adjOff": 105.5,
      "adjOffRank": 150,
      "adjTempo": 79.7,
      "adjTempoRank": 2,
      "conference": "SC",
      "rank": 288,
      "record": "12-21"
    },
    "Toledo": {
      "adjDef": 106.1,
      "adjDefRank": 203,
      "adjEM": 2.69,
      "adjOff": 108.8,
      "adjOffRank": 93,
      "adjTempo": 66.4,
      "adjTempoRank": 263,
      "conference": "MAC",
      "rank": 128,
      "record": "17-17"
    },
    "Towson": {
      "adjDef": 101.2,
      "adjDefRank": 100,
      "adjEM": 4.22,
      "adjOff": 105.5,
      "adjOffRank": 151,
      "adjTempo": 67.9,
      "adjTempoRank": 196,
      "conference": "CAA",
      "rank": 116,
      "record": "20-13"
    },
    "Troy": {
      "adjDef": 107.6,
      "adjDefRank": 232,
      "adjEM": 2.04,
      "adjOff": 109.7,
      "adjOffRank": 79,
      "adjTempo": 68.1,
      "adjTempoRank": 182,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 137,
      "record": "22-15",
      "seed": 15
    },
    "Tulane": {
      "adjDef": 109.2,
      "adjDefRank": 264,
      "adjEM": -6.58,
      "adjOff": 102.6,
      "adjOffRank": 207,
      "adjTempo": 70.4,
      "adjTempoRank": 64,
      "conference": "Amer",
      "rank": 241,
      "record": "6-25"
    },
    "Tulsa": {
      "adjDef": 102.9,
      "adjDefRank": 135,
      "adjEM": 1.44,
      "adjOff": 104.3,
      "adjOffRank": 172,
      "adjTempo": 68.2,
      "adjTempoRank": 180,
      "conference": "Amer",
      "rank": 144,
      "record": "15-17"
    },
    "UAB": {
      "adjDef": 106.4,
      "adjDefRank": 207,
      "adjEM": -2.52,
      "adjOff": 103.9,
      "adjOffRank": 187,
      "adjTempo": 66.1,
      "adjTempoRank": 275,
      "conference": "CUSA",
      "rank": 196,
      "record": "17-16"
    },
    "UC Davis": {
      "adjDef": 102.2,
      "adjDefRank": 115,
      "adjEM": -4.41,
      "adjOff": 97.8,
      "adjOffRank": 293,
      "adjTempo": 68.9,
      "adjTempoRank": 125,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 218,
      "record": "23-13",
      "seed": 16
    },
    "UC Irvine": {
      "adjDef": 100,
      "adjDefRank": 81,
      "adjEM": 1.86,
      "adjOff": 101.8,
      "adjOffRank": 222,
      "adjTempo": 66.5,
      "adjTempoRank": 258,
      "conference": "BW",
      "rank": 139,
      "record": "21-15"
    },
    "UC Riverside": {
      "adjDef": 105.9,
      "adjDefRank": 196,
      "adjEM": -13.63,
      "adjOff": 92.3,
      "adjOffRank": 339,
      "adjTempo": 67.9,
      "adjTempoRank": 198,
      "conference": "BW",
      "rank": 311,
      "record": "8-21"
    },
    "UC Santa Barbara": {
      "adjDef": 109.2,
      "adjDefRank": 262,
      "adjEM": -17.84,
      "adjOff": 91.3,
      "adjOffRank": 343,
      "adjTempo": 64.1,
      "adjTempoRank": 323,
      "conference": "BW",
      "rank": 331,
      "record": "6-22"
    },
    "UCF": {
      "adjDef": 93.3,
      "adjDefRank": 18,
      "adjEM": 10.91,
      "adjOff": 104.2,
      "adjOffRank": 178,
      "adjTempo": 66,
      "adjTempoRank": 276,
      "conference": "Amer",
      "rank": 68,
      "record": "24-12"
    },
    "UCLA": {
      "adjDef": 100.4,
      "adjDefRank": 85,
      "adjEM": 23.63,
      "adjOff": 124,
      "adjOffRank": 2,
      "adjTempo": 72.6,
      "adjTempoRank": 20,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 16,
      "record": "31-5",
      "seed": 3
    },
    "UMBC": {
      "adjDef": 110.9,
      "adjDefRank": 297,
      "adjEM": -2.97,
      "adjOff": 107.9,
      "adjOffRank": 108,
      "adjTempo": 71,
      "adjTempoRank": 52,
      "conference": "AE",
      "rank": 201,
      "record": "21-13"
    },
    "UMKC": {
      "adjDef": 107.4,
      "adjDefRank": 225,
      "adjEM": -4.42,
      "adjOff": 103,
      "adjOffRank": 198,
      "adjTempo": 70.7,
      "adjTempoRank": 58,
      "conference": "WAC",
      "rank": 219,
      "record": "18-17"
    },
    "UMass Lowell": {
      "adjDef": 115.8,
      "adjDefRank": 337,
      "adjEM": -11.5,
      "adjOff": 104.3,
      "adjOffRank": 173,
      "adjTempo": 71.8,
      "adjTempoRank": 30,
      "conference": "AE",
      "rank": 295,
      "record": "11-20"
    },
    "UNC Asheville": {
      "adjDef": 102.5,
      "adjDefRank": 122,
      "adjEM": 4.85,
      "adjOff": 107.4,
      "adjOffRank": 119,
      "adjTempo": 68.3,
      "adjTempoRank": 174,
      "conference": "BSth",
      "rank": 110,
      "record": "23-10"
    },
    "UNC Greensboro": {
      "adjDef": 104.5,
      "adjDefRank": 173,
      "adjEM": 3.19,
      "adjOff": 107.7,
      "adjOffRank": 113,
      "adjTempo": 66.9,
      "adjTempoRank": 240,
      "conference": "SC",
      "rank": 121,
      "record": "25-10"
    },
    "UNC Wilmington": {
      "adjDef": 105.5,
      "adjDefRank": 193,
      "adjEM": 12.46,
      "adjOff": 118,
      "adjOffRank": 19,
      "adjTempo": 70.3,
      "adjTempoRank": 67,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 59,
      "record": "29-6",
      "seed": 12
    },
    "UNLV": {
      "adjDef": 105.3,
      "adjDefRank": 187,
      "adjEM": -7.01,
      "adjOff": 98.3,
      "adjOffRank": 286,
      "adjTempo": 68.4,
      "adjTempoRank": 169,
      "conference": "MWC",
      "rank": 248,
      "record": "11-21"
    },
    "USC": {
      "adjDef": 100.7,
      "adjDefRank": 91,
      "adjEM": 13.45,
      "adjOff": 114.2,
      "adjOffRank": 38,
      "adjTempo": 68.7,
      "adjTempoRank": 138,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 53,
      "record": "26-10",
      "seed": 11
    },
    "USC Upstate": {
      "adjDef": 111.1,
      "adjDefRank": 301,
      "adjEM": -6.89,
      "adjOff": 104.2,
      "adjOffRank": 176,
      "adjTempo": 68.4,
      "adjTempoRank": 166,
      "conference": "ASun",
      "rank": 244,
      "record": "17-16"
    },
    "UT Arlington": {
      "adjDef": 100.8,
      "adjDefRank": 93,
      "adjEM": 9.8,
      "adjOff": 110.6,
      "adjOffRank": 68,
      "adjTempo": 70.6,
      "adjTempoRank": 59,
      "conference": "SB",
      "rank": 75,
      "record": "27-9"
    },
    "UT Rio Grande Valley": {
      "adjDef": 112.9,
      "adjDefRank": 318,
      "adjEM": -12.02,
      "adjOff": 100.9,
      "adjOffRank": 242,
      "adjTempo": 73.9,
      "adjTempoRank": 8,
      "conference": "WAC",
      "rank": 300,
      "record": "10-22"
    },
    "UTEP": {
      "adjDef": 102.7,
      "adjDefRank": 128,
      "adjEM": -2.93,
      "adjOff": 99.7,
      "adjOffRank": 264,
      "adjTempo": 66.3,
      "adjTempoRank": 265,
      "conference": "CUSA",
      "rank": 200,
      "record": "15-17"
    },
    "UTSA": {
      "adjDef": 102.7,
      "adjDefRank": 127,
      "adjEM": -7.25,
      "adjOff": 95.4,
      "adjOffRank": 323,
      "adjTempo": 68.1,
      "adjTempoRank": 186,
      "conference": "CUSA",
      "rank": 251,
      "record": "14-19"
    },
    "Utah": {
      "adjDef": 99.3,
      "adjDefRank": 72,
      "adjEM": 14.2,
      "adjOff": 113.5,
      "adjOffRank": 40,
      "adjTempo": 68.7,
      "adjTempoRank": 142,
      "conference": "P12",
      "rank": 47,
      "record": "20-12"
    },
    "Utah St.": {
      "adjDef": 104.5,
      "adjDefRank": 170,
      "adjEM": 2.42,
      "adjOff": 106.9,
      "adjOffRank": 129,
      "adjTempo": 66.6,
      "adjTempoRank": 257,
      "conference": "MWC",
      "rank": 130,
      "record": "15-17"
    },
    "Utah Valley": {
      "adjDef": 99.7,
      "adjDefRank": 75,
      "adjEM": 1.25,
      "adjOff": 101,
      "adjOffRank": 240,
      "adjTempo": 73,
      "adjTempoRank": 13,
      "conference": "WAC",
      "rank": 147,
      "record": "17-17"
    },
    "VCU": {
      "adjDef": 96,
      "adjDefRank": 41,
      "adjEM": 14.07,
      "adjOff": 110.1,
      "adjOffRank": 72,
      "adjTempo": 68.8,
      "adjTempoRank": 134,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 48,
      "record": "26-9",
      "seed": 10
    },
    "VMI": {
      "adjDef": 113.8,
      "adjDefRank": 323,
      "adjEM": -16.27,
      "adjOff": 97.6,
      "adjOffRank": 297,
      "adjTempo": 69.1,
      "adjTempoRank": 112,
      "conference": "SC",
      "rank": 328,
      "record": "6-24"
    },
    "Valparaiso": {
      "adjDef": 100.5,
      "adjDefRank": 88,
      "adjEM": 5.82,
      "adjOff": 106.3,
      "adjOffRank": 139,
      "adjTempo": 67.2,
      "adjTempoRank": 220,
      "conference": "Horz",
      "rank": 104,
      "record": "24-9"
    },
    "Vanderbilt": {
      "adjDef": 95.9,
      "adjDefRank": 38,
      "adjEM": 16.53,
      "adjOff": 112.4,
      "adjOffRank": 48,
      "adjTempo": 65.8,
      "adjTempoRank": 285,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "19-16",
      "seed": 9
    },
    "Vermont": {
      "adjDef": 99.4,
      "adjDefRank": 73,
      "adjEM": 11.67,
      "adjOff": 111.1,
      "adjOffRank": 62,
      "adjTempo": 64.5,
      "adjTempoRank": 315,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 63,
      "record": "29-6",
      "seed": 13
    },
    "Villanova": {
      "adjDef": 92.5,
      "adjDefRank": 12,
      "adjEM": 29.88,
      "adjOff": 122.4,
      "adjOffRank": 3,
      "adjTempo": 64.1,
      "adjTempoRank": 324,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 2,
      "record": "32-4",
      "seed": 1
    },
    "Virginia": {
      "adjDef": 88,
      "adjDefRank": 2,
      "adjEM": 24.29,
      "adjOff": 112.2,
      "adjOffRank": 50,
      "adjTempo": 59.2,
      "adjTempoRank": 351,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 12,
      "record": "23-11",
      "seed": 5
    },
    "Virginia Tech": {
      "adjDef": 103.5,
      "adjDefRank": 156,
      "adjEM": 13.8,
      "adjOff": 117.3,
      "adjOffRank": 21,
      "adjTempo": 68.4,
      "adjTempoRank": 167,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "22-11",
      "seed": 9
    },
    "Wagner": {
      "adjDef": 108,
      "adjDefRank": 239,
      "adjEM": -7,
      "adjOff": 101,
      "adjOffRank": 239,
      "adjTempo": 62.9,
      "adjTempoRank": 340,
      "conference": "NEC",
      "rank": 247,
      "record": "16-14"
    },
    "Wake Forest": {
      "adjDef": 104.7,
      "adjDefRank": 176,
      "adjEM": 16.25,
      "adjOff": 120.9,
      "adjOffRank": 7,
      "adjTempo": 70.7,
      "adjTempoRank": 57,
      "conference": "ACC",
      "finish": "First Four",
      "rank": 36,
      "record": "19-14",
      "seed": 11
    },
    "Washington": {
      "adjDef": 107.4,
      "adjDefRank": 224,
      "adjEM": 0.34,
      "adjOff": 107.7,
      "adjOffRank": 111,
      "adjTempo": 71.1,
      "adjTempoRank": 51,
      "conference": "P12",
      "rank": 163,
      "record": "9-22"
    },
    "Washington St.": {
      "adjDef": 106.1,
      "adjDefRank": 202,
      "adjEM": -1.73,
      "adjOff": 104.4,
      "adjOffRank": 171,
      "adjTempo": 67.7,
      "adjTempoRank": 204,
      "conference": "P12",
      "rank": 193,
      "record": "13-18"
    },
    "Weber St.": {
      "adjDef": 109,
      "adjDefRank": 256,
      "adjEM": -0.54,
      "adjOff": 108.5,
      "adjOffRank": 100,
      "adjTempo": 67,
      "adjTempoRank": 238,
      "conference": "BSky",
      "rank": 179,
      "record": "20-14"
    },
    "West Virginia": {
      "adjDef": 89.4,
      "adjDefRank": 4,
      "adjEM": 27.17,
      "adjOff": 116.5,
      "adjOffRank": 26,
      "adjTempo": 69.9,
      "adjTempoRank": 84,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 7,
      "record": "28-9",
      "seed": 4
    },
    "Western Carolina": {
      "adjDef": 105.1,
      "adjDefRank": 183,
      "adjEM": -15.11,
      "adjOff": 90,
      "adjOffRank": 348,
      "adjTempo": 66.8,
      "adjTempoRank": 244,
      "conference": "SC",
      "rank": 322,
      "record": "9-23"
    },
    "Western Illinois": {
      "adjDef": 108.9,
      "adjDefRank": 253,
      "adjEM": -11.75,
      "adjOff": 97.1,
      "adjOffRank": 300,
      "adjTempo": 68.8,
      "adjTempoRank": 130,
      "conference": "Sum",
      "rank": 297,
      "record": "8-20"
    },
    "Western Kentucky": {
      "adjDef": 109.1,
      "adjDefRank": 259,
      "adjEM": -6.15,
      "adjOff": 103,
      "adjOffRank": 199,
      "adjTempo": 65.8,
      "adjTempoRank": 282,
      "conference": "CUSA",
      "rank": 232,
      "record": "15-17"
    },
    "Western Michigan": {
      "adjDef": 106.8,
      "adjDefRank": 213,
      "adjEM": 0.36,
      "adjOff": 107.1,
      "adjOffRank": 124,
      "adjTempo": 67.9,
      "adjTempoRank": 199,
      "conference": "MAC",
      "rank": 162,
      "record": "16-16"
    },
    "Wichita St.": {
      "adjDef": 92.6,
      "adjDefRank": 13,
      "adjEM": 26.14,
      "adjOff": 118.7,
      "adjOffRank": 13,
      "adjTempo": 68,
      "adjTempoRank": 191,
      "conference": "MVC",
      "finish": "Round of 32",
      "rank": 8,
      "record": "31-5",
      "seed": 10
    },
    "William & Mary": {
      "adjDef": 109.7,
      "adjDefRank": 272,
      "adjEM": 2.28,
      "adjOff": 111.9,
      "adjOffRank": 52,
      "adjTempo": 70.9,
      "adjTempoRank": 53,
      "conference": "CAA",
      "rank": 133,
      "record": "17-14"
    },
    "Winthrop": {
      "adjDef": 101.6,
      "adjDefRank": 103,
      "adjEM": 4.53,
      "adjOff": 106.1,
      "adjOffRank": 144,
      "adjTempo": 71.1,
      "adjTempoRank": 47,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 113,
      "record": "26-7",
      "seed": 13
    },
    "Wisconsin": {
      "adjDef": 92.1,
      "adjDefRank": 9,
      "adjEM": 22.99,
      "adjOff": 115.1,
      "adjOffRank": 33,
      "adjTempo": 63.4,
      "adjTempoRank": 334,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 21,
      "record": "27-10",
      "seed": 8
    },
    "Wofford": {
      "adjDef": 108.4,
      "adjDefRank": 246,
      "adjEM": 1.54,
      "adjOff": 109.9,
      "adjOffRank": 75,
      "adjTempo": 66.7,
      "adjTempoRank": 252,
      "conference": "SC",
      "rank": 142,
      "record": "16-17"
    },
    "Wright St.": {
      "adjDef": 106.2,
      "adjDefRank": 205,
      "adjEM": 0.34,
      "adjOff": 106.5,
      "adjOffRank": 136,
      "adjTempo": 69,
      "adjTempoRank": 116,
      "conference": "Horz",
      "rank": 164,
      "record": "20-12"
    },
    "Wyoming": {
      "adjDef": 102,
      "adjDefRank": 111,
      "adjEM": 2.78,
      "adjOff": 104.8,
      "adjOffRank": 163,
      "adjTempo": 72.9,
      "adjTempoRank": 14,
      "conference": "MWC",
      "rank": 127,
      "record": "23-15"
    },
    "Xavier": {
      "adjDef": 99,
      "adjDefRank": 67,
      "adjEM": 16.7,
      "adjOff": 115.7,
      "adjOffRank": 29,
      "adjTempo": 67.2,
      "adjTempoRank": 223,
      "conference": "BE",
      "finish": "Elite Eight",
      "rank": 31,
      "record": "24-14",
      "seed": 11
    },
    "Yale": {
      "adjDef": 105.2,
      "adjDefRank": 184,
      "adjEM": 1.01,
      "adjOff": 106.2,
      "adjOffRank": 141,
      "adjTempo": 68.2,
      "adjTempoRank": 181,
      "conference": "Ivy",
      "rank": 151,
      "record": "18-11"
    },
    "Youngstown St.": {
      "adjDef": 112.9,
      "adjDefRank": 317,
      "adjEM": -10.29,
      "adjOff": 102.6,
      "adjOffRank": 209,
      "adjTempo": 71.7,
      "adjTempoRank": 32,
      "conference": "Horz",
      "rank": 282,
      "record": "13-21"
    }
  },
  "2018": {
    "Abilene Christian": {
      "adjDef": 106.8,
      "adjDefRank": 205,
      "adjEM": -8.66,
      "adjOff": 98.2,
      "adjOffRank": 298,
      "adjTempo": 68.6,
      "adjTempoRank": 155,
      "conference": "Slnd",
      "rank": 262,
      "record": "16-16"
    },
    "Air Force": {
      "adjDef": 106.8,
      "adjDefRank": 202,
      "adjEM": -6.25,
      "adjOff": 100.5,
      "adjOffRank": 250,
      "adjTempo": 66,
      "adjTempoRank": 284,
      "conference": "MWC",
      "rank": 237,
      "record": "12-19"
    },
    "Akron": {
      "adjDef": 109.9,
      "adjDefRank": 270,
      "adjEM": -8.08,
      "adjOff": 101.8,
      "adjOffRank": 234,
      "adjTempo": 67.8,
      "adjTempoRank": 198,
      "conference": "MAC",
      "rank": 255,
      "record": "14-18"
    },
    "Alabama": {
      "adjDef": 95.2,
      "adjDefRank": 20,
      "adjEM": 13.41,
      "adjOff": 108.7,
      "adjOffRank": 106,
      "adjTempo": 68.7,
      "adjTempoRank": 151,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 57,
      "record": "20-16",
      "seed": 9
    },
    "Alabama A&M": {
      "adjDef": 114.9,
      "adjDefRank": 331,
      "adjEM": -28.31,
      "adjOff": 86.6,
      "adjOffRank": 350,
      "adjTempo": 65.8,
      "adjTempoRank": 293,
      "conference": "SWAC",
      "rank": 351,
      "record": "3-28"
    },
    "Alabama St.": {
      "adjDef": 115.7,
      "adjDefRank": 337,
      "adjEM": -19.86,
      "adjOff": 95.8,
      "adjOffRank": 322,
      "adjTempo": 68.8,
      "adjTempoRank": 145,
      "conference": "SWAC",
      "rank": 339,
      "record": "8-23"
    },
    "Albany": {
      "adjDef": 105.4,
      "adjDefRank": 180,
      "adjEM": 1.71,
      "adjOff": 107.1,
      "adjOffRank": 134,
      "adjTempo": 66.2,
      "adjTempoRank": 272,
      "conference": "AE",
      "rank": 151,
      "record": "22-10"
    },
    "Alcorn St.": {
      "adjDef": 114.4,
      "adjDefRank": 325,
      "adjEM": -19.43,
      "adjOff": 95,
      "adjOffRank": 330,
      "adjTempo": 68,
      "adjTempoRank": 186,
      "conference": "SWAC",
      "rank": 336,
      "record": "11-21"
    },
    "American": {
      "adjDef": 111.8,
      "adjDefRank": 307,
      "adjEM": -16.14,
      "adjOff": 95.6,
      "adjOffRank": 324,
      "adjTempo": 66.5,
      "adjTempoRank": 256,
      "conference": "Pat",
      "rank": 328,
      "record": "6-24"
    },
    "Appalachian St.": {
      "adjDef": 107.5,
      "adjDefRank": 224,
      "adjEM": -4.11,
      "adjOff": 103.4,
      "adjOffRank": 199,
      "adjTempo": 70.1,
      "adjTempoRank": 75,
      "conference": "SB",
      "rank": 208,
      "record": "15-18"
    },
    "Arizona": {
      "adjDef": 100.4,
      "adjDefRank": 83,
      "adjEM": 17.7,
      "adjOff": 118,
      "adjOffRank": 15,
      "adjTempo": 67.1,
      "adjTempoRank": 224,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 28,
      "record": "27-8",
      "seed": 4
    },
    "Arizona St.": {
      "adjDef": 102.6,
      "adjDefRank": 123,
      "adjEM": 14.13,
      "adjOff": 116.7,
      "adjOffRank": 18,
      "adjTempo": 71.3,
      "adjTempoRank": 43,
      "conference": "P12",
      "finish": "First Four",
      "rank": 49,
      "record": "20-12",
      "seed": 11
    },
    "Arkansas": {
      "adjDef": 101.7,
      "adjDefRank": 106,
      "adjEM": 14.69,
      "adjOff": 116.3,
      "adjOffRank": 20,
      "adjTempo": 69.7,
      "adjTempoRank": 91,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 44,
      "record": "23-12",
      "seed": 7
    },
    "Arkansas Pine Bluff": {
      "adjDef": 110,
      "adjDefRank": 275,
      "adjEM": -16.45,
      "adjOff": 93.6,
      "adjOffRank": 339,
      "adjTempo": 67.5,
      "adjTempoRank": 211,
      "conference": "SWAC",
      "rank": 329,
      "record": "14-21"
    },
    "Arkansas St.": {
      "adjDef": 115.1,
      "adjDefRank": 333,
      "adjEM": -10.56,
      "adjOff": 104.5,
      "adjOffRank": 182,
      "adjTempo": 69.5,
      "adjTempoRank": 99,
      "conference": "SB",
      "rank": 281,
      "record": "11-21"
    },
    "Army": {
      "adjDef": 108.5,
      "adjDefRank": 245,
      "adjEM": -8.69,
      "adjOff": 99.8,
      "adjOffRank": 259,
      "adjTempo": 70.4,
      "adjTempoRank": 67,
      "conference": "Pat",
      "rank": 264,
      "record": "13-17"
    },
    "Auburn": {
      "adjDef": 96.7,
      "adjDefRank": 37,
      "adjEM": 18.69,
      "adjOff": 115.4,
      "adjOffRank": 26,
      "adjTempo": 72.6,
      "adjTempoRank": 18,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 23,
      "record": "26-8",
      "seed": 4
    },
    "Austin Peay": {
      "adjDef": 107,
      "adjDefRank": 211,
      "adjEM": -2.09,
      "adjOff": 105,
      "adjOffRank": 172,
      "adjTempo": 69.2,
      "adjTempoRank": 115,
      "conference": "OVC",
      "rank": 189,
      "record": "19-15"
    },
    "BYU": {
      "adjDef": 98,
      "adjDefRank": 54,
      "adjEM": 10.32,
      "adjOff": 108.3,
      "adjOffRank": 110,
      "adjTempo": 67.9,
      "adjTempoRank": 190,
      "conference": "WCC",
      "rank": 73,
      "record": "24-11"
    },
    "Ball St.": {
      "adjDef": 103.3,
      "adjDefRank": 141,
      "adjEM": -0.08,
      "adjOff": 103.3,
      "adjOffRank": 202,
      "adjTempo": 69.9,
      "adjTempoRank": 83,
      "conference": "MAC",
      "rank": 173,
      "record": "19-13"
    },
    "Baylor": {
      "adjDef": 95.5,
      "adjDefRank": 23,
      "adjEM": 16.02,
      "adjOff": 111.5,
      "adjOffRank": 62,
      "adjTempo": 66.7,
      "adjTempoRank": 249,
      "conference": "B12",
      "rank": 34,
      "record": "19-15"
    },
    "Belmont": {
      "adjDef": 103.5,
      "adjDefRank": 146,
      "adjEM": 9.15,
      "adjOff": 112.7,
      "adjOffRank": 56,
      "adjTempo": 66.9,
      "adjTempoRank": 240,
      "conference": "OVC",
      "rank": 83,
      "record": "24-9"
    },
    "Bethune Cookman": {
      "adjDef": 110.6,
      "adjDefRank": 288,
      "adjEM": -11.05,
      "adjOff": 99.5,
      "adjOffRank": 262,
      "adjTempo": 76,
      "adjTempoRank": 3,
      "conference": "MEAC",
      "rank": 288,
      "record": "18-14"
    },
    "Binghamton": {
      "adjDef": 109.4,
      "adjDefRank": 262,
      "adjEM": -11.93,
      "adjOff": 97.4,
      "adjOffRank": 307,
      "adjTempo": 66.1,
      "adjTempoRank": 280,
      "conference": "AE",
      "rank": 297,
      "record": "11-20"
    },
    "Boise St.": {
      "adjDef": 97,
      "adjDefRank": 42,
      "adjEM": 12.9,
      "adjOff": 109.9,
      "adjOffRank": 82,
      "adjTempo": 68.8,
      "adjTempoRank": 141,
      "conference": "MWC",
      "rank": 61,
      "record": "23-9"
    },
    "Boston College": {
      "adjDef": 102.9,
      "adjDefRank": 128,
      "adjEM": 10.21,
      "adjOff": 113.1,
      "adjOffRank": 52,
      "adjTempo": 70.2,
      "adjTempoRank": 73,
      "conference": "ACC",
      "rank": 77,
      "record": "19-16"
    },
    "Boston University": {
      "adjDef": 108.4,
      "adjDefRank": 240,
      "adjEM": -8.21,
      "adjOff": 100.2,
      "adjOffRank": 255,
      "adjTempo": 67.5,
      "adjTempoRank": 208,
      "conference": "Pat",
      "rank": 258,
      "record": "15-16"
    },
    "Bowling Green": {
      "adjDef": 108.1,
      "adjDefRank": 233,
      "adjEM": -6.04,
      "adjOff": 102,
      "adjOffRank": 231,
      "adjTempo": 70,
      "adjTempoRank": 76,
      "conference": "MAC",
      "rank": 232,
      "record": "16-16"
    },
    "Bradley": {
      "adjDef": 100.4,
      "adjDefRank": 84,
      "adjEM": 3.98,
      "adjOff": 104.4,
      "adjOffRank": 184,
      "adjTempo": 65.8,
      "adjTempoRank": 292,
      "conference": "MVC",
      "rank": 124,
      "record": "20-13"
    },
    "Brown": {
      "adjDef": 110,
      "adjDefRank": 274,
      "adjEM": -9.16,
      "adjOff": 100.8,
      "adjOffRank": 246,
      "adjTempo": 71.7,
      "adjTempoRank": 33,
      "conference": "Ivy",
      "rank": 267,
      "record": "11-16"
    },
    "Bryant": {
      "adjDef": 120.7,
      "adjDefRank": 350,
      "adjEM": -22.06,
      "adjOff": 98.7,
      "adjOffRank": 283,
      "adjTempo": 70.1,
      "adjTempoRank": 74,
      "conference": "NEC",
      "rank": 344,
      "record": "3-28"
    },
    "Bucknell": {
      "adjDef": 102.2,
      "adjDefRank": 116,
      "adjEM": 7.31,
      "adjOff": 109.5,
      "adjOffRank": 90,
      "adjTempo": 71.4,
      "adjTempoRank": 40,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 97,
      "record": "25-10",
      "seed": 14
    },
    "Buffalo": {
      "adjDef": 103.2,
      "adjDefRank": 135,
      "adjEM": 11.53,
      "adjOff": 114.7,
      "adjOffRank": 34,
      "adjTempo": 72.7,
      "adjTempoRank": 16,
      "conference": "MAC",
      "finish": "Round of 32",
      "rank": 67,
      "record": "27-9",
      "seed": 13
    },
    "Butler": {
      "adjDef": 97.2,
      "adjDefRank": 45,
      "adjEM": 19.05,
      "adjOff": 116.2,
      "adjOffRank": 22,
      "adjTempo": 67.6,
      "adjTempoRank": 207,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 20,
      "record": "21-14",
      "seed": 10
    },
    "Cal Poly": {
      "adjDef": 113.2,
      "adjDefRank": 320,
      "adjEM": -14.98,
      "adjOff": 98.2,
      "adjOffRank": 294,
      "adjTempo": 66.2,
      "adjTempoRank": 270,
      "conference": "BW",
      "rank": 325,
      "record": "9-22"
    },
    "Cal St. Bakersfield": {
      "adjDef": 103.9,
      "adjDefRank": 157,
      "adjEM": -6.65,
      "adjOff": 97.3,
      "adjOffRank": 308,
      "adjTempo": 65.3,
      "adjTempoRank": 314,
      "conference": "WAC",
      "rank": 242,
      "record": "12-18"
    },
    "Cal St. Fullerton": {
      "adjDef": 103.1,
      "adjDefRank": 133,
      "adjEM": 0.62,
      "adjOff": 103.7,
      "adjOffRank": 192,
      "adjTempo": 69.6,
      "adjTempoRank": 98,
      "conference": "BW",
      "finish": "Round of 64",
      "rank": 162,
      "record": "20-12",
      "seed": 15
    },
    "Cal St. Northridge": {
      "adjDef": 113.2,
      "adjDefRank": 319,
      "adjEM": -16.46,
      "adjOff": 96.7,
      "adjOffRank": 313,
      "adjTempo": 65.8,
      "adjTempoRank": 290,
      "conference": "BW",
      "rank": 330,
      "record": "6-24"
    },
    "California": {
      "adjDef": 105.4,
      "adjDefRank": 181,
      "adjEM": -7.27,
      "adjOff": 98.2,
      "adjOffRank": 296,
      "adjTempo": 68.7,
      "adjTempoRank": 154,
      "conference": "P12",
      "rank": 244,
      "record": "8-24"
    },
    "Campbell": {
      "adjDef": 110.8,
      "adjDefRank": 294,
      "adjEM": -5.01,
      "adjOff": 105.8,
      "adjOffRank": 155,
      "adjTempo": 68,
      "adjTempoRank": 184,
      "conference": "BSth",
      "rank": 218,
      "record": "18-16"
    },
    "Canisius": {
      "adjDef": 104.1,
      "adjDefRank": 159,
      "adjEM": 3.05,
      "adjOff": 107.2,
      "adjOffRank": 133,
      "adjTempo": 68.9,
      "adjTempoRank": 131,
      "conference": "MAAC",
      "rank": 131,
      "record": "21-12"
    },
    "Central Arkansas": {
      "adjDef": 109.8,
      "adjDefRank": 268,
      "adjEM": -4.41,
      "adjOff": 105.4,
      "adjOffRank": 164,
      "adjTempo": 72.7,
      "adjTempoRank": 17,
      "conference": "Slnd",
      "rank": 213,
      "record": "18-17"
    },
    "Central Connecticut": {
      "adjDef": 110.8,
      "adjDefRank": 296,
      "adjEM": -12.85,
      "adjOff": 98,
      "adjOffRank": 300,
      "adjTempo": 64.5,
      "adjTempoRank": 329,
      "conference": "NEC",
      "rank": 310,
      "record": "14-18"
    },
    "Central Michigan": {
      "adjDef": 109.9,
      "adjDefRank": 272,
      "adjEM": 0.13,
      "adjOff": 110.1,
      "adjOffRank": 79,
      "adjTempo": 67.6,
      "adjTempoRank": 205,
      "conference": "MAC",
      "rank": 168,
      "record": "21-15"
    },
    "Charleston Southern": {
      "adjDef": 106.8,
      "adjDefRank": 206,
      "adjEM": -7.43,
      "adjOff": 99.4,
      "adjOffRank": 265,
      "adjTempo": 66.5,
      "adjTempoRank": 262,
      "conference": "BSth",
      "rank": 246,
      "record": "15-16"
    },
    "Charlotte": {
      "adjDef": 112.1,
      "adjDefRank": 312,
      "adjEM": -12.81,
      "adjOff": 99.3,
      "adjOffRank": 267,
      "adjTempo": 71.2,
      "adjTempoRank": 48,
      "conference": "CUSA",
      "rank": 308,
      "record": "6-23"
    },
    "Chattanooga": {
      "adjDef": 111.8,
      "adjDefRank": 304,
      "adjEM": -12.53,
      "adjOff": 99.2,
      "adjOffRank": 271,
      "adjTempo": 65.5,
      "adjTempoRank": 308,
      "conference": "SC",
      "rank": 304,
      "record": "10-23"
    },
    "Chicago St.": {
      "adjDef": 119.1,
      "adjDefRank": 348,
      "adjEM": -22.59,
      "adjOff": 96.5,
      "adjOffRank": 315,
      "adjTempo": 72.1,
      "adjTempoRank": 26,
      "conference": "WAC",
      "rank": 346,
      "record": "3-29"
    },
    "Cincinnati": {
      "adjDef": 86.8,
      "adjDefRank": 2,
      "adjEM": 26.6,
      "adjOff": 113.4,
      "adjOffRank": 49,
      "adjTempo": 64.5,
      "adjTempoRank": 330,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 4,
      "record": "31-5",
      "seed": 2
    },
    "Clemson": {
      "adjDef": 92.6,
      "adjDefRank": 7,
      "adjEM": 21.65,
      "adjOff": 114.2,
      "adjOffRank": 44,
      "adjTempo": 66.1,
      "adjTempoRank": 274,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "25-10",
      "seed": 5
    },
    "Cleveland St.": {
      "adjDef": 110,
      "adjDefRank": 277,
      "adjEM": -12.14,
      "adjOff": 97.9,
      "adjOffRank": 302,
      "adjTempo": 67.1,
      "adjTempoRank": 231,
      "conference": "Horz",
      "rank": 300,
      "record": "12-23"
    },
    "Coastal Carolina": {
      "adjDef": 105.9,
      "adjDefRank": 189,
      "adjEM": -4.19,
      "adjOff": 101.8,
      "adjOffRank": 235,
      "adjTempo": 67.8,
      "adjTempoRank": 194,
      "conference": "SB",
      "rank": 209,
      "record": "14-18"
    },
    "Colgate": {
      "adjDef": 108.7,
      "adjDefRank": 252,
      "adjEM": -3.72,
      "adjOff": 105,
      "adjOffRank": 170,
      "adjTempo": 66.6,
      "adjTempoRank": 254,
      "conference": "Pat",
      "rank": 202,
      "record": "19-14"
    },
    "College of Charleston": {
      "adjDef": 103.6,
      "adjDefRank": 149,
      "adjEM": 5.16,
      "adjOff": 108.7,
      "adjOffRank": 105,
      "adjTempo": 64.9,
      "adjTempoRank": 323,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 115,
      "record": "26-8",
      "seed": 13
    },
    "Colorado": {
      "adjDef": 99.6,
      "adjDefRank": 77,
      "adjEM": 5.27,
      "adjOff": 104.9,
      "adjOffRank": 173,
      "adjTempo": 67.8,
      "adjTempoRank": 197,
      "conference": "P12",
      "rank": 114,
      "record": "17-15"
    },
    "Colorado St.": {
      "adjDef": 108.3,
      "adjDefRank": 237,
      "adjEM": -5.66,
      "adjOff": 102.6,
      "adjOffRank": 214,
      "adjTempo": 69.6,
      "adjTempoRank": 97,
      "conference": "MWC",
      "rank": 224,
      "record": "11-21"
    },
    "Columbia": {
      "adjDef": 113.3,
      "adjDefRank": 321,
      "adjEM": -6.01,
      "adjOff": 107.3,
      "adjOffRank": 128,
      "adjTempo": 70,
      "adjTempoRank": 80,
      "conference": "Ivy",
      "rank": 231,
      "record": "8-19"
    },
    "Connecticut": {
      "adjDef": 105.4,
      "adjDefRank": 178,
      "adjEM": -0.43,
      "adjOff": 105,
      "adjOffRank": 171,
      "adjTempo": 67,
      "adjTempoRank": 236,
      "conference": "Amer",
      "rank": 179,
      "record": "14-18"
    },
    "Coppin St.": {
      "adjDef": 110.5,
      "adjDefRank": 284,
      "adjEM": -23.97,
      "adjOff": 86.5,
      "adjOffRank": 351,
      "adjTempo": 67.1,
      "adjTempoRank": 232,
      "conference": "MEAC",
      "rank": 347,
      "record": "5-27"
    },
    "Cornell": {
      "adjDef": 113.6,
      "adjDefRank": 324,
      "adjEM": -8.9,
      "adjOff": 104.7,
      "adjOffRank": 177,
      "adjTempo": 69.2,
      "adjTempoRank": 117,
      "conference": "Ivy",
      "rank": 265,
      "record": "12-16"
    },
    "Creighton": {
      "adjDef": 98.6,
      "adjDefRank": 58,
      "adjEM": 16.91,
      "adjOff": 115.5,
      "adjOffRank": 25,
      "adjTempo": 70.8,
      "adjTempoRank": 57,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 30,
      "record": "21-12",
      "seed": 8
    },
    "Dartmouth": {
      "adjDef": 110.6,
      "adjDefRank": 289,
      "adjEM": -12.09,
      "adjOff": 98.5,
      "adjOffRank": 287,
      "adjTempo": 66.8,
      "adjTempoRank": 245,
      "conference": "Ivy",
      "rank": 299,
      "record": "7-20"
    },
    "Davidson": {
      "adjDef": 102.3,
      "adjDefRank": 120,
      "adjEM": 14.7,
      "adjOff": 117,
      "adjOffRank": 16,
      "adjTempo": 64.5,
      "adjTempoRank": 332,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 43,
      "record": "21-12",
      "seed": 12
    },
    "Dayton": {
      "adjDef": 108.3,
      "adjDefRank": 238,
      "adjEM": 0.02,
      "adjOff": 108.4,
      "adjOffRank": 109,
      "adjTempo": 66.3,
      "adjTempoRank": 267,
      "conference": "A10",
      "rank": 172,
      "record": "14-17"
    },
    "DePaul": {
      "adjDef": 97.5,
      "adjDefRank": 49,
      "adjEM": 7.03,
      "adjOff": 104.5,
      "adjOffRank": 183,
      "adjTempo": 69.8,
      "adjTempoRank": 89,
      "conference": "BE",
      "rank": 99,
      "record": "11-20"
    },
    "Delaware": {
      "adjDef": 109.6,
      "adjDefRank": 267,
      "adjEM": -7.57,
      "adjOff": 102,
      "adjOffRank": 232,
      "adjTempo": 65.9,
      "adjTempoRank": 285,
      "conference": "CAA",
      "rank": 247,
      "record": "14-19"
    },
    "Delaware St.": {
      "adjDef": 118.3,
      "adjDefRank": 344,
      "adjEM": -27.94,
      "adjOff": 90.3,
      "adjOffRank": 344,
      "adjTempo": 64.9,
      "adjTempoRank": 322,
      "conference": "MEAC",
      "rank": 350,
      "record": "4-28"
    },
    "Denver": {
      "adjDef": 106.8,
      "adjDefRank": 203,
      "adjEM": -4.23,
      "adjOff": 102.6,
      "adjOffRank": 217,
      "adjTempo": 66.4,
      "adjTempoRank": 265,
      "conference": "Sum",
      "rank": 210,
      "record": "15-15"
    },
    "Detroit": {
      "adjDef": 116.8,
      "adjDefRank": 342,
      "adjEM": -14.2,
      "adjOff": 102.6,
      "adjOffRank": 215,
      "adjTempo": 72.4,
      "adjTempoRank": 21,
      "conference": "Horz",
      "rank": 319,
      "record": "8-24"
    },
    "Drake": {
      "adjDef": 104.8,
      "adjDefRank": 171,
      "adjEM": 1.09,
      "adjOff": 105.9,
      "adjOffRank": 152,
      "adjTempo": 68.4,
      "adjTempoRank": 169,
      "conference": "MVC",
      "rank": 155,
      "record": "17-17"
    },
    "Drexel": {
      "adjDef": 110.7,
      "adjDefRank": 292,
      "adjEM": -7.73,
      "adjOff": 103,
      "adjOffRank": 211,
      "adjTempo": 69,
      "adjTempoRank": 123,
      "conference": "CAA",
      "rank": 251,
      "record": "13-20"
    },
    "Duke": {
      "adjDef": 93.6,
      "adjDefRank": 9,
      "adjEM": 28.86,
      "adjOff": 122.4,
      "adjOffRank": 3,
      "adjTempo": 69.7,
      "adjTempoRank": 93,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 3,
      "record": "29-8",
      "seed": 2
    },
    "Duquesne": {
      "adjDef": 108.2,
      "adjDefRank": 236,
      "adjEM": -3.63,
      "adjOff": 104.6,
      "adjOffRank": 179,
      "adjTempo": 66.2,
      "adjTempoRank": 269,
      "conference": "A10",
      "rank": 201,
      "record": "16-16"
    },
    "East Carolina": {
      "adjDef": 109.5,
      "adjDefRank": 263,
      "adjEM": -12.78,
      "adjOff": 96.7,
      "adjOffRank": 312,
      "adjTempo": 68.7,
      "adjTempoRank": 148,
      "conference": "Amer",
      "rank": 307,
      "record": "10-20"
    },
    "East Tennessee St.": {
      "adjDef": 97.8,
      "adjDefRank": 52,
      "adjEM": 7.49,
      "adjOff": 105.3,
      "adjOffRank": 166,
      "adjTempo": 67.6,
      "adjTempoRank": 203,
      "conference": "SC",
      "rank": 93,
      "record": "25-9"
    },
    "Eastern Illinois": {
      "adjDef": 107.1,
      "adjDefRank": 213,
      "adjEM": -8.14,
      "adjOff": 99,
      "adjOffRank": 277,
      "adjTempo": 65.5,
      "adjTempoRank": 305,
      "conference": "OVC",
      "rank": 257,
      "record": "12-19"
    },
    "Eastern Kentucky": {
      "adjDef": 112.6,
      "adjDefRank": 316,
      "adjEM": -10.96,
      "adjOff": 101.7,
      "adjOffRank": 237,
      "adjTempo": 68.6,
      "adjTempoRank": 161,
      "conference": "OVC",
      "rank": 286,
      "record": "11-20"
    },
    "Eastern Michigan": {
      "adjDef": 100,
      "adjDefRank": 81,
      "adjEM": 2.31,
      "adjOff": 102.3,
      "adjOffRank": 218,
      "adjTempo": 65.1,
      "adjTempoRank": 319,
      "conference": "MAC",
      "rank": 143,
      "record": "22-13"
    },
    "Eastern Washington": {
      "adjDef": 104.6,
      "adjDefRank": 164,
      "adjEM": 2.67,
      "adjOff": 107.3,
      "adjOffRank": 130,
      "adjTempo": 66.5,
      "adjTempoRank": 258,
      "conference": "BSky",
      "rank": 136,
      "record": "20-15"
    },
    "Elon": {
      "adjDef": 111.3,
      "adjDefRank": 301,
      "adjEM": -7.62,
      "adjOff": 103.6,
      "adjOffRank": 193,
      "adjTempo": 67.3,
      "adjTempoRank": 216,
      "conference": "CAA",
      "rank": 248,
      "record": "14-18"
    },
    "Evansville": {
      "adjDef": 96.5,
      "adjDefRank": 32,
      "adjEM": 2.85,
      "adjOff": 99.3,
      "adjOffRank": 266,
      "adjTempo": 65.7,
      "adjTempoRank": 295,
      "conference": "MVC",
      "rank": 134,
      "record": "17-15"
    },
    "FIU": {
      "adjDef": 103.7,
      "adjDefRank": 154,
      "adjEM": -5.33,
      "adjOff": 98.4,
      "adjOffRank": 292,
      "adjTempo": 69,
      "adjTempoRank": 128,
      "conference": "CUSA",
      "rank": 222,
      "record": "14-18"
    },
    "Fairfield": {
      "adjDef": 106.4,
      "adjDefRank": 195,
      "adjEM": -4.37,
      "adjOff": 102.1,
      "adjOffRank": 229,
      "adjTempo": 70.5,
      "adjTempoRank": 63,
      "conference": "MAAC",
      "rank": 212,
      "record": "17-16"
    },
    "Fairleigh Dickinson": {
      "adjDef": 112.4,
      "adjDefRank": 315,
      "adjEM": -10.16,
      "adjOff": 102.2,
      "adjOffRank": 222,
      "adjTempo": 70.5,
      "adjTempoRank": 66,
      "conference": "NEC",
      "rank": 274,
      "record": "13-18"
    },
    "Florida": {
      "adjDef": 95.5,
      "adjDefRank": 24,
      "adjEM": 18.96,
      "adjOff": 114.5,
      "adjOffRank": 41,
      "adjTempo": 67.1,
      "adjTempoRank": 226,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "21-13",
      "seed": 6
    },
    "Florida A&M": {
      "adjDef": 116.8,
      "adjDefRank": 341,
      "adjEM": -21.21,
      "adjOff": 95.6,
      "adjOffRank": 325,
      "adjTempo": 67.1,
      "adjTempoRank": 230,
      "conference": "MEAC",
      "rank": 341,
      "record": "9-25"
    },
    "Florida Atlantic": {
      "adjDef": 102,
      "adjDefRank": 112,
      "adjEM": -6.1,
      "adjOff": 95.9,
      "adjOffRank": 321,
      "adjTempo": 69.2,
      "adjTempoRank": 119,
      "conference": "CUSA",
      "rank": 233,
      "record": "12-19"
    },
    "Florida Gulf Coast": {
      "adjDef": 104,
      "adjDefRank": 158,
      "adjEM": 3.47,
      "adjOff": 107.5,
      "adjOffRank": 124,
      "adjTempo": 71.6,
      "adjTempoRank": 35,
      "conference": "ASun",
      "rank": 127,
      "record": "23-12"
    },
    "Florida St.": {
      "adjDef": 96.5,
      "adjDefRank": 33,
      "adjEM": 17.77,
      "adjOff": 114.3,
      "adjOffRank": 43,
      "adjTempo": 71.2,
      "adjTempoRank": 45,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 27,
      "record": "23-12",
      "seed": 9
    },
    "Fordham": {
      "adjDef": 106.1,
      "adjDefRank": 191,
      "adjEM": -11.63,
      "adjOff": 94.5,
      "adjOffRank": 334,
      "adjTempo": 66,
      "adjTempoRank": 283,
      "conference": "A10",
      "rank": 292,
      "record": "9-22"
    },
    "Fort Wayne": {
      "adjDef": 107.2,
      "adjDefRank": 215,
      "adjEM": 0.5,
      "adjOff": 107.7,
      "adjOffRank": 118,
      "adjTempo": 72.6,
      "adjTempoRank": 19,
      "conference": "Sum",
      "rank": 165,
      "record": "18-15"
    },
    "Fresno St.": {
      "adjDef": 99.6,
      "adjDefRank": 76,
      "adjEM": 10.07,
      "adjOff": 109.7,
      "adjOffRank": 85,
      "adjTempo": 66.6,
      "adjTempoRank": 252,
      "conference": "MWC",
      "rank": 79,
      "record": "21-11"
    },
    "Furman": {
      "adjDef": 101.5,
      "adjDefRank": 104,
      "adjEM": 7.41,
      "adjOff": 108.9,
      "adjOffRank": 99,
      "adjTempo": 68.6,
      "adjTempoRank": 156,
      "conference": "SC",
      "rank": 95,
      "record": "23-10"
    },
    "Gardner Webb": {
      "adjDef": 106.1,
      "adjDefRank": 192,
      "adjEM": -8.52,
      "adjOff": 97.6,
      "adjOffRank": 304,
      "adjTempo": 67.7,
      "adjTempoRank": 199,
      "conference": "BSth",
      "rank": 261,
      "record": "14-18"
    },
    "George Mason": {
      "adjDef": 107.3,
      "adjDefRank": 216,
      "adjEM": -5.17,
      "adjOff": 102.1,
      "adjOffRank": 223,
      "adjTempo": 69.1,
      "adjTempoRank": 122,
      "conference": "A10",
      "rank": 220,
      "record": "16-17"
    },
    "George Washington": {
      "adjDef": 106.2,
      "adjDefRank": 193,
      "adjEM": -2.66,
      "adjOff": 103.6,
      "adjOffRank": 196,
      "adjTempo": 65.8,
      "adjTempoRank": 288,
      "conference": "A10",
      "rank": 192,
      "record": "15-18"
    },
    "Georgetown": {
      "adjDef": 102.3,
      "adjDefRank": 119,
      "adjEM": 7.44,
      "adjOff": 109.7,
      "adjOffRank": 84,
      "adjTempo": 70.5,
      "adjTempoRank": 65,
      "conference": "BE",
      "rank": 94,
      "record": "15-15"
    },
    "Georgia": {
      "adjDef": 95.9,
      "adjDefRank": 27,
      "adjEM": 11.64,
      "adjOff": 107.5,
      "adjOffRank": 122,
      "adjTempo": 64.4,
      "adjTempoRank": 334,
      "conference": "SEC",
      "rank": 65,
      "record": "18-15"
    },
    "Georgia Southern": {
      "adjDef": 104.3,
      "adjDefRank": 161,
      "adjEM": 1.65,
      "adjOff": 105.9,
      "adjOffRank": 153,
      "adjTempo": 68.9,
      "adjTempoRank": 134,
      "conference": "SB",
      "rank": 152,
      "record": "21-12"
    },
    "Georgia St.": {
      "adjDef": 101.9,
      "adjDefRank": 110,
      "adjEM": 6.92,
      "adjOff": 108.8,
      "adjOffRank": 102,
      "adjTempo": 66.8,
      "adjTempoRank": 246,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 101,
      "record": "24-11",
      "seed": 15
    },
    "Georgia Tech": {
      "adjDef": 98.7,
      "adjDefRank": 61,
      "adjEM": 4.9,
      "adjOff": 103.6,
      "adjOffRank": 197,
      "adjTempo": 66.1,
      "adjTempoRank": 277,
      "conference": "ACC",
      "rank": 119,
      "record": "13-19"
    },
    "Gonzaga": {
      "adjDef": 95.1,
      "adjDefRank": 18,
      "adjEM": 23.03,
      "adjOff": 118.1,
      "adjOffRank": 14,
      "adjTempo": 69.4,
      "adjTempoRank": 107,
      "conference": "WCC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "32-5",
      "seed": 4
    },
    "Grambling St.": {
      "adjDef": 106,
      "adjDefRank": 190,
      "adjEM": -11.71,
      "adjOff": 94.3,
      "adjOffRank": 336,
      "adjTempo": 72.2,
      "adjTempoRank": 25,
      "conference": "SWAC",
      "rank": 295,
      "record": "17-14"
    },
    "Grand Canyon": {
      "adjDef": 97.5,
      "adjDefRank": 50,
      "adjEM": 4.53,
      "adjOff": 102.1,
      "adjOffRank": 226,
      "adjTempo": 69,
      "adjTempoRank": 125,
      "conference": "WAC",
      "rank": 121,
      "record": "22-12"
    },
    "Green Bay": {
      "adjDef": 108.6,
      "adjDefRank": 248,
      "adjEM": -8.67,
      "adjOff": 99.9,
      "adjOffRank": 258,
      "adjTempo": 72.2,
      "adjTempoRank": 23,
      "conference": "Horz",
      "rank": 263,
      "record": "13-20"
    },
    "Hampton": {
      "adjDef": 108.7,
      "adjDefRank": 251,
      "adjEM": -7.68,
      "adjOff": 101,
      "adjOffRank": 243,
      "adjTempo": 71.9,
      "adjTempoRank": 28,
      "conference": "MEAC",
      "rank": 250,
      "record": "19-16"
    },
    "Hartford": {
      "adjDef": 107.8,
      "adjDefRank": 231,
      "adjEM": -4.89,
      "adjOff": 102.9,
      "adjOffRank": 213,
      "adjTempo": 66.5,
      "adjTempoRank": 257,
      "conference": "AE",
      "rank": 215,
      "record": "19-14"
    },
    "Harvard": {
      "adjDef": 98.3,
      "adjDefRank": 55,
      "adjEM": 2.33,
      "adjOff": 100.6,
      "adjOffRank": 248,
      "adjTempo": 65.6,
      "adjTempoRank": 301,
      "conference": "Ivy",
      "rank": 141,
      "record": "18-14"
    },
    "Hawaii": {
      "adjDef": 102.5,
      "adjDefRank": 121,
      "adjEM": -3.38,
      "adjOff": 99.1,
      "adjOffRank": 273,
      "adjTempo": 67.9,
      "adjTempoRank": 192,
      "conference": "BW",
      "rank": 200,
      "record": "17-13"
    },
    "High Point": {
      "adjDef": 106.6,
      "adjDefRank": 199,
      "adjEM": -6.51,
      "adjOff": 100.1,
      "adjOffRank": 257,
      "adjTempo": 65.9,
      "adjTempoRank": 287,
      "conference": "BSth",
      "rank": 241,
      "record": "14-16"
    },
    "Hofstra": {
      "adjDef": 110.1,
      "adjDefRank": 282,
      "adjEM": 0.99,
      "adjOff": 111.1,
      "adjOffRank": 71,
      "adjTempo": 68.9,
      "adjTempoRank": 136,
      "conference": "CAA",
      "rank": 157,
      "record": "19-12"
    },
    "Holy Cross": {
      "adjDef": 108.9,
      "adjDefRank": 256,
      "adjEM": -11.36,
      "adjOff": 97.5,
      "adjOffRank": 306,
      "adjTempo": 62.9,
      "adjTempoRank": 346,
      "conference": "Pat",
      "rank": 290,
      "record": "12-19"
    },
    "Houston": {
      "adjDef": 94.4,
      "adjDefRank": 13,
      "adjEM": 20.24,
      "adjOff": 114.6,
      "adjOffRank": 38,
      "adjTempo": 68.4,
      "adjTempoRank": 167,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 18,
      "record": "27-8",
      "seed": 6
    },
    "Houston Baptist": {
      "adjDef": 116.5,
      "adjDefRank": 339,
      "adjEM": -17.64,
      "adjOff": 98.9,
      "adjOffRank": 280,
      "adjTempo": 73.5,
      "adjTempoRank": 14,
      "conference": "Slnd",
      "rank": 333,
      "record": "6-25"
    },
    "Howard": {
      "adjDef": 118.5,
      "adjDefRank": 346,
      "adjEM": -19.04,
      "adjOff": 99.4,
      "adjOffRank": 263,
      "adjTempo": 69.9,
      "adjTempoRank": 84,
      "conference": "MEAC",
      "rank": 335,
      "record": "10-23"
    },
    "IUPUI": {
      "adjDef": 109,
      "adjDefRank": 259,
      "adjEM": -10.72,
      "adjOff": 98.3,
      "adjOffRank": 293,
      "adjTempo": 66.1,
      "adjTempoRank": 276,
      "conference": "Horz",
      "rank": 284,
      "record": "11-19"
    },
    "Idaho": {
      "adjDef": 105.6,
      "adjDefRank": 184,
      "adjEM": 3.54,
      "adjOff": 109.2,
      "adjOffRank": 95,
      "adjTempo": 65.6,
      "adjTempoRank": 303,
      "conference": "BSky",
      "rank": 126,
      "record": "22-9"
    },
    "Idaho St.": {
      "adjDef": 108.9,
      "adjDefRank": 257,
      "adjEM": -5.7,
      "adjOff": 103.2,
      "adjOffRank": 204,
      "adjTempo": 68.1,
      "adjTempoRank": 181,
      "conference": "BSky",
      "rank": 226,
      "record": "14-16"
    },
    "Illinois": {
      "adjDef": 103,
      "adjDefRank": 129,
      "adjEM": 6.67,
      "adjOff": 109.6,
      "adjOffRank": 86,
      "adjTempo": 69.9,
      "adjTempoRank": 81,
      "conference": "B10",
      "rank": 102,
      "record": "14-18"
    },
    "Illinois Chicago": {
      "adjDef": 101.8,
      "adjDefRank": 107,
      "adjEM": -0.84,
      "adjOff": 100.9,
      "adjOffRank": 245,
      "adjTempo": 72.5,
      "adjTempoRank": 20,
      "conference": "Horz",
      "rank": 182,
      "record": "20-16"
    },
    "Illinois St.": {
      "adjDef": 103.3,
      "adjDefRank": 139,
      "adjEM": 2.39,
      "adjOff": 105.7,
      "adjOffRank": 157,
      "adjTempo": 68.9,
      "adjTempoRank": 138,
      "conference": "MVC",
      "rank": 140,
      "record": "18-15"
    },
    "Incarnate Word": {
      "adjDef": 115.1,
      "adjDefRank": 334,
      "adjEM": -21.6,
      "adjOff": 93.5,
      "adjOffRank": 341,
      "adjTempo": 69.7,
      "adjTempoRank": 95,
      "conference": "Slnd",
      "rank": 342,
      "record": "7-21"
    },
    "Indiana": {
      "adjDef": 99,
      "adjDefRank": 65,
      "adjEM": 10.44,
      "adjOff": 109.4,
      "adjOffRank": 92,
      "adjTempo": 66.6,
      "adjTempoRank": 251,
      "conference": "B10",
      "rank": 71,
      "record": "16-15"
    },
    "Indiana St.": {
      "adjDef": 102.1,
      "adjDefRank": 113,
      "adjEM": 2.63,
      "adjOff": 104.7,
      "adjOffRank": 176,
      "adjTempo": 69.9,
      "adjTempoRank": 82,
      "conference": "MVC",
      "rank": 138,
      "record": "13-18"
    },
    "Iona": {
      "adjDef": 107.3,
      "adjDefRank": 218,
      "adjEM": 2.63,
      "adjOff": 110,
      "adjOffRank": 80,
      "adjTempo": 70.7,
      "adjTempoRank": 58,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 137,
      "record": "20-14",
      "seed": 15
    },
    "Iowa": {
      "adjDef": 108.4,
      "adjDefRank": 242,
      "adjEM": 8.14,
      "adjOff": 116.6,
      "adjOffRank": 19,
      "adjTempo": 70.3,
      "adjTempoRank": 71,
      "conference": "B10",
      "rank": 88,
      "record": "14-19"
    },
    "Iowa St.": {
      "adjDef": 103.4,
      "adjDefRank": 143,
      "adjEM": 6.58,
      "adjOff": 109.9,
      "adjOffRank": 81,
      "adjTempo": 68.9,
      "adjTempoRank": 130,
      "conference": "B12",
      "rank": 103,
      "record": "13-18"
    },
    "Jackson St.": {
      "adjDef": 109,
      "adjDefRank": 260,
      "adjEM": -19.6,
      "adjOff": 89.4,
      "adjOffRank": 348,
      "adjTempo": 64.1,
      "adjTempoRank": 338,
      "conference": "SWAC",
      "rank": 337,
      "record": "12-20"
    },
    "Jacksonville": {
      "adjDef": 108.4,
      "adjDefRank": 241,
      "adjEM": -14.27,
      "adjOff": 94.2,
      "adjOffRank": 337,
      "adjTempo": 68.6,
      "adjTempoRank": 157,
      "conference": "ASun",
      "rank": 320,
      "record": "15-18"
    },
    "Jacksonville St.": {
      "adjDef": 100,
      "adjDefRank": 79,
      "adjEM": 2.32,
      "adjOff": 102.3,
      "adjOffRank": 219,
      "adjTempo": 66.2,
      "adjTempoRank": 271,
      "conference": "OVC",
      "rank": 142,
      "record": "23-13"
    },
    "James Madison": {
      "adjDef": 107.4,
      "adjDefRank": 221,
      "adjEM": -5.97,
      "adjOff": 101.4,
      "adjOffRank": 241,
      "adjTempo": 67.9,
      "adjTempoRank": 191,
      "conference": "CAA",
      "rank": 229,
      "record": "10-22"
    },
    "Kansas": {
      "adjDef": 97.4,
      "adjDefRank": 47,
      "adjEM": 23.49,
      "adjOff": 120.9,
      "adjOffRank": 5,
      "adjTempo": 68.8,
      "adjTempoRank": 144,
      "conference": "B12",
      "finish": "Final Four",
      "rank": 9,
      "record": "31-8",
      "seed": 1
    },
    "Kansas St.": {
      "adjDef": 95.3,
      "adjDefRank": 21,
      "adjEM": 14.77,
      "adjOff": 110.1,
      "adjOffRank": 78,
      "adjTempo": 65.6,
      "adjTempoRank": 302,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 42,
      "record": "25-12",
      "seed": 9
    },
    "Kennesaw St.": {
      "adjDef": 111.8,
      "adjDefRank": 305,
      "adjEM": -12.82,
      "adjOff": 98.9,
      "adjOffRank": 279,
      "adjTempo": 68.5,
      "adjTempoRank": 163,
      "conference": "ASun",
      "rank": 309,
      "record": "10-20"
    },
    "Kent St.": {
      "adjDef": 106.4,
      "adjDefRank": 194,
      "adjEM": -4.34,
      "adjOff": 102.1,
      "adjOffRank": 227,
      "adjTempo": 68.7,
      "adjTempoRank": 152,
      "conference": "MAC",
      "rank": 211,
      "record": "17-17"
    },
    "Kentucky": {
      "adjDef": 95.4,
      "adjDefRank": 22,
      "adjEM": 20.44,
      "adjOff": 115.8,
      "adjOffRank": 24,
      "adjTempo": 68.8,
      "adjTempoRank": 146,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 17,
      "record": "26-11",
      "seed": 5
    },
    "LIU Brooklyn": {
      "adjDef": 110.6,
      "adjDefRank": 287,
      "adjEM": -8.27,
      "adjOff": 102.3,
      "adjOffRank": 220,
      "adjTempo": 71.6,
      "adjTempoRank": 37,
      "conference": "NEC",
      "finish": "First Four",
      "rank": 259,
      "record": "18-17",
      "seed": 16
    },
    "LSU": {
      "adjDef": 103.2,
      "adjDefRank": 136,
      "adjEM": 11.62,
      "adjOff": 114.8,
      "adjOffRank": 33,
      "adjTempo": 68.7,
      "adjTempoRank": 149,
      "conference": "SEC",
      "rank": 66,
      "record": "18-15"
    },
    "La Salle": {
      "adjDef": 103.8,
      "adjDefRank": 155,
      "adjEM": 1.06,
      "adjOff": 104.9,
      "adjOffRank": 175,
      "adjTempo": 68.9,
      "adjTempoRank": 129,
      "conference": "A10",
      "rank": 156,
      "record": "13-19"
    },
    "Lafayette": {
      "adjDef": 112,
      "adjDefRank": 310,
      "adjEM": -10.17,
      "adjOff": 101.8,
      "adjOffRank": 233,
      "adjTempo": 68.3,
      "adjTempoRank": 174,
      "conference": "Pat",
      "rank": 275,
      "record": "10-21"
    },
    "Lamar": {
      "adjDef": 105.3,
      "adjDefRank": 177,
      "adjEM": -3.72,
      "adjOff": 101.6,
      "adjOffRank": 239,
      "adjTempo": 67.8,
      "adjTempoRank": 195,
      "conference": "Slnd",
      "rank": 203,
      "record": "19-14"
    },
    "Lehigh": {
      "adjDef": 111.9,
      "adjDefRank": 309,
      "adjEM": -6.23,
      "adjOff": 105.7,
      "adjOffRank": 156,
      "adjTempo": 70.8,
      "adjTempoRank": 56,
      "conference": "Pat",
      "rank": 235,
      "record": "16-14"
    },
    "Liberty": {
      "adjDef": 105.2,
      "adjDefRank": 175,
      "adjEM": -1.22,
      "adjOff": 103.9,
      "adjOffRank": 188,
      "adjTempo": 63.1,
      "adjTempoRank": 343,
      "conference": "BSth",
      "rank": 184,
      "record": "22-15"
    },
    "Lipscomb": {
      "adjDef": 102.8,
      "adjDefRank": 125,
      "adjEM": 0.43,
      "adjOff": 103.2,
      "adjOffRank": 203,
      "adjTempo": 74.9,
      "adjTempoRank": 5,
      "conference": "ASun",
      "finish": "Round of 64",
      "rank": 167,
      "record": "23-10",
      "seed": 15
    },
    "Little Rock": {
      "adjDef": 105.7,
      "adjDefRank": 185,
      "adjEM": -11.91,
      "adjOff": 93.8,
      "adjOffRank": 338,
      "adjTempo": 65,
      "adjTempoRank": 320,
      "conference": "SB",
      "rank": 296,
      "record": "7-25"
    },
    "Long Beach St.": {
      "adjDef": 108.7,
      "adjDefRank": 254,
      "adjEM": -2.62,
      "adjOff": 106.1,
      "adjOffRank": 147,
      "adjTempo": 71.8,
      "adjTempoRank": 30,
      "conference": "BW",
      "rank": 191,
      "record": "15-18"
    },
    "Longwood": {
      "adjDef": 110,
      "adjDefRank": 279,
      "adjEM": -20.41,
      "adjOff": 89.6,
      "adjOffRank": 347,
      "adjTempo": 69.1,
      "adjTempoRank": 121,
      "conference": "BSth",
      "rank": 340,
      "record": "7-26"
    },
    "Louisiana Lafayette": {
      "adjDef": 101.5,
      "adjDefRank": 105,
      "adjEM": 11.14,
      "adjOff": 112.7,
      "adjOffRank": 55,
      "adjTempo": 70.6,
      "adjTempoRank": 59,
      "conference": "SB",
      "rank": 69,
      "record": "27-7"
    },
    "Louisiana Monroe": {
      "adjDef": 106.5,
      "adjDefRank": 196,
      "adjEM": -6,
      "adjOff": 100.5,
      "adjOffRank": 251,
      "adjTempo": 66.1,
      "adjTempoRank": 278,
      "conference": "SB",
      "rank": 230,
      "record": "16-16"
    },
    "Louisiana Tech": {
      "adjDef": 104.9,
      "adjDefRank": 173,
      "adjEM": 2.01,
      "adjOff": 106.9,
      "adjOffRank": 137,
      "adjTempo": 67.3,
      "adjTempoRank": 218,
      "conference": "CUSA",
      "rank": 149,
      "record": "17-16"
    },
    "Louisville": {
      "adjDef": 96.8,
      "adjDefRank": 39,
      "adjEM": 15.41,
      "adjOff": 112.2,
      "adjOffRank": 57,
      "adjTempo": 69.7,
      "adjTempoRank": 92,
      "conference": "ACC",
      "rank": 38,
      "record": "22-14"
    },
    "Loyola Chicago": {
      "adjDef": 95,
      "adjDefRank": 17,
      "adjEM": 16.39,
      "adjOff": 111.4,
      "adjOffRank": 63,
      "adjTempo": 65.5,
      "adjTempoRank": 307,
      "conference": "MVC",
      "finish": "Final Four",
      "rank": 31,
      "record": "32-6",
      "seed": 11
    },
    "Loyola MD": {
      "adjDef": 110.8,
      "adjDefRank": 295,
      "adjEM": -14.58,
      "adjOff": 96.2,
      "adjOffRank": 318,
      "adjTempo": 68.4,
      "adjTempoRank": 168,
      "conference": "Pat",
      "rank": 323,
      "record": "9-22"
    },
    "Loyola Marymount": {
      "adjDef": 113.4,
      "adjDefRank": 322,
      "adjEM": -6.17,
      "adjOff": 107.2,
      "adjOffRank": 131,
      "adjTempo": 66.9,
      "adjTempoRank": 243,
      "conference": "WCC",
      "rank": 234,
      "record": "11-20"
    },
    "Maine": {
      "adjDef": 108.4,
      "adjDefRank": 239,
      "adjEM": -17.46,
      "adjOff": 90.9,
      "adjOffRank": 342,
      "adjTempo": 71.3,
      "adjTempoRank": 41,
      "conference": "AE",
      "rank": 332,
      "record": "6-26"
    },
    "Manhattan": {
      "adjDef": 104.2,
      "adjDefRank": 160,
      "adjEM": -4.95,
      "adjOff": 99.3,
      "adjOffRank": 269,
      "adjTempo": 65.8,
      "adjTempoRank": 291,
      "conference": "MAAC",
      "rank": 216,
      "record": "14-17"
    },
    "Marist": {
      "adjDef": 112.9,
      "adjDefRank": 317,
      "adjEM": -14.47,
      "adjOff": 98.5,
      "adjOffRank": 291,
      "adjTempo": 69.6,
      "adjTempoRank": 96,
      "conference": "MAAC",
      "rank": 321,
      "record": "6-25"
    },
    "Marquette": {
      "adjDef": 105.6,
      "adjDefRank": 182,
      "adjEM": 13.68,
      "adjOff": 119.2,
      "adjOffRank": 12,
      "adjTempo": 69.5,
      "adjTempoRank": 100,
      "conference": "BE",
      "rank": 53,
      "record": "21-14"
    },
    "Marshall": {
      "adjDef": 102.9,
      "adjDefRank": 126,
      "adjEM": 6.53,
      "adjOff": 109.4,
      "adjOffRank": 93,
      "adjTempo": 74.7,
      "adjTempoRank": 6,
      "conference": "CUSA",
      "finish": "Round of 32",
      "rank": 105,
      "record": "25-11",
      "seed": 13
    },
    "Maryland": {
      "adjDef": 99.1,
      "adjDefRank": 67,
      "adjEM": 15.03,
      "adjOff": 114.1,
      "adjOffRank": 45,
      "adjTempo": 66.3,
      "adjTempoRank": 266,
      "conference": "B10",
      "rank": 39,
      "record": "19-13"
    },
    "Maryland Eastern Shore": {
      "adjDef": 117.8,
      "adjDefRank": 343,
      "adjEM": -27.06,
      "adjOff": 90.8,
      "adjOffRank": 343,
      "adjTempo": 65.9,
      "adjTempoRank": 286,
      "conference": "MEAC",
      "rank": 349,
      "record": "7-25"
    },
    "Massachusetts": {
      "adjDef": 110.5,
      "adjDefRank": 285,
      "adjEM": -4.09,
      "adjOff": 106.4,
      "adjOffRank": 142,
      "adjTempo": 66.4,
      "adjTempoRank": 264,
      "conference": "A10",
      "rank": 207,
      "record": "13-20"
    },
    "McNeese St.": {
      "adjDef": 112.1,
      "adjDefRank": 311,
      "adjEM": -11.47,
      "adjOff": 100.6,
      "adjOffRank": 247,
      "adjTempo": 70.4,
      "adjTempoRank": 68,
      "conference": "Slnd",
      "rank": 291,
      "record": "12-17"
    },
    "Memphis": {
      "adjDef": 106.7,
      "adjDefRank": 200,
      "adjEM": 0.78,
      "adjOff": 107.5,
      "adjOffRank": 125,
      "adjTempo": 66.6,
      "adjTempoRank": 255,
      "conference": "Amer",
      "rank": 161,
      "record": "21-13"
    },
    "Mercer": {
      "adjDef": 110.5,
      "adjDefRank": 283,
      "adjEM": 1.27,
      "adjOff": 111.7,
      "adjOffRank": 60,
      "adjTempo": 64.3,
      "adjTempoRank": 335,
      "conference": "SC",
      "rank": 154,
      "record": "19-15"
    },
    "Miami FL": {
      "adjDef": 97.4,
      "adjDefRank": 48,
      "adjEM": 15.43,
      "adjOff": 112.9,
      "adjOffRank": 53,
      "adjTempo": 67,
      "adjTempoRank": 233,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 37,
      "record": "22-10",
      "seed": 6
    },
    "Miami OH": {
      "adjDef": 103,
      "adjDefRank": 132,
      "adjEM": -2.82,
      "adjOff": 100.2,
      "adjOffRank": 254,
      "adjTempo": 66.7,
      "adjTempoRank": 248,
      "conference": "MAC",
      "rank": 194,
      "record": "16-18"
    },
    "Michigan": {
      "adjDef": 90.5,
      "adjDefRank": 3,
      "adjEM": 24.2,
      "adjOff": 114.7,
      "adjOffRank": 35,
      "adjTempo": 64.9,
      "adjTempoRank": 324,
      "conference": "B10",
      "finish": "Runner-up",
      "rank": 7,
      "record": "33-8",
      "seed": 3
    },
    "Michigan St.": {
      "adjDef": 93.8,
      "adjDefRank": 10,
      "adjEM": 25.41,
      "adjOff": 119.2,
      "adjOffRank": 13,
      "adjTempo": 67,
      "adjTempoRank": 234,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 6,
      "record": "30-5",
      "seed": 3
    },
    "Middle Tennessee": {
      "adjDef": 96.6,
      "adjDefRank": 35,
      "adjEM": 14.68,
      "adjOff": 111.3,
      "adjOffRank": 67,
      "adjTempo": 66.5,
      "adjTempoRank": 259,
      "conference": "CUSA",
      "rank": 45,
      "record": "25-8"
    },
    "Milwaukee": {
      "adjDef": 103.4,
      "adjDefRank": 144,
      "adjEM": -3.11,
      "adjOff": 100.3,
      "adjOffRank": 253,
      "adjTempo": 65.7,
      "adjTempoRank": 294,
      "conference": "Horz",
      "rank": 196,
      "record": "16-17"
    },
    "Minnesota": {
      "adjDef": 103.7,
      "adjDefRank": 152,
      "adjEM": 5.08,
      "adjOff": 108.7,
      "adjOffRank": 104,
      "adjTempo": 69.8,
      "adjTempoRank": 85,
      "conference": "B10",
      "rank": 117,
      "record": "15-17"
    },
    "Mississippi": {
      "adjDef": 104.7,
      "adjDefRank": 168,
      "adjEM": 6.42,
      "adjOff": 111.1,
      "adjOffRank": 68,
      "adjTempo": 70,
      "adjTempoRank": 77,
      "conference": "SEC",
      "rank": 108,
      "record": "12-20"
    },
    "Mississippi St.": {
      "adjDef": 96.9,
      "adjDefRank": 40,
      "adjEM": 13.61,
      "adjOff": 110.5,
      "adjOffRank": 76,
      "adjTempo": 67.3,
      "adjTempoRank": 219,
      "conference": "SEC",
      "rank": 54,
      "record": "25-12"
    },
    "Mississippi Valley St.": {
      "adjDef": 114.5,
      "adjDefRank": 327,
      "adjEM": -24.21,
      "adjOff": 90.3,
      "adjOffRank": 345,
      "adjTempo": 69.4,
      "adjTempoRank": 106,
      "conference": "SWAC",
      "rank": 348,
      "record": "4-28"
    },
    "Missouri": {
      "adjDef": 97,
      "adjDefRank": 43,
      "adjEM": 14.83,
      "adjOff": 111.9,
      "adjOffRank": 58,
      "adjTempo": 65.7,
      "adjTempoRank": 296,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 40,
      "record": "20-13",
      "seed": 8
    },
    "Missouri St.": {
      "adjDef": 103.5,
      "adjDefRank": 145,
      "adjEM": 4.81,
      "adjOff": 108.3,
      "adjOffRank": 112,
      "adjTempo": 65.5,
      "adjTempoRank": 309,
      "conference": "MVC",
      "rank": 120,
      "record": "18-15"
    },
    "Monmouth": {
      "adjDef": 104.7,
      "adjDefRank": 170,
      "adjEM": -1.21,
      "adjOff": 103.5,
      "adjOffRank": 198,
      "adjTempo": 70,
      "adjTempoRank": 79,
      "conference": "MAAC",
      "rank": 183,
      "record": "11-20"
    },
    "Montana": {
      "adjDef": 98.4,
      "adjDefRank": 56,
      "adjEM": 10.4,
      "adjOff": 108.8,
      "adjOffRank": 101,
      "adjTempo": 68,
      "adjTempoRank": 185,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 72,
      "record": "26-8",
      "seed": 14
    },
    "Montana St.": {
      "adjDef": 111.3,
      "adjDefRank": 302,
      "adjEM": -9.27,
      "adjOff": 102.1,
      "adjOffRank": 228,
      "adjTempo": 69.7,
      "adjTempoRank": 94,
      "conference": "BSky",
      "rank": 270,
      "record": "13-19"
    },
    "Morehead St.": {
      "adjDef": 109,
      "adjDefRank": 258,
      "adjEM": -9.96,
      "adjOff": 99,
      "adjOffRank": 274,
      "adjTempo": 68.5,
      "adjTempoRank": 164,
      "conference": "OVC",
      "rank": 273,
      "record": "8-21"
    },
    "Morgan St.": {
      "adjDef": 111.1,
      "adjDefRank": 298,
      "adjEM": -14.82,
      "adjOff": 96.3,
      "adjOffRank": 317,
      "adjTempo": 68.9,
      "adjTempoRank": 133,
      "conference": "MEAC",
      "rank": 324,
      "record": "13-19"
    },
    "Mount St. Mary's": {
      "adjDef": 110.1,
      "adjDefRank": 281,
      "adjEM": -6.36,
      "adjOff": 103.7,
      "adjOffRank": 191,
      "adjTempo": 64.7,
      "adjTempoRank": 325,
      "conference": "NEC",
      "rank": 238,
      "record": "18-14"
    },
    "Murray St.": {
      "adjDef": 100.7,
      "adjDefRank": 93,
      "adjEM": 12.19,
      "adjOff": 112.9,
      "adjOffRank": 54,
      "adjTempo": 67.2,
      "adjTempoRank": 222,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 62,
      "record": "26-6",
      "seed": 12
    },
    "N.C. State": {
      "adjDef": 101.8,
      "adjDefRank": 109,
      "adjEM": 14.52,
      "adjOff": 116.3,
      "adjOffRank": 21,
      "adjTempo": 71.4,
      "adjTempoRank": 38,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 46,
      "record": "21-12",
      "seed": 9
    },
    "NJIT": {
      "adjDef": 106.6,
      "adjDefRank": 198,
      "adjEM": -7.95,
      "adjOff": 98.6,
      "adjOffRank": 286,
      "adjTempo": 66.9,
      "adjTempoRank": 241,
      "conference": "ASun",
      "rank": 254,
      "record": "14-16"
    },
    "Navy": {
      "adjDef": 104.3,
      "adjDefRank": 162,
      "adjEM": -5.67,
      "adjOff": 98.7,
      "adjOffRank": 284,
      "adjTempo": 65.4,
      "adjTempoRank": 310,
      "conference": "Pat",
      "rank": 225,
      "record": "20-12"
    },
    "Nebraska": {
      "adjDef": 97.1,
      "adjDefRank": 44,
      "adjEM": 13.54,
      "adjOff": 110.6,
      "adjOffRank": 75,
      "adjTempo": 67.4,
      "adjTempoRank": 214,
      "conference": "B10",
      "rank": 55,
      "record": "22-11"
    },
    "Nebraska Omaha": {
      "adjDef": 114.7,
      "adjDefRank": 330,
      "adjEM": -10.67,
      "adjOff": 104.1,
      "adjOffRank": 187,
      "adjTempo": 69.8,
      "adjTempoRank": 87,
      "conference": "Sum",
      "rank": 283,
      "record": "9-22"
    },
    "Nevada": {
      "adjDef": 101.8,
      "adjDefRank": 108,
      "adjEM": 18.39,
      "adjOff": 120.2,
      "adjOffRank": 7,
      "adjTempo": 69.4,
      "adjTempoRank": 105,
      "conference": "MWC",
      "finish": "Sweet 16",
      "rank": 25,
      "record": "29-8",
      "seed": 7
    },
    "New Hampshire": {
      "adjDef": 108.5,
      "adjDefRank": 244,
      "adjEM": -10.35,
      "adjOff": 98.2,
      "adjOffRank": 297,
      "adjTempo": 65.4,
      "adjTempoRank": 313,
      "conference": "AE",
      "rank": 278,
      "record": "10-21"
    },
    "New Mexico": {
      "adjDef": 106.8,
      "adjDefRank": 204,
      "adjEM": 6.52,
      "adjOff": 113.3,
      "adjOffRank": 51,
      "adjTempo": 71.3,
      "adjTempoRank": 42,
      "conference": "MWC",
      "rank": 106,
      "record": "19-15"
    },
    "New Mexico St.": {
      "adjDef": 95,
      "adjDefRank": 16,
      "adjEM": 12.92,
      "adjOff": 107.9,
      "adjOffRank": 116,
      "adjTempo": 68.5,
      "adjTempoRank": 165,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 60,
      "record": "28-6",
      "seed": 12
    },
    "New Orleans": {
      "adjDef": 107.4,
      "adjDefRank": 223,
      "adjEM": -8.95,
      "adjOff": 98.5,
      "adjOffRank": 288,
      "adjTempo": 66.1,
      "adjTempoRank": 279,
      "conference": "Slnd",
      "rank": 266,
      "record": "16-17"
    },
    "Niagara": {
      "adjDef": 113.5,
      "adjDefRank": 323,
      "adjEM": -3.99,
      "adjOff": 109.5,
      "adjOffRank": 88,
      "adjTempo": 73.7,
      "adjTempoRank": 10,
      "conference": "MAAC",
      "rank": 205,
      "record": "19-14"
    },
    "Nicholls St.": {
      "adjDef": 104.9,
      "adjDefRank": 172,
      "adjEM": -1.56,
      "adjOff": 103.3,
      "adjOffRank": 200,
      "adjTempo": 73.5,
      "adjTempoRank": 12,
      "conference": "Slnd",
      "rank": 185,
      "record": "21-11"
    },
    "Norfolk St.": {
      "adjDef": 108.6,
      "adjDefRank": 249,
      "adjEM": -13.14,
      "adjOff": 95.4,
      "adjOffRank": 328,
      "adjTempo": 69,
      "adjTempoRank": 127,
      "conference": "MEAC",
      "rank": 312,
      "record": "14-19"
    },
    "North Carolina": {
      "adjDef": 96.7,
      "adjDefRank": 38,
      "adjEM": 23.59,
      "adjOff": 120.3,
      "adjOffRank": 6,
      "adjTempo": 71.2,
      "adjTempoRank": 46,
      "conference": "ACC",
      "finish": "Round of 32",
      "rank": 8,
      "record": "26-11",
      "seed": 2
    },
    "North Carolina A&T": {
      "adjDef": 110.8,
      "adjDefRank": 293,
      "adjEM": -12.68,
      "adjOff": 98.1,
      "adjOffRank": 299,
      "adjTempo": 69.3,
      "adjTempoRank": 113,
      "conference": "MEAC",
      "rank": 306,
      "record": "20-15"
    },
    "North Carolina Central": {
      "adjDef": 110,
      "adjDefRank": 276,
      "adjEM": -13.41,
      "adjOff": 96.6,
      "adjOffRank": 314,
      "adjTempo": 65,
      "adjTempoRank": 321,
      "conference": "MEAC",
      "finish": "First Four",
      "rank": 316,
      "record": "19-16",
      "seed": 16
    },
    "North Dakota": {
      "adjDef": 109.5,
      "adjDefRank": 265,
      "adjEM": -6.41,
      "adjOff": 103.1,
      "adjOffRank": 207,
      "adjTempo": 71.4,
      "adjTempoRank": 39,
      "conference": "BSky",
      "rank": 239,
      "record": "12-20"
    },
    "North Dakota St.": {
      "adjDef": 105.4,
      "adjDefRank": 179,
      "adjEM": -0.37,
      "adjOff": 105,
      "adjOffRank": 169,
      "adjTempo": 66.5,
      "adjTempoRank": 261,
      "conference": "Sum",
      "rank": 176,
      "record": "15-17"
    },
    "North Florida": {
      "adjDef": 118.4,
      "adjDefRank": 345,
      "adjEM": -10.65,
      "adjOff": 107.7,
      "adjOffRank": 121,
      "adjTempo": 73.6,
      "adjTempoRank": 11,
      "conference": "ASun",
      "rank": 282,
      "record": "14-19"
    },
    "North Texas": {
      "adjDef": 103.4,
      "adjDefRank": 142,
      "adjEM": 2.2,
      "adjOff": 105.6,
      "adjOffRank": 162,
      "adjTempo": 67.8,
      "adjTempoRank": 196,
      "conference": "CUSA",
      "rank": 146,
      "record": "20-18"
    },
    "Northeastern": {
      "adjDef": 100.5,
      "adjDefRank": 86,
      "adjEM": 7.34,
      "adjOff": 107.8,
      "adjOffRank": 117,
      "adjTempo": 67,
      "adjTempoRank": 237,
      "conference": "CAA",
      "rank": 96,
      "record": "23-10"
    },
    "Northern Arizona": {
      "adjDef": 109.5,
      "adjDefRank": 266,
      "adjEM": -16.02,
      "adjOff": 93.5,
      "adjOffRank": 340,
      "adjTempo": 68.6,
      "adjTempoRank": 158,
      "conference": "BSky",
      "rank": 327,
      "record": "5-27"
    },
    "Northern Colorado": {
      "adjDef": 100.8,
      "adjDefRank": 94,
      "adjEM": 6.55,
      "adjOff": 107.3,
      "adjOffRank": 129,
      "adjTempo": 72.2,
      "adjTempoRank": 24,
      "conference": "BSky",
      "rank": 104,
      "record": "26-12"
    },
    "Northern Illinois": {
      "adjDef": 111,
      "adjDefRank": 297,
      "adjEM": -7.66,
      "adjOff": 103.3,
      "adjOffRank": 201,
      "adjTempo": 67,
      "adjTempoRank": 235,
      "conference": "MAC",
      "rank": 249,
      "record": "13-19"
    },
    "Northern Iowa": {
      "adjDef": 98.7,
      "adjDefRank": 60,
      "adjEM": 4.51,
      "adjOff": 103.2,
      "adjOffRank": 205,
      "adjTempo": 62.7,
      "adjTempoRank": 349,
      "conference": "MVC",
      "rank": 122,
      "record": "16-16"
    },
    "Northern Kentucky": {
      "adjDef": 99.6,
      "adjDefRank": 75,
      "adjEM": 7.93,
      "adjOff": 107.5,
      "adjOffRank": 123,
      "adjTempo": 68.6,
      "adjTempoRank": 159,
      "conference": "Horz",
      "rank": 90,
      "record": "22-10"
    },
    "Northwestern": {
      "adjDef": 100.6,
      "adjDefRank": 90,
      "adjEM": 8.57,
      "adjOff": 109.2,
      "adjOffRank": 96,
      "adjTempo": 63.9,
      "adjTempoRank": 340,
      "conference": "B10",
      "rank": 85,
      "record": "15-17"
    },
    "Northwestern St.": {
      "adjDef": 110.7,
      "adjDefRank": 291,
      "adjEM": -22.41,
      "adjOff": 88.3,
      "adjOffRank": 349,
      "adjTempo": 68.9,
      "adjTempoRank": 137,
      "conference": "Slnd",
      "rank": 345,
      "record": "4-25"
    },
    "Notre Dame": {
      "adjDef": 99.1,
      "adjDefRank": 68,
      "adjEM": 15.79,
      "adjOff": 114.9,
      "adjOffRank": 32,
      "adjTempo": 65.4,
      "adjTempoRank": 312,
      "conference": "ACC",
      "rank": 35,
      "record": "21-15"
    },
    "Oakland": {
      "adjDef": 107.7,
      "adjDefRank": 226,
      "adjEM": -0.23,
      "adjOff": 107.4,
      "adjOffRank": 126,
      "adjTempo": 70.3,
      "adjTempoRank": 72,
      "conference": "Horz",
      "rank": 175,
      "record": "19-14"
    },
    "Ohio": {
      "adjDef": 103.1,
      "adjDefRank": 134,
      "adjEM": -2.74,
      "adjOff": 100.4,
      "adjOffRank": 252,
      "adjTempo": 71.3,
      "adjTempoRank": 44,
      "conference": "MAC",
      "rank": 193,
      "record": "14-17"
    },
    "Ohio St.": {
      "adjDef": 94.9,
      "adjDefRank": 15,
      "adjEM": 21.16,
      "adjOff": 116,
      "adjOffRank": 23,
      "adjTempo": 67,
      "adjTempoRank": 238,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 16,
      "record": "25-9",
      "seed": 5
    },
    "Oklahoma": {
      "adjDef": 100.5,
      "adjDefRank": 85,
      "adjEM": 14.16,
      "adjOff": 114.6,
      "adjOffRank": 37,
      "adjTempo": 75.5,
      "adjTempoRank": 4,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 48,
      "record": "18-14",
      "seed": 10
    },
    "Oklahoma St.": {
      "adjDef": 98.6,
      "adjDefRank": 59,
      "adjEM": 12.93,
      "adjOff": 111.6,
      "adjOffRank": 61,
      "adjTempo": 68.9,
      "adjTempoRank": 135,
      "conference": "B12",
      "rank": 59,
      "record": "21-15"
    },
    "Old Dominion": {
      "adjDef": 96.6,
      "adjDefRank": 34,
      "adjEM": 12.14,
      "adjOff": 108.8,
      "adjOffRank": 103,
      "adjTempo": 64.5,
      "adjTempoRank": 331,
      "conference": "CUSA",
      "rank": 64,
      "record": "25-7"
    },
    "Oral Roberts": {
      "adjDef": 112.3,
      "adjDefRank": 314,
      "adjEM": -6.24,
      "adjOff": 106,
      "adjOffRank": 150,
      "adjTempo": 65.3,
      "adjTempoRank": 315,
      "conference": "Sum",
      "rank": 236,
      "record": "11-21"
    },
    "Oregon": {
      "adjDef": 100.9,
      "adjDefRank": 98,
      "adjEM": 10.24,
      "adjOff": 111.1,
      "adjOffRank": 70,
      "adjTempo": 67.3,
      "adjTempoRank": 215,
      "conference": "P12",
      "rank": 75,
      "record": "23-13"
    },
    "Oregon St.": {
      "adjDef": 101.3,
      "adjDefRank": 102,
      "adjEM": 6.99,
      "adjOff": 108.2,
      "adjOffRank": 114,
      "adjTempo": 65.5,
      "adjTempoRank": 306,
      "conference": "P12",
      "rank": 100,
      "record": "16-16"
    },
    "Pacific": {
      "adjDef": 107,
      "adjDefRank": 210,
      "adjEM": -0.43,
      "adjOff": 106.6,
      "adjOffRank": 139,
      "adjTempo": 66.3,
      "adjTempoRank": 268,
      "conference": "WCC",
      "rank": 178,
      "record": "14-18"
    },
    "Penn": {
      "adjDef": 99.4,
      "adjDefRank": 72,
      "adjEM": 3.69,
      "adjOff": 103.1,
      "adjOffRank": 208,
      "adjTempo": 68.9,
      "adjTempoRank": 132,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 125,
      "record": "24-9",
      "seed": 16
    },
    "Penn St.": {
      "adjDef": 95.1,
      "adjDefRank": 19,
      "adjEM": 19.18,
      "adjOff": 114.3,
      "adjOffRank": 42,
      "adjTempo": 67.3,
      "adjTempoRank": 217,
      "conference": "B10",
      "rank": 19,
      "record": "26-13"
    },
    "Pepperdine": {
      "adjDef": 114.5,
      "adjDefRank": 328,
      "adjEM": -9.51,
      "adjOff": 105,
      "adjOffRank": 168,
      "adjTempo": 67.5,
      "adjTempoRank": 210,
      "conference": "WCC",
      "rank": 271,
      "record": "6-26"
    },
    "Pittsburgh": {
      "adjDef": 104.7,
      "adjDefRank": 166,
      "adjEM": -5.76,
      "adjOff": 98.9,
      "adjOffRank": 278,
      "adjTempo": 65.2,
      "adjTempoRank": 318,
      "conference": "ACC",
      "rank": 227,
      "record": "8-24"
    },
    "Portland": {
      "adjDef": 108.7,
      "adjDefRank": 253,
      "adjEM": -9.66,
      "adjOff": 99,
      "adjOffRank": 275,
      "adjTempo": 67.1,
      "adjTempoRank": 228,
      "conference": "WCC",
      "rank": 272,
      "record": "10-22"
    },
    "Portland St.": {
      "adjDef": 107.3,
      "adjDefRank": 217,
      "adjEM": 0.56,
      "adjOff": 107.9,
      "adjOffRank": 115,
      "adjTempo": 74.6,
      "adjTempoRank": 7,
      "conference": "BSky",
      "rank": 164,
      "record": "20-14"
    },
    "Prairie View A&M": {
      "adjDef": 110.6,
      "adjDefRank": 290,
      "adjEM": -9.2,
      "adjOff": 101.4,
      "adjOffRank": 240,
      "adjTempo": 70.8,
      "adjTempoRank": 55,
      "conference": "SWAC",
      "rank": 269,
      "record": "16-18"
    },
    "Presbyterian": {
      "adjDef": 115.5,
      "adjDefRank": 336,
      "adjEM": -17.66,
      "adjOff": 97.8,
      "adjOffRank": 303,
      "adjTempo": 63.4,
      "adjTempoRank": 342,
      "conference": "BSth",
      "rank": 334,
      "record": "11-21"
    },
    "Princeton": {
      "adjDef": 107.8,
      "adjDefRank": 230,
      "adjEM": -2.44,
      "adjOff": 105.3,
      "adjOffRank": 165,
      "adjTempo": 64.5,
      "adjTempoRank": 328,
      "conference": "Ivy",
      "rank": 190,
      "record": "13-16"
    },
    "Providence": {
      "adjDef": 96.7,
      "adjDefRank": 36,
      "adjEM": 12.17,
      "adjOff": 108.9,
      "adjOffRank": 100,
      "adjTempo": 67.1,
      "adjTempoRank": 227,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 63,
      "record": "21-14",
      "seed": 10
    },
    "Purdue": {
      "adjDef": 96.4,
      "adjDefRank": 31,
      "adjEM": 26.27,
      "adjOff": 122.7,
      "adjOffRank": 2,
      "adjTempo": 67.2,
      "adjTempoRank": 223,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 5,
      "record": "30-7",
      "seed": 2
    },
    "Quinnipiac": {
      "adjDef": 112.2,
      "adjDefRank": 313,
      "adjEM": -10.46,
      "adjOff": 101.7,
      "adjOffRank": 236,
      "adjTempo": 66.5,
      "adjTempoRank": 260,
      "conference": "MAAC",
      "rank": 280,
      "record": "12-21"
    },
    "Radford": {
      "adjDef": 102.9,
      "adjDefRank": 127,
      "adjEM": 0.07,
      "adjOff": 102.9,
      "adjOffRank": 212,
      "adjTempo": 63,
      "adjTempoRank": 344,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 170,
      "record": "23-13",
      "seed": 16
    },
    "Rhode Island": {
      "adjDef": 97.2,
      "adjDefRank": 46,
      "adjEM": 13.93,
      "adjOff": 111.1,
      "adjOffRank": 69,
      "adjTempo": 68.4,
      "adjTempoRank": 173,
      "conference": "A10",
      "finish": "Round of 32",
      "rank": 52,
      "record": "26-8",
      "seed": 7
    },
    "Rice": {
      "adjDef": 110.1,
      "adjDefRank": 280,
      "adjEM": -11.09,
      "adjOff": 99,
      "adjOffRank": 276,
      "adjTempo": 67.5,
      "adjTempoRank": 212,
      "conference": "CUSA",
      "rank": 289,
      "record": "7-24"
    },
    "Richmond": {
      "adjDef": 108.1,
      "adjDefRank": 234,
      "adjEM": -0.78,
      "adjOff": 107.4,
      "adjOffRank": 127,
      "adjTempo": 67.9,
      "adjTempoRank": 189,
      "conference": "A10",
      "rank": 181,
      "record": "12-20"
    },
    "Rider": {
      "adjDef": 106.9,
      "adjDefRank": 208,
      "adjEM": 2.28,
      "adjOff": 109.2,
      "adjOffRank": 94,
      "adjTempo": 73.8,
      "adjTempoRank": 8,
      "conference": "MAAC",
      "rank": 145,
      "record": "22-10"
    },
    "Robert Morris": {
      "adjDef": 102.5,
      "adjDefRank": 122,
      "adjEM": -7.79,
      "adjOff": 94.7,
      "adjOffRank": 331,
      "adjTempo": 69.3,
      "adjTempoRank": 112,
      "conference": "NEC",
      "rank": 253,
      "record": "16-17"
    },
    "Rutgers": {
      "adjDef": 96.2,
      "adjDefRank": 28,
      "adjEM": 3.07,
      "adjOff": 99.2,
      "adjOffRank": 270,
      "adjTempo": 66,
      "adjTempoRank": 281,
      "conference": "B10",
      "rank": 130,
      "record": "15-19"
    },
    "SIU Edwardsville": {
      "adjDef": 113,
      "adjDefRank": 318,
      "adjEM": -13.61,
      "adjOff": 99.4,
      "adjOffRank": 264,
      "adjTempo": 69.2,
      "adjTempoRank": 118,
      "conference": "OVC",
      "rank": 318,
      "record": "9-21"
    },
    "SMU": {
      "adjDef": 100.9,
      "adjDefRank": 97,
      "adjEM": 8.61,
      "adjOff": 109.5,
      "adjOffRank": 91,
      "adjTempo": 64.3,
      "adjTempoRank": 336,
      "conference": "Amer",
      "rank": 84,
      "record": "17-16"
    },
    "Sacramento St.": {
      "adjDef": 110.6,
      "adjDefRank": 286,
      "adjEM": -10.77,
      "adjOff": 99.8,
      "adjOffRank": 260,
      "adjTempo": 65.6,
      "adjTempoRank": 304,
      "conference": "BSky",
      "rank": 285,
      "record": "7-25"
    },
    "Sacred Heart": {
      "adjDef": 108.6,
      "adjDefRank": 250,
      "adjEM": -12.57,
      "adjOff": 96,
      "adjOffRank": 320,
      "adjTempo": 68.4,
      "adjTempoRank": 172,
      "conference": "NEC",
      "rank": 305,
      "record": "10-21"
    },
    "Saint Joseph's": {
      "adjDef": 100.5,
      "adjDefRank": 88,
      "adjEM": 5.15,
      "adjOff": 105.6,
      "adjOffRank": 159,
      "adjTempo": 70.5,
      "adjTempoRank": 60,
      "conference": "A10",
      "rank": 116,
      "record": "16-16"
    },
    "Saint Louis": {
      "adjDef": 100,
      "adjDefRank": 80,
      "adjEM": 2.1,
      "adjOff": 102.1,
      "adjOffRank": 225,
      "adjTempo": 64.1,
      "adjTempoRank": 337,
      "conference": "A10",
      "rank": 148,
      "record": "17-16"
    },
    "Saint Mary's": {
      "adjDef": 103.2,
      "adjDefRank": 137,
      "adjEM": 16.13,
      "adjOff": 119.4,
      "adjOffRank": 11,
      "adjTempo": 63.5,
      "adjTempoRank": 341,
      "conference": "WCC",
      "rank": 32,
      "record": "30-6"
    },
    "Saint Peter's": {
      "adjDef": 100.5,
      "adjDefRank": 89,
      "adjEM": -5,
      "adjOff": 95.5,
      "adjOffRank": 326,
      "adjTempo": 62.6,
      "adjTempoRank": 350,
      "conference": "MAAC",
      "rank": 217,
      "record": "14-18"
    },
    "Sam Houston St.": {
      "adjDef": 105.9,
      "adjDefRank": 187,
      "adjEM": -3.77,
      "adjOff": 102.1,
      "adjOffRank": 224,
      "adjTempo": 67.2,
      "adjTempoRank": 221,
      "conference": "Slnd",
      "rank": 204,
      "record": "21-15"
    },
    "Samford": {
      "adjDef": 119.7,
      "adjDefRank": 349,
      "adjEM": -12,
      "adjOff": 107.7,
      "adjOffRank": 119,
      "adjTempo": 71.2,
      "adjTempoRank": 47,
      "conference": "SC",
      "rank": 298,
      "record": "10-22"
    },
    "San Diego": {
      "adjDef": 100.6,
      "adjDefRank": 91,
      "adjEM": 3.01,
      "adjOff": 103.6,
      "adjOffRank": 195,
      "adjTempo": 66.9,
      "adjTempoRank": 242,
      "conference": "WCC",
      "rank": 132,
      "record": "20-14"
    },
    "San Diego St.": {
      "adjDef": 96.4,
      "adjDefRank": 30,
      "adjEM": 14.09,
      "adjOff": 110.5,
      "adjOffRank": 77,
      "adjTempo": 68.8,
      "adjTempoRank": 142,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "22-11",
      "seed": 11
    },
    "San Francisco": {
      "adjDef": 101.1,
      "adjDefRank": 99,
      "adjEM": 0.97,
      "adjOff": 102.1,
      "adjOffRank": 230,
      "adjTempo": 67.1,
      "adjTempoRank": 225,
      "conference": "WCC",
      "rank": 158,
      "record": "22-17"
    },
    "San Jose St.": {
      "adjDef": 107.7,
      "adjDefRank": 227,
      "adjEM": -12.27,
      "adjOff": 95.4,
      "adjOffRank": 329,
      "adjTempo": 67.1,
      "adjTempoRank": 229,
      "conference": "MWC",
      "rank": 301,
      "record": "4-26"
    },
    "Santa Clara": {
      "adjDef": 109.5,
      "adjDefRank": 264,
      "adjEM": -10.34,
      "adjOff": 99.2,
      "adjOffRank": 272,
      "adjTempo": 64.6,
      "adjTempoRank": 326,
      "conference": "WCC",
      "rank": 277,
      "record": "11-20"
    },
    "Savannah St.": {
      "adjDef": 114.5,
      "adjDefRank": 326,
      "adjEM": -13.55,
      "adjOff": 100.9,
      "adjOffRank": 244,
      "adjTempo": 82.3,
      "adjTempoRank": 1,
      "conference": "MEAC",
      "rank": 317,
      "record": "15-17"
    },
    "Seattle": {
      "adjDef": 106.7,
      "adjDefRank": 201,
      "adjEM": -2.95,
      "adjOff": 103.7,
      "adjOffRank": 190,
      "adjTempo": 67.4,
      "adjTempoRank": 213,
      "conference": "WAC",
      "rank": 195,
      "record": "20-14"
    },
    "Seton Hall": {
      "adjDef": 98.8,
      "adjDefRank": 62,
      "adjEM": 18.04,
      "adjOff": 116.9,
      "adjOffRank": 17,
      "adjTempo": 69.5,
      "adjTempoRank": 103,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 26,
      "record": "22-12",
      "seed": 8
    },
    "Siena": {
      "adjDef": 108.9,
      "adjDefRank": 255,
      "adjEM": -12.38,
      "adjOff": 96.5,
      "adjOffRank": 316,
      "adjTempo": 65.7,
      "adjTempoRank": 298,
      "conference": "MAAC",
      "rank": 303,
      "record": "8-24"
    },
    "South Alabama": {
      "adjDef": 102.2,
      "adjDefRank": 117,
      "adjEM": -5.32,
      "adjOff": 96.9,
      "adjOffRank": 309,
      "adjTempo": 70.4,
      "adjTempoRank": 69,
      "conference": "SB",
      "rank": 221,
      "record": "14-18"
    },
    "South Carolina": {
      "adjDef": 95.7,
      "adjDefRank": 25,
      "adjEM": 9.91,
      "adjOff": 105.6,
      "adjOffRank": 161,
      "adjTempo": 68.7,
      "adjTempoRank": 153,
      "conference": "SEC",
      "rank": 80,
      "record": "17-16"
    },
    "South Carolina St.": {
      "adjDef": 118.7,
      "adjDefRank": 347,
      "adjEM": -21.86,
      "adjOff": 96.9,
      "adjOffRank": 310,
      "adjTempo": 70.5,
      "adjTempoRank": 64,
      "conference": "MEAC",
      "rank": 343,
      "record": "10-22"
    },
    "South Dakota": {
      "adjDef": 99.2,
      "adjDefRank": 69,
      "adjEM": 9.85,
      "adjOff": 109,
      "adjOffRank": 98,
      "adjTempo": 70.9,
      "adjTempoRank": 52,
      "conference": "Sum",
      "rank": 81,
      "record": "26-9"
    },
    "South Dakota St.": {
      "adjDef": 103.6,
      "adjDefRank": 150,
      "adjEM": 10.2,
      "adjOff": 113.8,
      "adjOffRank": 47,
      "adjTempo": 70.5,
      "adjTempoRank": 61,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 78,
      "record": "28-7",
      "seed": 12
    },
    "South Florida": {
      "adjDef": 109.3,
      "adjDefRank": 261,
      "adjEM": -10.45,
      "adjOff": 98.8,
      "adjOffRank": 282,
      "adjTempo": 64.6,
      "adjTempoRank": 327,
      "conference": "Amer",
      "rank": 279,
      "record": "10-22"
    },
    "Southeast Missouri St.": {
      "adjDef": 116.6,
      "adjDefRank": 340,
      "adjEM": -8.11,
      "adjOff": 108.5,
      "adjOffRank": 107,
      "adjTempo": 69.2,
      "adjTempoRank": 116,
      "conference": "OVC",
      "rank": 256,
      "record": "14-17"
    },
    "Southeastern Louisiana": {
      "adjDef": 105.9,
      "adjDefRank": 188,
      "adjEM": -3.31,
      "adjOff": 102.6,
      "adjOffRank": 216,
      "adjTempo": 65.7,
      "adjTempoRank": 297,
      "conference": "Slnd",
      "rank": 199,
      "record": "22-12"
    },
    "Southern": {
      "adjDef": 109.8,
      "adjDefRank": 269,
      "adjEM": -15.51,
      "adjOff": 94.3,
      "adjOffRank": 335,
      "adjTempo": 68,
      "adjTempoRank": 187,
      "conference": "SWAC",
      "rank": 326,
      "record": "15-18"
    },
    "Southern Illinois": {
      "adjDef": 103.3,
      "adjDefRank": 140,
      "adjEM": 2.82,
      "adjOff": 106.1,
      "adjOffRank": 146,
      "adjTempo": 66.6,
      "adjTempoRank": 253,
      "conference": "MVC",
      "rank": 135,
      "record": "20-13"
    },
    "Southern Miss": {
      "adjDef": 109.9,
      "adjDefRank": 271,
      "adjEM": -4.04,
      "adjOff": 105.8,
      "adjOffRank": 154,
      "adjTempo": 65.4,
      "adjTempoRank": 311,
      "conference": "CUSA",
      "rank": 206,
      "record": "16-18"
    },
    "Southern Utah": {
      "adjDef": 110,
      "adjDefRank": 273,
      "adjEM": -7.74,
      "adjOff": 102.2,
      "adjOffRank": 221,
      "adjTempo": 71.7,
      "adjTempoRank": 32,
      "conference": "BSky",
      "rank": 252,
      "record": "13-19"
    },
    "St. Bonaventure": {
      "adjDef": 99.5,
      "adjDefRank": 74,
      "adjEM": 11.21,
      "adjOff": 110.8,
      "adjOffRank": 74,
      "adjTempo": 69.4,
      "adjTempoRank": 104,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 68,
      "record": "26-8",
      "seed": 11
    },
    "St. Francis NY": {
      "adjDef": 111.8,
      "adjDefRank": 308,
      "adjEM": -13.17,
      "adjOff": 98.7,
      "adjOffRank": 285,
      "adjTempo": 69.2,
      "adjTempoRank": 114,
      "conference": "NEC",
      "rank": 313,
      "record": "13-18"
    },
    "St. Francis PA": {
      "adjDef": 111.2,
      "adjDefRank": 300,
      "adjEM": -4.86,
      "adjOff": 106.4,
      "adjOffRank": 143,
      "adjTempo": 68.2,
      "adjTempoRank": 180,
      "conference": "NEC",
      "rank": 214,
      "record": "18-13"
    },
    "St. John's": {
      "adjDef": 96.2,
      "adjDefRank": 29,
      "adjEM": 10.31,
      "adjOff": 106.5,
      "adjOffRank": 141,
      "adjTempo": 69.4,
      "adjTempoRank": 110,
      "conference": "BE",
      "rank": 74,
      "record": "16-17"
    },
    "Stanford": {
      "adjDef": 99.3,
      "adjDefRank": 71,
      "adjEM": 8.37,
      "adjOff": 107.7,
      "adjOffRank": 120,
      "adjTempo": 70.9,
      "adjTempoRank": 53,
      "conference": "P12",
      "rank": 87,
      "record": "19-16"
    },
    "Stephen F. Austin": {
      "adjDef": 99,
      "adjDefRank": 64,
      "adjEM": 5.58,
      "adjOff": 104.5,
      "adjOffRank": 181,
      "adjTempo": 71.1,
      "adjTempoRank": 49,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 112,
      "record": "28-7",
      "seed": 14
    },
    "Stetson": {
      "adjDef": 111.8,
      "adjDefRank": 306,
      "adjEM": -13.27,
      "adjOff": 98.5,
      "adjOffRank": 289,
      "adjTempo": 69.8,
      "adjTempoRank": 86,
      "conference": "ASun",
      "rank": 314,
      "record": "12-20"
    },
    "Stony Brook": {
      "adjDef": 102.1,
      "adjDefRank": 114,
      "adjEM": -5.33,
      "adjOff": 96.8,
      "adjOffRank": 311,
      "adjTempo": 68.4,
      "adjTempoRank": 171,
      "conference": "AE",
      "rank": 223,
      "record": "13-19"
    },
    "Syracuse": {
      "adjDef": 92.2,
      "adjDefRank": 5,
      "adjEM": 14.82,
      "adjOff": 107,
      "adjOffRank": 135,
      "adjTempo": 62.9,
      "adjTempoRank": 345,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 41,
      "record": "23-14",
      "seed": 11
    },
    "TCU": {
      "adjDef": 101.2,
      "adjDefRank": 100,
      "adjEM": 18.47,
      "adjOff": 119.7,
      "adjOffRank": 9,
      "adjTempo": 68.6,
      "adjTempoRank": 160,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 24,
      "record": "21-12",
      "seed": 6
    },
    "Temple": {
      "adjDef": 100,
      "adjDefRank": 78,
      "adjEM": 8.38,
      "adjOff": 108.3,
      "adjOffRank": 111,
      "adjTempo": 67.6,
      "adjTempoRank": 204,
      "conference": "Amer",
      "rank": 86,
      "record": "17-16"
    },
    "Tennessee": {
      "adjDef": 92.4,
      "adjDefRank": 6,
      "adjEM": 22.27,
      "adjOff": 114.6,
      "adjOffRank": 36,
      "adjTempo": 66,
      "adjTempoRank": 282,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 13,
      "record": "26-9",
      "seed": 3
    },
    "Tennessee Martin": {
      "adjDef": 107.2,
      "adjDefRank": 214,
      "adjEM": -11.66,
      "adjOff": 95.5,
      "adjOffRank": 327,
      "adjTempo": 66.1,
      "adjTempoRank": 275,
      "conference": "OVC",
      "rank": 294,
      "record": "10-21"
    },
    "Tennessee St.": {
      "adjDef": 100.8,
      "adjDefRank": 95,
      "adjEM": -3.27,
      "adjOff": 97.5,
      "adjOffRank": 305,
      "adjTempo": 65.6,
      "adjTempoRank": 300,
      "conference": "OVC",
      "rank": 198,
      "record": "15-15"
    },
    "Tennessee Tech": {
      "adjDef": 103.5,
      "adjDefRank": 147,
      "adjEM": -5.06,
      "adjOff": 98.5,
      "adjOffRank": 290,
      "adjTempo": 70.9,
      "adjTempoRank": 54,
      "conference": "OVC",
      "rank": 219,
      "record": "19-14"
    },
    "Texas": {
      "adjDef": 94.1,
      "adjDefRank": 12,
      "adjEM": 15.43,
      "adjOff": 109.5,
      "adjOffRank": 89,
      "adjTempo": 65.2,
      "adjTempoRank": 316,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 36,
      "record": "19-15",
      "seed": 10
    },
    "Texas A&M": {
      "adjDef": 94.4,
      "adjDefRank": 14,
      "adjEM": 16.96,
      "adjOff": 111.3,
      "adjOffRank": 66,
      "adjTempo": 69.5,
      "adjTempoRank": 101,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 29,
      "record": "22-13",
      "seed": 7
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 107.4,
      "adjDefRank": 222,
      "adjEM": -12.88,
      "adjOff": 94.5,
      "adjOffRank": 332,
      "adjTempo": 68.2,
      "adjTempoRank": 179,
      "conference": "Slnd",
      "rank": 311,
      "record": "11-18"
    },
    "Texas Southern": {
      "adjDef": 111.2,
      "adjDefRank": 299,
      "adjEM": -6.65,
      "adjOff": 104.6,
      "adjOffRank": 180,
      "adjTempo": 71.7,
      "adjTempoRank": 31,
      "conference": "SWAC",
      "finish": "Round of 64",
      "rank": 243,
      "record": "16-20",
      "seed": 16
    },
    "Texas St.": {
      "adjDef": 105.6,
      "adjDefRank": 183,
      "adjEM": -5.79,
      "adjOff": 99.8,
      "adjOffRank": 261,
      "adjTempo": 62.7,
      "adjTempoRank": 348,
      "conference": "SB",
      "rank": 228,
      "record": "15-18"
    },
    "Texas Tech": {
      "adjDef": 90.8,
      "adjDefRank": 4,
      "adjEM": 22.59,
      "adjOff": 113.3,
      "adjOffRank": 50,
      "adjTempo": 66.7,
      "adjTempoRank": 247,
      "conference": "B12",
      "finish": "Elite Eight",
      "rank": 11,
      "record": "27-10",
      "seed": 3
    },
    "The Citadel": {
      "adjDef": 114.9,
      "adjDefRank": 332,
      "adjEM": -13.33,
      "adjOff": 101.6,
      "adjOffRank": 238,
      "adjTempo": 77.2,
      "adjTempoRank": 2,
      "conference": "SC",
      "rank": 315,
      "record": "11-21"
    },
    "Toledo": {
      "adjDef": 108.6,
      "adjDefRank": 247,
      "adjEM": 5.31,
      "adjOff": 113.9,
      "adjOffRank": 46,
      "adjTempo": 68.4,
      "adjTempoRank": 166,
      "conference": "MAC",
      "rank": 113,
      "record": "23-11"
    },
    "Towson": {
      "adjDef": 104.7,
      "adjDefRank": 167,
      "adjEM": 1.29,
      "adjOff": 106,
      "adjOffRank": 151,
      "adjTempo": 67.9,
      "adjTempoRank": 188,
      "conference": "CAA",
      "rank": 153,
      "record": "18-14"
    },
    "Troy": {
      "adjDef": 106.5,
      "adjDefRank": 197,
      "adjEM": -0.37,
      "adjOff": 106.2,
      "adjOffRank": 145,
      "adjTempo": 68.3,
      "adjTempoRank": 175,
      "conference": "SB",
      "rank": 177,
      "record": "16-17"
    },
    "Tulane": {
      "adjDef": 106.9,
      "adjDefRank": 207,
      "adjEM": 0.04,
      "adjOff": 106.9,
      "adjOffRank": 136,
      "adjTempo": 69,
      "adjTempoRank": 126,
      "conference": "Amer",
      "rank": 171,
      "record": "14-17"
    },
    "Tulsa": {
      "adjDef": 102.3,
      "adjDefRank": 118,
      "adjEM": 6.02,
      "adjOff": 108.3,
      "adjOffRank": 113,
      "adjTempo": 67.3,
      "adjTempoRank": 220,
      "conference": "Amer",
      "rank": 110,
      "record": "19-12"
    },
    "UAB": {
      "adjDef": 103.3,
      "adjDefRank": 138,
      "adjEM": 6.32,
      "adjOff": 109.6,
      "adjOffRank": 87,
      "adjTempo": 67.7,
      "adjTempoRank": 201,
      "conference": "CUSA",
      "rank": 109,
      "record": "20-13"
    },
    "UC Davis": {
      "adjDef": 100.9,
      "adjDefRank": 96,
      "adjEM": 3.25,
      "adjOff": 104.1,
      "adjOffRank": 186,
      "adjTempo": 66.5,
      "adjTempoRank": 263,
      "conference": "BW",
      "rank": 129,
      "record": "22-11"
    },
    "UC Irvine": {
      "adjDef": 98,
      "adjDefRank": 53,
      "adjEM": 2.11,
      "adjOff": 100.1,
      "adjOffRank": 256,
      "adjTempo": 67,
      "adjTempoRank": 239,
      "conference": "BW",
      "rank": 147,
      "record": "18-17"
    },
    "UC Riverside": {
      "adjDef": 103.7,
      "adjDefRank": 153,
      "adjEM": -9.17,
      "adjOff": 94.5,
      "adjOffRank": 333,
      "adjTempo": 68.3,
      "adjTempoRank": 177,
      "conference": "BW",
      "rank": 268,
      "record": "9-22"
    },
    "UC Santa Barbara": {
      "adjDef": 107.1,
      "adjDefRank": 212,
      "adjEM": 4.27,
      "adjOff": 111.4,
      "adjOffRank": 64,
      "adjTempo": 66.7,
      "adjTempoRank": 250,
      "conference": "BW",
      "rank": 123,
      "record": "23-9"
    },
    "UCF": {
      "adjDef": 93.2,
      "adjDefRank": 8,
      "adjEM": 5.62,
      "adjOff": 98.9,
      "adjOffRank": 281,
      "adjTempo": 64.5,
      "adjTempoRank": 333,
      "conference": "Amer",
      "rank": 111,
      "record": "19-13"
    },
    "UCLA": {
      "adjDef": 101.4,
      "adjDefRank": 103,
      "adjEM": 13.52,
      "adjOff": 115,
      "adjOffRank": 31,
      "adjTempo": 70.3,
      "adjTempoRank": 70,
      "conference": "P12",
      "finish": "First Four",
      "rank": 56,
      "record": "21-12",
      "seed": 11
    },
    "UMBC": {
      "adjDef": 102.6,
      "adjDefRank": 124,
      "adjEM": 0.5,
      "adjOff": 103.1,
      "adjOffRank": 206,
      "adjTempo": 68.4,
      "adjTempoRank": 170,
      "conference": "AE",
      "finish": "Round of 32",
      "rank": 166,
      "record": "25-11",
      "seed": 16
    },
    "UMKC": {
      "adjDef": 108.5,
      "adjDefRank": 246,
      "adjEM": -10.32,
      "adjOff": 98.2,
      "adjOffRank": 295,
      "adjTempo": 69.3,
      "adjTempoRank": 111,
      "conference": "WAC",
      "rank": 276,
      "record": "10-22"
    },
    "UMass Lowell": {
      "adjDef": 114.6,
      "adjDefRank": 329,
      "adjEM": -11,
      "adjOff": 103.6,
      "adjOffRank": 194,
      "adjTempo": 71.6,
      "adjTempoRank": 36,
      "conference": "AE",
      "rank": 287,
      "record": "12-18"
    },
    "UNC Asheville": {
      "adjDef": 107.6,
      "adjDefRank": 225,
      "adjEM": -1.91,
      "adjOff": 105.7,
      "adjOffRank": 158,
      "adjTempo": 69.4,
      "adjTempoRank": 108,
      "conference": "BSth",
      "rank": 187,
      "record": "21-13"
    },
    "UNC Greensboro": {
      "adjDef": 95.7,
      "adjDefRank": 26,
      "adjEM": 9.22,
      "adjOff": 104.9,
      "adjOffRank": 174,
      "adjTempo": 65.2,
      "adjTempoRank": 317,
      "conference": "SC",
      "finish": "Round of 64",
      "rank": 82,
      "record": "27-8",
      "seed": 13
    },
    "UNC Wilmington": {
      "adjDef": 111.7,
      "adjDefRank": 303,
      "adjEM": -7.43,
      "adjOff": 104.3,
      "adjOffRank": 185,
      "adjTempo": 72,
      "adjTempoRank": 27,
      "conference": "CAA",
      "rank": 245,
      "record": "11-21"
    },
    "UNLV": {
      "adjDef": 104.9,
      "adjDefRank": 174,
      "adjEM": 6.44,
      "adjOff": 111.4,
      "adjOffRank": 65,
      "adjTempo": 72.3,
      "adjTempoRank": 22,
      "conference": "MWC",
      "rank": 107,
      "record": "20-13"
    },
    "USC": {
      "adjDef": 100.5,
      "adjDefRank": 87,
      "adjEM": 14.06,
      "adjOff": 114.5,
      "adjOffRank": 39,
      "adjTempo": 67.7,
      "adjTempoRank": 200,
      "conference": "P12",
      "rank": 51,
      "record": "24-12"
    },
    "USC Upstate": {
      "adjDef": 125,
      "adjDefRank": 351,
      "adjEM": -19.77,
      "adjOff": 105.2,
      "adjOffRank": 167,
      "adjTempo": 69,
      "adjTempoRank": 124,
      "conference": "ASun",
      "rank": 338,
      "record": "7-25"
    },
    "UT Arlington": {
      "adjDef": 102.1,
      "adjDefRank": 115,
      "adjEM": 3.37,
      "adjOff": 105.5,
      "adjOffRank": 163,
      "adjTempo": 72.8,
      "adjTempoRank": 15,
      "conference": "SB",
      "rank": 128,
      "record": "21-13"
    },
    "UT Rio Grande Valley": {
      "adjDef": 107.8,
      "adjDefRank": 229,
      "adjEM": -8.47,
      "adjOff": 99.3,
      "adjOffRank": 268,
      "adjTempo": 73.7,
      "adjTempoRank": 9,
      "conference": "WAC",
      "rank": 260,
      "record": "15-18"
    },
    "UTEP": {
      "adjDef": 104.5,
      "adjDefRank": 163,
      "adjEM": -6.49,
      "adjOff": 98,
      "adjOffRank": 301,
      "adjTempo": 67.6,
      "adjTempoRank": 206,
      "conference": "CUSA",
      "rank": 240,
      "record": "11-20"
    },
    "UTSA": {
      "adjDef": 104.7,
      "adjDefRank": 169,
      "adjEM": -0.09,
      "adjOff": 104.7,
      "adjOffRank": 178,
      "adjTempo": 71.8,
      "adjTempoRank": 29,
      "conference": "CUSA",
      "rank": 174,
      "record": "20-15"
    },
    "Utah": {
      "adjDef": 98.9,
      "adjDefRank": 63,
      "adjEM": 12.99,
      "adjOff": 111.8,
      "adjOffRank": 59,
      "adjTempo": 65.6,
      "adjTempoRank": 299,
      "conference": "P12",
      "rank": 58,
      "record": "23-12"
    },
    "Utah St.": {
      "adjDef": 104.7,
      "adjDefRank": 165,
      "adjEM": 2.57,
      "adjOff": 107.2,
      "adjOffRank": 132,
      "adjTempo": 66.8,
      "adjTempoRank": 244,
      "conference": "MWC",
      "rank": 139,
      "record": "17-17"
    },
    "Utah Valley": {
      "adjDef": 103,
      "adjDefRank": 131,
      "adjEM": 7.78,
      "adjOff": 110.8,
      "adjOffRank": 73,
      "adjTempo": 68.1,
      "adjTempoRank": 182,
      "conference": "WAC",
      "rank": 92,
      "record": "23-11"
    },
    "VCU": {
      "adjDef": 103.8,
      "adjDefRank": 156,
      "adjEM": 2.28,
      "adjOff": 106.1,
      "adjOffRank": 149,
      "adjTempo": 70.9,
      "adjTempoRank": 51,
      "conference": "A10",
      "rank": 144,
      "record": "18-15"
    },
    "VMI": {
      "adjDef": 107.4,
      "adjDefRank": 220,
      "adjEM": -17.43,
      "adjOff": 89.9,
      "adjOffRank": 346,
      "adjTempo": 68.8,
      "adjTempoRank": 139,
      "conference": "SC",
      "rank": 331,
      "record": "9-21"
    },
    "Valparaiso": {
      "adjDef": 101.2,
      "adjDefRank": 101,
      "adjEM": 1.78,
      "adjOff": 103,
      "adjOffRank": 210,
      "adjTempo": 69.7,
      "adjTempoRank": 90,
      "conference": "MVC",
      "rank": 150,
      "record": "15-17"
    },
    "Vanderbilt": {
      "adjDef": 107.3,
      "adjDefRank": 219,
      "adjEM": 7.93,
      "adjOff": 115.3,
      "adjOffRank": 29,
      "adjTempo": 65.8,
      "adjTempoRank": 289,
      "conference": "SEC",
      "rank": 91,
      "record": "12-20"
    },
    "Vermont": {
      "adjDef": 103.5,
      "adjDefRank": 148,
      "adjEM": 10.22,
      "adjOff": 113.8,
      "adjOffRank": 48,
      "adjTempo": 64.1,
      "adjTempoRank": 339,
      "conference": "AE",
      "rank": 76,
      "record": "27-8"
    },
    "Villanova": {
      "adjDef": 94,
      "adjDefRank": 11,
      "adjEM": 33.76,
      "adjOff": 127.8,
      "adjOffRank": 1,
      "adjTempo": 68.7,
      "adjTempoRank": 150,
      "conference": "BE",
      "finish": "Champion",
      "rank": 1,
      "record": "36-4",
      "seed": 1
    },
    "Virginia": {
      "adjDef": 85.6,
      "adjDefRank": 1,
      "adjEM": 29.53,
      "adjOff": 115.2,
      "adjOffRank": 30,
      "adjTempo": 59.4,
      "adjTempoRank": 351,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 2,
      "record": "31-3",
      "seed": 1
    },
    "Virginia Tech": {
      "adjDef": 99.3,
      "adjDefRank": 70,
      "adjEM": 16.03,
      "adjOff": 115.3,
      "adjOffRank": 28,
      "adjTempo": 68.7,
      "adjTempoRank": 147,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 33,
      "record": "21-12",
      "seed": 8
    },
    "Wagner": {
      "adjDef": 103.6,
      "adjDefRank": 151,
      "adjEM": -0.6,
      "adjOff": 103,
      "adjOffRank": 209,
      "adjTempo": 67.6,
      "adjTempoRank": 202,
      "conference": "NEC",
      "rank": 180,
      "record": "23-10"
    },
    "Wake Forest": {
      "adjDef": 103,
      "adjDefRank": 130,
      "adjEM": 7.94,
      "adjOff": 110.9,
      "adjOffRank": 72,
      "adjTempo": 69.5,
      "adjTempoRank": 102,
      "conference": "ACC",
      "rank": 89,
      "record": "11-20"
    },
    "Washington": {
      "adjDef": 99.5,
      "adjDefRank": 73,
      "adjEM": 7.09,
      "adjOff": 106.6,
      "adjOffRank": 140,
      "adjTempo": 69.8,
      "adjTempoRank": 88,
      "conference": "P12",
      "rank": 98,
      "record": "21-13"
    },
    "Washington St.": {
      "adjDef": 110,
      "adjDefRank": 278,
      "adjEM": -1.66,
      "adjOff": 108.4,
      "adjOffRank": 108,
      "adjTempo": 68.2,
      "adjTempoRank": 178,
      "conference": "P12",
      "rank": 186,
      "record": "12-19"
    },
    "Weber St.": {
      "adjDef": 105.8,
      "adjDefRank": 186,
      "adjEM": 0.88,
      "adjOff": 106.7,
      "adjOffRank": 138,
      "adjTempo": 67.5,
      "adjTempoRank": 209,
      "conference": "BSky",
      "rank": 159,
      "record": "20-11"
    },
    "West Virginia": {
      "adjDef": 96.9,
      "adjDefRank": 41,
      "adjEM": 22.44,
      "adjOff": 119.4,
      "adjOffRank": 10,
      "adjTempo": 69.2,
      "adjTempoRank": 120,
      "conference": "B12",
      "finish": "Sweet 16",
      "rank": 12,
      "record": "26-11",
      "seed": 5
    },
    "Western Carolina": {
      "adjDef": 108,
      "adjDefRank": 232,
      "adjEM": -12.3,
      "adjOff": 95.7,
      "adjOffRank": 323,
      "adjTempo": 69.4,
      "adjTempoRank": 109,
      "conference": "SC",
      "rank": 302,
      "record": "13-19"
    },
    "Western Illinois": {
      "adjDef": 107.7,
      "adjDefRank": 228,
      "adjEM": -11.65,
      "adjOff": 96.1,
      "adjOffRank": 319,
      "adjTempo": 68.1,
      "adjTempoRank": 183,
      "conference": "Sum",
      "rank": 293,
      "record": "12-16"
    },
    "Western Kentucky": {
      "adjDef": 100.1,
      "adjDefRank": 82,
      "adjEM": 14.45,
      "adjOff": 114.5,
      "adjOffRank": 40,
      "adjTempo": 68.8,
      "adjTempoRank": 143,
      "conference": "CUSA",
      "rank": 47,
      "record": "27-11"
    },
    "Western Michigan": {
      "adjDef": 108.2,
      "adjDefRank": 235,
      "adjEM": -2.02,
      "adjOff": 106.2,
      "adjOffRank": 144,
      "adjTempo": 67.8,
      "adjTempoRank": 193,
      "conference": "MAC",
      "rank": 188,
      "record": "17-15"
    },
    "Wichita St.": {
      "adjDef": 101.9,
      "adjDefRank": 111,
      "adjEM": 19.03,
      "adjOff": 121,
      "adjOffRank": 4,
      "adjTempo": 68.6,
      "adjTempoRank": 162,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 21,
      "record": "25-8",
      "seed": 4
    },
    "William & Mary": {
      "adjDef": 115.2,
      "adjDefRank": 335,
      "adjEM": 0.09,
      "adjOff": 115.3,
      "adjOffRank": 27,
      "adjTempo": 70,
      "adjTempoRank": 78,
      "conference": "CAA",
      "rank": 169,
      "record": "19-12"
    },
    "Winthrop": {
      "adjDef": 105.2,
      "adjDefRank": 176,
      "adjEM": 0.85,
      "adjOff": 106.1,
      "adjOffRank": 148,
      "adjTempo": 71.6,
      "adjTempoRank": 34,
      "conference": "BSth",
      "rank": 160,
      "record": "19-12"
    },
    "Wisconsin": {
      "adjDef": 99.1,
      "adjDefRank": 66,
      "adjEM": 10.68,
      "adjOff": 109.7,
      "adjOffRank": 83,
      "adjTempo": 62.7,
      "adjTempoRank": 347,
      "conference": "B10",
      "rank": 70,
      "record": "15-18"
    },
    "Wofford": {
      "adjDef": 108.5,
      "adjDefRank": 243,
      "adjEM": 0.59,
      "adjOff": 109.1,
      "adjOffRank": 97,
      "adjTempo": 66.1,
      "adjTempoRank": 273,
      "conference": "SC",
      "rank": 163,
      "record": "21-13"
    },
    "Wright St.": {
      "adjDef": 97.7,
      "adjDefRank": 51,
      "adjEM": 2.89,
      "adjOff": 100.6,
      "adjOffRank": 249,
      "adjTempo": 68.3,
      "adjTempoRank": 176,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 133,
      "record": "25-10",
      "seed": 14
    },
    "Wyoming": {
      "adjDef": 100.6,
      "adjDefRank": 92,
      "adjEM": 4.99,
      "adjOff": 105.6,
      "adjOffRank": 160,
      "adjTempo": 73.5,
      "adjTempoRank": 13,
      "conference": "MWC",
      "rank": 118,
      "record": "20-13"
    },
    "Xavier": {
      "adjDef": 98.6,
      "adjDefRank": 57,
      "adjEM": 21.51,
      "adjOff": 120.1,
      "adjOffRank": 8,
      "adjTempo": 70.5,
      "adjTempoRank": 62,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 15,
      "record": "29-6",
      "seed": 1
    },
    "Yale": {
      "adjDef": 107,
      "adjDefRank": 209,
      "adjEM": -3.18,
      "adjOff": 103.8,
      "adjOffRank": 189,
      "adjTempo": 68.8,
      "adjTempoRank": 140,
      "conference": "Ivy",
      "rank": 197,
      "record": "16-15"
    },
    "Youngstown St.": {
      "adjDef": 115.9,
      "adjDefRank": 338,
      "adjEM": -14.56,
      "adjOff": 101.4,
      "adjOffRank": 242,
      "adjTempo": 71.1,
      "adjTempoRank": 50,
      "conference": "Horz",
      "rank": 322,
      "record": "8-24"
    }
  },
  "2019": {
    "Abilene Christian": {
      "adjDef": 102,
      "adjDefRank": 121,
      "adjEM": 1.35,
      "adjOff": 103.4,
      "adjOffRank": 196,
      "adjTempo": 66.1,
      "adjTempoRank": 256,
      "conference": "Slnd",
      "finish": "Round of 64",
      "rank": 152,
      "record": "27-7",
      "seed": 15
    },
    "Air Force": {
      "adjDef": 107.7,
      "adjDefRank": 242,
      "adjEM": -7.06,
      "adjOff": 100.6,
      "adjOffRank": 252,
      "adjTempo": 66.6,
      "adjTempoRank": 235,
      "conference": "MWC",
      "rank": 243,
      "record": "14-18"
    },
    "Akron": {
      "adjDef": 94.1,
      "adjDefRank": 22,
      "adjEM": 5.87,
      "adjOff": 100,
      "adjOffRank": 259,
      "adjTempo": 66.2,
      "adjTempoRank": 254,
      "conference": "MAC",
      "rank": 108,
      "record": "17-16"
    },
    "Alabama": {
      "adjDef": 97.5,
      "adjDefRank": 58,
      "adjEM": 10.68,
      "adjOff": 108.2,
      "adjOffRank": 103,
      "adjTempo": 68.7,
      "adjTempoRank": 117,
      "conference": "SEC",
      "rank": 64,
      "record": "18-16"
    },
    "Alabama A&M": {
      "adjDef": 109.1,
      "adjDefRank": 264,
      "adjEM": -22.17,
      "adjOff": 87,
      "adjOffRank": 350,
      "adjTempo": 65.7,
      "adjTempoRank": 282,
      "conference": "SWAC",
      "rank": 344,
      "record": "5-27"
    },
    "Alabama St.": {
      "adjDef": 110.9,
      "adjDefRank": 300,
      "adjEM": -15.77,
      "adjOff": 95.2,
      "adjOffRank": 323,
      "adjTempo": 67.2,
      "adjTempoRank": 196,
      "conference": "SWAC",
      "rank": 328,
      "record": "12-19"
    },
    "Albany": {
      "adjDef": 108.3,
      "adjDefRank": 253,
      "adjEM": -9.86,
      "adjOff": 98.4,
      "adjOffRank": 285,
      "adjTempo": 65.9,
      "adjTempoRank": 270,
      "conference": "AE",
      "rank": 279,
      "record": "12-20"
    },
    "Alcorn St.": {
      "adjDef": 113.4,
      "adjDefRank": 330,
      "adjEM": -24.07,
      "adjOff": 89.3,
      "adjOffRank": 348,
      "adjTempo": 65.8,
      "adjTempoRank": 276,
      "conference": "SWAC",
      "rank": 349,
      "record": "10-21"
    },
    "American": {
      "adjDef": 105.2,
      "adjDefRank": 182,
      "adjEM": -3.12,
      "adjOff": 102.1,
      "adjOffRank": 220,
      "adjTempo": 66.6,
      "adjTempoRank": 233,
      "conference": "Pat",
      "rank": 200,
      "record": "15-15"
    },
    "Appalachian St.": {
      "adjDef": 109.7,
      "adjDefRank": 274,
      "adjEM": -3.53,
      "adjOff": 106.2,
      "adjOffRank": 142,
      "adjTempo": 71.6,
      "adjTempoRank": 28,
      "conference": "SB",
      "rank": 206,
      "record": "11-21"
    },
    "Arizona": {
      "adjDef": 98.2,
      "adjDefRank": 63,
      "adjEM": 7.35,
      "adjOff": 105.5,
      "adjOffRank": 153,
      "adjTempo": 66.7,
      "adjTempoRank": 227,
      "conference": "P12",
      "rank": 94,
      "record": "17-15"
    },
    "Arizona St.": {
      "adjDef": 98.2,
      "adjDefRank": 64,
      "adjEM": 11.55,
      "adjOff": 109.7,
      "adjOffRank": 73,
      "adjTempo": 70.8,
      "adjTempoRank": 47,
      "conference": "P12",
      "finish": "Round of 64",
      "rank": 57,
      "record": "23-11",
      "seed": 11
    },
    "Arkansas": {
      "adjDef": 97.2,
      "adjDefRank": 53,
      "adjEM": 12.92,
      "adjOff": 110.1,
      "adjOffRank": 66,
      "adjTempo": 70.4,
      "adjTempoRank": 57,
      "conference": "SEC",
      "rank": 54,
      "record": "18-16"
    },
    "Arkansas Pine Bluff": {
      "adjDef": 109.7,
      "adjDefRank": 273,
      "adjEM": -13.14,
      "adjOff": 96.6,
      "adjOffRank": 308,
      "adjTempo": 65.3,
      "adjTempoRank": 296,
      "conference": "SWAC",
      "rank": 313,
      "record": "13-19"
    },
    "Arkansas St.": {
      "adjDef": 110.2,
      "adjDefRank": 285,
      "adjEM": -7.8,
      "adjOff": 102.4,
      "adjOffRank": 210,
      "adjTempo": 68.3,
      "adjTempoRank": 140,
      "conference": "SB",
      "rank": 254,
      "record": "13-19"
    },
    "Army": {
      "adjDef": 103.2,
      "adjDefRank": 147,
      "adjEM": -6.89,
      "adjOff": 96.3,
      "adjOffRank": 312,
      "adjTempo": 69.8,
      "adjTempoRank": 75,
      "conference": "Pat",
      "rank": 242,
      "record": "13-19"
    },
    "Auburn": {
      "adjDef": 95.9,
      "adjDefRank": 36,
      "adjEM": 25,
      "adjOff": 120.9,
      "adjOffRank": 6,
      "adjTempo": 67.9,
      "adjTempoRank": 155,
      "conference": "SEC",
      "finish": "Final Four",
      "rank": 11,
      "record": "30-10",
      "seed": 5
    },
    "Austin Peay": {
      "adjDef": 106.8,
      "adjDefRank": 226,
      "adjEM": 4.29,
      "adjOff": 111.1,
      "adjOffRank": 55,
      "adjTempo": 69,
      "adjTempoRank": 105,
      "conference": "OVC",
      "rank": 128,
      "record": "22-11"
    },
    "BYU": {
      "adjDef": 104.3,
      "adjDefRank": 162,
      "adjEM": 7.72,
      "adjOff": 112,
      "adjOffRank": 46,
      "adjTempo": 70.9,
      "adjTempoRank": 44,
      "conference": "WCC",
      "rank": 86,
      "record": "19-13"
    },
    "Ball St.": {
      "adjDef": 98.6,
      "adjDefRank": 72,
      "adjEM": 3.91,
      "adjOff": 102.5,
      "adjOffRank": 209,
      "adjTempo": 70.3,
      "adjTempoRank": 59,
      "conference": "MAC",
      "rank": 133,
      "record": "16-17"
    },
    "Baylor": {
      "adjDef": 98.8,
      "adjDefRank": 75,
      "adjEM": 16.48,
      "adjOff": 115.3,
      "adjOffRank": 20,
      "adjTempo": 65.2,
      "adjTempoRank": 297,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 35,
      "record": "20-14",
      "seed": 9
    },
    "Belmont": {
      "adjDef": 101.5,
      "adjDefRank": 117,
      "adjEM": 14.09,
      "adjOff": 115.6,
      "adjOffRank": 18,
      "adjTempo": 71.3,
      "adjTempoRank": 34,
      "conference": "OVC",
      "finish": "Round of 64",
      "rank": 49,
      "record": "27-6",
      "seed": 11
    },
    "Bethune Cookman": {
      "adjDef": 105.5,
      "adjDefRank": 191,
      "adjEM": -11.92,
      "adjOff": 93.6,
      "adjOffRank": 332,
      "adjTempo": 72.2,
      "adjTempoRank": 18,
      "conference": "MEAC",
      "rank": 306,
      "record": "14-17"
    },
    "Binghamton": {
      "adjDef": 112.9,
      "adjDefRank": 322,
      "adjEM": -16.74,
      "adjOff": 96.1,
      "adjOffRank": 316,
      "adjTempo": 65.8,
      "adjTempoRank": 273,
      "conference": "AE",
      "rank": 332,
      "record": "10-23"
    },
    "Boise St.": {
      "adjDef": 104.2,
      "adjDefRank": 161,
      "adjEM": 3.13,
      "adjOff": 107.4,
      "adjOffRank": 116,
      "adjTempo": 65.5,
      "adjTempoRank": 286,
      "conference": "MWC",
      "rank": 139,
      "record": "13-20"
    },
    "Boston College": {
      "adjDef": 102.7,
      "adjDefRank": 138,
      "adjEM": 4.52,
      "adjOff": 107.2,
      "adjOffRank": 119,
      "adjTempo": 67.3,
      "adjTempoRank": 192,
      "conference": "ACC",
      "rank": 127,
      "record": "14-17"
    },
    "Boston University": {
      "adjDef": 110.4,
      "adjDefRank": 288,
      "adjEM": -5.96,
      "adjOff": 104.4,
      "adjOffRank": 176,
      "adjTempo": 66.3,
      "adjTempoRank": 247,
      "conference": "Pat",
      "rank": 231,
      "record": "15-18"
    },
    "Bowling Green": {
      "adjDef": 102.3,
      "adjDefRank": 132,
      "adjEM": 6.04,
      "adjOff": 108.4,
      "adjOffRank": 99,
      "adjTempo": 70.5,
      "adjTempoRank": 54,
      "conference": "MAC",
      "rank": 105,
      "record": "22-12"
    },
    "Bradley": {
      "adjDef": 101.4,
      "adjDefRank": 113,
      "adjEM": 0.09,
      "adjOff": 101.5,
      "adjOffRank": 234,
      "adjTempo": 65.3,
      "adjTempoRank": 292,
      "conference": "MVC",
      "finish": "Round of 64",
      "rank": 161,
      "record": "20-15",
      "seed": 15
    },
    "Brown": {
      "adjDef": 98.4,
      "adjDefRank": 68,
      "adjEM": 1.24,
      "adjOff": 99.6,
      "adjOffRank": 269,
      "adjTempo": 71.1,
      "adjTempoRank": 36,
      "conference": "Ivy",
      "rank": 153,
      "record": "20-12"
    },
    "Bryant": {
      "adjDef": 115.1,
      "adjDefRank": 343,
      "adjEM": -15.64,
      "adjOff": 99.5,
      "adjOffRank": 273,
      "adjTempo": 67.6,
      "adjTempoRank": 176,
      "conference": "NEC",
      "rank": 327,
      "record": "10-20"
    },
    "Bucknell": {
      "adjDef": 103.9,
      "adjDefRank": 156,
      "adjEM": 1.85,
      "adjOff": 105.8,
      "adjOffRank": 149,
      "adjTempo": 70.7,
      "adjTempoRank": 48,
      "conference": "Pat",
      "rank": 145,
      "record": "21-12"
    },
    "Buffalo": {
      "adjDef": 95.4,
      "adjDefRank": 31,
      "adjEM": 19.85,
      "adjOff": 115.2,
      "adjOffRank": 21,
      "adjTempo": 73.6,
      "adjTempoRank": 11,
      "conference": "MAC",
      "finish": "Round of 32",
      "rank": 22,
      "record": "32-4",
      "seed": 6
    },
    "Butler": {
      "adjDef": 102.1,
      "adjDefRank": 123,
      "adjEM": 9.24,
      "adjOff": 111.3,
      "adjOffRank": 53,
      "adjTempo": 66,
      "adjTempoRank": 263,
      "conference": "BE",
      "rank": 72,
      "record": "16-17"
    },
    "Cal Baptist": {
      "adjDef": 108.9,
      "adjDefRank": 258,
      "adjEM": -2.82,
      "adjOff": 106.1,
      "adjOffRank": 144,
      "adjTempo": 67.9,
      "adjTempoRank": 156,
      "conference": "WAC",
      "rank": 197,
      "record": "16-15"
    },
    "Cal Poly": {
      "adjDef": 114.9,
      "adjDefRank": 342,
      "adjEM": -17.86,
      "adjOff": 97,
      "adjOffRank": 303,
      "adjTempo": 66.2,
      "adjTempoRank": 255,
      "conference": "BW",
      "rank": 334,
      "record": "6-23"
    },
    "Cal St. Bakersfield": {
      "adjDef": 107.7,
      "adjDefRank": 243,
      "adjEM": -5.13,
      "adjOff": 102.6,
      "adjOffRank": 207,
      "adjTempo": 65.2,
      "adjTempoRank": 303,
      "conference": "WAC",
      "rank": 226,
      "record": "18-16"
    },
    "Cal St. Fullerton": {
      "adjDef": 102.5,
      "adjDefRank": 134,
      "adjEM": -4.15,
      "adjOff": 98.3,
      "adjOffRank": 286,
      "adjTempo": 69.9,
      "adjTempoRank": 72,
      "conference": "BW",
      "rank": 210,
      "record": "16-18"
    },
    "Cal St. Northridge": {
      "adjDef": 112.5,
      "adjDefRank": 318,
      "adjEM": -7.74,
      "adjOff": 104.8,
      "adjOffRank": 170,
      "adjTempo": 70.9,
      "adjTempoRank": 43,
      "conference": "BW",
      "rank": 253,
      "record": "13-21"
    },
    "California": {
      "adjDef": 110.3,
      "adjDefRank": 286,
      "adjEM": -6.84,
      "adjOff": 103.5,
      "adjOffRank": 192,
      "adjTempo": 66.6,
      "adjTempoRank": 234,
      "conference": "P12",
      "rank": 241,
      "record": "8-23"
    },
    "Campbell": {
      "adjDef": 109.8,
      "adjDefRank": 278,
      "adjEM": -1.49,
      "adjOff": 108.3,
      "adjOffRank": 101,
      "adjTempo": 65.7,
      "adjTempoRank": 281,
      "conference": "BSth",
      "rank": 181,
      "record": "20-13"
    },
    "Canisius": {
      "adjDef": 111.5,
      "adjDefRank": 307,
      "adjEM": -9.91,
      "adjOff": 101.6,
      "adjOffRank": 230,
      "adjTempo": 67.7,
      "adjTempoRank": 169,
      "conference": "MAAC",
      "rank": 282,
      "record": "15-17"
    },
    "Central Arkansas": {
      "adjDef": 111.4,
      "adjDefRank": 304,
      "adjEM": -11.44,
      "adjOff": 99.9,
      "adjOffRank": 262,
      "adjTempo": 70.5,
      "adjTempoRank": 53,
      "conference": "Slnd",
      "rank": 302,
      "record": "14-19"
    },
    "Central Connecticut": {
      "adjDef": 112.1,
      "adjDefRank": 313,
      "adjEM": -14.89,
      "adjOff": 97.2,
      "adjOffRank": 301,
      "adjTempo": 69.1,
      "adjTempoRank": 101,
      "conference": "NEC",
      "rank": 321,
      "record": "11-20"
    },
    "Central Michigan": {
      "adjDef": 106.1,
      "adjDefRank": 206,
      "adjEM": 4.27,
      "adjOff": 110.4,
      "adjOffRank": 63,
      "adjTempo": 71.3,
      "adjTempoRank": 33,
      "conference": "MAC",
      "rank": 129,
      "record": "23-12"
    },
    "Charleston": {
      "adjDef": 103.7,
      "adjDefRank": 153,
      "adjEM": 4.87,
      "adjOff": 108.5,
      "adjOffRank": 93,
      "adjTempo": 65.4,
      "adjTempoRank": 289,
      "conference": "CAA",
      "rank": 123,
      "record": "24-9"
    },
    "Charleston Southern": {
      "adjDef": 101,
      "adjDefRank": 105,
      "adjEM": 0.17,
      "adjOff": 101.1,
      "adjOffRank": 246,
      "adjTempo": 69.5,
      "adjTempoRank": 87,
      "conference": "BSth",
      "rank": 160,
      "record": "18-16"
    },
    "Charlotte": {
      "adjDef": 106.8,
      "adjDefRank": 224,
      "adjEM": -11.16,
      "adjOff": 95.6,
      "adjOffRank": 319,
      "adjTempo": 64.5,
      "adjTempoRank": 325,
      "conference": "CUSA",
      "rank": 297,
      "record": "8-21"
    },
    "Chattanooga": {
      "adjDef": 109.7,
      "adjDefRank": 275,
      "adjEM": -8.29,
      "adjOff": 101.5,
      "adjOffRank": 237,
      "adjTempo": 66.1,
      "adjTempoRank": 258,
      "conference": "SC",
      "rank": 266,
      "record": "12-20"
    },
    "Chicago St.": {
      "adjDef": 118.6,
      "adjDefRank": 351,
      "adjEM": -28.26,
      "adjOff": 90.4,
      "adjOffRank": 340,
      "adjTempo": 70.1,
      "adjTempoRank": 65,
      "conference": "WAC",
      "rank": 351,
      "record": "3-29"
    },
    "Cincinnati": {
      "adjDef": 94.8,
      "adjDefRank": 28,
      "adjEM": 17.5,
      "adjOff": 112.3,
      "adjOffRank": 42,
      "adjTempo": 63.4,
      "adjTempoRank": 338,
      "conference": "Amer",
      "finish": "Round of 64",
      "rank": 29,
      "record": "28-7",
      "seed": 7
    },
    "Clemson": {
      "adjDef": 91.8,
      "adjDefRank": 14,
      "adjEM": 16.14,
      "adjOff": 108,
      "adjOffRank": 106,
      "adjTempo": 66.2,
      "adjTempoRank": 252,
      "conference": "ACC",
      "rank": 36,
      "record": "20-14"
    },
    "Cleveland St.": {
      "adjDef": 111.6,
      "adjDefRank": 309,
      "adjEM": -9.7,
      "adjOff": 101.9,
      "adjOffRank": 223,
      "adjTempo": 69.1,
      "adjTempoRank": 103,
      "conference": "Horz",
      "rank": 278,
      "record": "10-21"
    },
    "Coastal Carolina": {
      "adjDef": 105.7,
      "adjDefRank": 197,
      "adjEM": 1.03,
      "adjOff": 106.8,
      "adjOffRank": 132,
      "adjTempo": 69.8,
      "adjTempoRank": 76,
      "conference": "SB",
      "rank": 154,
      "record": "17-17"
    },
    "Colgate": {
      "adjDef": 105.9,
      "adjDefRank": 201,
      "adjEM": 4.6,
      "adjOff": 110.5,
      "adjOffRank": 60,
      "adjTempo": 66.4,
      "adjTempoRank": 237,
      "conference": "Pat",
      "finish": "Round of 64",
      "rank": 126,
      "record": "24-11",
      "seed": 15
    },
    "Colorado": {
      "adjDef": 96.1,
      "adjDefRank": 39,
      "adjEM": 10.75,
      "adjOff": 106.8,
      "adjOffRank": 131,
      "adjTempo": 68.1,
      "adjTempoRank": 147,
      "conference": "P12",
      "rank": 63,
      "record": "23-13"
    },
    "Colorado St.": {
      "adjDef": 109,
      "adjDefRank": 261,
      "adjEM": -1.39,
      "adjOff": 107.6,
      "adjOffRank": 112,
      "adjTempo": 67.1,
      "adjTempoRank": 204,
      "conference": "MWC",
      "rank": 180,
      "record": "12-20"
    },
    "Columbia": {
      "adjDef": 106.5,
      "adjDefRank": 218,
      "adjEM": -4.55,
      "adjOff": 101.9,
      "adjOffRank": 222,
      "adjTempo": 67.6,
      "adjTempoRank": 178,
      "conference": "Ivy",
      "rank": 215,
      "record": "10-18"
    },
    "Connecticut": {
      "adjDef": 102.2,
      "adjDefRank": 129,
      "adjEM": 6.78,
      "adjOff": 109,
      "adjOffRank": 85,
      "adjTempo": 68.6,
      "adjTempoRank": 122,
      "conference": "Amer",
      "rank": 98,
      "record": "16-17"
    },
    "Coppin St.": {
      "adjDef": 109.3,
      "adjDefRank": 266,
      "adjEM": -20.33,
      "adjOff": 88.9,
      "adjOffRank": 349,
      "adjTempo": 70.2,
      "adjTempoRank": 60,
      "conference": "MEAC",
      "rank": 342,
      "record": "8-25"
    },
    "Cornell": {
      "adjDef": 104.9,
      "adjDefRank": 179,
      "adjEM": -4.99,
      "adjOff": 99.9,
      "adjOffRank": 264,
      "adjTempo": 67.9,
      "adjTempoRank": 154,
      "conference": "Ivy",
      "rank": 221,
      "record": "15-16"
    },
    "Creighton": {
      "adjDef": 99.3,
      "adjDefRank": 83,
      "adjEM": 12.67,
      "adjOff": 112,
      "adjOffRank": 47,
      "adjTempo": 68.7,
      "adjTempoRank": 118,
      "conference": "BE",
      "rank": 55,
      "record": "20-15"
    },
    "Dartmouth": {
      "adjDef": 108.1,
      "adjDefRank": 250,
      "adjEM": -4.7,
      "adjOff": 103.4,
      "adjOffRank": 194,
      "adjTempo": 65.9,
      "adjTempoRank": 271,
      "conference": "Ivy",
      "rank": 218,
      "record": "11-19"
    },
    "Davidson": {
      "adjDef": 100.4,
      "adjDefRank": 98,
      "adjEM": 7.78,
      "adjOff": 108.2,
      "adjOffRank": 102,
      "adjTempo": 64.7,
      "adjTempoRank": 319,
      "conference": "A10",
      "rank": 85,
      "record": "24-10"
    },
    "Dayton": {
      "adjDef": 100.8,
      "adjDefRank": 103,
      "adjEM": 11.27,
      "adjOff": 112.1,
      "adjOffRank": 43,
      "adjTempo": 64.7,
      "adjTempoRank": 322,
      "conference": "A10",
      "rank": 62,
      "record": "21-12"
    },
    "DePaul": {
      "adjDef": 106.7,
      "adjDefRank": 223,
      "adjEM": 5.02,
      "adjOff": 111.7,
      "adjOffRank": 49,
      "adjTempo": 70.1,
      "adjTempoRank": 64,
      "conference": "BE",
      "rank": 118,
      "record": "19-17"
    },
    "Delaware": {
      "adjDef": 113.2,
      "adjDefRank": 328,
      "adjEM": -8.28,
      "adjOff": 104.9,
      "adjOffRank": 165,
      "adjTempo": 63.3,
      "adjTempoRank": 341,
      "conference": "CAA",
      "rank": 265,
      "record": "17-16"
    },
    "Delaware St.": {
      "adjDef": 113.8,
      "adjDefRank": 333,
      "adjEM": -29.31,
      "adjOff": 84.5,
      "adjOffRank": 352,
      "adjTempo": 69.7,
      "adjTempoRank": 77,
      "conference": "MEAC",
      "rank": 352,
      "record": "6-25"
    },
    "Denver": {
      "adjDef": 113,
      "adjDefRank": 325,
      "adjEM": -13.05,
      "adjOff": 99.9,
      "adjOffRank": 261,
      "adjTempo": 67.1,
      "adjTempoRank": 203,
      "conference": "Sum",
      "rank": 312,
      "record": "8-22"
    },
    "Detroit": {
      "adjDef": 112.6,
      "adjDefRank": 320,
      "adjEM": -6.11,
      "adjOff": 106.5,
      "adjOffRank": 138,
      "adjTempo": 68.6,
      "adjTempoRank": 125,
      "conference": "Horz",
      "rank": 233,
      "record": "11-20"
    },
    "Drake": {
      "adjDef": 102.8,
      "adjDefRank": 139,
      "adjEM": 3.76,
      "adjOff": 106.5,
      "adjOffRank": 137,
      "adjTempo": 69.3,
      "adjTempoRank": 94,
      "conference": "MVC",
      "rank": 135,
      "record": "24-10"
    },
    "Drexel": {
      "adjDef": 113.2,
      "adjDefRank": 327,
      "adjEM": -7.57,
      "adjOff": 105.6,
      "adjOffRank": 151,
      "adjTempo": 69.1,
      "adjTempoRank": 102,
      "conference": "CAA",
      "rank": 251,
      "record": "13-19"
    },
    "Duke": {
      "adjDef": 89.3,
      "adjDefRank": 6,
      "adjEM": 30.62,
      "adjOff": 120,
      "adjOffRank": 7,
      "adjTempo": 72.1,
      "adjTempoRank": 20,
      "conference": "ACC",
      "finish": "Elite Eight",
      "rank": 4,
      "record": "32-6",
      "seed": 1
    },
    "Duquesne": {
      "adjDef": 105.5,
      "adjDefRank": 190,
      "adjEM": -0.9,
      "adjOff": 104.6,
      "adjOffRank": 171,
      "adjTempo": 69.8,
      "adjTempoRank": 73,
      "conference": "A10",
      "rank": 173,
      "record": "19-13"
    },
    "East Carolina": {
      "adjDef": 106.9,
      "adjDefRank": 227,
      "adjEM": -8.24,
      "adjOff": 98.7,
      "adjOffRank": 282,
      "adjTempo": 68.1,
      "adjTempoRank": 148,
      "conference": "Amer",
      "rank": 264,
      "record": "10-21"
    },
    "East Tennessee St.": {
      "adjDef": 101.4,
      "adjDefRank": 115,
      "adjEM": 8.48,
      "adjOff": 109.9,
      "adjOffRank": 67,
      "adjTempo": 67.2,
      "adjTempoRank": 201,
      "conference": "SC",
      "rank": 80,
      "record": "24-10"
    },
    "Eastern Illinois": {
      "adjDef": 112,
      "adjDefRank": 312,
      "adjEM": -11.94,
      "adjOff": 100,
      "adjOffRank": 258,
      "adjTempo": 66.9,
      "adjTempoRank": 214,
      "conference": "OVC",
      "rank": 307,
      "record": "14-18"
    },
    "Eastern Kentucky": {
      "adjDef": 106,
      "adjDefRank": 204,
      "adjEM": -6.23,
      "adjOff": 99.8,
      "adjOffRank": 266,
      "adjTempo": 76.6,
      "adjTempoRank": 2,
      "conference": "OVC",
      "rank": 235,
      "record": "13-18"
    },
    "Eastern Michigan": {
      "adjDef": 103,
      "adjDefRank": 144,
      "adjEM": 0.47,
      "adjOff": 103.4,
      "adjOffRank": 195,
      "adjTempo": 62.9,
      "adjTempoRank": 345,
      "conference": "MAC",
      "rank": 159,
      "record": "15-17"
    },
    "Eastern Washington": {
      "adjDef": 107.6,
      "adjDefRank": 240,
      "adjEM": -6.36,
      "adjOff": 101.3,
      "adjOffRank": 242,
      "adjTempo": 67.8,
      "adjTempoRank": 163,
      "conference": "BSky",
      "rank": 237,
      "record": "16-18"
    },
    "Elon": {
      "adjDef": 111.4,
      "adjDefRank": 306,
      "adjEM": -12.41,
      "adjOff": 99,
      "adjOffRank": 277,
      "adjTempo": 68,
      "adjTempoRank": 150,
      "conference": "CAA",
      "rank": 309,
      "record": "11-21"
    },
    "Evansville": {
      "adjDef": 104.9,
      "adjDefRank": 177,
      "adjEM": -5.2,
      "adjOff": 99.7,
      "adjOffRank": 268,
      "adjTempo": 69.5,
      "adjTempoRank": 86,
      "conference": "MVC",
      "rank": 227,
      "record": "11-21"
    },
    "FIU": {
      "adjDef": 105.8,
      "adjDefRank": 198,
      "adjEM": -3.41,
      "adjOff": 102.4,
      "adjOffRank": 211,
      "adjTempo": 77.6,
      "adjTempoRank": 1,
      "conference": "CUSA",
      "rank": 204,
      "record": "20-14"
    },
    "Fairfield": {
      "adjDef": 107.5,
      "adjDefRank": 235,
      "adjEM": -11.43,
      "adjOff": 96,
      "adjOffRank": 317,
      "adjTempo": 67.8,
      "adjTempoRank": 160,
      "conference": "MAAC",
      "rank": 301,
      "record": "9-22"
    },
    "Fairleigh Dickinson": {
      "adjDef": 110.7,
      "adjDefRank": 295,
      "adjEM": -4.22,
      "adjOff": 106.5,
      "adjOffRank": 139,
      "adjTempo": 66.8,
      "adjTempoRank": 223,
      "conference": "NEC",
      "finish": "Round of 64",
      "rank": 211,
      "record": "21-14",
      "seed": 16
    },
    "Florida": {
      "adjDef": 92.2,
      "adjDefRank": 16,
      "adjEM": 18.3,
      "adjOff": 110.5,
      "adjOffRank": 61,
      "adjTempo": 62.9,
      "adjTempoRank": 344,
      "conference": "SEC",
      "finish": "Round of 32",
      "rank": 26,
      "record": "20-16",
      "seed": 10
    },
    "Florida A&M": {
      "adjDef": 103.3,
      "adjDefRank": 148,
      "adjEM": -13.89,
      "adjOff": 89.4,
      "adjOffRank": 347,
      "adjTempo": 64.9,
      "adjTempoRank": 314,
      "conference": "MEAC",
      "rank": 318,
      "record": "12-19"
    },
    "Florida Atlantic": {
      "adjDef": 99.9,
      "adjDefRank": 91,
      "adjEM": 0.03,
      "adjOff": 99.9,
      "adjOffRank": 263,
      "adjTempo": 67.4,
      "adjTempoRank": 188,
      "conference": "CUSA",
      "rank": 162,
      "record": "17-16"
    },
    "Florida Gulf Coast": {
      "adjDef": 106.1,
      "adjDefRank": 207,
      "adjEM": -4.52,
      "adjOff": 101.6,
      "adjOffRank": 233,
      "adjTempo": 67.9,
      "adjTempoRank": 152,
      "conference": "ASun",
      "rank": 213,
      "record": "14-18"
    },
    "Florida St.": {
      "adjDef": 90.4,
      "adjDefRank": 10,
      "adjEM": 22.39,
      "adjOff": 112.8,
      "adjOffRank": 36,
      "adjTempo": 68.6,
      "adjTempoRank": 121,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 14,
      "record": "29-8",
      "seed": 4
    },
    "Fordham": {
      "adjDef": 104.7,
      "adjDefRank": 168,
      "adjEM": -8,
      "adjOff": 96.7,
      "adjOffRank": 306,
      "adjTempo": 65.2,
      "adjTempoRank": 304,
      "conference": "A10",
      "rank": 260,
      "record": "12-20"
    },
    "Fresno St.": {
      "adjDef": 99,
      "adjDefRank": 77,
      "adjEM": 9.36,
      "adjOff": 108.4,
      "adjOffRank": 100,
      "adjTempo": 67.9,
      "adjTempoRank": 157,
      "conference": "MWC",
      "rank": 71,
      "record": "23-9"
    },
    "Furman": {
      "adjDef": 97.4,
      "adjDefRank": 57,
      "adjEM": 11.52,
      "adjOff": 108.9,
      "adjOffRank": 89,
      "adjTempo": 66.6,
      "adjTempoRank": 230,
      "conference": "SC",
      "rank": 59,
      "record": "25-8"
    },
    "Gardner Webb": {
      "adjDef": 107.8,
      "adjDefRank": 245,
      "adjEM": -0.04,
      "adjOff": 107.8,
      "adjOffRank": 109,
      "adjTempo": 67.4,
      "adjTempoRank": 189,
      "conference": "BSth",
      "finish": "Round of 64",
      "rank": 163,
      "record": "23-12",
      "seed": 16
    },
    "George Mason": {
      "adjDef": 104,
      "adjDefRank": 157,
      "adjEM": 0.56,
      "adjOff": 104.6,
      "adjOffRank": 173,
      "adjTempo": 67.7,
      "adjTempoRank": 167,
      "conference": "A10",
      "rank": 156,
      "record": "18-15"
    },
    "George Washington": {
      "adjDef": 106.2,
      "adjDefRank": 211,
      "adjEM": -10.81,
      "adjOff": 95.4,
      "adjOffRank": 321,
      "adjTempo": 67.2,
      "adjTempoRank": 197,
      "conference": "A10",
      "rank": 293,
      "record": "9-24"
    },
    "Georgetown": {
      "adjDef": 102.5,
      "adjDefRank": 133,
      "adjEM": 6.45,
      "adjOff": 108.9,
      "adjOffRank": 90,
      "adjTempo": 71.9,
      "adjTempoRank": 25,
      "conference": "BE",
      "rank": 100,
      "record": "19-14"
    },
    "Georgia": {
      "adjDef": 102,
      "adjDefRank": 120,
      "adjEM": 4.13,
      "adjOff": 106.1,
      "adjOffRank": 143,
      "adjTempo": 68.4,
      "adjTempoRank": 134,
      "conference": "SEC",
      "rank": 132,
      "record": "11-21"
    },
    "Georgia Southern": {
      "adjDef": 102.2,
      "adjDefRank": 128,
      "adjEM": 6.33,
      "adjOff": 108.5,
      "adjOffRank": 94,
      "adjTempo": 73.2,
      "adjTempoRank": 14,
      "conference": "SB",
      "rank": 103,
      "record": "21-12"
    },
    "Georgia St.": {
      "adjDef": 102.8,
      "adjDefRank": 143,
      "adjEM": 4.85,
      "adjOff": 107.7,
      "adjOffRank": 111,
      "adjTempo": 70,
      "adjTempoRank": 69,
      "conference": "SB",
      "finish": "Round of 64",
      "rank": 124,
      "record": "24-10",
      "seed": 14
    },
    "Georgia Tech": {
      "adjDef": 96.5,
      "adjDefRank": 43,
      "adjEM": 5.26,
      "adjOff": 101.7,
      "adjOffRank": 228,
      "adjTempo": 66.8,
      "adjTempoRank": 225,
      "conference": "ACC",
      "rank": 115,
      "record": "14-18"
    },
    "Gonzaga": {
      "adjDef": 91.6,
      "adjDefRank": 12,
      "adjEM": 32.85,
      "adjOff": 124.5,
      "adjOffRank": 1,
      "adjTempo": 70.2,
      "adjTempoRank": 62,
      "conference": "WCC",
      "finish": "Elite Eight",
      "rank": 2,
      "record": "33-4",
      "seed": 1
    },
    "Grambling St.": {
      "adjDef": 104.4,
      "adjDefRank": 165,
      "adjEM": -8.56,
      "adjOff": 95.9,
      "adjOffRank": 318,
      "adjTempo": 69.6,
      "adjTempoRank": 82,
      "conference": "SWAC",
      "rank": 268,
      "record": "17-17"
    },
    "Grand Canyon": {
      "adjDef": 102.1,
      "adjDefRank": 124,
      "adjEM": 5.49,
      "adjOff": 107.6,
      "adjOffRank": 113,
      "adjTempo": 67.8,
      "adjTempoRank": 162,
      "conference": "WAC",
      "rank": 111,
      "record": "20-14"
    },
    "Green Bay": {
      "adjDef": 106.5,
      "adjDefRank": 219,
      "adjEM": -1.56,
      "adjOff": 104.9,
      "adjOffRank": 166,
      "adjTempo": 73.2,
      "adjTempoRank": 13,
      "conference": "Horz",
      "rank": 185,
      "record": "21-17"
    },
    "Hampton": {
      "adjDef": 108.2,
      "adjDefRank": 251,
      "adjEM": -0.85,
      "adjOff": 107.4,
      "adjOffRank": 115,
      "adjTempo": 71.3,
      "adjTempoRank": 32,
      "conference": "BSth",
      "rank": 170,
      "record": "18-17"
    },
    "Hartford": {
      "adjDef": 110.8,
      "adjDefRank": 297,
      "adjEM": -2.32,
      "adjOff": 108.5,
      "adjOffRank": 95,
      "adjTempo": 66.7,
      "adjTempoRank": 228,
      "conference": "AE",
      "rank": 194,
      "record": "18-15"
    },
    "Harvard": {
      "adjDef": 100.4,
      "adjDefRank": 99,
      "adjEM": 5.32,
      "adjOff": 105.7,
      "adjOffRank": 150,
      "adjTempo": 67.3,
      "adjTempoRank": 193,
      "conference": "Ivy",
      "rank": 114,
      "record": "19-12"
    },
    "Hawaii": {
      "adjDef": 106.8,
      "adjDefRank": 225,
      "adjEM": -3.12,
      "adjOff": 103.7,
      "adjOffRank": 188,
      "adjTempo": 66.3,
      "adjTempoRank": 243,
      "conference": "BW",
      "rank": 201,
      "record": "18-13"
    },
    "High Point": {
      "adjDef": 104.7,
      "adjDefRank": 169,
      "adjEM": -5,
      "adjOff": 99.7,
      "adjOffRank": 267,
      "adjTempo": 63.7,
      "adjTempoRank": 333,
      "conference": "BSth",
      "rank": 223,
      "record": "16-15"
    },
    "Hofstra": {
      "adjDef": 109,
      "adjDefRank": 262,
      "adjEM": 7.37,
      "adjOff": 116.4,
      "adjOffRank": 17,
      "adjTempo": 67.5,
      "adjTempoRank": 181,
      "conference": "CAA",
      "rank": 93,
      "record": "27-8"
    },
    "Holy Cross": {
      "adjDef": 108,
      "adjDefRank": 248,
      "adjEM": -6.81,
      "adjOff": 101.2,
      "adjOffRank": 244,
      "adjTempo": 63.6,
      "adjTempoRank": 336,
      "conference": "Pat",
      "rank": 239,
      "record": "16-17"
    },
    "Houston": {
      "adjDef": 91.2,
      "adjDefRank": 11,
      "adjEM": 24.13,
      "adjOff": 115.3,
      "adjOffRank": 19,
      "adjTempo": 66.1,
      "adjTempoRank": 260,
      "conference": "Amer",
      "finish": "Sweet 16",
      "rank": 12,
      "record": "33-4",
      "seed": 3
    },
    "Houston Baptist": {
      "adjDef": 112.9,
      "adjDefRank": 324,
      "adjEM": -9.88,
      "adjOff": 103,
      "adjOffRank": 201,
      "adjTempo": 73.9,
      "adjTempoRank": 9,
      "conference": "Slnd",
      "rank": 281,
      "record": "12-18"
    },
    "Howard": {
      "adjDef": 113.9,
      "adjDefRank": 334,
      "adjEM": -11.89,
      "adjOff": 102,
      "adjOffRank": 221,
      "adjTempo": 71.3,
      "adjTempoRank": 31,
      "conference": "MEAC",
      "rank": 305,
      "record": "17-17"
    },
    "IUPUI": {
      "adjDef": 105.8,
      "adjDefRank": 200,
      "adjEM": -2.07,
      "adjOff": 103.8,
      "adjOffRank": 186,
      "adjTempo": 68.9,
      "adjTempoRank": 111,
      "conference": "Horz",
      "rank": 191,
      "record": "16-17"
    },
    "Idaho": {
      "adjDef": 118.9,
      "adjDefRank": 352,
      "adjEM": -23.78,
      "adjOff": 95.2,
      "adjOffRank": 324,
      "adjTempo": 65.4,
      "adjTempoRank": 290,
      "conference": "BSky",
      "rank": 348,
      "record": "5-27"
    },
    "Idaho St.": {
      "adjDef": 118.4,
      "adjDefRank": 350,
      "adjEM": -16.18,
      "adjOff": 102.2,
      "adjOffRank": 216,
      "adjTempo": 66.8,
      "adjTempoRank": 221,
      "conference": "BSky",
      "rank": 329,
      "record": "11-19"
    },
    "Illinois": {
      "adjDef": 101.1,
      "adjDefRank": 108,
      "adjEM": 7.97,
      "adjOff": 109.1,
      "adjOffRank": 83,
      "adjTempo": 70.6,
      "adjTempoRank": 52,
      "conference": "B10",
      "rank": 84,
      "record": "12-21"
    },
    "Illinois Chicago": {
      "adjDef": 103.5,
      "adjDefRank": 152,
      "adjEM": -2.78,
      "adjOff": 100.8,
      "adjOffRank": 249,
      "adjTempo": 70,
      "adjTempoRank": 68,
      "conference": "Horz",
      "rank": 196,
      "record": "16-16"
    },
    "Illinois St.": {
      "adjDef": 104.8,
      "adjDefRank": 175,
      "adjEM": -3.45,
      "adjOff": 101.4,
      "adjOffRank": 240,
      "adjTempo": 66.7,
      "adjTempoRank": 226,
      "conference": "MVC",
      "rank": 205,
      "record": "17-16"
    },
    "Incarnate Word": {
      "adjDef": 120.6,
      "adjDefRank": 353,
      "adjEM": -23.06,
      "adjOff": 97.5,
      "adjOffRank": 298,
      "adjTempo": 64.9,
      "adjTempoRank": 313,
      "conference": "Slnd",
      "rank": 346,
      "record": "6-25"
    },
    "Indiana": {
      "adjDef": 95.4,
      "adjDefRank": 32,
      "adjEM": 13.73,
      "adjOff": 109.1,
      "adjOffRank": 82,
      "adjTempo": 66.9,
      "adjTempoRank": 215,
      "conference": "B10",
      "rank": 52,
      "record": "19-16"
    },
    "Indiana St.": {
      "adjDef": 105.4,
      "adjDefRank": 188,
      "adjEM": -3.99,
      "adjOff": 101.4,
      "adjOffRank": 239,
      "adjTempo": 68.9,
      "adjTempoRank": 106,
      "conference": "MVC",
      "rank": 208,
      "record": "15-16"
    },
    "Iona": {
      "adjDef": 109.7,
      "adjDefRank": 276,
      "adjEM": -2.88,
      "adjOff": 106.9,
      "adjOffRank": 129,
      "adjTempo": 70.6,
      "adjTempoRank": 51,
      "conference": "MAAC",
      "finish": "Round of 64",
      "rank": 198,
      "record": "17-16",
      "seed": 16
    },
    "Iowa": {
      "adjDef": 101.3,
      "adjDefRank": 111,
      "adjEM": 16.02,
      "adjOff": 117.4,
      "adjOffRank": 15,
      "adjTempo": 69.5,
      "adjTempoRank": 84,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 37,
      "record": "23-12",
      "seed": 10
    },
    "Iowa St.": {
      "adjDef": 96.8,
      "adjDefRank": 47,
      "adjEM": 22.09,
      "adjOff": 118.9,
      "adjOffRank": 9,
      "adjTempo": 67.7,
      "adjTempoRank": 171,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 15,
      "record": "23-12",
      "seed": 6
    },
    "Jackson St.": {
      "adjDef": 104.6,
      "adjDefRank": 167,
      "adjEM": -14.4,
      "adjOff": 90.2,
      "adjOffRank": 341,
      "adjTempo": 65.1,
      "adjTempoRank": 307,
      "conference": "SWAC",
      "rank": 319,
      "record": "13-19"
    },
    "Jacksonville": {
      "adjDef": 106.5,
      "adjDefRank": 216,
      "adjEM": -7.41,
      "adjOff": 99.1,
      "adjOffRank": 276,
      "adjTempo": 70.9,
      "adjTempoRank": 42,
      "conference": "ASun",
      "rank": 247,
      "record": "12-20"
    },
    "Jacksonville St.": {
      "adjDef": 99.6,
      "adjDefRank": 86,
      "adjEM": 5.81,
      "adjOff": 105.4,
      "adjOffRank": 157,
      "adjTempo": 67.6,
      "adjTempoRank": 173,
      "conference": "OVC",
      "rank": 110,
      "record": "24-9"
    },
    "James Madison": {
      "adjDef": 111.1,
      "adjDefRank": 302,
      "adjEM": -10.03,
      "adjOff": 101.1,
      "adjOffRank": 247,
      "adjTempo": 65.2,
      "adjTempoRank": 301,
      "conference": "CAA",
      "rank": 285,
      "record": "14-19"
    },
    "Kansas": {
      "adjDef": 92.3,
      "adjDefRank": 17,
      "adjEM": 21.57,
      "adjOff": 113.9,
      "adjOffRank": 27,
      "adjTempo": 70.1,
      "adjTempoRank": 66,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 17,
      "record": "26-10",
      "seed": 4
    },
    "Kansas St.": {
      "adjDef": 88.4,
      "adjDefRank": 3,
      "adjEM": 20.06,
      "adjOff": 108.4,
      "adjOffRank": 98,
      "adjTempo": 63.4,
      "adjTempoRank": 339,
      "conference": "B12",
      "finish": "Round of 64",
      "rank": 20,
      "record": "25-9",
      "seed": 4
    },
    "Kennesaw St.": {
      "adjDef": 110.9,
      "adjDefRank": 299,
      "adjEM": -18.91,
      "adjOff": 92,
      "adjOffRank": 338,
      "adjTempo": 68.2,
      "adjTempoRank": 144,
      "conference": "ASun",
      "rank": 339,
      "record": "6-26"
    },
    "Kent St.": {
      "adjDef": 107.5,
      "adjDefRank": 236,
      "adjEM": 2.38,
      "adjOff": 109.9,
      "adjOffRank": 69,
      "adjTempo": 67.5,
      "adjTempoRank": 183,
      "conference": "MAC",
      "rank": 143,
      "record": "22-11"
    },
    "Kentucky": {
      "adjDef": 90.1,
      "adjDefRank": 8,
      "adjEM": 27.57,
      "adjOff": 117.6,
      "adjOffRank": 14,
      "adjTempo": 65.6,
      "adjTempoRank": 284,
      "conference": "SEC",
      "finish": "Elite Eight",
      "rank": 8,
      "record": "30-7",
      "seed": 2
    },
    "LIU Brooklyn": {
      "adjDef": 106.5,
      "adjDefRank": 217,
      "adjEM": -8.2,
      "adjOff": 98.3,
      "adjOffRank": 290,
      "adjTempo": 70.8,
      "adjTempoRank": 45,
      "conference": "NEC",
      "rank": 263,
      "record": "16-16"
    },
    "LSU": {
      "adjDef": 97.5,
      "adjDefRank": 59,
      "adjEM": 20.22,
      "adjOff": 117.7,
      "adjOffRank": 12,
      "adjTempo": 70,
      "adjTempoRank": 67,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 19,
      "record": "28-7",
      "seed": 3
    },
    "La Salle": {
      "adjDef": 105.4,
      "adjDefRank": 189,
      "adjEM": -5.39,
      "adjOff": 100,
      "adjOffRank": 257,
      "adjTempo": 68.9,
      "adjTempoRank": 108,
      "conference": "A10",
      "rank": 228,
      "record": "10-21"
    },
    "Lafayette": {
      "adjDef": 112.9,
      "adjDefRank": 323,
      "adjEM": -11.27,
      "adjOff": 101.6,
      "adjOffRank": 231,
      "adjTempo": 68.9,
      "adjTempoRank": 109,
      "conference": "Pat",
      "rank": 298,
      "record": "10-20"
    },
    "Lamar": {
      "adjDef": 107.9,
      "adjDefRank": 247,
      "adjEM": -4.66,
      "adjOff": 103.3,
      "adjOffRank": 197,
      "adjTempo": 68.3,
      "adjTempoRank": 141,
      "conference": "Slnd",
      "rank": 217,
      "record": "20-13"
    },
    "Lehigh": {
      "adjDef": 109.7,
      "adjDefRank": 272,
      "adjEM": -0.79,
      "adjOff": 108.9,
      "adjOffRank": 91,
      "adjTempo": 71.6,
      "adjTempoRank": 29,
      "conference": "Pat",
      "rank": 169,
      "record": "20-11"
    },
    "Liberty": {
      "adjDef": 99.6,
      "adjDefRank": 87,
      "adjEM": 11.54,
      "adjOff": 111.1,
      "adjOffRank": 54,
      "adjTempo": 62.3,
      "adjTempoRank": 349,
      "conference": "ASun",
      "finish": "Round of 32",
      "rank": 58,
      "record": "29-7",
      "seed": 12
    },
    "Lipscomb": {
      "adjDef": 97.2,
      "adjDefRank": 49,
      "adjEM": 14.55,
      "adjOff": 111.7,
      "adjOffRank": 50,
      "adjTempo": 73.3,
      "adjTempoRank": 12,
      "conference": "ASun",
      "rank": 45,
      "record": "29-8"
    },
    "Little Rock": {
      "adjDef": 105,
      "adjDefRank": 180,
      "adjEM": -4.99,
      "adjOff": 100,
      "adjOffRank": 260,
      "adjTempo": 70.4,
      "adjTempoRank": 55,
      "conference": "SB",
      "rank": 222,
      "record": "10-21"
    },
    "Long Beach St.": {
      "adjDef": 106.3,
      "adjDefRank": 214,
      "adjEM": -5.08,
      "adjOff": 101.3,
      "adjOffRank": 243,
      "adjTempo": 71.5,
      "adjTempoRank": 30,
      "conference": "BW",
      "rank": 225,
      "record": "15-19"
    },
    "Longwood": {
      "adjDef": 107.3,
      "adjDefRank": 233,
      "adjEM": -7.83,
      "adjOff": 99.5,
      "adjOffRank": 274,
      "adjTempo": 67.2,
      "adjTempoRank": 195,
      "conference": "BSth",
      "rank": 256,
      "record": "16-18"
    },
    "Louisiana": {
      "adjDef": 107.9,
      "adjDefRank": 246,
      "adjEM": -1.21,
      "adjOff": 106.7,
      "adjOffRank": 133,
      "adjTempo": 72.1,
      "adjTempoRank": 21,
      "conference": "SB",
      "rank": 178,
      "record": "19-13"
    },
    "Louisiana Monroe": {
      "adjDef": 109.6,
      "adjDefRank": 271,
      "adjEM": 2.9,
      "adjOff": 112.5,
      "adjOffRank": 40,
      "adjTempo": 66.3,
      "adjTempoRank": 244,
      "conference": "SB",
      "rank": 140,
      "record": "19-16"
    },
    "Louisiana Tech": {
      "adjDef": 102.1,
      "adjDefRank": 126,
      "adjEM": 2.88,
      "adjOff": 105,
      "adjOffRank": 163,
      "adjTempo": 65.4,
      "adjTempoRank": 288,
      "conference": "CUSA",
      "rank": 141,
      "record": "20-13"
    },
    "Louisville": {
      "adjDef": 94.4,
      "adjDefRank": 24,
      "adjEM": 19.35,
      "adjOff": 113.7,
      "adjOffRank": 28,
      "adjTempo": 67.2,
      "adjTempoRank": 199,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 23,
      "record": "20-14",
      "seed": 7
    },
    "Loyola Chicago": {
      "adjDef": 100,
      "adjDefRank": 97,
      "adjEM": 4.18,
      "adjOff": 104.2,
      "adjOffRank": 180,
      "adjTempo": 62.7,
      "adjTempoRank": 346,
      "conference": "MVC",
      "rank": 131,
      "record": "20-14"
    },
    "Loyola MD": {
      "adjDef": 110.5,
      "adjDefRank": 291,
      "adjEM": -8.72,
      "adjOff": 101.8,
      "adjOffRank": 226,
      "adjTempo": 68.5,
      "adjTempoRank": 128,
      "conference": "Pat",
      "rank": 270,
      "record": "11-21"
    },
    "Loyola Marymount": {
      "adjDef": 99.8,
      "adjDefRank": 90,
      "adjEM": 3.84,
      "adjOff": 103.6,
      "adjOffRank": 191,
      "adjTempo": 62.7,
      "adjTempoRank": 347,
      "conference": "WCC",
      "rank": 134,
      "record": "22-12"
    },
    "Maine": {
      "adjDef": 111.9,
      "adjDefRank": 311,
      "adjEM": -18.68,
      "adjOff": 93.3,
      "adjOffRank": 335,
      "adjTempo": 64,
      "adjTempoRank": 329,
      "conference": "AE",
      "rank": 338,
      "record": "5-27"
    },
    "Manhattan": {
      "adjDef": 104.8,
      "adjDefRank": 172,
      "adjEM": -15.15,
      "adjOff": 89.6,
      "adjOffRank": 346,
      "adjTempo": 61.8,
      "adjTempoRank": 351,
      "conference": "MAAC",
      "rank": 323,
      "record": "11-21"
    },
    "Marist": {
      "adjDef": 109.8,
      "adjDefRank": 277,
      "adjEM": -9.52,
      "adjOff": 100.2,
      "adjOffRank": 254,
      "adjTempo": 64.5,
      "adjTempoRank": 323,
      "conference": "MAAC",
      "rank": 276,
      "record": "12-19"
    },
    "Marquette": {
      "adjDef": 96.7,
      "adjDefRank": 45,
      "adjEM": 16.52,
      "adjOff": 113.2,
      "adjOffRank": 32,
      "adjTempo": 68.8,
      "adjTempoRank": 116,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 33,
      "record": "24-10",
      "seed": 5
    },
    "Marshall": {
      "adjDef": 106.3,
      "adjDefRank": 213,
      "adjEM": 0.56,
      "adjOff": 106.9,
      "adjOffRank": 128,
      "adjTempo": 74.3,
      "adjTempoRank": 5,
      "conference": "CUSA",
      "rank": 157,
      "record": "23-14"
    },
    "Maryland": {
      "adjDef": 94.1,
      "adjDefRank": 21,
      "adjEM": 19.29,
      "adjOff": 113.4,
      "adjOffRank": 31,
      "adjTempo": 66,
      "adjTempoRank": 264,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 24,
      "record": "23-11",
      "seed": 6
    },
    "Maryland Eastern Shore": {
      "adjDef": 114.8,
      "adjDefRank": 340,
      "adjEM": -29.42,
      "adjOff": 85.4,
      "adjOffRank": 351,
      "adjTempo": 63.1,
      "adjTempoRank": 343,
      "conference": "MEAC",
      "rank": 353,
      "record": "7-25"
    },
    "Massachusetts": {
      "adjDef": 109.2,
      "adjDefRank": 265,
      "adjEM": -6.29,
      "adjOff": 102.9,
      "adjOffRank": 203,
      "adjTempo": 67.3,
      "adjTempoRank": 191,
      "conference": "A10",
      "rank": 236,
      "record": "11-21"
    },
    "McNeese St.": {
      "adjDef": 114.9,
      "adjDefRank": 341,
      "adjEM": -16.74,
      "adjOff": 98.1,
      "adjOffRank": 292,
      "adjTempo": 66.3,
      "adjTempoRank": 246,
      "conference": "Slnd",
      "rank": 331,
      "record": "9-22"
    },
    "Memphis": {
      "adjDef": 97.4,
      "adjDefRank": 54,
      "adjEM": 11.78,
      "adjOff": 109.2,
      "adjOffRank": 81,
      "adjTempo": 74.2,
      "adjTempoRank": 7,
      "conference": "Amer",
      "rank": 56,
      "record": "22-14"
    },
    "Mercer": {
      "adjDef": 106.5,
      "adjDefRank": 220,
      "adjEM": -3.06,
      "adjOff": 103.4,
      "adjOffRank": 193,
      "adjTempo": 66.8,
      "adjTempoRank": 224,
      "conference": "SC",
      "rank": 199,
      "record": "11-20"
    },
    "Miami FL": {
      "adjDef": 100.8,
      "adjDefRank": 104,
      "adjEM": 9.03,
      "adjOff": 109.9,
      "adjOffRank": 68,
      "adjTempo": 67,
      "adjTempoRank": 208,
      "conference": "ACC",
      "rank": 75,
      "record": "14-18"
    },
    "Miami OH": {
      "adjDef": 103.2,
      "adjDefRank": 146,
      "adjEM": 1.62,
      "adjOff": 104.8,
      "adjOffRank": 169,
      "adjTempo": 66.3,
      "adjTempoRank": 245,
      "conference": "MAC",
      "rank": 148,
      "record": "15-17"
    },
    "Michigan": {
      "adjDef": 86.2,
      "adjDefRank": 2,
      "adjEM": 28.32,
      "adjOff": 114.5,
      "adjOffRank": 24,
      "adjTempo": 64.8,
      "adjTempoRank": 317,
      "conference": "B10",
      "finish": "Sweet 16",
      "rank": 6,
      "record": "30-7",
      "seed": 2
    },
    "Michigan St.": {
      "adjDef": 90.2,
      "adjDefRank": 9,
      "adjEM": 30.81,
      "adjOff": 121,
      "adjOffRank": 5,
      "adjTempo": 66.9,
      "adjTempoRank": 211,
      "conference": "B10",
      "finish": "Final Four",
      "rank": 3,
      "record": "32-7",
      "seed": 2
    },
    "Middle Tennessee": {
      "adjDef": 105.6,
      "adjDefRank": 193,
      "adjEM": -7.28,
      "adjOff": 98.3,
      "adjOffRank": 288,
      "adjTempo": 67.6,
      "adjTempoRank": 175,
      "conference": "CUSA",
      "rank": 246,
      "record": "11-21"
    },
    "Milwaukee": {
      "adjDef": 109.6,
      "adjDefRank": 269,
      "adjEM": -11,
      "adjOff": 98.6,
      "adjOffRank": 283,
      "adjTempo": 66.1,
      "adjTempoRank": 259,
      "conference": "Horz",
      "rank": 296,
      "record": "9-22"
    },
    "Minnesota": {
      "adjDef": 97,
      "adjDefRank": 48,
      "adjEM": 14.35,
      "adjOff": 111.4,
      "adjOffRank": 52,
      "adjTempo": 67.4,
      "adjTempoRank": 186,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 46,
      "record": "22-14",
      "seed": 10
    },
    "Mississippi": {
      "adjDef": 99.2,
      "adjDefRank": 80,
      "adjEM": 13.98,
      "adjOff": 113.2,
      "adjOffRank": 33,
      "adjTempo": 68.5,
      "adjTempoRank": 131,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 50,
      "record": "20-13",
      "seed": 8
    },
    "Mississippi St.": {
      "adjDef": 97.6,
      "adjDefRank": 61,
      "adjEM": 20.04,
      "adjOff": 117.7,
      "adjOffRank": 13,
      "adjTempo": 67.8,
      "adjTempoRank": 161,
      "conference": "SEC",
      "finish": "Round of 64",
      "rank": 21,
      "record": "23-11",
      "seed": 5
    },
    "Mississippi Valley St.": {
      "adjDef": 114.5,
      "adjDefRank": 338,
      "adjEM": -24.63,
      "adjOff": 89.9,
      "adjOffRank": 344,
      "adjTempo": 69.2,
      "adjTempoRank": 96,
      "conference": "SWAC",
      "rank": 350,
      "record": "6-26"
    },
    "Missouri": {
      "adjDef": 97.2,
      "adjDefRank": 51,
      "adjEM": 9.78,
      "adjOff": 107,
      "adjOffRank": 127,
      "adjTempo": 64.8,
      "adjTempoRank": 318,
      "conference": "SEC",
      "rank": 68,
      "record": "15-17"
    },
    "Missouri St.": {
      "adjDef": 104,
      "adjDefRank": 158,
      "adjEM": -0.85,
      "adjOff": 103.1,
      "adjOffRank": 199,
      "adjTempo": 65.4,
      "adjTempoRank": 287,
      "conference": "MVC",
      "rank": 171,
      "record": "16-16"
    },
    "Monmouth": {
      "adjDef": 104.1,
      "adjDefRank": 160,
      "adjEM": -10.89,
      "adjOff": 93.2,
      "adjOffRank": 336,
      "adjTempo": 67.5,
      "adjTempoRank": 185,
      "conference": "MAAC",
      "rank": 295,
      "record": "14-21"
    },
    "Montana": {
      "adjDef": 103.9,
      "adjDefRank": 155,
      "adjEM": 3.53,
      "adjOff": 107.4,
      "adjOffRank": 114,
      "adjTempo": 66.9,
      "adjTempoRank": 220,
      "conference": "BSky",
      "finish": "Round of 64",
      "rank": 137,
      "record": "26-9",
      "seed": 15
    },
    "Montana St.": {
      "adjDef": 115.4,
      "adjDefRank": 346,
      "adjEM": -7.57,
      "adjOff": 107.8,
      "adjOffRank": 108,
      "adjTempo": 70,
      "adjTempoRank": 70,
      "conference": "BSky",
      "rank": 250,
      "record": "15-17"
    },
    "Morehead St.": {
      "adjDef": 110,
      "adjDefRank": 280,
      "adjEM": -7.27,
      "adjOff": 102.7,
      "adjOffRank": 205,
      "adjTempo": 67.2,
      "adjTempoRank": 200,
      "conference": "OVC",
      "rank": 245,
      "record": "13-20"
    },
    "Morgan St.": {
      "adjDef": 110.7,
      "adjDefRank": 296,
      "adjEM": -17.85,
      "adjOff": 92.8,
      "adjOffRank": 337,
      "adjTempo": 72.1,
      "adjTempoRank": 22,
      "conference": "MEAC",
      "rank": 333,
      "record": "9-21"
    },
    "Mount St. Mary's": {
      "adjDef": 110.1,
      "adjDefRank": 282,
      "adjEM": -15.28,
      "adjOff": 94.8,
      "adjOffRank": 326,
      "adjTempo": 67.1,
      "adjTempoRank": 202,
      "conference": "NEC",
      "rank": 324,
      "record": "9-22"
    },
    "Murray St.": {
      "adjDef": 98.6,
      "adjDefRank": 73,
      "adjEM": 13.83,
      "adjOff": 112.5,
      "adjOffRank": 41,
      "adjTempo": 69.4,
      "adjTempoRank": 89,
      "conference": "OVC",
      "finish": "Round of 32",
      "rank": 51,
      "record": "28-5",
      "seed": 12
    },
    "N.C. State": {
      "adjDef": 98.1,
      "adjDefRank": 62,
      "adjEM": 14.98,
      "adjOff": 113.1,
      "adjOffRank": 34,
      "adjTempo": 71,
      "adjTempoRank": 39,
      "conference": "ACC",
      "rank": 41,
      "record": "24-12"
    },
    "NJIT": {
      "adjDef": 103.3,
      "adjDefRank": 149,
      "adjEM": -1.54,
      "adjOff": 101.8,
      "adjOffRank": 227,
      "adjTempo": 66.9,
      "adjTempoRank": 216,
      "conference": "ASun",
      "rank": 183,
      "record": "22-13"
    },
    "Navy": {
      "adjDef": 107.2,
      "adjDefRank": 230,
      "adjEM": -9.88,
      "adjOff": 97.3,
      "adjOffRank": 299,
      "adjTempo": 66.9,
      "adjTempoRank": 212,
      "conference": "Pat",
      "rank": 280,
      "record": "12-19"
    },
    "Nebraska": {
      "adjDef": 99.1,
      "adjDefRank": 79,
      "adjEM": 14.35,
      "adjOff": 113.5,
      "adjOffRank": 30,
      "adjTempo": 66.4,
      "adjTempoRank": 238,
      "conference": "B10",
      "rank": 47,
      "record": "19-17"
    },
    "Nebraska Omaha": {
      "adjDef": 110.4,
      "adjDefRank": 289,
      "adjEM": -0.2,
      "adjOff": 110.2,
      "adjOffRank": 65,
      "adjTempo": 67.9,
      "adjTempoRank": 153,
      "conference": "Sum",
      "rank": 164,
      "record": "21-11"
    },
    "Nevada": {
      "adjDef": 95.9,
      "adjDefRank": 35,
      "adjEM": 18.18,
      "adjOff": 114,
      "adjOffRank": 26,
      "adjTempo": 69.3,
      "adjTempoRank": 91,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 27,
      "record": "29-5",
      "seed": 7
    },
    "New Hampshire": {
      "adjDef": 107.5,
      "adjDefRank": 237,
      "adjEM": -23.05,
      "adjOff": 84.4,
      "adjOffRank": 353,
      "adjTempo": 65.6,
      "adjTempoRank": 283,
      "conference": "AE",
      "rank": 345,
      "record": "5-24"
    },
    "New Mexico": {
      "adjDef": 105.9,
      "adjDefRank": 202,
      "adjEM": -1.85,
      "adjOff": 104.1,
      "adjOffRank": 183,
      "adjTempo": 72.8,
      "adjTempoRank": 15,
      "conference": "MWC",
      "rank": 188,
      "record": "14-18"
    },
    "New Mexico St.": {
      "adjDef": 99.3,
      "adjDefRank": 84,
      "adjEM": 13.62,
      "adjOff": 113,
      "adjOffRank": 35,
      "adjTempo": 65.9,
      "adjTempoRank": 269,
      "conference": "WAC",
      "finish": "Round of 64",
      "rank": 53,
      "record": "30-5",
      "seed": 12
    },
    "New Orleans": {
      "adjDef": 105.1,
      "adjDefRank": 181,
      "adjEM": -8.1,
      "adjOff": 97,
      "adjOffRank": 302,
      "adjTempo": 68.6,
      "adjTempoRank": 126,
      "conference": "Slnd",
      "rank": 262,
      "record": "19-14"
    },
    "Niagara": {
      "adjDef": 115.3,
      "adjDefRank": 345,
      "adjEM": -13.52,
      "adjOff": 101.8,
      "adjOffRank": 225,
      "adjTempo": 69.7,
      "adjTempoRank": 81,
      "conference": "MAAC",
      "rank": 315,
      "record": "13-19"
    },
    "Nicholls St.": {
      "adjDef": 111.6,
      "adjDefRank": 308,
      "adjEM": -12.85,
      "adjOff": 98.7,
      "adjOffRank": 281,
      "adjTempo": 68.5,
      "adjTempoRank": 129,
      "conference": "Slnd",
      "rank": 310,
      "record": "14-17"
    },
    "Norfolk St.": {
      "adjDef": 105.7,
      "adjDefRank": 196,
      "adjEM": -7.23,
      "adjOff": 98.5,
      "adjOffRank": 284,
      "adjTempo": 69.2,
      "adjTempoRank": 98,
      "conference": "MEAC",
      "rank": 244,
      "record": "22-14"
    },
    "North Alabama": {
      "adjDef": 105.3,
      "adjDefRank": 184,
      "adjEM": -10.02,
      "adjOff": 95.3,
      "adjOffRank": 322,
      "adjTempo": 68.4,
      "adjTempoRank": 135,
      "conference": "ASun",
      "rank": 284,
      "record": "10-22"
    },
    "North Carolina": {
      "adjDef": 92,
      "adjDefRank": 15,
      "adjEM": 27.69,
      "adjOff": 119.7,
      "adjOffRank": 8,
      "adjTempo": 74.3,
      "adjTempoRank": 6,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 7,
      "record": "29-7",
      "seed": 1
    },
    "North Carolina A&T": {
      "adjDef": 108.1,
      "adjDefRank": 249,
      "adjEM": -11.32,
      "adjOff": 96.8,
      "adjOffRank": 305,
      "adjTempo": 65.7,
      "adjTempoRank": 279,
      "conference": "MEAC",
      "rank": 300,
      "record": "19-13"
    },
    "North Carolina Central": {
      "adjDef": 109.3,
      "adjDefRank": 267,
      "adjEM": -11.55,
      "adjOff": 97.8,
      "adjOffRank": 295,
      "adjTempo": 65.3,
      "adjTempoRank": 291,
      "conference": "MEAC",
      "finish": "First Four",
      "rank": 303,
      "record": "18-16",
      "seed": 16
    },
    "North Dakota": {
      "adjDef": 106.3,
      "adjDefRank": 212,
      "adjEM": -8.76,
      "adjOff": 97.6,
      "adjOffRank": 297,
      "adjTempo": 69.7,
      "adjTempoRank": 79,
      "conference": "Sum",
      "rank": 271,
      "record": "12-18"
    },
    "North Dakota St.": {
      "adjDef": 110.6,
      "adjDefRank": 292,
      "adjEM": -3.2,
      "adjOff": 107.4,
      "adjOffRank": 117,
      "adjTempo": 65,
      "adjTempoRank": 312,
      "conference": "Sum",
      "finish": "Round of 64",
      "rank": 202,
      "record": "19-16",
      "seed": 16
    },
    "North Florida": {
      "adjDef": 104.8,
      "adjDefRank": 173,
      "adjEM": -0.69,
      "adjOff": 104.1,
      "adjOffRank": 182,
      "adjTempo": 71.9,
      "adjTempoRank": 24,
      "conference": "ASun",
      "rank": 167,
      "record": "16-17"
    },
    "North Texas": {
      "adjDef": 99.3,
      "adjDefRank": 82,
      "adjEM": 0.53,
      "adjOff": 99.8,
      "adjOffRank": 265,
      "adjTempo": 65.3,
      "adjTempoRank": 294,
      "conference": "CUSA",
      "rank": 158,
      "record": "21-12"
    },
    "Northeastern": {
      "adjDef": 103.4,
      "adjDefRank": 151,
      "adjEM": 7.59,
      "adjOff": 111,
      "adjOffRank": 58,
      "adjTempo": 66.2,
      "adjTempoRank": 253,
      "conference": "CAA",
      "finish": "Round of 64",
      "rank": 89,
      "record": "23-11",
      "seed": 13
    },
    "Northern Arizona": {
      "adjDef": 112.6,
      "adjDefRank": 319,
      "adjEM": -11.83,
      "adjOff": 100.8,
      "adjOffRank": 250,
      "adjTempo": 68.5,
      "adjTempoRank": 130,
      "conference": "BSky",
      "rank": 304,
      "record": "10-21"
    },
    "Northern Colorado": {
      "adjDef": 104.9,
      "adjDefRank": 178,
      "adjEM": -2.27,
      "adjOff": 102.6,
      "adjOffRank": 206,
      "adjTempo": 66.5,
      "adjTempoRank": 236,
      "conference": "BSky",
      "rank": 193,
      "record": "21-11"
    },
    "Northern Illinois": {
      "adjDef": 105.3,
      "adjDefRank": 185,
      "adjEM": 3.72,
      "adjOff": 109,
      "adjOffRank": 86,
      "adjTempo": 65.9,
      "adjTempoRank": 267,
      "conference": "MAC",
      "rank": 136,
      "record": "17-17"
    },
    "Northern Iowa": {
      "adjDef": 102.7,
      "adjDefRank": 136,
      "adjEM": -1.15,
      "adjOff": 101.5,
      "adjOffRank": 235,
      "adjTempo": 64.4,
      "adjTempoRank": 327,
      "conference": "MVC",
      "rank": 177,
      "record": "16-18"
    },
    "Northern Kentucky": {
      "adjDef": 102.3,
      "adjDefRank": 130,
      "adjEM": 7.14,
      "adjOff": 109.4,
      "adjOffRank": 76,
      "adjTempo": 68.2,
      "adjTempoRank": 143,
      "conference": "Horz",
      "finish": "Round of 64",
      "rank": 96,
      "record": "26-9",
      "seed": 14
    },
    "Northwestern": {
      "adjDef": 93.8,
      "adjDefRank": 19,
      "adjEM": 9.06,
      "adjOff": 102.9,
      "adjOffRank": 204,
      "adjTempo": 66,
      "adjTempoRank": 265,
      "conference": "B10",
      "rank": 74,
      "record": "13-19"
    },
    "Northwestern St.": {
      "adjDef": 110,
      "adjDefRank": 279,
      "adjEM": -19.81,
      "adjOff": 90.1,
      "adjOffRank": 342,
      "adjTempo": 68.6,
      "adjTempoRank": 124,
      "conference": "Slnd",
      "rank": 341,
      "record": "11-20"
    },
    "Notre Dame": {
      "adjDef": 101.1,
      "adjDefRank": 107,
      "adjEM": 6.95,
      "adjOff": 108,
      "adjOffRank": 105,
      "adjTempo": 65.2,
      "adjTempoRank": 298,
      "conference": "ACC",
      "rank": 97,
      "record": "14-19"
    },
    "Oakland": {
      "adjDef": 109.6,
      "adjDefRank": 268,
      "adjEM": -1.77,
      "adjOff": 107.8,
      "adjOffRank": 107,
      "adjTempo": 68.7,
      "adjTempoRank": 119,
      "conference": "Horz",
      "rank": 187,
      "record": "16-17"
    },
    "Ohio": {
      "adjDef": 100.5,
      "adjDefRank": 101,
      "adjEM": -1.55,
      "adjOff": 98.9,
      "adjOffRank": 278,
      "adjTempo": 69.2,
      "adjTempoRank": 95,
      "conference": "MAC",
      "rank": 184,
      "record": "14-17"
    },
    "Ohio St.": {
      "adjDef": 94.4,
      "adjDefRank": 25,
      "adjEM": 14.66,
      "adjOff": 109.1,
      "adjOffRank": 84,
      "adjTempo": 65.8,
      "adjTempoRank": 274,
      "conference": "B10",
      "finish": "Round of 32",
      "rank": 44,
      "record": "20-15",
      "seed": 11
    },
    "Oklahoma": {
      "adjDef": 94.1,
      "adjDefRank": 23,
      "adjEM": 16.94,
      "adjOff": 111.1,
      "adjOffRank": 57,
      "adjTempo": 68.6,
      "adjTempoRank": 123,
      "conference": "B12",
      "finish": "Round of 32",
      "rank": 32,
      "record": "20-14",
      "seed": 9
    },
    "Oklahoma St.": {
      "adjDef": 101.3,
      "adjDefRank": 110,
      "adjEM": 8.16,
      "adjOff": 109.5,
      "adjOffRank": 75,
      "adjTempo": 66.1,
      "adjTempoRank": 261,
      "conference": "B12",
      "rank": 83,
      "record": "12-20"
    },
    "Old Dominion": {
      "adjDef": 96,
      "adjDefRank": 38,
      "adjEM": 5.45,
      "adjOff": 101.4,
      "adjOffRank": 238,
      "adjTempo": 64.5,
      "adjTempoRank": 324,
      "conference": "CUSA",
      "finish": "Round of 64",
      "rank": 113,
      "record": "26-9",
      "seed": 14
    },
    "Oral Roberts": {
      "adjDef": 114.2,
      "adjDefRank": 336,
      "adjEM": -10.54,
      "adjOff": 103.7,
      "adjOffRank": 189,
      "adjTempo": 66.9,
      "adjTempoRank": 219,
      "conference": "Sum",
      "rank": 290,
      "record": "11-21"
    },
    "Oregon": {
      "adjDef": 91.7,
      "adjDefRank": 13,
      "adjEM": 17.86,
      "adjOff": 109.5,
      "adjOffRank": 74,
      "adjTempo": 64.2,
      "adjTempoRank": 328,
      "conference": "P12",
      "finish": "Sweet 16",
      "rank": 28,
      "record": "25-13",
      "seed": 12
    },
    "Oregon St.": {
      "adjDef": 103.4,
      "adjDefRank": 150,
      "adjEM": 8.33,
      "adjOff": 111.7,
      "adjOffRank": 48,
      "adjTempo": 65.7,
      "adjTempoRank": 280,
      "conference": "P12",
      "rank": 81,
      "record": "18-13"
    },
    "Pacific": {
      "adjDef": 104.6,
      "adjDefRank": 166,
      "adjEM": -4.56,
      "adjOff": 100,
      "adjOffRank": 256,
      "adjTempo": 65.1,
      "adjTempoRank": 308,
      "conference": "WCC",
      "rank": 216,
      "record": "14-18"
    },
    "Penn": {
      "adjDef": 99.9,
      "adjDefRank": 94,
      "adjEM": 4.21,
      "adjOff": 104.1,
      "adjOffRank": 181,
      "adjTempo": 66.9,
      "adjTempoRank": 217,
      "conference": "Ivy",
      "rank": 130,
      "record": "19-12"
    },
    "Penn St.": {
      "adjDef": 94.5,
      "adjDefRank": 27,
      "adjEM": 14.89,
      "adjOff": 109.4,
      "adjOffRank": 77,
      "adjTempo": 67.6,
      "adjTempoRank": 172,
      "conference": "B10",
      "rank": 43,
      "record": "14-18"
    },
    "Pepperdine": {
      "adjDef": 105.6,
      "adjDefRank": 194,
      "adjEM": 1.43,
      "adjOff": 107,
      "adjOffRank": 124,
      "adjTempo": 70.3,
      "adjTempoRank": 58,
      "conference": "WCC",
      "rank": 151,
      "record": "16-18"
    },
    "Pittsburgh": {
      "adjDef": 98.5,
      "adjDefRank": 70,
      "adjEM": 6.35,
      "adjOff": 104.9,
      "adjOffRank": 168,
      "adjTempo": 67,
      "adjTempoRank": 206,
      "conference": "ACC",
      "rank": 101,
      "record": "14-19"
    },
    "Portland": {
      "adjDef": 110.2,
      "adjDefRank": 283,
      "adjEM": -15.64,
      "adjOff": 94.6,
      "adjOffRank": 329,
      "adjTempo": 66.8,
      "adjTempoRank": 222,
      "conference": "WCC",
      "rank": 326,
      "record": "7-25"
    },
    "Portland St.": {
      "adjDef": 112.1,
      "adjDefRank": 314,
      "adjEM": -10.21,
      "adjOff": 101.9,
      "adjOffRank": 224,
      "adjTempo": 69.1,
      "adjTempoRank": 104,
      "conference": "BSky",
      "rank": 286,
      "record": "16-16"
    },
    "Prairie View A&M": {
      "adjDef": 106.2,
      "adjDefRank": 210,
      "adjEM": -4.13,
      "adjOff": 102.1,
      "adjOffRank": 219,
      "adjTempo": 71.1,
      "adjTempoRank": 38,
      "conference": "SWAC",
      "finish": "First Four",
      "rank": 209,
      "record": "22-13",
      "seed": 16
    },
    "Presbyterian": {
      "adjDef": 110.7,
      "adjDefRank": 293,
      "adjEM": -1.32,
      "adjOff": 109.3,
      "adjOffRank": 78,
      "adjTempo": 67.8,
      "adjTempoRank": 166,
      "conference": "BSth",
      "rank": 179,
      "record": "20-16"
    },
    "Princeton": {
      "adjDef": 100,
      "adjDefRank": 96,
      "adjEM": -1.06,
      "adjOff": 98.9,
      "adjOffRank": 279,
      "adjTempo": 67.6,
      "adjTempoRank": 177,
      "conference": "Ivy",
      "rank": 175,
      "record": "16-12"
    },
    "Providence": {
      "adjDef": 96.3,
      "adjDefRank": 41,
      "adjEM": 8.65,
      "adjOff": 105,
      "adjOffRank": 164,
      "adjTempo": 67.9,
      "adjTempoRank": 158,
      "conference": "BE",
      "rank": 79,
      "record": "18-16"
    },
    "Purdue": {
      "adjDef": 95.6,
      "adjDefRank": 34,
      "adjEM": 26.81,
      "adjOff": 122.5,
      "adjOffRank": 4,
      "adjTempo": 65.9,
      "adjTempoRank": 268,
      "conference": "B10",
      "finish": "Elite Eight",
      "rank": 9,
      "record": "26-10",
      "seed": 3
    },
    "Purdue Fort Wayne": {
      "adjDef": 109,
      "adjDefRank": 260,
      "adjEM": -2.17,
      "adjOff": 106.8,
      "adjOffRank": 130,
      "adjTempo": 71.6,
      "adjTempoRank": 26,
      "conference": "Sum",
      "rank": 192,
      "record": "18-15"
    },
    "Quinnipiac": {
      "adjDef": 111.7,
      "adjDefRank": 310,
      "adjEM": -6.21,
      "adjOff": 105.5,
      "adjOffRank": 154,
      "adjTempo": 66.9,
      "adjTempoRank": 218,
      "conference": "MAAC",
      "rank": 234,
      "record": "16-15"
    },
    "Radford": {
      "adjDef": 104.4,
      "adjDefRank": 164,
      "adjEM": 4.88,
      "adjOff": 109.3,
      "adjOffRank": 79,
      "adjTempo": 64.4,
      "adjTempoRank": 326,
      "conference": "BSth",
      "rank": 122,
      "record": "22-11"
    },
    "Rhode Island": {
      "adjDef": 101.4,
      "adjDefRank": 112,
      "adjEM": 2.44,
      "adjOff": 103.8,
      "adjOffRank": 185,
      "adjTempo": 67.5,
      "adjTempoRank": 180,
      "conference": "A10",
      "rank": 142,
      "record": "18-15"
    },
    "Rice": {
      "adjDef": 110.7,
      "adjDefRank": 294,
      "adjEM": -7.45,
      "adjOff": 103.2,
      "adjOffRank": 198,
      "adjTempo": 70.1,
      "adjTempoRank": 63,
      "conference": "CUSA",
      "rank": 248,
      "record": "13-19"
    },
    "Richmond": {
      "adjDef": 110.3,
      "adjDefRank": 287,
      "adjEM": -3.31,
      "adjOff": 107,
      "adjOffRank": 125,
      "adjTempo": 65.9,
      "adjTempoRank": 266,
      "conference": "A10",
      "rank": 203,
      "record": "13-20"
    },
    "Rider": {
      "adjDef": 105.9,
      "adjDefRank": 203,
      "adjEM": -4.44,
      "adjOff": 101.5,
      "adjOffRank": 236,
      "adjTempo": 73.9,
      "adjTempoRank": 8,
      "conference": "MAAC",
      "rank": 212,
      "record": "16-15"
    },
    "Robert Morris": {
      "adjDef": 104.9,
      "adjDefRank": 176,
      "adjEM": -8.72,
      "adjOff": 96.1,
      "adjOffRank": 314,
      "adjTempo": 66.3,
      "adjTempoRank": 250,
      "conference": "NEC",
      "rank": 269,
      "record": "18-17"
    },
    "Rutgers": {
      "adjDef": 96.8,
      "adjDefRank": 46,
      "adjEM": 8.76,
      "adjOff": 105.6,
      "adjOffRank": 152,
      "adjTempo": 67.7,
      "adjTempoRank": 170,
      "conference": "B10",
      "rank": 78,
      "record": "14-17"
    },
    "SIU Edwardsville": {
      "adjDef": 113.1,
      "adjDefRank": 326,
      "adjEM": -16.21,
      "adjOff": 96.9,
      "adjOffRank": 304,
      "adjTempo": 69.7,
      "adjTempoRank": 80,
      "conference": "OVC",
      "rank": 330,
      "record": "10-21"
    },
    "SMU": {
      "adjDef": 106.7,
      "adjDefRank": 221,
      "adjEM": 5.98,
      "adjOff": 112.7,
      "adjOffRank": 38,
      "adjTempo": 65,
      "adjTempoRank": 310,
      "conference": "Amer",
      "rank": 107,
      "record": "15-17"
    },
    "Sacramento St.": {
      "adjDef": 106,
      "adjDefRank": 205,
      "adjEM": -9.44,
      "adjOff": 96.6,
      "adjOffRank": 309,
      "adjTempo": 68.1,
      "adjTempoRank": 146,
      "conference": "BSky",
      "rank": 274,
      "record": "15-16"
    },
    "Sacred Heart": {
      "adjDef": 111,
      "adjDefRank": 301,
      "adjEM": -6.81,
      "adjOff": 104.2,
      "adjOffRank": 179,
      "adjTempo": 72,
      "adjTempoRank": 23,
      "conference": "NEC",
      "rank": 240,
      "record": "15-17"
    },
    "Saint Joseph's": {
      "adjDef": 107.2,
      "adjDefRank": 231,
      "adjEM": -1.89,
      "adjOff": 105.4,
      "adjOffRank": 159,
      "adjTempo": 68.5,
      "adjTempoRank": 127,
      "conference": "A10",
      "rank": 189,
      "record": "14-19"
    },
    "Saint Louis": {
      "adjDef": 96.2,
      "adjDefRank": 40,
      "adjEM": 6.02,
      "adjOff": 102.2,
      "adjOffRank": 214,
      "adjTempo": 66.4,
      "adjTempoRank": 239,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 106,
      "record": "23-13",
      "seed": 13
    },
    "Saint Mary's": {
      "adjDef": 97.4,
      "adjDefRank": 55,
      "adjEM": 17.31,
      "adjOff": 114.7,
      "adjOffRank": 23,
      "adjTempo": 62.7,
      "adjTempoRank": 348,
      "conference": "WCC",
      "finish": "Round of 64",
      "rank": 31,
      "record": "22-12",
      "seed": 11
    },
    "Saint Peter's": {
      "adjDef": 108.3,
      "adjDefRank": 252,
      "adjEM": -13.63,
      "adjOff": 94.7,
      "adjOffRank": 327,
      "adjTempo": 63.2,
      "adjTempoRank": 342,
      "conference": "MAAC",
      "rank": 316,
      "record": "10-22"
    },
    "Sam Houston St.": {
      "adjDef": 107.2,
      "adjDefRank": 229,
      "adjEM": -1.09,
      "adjOff": 106.1,
      "adjOffRank": 145,
      "adjTempo": 66.3,
      "adjTempoRank": 249,
      "conference": "Slnd",
      "rank": 176,
      "record": "21-12"
    },
    "Samford": {
      "adjDef": 104.7,
      "adjDefRank": 170,
      "adjEM": 1.88,
      "adjOff": 106.6,
      "adjOffRank": 136,
      "adjTempo": 69.2,
      "adjTempoRank": 99,
      "conference": "SC",
      "rank": 144,
      "record": "17-16"
    },
    "San Diego": {
      "adjDef": 98.3,
      "adjDefRank": 67,
      "adjEM": 7.58,
      "adjOff": 105.9,
      "adjOffRank": 147,
      "adjTempo": 66.9,
      "adjTempoRank": 213,
      "conference": "WCC",
      "rank": 90,
      "record": "21-15"
    },
    "San Diego St.": {
      "adjDef": 99,
      "adjDefRank": 78,
      "adjEM": 4.83,
      "adjOff": 103.9,
      "adjOffRank": 184,
      "adjTempo": 66.7,
      "adjTempoRank": 229,
      "conference": "MWC",
      "rank": 125,
      "record": "21-13"
    },
    "San Francisco": {
      "adjDef": 102.1,
      "adjDefRank": 125,
      "adjEM": 9.93,
      "adjOff": 112,
      "adjOffRank": 45,
      "adjTempo": 66.6,
      "adjTempoRank": 232,
      "conference": "WCC",
      "rank": 67,
      "record": "21-10"
    },
    "San Jose St.": {
      "adjDef": 114,
      "adjDefRank": 335,
      "adjEM": -21.99,
      "adjOff": 92,
      "adjOffRank": 339,
      "adjTempo": 69.3,
      "adjTempoRank": 92,
      "conference": "MWC",
      "rank": 343,
      "record": "4-27"
    },
    "Santa Clara": {
      "adjDef": 103.9,
      "adjDefRank": 154,
      "adjEM": -1.77,
      "adjOff": 102.1,
      "adjOffRank": 217,
      "adjTempo": 66.3,
      "adjTempoRank": 242,
      "conference": "WCC",
      "rank": 186,
      "record": "16-15"
    },
    "Savannah St.": {
      "adjDef": 116.1,
      "adjDefRank": 347,
      "adjEM": -19.45,
      "adjOff": 96.6,
      "adjOffRank": 307,
      "adjTempo": 74.9,
      "adjTempoRank": 3,
      "conference": "MEAC",
      "rank": 340,
      "record": "11-20"
    },
    "Seattle": {
      "adjDef": 102.8,
      "adjDefRank": 142,
      "adjEM": -1.99,
      "adjOff": 100.8,
      "adjOffRank": 248,
      "adjTempo": 67.2,
      "adjTempoRank": 198,
      "conference": "WAC",
      "rank": 190,
      "record": "18-15"
    },
    "Seton Hall": {
      "adjDef": 97.5,
      "adjDefRank": 60,
      "adjEM": 11.5,
      "adjOff": 109,
      "adjOffRank": 87,
      "adjTempo": 69.1,
      "adjTempoRank": 100,
      "conference": "BE",
      "finish": "Round of 64",
      "rank": 60,
      "record": "20-14",
      "seed": 10
    },
    "Siena": {
      "adjDef": 109.1,
      "adjDefRank": 263,
      "adjEM": -8.38,
      "adjOff": 100.7,
      "adjOffRank": 251,
      "adjTempo": 60.6,
      "adjTempoRank": 352,
      "conference": "MAAC",
      "rank": 267,
      "record": "17-16"
    },
    "South Alabama": {
      "adjDef": 107.7,
      "adjDefRank": 241,
      "adjEM": -4.52,
      "adjOff": 103.1,
      "adjOffRank": 200,
      "adjTempo": 66.3,
      "adjTempoRank": 241,
      "conference": "SB",
      "rank": 214,
      "record": "17-17"
    },
    "South Carolina": {
      "adjDef": 98.6,
      "adjDefRank": 71,
      "adjEM": 9.55,
      "adjOff": 108.1,
      "adjOffRank": 104,
      "adjTempo": 70.2,
      "adjTempoRank": 61,
      "conference": "SEC",
      "rank": 70,
      "record": "16-16"
    },
    "South Carolina St.": {
      "adjDef": 116.8,
      "adjDefRank": 348,
      "adjEM": -18.5,
      "adjOff": 98.3,
      "adjOffRank": 287,
      "adjTempo": 67,
      "adjTempoRank": 209,
      "conference": "MEAC",
      "rank": 337,
      "record": "8-26"
    },
    "South Dakota": {
      "adjDef": 106.2,
      "adjDefRank": 209,
      "adjEM": -5.61,
      "adjOff": 100.6,
      "adjOffRank": 253,
      "adjTempo": 67,
      "adjTempoRank": 210,
      "conference": "Sum",
      "rank": 229,
      "record": "13-17"
    },
    "South Dakota St.": {
      "adjDef": 105.3,
      "adjDefRank": 183,
      "adjEM": 7.46,
      "adjOff": 112.7,
      "adjOffRank": 37,
      "adjTempo": 71,
      "adjTempoRank": 40,
      "conference": "Sum",
      "rank": 92,
      "record": "24-9"
    },
    "South Florida": {
      "adjDef": 95.9,
      "adjDefRank": 37,
      "adjEM": 6.63,
      "adjOff": 102.6,
      "adjOffRank": 208,
      "adjTempo": 67.5,
      "adjTempoRank": 184,
      "conference": "Amer",
      "rank": 99,
      "record": "24-14"
    },
    "Southeast Missouri St.": {
      "adjDef": 110,
      "adjDefRank": 281,
      "adjEM": -12.22,
      "adjOff": 97.8,
      "adjOffRank": 294,
      "adjTempo": 67.6,
      "adjTempoRank": 174,
      "conference": "OVC",
      "rank": 308,
      "record": "10-21"
    },
    "Southeastern Louisiana": {
      "adjDef": 105.5,
      "adjDefRank": 192,
      "adjEM": -6.07,
      "adjOff": 99.5,
      "adjOffRank": 272,
      "adjTempo": 65.3,
      "adjTempoRank": 295,
      "conference": "Slnd",
      "rank": 232,
      "record": "17-16"
    },
    "Southern": {
      "adjDef": 112.1,
      "adjDefRank": 315,
      "adjEM": -17.91,
      "adjOff": 94.2,
      "adjOffRank": 330,
      "adjTempo": 65.7,
      "adjTempoRank": 277,
      "conference": "SWAC",
      "rank": 335,
      "record": "7-25"
    },
    "Southern Illinois": {
      "adjDef": 102.8,
      "adjDefRank": 140,
      "adjEM": 1.79,
      "adjOff": 104.6,
      "adjOffRank": 172,
      "adjTempo": 65.8,
      "adjTempoRank": 275,
      "conference": "MVC",
      "rank": 146,
      "record": "17-15"
    },
    "Southern Miss": {
      "adjDef": 102.2,
      "adjDefRank": 127,
      "adjEM": 4.93,
      "adjOff": 107.1,
      "adjOffRank": 121,
      "adjTempo": 64.8,
      "adjTempoRank": 316,
      "conference": "CUSA",
      "rank": 120,
      "record": "20-13"
    },
    "Southern Utah": {
      "adjDef": 105.8,
      "adjDefRank": 199,
      "adjEM": -9.4,
      "adjOff": 96.4,
      "adjOffRank": 311,
      "adjTempo": 71.2,
      "adjTempoRank": 35,
      "conference": "BSky",
      "rank": 273,
      "record": "17-17"
    },
    "St. Bonaventure": {
      "adjDef": 97.2,
      "adjDefRank": 50,
      "adjEM": 4.92,
      "adjOff": 102.1,
      "adjOffRank": 218,
      "adjTempo": 64,
      "adjTempoRank": 330,
      "conference": "A10",
      "rank": 121,
      "record": "18-16"
    },
    "St. Francis NY": {
      "adjDef": 105.6,
      "adjDefRank": 195,
      "adjEM": -9.5,
      "adjOff": 96.1,
      "adjOffRank": 315,
      "adjTempo": 68.3,
      "adjTempoRank": 138,
      "conference": "NEC",
      "rank": 275,
      "record": "17-16"
    },
    "St. Francis PA": {
      "adjDef": 113.5,
      "adjDefRank": 332,
      "adjEM": -8.04,
      "adjOff": 105.5,
      "adjOffRank": 155,
      "adjTempo": 68.4,
      "adjTempoRank": 137,
      "conference": "NEC",
      "rank": 261,
      "record": "18-15"
    },
    "St. John's": {
      "adjDef": 101,
      "adjDefRank": 106,
      "adjEM": 7.61,
      "adjOff": 108.6,
      "adjOffRank": 92,
      "adjTempo": 70.8,
      "adjTempoRank": 46,
      "conference": "BE",
      "finish": "First Four",
      "rank": 88,
      "record": "21-13",
      "seed": 11
    },
    "Stanford": {
      "adjDef": 98.2,
      "adjDefRank": 66,
      "adjEM": 5.47,
      "adjOff": 103.7,
      "adjOffRank": 187,
      "adjTempo": 69.9,
      "adjTempoRank": 71,
      "conference": "P12",
      "rank": 112,
      "record": "15-16"
    },
    "Stephen F. Austin": {
      "adjDef": 108.4,
      "adjDefRank": 254,
      "adjEM": -12.94,
      "adjOff": 95.5,
      "adjOffRank": 320,
      "adjTempo": 68.8,
      "adjTempoRank": 112,
      "conference": "Slnd",
      "rank": 311,
      "record": "14-16"
    },
    "Stetson": {
      "adjDef": 113.3,
      "adjDefRank": 329,
      "adjEM": -15.46,
      "adjOff": 97.9,
      "adjOffRank": 293,
      "adjTempo": 68.8,
      "adjTempoRank": 113,
      "conference": "ASun",
      "rank": 325,
      "record": "7-24"
    },
    "Stony Brook": {
      "adjDef": 99.9,
      "adjDefRank": 95,
      "adjEM": -0.42,
      "adjOff": 99.5,
      "adjOffRank": 271,
      "adjTempo": 69.3,
      "adjTempoRank": 90,
      "conference": "AE",
      "rank": 166,
      "record": "24-9"
    },
    "Syracuse": {
      "adjDef": 95.3,
      "adjDefRank": 30,
      "adjEM": 15.13,
      "adjOff": 110.5,
      "adjOffRank": 59,
      "adjTempo": 66.1,
      "adjTempoRank": 257,
      "conference": "ACC",
      "finish": "Round of 64",
      "rank": 39,
      "record": "20-14",
      "seed": 8
    },
    "TCU": {
      "adjDef": 95.2,
      "adjDefRank": 29,
      "adjEM": 15.04,
      "adjOff": 110.3,
      "adjOffRank": 64,
      "adjTempo": 68.9,
      "adjTempoRank": 107,
      "conference": "B12",
      "rank": 40,
      "record": "23-14"
    },
    "Temple": {
      "adjDef": 99.5,
      "adjDefRank": 85,
      "adjEM": 9.72,
      "adjOff": 109.2,
      "adjOffRank": 80,
      "adjTempo": 68.9,
      "adjTempoRank": 110,
      "conference": "Amer",
      "finish": "First Four",
      "rank": 69,
      "record": "23-10",
      "seed": 11
    },
    "Tennessee": {
      "adjDef": 96.5,
      "adjDefRank": 42,
      "adjEM": 26.24,
      "adjOff": 122.7,
      "adjOffRank": 3,
      "adjTempo": 67.8,
      "adjTempoRank": 164,
      "conference": "SEC",
      "finish": "Sweet 16",
      "rank": 10,
      "record": "31-6",
      "seed": 2
    },
    "Tennessee Martin": {
      "adjDef": 115.3,
      "adjDefRank": 344,
      "adjEM": -10.35,
      "adjOff": 104.9,
      "adjOffRank": 167,
      "adjTempo": 68.5,
      "adjTempoRank": 132,
      "conference": "OVC",
      "rank": 289,
      "record": "12-19"
    },
    "Tennessee St.": {
      "adjDef": 109.6,
      "adjDefRank": 270,
      "adjEM": -10.73,
      "adjOff": 98.9,
      "adjOffRank": 280,
      "adjTempo": 69.4,
      "adjTempoRank": 88,
      "conference": "OVC",
      "rank": 292,
      "record": "9-21"
    },
    "Tennessee Tech": {
      "adjDef": 104.7,
      "adjDefRank": 171,
      "adjEM": -14.87,
      "adjOff": 89.9,
      "adjOffRank": 345,
      "adjTempo": 68.3,
      "adjTempoRank": 139,
      "conference": "OVC",
      "rank": 320,
      "record": "8-23"
    },
    "Texas": {
      "adjDef": 94.4,
      "adjDefRank": 26,
      "adjEM": 19.11,
      "adjOff": 113.5,
      "adjOffRank": 29,
      "adjTempo": 64.7,
      "adjTempoRank": 320,
      "conference": "B12",
      "rank": 25,
      "record": "21-16"
    },
    "Texas A&M": {
      "adjDef": 99.7,
      "adjDefRank": 89,
      "adjEM": 7.49,
      "adjOff": 107.2,
      "adjOffRank": 120,
      "adjTempo": 67.4,
      "adjTempoRank": 187,
      "conference": "SEC",
      "rank": 91,
      "record": "14-18"
    },
    "Texas A&M Corpus Chris": {
      "adjDef": 104.8,
      "adjDefRank": 174,
      "adjEM": -10.25,
      "adjOff": 94.6,
      "adjOffRank": 328,
      "adjTempo": 64.9,
      "adjTempoRank": 315,
      "conference": "Slnd",
      "rank": 287,
      "record": "14-18"
    },
    "Texas Southern": {
      "adjDef": 107.5,
      "adjDefRank": 238,
      "adjEM": -2.35,
      "adjOff": 105.2,
      "adjOffRank": 161,
      "adjTempo": 74.7,
      "adjTempoRank": 4,
      "conference": "SWAC",
      "rank": 195,
      "record": "24-14"
    },
    "Texas St.": {
      "adjDef": 98.8,
      "adjDefRank": 76,
      "adjEM": 3.53,
      "adjOff": 102.4,
      "adjOffRank": 212,
      "adjTempo": 65.1,
      "adjTempoRank": 306,
      "conference": "SB",
      "rank": 138,
      "record": "24-10"
    },
    "Texas Tech": {
      "adjDef": 84.1,
      "adjDefRank": 1,
      "adjEM": 30.03,
      "adjOff": 114.1,
      "adjOffRank": 25,
      "adjTempo": 66.6,
      "adjTempoRank": 231,
      "conference": "B12",
      "finish": "Runner-up",
      "rank": 5,
      "record": "31-7",
      "seed": 3
    },
    "The Citadel": {
      "adjDef": 114.5,
      "adjDefRank": 337,
      "adjEM": -7.83,
      "adjOff": 106.7,
      "adjOffRank": 134,
      "adjTempo": 73.6,
      "adjTempoRank": 10,
      "conference": "SC",
      "rank": 257,
      "record": "12-18"
    },
    "Toledo": {
      "adjDef": 98.5,
      "adjDefRank": 69,
      "adjEM": 11.29,
      "adjOff": 109.8,
      "adjOffRank": 70,
      "adjTempo": 67.8,
      "adjTempoRank": 165,
      "conference": "MAC",
      "rank": 61,
      "record": "25-8"
    },
    "Towson": {
      "adjDef": 110.9,
      "adjDefRank": 298,
      "adjEM": -10.67,
      "adjOff": 100.2,
      "adjOffRank": 255,
      "adjTempo": 63.5,
      "adjTempoRank": 337,
      "conference": "CAA",
      "rank": 291,
      "record": "10-22"
    },
    "Troy": {
      "adjDef": 110.4,
      "adjDefRank": 290,
      "adjEM": -7.49,
      "adjOff": 102.9,
      "adjOffRank": 202,
      "adjTempo": 67,
      "adjTempoRank": 207,
      "conference": "SB",
      "rank": 249,
      "record": "12-18"
    },
    "Tulane": {
      "adjDef": 107.3,
      "adjDefRank": 232,
      "adjEM": -9.99,
      "adjOff": 97.3,
      "adjOffRank": 300,
      "adjTempo": 69.7,
      "adjTempoRank": 78,
      "conference": "Amer",
      "rank": 283,
      "record": "4-27"
    },
    "Tulsa": {
      "adjDef": 100.4,
      "adjDefRank": 100,
      "adjEM": 4.94,
      "adjOff": 105.4,
      "adjOffRank": 158,
      "adjTempo": 68.8,
      "adjTempoRank": 114,
      "conference": "Amer",
      "rank": 119,
      "record": "18-14"
    },
    "UAB": {
      "adjDef": 105.4,
      "adjDefRank": 187,
      "adjEM": 1.7,
      "adjOff": 107.1,
      "adjOffRank": 123,
      "adjTempo": 65.2,
      "adjTempoRank": 299,
      "conference": "CUSA",
      "rank": 147,
      "record": "20-15"
    },
    "UC Davis": {
      "adjDef": 104.1,
      "adjDefRank": 159,
      "adjEM": -7.61,
      "adjOff": 96.5,
      "adjOffRank": 310,
      "adjTempo": 65.9,
      "adjTempoRank": 272,
      "conference": "BW",
      "rank": 252,
      "record": "11-20"
    },
    "UC Irvine": {
      "adjDef": 98.2,
      "adjDefRank": 65,
      "adjEM": 9.16,
      "adjOff": 107.4,
      "adjOffRank": 118,
      "adjTempo": 65.3,
      "adjTempoRank": 293,
      "conference": "BW",
      "finish": "Round of 32",
      "rank": 73,
      "record": "31-6",
      "seed": 13
    },
    "UC Riverside": {
      "adjDef": 113.4,
      "adjDefRank": 331,
      "adjEM": -15.09,
      "adjOff": 98.3,
      "adjOffRank": 289,
      "adjTempo": 63.3,
      "adjTempoRank": 340,
      "conference": "BW",
      "rank": 322,
      "record": "10-23"
    },
    "UC Santa Barbara": {
      "adjDef": 106.1,
      "adjDefRank": 208,
      "adjEM": -0.86,
      "adjOff": 105.3,
      "adjOffRank": 160,
      "adjTempo": 64.7,
      "adjTempoRank": 321,
      "conference": "BW",
      "rank": 172,
      "record": "22-10"
    },
    "UCF": {
      "adjDef": 95.6,
      "adjDefRank": 33,
      "adjEM": 16.51,
      "adjOff": 112.1,
      "adjOffRank": 44,
      "adjTempo": 65,
      "adjTempoRank": 309,
      "conference": "Amer",
      "finish": "Round of 32",
      "rank": 34,
      "record": "24-9",
      "seed": 9
    },
    "UCLA": {
      "adjDef": 102.7,
      "adjDefRank": 137,
      "adjEM": 6.34,
      "adjOff": 109,
      "adjOffRank": 88,
      "adjTempo": 72.5,
      "adjTempoRank": 17,
      "conference": "P12",
      "rank": 102,
      "record": "17-16"
    },
    "UMBC": {
      "adjDef": 102,
      "adjDefRank": 119,
      "adjEM": -5.75,
      "adjOff": 96.2,
      "adjOffRank": 313,
      "adjTempo": 65,
      "adjTempoRank": 311,
      "conference": "AE",
      "rank": 230,
      "record": "21-13"
    },
    "UMKC": {
      "adjDef": 107.6,
      "adjDefRank": 239,
      "adjEM": -6.45,
      "adjOff": 101.1,
      "adjOffRank": 245,
      "adjTempo": 66.3,
      "adjTempoRank": 248,
      "conference": "WAC",
      "rank": 238,
      "record": "11-21"
    },
    "UMass Lowell": {
      "adjDef": 112.4,
      "adjDefRank": 317,
      "adjEM": -7.86,
      "adjOff": 104.5,
      "adjOffRank": 174,
      "adjTempo": 69.8,
      "adjTempoRank": 74,
      "conference": "AE",
      "rank": 258,
      "record": "15-17"
    },
    "UNC Asheville": {
      "adjDef": 117,
      "adjDefRank": 349,
      "adjEM": -23.64,
      "adjOff": 93.3,
      "adjOffRank": 333,
      "adjTempo": 61.9,
      "adjTempoRank": 350,
      "conference": "BSth",
      "rank": 347,
      "record": "4-27"
    },
    "UNC Greensboro": {
      "adjDef": 98.8,
      "adjDefRank": 74,
      "adjEM": 7.63,
      "adjOff": 106.4,
      "adjOffRank": 141,
      "adjTempo": 67.8,
      "adjTempoRank": 159,
      "conference": "SC",
      "rank": 87,
      "record": "29-7"
    },
    "UNC Wilmington": {
      "adjDef": 114.7,
      "adjDefRank": 339,
      "adjEM": -9.31,
      "adjOff": 105.4,
      "adjOffRank": 156,
      "adjTempo": 69.3,
      "adjTempoRank": 93,
      "conference": "CAA",
      "rank": 272,
      "record": "10-23"
    },
    "UNLV": {
      "adjDef": 106.4,
      "adjDefRank": 215,
      "adjEM": -0.29,
      "adjOff": 106.1,
      "adjOffRank": 146,
      "adjTempo": 67.5,
      "adjTempoRank": 182,
      "conference": "MWC",
      "rank": 165,
      "record": "17-14"
    },
    "USC": {
      "adjDef": 101.5,
      "adjDefRank": 118,
      "adjEM": 8.25,
      "adjOff": 109.8,
      "adjOffRank": 72,
      "adjTempo": 69.6,
      "adjTempoRank": 83,
      "conference": "P12",
      "rank": 82,
      "record": "16-17"
    },
    "USC Upstate": {
      "adjDef": 111.4,
      "adjDefRank": 305,
      "adjEM": -18.1,
      "adjOff": 93.3,
      "adjOffRank": 334,
      "adjTempo": 68,
      "adjTempoRank": 149,
      "conference": "BSth",
      "rank": 336,
      "record": "6-26"
    },
    "UT Arlington": {
      "adjDef": 99.9,
      "adjDefRank": 93,
      "adjEM": 1.45,
      "adjOff": 101.3,
      "adjOffRank": 241,
      "adjTempo": 66.3,
      "adjTempoRank": 251,
      "conference": "SB",
      "rank": 150,
      "record": "17-16"
    },
    "UT Rio Grande Valley": {
      "adjDef": 101.1,
      "adjDefRank": 109,
      "adjEM": -1.5,
      "adjOff": 99.6,
      "adjOffRank": 270,
      "adjTempo": 70.4,
      "adjTempoRank": 56,
      "conference": "WAC",
      "rank": 182,
      "record": "20-17"
    },
    "UTEP": {
      "adjDef": 101.4,
      "adjDefRank": 114,
      "adjEM": -11.31,
      "adjOff": 90.1,
      "adjOffRank": 343,
      "adjTempo": 68,
      "adjTempoRank": 151,
      "conference": "CUSA",
      "rank": 299,
      "record": "8-21"
    },
    "UTSA": {
      "adjDef": 104.3,
      "adjDefRank": 163,
      "adjEM": 1.54,
      "adjOff": 105.8,
      "adjOffRank": 148,
      "adjTempo": 72.1,
      "adjTempoRank": 19,
      "conference": "CUSA",
      "rank": 149,
      "record": "17-15"
    },
    "Utah": {
      "adjDef": 108.9,
      "adjDefRank": 257,
      "adjEM": 5.85,
      "adjOff": 114.8,
      "adjOffRank": 22,
      "adjTempo": 66.4,
      "adjTempoRank": 240,
      "conference": "P12",
      "rank": 109,
      "record": "17-14"
    },
    "Utah St.": {
      "adjDef": 97.2,
      "adjDefRank": 52,
      "adjEM": 15.41,
      "adjOff": 112.6,
      "adjOffRank": 39,
      "adjTempo": 68.1,
      "adjTempoRank": 145,
      "conference": "MWC",
      "finish": "Round of 64",
      "rank": 38,
      "record": "28-7",
      "seed": 8
    },
    "Utah Valley": {
      "adjDef": 102.3,
      "adjDefRank": 131,
      "adjEM": 6.22,
      "adjOff": 108.5,
      "adjOffRank": 96,
      "adjTempo": 68.8,
      "adjTempoRank": 115,
      "conference": "WAC",
      "rank": 104,
      "record": "25-10"
    },
    "VCU": {
      "adjDef": 89.5,
      "adjDefRank": 7,
      "adjEM": 14.89,
      "adjOff": 104.3,
      "adjOffRank": 177,
      "adjTempo": 68.4,
      "adjTempoRank": 136,
      "conference": "A10",
      "finish": "Round of 64",
      "rank": 42,
      "record": "25-8",
      "seed": 8
    },
    "VMI": {
      "adjDef": 112.7,
      "adjDefRank": 321,
      "adjEM": -10.3,
      "adjOff": 102.4,
      "adjOffRank": 213,
      "adjTempo": 71.6,
      "adjTempoRank": 27,
      "conference": "SC",
      "rank": 288,
      "record": "11-21"
    },
    "Valparaiso": {
      "adjDef": 103.1,
      "adjDefRank": 145,
      "adjEM": -4.89,
      "adjOff": 98.2,
      "adjOffRank": 291,
      "adjTempo": 65.2,
      "adjTempoRank": 300,
      "conference": "MVC",
      "rank": 220,
      "record": "15-18"
    },
    "Vanderbilt": {
      "adjDef": 101.4,
      "adjDefRank": 116,
      "adjEM": 0.81,
      "adjOff": 102.2,
      "adjOffRank": 215,
      "adjTempo": 67.3,
      "adjTempoRank": 194,
      "conference": "SEC",
      "rank": 155,
      "record": "9-23"
    },
    "Vermont": {
      "adjDef": 99.6,
      "adjDefRank": 88,
      "adjEM": 8.86,
      "adjOff": 108.5,
      "adjOffRank": 97,
      "adjTempo": 65.5,
      "adjTempoRank": 285,
      "conference": "AE",
      "finish": "Round of 64",
      "rank": 76,
      "record": "27-7",
      "seed": 13
    },
    "Villanova": {
      "adjDef": 99.2,
      "adjDefRank": 81,
      "adjEM": 17.33,
      "adjOff": 116.5,
      "adjOffRank": 16,
      "adjTempo": 63.6,
      "adjTempoRank": 335,
      "conference": "BE",
      "finish": "Round of 32",
      "rank": 30,
      "record": "26-10",
      "seed": 6
    },
    "Virginia": {
      "adjDef": 89.2,
      "adjDefRank": 5,
      "adjEM": 34.22,
      "adjOff": 123.4,
      "adjOffRank": 2,
      "adjTempo": 59.4,
      "adjTempoRank": 353,
      "conference": "ACC",
      "finish": "Champion",
      "rank": 1,
      "record": "35-3",
      "seed": 1
    },
    "Virginia Tech": {
      "adjDef": 94,
      "adjDefRank": 20,
      "adjEM": 24.07,
      "adjOff": 118.1,
      "adjOffRank": 11,
      "adjTempo": 63.7,
      "adjTempoRank": 334,
      "conference": "ACC",
      "finish": "Sweet 16",
      "rank": 13,
      "record": "26-9",
      "seed": 4
    },
    "Wagner": {
      "adjDef": 107.5,
      "adjDefRank": 234,
      "adjEM": -13.51,
      "adjOff": 93.9,
      "adjOffRank": 331,
      "adjTempo": 63.8,
      "adjTempoRank": 332,
      "conference": "NEC",
      "rank": 314,
      "record": "13-17"
    },
    "Wake Forest": {
      "adjDef": 105.3,
      "adjDefRank": 186,
      "adjEM": -0.93,
      "adjOff": 104.4,
      "adjOffRank": 175,
      "adjTempo": 67.6,
      "adjTempoRank": 179,
      "conference": "ACC",
      "rank": 174,
      "record": "11-20"
    },
    "Washington": {
      "adjDef": 93.5,
      "adjDefRank": 18,
      "adjEM": 14.28,
      "adjOff": 107.7,
      "adjOffRank": 110,
      "adjTempo": 66,
      "adjTempoRank": 262,
      "conference": "P12",
      "finish": "Round of 32",
      "rank": 48,
      "record": "27-9",
      "seed": 9
    },
    "Washington St.": {
      "adjDef": 110.2,
      "adjDefRank": 284,
      "adjEM": -3.79,
      "adjOff": 106.4,
      "adjOffRank": 140,
      "adjTempo": 70.6,
      "adjTempoRank": 50,
      "conference": "P12",
      "rank": 207,
      "record": "11-21"
    },
    "Weber St.": {
      "adjDef": 106.7,
      "adjDefRank": 222,
      "adjEM": -5.03,
      "adjOff": 101.7,
      "adjOffRank": 229,
      "adjTempo": 71.1,
      "adjTempoRank": 37,
      "conference": "BSky",
      "rank": 224,
      "record": "18-15"
    },
    "West Virginia": {
      "adjDef": 102.7,
      "adjDefRank": 135,
      "adjEM": 7.15,
      "adjOff": 109.8,
      "adjOffRank": 71,
      "adjTempo": 70.6,
      "adjTempoRank": 49,
      "conference": "B12",
      "rank": 95,
      "record": "15-21"
    },
    "Western Carolina": {
      "adjDef": 111.2,
      "adjDefRank": 303,
      "adjEM": -9.58,
      "adjOff": 101.6,
      "adjOffRank": 232,
      "adjTempo": 69.5,
      "adjTempoRank": 85,
      "conference": "SC",
      "rank": 277,
      "record": "7-25"
    },
    "Western Illinois": {
      "adjDef": 108.5,
      "adjDefRank": 256,
      "adjEM": -10.86,
      "adjOff": 97.6,
      "adjOffRank": 296,
      "adjTempo": 67.3,
      "adjTempoRank": 190,
      "conference": "Sum",
      "rank": 294,
      "record": "10-21"
    },
    "Western Kentucky": {
      "adjDef": 99.9,
      "adjDefRank": 92,
      "adjEM": 5.2,
      "adjOff": 105.1,
      "adjOffRank": 162,
      "adjTempo": 67.7,
      "adjTempoRank": 168,
      "conference": "CUSA",
      "rank": 116,
      "record": "20-14"
    },
    "Western Michigan": {
      "adjDef": 107,
      "adjDefRank": 228,
      "adjEM": -7.82,
      "adjOff": 99.2,
      "adjOffRank": 275,
      "adjTempo": 69.2,
      "adjTempoRank": 97,
      "conference": "MAC",
      "rank": 255,
      "record": "8-24"
    },
    "Wichita St.": {
      "adjDef": 96.5,
      "adjDefRank": 44,
      "adjEM": 10.1,
      "adjOff": 106.6,
      "adjOffRank": 135,
      "adjTempo": 68.3,
      "adjTempoRank": 142,
      "conference": "Amer",
      "rank": 66,
      "record": "22-15"
    },
    "William & Mary": {
      "adjDef": 108.4,
      "adjDefRank": 255,
      "adjEM": -4.79,
      "adjOff": 103.6,
      "adjOffRank": 190,
      "adjTempo": 68.6,
      "adjTempoRank": 120,
      "conference": "CAA",
      "rank": 219,
      "record": "14-17"
    },
    "Winthrop": {
      "adjDef": 107.8,
      "adjDefRank": 244,
      "adjEM": -0.74,
      "adjOff": 107,
      "adjOffRank": 126,
      "adjTempo": 72.7,
      "adjTempoRank": 16,
      "conference": "BSth",
      "rank": 168,
      "record": "18-12"
    },
    "Wisconsin": {
      "adjDef": 88.5,
      "adjDefRank": 4,
      "adjEM": 21.94,
      "adjOff": 110.4,
      "adjOffRank": 62,
      "adjTempo": 63.9,
      "adjTempoRank": 331,
      "conference": "B10",
      "finish": "Round of 64",
      "rank": 16,
      "record": "23-11",
      "seed": 5
    },
    "Wofford": {
      "adjDef": 97.4,
      "adjDefRank": 56,
      "adjEM": 20.69,
      "adjOff": 118.1,
      "adjOffRank": 10,
      "adjTempo": 65.7,
      "adjTempoRank": 278,
      "conference": "SC",
      "finish": "Round of 32",
      "rank": 18,
      "record": "30-5",
      "seed": 7
    },
    "Wright St.": {
      "adjDef": 102,
      "adjDefRank": 122,
      "adjEM": 5.02,
      "adjOff": 107.1,
      "adjOffRank": 122,
      "adjTempo": 65.2,
      "adjTempoRank": 305,
      "conference": "Horz",
      "rank": 117,
      "record": "21-14"
    },
    "Wyoming": {
      "adjDef": 108.9,
      "adjDefRank": 259,
      "adjEM": -13.86,
      "adjOff": 95.1,
      "adjOffRank": 325,
      "adjTempo": 68.4,
      "adjTempoRank": 133,
      "conference": "MWC",
      "rank": 317,
      "record": "8-24"
    },
    "Xavier": {
      "adjDef": 100.5,
      "adjDefRank": 102,
      "adjEM": 10.6,
      "adjOff": 111.1,
      "adjOffRank": 56,
      "adjTempo": 65.2,
      "adjTempoRank": 302,
      "conference": "BE",
      "rank": 65,
      "record": "19-16"
    },
    "Yale": {
      "adjDef": 102.8,
      "adjDefRank": 141,
      "adjEM": 8.79,
      "adjOff": 111.6,
      "adjOffRank": 51,
      "adjTempo": 71,
      "adjTempoRank": 41,
      "conference": "Ivy",
      "finish": "Round of 64",
      "rank": 77,
      "record": "22-8",
      "seed": 14
    },
    "Youngstown St.": {
      "adjDef": 112.3,
      "adjDefRank": 316,
      "adjEM": -7.97,
      "adjOff": 104.3,
      "adjOffRank": 178,
      "adjTempo": 67,
      "adjTempoRank": 205,
      "conference": "Horz",
      "rank": 259,
      "record": "12-20"
    }
  }
}