import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Plot from 'react-plotly.js';

const plotConfig = {
  displayModeBar: false,
  responsive: true,
  scrollZoom: true
};

const categories = ['adjEmHistory', 'adjDefHistory', 'adjOffHistory'];

const Team = ({ match, teamData }) => {
  const { team } = match.params;
  const { conference, rank, record } = teamData;
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    const data = [];

    for (let category of categories) {
      const dates = [];
      const values = [];
      for (let date in teamData[category]) {
        dates.push(date);
        values.push(teamData[category][date]);
      }
      // Slice off "History" from category
      const trace = { x: dates, y: values, name: category.slice(0, -7) };
      if (category === 'adjEmHistory') {
        trace.xaxis = 'x2';
        trace.yaxis = 'y2';
      }
      data.push(trace);
    }

    setPlotData(data);
  }, [teamData]);

  const plotLayout = {
    title: {
      text: 'Adjusted Efficiency',
    },
    yaxis: {
      title: 'Off/Def',
    },
    yaxis2: {
      title: 'Margin',
    },
  };

  if (window.innerWidth < 400) {
    plotLayout.grid = { rows: 2, columns: 1, pattern: 'independent' };
    plotLayout.yaxis.domain = [0, 0.45];
    plotLayout.yaxis2.domain = [0.55, 1];
  } else {
    plotLayout.grid = { rows: 1, columns: 2, pattern: 'independent' };
    plotLayout.xaxis = { domain: [0, 0.45] };
    plotLayout.xaxis2 = { domain: [0.55, 1] };
  }

  return teamData
    ? (
      <div className="team-portal-container">
        <div className="team-portal-header">
          <div className="team-portal-header-title header-title">{team}</div>
          <div><span className="label">Conference:</span> {conference}</div>
          <div><span className="label">Record:</span> {record}</div>
          <div><span className="label">Kenpom Rank:</span> {rank}</div>
        </div>
        <div className="team-portal-body">
          <Plot
            config={plotConfig}
            data={plotData}
            layout={plotLayout}
          />
        </div>
      </div>
    )
    : null;
}

const mapState = (state, ownProps) => ({
  teamData: (state.rankings[ownProps.match.params.year] && state.rankings[ownProps.match.params.year][ownProps.match.params.team]) || {}
})

export default connect(mapState)(Team);