export const getColumnIdFromStart = (columns, start) => {
  const column = columns.find(column => column.colId.startsWith(start));
  if (column && start) {
    return column.colId;
  } else {
    console.warn(`Could not find column starting with ${start}`);
  }
}

export const getStrippedColumnId = id => {
  return id.split('_')[0];
}
