import React, { Component } from 'react';
import { FaArrowUp, FaArrowDown, FaFilter } from 'react-icons/fa';
import classNames from 'classnames';
import queryString from 'query-string';

class GridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: props.column.sort, isFilterActive: props.column.isFilterActive() };
    this.handleSort = this.handleSort.bind(this);
    this.showFilter = this.showFilter.bind(this);

    this.filterIconId = `${this.props.displayName}-filter-icon`;
  }

  componentDidMount() {
    const { column } = this.props;
    column.addEventListener('sortChanged', () => {
      const sort = column.getSort();
      this.setState({ sort })
    });

    column.addEventListener('filterChanged', () => {
      this.setState({ isFilterActive: column.isFilterActive() });
    });
  }

  handleSort() {
    if (this.props.enableSorting) {
      let sort;
      switch (this.props.column.sort) {
        case 'asc':
          sort = 'desc';
          break;
        case 'desc':
          sort = null;
          break;
        default:
          sort = 'asc';
      }
      this.setState({ sort });
      this.props.setSort(sort);
      const search = queryString.parse(this.props.getSearch());
      const [id] = this.props.column.colId.split('_');
      if (sort) {
        search[`${id}Sort`] = sort;
      } else if (search[`${id}Sort`]) {
        delete search[`${id}Sort`];
      }
      const updatedQueryString = queryString.stringify(search);
      this.props.pushHistory('/rankings', `?${updatedQueryString}`);
    }
  }

  showFilter() {
    const filterIcon = document.getElementById(this.filterIconId);
    this.props.showColumnMenu(filterIcon);
    // Filters for peripheral columns get cutoff by grid overflow on first mount.
    // setTimeout lets them mount very briefly and then re-mount in proper position.
    setTimeout(() => {
      this.props.api.hidePopupMenu();
      this.props.showColumnMenu(filterIcon);
    }, 50)
  }

  render() {
    const sortIcon =
      this.state.sort
        ? this.state.sort === 'asc'
          ? <FaArrowUp className="grid-header-cell-sort-icon" />
          : <FaArrowDown className="grid-header-cell-sort-icon" />
        : null;

    return (
      <div className="grid-header-cell">
        <div className="grid-header-cell-sortable-container" onClick={this.handleSort}>
          <div className="grid-header-cell-name">{this.props.displayName}</div>
          {sortIcon}
        </div>
        {
          this.props.enableMenu
            ? (
              <FaFilter
                id={this.filterIconId}
                className={classNames({
                  'grid-header-cell-filter-icon-active': this.props.column.isFilterActive(),
                  'grid-header-cell-filter-icon-inactive': !this.props.column.isFilterActive(),
                })}
                onClick={this.showFilter}
              />
            )
            : null
        }
      </div>
    )
  }
}

export default GridHeader;