import React from 'react';
import { NavLink } from 'react-router-dom';

const GridLink = props => {
  return (
    <div className="grid-link">
      <NavLink to={`/teams/${props.value}/${props.year}`}>{props.value}</NavLink>
    </div>
  )
}

export default GridLink;