import React, { Component } from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import { About, Rankings, Team } from './views';
import { withFirebase, Navbar } from './components';

class App extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      this.props.firebase.getRankings();
      this.props.firebase.getUpdateTime();
      this.props.firebase.getYears();
    }
  }

  render() {
    return (
      <div className="flex-column">
        <Navbar />
        <div id="routes-container">
          <Switch>
            <Route exact path="/rankings" component={Rankings} />
            <Route path="/teams/:team/:year" component={Team} />
            <Route path="/about" component={About} />
            <Redirect from="/" to="/rankings" />
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(withFirebase(App));
