import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducer';

export * from './actions';

export default function configureStore(preloadedState) {
  const middleware = applyMiddleware(thunkMiddleware);
  const composedEnhancers = composeWithDevTools(middleware);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  return store;
}
